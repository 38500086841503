import React from 'react'
import { Typography } from '@mui/material'
import { ReportFieldItem, UserReportItem, ReportDataItem, UserReportFieldItem } from 'api/tenilo.reporting.api-client'
import ReportTableColumn from './components/report-table-column'

type Props = Readonly<{
  reportData: ReportDataItem | undefined
  reportFields: ReportFieldItem[] | undefined
  visibleDataRows: string[] | undefined
  visibleAlphaChannelRows: string[] | undefined
  userReport?: UserReportItem
  enablePaging?: boolean
  pageNumber: number | undefined
  pageSize: number | undefined
  showRowCounter?: boolean
  formFields: ReportFieldItem[] | undefined
  sortedUserReportFields: UserReportFieldItem[] | undefined
  sortedReportFields: ReportFieldItem[] | undefined
}>

const ReportTableDataRows: React.FC<Props> = ({ reportData, reportFields, visibleDataRows, visibleAlphaChannelRows, userReport, enablePaging, pageNumber, pageSize, showRowCounter, formFields, sortedReportFields, sortedUserReportFields }) => {

  const metaFields = React.useMemo(() => {
    return sortedReportFields?.filter(rf => rf.isMeta)
  }, [sortedReportFields])

  return (
    <>
      {reportData && reportFields && visibleDataRows && visibleDataRows.map((row, index) => {
        const rowCounter = <Typography key={`rt_rc_${index}`} variant="caption" fontWeight={600}>{index + 1 + (enablePaging && pageNumber && pageSize ? (pageNumber - 1) * pageSize : 0)}</Typography>
        const dataColumns: JSX.Element[] = []
        const rowValues = row.split(reportData.separator)
        const alphaChannelRow = visibleAlphaChannelRows ? visibleAlphaChannelRows[index] : ''
        const alphaChannelRowValues = alphaChannelRow ? alphaChannelRow.split(reportData.separator) : ''
        const metaData = metaFields?.map(mf => rowValues[mf.fieldIndex]);

        if (userReport) {
          sortedUserReportFields?.forEach(urf => {
            const rf = reportFields.find(field => field.id === urf.reportFieldId)
            if (!rf) { return }

            const formField = formFields?.find(ff => ff.id === urf.reportFieldId)
            if (!formField) { return }

            const datafield = rowValues[rf.fieldIndex]
            const alphaChannelField = alphaChannelRowValues[rf.fieldIndex]
            dataColumns.push(<ReportTableColumn key={`rt_rtc_${rf.fieldIndex}`} dataField={datafield} alphaChannelField={alphaChannelField} reportField={rf} metaData={metaData} />)
          })

          formFields?.forEach(ff => {
            const rf = reportFields.find(field => field.id === ff.id)
            if (!rf) { return }

            const userReportField = sortedUserReportFields?.find(urf => urf.reportFieldId === ff.id)
            if (userReportField) { return }

            const datafield = rowValues[rf.fieldIndex]
            const alphaChannelField = alphaChannelRowValues[rf.fieldIndex]
            dataColumns.push(<ReportTableColumn key={`rt_mrtc_${rf.fieldIndex}`} dataField={datafield} alphaChannelField={alphaChannelField} reportField={rf} metaData={metaData} />)
          })

        } else {
          sortedReportFields?.forEach(rf => {

            const formField = formFields?.find(ff => ff.id === rf.id)
            if (!formField) { return }

            const datafield = rowValues[rf.fieldIndex]
            const alphaChannelField = alphaChannelRowValues[rf.fieldIndex]
            dataColumns.push(<ReportTableColumn key={`rt_rtc_${rf.fieldIndex}`} dataField={datafield} alphaChannelField={alphaChannelField} reportField={rf} metaData={metaData} />)
          })
        }
        const elements: JSX.Element[] = []
        if (showRowCounter) {
          elements.push(rowCounter)
        }
        elements.push(...dataColumns)
        return [elements]
      })}

    </>
  )
}

export default React.memo(ReportTableDataRows)
