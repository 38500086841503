import React from 'react'
import * as yup from 'yup'

import { IGrandIdServiceItem, GrandIdServiceUpdateItem } from 'api/tenilo.support.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { SupportGrandIdModalContext } from '../support-grandid-modal-context'

type GrandIdServiceForm = Readonly<{
  value: string
  authenticateServiceKey: string
  active: boolean
}>

const mapGrandIdServiceFormToGrandIdServiceItem = (initialItem: IGrandIdServiceItem, item: GrandIdServiceForm): GrandIdServiceUpdateItem => {
  return new GrandIdServiceUpdateItem({
    id: initialItem.id,
    value: initialItem.value,
    authenticateServiceKey: item.authenticateServiceKey,
    active: item.active,
  })
}

const mapGrandIdServiceItemToGrandIdServiceForm = (item: IGrandIdServiceItem): GrandIdServiceForm => {
  const mappedItem: GrandIdServiceForm = {
    value: item.value,
    authenticateServiceKey: item.authenticateServiceKey ? item.authenticateServiceKey : '',
    active: item.active,
  }
  return mappedItem
}

const useSupportGrandIdServiceFormData = (initialGrandIdService?: IGrandIdServiceItem) => {
  const f = useFormatMessage()

  const { updateGrandIdServiceAsync } = SupportGrandIdModalContext.useActions()
  const { refreshGrandIdServices } = SupportGrandIdModalContext.useActions()
  const grandIdService = initialGrandIdService

  const initialValues = React.useMemo<GrandIdServiceForm>(
    () =>
      !!grandIdService
        ? mapGrandIdServiceItemToGrandIdServiceForm(grandIdService)
        : {
            value: '',
            authenticateServiceKey: '',
            active: false,
          },
    [grandIdService]
  )

  const onSubmit: OnSubmitFunction<GrandIdServiceForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      if (initialGrandIdService) {
        const mappedGrandIdService = mapGrandIdServiceFormToGrandIdServiceItem(initialGrandIdService, values)
        await updateGrandIdServiceAsync(mappedGrandIdService).finally(() => {
          setSubmitting(false)
          refreshGrandIdServices()
        })
      }
    },
    [initialGrandIdService, updateGrandIdServiceAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      /*title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),
*/
      /*      description: yup.string().max(
              1024,
              f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
                name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
                maxsize: 1024,
              })
            ),*/
    })
  }, [f])

  const name: Name<GrandIdServiceForm> = React.useMemo(
    () => ({
      value: 'value',
      authenticateServiceKey: 'authenticateServiceKey',
      active: 'active',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useSupportGrandIdServiceFormData
