import { PlannedActivityStatusEnum } from 'api/tillit.api-client'
import styled, { css } from 'styled-components'
import Colors from 'styles/colors'
import { InfoDelayedView } from './info-delayed-view'

export const getColorStyles = (status: PlannedActivityStatusEnum) => {
  switch (status) {
    case PlannedActivityStatusEnum.Completed:
      return Colors.JungleGreen
    case PlannedActivityStatusEnum.CompletedWithoutAction:
      return Colors.Punch
    case PlannedActivityStatusEnum.NotCompleted:
      return Colors.JellyBean
    case PlannedActivityStatusEnum.Delayed:
      return Colors.TeniloBrown
    case PlannedActivityStatusEnum.CannotTake:
      return Colors.Punch
    case PlannedActivityStatusEnum.Given:
      return Colors.JungleGreen
    case PlannedActivityStatusEnum.RelativeGiven:
      return Colors.JungleGreen
    case PlannedActivityStatusEnum.SelfGiven:
      return Colors.JungleGreen
    case PlannedActivityStatusEnum.SentAlong:
      return Colors.JungleGreen
    case PlannedActivityStatusEnum.NotGiven:
      return Colors.Punch
    case PlannedActivityStatusEnum.PartiallyComplete:
      return Colors.TeniloYellow
    case PlannedActivityStatusEnum.PartiallyGiven:
        return Colors.TeniloYellow
    case PlannedActivityStatusEnum.Started:
        return Colors.LightBlue
    default:
      return Colors.TeniloBrown
  }
}

export const InfoDelayedStyle = styled(InfoDelayedView)(({ status, theme }) => {
  const color = getColorStyles(status)
  return css`
    border-radius: 0.5vh;
    width: 100%;
    margin-bottom: 0.5vh;

    border: 1px solid ${color};
    /*border-left: 5px solid ${color};*/

    .infoIcon {
      vertical-align: middle;
      color: ${color};
      margin-right: 15px;
    }

    .textInfo {
      padding-top: 5px;
    }

    .date {
      color: ${Colors.Tuna};
      opacity: 0.6;
      float: right;
    }

    .textGray {
      color: ${Colors.Tuna};
      opacity: 0.6;
      word-break: break-all;
    }
    ${theme.breakpoints.down('md')}{
      width: auto;
      padding:5px;
      display: block;
    }
  `
})
