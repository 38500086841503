import React, { useEffect } from 'react'
import { Box, useTheme, MenuItem } from '@mui/material'
import { useFormatMessage } from 'localization'
import ModalHeader from 'components/modal-header'
import ModalFooter from 'components/modal-footer'
import TextButton from 'components/inputs/text-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import ContainedButton from 'components/inputs/contained-button'
import FilledSelect from 'components/inputs/filled-select'
import CenterModal from 'components/center-modal'
import { Form, Formik, useFormikContext, FormikContextType  } from 'formik'
import Spinner from 'components/spinner'
import isEmpty from 'lodash/isEmpty'
import { ticksToInputTime } from 'utilities/date-time-helpers'
import { WorkScheduleConnectionContext } from './work-schedule-connection-context'
import useWorkConnectionScheduleFormData from './utilities/use-work-schedule-connection-form-data'

const FormObserver: React.FC = () => {
  const { values } = useFormikContext() as FormikContextType<{
    workScheduleCommonShiftId: number | null;
    areaId: number | null;
  }>
  const { fetchNonInheritedCommonShiftsByAreaId } = WorkScheduleConnectionContext.useActions()
  const { workScheduleConnectionModalState } = WorkScheduleConnectionContext.useState()

  useEffect(()=>{
    void fetchNonInheritedCommonShiftsByAreaId(values.areaId,workScheduleConnectionModalState.item)
  },[values.areaId, fetchNonInheritedCommonShiftsByAreaId, workScheduleConnectionModalState])

  return null;
};


const WorkScheduleConnectionDialog: React.FC = () => {
  const f = useFormatMessage()
  const { workScheduleConnectionModalState, isLoading, availableAreas, nonInheritedCommonShifts, commonShifts } = WorkScheduleConnectionContext.useState()
  const { closeWorkScheduleModal } = WorkScheduleConnectionContext.useActions()
  const { initialValues, name, onSubmit, validationSchema } = useWorkConnectionScheduleFormData(workScheduleConnectionModalState.item)
  const theme = useTheme()


  return (
    <CenterModal open={workScheduleConnectionModalState.open} onClose={closeWorkScheduleModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty, handleBlur, handleChange, touched }) => {


          const handleChangeWorkScheduleCommonShiftId = (event: any, child: any) => {
            setFieldValue('workScheduleCommonShiftId', event.target.value)
          }

          const handleChangeAreaId = async (event: any, child: any) => {
            setFieldValue('areaId', event.target.value)
          }

          const shifts = !!workScheduleConnectionModalState.item ? commonShifts : nonInheritedCommonShifts

          return (
            <Form translate="yes">
              <FormObserver />
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={workScheduleConnectionModalState.item?.commonShiftDescription || f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_HEADER')}
                ml={3}
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>
                  <ModalSection
                    title={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_DIALOG_TITLE')}
                    subtitle={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_DIALOG_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledSelect
                          name={name.areaId}
                          label={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_DIALOG_TITLE')}
                          value={values.areaId || ""}
                          disabled={!!workScheduleConnectionModalState.item?.id}
                          onChange={handleChangeAreaId}>
                          {availableAreas?.map((area, index) => (
                            <MenuItem key={index} value={area.areaId}>
                              {area.organizationParentsName ? `${area.areaName} (${area.organizationParentsName})` : area.areaName}
                            </MenuItem>
                          ))}
                        </FilledSelect>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_TITLE')}
                    subtitle={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledSelect
                          name={name.workScheduleCommonShiftId}
                          label={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_TITLE')}
                          value={values.workScheduleCommonShiftId || ""}
                          disabled={!values.areaId || !!workScheduleConnectionModalState.item?.id || !nonInheritedCommonShifts ||  isEmpty(nonInheritedCommonShifts)}
                          onChange={handleChangeWorkScheduleCommonShiftId}>
                          {shifts?.map((shift, index) => (
                            <MenuItem key={index} value={shift.id}>
                              {f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_SHIFT_DETAILS',{
                                description: shift.description,
                                from: ticksToInputTime(shift.scheduledStartTimeTicks),
                                to: ticksToInputTime(shift.scheduledEndTimeTicks)
                              })}
                            </MenuItem>
                          ))}
                        </FilledSelect>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeWorkScheduleModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting || !values.workScheduleCommonShiftId}>
                    {!workScheduleConnectionModalState.item ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default WorkScheduleConnectionDialog
