import { Box, useTheme, Tab } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import { Tabs, TabPanel, a11yProps } from 'components/tab-panel'
import Spinner from 'components/spinner'
import ModalContent from 'components/modal-content'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import SupportUserModalBasicInformationView from './support-user-modal-basic-information-view'
import { SupportUserModalContext } from './support-user-modal-context'
import useFormData from './utilities/use-support-user-form-data'
import SupportUserModalRolesView from './support-user-modal-roles-view'

export type SupportUserModalProps = Readonly<{
  onSave?: () => void
}>

const SupportUserModalContainer: React.FC<SupportUserModalProps> = ({ onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()
  const [tabValue, setTabValue] = React.useState<number>(0)

  const handleTabChange = React.useCallback((event: any, newValue: React.SetStateAction<number>) => {
    setTabValue(newValue)
  },[])

  const { modalOpen, selectedUser, isLoading } = SupportUserModalContext.useState()

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedUser)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeSupportUserModal } = SupportUserModalContext.useActions()

  return (
    <CenterModal open={modalOpen} onClose={closeSupportUserModal} fullSize>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {

          return (
            <Form translate="yes" style={{display:'contents'}}>
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={f('SUPPORT_USER_MODAL_DIALOG_HEADER_TITLE')}
              />
              <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label={f('SUPPORT_USER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE')} {...a11yProps(0)} />
                <Tab label={`${f('SUPPORT_USER_DIALOG_VIEW_TAB_ROLES_TITLE')}`} {...a11yProps(1)} />
              </Tabs>
              <ModalContent style={{padding:'24px'}}>
                <TabPanel value={tabValue} index={0}>
                  <SupportUserModalBasicInformationView name={name} values={values}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <SupportUserModalRolesView areas={selectedUser?.userAreas} />
                </TabPanel>
              </ModalContent>
              <ModalFooter>
                <TextButton type="button" onClick={closeSupportUserModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedUser ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default SupportUserModalContainer
