import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ChevronRight } from 'mdi-material-ui'

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const DashboardKpiDetailsfooteractionBar : React.FC<{
    handleExpandClick: () => void,
    expanded: boolean,
    layoutSize: string,
}> = (props) => {
  const { expanded, layoutSize, handleExpandClick } = props

  return (
    <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        sx={{padding:0}}
    >
        <ChevronRight sx={{
        fontSize:layoutSize === 's' ? '1.2rem' : layoutSize === 'm' ? '1.4rem' : '1.6rem',
        }} />
    </ExpandMore>
  )
}

export default React.memo(DashboardKpiDetailsfooteractionBar)
