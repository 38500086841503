import { ReportFieldItem, ReportItem, UserReportFieldItem, UserReportItem } from "api/tenilo.reporting.api-client"
import { IUserItem } from "api/tillit.api-client"
import Color from "color"
import { useFormatMessage } from "localization"

export const dataSeparator = '@Sep@'

export const validFieldUse = (reportField: ReportFieldItem, user: IUserItem): boolean => {
  if (reportField.useDelegations && !user.useDelegations) {
    return false
  }
  if (reportField.useDrugLists && !user.useDrugLists) {
    return false
  }
  if (reportField.useWorkSchedule && !user.useWorkSchedule) {
    return false
  }

  return true
}

export const sortUserReportFields = (userReport: UserReportItem | undefined, formFields: ReportFieldItem[] | undefined): UserReportFieldItem[] | undefined => {

  if (!formFields) {
    return userReport?.userReportFields.sort((a, b) => {
      return a.sortIndex - b.sortIndex;
    })
  }

  return userReport?.userReportFields.filter(uReport => formFields.some(f => f.id === uReport.reportFieldId)).sort((a, b) => {
    const aIndex = formFields.findIndex(f => f.id === a.reportFieldId)
    const bIndex = formFields.findIndex(f => f.id === b.reportFieldId)
    return aIndex - bIndex
  })
}

export const sortReportFields = (report: ReportItem | undefined, formFields: ReportFieldItem[] | undefined): ReportFieldItem[] | undefined => {
  return report?.reportFields.sort((a, b) => {
    const formFieldA = formFields?.find(ff => ff.id === a.id)
    const formFieldB = formFields?.find(ff => ff.id === b.id)
    return (formFieldA ? formFieldA.fieldIndex : a.fieldIndex) - (formFieldB ? formFieldB.fieldIndex : b.fieldIndex)
  })
}

export const createReportFilename = (report: ReportItem | undefined, userReport: UserReportItem | undefined, suffix: string): string => {
  const reportName = report ? report.reportName : (userReport ? userReport.reportName : '')
  return `${reportName} ${new Date().toISOString()}.${suffix}`
}

export const getReportName = (report: ReportItem | undefined, userReport: UserReportItem | undefined): string => {
  return report && report.reportName ? report.reportName : (userReport && userReport.reportName ? userReport.reportName : '')
}

export const getReportDescription = (report: ReportItem | undefined, userReport: UserReportItem | undefined): string => {
  return report && report.reportDescription ? report.reportDescription : (userReport && userReport.reportDescription ? userReport.reportDescription : '')
}

export const extractReportHeadersAndData = (f: ReturnType<typeof useFormatMessage>, report: ReportItem, dataRows: string[],
  alphaChannelRows: string[] | undefined, user: IUserItem, replaceCheckMark: boolean | undefined, formFields: ReportFieldItem[] | undefined, sortedFieldItems: ReportFieldItem[] | undefined): any[] => {

  const sortedReportFields = sortReportFields(report, sortedFieldItems)?.filter(rf => validFieldUse(rf, user) && !(rf.isLink || rf.isMeta))

  const head: any[] = []
  const headers: string[] = []
  sortedReportFields?.forEach(rf => {
    const formField = formFields?.find(ff => ff.id === rf.id)
    if (!formField) { return }

    const headerName = rf.headerName ? f(rf.headerName) : ''
    headers.push(headerName)
  })
  head.push(headers)

  const rows: any[] = []
  dataRows.forEach(dataRow => {
    const dataFields = dataRow.split(dataSeparator)
    const rowData: string[] = []
    sortedReportFields?.forEach((rf) => {
      const formField = formFields?.find(ff => ff.id === rf.id)
      if (!formField) { return }

      let data = dataFields[rf.fieldIndex]
      if (replaceCheckMark) {
        data = data.replace('✔', 'X') //Note: jsPDF Cannot display ✔ without installing a custom font. Replacing it with X for now.
      }
      rowData.push(data)
    })
    rows.push(rowData)
  })

  const alphaRows: any[] = []
  alphaChannelRows?.forEach(alphaChannelRow => {
    const dataFields = alphaChannelRow.split(dataSeparator)
    const rowData: string[] = []
    sortedReportFields?.forEach((rf) => {
      const formField = formFields?.find(ff => ff.id === rf.id)
      if (!formField) { return }

      const data = dataFields[rf.fieldIndex]
      rowData.push(data)
    })
    alphaRows.push(rowData)
  })

  return [head, rows, alphaRows]
}

export const extractUserReportHeadersAndData = (f: ReturnType<typeof useFormatMessage>, userReport: UserReportItem, dataRows: string[],
  alphaChannelRows: string[] | undefined, user: IUserItem, formFields: ReportFieldItem[] | undefined, sortedFieldItems: ReportFieldItem[] | undefined): any[] => {

  const sortedReportFields = sortUserReportFields(userReport, sortedFieldItems)

  const head: any[] = []
  const headers: string[] = []
  sortedReportFields?.forEach(urf => {
    const rf = userReport.parentReport.reportFields.find(field => field.id === urf.reportFieldId)
    if (!rf) { return }
    if (rf.isLink || rf.isMeta) { return }
    if (!validFieldUse(rf, user)) { return }

    const formField = formFields?.find(ff => ff.id === urf.reportFieldId)
    if (!formField) { return }

    const headerName = rf.headerName ? f(rf.headerName) : ''
    headers.push(headerName)
  })
  formFields?.forEach(ff => {
    const rf = userReport.parentReport.reportFields.find(field => field.id === ff.id)
    if (!rf) { return }
    if (rf.isLink || rf.isMeta) { return }
    if (!validFieldUse(rf, user)) { return }

    const userReportField = sortedReportFields?.find(urf => urf.reportFieldId === ff.id)
    if (userReportField) { return }

    const headerName = rf.headerName ? f(rf.headerName) : ''
    headers.push(headerName)
  })
  head.push(headers)

  const rows: any[] = []
  dataRows.forEach(dataRow => {
    const dataFields = dataRow.split(dataSeparator)
    const rowData: string[] = []
    sortedReportFields?.forEach((urf) => {
      const rf = userReport.parentReport.reportFields.find(field => field.id === urf.reportFieldId)
      if (!rf) { return }
      if (rf.isLink || rf.isMeta) { return }
      if (!validFieldUse(rf, user)) { return }

      const formField = formFields?.find(ff => ff.id === urf.reportFieldId)
      if (!formField) { return }

      const data = dataFields[rf.fieldIndex]
      rowData.push(data)
    })
    formFields?.forEach((ff) => {
      const rf = userReport.parentReport.reportFields.find(field => field.id === ff.id)
      if (!rf) { return }
      if (rf.isLink || rf.isMeta) { return }
      if (!validFieldUse(rf, user)) { return }

      const userReportField = sortedReportFields?.find(urf => urf.reportFieldId === ff.id)
      if (userReportField) { return }

      const data = dataFields[rf.fieldIndex]
      rowData.push(data)
    })
    rows.push(rowData)
  })

  const alphaRows: any[] = []
  alphaChannelRows?.forEach(alphaRow => {
    const dataFields = alphaRow.split(dataSeparator)
    const rowData: string[] = []
    sortedReportFields?.forEach((urf) => {
      const rf = userReport.parentReport.reportFields.find(field => field.id === urf.reportFieldId)
      if (!rf) { return }
      if (rf.isLink || rf.isMeta) { return }
      if (!validFieldUse(rf, user)) { return }

      const formField = formFields?.find(ff => ff.id === urf.reportFieldId)
      if (!formField) { return }

      const data = dataFields[rf.fieldIndex]
      rowData.push(data)
    })
    formFields?.forEach((ff) => {
      const rf = userReport.parentReport.reportFields.find(field => field.id === ff.id)
      if (!rf) { return }
      if (rf.isLink || rf.isMeta) { return }
      if (!validFieldUse(rf, user)) { return }

      const userReportField = sortedReportFields?.find(urf => urf.reportFieldId === ff.id)
      if (userReportField) { return }

      const data = dataFields[rf.fieldIndex]
      rowData.push(data)
    })
    alphaRows.push(rowData)
  })

  return [head, rows, alphaRows]
}

export type FillAndTextColor = Readonly<{
  fillColor: string
  textColor: string
}>

export const fillAndTextColorFromAlpha = (alphaValue: number): FillAndTextColor => {
  //This will calculate new rgb colors based on alpha and base color
  //the result will have no alpha channel since this is not supported when exporting to excel

  //Red-ish color from color palette... C32C2C (195,44,44)
  const baseR = 195
  const baseG = 44
  const baseB = 44

  const alpha = (1 - alphaValue / 100.0) * 255
  const newR = Math.trunc((255 - baseR) * (alpha / 255.0) + baseR)
  const newG = Math.trunc((255 - baseG) * (alpha / 255.0) + baseG)
  const newB = Math.trunc((255 - baseG) * (alpha / 255.0) + baseB)

  const fillColor = Color(`rgb(${newR}, ${newG}, ${newB})`).hex()
  const textColor = Color(fillColor).contrast(Color('black')) > 6.0 ? '#000000' : '#FFFFFF'

  return {
    fillColor,
    textColor
  }
}
