import React from 'react'
import { PlanningGridContext } from '../planning-grid-context'
import { SessionProps } from '../planning-grid-types'
import { useDrop, UseDropProps } from './drag-and-drop/drag-and-drop'

export function useDropSessionRemove<S extends SessionProps>(
  spec: UseDropProps
) {
  const { onDrop, ...rest } = spec
  const { callbacks = {} } = PlanningGridContext.useActions()

  type HandleDrop = NonNullable<typeof onDrop>
  const handleDrop: HandleDrop = React.useCallback(
    (...args: Parameters<HandleDrop>) => {
      const props = args[0]
      const { type, ...session } = props.item
      const { meta, ...internal } = session as S

      if (callbacks.onUpdate) {
        callbacks.onUpdate({ internal, meta, action: 'remove' })
      }

      return onDrop ? onDrop(...args) : undefined
    },
    [callbacks, onDrop]
  )

  return useDrop(['SESSION_UPDATE', 'SESSION_REMOVE', 'SESSION_ADD'], {
    onDrop: handleDrop,
    ...rest,
  })
}
