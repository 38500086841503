import React, { FC } from 'react'
import { RoleEnum, UserTypeEnum } from 'api/tillit.api-client'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'
import { makeStyles } from '@mui/styles';
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'
import { FormikProps, FieldArray,  FieldArrayRenderProps } from 'formik'
import useUserForm from 'components/user-dialog/utilities/use-user-form'
import { Authorize } from 'components/authorization'
import DelegationView from './delegation-section-view'
import DelegationSectionContext from './delegation-section-context'

const useStyles = makeStyles({
  'delegation-container': {
    padding: '1vh 2vh 0 2vh',
    height:"calc(100% - 80px)",
    overflow:"auto",
  },
});

const DelegationContainer : FC<FormikProps<UserFormModelWithModifiedAreas>>  = ({...props}) => {
  const { name } = useUserForm()
  const classes = useStyles();
  const { user, loggedInUser } = DelegationSectionContext.useState()
  const { values } = props
  const f = useFormatMessage()

  const handleAddUserDelegation = (FieldArrayProps :  FieldArrayRenderProps) => () => {
    FieldArrayProps.unshift({
      userId: user?.id
    })
  }

  let disableEditUser = false
  if (loggedInUser.userAreas && loggedInUser.userType === UserTypeEnum.OrganizationMember) {
    const loggedInAdminOrPlannerUserAreas =  loggedInUser.userAreas.filter(a => (a.roleId === RoleEnum.Admin) || (a.roleId === RoleEnum.Planner))
    const loggedInAdminOrPlannerUserAreaIds = loggedInAdminOrPlannerUserAreas.map(a => a.areaId)
    const areaIdsToCheck = user?.userAreas?.map(ua => ua.areaId)

    const areaIdsExist = loggedInAdminOrPlannerUserAreaIds.some((areaId) => areaIdsToCheck?.includes(areaId))
    if (!areaIdsExist) {
      disableEditUser = true
    }
    else {
      disableEditUser = false
    }
  }

  return (
    <div className={classes['delegation-container']}>
      <FieldArray name={name.selectedDelegations}>
        {(FieldArrayProps) => {
          return (
            <div>
              <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
                {!disableEditUser && (
                  <ContainedButton type="button" color="secondary" onClick={handleAddUserDelegation(FieldArrayProps)}>
                    {f('USER_DIALOG_VIEW_DELEGATION_ADD_BUTTON_TEXT')}
                  </ContainedButton>
                )}
              </Authorize>
              {
                values.selectedDelegations?.map((selectedDelegation,i) =>(
                  <DelegationView key={`user-delegation-${i}`}  selectedDelegation={selectedDelegation} index={i} {...FieldArrayProps}/>
                ))
              }
            </div>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default DelegationContainer
