import React from 'react'
import * as yup from 'yup'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { useAuthenticationApi } from 'api'

type ForgotPasswordForm = Readonly<{
  email: string
}>

const handleError = (setStatus: (status?: any) => void) => (error: any) => {
  const data = error.response && JSON.parse(error.response).data
  //Should we want to set a specific error message... Set a status here and then fix in container
  if (data) {
    setStatus({ error: 2 })
  } else {
    setStatus({ error: 1 })
  }
}

// Define the hook to be used to leverage this form
const useForgotPasswordForm = () => {
  const f = useFormatMessage()

  const { forgotPassword } = useAuthenticationApi()

  const initialValues: ForgotPasswordForm = React.useMemo(
    () => ({
      email: '',
    }),
    []
  )

  const onSubmit: OnSubmitFunction<ForgotPasswordForm> = React.useCallback(
    ({ email }, { setSubmitting, setStatus }) => {
      forgotPassword(email)
        .then(() => {
          setStatus({ error: 100 })
        })
        .catch(handleError(setStatus))
        .finally(() => {
          setSubmitting(false)
        })
    },
    [forgotPassword]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ForgotPasswordForm>({
        email: yup
          .string()
          .email(f('FORGOT_PASSWORD_INCORRECT_EMAIL'))
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      }),
    [f]
  )

  const name: Name<ForgotPasswordForm> = React.useMemo(
    () => ({
      email: 'email',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useForgotPasswordForm
