import React from 'react'
import { IUserListItem } from 'api/tillit.api-client'
import { InfoDelayedStyle } from './info-delayed-style'
import { InfoDelayedView } from './info-delayed-view'

const InfoDelayedContainer: React.SFC<Omit<React.ComponentProps<typeof InfoDelayedView>, 'signedUser'> & {
  signedUser?: IUserListItem
}> = ({ signedUser, ...rest }) => {
  if (!signedUser) {
    return null
  }
  return <InfoDelayedStyle {...rest} signedUser={signedUser} />
}

export default React.memo(InfoDelayedContainer)
