import React, { MouseEventHandler } from 'react'
import { Box, Button, Switch, Typography } from '@mui/material'
import { useFormatMessage } from 'localization'
import { Colors } from 'styles/colors'
import Color from 'color'
import { ReactComponent as ColumnIcon } from './columnIcon.svg'
import { ReactComponent as GridIcon } from './gridIcon.svg'
import { ReactComponent as RowIcon } from './rowIcon.svg'
import { ReactComponent as NextIcon } from './nextIcon.svg'
import { ReactComponent as PrevIcon } from './prevIcon.svg'
import { ReactComponent as FirstIcon } from './firstIcon.svg'
import { ReactComponent as LastIcon } from './lastIcon.svg'
import { ReactComponent as FullScreenIcon } from './fullscreenIcon.svg'
import { ReactComponent as FullScreenExitIcon } from './fullscreenexitIcon.svg'

export enum BorderType {
  None = 'None',
  All = 'All',
  Columns = 'Columns',
  Rows = 'Rows',
}

type Props = Readonly<{
  firstPage?: () => void
  prevPage?: () => void
  nextPage?: () => void
  lastPage?: () => void
  enablePaging?: boolean
  pageNumber: number
  pageSize?: number | undefined
  lastPageNumber: number
  setBorders: (border: BorderType) => MouseEventHandler<HTMLButtonElement> | undefined
  borderType: BorderType
  enableBorderSelector?: boolean
  enableRowCounter?: boolean
  showRowCounter?: boolean
  switchShowRowNumbers?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  exportXLSX?: () => void
  exportCSV?: () => void
  exportPDF?: () => void
  fullscreen?: () => void
  isFullscreen?: boolean
  disabled?: boolean
}>

const ReportTableToolbar: React.FC<Props> = ({
  firstPage, prevPage, nextPage, lastPage, enablePaging, pageNumber, pageSize, lastPageNumber,
  setBorders, borderType, enableBorderSelector,
  enableRowCounter, showRowCounter, switchShowRowNumbers,
  exportXLSX, exportCSV, exportPDF, fullscreen, isFullscreen, disabled }) => {
  const f = useFormatMessage()
  return (
    <Box sx={{ borderRadius: '5px', height:'43px',paddingTop:'7px', backgroundColor: Color(Colors.AthensGray).string() }}>
      {true && (
        <Box sx={{ float: 'right' }}>
          <Button sx={{ minWidth: '0px' }} onClick={fullscreen} disabled={disabled}>{isFullscreen ? <FullScreenExitIcon /> : <FullScreenIcon />}</Button>
        </Box>
      )}
      {enablePaging && pageNumber && pageSize && (
        <Box sx={{ float: 'right' }}>
          <Button sx={{ minWidth: '0px' }} disabled={pageNumber === 1} onClick={firstPage}><FirstIcon /></Button>
          <Button sx={{ minWidth: '0px' }} disabled={pageNumber === 1} onClick={prevPage}><PrevIcon /></Button>
          <Button sx={{ minWidth: '0px' }} disabled={pageNumber === lastPageNumber} onClick={nextPage}><NextIcon /></Button>
          <Button sx={{ minWidth: '0px' }} disabled={pageNumber === lastPageNumber} onClick={lastPage}><LastIcon /></Button>
          <Typography sx={{ float: 'left', marginTop: '6px' }}>{`${f('REPORT_PAGE_TABLE_PAGE')} ${pageNumber}/${lastPageNumber}`}</Typography>
        </Box>
      )}
      {enableBorderSelector && (
        <>
          <Typography sx={{ float: 'left', marginTop: '6px', marginLeft: '6px' }}>{f('REPORT_PAGE_TABLE_BORDERS')}</Typography>
          <Button disabled={disabled} color={borderType === BorderType.All ? 'secondary' : 'primary'} sx={{ minWidth: '0px' }} onClick={setBorders(BorderType.All)}><GridIcon /></Button>
          <Button disabled={disabled} color={borderType === BorderType.Rows ? 'secondary' : 'primary'} sx={{ minWidth: '0px' }} onClick={setBorders(BorderType.Rows)}><RowIcon /></Button>
          <Button disabled={disabled} color={borderType === BorderType.Columns ? 'secondary' : 'primary'} sx={{ minWidth: '0px' }} onClick={setBorders(BorderType.Columns)}><ColumnIcon /></Button>
          <Button disabled={disabled} color={borderType === BorderType.None ? 'secondary' : 'primary'} sx={{ minWidth: '0px' }} onClick={setBorders(BorderType.None)}>{f('REPORT_PAGE_TABLE_NONE')}</Button>
        </>
      )}
      {enableRowCounter && (
        <Box sx={{ float: 'right' }}>
          <Typography sx={{ float: 'left', marginTop: '6px', marginLeft: '6px' }}>{f('REPORT_PAGE_TABLE_SHOW_ROW_NO')}</Typography>
          <Switch disabled={disabled} checked={showRowCounter} color="primary" onChange={switchShowRowNumbers} />
        </Box>
      )}
      {true && (
        <Box sx={{ float: 'right' }}>
          <Typography sx={{ float: 'left', marginTop: '6px', marginLeft: '6px' }}>{f('REPORT_PAGE_TABLE_EXPORT')}</Typography>
          <Button disabled={disabled} sx={{ minWidth: '0px' }} onClick={exportXLSX}>xlsx</Button>
          <Button disabled={disabled} sx={{ minWidth: '0px' }} onClick={exportCSV}>csv</Button>
          <Button disabled={disabled} sx={{ minWidth: '0px' }} onClick={exportPDF}>pdf</Button>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(ReportTableToolbar)
