import { Box, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridRowParams, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { RoleEnum, UserListItem, UserAreaItem } from 'api/tillit.api-client'
import { DeleteDialog } from 'components/delete-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import { useFormatMessage } from 'localization'
import React, { FC } from 'react'
import useAuthorization from 'utilities/use-authorization'
import { AccountRemove } from 'mdi-material-ui'
import { makeStyles } from '@mui/styles'
import { Authorize } from 'components/authorization'
import format from 'utilities/format'
import { uniqueAndSortedUserAreas } from 'utilities/user-helper'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterUsers } from 'utilities/user-helper'
import { UsersPageContext } from './users-page-context'
import UserAreas from './components/user-areas-container'
import { SendEmailToUsersModalContext } from './components/send-email-to-users-modal/send-email-to-users-modal-context'
import SendEmailToUsersModal from './components/send-email-to-users-modal'

const useStyles = makeStyles({
  'users-container': {
    padding: '2vh 2vh 0 2vh'
  },
});


const UsersActiveView: FC = () => {
  const { hasRoleOnAnyArea } = useAuthorization()
  const { openSendEmailToUsersModal } = SendEmailToUsersModalContext.useActions()
  const { modalOpen } = SendEmailToUsersModalContext.useState()
  const classes = useStyles();

  const { deactivateUserDialogOpen, userToDeactivate, activeUsers, userHasAccessToUserAreaPrincipalDoesNot, selectedUserUserAreasOutsidePrincipalAccess } = UsersPageContext.useState()

  const { openDeactivateUserDialog, closeDeactivateUserDialog, deactivateUser, closeRemoveUserAreasDialog, deactivateUserAreasPrincipalHasAccessTo } = UsersPageContext.useActions()
  const f = useFormatMessage()
  const getFilteredUsers = filterUsers(f)
  const { open: openUserDialog } = UserDialogContext.useActions()


  const handleAddUserClick = React.useCallback(() => openUserDialog(), [openUserDialog])
  const [selectUsersToEmail, setSelectUsersToEmail] = React.useState<boolean>(false);
  const [selectedUserIds, setSelectedUserIds] = React.useState<[number] | undefined>(undefined);

  const handleEnableSelectedUsersToEmail = React.useCallback(() => {
    setSelectUsersToEmail(true)
  }, [setSelectUsersToEmail])

  const handleSendEmails = React.useCallback(() => {
    if (selectedUserIds) {
      openSendEmailToUsersModal(selectedUserIds)
    }
  }, [selectedUserIds])

  const handleCancelSelectedUsersToEmail = React.useCallback(() => {
    setSelectUsersToEmail(false)
    setSelectedUserIds(undefined)
  }, [])

  const handleSelectedUsersChange = React.useCallback((ids) => {
    setSelectedUserIds(ids)
  }, [])

  const handleConfirmDeactivateUserClick = React.useCallback(() => deactivateUser(userToDeactivate!.id), [userToDeactivate, deactivateUser])

  const handleConfirmRemoveUserAreasPrincipalDoesNotHaveAccessTo = React.useCallback(() => deactivateUserAreasPrincipalHasAccessTo(userToDeactivate!.id), [userToDeactivate, deactivateUserAreasPrincipalHasAccessTo])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams) => {
      if (selectUsersToEmail) {
        return
      }
      openUserDialog(params.row.id)
    },
    [openUserDialog, selectUsersToEmail]
  )

  const handleDeactivateRowClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, user: UserListItem) => {
      _?.stopPropagation()
      openDeactivateUserDialog(user)
    },
    [openDeactivateUserDialog]
  )

  const isPersonnel: boolean = hasRoleOnAnyArea(RoleEnum.Caretaker) && !hasRoleOnAnyArea(RoleEnum.Planner, RoleEnum.Admin)

  const userAreasLookup: any[] = []
  activeUsers?.forEach(user => {
    user.userAreas?.forEach(userArea => {
      if (!userAreasLookup[userArea.areaId]) {
        userAreasLookup[userArea.areaId] = userArea.areaName
      }
    })
  })

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredUsers, setFilteredUsers] = React.useState<UserListItem[] | null>(activeUsers)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredUsers(filterValue, activeUsers)
      setFilteredUsers(filtered)
    } else {
      setFilteredUsers(activeUsers)
    }
    // NOTE: We are missing filterValue and getFilteredUsers as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [activeUsers])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredUsers(searchValue, activeUsers)
      setFilteredUsers(filtered)
    },
    [setFilterValue, setFilteredUsers, activeUsers, getFilteredUsers]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredUsers(activeUsers)
    },
    [setFilterValue, setFilteredUsers, activeUsers]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div className={classes['users-container']}>

      <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Box sx={{
            display: 'flex',
            flex: '50%',
          }}>
            <ContainedButton type="button" color="secondary" onClick={handleAddUserClick}>
              {f('USERS_PAGE_VIEW_ADD_USER_BUTTON_TEXT')}
            </ContainedButton>
            {!selectUsersToEmail && (
              <ContainedButton type="button" color="secondary" onClick={handleEnableSelectedUsersToEmail}>
                {f('USERS_PAGE_VIEW_ADD_USERS_EMAIL_LIST_TEXT')}
              </ContainedButton>
            )}
            {selectUsersToEmail && (
              <>
                <ContainedButton type="button" color="secondary" onClick={handleSendEmails} disabled={(selectedUserIds || []).length === 0}>
                  {f('USERS_PAGE_VIEW_EMAIL_SELECTED_USERS_TEXT')}
                </ContainedButton>
                <ContainedButton type="button" color="secondary" onClick={handleCancelSelectedUsersToEmail}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </ContainedButton>
              </>
            )}
          </Box>
          <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
            <FilledSearchTextField
              label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
              handleChangeFilter={handleChangeFilter}
              handleClearFilter={handleClearFilter}
              searchValue={filterValue}
            />
          </div>
        </Box>
      </Authorize>
      <DataGrid
        disableColumnFilter
        sx={{
          minHeight: '70vh',
          maxHeight: '70vh',
          '.MuiDataGrid-cell': { borderBottom: '0px' }
        }}
        rows={filteredUsers as any}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        onRowClick={handleRowClick}
        checkboxSelection={selectUsersToEmail}
        selectionModel={selectedUserIds || []}
        onSelectionModelChange={handleSelectedUsersChange}
        localeText={muiDataGridLocale(f)()}

        columns={[
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_FIRSTNAME'),
            field: 'firstName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.firstName}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_LASTNAME'),
            field: 'lastName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.lastName}
                </span>
              )
            },          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_EMAIL'),
            field: 'email',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.email}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_EMAIL_SENT_DATE'),
            field: 'emailSentDate',
            valueGetter: (params: GridValueGetterParams) => {
              const emailSentDate = params.value as Date | undefined
              return emailSentDate ? format(emailSentDate, 'yyyy-MM-dd HH:mm') : ''
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_AREAS_TITLE'),
            field: 'userAreas',
            valueGetter: (params: GridValueGetterParams) => {
              const userAreas = params.value as UserAreaItem[]
              return !!userAreas && uniqueAndSortedUserAreas(userAreas).map(ua => ua).join(', ')
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span>
                  <UserAreas user={params.row} />
                </span>
              )
            },
          },

          {
            headerName: ' ',
            flex: 0.2,
            field: 'no_field_deactivate',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              if (isPersonnel) {
                return <span />
              }
              return (
                <span>
                  <Tooltip
                    data-matomo-mask
                    title={f('USERS_PAGE_VIEW_DEACTIVATE_BUTTON_TOOLTIP', {
                      fullName: params.row.fullName,
                    })}
                    aria-label={f('USERS_PAGE_VIEW_DEACTIVATE_BUTTON_TOOLTIP', {
                      fullName: params.row.fullName,
                    })}>
                    <IconButton onClick={e => handleDeactivateRowClick(e, params.row)} size="large">
                      <AccountRemove color={'secondary'} />
                    </IconButton>
                  </Tooltip>
                </span>
              )
            },
          },
        ]}

        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[25, 50, 100]}
        pagination

      />

      {deactivateUserDialogOpen && userToDeactivate !== null && (
        <DeleteDialog
          dialogTitle={f('USERS_PAGE_VIEW_DEACTIVATE_DIALOG_TITLE')}
          dialogContentText={f('USERS_PAGE_VIEW_DEACTIVATE_DIALOG_MESSAGE', {
            fullName: userToDeactivate.fullName,
          })}
          buttonSubmitText={f('USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_REMOVE')}
          buttonCancelText={f('USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_CANCEL')}
          deleteDialogOpen={true}
          onClose={closeDeactivateUserDialog}
          onSubmit={handleConfirmDeactivateUserClick}
        />
      )}
      {userHasAccessToUserAreaPrincipalDoesNot && selectedUserUserAreasOutsidePrincipalAccess !== null && userToDeactivate !== null && (
       <DeleteDialog
       dialogTitle={f('USERS_PAGE_VIEW_DEACTIVATE_ON_PRINCIPAL_AREAS_DIALOG_TITLE')}
       dialogContentText={f('USERS_PAGE_VIEW_DEACTIVATE_ON_PRINCIPAL_AREAS_DIALOG_MESSAGE', {
         fullName: userToDeactivate.fullName,
       })}
       buttonSubmitText={f('USERS_PAGE_VIEW_REMOVE_FROM_AREAS_DIALOG_ACTIONS_REMOVE')}
       buttonCancelText={f('USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_CANCEL')}
       deleteDialogOpen={true}
       onClose={closeRemoveUserAreasDialog}
       onSubmit={handleConfirmRemoveUserAreasPrincipalDoesNotHaveAccessTo}
     />
   )}
      {modalOpen && (
        <SendEmailToUsersModal />
      )}
    </div>
  )
}

export default UsersActiveView
