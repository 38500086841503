import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import { AreaDropListItem, CustomerListItem } from 'api/tillit.api-client'
import { DailyActivitiesContext } from '../../../daily-activities-context'

type DroplistCustomerForm = Readonly<{
  selectedCustomer: CustomerListItem | undefined
  mainArea: AreaDropListItem | undefined
}>

const useDroplistCustomerFormData = (areaList: AreaDropListItem[] | undefined) => {

  const { setSelectedAreaId } = DailyActivitiesContext.useActions()
  const { droplistCustomerOpen } = DailyActivitiesContext.useState()

  let mainArea: AreaDropListItem | undefined
  const areas = areaList?.filter(area => area.kioskMainArea)
  if (areas && areas.length !== 0 && droplistCustomerOpen) {
    mainArea = areas[0]
    setSelectedAreaId(mainArea.areaId)
  }
  let selectedCustomer: CustomerListItem | undefined

  const initialValues = React.useMemo<DroplistCustomerForm>(() => {
    return {
      selectedCustomer,
      mainArea,
    }
  }, [mainArea, selectedCustomer])

  const onSubmit: OnSubmitFunction<DroplistCustomerForm> = React.useCallback(async (values, { setSubmitting }) => {}, [])

  const name: Name<DroplistCustomerForm> = React.useMemo(
    () => ({
      selectedCustomer: 'selectedCustomer',
      mainArea: 'mainArea',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    name,
  }
}

export default useDroplistCustomerFormData
