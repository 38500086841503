import React from 'react'
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, useTheme } from '@mui/material'
import { IActivityListItem, ActivityListItem } from 'api/tillit.api-client'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'
import { DeleteDialog } from 'components/delete-dialog'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { Delete } from 'mdi-material-ui'
import styled, { css } from 'styled-components'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { combinedTitle as activityCombinedTitle, recurrenceText, filterActivities } from 'utilities/activity-helper'

type Props = Readonly<{}>

const DesiredTemporaryActivities: React.FC<Props> = () => {
  const f = useFormatMessage()
  const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false)
  const { temporaryActivities, isLoading } = CustomerModalContext.useState()
  const { selectedActivity } = ActivityCreateModalContext.useState()
  const getFilteredActivities = filterActivities(f)

  const { openModal, removeActivityAsync, fetchActivityAsync } = ActivityCreateModalContext.useActions()
  const theme = useTheme()
  const handleActivityClick = React.useCallback((activity: IActivityListItem) => openModal(activity?.id), [openModal])

  const openDialogDelete = React.useCallback(
    (activity: IActivityListItem) => {
      void fetchActivityAsync(activity.id)
      setDeleteDialog(true)
    },
    [fetchActivityAsync]
  )

  const handleClose = React.useCallback(() => {
    setDeleteDialog(false)
  }, [setDeleteDialog])

  const handleRemoveActivity = React.useCallback(() => {
    if (selectedActivity) {
      void removeActivityAsync(selectedActivity)
    }
    handleClose()
  }, [removeActivityAsync, selectedActivity, handleClose])

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredActivities, setFilteredActivities] = React.useState<ActivityListItem[] | null>(temporaryActivities)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredActivities(filterValue, temporaryActivities)
      setFilteredActivities(filtered)
    } else {
      setFilteredActivities(temporaryActivities)
    }
    // NOTE: We are missing filterValue and getFilteredActivities as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [temporaryActivities])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredActivities(searchValue, temporaryActivities)
      setFilteredActivities(filtered)
    },
    [setFilterValue, setFilteredActivities, temporaryActivities, getFilteredActivities]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredActivities(temporaryActivities)
    },
    [setFilterValue, setFilteredActivities, temporaryActivities]
  )

  return (
    <>
      <Box mx={5}>
        <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '25%' }}>
          <FilledSearchTextField
            label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
            handleChangeFilter={handleChangeFilter}
            handleClearFilter={handleClearFilter}
            searchValue={filterValue}
          />
        </div>
        <List>
          {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
          {(filteredActivities || []).map(activity => {
            return <StyledDesiredTemporaryActivity activity={activity} key={activity.id} onClick={handleActivityClick} onClickRemove={openDialogDelete} />
          })}
        </List>
      </Box>
      {selectedActivity ? (
        <DeleteDialog
          dialogTitle={f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_TITLE')}
          dialogContentText={
            selectedActivity.isPlanned
              ? f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION_IS_PLANNED', { title: activityCombinedTitle(selectedActivity) || '' })
              : f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION', { title: activityCombinedTitle(selectedActivity) || '' })
          }
          buttonSubmitText={f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_REMOVE_BUTTON')}
          buttonCancelText={f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_CANCEL_BUTTON')}
          deleteDialogOpen={deleteDialog}
          onClose={handleClose}
          onSubmit={handleRemoveActivity}
        />
      ) : (
        deleteDialog && <Spinner size={64} color={theme.palette.primary.main} />
      )}
    </>
  )
}

const DesiredTemporaryActivity: React.SFC<{
  activity: IActivityListItem
  className?: string
  onClick?: (activity: IActivityListItem) => void
  onClickRemove?: (activity: IActivityListItem) => void
}> = ({ activity, className, onClick, onClickRemove }) => {
  const f = useFormatMessage()
  const activityRecurrenceText = recurrenceText(f)

  const handleClick = React.useCallback(() => onClick && onClick(activity), [activity, onClick])
  const handleRemoveCustomerActivity = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      if (onClickRemove) {
        onClickRemove(activity)
      }
    },
    [activity, onClickRemove]
  )

  return (
    <Box onClick={handleClick} className={className} border={1} borderColor="grey.300" borderRadius="5px" my={1} py={1} width="auto">
      <ListItem>
        <Box display={'flex'}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: activity.color }} />
          </ListItemAvatar>
          <ListItemText data-matomo-mask primary={activityCombinedTitle(activity)} secondary={activityRecurrenceText(activity)} />
        </Box>
        <Box ml={'auto'} />
        <IconButton onClick={handleRemoveCustomerActivity} size="large">
          <Delete color={'error'} />
        </IconButton>
      </ListItem>
    </Box>
  )
}

const StyledDesiredTemporaryActivity = styled(DesiredTemporaryActivity)(
  ({ activity }) => css`
    &:hover {
      ${activity.color ? `background-color: ${activity.color}22;` : ''}
      cursor: pointer;
    }
  `
)

export default DesiredTemporaryActivities
