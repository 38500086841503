import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import {
  mapPlannedActivityRowPlanningViewItemToMetaData,
  Session,
  Track,
} from '../schedule-view'

export const reduceRoutes = (
  routes: IPlannedRoutesActivitiesPlanningViewItem[],
  currentCalendarDate: Date
) => {
  const allTracks: Track[] = []
  const allSessions: Session[] = []
  for (const route of routes) {
    const { plannedRoutes } = route
    if (plannedRoutes === undefined || plannedRoutes.length === 0) {
      continue
    }

    const routeId = route.id

    allTracks.push({
      id: routeId,
      sortOrder: !!route.sortingIndex ? route.sortingIndex : 0,
      meta: route,
    })

    plannedRoutes.forEach(plannedRoute => {
      const { plannedActivityRows = [] } = plannedRoute

      const sessions = plannedActivityRows.map(plannedActivityRow => {
        const { startTime, endTime } = plannedActivityRow
        const startDate = new Date(startTime)
        const endDate = new Date(endTime)

        const lengthInMinutes = Math.floor((+endDate - +startDate) / 60000)

        let startsBeforeCalendarDate = false
        if ( startDate < currentCalendarDate) {
          startsBeforeCalendarDate = true
        }
        const meta = mapPlannedActivityRowPlanningViewItemToMetaData(
          plannedActivityRow, 
          startsBeforeCalendarDate
        )

        const session: Session = {
          meta,
          startDate,
          lengthInMinutes,
          id: plannedActivityRow.id,
          trackId: route.id,
        }
        return session
      })

      allSessions.push(...sessions)
    })
  }

  return { tracks: allTracks, sessions: allSessions }
}
