import React from 'react'
import httpMiddleware from 'utilities/http-middleware'
import { API_URL } from '../config/variables'
import { AreaClient, AreaDropListItem, AreaForUserListItem, AreaItem } from './tillit.api-client'

export class AreaApi {
  private readonly abortController = new AbortController()
  private readonly client: AreaClient

  constructor() {
    this.client = new AreaClient(API_URL, httpMiddleware({ signal: this.abortController.signal, headers:{'key-name': 'area'} }))
  }

  public abort = () => this.abortController.abort()

  public getAllAreasForUser = (userId: number): Promise<AreaForUserListItem[]> =>
    this.client
      .getAllAreas(userId)
      .then(areaListItem => (areaListItem === null ? Promise.reject(`No area items received from backend.`) : Promise.resolve(areaListItem)))

  public get = (areaId: number): Promise<AreaItem> =>
    this.client
      .getAreaById(areaId)
      .then(areaItem => (areaItem === null ? Promise.reject(`No area item received from backend for area id ${areaId}.`) : Promise.resolve(areaItem)))

  public listAreasOnKiosk = (deviceGuid: string): Promise<AreaDropListItem[]> =>
    this.client.listAreasOnKiosk(deviceGuid).then(areas => (areas === null ? Promise.reject(`No areas received from backend.`) : Promise.resolve(areas)))

  public updateArea = (area: AreaItem): Promise<AreaItem> =>
    this.client
      .update(area)
      .then(areaItem => (areaItem === null ? Promise.reject(`No area item received from backend for area id ${area.id}.`) : Promise.resolve(areaItem)))
}

const useAreaApi = () => React.useMemo(() => new AreaApi(), [])

export default useAreaApi
