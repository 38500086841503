import { useFormatMessage } from 'localization'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, FC } from 'react'
import PageRoute from 'config/page-routes'
import { getVisibleAreaData } from 'utilities/area-helper'
import { Link as RouterLink } from "react-router-dom"
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { hasSupportTokenData, revertSupportTokenData, getTokenData } from 'api/token-data'
import { useNavigate } from "react-router-dom"
import authenticationContext from 'components/authentication/authentication-context'
import format from 'utilities/format'
import NotificationContext from './notification-context'

const NotificationContainer: FC = ({ children }) => {
  const f = useFormatMessage()
  const { addAlerts, removeAlertByType } = NotificationContext.useActions()
  const navigate = useNavigate()
  const { reloadUser } = authenticationContext.useActions()
  const revertTimer = React.useRef<number | undefined>()

  const revertImpersonation = React.useCallback(() => {
    revertSupportTokenData()
    removeAlertByType('support-impersonating-alert')
    reloadUser().then(() => {
      navigate(PageRoute.Support.path)
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(getVisibleAreaData())) {
      addAlerts({
        type: 'visible-area-alert',
        severity: 'warning',
        renderBody: () => (
          <Typography variant="subtitle2">
            {f('NOTIFICATION_SELECTED_VISIBLE_AREA_MESSAGE')}
            <Link component={RouterLink} to={PageRoute.Profile.path}>{f('NOTIFICATION_SELECTED_VISIBLE_AREA_ACTION')}</Link>
          </Typography>
        )
      })
    }

    if (hasSupportTokenData()) {
      const userToken = getTokenData()
      const expiresOn = userToken?.tokenExpiresOn as Date
      const expireOnLocale = new Date(expiresOn.toLocaleString())
      const diffMs = expireOnLocale.getTime() - (new Date()).getTime()
      if (revertTimer.current) {
        window.clearTimeout(revertTimer.current)
      }
      revertTimer.current = window.setTimeout(revertImpersonation, diffMs)

      addAlerts({
        type: 'support-impersonating-alert',
        severity: 'info',
        renderBody: () => (
          <Typography variant="subtitle2">
            {f('SUPPORT_IMPERSONATING_MESSAGE')}<br />
            {f('SUPPORT_IMPERSONATING_MESSAGE_VALID_UNTIL', {
              validUntil: format(expireOnLocale, "HH:mm"),
              numMinutes: Math.round(diffMs / 60000)
            })}
            <Button onClick={revertImpersonation}>{f('SUPPORT_IMPERSONATING_MESSAGE_REVERT')}</Button>
          </Typography>
        )
      })
    }

    return () => {
      window.clearTimeout(revertTimer.current)
    }

  }, [])

  return <>
    {children}
  </>

}

export default NotificationContainer
