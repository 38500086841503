import React from 'react'
import * as yup from 'yup'

import { TemplateActivityCategoryCreateItem, ITemplateActivityCategoryItem, TemplateActivityCategoryUpdateItem, LegislationItem } from 'api/tillit.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { TemplateActivityCategoryModalContext } from '../template-activity-category-modal-context'

type TemplateActivityCategoryForm = Readonly<{
  legislation: LegislationItem | undefined
  legislationId: number | undefined
  title: string
  ownerOrganizationId: number
}>

const createTemplateActivityCategory = (values: TemplateActivityCategoryForm): TemplateActivityCategoryCreateItem => {
  const { legislation, title, ownerOrganizationId } = values

  return new TemplateActivityCategoryCreateItem({
    title,
    legislationId: legislation ? legislation.id : undefined,
    ownerOrganizationId,
  })
}

const mapTemplateActivityCategoryFormToTemplateActivityCategoryItem = (
  initialItem: ITemplateActivityCategoryItem,
  item: TemplateActivityCategoryForm
): TemplateActivityCategoryUpdateItem => {
  return new TemplateActivityCategoryUpdateItem({
    id: initialItem.id,
    legislationId: item.legislation ? item.legislation.id : undefined,
    title: item.title,
    ownerOrganizationId: initialItem.ownerOrganizationId,
  })
}

const mapTemplateActivityCategoryItemToTemplateActivityCategoryForm = (item: ITemplateActivityCategoryItem): TemplateActivityCategoryForm => {
  const legislation: LegislationItem = new LegislationItem()
  if (item.legislationId) {
    legislation.id = item.legislationId
    legislation.title = item.legislationTitle
  }

  const createItem: TemplateActivityCategoryForm = {
    legislation,
    legislationId: item.legislationId,
    ownerOrganizationId: item.ownerOrganizationId, // ? item.ownerOrganizationId : -1,
    title: item.title ? item.title : '',
  }
  return createItem
}

const useTemplateActivityCategoryFormData = (initialTemplateActivityCategory?: ITemplateActivityCategoryItem) => {
  const f = useFormatMessage()

  const { createTemplateActivityCategoryAsync, updateTemplateActivityCategoryAsync } = TemplateActivityCategoryModalContext.useActions()
  const { ownerOrganizationId } = TemplateActivityCategoryModalContext.useState()
  const category = initialTemplateActivityCategory

  const initialValues = React.useMemo<TemplateActivityCategoryForm>(
    () =>
      !!category
        ? mapTemplateActivityCategoryItemToTemplateActivityCategoryForm(category)
        : {
            legislationId: undefined,
            legislation: undefined,
            ownerOrganizationId: ownerOrganizationId ? ownerOrganizationId : -1,
            title: '',
          },
    [category, ownerOrganizationId]
  )

  const onSubmit: OnSubmitFunction<TemplateActivityCategoryForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const templateActivityCategory = createTemplateActivityCategory({
        ...values,
      })
      if (!initialTemplateActivityCategory) {
        await createTemplateActivityCategoryAsync(templateActivityCategory).finally(() => setSubmitting(false))
      } else {
        const mappedTemplateActivityCategory = mapTemplateActivityCategoryFormToTemplateActivityCategoryItem(initialTemplateActivityCategory, values)
        await updateTemplateActivityCategoryAsync(mappedTemplateActivityCategory).finally(() => setSubmitting(false))
      }
    },
    [createTemplateActivityCategoryAsync, initialTemplateActivityCategory, updateTemplateActivityCategoryAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),
    })
  }, [f])

  const name: Name<TemplateActivityCategoryForm> = React.useMemo(
    () => ({
      legislation: 'legislation',
      legislationId: 'legislationId',
      ownerOrganizationId: 'ownerOrganizationId',
      title: 'title',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useTemplateActivityCategoryFormData
