// import { PropTypes } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC } from 'react'

type Color = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'partial'
type ButtonProps = React.ComponentProps<typeof Button>

type Props = Omit<ButtonProps, 'variant' | 'color' | 'showSpinner'> & {
  color?: Color
  forwardRef?: ButtonProps['ref']
  showProgress?: boolean
  containerStyle?: React.CSSProperties;
}

const ContainedButtonView: FC<Props> = ({ color, forwardRef, showProgress, containerStyle = {}, ...rest }) => {
  return (
    <Box sx={{ m: 1, position: 'relative', ...containerStyle }} >
      <Button
        variant="contained"
        disabled={showProgress}
        color={color === 'error' || color === 'success' || color === 'partial' ? 'primary' : color}
        ref={forwardRef}
        {...rest}
      />
      {showProgress && (
        <CircularProgress
          size={24}
          sx={{
            color: 'primary',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  )

}

export default ContainedButtonView
