import React from 'react'
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import ModalSection from 'components/modal-section'
import { useFormatMessage } from 'localization'
import useUserForm from 'components/user-dialog/utilities/use-user-form'
import { RoleEnum } from 'api/tillit.api-client'
import { FormikProps } from 'formik'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import AreaRolesTable from 'components/user-dialog/components/area-roles-table'


const RoleSection: React.FC<FormikProps<UserFormModelWithModifiedAreas>> = ({
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  ...props
}) => {
  const f = useFormatMessage()
  const { addArea, removeArea, changeRole } = UserDialogContext.useActions()
  const { name } = useUserForm()

  const { availableAreas, listedAreas, checkedAreaRoles, user, dialogActionType, isPersonnel, calledFromOtherPersonell } = UserDialogContext.useState()

  const reducedAreas = availableAreas?.reduce<Readonly<{
    id: number;
    name: string;
    organizationParentsName: string;
  }>[]>((prev, area) => {
    const { id } = area
    if (
      !prev.some(p => p.id === id)
    ) {
      return [...prev, area]
    }
    return prev
  }, [])
    .sort((a1, a2) => (a1.name || '').localeCompare(a2.name || ''))


  const handleAddAreaChange = React.useCallback(
    async (event: SelectChangeEvent) => {
      if (typeof event.target.value === 'number') {
        const state = await addArea(event.target.value)
        setFieldValue('listedAreas', state.listedAreas)
      }
    },
    [addArea, setFieldValue]
  )

  const handleRemoveAreaClick = React.useCallback(
    () => (areaId: number) => {
      removeArea(areaId)
      setFieldTouched('areaRoles', true)
    },
    [removeArea, setFieldTouched]
  )

  const handleRoleChange = React.useCallback(
    () => async (areaId: number, role: RoleEnum, checked: boolean) => {
      await changeRole(areaId, role, checked)
    }, [changeRole]
  )

  React.useEffect(() => {
    setFieldValue('areaRoles', checkedAreaRoles)
    setFieldTouched('areaRoles', true)
  }, [checkedAreaRoles])

  return (
    <Box width="90%" sx={{ paddingTop: '20px' }}>
      {!isPersonnel && (dialogActionType === 'add' || (dialogActionType === 'edit' && (user?.active || calledFromOtherPersonell))) && (
        <ModalSection title={f('USER_DIALOG_VIEW_ROLESECTION_INFOTEXT_TITLE')} subtitle={f('USER_DIALOG_VIEW_ROLESECTION_INFOTEXT_SUBTITLE')} mb={2}>
          <FormControl error={!!errors.listedAreas && !!touched.listedAreas && touched.listedAreas.length === 0}>
            <InputLabel htmlFor={name.listedAreas}>{f('USER_DIALOG_VIEW_ROLESECTION_FORM_ADDAREA_SELECT_LABEL')}</InputLabel>
            <Select
              labelId={name.listedAreas}
              label={f('USER_DIALOG_VIEW_ROLESECTION_FORM_ADDAREA_SELECT_LABEL')}
              name={name.listedAreas}
              value=""
              onChange={handleAddAreaChange}>
              {reducedAreas.map(area => (
                <MenuItem key={`availableArea${area.id}`} value={area.id}>
                  {area.organizationParentsName ? `${area.name} (${area.organizationParentsName})` : area.name}
                </MenuItem>
              ))}
              {reducedAreas.length === 0 && (
                <MenuItem key={`no area`} selected disabled>
                  {f('USER_DIALOG_VIEW_ROLESECTION_FORM_ADDAREA_NO_AREAS_MENU_ITEM')}
                </MenuItem>
              )}
            </Select>
            <FormHelperText>{!!touched.listedAreas && touched.listedAreas.length === 0 && errors.listedAreas}</FormHelperText>
          </FormControl>
        </ModalSection>
      )}
      {listedAreas.length > 0 && (
        <Box mb={4}>
          <FormControl error={!!errors.areaRoles && !!touched.areaRoles} style={{ display: 'block' }}>
            <AreaRolesTable
              name={name.areaRoles}
              areas={listedAreas}
              checkedAreaRoles={checkedAreaRoles}
              onRoleChange={handleRoleChange()}
              onRemoveAreaClick={handleRemoveAreaClick()}
              touchedError={touched.areaRoles}
              forceDisable={dialogActionType === 'edit' && !(user?.active || calledFromOtherPersonell)}
            />
            <FormHelperText>{!!errors.areaRoles && !!touched.areaRoles && errors.areaRoles}</FormHelperText>
          </FormControl>
        </Box>
      )}
    </Box>
  )
}

export default RoleSection
