import React, { useState, useCallback, ReactNode, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import usePromiseApi from 'utilities/use-promise'
import { useFormatMessage } from 'localization'
import { createContext } from 'utilities/create-context'
import { AlertProps } from '@mui/material/Alert'
import { APP_VERSION } from 'config/variables'
import useTillitVersionApi from 'api/version-tillit-api'
import { useLocation } from 'react-router-dom'
import Link from '@mui/material/Link'
import PageRoute from 'config/page-routes'
import useSupportImpersonationRequestsApi from 'api/support-userimpersonationrequests-api'


type Props = Readonly<
  {
    type?: string
    title?: string
    renderBody?: () => ReactNode
  } & AlertProps
>

const NotificationContext = () => {
  const f = useFormatMessage()
  const location = useLocation()
  const { fetchAsync } = usePromiseApi()
  const { abandonUserImpersonationRequest } = useSupportImpersonationRequestsApi()
  const [alerts, setAlerts] = useState<Props[]>([])

  const addAlerts = useCallback((newAlerts: Props) => {
    setAlerts(curr => {

      if (!curr.some(alert => alert.type === newAlerts.type)) {
        return [...curr, newAlerts]
      }

      //replace existing alert
      const otherAlerts = curr.filter(alert => alert.type !== newAlerts.type)
      return [...otherAlerts, newAlerts]
    })
  }, [])

  const { getVersionTillitApi } = useTillitVersionApi()

  const removeAlert = useCallback((alertIndex: number) => (event: React.SyntheticEvent) => setAlerts(curr => [...curr.filter((alert, index) => index !== alertIndex)]), [])

  const removeAlertByType = useCallback((alertType: string) => setAlerts(curr => [...curr.filter((alert, index) => alert.type !== alertType)]), [])

  const resetAlerts = useCallback(() => {
    setAlerts([])
  }, [])

  const handleAppVersionCheck = React.useCallback(() => {
    fetchAsync(Promise.resolve(getVersionTillitApi())).then((res) => {

      if (parseInt(res) > parseInt(APP_VERSION ? APP_VERSION : '')) {

        if (location.pathname === PageRoute.DailyActivities.path && !window.timeoutId) {
          let timeoutId: number


          const locationReload = () => {
            window.location.reload()
          }
          const removeTimeout = () => {
            window.clearTimeout(timeoutId)
          }

          timeoutId = window.setTimeout(locationReload, 1000 * 60 * 10) // If there is an update and there have been no userinput on a kiosk for 10 minutes it reloads the page to get the new update
          window.timeoutId = timeoutId
          window.addEventListener("click", () => {
            removeTimeout()
            timeoutId = window.setTimeout(locationReload, 1000 * 60 * 10) // If there is an update and the kiosk is being used it resets the timer to 10 minutes on every user input
          })
        }

        addAlerts({ // add alert
          type: 'app-version-alert',
          severity: 'warning',
          renderBody: () => (
            <Typography variant="subtitle2">
              {f('NOTIFICATION_NEW_VERSION_AVAILABLE')}
              <Link
                component="button"
                onClick={() => window.location.reload()}
              >
                {f('NOTIFICATION_NEW_VERSION_UPDATE_NOW')}
              </Link>
            </Typography>
          )
        })
      }
    })
  }, [])

  useEffect(() => {
    handleAppVersionCheck()
    const intervalId = setInterval(() => {
      handleAppVersionCheck()
    }, 1000 * 60 * 15) // checks for new update every 15 min

    return () => {
      clearInterval(intervalId)
    };

  }, [])

  return {
    state: {
      alerts
    },
    actions: {
      addAlerts,
      removeAlert,
      resetAlerts,
      removeAlertByType,
    },
  }
}

export default createContext(NotificationContext)

