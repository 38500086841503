import { IUserListItem } from 'api/tillit.api-client'
import React from 'react'
import { InfoSignedStyle } from './info-signed-style'
import { InfoSignedView } from './info-signed-view'

const InfoSignedContainer: React.SFC<Omit<
  React.ComponentProps<typeof InfoSignedView>,
  'signedUser'
> & {
  signedUser?: IUserListItem
}> = ({ signedUser, ...rest }) => {
  if (!signedUser) {
    return null
  }
  return <InfoSignedStyle {...rest} signedUser={signedUser} />
}

export default React.memo(InfoSignedContainer)
