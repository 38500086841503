import { PropTypes } from '@mui/material'
import TextField, { FilledTextFieldProps } from '@mui/material/TextField'
import { Field } from 'formik'
import { TextField as FormikTextField } from 'formik-mui'
import React from 'react'

type Props = Omit<FilledTextFieldProps, 'variant' | 'color'> & {
  color?: PropTypes.Color
}

const FilledTextFieldView: React.FC<Props> = ({ name, ...props }) => {
  if (name) {
    return <Field variant={'filled'} name={name} {...props} component={FormikTextField} />
  }
  return <TextField variant={'filled'} {...props} color={'primary'} />
}

export default FilledTextFieldView
