import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { ReportSelectionItem } from 'api/tenilo.reporting.api-client'
import { Button } from '@mui/material'
import Colors from 'styles/colors'
import { FilterPlusOutline as AddFilter } from 'mdi-material-ui'
import { ArrayHelpers } from 'formik'

const ReportsPageSelectionOptionalView: FC<{ selection: ReportSelectionItem, helper: ArrayHelpers}> = ({ selection, helper }) => {
  const f = useFormatMessage()

  const addFilter = React.useCallback(() =>{
    helper.push(selection)
  },[])

  return (
    <Button sx={{backgroundColor: Colors.AthensGray, height: '55px', marginLeft: '10px'}} onClick={addFilter}><AddFilter/>{f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${selection.selectionId}`)}</Button>
  )
}

export default React.memo(ReportsPageSelectionOptionalView)
