/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import isEqual from 'lodash/isEqual'
import styled from 'styled-components'
import ErrorBoundary from 'components/error-boundary'
import { CustomDragLayer } from './components/custom-drag-layer'
import { Session } from './components/session'
import { TimeSubCell, useDropSessionCell } from './components/time-cell/time-cell-style'
import { Timeline } from './components/timeline'
import { InnerContext } from './contexts/inner-context'
import Constants from './planning-grid-constants'
import { ITracksProps } from './planning-grid-context'
import { Callbacks, SessionProps, TrackProps } from './planning-grid-types'
import { arrangePlanning } from './utilities/arrange-planning'

export type PlanningGridProps<T extends TrackProps, S extends SessionProps> = Readonly<{
  headerComponent?: React.ComponentClass<{
    internal: Pick<Readonly<{
      id: number;
      sortOrder: number;
      meta: any;
    }>, "id" | "sortOrder">;
    meta: any;
  }, any> | React.FunctionComponent<{
    internal: Pick<Readonly<{
      id: number;
      sortOrder: number;
      meta: any;
    }>, "id" | "sortOrder">;
    meta: any;
  }> | undefined
  timeComponent?: React.ComponentType<{ time: Date }>
  sessionComponent?: React.ComponentClass<{
    internal: Pick<Readonly<{
      startDate: Date;
      lengthInMinutes: number;
      id: number;
      trackId: number;
      meta: any;
      soughtDate?: Date | undefined;
    }>, "startDate" | "lengthInMinutes" | "id" | "trackId" | "soughtDate">;
    meta: any;
  }, any> | React.FunctionComponent<{
    internal: Pick<Readonly<{
      startDate: Date;
      lengthInMinutes: number;
      id: number;
      trackId: number;
      meta: any;
      soughtDate?: Date | undefined;
    }>, "startDate" | "lengthInMinutes" | "id" | "trackId" | "soughtDate">;
    meta: any;
  }> | undefined
  renderHeader?: ((props: {
    internal: Pick<Readonly<{
      id: number;
      sortOrder: number;
      meta: any;
    }>, "id" | "sortOrder">;
    meta: any;
  }) => React.ReactNode) | undefined;
  renderSession?: ((props: {
    internal: Pick<Readonly<{
      startDate: Date;
      lengthInMinutes: number;
      id: number;
      trackId: number;
      meta: any;
      soughtDate?: Date | undefined;
    }>, "startDate" | "lengthInMinutes" | "id" | "trackId" | "soughtDate">;
    meta: any;
  }) => React.ReactNode) | undefined;
  renderTime?: (props: { time: Date }) => React.ReactNode
  style?: React.CSSProperties
}>

type PlanningGridContainerProps<T extends TrackProps, S extends SessionProps> = Readonly<{
  calendarStartDate: Date
  calendarEndDate: Date
  tracks: T[]
  sessions: S[]
  preventHorizontalDrag?: boolean
  snapToGrid?: boolean
  timeScale?: number
  snapInMinutes?: number
  stepInMinutes?: number
  callbacks?: Callbacks<T, S>
  headerComponent?: PlanningGridProps<T, S>['headerComponent']
  timeComponent?: PlanningGridProps<T, S>['timeComponent']
  sessionComponent?: PlanningGridProps<T, S>['sessionComponent']
  renderHeader?: PlanningGridProps<T, S>['renderHeader']
  renderSession?: PlanningGridProps<T, S>['renderSession']
  renderTime?: PlanningGridProps<T, S>['renderTime']
  style?: React.CSSProperties
}>

export function PlanningGridContainer<T extends TrackProps, S extends SessionProps>({
  calendarStartDate,
  calendarEndDate,
  tracks,
  sessions,
  preventHorizontalDrag = false,
  snapToGrid,
  timeScale,
  stepInMinutes,
  snapInMinutes,
  callbacks = {},
  headerComponent,
  timeComponent,
  sessionComponent,
  renderHeader,
  renderSession,
  renderTime,
  style,
}: PlanningGridContainerProps<T, S>) {
  return (
    <InnerContext.Provider
      calendarStartDate={calendarStartDate}
      calendarEndDate={calendarEndDate}
      tracks={tracks}
      sessions={sessions}
      snapToGrid={snapToGrid}
      stepInMinutes={stepInMinutes}
      snapInMinutes={snapInMinutes}
      timeScale={timeScale}
      preventHorizontalDrag={preventHorizontalDrag}
      callbacks={callbacks as Callbacks<TrackProps, SessionProps>}>
      <ErrorBoundary>
        <Container
          headerComponent={headerComponent}
          timeComponent={timeComponent}
          sessionComponent={sessionComponent}
          renderHeader={renderHeader}
          renderSession={renderSession}
          renderTime={renderTime}
          style={style}
        />
      </ErrorBoundary>
    </InnerContext.Provider>
  )
}

export const MemoizedPlanningGridContainer = React.memo(PlanningGridContainer)

const div001: React.CSSProperties = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 'calc(100% - 10px)',
}

const div002: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'row'
}

const div003: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  flex: 1
}

const div004: React.CSSProperties = {
  height: `${Constants.TOP_HEADER_ROW_HEIGHT}px`,
  display: 'flex',
  flexDirection: 'row'
}

const div005: React.CSSProperties = {
  height: `${Constants.TOP_HEADER_ROW_HEIGHT}px`,
  minHeight: `${Constants.TOP_HEADER_ROW_HEIGHT}px`,
  width: `${Constants.LEFT_TIME_COLUMN_WIDTH}px`,
  minWidth: `${Constants.LEFT_TIME_COLUMN_WIDTH}px`,
  backgroundColor: 'white',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  boxSizing: 'border-box'
}

const div007: React.CSSProperties = {
  paddingRight: `${Constants.SCROLLBAR_WIDTH}px`
}

const div008: React.CSSProperties = {
  position: 'relative'
}

const div009: React.CSSProperties = {
  position: 'absolute',
  left: `${Constants.LEFT_TIME_COLUMN_WIDTH}px`,
  right: '0px',
  top: '0px',
  bottom: '0px',
  display: 'flex',
  pointerEvents: 'none'
}

type ContainerProps<T extends TrackProps, S extends SessionProps> = Readonly<{
  headerComponent?: PlanningGridProps<T, S>['headerComponent']
  timeComponent?: PlanningGridProps<T, S>['timeComponent']
  sessionComponent?: PlanningGridProps<T, S>['sessionComponent']
  renderHeader?: PlanningGridProps<T, S>['renderHeader']
  renderSession?: PlanningGridProps<T, S>['renderSession']
  renderTime?: PlanningGridProps<T, S>['renderTime']
  style?: React.CSSProperties
}>

const TrackVertical: React.FC = props => (
  <div
    {...props}
    style={{
      height: '100%',
      position: 'absolute',
      width: '10px',
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px'
    }}
  />
)
const ThumbVertical: React.FC = props => (
  <div
    {...props}
    style={{
      backgroundColor: 'rgba(176, 176, 176, 0.6)',
      height: '100%',
      position: 'relative',
      width: '10px'
    }}
  />
)

const Container = React.memo(
  <T extends TrackProps, S extends SessionProps>({
    headerComponent: HeaderComponent,
    timeComponent: TimeComponent,
    sessionComponent: SessionComponent,
    renderHeader,
    renderSession,
    renderTime,
    style,
  }: ContainerProps<T, S>) => {
    const { containerRef, timeArray, tracks, sessions, stepInMinutes } = InnerContext.useState()
    const { mapMinutesToPixels } = InnerContext.useActions()
    const trackHeaders = React.useMemo(() => tracks.slice(0).sort((t1, t2) => t1.sortOrder - t2.sortOrder), [tracks])
    const trackHeaderIds = React.useMemo(() => trackHeaders.map(t => t.id), [trackHeaders])
    const arrangedSessions = React.useMemo(() => {
      const mapedTrackHeaders: ITracksProps[] = trackHeaders.map(t => ({
        trackId: t.id,
        sortOrder: t.sortOrder,
        columnCount: 0,
        sessions: [],
      }))

      for (const session of sessions) {
        const track = mapedTrackHeaders.find(t => t.trackId === session.trackId)
        if (track !== undefined) {
          track.sessions.push(session)
        }
      }

      return arrangePlanning(mapedTrackHeaders)
    }, [trackHeaders, sessions])

    if (TimeComponent !== undefined && renderTime !== undefined) {
      throw Error('Can not use both render props and component for rendering time')
    }
    const stepInPixels = mapMinutesToPixels(stepInMinutes)
    const maxColumnCount = arrangedSessions.reduce((prev, { columnCount }) => (columnCount > prev ? columnCount : prev), 0)

    const setScrollbarsRef = React.useCallback(
      (instance: any) => {
        if (instance !== null) {
          const htmlDivElementRef = containerRef as any
          htmlDivElementRef.current = instance.view
        }
      },
      [containerRef]
    )
    return (
      <div style={div001}>
        <CustomDragLayer renderSession={renderSession as PlanningGridProps<TrackProps, SessionProps>['renderSession']} />
        <div
          style={{ ...style, ...div002 }}>
          <div style={div003}>
            <div style={div004}>
              <div style={div005} />
              {trackHeaders.map((trackHeader, trackHeaderIndex) => {
                if (renderHeader !== undefined && HeaderComponent !== undefined) {
                  throw Error(`Can't use both render props and component at the same time, choose one.`)
                }
                const { meta, ...internal } = trackHeader
                return (
                  <div
                    key={trackHeader.id}
                    style={{
                      flex: 1,
                      minWidth: `${maxColumnCount * Constants.DEFAULT_MIN_WIDTH_SESSION}px`
                    }}>
                    {HeaderComponent && <HeaderComponent internal={internal as any} meta={meta} />}
                    {renderHeader && renderHeader({ internal: internal as any, meta })}
                  </div>
                )
              })}
              <div style={div007} />
            </div>
            <Scrollbars ref={setScrollbarsRef} renderTrackVertical={TrackVertical} renderThumbVertical={ThumbVertical}>
              <div
                style={div008}>
                <Timeline centerScroll />
                <div style={div009}>
                  <MemoizedMapTrackContainers
                    arrangedSessions={arrangedSessions}
                    renderSession={renderSession as PlanningGridProps<TrackProps, SessionProps>['renderSession']}
                    sessionComponent={SessionComponent as PlanningGridProps<TrackProps, SessionProps>['sessionComponent']}
                  />
                </div>
                <Table
                  timeComponent={TimeComponent}
                  renderTime={renderTime}
                  timeArray={timeArray}
                  timeHeight={stepInPixels}
                  trackHeaderIds={trackHeaderIds}
                  maxColumnCount={maxColumnCount}
                />
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    )
  })

Container.displayName = 'Container'

type MapTrackContainersProps<T extends TrackProps, S extends SessionProps> = Readonly<{
  arrangedSessions: ITracksProps[]
  renderSession?: PlanningGridProps<T, S>['renderSession']
  sessionComponent?: PlanningGridProps<T, S>['sessionComponent']
}>

const MemoizedMapTrackContainers = React.memo(function <T extends TrackProps, S extends SessionProps>({
  arrangedSessions,
  renderSession,
  sessionComponent
}: MapTrackContainersProps<T, S>) {
  return (
    <>
      {
        arrangedSessions.map((track, trackIndex) => {
          const { sessions, columnCount } = track
          return (
            <TrackContainer trackId={track.trackId} key={track.trackId} index={trackIndex} columnCount={columnCount}>
              <MemoizedMapSessions
                sessions={sessions}
                renderSession={renderSession as PlanningGridProps<TrackProps, SessionProps>['renderSession']}
                sessionComponent={sessionComponent as PlanningGridProps<TrackProps, SessionProps>['sessionComponent']} />
            </TrackContainer>
          )
        })
      }
    </>
  )
}, (prev, next) => isEqual(prev, next))

type MapSessionsProps<T extends TrackProps, S extends SessionProps> = Readonly<{
  sessions: S[]
  renderSession?: PlanningGridProps<T, S>['renderSession']
  sessionComponent?: PlanningGridProps<T, S>['sessionComponent']
}>
MemoizedMapTrackContainers.displayName = 'MemoizedMapTrackContainers'
const MemoizedMapSessions = React.memo(function <T extends TrackProps, S extends SessionProps>({
  sessions,
  renderSession,
  sessionComponent: SessionComponent
}: MapSessionsProps<T, S>) {
  return (
    <>
      {sessions.map(session => {
        const { left, right, id } = session as any
        const { meta, ...internal } = session as SessionProps<S>
        return (
          <Session key={id} startProportion={left} widthProportion={right - left} session={session}>
            {props => {
              if (renderSession) {
                return renderSession({
                  internal: internal as any,
                  meta,
                  ...props,
                })
              }
              if (SessionComponent) {
                return <SessionComponent internal={internal as any} meta={meta} {...props} />
              }
              return null
            }}
          </Session>
        )
      })}
    </>
  )
}, (prev, next) => isEqual(prev, next))

MemoizedMapSessions.displayName = 'MemoizedMapSessions'
type TrackContainerProps = Readonly<{
  trackId: number
  index: number
  columnCount: number
  className?: string
}>

const StyledTrackContainer = styled.div<{ isDragging?: boolean }>`
  position:relative;
  pointer-events: ${(props) => props.isDragging ? 'auto' : 'none'};
  flex:1;
  `
const TrackContainer: React.FC<TrackContainerProps> = React.memo(({ trackId, index, columnCount, children, className }) => {

  const [drop, { isDragging }] = useDropSessionCell({
    trackId,
    accept: ['SESSION_UPDATE', 'SESSION_ADD'],
  })

  return (
    <StyledTrackContainer ref={drop} isDragging={isDragging}>
      {children}
    </StyledTrackContainer>
  )
})
TrackContainer.displayName = 'TrackContainer'


interface ITableProps {
  timeComponent?: PlanningGridProps<any, any>['timeComponent']
  renderTime?: PlanningGridProps<any, any>['renderTime']
  timeArray: Date[]
  trackHeaderIds: number[]
  maxColumnCount: number
  timeHeight: number
}

const Table: React.FC<ITableProps> = React.memo(props => {
  const { timeArray, timeHeight, trackHeaderIds, maxColumnCount, timeComponent: TimeComponent, renderTime } = props

  const td001 = {
    minWidth: `${maxColumnCount * Constants.DEFAULT_MIN_WIDTH_SESSION}px`,
    width: `${100 / trackHeaderIds.length}%`,
    padding: 0,
    margin: 0
  }

  const div001_table: React.CSSProperties = {
    height: `${timeHeight}px`,
    width: `${Constants.LEFT_TIME_COLUMN_WIDTH}px`
  }

  return (
    <table
      style={{
        borderCollapse: 'collapse',
        borderSpacing: '0px'
      }}>
      <tbody>
        {timeArray.map((time, timeIndex) => (
          <tr key={timeIndex}>
            <td style={{
              padding: 0,
              margin: 0,
            }}>
              <div
                style={div001_table}>
                {TimeComponent && <TimeComponent time={time} />}
                {renderTime && renderTime({ time })}
              </div>
            </td>
            {trackHeaderIds.map(trackHeaderId => (
              <td
                key={trackHeaderId}
                style={td001}>
                <TimeSubCellContainer
                  style={{
                    height: `${timeHeight}px`,
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderBottomWidth: '0px',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TimeSubCellInnerContainer trackHeaderId={trackHeaderId} />
                </TimeSubCellContainer>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}, (prev, next) => isEqual(prev, next))

Table.displayName = 'Table'

const TimeSubCellInnerContainer: React.FC<{ trackHeaderId: number }> = ({ trackHeaderId }) => {
  return React.useMemo(() => {
    return (
      <>
        {Array.from(Array(4)).map((_, index) => (
          <TimeSubCell key={index} trackId={trackHeaderId} />
        ))}
      </>
    )
  }, [trackHeaderId])
}

const TimeSubCellContainer = styled.div`
  >div:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06)
  }
`
