import React from 'react'
import {
  DelegationsKpiClient,
  KeyPerformanceIndicatorItem
} from 'api/tenilo.dashboard.api-client'
import { DASHBOARD_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class KpiDelegationsApi {
  private readonly abortController = new AbortController()
  private readonly client: DelegationsKpiClient

  public constructor() {
    this.client = new DelegationsKpiClient(DASHBOARD_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getDelegationsAboutToExpireKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .delegationsAboutToExpireKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )

  public getActivitiesSignedViolatingDelegationRequirementKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .activitiesSignedViolatingDelegationRequirementKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )


}

const useKpiDelegationsApi = () => React.useMemo(() => new KpiDelegationsApi(), [])

export default useKpiDelegationsApi
