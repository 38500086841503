import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction, UseFormHook } from 'types'
import * as yup from 'yup'
import { OrganizationInternalInfoContext } from '../organization-internal-info-modal-context'

type OrgInternalInfoFormModel = Readonly<{
  internalInfo: string | undefined
}>

export const useOrgInternalInfoForm: UseFormHook<OrgInternalInfoFormModel> = () => {
  const f = useFormatMessage()
  const { currInternalInfo } = OrganizationInternalInfoContext.useState()
  const { handleUpdateOrgInternalInfo } = OrganizationInternalInfoContext.useActions()

  const initialValues: OrgInternalInfoFormModel = React.useMemo(()=>{
    return {
      internalInfo: currInternalInfo || ''
    }
  },[currInternalInfo])

  const onSubmit: OnSubmitFunction<OrgInternalInfoFormModel> = React.useCallback((
    values,{ setSubmitting }
  )=>{
    setSubmitting(true)
    handleUpdateOrgInternalInfo(values.internalInfo)
    ?.then(()=>{setSubmitting(false)})
    .catch((err) => {setSubmitting(false)})
  },[currInternalInfo])

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<OrgInternalInfoFormModel>({
        internalInfo: yup.string().max(2000,f('BASIC_WARNING_MAXSIZE_FIELD_TEXT',{name:f('PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_TITLE'),maxsize:2000}))
      }),
    []
  )

  const name: Name<OrgInternalInfoFormModel> = React.useMemo(()=>{
    return {
      internalInfo: 'internalInfo'
    }
  },[])

  return { initialValues, onSubmit, validationSchema, name }
}
