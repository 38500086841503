import React from 'react'
import * as yup from 'yup'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { useAuthenticationApi } from 'api'

type ImpersonationRequestForm = Readonly<{
  type: string
}>

const handleError = (setStatus: (status?: any) => void) => (error: any) => {
  const data = error.response && JSON.parse(error.response).data
  //Should we want to set a specific error message... Set a status here and then fix in container
  if (data) {
    setStatus({ error: 2 })
  } else {
    setStatus({ error: 1 })
  }
}

// Define the hook to be used to leverage this form
const useImpersonationRequestForm = (userId: number, token: string) => {
  const f = useFormatMessage()

  const { forgotPassword } = useAuthenticationApi()

  const initialValues: ImpersonationRequestForm = React.useMemo(
    () => ({
      type: 'approve',
    }),
    []
  )

  const onSubmit: OnSubmitFunction<ImpersonationRequestForm> = React.useCallback(
    ({ type }, { setSubmitting, setStatus }) => {
      forgotPassword(type)
        .then(() => {
          setStatus({ error: 100 })
        })
        .catch(handleError(setStatus))
        .finally(() => {
          setSubmitting(false)
        })
    },
    [forgotPassword]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ImpersonationRequestForm>({
        type: yup
          .string()
      }),
    [f]
  )

  const name: Name<ImpersonationRequestForm> = React.useMemo(
    () => ({
      type: 'type',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useImpersonationRequestForm
