import { Box } from '@mui/material'
import CenterModal from 'components/center-modal'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'
import ContainedButton from 'components/inputs/contained-button'
import DateTimePicker from 'components/inputs/date-time-picker'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { PausedPeriodContext } from '../paused-period-context'
import useFormData from './utilities/use-paused-period-dialog-form-data'

const PausedPeriodDialogView: React.FC = () => {
  const f = useFormatMessage()
  const { customerInfo } = CustomerModalContext.useState()
  const { initialValues, name, onSubmit, validationSchema } = useFormData()
  const { modalOpen, dialogActionType } = PausedPeriodContext.useState()
  const { closeModal } = PausedPeriodContext.useActions()
  const isPassedPeriod = initialValues.endDateTime && initialValues.endDateTime < (new Date())
  const isStartDateIsPassed = initialValues.startDateTime && initialValues.startDateTime < (new Date())

  return (
    <CenterModal open={modalOpen} onClose={closeModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, errors }) => (
          <Form translate="yes">
            <ModalHeader
              data-matomo-mask
              title={dialogActionType === 'edit' ? f('PAUSED_PERIOD_DIALOG_TITLE_EDIT') : f('PAUSED_PERIOD_DIALOG_TITLE_ADD')}
              subtitle={customerInfo ? customerInfo.fullName : ''}
            />
            <Box mx={5} mt={8} mb={4}>
              <div
                style={{
                  maxHeight: '70vh',
                  overflow: 'auto',
                }}>
                <ModalSection title={f('PAUSED_PERIOD_DIALOG_SECTION_DATE')} subtitle={f('PAUSED_PERIOD_DIALOG_SECTION_DATE_INFOTEXT_SUBTITLE')}>
                  <ModalSectionRow>
                    <DateTimePicker
                      label={f('PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_START_DATE')}
                      name={name.startDateTime}
                      disablePast
                      ampm={false}
                      format="yyyy-MM-dd HH:mm"
                      disabled={!!isPassedPeriod || !!isStartDateIsPassed}
                    />
                    <DateTimePicker
                      label={f('PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_END_DATE')}
                      name={name.endDateTime}
                      format="yyyy-MM-dd HH:mm"
                      disabled={!!isPassedPeriod}
                    />
                  </ModalSectionRow>
                  <ModalSectionRow>
                    <FilledTextField
                      data-matomo-mask
                      name={name.comment}
                      label={f('PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_COMMENT')}
                      color={errors.comment ? 'primary' : 'secondary'}
                      fullWidth
                      multiline
                      rows={5}
                      error={!!errors.comment}
                      disabled={!!isPassedPeriod}
                    />
                  </ModalSectionRow>
                </ModalSection>
              </div>
            </Box>
            <ModalFooter>
              <TextButton type="button" onClick={closeModal} disabled={isSubmitting}>
                {f('PAUSED_PERIOD_DIALOG_CANCEL')}
              </TextButton>
              {!isPassedPeriod && (
                <Box ml={2}>
                  <ContainedButton type="submit" color="secondary" showProgress={isSubmitting}>
                    {f('PAUSED_PERIOD_DIALOG_SAVE')}
                  </ContainedButton>
                </Box>
              )}
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CenterModal>
  )
}

export default PausedPeriodDialogView
