import React, { FC } from 'react';
import { useFormatMessage } from 'localization'
import { Switch } from '@mui/material'
import ContainedButton from 'components/inputs/contained-button'
import { Box, IconButton, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import { Colors } from 'styles/colors'
import { ChevronDoubleLeft } from 'mdi-material-ui'
import { CSSPropertiesWithVars } from 'styles/variables'
import {
  IPlannedRoutesActivitiesPlanningViewItem,
} from 'api/tillit.api-client'

type Props = Readonly<{
  openMobileTourMenu: boolean
  routes?: IPlannedRoutesActivitiesPlanningViewItem[] | undefined
  toggleOpenMobileTourMenu: () => void
  toggleRoute: (route: IPlannedRoutesActivitiesPlanningViewItem) => void
  hideAllRoutes: () => void
  showAllRoutes: () => void
}>

const PlanningRoutesList = styled(Box)(({ theme }) => css`
  >.tenilo-planning-tour-menu-close-button{
    display: none;
  }
  ${[theme.breakpoints.down('md')]}{
    >.tenilo-planning-tour-menu-close-button{
      display: block;
      margin-left: auto;
      color: ${Colors.TeniloBeige};
      >svg {
        font-size: 2.5rem;
      }
    }
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--planningRoutesListMobileWidth,0);
    z-index: 2000;
    transition: width 0.3s ease-in-out;
    background-color: rgba(10, 84, 87,0.97);
    overflow: hidden;
  }
`)


const PlanningRoutesMenuMobile: FC<Props> = ({ openMobileTourMenu, routes, toggleOpenMobileTourMenu, toggleRoute, hideAllRoutes, showAllRoutes }) => {
  const f = useFormatMessage()

  return (
    <PlanningRoutesList pr={5} style={{
      '--planningRoutesListMobileWidth': openMobileTourMenu ? '100%' : '0px'
    } as CSSPropertiesWithVars} sx={(theme) => ({ [theme.breakpoints.up('lg')]: { display: 'none' } })}>
      <IconButton
        className="tenilo-planning-tour-menu-close-button"
        aria-label='tours-close-button'
        onClick={toggleOpenMobileTourMenu}
      >
        <ChevronDoubleLeft />
      </IconButton>
      <>
        {routes && routes.map((route, index) => {
          return (
            <Box key={route.id} display="flex" flexDirection="row" sx={{
              backgroundColor: 'white',
              margin: '10px',
              borderRadius: '10px',
              padding: '10px'
            }}>
              <Box sx={{
                backgroundColor: route.color,
                borderRadius: '25px',
                height: '10px',
                width: '10px',
                minHeight: '10px',
                minWidth: '10px',
                margin: '5px',
                border: '1px solid #6e6e6e',
                alignSelf: 'center'
              }} />
              <Typography data-matomo-mask sx={{ marginRight: '16px', alignSelf: 'center' }}>{route.name}</Typography>
              <Typography sx={{ marginRight: '0px', alignSelf: 'center', flexGrow: '1', textAlign: 'right' }}>{f('PLANNING_PAGE_ROUTESMENU_HIDE')}</Typography>
              <Switch checked={!route.hidden} color="primary" onChange={() => { toggleRoute(route) }} />
              <Typography sx={{ marginRight: '0px', alignSelf: 'center' }}>{f('PLANNING_PAGE_ROUTESMENU_SHOW')}</Typography>
            </Box>
          )
        })
        }
        <Box sx={{
          alignItems: 'baseline', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'stretch',
          margin: '10px',

        }}>
          <ContainedButton color='secondary' onClick={hideAllRoutes}>{f('PLANNING_PAGE_ROUTESMENU_HIDE_ALL')}</ContainedButton>

          <ContainedButton color='secondary' onClick={showAllRoutes}>{f('PLANNING_PAGE_ROUTESMENU_SHOW_ALL')}</ContainedButton>
        </Box>

      </>
    </PlanningRoutesList>
  )
}

export default PlanningRoutesMenuMobile

