/* eslint-disable react-hooks/exhaustive-deps */
import { useActivityApi } from 'api'
import { IPlannedActivityRowItem, IPlannedActivityRowSigningItem, PlannedActivityStatusEnum,
  IRecentPerformedSigningItem, FulfilledDelegationItem, UnitTypeEnum, PlannedActivitySignedParticipantCreateItem, PlannedActivityRowItem, RecentPerformedSigningItem, PlannedActivitySignedDelegationViolationsCreateItem } from 'api/tillit.api-client'
import React from 'react'
import usePromiseApi from 'utilities/use-promise'
import { getDeviceGuid } from 'api/device-guid'
import { filter } from 'lodash'
import { hasCompleteStatus } from 'utilities/planned-activity-helper'
import { createContext } from 'utilities/create-context'


const useSingleActivityModalContext = () => {
  const {
    completePlannedActivity,
    completePlannedActivityWithoutAction,
    delayPlannedActivity,
    getDelayedActivityShouldOccurToday,
    completeDelayedActivityWithoutAction,
    deleteByPlannedActivityRowId,
    completePartiallyCompletedActivity,
    completePartiallyGivenActivity,
    getPlannedDailyActivity,
    unsignPlannedActivityWithoutAction
  } = useActivityApi()

  const { state: fetchState, fetchAsync } = usePromiseApi()

  const [plannedActivity, setPlannedActivity] = React.useState<IPlannedActivityRowItem | null>(null)
  const [delaySignings, setDelaySignings] = React.useState<IPlannedActivityRowSigningItem[] | null>(null)
  const [delayedActivityShouldOccurToday, setDelayedActivityShouldOccurToday] = React.useState<boolean>(false)
  const [activityHasActiveDelayedOccurance, setActivityHasActiveDelayedOccurance] = React.useState<boolean>(false)
  const [recentlyPerformedSignings, setRecentlyPerformedSignings] = React.useState<IRecentPerformedSigningItem[] | null>(null)
  const [hasDelegationsNotFulfilled, setHasDelegationsNotFulfilled] = React.useState<boolean>(false)
  const [delegationsFulfillment, setDelegationsFulfillment] = React.useState<FulfilledDelegationItem[] | undefined>(undefined)
  const [temporaryActivityCreatedToday, setTemporaryActivityCreatedToday] = React.useState<boolean>(false)
  const [ listingModalOpen, setListingModalOpen ] = React.useState<boolean>(false)
  const [isMeasurementUnitType, setIsMeasurementUnitType] = React.useState(false)
  const [activityHasUnitType, setActivityHasUnitType] = React.useState(false)
  const [removeActivityDialogOpen, setRemoveActivityDialogOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [isPartiallyCompletedModalOpen, setIsPartiallyCompletedModalOpen] = React.useState(false)

  const onUnsignClicked = React.useCallback(
    async (comment?: string): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }
      const activity = await fetchAsync(
        unsignPlannedActivityWithoutAction({
          plannedActivityRowId: plannedActivity.id,
          comment,
          hasDelegationViolations: false,
        })
      )
      return activity
    },
    [plannedActivity]
  )

  const fetchDelayedActivityShouldOccurToday = React.useCallback((plannedActivityRowId: number) => {
    const deviceGuid = getDeviceGuid()
    if (!deviceGuid) {
      throw new Error('No device guid')
    }

    const day: Date = new Date()
    const start: Date = new Date(day.getFullYear(), day.getMonth(), day.getDate() - 1, 24)
    const end: Date = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 0)
    return fetchAsync(getDelayedActivityShouldOccurToday(plannedActivityRowId, deviceGuid, start, end)).then(shouldOccur => {
      setDelayedActivityShouldOccurToday(shouldOccur)
    })
  }, [getDelayedActivityShouldOccurToday, setDelayedActivityShouldOccurToday])

  const fetchActivity = React.useCallback((plannedActivityRowId: number) => {
    setPlannedActivity(plannedActivity)
      setDelaySignings(null)
      setDelayedActivityShouldOccurToday(false)
      setActivityHasActiveDelayedOccurance(false)
      setRecentlyPerformedSignings(null)
      setHasDelegationsNotFulfilled(false)
      setDelegationsFulfillment(undefined)

    return fetchAsync(getPlannedDailyActivity(plannedActivityRowId)).then(plannedDailyActivity => {
      if (plannedDailyActivity.plannedActivityRow)
      {
        setPlannedActivity(plannedDailyActivity.plannedActivityRow)
      }

      if (plannedDailyActivity.recentActivitySignings)
      {
        setRecentlyPerformedSignings(plannedDailyActivity.recentActivitySignings)
      }

      setTemporaryActivityCreatedToday(plannedDailyActivity.isTemporaryActivityCreatedToday)
      setDelegationsFulfillment(plannedDailyActivity.notFullfilledDelegations)


      setActivityHasActiveDelayedOccurance(plannedDailyActivity.activityHasActiveDelayedOccurance)
      if (activityHasActiveDelayedOccurance)
      {
        if (plannedDailyActivity.activeDelayedSignings) {
          setDelaySignings(plannedDailyActivity.activeDelayedSignings)
        }
      }

      if (!plannedDailyActivity.plannedActivityRow)
      {
        throw Error ('There is no plannedActivityRowSelected')
      }

      if (plannedDailyActivity.plannedActivityRow.plannedActivityStatusId === PlannedActivityStatusEnum.NotCompleted ||
        plannedDailyActivity.plannedActivityRow.plannedActivityStatusId === PlannedActivityStatusEnum.Delayed) {
          const notFulfilled = filter(plannedDailyActivity.notFullfilledDelegations, (delegation: FulfilledDelegationItem) => {
            return !delegation.isFulfilled
          })
          setHasDelegationsNotFulfilled(notFulfilled ? notFulfilled.length > 0 : false)
      }

      if (plannedDailyActivity.plannedActivityRow?.templateActivityUnitTypeEnumId === UnitTypeEnum.MeasurementUnit) {
        setIsMeasurementUnitType(true)
        setActivityHasUnitType(true)
      }
      if (plannedDailyActivity.plannedActivityRow?.templateActivityUnitTypeEnumId === UnitTypeEnum.TimeUnit) {
        setIsMeasurementUnitType(false)
        setActivityHasUnitType(true)
      }
      if (plannedDailyActivity.plannedActivityRow.plannedActivityStatusId === PlannedActivityStatusEnum.Delayed) {
        void fetchDelayedActivityShouldOccurToday(plannedActivityRowId)
        setActivityHasActiveDelayedOccurance(false) // a delayed activity cannot have another active delayed instance!
      } else {
        setDelayedActivityShouldOccurToday(false) // not delayed... so we don't care
      }
    })
  }, [setActivityHasActiveDelayedOccurance, setDelayedActivityShouldOccurToday,])

  const onCompleteClick = React.useCallback(
    async (statusId: PlannedActivityStatusEnum, comment?: string): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }

      let plannedActivitySignedDelegationViolations: PlannedActivitySignedDelegationViolationsCreateItem[] = []
      if (hasDelegationsNotFulfilled && delegationsFulfillment) {
        plannedActivitySignedDelegationViolations = delegationsFulfillment.filter(df => !df.isFulfilled).map(df => {
          return new PlannedActivitySignedDelegationViolationsCreateItem({
            delegationId: df.id
          })
        })
      }

      const activity = await fetchAsync(
        completePlannedActivity({
          plannedActivityRowId: plannedActivity.id,
          signingComment: comment,
          comment: plannedActivity.comment,
          givenAmount: plannedActivity.activity_ExpectedAmount,
          plannedActivityStatusId: statusId,
          hasDelegationViolations: hasDelegationsNotFulfilled,
          plannedActivitySignedDelegationViolations
        })
      )
      return activity
    },
    [plannedActivity, hasDelegationsNotFulfilled, delegationsFulfillment]
  )

  const onSigningClick = React.useCallback(
    async (statusId: PlannedActivityStatusEnum,
            comment?: string,
            plannedActivitySignedParticipants?: PlannedActivitySignedParticipantCreateItem[] | undefined,
            plannedActivitySignedDelegationViolations?: PlannedActivitySignedDelegationViolationsCreateItem[] | undefined,
            hasDelegationViolations: boolean = false): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }
      const activity = await fetchAsync(
        completePlannedActivity({
          plannedActivityRowId: plannedActivity.id,
          signingComment: comment,
          comment: plannedActivity.comment,
          givenAmount: statusId === PlannedActivityStatusEnum.CompletedWithoutAction ? undefined : plannedActivity.activity_ExpectedAmount,
          plannedActivityStatusId: statusId,
          plannedActivitySignedParticipants,
          plannedActivitySignedDelegationViolations,
          hasDelegationViolations
        })
      )
      return activity
    },
    [plannedActivity]
  )

  const onCompleteWithoutActionClicked = React.useCallback(
    async (statusId: PlannedActivityStatusEnum, comment?: string): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }
      const activity = await fetchAsync(
        completePlannedActivityWithoutAction({
          plannedActivityRowId: plannedActivity.id,
          signingComment: comment,
          comment: plannedActivity.comment,
          givenAmount: undefined,
          plannedActivityStatusId: statusId,
          hasDelegationViolations: false
        })
      )
      return activity
    },
    [plannedActivity]
  )

  const onCompleteDelayedWithoutActionClicked = React.useCallback(
    async (statusId: PlannedActivityStatusEnum, comment?: string): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }
      const activity = await fetchAsync(
        completeDelayedActivityWithoutAction({
          plannedActivityRowId: plannedActivity.id,
          signingComment: comment,
          comment: plannedActivity.comment,
          plannedActivityStatusId: statusId,
          hasDelegationViolations: false
        })
      )
      void fetchActivity(activity.id)
      return activity
    },
    [plannedActivity]
  )

  const onDelayClicked = React.useCallback(
    async (comment?: string): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }
      const activity = await fetchAsync(
        delayPlannedActivity({
          plannedActivityRowId: plannedActivity.id,
          signingComment: comment,
          comment: plannedActivity.comment,
          hasDelegationViolations: false
        })
      )
      return activity
    },
    [plannedActivity]
  )

  const onPartiallyCompletedClicked = React.useCallback(
    async (comment?: string, givenAmount?: number): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }

      let plannedActivitySignedDelegationViolations: PlannedActivitySignedDelegationViolationsCreateItem[] = []
      if (hasDelegationsNotFulfilled && delegationsFulfillment) {
        plannedActivitySignedDelegationViolations = delegationsFulfillment.filter(df => !df.isFulfilled).map(df => {
          return new PlannedActivitySignedDelegationViolationsCreateItem({
            delegationId: df.id
          })
        })
      }

      const activity = await fetchAsync(
        completePartiallyCompletedActivity({
          plannedActivityRowId: plannedActivity.id,
          givenAmount: givenAmount || plannedActivity.activity_ExpectedAmount,
          signingComment: comment,
          comment: plannedActivity.comment,
          hasDelegationViolations: hasDelegationsNotFulfilled,
          plannedActivitySignedDelegationViolations,
        })
      )
      void fetchActivity(activity.id)
      return activity
    },
    [plannedActivity, hasDelegationsNotFulfilled, delegationsFulfillment]
  )

  const onPartiallyGivenClicked = React.useCallback(
    async (comment?: string, givenAmount?: number): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }
      let plannedActivitySignedDelegationViolations: PlannedActivitySignedDelegationViolationsCreateItem[] = []
      if (hasDelegationsNotFulfilled && delegationsFulfillment) {
        plannedActivitySignedDelegationViolations = delegationsFulfillment.filter(df => !df.isFulfilled).map(df => {
          return new PlannedActivitySignedDelegationViolationsCreateItem({
            delegationId: df.id
          })
        })
      }
      const activity = await fetchAsync(
        completePartiallyGivenActivity({
          plannedActivityRowId: plannedActivity.id,
          givenAmount: givenAmount || plannedActivity.activity_ExpectedAmount,
          signingComment: comment,
          comment: plannedActivity.comment,
          hasDelegationViolations: hasDelegationsNotFulfilled,
          plannedActivitySignedDelegationViolations,
        })
      )
      void fetchActivity(activity.id)
      return activity
    },
    [plannedActivity, hasDelegationsNotFulfilled, delegationsFulfillment]
  )

  const handleRemoveActivity = React.useCallback(async (plannedActivityRowId: number): Promise<number> => {
    return fetchAsync(deleteByPlannedActivityRowId(plannedActivityRowId))
  }, [deleteByPlannedActivityRowId])

  const closeRemoveActivityDialog = React.useCallback(() => {
    setRemoveActivityDialogOpen(false)
  }, [setRemoveActivityDialogOpen])

  const openRemoveActivityDialog = React.useCallback(() => {
    setRemoveActivityDialogOpen(true)
  }, [setRemoveActivityDialogOpen])

  const isComplete = React.useMemo(() => plannedActivity && hasCompleteStatus(plannedActivity.plannedActivityStatusId), [plannedActivity])
  const isCompleteWithoutAction = React.useMemo(
    () => plannedActivity && plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.CompletedWithoutAction,
    [plannedActivity]
  )
  const isDelayed = React.useMemo(() => plannedActivity && plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.Delayed, [plannedActivity])

  const close = React.useCallback(() => {
    setOpen(false)
    setListingModalOpen(false)
    setPlannedActivity(null)
    setActivityHasUnitType(false)
    setIsPartiallyCompletedModalOpen(false)
  }, [])

  return {
    state: {
      plannedActivity,
      fetchState,
      open,
      isComplete,
      isCompleteWithoutAction,
      isDelayed,
      delaySignings,
      delayedActivityShouldOccurToday,
      activityHasActiveDelayedOccurance,
      recentlyPerformedSignings,
      hasDelegationsNotFulfilled,
      delegationsFulfillment,
      temporaryActivityCreatedToday,
      removeActivityDialogOpen,
      isMeasurementUnitType,
      activityHasUnitType,
      isPartiallyCompletedModalOpen,
      listingModalOpen
    },
    actions: {
      fetchActivity,
      setPlannedActivity,
      setOpen,
      onCompleteClick,
      onCompleteWithoutActionClicked,
      onCompleteDelayedWithoutActionClicked,
      onSigningClick,
      onDelayClicked,
      close,
      handleRemoveActivity,
      openRemoveActivityDialog,
      closeRemoveActivityDialog,
      onPartiallyCompletedClicked,
      onPartiallyGivenClicked,
      setIsPartiallyCompletedModalOpen,
      setListingModalOpen,
      onUnsignClicked
    },
  }
}

export default createContext(useSingleActivityModalContext)


