import React, { useCallback, useMemo } from 'react'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import isEmpty from 'lodash/isEmpty'
import { useFormatMessage } from 'localization'
import { UserAreaItem } from 'api/tillit.api-client'
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getVisibleAreaData, setVisibleAreaData, removeVisibleAreaData } from 'utilities/area-helper'
import NotificationContext from 'components/notification/notification-context'

type Props = Readonly<
  {
    userAreas: UserAreaItem[]
    setVisibleAreas: React.Dispatch<React.SetStateAction<UserAreaItem[]>>
  }
>

const SelectedVisibleAreas: React.FC<Props> = ({ userAreas, setVisibleAreas }) => {
  const f = useFormatMessage()
  const { removeAlertByType } = NotificationContext.useActions()
  const getAreaName = useCallback(( area :  UserAreaItem )=> {
    if (area.organizationParentsName) {
      return `${area.areaName} (${area.organizationParentsName})`
    }
    return area.areaName || ''
  }, [])

  const renderInput = useCallback(( params : AutocompleteRenderInputParams ) => {
    return <TextField
              {...params}
              color='secondary'
              label={f('ADMINISTER_SELECTED_VISIBLE_AREA_TITLE')}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth:'2px',
                    borderColor: 'secondary.main',
                  },
                  '&:hover fieldset': {
                    borderColor: 'secondary.main',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'secondary.main',
                  },
                },
              }}
            />
  }, [])

  const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: UserAreaItem[] | []) => {
    if(isEmpty(value)){
      removeVisibleAreaData()
      removeAlertByType('visible-area-alert')
      setVisibleAreas(value)
      return
    }
    setVisibleAreaData(value)
    setVisibleAreas(value)
  },[])

  const defaultValue = useMemo(()=>{
    return getVisibleAreaData()
  },[])

  const checkOptionEquality = useCallback(( option, value )=> option.areaId === value.areaId ,[])

  return (
    <ModalSection
      title={f('ADMINISTER_SELECTED_VISIBLE_AREA_TITLE')}
      subtitle={f('ADMINISTER_SELECTED_VISIBLE_AREA_SUBTITLE')}
      responsive>
      <ModalSectionRow responsive>
        <Autocomplete
          multiple
          filterSelectedOptions
          limitTags={2}
          options={userAreas}
          getOptionLabel={getAreaName}
          defaultValue={defaultValue}
          isOptionEqualToValue={checkOptionEquality}
          onChange={handleChange}
          renderInput={renderInput}
          sx={{
            width: '100%',
            maxWidth: '400px',
            m:1
          }}
        />
      </ModalSectionRow>
    </ModalSection>
  )
}

export default SelectedVisibleAreas
