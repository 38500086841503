import { Box, FormControl, MenuItem, Typography, useTheme } from '@mui/material'
import { ICustomerItem } from 'api/tillit.api-client'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { Field, Form, Formik } from 'formik'
import { Select } from 'formik-mui'
import { useFormatMessage } from 'localization'
import { CustomersPageContext } from 'pages/customers/customers-page-context'
import React from 'react'
import useFormData from './utilities/use-basic-information-form-data'

interface IBasicInformation {
  customRef: React.RefObject<any>
  customerInfo: ICustomerItem
}

const BasicInformation: React.FC<IBasicInformation> = ({ customRef, customerInfo }) => {
  const f = useFormatMessage()
  const { areas } = CustomersPageContext.useState()
  const { customerHasActivityOnCurrentAreaTemplateActivity } = CustomerModalContext.useState()
  const theme = useTheme()
  const { initialValues, name, onSubmit, validationSchema } = useFormData(customerInfo, areas)

  return (
    <Box px={2} mx={5} height="60vh" overflow="auto">
      <Formik innerRef={customRef} initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, errors }) => (
          <Form translate="yes">
            <ModalSection
              title={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_AREA')}
              subtitle={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_AREA_SUBTITLE')}>
              <ModalSectionRow>
                <FormControl>
                  <Field component={Select} value={values.area} name={name.area}>
                    {areas.map((areaOpt, index) => (
                      <MenuItem selected={values.area === areaOpt.id} key={index} value={areaOpt.areaId}>
                        {areaOpt.organizationParentsName ? `${areaOpt.areaName} (${areaOpt.organizationParentsName})` : areaOpt.areaName}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </ModalSectionRow>
            </ModalSection>
            {customerHasActivityOnCurrentAreaTemplateActivity && (
              <ModalSection title={''} subtitle={''}>
                <ModalSectionRow>
                  <Box width="80%">
                    <Typography style={{ color: theme.palette.error.main }}>
                      {f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_CHANGE_AREA_TEMPLATE_ACTIVITY_ERROR')}
                    </Typography>
                  </Box>
                </ModalSectionRow>
              </ModalSection>
            )}
            <ModalSection
              title={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_TITLE')}
              subtitle={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_SUBTITLE')}>
              <ModalSectionRow>
                <FormControl>
                  <ModalSectionRow>
                    <FormControl>
                      <FilledTextField
                        data-matomo-mask
                        type="text"
                        fullWidth
                        label={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_TITLE')}
                        name={name.roomNumber}
                        value={values.roomNumber}
                        color="secondary"
                      />
                    </FormControl>
                  </ModalSectionRow>
                </FormControl>
              </ModalSectionRow>
            </ModalSection>
            <ModalSection
              title={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_TITLE')}
              subtitle={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_SUBTITLE')}>
              <ModalSectionRow>
                <FilledTextField
                  data-matomo-mask
                  fullWidth
                  label={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_FIRSTNAME_TEXTFIELD_LABEL')}
                  name={name.firstName}
                  value={values.firstName}
                  color="secondary"
                />
                <FilledTextField
                  data-matomo-mask
                  fullWidth
                  label={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_LASTNAME_TEXTFIELD_LABEL')}
                  name={name.lastName}
                  value={values.lastName}
                  color="secondary"
                />
              </ModalSectionRow>
            </ModalSection>
            <ModalSection title={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_TITLE')} subtitle={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_SUBTITLE')}>
              <ModalSectionRow>
                <FilledTextField
                  data-matomo-mask
                  fullWidth
                  label={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_TEXTFIELD_LABEL')}
                  name={name.extraInfo}
                  value={values.extraInfo}
                  color="secondary"
                />
              </ModalSectionRow>
            </ModalSection>
            <ModalSection title={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_INFOTEXT_TITLE')}>
              <ModalSectionRow>
                <FilledTextField
                  data-matomo-mask
                  fullWidth
                  multiline
                  rows={4}
                  label={f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL')}
                  name={name.description}
                  value={values.description}
                  color="secondary"
                />
              </ModalSectionRow>
            </ModalSection>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default BasicInformation
