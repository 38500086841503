import Hammer from 'hammerjs'
import React from 'react'
import { createContext } from 'utilities/create-context'
import { ItemTypes } from '../../planning-grid-types'

type Data = Readonly<{
  type: keyof typeof ItemTypes
  item: any
  element: HTMLDivElement
  collection: {
    initialOffset: { left: number; top: number }
    currentOffset: { left: number; top: number }
    differenceFromInitialOffset: { dx: number; dy: number }
    mousePos:{x:number,y:number}
  }
}>

const useDragAndDropContext = (props: { readonly?: boolean }) => {
  const { readonly = false } = props
  const [draggingData, setDraggingData] = React.useState<Data | null>(null)
  const [isDragging, setIsDragging] = React.useState(false)

  React.useEffect(() => {
    setIsDragging(draggingData !== null)
  }, [draggingData])

  return {
    state: { draggingData, isDragging, readonly },
    actions: { setDraggingData },
  }
}

const DragAndDropContext = createContext(useDragAndDropContext)

const useLayout = (): [Data | null] => {
  const { draggingData } = DragAndDropContext.useState()
  return [draggingData]
}

export type UseDropProps = Readonly<{
  onDrop?: (props: Data) => void
  canDrop?: (props: Data) => boolean
}>

type CustomeEvent = Readonly<{ draggingData: Data }>
type CustomEventListener = (evt: { detail: CustomeEvent } & Event) => void

const useDrop = (
  accept: Array<keyof typeof ItemTypes>,
  props: UseDropProps
): [
  React.MutableRefObject<HTMLDivElement | null>,
  { canDrop: boolean; isOver: boolean; isDragging: boolean }
] => {
  const { canDrop = () => true, onDrop } = props
  const { isDragging, draggingData } = DragAndDropContext.useState()
  const ref = React.useRef<HTMLDivElement>(null)
  const [isOver, setIsOver] = React.useState(false)
  const acceptRef = React.useRef(accept)

  React.useEffect(() => {
    const { current } = ref

    if (current !== null) {
      const handleDrop: CustomEventListener = event => {
        const { detail } = event

        if (!acceptRef.current.includes(detail.draggingData.type)) {
          return
        }

        if (canDrop(detail.draggingData) && onDrop !== undefined) {
          onDrop(detail.draggingData)
        }
      }
      current.addEventListener('ondrop', handleDrop as EventListener)
      return () => {
        current.removeEventListener('ondrop', handleDrop as EventListener)
      }
    }
    return () => {}
  }, [canDrop, onDrop])

  React.useEffect(() => {
    const { current } = ref

    const handleMouseOver = (event: MouseEvent) => {
      setIsOver(true)
    }

    const handleMouseOut = (event: MouseEvent) => {
      setIsOver(false)
    }

    if (current !== null) {
      current.addEventListener('mouseover', handleMouseOver)
      current.addEventListener('mouseout', handleMouseOut)
      return () => {
        current.removeEventListener('mouseout', handleMouseOut)
        current.removeEventListener('mouseover', handleMouseOver)
      }
    }
    return () => {}
  }, [])

  return [
    ref,
    {
      canDrop: !!draggingData && !!canDrop && canDrop(draggingData!),
      isOver,
      isDragging,
    },
  ]
}

export type UseDragProps = Readonly<{
  preventHorizontalDrag?: boolean
  onStart?: (props: Data) => void
  onUpdate?: (props: Data) => void
  onEnd?: (props: Data) => void
  canDrag?: (props: Data) => boolean
}>

type UseDragHook = (
  type: keyof typeof ItemTypes,
  item: any,
  props?: UseDragProps
) => [
  Data | null,
  React.MutableRefObject<HTMLDivElement | null>,
  { isDragging: boolean }
]

const useDrag: UseDragHook = (type, item, props = {}) => {
  const { onStart, onUpdate, onEnd, preventHorizontalDrag = false } = props
  const { draggingData, readonly } = DragAndDropContext.useState()
  const { setDraggingData } = DragAndDropContext.useActions()

  const ref = React.useRef<HTMLDivElement>(null)
  const isDraggingRef = React.useRef<boolean>(false)
  const itemRef = React.useRef(item)
  const typeRef = React.useRef(type)

  React.useEffect(() => {
    itemRef.current = item
  }, [item])

  const canDrag = React.useMemo(
    () => (props.canDrag ? props.canDrag : () => true),
    [props.canDrag]
  )

  React.useEffect(() => {
    const { current } = ref
    let draggingDataEffect: Data | null = null

    if (current !== null) {
      let rect: DOMRect | null = null
      let dx = 0
      let dy = 0
      let mousePos = {x:0,y:0}
      let needForRAF = true
      const mc = new Hammer(current)
      mc.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }))
      mc.add(new Hammer.Press())

      const update = () => {
        needForRAF = true

        // We we currently is not dragging, do not update state
        if (!isDraggingRef.current) {
          return
        }

        if (rect === null) {
          //Event for mouse down has not been fired
          return
        }

        const initialOffset = { left: rect.left, top: rect.top }

        draggingDataEffect = {
          type: typeRef.current,
          item: itemRef.current,
          element: current,
          collection: {
            initialOffset,
            currentOffset: {
              left: initialOffset.left + dx,
              top: initialOffset.top + dy,
            },
            differenceFromInitialOffset: { dx, dy },
            mousePos
          },
        }
        setDraggingData(draggingDataEffect)

        if (onUpdate !== undefined) {
          onUpdate(draggingDataEffect)
        }
      }

      const handlePan = (event: HammerInput) => {
        if (itemRef.current) {
          const planMeta = itemRef.current.meta;
          if ((planMeta?.plannedActivityStatusId === "Completed" || planMeta?.plannedActivityStatusId === "CompletedWithoutAction") || planMeta?.startsBeforeCalendarDate) {
            return // Completed can't be moved
          }
        }

        if (!preventHorizontalDrag) {
          dx = event.deltaX
          mousePos.x = event.center.x
        }
        dy = event.deltaY
        mousePos.y = event.center.y

        if (needForRAF) {
          needForRAF = false // no need to call rAF up until next frame
          requestAnimationFrame(update) // request 60fps animation
        }
      }

      const handlePanEnd = (event: HammerInput) => {
        if (itemRef.current) {
          const planMeta = itemRef.current.meta;
          if ((planMeta?.plannedActivityStatusId === "Completed" || planMeta?.plannedActivityStatusId === "CompletedWithoutAction") || planMeta?.startsBeforeCalendarDate) {
            return // Completed can't be moved
          }
        }

        isDraggingRef.current = false

        if (draggingDataEffect === null) {
          return
        }

        if (onEnd) {
          onEnd(draggingDataEffect)
        }

        const evt = new CustomEvent<CustomeEvent>('ondrop', {
          detail: { draggingData: draggingDataEffect },
          bubbles: true,
        })

        if (event.srcEvent.target !== null) {
          event.srcEvent.target.dispatchEvent(evt)
        }

        /// Reset dragging data to null
        draggingDataEffect = null
        setDraggingData(null)

        mc.off('pan', handlePan)
        mc.off('panend', handlePanEnd)
      }

      const handlePanStart = (event: HammerInput) => {
        if (itemRef.current) {
          const planMeta = itemRef.current.meta;
          if ((planMeta?.plannedActivityStatusId === "Completed" || planMeta?.plannedActivityStatusId === "CompletedWithoutAction") || planMeta?.startsBeforeCalendarDate) {
            return // Completed can't be moved
          }
        }

        rect = current.getBoundingClientRect()
        const initialOffset = { left: rect.left, top: rect.top }
        dx = 0
        dy = 0
        isDraggingRef.current = true

        const data: Data = {
          type: typeRef.current,
          item: itemRef.current,
          element: current,
          collection: {
            initialOffset,
            currentOffset: initialOffset,
            differenceFromInitialOffset: { dx: 0, dy: 0 },
            mousePos
          },
        }
        if (!canDrag(data)) {
          return
        }

        const evt = new CustomEvent<CustomeEvent>('ondrag', {
          detail: { draggingData: data },
          bubbles: true,
        })

        if (event.srcEvent.target !== null) {
          event.srcEvent.target.dispatchEvent(evt)
        }

        draggingDataEffect = data
        setDraggingData(draggingDataEffect)

        if (onStart !== undefined) {
          onStart(draggingDataEffect)
        }
        mc.on('panend', handlePanEnd)
        mc.on('pan', handlePan)
      }
      if (!readonly) {
        mc.on('panstart', handlePanStart)
      }
      return () => {
        mc.off('pan', handlePan)
        mc.off('panend', handlePanEnd)
        mc.off('panstart', handlePanStart)
      }
    }
    return () => {}
  }, [
    canDrag,
    onEnd,
    onStart,
    onUpdate,
    preventHorizontalDrag,
    readonly,
    setDraggingData,
  ])

  return [draggingData, ref, { isDragging: isDraggingRef.current }]
}

const DragAndDropProvider = DragAndDropContext.Provider
const useDragAndDropState = DragAndDropContext.useState

export {
  DragAndDropProvider,
  useDragAndDropState,
  useDrag,
  useDrop,
  useLayout,
  DragAndDropContext,
}
