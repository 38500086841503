import { AuthenticationNoRefreshApi } from 'api/authentication-no-refresh-api'
import { clearTokenData, getTokenData } from 'api/token-data'
import PageRoutes from 'config/page-routes'
import { clearUserData } from 'components/authentication/utilities/user-storage'
import { getVisibleAreaIdsString } from 'utilities/area-helper'
import { setIsLoggedOut } from './logged-out-helper'

type HTTP = Readonly<{
  fetch(url: RequestInfo, init?: RequestInit): Promise<Response>
}>

const auth = new AuthenticationNoRefreshApi()

export const originalFetch = window.fetch

const httpMiddleware = (initOptions: RequestInit = {}): HTTP => ({
  fetch: (url, fetchInit = {}): Promise<Response> => {
    const tokenData = getTokenData()
    const visibleAreas = getVisibleAreaIdsString()
    const init = {
      ...fetchInit,
      ...initOptions,
      headers: {
        ...fetchInit.headers,
        ...initOptions.headers,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Authorization: `Bearer ${tokenData && tokenData.token}`,
        'Visible-Areas': visibleAreas,
      },
    }
    return window.fetch(url, init).then(result => {
      // If Unauthorized
      if (result.status === 401) {
        // If we do not have token data, redirect user to login screen
        if (tokenData === null) {
          clearTokenData()
          clearUserData()
          return Promise.reject(
            //'No refresh token available, can not refresh auth token, redirecting to login page.
            window.location.replace(PageRoutes.Login.path)
          )
        }

        // If loged in user has token data, try to refresh auth token
        return handleTokenExpired(url, init, tokenData.refreshToken)
      }

      // If all is OK, return result
      return Promise.resolve(result)
    })
  },
})

const handleTokenExpired = (url: RequestInfo, init: RequestInit, refreshToken: string) => {
  return auth
    .generateJwtTokenFromRefreshToken(refreshToken)
    .catch(() => {
      // Här hamnar man ifall man blivit utloggad från backend.
      // Vi skall visa en modal om att man blivit utloggad. Beskrivet i TEN-987.
      clearTokenData()
      clearUserData()
      return Promise.reject(
        //Can not refresh auth token, redirecting to login page.
        setIsLoggedOut()
      )
    })
    .then(resultItem => {
      const requestInit = {
        ...init,
        headers: {
          ...init.headers,
          Authorization: `Bearer ${resultItem.token}`,
        },
      }
      return window.fetch(url, { ...requestInit })
    })
}

export const callIntercept = (cb?: (options?: RequestInit, response?: Response) => void): void => {
  window.fetch = async (url: RequestInfo, options: RequestInit | undefined) => {
    const response = await originalFetch(url, options)

    if (cb) {
      cb(options, response)
    }

    return response
  }
}

export default httpMiddleware
