import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Checkbox } from '@mui/material'
import React from 'react'
import { RoleEnum } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { UserAreaListItem } from 'api/tenilo.support.api-client'

const AllRoles = Object.keys(RoleEnum)
  .filter(role => isNaN(Number(RoleEnum[role])))
  .map(role => role as RoleEnum)

const SupportUserModalRolesView: React.FC<{ areas?: UserAreaListItem[] }> = ({ areas }) => {
  const f = useFormatMessage()

  const reducedAreas = areas?.reduce<UserAreaListItem[]>((prev, area) => {
    const { areaId } = area
    if (
      !prev.some(p => p.areaId === areaId)
    ) {
      return [...prev, area]
    }
    return prev
  }, [])
    .sort((a1, a2) => (a1.areaName || '').localeCompare(a2.areaName || ''))

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{f('SUPPORT_USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_AREA_COLUMN_TITLE')}</TableCell>
          {AllRoles.map((role) => (
            <TableCell key={role}>{f('SUPPORT_USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_ROLE_COLUMN_TITLE', { role })}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {reducedAreas?.map(reducedArea => (
          <TableRow key={reducedArea.id}>
            <TableCell>
              <Typography variant="subtitle1">{reducedArea.organizationParentsName ? `${reducedArea.areaName} (${reducedArea.organizationParentsName})` : reducedArea.areaName}</Typography>
            </TableCell>
            {AllRoles.map((role, i) => (
              <TableCell key={i}>
                <Checkbox disabled checked={areas?.some(area => area.areaId === reducedArea.areaId && area.roleId === role)} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SupportUserModalRolesView
