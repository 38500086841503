import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import 'date-fns'
import React from 'react'
import { FutureRouteActivityRowItem } from 'api/tillit.api-client'

import { useFormatMessage } from 'localization'
import styled from 'styled-components/macro'
import { format } from 'date-fns'

interface IAlertFutureChanges {
  open: boolean
  showPenMarkedInfo: boolean
  futureRows: FutureRouteActivityRowItem[] | null
  onClose: () => void
  onChangeUpTillNext: () => void
  onReplaceAll: () => void
}

const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  flex: 1;
  &:hover {
    background-color: #f5f5f5;
  }
`

const AlertFutureChanges: React.FC<IAlertFutureChanges> = ({ open, showPenMarkedInfo, futureRows, onClose, onChangeUpTillNext, onReplaceAll }) => {
  const f = useFormatMessage()

  const handleChangeUpTillNextClick = () => onChangeUpTillNext()
  const handleReplaceAllClick = () => onReplaceAll()
  const handleCloseClick = () => onClose()
  const handleCancelClick = () => onClose()

  return (
    <Dialog open={open} onClose={handleCloseClick} fullWidth={true} maxWidth="md">
      <DialogTitle>{f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1')}</DialogContentText>
        {showPenMarkedInfo && <DialogContentText>{f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2')}</DialogContentText>}
        <Box>
          <List style={{ maxHeight: '30vh', overflow: 'auto' }} subheader={<li />}>
            <div
              style={{
                position: 'sticky',
                top: '0',
                backgroundColor: '#ffffff',
                zIndex: 1000,
              }}>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '8vw',
                  maxWidth: '8vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_START_DATE')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '8vw',
                  maxWidth: '8vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_END_DATE')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '15vw',
                  maxWidth: '15vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_TIME')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '8vw',
                  maxWidth: '8vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_ROUTE')}
              </ListSubheader>
            </div>
            {(futureRows || []).map(item => (
              <ListItem key={item.routeActivityRowId} button>
                <ListItemText
                  style={{
                    minWidth: '8vw',
                    maxWidth: '8vw',
                    overflow: 'hidden',
                  }}>
                  <div>{item.startDate ? format(item.startDate, 'yyyy-MM-dd') : ''}</div>
                </ListItemText>
                <ListItemText
                  style={{
                    minWidth: '8vw',
                    maxWidth: '8vw',
                    overflow: 'hidden',
                  }}>
                  <div>{item.endDate ? format(item.endDate, 'yyyy-MM-dd') : ''}</div>
                </ListItemText>
                <ListItemText
                  style={{
                    minWidth: '15vw',
                    maxWidth: '15vw',
                    overflow: 'hidden',
                  }}>
                  {!item.unplannedContainerRoute && <div>{`${item.startTimeHHMM} - ${item.endTimeHHMM} (${item.durationMinutes} min) `}</div>}
                </ListItemText>
                <ListItemText style={{ minWidth: '8vw', maxWidth: '8vw' }}>
                  <>
                    {!item.unplannedContainerRoute && (
                      <div
                        data-matomo-mask
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {item.color && (
                          <Box
                            style={{
                              backgroundColor: item.color,
                              height: '10px',
                              width: '10px',
                              minHeight: '10px',
                              minWidth: '10px',
                              marginRight: '5px',
                              borderRadius: '25px',
                              border: '1px solid #6e6e6e',
                              display: 'inline-block',
                            }}
                          />
                        )}
                        {item.name}
                      </div>
                    )}
                    {item.unplannedContainerRoute && (
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {'Oplanerad'}
                      </div>
                    )}
                  </>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick} color="secondary">
          {f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT')}
        </Button>
        <Button onClick={handleChangeUpTillNextClick} color="secondary" autoFocus={false}>
          {f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_UP_UNTIL_BUTTON_TEXT')}
        </Button>
        <Button onClick={handleReplaceAllClick} color="secondary" autoFocus={false}>
          {f('PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_REPLACE_ALL_BUTTON_TEXT')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertFutureChanges
