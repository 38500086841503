import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  CommonShiftInheritanceItem,
  ICommonShiftInheritanceUpdateItem,
  ICommonShiftInheritanceCreateItem,
  CommonShiftItem,
  CommonShiftListItem
} from 'api/tenilo.workschedule.api-client'
import { RoleEnum } from 'api/tillit.api-client'
import isEmpty from 'lodash/isEmpty'
import { useDistinctAreas } from 'utilities/use-user-areas'
import { useWorkScheduleApi } from 'api'
import Authentication from 'components/authentication'
import useAuthorization from 'utilities/use-authorization'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { rootUser } from 'utilities/use-user-areas'
import { getVisibleAreaData } from 'utilities/area-helper'


type workScheduleConnectionModalStateType = Readonly<{
  open: boolean,
  item?: CommonShiftInheritanceItem
}>

const useWorkScheduleConnectionContext = () => {
  const [nonInheritedCommonShifts, setNonInheritedCommonShifts] = useState<CommonShiftListItem[]>()
  const [inheritedCommonShifts, setInheritedCommonShifts] = useState<CommonShiftInheritanceItem[]>()
  const [commonShifts, setCommonShifts] = useState<CommonShiftItem[]>()
  const { abort,
    listInheritedCommonShifts,
    createCommonShiftInheritance,
    updateCommonShiftInheritance,
    deleteCommonShiftInheritance,
    listCommonShifts,
    listNonInheritedCommonShifts
  } = useWorkScheduleApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { getAdminAreas } = useAuthorization()
  const [workScheduleConnectionModalState, setWorkScheduleConnectionModalState] = React.useState<workScheduleConnectionModalStateType>({
    open:false
  })
  const openWorkScheduleModal = useCallback((shift? : CommonShiftInheritanceItem)=>()=>{
    setWorkScheduleConnectionModalState({
      open: true,
      item: shift
    })
  },[])
  const { distinctAreas } = useDistinctAreas()

  const closeWorkScheduleModal = useCallback(()=>{
    setWorkScheduleConnectionModalState({
      open: false,
    })
  },[])

  const fetchListCommonShifts = useCallback(() => {
    if (!currentUser.organizationId) {
      return
    }
    fetchAsync( listCommonShifts(currentUser.organizationId).then( shifts => {
      setCommonShifts(shifts)
    }))
  }, [currentUser, fetchAsync, listCommonShifts])

  const fetchListInheritedCommonShifts = useCallback(() => {
    if (!currentUser.organizationId) {
      return
    }
    fetchAsync( listInheritedCommonShifts(currentUser.organizationId).then( shifts => {
      if(shifts){
        if(!rootUser(currentUser)){
          const adminAreas = getAdminAreas([RoleEnum.Planner])
          shifts = shifts.filter(shift => adminAreas?.some(adminArea => adminArea.areaId === shift.areaId))
        }
        const visibleArea = getVisibleAreaData().map(area=>area.areaId)
        if(!isEmpty(visibleArea)){
          shifts = shifts.filter(shift => visibleArea.includes(shift.areaId))
        }
        setInheritedCommonShifts(shifts)
      }
    }))
  }, [currentUser, fetchAsync, listInheritedCommonShifts])

  const fetchNonInheritedCommonShiftsByAreaId = useCallback((areaId : number | null, selectedShift : CommonShiftInheritanceItem | undefined) => {
    if(selectedShift || !areaId){
      return
    }
    listNonInheritedCommonShifts(areaId).then(shifts => {
      setNonInheritedCommonShifts(shifts)
    })
  },[commonShifts])


  const availableAreas = useMemo(()=>{
    const visibleArea = getVisibleAreaData().map(area=>area.areaId)
    if(!rootUser(currentUser)){
      const adminAreas = getAdminAreas([RoleEnum.Planner])
      if(!isEmpty(visibleArea)){
        return distinctAreas(adminAreas).filter(area => visibleArea.includes(area.areaId))
      }
      return distinctAreas(adminAreas)
    }
    if(!isEmpty(visibleArea)){
      return currentUser.userAreas?.filter(area => visibleArea.includes(area.areaId))
    }
    return currentUser.userAreas
  },[currentUser])

  useEffect(() => () => abort(), [abort])

  useEffect(() => {
    void fetchListInheritedCommonShifts()
  }, [fetchListInheritedCommonShifts])

  useEffect(() => {
    void fetchListCommonShifts()
  }, [fetchListCommonShifts])


  const handleUpdateInheritedCommonShift = useCallback((shift : ICommonShiftInheritanceUpdateItem)=>fetchAsync(updateCommonShiftInheritance(shift)),[])

  const handleCreateInheritedCommonShift = useCallback((shift : ICommonShiftInheritanceCreateItem)=>fetchAsync(createCommonShiftInheritance(shift)),[])

  const handleRemoveInheritedCommonShift = useCallback((shiftId : number) => fetchAsync(deleteCommonShiftInheritance(shiftId)).then(fetchListInheritedCommonShifts),[])

  return {
    state: {
      ...stateOfFetch,
      availableAreas,
      inheritedCommonShifts,
      currentUser,
      workScheduleConnectionModalState,
      commonShifts,
      nonInheritedCommonShifts
    },
    actions: {
      openWorkScheduleModal,
      closeWorkScheduleModal,
      fetchListInheritedCommonShifts,
      handleUpdateInheritedCommonShift,
      handleCreateInheritedCommonShift,
      handleRemoveInheritedCommonShift,
      fetchNonInheritedCommonShiftsByAreaId,
    }
  }
}

export const WorkScheduleConnectionContext = createContext(useWorkScheduleConnectionContext)
