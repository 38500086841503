import { DataGrid, GridRowParams, GridCallbackDetails, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { AreaForUserListItem } from 'api/tillit.api-client'
import Page, { PageHeader } from 'components/page'
import { useFormatMessage } from 'localization'
import React from 'react'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterAreas } from 'utilities/area-helper'
import { NotificationContainer } from 'components/notification'
import { AreaModalContext } from 'components/area-modal/area-modal-context'
import { AreasPageContext } from './areas-page-context'

const AreasPageView: React.FC = () => {
  const { data } = AreasPageContext.useState()
  const { setAreaId } = AreaModalContext.useActions()
  const f = useFormatMessage()
  const getFilteredAreas = filterAreas(f)

  let areasForUser: AreaForUserListItem[] = []
  if (data) {
    areasForUser = data
  }

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      setAreaId(Number(params.row.id))
    },
    []
  )

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredAreas, setFilteredAreas] = React.useState<AreaForUserListItem[]>(areasForUser)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredAreas(filterValue, areasForUser)
      setFilteredAreas(filtered)
    } else {
      setFilteredAreas(areasForUser)
    }
    // NOTE: We are missing filterValue and getFilteredAreas as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [areasForUser])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredAreas(searchValue, areasForUser)
      setFilteredAreas(filtered)
    },
    [setFilterValue, setFilteredAreas, areasForUser, getFilteredAreas]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredAreas(areasForUser)
    },
    [setFilterValue, setFilteredAreas, areasForUser]
  )


  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <NotificationContainer>
      <Page>
        <PageHeader title={f('AREAS_PAGE_VIEW_HEAD_TITLE')} description={f('AREAS_PAGE_VIEW_HEAD_DESCRIPTION')} />

        <div style={{ columnCount: 2 }}>
          <div>&nbsp;</div>
          <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
            <FilledSearchTextField
              label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
              handleChangeFilter={handleChangeFilter}
              handleClearFilter={handleClearFilter}
              searchValue={filterValue}
            />
          </div>
        </div>

        <DataGrid
          disableColumnFilter
          sx={{
            minHeight: '70vh',
            maxHeight: '70vh',
            '.MuiDataGrid-cell': { borderBottom: '0px' }
          }}
          rows={filteredAreas}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          onRowClick={handleRowClick}

          localeText={muiDataGridLocale(f)()}

          columns={[
            {
              flex: 1,
              headerName: f('AREAS_PAGE_VIEW_COLUMN_NAME'),
              field: 'name',
            },
            {
              flex: 1,
              headerName: f('AREAS_PAGE_VIEW_COLUMN_ORGANIZATION_PARENT_NAME'),
              field: 'organizationParentsName',
            },

          ]}

          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[25, 50, 100]}
          pagination

        />

      </Page>
    </NotificationContainer>
  )
}

export default AreasPageView
