import Box, { BoxProps } from '@mui/material/Box'
import React from 'react'

const ModalSectionRowContainer: React.SFC<BoxProps & {responsive?:boolean,responsiveRow?:boolean,noSeparator?:boolean}> = ({ children, responsive, responsiveRow,noSeparator, ...rest }) => {
  const childrenCount = React.useMemo(() => React.Children.count(children), [children])
  const childrenWithBox = React.Children.map(children, (child, index) => (
    <>
      {child}
      {(!responsiveRow && !noSeparator && child && index !== childrenCount - 1) && <Box pl={2} />}
    </>
  ))
  return (
    <Box display={'flex'} flexDirection={'row'} {...rest} sx={(theme)=>({
      ...rest?.sx,
      [theme.breakpoints.down('md')]: responsive ? {
        display:'flex',
        flexDirection:'column',
        gap:0
      }: responsiveRow ? {
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        gap:'2px'
      } : {}
    })}>
      {childrenWithBox}
    </Box>
  )
}

export default ModalSectionRowContainer
