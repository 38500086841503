import { Box, Checkbox, Typography } from '@mui/material'
import  { Portal } from '@mui/base/Portal'
import {
  IKioskItem,
  IRouteKioskTimeBasedPlanningItem,
  ITimeBasedRouteContainerItem,
  KioskAreaItem,
  TimeBasedRouteContainerItem,
  TimeBasedRouteItem,
} from 'api/tillit.api-client'
import ContainedButton from 'components/inputs/contained-button'
import MuiErrorMessage from 'components/inputs/mui-error-message'
import OutlinedButton from 'components/inputs/outlined-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import { Field, FieldArray, FieldProps, Form, Formik, FormikHelpers, FormikHandlers } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import styled, { css } from 'styled-components'
import format from 'utilities/format'
import { AddCombinedRouteModal } from './component/add-combined-route-modal'

const StyledForm = styled(Form)(
  _ => css`
    flex: 1;
    display: flex;
    flex-direction: column;
  `
)

export const ManageCombinedRoutesModalContainer: React.SFC = () => {
  const f = useFormatMessage()
  const { selectedDevice } = PlanningPageContext.useState()
  const {
    manageCombinedRoutes: { saveCombinedRoutes, setAddModalOpen, setCombinedRouteModalOpen },
    updateDevicePlanningView,
  } = PlanningPageContext.useActions()
  const container = React.useRef(null)

  if (!selectedDevice) {
    throw Error('No device selected. A device needs to be selected to open this manager modal.')
  }

  const { getTimeBasedRouteContainerItemActives, getKioskAreaItemActives } = useHelper(selectedDevice)
  const containers = getTimeBasedRouteContainerItemActives()

  const { id: kioskId, kioskName, kioskAreas = [], nightFromHour, nightFromMinute, nightToHour, nightToMinute } = selectedDevice

  const handleAddCombinedRouteClick = () => {
    setAddModalOpen(true)
  }

  const fromHourMinutes = new Date()
  fromHourMinutes.setHours(nightFromHour!, nightFromMinute!)

  const toHourMinutes = new Date()
  toHourMinutes.setHours(nightToHour!, nightToMinute!)

  const handleValidate = React.useCallback(
    (values: { timeBasedRouteContainers: TimeBasedRouteContainerItemActive[] }) => {
      const mainAreaRoutes = selectedDevice.kioskAreas
        ?.find(a => a.kioskMainArea)
        ?.area_Routes?.filter(r => !r.name || r.name.indexOf('_UnplannedContainer') < 0)

      const timeBasedRoutes = values.timeBasedRouteContainers
        .flatMap(x => x.areas)
        .flatMap(a => a.area_Routes)
        .filter(r => r.active)

      const isMissingAMainAreaRoute = (mainAreaRoutes || []).some(r => !timeBasedRoutes.some(tbr => tbr.id === r.id))

      if (isMissingAMainAreaRoute) {
        return {
          timeBasedRouteContainers: f('PLANNING_PAGE_DEVICE_PLANNING_MANAGE_COMBINED_ROUTE_MAIN_AREA_MISSING'),
        }
      }

      return {}
    },
    [f, selectedDevice]
  )

  const handleSubmit = React.useCallback(
    (
      values: {
        timeBasedRouteContainers: TimeBasedRouteContainerItemActive[]
      },
      formikHelpers: FormikHelpers<{
        timeBasedRouteContainers: TimeBasedRouteContainerItemActive[]
      }>
    ) => {
      try {
        const { setSubmitting } = formikHelpers
        const updatedTimeBasedRouteContainers: TimeBasedRouteContainerItem[] = values.timeBasedRouteContainers.map(timeBasedRouteContainer => {
          const { areas, id: timeBasedRouteContainerId, ...restTimeBasedRouteContainer } = timeBasedRouteContainer

          const timeBasedRoutes = areas.reduce<TimeBasedRouteItem[]>((prevAreas, area) => {
            const kioskArea = kioskAreas.find(a => a.id === area.id)
            if (!kioskArea) {
              throw Error(`Cant find kiosk area for area with id: ${area.id}`)
            }
            const { id: areaId, area_Routes } = area

            // TODO: Remove extra routes and extra time based route item when routes ending with '_UnplannedContainer' are
            // filtered and not required in backend
            const s = area_Routes.some(r => r.active)
            const extraRoutes = (!!s && !!kioskArea.area_Routes ? kioskArea.area_Routes : []).filter(r => r.name && r.name.endsWith('_UnplannedContainer'))
            const extraTimeBasedRouteItem = extraRoutes.map(
              e =>
                new TimeBasedRouteItem({
                  id: e.id,
                  timeBasedRouteContainerId,
                  routeId: e.id,
                  area_Id: area.id,
                })
            )

            const routes = area_Routes.reduce<TimeBasedRouteItem[]>((prevRoutes, route) => {
              const { id: routeId, timeBasedRouteId, active: routeActive } = route
              if (!routeActive) {
                return prevRoutes
              }
              const item = new TimeBasedRouteItem({
                id: timeBasedRouteId,
                timeBasedRouteContainerId,
                routeId,
                area_Id: areaId,
              })
              return [...prevRoutes, item]
            }, [])
            return [...prevAreas, ...routes, ...extraTimeBasedRouteItem]
          }, [])

          const timeBasedRouteContainerItem = new TimeBasedRouteContainerItem({
            id: timeBasedRouteContainerId,
            ...restTimeBasedRouteContainer,
            timeBasedRoutes,
          })

          return timeBasedRouteContainerItem
        })

        saveCombinedRoutes(kioskId, updatedTimeBasedRouteContainers)
          .then(_ => setCombinedRouteModalOpen(false))
          .then(_ => updateDevicePlanningView())
          .finally(() => setSubmitting(false))

      } catch (error) {
        console.log(error)
      }
    },
    [kioskId, kioskAreas, saveCombinedRoutes, setCombinedRouteModalOpen, updateDevicePlanningView]
  )

  const handleCloseModalClick = React.useCallback(() => setCombinedRouteModalOpen(false), [setCombinedRouteModalOpen])

  return (
    <>
      <div ref={container} />
      <Formik initialValues={{ timeBasedRouteContainers: containers }} validate={handleValidate} onSubmit={handleSubmit}>
        {({ values, dirty, isSubmitting, handleChange, initialValues, resetForm, setFieldValue }) => (
          <StyledForm translate="yes">
            <ModalHeader title={kioskName} subtitle={`${format(fromHourMinutes, 'HH:mm')}  - ${format(toHourMinutes, 'HH:mm')}`} />
            <Box mx={5} display="flex" flexDirection="row" overflow="auto">
              <FieldArray name={'timeBasedRouteContainers'}>
                {containersHelpers => {
                  const { timeBasedRouteContainers } = values
                  return (
                    <>
                      {timeBasedRouteContainers
                        .sort((a, b) => (a.sortingIndex || 0) - (b.sortingIndex || 0))
                        .map((timeBasedRouteContainer, timeBasedRouteContainerIndex) => {
                          const { areas, name: containerName, color, sortingIndex } = timeBasedRouteContainer
                          return (
                            <StyledCombinedRoute name={containerName} color={color} key={sortingIndex}>
                              <FieldArray name={`timeBasedRouteContainers.${timeBasedRouteContainerIndex}.areas`}>
                                {areasHelpers =>
                                  areas.map((area, areaIndex) => {
                                    const handleReplace = (item: KioskAreaItemActive) => areasHelpers.replace(areaIndex, item)
                                    const handleRouteUpdate = updateAllRoutes(timeBasedRouteContainer, values.timeBasedRouteContainers, setFieldValue)(area)
                                    return (
                                      <StyledArea area={area} onReplace={handleReplace} onRouteUpdated={handleRouteUpdate} key={area.areaId}>
                                        {({ routes }) => {
                                          return routes.map((route, routeIndex) => {
                                            const routeFieldName = `timeBasedRouteContainers.${timeBasedRouteContainerIndex}.areas.${areaIndex}.area_Routes.${routeIndex}.active`
                                            const handleChangeCheckbox = (e: React.ChangeEvent<any>) => {
                                              const enabled = e.target.checked
                                              if (enabled) {
                                                handleRouteUpdate(route)
                                              }

                                              handleChange(e)
                                            }

                                            return <StyledRouteField key={route.id} route={route} onChange={handleChangeCheckbox} name={routeFieldName} />
                                          })
                                        }}
                                      </StyledArea>
                                    )
                                  })
                                }
                              </FieldArray>
                            </StyledCombinedRoute>
                          )
                        })}
                      <OutlinedButton style={{ alignSelf: 'flex-start' }} color="secondary" onClick={handleAddCombinedRouteClick}>
                        {f('PLANNING_PAGE_DEVICE_PLANNING_MANAGE_COMBINED_ROUTE_ADD_ROUTE_BUTTON_TEXT')}
                      </OutlinedButton>
                      <Portal container={container.current}>
                        <AddCombinedRouteModal
                          // tslint:disable-next-line: jsx-no-lambda
                          onSaveClick={(name, color) => {
                            const areas = getKioskAreaItemActives()
                            const item: TimeBasedRouteContainerItemActive = {
                              id: -1,
                              areas,
                              name,
                              color,
                              sortingIndex: timeBasedRouteContainers.length,
                              kioskId,
                              timeBasedRoutes: [],
                            }
                            containersHelpers.push(item)
                          }}
                        />
                      </Portal>
                    </>
                  )
                }}
              </FieldArray>
            </Box>
            <Box mx={5} display="flex" justifyContent="flex-end">
              <MuiErrorMessage name="timeBasedRouteContainers" />
            </Box>
            <ModalFooter>
              <TextButton onClick={handleCloseModalClick}>{f('BASIC_BUTTON_CLOSE_TEXT')}</TextButton>
              <ContainedButton type="submit" color="secondary" showProgress={isSubmitting} disabled={!dirty}>
                {f('BASIC_BUTTON_SAVE_TEXT')}
              </ContainedButton>
            </ModalFooter>
          </StyledForm>
        )}
      </Formik>
    </>
  )
}

type RouteKioskTimeBasedPlanningItemActive = IRouteKioskTimeBasedPlanningItem & {
  active: boolean
  timeBasedRouteId: number
}
type KioskAreaItemActive = Omit<KioskAreaItem, 'area_Routes'> & {
  area_Routes: RouteKioskTimeBasedPlanningItemActive[]
}

type TimeBasedRouteContainerItemActive = ITimeBasedRouteContainerItem & {
  areas: KioskAreaItemActive[]
}

const useHelper = (initialValue: IKioskItem) => {
  const { timeBasedRouteContainers = [], kioskAreas = [] } = initialValue

  const getKioskAreaItemActives = React.useCallback(
    (timeBasedRoutes: TimeBasedRouteItem[] = []) =>
      kioskAreas
        .map<KioskAreaItemActive>(kioskArea => {
          const clone = JSON.parse(JSON.stringify(kioskArea)) as KioskAreaItemActive
          const { area_Routes = [] } = clone

          const routes = area_Routes
            .filter(route => !(route.name && route.name.endsWith('_UnplannedContainer')))
            .map(route => {
              const timeBasedRoute = timeBasedRoutes.find(t => t.routeId === route.id)

              return {
                ...route,
                active: !!timeBasedRoute,
                timeBasedRouteId: !!timeBasedRoute ? timeBasedRoute.id : -1,
              }
            })

          return { ...clone, area_Routes: routes }
        })
        .filter(kioskArea => kioskArea.area_Routes.length > 0),
    [kioskAreas]
  )

  const getTimeBasedRouteContainerItemActives = React.useCallback((): TimeBasedRouteContainerItemActive[] => {
    const containers: TimeBasedRouteContainerItemActive[] = []
    for (const timeBasedRouteContainer of timeBasedRouteContainers) {
      const { timeBasedRoutes = [] } = timeBasedRouteContainer
      const areas = getKioskAreaItemActives(timeBasedRoutes)
      const container: TimeBasedRouteContainerItemActive = {
        ...timeBasedRouteContainer,
        areas,
      }

      containers.push(container)
    }

    return containers
  }, [getKioskAreaItemActives, timeBasedRouteContainers])

  return { getTimeBasedRouteContainerItemActives, getKioskAreaItemActives }
}

type CombinedRouteProps = Readonly<{
  name: string
  color?: string
  className?: string
}>
const CombinedRoute: React.FC<CombinedRouteProps> = ({ name, children, className }) => {
  return (
    <div className={className}>
      <div className={'border'} />
      <div className={'container'}>
        <Typography variant="subtitle1">{name}</Typography>
        {children}
      </div>
    </div>
  )
}

const StyledCombinedRoute = styled(CombinedRoute)(
  ({ color }) => css`
    display: flex;
    flex-direction: row;
    min-height: 584px;
    min-width: 214px;
    background-color: #fafafa;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 20px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #f2f2f2;
    }

    .border {
      width: 4px;
      background-color: ${color};
      border-radius: 4px;
    }

    .container {
      padding: 20px;
    }
  `
)

const Area: React.FC<{
  onReplace: (item: KioskAreaItemActive) => void
  onRouteUpdated: (route: RouteKioskTimeBasedPlanningItemActive) => void
  area: KioskAreaItemActive
  children: ({ routes }: { routes: RouteKioskTimeBasedPlanningItemActive[] }) => React.ReactNode
  className?: string
}> = ({ className, children, area, onReplace, onRouteUpdated }) => {
  const { area_Routes = [], area_Name } = area

  const handleAreaChecked = (event: any) => {
    const { checked } = event.target
    const updatedArea: KioskAreaItemActive = {
      ...area,
      area_Routes: area_Routes.map(r => ({
        ...r,
        active: checked,
      })),
    }
    if (checked) {
      area_Routes.forEach(route => {
        onRouteUpdated(route)
      })
    }

    onReplace(updatedArea)
  }

  const allRoutesAreChecked = !area_Routes.some(r => !r.active)
  return (
    <div className={className}>
      <div className="header">
        <Checkbox checked={allRoutesAreChecked} onChange={handleAreaChecked} color="secondary" />
        <Typography variant="subtitle1">{area_Name || 'No name'}</Typography>
      </div>
      <div className="routes-container">{children({ routes: area_Routes })}</div>
    </div>
  )
}

const updateAllRoutes = (
  currentTimeBasedRouteContainer: TimeBasedRouteContainerItemActive,
  timeBasedRouteContainers: TimeBasedRouteContainerItemActive[],
  setFieldValue: any //SK: Can we make this type secure? Based on how field value is set below. (field: "timeBasedRouteContainers", value: any, shouldValidate?: boolean | undefined) => void
) => (currentArea: KioskAreaItemActive) => (currentRoute: RouteKioskTimeBasedPlanningItemActive) => {
  timeBasedRouteContainers.forEach((routeContainer, routeContainerIndex) =>
    routeContainer.areas.forEach((area, areaIndex) =>
      area.area_Routes.forEach((route, routeIndex) => {
        const isCurrentRoute = currentTimeBasedRouteContainer.id === routeContainer.id && currentArea.id === area.id && route.id === currentRoute.id
        if (!isCurrentRoute && route.id === currentRoute.id && route.active) {
          setFieldValue(`timeBasedRouteContainers.${routeContainerIndex}.areas.${areaIndex}.area_Routes.${routeIndex}.active`, false)
        }
      })
    )
  )
}

const StyledArea = styled(Area)(
  () => css`
    margin: 12px 0;
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0%;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .routes-container {
      margin-left: 24px;
    }
  `
)

const RouteField: React.FC<{
  route: RouteKioskTimeBasedPlanningItemActive
  name: string
  className?: string
  onChange: FormikHandlers['handleChange']
}> = ({ className, name, route, onChange }) => {
  const { active: routeActive } = route

  return (
    <div className={className}>
      <Field name={name} checked={routeActive} color="secondary">
        {({ field }: FieldProps) => <Checkbox {...field} value={routeActive ? 'checked' : ''} onChange={onChange} checked={routeActive} />}
      </Field>
      <Typography variant="subtitle1">{route.name || 'No name'}</Typography>
    </div>
  )
}

const StyledRouteField = styled(RouteField)(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 12px 0;
    &:first {
      margin-top: 0;
    }

    &:last {
      margin-bottom: 0%;
    }
  `
)
