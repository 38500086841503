import React from 'react'
import { useDelegationApi } from 'api'
import { DelegationItem, DelegationCreateItem, DelegationUpdateItem, DelegationCategoryItem, DelegationCategoryListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { AdministerOrganizationDelegationsContext } from '../../administer-organization-delegations-context'

const useDelegationModalContext = () => {
  const { getDelegation, updateDelegation, createDelegation, getDelegationCategory, listDelegationCategories } = useDelegationApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [ownerOrganizationId, setOwnerOrganizationId] = React.useState<number | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedDelegation, setSelectedDelegation] = React.useState<DelegationItem | undefined>()

  const { user: currentUser } = Authentication.useAuthenticatedState()

  const { fetchDelegationsAsync } = AdministerOrganizationDelegationsContext.useActions()

  const [selectedDelegationCategory, setSelectedDelegationCategory] = React.useState<DelegationCategoryItem | undefined>()

  const [delegationCategoryItems, setDelegationCategoryItems] = React.useState<DelegationCategoryListItem[]>([])

  const fetchDelegationCategoriesAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listDelegationCategories(currentUser.organizationId)).then(setDelegationCategoryItems)
  }, [currentUser.organizationId, fetchAsync, listDelegationCategories])


  const fetchNewSelectedDelegationCategoryAsync = React.useCallback(
    async (delegationCategoryId: number | undefined) => {
      if (!delegationCategoryId) {
        setSelectedDelegationCategory(undefined)
        return
      }

      await fetchAsync(getDelegationCategory(delegationCategoryId)).then(delegationCategory => {
        if (delegationCategory instanceof DelegationCategoryItem) {
          setSelectedDelegationCategory(delegationCategory)
        }
      })
    },
    [fetchAsync, getDelegation, setSelectedDelegation]
  )

  const fetchDelegationAsync = React.useCallback(
    async (delegationId: number) => {
      await fetchAsync(getDelegation(delegationId)).then(ita => {
        if (ita instanceof DelegationItem) {
          setSelectedDelegation(ita)
          void fetchNewSelectedDelegationCategoryAsync(ita.delegationCategoryId)
        }
      })
    },
    [fetchAsync, getDelegation, fetchNewSelectedDelegationCategoryAsync]
  )

  const openDelegationModal = React.useCallback(
    (delegationId?: number) => {

      void fetchDelegationCategoriesAsync().then(() => {
        if (!!delegationId) {
          void fetchDelegationAsync(delegationId).then(() => setModalOpen(true))
        } else {
          setModalOpen(true)
        }
      })
    },
    [fetchDelegationAsync, fetchDelegationCategoriesAsync]
  )

  const closeDelegationModal = React.useCallback(() => {
    setSelectedDelegation(undefined)
    setModalOpen(false)
  }, [])

  const createDelegationAsync = React.useCallback(
    (createDelegationModel: DelegationCreateItem) => {
      return fetchAsync(
        createDelegation({
          ...createDelegationModel,
        })
      ).then(_ => {
        void fetchDelegationsAsync()
        closeDelegationModal()
      })
    },
    [fetchAsync, createDelegation, closeDelegationModal, fetchDelegationsAsync]
  )

  const updateDelegationAsync = React.useCallback(
    (delegation: DelegationUpdateItem) => {
      return fetchAsync(updateDelegation(delegation))
        .then(_ => {
          void fetchDelegationsAsync()
          closeDelegationModal()
        })
        .catch(() => {
          closeDelegationModal()
        })
    },
    [closeDelegationModal, fetchAsync, fetchDelegationsAsync, updateDelegation]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedDelegation,
      ownerOrganizationId,
      selectedDelegationCategory,
      delegationCategoryItems,
    },
    actions: {
      openDelegationModal,
      closeDelegationModal,
      createDelegationAsync,
      updateDelegationAsync,
      setOwnerOrganizationId,
      setSelectedDelegation,
      setSelectedDelegationCategory,
      fetchNewSelectedDelegationCategoryAsync,
    },
  }
}

export const DelegationModalContext = createContext(useDelegationModalContext)
