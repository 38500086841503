import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Authentication from 'components/authentication'
import PageRoute from 'config/page-routes'

const PublicRouteContainer: React.FunctionComponent = () => {
  const { isLoggedIn } = Authentication.useActions()
  const location = useLocation()

  const state = location.state as { from?: Location }

  return (
    isLoggedIn() && location.pathname === PageRoute.Login.path
    ?
    state?.from
    ?
      <Navigate to={state.from.pathname} replace />
    :
      <Navigate to={PageRoute.Dashboard.path} />
    :
      <Outlet />
  )

}

export default PublicRouteContainer
