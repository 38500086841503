import { useKioskApi } from 'api'
import { setDeviceGuid } from 'api/device-guid'
import { IKioskRequestItem } from 'api/tillit.api-client'
import { AdministerDevicesContext } from 'pages/administer/components/administer-devices'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useActivateDeviceDialogContext = () => {
  const { requestKioskActivation } = useKioskApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const {
    fetchDeviceGuidFromLocalStorageAsync,
    fetchActivatedDevicesListAsync,
  } = AdministerDevicesContext.useActions()

  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [activateThisDevice, setActivateThisDevice] = React.useState(true)

  const handleOpenDialog = React.useCallback(() => {
    setActivateThisDevice(true)
    setDialogOpen(true)
  }, [])
  const handleCloseDialog = React.useCallback(() => setDialogOpen(false), [])

  const handleOpenDialogForNewDevice = React.useCallback(() => {
    setActivateThisDevice(false)
    setDialogOpen(true)
  }, [])
  const handleCloseDialogForNewDevice = React.useCallback(() => setDialogOpen(false), [])


  const requestActivation = React.useCallback(
    async (kioskRequestItem: IKioskRequestItem) => {
      const deviceGuid = await fetchAsync(
        requestKioskActivation(kioskRequestItem)
      )
      if (activateThisDevice) {
        setDeviceGuid(deviceGuid)
        fetchDeviceGuidFromLocalStorageAsync()
      }
      fetchActivatedDevicesListAsync()
      return deviceGuid
    },
    [
      fetchAsync,
      requestKioskActivation,
      fetchDeviceGuidFromLocalStorageAsync,
      fetchActivatedDevicesListAsync,
      activateThisDevice,
    ]
  )

  return {
    state: { dialogOpen, activateThisDevice, ...stateOfFetch },
    actions: { handleOpenDialog, handleCloseDialog, requestActivation, handleOpenDialogForNewDevice, handleCloseDialogForNewDevice },
  }
}

export const ActivateDeviceDialogContext = createContext(
  useActivateDeviceDialogContext
)
