import { PageHeader } from 'components/page'
import { useFormatMessage } from 'localization'
import React from 'react'
import OrganizationTree from './components/organization-tree'
import { OrganizationContext } from './organization-context'

type Props = Readonly<{ className?: string }>

const OrganizationContainer: React.FC<Props> = ({ className }) => {
  const f = useFormatMessage()
  const { organization } = OrganizationContext.useState()
  const title = organization ? organization.name : '\u00A0'
  return (
    <div className={className}>
      <PageHeader
        overline={f('ORGANIZATION_PAGE_CONTAINER_HEADER_OVERLINE')}
        title={title}
        description={f('ORGANIZATION_PAGE_CONTAINER_HEADER_SUBTITLE')}
      />
      <OrganizationTree />
    </div>
  )
}

export default OrganizationContainer
