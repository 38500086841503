import React from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import Spinner from 'components/spinner'
import Authentication from 'components/authentication'
import { useFormatMessage } from 'localization'
import { SupportGrandIdModalContext } from './components/support-grandid-modal/support-grandid-modal-context'
import { SupportSystemContext } from './support-system-context'

const SupportSystemView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { isLoading } = SupportSystemContext.useState()

  const { openSupportGrandIdModal } = SupportGrandIdModalContext.useActions()

  const f = useFormatMessage()

  const handleSupportGrandIdClick = React.useCallback(() => {
    openSupportGrandIdModal()
  }, [])

  const theme = useTheme()

  return (
    <div style={{ padding: '2vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box><Button variant='contained' onClick={handleSupportGrandIdClick}>Hantera GrandId</Button></Box>
            </Box>
          )}


        </>
      )}




    </div>
  )
}

export default SupportSystemView
