import { STORAGEPREFIX } from 'config/variables'
import { IJwtTokenGenerationResultItem } from './tillit.api-client'

export const TOKEN_DATA_KEY =
  (STORAGEPREFIX ? STORAGEPREFIX : '') + 'token_data'
export const OLD_TOKEN_DATA_KEY =
  (STORAGEPREFIX ? STORAGEPREFIX : '') + 'old_token_data'
export const SUPPORT_TOKEN_DATA_KEY =
  (STORAGEPREFIX ? STORAGEPREFIX : '') + 'support_token_data'

export const hasTokenData = (): boolean =>
  localStorage.getItem(TOKEN_DATA_KEY) !== null

export const getTokenData = (): IJwtTokenGenerationResultItem | null => {
  const item = localStorage.getItem(TOKEN_DATA_KEY)
  return item ? JSON.parse(item) : null
}

export const getOldTokenData = (): IJwtTokenGenerationResultItem | null => {
  const item = localStorage.getItem(OLD_TOKEN_DATA_KEY)
  return item ? JSON.parse(item) : null
}

export const setTokenData = (tokenData: IJwtTokenGenerationResultItem) =>
  localStorage.setItem(TOKEN_DATA_KEY, JSON.stringify(tokenData))

export const setOldTokenData = (tokenData: IJwtTokenGenerationResultItem) =>
  localStorage.setItem(OLD_TOKEN_DATA_KEY, JSON.stringify(tokenData))

export const clearTokenData = () => {
  localStorage.removeItem(OLD_TOKEN_DATA_KEY)
  return localStorage.removeItem(TOKEN_DATA_KEY)
}

export const setSupportTokenData = (tokenData: IJwtTokenGenerationResultItem) =>
  localStorage.setItem(SUPPORT_TOKEN_DATA_KEY, JSON.stringify(tokenData))

export const clearSupportTokenData = () =>
  localStorage.removeItem(SUPPORT_TOKEN_DATA_KEY)

export const hasSupportTokenData = (): boolean =>
  localStorage.getItem(SUPPORT_TOKEN_DATA_KEY) !== null

  export const getSupportTokenData = (): IJwtTokenGenerationResultItem | null => {
    const item = localStorage.getItem(SUPPORT_TOKEN_DATA_KEY)
    return item ? JSON.parse(item) : null
  }

  export const revertSupportTokenData = () => {
    const supportTokenData = getSupportTokenData()
    if (supportTokenData) {
      setTokenData(supportTokenData)
      clearSupportTokenData()
    } else {
      clearTokenData()
    }
  }


