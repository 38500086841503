import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { RoleEnum, UserTypeEnum } from 'api/tillit.api-client'
import { useField } from 'formik'
import useUserAreas from 'utilities/use-user-areas'
import { Box } from '@mui/material'
import { Authorize } from 'components/authorization'
import { isEmpty } from 'lodash'
import type { InnerContainerPropType } from 'pages/reports/config'
import ReportsPageContext from 'pages/reports/reports-page-context'
import Authentication from 'components/authentication'
import { ReportSelectList } from '../reports-page-inputs'
import TransFormToOrganizationReportView from './components/transform-to-organization-report'

const ReportsPageAvailabilitiesView: FC<InnerContainerPropType> = ({ }) => {
  const f = useFormatMessage()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { userReport } = ReportsPageContext.useState()
  const userAreas = useUserAreas([RoleEnum.Admin, RoleEnum.Planner])
  const [field, meta, helpers] = useField({ name: 'availabilities' })

  const [inTransformation, setInTransformation] = React.useState<boolean>(false);

  const initialValues = React.useMemo(() => {
    if (userReport && userReport.userReportAvailabilities && !isEmpty(userReport.userReportAvailabilities)) {
      return userAreas.filter(area => {
        return userReport.userReportAvailabilities.some(ua => ua.ownerAreaId === area.areaId || ua.ownerOrganizationId === area.areaId)
      })
    }
    return undefined

  }, [userReport])

  React.useEffect(() => {
    if (initialValues) {
      helpers.setValue(initialValues)
    } else {
      helpers.setValue(undefined)
    }
  }, [initialValues])

  const onTransformClick = React.useCallback(() => {
    setInTransformation(true)
  }, [])

  const onAbortTransformClick = React.useCallback(() => {
    setInTransformation(false)
  }, [])

  return React.useMemo(() => {
    return (
      <Box sx={{
        width: '50%',
        margin: '0px 0px 10px 0',
        padding: '10px 0 0 0'
      }}>
        {!inTransformation && (
          <>
            <ReportSelectList
              multiple
              options={userAreas}
              lableKey="areaName"
              textFieldLable={f('REPORT_PAGE_VIEW_SECTION_AVAILABILITIES')}
              name="availabilities"
              includedInForm
            />
          </>
        )}
        <Authorize mainRoles={[UserTypeEnum.OrganizationAdmin, UserTypeEnum.TeniloSupport]}>
          {userReport?.createdById === currentUser.id && (
            <TransFormToOrganizationReportView onTransformClick={onTransformClick} onAbortTransformClick={onAbortTransformClick}></TransFormToOrganizationReportView>
          )}
        </Authorize>
      </Box>
    )

  }, [field.value, userAreas, currentUser, onAbortTransformClick, onTransformClick, userReport])
}

export default ReportsPageAvailabilitiesView
