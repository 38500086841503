import React from 'react'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'
import supportPageContext from 'pages/support/support-page-context'

const useSupportSystemContext = () => {
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  const location = useLocation()

  // When this component unmounts, abort all ongoing calls
  //React.useEffect(() => () => abort(), [abort])

  return {
    state: {
      ...stateOfFetch,
    },
    actions: {
    },
  }
}

export const SupportSystemContext = createContext(useSupportSystemContext)
