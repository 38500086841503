import React from 'react'
import {
  Box,
  useTheme,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { CloseCircle } from 'mdi-material-ui'
import CenterModal from 'components/center-modal'
import BigCheckbox from 'components/inputs/big-checkbox'
import ColorPicker from 'components/inputs/color-picker'
import ColorPickerStyle, { ColorPickerCircleColor } from 'components/inputs/color-picker/color-picker-style'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { DeleteDialog } from 'components/delete-dialog'
import { UserAreaItem, TemplateActivityCategoryListItem, TemplateActivityTypeEnum, TemplateActivityDelegationItem, UnitListItem, SeverityLevelEnum, RoleEnum } from 'api/tillit.api-client'
import { useDistinctAreas } from 'utilities/use-user-areas'
import Authentication from 'components/authentication'
import SelectInput from 'components/inputs/select-input'
import { UserTypeEnum } from 'api/tillit.api-client'
import ModalContent from 'components/modal-content'
import { TemplateActivityModalContext } from './template-activity-modal-context'
import useFormData from './utilities/use-template-activity-form-data'

export type TemplateActivityModalProps = Readonly<{
  propsTitle?: string
  propsSubtitle?: string
  ownerAreaId?: number
  activeAreaId?: number
  onSave?: () => void
}>

export type SelectableTemplateActivityDelegationItem = {
  delegationId: number
  delegationTitle?: string | undefined
}

const TemplateActivityModalContainer: React.FC<TemplateActivityModalProps> = ({ propsTitle, ownerAreaId, onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()
  const { distinctAreas } = useDistinctAreas()
  const { modalOpen, selectedTemplateActivity, isLoading, templateActivityCategories, delegationItems, confirmMoveDialogOpen, units } = TemplateActivityModalContext.useState()

  const { setOwnerAreaId, moveToOrganization, closeConfirmMoveDialog, openConfirmMoveDialog } = TemplateActivityModalContext.useActions()

  React.useEffect(() => {
    setOwnerAreaId(ownerAreaId)
  }, [ownerAreaId, setOwnerAreaId])

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedTemplateActivity)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeTemplateActivityModal } = TemplateActivityModalContext.useActions()

  const getOwnerAreaName = (item: any) => {
    const ua = item as UserAreaItem

    if (ua.organizationParentsName) {
      return `${ua.areaName} (${ua.organizationParentsName})`
    }
    return ua.areaName || ''
  }

  const getCategoryTitle = (item: any) => {
    const tac = item as TemplateActivityCategoryListItem
    return tac?.title ? tac.title : ""
  }

  const getUnitTitle = (item: any) => {
    const u = item as UnitListItem
    return u?.title ? u.title : ""
  }

  const getDelegationTitle = (item: any) => {
    const tad = item as TemplateActivityDelegationItem
    return tad?.delegationTitle ? tad.delegationTitle : ""
  }

  const { user: currentUser } = Authentication.useAuthenticatedState()
  const userIsSysAdminOrOrgAdminOrTeniloSupport = currentUser.userType === UserTypeEnum.OrganizationAdmin || currentUser.userType === UserTypeEnum.TeniloSupport || currentUser.userType === UserTypeEnum.SystemAdmin

  const handleConfirmMoveTemplateActivityClick = React.useCallback(() => {
    moveToOrganization()
  }, [moveToOrganization])

  return (
    <CenterModal open={modalOpen} onClose={closeTemplateActivityModal} fullSize>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {

          const isOrganizationTemplateAndUserIsAreaAdmin = !!values.ownerOrganizationId && !userIsSysAdminOrOrgAdminOrTeniloSupport
          const isSaved = !!values.id

          const handleChangeOwnerArea = (_: React.ChangeEvent<{}>, value: any) => {
            const uaV = value as UserAreaItem
            if (uaV) {
              setFieldValue('ownerAreaId', uaV.areaId)
            } else {
              setFieldValue('ownerAreaId', undefined)
            }
          }

          const handleChangeCategory = (_: React.ChangeEvent<{}>, value: any) => {
            const tacV = value as TemplateActivityCategoryListItem
            if (tacV) {
              setFieldValue('templateActivityCategoryId', tacV.id)
            } else {
              setFieldValue('templateActivityCategoryId', undefined)
            }
          }

          const handleChangeUnit = (_: React.ChangeEvent<{}>, value: any) => {
            const uV = value as UnitListItem
            if (uV) {
              setFieldValue('unitId', uV.id)
            } else {
              setFieldValue('unitId', undefined)
            }
          }

          const handleRemoveTemplateActivityDelegation = (delegationId: number) => () => {
            const templateActivityDelegations = values.templateActivityDelegations?.filter(d => d.delegationId !== delegationId)
            setFieldValue('templateActivityDelegations', templateActivityDelegations)
          }

          const handleMoveToOrganizationClick = () => {
            if (values.id) {
              openConfirmMoveDialog(values.id)
            }
          }

          return (
            <Form translate="yes" style={{display:'contents'}}>
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={
                  propsTitle
                    ? propsTitle
                    : selectedTemplateActivity
                      ? f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_EDIT_TITLE')
                      : f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TITLE')
                }
              />
              <ModalContent style={{padding:'20px'}}>
                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_TITLE_TEXTFIELD_LABEL')}
                          name={name.title}
                          color="secondary"
                          fullWidth
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_CUSTOMER')}`}
                          name={name.templateActivityTypeId}
                          value={TemplateActivityTypeEnum.CustomerTemplate}
                          checkedByValue
                          returnValue="value"
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin || isSaved}
                        />
                        <BigCheckbox
                          label={`${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_AREA')}`}
                          name={name.templateActivityTypeId}
                          value={TemplateActivityTypeEnum.AreaTemplate}
                          checkedByValue
                          returnValue="value"
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin || isSaved}
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  {values.templateActivityTypeId === TemplateActivityTypeEnum.AreaTemplate && (
                    <ModalSection
                      title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_USEPARTICIPANTLIST_INFOTEXT_TITLE')}
                      subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_USEPARTICIPANTLIST_INFOTEXT_SUBTITLE')}>
                      <ModalSectionRow>
                        <>
                          <BigCheckbox
                            label={`${f('BASIC_TEXT_YES')}`}
                            name={name.useParticipantList}
                            value="true"
                            checkedByValue
                            returnValue="value"
                            disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                          />
                          <BigCheckbox
                            label={`${f('BASIC_TEXT_NO')}`}
                            name={name.useParticipantList}
                            value="false"
                            checkedByValue
                            returnValue="value"
                            disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                          />
                        </>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  {!values.ownerOrganizationId && (
                    <ModalSection
                      title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_TITLE')}
                      subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_SUBTITLE')}>
                      <ModalSectionRow>
                        <Box width="50%">
                          <Box flex={1}>
                            <SelectInput
                              label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_AREA_COMPONENT_LABEL_DEFAULT')}
                              onChange={handleChangeOwnerArea}
                              name={name.ownerArea}
                              options={distinctAreas(currentUser.userAreas, [RoleEnum.Admin]) as any}
                              getOptionLabel={getOwnerAreaName}
                              fullWidth={true}
                              error={!!errors.ownerArea}
                              helperText={errors.ownerArea}
                            />
                          </Box>
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CATEGORY_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CATEGORY_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Box flex={1}>
                          <SelectInput
                            label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_CATEGORY_COMPONENT_LABEL_DEFAULT')}
                            onChange={handleChangeCategory}
                            name={name.templateActivityCategory}
                            options={templateActivityCategories as any}
                            getOptionLabel={getCategoryTitle}
                            fullWidth={true}
                            disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {currentUser.useDrugLists && String(values.useParticipantList) === 'false' && (
                    <ModalSection
                      title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_UNIT_AMOUNT_TITLE')}
                      subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_UNIT_AMOUNT_SUBTITLE')}>
                      <ModalSectionRow>
                        <FilledTextField
                          label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_UNIT_AMOUNT_COMPONENT_LABEL_DEFAULT')}
                          name={name.unitAmount}
                          type="number"
                          color="secondary"
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                        />
                        <Box width="40%">
                          <Box flex={1}>
                            <SelectInput
                              label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_UNIT_COMPONENT_LABEL_DEFAULT')}
                              onChange={handleChangeUnit}
                              name={name.unit}
                              options={units as any}
                              getOptionLabel={getUnitTitle}
                              fullWidth
                              disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                            />
                          </Box>
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_LENGTH_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_LENGTH_SUBTITLE')}>
                    <ModalSectionRow>
                      <FilledTextField
                        label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL')}
                        name={name.visitLengthInMinutes}
                        color="secondary"
                        disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                      />
                    </ModalSectionRow>
                    <ModalSectionRow>
                      <BigCheckbox
                        label={`10 ${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="10"
                        checkedByValue
                        returnValue="value"
                        disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                      />
                      <BigCheckbox
                        label={`20 ${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="20"
                        checkedByValue
                        returnValue="value"
                        disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                      />
                      <BigCheckbox
                        label={`30 ${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="30"
                        checkedByValue
                        returnValue="value"
                        disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                      />
                      <BigCheckbox
                        label={`60 ${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="60"
                        checkedByValue
                        returnValue="value"
                        disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                      />
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_ALERT_MINUTES_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_ALERT_MINUTES_SUBTITLE')}>
                    <ModalSectionRow>
                      <FilledTextField
                        label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_ALERT_MINUTES_TEXTFIELD_LABEL')}
                        name={name.alertFromStartInMinutes}
                        color="secondary"
                        disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                      />
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_NORMAL')}`}
                          name={name.severityLevelId}
                          value={SeverityLevelEnum.Normal}
                          checkedByValue
                          returnValue="value"
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                        />
                        <BigCheckbox
                          label={`${f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_CRITICAL')}`}
                          name={name.severityLevelId}
                          value={SeverityLevelEnum.Critical}
                          checkedByValue
                          returnValue="value"
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          data-matomo-mask
                          name={name.description}
                          label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_LABEL')}
                          color="secondary"
                          fullWidth
                          multiline
                          rows={5}
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {currentUser.useDelegations && (
                    <ModalSection
                      title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_TITLE')}
                      subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_SUBTITLE')}>
                      <ModalSectionRow>
                        <Box width="80%">
                          <SelectInput
                            name={name.templateActivityDelegations}
                            options={delegationItems.reduce((prev: SelectableTemplateActivityDelegationItem[], curr) => {
                              if (!values.templateActivityDelegations?.some(val => val.delegationId === curr.id)) {
                                return [
                                  ...prev,
                                  {
                                    delegationId: curr.id,
                                    delegationTitle: curr.title,
                                  },
                                ]
                              }
                              return [...prev]
                            }, []) as any}
                            label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_LABLE')}
                            getOptionLabel={getDelegationTitle}
                            fullWidth
                            multiple
                            disableClearable
                            noValue
                            disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                          />
                          {values?.templateActivityDelegations && (
                            <Table>
                              <TableBody>
                                {values?.templateActivityDelegations.map(delegation => (
                                  <TableRow key={`timebasedArea-${delegation.delegationId}`}>
                                    <TableCell>{delegation.delegationTitle}</TableCell>
                                    <TableCell align="right" padding="none">
                                      <IconButton onClick={handleRemoveTemplateActivityDelegation(delegation.delegationId)} disabled={isOrganizationTemplateAndUserIsAreaAdmin}>
                                        <CloseCircle />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          )}
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_INFOTEXT_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <div>
                        {!isOrganizationTemplateAndUserIsAreaAdmin && <ColorPicker name={name.color} />}
                        {isOrganizationTemplateAndUserIsAreaAdmin && (
                          <ColorPickerStyle>
                            <ColorPickerCircleColor color={values.color} />
                          </ColorPickerStyle>
                        )}
                      </div>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_SHOW_ON_DEVICE_INFOTEXT_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_SHOW_ON_DEVICE_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('BASIC_TEXT_YES')}`}
                          name={name.showColorOnDevice}
                          value="true"
                          checkedByValue
                          returnValue="value"
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                        />
                        <BigCheckbox
                          label={`${f('BASIC_TEXT_NO')}`}
                          name={name.showColorOnDevice}
                          value="false"
                          checkedByValue
                          returnValue="value"
                          disabled={isOrganizationTemplateAndUserIsAreaAdmin}
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  {!values.ownerOrganizationId && values.id && userIsSysAdminOrOrgAdminOrTeniloSupport && (
                    <ModalSection
                      title={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_TITLE')}
                      subtitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_SUBTITLE')}>
                      <ModalSectionRow>
                        <Box width="50%">
                          <Box flex={1}>
                            <ContainedButton type="button" color="secondary" onClick={handleMoveToOrganizationClick}>
                              {f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_BUTTON_TEXT')}
                            </ContainedButton>
                          </Box>
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}
              </ModalContent>
              <ModalFooter>
                <TextButton type="button" onClick={closeTemplateActivityModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton
                    type="submit"
                    color={'secondary'}
                    showProgress={isSubmitting}
                    disabled={!dirty || isSubmitting || isOrganizationTemplateAndUserIsAreaAdmin}>
                    {!selectedTemplateActivity ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
      {confirmMoveDialogOpen && (
        <DeleteDialog
          dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_TITLE')}
          dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_MESSAGE')}
          buttonSubmitText={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_CONFIRM')}
          buttonCancelText={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_CANCEL')}
          deleteDialogOpen
          onClose={closeConfirmMoveDialog}
          onSubmit={handleConfirmMoveTemplateActivityClick}
        />
      )}

    </CenterModal>
  )
}

export default TemplateActivityModalContainer
