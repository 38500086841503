import AuthenticationContext from 'components/authentication/authentication-context'
import NotFoundPage from 'pages/not-found'
import React from 'react'
import { useParams } from 'react-router-dom'
import useUserAreas from 'utilities/use-user-areas'
import { RouteMenuContext } from './components/route-menu/route-menu-context'
import { PlanningPageContext } from './planning-page-context'
import PlanningPageView from './planning-page-view'

const PlanningPageContainer: React.FC = () => {
  const { areaId: planningIdString, date: dateStr, type, usingCalendarFromDevice: usingCalendarFromDeviceString } = useParams() as {
    areaId: string
    date: string
    type: 'areas' | 'devices'
    usingCalendarFromDevice: 'true' | 'false'
  }
  const planningId = React.useMemo(() => +planningIdString, [planningIdString])
  const date = React.useMemo(() => new Date(dateStr), [dateStr])

  const usingCalendarFromDevice = React.useMemo(() => usingCalendarFromDeviceString === 'true', [usingCalendarFromDeviceString])

  const { setPlanningId, setPlanningType, setUsingCalendarFromDevice } = AuthenticationContext.useActions()

  const areas = useUserAreas()

  React.useEffect(() => {
    setUsingCalendarFromDevice(usingCalendarFromDevice)
  }, [usingCalendarFromDevice])

  React.useEffect(() => {
    if (!isNaN(planningId)) {
      setPlanningId(planningId)
    }
  }, [planningId])

  React.useEffect(() => {
    setPlanningType(type)
  }, [type])

  if (isNaN(planningId)) {
    return <NotFoundPage />
  }

  if (type === 'areas' && !areas.some(a => a.areaId === planningId)) {
    return <NotFoundPage />
  }

  if (!(date instanceof Date) || isNaN(+date)) {
    return <NotFoundPage />
  }

  return (
    <PlanningPageContext.Provider
      planningId={planningId}
      initialDate={date}
      type={type}>
      <RouteMenuContext.Provider>
        <PlanningPageView />
      </RouteMenuContext.Provider>
    </PlanningPageContext.Provider>
  )
}

export default PlanningPageContainer
