import { CustomerItem, ICustomerItem, IUserAreaItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'
import usePromiseApi from 'utilities/use-promise'
import { useCustomerApi } from 'api'
import { CustomerModalContext } from './../../../customer-modal-context'

interface IBasicInfoModel {
  area?: number
  roomNumber: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  description: string | undefined
  extraInfo?: string
}

const useBasicInfoModalFormData = (customerInfo: ICustomerItem, areas: IUserAreaItem[]) => {
  const { updateCustomerAsync, setCustomerHasActivityOnCurrentAreaTemplateActivity, clearUser } = CustomerModalContext.useActions()
  const f = useFormatMessage()
  const { fetchAsync } = usePromiseApi()
  const { hasAreaTemplateActivityUsage } = useCustomerApi()

  const { customerAreas = [], roomNumber, firstName, lastName, description, id, statusId, extraInfo } = customerInfo
  const area = customerAreas.length > 0 ? customerAreas[0].areaId : areas.length > 0 ? areas[0].areaId : undefined

  const initialValues: IBasicInfoModel = React.useMemo(
    () => ({
      area,
      roomNumber,
      firstName,
      lastName,
      description,
      extraInfo: extraInfo || '',
    }),
    [area, roomNumber, description, firstName, lastName, extraInfo]
  )

  const onSubmit: OnSubmitFunction<IBasicInfoModel> = React.useCallback(
    (values, { setSubmitting }) => {
      const customer = {
        id,
        statusId,
        roomNumber: values.roomNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        description: values.description,
        extraInfo: values.extraInfo,
        customerAreas: [
          {
            areaId: values.area,
          },
        ],
      } as CustomerItem

      if (values.area !== initialValues.area) {
        return fetchAsync(hasAreaTemplateActivityUsage(customer.id)).then(exists => {
          if (exists) {
            setCustomerHasActivityOnCurrentAreaTemplateActivity(true)
            return Promise.resolve()
          } else {
            setCustomerHasActivityOnCurrentAreaTemplateActivity(false)

            void updateCustomerAsync(customer).then(() => {
              setSubmitting(false)
              clearUser()
            })
            return Promise.resolve()
          }
        })
      } else {
        setCustomerHasActivityOnCurrentAreaTemplateActivity(false)

        void updateCustomerAsync(customer).then(() => {
          setSubmitting(false)
          clearUser()
        })
        return Promise.resolve()
      }
    },
    [id, statusId, updateCustomerAsync, setCustomerHasActivityOnCurrentAreaTemplateActivity, hasAreaTemplateActivityUsage, fetchAsync]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<IBasicInfoModel>({
        area: yup.number().required('Name is a required field'),
        roomNumber: yup
          .string()
          .ensure()
          .max(
            20,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_TITLE'),
              maxsize: 20,
            })
          ),
        firstName: yup
          .string()
          .required('First Name is a required field')
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_FIRSTNAME_TEXTFIELD_LABEL'),
              maxsize: 128,
            })
          ),
        lastName: yup
          .string()
          .required('Last Name is a required field')
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_LASTNAME_TEXTFIELD_LABEL'),
              maxsize: 128,
            })
          ),
        extraInfo: yup
          .string()
          .ensure()
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_TEXTFIELD_LABEL'),
              maxsize: 128,
            })
          ),
        description: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL'),
            maxsize: 1024,
          })
        ),
      }),
    [f]
  )

  const name: Name<IBasicInfoModel> = React.useMemo(
    () => ({
      area: 'area',
      roomNumber: 'roomNumber',
      firstName: 'firstName',
      lastName: 'lastName',
      description: 'description',
      extraInfo: 'extraInfo',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useBasicInfoModalFormData
