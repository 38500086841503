const LEFT_TIME_COLUMN_WIDTH = 45
const TOP_HEADER_ROW_HEIGHT = 42
const DEFAULT_STEP_IN_MINUTES = 60
const SCROLLBAR_WIDTH = 0
const DEFAULT_TIME_SCALE = 1
const DEFAULT_SNAP_IN_MINUTES = 5
const DEFAULT_SCROLL_SPEED = 6
const DEFAULT_MIN_WIDTH_SESSION = 1

export default {
  DEFAULT_TIME_SCALE,
  DEFAULT_SNAP_IN_MINUTES,
  DEFAULT_STEP_IN_MINUTES,
  LEFT_TIME_COLUMN_WIDTH,
  TOP_HEADER_ROW_HEIGHT,
  SCROLLBAR_WIDTH,
  DEFAULT_SCROLL_SPEED,
  DEFAULT_MIN_WIDTH_SESSION,
}
