import React from 'react'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'
import { NotificationListItem, PageResultOfNotificationListItem } from 'api/tenilo.dashboard.api-client'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import useNotificationApi from 'api/notification-api'

interface ListNotificationsPagedType {
  loading: boolean
  viewList: boolean
  currentPage?: number
  nextPage?: number
  currentRes?: PageResultOfNotificationListItem
  items: NotificationListItem[]
}

const useDashboardNotificationContext = () => {
  const { fetchAsync } = usePromiseApi()
  const { getCurrentAreaIds, currentUser } = DashboardPageContext.useState()
  const { setNumberOfNewNotifications, setLatestNotificationId } = DashboardPageContext.useActions()
  const { listNewNotifications, listNotificationsPaged, abort } = useNotificationApi()
  const [ newNotifications, setNewNotifications ] = React.useState<NotificationListItem[]>()
  const [ listNotifications, setListNotifications ] = React.useState<ListNotificationsPagedType>({
    loading:false,
    viewList:false,
    items:[]
  })

  const fetchNewNotifications = React.useCallback(() => {
    if (currentUser?.organizationId) {
      fetchAsync(listNewNotifications(currentUser.organizationId,getCurrentAreaIds())).then(res => {
        const numNew = res && res.length ? res.length : 0
        setNumberOfNewNotifications(numNew)

        const latestNotificationId = Math.max(...res.map(n => n.id))
        setLatestNotificationId(latestNotificationId)

        setNewNotifications(res)

        if(!listNotifications?.currentRes){
          void fetchNotificationsPaged(numNew===0)()
        }
      })
    }
  }, [currentUser])

  const fetchNotificationsPaged = React.useCallback((click:boolean = false) => () => {
    if (currentUser?.organizationId) {
      if(click){
        setListNotifications(curr => {
          return {
            ...curr,
            loading: true,
            viewList: true
          }
        })
      }
      fetchAsync(listNotificationsPaged(listNotifications?.nextPage || 1, 10, currentUser.organizationId,getCurrentAreaIds())).then(res => {
        setListNotifications(curr => {
          if(!click){
            return {
              ...curr,
              currentRes: res,
            }
          }
          return {
            ...curr,
            loading:false,
            currentPage: res.page,
            nextPage: res.page + 1,
            currentRes: res,
            items: [...curr.items , ...res?.items]
          }
        })
      })
    }
  }, [currentUser, listNotifications])

  React.useEffect(()=>{
    void fetchNewNotifications()
  },[currentUser])

  React.useEffect(() => () => abort(), [])
  return {
    state: {
      newNotifications,
      listNotifications
    },
    actions: {
      fetchNotificationsPaged
    },
  }
}

export default createContext(useDashboardNotificationContext)
