import React from 'react'
import { ReactComponent as CornerLeft } from 'assets/images/darkturkos_corner_left.svg'
import { ReactComponent as TeniloLogo } from 'assets/images/logotype_beige.svg'
import { TransitConnectionVariant as Tours  } from 'mdi-material-ui'
import IconButton from '@mui/material/IconButton';
import { STORAGEPREFIX } from 'config/variables'

const prefix = STORAGEPREFIX ? STORAGEPREFIX : ''
let envName: any
if (prefix.includes('dev')) {
  envName = 'DEV'
}
if (prefix.includes('qa')) {
  envName = 'QA'
}
if (prefix.includes('test')) {
  envName = 'TEST'
}
type Props = Readonly<{
  className?:string
  toggleOpenMobileTourMenu?: () => void
}
>
const TeniloBanner : React.FC<Props> =  ({className, toggleOpenMobileTourMenu}) => {

  const handleShowMobileTourView = React.useCallback(() => {
    if(toggleOpenMobileTourMenu) {toggleOpenMobileTourMenu()}
  }, [toggleOpenMobileTourMenu])

  return (
    <div style={{height:'75px',position:'absolute',left:0,right:0,display:'flex',alignItems:'center'}} className={className}>
      <IconButton sx={{
          display:'none',
          p:0,
          ml:2.5,
          justifyContent:'center',
          alignItems:'center'
        }}
      className='tenilo-banner_tours'
      aria-label='tours'
      color='primary'
      onClick={handleShowMobileTourView}
      >
        <Tours  />
      </IconButton>
      <div style={{position:'absolute',top:0,right:0,width:'auto',display:'flex',alignItems:'center',height:'100%'}}>
        <CornerLeft
          style={{
            height: '100%',
            transform: 'scaleX(-1)',
          }}
        />
        <TeniloLogo
          style={{
            position: 'absolute',
            left: '30%',
            height: '40%'
          }}
        />
        {envName && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: '5%',
              fontSize: '15px',
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              color: '#F8F2EA',
            }}>
            {envName}
          </div>
        )}
      </div>
    </div>
  )
}

export default TeniloBanner
