import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { NavLink } from 'react-router-dom'
import PageRoute from 'config/page-routes'
import { LoginPage } from 'components/page'
import ContainedButton from 'components/inputs/contained-button'
import OutlinedTextField from 'components/inputs/outlined-text-field'
import { useFormatMessage } from 'localization'
import styled, { css } from 'styled-components'
import useForgotPasswordForm from './utilities/use-forgot-password-form'

const StyledNavLink = styled(NavLink)(
  props => css`
    text-decoration: none;
    &.active > * {
      color: ${props.theme.palette.primary.contrastText};
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.3);
    }
  `
)

const ForgotPasswordContainer: React.SFC = () => {
  const { initialValues, onSubmit, validationSchema, name } = useForgotPasswordForm()
  const f = useFormatMessage()

  return (
    <LoginPage>
      <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
        <Grid item>
          <Typography sx={{
              fontWeight:600,
              letterSpacing:'0.01em'
            }} variant="h3">{f('FORGOT_PASSWORD_TITLE')}</Typography>
        </Grid>
        <Grid item>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnBlur={false}>
            {({ isSubmitting, status }) => (
              <Form translate="yes">
                <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
                  <Grid item>
                    <OutlinedTextField
                      data-matomo-mask
                      fullWidth
                      name={name.email}
                      autoFocus
                      label={f('BASIC_LABEL_EMAIL_TEXT')}
                      type="email"
                      color="secondary"
                      inputProps={{autoComplete: 'off'}}
                      disabled={status && status.error && status.error === 100}
                      />
                  </Grid>
                  {status && status.error && status.error !== 100 && <Typography color={'error'}>{f('FORGOT_PASSWORD_ERROR_TEXT')}</Typography>}
                  {status && status.error && status.error === 100 && <Typography color={'primary'}>{f('FORGOT_PASSWORD_LINK_SENT')}</Typography>}

                  {status && status.error && status.error === 100 ? (
                    ''
                  ) : (
                    <Grid item>
                      <ContainedButton containerStyle={{margin:0,display:'flex',justifyContent:'center'}} sx={{
                          width:'80%',
                          paddingTop:'10px',
                          paddingBottom:'10px',
                          borderRadius:'20px'
                        }} type="submit" color="primary" showProgress={isSubmitting}>
                        {f('FORGOT_PASSWORD_BUTTON_TEXT')}
                      </ContainedButton>
                    </Grid>
                  )}
                  <Grid  item sx={{textAlign: 'center'}}>
                    <StyledNavLink  to={PageRoute.Login.path}>
                      <Typography sx={
                        {fontWeight:500}
                      } variant="subtitle1" color="secondary">{f('GENERAL_BACK')}</Typography>
                    </StyledNavLink>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </LoginPage>
  )
}

export default ForgotPasswordContainer
