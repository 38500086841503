import React, { useState } from 'react'
import { Box, MenuItem, Typography, Tooltip } from '@mui/material';
import has from 'lodash/has'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import { useFormatMessage } from 'localization'
import { FieldArrayRenderProps, FieldArray } from 'formik'
import { UserDelegationItem, UserDelegationPlacementItem } from 'api/tillit.api-client'
import { PlusBox, Close, ChevronDown, ChevronUp, Delete } from 'mdi-material-ui'
import styled from 'styled-components'
import { DeleteDialog } from 'components/delete-dialog'
import FilledSelect from 'components/inputs/filled-select'
import DelegationSectionContext from './delegation-section-context'
import UserDelegationTitles from './components/user-delegation-titles'
import UserDelegationPlacementCardStaticContent from './components/user-delegation-placement-card-static'
import UserDelegationPlacementCardDynamicContent from './components/user-delegation-placement-card-dynamic'

type Props = Readonly<
  {
    selectedDelegation: UserDelegationItem
    index: number
  } & FieldArrayRenderProps
>

const UserDelegationCard = styled(Box)`
  margin: 2rem 0;
  background-color: #f8f2ea;
  border-radius: 0.5rem;
  padding:1rem;
  box-shadow: .5px .5px 2px 0px #A45217;
  width: 90%;
  position: relative;
`

const UserDelegationPlacementCard = styled(Box)`
  background-color: #ffff;
  overflow: hidden;
  white-space: nowrap;
  padding:.5rem;
  margin:.5rem 0;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
  display:flex;
  position: relative;
  h6 {
    flex: 1;
    padding: 0 10px;
  }
`
const UserDelegationToolsBox = styled(Box)`
  display:flex;
  justify-content: flex-end;
  svg {
    cursor: pointer;
  }
`

const UserDelegationPlacementToolsBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  height: 2rem;
  top: 0;
  right: 10px;
  svg {
    cursor: pointer;
    height: 100%;
  }
`


const DelegationView: React.FC<Props> = ({ selectedDelegation, index, ...FieldArrayProps }) => {
  const f = useFormatMessage()
  const [dynamicMode, setDynamicMode] = useState<number>()
  const { delegationItems } = DelegationSectionContext.useState()
  const [deactiveDelegationPlacementItemId, setDeactiveDelegationPlacementItem] = React.useState<number | null>(null)

  const isSavedDelegation = React.useCallback((): boolean => {
    return has(selectedDelegation, 'id')
  }, [selectedDelegation])

  const hasSavedDelegationPlacement = React.useCallback((userDelegationPlacements: UserDelegationItem['userDelegationPlacements']): boolean | undefined => {
    return userDelegationPlacements?.some((placement: UserDelegationPlacementItem) => has(placement, 'id') && placement.active)
  }, [])

  const handleDynamicMode = (placementIndex: number): void => {
    if (placementIndex === dynamicMode) { setDynamicMode(undefined) }
    else { setDynamicMode(placementIndex) }
  }

  const handleChangeUserDelegation = (event: any, child: any) => {
    selectedDelegation.userDelegationPlacements?.map((placement: UserDelegationPlacementItem, i: number) => {
      FieldArrayProps.form.setFieldValue(`selectedDelegations.${index}.userDelegationPlacements.${i}.areaId`, '')
    })
    FieldArrayProps.form.setFieldValue(`selectedDelegations.${index}.delegationId`, event.target.value)
    if (!has(selectedDelegation, 'userDelegationPlacements')) {
      const date = new Date();
      const nextYear = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
      FieldArrayProps.form.setFieldValue(`selectedDelegations.${index}.userDelegationPlacements`, [{
        delegationStartTime: date,
        delegationEndTime: nextYear,
      }])
    }
  }

  const handleToggleDeactiveDelegationPlacementModal = (placementId: number | null = null) => () => {
    setDeactiveDelegationPlacementItem(placementId)
  }

  const handleAddPlacement = (props: FieldArrayRenderProps) => () => {
    const date = new Date();
    const nextYear = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
    props.push({
      delegationStartTime: date,
      delegationEndTime: nextYear,
    })
  }

  return (
    <UserDelegationCard>
      {isSavedDelegation() ?
        <Typography variant="h6">
          {selectedDelegation.delegationTitle}
        </Typography>
        :
        <>
          <Tooltip title={f('USER_DIALOG_VIEW_DELEGATION_DEACTIVE_BUTTON_TITLE')}>
            <Close sx={{ position: 'absolute', right: '1rem', top: '.5rem', cursor: 'pointer' }} color="secondary" onClick={() => FieldArrayProps.remove(index)} />
          </Tooltip>
          <FilledSelect
            labelId={`user-delegation-label-id-${index}`}
            id={`user-delegation-id-${index}`}
            label={f('USER_DIALOG_VIEW_DELEGATION_TITLE')}
            onChange={handleChangeUserDelegation}
            value={FieldArrayProps.form.getFieldMeta(`selectedDelegations.${index}.delegationId`).value || ''}
            name={`selectedDelegations.${index}.delegationId`}>
            {delegationItems.map(item => (
              <MenuItem sx={{
                minWidth: '272px'
              }} key={`delegation-${item.id}`} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </FilledSelect>
        </>
      }
      <FieldArray name={`selectedDelegations.${index}.userDelegationPlacements`}>
        {(props) => {
          const handleSubmitDeactiveDelegationPlacementModal = () => {
            props.form.setFieldValue(`selectedDelegations.${index}.userDelegationPlacements.${deactiveDelegationPlacementItemId}.active`, false)
            handleToggleDeactiveDelegationPlacementModal()()
          }
          return (
            <div>
              {
                <>
                  {hasSavedDelegationPlacement(selectedDelegation.userDelegationPlacements) && (<UserDelegationTitles />)}
                  {
                    selectedDelegation.userDelegationPlacements?.map((placement: UserDelegationPlacementItem, i: number) => (
                      (placement.active || !has(placement, 'id')) && (
                        <UserDelegationPlacementCard key={`user-delegation-placement-${i}`} >
                          <UserDelegationPlacementToolsBox>
                            {
                              has(placement, 'id') ?
                                i !== dynamicMode ?
                                  <Tooltip title={f('USER_DIALOG_VIEW_DELEGATION_OPEN_PLACEMENT_BUTTON_TITLE')}>
                                    <ChevronDown color="primary" onClick={() => handleDynamicMode(i)} />
                                  </Tooltip>
                                  :
                                  <Tooltip title={f('USER_DIALOG_VIEW_DELEGATION_CLOSE_PLACEMENT_BUTTON_TITLE')}>
                                    <ChevronUp color="primary" onClick={() => handleDynamicMode(i)} />
                                  </Tooltip>
                                :
                                <Tooltip title={f('USER_DIALOG_VIEW_DELEGATION_DEACTIVE_BUTTON_TITLE')}>
                                  <Close color="secondary" onClick={() => props.pop()} />
                                </Tooltip>
                            }
                            {
                              has(placement, 'id') && (
                                <Tooltip title={f('USER_DIALOG_VIEW_DELEGATION_DEACTIVE_BUTTON_TITLE')}>
                                  <Delete sx={{ fontSize: '1.3rem' }} color="secondary" onClick={handleToggleDeactiveDelegationPlacementModal(i)} />
                                </Tooltip>
                              )
                            }
                          </UserDelegationPlacementToolsBox>
                          {
                            has(placement, 'id') && i !== dynamicMode ?
                              <UserDelegationPlacementCardStaticContent placement={placement} />
                              :
                              <UserDelegationPlacementCardDynamicContent
                                delegationIndex={index}
                                placementIndex={i}
                                delegationId={props.form.getFieldMeta(`selectedDelegations.${index}.delegationId`).value || ''}
                                isSavedPlacement={has(placement, 'id')}
                                {...props}
                              />
                          }
                        </UserDelegationPlacementCard >
                      )
                    ))
                  }
                </>
              }
              <UserDelegationToolsBox>
                <Tooltip title={f('USER_DIALOG_VIEW_DELEGATION_ADD_PLACEMENT_BUTTON_TITLE')}>
                  <PlusBox color="primary" onClick={handleAddPlacement(props)} />
                </Tooltip>
              </UserDelegationToolsBox>
              {isNumber(deactiveDelegationPlacementItemId) && (
                <DeleteDialog
                  dialogTitle={f('USER_DIALOG_VIEW_DELEGATION_DEACTIVATE_DIALOG_TITLE')}
                  dialogContentText={f('USER_DIALOG_VIEW_DELEGATION_DEACTIVATE_DIALOG_MESSAGE')}
                  buttonSubmitText={f('USER_DIALOG_VIEW_DELEGATION_DEACTIVE_BUTTON_TITLE')}
                  buttonCancelText={f('USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_CANCEL')}
                  deleteDialogOpen
                  onClose={handleToggleDeactiveDelegationPlacementModal()}
                  onSubmit={handleSubmitDeactiveDelegationPlacementModal}
                />
              )}
            </div>
          )
        }
        }
      </FieldArray>

    </UserDelegationCard>
  )
}

export default DelegationView
