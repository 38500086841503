import React, { FC } from 'react';
import { IUserItem } from 'api/tillit.api-client'
import styled, { css } from 'styled-components';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useFormatMessage } from 'localization'
import Collapse from '@mui/material/Collapse';
import { ChevronUp, Menu } from 'mdi-material-ui'
import { Colors } from 'styles/colors'

type Props = Readonly<{
  className?: string
  handleLockClicked: () => void
  handleToggleSignedClick?: () => void
  handleToggleMultiSigningClick?: () => void
  handleSignMultipleClick: () => void
  handleToggleBulletinBoardClick: () => void
  locked: boolean
  showSigned: boolean
  useMultiSigning: boolean
  openWorkScheduleDialog: () => void
  user?: IUserItem
  openPlanningPage: () => void
  handleToggleInternalInformationModal: () => void
  allowNavigateToPlanningPage: boolean | undefined
  allowUseMultiSigning: boolean | undefined
  disablePerformMultiSign: boolean | undefined
  showBulletinBoard: boolean | undefined
}>

const DailyActivityMainMenuMobile: FC<Props> = ({ className, handleLockClicked, handleToggleSignedClick, handleToggleMultiSigningClick, handleSignMultipleClick, handleToggleBulletinBoardClick, locked, showSigned, useMultiSigning, openWorkScheduleDialog, user, openPlanningPage, allowNavigateToPlanningPage, allowUseMultiSigning, handleToggleInternalInformationModal, disablePerformMultiSign, showBulletinBoard }) => {
  const f = useFormatMessage()
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setOpen(!open);
  };
  return (
    <List
      className={className}
    >
      <ListItemButton onClick={handleClick}>
        {open ? <ChevronUp /> : <Menu />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List disablePadding>
          <ListItemButton onClick={handleLockClicked}>
            <ListItemText primary={
              locked ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_UNLOCK_BUTTON_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_LOCK_BUTTON_TEXT')
            } />
          </ListItemButton>
          <ListItemButton onClick={handleToggleSignedClick}>
            <ListItemText primary={
              showSigned ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_HIDE_SIGNED_TOGGLE_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_SIGNED_TOGGLE_TEXT')
            } />
          </ListItemButton>
          {!locked && user?.useWorkSchedule && (
            <ListItemButton onClick={openWorkScheduleDialog}>
              <ListItemText primary={
                f('DAILY_ACTIVITIES_PAGE_CONTAINER_WORK_SCHEDULES_BUTTON_TEXT')
              } />
            </ListItemButton>
          )}
          <ListItemButton onClick={handleToggleInternalInformationModal}>
            <ListItemText primary={
              f('DAILY_ACTIVITIES_PAGE_CONTAINER_INTERNAL_INFORMATION_BUTTON_TEXT')
            } />
          </ListItemButton>
          {!locked && allowNavigateToPlanningPage && (
            <ListItemButton onClick={openPlanningPage}>
              <ListItemText primary={f('DAILY_ACTIVITIES_PAGE_CONTAINER_PLANNINGPAGE_BUTTON_TEXT')} />
            </ListItemButton>
          )}

          {!locked && allowUseMultiSigning && (
            <>
              <ListItemButton onClick={handleToggleMultiSigningClick}>
                <ListItemText primary={
                  useMultiSigning ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_DEACTIVATE_MULTISIGN_TOGGLE_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_ACTIVATE_MULTISIGN_TOGGLE_TEXT')
                } />
              </ListItemButton>

              {useMultiSigning && (
                <ListItemButton onClick={handleSignMultipleClick} disabled={disablePerformMultiSign}>
                  <ListItemText primary={f('DAILY_ACTIVITIES_PAGE_CONTAINER_PERFORM_MULTISIGN_TEXT')} />
                </ListItemButton>
              )}

            </>
          )}

          {!locked && (
            <ListItemButton onClick={handleToggleBulletinBoardClick} >
              <ListItemText primary={showBulletinBoard ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_HIDE_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_SHOW_TEXT')} />
            </ListItemButton>
          )}

        </List>
      </Collapse>
    </List>
  )
}



export default styled(DailyActivityMainMenuMobile)(({ theme }) => css`
  display: none;
  .MuiListItemButton-root{
    height: 25px;
  }
  >.MuiListItemButton-root {
    background-color: ${theme.palette.primary.main};
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.TeniloBeige};
    &:hover {
      background-color: ${theme.palette.primary.main};
    }
  }
  >.MuiCollapse-root {
    .MuiListItemButton-root {
      background-color: ${Colors.TeniloBeige};
      border-bottom: 1px solid ${theme.palette.primary.main};
      &:hover {
        background-color: ${Colors.TeniloBeige};
      }
    }
    .MuiListItemText-primary {
      color: ${theme.palette.primary.main};
      font-size: 14px;
      font-weight: 500;
    }
  }
  ${[theme.breakpoints.down('md')]}{
    display: block;
  }
`)
