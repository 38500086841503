import React from 'react'
import styled from 'styled-components'
import generateRandomString from 'utilities/generate-random-string'
import BigCheckboxWithoutFormView from './big-checkbox-without-form-view'

type Props = Readonly<{
  disabled?: boolean | undefined
  label?: string
  value?: number
  selectValue?: number
  onSelectedValueChanged: any
}>

const BigCheckboxWithoutFormContainer: React.FC<Props> = ({ disabled, label, value, selectValue, onSelectedValueChanged }) => {
  const id = React.useMemo(() => generateRandomString(), [])

  const checked = React.useCallback(
    () => {
      return value === selectValue
    },
    [selectValue, value]
  )

  const onChangeValue = () => {
    onSelectedValueChanged(selectValue)
  }

  return (
    <StyledLabel htmlFor={id}>
      <input
        disabled={disabled}
        type="checkbox"
        id={id}
        {...(value ? { value } : null)}
        style={{ visibility: 'hidden', position: 'absolute' }}
        onChange={onChangeValue}
      />
      <BigCheckboxWithoutFormView checked={checked()} label={label} />
    </StyledLabel>
  )
}

const StyledLabel = styled.label`
  display: inline-block;
`

export default React.memo(BigCheckboxWithoutFormContainer)
