import React from 'react'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'

import { DelegationListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'

import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterDelegations } from 'utilities/delegation-helper'

import { UserTypeEnum } from 'api/tillit.api-client'
import AuthenticationContext from 'components/authentication/authentication-context'
import { DelegationModalContext } from './components/delegation-modal/delegation-modal-context'
import { AdministerOrganizationDelegationsContext } from './administer-organization-delegations-context'

const AdministerOrganizationDelegationsView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { delegationItems, removeDialogOpen, removeInvalidDialogOpen, isLoading, delegationToRemove, delegationInvalidToRemove } = AdministerOrganizationDelegationsContext.useState()
  const { openRemoveDialog, deleteDelegationAsync, closeRemoveDialog, closeRemoveInvalidDialog } = AdministerOrganizationDelegationsContext.useActions()

  const { openDelegationModal, setOwnerOrganizationId } = DelegationModalContext.useActions()

  const { user } = AuthenticationContext.useAuthenticatedState()
  const userIsSysAdminOrOrgAdminOrTeniloSupport = user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport || user.userType === UserTypeEnum.SystemAdmin

  const f = useFormatMessage()
  const getFilteredDelegations = filterDelegations(f)

  const handleAddNewDelegationClick = React.useCallback(() => {
    setOwnerOrganizationId(currentUser.organizationId)
    openDelegationModal()
  }, [currentUser, setOwnerOrganizationId, openDelegationModal])

  const handleRemoveDelegationClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, delegation: DelegationListItem) => {
      _?.stopPropagation()
      openRemoveDialog(delegation)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveDelegationClick = React.useCallback(() => deleteDelegationAsync(delegationToRemove!.id), [delegationToRemove, deleteDelegationAsync])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openDelegationModal(params.row.id)
    },
    [openDelegationModal]
  )

  const theme = useTheme()


  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredDelegations, setFilteredDelegations] = React.useState<DelegationListItem[]>(delegationItems)


  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredDelegations(filterValue, delegationItems)
      setFilteredDelegations(filtered)
    } else {
      setFilteredDelegations(delegationItems)
    }
    // NOTE: We are missing filterValue and getFilteredDelegations as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [delegationItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredDelegations(searchValue, delegationItems)
      setFilteredDelegations(filtered)
    },
    [setFilterValue, setFilteredDelegations, delegationItems, getFilteredDelegations]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredDelegations(delegationItems)
    },
    [setFilterValue, setFilteredDelegations, delegationItems]
  )


  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '0.5vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && userIsSysAdminOrOrgAdminOrTeniloSupport ? (
            <div style={{ columnCount: 2 }}>
              <ContainedButton type="button" color="secondary" onClick={handleAddNewDelegationClick}>
                {f('ADMINISTER_DELEGATIONS_ORGANIZATION_ADD_BUTTON_TEXT')}
              </ContainedButton>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          ) : (
            <div style={{ columnCount: 2 }}>
              <div>&nbsp;</div>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredDelegations}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_NAME'),
                field: 'title',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_DESCRIPTION'),
                field: 'description',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_CATEGORY'),
                field: 'delegationCategoryTitle',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_delete',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.title,
                        })}
                        aria-label={f('ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.title,
                        })}>
                        <IconButton disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport} onClick={e => handleRemoveDelegationClick(e, params.row)} size="large">
                          <Delete color={userIsSysAdminOrOrgAdminOrTeniloSupport ? 'secondary' : 'disabled'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />
          {removeDialogOpen && delegationToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_MESSAGE', {
                name: delegationToRemove.title,
              })}
              buttonSubmitText={f('ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen={true}
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemoveDelegationClick}
            />
          )}

          {removeInvalidDialogOpen && delegationInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_MESSAGE', {
                title: delegationInvalidToRemove.title,
              })}
              buttonOkText={f('ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen={true}
              onClose={closeRemoveInvalidDialog}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdministerOrganizationDelegationsView
