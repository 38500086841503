import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Typography, Slide, Fade } from '@mui/material'
import { useFormatMessage } from 'localization'
import { ReactComponent as TeniloHouseShape } from 'assets/images/tenilo_house_path.svg'
import { ReactComponent as AlleviLogo } from 'assets/images/allevi_logo.svg'
import LoginBackground from "assets/images/login_background.jpg"
import TeniloLogo2x from "assets/images/Tenilo_vit2x.png"
import PageRoute from 'config/page-routes'
import { useLocationState } from 'utilities/react-router-hooks'

const backgroundAnimation = keyframes`
  0% {background-size: 300% 100%}
  100% {background-size: 100% 100% }
`

const StyledLoginPage = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto 60px;
  grid-template-columns: auto;
  background: rgb(10,84,87);
  background: radial-gradient(closest-side,rgba(10,84,87,0.5) 0%, rgba(10,84,87,1) 95%);
  animation-name: ${backgroundAnimation};
  animation-delay: 0.2s;
  animation-duration: 15s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  box-sizing: border-box;
  overflow: hidden;
  *{
    box-sizing: border-box;
  }
`

const LeftCircleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 650px;
  max-width: 100%;
  width: 600px;
  z-index: 0;
  overflow: hidden;
`

const RightCircleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 650px;
  max-width: 100%;
  width: 600px;
  z-index: 0;
  overflow: hidden;
`
const LeftCircle = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  left: -80px;
  bottom: -80px;
  overflow: hidden;
  ${({ theme }) => [theme.breakpoints.down('sm')]}{
    width: 200px;
    left: -30px;
    bottom: -450px;
  }
`

const RightCircle = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  right: -180px;
  top: -200px;
  transform: rotate(0.5turn);
  overflow: hidden;
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    right: -150px;
    top: -200px;
  }
`
const MainContentContainer = styled.div`
  grid-row: 1;
`

const FormContainer = styled.div`
  box-shadow: 1px 1px 15px 5px rgb(0 0 0 / 50%);
  width: 99vw;
  display: flex;
  position: relative;
  flex-direction: row;
  min-height: 500px;
  max-width: 1400px;
  border-radius: 20px;
  overflow: hidden;
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    flex-direction: column;
    align-items: flex-start;
    height: unset;
    min-height: 500px;
    max-width: 500px;
    min-width: 300px;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]}{
    max-width: 99vw;
  }
`
const FormContainerLeftSide = styled.div`
  position: relative;
  width: 60%;
  height: 100%;
  padding: 4rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${LoginBackground});
    background-size: cover;
    background-position: 65%;
    filter: brightness(75%);
  }
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    width: 100%;
    height: 40%;
    padding: 1.5rem 4rem;
    &::before {
      filter: brightness(50%);
    }
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]}{
    padding: 1.5rem 2rem;
  }
`

const FormContainerLeftSideContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const FormContainerLeftSideContent = styled.div`
  >img {
    width: 50%;
    min-width: 300px;
    height: auto;
    max-width: 100%
  }
  >h3 {
    font-weight: 600;
    font-size: 2.5rem;
    padding-left: 10px;
    letter-spacing: 0.05em;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    >img {
      min-width: 200px;
    }
    >h3 {
      font-size: 1.5rem;
    }
  }
`

const FormContainerRightSide = styled.div`
  width: 40%;
  height: 100%;
  padding: 4rem;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #fff;
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    width: 100%;
    height: 100%;
    padding: 1.5rem 4rem 3rem 4rem;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]}{
    padding: 1.5rem 2rem;
  }
`

const MainContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Footer = styled.div`
  grid-row: 2;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
`

const FooterLeftSide = styled.div`
  grid-row: 2;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
`

const FooterRightSide = styled.div`
`
const AlleviLogoContainer = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 0.75rem;
`
const AlleviLinkContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  >h6 {
    font-weight: 700;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    >h6{
      display: none;
    }
  }
`

const LoginPage: React.FC = ({ children }) => {
  const f = useFormatMessage()
  const locationState = useLocationState()
  const prefixDailyActivities = PageRoute.DailyActivities.path + "/"

  const disableLink = locationState?.pathname.startsWith(prefixDailyActivities) && locationState?.pathname.length > prefixDailyActivities.length

  return (
    <StyledLoginPage>
      <LeftCircleContainer>
        <LeftCircle>
          <TeniloHouseShape />
        </LeftCircle>
      </LeftCircleContainer>
      <MainContentContainer>
        <MainContent>
          <FormContainer>
            <Fade in timeout={1000}>
              <FormContainerLeftSide>
                <Fade in timeout={1800}>
                  <FormContainerLeftSideContentWrapper>
                    <FormContainerLeftSideContent>
                      <Slide in direction="left" timeout={600}>
                        <img src={TeniloLogo2x} alt="Tenilo" />
                      </Slide>
                    </FormContainerLeftSideContent>
                    <FormContainerLeftSideContent>
                      <Slide in direction="left" timeout={600}>
                        <Typography color="white" variant="h3">{f('LOGIN_PAGE_CONTAINER_WELCOME_TEXT')}</Typography>
                      </Slide>
                    </FormContainerLeftSideContent>
                  </FormContainerLeftSideContentWrapper>
                </Fade>
              </FormContainerLeftSide>
            </Fade>
            <FormContainerRightSide>
              {children}
            </FormContainerRightSide>
          </FormContainer>
        </MainContent>
      </MainContentContainer>
      <Footer>
        <FooterLeftSide>
        </FooterLeftSide>
        <FooterRightSide>
          {!disableLink && (
            <AlleviLinkContainer href="https://allevi.se/" target="_blank">
              <AlleviLogoContainer>
                <AlleviLogo />
              </AlleviLogoContainer>
              <Typography color="#F8F2EA" variant="subtitle2">{f('LOGIN_PAGE_CONTAINER_ALLEVI_LINK_TEXT')}</Typography>
            </AlleviLinkContainer>
          )}
          {disableLink && (
            <AlleviLinkContainer>
              <AlleviLogoContainer>
                <AlleviLogo />
              </AlleviLogoContainer>
              <Typography color="#F8F2EA" variant="subtitle2">{f('LOGIN_PAGE_CONTAINER_ALLEVI_LINK_TEXT')}</Typography>
            </AlleviLinkContainer>
          )}
        </FooterRightSide>
      </Footer>
    </StyledLoginPage>
  )
}

export default LoginPage
