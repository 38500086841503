import React, { HTMLProps } from 'react';
import { DailyActivitiesContext, IRouteKioskDailyActivitiesContainerItemWithHasLapsed } from 'pages/daily-activities/daily-activities-context';
import { Box, BoxProps, Button, ButtonProps, Collapse, styled } from '@mui/material';
import { IUserItem, RoleEnum } from 'api/tillit.api-client';
import { purple } from '@mui/material/colors';
import { ChevronUp, Menu } from 'mdi-material-ui'
import { inputDateTimeToTicks, ticksToInputDateTime } from 'utilities/date-time-helpers';

interface Props {
  data: IRouteKioskDailyActivitiesContainerItemWithHasLapsed | null
  user: IUserItem | undefined
  selectedAreaId: number | undefined
  handleSetSelectedAdditionalAreaId: (areaId: number) => void
}

const TabButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText('#DBD5D0'),
  width: '100%',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  '&:hover': {
    color: theme.palette.getContrastText('#0A5457'),
    backgroundColor: '#DBD5D0',
  },
  "&.Mui-disabled": {
    color: "#0A5457"
  },
  [theme.breakpoints.down('md')]: {
    '&:hover': {
      color: theme.palette.getContrastText('#0A5457'),
      backgroundColor: '#DBD5D0',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
        },
  }
}));

const AreaBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '0px',
  borderTop: '0px',
  borderLeft: '0px',
  borderRight: '0px',
  borderBottom: '2px',
  borderStyle: 'solid',
  borderColor: '#DBD5D0',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }
}));

const AreaTab = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  [theme.breakpoints.down('md')]: {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  }
}));


const DailyActivityAdditionalAreas: React.FC<Props> = ({ data, user, selectedAreaId, handleSetSelectedAdditionalAreaId }) => {

  const {
    setSelectedAdditionalAreaId,
  } = DailyActivitiesContext.useActions()

  const additionalAreas = React.useMemo(() => {
    if (!user) {
      return data?.additionalAreas
    }
    return data?.additionalAreas?.filter(area => user.userAreas?.some(ua => ua.areaId === area.areaId && ua.roleId === RoleEnum.Caretaker))
  }, [user, data])

  const additionalAreasShouldBeVisible = React.useMemo(() => {
    if (!data) { return false }
    if (!data.showAdditionalAreas) {
      return false
    }

    if (data.showAdditionalAreasAllDay) {
      return true
    }

    if (!data.showAdditionalAreasStartTimeTicks || !data.showAdditionalAreasEndTimeTicks) {
      return false
    }

    const now = inputDateTimeToTicks(new Date())
    const start = data.showAdditionalAreasStartTimeTicks
    const end = data.showAdditionalAreasEndTimeTicks
    if (end < start) {
      if (now > start) {
        return true
      } else {
        if (now < end) {
          return true
        }
      }
    }
    if (now >= start && now <= end) {
      return true
    }

    return false
  }, [data])


  React.useEffect(() => {
    if (!additionalAreasShouldBeVisible) {
      setSelectedAdditionalAreaId(undefined)
    }
  }, [additionalAreasShouldBeVisible])

//               <span key={additionalArea.id} style={{ backgroundColor: col, width: '100%', MozBorderRadiusTopright:'10px', MozBorderRadiusTopleft:'10px' }}>


  return (
    <>
      {additionalAreasShouldBeVisible && (
        <AreaBox>
          {additionalAreas && additionalAreas.map(additionalArea => {
            const isSelectedOrMainArea = (selectedAreaId && selectedAreaId === additionalArea.areaId) || (!selectedAreaId && additionalArea.kioskMainArea)
            const col = isSelectedOrMainArea ? '#DBD5D0' : '#F8F2EA'

            return (
              <AreaTab key={additionalArea.id} sx={{backgroundColor: col}}>
                <TabButton disabled={isSelectedOrMainArea} onClick={() => handleSetSelectedAdditionalAreaId(additionalArea.areaId)}>{additionalArea.area_Name}</TabButton>
              </AreaTab>
            )
          })}
        </AreaBox>
      )}
    </>
  );
}

export default DailyActivityAdditionalAreas
