import styled, { css } from 'styled-components'
import OrganizationTreeContainer from './organization-tree-container'

const OrganizationTreeStyles = styled(OrganizationTreeContainer)(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    background-color: #f9f9f9;
    margin: 0 -${theme.spacing(5)};
    flex-grow: 1;
    overflow: hidden;
  `
)

export default OrganizationTreeStyles
