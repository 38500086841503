import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { Box, Button } from '@mui/material'
import type { ReportsPageFormType, InnerContainerPropType } from 'pages/reports/config'
import { useFormikContext } from 'formik'
import { ReportTextField } from '../reports-page-inputs'


const ReportsSaveAsView : FC<InnerContainerPropType> = ({ applyRequirement, toggleSaveAsSection }) => {
  const f = useFormatMessage()
  const { submitForm, isValid, setFieldValue, errors, setTouched } = useFormikContext<ReportsPageFormType>()

  const onSubmit = React.useCallback((type: string) => () => {
    if(!isValid) {
      const shouldTouchSomeFields = Object.keys(errors).length > 0
      if (shouldTouchSomeFields) {
        setTouched(Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {}))
      }
      return
    }
    setFieldValue('submitType',type)
    submitForm()
  }, [isValid,errors])

  return React.useMemo(() => {
    return (
      <>
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            width: '50%',
            margin: '0px 0px 10px 0'
          }}>
          <ReportTextField name="name" label={f('REPORT_PAGE_VIEW_FILTER_SELECTION_NAME')} applyRequirement={applyRequirement}/>
          <ReportTextField name="description" multiline label={f('REPORT_PAGE_VIEW_FILTER_SELECTION_DESCRIPTION')} />
        </Box>
        <Box sx={{display:'flex',justifyContent:'end',width:'100%'}}>
          <Button onClick={toggleSaveAsSection}>{f('REPORT_PAGE_VIEW_SECTION_SUBMIT_CANCEL')}</Button>
          <Button onClick={onSubmit('save')}>{f('REPORT_PAGE_VIEW_SECTION_SUBMIT_SAVE')}</Button>
        </Box>
      </>
    )
  },[applyRequirement, onSubmit])
}

export default React.memo(ReportsSaveAsView)
