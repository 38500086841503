import React, { FC } from 'react'
import Page, { PageHeader } from 'components/page'
import { useFormatMessage } from 'localization'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { Formik, Form } from "formik";
import { NotificationContainer } from 'components/notification'
import ReportTable from './components/report-table'
import ReportsPageSelectReportView from './components/reports-page-select-report'
import ReportsPageContext from './reports-page-context'
import useReportsPageForm from './utilities/reports-page-form'
import ReportsPageSectionsWrapperView from './components/reports-page-section'
import { BorderType } from './components/report-table/components/report-table-toolbar/report-table-toolbar-container'

const ReportPageInnerWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const ReportsPageView: FC = () => {
  const f = useFormatMessage()
  const { report, reportData, userReport } = ReportsPageContext.useState()
  const { ...formikFields } = useReportsPageForm()

  return (
    <NotificationContainer>
      <Page>
        <PageHeader
          title={f('REPORT_PAGE_VIEW_HEAD_TITLE')}
        />
        <ReportPageInnerWrapper>
          <Formik {...formikFields}>
            {(props) => (
              <Form>
                <ReportsPageSelectReportView />
                <ReportsPageSectionsWrapperView />
                <ReportTable
                  report={report}
                  reportData={reportData}
                  userReport={userReport}
                  enableRowCounter
                  enablePaging
                  pageSize={50}
                  enableBorderSelector
                  borders={BorderType.All}
                  formFields={props.values.fields}
                />
              </Form>
            )}
          </Formik>
        </ReportPageInnerWrapper>
      </Page>
    </NotificationContainer>
  )
}

export default ReportsPageView
