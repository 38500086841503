import React from 'react'
import { Box, useTheme } from '@mui/material'
import ModalHeader from 'components/modal-header'
import ModalFooter from 'components/modal-footer'
import TextButton from 'components/inputs/text-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import FilledTextField from 'components/inputs/filled-text-field'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'
import CenterModal from 'components/center-modal'
import { Form, Formik } from 'formik'
import Authentication from 'components/authentication'
import { UserTypeEnum } from 'api/tillit.api-client'
import Spinner from 'components/spinner'
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import isEmpty from 'lodash/isEmpty'
import { WorkScheduleContext } from './work-schedule-context'
import useWorkScheduleFormData from './utilities/use-work-schedule-form-data'


const WorkScheduleDialog: React.FC = () => {
  const f = useFormatMessage()
  const { workScheduleModalState, isLoading } = WorkScheduleContext.useState()
  const { closeWorkScheduleModal } = WorkScheduleContext.useActions()
  const theme = useTheme()
  const { initialValues, name, onSubmit, validationSchema } = useWorkScheduleFormData(workScheduleModalState.item)

  const { user: currentUser } = Authentication.useAuthenticatedState()
  const userIsSysAdminOrOrgAdminOrTeniloSupport = currentUser.userType === UserTypeEnum.OrganizationAdmin || currentUser.userType === UserTypeEnum.TeniloSupport || currentUser.userType === UserTypeEnum.SystemAdmin

  return (
    <CenterModal open={workScheduleModalState.open} onClose={closeWorkScheduleModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty, handleBlur, handleChange, touched }) => {
          const handleChangeScheduledStartTimeTicks = (val) => {
            setFieldValue('scheduledStartTimeTicks', val)
          }

          const handleChangeScheduledEndTimeTicks = (val) => {
            setFieldValue('scheduledEndTimeTicks', val)
          }

          return (
            <Form translate="yes">
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={workScheduleModalState.item?.description || f('ADMINISTER_PERSONNEL_WORKSCHEDULE_LABEL')}
                ml={3}
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>
                  <ModalSection title={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DIALOG_TITLE')} subtitle={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DIALOG_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          label={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_LABEL')}
                          name={name.description}
                          color="secondary"
                          fullWidth
                          disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection title={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_START_TIME_DIALOG_TITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <TimePicker
                          label={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_START_TIME_DIALOG_TITLE')}
                          value={values.scheduledStartTimeTicks}
                          ampm={false}
                          sx={{width:'100%'}}
                          onChange={handleChangeScheduledStartTimeTicks}
                          disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport}
                          slotProps={{
                            textField:{
                              variant:'filled',
                              error:touched && !!errors?.scheduledStartTimeTicks,
                              helperText:touched && errors?.scheduledStartTimeTicks
                            }
                          }}
                          localeText={{
                            toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection title={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_END_TIME_DIALOG_TITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <TimePicker
                          label={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_END_TIME_DIALOG_TITLE')}
                          value={values.scheduledEndTimeTicks}
                          ampm={false}
                          sx={{width:'100%'}}
                          onChange={handleChangeScheduledEndTimeTicks}
                          disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport}
                          slotProps={{
                            textField:{
                              variant:'filled',
                              error:touched && !!errors?.scheduledEndTimeTicks,
                              helperText:touched && errors?.scheduledEndTimeTicks
                            }
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          localeText={{
                            toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeWorkScheduleModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting || !isEmpty(errors)}>
                    {!workScheduleModalState.item ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default WorkScheduleDialog
