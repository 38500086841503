import React, { FC } from 'react';
import { Box, Typography, TextField, Checkbox, IconButton, Tooltip } from '@mui/material'
import {
  IOrganizationPlannedActivityStatusItem,
  IPlannedActivityStatusItem
} from 'api/tillit.api-client'
import { getSigningStatusLabel, getStatusColor } from 'utilities/signing-status-helpers'
import styled from 'styled-components'
import { useFormatMessage } from 'localization'
import { Form, Formik, Field  } from 'formik'
import { ContentSave, Delete, AlertCircleOutline  } from 'mdi-material-ui'
import useSigningFormData from '../utilities/use-signing-status-form-data'

type Props = Readonly<{
  plannedStatus?:  IPlannedActivityStatusItem
  orgPlannedStatus?: IOrganizationPlannedActivityStatusItem | null
  handleRemoveSigningStatus: (statusId: number | undefined) => () => Promise<void> | undefined
}>

const SigningStatusItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 5px;
  padding: 0 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 0px;
  >form {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    >*{
      flex:1;
      max-width: 400px;
      margin-right: 5px;
      &:last-child{
        max-width: none;
      }
      &:nth-child(4){
        flex:2;
        text-align: center;
      }
    }
    >.MuiButtonBase-root{
      flex-grow: 0;
      margin-left: 10px;
    }
  }
  `

const SigningStatusItemsHeaderWrapper = styled(SigningStatusItemWrapper)`
  >*{
    flex: 1;
    max-width: 400px;
    margin-right: 5px;
    &:last-child{
      max-width: none;
    }
    &:nth-child(3){
      flex-grow: 0;
      flex-basis: content;
      margin-left: 10px;
    }
    &:nth-child(4){
      flex:2
    }
  }
`
const SigningStatusItemActionsWrapper = styled(Box)`
  display: flex;
  height: 100%;
  justify-content: center;
  justify-content: flex-end;
`


export const SigningStatusItem : FC< Props > = ({ plannedStatus, orgPlannedStatus, handleRemoveSigningStatus }) => {
  const f = useFormatMessage()
  const { initialValues, name, onSubmit, validationSchema } = useSigningFormData({plannedStatus, orgPlannedStatus })

  return (
    <SigningStatusItemWrapper>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) => {
        return (
          <Form translate="yes">
            <Typography sx={{
              display: 'flex',
              alignItems:'center',
              ml:3
            }} variant="body2" >
              <AlertCircleOutline sx={{
                width:'20px',
                mr:'3px',
                color: getStatusColor(plannedStatus?.id).color
              }} />
              { getSigningStatusLabel(f,plannedStatus?.id) }
            </Typography>
            <Field
              variant="standard"
              error={props.errors.organizationTitle}
              disabled={props.values.disable}
              focused={false}
              name={name.organizationTitle}
              type="search"
              color="primary"
              fullWidth
              as={TextField}
            />
            <Field name={name.disable} type="checkbox" color="secondary" as={Checkbox} />
            <Typography variant="body2">
              {
                plannedStatus?.partial && (
                  f('SIGNING_STATUS_PARTIALLY_HELPER')
                )
              }
            </Typography>
            <SigningStatusItemActionsWrapper>
              <Tooltip title={f('ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_SAVE')} disableInteractive>
                <Box component="span">
                  <IconButton
                    type="submit"
                    disabled={
                      (props.initialValues.organizationTitle?.trim() === props.values.organizationTitle?.trim()) &&
                      (props.initialValues.disable === props.values.disable)
                    }  color="primary">
                    <ContentSave />
                  </IconButton>
                </Box>
              </Tooltip>
              <Tooltip title={f('ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_REMOVE')} disableInteractive>
                <Box component="span">
                  <IconButton color="secondary" disabled={!orgPlannedStatus?.id} onClick={handleRemoveSigningStatus(orgPlannedStatus?.id)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Tooltip>
            </SigningStatusItemActionsWrapper>
          </Form>
        )
      }}
      </Formik>
    </SigningStatusItemWrapper>
  );
};

export const SigningStatusItemsHeader : FC = () => {
  const f = useFormatMessage()
  return (
    <SigningStatusItemsHeaderWrapper>
      <Typography sx={{ml:3}} variant="subtitle2">{f('ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_DEFAULT_TITLE')}</Typography>
      <Typography variant="subtitle2">{f('ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ALIAS_TITLE')}</Typography>
      <Typography variant="subtitle2">{f('ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_DISABLE')}</Typography>
      <Typography variant="subtitle2" />
      <Typography variant="subtitle2" />
    </SigningStatusItemsHeaderWrapper>
  )
}

export const SigningStatusItemsMeasurementunitHeader : FC = () => {
  const f = useFormatMessage()
  return (
    <SigningStatusItemsHeaderWrapper>
      <Typography variant="subtitle2">{f('ADMINISTER_UNIT_MODAL_DIALOG_TYPE_MEASUREMENTUNIT')}</Typography>
    </SigningStatusItemsHeaderWrapper>
  )
}

export const SigningStatusItemsTimeunitHeader : FC = () => {
  const f = useFormatMessage()
  return (
    <SigningStatusItemsHeaderWrapper>
      <Typography variant="subtitle2">{f('ADMINISTER_UNIT_MODAL_DIALOG_TYPE_TIMEUNIT')}</Typography>
    </SigningStatusItemsHeaderWrapper>
  )
}

export const SigningStatusItemsOtherHeader : FC = () => {
  const f = useFormatMessage()
  return (
    <SigningStatusItemsHeaderWrapper>
      <Typography variant="subtitle2">{f('ADMINISTER_UNIT_MODAL_DIALOG_TYPE_OTHER')}</Typography>
    </SigningStatusItemsHeaderWrapper>
  )
}
