import React from 'react'
import { Box, Menu } from '@mui/material'
import ContainedButton from 'components/inputs/contained-button'
import { MenuDown } from 'mdi-material-ui'

type ContainedMenuButtonProps = Omit<React.ComponentProps<typeof ContainedButton>, 'endIcon' | 'forwardRef'> & { items: React.ReactElement[] }

const ContainedMenuButton: React.FC<ContainedMenuButtonProps> = props => {
  const { children, onClick, items, disabled, ...rest } = props
  const buttonEl = React.useRef<HTMLButtonElement>(null)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const handleClose = () => {
    setIsMenuOpen(false)
  }

  const handleMenuClick: React.ComponentProps<typeof MenuDown>['onClick'] = event => {
    if (disabled) {
      return
    }
    setIsMenuOpen(true)
  }

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick !== undefined && !isMenuOpen) {
      onClick(event)
    }
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        border: '1px solid black',
        [theme.breakpoints.down('md')]: {
          height: '50px',
          border: 'none',
          alignItems: 'flex-start',
          mt: 0.5
        }
      })}>
      <ContainedButton
        id="button-id-clicked"
        aria-haspopup="true"
        disabled={disabled}
        forwardRef={buttonEl}
        onClick={handleOnClick}
        containerStyle={{ margin: 0, width: '100%', height: '100%' }}
        sx={(theme) => ({
          width: '100%',
          fontSize: '12px',
          height: '100%',
          [theme.breakpoints.down('md')]: {
            padding: 0,
            borderRadius: 0,
            height: '25px',
            letterSpacing: '0.5px'
          }
        })}
        {...rest}
      >
        {children}
      </ContainedButton>
      <MenuDown sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          height: '25px',
          boxSizing: 'border-box',
          border: '1px solid gray',
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        }
      })} onClick={handleMenuClick}>send</MenuDown>
      <Menu
        anchorEl={buttonEl.current}
        keepMounted={true}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            '.MuiMenu-paper': {
              marginTop: '2px'
            },
            '.MuiMenu-list': {
              padding: 0
            },
            '.MuiMenuItem-root': {
              height: '25px',
              minHeight: '25px',
              fontSize: '13px',
              fontWeight: 500
            }
          }
        })}
      >
        {items.map((item, index) =>
          React.cloneElement(item, {
            key: index,
            onClick: (event: any) => {
              setIsMenuOpen(false)
              if (item !== undefined && item !== null && item.props.onClick) {
                item.props.onClick(event)
              }
            },
          })
        )}
      </Menu>
    </Box>
  )
}

export default React.memo(ContainedMenuButton)
