import { useSupportAreasApi } from 'api'
import { AreaItem, AreaUpdateItem } from 'api/tenilo.support.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
//import { AdministerSupportAreasContext } from '../administer-legislations/administer-legislations-context'

const useSupportAreaModalContext = () => {
  const { getArea, updateArea, anonymizeArea } = useSupportAreasApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedArea, setSelectedArea] = React.useState<AreaItem | undefined>()

  //const { fetchSupportAreasAsync } = AdministerSupportAreasContext.useActions()

  const fetchAreaAsync = React.useCallback(
    async (areaId: number) => {
      await fetchAsync(getArea(areaId)).then(area => {
        if (area instanceof AreaItem) {
          setSelectedArea(area)
        }
      })
    },
    [fetchAsync, getArea]
  )

  const openSupportAreaModal = React.useCallback(
    (areaId?: number) => {
      if (!!areaId) {
        void fetchAreaAsync(areaId).then(_ => setModalOpen(true))
      } else {
        setModalOpen(true)
      }
    },
    [fetchAreaAsync]
  )

  const closeSupportAreaModal = React.useCallback(() => {
    setSelectedArea(undefined)
    setModalOpen(false)
  }, [])

  const updateAreaAsync = React.useCallback(
    (area: AreaUpdateItem) => {
      return fetchAsync(updateArea(area))
        .then(() => {
          //void fetchSupportAreasAsync()
          closeSupportAreaModal()
        })
        .catch(() => {
          closeSupportAreaModal()
        })
    },
    [closeSupportAreaModal, fetchAsync, updateArea]
  )

  const anonymizeAreaAsync = React.useCallback(() => {
    if (!selectedArea) {
      return
    }
    return fetchAsync(anonymizeArea(selectedArea.id))
      .then(() => {
        closeSupportAreaModal()
      })
      .catch(() => {
        closeSupportAreaModal()
      })
  },
    [closeSupportAreaModal, fetchAsync, anonymizeArea, selectedArea]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedArea,
    },
    actions: {
      openSupportAreaModal,
      closeSupportAreaModal,
      updateAreaAsync,
      setSelectedArea,
      anonymizeAreaAsync
    },
  }
}

export const SupportAreaModalContext = createContext(useSupportAreaModalContext)
