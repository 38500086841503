import { useSupportUserImpersonationRequestsApi } from 'api'
import { UserItem, UserImpersonationRequestItem } from 'api/tenilo.support.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useSupportUserImpersonateModalContext = () => {
  const { createUserImpersonationRequest, deleteUserImpersonationRequest, abandonUserImpersonationRequest, getActiveUserImpersonationRequest, generateJwtTokenForUserImpersonationRequest } = useSupportUserImpersonationRequestsApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState<UserItem | undefined>()
  const [activeRequest, setActiveRequest] = React.useState<UserImpersonationRequestItem | undefined>()

  const openSupportUserImpersonateModal = React.useCallback(
    (userId: number) => {
      getActiveUserImpersonationRequestAsync(userId).then(() => setModalOpen(true))
    },
    []
  )

  const closeSupportUserImpersonateModal = React.useCallback(() => {
    setSelectedUser(undefined)
    setModalOpen(false)
  }, [])

  const createUserImpersonateRequestAsync = React.useCallback(
    (userId: number) => {
      setIsSubmitting(true)
      return fetchAsync(createUserImpersonationRequest({impersonateUserId: userId}))
        .then(() => {
          void getActiveUserImpersonationRequestAsync(userId)
        })
        .catch(() => {
          closeSupportUserImpersonateModal()
        })
    },
    [closeSupportUserImpersonateModal, fetchAsync, createUserImpersonationRequest]
  )

  const deleteUserImpersonateRequestAsync = React.useCallback(
    (id: number) => {
      return fetchAsync(deleteUserImpersonationRequest(id))
        .then(() => {
          closeSupportUserImpersonateModal()
        })
        .catch(() => {
          closeSupportUserImpersonateModal()
        })
    },
    [closeSupportUserImpersonateModal, fetchAsync, deleteUserImpersonationRequest]
  )

  const abandonUserImpersonateRequestAsync = React.useCallback(
    (id: number) => {
      return fetchAsync(abandonUserImpersonationRequest(id))
        .then(() => {
          closeSupportUserImpersonateModal()
        })
        .catch(() => {
          closeSupportUserImpersonateModal()
        })
    },
    [closeSupportUserImpersonateModal, fetchAsync, abandonUserImpersonationRequest]
  )

  const getActiveUserImpersonationRequestAsync = React.useCallback(
    (userId: number) => {
      return fetchAsync(getActiveUserImpersonationRequest(userId))
        .then((item) => {
          setActiveRequest(item)
        })
        .catch(() => {
          closeSupportUserImpersonateModal()
        })
    },
    [closeSupportUserImpersonateModal, fetchAsync, getActiveUserImpersonationRequest]
  )

  const generateJwtTokenForUserImpersonationRequestAsync = React.useCallback(
    async (userimpersonationrequestId: number) => {
      await fetchAsync(generateJwtTokenForUserImpersonationRequest(userimpersonationrequestId))
      /*.then(() => {
        console.log("jejjeje")
      })*/
    },
    [fetchAsync, generateJwtTokenForUserImpersonationRequest]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedUser,
      activeRequest,
      isSubmitting,
    },
    actions: {
      openSupportUserImpersonateModal,
      closeSupportUserImpersonateModal,
      createUserImpersonateRequestAsync,
      deleteUserImpersonateRequestAsync,
      abandonUserImpersonateRequestAsync,
      getActiveUserImpersonationRequestAsync,
      generateJwtTokenForUserImpersonationRequestAsync,
      setSelectedUser,
      setActiveRequest,
      setIsSubmitting,
    },
  }
}

export const SupportUserImpersonateModalContext = createContext(useSupportUserImpersonateModalContext)
