import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import EllipsisTypographyStyle from 'components/ellipsis-typography'
import Color from 'color'
import React from 'react'
import { useFormatMessage } from 'localization'

type Props = Readonly<
  {
    title: string
    color: string | undefined
    isActive: boolean
    isLocked: boolean
    isCommonActivity?: boolean
    roomNumber?: string
    initials: string
    customer: string
  } & BoxProps
>

const DailyActivitySubGroupItemContainer: React.FC<Props> = ({
  title,
  color,
  isActive,
  isLocked,
  isCommonActivity,
  roomNumber,
  initials,
  customer,
  ...rest
}) => {
  const f = useFormatMessage()

  const textColor = !isActive || Color(color).contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'

  const getTexts = () => {
    if (isCommonActivity) {
      return {
        title: f('DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT'),
      }
    }
    if (isLocked && !isCommonActivity) {
      return { title: roomNumber?.trim() || initials, subtitle: '' }
    }
    if (!isLocked && !isCommonActivity) {
      return {
        title: roomNumber?.trim() ? roomNumber : customer,
        subtitle: roomNumber?.trim() ? roomNumber : customer,
      }
    }
    return { title: '', subtitle: '' }
  }

  const texts = getTexts()

  return (
    <Box {...rest}>
      <Box className="left-pane">
        <EllipsisTypographyStyle
          variant={'subtitle2'}
          sx={{
            color: textColor,
            width:'100%',
            whiteSpace: 'nowrap'
          }}>
            {texts.title}
        </EllipsisTypographyStyle>
      </Box>
      <Box className={'right-pane'} />
    </Box>
  )
}

export default DailyActivitySubGroupItemContainer
