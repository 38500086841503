import React from 'react'
import { Portal, Alert, Stack, AlertTitle } from '@mui/material'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import NotificationContext from './notification-context'

const NotificationBarContainer = styled(Stack)`
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 80px;
  right: 5px;
  z-index: 100;
  ${({theme}) => [theme.customBreakpoints.justMobileTablet]}{
  }
`

const NotificationBar : React.FC = () => {
  const { alerts } = NotificationContext.useState()
  const { removeAlert } = NotificationContext.useActions()

  return (
    <>
      {
        isEmpty(alerts) ?
          null
        :
          <Portal>
            <NotificationBarContainer spacing={1}>
              {
                alerts.map((alert, i) => {
                  const { title, renderBody, ...alertProps }  = alert
                  const body =  renderBody ? renderBody() : ''
                  return (
                    <Alert {...alertProps} key={i}  onClose={removeAlert(i)} sx={{padding:'0 5px',alignItems:'center'}}>
                      <AlertTitle>{title}</AlertTitle>
                      {body}
                    </Alert>
                  )
                })
              }
            </NotificationBarContainer>
          </Portal>
      }
    </>
  );
}

export default NotificationBar
