import { useFormatMessage } from 'localization'

export const muiDataGridLocale = (f: ReturnType<typeof useFormatMessage>) => () => { //= { //}: GridLocaleText = {
  return {
    //Root
    noRowsLabel: f('muiLocaleNoRowsLabel'),
    noResultsOverlayLabel: f('muiLocaleNoResultsOverlayLabel'),
    errorOverlayDefaultLabel: f('muiLocaleErrorOverlayDefaultLabel'),

    // Density selector toolbar button text
    toolbarDensity: f('muiLocaleToolbarDensity'),
    toolbarDensityLabel: f('muiLocaleToolbarDensityLabel'),
    toolbarDensityCompact: f('muiLocaleToolbarDensityCompact'),
    toolbarDensityStandard: f('muiLocaleToolbarDensityStandard'),
    toolbarDensityComfortable: f('muiLocaleToolbarDensityComfortable'),

    // Columns selector toolbar button text
    toolbarColumns: f('muiLocaleToolbarColumns'),
    toolbarColumnsLabel: f('muiLocaleToolbarColumnsLabel'),

    // Filters toolbar button text
    toolbarFilters: f('muiLocaleToolbarFilters'),
    toolbarFiltersLabel: f('muiLocaleToolbarFiltersLabel'),
    toolbarFiltersTooltipHide: f('muiLocaleToolbarFiltersTooltipHide'),
    toolbarFiltersTooltipShow: f('muiLocaleToolbarFiltersTooltipShow'),
    toolbarFiltersTooltipActive: (count: number) => f('muiLocaleToolbarFiltersTooltipActive', { count }),

    // Export selector toolbar button text
    toolbarExport: f('muiLocaleToolbarExport'),
    toolbarExportLabel: f('muiLocaleToolbarExportLabel'),
    toolbarExportCSV: f('muiLocaleToolbarExportCSV'),
    toolbarExportPrint: f('muiLocaleToolbarExportPrint'),

    // Columns panel text
    columnsPanelTextFieldLabel: f('muiLocaleColumnsPanelTextFieldLabel'),
    columnsPanelTextFieldPlaceholder: f('muiLocaleColumnsPanelTextFieldPlaceholder'),
    columnsPanelDragIconLabel: f('muiLocaleColumnsPanelDragIconLabel'),
    columnsPanelShowAllButton: f('muiLocaleColumnsPanelShowAllButton'),
    columnsPanelHideAllButton: f('muiLocaleColumnsPanelHideAllButton'),

    // Filter panel text
    filterPanelAddFilter: f('muiLocaleFilterPanelAddFilter'),
    filterPanelDeleteIconLabel: f('muiLocaleFilterPanelDeleteIconLabel'),
    filterPanelOperators: f('muiLocaleFilterPanelOperators'),
    filterPanelOperatorAnd: f('muiLocaleFilterPanelOperatorAnd'),
    filterPanelOperatorOr: f('muiLocaleFilterPanelOperatorOr'),
    filterPanelColumns: f('muiLocaleFilterPanelColumns'),
    filterPanelInputLabel: f('muiLocaleFilterPanelInputLabel'),
    filterPanelInputPlaceholder: f('muiLocaleFilterPanelInputPlaceholder'),

    // Filter operators text
    filterOperatorContains: f('muiLocaleFilterOperatorContains'),
    filterOperatorEquals: f('muiLocaleFilterOperatorEquals'),
    filterOperatorStartsWith: f('muiLocaleFilterOperatorStartsWith'),
    filterOperatorEndsWith: f('muiLocaleFilterOperatorEndsWith'),
    filterOperatorIs: f('muiLocaleFilterOperatorIs'),
    filterOperatorNot: f('muiLocaleFilterOperatorNot'),
    filterOperatorAfter: f('muiLocaleFilterOperatorAfter'),
    filterOperatorOnOrAfter: f('muiLocaleFilterOperatorOnOrAfter'),
    filterOperatorBefore: f('muiLocaleFilterOperatorBefore'),
    filterOperatorOnOrBefore: f('muiLocaleFilterOperatorOnOrBefore'),
    filterOperatorIsEmpty: f('muiLocaleFilterOperatorIsEmpty'),
    filterOperatorIsNotEmpty: f('muiLocaleFilterOperatorIsNotEmpty'),
    filterOperatorIsAnyOf: f('muiLocaleFilterOperatorIsAnyOf'),

    // Filter values text
    filterValueAny: f('muiLocaleFilterValueAny'),
    filterValueTrue: f('muiLocaleFilterValueTrue'),
    filterValueFalse: f('muiLocaleFilterValueFalse'),

    // Column menu text
    columnMenuLabel: f('muiLocaleColumnMenuLabel'),
    columnMenuShowColumns: f('muiLocaleColumnMenuShowColumns'),
    columnMenuFilter: f('muiLocaleColumnMenuFilter'),
    columnMenuHideColumn: f('muiLocaleColumnMenuHideColumn'),
    columnMenuUnsort: f('muiLocaleColumnMenuUnsort'),
    columnMenuSortAsc: f('muiLocaleColumnMenuSortAsc'),
    columnMenuSortDesc: f('muiLocaleColumnMenuSortDesc'),

    // Column header text
    columnHeaderFiltersTooltipActive: (count: number) => f('muiLocaleColumnHeaderFiltersTooltipActive', { count }),


    //columnHeaderFiltersTooltipActive: (count) =>
    //count !== 1 ? `${count} active filters` : `${count} active filter`,

    //columnHeaderFiltersLabel: 'Show filters',
    //columnHeaderSortIconLabel: 'Sort',

    // Rows selected footer text
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ${f('muiLocaleFooterRowsSelected')}`
        : `${count.toLocaleString()} ${f('muiLocaleFooterRowSelected')}`,

    /*
  // Total rows footer text
  footerTotalRows: 'Total Rows:',
 
  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
 
  // Checkbox selection text
  checkboxSelectionHeaderName: 'Checkbox selection',
  checkboxSelectionSelectAllRows: 'Select all rows',
  checkboxSelectionUnselectAllRows: 'Unselect all rows',
  checkboxSelectionSelectRow: 'Select row',
  checkboxSelectionUnselectRow: 'Unselect row',
 
  // Boolean cell text
  booleanCellTrueLabel: 'true',
  booleanCellFalseLabel: 'false',
 
  // Actions cell more text
  actionsCellMore: 'more',
 
  // Column pinning text
  pinToLeft: 'Pin to left',
  pinToRight: 'Pin to right',
  unpin: 'Unpin',
 
  // Tree Data
  treeDataGroupingHeaderName: 'Group',
  treeDataExpand: 'see children',
  treeDataCollapse: 'hide children',
 
  // Grouping columns
  groupingColumnHeaderName: 'Group',
  groupColumn: (name) => `Group by ${name}`,
  unGroupColumn: (name) => `Stop grouping by ${name}`,
 
  // Master/detail
  expandDetailPanel: 'Expand',
  collapseDetailPanel: 'Collapse',
 
  // Used core components translation keys
  MuiTablePagination: {},*/
  }
}