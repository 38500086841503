import React from 'react'
import SupportPageContext from './support-page-context'
import SupportPageView from './support-page-view'

const SupportPageContainer: React.FC = () => {

  return (
    <SupportPageContext.Provider>
      <SupportPageView />
    </SupportPageContext.Provider>
  )
}

export default SupportPageContainer
