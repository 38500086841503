import React from 'react'
import { useFormatMessage } from 'localization'
import { isSearchValueValid } from './filter-helper'
import { UserImpersonationRequestListItem } from 'api/tenilo.support.api-client'

export const filterUserImpersonationRequests = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: UserImpersonationRequestListItem[]
): UserImpersonationRequestListItem[] => {
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const searchText = `${item.impersonateUser.fullName} ${item.impersonateUser.email} ${item.impersonateUser.organizationName} ${item.impersonateUser.phoneNumber} ${f(`UserImpersonationRequestStatus_${item.statusId}`)}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as UserImpersonationRequestListItem[]
  }
}

