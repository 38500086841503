export default {
ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_SUBTITLE:'Den förväntade mängd eller längd på aktiviteten mätt i tidsenhet eller mått/mängdenhet',
ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_TITLE:'Förväntad mängd / längd',
ACTIVITY_DIALOG_CONTAINER_FOOTER_ADD_BUTTON_TEXT:'Lägg till',
ACTIVITY_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Avbryt',
ACTIVITY_DIALOG_CONTAINER_HEADER_EDIT_TITLE:'Ändra aktivitet',
ACTIVITY_DIALOG_CONTAINER_HEADER_OVERLINE:' ',
ACTIVITY_DIALOG_CONTAINER_HEADER_SUBTITLE:' ',
ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE:'Lägg till aktivitet',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_FROM_START_TEXTFIELD_LABEL:'Minuter innan varning',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_EXPECTED_AMOUNT_LABEL:'Förväntad mängd / längd',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL:'Längd på aktiviteten',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_NAME_TEXTFIELD_LABEL:'Tilläggstext',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL:'min',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TEMPLATEACTIVITY_REQUIRED:'Aktivitetsmall måste anges',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_ROW2_SUBTITLE:'Hur lång tid aktiviteten uppskattas att ta.',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_ROW2_TITLE:'Längd',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE:'Kort tilläggstext till aktivitetsmall',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE:'Tilläggstext',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW1_SUBTITLE:'Startdatum för aktiviteten',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW1_TITLE:'Startdatum',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW2_SUBTITLE:'Mellan vilka tider aktiviteten bör infalla',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW2_TITLE:'Tidsintervall',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW3_SUBTITLE:'Antal minuter från när aktiviteten är utplanerad i tid och då varning (blinkning) skall påbörjas på aktivitetstavlan. Genom att sätta denna till 0 minuter inaktiveras varningen.',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW3_TITLE:'Varning',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW4_SUBTITLE:'Hur ofta aktiviteten skall återkomma',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW4_TITLE:'Upprepa',
ACTIVITY_DIALOG_CONTAINER_INFO_PLANNED_ACTIVITY_CANNOT_BE_CHANGED:'Det här besöket har planerade aktiviteter i framtiden, du kan bara ändra vissa uppgifter.',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_INFOTEXT_SUBTITLE:'Färg kan användas för att kategorisera olika aktiviteter och att få en snabb översyn över dessa i planeringsvyerna.',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_INFOTEXT_TITLE:'Färg',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_SHOW_ON_DEVICE:'Visa färg på aktivitetstavlan',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_SHOW_ON_DEVICE_SUBTITLE:'Skall färgen visas på aktivitetstavlan?',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLORPICKER_LABEL:'Färg',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_NAME_LABEL:'Namn',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL:'Beskrivning',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_AREA:'Beskrivning',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_ORG:'Beskrivning',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE:'Ytterligare information',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_AREA:'Beskrivning av aktivitetsmallen från avdelningen',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_ORG:'Beskrivning av aktivitetsmallen från organisationen',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE:'Beskrivning',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_AREA:'Avdelningsbeskrivning',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_ORG:'Organisationsbeskrivning',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS:'Deltagare',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS_SUBTITLE:'Personer som skall deltaga i aktiviteten',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_CRITICAL:'Tidskritisk',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_NORMAL:'Normal',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_SUBTITLE:'Aktivitetens prioritetsnivå',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_TITLE:'Prioritet',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_EVERYDAY_LABEL:'Varje dag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_FRIDAY_LABEL:'Fredag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_MONDAY_LABEL:'Måndag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_SATURDAY_LABEL:'Lördag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_SUNDAY_LABEL:'Söndag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_THURSDAY_LABEL:'Torsdag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_TUESDAY_LABEL:'Tisdag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_WEDNESDAY_LABEL:'Onsdag',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_ENDDATE_DATEPICKER_LABEL:'Slutdatum',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_MONTHLYDATEINTERVAL_TEXT:'Återkommande den {prefix, selectordinal, one {#:a} two {#:a} few {#:e} other {#:e}} {recurrenceInterval, plural, =1 {varje} =2 {varannan} other {var {recurrenceInterval}:e}} månad',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_MONTHLYDAYINTERVAL_TEXT:'Återkommande {prefix} {suffix}en {recurrenceInterval, plural, =1 {varje} =2 {varannan} other {var {recurrenceInterval}:e}} månad',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_STARTDATE_DATEPICKER_LABEL:'Startdatum',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TIMEFROM_TIMEPICKER_LABEL:'Från tid',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TIMETO_TIMEPICKER_LABEL:'Till tid',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_BIWEEKLY_TEXT:'Inträffar varannan {day} från och med {startDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_CHOOSE_ENDDATE_TEXT:'Välj ett slutdatum',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_DAILY_TEXT:'Inträffar var {recurrenceInterval}:e dag från och med {startDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_DELETE_ENDDATE_TEXT:'Ta bort slutdatum',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_ENDDATE_TEXT:'fram till {endDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_EVERYDAY_TEXT:'Inträffar varje dag från och med {startDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_MONTHLY_TEXT:'Inträffar den {monthlyDay} gånger {recurrenceInterval} månad från {startDate}.',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_WEEKLY_TEXT:'Inträffar varje {day} mellan {fromTime} och {toTime} från {startDate}.',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_YEARLY_TEXT:'Inträffar årligen.',
ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_LABEL_DEFAULT:'Aktivitetsmall',
ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_SUBTITLE:'Välj aktivitetsmall',
ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_TITLE:'Aktivitetsmall',
ACTIVITY_PARTICIPANT_ACCEPT_BUTTON_TOOLTIP:'{name} tackade ja till deltagande',
ACTIVITY_PARTICIPANT_CHOOSE_PARTICIPANT_TO_ADD:'Välj person att lägga till som deltagare',
ACTIVITY_PARTICIPANT_DECLINED:'Avböjde',
ACTIVITY_PARTICIPANT_DECLINE_BUTTON_TOOLTIP:'{name} avböjde deltagande',
ACTIVITY_PARTICIPANT_DELETE_BUTTON_TOOLTIP:'Ta bort {name}',
ACTIVITY_PARTICIPANT_PARTICIPATED:'Deltog',
ACTIVITY_TEMPORARY_DIALOG_CONTAINER_HEADER_EDIT_TITLE:'Ändra tillfällig aktivitet',
ACTIVITY_WEEK_REQUIRED_DAY_HELPER_TEXT:'Minst en veckodag måste vara vald',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK:'Kopiera länk',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_COPIED:'Länk kopierad',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_IP:'Kopiera IP-länk',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_MANAGE:'Administrera',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_OPEN_DEVICE:'Öppna tavla',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_HEAD_DEVICE:'Aktivitetstavla',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_HEAD_ID:'ID',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_HEAD_STATUS:'Status',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TITLE:'Aktiverade aktivitetstavlor',
ADMINISTER_ACTIVATED_DEVICE_SECTION_TITLE:'Alla aktiverade aktivitetstavlor',
ADMINISTER_DELEGATIONS_INHERITED_ADD_BUTTON_TEXT:'Koppla delegering',
ADMINISTER_DELEGATIONS_INHERITED_COLUMN_AREA:'Avdelning',
ADMINISTER_DELEGATIONS_INHERITED_COLUMN_CATEGORY:'Kategori',
ADMINISTER_DELEGATIONS_INHERITED_COLUMN_NAME:'Namn',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_BUTTON_TOOLTIP:'Ta bort {name}',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {title} från avdelning {areaName}, är du säker?',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_TITLE:'Ta bort kopplad delegering',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används som en aktiv delegering',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_INVALID_DIALOG_TITLE:'Ta bort delegering tillåts ej',
ADMINISTER_DELEGATIONS_INHERITED_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga kopplade delegeringar',
ADMINISTER_DELEGATIONS_MODAL_DIALOG_DELEGATION_CATEGORY_LABEL:'Kategori',
ADMINISTER_DELEGATIONS_ORGANIZATION_ADD_BUTTON_TEXT:'Lägg till delegering',
ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_CATEGORY:'Kategori',
ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_DESCRIPTION:'Beskrivning',
ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_NAME:'Namn',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_BUTTON_TOOLTIP:'Ta bort {name}',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {name}, är du säker?',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_TITLE:'Ta bort delegering',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den finns kopplad mot en aktivitetsmall',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_TITLE:'Ta bort delegering tillåts ej',
ADMINISTER_DELEGATIONS_ORGANIZATION_NO_VALUES_DISPLAYED_TEXT:'Inga tillgängliga delegeringar',
ADMINISTER_DELEGATIONS_PAGE_VIEW_HEADER:'Delegeringar',
ADMINISTER_DELEGATIONS_VIEW_CATEGORY_LABEL:'Kategorier',
ADMINISTER_DELEGATIONS_VIEW_ORGANIZATION_LABEL:'Organisation',
ADMINISTER_DELEGATION_CATEGORY_ADD_BUTTON_TEXT:'Lägg till kategori',
ADMINISTER_DELEGATION_CATEGORY_COLUMN_TITLE:'Kategori',
ADMINISTER_DELEGATION_CATEGORY_DELETE_BUTTON_TOOLTIP:'Ta bort {title}',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {title}, är du säker?',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_TITLE:'Ta bort kategori',
ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används på en delegering',
ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_TITLE:'Ta bort kategori tillåts ej',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra kategori',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_HEADER_TITLE:'Lägg till kategori',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Kategori',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_SUBTITLE:'Beskrivning av kategorin',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_TITLE:'Kategori',
ADMINISTER_DELEGATION_CATEGORY_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga kategorier',
ADMINISTER_DELEGATION_MODAL_DIALOG_DELEGATION_CATEGORY_SUBTITLE:'Välj kategori för delegeringen ifall tillämpbart',
ADMINISTER_DELEGATION_MODAL_DIALOG_DELEGATION_CATEGORY_TITLE:'Kategori',
ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_COMPONENT_LABEL_DEFAULT:'Beskrivning',
ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_SUBTITLE:'Beskrivning',
ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_TITLE:'Beskrivning',
ADMINISTER_DELEGATION_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra delegering',
ADMINISTER_DELEGATION_MODAL_DIALOG_HEADER_TITLE:'Lägg till delegering',
ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Delegering',
ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_SUBTITLE:'Kort beskrivande titel',
ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_TITLE:'Delegering',
ADMINISTER_DEVICE_SECTION_ACTIONS_SECTION_DESCRIPTION:'Denna aktivitetstavla är aktiverad och du kan växla läge för att se dagliga aktiviteter.',
ADMINISTER_DEVICE_SECTION_ACTIONS_SECTION_TITLE:'Aktivitetstavelshandlingar',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_BUTTON_ACTIVATE:'Aktivera aktivitetstavlan',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_BUTTON_DEACTIVATE:'Inaktivera aktivitetstavlan',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_DESCRIPTION_ACTIVATED:'Denna aktivitetstavla är nu aktiverad och kan visa dagliga aktiviteter, vänligen använd knappen nedan för att växla läge.',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_DESCRIPTION_NOT_ACTIVATED:'För att kunna se dagliga aktiviteter på denna aktivitetstavla måste den först aktiveras. Använd knappen till höger för att aktivera.',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_TITLE_ACTIVATED:'Denna aktivitetstavla är aktiverad',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_TITLE_NOT_ACTIVATED:'Denna aktivitetstavla är inte aktiverad',
ADMINISTER_DEVICE_SECTION_TITLE:'Denna aktivitetstavla',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_DISABLE:'Använd ej',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_REMOVE:'Ta bort egna ändringar',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_SAVE:'Spara',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ALIAS_TITLE:'Alias',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_DEFAULT_TITLE:'Förinställd titel',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_LABEL:'Signeringsstatusar',
ADMINISTER_DRUG_LIST_VIEW_UNIT_LABEL:'Enheter',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREADESCRIPTION_LABEL:'Beskrivning',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREADESCRIPTION_SUBTITLE:'Avdelningsbeskrivning kan användas för att komplettera eller ersätta beskrivningen av delegeringen från organisationen.',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREADESCRIPTION_TITLE:'Avdelningsbeskrivning',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREA_LABEL:'Avdelning',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREA_SUBTITLE:'Välj vilken avdelning kopplingen skall ske mot',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREA_TITLE:'Avdelning',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_COMBINATION_ALREADY_EXISTS:'Kombinationen av vald avdelning och delegering finns redan kopplad och kan inte kopplas ännu engång. Vänligen kontrollera gjorda val och försök på nytt!',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_DELEGATION_LABEL:'Kopplad delegering',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_DELEGATION_SUBTITLE:'Välj vilken delegering som skall kopplas',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_DELEGATION_TITLE:'Delegering',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra kopplad delegering',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_HEADER_TITLE:'Koppla delegering',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_ORGDESCRIPTION_LABEL:'Beskrivning',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_ORGDESCRIPTION_SUBTITLE:'Beskrivning av delegeringen från organisationen',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_ORGDESCRIPTION_TITLE:'Organisationsbeskrivning',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_USETEMPLATEDESCRIPTION_SUBTITLE:'Välj ifall organisationsbeskrivningen skall användas',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_USETEMPLATEDESCRIPTION_TITLE:'Använd organisationsbeskrivning',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL:'Beskrivning',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_SUBTITLE:'Avdelningsbeskrivning kan användas för att komplettera eller ersätta beskrivningen av aktivitetsmallen från organisationen.',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_TITLE:'Avdelningsbeskrivning',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_LABEL:'Avdelning',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_SUBTITLE:'Välj vilken avdelning kopplingen skall ske mot',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_TITLE:'Avdelning',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_COMBINATION_ALREADY_EXISTS:'Kombinationen av vald avdelning och aktivitetsmall finns redan kopplad och kan inte kopplas ännu engång. Vänligen kontrollera gjorda val och försök på nytt!',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra kopplad aktivitetsmall',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_HEADER_TITLE:'Koppla aktivitetsmall',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_LABEL:'Beskrivning',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_SUBTITLE:'Beskrivning av aktivitetsmallen från organisationen',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_TITLE:'Organisationsbeskrivning',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_LABEL:'Kopplad aktivitetsmall',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_SUBTITLE:'Välj vilken aktivitetsmall som skall kopplas',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_TITLE:'Aktivitetsmall',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_USETEMPLATEDESCRIPTION_SUBTITLE:'Välj ifall organisationsbeskrivningen skall användas',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_USETEMPLATEDESCRIPTION_TITLE:'Använd organisationsbeskrivning',
ADMINISTER_LEGISLATION_ADD_BUTTON_TEXT:'Lägg till lagrum',
ADMINISTER_LEGISLATION_COLUMN_DESCRIPTION:'Beskrivning',
ADMINISTER_LEGISLATION_COLUMN_TITLE:'Lagrum',
ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP:'Ta bort',
ADMINISTER_LEGISLATION_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_LEGISLATION_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_LEGISLATION_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {title}, är du säker?',
ADMINISTER_LEGISLATION_DELETE_DIALOG_TITLE:'Ta bort lagrum',
ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används på en aktivitetsmallskategori',
ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_TITLE:'Ta bort lagrum tillåts ej',
ADMINISTER_LEGISLATION_MODAL_DIALOG_DESCRIPTION_COMPONENT_LABEL_DEFAULT:'Beskrivning',
ADMINISTER_LEGISLATION_MODAL_DIALOG_DESCRIPTION_SUBTITLE:'Beskrivning',
ADMINISTER_LEGISLATION_MODAL_DIALOG_DESCRIPTION_TITLE:'Beskrivning',
ADMINISTER_LEGISLATION_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra lagrum',
ADMINISTER_LEGISLATION_MODAL_DIALOG_HEADER_TITLE:'Lägg till lagrum',
ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Lagrum',
ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_SUBTITLE:'Kort beskrivande titel',
ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_TITLE:'Lagrum',
ADMINISTER_LEGISLATION_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga lagrum',
ADMINISTER_MANAGE_DEVICE_DIALOG_ADD_ADDITIONALAREAS_TITLE:'Koppla avdelning',
ADMINISTER_MANAGE_DEVICE_DIALOG_BUTTON_CANCEL:'Avbryt',
ADMINISTER_MANAGE_DEVICE_DIALOG_BUTTON_SAVE:'Spara',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_COMBINED_SUBTITLE:'Lägg till avdelningar vars turer kommer vara tillgängliga för planerare att skapa kombinerade turer med.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_BUTTON:'Deaktivera aktivitetstavlan',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_SUBTITLE:'Avslutar en aktivitetstavla samt dess funktion att signera aktiviteter.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_TITLE:'Deaktivera aktivitetstavlan',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ADDITIONALAREAS_SUBTITLE:'Kopplade avdelningar',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ADDITIONALAREAS_TITLE:'Fler avdelningar',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_SUBTITLE:'Genom att tillåta åtkomst så visas en knapp på aktivitetstavlan som öppnar planeringen i skrivskyddat läge',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_TITLE:'Tillåt åtkomst till planeringskalender',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_SUBTITLE:'Genom att tillåta "Signera flera" så visas en knapp på aktivitetstavlan som möjligör signering av flera aktiviteter samtidigt',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_TITLE:'Tillåt "Signera flera"',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_DESCRIPTION_LABEL:'Beskrivning',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL:'Minuter',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_SUBTITLE:'Antal minuter innan låsning vid inaktivitet. 1-540 minuter.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_TITLE:'Antal minuter',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASENDTIME_LABEL:'Sluttid',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASSTARTTIME_LABEL:'Starttid',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASTIMES_SUBTITLE:'Välj mellan vilka tider på dygnet eller om det gäller hela dygnet som dessa avdelningar skall visas',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASTIMES_TITLE:'Tid för visning',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASWHOLEDAY_TITLE:'Hela dygnet',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREAS_SUBTITLE:'Här kan fler avdelningar kopplas. Dessa visas som flikar på aktivitetstavlan.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREAS_TITLE:'Visa fler avdelningar',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_USEMANUALSESSIONEXPIRATION_SUBTITLE:'Manuell tid för låsning låter dig välja hur lång tid som tillåts innan aktivitetstavlan låses vid inaktivitet.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_USEMANUALSESSIONEXPIRATION_TITLE:'Använd manuell tid för låsning',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_TITLE:'Beskrivning',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_MAIN_AREA_SUBTITLE:'Välj avdelning för denna aktivitetstavla',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_MAIN_AREA_TITLE:'Avdelning',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_FIELD_NAME_LABEL:'Namn',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_SUBTITLE:'Sätt ett namn för denna aktivitetstavla',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_TITLE:'Namn',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_FIELD_FROM_TIME_LABEL:'Från tid',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_FIELD_TO_TIME_LABEL:'Till tid',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_SUBTITLE:'Välj mellan vilka tider som kombineringen skall ske.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_TITLE:'Tider för kombinering',
ADMINISTER_MANAGE_DEVICE_DIALOG_TITLE:'Administrera aktivitetstavla',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_BUTTON_ACTIVATE:'Aktivera',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_BUTTON_CANCEL:'Avbryt',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_ALLOWNAVIGATE:'Tillåt åtkomst till planeringskalendern på aktivitetstavlan',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_ALLOWUSEMULTISIGNING:'Tillåt "Signera flera"',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_AREA:'Sök avdelning',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_DESCRIPTION:'Beskrivning',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_NAME:'Namn',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_USEMANUALSESSIONEXPIRATION:'Använd manuell tid för låsning',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_SUBTITLE:' ',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_TITLE:'Aktivera aktivitetstavlan',
ADMINISTER_PAGE_NEW_ACTIVE_DEVICE_DIALOG_TITLE:'Lägg till och aktivera ny aktivitetstavla',
ADMINISTER_PAGE_TITLE:'Administrera',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_HEADER_DESCRIPTION:'Lista över samtliga aktivitetstavlor som är aktiverade på dina avdelningar.',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_HEADER_TITLE:'Aktiverade aktivitetstavlor',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_ACTIVATEDBY_COLUMN_HEADER:'Aktiverad av',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_ACTIVATEDON_COLUMN_HEADER:'Aktiveringsdatum',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_AREAS_COLUMN_HEADER:'Avdelningar',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_CREATEDBY_TITLE:'Skapad av',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_CREATEDON_TITLE:'Datum',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_DEVICE_TITLE:'Aktivitetstavla',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_KIOSKNAME_TITLE:'Kiosknamn',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_STATUSID_TITLE:'Status ID',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_STATUS_APPROVED:'Godkänd',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_STATUS_NOTAPPROVED:'Ej godkänd',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TITLE:'Alla aktiverade aktivitetstavlor',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_ADD_DEVICE_DESCRIPTION:'Lägg till och aktivera en ny aktivitetstavla',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_ADD_DEVICE_TITLE:'Lägg till ny aktivitetstavla',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_BUTTON_Add_DEVICE_TEXT:'Lägg till aktivitetstavla',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_DESCRIPTION:'För att kunna visa dagliga aktiviteter på denna aktivitetstavla behöver den först bli aktiverad. Tryck på knappen till höger för att aktivera.',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_HEADER_SUBTITLE:'Denna aktivitetstavla är inte aktiverad.',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_NEW_HEADER_TITLE:'Ny aktivitetstavla',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_NOTACTIVATED_TITLE:'Aktivitetstavlan är inte aktiverad',
ADMINISTER_PAGE_VIEW_HEADER_TITLE:'Administrera',
ADMINISTER_PAGE_VIEW_TAB_0_TITLE:'Aktivitetstavlor',
ADMINISTER_PAGE_VIEW_TAB_1_TITLE:'Aktivitetsmallar',
ADMINISTER_PAGE_VIEW_TAB_2_TITLE:'Lagrum',
ADMINISTER_PAGE_VIEW_TAB_3_TITLE:'Delegeringar',
ADMINISTER_PAGE_VIEW_TAB_4_TITLE:'Läkemedelslista',
ADMINISTER_PAGE_VIEW_TAB_5_TITLE:'Personalhantering',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_ADD_BUTTON_TEXT:'Koppla arbetspass',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_DIALOG_SUBTITLE:'Välj en avdelning',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_DIALOG_TITLE:'Avdelning',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_TITLE:'Avdelning',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_CHOOSE_TO_CONNECT_BUTTON_TEXT:'Välj arbetspass att koppla',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_HEADER:'Koppla arbetspass',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_SUBTITLE:'Välj ett arbetspass',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_TITLE:'Arbetspass',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_LABEL:'Kopplade arbetspass',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TITLE:'Arbetspass',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_BUTTON_ABORT:'Avbryt',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_BUTTON_CONNECT:'Koppla',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_COLUMN_AREA:'Avdelning',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_TITLE:'Välj avdelningar att koppla till...',
ADMINISTER_PERSONNEL_WORKSCHEDULE_ADD_BUTTON_TEXT:'Lägg till arbetspass',
ADMINISTER_PERSONNEL_WORKSCHEDULE_CONNECT_BUTTON_TEXT:'Koppla valda arbetspass',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_BUTTON_TEXT:'Ta bort {title}',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {title}, är du säker?',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_SHIFT_DETAILS:'{description} ({from} till {to})',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_TITLE:'Ta bort arbetspass',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används på en avdelning',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_TITLE:'Ta bort arbetspass tillåts ej',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DESCRIPTION:'Beskrivning',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DIALOG_SUBTITLE:'Beskrivning',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DIALOG_TITLE:'Arbetspass',
ADMINISTER_PERSONNEL_WORKSCHEDULE_END_TIME:'Slut',
ADMINISTER_PERSONNEL_WORKSCHEDULE_END_TIME_DIALOG_TITLE:'Sluttid',
ADMINISTER_PERSONNEL_WORKSCHEDULE_LABEL:'Arbetspass',
ADMINISTER_PERSONNEL_WORKSCHEDULE_START_TIME:'Start',
ADMINISTER_PERSONNEL_WORKSCHEDULE_START_TIME_DIALOG_TITLE:'Starttid',
ADMINISTER_SEARCH_AREA_COMPONENT_LABEL_DEFAULT:'Sök avdelning',
ADMINISTER_SELECTED_VISIBLE_AREA_SUBTITLE:'Välj vilken eller vilka avdelningar du vill filtrera på (Filtreringen sker över hela applikationen tills den tas bort eller ändras)',
ADMINISTER_SELECTED_VISIBLE_AREA_TITLE:'Välj avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_ADD_BUTTON_TEXT:'Lägg till aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_AREA:'Avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_CATEGORY:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_LEGISLATION:'Lagrum',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_TITLE:'Namn',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_BUTTON_TOOLTIP:'Ta bort {name}',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {name}, är du säker?',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_TITLE:'Ta bort aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används på en aktivitet',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_TITLE:'Ta bort aktivitetsmall tillåts ej',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga aktivitetsmallar',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_ADD_BUTTON_TEXT:'Koppla aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_AREA:'Avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_CATEGORY:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_LEGISLATION:'Lagrum',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_NAME:'Namn',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_BUTTON_TOOLTIP:'Ta bort {name}',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {title} från avdelning {areaName}, är du säker?',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_TITLE:'Ta bort kopplad aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används på en aktivitet',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_TITLE:'Ta bort aktivitetsmall tillåts ej',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga kopplade aktivitetsmallar',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_LABLE:'Delegering',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_SUBTITLE:'Lägg till delegeringar som krävs för den här aktivitetsmallen.',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_TITLE:'Delegeringskrav',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_SUBTITLE:'Välj avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_TITLE:'Avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CATEGORY_SUBTITLE:'Kategori för aktivitetsmallen',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CATEGORY_TITLE:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_CANCEL:'Avbryt',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_CONFIRM:'Ja',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_MESSAGE:'Är du säker på att du vill flytta aktivitetsmallen till organisationsnivån?',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_TITLE:'Flytta aktivitetsmall till organisationsnivå',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_EDIT_TITLE:'Ändra aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_ALERT_MINUTES_TEXTFIELD_LABEL:'Minuter innan varning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL:'Längd',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL:'min',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_TITLE_TEXTFIELD_LABEL:'Aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_ALERT_MINUTES_SUBTITLE:'Antal minuter från när aktiviteten är utplanerad i tid och då varning (blinkning) skall påbörjas på aktivitetstavlan. Genom att sätta denna till 0 minuter inaktiveras varningen.',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_ALERT_MINUTES_TITLE:'Varning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_LENGTH_SUBTITLE:'Hur lång tid aktiviteten uppskattas att ta.',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_LENGTH_TITLE:'Längd (minuter)',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_SUBTITLE:'Information om aktivitetsmallen. En beskrivande titel',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_TITLE:'Information',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_BUTTON_TEXT:'Flytta till organisationsnivå',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_SUBTITLE:'Flytta aktivitetsmall till organisationsnivå',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_TITLE:'Flytta aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_INFOTEXT_SUBTITLE:'Färg kan användas för att kategorisera olika aktiviteter och att få en snabb översyn över dessa i planeringsvyerna.',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_INFOTEXT_TITLE:'Färg',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_SHOW_ON_DEVICE_INFOTEXT_SUBTITLE:'Skall färgen visas på aktivitetstavlan?',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_SHOW_ON_DEVICE_INFOTEXT_TITLE:'Visa färg på aktivitetstavlan',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_LABEL:'Beskrivning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_SUBTITLE:'Ytterligare beskrivning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_TITLE:'Beskrivning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_USEPARTICIPANTLIST_INFOTEXT_SUBTITLE:'Skall mallen tillåta användande av deltagarlista?',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_USEPARTICIPANTLIST_INFOTEXT_TITLE:'Deltagarlista',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_AREA_COMPONENT_LABEL_DEFAULT:'Avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_CATEGORY_COMPONENT_LABEL_DEFAULT:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_UNIT_AMOUNT_COMPONENT_LABEL_DEFAULT:'Förväntad mängd',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_UNIT_COMPONENT_LABEL_DEFAULT:'Enhet',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_CRITICAL:'Tidskritisk',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_NORMAL:'Normal',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_SUBTITLE:'Aktivitetens prioritetsnivå',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_TITLE:'Prioritet',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TITLE:'Lägg till aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_AREA:'Allmän',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_CUSTOMER:'Kund',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_SUBTITLE:'Typen avgör aktivitetsmallens användningsområde. Valet Kund gör aktivitetsmallen tillgänglig vid skapande av aktivitet för kund. Valet Allmän gör aktivitetsmallen tillgänglig på avdelningsnivå för allmänna aktiviteter.',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_TITLE:'Typ',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_UNIT_AMOUNT_SUBTITLE:'Den förväntade mängd eller längd mätt i tidsenhet eller mått/mängdenhet kopplat till vald enhet',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_UNIT_AMOUNT_TITLE:'Förväntad mängd / längd och enhet',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_ABORT_CONNECT_TEMPLATES_BUTTON_TEXT:'Avbryt',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_ADD_BUTTON_TEXT:'Lägg till aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_CHOOSE_TEMPLATES_TO_CONNECT_BUTTON_TEXT:'Välj mallar att koppla',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_CATEGORY:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_LEGISLATION:'Lagrum',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_NAME:'Namn',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_TYPE:'Typ',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_CONNECT_TEMPLATES_BUTTON_TEXT:'Koppla valda mallar',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_BUTTON_TOOLTIP:'Ta bort {name}',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {name}, är du säker?',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_TITLE:'Ta bort aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den finns kopplad mot en avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_TITLE:'Ta bort aktivitetsmall tillåts ej',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga aktivitetsmallar',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_AREA_LABEL:'Avdelning',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_CATEGORY_LABEL:'Kategorier',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_INHERITANCE_LABEL:'Kopplade aktivitetsmallar',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_ORGANIZATION_LABEL:'Organisation',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra kategori',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_HEADER_TITLE:'Lägg till kategori',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_LEGISLATION_SUBTITLE:'Välj lagrum ifall tillämpbart',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_LEGISLATION_TITLE:'Lagrum',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_SELECT_LEGISLATION_COMPONENT_LABEL_DEFAULT:'Lagrum',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_SUBTITLE:'Beskrivning av kategorin',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_TITLE:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_ADD_BUTTON_TEXT:'Lägg till kategori',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_LEGISLATION:'Lagrum',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_TITLE:'Kategori',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_BUTTON_TOOLTIP:'Ta bort {title}',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {title}, är du säker?',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_TITLE:'Ta bort kategori',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används på en aktivitetsmall',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_TITLE:'Ta bort kategori tillåts ej',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga kategorier',
ADMINISTER_UNIT_ADD_BUTTON_TEXT:'Lägg till enhet',
ADMINISTER_UNIT_COLUMN_ABBREVIATION:'Förkortning',
ADMINISTER_UNIT_COLUMN_TITLE:'Enhet',
ADMINISTER_UNIT_DELETE_BUTTON_TOOLTIP:'Ta bort {title}',
ADMINISTER_UNIT_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
ADMINISTER_UNIT_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
ADMINISTER_UNIT_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {title}, är du säker?',
ADMINISTER_UNIT_DELETE_DIALOG_TITLE:'Ta bort enhet',
ADMINISTER_UNIT_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_UNIT_DELETE_INVALID_DIALOG_MESSAGE:'Du kan ej ta bort {title} då den används på en aktivitetsmall',
ADMINISTER_UNIT_DELETE_INVALID_DIALOG_TITLE:'Ta bort enhet tillåts ej',
ADMINISTER_UNIT_MODAL_DIALOG_ABBREVIATION_COMPONENT_LABEL_DEFAULT:'Förkortning',
ADMINISTER_UNIT_MODAL_DIALOG_ABBREVIATION_SUBTITLE:'Enhetsförkortning',
ADMINISTER_UNIT_MODAL_DIALOG_ABBREVIATION_TITLE:'Förkortning',
ADMINISTER_UNIT_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra enhet',
ADMINISTER_UNIT_MODAL_DIALOG_HEADER_TITLE:'Lägg till enhet',
ADMINISTER_UNIT_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Enhet',
ADMINISTER_UNIT_MODAL_DIALOG_TITLE_SUBTITLE:'Enhetsbeskrivning',
ADMINISTER_UNIT_MODAL_DIALOG_TITLE_TITLE:'Enhet',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_MEASUREMENTUNIT:'Mått/mängd',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_OTHER:'Övrigt',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_SUBTITLE:'Enhetstyp',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_TIMEUNIT:'Ordinarie / Tid',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_TITLE:'Typ',
ADMINISTER_UNIT_NO_VALUES_DIPLAYED_TEXT:'Inga tillgängliga enheter',
AREAS_DIALOG_TAB_TEMPORARY_ACTITIVIES_TITLE:'Tillfälliga Allmänna Aktiviteter',
AREAS_DIALOG_VIEW_TAB_AREAS_TITLE:'Sektion och avdelning',
AREAS_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basinformation',
AREAS_DIALOG_VIEW_TAB_DESIRED_ACTIVITIES_TITLE:'Allmänna Aktiviteter',
AREAS_PAGE_VIEW_COLUMN_NAME:'Avdelningsnamn',
AREAS_PAGE_VIEW_COLUMN_ORGANIZATION_PARENT_NAME:'Organisationsstruktur',
AREAS_PAGE_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'Inga avdelningar finns tillgängliga',
AREAS_PAGE_VIEW_HEAD_DESCRIPTION:'Översikt av dina avdelningar',
AREAS_PAGE_VIEW_HEAD_TITLE:'Avdelningar',
AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_AREA_NAME_SUBTITLE:'Namnet på avdelningen',
AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_AREA_NAME_TITLE:'Avdelningsnamn',
AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INTERNAL_INFORMATION_TITLE:'Supportinformation för avdelningen',
AREA_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL:'Övrig information',
AREA_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_INFOTEXT_SUBTITLE:'Övrig information',
AREA_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_INFOTEXT_TITLE:'Övrig information',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_SUBTITLE:'Genom att tillåta åtkomst så visas en knapp på appen som öppnar planeringen i skrivskyddat läge',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_TITLE:'Tillåt åtkomst till planeringskalender på app',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_SUBTITLE:'Genom att tillåta "Signera flera" så visas en knapp på appen som möjligör signering av flera aktiviteter samtidigt',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_TITLE:'Tillåt "Signera flera" på app',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_SUPPRESSTIMESONKIOSKANDPWA_SUBTITLE:'Genom att aktivera denna inställning så kommer tider (start, slut, längd) att döljas för avdelningen. Observera att inställningen EJ påverkar tidskritiska aktiviteter eller aktiviteter där tid för varning (blinkning) har angivits (skiljt i från 0). ',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_SUPPRESSTIMESONKIOSKANDPWA_TITLE:'Dölj tider på aktivitetstavla och app',
BASIC_AREA:'Avdelning',
BASIC_AREA_EMPTY_INPUT_TEXT:'--Inga tillgängliga avdelningar--',
BASIC_BUTTON_ABORT_TEXT:'Stoppa',
BASIC_BUTTON_ADD_TEXT:'Lägg till',
BASIC_BUTTON_BACK_TEXT:'Tillbaka',
BASIC_BUTTON_CANCEL_TEXT:'Avbryt',
BASIC_BUTTON_CLOSE_TEXT:'Stäng',
BASIC_BUTTON_LOGOUT_TEXT:'Logga ut',
BASIC_BUTTON_MANAGE_TEXT:'Hantera',
BASIC_BUTTON_OK_TEXT:'OK',
BASIC_BUTTON_REMOVE_TEXT:'Ta bort',
BASIC_BUTTON_SAVE_TEXT:'Spara',
BASIC_BUTTON_SEND_TEXT:'Skicka',
BASIC_BUTTON_SUSPEND_TEXT:'Avsluta',
BASIC_BUTTON_UPDATE_TEXT:'Uppdatera',
BASIC_COMMENT_TITLE_TEXT:'Kommentar',
BASIC_CUSTOMER:'Kund',
BASIC_CUSTOMER_LINK_TEXT:'Länk till kund',
BASIC_DATE_DAY_FRIDAY_TEXT:'Fredag',
BASIC_DATE_DAY_MONDAY_TEXT:'Måndag',
BASIC_DATE_DAY_SATURDAY_TEXT:'Lördag',
BASIC_DATE_DAY_SUNDAY_TEXT:'Söndag',
BASIC_DATE_DAY_THURSDAY_TEXT:'Torsdag',
BASIC_DATE_DAY_TUESDAY_TEXT:'Tisdag',
BASIC_DATE_DAY_WEDNESDAY_TEXT:'Onsdag',
BASIC_DATE_MONTH_APRIL_TEXT:'April',
BASIC_DATE_MONTH_AUGUST_TEXT:'Augusti',
BASIC_DATE_MONTH_DECEMBER_TEXT:'December',
BASIC_DATE_MONTH_FEBRUARY_TEXT:'Februari',
BASIC_DATE_MONTH_JANUARY_TEXT:'Januari',
BASIC_DATE_MONTH_JULY_TEXT:'Juli',
BASIC_DATE_MONTH_JUNE_TEXT:'Juni',
BASIC_DATE_MONTH_MARCH_TEXT:'Mars',
BASIC_DATE_MONTH_MAY_TEXT:'Maj',
BASIC_DATE_MONTH_NOVEMBER_TEXT:'November',
BASIC_DATE_MONTH_OCTOBER_TEXT:'Oktober',
BASIC_DATE_MONTH_SEPTEMBER_TEXT:'September',
BASIC_DECLINED_PARTICIPANT_TITLE_TEXT:'Personer som avböjde',
BASIC_DELEGATED_BY:'Delegerad av',
BASIC_DESCRIPTION_AREA_TITLE_TEXT:'Avdelningsbeskrivning',
BASIC_DESCRIPTION_ORGANIZATION_TITLE_TEXT:'Organisationsbeskrivning',
BASIC_DESCRIPTION_OWNER_CUSTOMER_DESCRIPTION_TITLE_TEXT:'Övrig information',
BASIC_DESCRIPTION_TITLE_TEXT:'Beskrivning',
BASIC_ERROR_MAX:'Max {max}',
BASIC_ERROR_MIN:'Min {min}',
BASIC_INTEGER_FIELD_HELPER_TEXT:'{name} måste vara ett heltal',
BASIC_INVALID_TIME_FIELD_HELPER_TEXT:'Ogiltig tid',
BASIC_LABEL_AREA_SELECT_INPUT_TEXT:'Avdelning',
BASIC_LABEL_EMAIL_TEXT:'E-post',
BASIC_LABEL_FROM_DATE_SELECT_INPUT_DATE:'Från Datum',
BASIC_LABEL_TO_DATE_SELECT_INPUT_DATE:'Till Datum',
BASIC_NO_RESULT:'Inget resultat',
BASIC_PARTICIPANT_TITLE_TEXT:'Personer som deltog',
BASIC_PLANNED_PARTICIPANT_TITLE_TEXT:'Planerade deltagare',
BASIC_PLANNING_LINK_TEXT:'Länk till planering',
BASIC_REQUIRED_FIELD_HELPER_TEXT:'Obligatoriskt fält',
BASIC_REQUIRED_PASSWORD_LENGTH_HELPER_TEXT:'Lösenordet måste vara minst 8 tecken',
BASIC_ROW_TEXT:'Rad',
BASIC_SEARCH_FIELD_PLACEHOLDER:'Sök',
BASIC_SHORT_DAY_TEXT:'d',
BASIC_SHORT_HOUR_TEXT:'t',
BASIC_SHORT_MINUTE_TEXT:'m',
BASIC_SIGNINGMODAL_LINK_TEXT:'Öppna signering',
BASIC_TEXT_AREA_COUNT_MULTIPLE_TEXT:'Avdelningar',
BASIC_TEXT_AREA_COUNT_ONE_TEXT:'Avdelning',
BASIC_TEXT_AREA_OR_ROUTE_COUNT_ZERO_TEXT:'Inga avdelningar/turer',
BASIC_TEXT_COPY:'kopia',
BASIC_TEXT_MINUTES:'Minuter',
BASIC_TEXT_MINUTES_ABBREVIATION:'min',
BASIC_TEXT_NO:'Nej',
BASIC_TEXT_ROUTE_COUNT_MULTIPLE_TEXT:'turer',
BASIC_TEXT_ROUTE_COUNT_ONE_TEXT:'tur',
BASIC_TEXT_WEEKDAY_FRIDAY:'Fredag',
BASIC_TEXT_WEEKDAY_MONDAY:'Måndag',
BASIC_TEXT_WEEKDAY_SATURDAY:'Lördag',
BASIC_TEXT_WEEKDAY_SUNDAY:'Söndag',
BASIC_TEXT_WEEKDAY_THURSDAY:'Torsdag',
BASIC_TEXT_WEEKDAY_TUESDAY:'Tisdag',
BASIC_TEXT_WEEKDAY_WEDNESDAY:'Onsdag',
BASIC_TEXT_YES:'Ja',
BASIC_USERDELEGATION_LINK_TEXT:'Länk till delegering',
BASIC_USER_LINK_TEXT:'Länk till användare',
BASIC_VALID:'Giltig',
BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES:'{name} får vara som mest {maxsize} minuter.',
BASIC_WARNING_MAXSIZE_FIELD_TEXT:'{name} får vara som mest {maxsize} tecken långt.',
BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES:'{name} får vara som minst {minsize} minuter.',
BASIC_WARNING_REQUIRED_FIELD_TEXT:'{Username {Användarnamn} Password {Lösenord}} är ett obligatoriskt fält.',
BULLETIN_BOARD_CONTAINER_BUTTON_CLOSE:'Stäng anslagstavlan',
BULLETIN_BOARD_CONTAINER_BUTTON_CREATE_NEW:'Skapa notis',
BULLETIN_BOARD_ITEM_MODAL_COLOR_TITLE:'Färg',
BULLETIN_BOARD_ITEM_MODAL_CONTENT_SUBTITLE:' ',
BULLETIN_BOARD_ITEM_MODAL_CONTENT_TITLE:'Innehåll',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_CONTENT_LABEL:'Innehåll',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_EDIT_TITLE:'Ändra notis',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_LABEL:'Rubrik',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_TITLE:'Skapa notis',
BULLETIN_BOARD_ITEM_MODAL_HEADER_SUBTITLE:' ',
BULLETIN_BOARD_ITEM_MODAL_HEADER_TITLE:'Rubrik',
CHANGE_PASSWORD_DASHBOARD_SECTION_BUTTON_TEXT:'Byt lösenord',
CHANGE_PASSWORD_DASHBOARD_SECTION_SUBTITLE:'Byt lösenord för denna användaren',
CHANGE_PASSWORD_DASHBOARD_SECTION_TITLE:'Byt lösenord',
CHANGE_PASSWORD_SCENE_CHANGE_PASSWORD_BUTTON_TEXT:'Fortsätt',
CHANGE_PASSWORD_SCENE_CHANGE_PASSWORD_TITLE:'Byt lösenord',
CHANGE_PASSWORD_SCENE_CONFIRM_PASSWORD_ERROR_TEXT:'{type, plural, =1 {Nuvarande lösenord är inkorrekt} =2 {Lösenordet kan inte innehålla din e-post} =100 {Lösenordet är ändrat} other {}}',
CHANGE_PASSWORD_SCENE_CONFIRM_PASSWORD_INPUT_LABEL:'Bekräfta lösenord',
CHANGE_PASSWORD_SCENE_CURRENT_PASSWORD_INPUT_LABEL:'Nuvarande lösenord',
CHANGE_PASSWORD_SCENE_LOGIN_BUTTON_TEXT:'Logga in',
CHANGE_PASSWORD_SCENE_NEW_PASSWORD_CANNOT_CONTAIN_SPACES_ERROR_TEXT:'Lösenord får inte innehålla mellanrum',
CHANGE_PASSWORD_SCENE_NEW_PASSWORD_INPUT_LABEL:'Nytt lösenord',
COMPOUNDED_TYPE_AREA:'Typ: Avdelning',
COMPOUNDED_TYPE_CUSTOMER:'Typ: Kund',
CONFIRMATION_BOX_CREATED:'Skapat',
CONFIRMATION_BOX_CREATED_ACTIVITY:'Aktiviteten skapad',
CONFIRMATION_BOX_CREATED_AREA:'Avdelningen skapad',
CONFIRMATION_BOX_CREATED_CUSTOMER:'Kunden skapad',
CONFIRMATION_BOX_CREATED_KIOSK:'Aktivitetstavlan skapad',
CONFIRMATION_BOX_CREATED_USER:'Användaren skapad',
CONFIRMATION_BOX_DELETED:'Raderad',
CONFIRMATION_BOX_DELETED_ACTIVITY:'Aktiviteten raderad',
CONFIRMATION_BOX_DELETED_AREA:'Avdelningen raderad',
CONFIRMATION_BOX_DELETED_CUSTOMER:'Kunden raderad',
CONFIRMATION_BOX_DELETED_KIOSK:'Aktivitetstavlan raderad',
CONFIRMATION_BOX_DELETED_USER:'Lyckats',
CONFIRMATION_BOX_SUCCEEDED:'Lyckats',
CONFIRMATION_BOX_UPDATED:'Uppdaterad',
CONFIRMATION_BOX_UPDATED_ACTIVITY:'Aktiviteten uppdaterad',
CONFIRMATION_BOX_UPDATED_AREA:'Avdelningen uppdaterad',
CONFIRMATION_BOX_UPDATED_CUSTOMER:'Kunden uppdaterad',
CONFIRMATION_BOX_UPDATED_KIOSK:'Aktivitetstavlan uppdaterad',
CONFIRMATION_BOX_UPDATED_USER:'Användaren uppdaterad',
CONNECT_TEMPLATE_ACTIVITIES_TO_AREAS_MODAL_BUTTON_CONNECT:'Koppla',
CONNECT_TEMPLATE_ACTIVITIES_TO_AREAS_MODAL_COLUMN_AREA:'Avdelning',
CONNECT_TEMPLATE_ACTIVITIES_TO_AREAS_MODAL_TITLE:'Välj avdelningar att koppla till...',
CONTACT_SUPPORT_INFO_TEXT_SUBTITLE:'Telefonnummer support',
CONTACT_SUPPORT_INFO_TEXT_TITLE:'E-postadress support',
CREATE_ACTIVITY_DAILY:'Dag',
CREATE_ACTIVITY_MONTHLY:'Månad',
CREATE_ACTIVITY_WEEKLY:'Vecka',
CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_ACTIVITIES_STATUS:'Inträffar {prefix} {recurrenceInterval, plural, =1 {varje} =2 {varannan} other {var {dayOfMonth}}} {recurrenceType, select, monthly {månad} weekly {vecka} daily {dag} other {}} från {startDate, date, long}{hasEndDate, select, true { till {endDate, date, long}} false {} other {}} mellan kl. {fromTime, time, short}-{toTime, time, short}',
CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_ACTIVITIES_WEEK_ORRDINAL:'{week, selectordinal, one {#a} two {#a} few {#e} other {#e}}',
CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_TEMPORARY_ACTIVITIES_STATUS:'Inträffar {prefix} {startDate, date, long} mellan kl. {fromTime, time, short}-{toTime, time, short}',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_BUTTON_TOOLTIP:'Avsluta kunden {fullName}',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_ACTIONS_REMOVE:'Avsluta',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_MESSAGE:'Du kommer att avsluta kunden {fullName}, är du säker?',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_TITLE:'Avsluta kund',
CUSTOMERS_PAGE_VIEW_ADD_CUSTOMER_BUTTON_TEXT:'Lägg till kund',
CUSTOMERS_PAGE_VIEW_COLUMN_AREAS_TITLE:'Avdelning',
CUSTOMERS_PAGE_VIEW_COLUMN_FIRSTNAME:'Förnamn',
CUSTOMERS_PAGE_VIEW_COLUMN_LASTNAME:'Efternamn',
CUSTOMERS_PAGE_VIEW_COLUMN_NAME_TEXT:' ',
CUSTOMERS_PAGE_VIEW_COLUMN_NAME_TITLE:'Namn',
CUSTOMERS_PAGE_VIEW_COLUMN_ROOMNUMBER:'Kortnamn/nummer',
CUSTOMERS_PAGE_VIEW_COLUMN_STATUS_TEXT:'{active, select, true {Aktiv} false {Inaktiv} other {Inaktiv}}',
CUSTOMERS_PAGE_VIEW_COLUMN_STATUS_TITLE:'Status',
CUSTOMERS_PAGE_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'Inga kunder finns tillgängliga',
CUSTOMERS_PAGE_VIEW_HEAD_DESCRIPTION:'Lägg till och/eller administrera registrerad kund',
CUSTOMERS_PAGE_VIEW_HEAD_OVERLINE:'Administrera',
CUSTOMERS_PAGE_VIEW_HEAD_TITLE:'Kunder',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_BUTTON_TOOLTIP:'Ta bort kunden {fullName}',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort kunden {fullName}, är du säker? I samband med borttaget så kommer gallring av personuppgifterna att utföras.',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_TITLE:'Ta bort kund',
CUSTOMERS_PAGE_VIEW_TAB_ACTIVE_CUSTOMERS:'Aktiva kunder',
CUSTOMERS_PAGE_VIEW_TAB_INACTIVE_CUSTOMERS:'Avslutade kunder',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_FORM_SELECT_AREA_LABEL:'Koppla avdelning',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_FORM_SELECT_NONE_TEXT:'Välj avdelning',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_INFOTEXT_SUBTITLE:'Koppla kund till avdelning',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_INFOTEXT_TITLE:'Avdelning',
CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_SUBTITLE:'Information som skrivs här kommer att visas på rapporterna för gjorda signeringar',
CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_TEXTFIELD_LABEL:'Extra information',
CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_TITLE:'Extra information',
CUSTOMER_CREATE_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Avbryt',
CUSTOMER_CREATE_DIALOG_CONTAINER_FOOTER_SUBMIT_BUTTON_TEXT:'Lägg till',
CUSTOMER_CREATE_DIALOG_CONTAINER_HEADER_SUBTITLE:'Basinformation',
CUSTOMER_CREATE_DIALOG_CONTAINER_HEADER_TITLE:'Lägg till kund',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_FIRSTNAME_LABEL:'Förnamn',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_LASTNAME_LABEL:'Efternamn',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE:'Lägg till information som gör det enklare för er att identifiera och kontakta kund.',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE:'Personuppgifter',
CUSTOMER_CREATE_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL:'Övrig information',
CUSTOMER_CREATE_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE:'Övrig information',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_SUBTITLE:'Information som skrivs här kommer att visas på rapporterna för gjorda signeringar',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_TEXTFIELD_LABEL:'Extra information',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_TITLE:'Extra information',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_CHANGE_AREA_TEMPLATE_ACTIVITY_ERROR:'Kunden har kopplade aktivitetsmallar som är unika till den ursprungliga avdelningen.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_FIRSTNAME_TEXTFIELD_LABEL:'Förnamn',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_LASTNAME_TEXTFIELD_LABEL:'Efternamn',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_AREA:'Avdelning',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_AREA_SUBTITLE:'Avdelningen som kunden tillhör.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_SUBTITLE:'Information som gör det enklare för er att identifiera och kontakta kund.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_TITLE:'Personuppgifter',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_SUBTITLE:'Kortnamn/nummer används för att anonymisera kunden. Kortnamn/nummer ersätter namnet på kunden.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_TITLE:'Kortnamn/nummer',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL:'Övrig information',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_INFOTEXT_TITLE:'Övrig information',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_ADDVISITS_BUTTON_TEXT:'Lägg till aktivitet',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_ALERT_FROM_START_ONLY_NUMBERS_ERROR_TEXT:'Minuter innan varning får bara bestå av siffor',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_DUPLICATE:'Duplicera aktivitet',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_CANCEL_BUTTON:'Avbryt',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION:'Du håller på att radera {title}. Är du säker?',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION_IS_PLANNED:'Du håller på att radera {title} som har planerade aktiviteter i framtiden. Är du säker?',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_REMOVE_BUTTON:'Radera',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_TITLE:'Radera aktivitet',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_VISIT_LENGTH_ONLY_NUMBERS_ERROR_TEXT:'Besökets längd får bara bestå av siffror',
CUSTOMER_DIALOG_PAUSED_PERIOD_ADD:'Lägg till pausad period',
CUSTOMER_DIALOG_PAUSED_PERIOD_HIDE_HISTORY_TITLE:'Göm historik',
CUSTOMER_DIALOG_PAUSED_PERIOD_INVALIDENDDATETIME:'Slutdatum får inte vara tidigare än startdatum',
CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_SUBTITLE:'{startDate} {isEndDate, select, true {till {endDate}} false {} other {}}',
CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_TITLE:'Pausad',
CUSTOMER_DIALOG_PAUSED_PERIOD_TITLE:'Pausade perioder',
CUSTOMER_DIALOG_PAUSED_PERIOD_VIEW_HISTORY_TITLE:'Visa historik',
CUSTOMER_DIALOG_TAB_TEMPORARY_ACTITIVIES_TITLE:'Tillfälliga Aktiviteter',
CUSTOMER_DIALOG_VIEW_FOOTER_CANCEL_BUTTON_TEXT:'Avbryt',
CUSTOMER_DIALOG_VIEW_TAB_AREAS_TITLE:'Sektion och avdelning',
CUSTOMER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basinformation',
CUSTOMER_DIALOG_VIEW_TAB_DESIRED_ACTIVITIES_TITLE:'Aktiviteter',
DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT:'Allmän aktivitet',
DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT_ABBREVIATION:'AÅ',
DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT:'Planerad',
DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT_STARTED:'Påbörjad',
DAILY_ACTIVITIES_ITEM_CONTAINER_SIGNED_ACTIVITY_TEXT:'Signerad',
DAILY_ACTIVITIES_MODAL_FROM_TITLE:'Från',
DAILY_ACTIVITIES_MODAL_INTERNAL_INFORMATION_AREA_SECTION_TITLE:'Avdelning',
DAILY_ACTIVITIES_MODAL_INTERNAL_INFORMATION_ORG_SECTION_TITLE:'Organisation',
DAILY_ACTIVITIES_MODAL_INTERNAL_INFORMATION_TITLE:'Supportinformation',
DAILY_ACTIVITIES_MODAL_PERSONNEL_TITLE:'Personal',
DAILY_ACTIVITIES_MODAL_ROUTE_TITLE:'Tur',
DAILY_ACTIVITIES_MODAL_SUBSTITUTES_TITLE:'ersätter',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_BUTTON:'Lägg till',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_SUBTITLE:'Lägg till en tillfällig aktivitet på kund eller avdelning ',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_TITLE:'Lägg till en tillfällig aktivitet',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_CUSTOMER_ACTIVITY_BUTTON:'Lägg till',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_CUSTOMER_ACTIVITY_DISABLED_BUTTON:'Välj en kund',
DAILY_ACTIVITIES_MODAL_TO_TITLE:'Till',
DAILY_ACTIVITIES_MODAL_WORKSCHEDULE_EMPTY_TEXT:'Finns inga arbetspass',
DAILY_ACTIVITIES_MODAL_WORKSCHEDULE_TITLE:'Arbetspass',
DAILY_ACTIVITIES_PAGE_CONTAINER_ACTIVATE_MULTISIGN_TOGGLE_TEXT:'Signera flera',
DAILY_ACTIVITIES_PAGE_CONTAINER_ACTIVITIES_NOSCHEDULED_MESSAGE_TEXT:'Inga aktiviteter är planerade.',
DAILY_ACTIVITIES_PAGE_CONTAINER_ADD_TEMPORARY_ACTIVITY:'Ny Aktivitet',
DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_HIDE_TEXT:'Stäng anslagstavla',
DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_SHOW_TEXT:'Öppna anslagstavlan',
DAILY_ACTIVITIES_PAGE_CONTAINER_DEACTIVATE_MULTISIGN_TOGGLE_TEXT:'Avbryt Signera flera',
DAILY_ACTIVITIES_PAGE_CONTAINER_FUTURE:'Framtid',
DAILY_ACTIVITIES_PAGE_CONTAINER_GROUPSLIST_ALL_TITLE:'Alla',
DAILY_ACTIVITIES_PAGE_CONTAINER_HEADER_SUBTITLE:'Dagens arbetsuppgifter. Klicka på en aktivitet för att signera den som utförd. Logga in med din PIN-kod.',
DAILY_ACTIVITIES_PAGE_CONTAINER_HEADER_TITLE:'Dagens aktiviteter',
DAILY_ACTIVITIES_PAGE_CONTAINER_HIDE_SIGNED_TOGGLE_TEXT:'Dölj Signerade',
DAILY_ACTIVITIES_PAGE_CONTAINER_HISTORY:'Historik',
DAILY_ACTIVITIES_PAGE_CONTAINER_INTERNAL_INFORMATION_BUTTON_TEXT:'Supportinformation',
DAILY_ACTIVITIES_PAGE_CONTAINER_LATEST_LOCK_TEXT:'Låses',
DAILY_ACTIVITIES_PAGE_CONTAINER_LATEST_UPDATE_TEXT:'Uppdaterad',
DAILY_ACTIVITIES_PAGE_CONTAINER_PERFORM_MULTISIGN_TEXT:'Signera valda',
DAILY_ACTIVITIES_PAGE_CONTAINER_PLANNINGPAGE_BUTTON_TEXT:'Öppna kalender',
DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_LOCK_BUTTON_TEXT:'Lås',
DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_UNLOCK_BUTTON_TEXT:'Lås upp',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_MAIN_MENU_CUSTOMER_VIEW:'Visa huvudmenyn sorterad efter kund',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_OPEN_MY_TOUR_TITLE:'Öppna min tur',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_SHOW_EXACT_TIME_ON_ACTIVITY:'Visa längd på aktiviteter',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_TITLE:'Inställningar',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_UNIT_HOURS:'tim',
DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_LESS_DAILY_ACTIVITIES:'Visa mindre',
DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_MORE_DAILY_ACTIVITIES:'Tidshorisont för aktiviteter',
DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_SIGNED_TOGGLE_TEXT:'Visa signerade',
DAILY_ACTIVITIES_PAGE_CONTAINER_WORK_SCHEDULES_BUTTON_TEXT:'Arbetspass',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_HEADER_SUBTITLE:'Signering av flera aktiviteter',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_HEADER_TITLE:'Signera flera',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_INFOSECTION_SUBTITLE:'Samtliga aktiviteter kommer att signeras med den status du väljer. Skall någon aktivitet ha en annan status välj ordinare signering istället.',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_INFOSECTION_TITLE:'Status för signering',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Avbryt',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_FOOTER_DELETE_BUTTON_TEXT:'Radera',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_HEADER_TITLE:'Ange PIN-kod',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_WRONG_PIN_TEXT:'Felaktig PIN',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort aktivteten, är du säker?',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_TITLE:'Ta bort aktivitet',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CANNOT_TAKE_INFO_TEXT:'Kan ej ta',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETEDWITHOUTACTION_TEXT:'Signerad utan handling',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETEDWITHOUTACTION_WITH_COMMENT_TEXT:'Signerad utan handling med kommentar',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETED_TEXT:'Utförd',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETED_WITH_COMMENT_TEXT:'Utförd med kommentar',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_DELAY_TEXT:'Senarelägg',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_DELAY_WITH_COMMENT_TEXT:'Senarelägg med kommentar',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_PARTIALLY_COMPLETED_TEXT:'Delvis utförd',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_PARTIALLY_GIVEN_TEXT:'Delvis given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_REMOVEACTIVITY_TEXT:'Ta bort',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT:'{status} med kommentar',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYEDSECTION_SUBTITLE:'Den senarelagda aktiviten behöver hanteras',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYEDSECTION_TITLE:'Senarelagd aktivitet',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYSECTION_SUBTITLE:'Senarelägg aktiviteten. Om något behöver förtydligas lämna en kommentar.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYSECTION_TITLE:'Senarelägg',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELEGATIONSECTION_SUBTITLE:'Aktiviteten har krav om delegering där uppfyllnad ej nås',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELEGATIONSECTION_TITLE:'Delegeringskrav',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_DELAYED_INFO:'Denna senarelagda aktivitet förekommer dubbelt idag och behöver hanteras innan dagens aktivitet kan signeras',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_INFO:'Aktiviteten förekommer dubbelt idag på grund av att en tidigare inplanerad aktivitet har senarelagts. Den senarelagda behöver hanteras innan dagens aktivitet kan signeras',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_SUBTITLE:' ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_TITLE:'Dubbel förekomst',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DRUGLISTSECTION_SUBTITLE:'Den förväntade mängd eller längd på aktiviteten mätt i tidsenhet eller mått/mängdenhet',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DRUGLISTSECTION_TITLE:'Förväntad mängd / längd',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_SUBTITLE:'Signera aktiviteten. Om något behöver förtydligas lämna en kommentar.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_TITLE:'Signera',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECENTSECTION_SUBTITLE:'Visar tidigare utförda de senaste 24 timmarna samt ytterligare en utförd.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECENTSECTION_TITLE:'Tidigare utförda',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_ONETIME:' ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_SUBTITLE:' ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_TITLE:'Tillfällig aktivitet',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_REMOVESECTION_SUBTITLE:'Tillfälliga aktiviteter som är skapade under dygnet har möjlighet att tas bort via tavlan',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_REMOVESECTION_TITLE:'Ta bort aktivitet',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_SUBTITLE:'Aktivitetens prioritetsnivå',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_TIMECRITICAL:'Tidskritisk, planerad start: ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_TITLE:'Prioritet',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STARTED_SUBTITLE:'Påbörja aktiviteten.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STARTED_TITLE:'Påbörja',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STATUSSECTION_SUBTITLE:'Senaste status',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STATUSSECTION_TITLE:'Status',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_DELAYED_INFO_TEXT:'Senarelagd',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_GIVEN_INFO_TEXT:'Given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_SUBTITLE:'När du ångrar en signering är det krav på att du noterar varför.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_TITLE:'Kommentar',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_NOT_GIVEN_INFO_TEXT:'Ej given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_PARTIALLY_COMPLETE_INFO_TEXT:'Delvis utförd',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_PARTIALLY_GIVEN_INFO_TEXT:'Delvis given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_RELATIVE_GIVEN_INFO_TEXT:'Anhörig givit',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SELF_GIVEN_INFO_TEXT:'Tagit själv',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SENT_ALONG_INFO_TEXT:'Medskickad',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_DONE_INFO_TEXT:'Utförd',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_NOTDONE_INFO_TEXT:'Signerad utan handling',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_UNSIGNED_INFO_TEXT:'Ångrad',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SINGLE_ACTIVITY_COMPLETEDWITHOUTACTION_COMMENT_MODAL_SUBTITLE:'När du signerar en aktivitet som signerad utan handling, krävs en notering. Var god ange notering nedan.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_INFO_TEXT:'Förväntad mängd är {expected} {abbreviation}',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_TITLE_PARTIALLY_COMPLETED_TEXT:'Delvis utförd',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_TITLE_PARTIALLY_GIVEN_TEXT:'Delvis given',
DAILY_ACTIVITIES_PAGE_USER_DIALOG_CONTAINER_HEADER_PERSONAL_LIST_TITLE:'Välj personal',
DAILY_ACTIVITIES_PAGE_USER_DIALOG_CONTAINER_HEADER_RECENT_LOGGED_IN_USERS_TITLE:'Nyligen inloggade',
DAILY_ACTIVITY_EXPIRING_DELEGATIONS:'Utgående delegeringar',
DAILY_ACTIVITY_EXPIRING_DELEGATIONS_SHOW:'Visa',
DAILY_ACTIVITY_LOGGED_IN_BUTTON_CHANGE:'Växla',
DAILY_ACTIVITY_LOGGED_IN_STATUS:'Inloggad',
DASHBOARD_PAGE_CONTAINER_HEADER_TITLE:'Dashboard',
DASHBOARD_PAGE_KPI_VALUE_UNIT_DAYS:'d',
DASHBOARD_PAGE_KPI_VALUE_UNIT_HOURS:'tim',
DASHBOARD_PAGE_KPI_VALUE_UNIT_MINUTES:'min',
DASHBOARD_PAGE_KPI_VALUE_UNIT_PERCENTAGE:'%',
DASHBOARD_PAGE_KPI_VALUE_UNIT_PIECES:'st',
DASHBOARD_PAGE_NOTIFICATION_BUTTON_TEXT:'Läs mer',
DATE_PICKER_TOOLBAR_TITLE:'Välj datum',
DATE_TIME_PICKER_TOOLBAR_TITLE:'Välj datum och tid',
DEFAULT_ERROR_FALLBACK_MSG:'Något gick fel!',
ERROR_USER_MISSING_ORG_ID:'Inloggad användare saknar organisations-id',
EXPIRING_DELEGATIONS_CHANGE_ORGANIZATION:'Byt organisation',
EXPIRING_DELEGATIONS_DASHBOARD_SECTION_BUTTON_TEXT:'Visa utgående delegeringar',
EXPIRING_DELEGATIONS_DASHBOARD_SECTION_SUBTITLE:'Visa utgående delegeringar',
EXPIRING_DELEGATIONS_DASHBOARD_SECTION_TITLE:'Utgående delegeringar',
EXPIRING_DELEGATIONS_MODAL_HEADER_NO_RESULT_TITLE:'Inga utgående delegeringar',
EXPIRING_DELEGATIONS_MODAL_HEADER_SUBTITLE:'Visar delegeringar som kommer att gå ut i en nära framtid',
EXPIRING_DELEGATIONS_MODAL_HEADER_TITLE:'Utgående delegeringar',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATEDBYUSERFULLNAME_TABLE_HEADER:'Delegerad av',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATEDFORAREANAME_TABLE_HEADER:'Delegerad mot avdelning',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATEDFORCUSTOMERFULLNAME_TABLE_HEADER:'Delegerad mot kund',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATIONENDTIME_TABLE_HEADER:'Slut',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATIONSTARTTIME_TABLE_HEADER:'Start',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATIONTITLE_TABLE_HEADER:'Delegering',
EXPIRING_DELEGATIONS_VIEW_DIALOG_USERFULLNAME_TABLE_HEADER:'Personal',
FORGOT_PASSWORD_BUTTON_TEXT:'Fortsätt',
FORGOT_PASSWORD_EMAIL:'Ange e-postadress',
FORGOT_PASSWORD_ERROR_TEXT:'Kan ej hitta en användare med angiven e-postadress.',
FORGOT_PASSWORD_INCORRECT_EMAIL:'Ange en giltig e-postadress',
FORGOT_PASSWORD_LINK_SENT:'Du kommer inom kort få ett e-post med en länk för att sätta ett nytt lösenord / pinkod',
FORGOT_PASSWORD_TITLE:'Glömt lösenord / pinkod',
FUTURE_MODAL_HEADER_NO_RESULT_TITLE:'Inga planerade aktiviteter de kommande två veckorna',
FUTURE_MODAL_HEADER_SUBTITLE:'Visar planerade aktiviteter de kommande två veckorna',
FUTURE_MODAL_HEADER_TITLE:'Framtida planerade aktiviteter',
FUTURE_MODAL_TOGGLE_ALL:'Alla',
FUTURE_MODAL_TOGGLE_ORDINARY:'Ordinarie',
FUTURE_MODAL_TOGGLE_TEMPORARY:'Tillfälliga',
GENERAL_APPROVE:'Godkänn',
GENERAL_BACK:'Tillbaka',
GENERAL_DENY:'Avböj',
GENERATE_ACTIVITY_STATUS_PREFIX_EVERY_DAY_TEXT:'alla dagar',
GIDS_CALLBACK_REQUEST_INFO:'Inloggning pågår. Vänligen avvakta.',
GIDS_CALLBACK_REQUEST_TITLE:'Loggar in...',
GRANDIDSETTING_MODAL_DIALOG_HEADER_TITLE:'Hantera inloggningsmetod',
HANGFIRE_BUTTON_LINK:'Hangfire',
HANGFIRE_SUBTITLE:'Öppna Hangfire portalen',
HANGFIRE_TITLE:'Hangfire',
HISTORY_MODAL_HEADER_NO_RESULT_TITLE:'Inga händelser den senaste veckan',
HISTORY_MODAL_HEADER_SUBTITLE:'Visar senaste veckans händelser',
HISTORY_MODAL_HEADER_TITLE:'Historik över tidigare händelser för aktiviteter',
HISTORY_MODAL_TOGGLE_ALL:'Alla',
HISTORY_MODAL_TOGGLE_ORDINARY:'Ordinarie',
HISTORY_MODAL_TOGGLE_TEMPORARY:'Tillfälliga',
IMPERSONATION_REQUEST_INFO:'Tenilo support ({requestedby}) har begärt att få logga in med ditt användarkonto.',
IMPERSONATION_REQUEST_INFO_APPROVE:'För att godkänna inloggningen tryck på knappen Godkänn. Önskar du inte tillåta inloggningen så trycker du på knappen Avböj.',
IMPERSONATION_REQUEST_INFO_ENSURE:'Vänligen säkerställ att ni har kommit överens om detta.',
IMPERSONATION_REQUEST_INFO_HANDLED:'Tack! Inloggningsbegäran är nu hanterad.',
IMPERSONATION_REQUEST_TITLE:'Inloggningsbegäran',
INVOICE_FORM_CONTAINER_FILE_NAME:'kundrapport_{fromDate}-{toDate}.csv',
INVOICE_FORM_CONTAINER_FROMDATE_DATEPICKER_LABEL:'Från datum',
INVOICE_FORM_CONTAINER_ORGANIZATIONID_SELECT_LABEL:'Organisation',
INVOICE_FORM_CONTAINER_SUBMIT_BUTTON_TEXT:'Ladda ner',
INVOICE_FORM_CONTAINER_SUBTITLE:'För att ladda en en kundrapport, välj organisation, och från- och till-datum, klicka sedan på ladda ner. Filen kommer döpas till kundrapport_ddmmyy-ddmmyy.csv',
INVOICE_FORM_CONTAINER_TITLE:'Ladda ner kundrapport',
INVOICE_FORM_CONTAINER_TODATE_DATEPICKER_LABEL:'Till datum',
LISTING_MODAL_BUTTON_ABORT:'Avbryt',
LISTING_MODAL_BUTTON_SAVE:'Spara',
LISTING_MODAL_LISTING_FIELD_LABEL:'Kommentar',
LOGGED_IN_USER:'Inloggad',
LOGIN_PAGE_CONTAINER_ALLEVI_LINK_TEXT:'En del av Allevi',
LOGIN_PAGE_CONTAINER_ERROR:'Fel användarnamn eller lösenord.',
LOGIN_PAGE_CONTAINER_FORM_EMAIL_LABEL:'E-post',
LOGIN_PAGE_CONTAINER_FORM_FORGOTTEN_PASSWORD:'Glömt lösenord / pinkod?',
LOGIN_PAGE_CONTAINER_FORM_LOGIN_BUTTON_TEXT:'Fortsätt',
LOGIN_PAGE_CONTAINER_FORM_LOGIN_FAILED_LABEL:'Misslyckat inloggningsförsök. Vänligen kontrollera inmatad epostadress och lösenord.',
LOGIN_PAGE_CONTAINER_FORM_ORGANIZATION_LABEL:'Organisation',
LOGIN_PAGE_CONTAINER_FORM_PASSWORD_LABEL:'Lösenord',
LOGIN_PAGE_CONTAINER_FORM_PWA_LINK:'Tenilo app',
LOGIN_PAGE_CONTAINER_FORM_REMEMBER_ME_TEXT:'Kom ihåg mig',
LOGIN_PAGE_CONTAINER_FORM_REMEMBER_ORGANIZATION_TEXT:'Kom ihåg organisation',
LOGIN_PAGE_CONTAINER_HEADER_LOGIN_TITLE:'Logga in',
LOGIN_PAGE_CONTAINER_WELCOME_TEXT:'Välkommen',
LOGIN_PAGE_MODAL_CHOOSE_ORGANIZATION:'Välj organisation',
LOGIN_SERVICE_TYPE_GIDS_BANKID:'BankID',
LOGIN_SERVICE_TYPE_GIDS_FREJAEID:'Freja eID',
LOGIN_SERVICE_TYPE_GIDS_SAML:'Saml',
LOGIN_SERVICE_TYPE_STANDARD:'Standard',
LOGOUT_ALERT_BUTTON_OK:'Ok',
LOGOUT_ALERT_TEXT:'Logga in igen om du behöver fortsätta använda kiosk-läget.',
LOGOUT_ALERT_TITLE:'Du har blivit utloggad',
LOG_IN_SCENE_LOG_IN_BUTTON_TEXT:'Logga in',
LOG_IN_SCENE_LOG_IN_PASSWORD_INPUT_TEXT:'Lösenord',
LOG_IN_SCENE_LOG_IN_TITLE:'Logga in',
LOG_IN_SCENE_LOG_IN_USERNAME_INPUT_TEXT:'Användarnamn',
LOG_IN_SCENE_LOG_OUT_BUTTON_TEXT:'Logga ut',
MAIN_MENU_BUTTON_HOVER_ADMINISTER_DEVICES:'Administration',
MAIN_MENU_BUTTON_HOVER_AREAS:'Avdelningar',
MAIN_MENU_BUTTON_HOVER_CUSTOMERS:'Kunder',
MAIN_MENU_BUTTON_HOVER_DASHBOARD:'Dashboard',
MAIN_MENU_BUTTON_HOVER_DEVICE_PLANNING:'Aktivitetstavelsplanering',
MAIN_MENU_BUTTON_HOVER_LOGOUT:'Logga ut',
MAIN_MENU_BUTTON_HOVER_ORG_STRUCTURE:'Organisationsstruktur',
MAIN_MENU_BUTTON_HOVER_PERSONNEL:'Personal',
MAIN_MENU_BUTTON_HOVER_PROFILE:'Profil',
MAIN_MENU_BUTTON_HOVER_REPORTS:'Rapporter',
MAIN_MENU_BUTTON_HOVER_SUPPORT:'Support',
MAIN_MENU_BUTTON_HOVER_UNIT_PLANNING:'Planering',
META_FIELD_plannedActivityRowId:'Id',
NOTIFICATION_NEW_VERSION_AVAILABLE:'Det finns en ny uppdatering av Tenilo. Du behöver ladda om sidan för att få de nya uppdateringarna',
NOTIFICATION_NEW_VERSION_UPDATE_NOW:'Klicka här för att uppdatera nu',
NOTIFICATION_SELECTED_VISIBLE_AREA_ACTION:'klicka här',
NOTIFICATION_SELECTED_VISIBLE_AREA_MESSAGE:'Du filtrerar just nu på en eller flera avdelningar ',
ORGANIZATION_PAGE_CONTAINER_HEADER_OVERLINE:'Administrera',
ORGANIZATION_PAGE_CONTAINER_HEADER_SUBTITLE:'Översikt över organisationens avdelningar.',
ORGANIZATION_PAGE_CONTAINER_HEADER_TITLE:' ',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_FOOTER_CANCEL_BUTTON:'Avbryt',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_FOOTER_REMOVE_BUTTON:'Ta bort',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_INFOTEXT_SUBTITLE:'Namn på avdelning.',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_INFOTEXT_TITLE:'Namn',
PAUSED_PERIOD_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
PAUSED_PERIOD_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
PAUSED_PERIOD_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort den pausade perioden för {date}. Tryck på Ta bort för att forsätta, eller Avbryt för att inte ta bort perioden',
PAUSED_PERIOD_DELETE_DIALOG_TITLE:'Ta bort pausad period?',
PAUSED_PERIOD_DELETE_NOT_ALLOWED_DIALOG_MESSAGE:'Du kan ej ta bort en pausad period med passerat startdatum ({date}). Du behöver redigera perioden och sätta ett slutdatum. ',
PAUSED_PERIOD_DELETE_NOT_ALLOWED_DIALOG_TITLE:'Borttag av pausad period tillåts ej',
PAUSED_PERIOD_DIALOG_CANCEL:'Avbryt',
PAUSED_PERIOD_DIALOG_SAVE:'Spara',
PAUSED_PERIOD_DIALOG_SECTION_DATE:'Datum',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INFOTEXT_SUBTITLE:'Datum och tid för när pausade perioden skall gälla.',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_COMMENT:'Beskrivning',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_END_DATE:'Slutdatum',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_START_DATE:'Startdatum',
PAUSED_PERIOD_DIALOG_TITLE_ADD:'Lägg till pausad period',
PAUSED_PERIOD_DIALOG_TITLE_EDIT:'Editera pausad period',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT:'Avbryt',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_END_DATE:'Slutdatum',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1:'Det finns kommande förändringar på denna serie. Om du endast vill införa den nu gjorda justeringen fram tills nästa tryck på TILLS NÄSTA. Vill du ersätta alla kommande förändringar med den gjorda justeringen, tryck på ERSÄTT ALLA.',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2:'Om du istället endast önskar att justera aktiviteten just denna dag, tryck på AVBRYT och välj att pennmarkera aktiviteteten och gör sedan justeringen på nytt.',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TITLE:'Kommande förändringar på denna serie',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_REPLACE_ALL_BUTTON_TEXT:'Ersätt alla',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_ROUTE:'Tur',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_START_DATE:'Startdatum',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_TIME:'Tid',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_UP_UNTIL_BUTTON_TEXT:'Tills nästa',
PLANNING_PAGE_AREA_CONTAINER_CREATE_ACTIVITY_TITLE:'Lägg till allmän aktivitet',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT:'Avbryt',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_END_DATE:'Slutdatum',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1:'Det finns kommande förändringar på denna serie. Om du endast vill avplanera fram tills nästa ändring tryck på TILLS NÄSTA. Vill du avplanera även de kommande förändringarna, tryck på AVPLANERA ALLA.',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2:'Om du istället endast önskar att avplanera aktiviteten just denna dag, tryck på AVBRYT och välj att pennmarkera aktiviteteten och gör sedan avplaneringen på nytt.',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_MESSAGE_TITLE:'Avplanering av serie med kommande förändringar',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_REPLACE_ALL_BUTTON_TEXT:'Avplanera alla',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_ROUTE:'Tur',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_START_DATE:'Startdatum',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_TIME:'Tid',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_UP_UNTIL_BUTTON_TEXT:'Tills nästa',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT:'Avbryt',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_COMMENT:'Kommentar',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_CONFIRM_BUTTON_TEXT:'Avplanera',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_END_DATE:'Slutdatum',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1:'Det finns kommande förändringar och pennmarkerade förekomster på denna serie.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2:'För kommande förändringar, välj ifall du vill avplanera fram tills nästa förändring (Tills nästa) eller om vill avplanera även de kommande förändringarna (Avplanera alla).',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION3:'För pennmarkerade förekomster, välj ifall du vill att dessa skall vara fortsatt planerade (Ingen åtgärd) eller om du vill avplanera även de pennmarkerade förekomsterna (Avplanera).',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION4:'Om du endast önskar att avplanera aktiviteten just denna dag, tryck på AVBRYT och välj att pennmarkera aktiviteteten och gör sedan avplaneringen på nytt.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TITLE:'Avplanering av serie med kommande förändringar och pennmarkerade förekomster',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MODIFIED_BY:'Ändrad av',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_PLANNED:'Planerad',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_PLANNED_TIME:'{fromTime} till {endTime} ({duration} Minuter)',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_NOACTION:'Ingen åtgärd',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNPLAN:'Avplanera',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNPLANALL:'Avplanera alla',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNTILNEXT:'Tills nästa',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_ROUTE:'Tur',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_START_DATE:'Startdatum',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_TIME:'Tid',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_CANCEL:'Avbryt',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_EXCL:'Nej',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_INCL:'Ja',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_COMMENT:'Kommentar',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_MODIFIED_BY:'Ändrad av',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_PLANNED:'Planerad',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_ROUTE:'Tur',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_PLANNED_TIME:'{fromTime} till {endTime} ({duration} Minuter)',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE:'Avplanering pennmarkeringar {title}',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW1:'Serien har nedanstående framtida pennmarkeringar.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW2:'• Tryck på JA, för att fortsätta med avplaneringen av serien och även avplanera de pennmarkerade förekomsterna.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW3:'• Tryck på NEJ, för att fortsätta med avplaneringen av serien men för att ej avplanera de pennmarkerade förekomsterna.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_WARNING:'Notera att vid avplanering av de pennmarkerade förekomsterna så kommer eventuella ändringar på dessa att gå förlorade.',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_COMMON_ACTIVITY:'Allmän aktivitet',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_FOOTER_ADD_BUTTON_TEXT:'Lägg till',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Avbryt',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_HEADER_OVERLINE:' ',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_HEADER_SUBTITLE:' ',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE:'Lägg till en tillfällig aktivitet',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_ERROR_TEXT:'Minuter innan varning får bara bestå av siffor',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_LABEL:'Minuter innan varning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_ERROR_TEXT:'Längd får bara bestå av siffor',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_LABEL:'Längd',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_SUBTITLE:'Hur lång tid aktiviteten uppskattas att ta.',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TITLE:'Längd',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTHOUR_LABEL:'tim',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTMINUTE_LABEL:'min',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TEMPLATEACTIVITY_REQUIRED:'Aktivitetsmall måste anges',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TITLE_LABEL:'Tilläggstext',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE:'Kort tilläggstext till aktivitetsmall',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE:'Information',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_ALERT_SUBTITLE:'Antal minuter från när aktiviteten är utplanerad i tid och då varning (blinkning) skall påbörjas på aktivitetstavlan. Genom att sätta denna till 0 minuter inaktiveras varningen.',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_ALERT_TITLE:'Varning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_LABEL:'Färg',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SHOW_ON_DEVICE:'Visa färg',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SHOW_ON_DEVICE_SUBTITLE:'Skall färgen visas på aktivitetstavlan?',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SUBTITLE:'Färg kan användas för att kategorisera olika aktiviteter och att få en snabb översyn över dessa i planeringsvyerna.',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_TITLE:'Färg',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL:'Beskrivning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_AREA:'Beskrivning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_ORG:'Beskrivning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE:'Ytterligare information',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_AREA:'Beskrivning av aktivitetsmallen från avdelningen',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_ORG:'Beskrivning av aktivitetsmallen från organisationen',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE:'Beskrivning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_AREA:'Avdelningsbeskrivning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_ORG:'Organisationsbeskrivning',
PLANNING_PAGE_DEVICE_PLANNING_ADD_COMBINED_ROUTE_SET_COLOR:'Sätt namn och färg på kombinerad tur.',
PLANNING_PAGE_DEVICE_PLANNING_ADD_COMBINED_ROUTE_TITLE:'Skapa kombinerad tur',
PLANNING_PAGE_DEVICE_PLANNING_MANAGE_COMBINED_ROUTE_ADD_ROUTE_BUTTON_TEXT:'Skapa kombinerad tur',
PLANNING_PAGE_DEVICE_PLANNING_MANAGE_COMBINED_ROUTE_MAIN_AREA_MISSING:'Aktivitetstavlan måste inkludera alla turer till huvudavdelningen',
PLANNING_PAGE_DEVICE_PLANNING_MANAGE_DEVICE_ROUTE_BUTTON_TEXT:'Administrera',
PLANNING_PAGE_DEVICE_PLANNING_PAGE_CONTAINER_NO_DEVICE_TEXT:'Vänligen aktivera en aktivitetstavla',
PLANNING_PAGE_MAINMENU_LOCK:'Lås',
PLANNING_PAGE_MAINMENU_OPEN_DAILYACTIVITIES:'Öppna aktivitetstavlan',
PLANNING_PAGE_ROUTESMENU_HIDE:'Dölj',
PLANNING_PAGE_ROUTESMENU_HIDE_ALL:'Dölj alla',
PLANNING_PAGE_ROUTESMENU_SHOW:'Visa',
PLANNING_PAGE_ROUTESMENU_SHOW_ALL:'Visa alla',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_ADDLEFT_ITEM_TEXT:'Skapa vänster',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_ADDRIGHT_ITEM_TEXT:'Skapa höger',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_EDIT_ITEM_TEXT:'Ändra',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ITEM_TEXT:'Ta bort',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ALREADY_REMOVED_FROM_OTHER_DATE_THAN_TOMORROW_DIALOG_MESSAGE:'{routeName} är redan borttagen, slutdatum är {endDate}. Vill du ta bort den från och med valt datum?',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ALREADY_REMOVED_FROM_TOMORROW_DIALOG_MESSAGE:'{routeName} är redan borttagen, slutdatum är {endDate}. Vill du ta bort den från och med imorgon?',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_CANCEL:'Avbryt',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_MESSAGE:'Du är påväg att ta bort tur {route} vilket har aktiviteter planerade i framtiden. Klicka Ta bort för att fortsätta eller Avbryt för att inte ta bort turen.',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_REMOVE:'Ta bort',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_TITLE:'Ta bort tur',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_CANCEL:'Avbryt',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_MESSAGE:'{routeName} kan ej tas bort från dagens datum. Vill du ta bort {routeName} från och med imorgon?',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_REMOVE:'Ta bort från imorgon',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_TITLE:'Ta bort tur',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_MESSAGE:'{routeName} är redan borttagen, slutdatum är {endDate}.',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_OK:'Ok',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_TITLE:'Turen är borttagen',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_CANCEL_BUTTON_TEXT:'Avbryt',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_DIALOG_TITLE:'Skapa tur',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_FORM_NAME_LABEL:'Namn',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_SAVE_BUTTON_TEXT:'Spara',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_SUBTITLE:'De senaste arbetspassen',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_TITLE:'Senaste arbetspass',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_DATE_LABEL:'Till datum',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_LABEL:'Till tid',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_DATE_LABEL:'Från datum',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_LABEL:'Från tid',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_STARTDATE_LABEL:'Startdatum',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_STARTDATE_SUBTITLE:'För arbetspasset',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_STARTDATE_TITLE:'Startdatum',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_FOR_LABEL:'Ersättare för',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_SUBTITLE:'Ange arbetspassets tidsintervall',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_TITLE:'Tidsintervall',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TITLE:'Skapa arbetspass',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_LABEL:'Personal',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_SUBTITLE:'Välj den personal som du vill skapa arbetspasset för',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_TITLE:'Personal',
PLANNING_PAGE_SCHEDULE_VIEW_CREATE_ROUTE_BUTTON_TEXT:'Skapa tur',
PLANNING_PAGE_SCHEDULE_VIEW_EDITCOMMENT_FORM_NAME_LABEL:'Kommentar',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_CANCEL_BUTTON_TEXT:'Avbryt',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_DIALOG_TITLE:'Ändra tur',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_FORM_NAME_LABEL:'Namn',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_SAVE_BUTTON_TEXT:'Spara',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_SUBTITLE:'De senaste arbetspassen för {userFullname}',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_TITLE:'Senaste arbetspass',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_DATE_LABEL:'Till datum',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_LABEL:'Till tid',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_DATE_LABEL:'Från datum',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_LABEL:'Från tid',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_STARTDATE_SUBTITLE:'För arbetspasset',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_STARTDATE_TITLE:'Startdatum',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_FOR_LABEL:'Ersättare för',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_LABEL:'Ersättare',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_CHECKBOX:'Ersätt fler',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_LABEL:'Ersättare för alla nedanstående pass',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_SUBTITLE:'Bockas i ifall man vill ersätta alla framtida pass på en personal.',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_TITLE:'Ersätt alla framtida pass',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_SUBTITLE:'Den personal som ersätter den ursprungligen planerade personalen',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_TITLE:'Ersättare',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_SUBTITLE:'Arbetspassets tidsintervall',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_TITLE:'Tidsintervall',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TITLE:'Ändra arbetspass',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TITLE_READONLY:'Arbetspass',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_LABEL:'Personal',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_SUBTITLE:'Den personal som kommer att utföra arbetspasset',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_TITLE:'Personal',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_FORM_CANCEL_BUTTON_TEXT:'Avbryt',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_FORM_SEARCH_LABEL:'Kundens namn',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_FORM_SELECT_LABEL:'Välj',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_INFOTEXT_DESCRIPTION_TEXT:'Sök efter kund för att skapa ett temporärt besök',
PLANNING_PAGE_SHOW_PLANNED_WORKSCHEDULES:'Visa planerade arbetspass',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_ACTIVITIES_NODATA_TEXT:'Skapa aktiviteter',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_ACTIVITIES_TITLE:'Aktivitet',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_COMMON_TASKS_TITLE:'Allmänna aktiviteter',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_PERSONNEL_TITLE:'Personal',
PLANNING_PAGE_SNAP_TO_5_MINUTES:'Avrunda till var 5:e minut',
PLANNING_PAGE_TREEVIEW_CHECKBOX_SHOW_ALL_WORKSCHEDULES:'Visa alla arbetspass',
PLANNING_PAGE_TREEVIEW_CHECKBOX_SHOW_PLANNED_ACTIVITIES:'Visa alla aktiviteter',
PLANNING_PAGE_VIEW_BUTTON_OPEN_DAILYACTIVITIES:'Öppna aktivitetstavlan',
PLANNING_PAGE_VIEW_HEAD_SUBTITLE:' ',
PLANNING_PAGE_VIEW_HEAD_TITLE:'Planering',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_CANCEL_BUTTON:'Avbryt',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_DESCRIPTION:'Du håller på att radera arbetspasset för {title}. Är du säker?',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_REMOVE_BUTTON:'Radera',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_TITLE:'Radera arbetspass',
POPOVER_INFO_ACTIVITY_COMMENT:'Kommentar',
POPOVER_INFO_ACTIVITY_COMPLETED:'Utförd {signingDate} av {signedBy}',
POPOVER_INFO_ACTIVITY_COMPLETEDWITHOUTACTION:'Signerad utan handling {signingDate} av {signedBy}',
POPOVER_INFO_ACTIVITY_EXCL_PENMARKED:' (exklusive pennmarkerade)',
POPOVER_INFO_ACTIVITY_INCL_PENMARKED:' (inklusive pennmarkerade)',
POPOVER_INFO_ACTIVITY_NOT_IN_A_SERIES:'Ingår ej i serie',
POPOVER_INFO_ACTIVITY_PARTICIPANTS:'Deltagare',
POPOVER_INFO_ACTIVITY_PLANNED:'Planerad',
POPOVER_INFO_ACTIVITY_RECURRANCE_ONETIME:'Tillfällig aktivitet',
POPOVER_INFO_ACTIVITY_SEVERITYLEVEL_TIMECRITICAL:'Tidskritisk',
POPOVER_INFO_ACTIVITY_TEMPORARY_CHANGE:'Tillfällig ändring för dagens datum',
POPOVER_INFO_ACTIVITY_TIMESPAN:'Mellan vilka tider aktiviteten bör infalla',
POPOVER_INFO_ACTIVITY_UNDO_BUTTON:'Avplanera',
POPOVER_INFO_ACTIVITY_UNDO_BUTTON_ALL:'Avplanera seriens alla dagar',
POPOVER_INFO_ACTIVITY_UNDO_BUTTON_SERIES:'Avplanera dagens serie',
POPOVER_INFO_ACTIVITY_UNTIL:'till',
POPOVER_INFO_NAME_COMMON_ACTIVITY:'Allmän aktivitet',
PRIVACY_POLICY_BUTTON_LINK:'Integritetspolicy',
PRIVACY_POLICY_SUBTITLE:'Läs vår integritetspolicy',
PRIVACY_POLICY_TITLE:'Integritetspolicy',
PROFILE_PAGE_GAP_REPORT_BUTTON:'Visa glapprapport',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_AREANAME:'Avdelning',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_AREA_BELONGING:'Välj avdelningstillhörighet',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_END:'Sluttid',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_LINK:'Länk',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_LINK_TEXT:'Länk till planering',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_START:'Starttid',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_ROUTENAME:'Tur',
PROFILE_PAGE_GAP_REPORT_SUBTITLE:'Visa en rapport på glappet mellan arbetsschema och planerade aktiviteter under en valfri tidsperiod.',
PROFILE_PAGE_GAP_REPORT_TITLE:'Glapprapport',
PROFILE_PAGE_MANUALS_ACT_AND_PLAN:'3. Manual skapa aktiviteter och planering',
PROFILE_PAGE_MANUALS_ENABLE_DEVICE:'4. Manual aktivera aktivitetstavla',
PROFILE_PAGE_MANUALS_GET_START_WITH_TENILO:'1. Kom igång med Tenilo',
PROFILE_PAGE_MANUALS_LINK:'Manualer',
PROFILE_PAGE_MANUALS_REPORTS:'5. Manual rapporter',
PROFILE_PAGE_MANUALS_SUBTITLE:'Användarmanualerna för Tenilo',
PROFILE_PAGE_MANUALS_TEMPLATE_ACTIVITIES:'2. Manual skapa grundmallar',
PROFILE_PAGE_MANUALS_TITLE:'Manualer',
PROFILE_PAGE_MANUALS_USER:'Manual för utförare',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_LABEL:'Info',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_LINK:'Supportinformation',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_SUBTITLE:'Supportinformation för organisationen',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_TITLE:'Supportinformation',
RECURRANCE_INTERVAL_1:'Varje',
RECURRANCE_INTERVAL_10:'Var tionde',
RECURRANCE_INTERVAL_11:'Var elfte',
RECURRANCE_INTERVAL_12:'Var tolfte',
RECURRANCE_INTERVAL_2:'Varannan',
RECURRANCE_INTERVAL_3:'Var tredje',
RECURRANCE_INTERVAL_4:'Var fjärde',
RECURRANCE_INTERVAL_5:'Var femte',
RECURRANCE_INTERVAL_6:'Var sjätte',
RECURRANCE_INTERVAL_7:'Var sjunde',
RECURRANCE_INTERVAL_8:'Var åttonde',
RECURRANCE_INTERVAL_9:'Var nionde',
REPORT_FIELD_ActivityTitle:'Tilläggstext',
REPORT_FIELD_AreaName:'Avdelning',
REPORT_FIELD_CustomerRoomNumber:'Kortnamn/nummer',
REPORT_FIELD_DelegatedAreaName:'Delegerad mot avdelning',
REPORT_FIELD_DelegatedBy:'Delegerad av',
REPORT_FIELD_DelegatedCustomerFullName:'Delegerad mot kund',
REPORT_FIELD_DelegationTitle:'Delegering',
REPORT_FIELD_EndTime:'Sluttid',
REPORT_FIELD_ExpectedAmount:'Förväntad mängd',
REPORT_FIELD_ExtraInformation:'Extra info',
REPORT_FIELD_Firstname:'Förnamn',
REPORT_FIELD_GivenAmount:'Given mängd',
REPORT_FIELD_Hour00:'00',
REPORT_FIELD_Hour01:'01',
REPORT_FIELD_Hour02:'02',
REPORT_FIELD_Hour03:'03',
REPORT_FIELD_Hour04:'04',
REPORT_FIELD_Hour05:'05',
REPORT_FIELD_Hour06:'06',
REPORT_FIELD_Hour07:'07',
REPORT_FIELD_Hour08:'08',
REPORT_FIELD_Hour09:'09',
REPORT_FIELD_Hour10:'10',
REPORT_FIELD_Hour11:'11',
REPORT_FIELD_Hour12:'12',
REPORT_FIELD_Hour13:'13',
REPORT_FIELD_Hour14:'14',
REPORT_FIELD_Hour15:'15',
REPORT_FIELD_Hour16:'16',
REPORT_FIELD_Hour17:'17',
REPORT_FIELD_Hour18:'18',
REPORT_FIELD_Hour19:'19',
REPORT_FIELD_Hour20:'20',
REPORT_FIELD_Hour21:'21',
REPORT_FIELD_Hour22:'22',
REPORT_FIELD_Hour23:'23',
REPORT_FIELD_IsTemporaryActivity:'Tillfällig',
REPORT_FIELD_Lastname:'Efternamn',
REPORT_FIELD_Legislation:'Lagrum',
REPORT_FIELD_MissingDelegations:'Saknade delegeringar',
REPORT_FIELD_ParticipantsAttended:'Deltog',
REPORT_FIELD_ParticipantsDenied:'Avböjde',
REPORT_FIELD_Personell:'Personal',
REPORT_FIELD_PlannedActivityComment:'Kommentar',
REPORT_FIELD_PlannedActivityStartTime:'Planerad start',
REPORT_FIELD_PlannedActivityTime:'Planerad tid',
REPORT_FIELD_PlannedActivityTimeMinutes:'Planerad tid(minuter)',
REPORT_FIELD_PlannedParticipants:'Planerade deltagare',
REPORT_FIELD_PlanningLink:'Länk till planering',
REPORT_FIELD_RouteName:'Tur',
REPORT_FIELD_SignedBy:'Signerad av',
REPORT_FIELD_SignedByExtraInformation:'Extra info',
REPORT_FIELD_SignedPlannedTimeDiff:'Diff (planerad / signerad)',
REPORT_FIELD_SignedTime:'Signerad tid',
REPORT_FIELD_SigningComment:'Signeringskommentar',
REPORT_FIELD_SigningModalLink:'Öppna signering',
REPORT_FIELD_SigningType:'Signeringstyp',
REPORT_FIELD_StartTime:'Startid',
REPORT_FIELD_Substitute:'Ersättare',
REPORT_FIELD_TemplateActivity:'Aktivitetsmall',
REPORT_FIELD_TemplateActivityCategory:'Kategori',
REPORT_FIELD_Title:'Titel',
REPORT_FIELD_Total:'Totalt',
REPORT_FIELD_Unit:'Enhet',
REPORT_PAGE_TABLE_BORDERS:'Ramar',
REPORT_PAGE_TABLE_EXPORT:'Export',
REPORT_PAGE_TABLE_NONE:'Inga',
REPORT_PAGE_TABLE_PAGE:'Sida',
REPORT_PAGE_TABLE_SHOW_ROW_NO:'Visa radnummer',
REPORT_PAGE_VIEW_DELETE_DIALOG_CANCEL_BUTTON:'Avbryt',
REPORT_PAGE_VIEW_DELETE_DIALOG_DESCRIPTION:'Du håller på att ta bort {title}. Är du säker?',
REPORT_PAGE_VIEW_DELETE_DIALOG_REMOVE_BUTTON:'Ta bort',
REPORT_PAGE_VIEW_DELETE_DIALOG_TITLE:'Ta bort rapport',
REPORT_PAGE_VIEW_FILTER_REPORTS_ALL:'Alla',
REPORT_PAGE_VIEW_FILTER_REPORTS_AREA:'Avdelning',
REPORT_PAGE_VIEW_FILTER_REPORTS_BASE:'Bas',
REPORT_PAGE_VIEW_FILTER_REPORTS_ORGANISATION:'Organisation',
REPORT_PAGE_VIEW_FILTER_REPORTS_USER:'Mina rapporter',
REPORT_PAGE_VIEW_FILTER_SELECTION_ActivityType:'Aktivitetstyp',
REPORT_PAGE_VIEW_FILTER_SELECTION_Area:'Avdelning',
REPORT_PAGE_VIEW_FILTER_SELECTION_Customer:'Kund',
REPORT_PAGE_VIEW_FILTER_SELECTION_DESCRIPTION:'Beskrivning',
REPORT_PAGE_VIEW_FILTER_SELECTION_DeclinedParticipant:'Avböjde',
REPORT_PAGE_VIEW_FILTER_SELECTION_DelegationEndDate:'Delegation datum',
REPORT_PAGE_VIEW_FILTER_SELECTION_DelegationEndDate_From:'Delegation datum - från',
REPORT_PAGE_VIEW_FILTER_SELECTION_DelegationEndDate_To:'till',
REPORT_PAGE_VIEW_FILTER_SELECTION_DistributionAggregateType:'Aggregeringsnivå',
REPORT_PAGE_VIEW_FILTER_SELECTION_DistributionGroupType:'Grupperingstyp',
REPORT_PAGE_VIEW_FILTER_SELECTION_Legislation:'Lagrum',
REPORT_PAGE_VIEW_FILTER_SELECTION_NAME:'Namn',
REPORT_PAGE_VIEW_FILTER_SELECTION_Participant:'Deltog',
REPORT_PAGE_VIEW_FILTER_SELECTION_PlannedDate:'Planerat datum',
REPORT_PAGE_VIEW_FILTER_SELECTION_PlannedDate_From:'Planerat datum - från',
REPORT_PAGE_VIEW_FILTER_SELECTION_PlannedDate_To:'till',
REPORT_PAGE_VIEW_FILTER_SELECTION_Recurrence:'Upprepning',
REPORT_PAGE_VIEW_FILTER_SELECTION_Route:'Tur',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignDate:'Signerat datum',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignDate_From:'Signerat datum - från',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignDate_To:'till',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignType:'Signeringstyp',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignedBy:'Signerad av',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemplateActivity:'Aktivitetsmall',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemplateActivityCategory:'Kategori',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemplateActivityType:'Aktivitetstyp',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemporaryActivity:'Tillfällig aktivitet',
REPORT_PAGE_VIEW_FILTER_SELECTION_User:'Personal',
REPORT_PAGE_VIEW_FILTER_SELECTION_ViewReport:'Visa rapport',
REPORT_PAGE_VIEW_HEAD_TITLE:'Rapporter',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES:'Tillhörighet',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM:'Omvandla till organisationsrapport',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM_ABORT:'Avbryt omvandling',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM_INFO:'Tryck på spara för att slutföra omvandlingen till organisationsrapport.',
REPORT_PAGE_VIEW_SECTION_COLUMNS:'Kolumner',
REPORT_PAGE_VIEW_SECTION_INFO:'Beskrivning',
REPORT_PAGE_VIEW_SECTION_NAMEANDDESCRIPTION:'Namn och Beskrivning',
REPORT_PAGE_VIEW_SECTION_SELECTIONS:'Urval',
REPORT_PAGE_VIEW_SECTION_SUBMIT:' ',
REPORT_PAGE_VIEW_SECTION_SUBMIT_CANCEL:'Avbryt',
REPORT_PAGE_VIEW_SECTION_SUBMIT_REMOVE:'Ta bort',
REPORT_PAGE_VIEW_SECTION_SUBMIT_SAVE:'Spara',
REPORT_PAGE_VIEW_SECTION_SUBMIT_SAVEAS:'Spara som',
REPORT_PAGE_VIEW_SECTION_SUBMIT_UPDATE:'Spara',
REPORT_PAGE_VIEW_SELECT_TITLE:'Välj rapport',
RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_INPUT_LABEL:'Bekräfta lösenord',
RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_MIN_LENGTH:'Lösenordet behöver vara minst 8 tecken långt',
RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_VALIDATION_EQUAL:'Lösenorden matchar ej',
RESET_PASSWORD_PAGE_NEW_PASSWORD_INPUT_LABEL:'Nytt lösenord',
RESET_PASSWORD_PAGE_RESET_PASSWORD_TITLE:'Uppdatera lösenord',
RESET_PASSWORD_PAGE_SUBMIT_BUTTON_TEXT:'Uppdatera lösenord',
RESET_PINCODE_PAGE_CONFIRM_PINCODE_INPUT_LABEL:'Bekräfta pinkod',
RESET_PINCODE_PAGE_CONFIRM_PINCODE_VALIDATION_EQUAL:'Pinkoderna matchar ej',
RESET_PINCODE_PAGE_NEW_PINCODE_INPUT_LABEL:'Ny pinkod',
RESET_PINCODE_PAGE_RESET_PINCODE_TITLE:'Uppdatera pinkod',
RESET_PINCODE_PAGE_SUBMIT_BUTTON_TEXT:'Uppdatera pinkod',
ROUTE_EDIT_MODAL_UNIQUE_COLOR_HELPER_TEXT:'Kan inte ha samma färg som annan tur',
ROUTE_EDIT_MODAL_UNIQUE_NAME_HELPER_TEXT:'Kan inte ha samma namn som annan tur',
ROUTE_MENU_PLANNING_COMPLIANCE_COMMOM_ACTIVITY:'Allmän aktivitet',
ROUTE_MENU_PLANNING_COMPLIANCE_NON_COMPLIANT_WORKSCHEDULES:'Personal och arbetspass som ej uppfyller krav',
ROUTE_MENU_PLANNING_COMPLIANCE_NOT_SATISFIED_REQUIREMENTS:'Ej uppfyllda krav',
ROUTE_MENU_PLANNING_COMPLIANCE_OUTSIDE_WORKSCHEDULES:'Planerade aktiviteter utanför arbetspass',
ROUTE_MENU_PLANNING_COMPLIANCE_WORKSCHEDULES_IN_CONFLICT:'Arbetspass i konflikt',
ROUTE_MENU_PLANNING_PARTICIPANTS:'Deltagare',
ROUTE_MENU_PLANNING_REQUIREMENTS:'Delegeringskrav',
SIGNED_ACTIVITIES_VIEW_DIALOG_ACTIVITY_NAME_TABLE_HEADER:'Tilläggstext',
SIGNED_ACTIVITIES_VIEW_DIALOG_ACTIVITY_PLANNED_TIME_TABLE_HEADER:'Planerad',
SIGNED_ACTIVITIES_VIEW_DIALOG_CUSTOMER_FIRSTNAME_TABLE_HEADER:'Förnamn',
SIGNED_ACTIVITIES_VIEW_DIALOG_CUSTOMER_LASTNAME_TABLE_HEADER:'Efternamn',
SIGNED_ACTIVITIES_VIEW_DIALOG_CUSTOMER_ROOM_NUMBER_TABLE_HEADER:'Kortnamn/nummer',
SIGNED_ACTIVITIES_VIEW_DIALOG_DECLINED_PLANNED_ACTIVITY_SIGNED_PARTICIPANTS_TABLE_HEADER:'Avböjde',
SIGNED_ACTIVITIES_VIEW_DIALOG_DIFF_TABLE_HEADER:'Diff (planerad / signerad)',
SIGNED_ACTIVITIES_VIEW_DIALOG_LEGISLATION_TABLE_HEADER:'Lagrum',
SIGNED_ACTIVITIES_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'Finns inga signeringar mellan valda datum',
SIGNED_ACTIVITIES_VIEW_DIALOG_PARTICIPATED_PLANNED_ACTIVITY_SIGNED_PARTICIPANTS_TABLE_HEADER:'Deltog',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_CANCELED:'Ångrad',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_COMPLETED:'Utförd',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_DELAYED:'Senarelagd',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_NOT_COMPLETED:'Signerad utan handling',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_PARTIALLY_COMPLETE:'Delvis utförd',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_PARTIALLY_GIVEN:'Delvis given',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_COMMENT_TABLE_HEADER:'Signeringskommentar',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_DATE_TABLE_HEADER:'Signerad',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_EXPECTED_AMOUNT_TABLE_HEADER:'Förväntad mängd',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_GIVEN_AMOUNT_TABLE_HEADER:'Given mängd',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_NOTE_TABLE_HEADER:'Kommentar',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_UNIT_TABLE_HEADER:'Enhet',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_USER_TABLE_HEADER:'Signerad av',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGN_TYPE_TABLE_HEADER:'Signeringstyp',
SIGNED_ACTIVITIES_VIEW_DIALOG_TEMPLATE_ACTIVITY_CATEGORY_NAME_TABLE_HEADER:'Kategori',
SIGNED_ACTIVITIES_VIEW_DIALOG_TEMPLATE_ACTIVITY_NAME_TABLE_HEADER:'Aktivitetsmall',
SIGNED_ACTIVITIES_VIEW_DIALOG_TITLE:'Signerade Aktiviteter',
SIGNED_ACTIVITY_CHANGE_ORGANIZATION:'Byt organisation',
SIGNED_ACTIVITY_DASHBOARD_SECTION_BUTTON_TEXT:'Visa historik',
SIGNED_ACTIVITY_DASHBOARD_SECTION_SUBTITLE:'Visa historik över signerade aktiviteter',
SIGNED_ACTIVITY_DASHBOARD_SECTION_TITLE:'Signerad aktivitet',
SIGNING_PARTICIPANTS_MODAL_TITLE:'Deltagarlista',
SIGNING_PARTICIPANTS_MODAL_TITLE_WITHCOMMENT:'Kommentar och deltagarlista',
SIGNING_STATUS_CANNOT_TAKE:'Kan ej ta',
SIGNING_STATUS_COMPLETED:'Utförd',
SIGNING_STATUS_COMPLETED_WITHOUT_ACTION:'Signerad utan handling',
SIGNING_STATUS_DELAYED:'Senarelagd',
SIGNING_STATUS_GIVEN:'Given',
SIGNING_STATUS_NOT_COMPLETED:'Ångrad',
SIGNING_STATUS_NOT_GIVEN:'Ej given',
SIGNING_STATUS_PARTIALLY_COMPLETE:'Rapportera avvikande',
SIGNING_STATUS_PARTIALLY_GIVEN:'Delvis given',
SIGNING_STATUS_PARTIALLY_HELPER:'Möjligt att rapportera avvikande mängd vid signering.',
SIGNING_STATUS_RELATIVE_GIVEN:'Anhörig givit',
SIGNING_STATUS_SELF_GIVEN:'Tagit själv',
SIGNING_STATUS_SENT_ALONG:'Medskickad',
SIGNING_STATUS_STARTED:'Påbörjad',
SINGLE_ACTIVITY_MODAL_BY:'av',
SINGLE_ACTIVITY_MODAL_UNDO:'Ångra',
START_PAGE_VIEW_ACTIONS_GETINFO_BUTTON_TEXT:'Hämta min information',
START_PAGE_VIEW_ACTIONS_LOGOUT_BUTTON_TEXT:'Logga ut',
START_PAGE_VIEW_ACTIONS_REMOVETOKEN_BUTTON_TEXT:'Släpp Token',
START_PAGE_VIEW_INFOSECTION_NAME_TEXT:'Namn',
SUPPORT_AREAS_COLUMN_AREANAME:'Avdelning',
SUPPORT_AREAS_COLUMN_ORGANIZATIONNAME:'Organisation',
SUPPORT_AREAS_COLUMN_PARENTSNAME:'Organisationsstruktur',
SUPPORT_AREA_BUTTON:'Hantera avdelning',
SUPPORT_AREA_MODAL_DIALOG_ACTIVE_TITLE:'Aktiv',
SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_MESSAGE:'Genom att starta gallring så kommer ALLA personuppgifter (för avdelningens allmänna aktiviteter, kunder som är kopplade mot avdelningen och sådan personal som ENDAST tillhör den aktuella avdelningen) att anonymiseras. Vänligen säkerställ att det utförs på uppdrag av organisationen eller kundansvarig. Det går EJ att återställa informationen.',
SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_OK:'Starta gallring',
SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_TITLE:'Gallra personuppgifter',
SUPPORT_AREA_MODAL_DIALOG_AREA_ANONYMIZE:'Gallra personuppgifter',
SUPPORT_AREA_MODAL_DIALOG_AREA_TITLE:'Avdelning',
SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_LABEL:'Datum',
SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_SUBTITLE:'Ange det datum då avdelningen önskar att autosigneringen skall slås av.',
SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_TITLE:'Datum då autosigneringen skall slås av',
SUPPORT_AREA_MODAL_DIALOG_HAS_NOT_PERFORMAUTOSIGN_AND_DIRTY_DESCRIPTION:'Autosigneringen kommer att slås av vid sparning.',
SUPPORT_AREA_MODAL_DIALOG_HAS_NOT_PERFORMAUTOSIGN_DESCRIPTION:'Autosigneringen är avslagen.',
SUPPORT_AREA_MODAL_DIALOG_HAS_PERFORMAUTOSIGN_AND_DIRTY_DESCRIPTION:'Autosigneringen kommer att slås på vid sparning och kommer att utföras kommande natt (samt efterföljande nätter).',
SUPPORT_AREA_MODAL_DIALOG_HAS_PERFORMAUTOSIGN_DESCRIPTION:'Autosigneringen är påslagen.',
SUPPORT_AREA_MODAL_DIALOG_HEADER_TITLE:'Hantera avdelning',
SUPPORT_AREA_MODAL_DIALOG_IS_ACTIVE_AND_DIRTY_DESCRIPTION:'Avdelningen kommer att aktiveras vid sparning.',
SUPPORT_AREA_MODAL_DIALOG_IS_ACTIVE_DESCRIPTION:'Avdelningen är aktiv. Ifall organisationen önskar inaktivera avdelningen så bör man först säkerställa att aktiva kunder flyttats från den aktuella avdelningen.',
SUPPORT_AREA_MODAL_DIALOG_IS_NOT_ACTIVE_AND_DIRTY_DESCRIPTION:'Avdelningen kommer att inaktiveras vid sparning',
SUPPORT_AREA_MODAL_DIALOG_IS_NOT_ACTIVE_DESCRIPTION:'Avdelningen är inaktiv',
SUPPORT_AREA_MODAL_DIALOG_NOTIFY_TENILO_REPRESENTATIVE:'OBS! Meddela kundansvarig på Tenilo ifall ändringen sparas.',
SUPPORT_AREA_MODAL_DIALOG_ORGANIZATION_TITLE:'Organisation',
SUPPORT_AREA_MODAL_DIALOG_PERFORMAUTOSIGN_TITLE:'Autosignering',
SUPPORT_GIDS_COLUMN_GRANDIDPARAMETERID:'Parameter',
SUPPORT_GIDS_COLUMN_GRANDIDSERVICEID:'Service',
SUPPORT_GIDS_COLUMN_PARAMETERVALUE:'Parametervärde',
SUPPORT_GRANDID_MODAL_DIALOG_ACTIVE_SUBTITLE:'Genom att aktivera en service så kommer denna bli tillgänglig som inloggningsmetod på inloggningssidan!',
SUPPORT_GRANDID_MODAL_DIALOG_ACTIVE_TITLE:'Aktiv',
SUPPORT_GRANDID_MODAL_DIALOG_AUTHKEY_SUBTITLE:'Ange den nyckel som erhållits från Svensk e-Id och som är aktuell för den valda servicen. OBS! Detta är ej kund api nyckeln! Kund api nyckel skall matas in på organisationen!',
SUPPORT_GRANDID_MODAL_DIALOG_AUTHKEY_TITLE:'Authenticate Service Key',
SUPPORT_GRANDID_MODAL_DIALOG_SERVICE_TITLE:'Service',
SUPPORT_IMPERSONATING_MESSAGE:'OBS! Du är i supportläge för användaren.',
SUPPORT_IMPERSONATING_MESSAGE_REVERT:'Lämna',
SUPPORT_IMPERSONATING_MESSAGE_VALID_UNTIL:'Giltigt till: {validUntil} ({numMinutes} minuter).',
SUPPORT_ORGANIZATION_BUTTON:'Hantera organisation',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ACTIVE_TITLE:'Aktiv',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ADD_GRANDID_SETTING_BUTTON_TEXT:'Lägg till inloggningsmetod',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWAREATEMPLATEACTIVITIES_SUBTITLE:'Möjliggör för avdelningarna inom organisationen att skapa egna aktivitetsmallar.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWAREATEMPLATEACTIVITIES_TITLE:'Tillåt aktivitetsmallar på avdelningsnivå',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWCOMMENTONSIGNING_SUBTITLE:'Möjliggör för personalen att ge en kommentar i samband med signering av en aktivitet.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWCOMMENTONSIGNING_TITLE:'Tillåt kommentar på signering',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSIGNINGWHENDELEGATIONSISNOTFULFILLED_TITLE:'Tillåt signering vid ej uppfyllda delegeringskrav',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTANDARDLOGIN_SUBTITLE:'Standardlogin är epostadress + lösenord. Ifall organisationen använder sig exempelvis av SAML inloggning så kan denna inställning användas för att ej tillåta standardinloggningen. Övriga delar i systemet som rör standardinloggningen är dock orörda av detta.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTANDARDLOGIN_TITLE:'Tillåt standardlogin',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTARTEDACTIVITYSTATUS_SUBTITLE:'Visar en knapp som kan användas för att indikera att aktiviteten är startad.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTARTEDACTIVITYSTATUS_TITLE:'Tillåt status Påbörjad på aktivitetstavlan',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_MESSAGE:'Genom att starta gallring så kommer ALLA personuppgifter (för användare och kunder samt avdelningarnas allmänna aktiviteter inom organisationen) att anonymiseras. Vänligen säkerställ att det utförs på uppdrag av organisationen eller kundansvarig. Det går EJ att återställa informationen.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_OK:'Starta gallring',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_TITLE:'Gallra personuppgifter',
SUPPORT_ORGANIZATION_MODAL_DIALOG_CUSTOMERPAUSEDPERIODDAYSHORIZON_SUBTITLE:'Anger hur många dagar innan en pausad brukare återkommer som notifiering skall skickas ut.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_CUSTOMERPAUSEDPERIODDAYSHORIZON_TITLE:'Pausade brukare - tidhorisont (dagar)',
SUPPORT_ORGANIZATION_MODAL_DIALOG_EXPECTEDEMAILDOMAIN_TITLE:'Förväntad epostdomän',
SUPPORT_ORGANIZATION_MODAL_DIALOG_EXPIRINGDELEGATIONSWEEKSHORIZON_TITLE:'Utgående delegeringar - tidhorisont (veckor)',
SUPPORT_ORGANIZATION_MODAL_DIALOG_FEATURE_INFO:'OBS! Ändringar som innebär att tilläggsfunktionalitet slås på eller av skall göras i samråd med kundansvarig hos Tenilo.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_GRANDIDORGANIZATIONIDENTIFIER_SUBTITLE:'Identifieraren används av användaren för att ange vilken organisation som inloggningen gäller. Sätt en identiferare som är unik (kontroll görs ej!).',
SUPPORT_ORGANIZATION_MODAL_DIALOG_GRANDIDORGANIZATIONIDENTIFIER_TITLE:'GrandID organisationsidentifierare',
SUPPORT_ORGANIZATION_MODAL_DIALOG_HEADER_TITLE:'Hantera organisation',
SUPPORT_ORGANIZATION_MODAL_DIALOG_INACTIVE_AND_DIRTY_TITLE:'OBS! Organisationen kommer att inaktiveras vid sparning. Organisationen kommer ej längre att gå att komma åt för alla användare inom organisationen.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_LOGINSLIDINGEXPIRATIONMINUTES_SUBTITLE:'Använd automatisk utloggning för organisationen. Anges tillsammans med antal minuter.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_LOGINSLIDINGEXPIRATIONMINUTES_TITLE:'Automatisk utloggning',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_ANONYMIZE:'Gallra personuppgifter',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_IP_RANGE:'IP-range för aktivitettavla (auto-trust)',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_TITLE:'Namn',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REGISTRATIONNUMBER_TITLE:'Organisationsnummer',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_MESSAGE:'Genom att ta bort inlogggningsmetoden så blir det inte inte längre möjligt för aktuell organisation att logga in med valda metoden. Är du säker på att du vill ta bort?',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_OK:'Ta bort',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_TITLE:'Borttag av inloggningsmetod',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_0_TITLE:'Grunduppgifter',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_1_TITLE:'Inställningar',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_2_TITLE:'Tilläggsfunktionalitet',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_3_TITLE:'GrandID-inställningar',
SUPPORT_ORGANIZATION_MODAL_DIALOG_USEDELEGATIONS_TITLE:'Använder delegeringar',
SUPPORT_ORGANIZATION_MODAL_DIALOG_USEDRUGLISTS_TITLE:'Använder läkemedelshantering',
SUPPORT_ORGANIZATION_MODAL_DIALOG_USEWORKSCHEDULE_TITLE:'Använder personalscheman',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETERVALUE_SUBTITLE:' ',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETERVALUE_TITLE:'Parametervärde',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_APIKEY_TITLE:'Api nyckel',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_SUBTITLE:' ',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_TITLE:'Parametertyp',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_BANKID_TITLE:'BankId',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_FREJAEID_TITLE:'Freja eID',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_SAML_TITLE:'Saml',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_SUBTITLE:' ',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_TITLE:'GrandID service',
SUPPORT_PAGE_VIEW_AREA_LABEL:'Avdelning',
SUPPORT_PAGE_VIEW_HEAD_TITLE:'Support',
SUPPORT_PAGE_VIEW_ORGANIZATION_LABEL:'Organisation',
SUPPORT_PAGE_VIEW_TAB_0_TITLE:'Användare',
SUPPORT_PAGE_VIEW_TAB_1_TITLE:'Organisation och avdelningar',
SUPPORT_PAGE_VIEW_TAB_2_TITLE:'Inloggningsbegäran - användare',
SUPPORT_PAGE_VIEW_TAB_3_TITLE:'Systeminställningar',
SUPPORT_USERSIMPERSONATION_COLUMN_ORGANIZATIONNAME:'Organisation',
SUPPORT_USERSIMPERSONATION_COLUMN_REQUESTEDON:'Upprättad',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONABANDONEDON:'Avslutad',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONAPPROVEDON:'Godkänd',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONDENIEDON:'Avböjd',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONEXPIRESON:'Giltig till',
SUPPORT_USERSIMPERSONATION_COLUMN_STATUS:'Status',
SUPPORT_USERSIMPERSONATION_COLUMN_USER:'Användare',
SUPPORT_USERS_COLUMN_EMAIL:'Epost',
SUPPORT_USERS_COLUMN_FULLNAME:'Namn',
SUPPORT_USERS_COLUMN_IMPERSONATE_APPROVED_BUTTON_TOOLTIP:'Begäran om att logga in som {fullName} är godkänd',
SUPPORT_USERS_COLUMN_IMPERSONATE_AWAITING_BUTTON_TOOLTIP:'Inväntar godkännande av begäran om att logga in som {fullName}',
SUPPORT_USERS_COLUMN_IMPERSONATE_BUTTON_TOOLTIP:'Skapa begäran om att logga in som {fullName}',
SUPPORT_USERS_COLUMN_IMPERSONATE_NOT_ALLOWED_BUTTON_TOOLTIP:'Det går ej att skapa begäran för användare som endast har rollen personal',
SUPPORT_USERS_COLUMN_ORGANIZATIONNAME:'Organisation',
SUPPORT_USERS_COLUMN_PHONENUMBER:'Telefonnummer',
SUPPORT_USERS_COLUMN_USERTYPEID:'Användartyp',
SUPPORT_USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_AREA_COLUMN_TITLE:'Avdelning',
SUPPORT_USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_ROLE_COLUMN_TITLE:'{role, select, Admin {Admin} Planner {Planerare} Caretaker {Personal} other { }}',
SUPPORT_USER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basinformation',
SUPPORT_USER_DIALOG_VIEW_TAB_ROLES_TITLE:'Roller',
SUPPORT_USER_IMPERSONATE_DIALOG_ABANDON_REQUEST:'Avsluta inloggningsbegäran',
SUPPORT_USER_IMPERSONATE_DIALOG_ACTIVE_REQUEST_SUBTITLE:' ',
SUPPORT_USER_IMPERSONATE_DIALOG_ACTIVE_REQUEST_TITLE:'Status aktiv begäran',
SUPPORT_USER_IMPERSONATE_DIALOG_CREATE_REQUEST:'Skapa begäran',
SUPPORT_USER_IMPERSONATE_DIALOG_HEADER_TITLE:'Inloggningsbegäran - användare',
SUPPORT_USER_IMPERSONATE_DIALOG_LOGIN_AS_USER:'Logga in som användaren',
SUPPORT_USER_IMPERSONATE_DIALOG_REMOVE_REQUEST:'Ta bort pågående begäran',
SUPPORT_USER_IMPERSONATE_DIALOG_USER_SUBTITLE:' ',
SUPPORT_USER_IMPERSONATE_DIALOG_USER_TITLE:'Användare',
SUPPORT_USER_IMPERSONATE_DIALOG_VALID_TO_TITLE:'Giltig till',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDONEDON_SUBTITLE:'Datum och tid då sessionen avslutades',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDONEDON_TITLE:'Avslutad',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDON_REQUEST:'Avsluta inloggningsbegäran',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_HEADER_TITLE:'Inloggningsbegäran - användare',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_LOGIN_AS_USER:'Logga in som användaren',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ORGANIZATION_TITLE:'Organisation',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_REQUESTEDON_SUBTITLE:'Datum och tid då begäran om inloggning som användaren upprättades',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_REQUESTEDON_TITLE:'Upprättad',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_STATUS_SUBTITLE:'Information om aktuell status för begäran',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_STATUS_TITLE:'Status',
SUPPORT_USER_IMPERSONATIONREQUEST_EMAIL_LABEL:'Epost',
SUPPORT_USER_IMPERSONATIONREQUEST_FIRSTNAME_LABEL:'Förnamn',
SUPPORT_USER_IMPERSONATIONREQUEST_INFOTEXT_TITLE:'Personuppgifter',
SUPPORT_USER_IMPERSONATIONREQUEST_LASTNAME_LABEL:'Efternamn',
SUPPORT_USER_IMPERSONATIONREQUEST_PHONENUMBER_LABEL:'Telefonnummer',
SUPPORT_USER_MODAL_DIALOG_HEADER_TITLE:'Supportera användare',
SUPPORT_USER_MODAL_DIALOG_ORGANIZATION_TITLE:'Organisation',
SUPPORT_USER_MODAL_DIALOG_TYPE_MEMBER:'Medlem',
SUPPORT_USER_MODAL_DIALOG_TYPE_ORGADMIN:'Organisationsadmininstratör',
SUPPORT_USER_MODAL_DIALOG_TYPE_SUBTITLE:'"Medlem" används för vanliga användare såsom personal och avdelningsadministatörer. "Organisationsadministratör" skall enbart användas då användaren behöver komma åt allt inom organisationen.',
SUPPORT_USER_MODAL_DIALOG_TYPE_TITLE:'Användartyp',
SUPPORT_USER_MODAL_EMAIL_LABEL:'Epost',
SUPPORT_USER_MODAL_FIRSTNAME_LABEL:'Förnamn',
SUPPORT_USER_MODAL_INACTIVE_USER:'OBS! Detta är en inaktiv användare! Skall användaren återaktiveras behöver detta göras av en avdelnings- eller organisationsadministratör.',
SUPPORT_USER_MODAL_INFOTEXT_TITLE:'Användarinformation',
SUPPORT_USER_MODAL_LASTNAME_LABEL:'Efternamn',
SUPPORT_USER_MODAL_PHONENUMBER_LABEL:'Telefonnummer',
SWITCH_DEVICE_MODE_DASHBOARD_SECTION_BUTTON_TEXT:'Visa aktivitetstavel-läge',
SWITCH_DEVICE_MODE_DASHBOARD_SECTION_SUBTITLE:'Visar aktivitetsvyn',
SWITCH_DEVICE_MODE_DASHBOARD_SECTION_TITLE:'Aktivitetstavla',
TABLE_PAGINATION_COUNT_TEXT:'{from}-{to} av {count}',
TABLE_PAGINATION_FIRST_PAGE_TOOLTIP_TEXT:'Första sidan',
TABLE_PAGINATION_LAST_PAGE_TOOLTIP_TEXT:'Sista sidan',
TABLE_PAGINATION_NEXT_PAGE_TOOLTIP_TEXT:'Nästa sida',
TABLE_PAGINATION_PREVIOUS_PAGE_TOOLTIP_TEXT:'Föregående sida',
TABLE_PAGINATION_ROWS_TEXT:'Rader',
TEST_USE_FORMAT_MESSAGE_TEXT:'Öka',
TEST_USE_FORMAT_MESSAGE_TEXT_AND_VALUE:'Värde {value}',
TIME_PICKER_TOOLBAR_TITLE:'Välj tid',
TOKEN_EXPIRED_TEXT:'Du har blivit utloggad på grund av inaktivitet. Det krävs en ny inloggning för att använda systemet. Tryck på OK knappen för att bli omdirigerad till inloggningssidan.',
TOKEN_EXPIRED_TITLE:'Utloggad',
USERS_PAGE_HEAD_TITLE:'Personal',
USERS_PAGE_HEAD_TITLE_DESCRIPTION:'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sqeui nesciunt',
USERS_PAGE_HEAD_TITLE_OVERLINE:'Administrera',
USERS_PAGE_VIEW_ACTIVATE_BUTTON_TOOLTIP:'Aktivera {fullName}',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_ACTIONS_CANCEL:'Avbryt',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_ACTIONS_REMOVE:'Aktivera',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_MESSAGE:'Du kommer att aktivera {fullName}, är du säker?',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_TITLE:'Aktivera personal',
USERS_PAGE_VIEW_ADD_USERS_EMAIL_LIST_TEXT:'Maila inloggningsuppgifter',
USERS_PAGE_VIEW_ADD_USER_BUTTON_TEXT:'Lägg till personal',
USERS_PAGE_VIEW_COLUMN_AREAS_TEXT:'{firstArea} +{numberOfRemainingAreas} till',
USERS_PAGE_VIEW_COLUMN_AREAS_TITLE:'Avdelning',
USERS_PAGE_VIEW_COLUMN_EMAIL:'Epost',
USERS_PAGE_VIEW_COLUMN_EMAIL_SENT_DATE:'Välkomstmail skickat datum',
USERS_PAGE_VIEW_COLUMN_FIRSTNAME:'Förnamn',
USERS_PAGE_VIEW_COLUMN_INFO:'Information',
USERS_PAGE_VIEW_COLUMN_INFO_INACTIVE:'Inaktiverad',
USERS_PAGE_VIEW_COLUMN_LASTNAME:'Efternamn',
USERS_PAGE_VIEW_COLUMN_NAME_SUBTEXT:' ',
USERS_PAGE_VIEW_COLUMN_NAME_TEXT:' ',
USERS_PAGE_VIEW_COLUMN_NAME_TITLE:'Namn',
USERS_PAGE_VIEW_COLUMN_ROLE_TEXT:'{role, select, Admin {Verksamhetsadmin} Planner {Planerare} Caretaker {Personal} other { }}',
USERS_PAGE_VIEW_COLUMN_ROLE_TITLE:'Roll',
USERS_PAGE_VIEW_COLUMN_STATUS_TEXT:'{active, select, true {Aktiv} false {Inaktiv} other {Inaktiv}}',
USERS_PAGE_VIEW_COLUMN_STATUS_TITLE:'Status',
USERS_PAGE_VIEW_DEACTIVATE_BUTTON_TOOLTIP:'Inaktivera {fullName}',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_CANCEL:'Avbryt',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_REMOVE:'Inaktivera',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_MESSAGE:'Du kommer att inaktivera {fullName}, är du säker?',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_TITLE:'Inaktivera personal',
USERS_PAGE_VIEW_DEACTIVATE_ON_PRINCIPAL_AREAS_DIALOG_MESSAGE:'{fullName} finns på en eller flera avdelningar du inte har tillgång till. Inaktivering ej möjligt. Vill du istället ta bort {fullName} från dina avdelningar?',
USERS_PAGE_VIEW_DEACTIVATE_ON_PRINCIPAL_AREAS_DIALOG_TITLE:'Ta bort personal från dina avdelningar',
USERS_PAGE_VIEW_DELETE_BUTTON_TOOLTIP:'Ta bort {fullName}',
USERS_PAGE_VIEW_DELETE_DIALOG_ACTIONS_CANCEL:'Avbryt',
USERS_PAGE_VIEW_DELETE_DIALOG_ACTIONS_REMOVE:'Ta bort',
USERS_PAGE_VIEW_DELETE_DIALOG_MESSAGE:'Du kommer att ta bort {fullName}, är du säker? I samband med borttaget så kommer gallring av personuppgifterna att utföras.',
USERS_PAGE_VIEW_DELETE_DIALOG_TITLE:'Ta bort personal',
USERS_PAGE_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'Ingen personal finns tillgänglig',
USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_PRINCIPAL_TITLE:'Skicka till dig själv',
USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_RESET_PASSOWRD_TITLE:'Återställ lösenord',
USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_WELCOME_EMAIL_TITLE:'Skicka välkomstmail',
USERS_PAGE_VIEW_EMAIL_DIALOG_USER_TITLE:'Användare',
USERS_PAGE_VIEW_EMAIL_SELECTED_USERS_TEXT:'Maila vald personal',
USERS_PAGE_VIEW_HEAD_DESCRIPTION:'Lägg till och/eller administrera registrerad personal',
USERS_PAGE_VIEW_HEAD_OVERLINE:'Administrera',
USERS_PAGE_VIEW_HEAD_TITLE:'Personal',
USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_ACTIONS_OK:'Avbryt',
USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_MESSAGE:'Det går inte att aktivera {fullName}. Säkerställ att ingen aktiv personal använder epost-adressen {email}',
USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_TITLE:'Aktivera personal tillåts ej',
USERS_PAGE_VIEW_REMOVE_FROM_AREAS_DIALOG_ACTIONS_REMOVE:'Ta bort',
USERS_PAGE_VIEW_TAB_ACTIVE_USERS:'Aktiv personal',
USERS_PAGE_VIEW_TAB_INACTIVE_USERS:'Inaktiv personal',
USERS_PAGE_VIEW_TAB_ORGANIZATION_USERS:'Övrig personal',
USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_AREA_OR_CUSTOMER_MUST_BE_SELECTED:'Antingen avdelning eller kund måste väljas',
USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_DELEGATED_BY_REQUIRED:'Delegerad av måste anges',
USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_DELEGATIONENDTIME_INVALID:'Slutdatum får inte vara tidigare än startdatum',
USER_DIALOG_VIEW_DELEGATION_ADD_BUTTON_TEXT:'Lägg till delegering',
USER_DIALOG_VIEW_DELEGATION_ADD_PLACEMENT_BUTTON_TITLE:'Lägg till',
USER_DIALOG_VIEW_DELEGATION_AREA_CUSTOMER_TITLE:'Avdelning/Kund',
USER_DIALOG_VIEW_DELEGATION_AREA_TITLE:'Avdelning',
USER_DIALOG_VIEW_DELEGATION_AREA_VALUE:'Avdelning: {value}',
USER_DIALOG_VIEW_DELEGATION_CLOSE_PLACEMENT_BUTTON_TITLE:'Fäll ihop',
USER_DIALOG_VIEW_DELEGATION_CUSTOMER_TITLE:'Kund',
USER_DIALOG_VIEW_DELEGATION_CUSTOMER_VALUE:'Kund: {value}',
USER_DIALOG_VIEW_DELEGATION_DEACTIVATE_DIALOG_MESSAGE:'Du kommer att ta bort den här delegeringen, är du säker?',
USER_DIALOG_VIEW_DELEGATION_DEACTIVATE_DIALOG_TITLE:'Ta bort delegering',
USER_DIALOG_VIEW_DELEGATION_DEACTIVE_BUTTON_TITLE:'Ta bort',
USER_DIALOG_VIEW_DELEGATION_DELEGATED_BY_TITLE:'Delegerad av',
USER_DIALOG_VIEW_DELEGATION_DESCRIPTION_TITLE:'Beskrivning',
USER_DIALOG_VIEW_DELEGATION_END_DATE_TITLE:'Slutdatum',
USER_DIALOG_VIEW_DELEGATION_END_DATE_VALUE:'Fram till: {date}',
USER_DIALOG_VIEW_DELEGATION_OPEN_PLACEMENT_BUTTON_TITLE:'Fäll ut',
USER_DIALOG_VIEW_DELEGATION_START_DATE_TITLE:'Startdatum',
USER_DIALOG_VIEW_DELEGATION_START_DATE_VALUE:'Från: {date}',
USER_DIALOG_VIEW_DELEGATION_TITLE:'Delegering',
USER_DIALOG_VIEW_EMAIL_ADMIN_AND_PLANNER_HELPER_TEXT:'Admin och planerare måste ha en e-postadress',
USER_DIALOG_VIEW_EMAIL_UNIQUE_HELPER_TEXT:'E-postadressen måste vara unik',
USER_DIALOG_VIEW_EMAIL_VALID_HELPER_TEXT:'E-postadressen måste vara giltig',
USER_DIALOG_VIEW_EXTRAINFO_LABEL:'Extra information',
USER_DIALOG_VIEW_EXTRAINFO_SUBTITLE:'Information som skrivs här kommer att visas på rapporterna för gjorda signeringar',
USER_DIALOG_VIEW_EXTRAINFO_TITLE:'Extra information',
USER_DIALOG_VIEW_FOOTER_CANCEL_BUTTON_TEXT:'Avbryt',
USER_DIALOG_VIEW_FOOTER_CREATE_BUTTON_TEXT:'Lägg till',
USER_DIALOG_VIEW_FOOTER_UPDATE_BUTTON_TEXT:'Uppdatera',
USER_DIALOG_VIEW_HEADER_SUBTITLE:' ',
USER_DIALOG_VIEW_HEADER_TITLE:'Lägg till personal',
USER_DIALOG_VIEW_INFOSECTION_FORM_EMAIL_DOMAIN_WARNING:'E-postadressen innehåller ej förväntad domän {expectedEmailDomain}',
USER_DIALOG_VIEW_INFOSECTION_FORM_EMAIL_LABEL:'E-post',
USER_DIALOG_VIEW_INFOSECTION_FORM_FIRSTNAME_LABEL:'Förnamn',
USER_DIALOG_VIEW_INFOSECTION_FORM_LASTNAME_LABEL:'Efternamn',
USER_DIALOG_VIEW_INFOSECTION_FORM_PHONENUMBER_LABEL:'Telefonnummer',
USER_DIALOG_VIEW_INFOSECTION_INFOTEXT_SUBTITLE:'Lägg till information som gör det enklare för er att identifiera och kontakta användaren.',
USER_DIALOG_VIEW_INFOSECTION_INFOTEXT_TITLE:'Personuppgifter',
USER_DIALOG_VIEW_OTHERSECTION_FORM_OTHER_LABEL:'Övrig information',
USER_DIALOG_VIEW_OTHERSECTION_INFOTEXT_TITLE:'Övrig information',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_BUTTON_TEXT:'Återställ lösenordet',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_CHECKBOX_TEXT:'Skicka återställt lösenord till dig själv också',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_RECEIPT_TEXT:'Ett mejl med länk för återställande av lösenord har nu skickats.',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_INFOTEXT_TITLE:'Återställ lösenordet',
USER_DIALOG_VIEW_RESETPASSWORDSECTON_MISSING_USER_EMAIL_TEXT:'Personalen behöver en e-post för att kunna återställa lösenordet.',
USER_DIALOG_VIEW_ROLESECTION_FORM_ADDAREA_NO_AREAS_MENU_ITEM:'Inga tillgängliga avdelningar',
USER_DIALOG_VIEW_ROLESECTION_FORM_ADDAREA_SELECT_LABEL:'Lägg till avdelning',
USER_DIALOG_VIEW_ROLESECTION_INFOTEXT_SUBTITLE:'Lägg till avdelningar och markera de roller som användaren ska ha. Användaren måste ha en roll på minst en avdelning.',
USER_DIALOG_VIEW_ROLESECTION_INFOTEXT_TITLE:'Roll',
USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_AREA_COLUMN_TITLE:'Avdelning',
USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_ROLE_COLUMN_TITLE:'{role, select, Admin {Admin} Planner {Planerare} Caretaker {Personal} other { }}',
USER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basinformation',
USER_DIALOG_VIEW_TAB_DELEGATIONS_TITLE:'Delegeringar',
USER_DIALOG_VIEW_TAB_ROLES_TITLE:'Roller',
USER_DIALOG_VIEW_USERSECTION_FORM_AREA_HELPER_TEXT:'Måste välja minst en aktiv avdelning',
USER_DIALOG_VIEW_USERSECTION_FORM_AREA_ROLE_HELPER_TEXT:'Måste välja minst en roll per avdelning',
USER_DIALOG_VIEW_USERSECTION_FORM_PHONENUMBER_HELPER_TEXT:'Telefonnummer har fel format. Exempel: +46701234567',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ERROR_TEXT:'PIN måste vara {length} nummer långt',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_LABEL:'PIN-kod',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ONLY_NUMBERS_ERROR_TEXT:'PIN får bara bestå av siffror',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_REQUIRED_FOR_PERSONNELL_ERROR_TEXT:'PIN måste anges för personal',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_CANNOT_CONTAIN_AT_TEXT:'Användarnamn får inte innehålla @',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_CANNOT_CONTAIN_SPACES_ERROR_TEXT:'Användarnamn får inte innehålla mellanrum',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_CANNOT_CONTAIN_SYMBOL_ERROR_TEXT:'Användarnamn får inte innehålla specialtecken',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_LABEL:'Användarnamn',
USER_DIALOG_VIEW_USERSECTION_INFOTEXT_SUBTITLE:'PIN-koden används av personal för att låsa upp dagsvyn och signera aktiviteter.',
USER_DIALOG_VIEW_USERSECTION_INFOTEXT_TITLE:'Inloggning',
UserImpersonationRequestStatus_Abandoned:'Avslutad',
UserImpersonationRequestStatus_Approved:'Godkänd',
UserImpersonationRequestStatus_AwaitingApproval:'Inväntar godkännande',
UserImpersonationRequestStatus_Denied:'Avböjd av användaren',
UserImpersonationRequestStatus_Expired:'Löpt ut',
WORK_SCHEDULE_REPORT_DASHBOARD_BUTTON:'Visa schemarapport',
WORK_SCHEDULE_REPORT_DASHBOARD_SUBTITLE:'Visa en rapport på personalarbetspass under en valfri tidsperiod.',
WORK_SCHEDULE_REPORT_DASHBOARD_TITLE:'Schemarapport',
WORK_SCHEDULE_REPORT_MODAL_VALUE_AREANAME:'Avdelning (utförande)',
WORK_SCHEDULE_REPORT_MODAL_VALUE_AREA_BELONGING:'Välj avdelningstillhörighet',
WORK_SCHEDULE_REPORT_MODAL_VALUE_ROUTENAME:'Tur',
WORK_SCHEDULE_REPORT_MODAL_VALUE_SCHEDULED_END:'Sluttid',
WORK_SCHEDULE_REPORT_MODAL_VALUE_SCHEDULED_START:'Starttid',
WORK_SCHEDULE_REPORT_MODAL_VALUE_SUBSTITUTEUSER_FULLNAME:'Ersättare',
WORK_SCHEDULE_REPORT_MODAL_VALUE_USER_FULLNAME:'Personal',
muiLocaleColumnHeaderFiltersTooltipActive:'{count, plural, =1 {aktivt filter} other {aktiva filter}}',
muiLocaleColumnMenuFilter:'Filter',
muiLocaleColumnMenuHideColumn:'Göm',
muiLocaleColumnMenuLabel:'Meny',
muiLocaleColumnMenuShowColumns:'Visa kolumner',
muiLocaleColumnMenuSortAsc:'Sortera stigande',
muiLocaleColumnMenuSortDesc:'Sortera fallande',
muiLocaleColumnMenuUnsort:'Ta bort sortering',
muiLocaleColumnsPanelDragIconLabel:'Sortera om kolumn',
muiLocaleColumnsPanelHideAllButton:'Göm alla',
muiLocaleColumnsPanelShowAllButton:'Visa alla',
muiLocaleColumnsPanelTextFieldLabel:'Hitta kolumn',
muiLocaleColumnsPanelTextFieldPlaceholder:'Kolumntitel',
muiLocaleErrorOverlayDefaultLabel:'Ett fel inträffade',
muiLocaleFilterOperatorAfter:'är efter',
muiLocaleFilterOperatorBefore:'är före',
muiLocaleFilterOperatorContains:'innehåller',
muiLocaleFilterOperatorEndsWith:'slutar med',
muiLocaleFilterOperatorEquals:'är lika med',
muiLocaleFilterOperatorIs:'är',
muiLocaleFilterOperatorIsAnyOf:'är någon av',
muiLocaleFilterOperatorIsEmpty:'är tomt',
muiLocaleFilterOperatorIsNotEmpty:'är inte tomt',
muiLocaleFilterOperatorNot:'är inte',
muiLocaleFilterOperatorOnOrAfter:'är på eller efter',
muiLocaleFilterOperatorOnOrBefore:'är på eller före',
muiLocaleFilterOperatorStartsWith:'börjar med',
muiLocaleFilterPanelAddFilter:'Lägg till filter',
muiLocaleFilterPanelColumns:'Kolumner',
muiLocaleFilterPanelDeleteIconLabel:'Ta bort',
muiLocaleFilterPanelInputLabel:'Värde',
muiLocaleFilterPanelInputPlaceholder:'Filtervärde',
muiLocaleFilterPanelOperatorAnd:'Och',
muiLocaleFilterPanelOperatorOr:'Eller',
muiLocaleFilterPanelOperators:'Operatorer',
muiLocaleFilterValueAny:'någon',
muiLocaleFilterValueFalse:'falskt',
muiLocaleFilterValueTrue:'sant',
muiLocaleFooterRowSelected:'rad vald',
muiLocaleFooterRowsSelected:'rader valda',
muiLocaleNoResultsOverlayLabel:'Inget resultat',
muiLocaleNoRowsLabel:'Inga rader',
muiLocaleToolbarColumns:'Kolumner',
muiLocaleToolbarColumnsLabel:'Välj kolumner',
muiLocaleToolbarDensity:'Radstorlek',
muiLocaleToolbarDensityComfortable:'Stor',
muiLocaleToolbarDensityCompact:'Liten',
muiLocaleToolbarDensityLabel:'Radstorlek',
muiLocaleToolbarDensityStandard:'Mellan',
muiLocaleToolbarExport:'Exportera',
muiLocaleToolbarExportCSV:'Ladda ner som CSV',
muiLocaleToolbarExportLabel:'Exportera',
muiLocaleToolbarExportPrint:'Skriv ut',
muiLocaleToolbarFilters:'Filter',
muiLocaleToolbarFiltersLabel:'Visa filter',
muiLocaleToolbarFiltersTooltipActive:'{count, plural, =1 {aktivt filter} other {aktiva filter}}',
muiLocaleToolbarFiltersTooltipHide:'Göm filter',
muiLocaleToolbarFiltersTooltipShow:'Visa filter',
}