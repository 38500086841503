import React from 'react'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'

import { TemplateActivityInheritanceItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterTemplateActivityInheritances } from 'utilities/template-activity-helper'

import { InheritedTemplateActivityModalContext } from '../inherited-template-activity-modal/inherited-template-activity-modal-context'
import { AdministerInheritedTemplateActivitiesContext } from './administer-inherited-template-activities-context'

const AdministerInheritedTemplateActivitiesView: React.FC = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const {
    inheritedTemplateActivityListItems,
    removeDialogOpen,
    templateActivityToRemove,
    isLoading,
    removeInvalidDialogOpen,
    templateActivityInvalidToRemove,
  } = AdministerInheritedTemplateActivitiesContext.useState()
  const {
    openRemoveDialog,
    closeRemoveDialog,
    deleteTemplateActivityAsync,
    closeRemoveInvalidDialog,
  } = AdministerInheritedTemplateActivitiesContext.useActions()

  const { openInheritedTemplateActivityModal } = InheritedTemplateActivityModalContext.useActions()

  const f = useFormatMessage()
  const getFilteredTemplateActivityInheritances = filterTemplateActivityInheritances(f)
  const handleAddNewInheritedTemplateActivtiyClick = React.useCallback(() => openInheritedTemplateActivityModal(), [openInheritedTemplateActivityModal])

  const handleRemoveTemplateActivityClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, templateActivity: TemplateActivityInheritanceItem) => {
      _?.stopPropagation()
      openRemoveDialog(templateActivity)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveTemplateActivityClick = React.useCallback(() => deleteTemplateActivityAsync(templateActivityToRemove!.id), [
    templateActivityToRemove,
    deleteTemplateActivityAsync,
  ])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openInheritedTemplateActivityModal(params.row.id)
    },
    [openInheritedTemplateActivityModal]
  )

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredTemplateActivityInheritances, setFilteredTemplateActivityInheritances] = React.useState<TemplateActivityInheritanceItem[]>(inheritedTemplateActivityListItems)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredTemplateActivityInheritances(filterValue, inheritedTemplateActivityListItems)
      setFilteredTemplateActivityInheritances(filtered)
    } else {
      setFilteredTemplateActivityInheritances(inheritedTemplateActivityListItems)
    }
    // NOTE: We are missing filterValue and getFilteredTemplateActivityInheritances as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [inheritedTemplateActivityListItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredTemplateActivityInheritances(searchValue, inheritedTemplateActivityListItems)
      setFilteredTemplateActivityInheritances(filtered)
    },
    [setFilterValue, setFilteredTemplateActivityInheritances, inheritedTemplateActivityListItems, getFilteredTemplateActivityInheritances]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredTemplateActivityInheritances(inheritedTemplateActivityListItems)
    },
    [setFilterValue, setFilteredTemplateActivityInheritances, inheritedTemplateActivityListItems]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '0.5vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId ? (
            <div style={{ columnCount: 2 }}>
              <ContainedButton type="button" color="secondary" onClick={handleAddNewInheritedTemplateActivtiyClick}>
                {f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_ADD_BUTTON_TEXT')}
              </ContainedButton>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          ) : (
            <div style={{ columnCount: 2 }}>
              <div>&nbsp;</div>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredTemplateActivityInheritances}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_NAME'),
                field: 'templateActivityTitle',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_AREA'),
                field: 'areaName',
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      {params.row.areaOrganizationParentsName ? `${params.row.areaName} (${params.row.areaOrganizationParentsName})` : params.row.areaName}
                    </span>
                  )
                },              
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_CATEGORY'),
                field: 'templateActivityCategoryTitle',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_LEGISLATION'),
                field: 'legislationTitle',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_deactivate',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.templateActivityTitle,
                        })}
                        aria-label={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.templateActivityTitle
                        })}>
                        <IconButton onClick={e => handleRemoveTemplateActivityClick(e, params.row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />

          {removeDialogOpen && templateActivityToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_MESSAGE', {
                title: templateActivityToRemove.templateActivityTitle,
                areaName: templateActivityToRemove.areaName ? templateActivityToRemove.areaName : '',
              })}
              buttonSubmitText={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen={true}
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemoveTemplateActivityClick}
            />
          )}

          {removeInvalidDialogOpen && templateActivityInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_MESSAGE', {
                title: templateActivityInvalidToRemove.templateActivityTitle,
              })}
              buttonOkText={f('ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen={true}
              onClose={closeRemoveInvalidDialog}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdministerInheritedTemplateActivitiesView
