import React from 'react'
import { Box, Snackbar, Alert } from '@mui/material'
import ConfirmationBoxContext from './confirmation-box-context'


const ConfirmationBoxView : React.FC = () => {
  const { openConfirmationBox } = ConfirmationBoxContext.useState()
  const { setOpenConfirmationBox } = ConfirmationBoxContext.useActions()

  const handleClose = React.useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenConfirmationBox({state:false})
  },[])

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openConfirmationBox.state}
        autoHideDuration={3000}
        onClose={handleClose}
        key='SlideTransition'
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {openConfirmationBox.msg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ConfirmationBoxView
