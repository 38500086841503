import React from 'react'
import * as yup from 'yup'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
//import { BulletinBoardItemModalContext } from '../legislation-modal-context'
import { BulletinBoardContext, BulletinBoardItemModel } from '../../../bulletin-board-context'
import { BulletinBoardItemModalContext } from '../bulletin-board-item-modal-context'

type BulletinBoardItemForm = Readonly<{
  header?: string
  content?: string
  color: string
}>

const createBulletinBoardItem = (values: BulletinBoardItemForm): BulletinBoardItemModel => {
  const { header, content, color } = values

  const newItem: BulletinBoardItemModel = {
    id: -1, //Will be adjusted in BulletinBoard context
    createdByName: 'SK', //TODO
    createdDate: new Date(),
    header,
    content,
    color,
    height: 200,
    width: 200,
    left: 150, // Get random
    top: 300, // Get random
  }
  return newItem
}

const mapBulletinBoardItemFormToBulletinBoardItemModel = (initialItem: BulletinBoardItemModel, item: BulletinBoardItemForm): BulletinBoardItemModel => {
  const newItem: BulletinBoardItemModel = {
    ...initialItem,
    header: item.header,
    content: item.content,
    color: item.color,
  }

  return newItem
}

const mapBulletinBoardItemModelToBulletinBoardItemForm = (item: BulletinBoardItemModel): BulletinBoardItemForm => {
  const newItem: BulletinBoardItemForm = {
    header: item.header,
    content: item.content,
    color: item.color,
  }
  return newItem
}

const useBulletinBoardItemFormData = (initialBulletinBoardItem?: BulletinBoardItemModel) => {
  const f = useFormatMessage()
  const { closeBulletinBoardItemModal } = BulletinBoardItemModalContext.useActions()
  const { createBulletinItem, updateBulletinItem } = BulletinBoardContext.useActions()
  const bbItem = initialBulletinBoardItem

  const initialValues = React.useMemo<BulletinBoardItemForm>(
    () =>
      !!bbItem
        ? mapBulletinBoardItemModelToBulletinBoardItemForm(bbItem)
        : {
            header: '',
            content: '',
            color: '#F0C200',
          },
    [bbItem]
  )

  const onSubmit: OnSubmitFunction<BulletinBoardItemForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      if (!initialBulletinBoardItem) {
        const newItem = createBulletinBoardItem({
          ...values,
        })
        createBulletinItem(newItem)
        //await createBulletinBoardItemAsync(templateActivityCategory).finally(() => setSubmitting(false))
      } else {
        const mappedBulletinBoardItem = mapBulletinBoardItemFormToBulletinBoardItemModel(initialBulletinBoardItem, values)
        updateBulletinItem(mappedBulletinBoardItem)
      }
      closeBulletinBoardItemModal()
    },
    [initialBulletinBoardItem, closeBulletinBoardItemModal, createBulletinItem, updateBulletinItem]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      header: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          40,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),

      content: yup.string().max(
        300,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
          maxsize: 1024,
        })
      ),
    })
  }, [f])

  const name: Name<BulletinBoardItemForm> = React.useMemo(
    () => ({
      header: 'header',
      content: 'content',
      color: 'color',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useBulletinBoardItemFormData
