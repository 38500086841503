import { FormControlLabel, Typography, useTheme, Switch } from '@mui/material'
import { Colors as ThemeColors } from 'styles/colors'
import {
  IPlannedActivityRowPlanningViewItem,
  IPlannedRoutesActivitiesPlanningViewItem,
  ISidePanelPlannedActivityRowsPlanningViewItem,
  SidePanelPlannedRouteUserWorkScheduleItem,
  PlannedActivityStatusEnum,
  SeverityLevelEnum,
  PlannedRouteUserWorkSchedulePlanningViewItem,
  RequiredDelegationItem,
  PlannedActivityParticipantItem,
} from 'api/tillit.api-client'

import {
  PlannedRouteUserWorkScheduleItem,
  PlanningComplianceItem,
  FulfilledDelegationItem,
} from 'api/tenilo.workschedule.api-client'

import { ReactComponent as PencilIcon } from 'assets/images/icons/pencil.svg'
import { ClockAlert as TimeCritical, SyncOff as TemporaryActivity, AccountMultiple as ParticipantsIcon } from 'mdi-material-ui'
import Color from 'color'
import { ScheduleCalendar, ScheduleCalendarProvider } from 'components/planning-grid'
import { Callbacks, SessionProps, TrackProps } from 'components/planning-grid/planning-grid-types'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { IPlannedActivityRowPlanningViewItemWithTime } from 'pages/planning/planning-page-helper'
import React from 'react'

import format from 'utilities/format'
import { useIsPlanningReadonly } from 'utilities/use-is-planning-readonly'
import styled from 'styled-components'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Authentication from 'components/authentication'
import { hasCompleteStatus } from 'utilities/planned-activity-helper'
import AlertFutureChanges from '../alert-future-changes/alert-future-changes-view'
import RouteMenuContainer from '../route-menu/route-menu-container'
import { IRouteMenuOption } from '../route-menu/route-menu-options'
import { Sidebar } from './components/sidebar'
import PlannedRouteWorkSchedulesContainer from './components/planned-route-workschedules'
import { reduceRoutes } from './utilities/reduce-routes'
import SortedParticipantsBulletList from './components/sorted-participants-bullet-list'

export interface ISchedule {
  type: 'areas' | 'devices'
  preventHorizontalDrag?: boolean
  hideSidebars?: boolean
  allowRouteChanges?: boolean
  onDoubleClick?: (props: { startTime: Date; routeId: number }) => void
  routes?: IPlannedRoutesActivitiesPlanningViewItem[]
  handleClickMenuOptionParent?: (name: IRouteMenuOption['name'], route: IPlannedRoutesActivitiesPlanningViewItem) => void
}

export type PlanMetaData = Readonly<{
  metaType: string,
  activityColor?: string
  plannedActivityRowId: number
  plannedRouteId: number
  activityName?: string
  activityRowId: number
  activityRowHasFutureChanges: boolean
  activityReccurrenceId: number
  plannedActivityStatusId: PlannedActivityStatusEnum
  detachedFromSeries: boolean
  desiredStartDate?: Date
  desiredEndDate?: Date
  isCommonActivity: boolean
  firstName?: string
  lastName?: string
  roomNumber?: string
  desiredVisitLengthInMinutes: number
  isPlanned: boolean
  comment?: string
  activityShowColorOnDevice: boolean
  templateActivityTitle?: string
  hasUnfulfilledDelegations: boolean
  startsBeforeCalendarDate: boolean
  activity_SeverityLevelId: SeverityLevelEnum
  plannedActivityParticipants?: PlannedActivityParticipantItem[] | undefined
  useParticipantList: boolean
  createdOn: Date
  ownerCustomerId: number | undefined
  ownerAreaId: number | undefined
}>

export type UserWorkScheduleMetaData = Readonly<{
  metaType: string,
  id: number,
  plannedRouteId: number,
  routeId: number,
  userId: number,
  userFullName: string | undefined,
  isPlanned: boolean,
  scheduledStart: Date,
  scheduledEnd: Date,
  substituteUserId: number | undefined,
  substituteUserFullName: string | undefined,
}>


export const mapMetaDataToPlannedActivityRowPlanningViewItem = (
  meta: PlanMetaData,
  internal: Omit<SessionProps, 'meta'>,
  newRouteId: number
): IPlannedActivityRowPlanningViewItemWithTime => {
  const { id, lengthInMinutes, startDate } = internal
  const endDate = new Date(+startDate + lengthInMinutes * 60000)

  return {
    id,
    activityRowId: meta.activityRowId,
    activityRowHasFutureChanges: meta.activityRowHasFutureChanges,
    activityReccurrenceId: meta.activityReccurrenceId,
    startTime: startDate,
    endTime: endDate,
    plannedActivityStatusId: meta.plannedActivityStatusId,
    plannedRouteId: meta.plannedRouteId,
    activityColor: meta.activityColor,
    ownerCustomerFirstName: meta.firstName,
    ownerCustomerLastName: meta.lastName,
    ownerCustomerId: meta.isCommonActivity ? undefined : meta.ownerCustomerId,
    ownerAreaId: meta.isCommonActivity ? meta.ownerAreaId : undefined,
    activityName: meta.activityName,
    detachedFromSeries: meta.detachedFromSeries,
    visitLengthInMinutes: lengthInMinutes,
    newRouteId,
    comment: meta.comment,
    activityShowColorOnDevice: meta.activityShowColorOnDevice,
    templateActivityTitle: meta.templateActivityTitle,
    hasUnfulfilledDelegations: meta.hasUnfulfilledDelegations,
    activity_SeverityLevelId: meta.activity_SeverityLevelId,
    plannedActivityParticipants: meta.plannedActivityParticipants,
    useParticipantList: meta.useParticipantList,
    createdOn: meta.createdOn
  }
}

export const mapPlannedActivityRowPlanningViewItemToMetaData = (plannedActivityRow: IPlannedActivityRowPlanningViewItem, startsBeforeCalendarDate: boolean): PlanMetaData => {
  return {
    metaType: "PlanMetaDataType",
    activityColor: plannedActivityRow.activityColor,
    plannedActivityRowId: plannedActivityRow.id,
    plannedRouteId: plannedActivityRow.plannedRouteId,
    activityName: plannedActivityRow.activityName,
    activityRowId: plannedActivityRow.activityRowId,
    activityRowHasFutureChanges: plannedActivityRow.activityRowHasFutureChanges,
    activityReccurrenceId: plannedActivityRow.activityReccurrenceId,
    plannedActivityStatusId: plannedActivityRow.plannedActivityStatusId,
    detachedFromSeries: plannedActivityRow.detachedFromSeries,
    desiredStartDate: undefined,
    desiredEndDate: undefined,
    desiredVisitLengthInMinutes: 60,
    isPlanned: true,
    firstName: plannedActivityRow.ownerCustomerFirstName,
    lastName: plannedActivityRow.ownerCustomerLastName,
    roomNumber: plannedActivityRow.ownerCustomerRoomNumber,
    isCommonActivity: !plannedActivityRow.ownerCustomerId,
    comment: plannedActivityRow.comment,
    activityShowColorOnDevice: plannedActivityRow.activityShowColorOnDevice,
    templateActivityTitle: plannedActivityRow.templateActivityTitle,
    hasUnfulfilledDelegations: plannedActivityRow.hasUnfulfilledDelegations,
    startsBeforeCalendarDate,
    activity_SeverityLevelId: plannedActivityRow.activity_SeverityLevelId,
    plannedActivityParticipants: plannedActivityRow.plannedActivityParticipants,
    useParticipantList: plannedActivityRow.useParticipantList,
    createdOn: plannedActivityRow.createdOn,
    ownerCustomerId: plannedActivityRow.ownerCustomerId,
    ownerAreaId: plannedActivityRow.ownerAreaId,
  }
}

export const mapSidePanelPlannedRouteUserWorkScheduleToMetaData = (
  sidepanelPlannedRouteUserWorkSchedule: SidePanelPlannedRouteUserWorkScheduleItem
): UserWorkScheduleMetaData => {

  return {
    metaType: "UserWorkScheduleMetaDataType",
    userId: sidepanelPlannedRouteUserWorkSchedule.userId,
    userFullName: sidepanelPlannedRouteUserWorkSchedule.userFullName,
    plannedRouteId: sidepanelPlannedRouteUserWorkSchedule.plannedRouteId,
    id: sidepanelPlannedRouteUserWorkSchedule.id,
    isPlanned: sidepanelPlannedRouteUserWorkSchedule.isPlanned,
    scheduledStart: sidepanelPlannedRouteUserWorkSchedule.scheduledStart,
    scheduledEnd: sidepanelPlannedRouteUserWorkSchedule.scheduledEnd,
    routeId: sidepanelPlannedRouteUserWorkSchedule.routeId,
    substituteUserId: sidepanelPlannedRouteUserWorkSchedule.substituteUserId,
    substituteUserFullName: sidepanelPlannedRouteUserWorkSchedule.substituteUserFullName,
  }
}

export const mapPlannedRouteUserWorkSchedulePlanningViewItemToMetaData = (
  plannedRouteUserWorkSchedule: PlannedRouteUserWorkSchedulePlanningViewItem
): UserWorkScheduleMetaData => {
  return {
    metaType: "UserWorkScheduleMetaDataType",
    userId: plannedRouteUserWorkSchedule.userId,
    userFullName: plannedRouteUserWorkSchedule.userFullName,
    plannedRouteId: plannedRouteUserWorkSchedule.plannedRouteId,
    id: plannedRouteUserWorkSchedule.id,
    isPlanned: true, //plannedRouteUserWorkSchedule.isPlanned,
    scheduledStart: plannedRouteUserWorkSchedule.scheduledStart,
    scheduledEnd: plannedRouteUserWorkSchedule.scheduledEnd,
    routeId: plannedRouteUserWorkSchedule.routeId,
    substituteUserId: plannedRouteUserWorkSchedule.substituteUserId,
    substituteUserFullName: plannedRouteUserWorkSchedule.substituteUserFullName,
  }
}



export const mapMetaDataToPlannedRouteUserWorkScheduleItem = (
  meta: UserWorkScheduleMetaData,
  internal: Omit<SessionProps, 'meta'>,
  newRouteId: number
): PlannedRouteUserWorkScheduleItem => {
  //const { id, lengthInMinutes, startDate } = internal
  //const endDate = new Date(+startDate + lengthInMinutes * 60000)

  return new PlannedRouteUserWorkScheduleItem({
    id: meta.id,
    plannedRouteId: newRouteId,
    userId: meta.userId,
    scheduledStart: meta.scheduledStart,
    scheduledEnd: meta.scheduledEnd,
    routeId: meta.routeId,
    routeName: '',
    userFullName: '',
    substituteUserId: meta.substituteUserId,
    substituteUserFullName: meta.substituteUserFullName ? meta.substituteUserFullName : '',
    areaId: -1, //meta.areaId,
    areaName: '', //meta.areaName ? meta.areaName : '',
  })
}


export const mapSidePanelPlannedActivityRowsPlanningViewItemToMetaData = (
  sidepanelPlannedActivityRow: ISidePanelPlannedActivityRowsPlanningViewItem,
  currentDate: Date
): PlanMetaData => {
  const { fromTimeHour, fromTimeMinute, toTimeHour, toTimeMinute } = sidepanelPlannedActivityRow
  const desiredStartDate = new Date(currentDate)
  desiredStartDate.setHours(fromTimeHour)
  desiredStartDate.setMinutes(fromTimeMinute)
  desiredStartDate.setMilliseconds(0)

  const shouldBeNextDay = fromTimeHour * 60 + fromTimeMinute > toTimeHour * 60 + toTimeMinute
  const desiredEndDate = new Date(currentDate)
  if (shouldBeNextDay) {
    desiredEndDate.setDate(currentDate.getDate() + 1)
  }
  desiredEndDate.setHours(toTimeHour)
  desiredEndDate.setMinutes(toTimeMinute)
  desiredEndDate.setMilliseconds(0)
  return {
    metaType: "PlanMetaDataType",
    activityColor: sidepanelPlannedActivityRow.color,
    plannedActivityRowId: sidepanelPlannedActivityRow.id,
    activityName: sidepanelPlannedActivityRow.activityTitle,
    activityRowId: sidepanelPlannedActivityRow.activityRowId,
    activityReccurrenceId: sidepanelPlannedActivityRow.activityReccurrenceId,
    plannedRouteId: sidepanelPlannedActivityRow.plannedRouteId,
    activityRowHasFutureChanges: sidepanelPlannedActivityRow.activityRowHasFutureChanges,
    plannedActivityStatusId: sidepanelPlannedActivityRow.plannedActivityStatusId,
    detachedFromSeries: sidepanelPlannedActivityRow.detachedFromSeries,
    desiredStartDate,
    desiredEndDate,
    desiredVisitLengthInMinutes: sidepanelPlannedActivityRow.visitLengthInMinutes,
    isPlanned: sidepanelPlannedActivityRow.isPlanned,
    firstName: sidepanelPlannedActivityRow.ownerCustomerFirstName,
    lastName: sidepanelPlannedActivityRow.ownerCustomerLastName,
    roomNumber: sidepanelPlannedActivityRow.ownerCustomerRoomNumber,
    isCommonActivity: !sidepanelPlannedActivityRow.ownerCustomerId,
    comment: sidepanelPlannedActivityRow.comment,
    activityShowColorOnDevice: sidepanelPlannedActivityRow.activityShowColorOnDevice,
    templateActivityTitle: sidepanelPlannedActivityRow.templateActivityTitle,
    hasUnfulfilledDelegations: false, // sidepanelPlannedActivityRow.hasUnfulfilledDelegations,
    startsBeforeCalendarDate: false,
    activity_SeverityLevelId: sidepanelPlannedActivityRow.activitySeverityLevelId,
    plannedActivityParticipants: sidepanelPlannedActivityRow.plannedActivityParticipants,
    useParticipantList: sidepanelPlannedActivityRow.useParticipantList,
    createdOn: sidepanelPlannedActivityRow.createdOn,
    ownerCustomerId: sidepanelPlannedActivityRow.ownerCustomerId,
    ownerAreaId: sidepanelPlannedActivityRow.ownerAreaId,
  }
}

export interface ICellData {
  startTime: Date
  routeId: number
}

export type Session = SessionProps<PlanMetaData>
export type Track = TrackProps<IPlannedRoutesActivitiesPlanningViewItem>

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    zIndex: 1000000,
    maxWidth: 'none',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.438), 0 1px 2px rgba(0, 0, 0, 0.65)',
  },
}));

const ScheduleView: React.FC<ISchedule> = ({
  allowRouteChanges = true,
  handleClickMenuOptionParent,
  preventHorizontalDrag = false,
  type,
  onDoubleClick,
  hideSidebars = false,
  routes = [],
}) => {
  const {
    calendarStartDate,
    calendarEndDate,
    isLoading,
    alertFutureChangesIsOpen,
    alertFutureChangesMoveData,
    alertFutureChangesPlanData,
    alertFutureChangesPlanDataRoute,
    alertFutureChangesRows,
    nonComplianceForAreaAndCalendarDate,
    currentUser,
    notSatisfiedPlannedActivities,
  } = PlanningPageContext.useState()

  const {
    movePlannedActivity,
    planActivity,
    updateDetachedPlannedActivity,
    openDialogInfoActivity,
    userWantsToUnplanByDroppingInUnplannedSection,
    setAlertFutureChangesIsOpen,
    isActivityRequirementsNotSatisfied,
    activityRequirementsNotSatisfied,
    activityDelegationRequirements,
    cleanUpFutureChanges,
    planUserWorkSchedule,
    unplanUserWorkSchedule,
  } = PlanningPageContext.useActions()

  const theme = useTheme()
  const f = useFormatMessage()
  const [showPlannedWorkSchedules, setShowPlannedWorkSchedules] = React.useState(true)
  const { usingCalendarFromDevice } = Authentication.useAuthenticatedState()

  const handleCloseAlertFutureChanges = React.useCallback(() => {
    setAlertFutureChangesIsOpen(false)
  }, [setAlertFutureChangesIsOpen])

  const handleAlertFutureChangesReplaceAllClick = React.useCallback(() => {
    setAlertFutureChangesIsOpen(false)

    if (!(alertFutureChangesMoveData || alertFutureChangesPlanData)) {
      return
    }

    const overwriteFutureChanges = true
    const implementChangesUntil = false
    const implementChangesUntilDate = undefined

    if (alertFutureChangesMoveData) {
      void movePlannedActivity(alertFutureChangesMoveData, overwriteFutureChanges, allowRouteChanges, implementChangesUntil, implementChangesUntilDate) // IPlannedActivityRowPlanningViewItem
    }

    if (alertFutureChangesPlanData) {
      void planActivity(alertFutureChangesPlanData, alertFutureChangesPlanDataRoute, overwriteFutureChanges, implementChangesUntil, implementChangesUntilDate) // IPlannedActivityRowPlanningViewItem
    }

    cleanUpFutureChanges()
  }, [
    cleanUpFutureChanges,
    movePlannedActivity,
    planActivity,
    setAlertFutureChangesIsOpen,
    alertFutureChangesMoveData,
    alertFutureChangesPlanData,
    alertFutureChangesPlanDataRoute,
    allowRouteChanges,
  ])

  const handleAlertFutureChangesUpTillNextClick = React.useCallback(() => {
    setAlertFutureChangesIsOpen(false)

    if (!(alertFutureChangesMoveData || alertFutureChangesPlanData)) {
      return
    }

    if (!alertFutureChangesRows || alertFutureChangesRows.length === 0) {
      return
    }

    const overwriteFutureChanges = false
    const implementChangesUntil = true

    const implementChangesUntilDate = alertFutureChangesRows[0].startDate
    if (alertFutureChangesMoveData) {
      void movePlannedActivity(alertFutureChangesMoveData, overwriteFutureChanges, allowRouteChanges, implementChangesUntil, implementChangesUntilDate) // IPlannedActivityRowPlanningViewItem
    }

    if (alertFutureChangesPlanData) {
      void planActivity(alertFutureChangesPlanData, alertFutureChangesPlanDataRoute, overwriteFutureChanges, implementChangesUntil, implementChangesUntilDate) // IPlannedActivityRowPlanningViewItem
    }

    cleanUpFutureChanges()
  }, [
    cleanUpFutureChanges,
    movePlannedActivity,
    planActivity,
    setAlertFutureChangesIsOpen,
    alertFutureChangesRows,
    alertFutureChangesMoveData,
    alertFutureChangesPlanData,
    alertFutureChangesPlanDataRoute,
    allowRouteChanges,
  ])

  const handleUserWorkScheduleUpdate = React.useCallback<NonNullable<Callbacks<Track, Session>['onUpdate']>>(
    props => {
      const { internal, meta, action } = props

      const userWorkScheduleMeta = meta as unknown as UserWorkScheduleMetaData // https://bobbyhadz.com/blog/typescript-conversion-of-type-to-type-may-be-mistake
      const userWorkSchedule = mapMetaDataToPlannedRouteUserWorkScheduleItem(userWorkScheduleMeta, internal, internal.trackId)

      switch (action) {
        case 'add':
          void planUserWorkSchedule(userWorkSchedule, internal.trackId)
          break
        case 'remove':
          void unplanUserWorkSchedule(userWorkSchedule)
          break
        default:
          throw new Error(`handleUserWorkScheduleUpdate - action ${action} is not handled`)
      }

    },
    [allowRouteChanges, movePlannedActivity, planUserWorkSchedule, updateDetachedPlannedActivity, userWantsToUnplanByDroppingInUnplannedSection]
  )

  const handleUpdate = React.useCallback<NonNullable<Callbacks<Track, Session>['onUpdate']>>(
    props => {
      const { internal, meta, action } = props
      if (meta.metaType === "UserWorkScheduleMetaDataType") {
        try {
          handleUserWorkScheduleUpdate(props)
        } catch (error) {
          console.log(error)
          return
        }
      } else {

        const plannedActivity = mapMetaDataToPlannedActivityRowPlanningViewItem(meta, internal, internal.trackId)

        switch (action) {
          case 'add':
            void planActivity(plannedActivity, internal.trackId, false)
            break
          case 'remove':
            if (plannedActivity.detachedFromSeries) {
              void updateDetachedPlannedActivity(plannedActivity, true)
            } else {
              userWantsToUnplanByDroppingInUnplannedSection(plannedActivity)
            }
            break
          case 'update':
            if (plannedActivity.detachedFromSeries) {
              void updateDetachedPlannedActivity(plannedActivity, false)
            } else {
              void movePlannedActivity(plannedActivity, false, allowRouteChanges) // IPlannedActivityRowPlanningViewItem
            }
            break
          default:
            return
          //handleUpdate - action ${action} is not handled
        }

      }

    },
    [allowRouteChanges, movePlannedActivity, planActivity, updateDetachedPlannedActivity, userWantsToUnplanByDroppingInUnplannedSection]
  )



  const openPopoverInfoActivity = React.useCallback(
    (props: any) => {
      openDialogInfoActivity(true, props.event.id, {
        top: props.originalEvent.y,
        left: props.originalEvent.x,
      })
    },
    [openDialogInfoActivity]
  )
  const [currentDate, setCurrentDate] = React.useState({
    calendarStartDate,
    calendarEndDate,
  })

  const { tracks, sessions } = React.useMemo(() => reduceRoutes(routes, currentDate.calendarStartDate), [routes, currentDate])


  const maxNumberOfPlannedRouteUserWorkSchedules = React.useMemo(() => {
    let maxPRUWS = 0;
    routes.forEach(r => {
      let routePRUWS = 0
      r.plannedRoutes?.forEach(pr => {
        routePRUWS += pr.plannedRouteUserWorkSchedules ? pr.plannedRouteUserWorkSchedules.length : 0
      })
      maxPRUWS = Math.max(maxPRUWS, routePRUWS)
    })
    return maxPRUWS
  }, [routes])

  const handleDoubleClickReadonlyPlanning = React.useCallback(() => {
    //console.log("Read only planning. No Doubleclick!!!")
  }, [])

  const handleDoubleClick = React.useCallback<NonNullable<Callbacks<Track, Session>['onDoubleClick']>>(
    props => {
      const { internal, startDate } = props
      if (onDoubleClick) {
        onDoubleClick({
          startTime: startDate,
          routeId: internal.id,
        })
      }
    },
    [onDoubleClick]
  )

  const renderHeader: React.ComponentProps<typeof ScheduleCalendar>['renderHeader'] = React.useCallback(
    ({ meta: route }) => {
      const routePlanningCompliances = nonComplianceForAreaAndCalendarDate?.filter(nc => nc.routeId === route.id)
      let routePlanningCompliance: PlanningComplianceItem | undefined
      if (routePlanningCompliances && routePlanningCompliances.length === 1) // there should and could only be one
      {
        routePlanningCompliance = routePlanningCompliances[0]
      }

      return (
        <>
          <RouteMenuContainer route={route} handleClickMenuOptionParent={handleClickMenuOptionParent} type={type} routePlanningCompliance={routePlanningCompliance} />

          {currentUser && currentUser.useWorkSchedule && type === "areas" && showPlannedWorkSchedules && (
            <PlannedRouteWorkSchedulesContainer route={route} maxPlannedRouteUserWorkSchedules={maxNumberOfPlannedRouteUserWorkSchedules} />
          )}
        </>
      )
    },
    [handleClickMenuOptionParent, type, nonComplianceForAreaAndCalendarDate, showPlannedWorkSchedules, currentUser, maxNumberOfPlannedRouteUserWorkSchedules]
  )

  const renderTime: React.ComponentProps<typeof ScheduleCalendar>['renderTime'] = React.useCallback(
    ({ time }) => (
      <div
        style={{
          boxSizing: 'border-box',
          textAlign: 'center',
          backgroundColor: '#fff',
          width: '100%',
          height: '100%',
          border: 'rgba(0, 0, 0, 0.12) solid 1px',
          color: 'rgba(0, 0, 0, 0.87)'
        }}>
        <div>
          <Typography variant="caption">{format(time, 'HH:mm')}</Typography>
        </div>
      </div>
    ),
    []
  )




  const renderWorkScheduleSession: React.ComponentProps<typeof ScheduleCalendar>['renderSession'] = React.useCallback(
    meta => {
      const workSchedule = meta as UserWorkScheduleMetaData

      let title = workSchedule.userFullName
      if (workSchedule.substituteUserId) {
        title = workSchedule.substituteUserFullName
      }
      let backgroundColor = ThemeColors.TeniloGreen
      // If there's a substitute let's change the color...
      if (workSchedule.substituteUserId) {
        backgroundColor = ThemeColors.TeniloBrown;
      }

      const lengthInMinutes = (workSchedule.scheduledEnd.getTime() - workSchedule.scheduledStart.getTime()) / (1000 * 60)
      const fromFullTimeString = format(workSchedule.scheduledStart, 'HH:mm')
      const toFullTimeString = format(workSchedule.scheduledEnd, 'HH:mm')

      const workScheduleInfoString = `${fromFullTimeString} - ${toFullTimeString} (${lengthInMinutes} ${f('BASIC_TEXT_MINUTES_ABBREVIATION')})`
      const textColor = 'white' // darkColor.contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'

      const div000: React.CSSProperties = {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        paddingRight: '1px',
        boxSizing: 'border-box',
        borderRadius: '5px',
      }

      const div001_renderWorkScheduleSession: React.CSSProperties = {
        paddingBottom: '0.5px',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box'
      }

      const div002_renderWorkScheduleSession: React.CSSProperties = {
        backgroundColor,
        boxSizing: 'border-box',
        overflow: 'hidden',
        width: '100%'
        /* Min height is the sum of font height, and padding top and bottom */
        /* overflow: hidden;
        min-height: calc(1.25rem + 16px); */
      }

      const div003: React.CSSProperties = {
        padding: '8px',
        position: 'relative'
      }

      const typo001: React.CSSProperties = {
        color: textColor,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }

      return (
        <HtmlTooltip
          title={
            <>
              <Typography data-matomo-mask variant="subtitle2" pt="1vh" fontWeight={600}>{title}</Typography>
              <Typography variant="subtitle2" fontWeight={400}>{workScheduleInfoString}</Typography>
            </>
          }
        >

          <div style={div000}>
            <div style={div001_renderWorkScheduleSession}>
              <div style={div002_renderWorkScheduleSession}>
                <div style={div003}>
                  <Typography
                    data-matomo-mask
                    variant={'subtitle2'}
                    style={typo001}>
                    {title}
                  </Typography>
                  <Typography
                    variant={'subtitle2'}
                    style={typo001}>
                    {workScheduleInfoString}
                  </Typography>
                </div>
                <div style={div003} />
              </div>
            </div>
          </div>
        </HtmlTooltip>
      )


    }
    ,
    [openPopoverInfoActivity]
  )

  const handleClickActivity = React.useCallback((id: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    openPopoverInfoActivity({
      event: { id },
      originalEvent: {
        x: event.clientX,
        y: event.clientY,
      },
    })
  }, [])

  const renderSession: React.ComponentProps<typeof ScheduleCalendar>['renderSession'] = React.useCallback(
    props => {
      const { meta, internal } = props
      const isWorkSchedule = meta.metaType === "UserWorkScheduleMetaDataType"
      if (isWorkSchedule) {
        //WORKSCHEDULE!!!!!
        return renderWorkScheduleSession(meta)
      }

      const activity = meta as PlanMetaData
      const activity_Title = activity.activityName
      const templateActivityTitle = activity.templateActivityTitle
      const combinedActivityTitle = templateActivityTitle
        ? templateActivityTitle + ' ' + (activity_Title ? '(' + activity_Title + ')' : '')
        : activity_Title
          ? activity_Title
          : ''

      const title = activity.isCommonActivity
        ? `${f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_COMMON_ACTIVITY')}: ${combinedActivityTitle}`
        : (usingCalendarFromDevice && activity.roomNumber && activity.roomNumber.trim().length > 0 ? `${activity.roomNumber}: ${combinedActivityTitle}` : `${activity.firstName} ${activity.lastName}: ${combinedActivityTitle}`)

      const backgroundColor = Color(activity.activityColor)
      const darkColor = backgroundColor
      const textColor = darkColor.contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'
      const isComplete = activity ? hasCompleteStatus(activity.plannedActivityStatusId) : false
      const isNotSatisfied = isActivityRequirementsNotSatisfied(activity.plannedActivityRowId)
      const notSatisfiedPlannedActivity = activityRequirementsNotSatisfied(activity.plannedActivityRowId)
      const delegationRequirements = activityDelegationRequirements(activity.plannedActivityRowId)

      const div000: React.CSSProperties = {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        paddingRight: '1px',
        boxSizing: 'border-box'
      }

      const div001_renderSession: React.CSSProperties = {
        paddingBottom: '0.5px',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        border: isNotSatisfied ? '3px solid red' : 'none'
      }

      const div002_renderSession: React.CSSProperties = {
        backgroundColor: darkColor.string(),
        boxSizing: 'border-box',
        overflow: 'hidden',
        width: '100%',
        /* Min height is the sum of font height, and padding top and bottom */
        /* overflow: hidden;
        min-height: calc(1.25rem + 16px); */
        backgroundSize: isComplete ? '15px 15px' : 'unset',
        backgroundImage: isComplete ? `repeating-linear-gradient(45deg, #ffffff 0, #ffffff 0.5px, ${darkColor.string()} 0, ${darkColor.string()} 50%)` : 'unset',
        border: isNotSatisfied ? '1px solid white' : 'none'
      }

      const div003: React.CSSProperties = {
        padding: '8px',
        position: 'relative'
      }

      const div004: React.CSSProperties = {
        height: '22px',
        width: '22px',
        borderRadius: '0 0 2px 2px',
        backgroundColor: '#147efb',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.27)',
        position: 'absolute',
        top: 0,
        right: '10px'
      }

      const typo001: React.CSSProperties = {
        color: textColor,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }

      const oneTimeOccurrence = 4 //Accoring to Recurrences-table

      const showDetachedFactor = activity.detachedFromSeries ? 1 : 0
      const showTimeCriticalFactor = (activity.activity_SeverityLevelId === SeverityLevelEnum.Critical) ? 1 : 0
      const showTemporaryFactor = (activity.activityReccurrenceId === 4) ? 1 : 0
      const showParticipantsFactor = (activity.useParticipantList && activity.plannedActivityParticipants && activity.plannedActivityParticipants.length > 0) ? 1 : 0

      const IconSpacing = 34
      let timeCriticalRightPixels = showDetachedFactor * IconSpacing
      timeCriticalRightPixels = timeCriticalRightPixels !== 0 ? timeCriticalRightPixels : 10

      let temporaryActivityRightPixels = showDetachedFactor * IconSpacing + showTimeCriticalFactor * IconSpacing
      temporaryActivityRightPixels = temporaryActivityRightPixels !== 0 ? temporaryActivityRightPixels : 10

      let participantsRightPixels = showDetachedFactor * IconSpacing + showTimeCriticalFactor * IconSpacing + showTemporaryFactor * IconSpacing
      participantsRightPixels = participantsRightPixels !== 0 ? participantsRightPixels : 10

      const startDate = internal.startDate
      const lengthInMinutes = internal.lengthInMinutes
      const endDate = new Date(startDate.getTime() + (lengthInMinutes * 60 * 1000))
      const plannedTime = `${format(startDate, 'HH:mm')} - ${format(endDate, 'HH:mm')} (${lengthInMinutes} minuter)`

      /*
      TODO: Show participants in tooltip. PlanMetaData needs to be extended!
                  {activity.plannedActivityParticipants && (
              <Box display="flex">
                <CommentsWrapper>
                  <div style={{ marginRight: '10px' }}>
                    <ParticipantsIcon height="18px" />
                  </div>
                  <Typography variant="body1">{plannedActivity.plannedActivityParticipants.map((pa, index) => {
                      return `${pa.customerFullName}${plannedActivity.plannedActivityParticipants?.length && index < plannedActivity.plannedActivityParticipants?.length -1 ? ', ' : '' }`
                    })
                  }
                  </Typography>
                </CommentsWrapper>
              </Box>
            )}
*/


      return (
        <HtmlTooltip followCursor
          title={
            <>
              <Typography data-matomo-mask variant="subtitle2" pt="1vh" fontWeight={600}>{title}</Typography>
              <Typography variant="subtitle2" fontWeight={400}>{plannedTime}</Typography>
              {delegationRequirements && delegationRequirements.delegationItems && delegationRequirements.delegationItems.length > 0 && (
                <Typography variant="subtitle2" pt="1vh" fontWeight={600}>{f('ROUTE_MENU_PLANNING_REQUIREMENTS')}</Typography>
              )
              }
              {delegationRequirements && delegationRequirements.delegationItems?.map((rdi: RequiredDelegationItem) => (
                <Typography key={`rdpa_rdi_${rdi.id}`} variant="subtitle2" fontWeight={400}> • {rdi.title}</Typography>
              ))
              }
              {isNotSatisfied && (
                <Typography variant="subtitle2" pt="1vh" fontWeight={600}>{f('ROUTE_MENU_PLANNING_COMPLIANCE_NOT_SATISFIED_REQUIREMENTS')}</Typography>
              )}
              {isNotSatisfied &&
                notSatisfiedPlannedActivity && notSatisfiedPlannedActivity.notFulfilledDelegations.map((nffd: FulfilledDelegationItem) => (
                  <Typography key={`nspa_nffd_${nffd.id}`} variant="subtitle2" fontWeight={400}> • {nffd.title}</Typography>
                ))
              }
              {showParticipantsFactor === 1 && (
                <Typography variant="subtitle2" pt="1vh" fontWeight={600}>{f('ROUTE_MENU_PLANNING_PARTICIPANTS')}</Typography>
              )}
              {showParticipantsFactor === 1 && activity.plannedActivityParticipants && (
                <SortedParticipantsBulletList participants={activity.plannedActivityParticipants} />
              )
              }

            </>
          }
        >
          <div style={div000}>
            <div style={div001_renderSession} onClick={handleClickActivity(activity.plannedActivityRowId)}>
              <div style={div002_renderSession}>
                <div style={div003}>
                  {(activity.detachedFromSeries) && (
                    <div
                      style={div004}>
                      <PencilIcon
                        style={{
                          display: 'block',
                          margin: 'auto',
                          marginTop: '3px',
                          height: '16px',
                          width: '16px',
                          fill: 'white',
                        }}
                      />
                    </div>
                  )}
                  {(activity.useParticipantList && activity.plannedActivityParticipants && activity.plannedActivityParticipants.length > 0) && (
                    <div
                      style={{ ...div004, right: participantsRightPixels }}>
                      <ParticipantsIcon
                        style={{
                          display: 'block',
                          margin: 'auto',
                          marginTop: '3px',
                          height: '16px',
                          width: '16px',
                          fill: 'white',
                        }}
                      />
                    </div>
                  )}
                  {(activity.activity_SeverityLevelId === SeverityLevelEnum.Critical) && (
                    <div
                      style={{ ...div004, right: timeCriticalRightPixels }}>
                      <TimeCritical
                        style={{
                          display: 'block',
                          margin: 'auto',
                          marginTop: '3px',
                          height: '16px',
                          width: '16px',
                          fill: 'white',
                        }}
                      />
                    </div>
                  )}
                  {(activity.activityReccurrenceId === oneTimeOccurrence) && (
                    <div
                      style={{ ...div004, right: temporaryActivityRightPixels }}>
                      <TemporaryActivity
                        style={{
                          display: 'block',
                          margin: 'auto',
                          marginTop: '3px',
                          height: '16px',
                          width: '16px',
                          fill: 'white',
                        }}
                      />
                    </div>
                  )}

                  <Typography
                    data-matomo-mask
                    variant={'subtitle2'}
                    style={typo001}>
                    {title}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </HtmlTooltip>
      )

    },
    [openPopoverInfoActivity, notSatisfiedPlannedActivities]
  )

  const [snapToGrid, setSnapToGrid] = React.useState(true)

  const handleSnapToGrid = React.useCallback(
    () => {
      setSnapToGrid(!snapToGrid)
    }, [snapToGrid]
  )

  const [timeScale, setTimeScale] = React.useState<number>(0.55)
  const [minutesStep, setMinutesStep] = React.useState<number>(60)

  const handleZoom = React.useCallback((level: ZoomLevel) => {
    setTimeScale(level.value)
    setMinutesStep(level.minutesStep)
  }, [])

  const handleShowPlannedWorkSchedules = React.useCallback(
    () => {
      setShowPlannedWorkSchedules(!showPlannedWorkSchedules)
    }, [showPlannedWorkSchedules]
  )


  const tempRoutesRef = React.useRef(routes)
  React.useEffect(() => {
    if (routes !== tempRoutesRef.current) {
      tempRoutesRef.current = routes
      setCurrentDate({
        calendarStartDate,
        calendarEndDate,
      })
    }
  }, [calendarEndDate, calendarStartDate, routes])

  // If date has past, set planning to be readonly
  const readonlyPlanning = useIsPlanningReadonly(currentDate.calendarStartDate) || usingCalendarFromDevice

  const div001: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const div002: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    position: 'relative'
  }

  const toolDiv: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between'
  }
  return (
    <>
      <AlertFutureChanges
        open={alertFutureChangesIsOpen}
        futureRows={alertFutureChangesRows}
        onClose={handleCloseAlertFutureChanges}
        onChangeUpTillNext={handleAlertFutureChangesUpTillNextClick}
        onReplaceAll={handleAlertFutureChangesReplaceAllClick}
        showPenMarkedInfo={!alertFutureChangesPlanData}
      />
      <div
        style={div001}>
        <div style={toolDiv}>
          <div>
            {!usingCalendarFromDevice && (
              <FormControlLabel
                onChange={handleSnapToGrid}
                control={<Switch checked={snapToGrid} color="primary" />}
                label={f('PLANNING_PAGE_SNAP_TO_5_MINUTES')}
                labelPlacement="end"
              />
            )}

            <ZoomContainer
              initialValue={timeScale}
              levels={[
                { label: ' 50% ', value: 1, minutesStep: 60 },
                { label: ' 100% ', value: 0.55, minutesStep: 30 },
                { label: ' 200% ', value: 0.2, minutesStep: 15 },
                { label: ' 400% ', value: 0.05, minutesStep: 5 },
              ]}
              onZoom={handleZoom}>
              {({ label }) => (
                <Typography variant="body1" component="span">
                  {label}
                </Typography>
              )}
            </ZoomContainer>
          </div>
          {currentUser && currentUser.useWorkSchedule && (
            <div>
              <FormControlLabel
                onChange={handleShowPlannedWorkSchedules}
                control={<Switch checked={showPlannedWorkSchedules} color="primary" />}
                label={f('PLANNING_PAGE_SHOW_PLANNED_WORKSCHEDULES')}
                labelPlacement="end"
              />
            </div>
          )}
        </div>
        <div
          style={div002}>
          <ScheduleCalendarProvider readonly={readonlyPlanning}>
            {routes.length === 0 && isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
            {routes.length > 0 && (
              <>
                <ScheduleCalendar
                  stepInMinutes={minutesStep}
                  timeScale={timeScale}
                  snapToGrid
                  snapInMinutes={snapToGrid ? 5 : 1}
                  preventHorizontalDrag={preventHorizontalDrag}
                  calendarStartDate={currentDate.calendarStartDate}
                  calendarEndDate={currentDate.calendarEndDate}
                  callbacks={{
                    onDoubleClick: readonlyPlanning ? handleDoubleClickReadonlyPlanning : handleDoubleClick,
                    onUpdate: handleUpdate,
                  }}
                  tracks={tracks}
                  sessions={sessions}
                  renderHeader={renderHeader}
                  renderTime={renderTime}
                  renderSession={renderSession}
                />
                {!hideSidebars && !usingCalendarFromDevice && <Sidebar />}
              </>
            )}
          </ScheduleCalendarProvider>
        </div>
      </div>
    </>
  )
}

type ZoomLevel = Readonly<{ label: string; value: number, minutesStep: number }>
type ZoomContainerProps = Readonly<{
  initialValue: number
  levels: ZoomLevel[]
  children?: (props: { label: string }) => React.ReactNode
  onZoom?(level: ZoomLevel): void
}>
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function ZoomContainer(props: ZoomContainerProps) {
  const { initialValue, levels, children, onZoom } = props
  const { current, index, next, previous } = useStepper({
    steps: levels,
    initialIndex: levels.findIndex(l => l.value === initialValue),
  })

  React.useEffect(() => {
    if (onZoom) {
      onZoom(current)
    }
  }, [current, onZoom])

  return (
    <>
      <button onClick={previous} disabled={index === 0}>
        -
      </button>
      {children && children(current)}
      <button onClick={next} disabled={index === levels.length - 1}>
        +
      </button>
    </>
  )
}

type StepperProps<T> = Readonly<{
  steps: T[]
  initialIndex?: number
}>
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function useStepper<T>(props: StepperProps<T>) {
  const { steps, initialIndex = 0 } = props
  const [index, setIndex] = React.useState(initialIndex)
  const current = React.useMemo(() => steps[index], [steps, index])

  const updateIndex = React.useCallback(
    (newIndex: number) => {
      setIndex(Math.max(0, Math.min(steps.length - 1, newIndex)))
    },
    [steps.length]
  )

  const next = React.useCallback(() => {
    updateIndex(index + 1)
  }, [index, updateIndex])

  const previous = React.useCallback(() => {
    updateIndex(index - 1)
  }, [index, updateIndex])

  return { current, index, next, previous }
}

export default React.memo(ScheduleView)
