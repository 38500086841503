import { Box, CircularProgress, Typography } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import React from 'react'
import { UserImpersonationRequestStatusEnum, UserListItem } from 'api/tenilo.support.api-client'
import format from 'utilities/format'
import authenticationContext from 'components/authentication/authentication-context'
import { useNavigate } from "react-router-dom"
import PageRoute from 'config/page-routes'
import { SupportUserImpersonateModalContext } from './support-user-impersonate-modal-context'

export type SupportUserImpersonateModalProps = Readonly<{
  onAbortImpersonation: () => void,
  onConfirmImpersonation: () => void,
  userToImpersonate: UserListItem
}>

const SupportUserImpersonateModalContainer: React.FC<SupportUserImpersonateModalProps> = ({ onAbortImpersonation, onConfirmImpersonation, userToImpersonate }) => {
  const f = useFormatMessage()

  const navigate = useNavigate()

  const { reloadUser } = authenticationContext.useActions()
  const { generateJwtTokenForUserImpersonationRequestAsync, createUserImpersonateRequestAsync, deleteUserImpersonateRequestAsync, abandonUserImpersonateRequestAsync, openSupportUserImpersonateModal, getActiveUserImpersonationRequestAsync, closeSupportUserImpersonateModal, setActiveRequest } = SupportUserImpersonateModalContext.useActions()
  const { activeRequest, isLoading } = SupportUserImpersonateModalContext.useState()

  const timeout = React.useRef<number | undefined>()

  React.useEffect(() => {
    void openSupportUserImpersonateModal(userToImpersonate.id)
  }, [userToImpersonate])

  React.useEffect(() => {
    if (!activeRequest) {
      return
    }

    if (activeRequest?.statusId === UserImpersonationRequestStatusEnum.Approved || activeRequest?.statusId === UserImpersonationRequestStatusEnum.Denied) {
      clearInterval(timeout.current)
    }

    const RefreshActiveRequest = () => {
      return getActiveUserImpersonationRequestAsync(userToImpersonate.id)
    }

    timeout.current = window.setTimeout(RefreshActiveRequest, 3 * 1000)
    return () => {
      clearInterval(timeout.current)
    }
  }, [
    activeRequest,
    userToImpersonate,
  ])

  const onCreateUserRequestClick = React.useCallback(() => {
    createUserImpersonateRequestAsync(userToImpersonate.id).then(() => {
      void getActiveUserImpersonationRequestAsync(userToImpersonate.id)
    })
  }, [userToImpersonate])

  const onDeleteUserRequestClick = React.useCallback(() => {
    if (activeRequest) {
      deleteUserImpersonateRequestAsync(activeRequest?.id).then(() => {
        setActiveRequest(undefined)
        void getActiveUserImpersonationRequestAsync(userToImpersonate.id)
      })
    }

  }, [activeRequest, userToImpersonate])

  const onAbandonUserRequestClick = React.useCallback(() => {
    if (activeRequest) {
      abandonUserImpersonateRequestAsync(activeRequest?.id).then(() => {
        setActiveRequest(undefined)
        void getActiveUserImpersonationRequestAsync(userToImpersonate.id)
      })
    }

  }, [activeRequest, userToImpersonate])

  const onLoginAsUserClick = React.useCallback(() => {

    if (activeRequest && activeRequest.statusId === UserImpersonationRequestStatusEnum.Approved) {
      void generateJwtTokenForUserImpersonationRequestAsync(activeRequest.id).then(() => {
        closeSupportUserImpersonateModal()
        reloadUser().then(() => {
          navigate(PageRoute.Dashboard.path)
        })
      })
    }

  }, [activeRequest, userToImpersonate])

  return (
    <CenterModal open onClose={onAbortImpersonation}>
      <ModalHeader
        title={f('SUPPORT_USER_IMPERSONATE_DIALOG_HEADER_TITLE')}
      />
      <Box mx={3}>
        <div
          style={{
            maxHeight: '70vh',
            minWidth: '40vw',
            overflow: 'auto',
          }}>
          <ModalSection
            title={f('SUPPORT_USER_IMPERSONATE_DIALOG_USER_TITLE')}
            subtitle={f('SUPPORT_USER_IMPERSONATE_DIALOG_USER_SUBTITLE')}>
            <ModalSectionRow>
              <Typography data-matomo-mask>{userToImpersonate.fullName}</Typography>
            </ModalSectionRow>
          </ModalSection>

          {activeRequest && (
            <ModalSection
              title={f('SUPPORT_USER_IMPERSONATE_DIALOG_ACTIVE_REQUEST_TITLE')}
              subtitle={f('SUPPORT_USER_IMPERSONATE_DIALOG_ACTIVE_REQUEST_SUBTITLE')}>
              <ModalSectionRow>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography>{f(`UserImpersonationRequestStatus_${activeRequest.statusId}`)}</Typography>
                  {activeRequest.statusId === UserImpersonationRequestStatusEnum.AwaitingApproval && (
                    <Box sx={{ marginLeft: '10px' }}>
                      <CircularProgress size={24} color="primary" />
                    </Box>
                  )}
                </Box>
              </ModalSectionRow>
            </ModalSection>
          )}

          {activeRequest && activeRequest.statusId === UserImpersonationRequestStatusEnum.Approved && (
            <ModalSection
              title={f('SUPPORT_USER_IMPERSONATE_DIALOG_VALID_TO_TITLE')}
              subtitle={' '}>
              <ModalSectionRow>
                <Typography>{format(activeRequest.sessionExpiresOn as Date, 'yyyy-MM-dd HH:mm')}</Typography>
              </ModalSectionRow>
            </ModalSection>
          )}


        </div>
      </Box>

      <ModalFooter>
        <TextButton type="button" onClick={onAbortImpersonation}>
          {f('BASIC_BUTTON_CLOSE_TEXT')}
        </TextButton>
        <Box ml={2}>
          {!activeRequest && (
            <ContainedButton color='secondary' disabled={isLoading} onClick={onCreateUserRequestClick}>{f('SUPPORT_USER_IMPERSONATE_DIALOG_CREATE_REQUEST')}</ContainedButton>
          )}
          {activeRequest && activeRequest.statusId === UserImpersonationRequestStatusEnum.AwaitingApproval && (
            <ContainedButton color='secondary' onClick={onDeleteUserRequestClick}>{f('SUPPORT_USER_IMPERSONATE_DIALOG_REMOVE_REQUEST')}</ContainedButton>
          )}
          {activeRequest && activeRequest.statusId === UserImpersonationRequestStatusEnum.Approved && (
            <Box display={'flex'} flexDirection={'row'}>
              <ContainedButton color='secondary' onClick={onAbandonUserRequestClick}>{f('SUPPORT_USER_IMPERSONATE_DIALOG_ABANDON_REQUEST')}</ContainedButton>
              <ContainedButton color='secondary' onClick={onLoginAsUserClick}>{f('SUPPORT_USER_IMPERSONATE_DIALOG_LOGIN_AS_USER')}</ContainedButton>
            </Box>
          )}
        </Box>
      </ModalFooter>
    </CenterModal>
  )
}

export default SupportUserImpersonateModalContainer
