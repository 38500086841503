import { Box, Typography, useTheme } from '@mui/material'
import { uniq } from 'lodash'
import CenterModal from 'components/center-modal'
import ModalHeader from 'components/modal-header'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { DailyActivitiesContext } from 'pages/daily-activities/daily-activities-context'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { UserListItem } from 'api/tillit.api-client'

const ModalBox = styled(Box)`
  padding: 1vh 3vw 1vh 3vw;
  background-color: #f9f9f9;
  ${({ theme }) => theme.breakpoints.down('md')}{
    padding: 5px;
  }
`

const UserSquareBoxContainer = styled(Box)(({ theme }) => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${theme.breakpoints.down('md')}{
    justify-content: flex-start;
    ${UserSquareBox}{
      width: 70px;
      min-height: 70px;
      height: auto;
      margin: 5px;
      padding: 5px;
      >h2{
        font-size: 1rem;
        font-weight: 500;
      }
      ${Typography70}{
        font-size: 12px;
        font-weight: 400;
        line-height: 1em;
      }
    }
    ${LetterSquareBox}{
      width: 30px;
      height: 30px;
      margin: 2px;
      >h5{
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
`)

const UserModalContainer: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const {
    userModal: { fetchState, open, users, recentLoggedInUsers, indexLetterOfUsersInList },
  } = DailyActivitiesContext.useState()
  const { isLoading } = fetchState

  const { closeUserModal, onUserSelect } = DailyActivitiesContext.useActions()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredUsers, setFilteredUsers] = React.useState<UserListItem[] | null>(users)

  React.useEffect(() => {
    if (filterValue) {
      setFilteredUsers(filteredUsers)
    } else {
      setFilteredUsers(users)
    }
    // NOTE: We are missing filterValue and getFilteredActivities as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [users])

  const getFilteredUsers = React.useCallback((letterFilter: string, userList: UserListItem[] | null): UserListItem[] | null => {
    const filtered = userList?.filter(user => (user.firstName ? user.firstName[0].toLocaleUpperCase() === letterFilter : false))
    if (filtered) {
      return filtered
    } else {
      return null
    }
  }, [])

  const handleLetterClicked = React.useCallback(
    (letter: string) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      setFilterValue(letter.toLocaleUpperCase())
      const filtered = getFilteredUsers(letter, users)
      setFilteredUsers(filtered)
    },
    [setFilterValue, setFilteredUsers, users, getFilteredUsers]
  )

  const handleClose = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setFilterValue('')
    setFilteredUsers(users)
    closeUserModal()
  }, [setFilterValue, setFilteredUsers, users, closeUserModal])

  const handleUserClicked = React.useCallback(
    (userId: number) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      setFilterValue('')
      setFilteredUsers(users)
      onUserSelect(userId)
    },
    [onUserSelect, setFilterValue, setFilteredUsers, users]
  )

  return (
    <CenterModal open={open} onClose={handleClose} responsive>
      <ModalBox style={{ flex: '1', overflow: 'auto' }}>
        {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
        {!isLoading && (recentLoggedInUsers || []).length > 0 && (
          <ModalBox>
            <ModalHeader
              title={(!isLoading && f('DAILY_ACTIVITIES_PAGE_USER_DIALOG_CONTAINER_HEADER_RECENT_LOGGED_IN_USERS_TITLE')) || ''}
              justifyContent="center"
              responsive
            />
            <UserSquareBoxContainer>
              {(recentLoggedInUsers || []).map((user, index) => {
                const initials = `${(user.firstName || '?')[0]}${(user.lastName || '?')[0]}`

              return (
                <UserSquareBox onClick={handleUserClicked(user.id)} key={index}>
                  <Typography variant={'h2'}>{initials}</Typography>
                  <Typography70 data-matomo-mask variant={'subtitle1'}>{`${user.firstName} ${user.lastName}`}</Typography70>
                </UserSquareBox>
              )
            })}
          </UserSquareBoxContainer>
        </ModalBox>
      )}
      {!isLoading && (
        <ModalBox>
          <ModalHeader title={(!isLoading && f('DAILY_ACTIVITIES_PAGE_USER_DIALOG_CONTAINER_HEADER_PERSONAL_LIST_TITLE')) || ''} justifyContent="center" responsive />
          <UserSquareBoxContainer>
            {uniq(indexLetterOfUsersInList || []).map((letter, index) => {
              return (
                <LetterSquareBox onClick={handleLetterClicked(letter)} key={index}>
                  <Typography variant={'h5'}>{letter}</Typography>
                </LetterSquareBox>
              )
            })}
          </UserSquareBoxContainer>
          <UserSquareBoxContainer>
            {(filteredUsers || []).map((user, index) => {
              const initials = `${(user.firstName || '?')[0]}${(user.lastName || '?')[0]}`

              return (
                <UserSquareBox onClick={handleUserClicked(user.id)} key={index}>
                  <Typography variant={'h2'}>{initials}</Typography>
                  <Typography70 data-matomo-mask variant={'subtitle1'}>{`${user.firstName} ${user.lastName}`}</Typography70>
                </UserSquareBox>
              )
            })}
          </UserSquareBoxContainer>
        </ModalBox>
      )}
      </ModalBox>
    </CenterModal>
  )
}

export const Typography70 = styled(Typography)`
  opacity: 0.7;
  word-break: break-word;
`

export const UserSquareBox = styled.div`
  width: 154px;
  height: 154px;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  background-color: #e0efff;
  margin: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`

export const LetterSquareBox = styled.div`
  width: 2vw;
  height: 4vh;
  border-radius: 0.5rem;
  border: 1px solid black;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  cursor: pointer;
`

export default UserModalContainer
