import React from 'react'

import { useHangfireApi } from 'api'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useHangfireContextModal = () => {
  const { getPortal } = useHangfireApi()
  const { state } = usePromiseApi()

  const [hangfireContent, setHangfireContent] = React.useState<string>('')

  React.useEffect(() => {
    getPortal().then(setHangfireContent)
  }, [getPortal, setHangfireContent])

  return {
    state: {
      ...state,
      hangfireContent,
    },
    actions: {},
  }
}

export const HangfireModalContext = createContext(useHangfireContextModal)
