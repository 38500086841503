import React from 'react'

import {
  LegislationClient,
  LegislationItem,
  LegislationListItem,
  LegislationUpdateItem,
  LegislationCreateItem,
  ILegislationUpdateItem,
  ILegislationCreateItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class LegislationApi {
  private readonly abortController = new AbortController()
  private readonly client: LegislationClient

  public constructor() {
    this.client = new LegislationClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public hasTemplateActivityCategoryConnectedToLegislation = (legislationId: number): Promise<boolean> =>
    this.client
      .hasTemplateActivityCategoryConnectedToLegislation(legislationId)
      .then(exists =>
        exists === null
          ? Promise.reject(`Unexpected response from hasTemplateActivityCategoryConnectedToLegislation for legislation id ${legislationId}.`)
          : Promise.resolve(exists)
      )

  public getLegislation = (legislationId: number): Promise<LegislationItem> =>
    this.client
      .getLegislation(legislationId)
      .then(legislationItem =>
        legislationItem === null
          ? Promise.reject(`No legislation item received from backend for legislation id ${legislationId}.`)
          : Promise.resolve(legislationItem)
      )

  public listLegislations = (organizationId: number): Promise<LegislationListItem[]> =>
    this.client
      .listOrganizationLegislations(organizationId)
      .then(legislationListItem =>
        legislationListItem === null
          ? Promise.reject(`No legislations received from backend for organization id ${organizationId}.`)
          : Promise.resolve(legislationListItem)
      )

  public updateLegislation = (legislation: ILegislationUpdateItem) => {
    const model = new LegislationUpdateItem(legislation)
    return this.client
      .updateLegislation(model)
      .then(legislationItem =>
        legislationItem === null ? Promise.reject(`When creating an legislation, no legislation item received from backend.`) : Promise.resolve(legislationItem)
      )
  }

  public createLegislation = (legislation: ILegislationCreateItem) => {
    const model = new LegislationCreateItem(legislation)
    return this.client
      .createLegislation(model)
      .then(legislationItem =>
        legislationItem === null ? Promise.reject(`When creating a legislation, no legislation item received from backend.`) : Promise.resolve(legislationItem)
      )
  }

  public deleteLegislation = (legislationId: number) => this.client.deleteLegislation(legislationId).then(() => Promise.resolve(legislationId))
}

const useLegislationApi = () => React.useMemo(() => new LegislationApi(), [])

export default useLegislationApi
