import React from 'react'

import {
  OpenUserImpersonationRequestClient,
  UserImpersonationRequestInfoItem,
  UserImpersonationRequestCreateItem,
  IUserImpersonationRequestCreateItem,
  UserImpersonationRequestListItem,
} from 'api/tenilo.support.api-client'
import { SUPPORT_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import { clearTokenData, getTokenData, hasTokenData, setTokenData, setSupportTokenData } from './token-data'


export class SupportUserImpersonationRequestsOpenApi {
  private readonly abortController = new AbortController()
  private readonly client: OpenUserImpersonationRequestClient

  public constructor() {
    this.client = new OpenUserImpersonationRequestClient(SUPPORT_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getUserImpersonationRequestInfo = (impersonateUserId: number, impersonationRequestToken: string): Promise<UserImpersonationRequestInfoItem> =>
    this.client
      .getUserImpersonationRequest(impersonateUserId,impersonationRequestToken)
      .then(item =>
        item === null
          ? Promise.reject(`No UserImpersonationRequest item received from backend.`)
          : Promise.resolve(item)
      )

  public approveUserImpersonationRequest = (impersonateUserId: number, impersonationRequestToken: string) => {
    return this.client
      .approveUserImpersonationRequest(impersonateUserId,impersonationRequestToken )
      .then(item =>
        item === null ? Promise.reject(`When approving an UserImpersonationRequest, no item received from backend.`) : Promise.resolve(item)
      )
  }

  public denyUserImpersonationRequest = (impersonateUserId: number, impersonationRequestToken: string) => {
    return this.client
      .denyUserImpersonationRequest(impersonateUserId,impersonationRequestToken )
      .then(item =>
        item === null ? Promise.reject(`When denying an UserImpersonationRequest, no item received from backend.`) : Promise.resolve(item)
      )
  }

}

const useSupportUserImpersonationRequestsOpenApi = () => React.useMemo(() => new SupportUserImpersonationRequestsOpenApi(), [])

export default useSupportUserImpersonationRequestsOpenApi
