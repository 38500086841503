import { Box, CardContent, Grid, IconButton, List, Typography, FormControlLabel, Switch } from '@mui/material'
import { ChevronLeft, ChevronRight, Plus } from 'mdi-material-ui'
import { Colors as ThemeColors } from 'styles/colors'
import { ISidePanelUsersWorkSchedulesItem, SidePanelPlannedRouteUserWorkScheduleItem } from 'api/tillit.api-client'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import { DeleteDialog } from 'components/delete-dialog'
import { SessionProps } from 'components/planning-grid/planning-grid-types'
import { useDrag, UseDragProps, UseDropProps } from 'components/planning-grid/utilities/drag-and-drop/drag-and-drop'
import { useDropSessionRemove } from 'components/planning-grid/utilities/use-drop-session-remove'
import produce from 'immer'
import { useFormatMessage } from 'localization'
import { Delete } from 'mdi-material-ui'
import { StyledCard, StyledTreeViewContainer } from 'pages/planning/components/sidebar/side-bar-style'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import styled from 'styled-components'
import ErrorBoundary from 'components/error-boundary'
import format from 'utilities/format'
import { mapSidePanelPlannedRouteUserWorkScheduleToMetaData, UserWorkScheduleMetaData } from '../../../../schedule-view'
import { useUsersSidepanel } from './utilities/use-users-sidepanel'

export enum SidebarColumnType {
  Personel,
}

interface ISidebarColumnProps {
  columnType: SidebarColumnType
  columnName: 'personel'  //| 'customer' | 'area'
  title: string
  isLoading: boolean
  initialUsersWorkSchedules: ISidePanelUsersWorkSchedulesItem[]
  initialVisibility: boolean
}

export const SideBarColumnContainer: React.FC<ISidebarColumnProps> = ({ columnType, title, initialUsersWorkSchedules, initialVisibility = false, columnName }) => {
  const { userWorkSchedules, isFiltered, isVisible, toggleFilter, toggleVisibility, setIsPlannedFor, isPlanned } = useUsersSidepanel(
    initialUsersWorkSchedules,
    initialVisibility
  )

  const f = useFormatMessage()

  const { selectedPlannedRouteUserWorkScheduleToDelete } = PlanningPageContext.useState()
  const { openCreateUserWorkScheduleDialog,
    removePlannedRouteUserWorkScheduleAsync,
    setSelectedPlannedRouteUserWorkScheduleToDelete,
    fetchNonCompliantRoutesForPlannedUserWorkScheduleAsync,
  } = PlanningPageContext.useActions()
  const { openModal } = ActivityCreateModalContext.useActions()

  const handleOpenModalClick = React.useCallback(() => {
    openCreateUserWorkScheduleDialog()
  }, [columnName, openCreateUserWorkScheduleDialog, openModal])

  const handleCanDrop: UseDropProps['canDrop'] = React.useCallback(
    ({ type, item }) => {
      const { meta } = item as SessionProps<UserWorkScheduleMetaData>
      const b = isPlanned(meta.id)
      return b
    },
    [isPlanned]
  )

  const handleDrop: UseDropProps['onDrop'] = React.useCallback(
    ({ type, item, element, collection }) => {
      const { meta } = item as SessionProps<UserWorkScheduleMetaData>

      if (!meta.isPlanned) {
        setIsPlannedFor(meta.id, false)
      }
    },
    [setIsPlannedFor]
  )

  const [drop, { canDrop, isOver, isDragging }] = useDropSessionRemove({
    canDrop: handleCanDrop,
    onDrop: handleDrop,
  })

  const handleUserWorkSchedulePlan = React.useCallback(
    (meta: UserWorkScheduleMetaData) => {
      setIsPlannedFor(meta.id, meta.isPlanned)
    },
    [setIsPlannedFor]
  )

  const handleDragStart = React.useCallback(
    (meta: UserWorkScheduleMetaData) => {
      void fetchNonCompliantRoutesForPlannedUserWorkScheduleAsync(meta.id)
    },
    []
  )

  const handleRemovePlannedRouteUserWorkSchedule = React.useCallback(() => {
    removePlannedRouteUserWorkScheduleAsync()
    setSelectedPlannedRouteUserWorkScheduleToDelete(null)
  }, [removePlannedRouteUserWorkScheduleAsync, setSelectedPlannedRouteUserWorkScheduleToDelete])

  const handleCloseDeleteDialog = React.useCallback(() => {
    setSelectedPlannedRouteUserWorkScheduleToDelete(null)
  }, [])
  return (
    <>
      <StyledTreeViewContainer ref={drop} active={isVisible} isover={isOver && canDrop && isDragging}>
        <div className="title-style">
          <GridContainer>
            <Grid item onClick={toggleVisibility}>
              {isVisible ? <ChevronRight /> : <ChevronLeft />}
            </Grid>
            <GridTitle>{title}</GridTitle>
            <Box ml={'auto'} />
            <Grid item>{isVisible && <Plus color="secondary" onClick={handleOpenModalClick} />}</Grid>
          </GridContainer>
        </div>
        <div className="list-container">
          <Box mb={2} display="flex" alignItems="center">
            <FormControlLabel
              onChange={toggleFilter}
              control={<Switch checked={isFiltered} color="primary" />}
              label={f('PLANNING_PAGE_TREEVIEW_CHECKBOX_SHOW_ALL_WORKSCHEDULES')}
              labelPlacement="end"
            />
          </Box>

          <div className={`list-style`}>
            {userWorkSchedules
              .slice()
              .sort((a1, a2) => {
                return sortAccordingToPanelTitle(getPanelTitle(a1), getPanelTitle(a2))
              })
              .map((item, index) => {
                const { sidePanelPlannedRouteUserWorkScheduleItems = [] } = item
                return (
                  <CardContainer
                    columnType={columnType}
                    key={index}
                    title={createTitle(item)}
                    onUserWorkSchedulePlan={handleUserWorkSchedulePlan}
                    onDragStart={handleDragStart}
                    sidePanelUserWorkScheduleItems={sidePanelPlannedRouteUserWorkScheduleItems}
                  />
                )
              })}
          </div>
        </div>
      </StyledTreeViewContainer>
      {selectedPlannedRouteUserWorkScheduleToDelete !== null && (
        <DeleteDialog
          dialogTitle={f('PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_TITLE')}
          dialogContentText={
            f('PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_DESCRIPTION', { title: selectedPlannedRouteUserWorkScheduleToDelete.userFullName || '' })
          }
          buttonSubmitText={f('PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_REMOVE_BUTTON')}
          buttonCancelText={f('PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_CANCEL_BUTTON')}
          deleteDialogOpen
          onClose={handleCloseDeleteDialog}
          onSubmit={handleRemovePlannedRouteUserWorkSchedule}
        />
      )}
    </>
  )
}

const getPanelTitle = (item: ISidePanelUsersWorkSchedulesItem): string => {
  return (item.userFirstName ?? '') + (item.userLastName ?? '')
}

const sortAccordingToPanelTitle = (title1: string, title2: string): number => {
  if (title1 && title2 && title1 < title2) {
    return -1
  }
  if (title1 && title2 && title1 > title2) {
    return 1
  }
  return 0
}

const GridTitle: React.FC = ({ children }) => {
  return (
    <Grid
      item
      css={{
        paddingBottom: '5px',
        whiteSpace: 'nowrap'
      }}>
      <Typography>{children}</Typography>
    </Grid>
  )
}

const GridContainer: React.FC = ({ children }) => {
  return (
    <Grid container direction="row" alignItems="center" wrap="nowrap">
      {children}
    </Grid>
  )
}

const CardContainer: React.SFC<{
  columnType: SidebarColumnType
  title: string
  sidePanelUserWorkScheduleItems: SidePanelPlannedRouteUserWorkScheduleItem[]
  onUserWorkSchedulePlan?: (meta: UserWorkScheduleMetaData) => void
  onDragStart?: (meta: UserWorkScheduleMetaData) => void
}> = ({ children, title, sidePanelUserWorkScheduleItems, columnType, onUserWorkSchedulePlan, onDragStart }) => {
  const { setSelectedPlannedRouteUserWorkScheduleToDelete } = PlanningPageContext.useActions()

  const handleOnDeleteClick = React.useCallback((plannedRouteUserWorkSchedule) => () => {
    setSelectedPlannedRouteUserWorkScheduleToDelete(plannedRouteUserWorkSchedule)
  }, [setSelectedPlannedRouteUserWorkScheduleToDelete])

  return (
    <StyledCard>
      <CardContent>
        <Typography data-matomo-mask>{title}</Typography>
        <List dense>
          {sidePanelUserWorkScheduleItems
            .slice(0)
            .sort((a, b) => a.scheduledStart.getTime() - b.scheduledStart.getTime())
            .map(plannedRouteUserWorkSchedule => {
              const meta = mapSidePanelPlannedRouteUserWorkScheduleToMetaData(plannedRouteUserWorkSchedule)
              return (
                <ErrorBoundary key={`e-${plannedRouteUserWorkSchedule.id}`}>

                  <SidebarPlannedRouteUserWorkSchedule
                    onDeleteClick={handleOnDeleteClick(plannedRouteUserWorkSchedule)}
                    columnType={columnType}
                    key={plannedRouteUserWorkSchedule.id}
                    meta={meta}
                    onUserWorkSchedulePlan={onUserWorkSchedulePlan}
                    onDragStart={onDragStart}
                  />
                </ErrorBoundary>
              )
            })}
        </List>
      </CardContent>
    </StyledCard>
  )
}

const createTitle = (item: ISidePanelUsersWorkSchedulesItem): string => {
  return `${item.userFirstName} ${item.userLastName}`
}

interface ISidebarPlannedRouteUserWorkScheduleProps {
  columnType: SidebarColumnType
  meta: UserWorkScheduleMetaData
  onDeleteClick?: () => void
  onUserWorkSchedulePlan?: (meta: UserWorkScheduleMetaData) => void
  onDragStart?: (meta: UserWorkScheduleMetaData) => void
  className?: string
}

const SidebarPlannedRouteUserWorkSchedule: React.SFC<ISidebarPlannedRouteUserWorkScheduleProps> = ({ meta, onUserWorkSchedulePlan, onDragStart, onDeleteClick, className, columnType }) => {
  const {
    id,
    scheduledStart,
    scheduledEnd,
    isPlanned,
    substituteUserFullName,
    substituteUserId
  } = meta

  const { openEditUserWorkScheduleDialog, setNonCompliantRoutesForPlannedUserWorkSchedule } = PlanningPageContext.useActions()

  const f = useFormatMessage()

  if (!scheduledStart) {
    throw Error('No scheduled start date received')
  }
  if (!scheduledEnd) {
    throw Error('No scheduled end date received')
  }

  const fromFullTimeString = format(scheduledStart, 'HH:mm')
  const toFullTimeString = format(scheduledEnd, 'HH:mm')

  const soughtDate = React.useMemo(() => new Date(+scheduledStart + (+scheduledEnd - +scheduledStart) / 2), [scheduledEnd, scheduledStart])
  const lengthInMinutes = (scheduledEnd.getTime() - scheduledStart.getTime()) / (1000 * 60)
  const item = React.useMemo(() => ({ lengthInMinutes, id, soughtDate, meta }), [id, lengthInMinutes, meta, soughtDate])

  const handleStart: UseDragProps['onStart'] = React.useCallback(
    ({ item: draggingItem }) => {
      const { meta: draggingMeta } = draggingItem as SessionProps<UserWorkScheduleMetaData>
      onDragStart?.(draggingMeta)
    },
    [onDragStart]
  )

  const handleEnd: UseDragProps['onEnd'] = React.useCallback(
    ({ item: draggedItem }) => {
      setNonCompliantRoutesForPlannedUserWorkSchedule(null)
      if (onUserWorkSchedulePlan) {
        const { meta: draggedMeta } = draggedItem as SessionProps<UserWorkScheduleMetaData>
        const updatedMeta = produce(draggedMeta, draft => {
          // We should not set isPlanned here. This is a problem when dropping outside expected areas.
          // Reload takes care of it for now. Leaving it in as a reminder.
          //draft.isPlanned = true
        })
        onUserWorkSchedulePlan(updatedMeta)
      }
      return undefined
    },
    [onUserWorkSchedulePlan]
  )

  const editUserWorkSchedule = React.useCallback(
    (props: any) => {
      openEditUserWorkScheduleDialog(false, props.event.id)
    },
    [openEditUserWorkScheduleDialog]
  )

  const handleOnClick = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    editUserWorkSchedule({
      event: { id },
      originalEvent: {
        x: event.clientX,
        y: event.clientY,
      },
    })
  }, [id])

  const handleCanDrag = React.useCallback(() => {
    return !isPlanned
  }, [isPlanned])

  // eslint-disable-next-line
  const [_, drag, { isDragging }] = useDrag('SESSION_ADD', item, {
    onStart: handleStart,
    onEnd: handleEnd,
    canDrag: handleCanDrag,
  })

  const timeString = `${fromFullTimeString} - ${toFullTimeString} (${lengthInMinutes} ${f('BASIC_TEXT_MINUTES_ABBREVIATION')})`

  const textColor = isPlanned || isDragging ? 'rgba(0, 0, 0, 0.82)' : 'white'

  let workScheduleTimeString = `${fromFullTimeString} - ${toFullTimeString}`
  const workScheduleLengthString = `(${lengthInMinutes} ${f('BASIC_TEXT_MINUTES_ABBREVIATION')})`
  let backgroundColor = ThemeColors.TeniloGreen
  if (substituteUserId) {
    backgroundColor = ThemeColors.TeniloBrown
    workScheduleTimeString = `${workScheduleTimeString} (${substituteUserFullName})`
  }


  return (
    <SidebarPlannedRouteUserWorkScheduleWrapper>
      <SidebarPlannedRouteUserWorkScheduleStyle ref={drag} isPlanned={isPlanned || isDragging} textColor={textColor} activityColor={backgroundColor} onClick={handleOnClick}>
        <Typography
          variant="subtitle2"
          style={{
            color: textColor,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {workScheduleTimeString}
        </Typography>
        <RightSection className="typography-time">
          {timeString && (
            <Typography
              variant="subtitle2"
              style={{
                paddingRight: '4px',
                color: textColor,
                whiteSpace: 'nowrap',
              }}>
              {workScheduleLengthString}
            </Typography>
          )}

        </RightSection>
      </SidebarPlannedRouteUserWorkScheduleStyle>
      <DeleteIconButton>
        <IconButton size="small" onClick={onDeleteClick}>
          <Delete htmlColor="white" />
        </IconButton>
      </DeleteIconButton>
    </SidebarPlannedRouteUserWorkScheduleWrapper>
  )
}

const DeleteIconButton = styled.div`
    position: relative;
    min-width: 34px;
    min-height: 34px;
    margin-left: 8px;
    background-color:#DF3E31;
    border-radius: 5px;
  `

const SidebarPlannedRouteUserWorkScheduleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 5px 0;

  ${DeleteIconButton} {
    display: none;
  }

  &:hover {
    ${DeleteIconButton} {
      display: inherit;
    }
  }
`

const RightSection = styled('div')`
  display: flex;
  flex-direction: row;
`

type SidebarPlannedRouteUserWorkScheduleStyleProps = Readonly<{
  isPlanned: boolean
  activityColor: string
  textColor: string
}>

const SidebarPlannedRouteUserWorkScheduleStyle = styled.div<SidebarPlannedRouteUserWorkScheduleStyleProps>`
  flex: 1;
  color: ${props => props.textColor};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 34px;
  padding: 0 5px;
  border-radius: 5px;
  user-select: none;

  ${props =>
    props.isPlanned
      ? `
        border: 2px dashed rgb(179, 179, 179);
        color: rgb(179, 179, 179);
      `
      : `
        border: 2px dashed transparent;
        cursor: pointer;
        background-color: ${props.activityColor};
      `
  };
`
