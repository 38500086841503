import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import {
  PlannedRouteUserWorkScheduleUpdateItem,
  IPlannedRouteUserWorkScheduleItem
} from 'api/tenilo.workschedule.api-client'
import {
  UserListItem, UserTypeEnum,
} from 'api/tillit.api-client'
import * as yup from 'yup'

interface UserWorkScheduleForm {
  id: number,
  startDate: Date | undefined,
  endDate: Date | undefined,
  scheduledStart: Date | undefined,
  scheduledEnd: Date | undefined,
  userId: number | undefined,
  user: UserListItem | undefined,
  substituteUserId: number | undefined,
  substituteUser: UserListItem | undefined,
  substituteUserIdForAllSelected: number | undefined,
  substituteUserForAllSelected: UserListItem | undefined,
}

const useEditUserWorkScheduleModalFormData = () => {
  const f = useFormatMessage()

  const {
    editUserWorkScheduleModalData,
    editUserWorkScheduleModalOpen
  } = PlanningPageContext.useState()

  const {
    closeEditUserWorkScheduleDialog,
    updateUserWorkScheduleAsync,
  } = PlanningPageContext.useActions()

  const initialValues = React.useMemo<UserWorkScheduleForm>(
    () => {
      if (editUserWorkScheduleModalData) {

        const selectedUser = new UserListItem({
          id: editUserWorkScheduleModalData.userId,
          fullName: editUserWorkScheduleModalData.userFullName,
          active: true,
          userType: UserTypeEnum.OrganizationMember
        })

        let substituteUser
        if (editUserWorkScheduleModalData.substituteUserId) {
          substituteUser = new UserListItem({
            id: editUserWorkScheduleModalData.substituteUserId,
            fullName: editUserWorkScheduleModalData.substituteUserFullName,
            active: true,
            userType: UserTypeEnum.OrganizationMember
          })
        }

        return {
          id: editUserWorkScheduleModalData.id,
          startDate: new Date(new Date(editUserWorkScheduleModalData?.scheduledStart.getTime()).setHours(0, 0, 0, 0)),
          endDate: new Date(new Date(editUserWorkScheduleModalData?.scheduledEnd.getTime()).setHours(0, 0, 0, 0)),
          scheduledStart: editUserWorkScheduleModalData?.scheduledStart,
          scheduledEnd: editUserWorkScheduleModalData?.scheduledEnd,
          userId: editUserWorkScheduleModalData?.userId,
          user: selectedUser,
          substituteUserId: editUserWorkScheduleModalData.substituteUserId,
          substituteUser,
          substituteUserForAllSelected: undefined,
          substituteUserIdForAllSelected: undefined,
        }
      }

      return {
        id: -1,
        startDate: undefined,
        endDate: undefined,
        scheduledStart: undefined,
        scheduledEnd: undefined,
        userId: undefined,
        user: undefined,
        substituteUserId: undefined,
        substituteUser: undefined,
        substituteUserIdForAllSelected: undefined,
        substituteUserForAllSelected: undefined,
      }

    },
    [editUserWorkScheduleModalData, editUserWorkScheduleModalOpen]
  )

  const mapUserWorkScheduleFormToUserWorkScheduleItem = (initialItem: IPlannedRouteUserWorkScheduleItem, item: UserWorkScheduleForm): PlannedRouteUserWorkScheduleUpdateItem | null => {

    if (!item) {
      return null
    }
    if (!item.startDate) {
      return null
    }
    if (!item.scheduledStart) {
      return null
    }
    if (!item.endDate) {
      return null
    }
    if (!item.scheduledEnd) {
      return null
    }

    const newScheduledStart = new Date(item.startDate.getTime())
    newScheduledStart.setHours(item.scheduledStart.getHours())
    newScheduledStart.setMinutes(item.scheduledStart.getMinutes())

    const newScheduledEnd = new Date(item.endDate.getTime())
    newScheduledEnd.setHours(item.scheduledEnd.getHours())
    newScheduledEnd.setMinutes(item.scheduledEnd.getMinutes())

    return new PlannedRouteUserWorkScheduleUpdateItem({
      id: initialItem.id,
      plannedRouteId: initialItem.plannedRouteId,
      userId: initialItem.userId,
      scheduledStart: newScheduledStart ? newScheduledStart : initialItem.scheduledStart,
      scheduledEnd: newScheduledEnd ? newScheduledEnd : initialItem.scheduledEnd,
      substituteUserId: item.substituteUserId,
      substituteUserIdForAllSelected: item.substituteUserIdForAllSelected,
    })
  }



  const onSubmit: OnSubmitFunction<UserWorkScheduleForm> = React.useCallback(
    async (values, { setSubmitting }) => {

      const initialItem = editUserWorkScheduleModalData as IPlannedRouteUserWorkScheduleItem

      if (!initialItem) {
        closeEditUserWorkScheduleDialog()
        setSubmitting(false)
      }

      const mappedItem = mapUserWorkScheduleFormToUserWorkScheduleItem(initialItem, values)
      if (mappedItem) {
        await updateUserWorkScheduleAsync(mappedItem).finally(() => {
          closeEditUserWorkScheduleDialog()
          setSubmitting(false)
        })
      }

    },
    [
      closeEditUserWorkScheduleDialog,
      updateUserWorkScheduleAsync,
      editUserWorkScheduleModalData,
    ]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        startDate: yup.date(),
        endDate: yup.date(),
        scheduledStart: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        scheduledEnd: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        userId: yup.number(),
        user: yup.object().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        substituteUserId: yup.number().nullable(),
        substituteUser: yup.object().nullable(),
        substituteUserIdForAllSelected: yup.number().nullable(),
        substituteUserForAllSelected: yup.object().nullable(),

      }),
    [f]
  )

  const name: Name<UserWorkScheduleForm> = React.useMemo(
    () => ({
      id: 'id',
      startDate: 'startDate',
      endDate: 'endDate',
      scheduledStart: 'scheduledStart',
      scheduledEnd: 'scheduledEnd',
      userId: 'userId',
      user: 'user',
      substituteUserId: 'substituteUserId',
      substituteUser: 'substituteUser',
      substituteUserIdForAllSelected: 'substituteUserIdForAllSelected',
      substituteUserForAllSelected: 'substituteUserForAllSelected',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useEditUserWorkScheduleModalFormData
