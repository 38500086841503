import React from 'react'
import { RoleEnum, UserAreaItem } from 'api/tillit.api-client'
import { Box } from '@mui/material'
import SelectInput from 'components/inputs/select-input'
import { useFormatMessage } from 'localization'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import AdditionalAreasList from './components/additional-areas-list'

type Props = Readonly<{
  areas: UserAreaItem[] | undefined
  additionalAreas: UserAreaItem[] | undefined
}>

const AdditionalAreas: React.FC<Props> = ({ areas, additionalAreas }) => {
  const f = useFormatMessage()

  const availableAreas = React.useMemo(() => {
    return areas?.filter(area => !additionalAreas?.some(ap => ap.areaId === area.areaId)).sort((a, b) => (a.areaName || '').localeCompare(b.areaName || ''))
  }, [areas, additionalAreas])

  const sortedAdditionalAreas = React.useMemo(() => {
    return additionalAreas?.sort((a, b) => (a.areaName || '').localeCompare(b.areaName || ''))
  }, [additionalAreas])

  const handleAddAdditionalArea = (FieldArrayProps: FieldArrayRenderProps) => (event: any, value: any) => {
    const area = value as UserAreaItem
    FieldArrayProps.unshift(new UserAreaItem({
      id: -1,
      areaId: area.areaId,
      areaName: area.areaName,
      hasChildAreas: false,
      hasRoutes: false,
      userId: -1,
      roleId: RoleEnum.Planner
    }))
  }

  const getAreaName = React.useCallback((value: any): string => {
    const area = value as UserAreaItem
    return area.areaName as string
  }, [])

  return (
    <Box>
      <FieldArray name={'timeBasedArea'}>
        {(FieldArrayProps) => {
          return (
            <>
              <SelectInput
                data-matomo-mask
                name='selectedArea'
                options={availableAreas as any}
                label={f('ADMINISTER_MANAGE_DEVICE_DIALOG_ADD_ADDITIONALAREAS_TITLE')}
                onChange={handleAddAdditionalArea(FieldArrayProps)}
                getOptionLabel={getAreaName}
                fullWidth
              />
              <AdditionalAreasList additionalAreas={sortedAdditionalAreas} {...FieldArrayProps} />
            </>
          )
        }}
      </FieldArray>
    </Box>
  )
}

export default AdditionalAreas
