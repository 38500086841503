import React from 'react'
import { HangfireModal } from './components/hangfire-modal'
import { PrivacyPolicyModal } from './components/privacy-policy-modal'
import { OrganizationInternalInfoModal } from './components/organization-internal-information-modal'
import { ProfilePageContext } from './profile-page-context'
import { ProfilePageView } from './profile-page-view'
import { ProfileSigningStatusesContext } from './profile-signing-statuses-context'
import { ManualsModal } from './components/manuals'

const ProfilePageContainer: React.FC = () => {

  return (
    <ProfilePageContext.Provider>
      <ProfileSigningStatusesContext.Provider>
        <ProfilePageView />
        <PrivacyPolicyModal />
        <HangfireModal />
        <OrganizationInternalInfoModal />
        <ManualsModal />
      </ProfileSigningStatusesContext.Provider>
    </ProfilePageContext.Provider>
  )
}

export default ProfilePageContainer
