import React from 'react'

import {
  ChangePasswordUsingCurrentPasswordItem,
  ChangePasswordUsingTokenItem,
  ChangePinCodeUsingTokenItem,
  IUserItem,
  PageResultOfUserListItem,
  RoleEnum,
  UserAreaCreateItem,
  UserClient,
  UserCreateItem,
  UserItem,
  UserTypeEnum,
  UserUpdateItem,
  UserDelegationPersistItem,
  UserDelegationItem,
  UserListItem,
  SendEmailToUsersItem,
  UserAreaItem,
  IUserSettings,
  UserSettings,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export type UserFormModel = Readonly<{
  active: boolean
  description?: string
  email?: string
  firstName?: string
  id?: number
  lastName?: string
  organizationId?: number
  phoneNumber?: string
  pinCode?: string
  userAreas?: Array<{
    areaId: number
    roleId: RoleEnum
  }>
  userDelegations?: UserDelegationPersistItem[]
  selectedDelegations?: UserDelegationItem[]
  userType: 'SystemAdmin' | 'OrganizationMember'
  extraInfo?: string
}>

const getUserTypeEnum = (type: 'SystemAdmin' | 'OrganizationMember') => {
  switch (type) {
    case 'SystemAdmin':
      return UserTypeEnum.SystemAdmin
    case 'OrganizationMember':
      return UserTypeEnum.OrganizationMember
  }
}

export class UserApi {
  private readonly client: UserClient
  private readonly abortController = new AbortController()

  constructor() {
    this.client = new UserClient(API_URL, httpMiddleware({ signal: this.abortController.signal, headers: { 'key-name': 'user' } }))
  }

  public abort = () => this.abortController.abort()

  public requestChangePasswordToken = (userId: number, sendToAdmin: boolean) => this.client.requestChangePasswordToken(userId, sendToAdmin)

  public changePasswordUsingCurrentPassword = (userId: number, currentPassword: string, newPassword: string): Promise<void> => {
    const model = new ChangePasswordUsingCurrentPasswordItem({
      userId,
      currentPassword,
      newPassword,
    })
    return this.client.changePasswordUsingCurrentPassword(userId, model).then(_ => Promise.resolve())
  }

  public changePasswordUsingToken = (userId: number, token: string, newPassword: string): Promise<void> => {
    const model = new ChangePasswordUsingTokenItem({
      userId,
      token,
      newPassword,
    })
    return this.client.changePasswordUsingToken(userId, model).then(_ => Promise.resolve())
  }

  public changePinCodeUsingToken = (userId: number, token: string, newPinCode: string): Promise<void> => {
    const model = new ChangePinCodeUsingTokenItem({
      userId,
      token,
      newPinCode,
    })
    return this.client.changePinCodeUsingToken(userId, model).then(_ => Promise.resolve())
  }

  public create = async (user: Omit<UserFormModel, 'id'>): Promise<UserItem> => {
    const model = new UserCreateItem({
      ...user,
      userType: getUserTypeEnum(user.userType),
      userAreas: (user.userAreas || []).map(ua => new UserAreaCreateItem(ua)),
    })
    return this.client
      .create(model)
      .then(userItem => (userItem === null ? Promise.reject(`No user received from backend when creating.`) : Promise.resolve(userItem)))
  }

  public list = (page: number, pageSize: number, organizationId: number | null): Promise<PageResultOfUserListItem> =>
    this.client
      .list(page, pageSize, organizationId, null)
      .then(userItem => (userItem === null ? Promise.reject(`No list of users received from backend.`) : Promise.resolve(userItem)))

  public listAllPersonnelOnOtherAreas = (page: number, pageSize: number, organizationId: number | null): Promise<PageResultOfUserListItem> =>
    this.client
      .listPersonnelOnOtherAreas(page, pageSize, organizationId, null)
      .then(userItem => (userItem === null ? Promise.reject(`No list of users received from backend.`) : Promise.resolve(userItem)))

  public get = async (userId: number): Promise<IUserItem> =>
    await this.client.getUser(userId).then(userItem => (userItem === null ? Promise.reject(`No user received from backend.`) : Promise.resolve(userItem)))

  public update = async (user: Omit<UserFormModel, 'id'> & { id: number }) => {
    const model = new UserUpdateItem({
      ...user,
      userType: getUserTypeEnum(user.userType),
      userAreas: user.userAreas ? user.userAreas.map(ua => new UserAreaCreateItem(ua)) : [],
    })
    return await this.client.updateUser(model)
  }

  public remove = (userId: number): Promise<boolean> => this.client.remove(userId).then(() => true)

  public activate = (userId: number): Promise<boolean> => this.client.activate(userId).then(() => true)

  public deactivate = (userId: number): Promise<boolean> => this.client.deactivate(userId).then(() => true)

  public isEmailUnique = async (email: string, userId: number): Promise<boolean> =>
    await this.client
      .isEmailUnique(email, userId)
      .then(unique => (unique === null ? Promise.reject(`Cannot determine if email is unique`) : Promise.resolve(unique)))

  public isValidToActivate = async (userId: number): Promise<boolean> =>
    await this.client
      .isValidToActivate(userId)
      .then(unique => (unique === null ? Promise.reject(`Cannot determine if user is valid to activate`) : Promise.resolve(unique)))

  public listActiveUsersByAreaId = async (areaId: number): Promise<UserListItem[]> =>
    await this.client
      .listActiveUsersByAreaId(areaId)
      .then(userItems => (userItems === null ? Promise.reject(`No users received from backend.`) : Promise.resolve(userItems)))

  public sendMultipleEmailsToUsers = (model: SendEmailToUsersItem[]) => {
    return this.client
      .sendMultipleEmails(model)
      .then(() => Promise.resolve())
      .catch(err => {
        throw err
      })
  }

  public getDbUserAreas = async (userId: number): Promise<UserAreaItem[]> =>
    await this.client
      .getDbUserAreas(userId)
      .then(userAreaItems => (userAreaItems === null ? Promise.reject(`No userareas received from backend.`) : Promise.resolve(userAreaItems)))

  public removeUserAreasOnUserPrincipalHasAccessTo = async (userId: number): Promise<boolean> =>
    this.client.removeUserAreasOnUserPrincipalHasAccessTo(userId).then(() => true)

  public updateUserSettings = async (userId: number, settings: IUserSettings) => {
    const model = new UserSettings(settings)
    return this.client.updateUserSettings(userId, model)
  }
}

const useUserApi = () => React.useMemo(() => new UserApi(), [])

export default useUserApi
