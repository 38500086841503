import React from 'react'
import { List } from '@mui/material'
import { FieldArrayRenderProps } from 'formik'
import { ParticipantItem } from '../../participant-item-type'
import ParticipantListItem from './components/participant-list-item'

type Props = Readonly<{
  activityParticipants: ParticipantItem[] | undefined
  enableDecline?: boolean
} & FieldArrayRenderProps>

const ParticipantsList: React.FC<Props> = ({ activityParticipants, enableDecline, ...FieldArrayProps }) => {
  return (
    <List style={{ maxHeight: '50vh', overflow: 'auto' }}>
      {(activityParticipants || []).map((participant: ParticipantItem, index: number) => {
        return (
          <ParticipantListItem
            participant={participant}
            key={`participant-list-item-${index}`}
            index={index}
            enableDecline={enableDecline}
            {...FieldArrayProps}
          />
        )
      })}
    </List>
  )
}

export default ParticipantsList
