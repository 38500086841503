import React from 'react'
import { useUserWorkScheduleApi } from 'api'
import usePromiseApi from 'utilities/use-promise'
import { createContext } from 'utilities/create-context'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { LatestUserWorkScheduleItem, PlannedRouteUserWorkScheduleItem, UpdateMultiplePlannedUserWorkScheduleItem } from 'api/tenilo.workschedule.api-client'

const useEditUserWorkScheduleModalContext = () => {

  const { calendarStartDate } = PlanningPageContext.useState()
  const { listLatestUserWorkSchedules, listFuturePlannedUserWorkSchedulesOnUser, updateMultiplePlannedUserWorkSchedules } = useUserWorkScheduleApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [latestUserWorkSchedules, setLatestUserWorkSchedules] = React.useState<LatestUserWorkScheduleItem[] | undefined>([])
  const [futureUserWorkSchedulesOnUser, setFutureUserWorkSchedulesOnUser] = React.useState<PlannedRouteUserWorkScheduleItem[] | undefined>([])

  const getLatestWorkSchedulesForSubstituteUser = React.useCallback((userId: number, currentPlannedRouteUserWorkScheduleId: number) => {

    void fetchAsync(listLatestUserWorkSchedules(userId, calendarStartDate)).then(result => {
      //filter result to avoid showing the current workschedule
      setLatestUserWorkSchedules(result.filter(pruws => pruws.id !== currentPlannedRouteUserWorkScheduleId))
    })

  }, [fetchAsync, listLatestUserWorkSchedules, calendarStartDate])

  const getFuturePlannedUserWorkSchedulesOnUser = React.useCallback((userId: number, areaId: number, plannedRouteUserWorkScheduleId: number) => {

    void fetchAsync(listFuturePlannedUserWorkSchedulesOnUser(userId, areaId, plannedRouteUserWorkScheduleId)).then(result => {
      setFutureUserWorkSchedulesOnUser(result)
    })
  }, [fetchAsync, listFuturePlannedUserWorkSchedulesOnUser, setFutureUserWorkSchedulesOnUser])

  return {
    state: {
      ...stateOfFetch,
      latestUserWorkSchedules,
      futureUserWorkSchedulesOnUser,
    },
    actions: {
      getLatestWorkSchedulesForSubstituteUser,
      setLatestUserWorkSchedules,
      getFuturePlannedUserWorkSchedulesOnUser,
      setFutureUserWorkSchedulesOnUser,
    },
  }
}

export const EditUserWorkScheduleModalContext = createContext(useEditUserWorkScheduleModalContext)
