import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import {
  PlannedRouteUserWorkScheduleCreateItem,
  CommonShiftListItem
} from 'api/tenilo.workschedule.api-client'

import {
  UserListItem,
} from 'api/tillit.api-client'

import * as yup from 'yup'

interface UserWorkScheduleForm {
  startDate: Date | undefined,
  endDate: Date | undefined,
  scheduledStart: Date,
  scheduledEnd: Date,
  userId: number | undefined,
  user: UserListItem | undefined,
  commonShift: CommonShiftListItem | undefined
}

const useCreateUserWorkScheduleModalFormData = () => {
  const f = useFormatMessage()

  const {
    closeCreateUserWorkScheduleDialog,
    createUserWorkScheduleAsync,
  } = PlanningPageContext.useActions()

  const { unplannedRouteId, calendarStartDate, createUserWorkScheduleModalOpen, calendarEndDate } = PlanningPageContext.useState()

  const initialValues = React.useMemo<UserWorkScheduleForm>(
    () => {
      const startDate = new Date(new Date(calendarStartDate).setHours(0, 0, 0, 0))
      const endDate = new Date(new Date(calendarStartDate).setHours(0, 0, 0, 0))
      const scheduledStart = new Date(new Date(calendarStartDate).setHours(7, 0, 0))
      const scheduledEnd = new Date(new Date(calendarStartDate).setHours(16, 0, 0))

      return {
        startDate,
        endDate,
        scheduledStart,
        scheduledEnd,
        userId: undefined,
        user: undefined,
        commonShift: undefined
      }
    },
    [calendarStartDate, createUserWorkScheduleModalOpen]
  )

  const createUserWorkSchedule = (values: UserWorkScheduleForm, routeId: number | undefined): PlannedRouteUserWorkScheduleCreateItem => {
    const { userId, startDate, endDate, scheduledStart, scheduledEnd } = values

    if (startDate && endDate) {
      const newScheduledStart = new Date(startDate.getTime())
      newScheduledStart.setHours(scheduledStart.getHours())
      newScheduledStart.setMinutes(scheduledStart.getMinutes())

      const newScheduledEnd = new Date(endDate.getTime())
      newScheduledEnd.setHours(scheduledEnd.getHours())
      newScheduledEnd.setMinutes(scheduledEnd.getMinutes())

      return new PlannedRouteUserWorkScheduleCreateItem({
        userId: userId ? userId : -1,
        scheduledStart: newScheduledStart,
        scheduledEnd: newScheduledEnd,
        routeId: routeId ? routeId : -1,
        calendarDay: calendarEndDate
      })
    }

    return new PlannedRouteUserWorkScheduleCreateItem({
      userId: userId ? userId : -1,
      scheduledStart: new Date(),
      scheduledEnd: new Date(),
      routeId: routeId ? routeId : -1,
      calendarDay: new Date()
    })
  }

  const onSubmit: OnSubmitFunction<UserWorkScheduleForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const userWorkSchedule = createUserWorkSchedule(
        {
          ...values,
        },
        unplannedRouteId)

      await createUserWorkScheduleAsync(userWorkSchedule).finally(() => {
        closeCreateUserWorkScheduleDialog()
        setSubmitting(false)
      })

      /* const initialItem = editUserWorkScheduleModalData as IPlannedRouteUserWorkScheduleItem

       if (!initialItem) {
         closeCreateUserWorkScheduleDialog()
         setSubmitting(false)
       }

       const mappedItem = mapUserWorkScheduleFormToUserWorkScheduleItem(initialItem, values)
       await updateUserWorkScheduleAsync(mappedItem).finally(() => {
         closeCreateUserWorkScheduleDialog()
         setSubmitting(false)
       })

       //        editRouteAsync(editUserWorkScheduleModalData!, values.routeName, values.routeColor)
 */
    },
    [
      closeCreateUserWorkScheduleDialog,
      createUserWorkScheduleAsync,
      unplannedRouteId,
    ]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        startDate: yup.date(),
        endDate: yup.date(),
        scheduledStart: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        scheduledEnd: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        userId: yup.number(),
        user: yup.object().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        commonShift: yup.object(),
      }),
    [f]
  )

  const name: Name<UserWorkScheduleForm> = React.useMemo(
    () => ({
      startDate: 'startDate',
      endDate: 'endDate',
      scheduledStart: 'scheduledStart',
      scheduledEnd: 'scheduledEnd',
      userId: 'userId',
      user: 'user',
      commonShift: 'commonShift'
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useCreateUserWorkScheduleModalFormData
