import format from 'utilities/format'
import { Typography } from '@mui/material'
import { Colors as ThemeColors } from 'styles/colors'
import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import React from 'react'
import styled, { css } from 'styled-components'
import ErrorBoundary from 'components/error-boundary'
import { useDrag, UseDragProps } from 'components/planning-grid/utilities/drag-and-drop/drag-and-drop'
import { SessionProps } from 'components/planning-grid/planning-grid-types'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { mapPlannedRouteUserWorkSchedulePlanningViewItemToMetaData, UserWorkScheduleMetaData } from '../../schedule-view'
import produce from 'immer'
import { PlanningPageContext } from 'pages/planning/planning-page-context'

interface IPlannedRouteWorkSchedulesContainer {
  route: IPlannedRoutesActivitiesPlanningViewItem,
  maxPlannedRouteUserWorkSchedules: number
}

interface IPlannedRouteUserWorkScheduleProps {
  meta: UserWorkScheduleMetaData
  onUserWorkSchedulePlan?: (meta: UserWorkScheduleMetaData) => void
  onDragStart?: (meta: UserWorkScheduleMetaData) => void
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    zIndex: 1000000,
    maxWidth: 'none',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.438), 0 1px 2px rgba(0, 0, 0, 0.65)',
  },
}));

const PlannedRouteUserWorkSchedule: React.FC<IPlannedRouteUserWorkScheduleProps> = ({ meta, onUserWorkSchedulePlan, onDragStart }) => {
  const {
    id,
    userId,
    plannedRouteId,
    scheduledStart,
    scheduledEnd,
    isPlanned,
    substituteUserId,
    userFullName,
    substituteUserFullName,
  } = meta

  const { openEditUserWorkScheduleDialog, setNonCompliantRoutesForPlannedUserWorkSchedule, setNonCompliantRoutesandTimesForPlannedActivity } = PlanningPageContext.useActions()
  const { calendarStartDate, nonCompliantRoutesandTimesForPlannedActivity } = PlanningPageContext.useState()

  let itemBackgroundColor = ThemeColors.TeniloGreen;
  // If there's a substitute let's change the color...
  if (substituteUserId) {
    itemBackgroundColor = ThemeColors.TeniloBrown;
  }

  const isFromPreviousDate = scheduledStart < calendarStartDate

  if (!scheduledStart) {
    throw Error('No scheduled start date received')
  }
  if (!scheduledEnd) {
    throw Error('No scheduled end date received')
  }

  const fromFullTimeString = format(scheduledStart, 'HH:mm')
  const toFullTimeString = format(scheduledEnd, 'HH:mm')

  const soughtDate = React.useMemo(() => new Date(+scheduledStart + (+scheduledEnd - +scheduledStart) / 2), [scheduledEnd, scheduledStart])
  const lengthInMinutes = (scheduledEnd.getTime() - scheduledStart.getTime()) / (1000 * 60)
  const item = React.useMemo(() => ({ lengthInMinutes, id, soughtDate, meta }), [id, lengthInMinutes, meta, soughtDate])

  let workScheduleInfoString
  if (substituteUserId) {
    workScheduleInfoString = `${fromFullTimeString} - ${toFullTimeString} (${substituteUserFullName})`
  } else {
    workScheduleInfoString = `${fromFullTimeString} - ${toFullTimeString} (${userFullName})`
  }

  /*let nonCompliant = false

  nonCompliantRoutesandTimesForPlannedActivity?.forEach(ncrt => {
    ncrt.nonCompliantPlannedRouteWorkScheduleIds.forEach(ncwsId => {
      if (ncwsId === id) {
        nonCompliant = true
      }
    })
  })*/

  const nonCompliant = React.useMemo(() => {
    let nc = false

    nonCompliantRoutesandTimesForPlannedActivity?.forEach(ncrt => {
      ncrt.nonCompliantPlannedRouteWorkScheduleIds.forEach(ncwsId => {
        if (ncwsId === id) {
          nc = true
        }
      })
    })
    return nc

  }, [nonCompliantRoutesandTimesForPlannedActivity])


  const handleStart: UseDragProps['onStart'] = React.useCallback(
    ({ item: draggingItem }) => {
      const { meta: draggingMeta } = draggingItem as SessionProps<UserWorkScheduleMetaData>
      onDragStart?.(draggingMeta)
    },
    []
  )

  const handleEnd: UseDragProps['onEnd'] = React.useCallback(
    ({ item: draggedItem }) => {
      setNonCompliantRoutesForPlannedUserWorkSchedule(null)
      setNonCompliantRoutesandTimesForPlannedActivity(null)
      if (onUserWorkSchedulePlan) {
        const { meta: draggedMeta } = draggedItem as SessionProps<UserWorkScheduleMetaData>
        const updatedMeta = produce(draggedMeta, draft => {
          // We should not set isPlanned here. This is a problem when dropping outside expected areas.
          // Reload takes care of it for now. Leaving it in as a reminder.
          //draft.isPlanned = true
        })
        onUserWorkSchedulePlan(updatedMeta)
      }
      return undefined
    },
    [onUserWorkSchedulePlan]
  )

  const editUserWorkSchedule = React.useCallback(
    (props: any) => {
      openEditUserWorkScheduleDialog(true, props.event.id)
    },
    [openEditUserWorkScheduleDialog]
  )

  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(event => {
    editUserWorkSchedule({
      event: { id }
    })
  }, [editUserWorkSchedule])

  const handleCanDrag = React.useCallback(() => {
    return true //!isPlanned
  }, [isPlanned])

  // eslint-disable-next-line
  const [_, drag, { isDragging }] = useDrag('SESSION_ADD', item, {
    onStart: handleStart,
    onEnd: handleEnd,
    canDrag: handleCanDrag,
  })

  const textColor = isDragging ? 'rgba(0, 0, 0, 0.82)' : 'white'

  return (
    <HtmlTooltip followCursor
      title={
        <>
          <Typography data-matomo-mask variant="subtitle2" fontWeight={400}>{workScheduleInfoString}</Typography>
        </>
      }
    >
      <StyledPlannedRouteWorkScheduleItem
        key={meta.id}
        ref={drag}
        isDragging={isDragging}
        backgroundColor={itemBackgroundColor}
        textColor={textColor} onClick={handleOnClick} isFromPreviousDate={isFromPreviousDate} nonCompliant={nonCompliant}>
        <Typography
          data-matomo-mask
          variant="subtitle2"
          style={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: textColor,
            height: '24px',
            overflowY: 'hidden'
          }}>
          {workScheduleInfoString}
        </Typography>
      </StyledPlannedRouteWorkScheduleItem>
    </HtmlTooltip>
  )

}

const PlannedRouteWorkSchedulesContainer: React.FC<IPlannedRouteWorkSchedulesContainer> = ({ route, maxPlannedRouteUserWorkSchedules }) => {

  const { fetchNonCompliantRoutesForPlannedUserWorkScheduleAsync } = PlanningPageContext.useActions()

  const handleDragStart = React.useCallback(
    (meta: UserWorkScheduleMetaData) => {
      void fetchNonCompliantRoutesForPlannedUserWorkScheduleAsync(meta.id)
    }, []
  )

  const plannedRouteHeight = maxPlannedRouteUserWorkSchedules * 27 + 5

  return (
    <StyledPlannedRouteWorkSchedules
      height={plannedRouteHeight}
    >
      {route.plannedRoutes?.sort((a, b) => a.calendarDay.getTime() - b.calendarDay.getTime()).map(plannedRoute => {
        return plannedRoute.plannedRouteUserWorkSchedules?.sort((a, b) => a.scheduledStart.getTime() - b.scheduledStart.getTime()).map(pruws => {
          const meta = mapPlannedRouteUserWorkSchedulePlanningViewItemToMetaData(pruws)
          return (
            <ErrorBoundary key={`e-${pruws.id}`}>
              <PlannedRouteUserWorkSchedule
                key={pruws.id}
                meta={meta}
                onDragStart={handleDragStart}
              />
            </ErrorBoundary>
          )
        })
      })}
    </StyledPlannedRouteWorkSchedules>
  )
}

const StyledPlannedRouteWorkSchedules = styled.div<{ height: number }>`
    position: relative;
    z-index: 1000;
    pointer-events: none;
    opacity: 1;
    height: ${props => props.height}px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    overflow-x: hidden;
    width: 100%;
  `

type StyledPlannedRouteWorkScheduleItemProps = Readonly<{
  isDragging: boolean,
  backgroundColor: string,
  textColor: string,
  isFromPreviousDate: boolean,
  nonCompliant: boolean,
}>

const StyledPlannedRouteWorkScheduleItem = styled.div<StyledPlannedRouteWorkScheduleItemProps>`
  flex: 1;
  color: white;
  overflow: hidden;
  pointer-events: auto;
  height: 24px;
  padding: 0 5px;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  margin-bottom: 2px;
  width: 90%;
  opacity: ${props => (props.isFromPreviousDate ? 0.5 : 1)};

  ${props =>
    props.isDragging
      ? `
        border: 2px dashed rgb(179, 179, 179);
        color: rgb(179, 179, 179);
      `
      : `
        border: ${props.nonCompliant ? 'rgba(255, 0, 0, 0.82) solid 3px' : 'rgba(0, 0, 0, 0.12) solid 1px'};
        cursor: pointer;
        background-color: ${props.backgroundColor};
      `
  };
`

export default PlannedRouteWorkSchedulesContainer
