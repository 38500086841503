import { TextField } from '@mui/material'
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import Autocomplete from 'components/inputs/autocomplete'
import { AutocompleteContainerProps } from 'components/inputs/autocomplete/autocomplete-container'
import { useFormatMessage } from 'localization'
import React from 'react'

type SearchAreaInputProps = Readonly<
  Omit<AutocompleteContainerProps, 'renderInput'> & {
    value?: any
    error?: boolean
    helperText?: React.ReactNode
    label?: string
    fullWidth?: boolean
    minWidth?: number
    noValue?: boolean
  }
>

const SearchAreaInputView: React.FC<SearchAreaInputProps> = ({ name, value, error, helperText, label, fullWidth = false, minWidth = 0, noValue, ...props }) => {
  const f = useFormatMessage()

  const autocompleteRenderInput = React.useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        name={name}
        label={label ? label : f('ADMINISTER_SEARCH_AREA_COMPONENT_LABEL_DEFAULT')}
        variant="filled"
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        style={{ minWidth }}
      />
    ),
    [name, label, f, fullWidth, error, helperText, minWidth]
  )

  const renderTags = React.useCallback(() => null, [])

  return <Autocomplete {...props} name={name} renderInput={autocompleteRenderInput} {...(noValue ? { renderTags } : {})} />
}

export default SearchAreaInputView
