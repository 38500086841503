import { Box, Tab, useTheme } from '@mui/material'
import { IUserAreaItem, RoleEnum } from 'api/tillit.api-client'
import Page, { PageHeader } from 'components/page'
import Spinner from 'components/spinner'
import PageRoute from 'config/page-routes'
import { useFormatMessage } from 'localization'
import React from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Authentication from 'components/authentication'
import { getVisibleAreaData } from 'utilities/area-helper'
import { rootUser } from 'utilities/use-user-areas'
import { NotificationContainer } from 'components/notification'
import isEmpty from 'lodash/isEmpty'
import useAuthorization from 'utilities/use-authorization'
import { TableLarge } from 'mdi-material-ui'
import ContainedButton from 'components/inputs/contained-button'
import makeStyles from '@mui/styles/makeStyles'
import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import { addDays } from 'date-fns'
import Area from './components/area'
import DayPicker from './components/day-picker'
import { DevicePlanningPage } from './components/device-planning-page'
import { PlanningPageContext } from './planning-page-context'
import { StyledDayPickerBox, StyledTabs } from './planning-page-styles'
import ConfirmUnplanPenMarkedModal from './components/confirm-unplan-penmarked-modal'
import ConfirmUnplanFutureChangesModal from './components/confirm-unplan-future-changes-modal'
import ConfirmUnplanPenMarkedAndFutureChangesModal from './components/confirm-unplan-penmarked-and-future-changes-modal'
import PlanningMainMenuMobile from './components/planning-main-menu-mobile'
import PlanningRoutesMenuMobile from './components/planning-routes-menu-mobile'
import { getOldTokenData, setTokenData } from 'api/token-data'

const useStyles = makeStyles(theme => {
  return ({
    leftMargin: {
      marginLeft: theme.spacing(2),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    'tenilo-planning-page': {
      display: 'block',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        '& .tenilo-main': {
          marginLeft: 0,
          width: '100%',
          paddingTop: theme.spacing(1.25),
          paddingLeft: theme.spacing(2.5),
          paddingRight: theme.spacing(2.5),
          paddingBottom: '0px',
          height: 'calc(100% - 60px)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        },
        '& .tenilo-banner': {
          position: 'relative !important',
          height: '60px !important',
          '& .tenilo-banner_tours': {
            display: 'flex'
          }
        }
      }
    }
  }
  )
})

type TabPanelProps = Readonly<{
  index: number
}>

type PlanningPageViewProps = Readonly<{}>

export const TabPanel: React.FC<TabPanelProps> = ({ children, index }) => {
  return (
    <>
      <Box role="tabpanel" id={`tabpanel-${index}`} display={'flex'} flex={1} flexDirection="column">
        {children}
      </Box>
    </>
  )
}

export const a11yProps = (index: number) => {
  return {
    id: `group-tab-${index}`,
    'aria-controls': `group-tabpanel-${index}`,
  }
}

const PlanningPageView: React.FC<PlanningPageViewProps> = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const classes = useStyles()
  const { areas, planningId, isLoading, openMobileTourMenu, routes } = PlanningPageContext.useState()
  const { toggleOpenMobileTourMenu, toggleRouteVisibility, hideAllRoutes, showAllRoutes } = PlanningPageContext.useActions()

  const visibleAreaIds = React.useMemo(()=>{
    return getVisibleAreaData().map(area => area.areaId)
  },[])

  const { user: currentUser, usingCalendarFromDevice } = Authentication.useAuthenticatedState()
  const { getAdminAreas } = useAuthorization()
  const navigate = useNavigate()
  const { unsetUser, setPlanningId, setUsingCalendarFromDevice } = Authentication.useActions()

  const visibleAreas = React.useMemo(() => {
    let planningAreas: IUserAreaItem[] = areas
    if (!rootUser(currentUser)) {
      const adminAreas = getAdminAreas([RoleEnum.Planner])
      planningAreas = planningAreas.filter(area => adminAreas?.some(adminArea => adminArea.areaId === area.areaId))
    }
    if (!isEmpty(visibleAreaIds) && areas.length > 1) {
      planningAreas = planningAreas.filter(area => visibleAreaIds.includes(area.areaId))
    }
    return planningAreas
  }, [visibleAreaIds, areas, currentUser])

  const { date: urlDate, type } = useParams() as { date: string, type: string }

  const handleTabClick = (areaId: number) => () => {
    navigate(
      PageRoute.Planning.path
        .replace(':areaId', areaId.toString())
        .replace(':date', urlDate)
        .replace(':type', type)
        .replace(':usingCalendarFromDevice', 'false')
    )
  }

  const linkToDailyActivities = React.useCallback(() => () => {
    unsetUser()
    setPlanningId(null) // areaId
    setUsingCalendarFromDevice(false)

    const oldTokenData = getOldTokenData()
    if (oldTokenData) {
      setTokenData(oldTokenData)
    }

    navigate(PageRoute.DailyActivities.path)
  }, [])

  const toggleRoute = React.useCallback((route: IPlannedRoutesActivitiesPlanningViewItem) => {
    toggleRouteVisibility(route.id)
  }, [toggleRouteVisibility])

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const updateScreenWidth = () => {
    if (window.innerWidth > 900) {
      showAllRoutes()
    }
    setScreenWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => window.removeEventListener("resize", updateScreenWidth);
  });

  return (
    <NotificationContainer>
      <Page
        noPadding
        noMargin={usingCalendarFromDevice}
        className={classes["tenilo-planning-page"]}
        toggleOpenMobileTourMenu={toggleOpenMobileTourMenu}>

        {screenWidth < 900 && (
          <PlanningRoutesMenuMobile
            openMobileTourMenu={openMobileTourMenu}
            routes={routes}
            hideAllRoutes={hideAllRoutes}
            showAllRoutes={showAllRoutes}
            toggleOpenMobileTourMenu={toggleOpenMobileTourMenu}
            toggleRoute={toggleRoute}
          />
        )}
        <Box display="flex" flexDirection="row" height="100%">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginLeft: '0px',
            }}>
            <Box mx={5} mt={5} position="relative">
              <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
                <PageHeader title={f('PLANNING_PAGE_VIEW_HEAD_TITLE')} description={f('PLANNING_PAGE_VIEW_HEAD_SUBTITLE')} />
              </Box>
              {screenWidth >= 900 && (
                <StyledDayPickerBox>
                  {usingCalendarFromDevice ? (
                    <DayPicker maxDate={addDays(new Date(), 14)} />
                  ) : (
                    <DayPicker />
                  )}
                </StyledDayPickerBox>
              )}
              {isLoading && (
                <div style={{ position: 'relative' }}>
                  <Spinner size={64} color={theme.palette.primary.main} />
                </div>
              )}
            </Box>
            {usingCalendarFromDevice ? (
              <>
                {screenWidth < 900 && (
                  <PlanningMainMenuMobile
                    locked={false}
                    user={currentUser}
                    handleLockClicked={linkToDailyActivities()}
                    openDailyActivities={linkToDailyActivities()}
                  />
                )
                }
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
                    <ContainedButton sx={{ height: '36.5px', alignSelf: 'middle' }} onClick={linkToDailyActivities()}>
                      <TableLarge />{f('PLANNING_PAGE_VIEW_BUTTON_OPEN_DAILYACTIVITIES')}
                    </ContainedButton>
                  </Box>
                  <Area />
                </Box>
              </>
            ) : (
              type === 'areas' ? (
                <Box display="flex" flexDirection="column" flex={1}>
                  <StyledTabs value={visibleAreas.findIndex(a => a.areaId === planningId)} variant="scrollable" scrollButtons={false}>
                    {visibleAreas.map(area => (
                      <Tab sx={{ zIndex: 1 }} key={area.areaId} label={area.areaName} {...a11yProps(area.areaId)} onClick={handleTabClick(area.areaId)} />
                    ))}
                  </StyledTabs>
                  <TabPanel index={visibleAreas.findIndex(a => a.areaId === planningId)}>
                    <Area />
                  </TabPanel>
                </Box>
              ) : (
                <DevicePlanningPage />
              )
            )}
          </div>
        </Box>
        <ConfirmUnplanPenMarkedModal />
        <ConfirmUnplanFutureChangesModal />
        <ConfirmUnplanPenMarkedAndFutureChangesModal />
      </Page>
    </NotificationContainer>
  )
}

export default PlanningPageView
