import React from 'react'

import {
  AuthenticationClient,
  FederatedLoginResponse,
  GrandIdRequestModel,
  JwtTokenGenerationResultItem,
  LoginAppModel,
  LoginWithPinAppModel,
  RequestorValidityItem,
  RevokeRefreshTokenAppModel,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import { clearTokenData, getTokenData, hasTokenData, setTokenData } from './token-data'

export class AuthenticationApi {
  get isAuthenticated() {
    const data = hasTokenData()
    return data
  }
  private readonly client: AuthenticationClient
  private readonly abortController = new AbortController()

  constructor() {
    this.client = new AuthenticationClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public generateJwtTokenFromPin = (userId: number, pin: string, deviceGuid: string): Promise<JwtTokenGenerationResultItem> => {
    const model = new LoginWithPinAppModel({ userId, pin, deviceGuid })
    return this.client.generateJwtTokenFromPin(model).then(resultItem => {
      if (resultItem === null) {
        return Promise.reject('No jwt token received from backend.')
      }
      setTokenData(resultItem)
      return Promise.resolve(resultItem)
    })
  }

  public revokeRefreshToken = (): Promise<void> => {
    const tokenData = getTokenData()
    if (tokenData === null) {
      return Promise.resolve()
    }
    const refreshToken = tokenData.refreshToken
    const model = new RevokeRefreshTokenAppModel({ refreshToken })
    return this.client
      .revokeRefreshToken(model)
      .then(_ => Promise.resolve())
      .finally(clearTokenData)
  }

  public generateJwtTokenFromLogin = (email: string, password: string): Promise<void> => {
    const model = new LoginAppModel({ email, password })
    return this.client.generateJwtTokenFromLogin(model).then(resultItem => {
      if (resultItem === null) {
        return Promise.reject('No jwt token received from backend.')
      }
      setTokenData(resultItem)
      return Promise.resolve()
    })
  }

  public forgotPassword = (email: string): Promise<void> => {
    if (email == null) {
      return Promise.reject('No email given')
    }
    return this.client
      .forgotPassword(email)
      .then(() => Promise.resolve())
      .catch(() => Promise.reject('Unable to reset password'))
  }

  /* GrandID */
  public initiateGidsSaml = (organization: string): Promise<FederatedLoginResponse> => {
    if (organization == null) {
      return Promise.reject('No organization given')
    }
    return this.client
      .initiateGidsSaml(organization)
      .then(result => Promise.resolve(result))
      .catch(() => Promise.reject('Unable to initiate Gids Saml'))
  }

  public initiateGidsFrejaeId = (organization: string): Promise<FederatedLoginResponse> => {
    if (organization == null) {
      return Promise.reject('No organization given')
    }
    return this.client
      .initiateGidsFrejaeId(organization)
      .then(result => Promise.resolve(result))
      .catch(() => Promise.reject('Unable to initiate Gids Freja eID'))
  }

  public generateJwtTokenFromGidsSession = (model: GrandIdRequestModel): Promise<void> => {
    return this.client.generateJwtTokenFromGidsSession(model).then(resultItem => {
      if (resultItem === null) {
        return Promise.reject('No jwt token received from backend.')
      }
      setTokenData(resultItem)
      return Promise.resolve()
    })
  }

  public logOutFromGids = (model: GrandIdRequestModel): Promise<void> => {
    return this.client.logutFromGids(model).then(() => {
      return Promise.resolve()
    })
  }

  /* Org IP check */
  public isRequestorValid = async (deviceGuid: string): Promise<boolean> =>
    this.client
      .isRequestorValid(deviceGuid)
      .then(exists => (exists === null ? Promise.reject(`Unexpected response from isRequestorValid for device guid ${deviceGuid}.`) : Promise.resolve(exists)))

  public getRequestorKioskTokenByGuid = async (deviceGuid: string) =>
    this.client
      .getRequestorKioskTokenByGuid(deviceGuid)
      .then(tokens => (tokens === null ? Promise.reject(`No tokens received from backend`) : Promise.resolve(tokens)))

  public getRequestorValidity = async (deviceGuid: string): Promise<RequestorValidityItem> =>
    this.client
      .getRequestorValidity(deviceGuid)
      .then(result =>
        result === null ? Promise.reject(`Unexpected response from getRequestorValidity for device guid ${deviceGuid}.`) : Promise.resolve(result)
      )
}

const useAuthenticationApi = () => React.useMemo(() => new AuthenticationApi(), [])
export default useAuthenticationApi

export const useAuthenticationApiNoMemo = () => new AuthenticationApi()
