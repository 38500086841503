import React from 'react'
import { SendEmailToUsersItem } from 'api/tillit.api-client'
import { OnSubmitFunction } from 'types'
import { UsersPageContext } from 'pages/users/users-page-context'
import { SendEmailToUsersModalContext } from '../send-email-to-users-modal-context'



const useSendEmailToUsersFormData = (userIds: [number]) => {
  const { sendEmailToUsersAsync, closeSendEmailToUsersModal } = SendEmailToUsersModalContext.useActions()
  const { fetchUsersAsync } = UsersPageContext.useActions()

  const initialValues = React.useMemo(() => {
    return userIds.map(uid => {
      return new SendEmailToUsersItem({
        userId: uid,
        sendWelcomeEmail: false,
        sendResetPassword: false,
        sendToPrincipal: false
      })
    })
  },[userIds])

  const onSubmit: OnSubmitFunction<SendEmailToUsersItem[]> = React.useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true)
      return sendEmailToUsersAsync(values)
      .then(() => {
        return fetchUsersAsync()
      })
      .then(() => {
        setSubmitting(false)
        closeSendEmailToUsersModal()
      })
      .catch((err) => console.log(err))
    },
    []
  )

  return {
    initialValues,
    onSubmit
  }
}

export default useSendEmailToUsersFormData
