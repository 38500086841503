import { IMovePlannedActivityRowSeriesItem, IPlannedActivityRowItem, IPlannedActivityRowPlanningViewItem, RecurrenceEnum, SeverityLevelEnum } from 'api/tillit.api-client'

export interface IPlannedActivityRowPlanningViewItemWithTime extends IPlannedActivityRowPlanningViewItem {
  startTime: Date
  endTime: Date
  visitLengthInMinutes: number
  newRouteId?: number
}

/* This helper convert start time and end time from scheduler to model defined (startHour, startMinute, endHour, endMinute) */
export const movePlannedActivityRowDataHelper = (plannedActivityRow: IPlannedActivityRowPlanningViewItemWithTime): IMovePlannedActivityRowSeriesItem => ({
  plannedActivityRowId: plannedActivityRow.id,
  newRouteId: plannedActivityRow.newRouteId,
  forceOverwriteOfFutureChanges: false,
  startTime: plannedActivityRow.startTime,
  endTime: plannedActivityRow.endTime,
  includePenMarked: false,
  implementChangesUntil: false,
  implementChangesUntilDate: undefined,
})

export const plannedActivityRowDataHelper = (plannedActivityRow: IPlannedActivityRowPlanningViewItemWithTime): IPlannedActivityRowItem => ({
  id: plannedActivityRow.id,
  activityRowId: plannedActivityRow.activityRowId,
  comment: plannedActivityRow.comment,
  activityRow: undefined,
  plannedRoute: undefined,
  plannedActivityStatusId: plannedActivityRow.plannedActivityStatusId,
  plannedRouteId: plannedActivityRow.plannedRouteId,
  detachedFromSeries: plannedActivityRow.detachedFromSeries,
  activity_RecurrenceId: RecurrenceEnum.Daily, // Will not be used by backend
  activity_RecurrenceInterval: plannedActivityRow.plannedRouteId, // Will not be used by backend
  activity_StartDate: plannedActivityRow.startTime, // Will not be used by backend
  signing_LatestSigningDate: new Date(), // Will not be used by backend
  startTime: plannedActivityRow.startTime,
  endTime: plannedActivityRow.endTime,
  activity_AlertFromStartInMinutes: 0, // Will not be used by backend
  isPlanned: false, // Will not be used by backend
  activity_ShowColorOnDevice: false, // Will not be used by backend
  useTemplateActivityDescription: false, // Will not be used by backend
  isAreaTemplateActivity: false, // Will not be used by backend
  organization_AllowCommentOnSigning: false, // Will not be used by backend
  organization_AllowSigningWhenDelegationsIsNotFulfilled: false, // Will not be used by backend
  activity_SeverityLevelId: SeverityLevelEnum.Normal, // Will not be used by backend
  useParticipantList: false, // Will not be used by backend
  createdOn: plannedActivityRow.createdOn,
})
