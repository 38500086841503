import { MenuItem } from '@mui/material'
import ContainedButton from 'components/inputs/contained-button'
import DatePicker from 'components/inputs/date-picker'
import FilledSelect from 'components/inputs/filled-select'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { InvoiceFormContext } from './invoice-form-context'
import { useInvoiceForm } from './utilities/use-invoice-form'

export const InvoiceFormView: React.SFC = () => {
  const { name, ...formikProps } = useInvoiceForm()
  const { organizations } = InvoiceFormContext.useState()

  const f = useFormatMessage()
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, status, errors, touched, setFieldValue, values, ...rest }) => {

        const handleChangeOrganization = (event: any, child: any) => {
          setFieldValue('organizationId', event.target.value)
        }

        return (
          <Form translate="yes">
            <ModalSection title={f('INVOICE_FORM_CONTAINER_TITLE')} subtitle={f('INVOICE_FORM_CONTAINER_SUBTITLE')} responsive>
              <ModalSectionRow responsive>
                <FilledSelect
                  disabled={isSubmitting}
                  error={!!errors.organizationId && !!touched.organizationId}
                  name={name.organizationId}
                  onChange={handleChangeOrganization}
                  value={values.organizationId}
                  label={f('INVOICE_FORM_CONTAINER_ORGANIZATIONID_SELECT_LABEL')}>
                  {organizations.map((organization, index) => (
                    <MenuItem key={index} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  ))}
                </FilledSelect>
              </ModalSectionRow>
              <ModalSectionRow responsive>
                <DatePicker
                  disabled={isSubmitting}
                  name={name.fromDate}
                  label={f('INVOICE_FORM_CONTAINER_FROMDATE_DATEPICKER_LABEL')}
                  format="yyyy-MM-dd"
                />
                <DatePicker
                  disabled={isSubmitting}
                  name={name.toDate}
                  label={f('INVOICE_FORM_CONTAINER_TODATE_DATEPICKER_LABEL')}
                  format="yyyy-MM-dd"
                />
              </ModalSectionRow>
              <ModalSectionRow responsive>
                <ContainedButton type="submit" color="secondary" showProgress={isSubmitting}>
                  {f('INVOICE_FORM_CONTAINER_SUBMIT_BUTTON_TEXT')}
                </ContainedButton>
                {!!status && <p>{status.error}</p>}
              </ModalSectionRow>
            </ModalSection>
          </Form>
        )
      }}
    </Formik>
  )
}
