import React from 'react'
import {
  GeneralKpiClient,
  KeyPerformanceIndicatorItem
} from 'api/tenilo.dashboard.api-client'
import { DASHBOARD_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class KpiApi {
  private readonly abortController = new AbortController()
  private readonly client: GeneralKpiClient

  public constructor() {
    this.client = new GeneralKpiClient(DASHBOARD_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getSigningStatusesKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .getSigningStatusesKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )

  public getNumDelayedActivitiesKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .getNumDelayedActivitiesKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )

  public getNumUnsignedActivitiesKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .getNumUnsignedActivitiesKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )

  public getPlannedActivityTimeKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .getPlannedActivityTimeKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )

  public getFutureUnplannedActivitiesKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .getFutureUnplannedActivitiesKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )

  public getActivitiesAboutToEndKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .getActivitiesAboutToEndKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )


}

const useKpiApi = () => React.useMemo(() => new KpiApi(), [])

export default useKpiApi
