import React from 'react'
import { Box, IconButton, ListItem, Typography, Tooltip } from '@mui/material'
import { CloseCircle, AccountMinus as DeclineIcon, AccountPlus as AcceptIcon } from 'mdi-material-ui'
import { useFormatMessage } from 'localization'
import styled, { css } from 'styled-components'
import { FieldArrayRenderProps } from 'formik'
import { ParticipantItem } from '../../../../participant-item-type'

type Props = Readonly<{
  participant: ParticipantItem
  key: string
  index: number
  enableDecline?: boolean
} & FieldArrayRenderProps>

const ParticipantListItemContainer: React.FC<Props> = ({ participant, key, index, enableDecline, ...FieldArrayProps }) => {
  const f = useFormatMessage()

  const handleRemoveClick = React.useCallback(() => {
    FieldArrayProps.remove(index)
  }, [FieldArrayProps, index])

  const handleDeclineClick = React.useCallback(() => {
    participant.declined = true
    FieldArrayProps.replace(index, participant)
  }, [FieldArrayProps, index, participant])

  const handleAcceptClick = React.useCallback(() => {
    participant.declined = false
    FieldArrayProps.replace(index, participant)
  }, [FieldArrayProps, index, participant])

  return (
    <StyledBox border={1} borderColor="grey.300" borderRadius="5px" marginBottom={1} width="auto" key={key}>
      <ListItem>
        <Box display={'flex'}>
          <Typography data-matomo-mask>{participant.customerFullName}</Typography>
        </Box>
        <Box ml={'auto'} />
        {enableDecline && (
          <>
            {participant.declined ? (
              <>
                <Typography color="error">{f('ACTIVITY_PARTICIPANT_DECLINED')}</Typography>
                <Tooltip
                  title={f('ACTIVITY_PARTICIPANT_ACCEPT_BUTTON_TOOLTIP', { name: participant.customerFullName, })}
                  aria-label={f('ACTIVITY_PARTICIPANT_ACCEPT_BUTTON_TOOLTIP', { name: participant.customerFullName })}>
                  <IconButton onClick={handleAcceptClick} size="large">
                    <AcceptIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Typography color="green">{f('ACTIVITY_PARTICIPANT_PARTICIPATED')}</Typography>
                <Tooltip
                  title={f('ACTIVITY_PARTICIPANT_DECLINE_BUTTON_TOOLTIP', { name: participant.customerFullName, })}
                  aria-label={f('ACTIVITY_PARTICIPANT_DECLINE_BUTTON_TOOLTIP', { name: participant.customerFullName })}>
                  <IconButton onClick={handleDeclineClick} size="large">
                    <DeclineIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        )}
        <Tooltip
          title={f('ACTIVITY_PARTICIPANT_DELETE_BUTTON_TOOLTIP', { name: participant.customerFullName, })}
          aria-label={f('ACTIVITY_PARTICIPANT_DELETE_BUTTON_TOOLTIP', { name: participant.customerFullName })}>
          <IconButton onClick={handleRemoveClick} size="large">
            <CloseCircle />
          </IconButton>
        </Tooltip>
      </ListItem>
    </StyledBox >
  )
}

const StyledBox = styled(Box)(
  ({ }) => css`
        &:hover {
          background-color: #F0F0F0};
        cursor: pointer;
    }
        `
)

export default ParticipantListItemContainer
