import React from 'react'
import { useFormatMessage } from 'localization'
import { isSearchValueValid } from './filter-helper'
import { AreaListItem } from 'api/tenilo.support.api-client'

export const filterAreas = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: AreaListItem[]
): AreaListItem[] => {
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const searchText = `${item.name} ${item.organizationParentsName} ${item.organizationName}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as AreaListItem[]
  }
}

