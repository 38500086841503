import styled from 'styled-components'
import Colors from 'styles/colors'
import DailyActivitySubGroupItemContainer from './daily-activity-subgroup-item-container'

const DailyActivitySubGroupItemStyle = styled(DailyActivitySubGroupItemContainer)`
  width: 132px;
  margin-left: 30px;
  padding: 0px;
  height: 50px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  ${props => (props.isActive ? `color: ${Colors.White};` : '')}
  background-color: ${props => (props.isActive ? props.color : 'white')};
  box-sizing: border-box;
  * {
    box-sizing: border-box
  };

  .right-pane{
    height: 100%;
    width: 24px;
    float: right;
    background-color: ${props => props.color}
  };
  .left-pane {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  ${props => [props.theme.breakpoints.down('md')]}{
    width: 70%;
    max-width: 350px;
    height: 35px;
    margin: 10px auto;
  }
`

export default DailyActivitySubGroupItemStyle
