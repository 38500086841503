import Page from 'components/page'
import React from 'react'
import { OrganizationContext } from './organization-context'
import OrganizationStyles from './organization-styles'

const OrganizationView: React.SFC = () => {
  return (
    <OrganizationContext.Provider>
      <Page noPadding={true}>
        <OrganizationStyles />
      </Page>
    </OrganizationContext.Provider>
  )
}

export default OrganizationView
