import React from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { IPlannedActivityRowItem } from 'api/tillit.api-client'
import CenterModal from 'components/center-modal'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalHeader from 'components/modal-header'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import BigCheckboxWithoutForm from '../big-checkbox-without-form'

interface IListingModalContainerProps {
  open: boolean
  title: string
  subtitle?: string
  plannedActivity: IPlannedActivityRowItem | null
  onSaveClick: (comment: string, givenAmount?: number | undefined) => void
  onClose?: () => void
  withComment: boolean
}

const GivenAmountButtonsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap:2px;
`

const SingleActivityPartialModalContainer: React.FC<IListingModalContainerProps> = ({ open, title, subtitle, plannedActivity, onSaveClick, onClose, withComment }) => {
  const f = useFormatMessage()


  const [givenAmount, setGivenAmount] = React.useState<number | undefined>(undefined)
  const [comment, setComment] = React.useState('')

  React.useEffect(() => {
    if (open) {
      setGivenAmount(0)
      setComment('')
    }
  }, [open])

  const handleSaveClick = React.useCallback(() => {
    if (onSaveClick !== undefined) {
      onSaveClick(comment, givenAmount)
    }
    if (onClose !== undefined) {
      onClose()
    }
  }, [givenAmount, onSaveClick, onClose, comment])

  const expectedAmount = plannedActivity?.activity_ExpectedAmount

  let abbreviation = ''

  if (plannedActivity?.templateActivityUnitAbbreviation !== undefined) {
    abbreviation = plannedActivity?.templateActivityUnitAbbreviation
  }

  const calculatePercentageForButtons = (percentageValue: number) => {
    if (expectedAmount === undefined) {
      return undefined
    }
    return Math.round(expectedAmount * percentageValue)
  }

  const handleGivenAmountChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setGivenAmount(parseFloat(event.target.value))
  }, [])

  const handleSelectedGivenAmountChange = React.useCallback((selectedAmount: number) => {
    setGivenAmount(selectedAmount)
  }, [setGivenAmount])
  const handleCommentChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComment(event.target.value)
  }, [])

  return (
    <CenterModal open={open} onClose={onClose} responsive>
      <ModalHeader data-matomo-mask title={title} subtitle={subtitle} responsive />
      <Box sx={(theme) => ({
        px: 4,
        [theme.breakpoints.down('md')]: {
          px: 1
        }
      })}>
        {withComment && (
          <ModalSectionRow responsive>
            <FilledTextField
              data-matomo-mask
              fullWidth={true}
              label={f('LISTING_MODAL_LISTING_FIELD_LABEL')}
              multiline={true}
              rows="4"
              margin="normal"
              value={comment}
              onChange={handleCommentChange}
            />
          </ModalSectionRow>
        )}
        <ModalSectionRow responsive>
          <FilledTextField
            fullWidth={true}
            label={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_INFO_TEXT', { expected: expectedAmount?.toString(), abbreviation })}
            margin="normal"
            type="number"
            value={givenAmount}
            onChange={handleGivenAmountChange}
          />
        </ModalSectionRow>
        {expectedAmount && (<ModalSectionRow responsive>
          <GivenAmountButtonsWrapper>
            <BigCheckboxWithoutForm
              label={calculatePercentageForButtons(.2)?.toString() + abbreviation}
              value={givenAmount}
              selectValue={calculatePercentageForButtons(.2)}
              onSelectedValueChanged={handleSelectedGivenAmountChange}
            />
            <BigCheckboxWithoutForm
              label={calculatePercentageForButtons(.4)?.toString() + abbreviation}
              value={givenAmount}
              selectValue={calculatePercentageForButtons(.4)}
              onSelectedValueChanged={handleSelectedGivenAmountChange}
            />
            <BigCheckboxWithoutForm
              label={calculatePercentageForButtons(.5)?.toString() + abbreviation}
              value={givenAmount}
              selectValue={calculatePercentageForButtons(.5)}
              onSelectedValueChanged={handleSelectedGivenAmountChange}
            />
            <BigCheckboxWithoutForm
              label={calculatePercentageForButtons(.6)?.toString() + abbreviation}
              value={givenAmount}
              selectValue={calculatePercentageForButtons(.6)}
              onSelectedValueChanged={handleSelectedGivenAmountChange}
            />
            <BigCheckboxWithoutForm
              label={calculatePercentageForButtons(.8)?.toString() + abbreviation}
              value={givenAmount}
              selectValue={calculatePercentageForButtons(.8)}
              onSelectedValueChanged={handleSelectedGivenAmountChange}
            />
          </GivenAmountButtonsWrapper>
        </ModalSectionRow>)}
      </Box>
      <Box sx={(theme) => ({
        display: 'flex',
        alignSelf: 'flex-end',
        px: 4,
        pb: 2,
        [theme.breakpoints.down('md')]: {
          px: 1,
          pb: 1
        }
      })}>
        <TextButton type="button" color="secondary" onClick={onClose}>
          {f('BASIC_BUTTON_CANCEL_TEXT')}
        </TextButton>
        <Box pl={1} />
        <TextButton type="button" color="secondary" onClick={handleSaveClick}>
          {f('BASIC_BUTTON_SAVE_TEXT')}
        </TextButton>
      </Box>
    </CenterModal>
  )
}

export default React.memo(SingleActivityPartialModalContainer)
