import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import DatePicker  from 'components/inputs/date-picker'
import 'date-fns'
import { useFormatMessage } from 'localization'
import { ChevronLeft, ChevronRight } from 'mdi-material-ui'
import Colors from 'styles/colors'
import { IFormattedDate, INavigateDate } from './day-picker-container'
import { StyledDateBox } from './day-picker-styles'

interface IDayPickerView {
  open: boolean
  day: Date
  formattedDate: IFormattedDate
  onClose: () => void
  onOpen: () => void
  onChangeDay: (date: Date) => void
  navigateDate: (type: INavigateDate) => () => void
  maxDate?: Date
}

const DayPickerView: React.FC<IDayPickerView> = ({ open, onClose, onOpen, day, onChangeDay, formattedDate, navigateDate, maxDate }) => {
  const f = useFormatMessage()
  const maxDateReached = maxDate && day.getDate() === maxDate.getDate()
  return (
    <>
      <Box display="none">
        <DatePicker
          value={day}
          onChange={onChangeDay}
          open={open}
          onClose={onClose}
          label=' '
          maxDate={maxDate}
        />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={navigateDate('prev')} size="large">
          <ChevronLeft fontSize="large" color="secondary" />
        </IconButton>
        <StyledDateBox mx={1} onClick={onOpen}>
          <Typography
            variant="h4"
            style={{
              color: formattedDate.today === false ? Colors.Punch : Colors.Tuna,
            }}>
            {formattedDate.month} {formattedDate.date}
          </Typography>
          <Typography>{formattedDate.dayName}</Typography>
        </StyledDateBox>
        <IconButton onClick={navigateDate('next')} size="large" disabled={maxDateReached}>
          <ChevronRight fontSize="large" color="secondary" />
        </IconButton>
        <IconButton onClick={navigateDate('today')} size="large">
          <Typography style={{ color: Colors.JellyBean }}>Idag</Typography>
        </IconButton>
      </Box>
    </>
  )
}

export default DayPickerView
