import React from 'react'
import {
  WorkScheduleKpiClient,
  KeyPerformanceIndicatorItem
} from 'api/tenilo.dashboard.api-client'
import { DASHBOARD_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class KpiWorkScheduleApi {
    private readonly abortController = new AbortController()
    private readonly client: WorkScheduleKpiClient

    public constructor() {
        this.client = new WorkScheduleKpiClient(DASHBOARD_API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public getEfficiencyRateKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
        this.client
            .getEfficiencyRateKpi(organizationId,areaIds)
            .then(res =>
              res === null
                    ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
                    : Promise.resolve(res)
            )
}

const useKpiWorkScheduleApi = () => React.useMemo(() => new KpiWorkScheduleApi(), [])

export default useKpiWorkScheduleApi
