import { useLegislationApi } from 'api'
import { LegislationItem, LegislationCreateItem, LegislationUpdateItem } from 'api/tillit.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { AdministerLegislationsContext } from '../../administer-legislations-context'

const useLegislationModalContext = () => {
  const { getLegislation, updateLegislation, createLegislation } = useLegislationApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [ownerOrganizationId, setOwnerOrganizationId] = React.useState<number | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedLegislation, setSelectedLegislation] = React.useState<LegislationItem | undefined>()

  const { fetchLegislationsAsync } = AdministerLegislationsContext.useActions()

  const fetchLegislationAsync = React.useCallback(
    async (legislationId: number) => {
      await fetchAsync(getLegislation(legislationId)).then(ita => {
        if (ita instanceof LegislationItem) {
          setSelectedLegislation(ita)
        }
      })
    },
    [fetchAsync, getLegislation]
  )

  const openLegislationModal = React.useCallback(
    (templateActivityId?: number) => {
      if (!!templateActivityId) {
        void fetchLegislationAsync(templateActivityId).then(_ => setModalOpen(true))
      } else {
        setModalOpen(true)
      }
    },
    [fetchLegislationAsync]
  )

  const closeLegislationModal = React.useCallback(() => {
    setSelectedLegislation(undefined)
    setModalOpen(false)
  }, [])

  const createLegislationAsync = React.useCallback(
    (createLegislationModel: LegislationCreateItem) => {
      return fetchAsync(
        createLegislation({
          ...createLegislationModel,
        })
      ).then(_ => {
        void fetchLegislationsAsync()
        closeLegislationModal()
      })
    },
    [fetchAsync, createLegislation, closeLegislationModal, fetchLegislationsAsync]
  )

  const updateLegislationAsync = React.useCallback(
    (templateActivityCategory: LegislationUpdateItem) => {
      return fetchAsync(updateLegislation(templateActivityCategory))
        .then(_ => {
          void fetchLegislationsAsync()
          closeLegislationModal()
        })
        .catch(() => {
          closeLegislationModal()
        })
    },
    [closeLegislationModal, fetchAsync, fetchLegislationsAsync, updateLegislation]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedLegislation,
      ownerOrganizationId,
    },
    actions: {
      openLegislationModal,
      closeLegislationModal,
      createLegislationAsync,
      updateLegislationAsync,
      setOwnerOrganizationId,
      setSelectedLegislation,
    },
  }
}

export const LegislationModalContext = createContext(useLegislationModalContext)
