import React from 'react'
import { FormControl, InputLabel, Select } from '@mui/material'
import { SelectProps } from '@mui/material/Select'
import MuiErrorMessage from 'components/inputs/mui-error-message'
import { Field } from 'formik'
import Colors from 'styles/colors'

type Props = Readonly<{ label?: string }> &
  Omit<SelectProps, 'name'> & {
    name?: string,
    errorMessage?: string,
  }

const FilledSelectContainer: React.FC<Props> = ({ children, name = '\u00A0', fullWidth = false, className, error, errorMessage, ...rest }) => {
  return (
    <FormControl className={className} variant="filled" fullWidth={fullWidth} error={error}>
      <InputLabel id={rest.labelId}>{rest.label}</InputLabel>
      <Field component={Select} name={name} {...rest} autoWidth={true}>
        {children}
      </Field>
      {error && (
        <>
          <div style={{ color: Colors.RedOrange, fontSize: 'smaller' }}>{errorMessage}</div>
        </>
      )}
      <MuiErrorMessage name={name} />
    </FormControl>
  )
}

export default FilledSelectContainer
