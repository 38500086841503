import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { CommonShiftInheritanceItem } from 'api/tenilo.workschedule.api-client'
import { useFormatMessage } from 'localization'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import ContainedButton from 'components/inputs/contained-button'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { DeleteDialog } from 'components/delete-dialog'
import Tooltip from '@mui/material/Tooltip'
import { IconButton, Box } from '@mui/material'
import { Delete } from 'mdi-material-ui'
import { WorkScheduleConnectionContext } from './work-schedule-connection-context'

type RemoveItemStateType = Readonly<{
  open: boolean,
  item?: CommonShiftInheritanceItem
}>

const WorkScheduleConnectionView: React.FC = () => {
  const f = useFormatMessage()
  const { currentUser, inheritedCommonShifts, isLoading, commonShifts } = WorkScheduleConnectionContext.useState()
  const { openWorkScheduleModal, handleRemoveInheritedCommonShift } = WorkScheduleConnectionContext.useActions()
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [filterValue, setFilterValue] = React.useState<string>('')
  const [filteredInheritedCommonShifts, setFilteredInheritedCommonShifts] = React.useState<CommonShiftInheritanceItem[]>()
  const [removeItem, setRemoveItem] = React.useState<RemoveItemStateType>({
    open: false
  });

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
    },
    []
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
    },
    []
  )

  const handleRemoveClick = React.useCallback(
    (params) => (_: React.MouseEvent<Element, MouseEvent> | undefined ) => {
      _?.stopPropagation()
      setRemoveItem({
        open: true,
        item: params.row
      })
    },
    [removeItem]
  )

  React.useEffect(()=>{
    if(filterValue){
      const validFilteredInheritedCommonShifts : CommonShiftInheritanceItem[] = []
      inheritedCommonShifts?.forEach((shift,i)=>{
          if(JSON.stringify(shift).toLowerCase().normalize('NFC').includes(filterValue.toLowerCase().normalize('NFC'))){
            validFilteredInheritedCommonShifts.push(shift)
          }
          if(i + 1 === inheritedCommonShifts.length){
            setFilteredInheritedCommonShifts(validFilteredInheritedCommonShifts)
          }
      })
    }else{
      setFilteredInheritedCommonShifts(inheritedCommonShifts)
    }
  },[filterValue, inheritedCommonShifts])

  const handleConfirmRemoveunitClick = React.useCallback(()=>{
    if(removeItem.item?.id) {
      handleRemoveInheritedCommonShift(removeItem.item?.id).then(closeRemoveDialog)
    }else{
      return closeRemoveDialog()
    }
  },[removeItem])

  const closeRemoveDialog = React.useCallback(() => {
    setRemoveItem({
      open: false,
    })
  }, [])

  const handleRowClick = React.useCallback(
    (params) => {
      openWorkScheduleModal(params.row)()
    },
    []
  )

  return (
    <div style={{ padding: '0.5vh' }}>
      <>
        {currentUser.organizationId && (
          <Box style={{ display: 'flex', justifyContent:'space-between'}}>
            <Box sx={{ display: 'flex', flex: '50%'}}>
              <ContainedButton containerStyle={{marginLeft:0}} disabled={!commonShifts || isEmpty(commonShifts)} type="button" color="secondary" onClick={openWorkScheduleModal()}>
                {f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_ADD_BUTTON_TEXT')}
              </ContainedButton>
            </Box>
            <div style={{width:'25%', paddingBottom: '1vh'}}>
              <FilledSearchTextField
                label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                handleChangeFilter={handleChangeFilter}
                handleClearFilter={handleClearFilter}
                searchValue={filterValue}
              />
            </div>
          </Box>
        )}
        <DataGrid
          disableColumnFilter
          sx={{
            minHeight: '70vh',
            maxHeight: '70vh',
            '.MuiDataGrid-cell': { borderBottom: '0px' }
          }}
          loading={isLoading && !inheritedCommonShifts}
          rows={filteredInheritedCommonShifts || []}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          onRowClick={handleRowClick}
          localeText={muiDataGridLocale(f)()}
          columns={[
            {
              flex: 1,
              headerName: f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TITLE'),
              field: 'commonShiftDescription',
            },
            {
              flex: 1,
              headerName: f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_TITLE'),
              field: 'areaName',
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <span>
                   {params.row.areaOrganizationParentsName ? `${params.row.areaName} (${params.row.areaOrganizationParentsName})` : params.row.areaName}
                  </span>
                )
              },
            },
            {
              headerName: ' ',
              flex: 0.2,
              field: 'no_field_delete',
              filterable: false,
              type: 'actions',
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <span>
                    <Tooltip
                      title={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_BUTTON_TEXT', {
                        title: '',
                      })}
                      aria-label={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_BUTTON_TEXT', {
                        title: '',
                      })}>
                      <IconButton onClick={handleRemoveClick(params)} size="large">
                        <Delete color={'secondary'} />
                      </IconButton>
                    </Tooltip>
                  </span>
                )
              },
            },
          ]}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[25, 50, 100]}
          pagination

        />
      </>
      <DeleteDialog
        dialogTitle={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_TITLE')}
        dialogContentText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_MESSAGE', {
          title: ''
        })}
        buttonSubmitText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_REMOVE')}
        buttonCancelText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_CANCEL')}
        deleteDialogOpen={removeItem.open}
        onClose={closeRemoveDialog}
        onSubmit={handleConfirmRemoveunitClick}
      />
    </div>
  )
}

export default WorkScheduleConnectionView
