import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import Spinner from 'components/spinner'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { AdministerDevicesContext } from './administer-devices-context'
import ActivatedDevicesSection from './components/activated-devices-section'
import DeviceSection from './components/device-section'

const useStyles = makeStyles(theme => {
  return ({
    'tenilo-administer-devices-page': {
      padding: '2vh',
      '&>div' :{
        '&>div' :{
          flexDirection:'column',
        }
      },
      [theme.customBreakpoints.justMobileTablet]: {
        padding:0,
        '&>div':{
          width: '100%',
          '&>div':{
            margin:0,
            '&>div':{
              margin:0,
            }
          }
        }

      }
    }
  }
  )
})

const AdministerDevicesView: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { isLoading } = AdministerDevicesContext.useState()

  return (
    <Box className={classes["tenilo-administer-devices-page"]}>
      <div>
        <Box display="flex">
          {isLoading ? (
            <Spinner size={64} color={theme.palette.primary.main} />
          ) : (
            <>
              <DeviceSection />
              <ActivatedDevicesSection />
            </>
          )}
        </Box>
      </div>
    </Box>
  )
}

export default AdministerDevicesView
