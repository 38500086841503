import { useUserApi } from 'api'
import { SendEmailToUsersItem } from 'api/tillit.api-client'
import { UsersPageContext } from 'pages/users/users-page-context'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useSendEmailToUsersModalContext = () => {
  const { sendMultipleEmailsToUsers } = useUserApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedUserIds, setSelectedUserIds] = React.useState<[number]>([0])

  const { activeUsers } = UsersPageContext.useState()

  const openSendEmailToUsersModal = React.useCallback(
    (userIds: [number]) => {
      setSelectedUserIds(userIds)
      setModalOpen(true)
    },
    [selectedUserIds]
  )

  const closeSendEmailToUsersModal = React.useCallback(() => {
    setModalOpen(false)
  }, [])

  const sendEmailToUsersAsync = React.useCallback(
    async (sendEmailToUsersItem: SendEmailToUsersItem[]) => {
      return fetchAsync(sendMultipleEmailsToUsers(sendEmailToUsersItem))
    },
    []
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedUserIds,
      activeUsers
    },
    actions: {
      openSendEmailToUsersModal,
      closeSendEmailToUsersModal,
      sendEmailToUsersAsync,
    },
  }
}

export const SendEmailToUsersModalContext = createContext(useSendEmailToUsersModalContext)
