import React, { FC } from 'react'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import UserDialog from 'components/user-dialog'
import { UsersPageContext } from './users-page-context'
import UsersPageView from './users-page-view'
import { SendEmailToUsersModalContext } from './components/send-email-to-users-modal/send-email-to-users-modal-context'

const UsersPageContainer: FC = () => {
  return (
    <UsersPageContext.Provider>
      <SendEmailToUsersModalContext.Provider>
      <UserDialogContext.Provider>
        <UsersPageView />
        <UserDialog />
      </UserDialogContext.Provider>
      </SendEmailToUsersModalContext.Provider>
    </UsersPageContext.Provider>
  )
}

export default UsersPageContainer
