import * as React from 'react';
import { useField, FieldAttributes } from "formik"
import { UserReportSelectionItem, ReportSelectionListItemValue } from 'api/tenilo.reporting.api-client'
import { ToggleButtonGroup, ToggleButton, Typography, FormHelperText } from "@mui/material"
import { useFormatMessage } from 'localization'
import { isEmpty } from 'lodash'

type ReportToggleButtonProps = { options: any[], exclusive: boolean, initialValues?: UserReportSelectionItem, required?:boolean } & FieldAttributes<Record<string, unknown>>

const ReportToggleButton: React.FC<ReportToggleButtonProps> = ({
  options,
  exclusive,
  initialValues,
  required,
  ...props
}) => {
  const f = useFormatMessage()
  const [field, meta, helpers] = useField<Record<string, unknown>>(props)

  const handleChange = React.useCallback((e, newValue: any) => {
    helpers.setValue(newValue)
  }, [])

  const handleBlur = React.useCallback((e) => {
    helpers.setTouched(true)
  }, [])

  React.useEffect(()=>{
    if(initialValues){
      const stringValues = initialValues.selectedValues
      const values = options.filter((option:ReportSelectionListItemValue)  => stringValues.includes(option.id.toString()))
      if(!isEmpty(values)){
          helpers.setValue(values[0].id as Record<string, unknown>)
      }
    }else{
      if(required && options){
        helpers.setValue(options[0]?.id)
      }
    }
  },[initialValues,options])

  React.useEffect(()=>{
    if(required && !field.value && !meta.error ){
      helpers.setError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
    }
  },[field,meta])



  return  React.useMemo(()=>{
    if (!options || options.length === 0) return null
    return (
      <>
        <Typography>{f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${field.name}`)}</Typography>
        <ToggleButtonGroup
          color="primary"
          sx={{
            display: 'flex',
            marginBottom: '5px',
            '*': {
              padding: '6px'
            }
          }}
          {...field}
          exclusive={exclusive}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {options.map(option => (
            <ToggleButton key={`tb_s_${option.value}`} value={option.id}>{option.value}</ToggleButton>
          ))}
        </ToggleButtonGroup>
        <FormHelperText sx={{margin:'0 14px'}} error={meta.touched && !!meta.error}> {(meta.touched && meta.error) ? meta.error : ' ' }</FormHelperText>
      </>
    )

  },[field.value,meta.error,field.name,meta.touched,options])
}

export default ReportToggleButton
