import { Box, Button, DialogContent, IconButton, Switch, Typography } from '@mui/material'
import { IKioskAreaCreateItem, UserAreaItem } from 'api/tillit.api-client'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalContent from 'components/modal-content'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { useDistinctAreas } from 'utilities/use-user-areas'
import React from 'react'
import BigCheckbox from 'components/inputs/big-checkbox'
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import SearchAreaInput from '../search-area-input'
import { ManageDeviceDialogContext } from './manage-device-dialog-context'
import { useManageDeviceForm } from './utilities/use-manage-device-form'
import AdditionalAreas from './components/additional-areas'

const ManageDeviceDialogView: React.FC = () => {
  const f = useFormatMessage()
  const { distinctAreas } = useDistinctAreas()
  const { device, areas = [] } = ManageDeviceDialogContext.useState()
  const { handleCloseDialog } = ManageDeviceDialogContext.useActions()
  const [mainArea, setMainArea] = React.useState<IKioskAreaCreateItem | undefined>(undefined)

  if (!device) {
    throw Error('No device found when mounting ManageDeviceDialogView')
  }

  const { kioskAreas = [] } = device

  React.useEffect(() => {
    setMainArea(kioskAreas.find(a => a.kioskMainArea === true))
  }, [kioskAreas])

  const { initialValues, name, onSubmit, validationSchema } = useManageDeviceForm()

  const getAreaName = (item: any) => {
    const a = item as UserAreaItem
    return a?.organizationParentsName ? `${a.areaName} (${a.organizationParentsName})` : a.areaName || ''
  }

  const getOptionLabel = (option: UserAreaItem) => option.areaName!

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors, touched, values, setFieldValue }) => {
        const handleChangeMainArea = (_: React.ChangeEvent<{}>, value: any) => {
          const uaV = value as UserAreaItem
          setMainArea({
            areaId: uaV.areaId,
            kioskMainArea: true,
          })
        }

        const switchUseManualSessionExpiration = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          setFieldValue("useManualSessionExpiration", checked)
          if (!checked) {
            setFieldValue("manualSessionExpirationMinutes", 2)
          }
        }

        const switchShowAdditionalAreas = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          setFieldValue("showAdditionalAreas", checked)
          if (!checked) {
            setFieldValue("showAdditionalAreasAllDay", false)
            setFieldValue("showAdditionalAreasStartTimeTicks", undefined)
            setFieldValue("showAdditionalAreasEndTimeTicks", undefined)
            setFieldValue("timeBasedArea", undefined)
          }
        }

        const switchShowAdditionalAreasAllDay = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          setFieldValue("showAdditionalAreasAllDay", checked)
          if (checked) {
            setFieldValue("showAdditionalAreasStartTimeTicks", undefined)
            setFieldValue("showAdditionalAreasEndTimeTicks", undefined)
          }
        }

        const handleChangeShowAdditionalAreasStartTimeTicks = (val) => {
          setFieldValue('showAdditionalAreasStartTimeTicks', val)
        }

        const handleChangeShowAdditionalAreasEndTimeTicks = (val) => {
          setFieldValue('showAdditionalAreasEndTimeTicks', val)
        }

        const handleRemoveTimeBasedArea = (areaId: number) => () => {
          setFieldValue(
            'timeBasedArea',
            values.timeBasedArea?.filter(a => a.areaId !== areaId)
          )
        }

        return (
          <Form translate="yes" style={{ display: 'contents' }}>
            <ModalHeader title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_TITLE')} subtitle={device!.kioskName} mb={5} responsive />
            <ModalContent style={{ padding: '20px' }}>
              <ModalSection
                title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_TITLE')}
                subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_SUBTITLE')}
                responsive
              >
                <ModalSectionRow responsive>
                  <FilledTextField name={name.name} label={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_FIELD_NAME_LABEL')} fullWidth />
                </ModalSectionRow>
              </ModalSection>
              <ModalSection title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_TITLE')} responsive>
                <ModalSectionRow responsive>
                  <FilledTextField
                    data-matomo-mask
                    name={name.comment}
                    label={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_DESCRIPTION_LABEL')}
                    rows={5}
                    multiline
                    fullWidth
                  />
                </ModalSectionRow>
              </ModalSection>
              <ModalSection
                title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_TITLE')}
                subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_SUBTITLE')}>
                <ModalSectionRow>
                  <>
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_YES')}`}
                      name={name.allowNavigateToPlanningPage}
                      value="true"
                      checkedByValue
                      returnValue="value"
                    />
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_NO')}`}
                      name={name.allowNavigateToPlanningPage}
                      value="false"
                      checkedByValue
                      returnValue="value"
                    />
                  </>
                </ModalSectionRow>
              </ModalSection>

              <ModalSection
                title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_TITLE')}
                subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_SUBTITLE')}>
                <ModalSectionRow>
                  <>
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_YES')}`}
                      name={name.allowUseMultiSigning}
                      value="true"
                      checkedByValue
                      returnValue="value"
                    />
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_NO')}`}
                      name={name.allowUseMultiSigning}
                      value="false"
                      checkedByValue
                      returnValue="value"
                    />
                  </>
                </ModalSectionRow>
              </ModalSection>

              <ModalSection
                title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_USEMANUALSESSIONEXPIRATION_TITLE')}
                subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_USEMANUALSESSIONEXPIRATION_SUBTITLE')}>
                <ModalSectionRow>
                  <Switch checked={values.useManualSessionExpiration} color="primary" onChange={switchUseManualSessionExpiration} />
                </ModalSectionRow>
              </ModalSection>

              {values.useManualSessionExpiration && (
                <ModalSection
                  title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_TITLE')}
                  subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_SUBTITLE')}>
                  <ModalSectionRow>
                    <>
                      <FilledTextField
                        name={name.manualSessionExpirationMinutes}
                        label={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL')}
                      />
                    </>
                  </ModalSectionRow>
                </ModalSection>
              )}

              <ModalSection
                title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_MAIN_AREA_TITLE')}
                subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_MAIN_AREA_SUBTITLE')}
                responsive>
                <ModalSectionRow responsive>
                  <Box flex={1}>
                    <SearchAreaInput
                      onChange={handleChangeMainArea}
                      name={name.mainArea}
                      options={distinctAreas(areas) as any}
                      getOptionLabel={getAreaName}
                      error={!!errors.mainArea && !!touched.mainArea}
                      helperText={!!touched.mainArea && errors.mainArea}
                      fullWidth={true}
                    />
                  </Box>
                </ModalSectionRow>
              </ModalSection>


              <ModalSection
                title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREAS_TITLE')}
                subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREAS_SUBTITLE')}>
                <ModalSectionRow>
                  <Switch checked={values.showAdditionalAreas} color="primary" onChange={switchShowAdditionalAreas} />
                </ModalSectionRow>
              </ModalSection>

              {values.showAdditionalAreas && (
                <>
                  <ModalSection
                    title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASTIMES_TITLE')}
                    subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASTIMES_SUBTITLE')}>
                    <ModalSectionRow>
                      <Typography>{f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASWHOLEDAY_TITLE')}</Typography>
                      <Switch checked={values.showAdditionalAreasAllDay} color="primary" onChange={switchShowAdditionalAreasAllDay} />
                    </ModalSectionRow>
                    {!values.showAdditionalAreasAllDay && (
                      <ModalSectionRow>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                          <TimePicker
                            label={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASSTARTTIME_LABEL')}
                            value={values.showAdditionalAreasStartTimeTicks}
                            ampm={false}
                            sx={{ width: '50%', marginRight: '10px' }}
                            onChange={handleChangeShowAdditionalAreasStartTimeTicks}
                            slotProps={{
                              textField: {
                                variant: 'filled',
                                error: touched && !!errors?.showAdditionalAreasStartTimeTicks,
                                helperText: touched && errors?.showAdditionalAreasStartTimeTicks
                              }
                            }}
                            localeText={{
                              toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                              okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                              cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                            }}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                          <TimePicker
                            label={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASENDTIME_LABEL')}
                            value={values.showAdditionalAreasEndTimeTicks}
                            ampm={false}
                            sx={{ width: '50%' }}
                            onChange={handleChangeShowAdditionalAreasEndTimeTicks}
                            slotProps={{
                              textField: {
                                variant: 'filled',
                                error: touched && !!errors?.showAdditionalAreasEndTimeTicks,
                                helperText: touched && errors?.showAdditionalAreasEndTimeTicks
                              }
                            }}
                            localeText={{
                              toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                              okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                              cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                            }}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </Box>
                      </ModalSectionRow>
                    )}
                  </ModalSection>
                  <ModalSection
                    title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ADDITIONALAREAS_TITLE')}
                    subtitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ADDITIONALAREAS_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box sx={{ width: '100%' }}>
                        <AdditionalAreas
                          areas={areas.filter(a => mainArea !== undefined && a.areaId !== mainArea.areaId)}
                          additionalAreas={values.timeBasedArea} />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                </>
              )}

            </ModalContent>
            <ModalFooter>
              <Button type="button" variant="text" onClick={handleCloseDialog}>
                {f('ADMINISTER_MANAGE_DEVICE_DIALOG_BUTTON_CANCEL')}
              </Button>
              <ContainedButton type="submit" color="secondary" showProgress={isSubmitting}>
                {f('ADMINISTER_MANAGE_DEVICE_DIALOG_BUTTON_SAVE')}
              </ContainedButton>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ManageDeviceDialogView
