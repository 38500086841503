import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import useOpenSettingsApi from 'api/openSettings-api'
import { LoginMethodsItem } from 'api/tillit.api-client'

const useLoginPageContext = () => {
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const [loginMethods, setLoginMethods] = React.useState<LoginMethodsItem[]>()
  const { abort, getActiveLoginMethods } = useOpenSettingsApi()

  const getActiveLoginMethodsAsync = React.useCallback(() => {
    return fetchAsync(getActiveLoginMethods())
      .then(methods => {
        if (methods) {
          setLoginMethods(methods)
        }
        return methods
      })
      .catch(err => { throw err })
  }, [])

  React.useEffect(() => {
    getActiveLoginMethodsAsync()?.catch(err => console.error(err))
  }, [])

  React.useEffect(() => () => abort(), [abort])

  return {
    state: {
      loginMethods,
    },
    actions: {
    },
  }
}

export default createContext(useLoginPageContext)
