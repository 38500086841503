import React from 'react'
import { useUserWorkScheduleApi } from 'api'
import usePromiseApi from 'utilities/use-promise'
import { createContext } from 'utilities/create-context'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { LatestUserWorkScheduleItem } from 'api/tenilo.workschedule.api-client'

const useCreateUserWorkScheduleModalContext = () => {

  const { calendarStartDate } = PlanningPageContext.useState()
  const { listLatestUserWorkSchedules } = useUserWorkScheduleApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [latestUserWorkSchedules, setLatestUserWorkSchedules] = React.useState<LatestUserWorkScheduleItem[] | undefined>([])

  const getLatestWorkSchedulesForUser = React.useCallback((userId: number) => {

    void fetchAsync(listLatestUserWorkSchedules(userId,calendarStartDate)).then(setLatestUserWorkSchedules)

  }, [fetchAsync, listLatestUserWorkSchedules, calendarStartDate])


  return {
    state: {
      ...stateOfFetch,
      latestUserWorkSchedules,
    },
    actions: {
      getLatestWorkSchedulesForUser,
      setLatestUserWorkSchedules
    },
  }
}

export const CreateUserWorkScheduleModalContext = createContext(useCreateUserWorkScheduleModalContext)
