import { Box, Tab, useTheme } from '@mui/material'
import OutlinedButton from 'components/inputs/outlined-button'
import Spinner from 'components/spinner'
import PageRoute from 'config/page-routes'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { StyledTabs } from 'pages/planning/planning-page-styles'
import { a11yProps } from 'pages/planning/planning-page-view'
import React from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { RoleEnum } from 'api/tillit.api-client'
import useAuthorization from 'utilities/use-authorization'
import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import { StyledAreaContainer } from '../area/area-container'
import InfoActivity from '../popover-info-activity'
import Schedule from '../schedule'
import EditRouteModal from '../edit-route-modal'
import { Authorize } from 'components/authorization'
import { IRouteMenuOption } from '../route-menu/route-menu-options'
import { ManageCombinedRoutesModal } from './components/manage-combined-routes-modal'


export const DevicePlanningPageContainer: React.SFC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const { hasRoleOnAnyArea } = useAuthorization()
  const navigate = useNavigate()

  const { devices, deviceData, selectedDevice, isLoading, planningDeviceId } = PlanningPageContext.useState()

  const {
    manageCombinedRoutes: { setCombinedRouteModalOpen },
    onOpenEditRouteModal,
    removeTimeBasedRouteContainerAsync,
  } = PlanningPageContext.useActions()

  const { date: urlDate, type } = useParams() as {date: string, type: "areas" | "devices"}

  const handleDeviceTabClick = (deviceId: number) => () => {
    navigate(
      PageRoute.Planning.path
        .replace(':areaId', deviceId.toString())
        .replace(':date', urlDate)
        .replace(':type', type)
        .replace(':usingCalendarFromDevice', 'false')
    )
  }

  const handleOpenEditRouteModal = React.useCallback(
    (route: IPlannedRoutesActivitiesPlanningViewItem) => {
      onOpenEditRouteModal(route, true)
    },
    [onOpenEditRouteModal]
  )

  const handleClickMenuOptionParent = React.useCallback(
    (name: IRouteMenuOption['name'], route: IPlannedRoutesActivitiesPlanningViewItem) => {
      switch (name) {
        case 'edit':
          return handleOpenEditRouteModal(route)
        case 'remove':
          removeTimeBasedRouteContainerAsync(route)
          return
        default:
          return
      }
    },
    [handleOpenEditRouteModal, removeTimeBasedRouteContainerAsync]
  )

  const handleMangeCombinedRoutesClick = () => setCombinedRouteModalOpen(true)

  return (
    <>
      <ManageCombinedRoutesModal />
      {isLoading && deviceData.planning.length === 0 ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          <InfoActivity />
          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <StyledTabs value={devices.findIndex(d => d.id === planningDeviceId)} variant="scrollable" scrollButtons={true} style={{ flex: 1 }}>
                {devices.map((device, index) => (
                  <Tab key={index} label={device.kioskName} {...a11yProps(device.id)} onClick={handleDeviceTabClick(device.id)} />
                ))}
              </StyledTabs>
              <Authorize allowedRoles={[RoleEnum.Admin]}>
                {!!selectedDevice && (
                  <OutlinedButton style={{ marginRight: '1rem' }} onClick={handleMangeCombinedRoutesClick} color="secondary">
                    {f('PLANNING_PAGE_DEVICE_PLANNING_MANAGE_DEVICE_ROUTE_BUTTON_TEXT')}
                    {` ${selectedDevice.kioskName}`}
                  </OutlinedButton>
                )}
              </Authorize>
            </Box>
            {!!selectedDevice && (
              <StyledAreaContainer pl={3} pt={3} display="flex" flex={1} position="relative">
                <Schedule
                  hideSidebars={true}
                  allowRouteChanges={false}
                  preventHorizontalDrag={true}
                  /*actionBegin={handleActionBegin}
                    drag={handleScheduleDrag}*/
                  routes={deviceData.planning}
                  /*plannedActivityRows={deviceData.rows}
                    routePlanningData={deviceData.planning}*/
                  type={type}
                  handleClickMenuOptionParent={handleClickMenuOptionParent}
                />
                <EditRouteModal />
              </StyledAreaContainer>
            )}
            {!selectedDevice && f('PLANNING_PAGE_DEVICE_PLANNING_PAGE_CONTAINER_NO_DEVICE_TEXT')}
          </Box>
        </>
      )}
    </>
  )
}
