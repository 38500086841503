import { getMinutesBetween } from './get-minutes-between'

export const getStartTimeInMinutes = (
  calendarStartDate: Date,
  sessionStartDate: Date
) =>
  /* if (sessionStartDate < calendarStartDate) {
    throw Error(
      `Start date of session (${sessionStartDate}) needs to be bigger than start date of calendar (${calendarStartDate})`
    )
  }*/

  /* if (calendarStartDate.getMonth() !== sessionStartDate.getMonth()) {
    throw Error(
      'Start date of session and start date of calendar needs to be in the same month'
    )
  }*/

  getMinutesBetween(
    calendarStartDate,
    new Date(Math.max(+calendarStartDate, +sessionStartDate))
  )
