import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { CloseCircle } from 'mdi-material-ui'
import React from 'react'

import { IUserItem, RoleEnum, UserAreaItem, UserTypeEnum } from 'api/tillit.api-client'
import AuthenticationContext from 'components/authentication/authentication-context'
import { AreaRole, UserDialogContext } from 'components/user-dialog/user-dialog-context'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'
import { FormikTouched } from 'formik'
import { useFormatMessage } from 'localization'
import { StyledCheckbox } from './area-roles-style'

export type AreaRolesTableProps = Readonly<{
  name: string
  areas: Array<{
    id: number
    name: string
    organizationParentsName: string
  }>
  checkedAreaRoles: AreaRole[]
  onRoleChange: (areaId: number, role: RoleEnum, checked: boolean) => void
  onRemoveAreaClick: (areaId: number) => void
  touchedError: FormikTouched<UserFormModelWithModifiedAreas['areaRoles'] | undefined>
  forceDisable: boolean
}>

const AllRoles = Object.keys(RoleEnum)
  .filter(role => isNaN(Number(RoleEnum[role])))
  .map(role => role as RoleEnum)

const AreaRolesTableView: React.FC<AreaRolesTableProps> = ({ areas, checkedAreaRoles, onRoleChange, onRemoveAreaClick, touchedError, forceDisable }) => {
  const f = useFormatMessage()
  const { user } = AuthenticationContext.useAuthenticatedState()
  const { user: fetchedUser } = UserDialogContext.useState()

  const { userAreas = [] } = user

  let forceDisablePlannerRole = false
  if (user.userAreas && user.userType === UserTypeEnum.OrganizationMember) {
    const loggedInAdminOrPlannerUserAreas =  user.userAreas.filter(a => (a.roleId === RoleEnum.Admin) || (a.roleId === RoleEnum.Planner))
    const loggedInAdminOrPlannerUserAreaIds = loggedInAdminOrPlannerUserAreas.map(a => a.areaId)
    const areaIdsToCheck = fetchedUser?.userAreas?.map(ua => ua.areaId)

    const areaIdsExist = loggedInAdminOrPlannerUserAreaIds.some((areaId) => areaIdsToCheck?.includes(areaId))
    if (!areaIdsExist) {
      forceDisablePlannerRole = true
    }
    else {
      forceDisablePlannerRole = false
    }
  }

  // Map with areaId: UserArea of highest role on the area
  const userAreasMap = new Map()
  for (const userArea of userAreas) {
    if (
      !userAreasMap.has(userArea.areaId) ||
      userArea.roleId === RoleEnum.Admin ||
      (userArea.roleId === RoleEnum.Planner && userAreasMap.get(userArea.areaId).roleId !== RoleEnum.Admin)
    ) {
      userAreasMap.set(userArea.areaId, userArea)
    }
  }

  const calculateError = React.useCallback(
    (areaId: number) => {
      return !checkedAreaRoles.some(c => c.areaId === areaId) && !!touchedError
    },
    [touchedError, checkedAreaRoles]
  )

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{f('USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_AREA_COLUMN_TITLE')}</TableCell>
          {AllRoles.map(role => (
            <TableCell key={role}>{f('USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_ROLE_COLUMN_TITLE', { role })}</TableCell>
          ))}
          <TableCell>{/* This column is for the remove button */}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {areas.sort((a1, a2) => (a1.name || '').localeCompare(a2.name || '')).map(area => (
          <TableRow key={area.id}>
            <TableCell>
              <Typography variant="subtitle1">{area.organizationParentsName ? `${area.name} (${area.organizationParentsName})` : area.name}</Typography>
            </TableCell>
            {AllRoles.map(role => (
              <TableCellForRole
                key={role}
                user={user}
                userRole={userAreasMap.get(area.id)}
                areaId={area.id}
                role={role}
                checked={checkedAreaRoles.some(c => c.areaId === area.id && c.roleId === role)}
                onRoleChange={onRoleChange}
                error={calculateError(area.id)}
                forceDisable={forceDisable}
                forceDisablePlannerRole={forceDisablePlannerRole}
              />
            ))}
            <TableCellForRemoveArea
              user={user}
              areaId={area.id}
              userRole={userAreasMap.get(area.id)}
              checkedAreaRoles={checkedAreaRoles.filter(c => c.areaId === area.id)}
              onRemoveAreaClick={onRemoveAreaClick}
              forceDisable={forceDisable}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const TableCellForRole: React.FC<{
  user: IUserItem
  areaId: number
  userRole?: UserAreaItem
  role: RoleEnum
  checked: boolean
  error: boolean
  forceDisable: boolean
  forceDisablePlannerRole: boolean
  onRoleChange: (areaId: number, role: RoleEnum, checked: boolean) => void
}> = ({ user, areaId, userRole, role, checked, onRoleChange, error, forceDisable, forceDisablePlannerRole }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onRoleChange(areaId, role, e.target.checked)
  }
  let disabled = false

  // This could be undefined if current user does not have any role on the area (which should disable checkbox)
  const roleId = userRole?.roleId

  switch (role) {
    case RoleEnum.Admin: {
      disabled = ![UserTypeEnum.SystemAdmin, UserTypeEnum.OrganizationAdmin].includes(user.userType)
      break
    }
    case RoleEnum.Planner: {
      disabled = roleId !== RoleEnum.Admin || forceDisablePlannerRole
      break
    }
    case RoleEnum.Caretaker:
      disabled = roleId !== RoleEnum.Admin && roleId !== RoleEnum.Planner
      break
    default: {
      disabled = true
      break
    }
  }

  if (user.userType === UserTypeEnum.SystemAdmin || user.userType === UserTypeEnum.OrganizationAdmin) {
    disabled = false
  }

  if (forceDisable) {
    disabled = true
  }

  return (
    <TableCell>
      <StyledCheckbox disabled={disabled} checked={checked} onChange={handleChange} error={error} />
    </TableCell>
  )
}

const TableCellForRemoveArea: React.FC<{
  user: IUserItem
  areaId: number
  userRole?: UserAreaItem
  checkedAreaRoles: AreaRole[]
  onRemoveAreaClick: (areaId: number) => void
  forceDisable: boolean
}> = ({ user, areaId, userRole, checkedAreaRoles, onRemoveAreaClick, forceDisable }) => {
  let enabled =
    !!userRole &&
    ((userRole.roleId === RoleEnum.Admin && !checkedAreaRoles.some(c => c.roleId === RoleEnum.Admin)) ||
      (userRole.roleId === RoleEnum.Planner && !checkedAreaRoles.some(c => c.roleId === RoleEnum.Admin || c.roleId === RoleEnum.Planner)))

  if (forceDisable) {
    enabled = false
  }

  if (user.userType === UserTypeEnum.OrganizationAdmin) {
    enabled = true
  }

  const handleRemoveAreaClick = () => {
    if (enabled) {
      onRemoveAreaClick(areaId)
    }
  }

  return (
    <TableCell>
      <IconButton onClick={handleRemoveAreaClick} disabled={!enabled} size="large">
        <CloseCircle />
      </IconButton>
    </TableCell>
  )
}

export default AreaRolesTableView
