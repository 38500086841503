import { Box } from '@mui/material'
import CenterModal from 'components/center-modal'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import React from 'react'

interface IListingModalContainerProps {
  open: boolean
  title: string
  subtitle?: string
  onSaveClick?: (comment?: string) => void
  onClose?: () => void
}

const SingleActivityCommentModalContainer: React.FC<IListingModalContainerProps> = ({ open, title, subtitle, onSaveClick, onClose }) => {
  const f = useFormatMessage()

  const [comment, setComment] = React.useState('')

  React.useEffect(() => {
    // Be sur eto clear the comment fields each time modal opens
    if (open) {
      setComment('')
    }
  }, [open])

  const handleSaveClick = React.useCallback(() => {
    if (onSaveClick !== undefined) {
      onSaveClick(comment)
    }
    if (onClose !== undefined) {
      onClose()
    }
  }, [comment, onSaveClick, onClose])

  const handleCommentChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComment(event.target.value)
  }, [])

  return (
    <CenterModal open={open} onClose={onClose} responsive>
      <ModalHeader data-matomo-mask title={title} subtitle={subtitle} responsive />
      <Box sx={(theme)=>({
        px:4,
        [theme.breakpoints.down('md')]:{
          px:1
        }
      })}>
        <FilledTextField
          data-matomo-mask
          fullWidth={true}
          label={f('LISTING_MODAL_LISTING_FIELD_LABEL')}
          multiline={true}
          rows="4"
          margin="normal"
          value={comment}
          onChange={handleCommentChange}
        />
      </Box>
      <Box sx={(theme)=>({
        display: 'flex',
        alignSelf:'flex-end',
        px:4,
        pb:2,
        [theme.breakpoints.down('md')]:{
          px:1,
          pb:1
        }
      })}>
        <TextButton type="button" color="secondary" onClick={onClose}>
          {f('BASIC_BUTTON_CANCEL_TEXT')}
        </TextButton>
        <Box pl={1} />
        <TextButton type="button" color="secondary" onClick={handleSaveClick}>
          {f('BASIC_BUTTON_SAVE_TEXT')}
        </TextButton>
      </Box>
    </CenterModal>
  )
}

export default React.memo(SingleActivityCommentModalContainer)
