import React from 'react'
import { InnerContext } from '../contexts/inner-context'

export type ResizeActions = Readonly<{
  onResizeStart?: () => void
  onResizeUpdate?: (delta: number) => void
  onResizeStop?: (delta: number) => void
}>

export const useResize = (
  callbacks: ResizeActions = {}
): [React.RefObject<HTMLDivElement>] => {
  const { containerRef } = InnerContext.useState()
  const ref = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    const { current: refCurrent } = ref
    const { current: containerRefCurrent } = containerRef

    if (refCurrent === null || containerRefCurrent === null) {
      return
    }

    let delta = 0
    let prevClientY: number | undefined
    let prevScroll: number | undefined
    let needForRAF = true

    const update = () => {
      needForRAF = true
      if (callbacks.onResizeUpdate) {
        callbacks.onResizeUpdate(delta)
      }
    }

    const handleMouseMove = (event: MouseEvent) => {
      const eventClientY = event.clientY
      delta += eventClientY - (prevClientY || eventClientY)
      if (needForRAF) {
        needForRAF = false // no need to call rAF up until next frame
        requestAnimationFrame(update) // request 60fps animation
      }
      prevClientY = eventClientY
    }

    const handleMouseUp = () => {
      if (callbacks.onResizeStop) {
        callbacks.onResizeStop(delta)
      }
      prevClientY = undefined
      delta = 0

      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      containerRefCurrent.removeEventListener('scroll', handleScoll)
    }

    const handleMouseDown = () => {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
      containerRefCurrent.addEventListener('scroll', handleScoll)
      if (callbacks.onResizeStart) {
        callbacks.onResizeStart()
      }
    }

    const handleScoll = () => {
      const { scrollTop } = containerRefCurrent
      if (prevScroll !== undefined) {
        const deltaScroll = prevScroll - scrollTop
        if (scrollTop > 0) {
          delta -= deltaScroll
        }
        if (scrollTop < 0) {
          delta += deltaScroll
        }
      }
      prevScroll = scrollTop
    }

    refCurrent.addEventListener('mousedown', handleMouseDown)

    return () => {
      refCurrent.removeEventListener('mousedown', handleMouseDown)
      containerRefCurrent.removeEventListener('scroll', handleScoll)
    }
  }, [callbacks, containerRef])

  return [ref]
}
