import styled, { css } from 'styled-components'
// import withSpinner from 'utilities/with-spinner'
import OrganizationLevelView from './organization-level-view'

const OrganizationLevelStyles = styled(OrganizationLevelView)(
  () => css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 40px 0 40px;
    min-width: 200px;
  `
)

// export default withSpinner(OrganizationLevelStyles)
export default OrganizationLevelStyles
