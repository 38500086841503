import { Box, Tab, Tabs as MuiTabs, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { isNumber } from 'lodash'
import ActivityCreateModal from 'components/activity-create-modal'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import Spinner from 'components/spinner'
import ModalContent from 'components/modal-content'
import { useFormatMessage } from 'localization'
import React from 'react'
import styled from 'styled-components'
import { BasicInformation, DesiredActivities } from './components'
import DesiredTemporaryActivities from './components/desired-temporary-activities/desired-temporary-activities-view'
import PausedPeriod from './components/paused-period'
import PausedPeriodDialog from './components/paused-period/pause-period-dialog'
import { PausedPeriodContext } from './components/paused-period/paused-period-context'
import { CustomerModalContext } from './customer-modal-context'

const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
`

type ITabPanelProps = Readonly<{
  index: number
  value: number
}>

const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CustomerInfoModalView: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const { fetchCustomerInfoAsync: fetchCustomerInfoActivityCreateModal } = ActivityCreateModalContext.useActions()
  const { activities, isLoading, temporaryActivities, pausedPeriods, customerInfo, customerId } = CustomerModalContext.useState()
  const {
    fetchCustomerInfoAsync,
    clearUser,
    setCustomerHasActivityOnCurrentAreaTemplateActivity,
  } = CustomerModalContext.useActions()

  const handleClose = React.useCallback(() => {
    clearUser()
  }, [clearUser])

  const [value, setValue] = React.useState(0)

  const handleChange = React.useCallback( (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue)
  },[value])

  const basicInformationRef = React.createRef<any>()

  const handleSaveClick = (tabNumber: number) => () => {
    switch (tabNumber) {
      case 0:
        basicInformationRef.current.handleSubmit()
        break
      default:
        handleClose()
        break
    }
  }

  const handleSaveCreateActivity = () => {
    fetchCustomerInfoAsync(customerInfo!.id)
  }

  React.useEffect(() => {
    if(isNumber(customerId)){
      setCustomerHasActivityOnCurrentAreaTemplateActivity(false)
      fetchCustomerInfoActivityCreateModal(+customerId)
      fetchCustomerInfoAsync(+customerId)
    }
  }, [
    customerId,
    fetchCustomerInfoActivityCreateModal,
    fetchCustomerInfoAsync,
    setCustomerHasActivityOnCurrentAreaTemplateActivity,
  ])

  return (
    <CenterModal open={isNumber(customerId)} onClose={handleClose} fullSize>
      {!customerInfo || !activities || !pausedPeriods || !temporaryActivities ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          <ModalHeader data-matomo-mask title={customerInfo.fullName} />
          <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} centered={true}>
            <Tab label={f('CUSTOMER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE')} {...a11yProps(0)} />
            <Tab label={`${f('CUSTOMER_DIALOG_VIEW_TAB_DESIRED_ACTIVITIES_TITLE')} (${activities.length})`} {...a11yProps(1)} />
            <Tab label={`${f('CUSTOMER_DIALOG_TAB_TEMPORARY_ACTITIVIES_TITLE')} (${temporaryActivities.length})`} {...a11yProps(2)} />
            <Tab label={`${f('CUSTOMER_DIALOG_PAUSED_PERIOD_TITLE')} (${pausedPeriods.length})`} {...a11yProps(3)} />
          </Tabs>
          <ModalContent>
            <TabPanel value={value} index={0}>
              <BasicInformation customRef={basicInformationRef} />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <DesiredActivities />
              <ActivityCreateModal onSave={handleSaveCreateActivity} />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <DesiredTemporaryActivities />
            </TabPanel>

            <TabPanel value={value} index={3}>
              <PausedPeriodContext.Provider>
                <PausedPeriod />
                <PausedPeriodDialog />
              </PausedPeriodContext.Provider>
            </TabPanel>
          </ModalContent>
          <ModalFooter>
            <TextButton onClick={handleClose} disabled={isLoading}>
              {f('BASIC_BUTTON_CANCEL_TEXT')}
            </TextButton>
            <ContainedButton onClick={handleSaveClick(value)} disabled={isLoading} color="secondary">
              {f('BASIC_BUTTON_SAVE_TEXT')}
            </ContainedButton>
          </ModalFooter>
        </>
      )}
    </CenterModal>
  )
}

export default CustomerInfoModalView
