import { Avatar, Box, IconButton, Menu, MenuItem, Popover, Typography, Tooltip } from '@mui/material'
import { IPlannedActivityRowItem, IPlannedActivityRowPlanningViewItem, RecurrenceEnum, PlannedActivityStatusEnum, SeverityLevelEnum, PlannedActivityRowPlanningViewItem } from 'api/tillit.api-client'
import OutlinedButton from 'components/inputs/outlined-button'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'
import {
  Account as AccountIcon,
  ClockOutline as ClockOutlineIcon,
  Comment as CommentIcon,
  AccountMultiple as ParticipantsIcon,
  Pencil as PencilIcon,
  Text as TextSubjectIcon,
  ClockAlert as TimeCritical,
  SyncOff as TemporaryActivity
} from 'mdi-material-ui'

import { Delete } from 'mdi-material-ui'
import styled from 'styled-components'

import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React, { SyntheticEvent } from 'react'
import { ActivityStatusItem, generateActivityStatusText } from 'utilities/generate-activity-status-text'

import { Colors } from 'styles/colors'
import format from 'utilities/format'
import { useIsPlanningReadonly } from 'utilities/use-is-planning-readonly'
import Authentication from 'components/authentication'
import SortedParticipants from 'components/sorted-participants'
import { hasCompleteStatus } from 'utilities/planned-activity-helper'
import EditCommentModal from '../edit-comment-modal'
import EditParticipantsModal from '../edit-participants-modal'
import { StyledInfoActivity } from './popover-info-activity-styles'

export interface IInfoActivityPopover {
  open: boolean
  id: number
  position: { top: number; left: number } | null
}

const PopoverInfoActivity: React.FC = () => {
  const f = useFormatMessage()
  const { usingCalendarFromDevice } = Authentication.useAuthenticatedState()

  const {
    dialogInfoActivityOpen,
    plannedActivity,
    unplannedRouteId,
    routes = [],
    calendarStartDate,
    menuOpen,
    anchorPopEl,
    activityToUnplan,
  } = PlanningPageContext.useState()

  const {
    closeDialogInfoActivity,
    detachedFromSeries,
    unplanActivity,
    unplanAllPlannedActivity,
    setConfirmUnplanPenMarkedModalOpen,
    setConfirmUnplanFutureChangesModalOpen,
    setConfirmUnplanPenmarkedAndFutureChangesModalOpen,
    setMenuOpen,
    setAnchorPopEl,
    setActivityToUnplan,
    setEditCommentModalData,
    setOpenEditCommentModal,
    setEditParticipantsModalData,
    removeDetachedComment,
    fetchCustomersOnPlanningAreaAndOpenParticipantsModalAsync,
  } = PlanningPageContext.useActions()

  const readonlyPlanning = useIsPlanningReadonly(calendarStartDate) || usingCalendarFromDevice

  const handleMenuClose = (event: SyntheticEvent) => {
    setMenuOpen(false)
    setAnchorPopEl(null)
    setActivityToUnplan(null)
  }

  const handleClose = () => {
    closeDialogInfoActivity()
  }

  const dateFilter = (time: Date) => {
    return format(time, 'HH:mm')
  }

  const timeFilter = (time: number) => {
    if (time < 10) {
      return `0${time}`
    } else {
      return time
    }
  }

  const getDuration = (activityItem: IPlannedActivityRowItem) => {
    const timeStart = new Date(activityItem.startTime)
    const timeEnd = new Date(activityItem.endTime)
    return `${(+timeEnd - +timeStart) / 60 / 1000} Minuter`
  }

  const handleUnplan = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (unplannedRouteId && plannedActivity) {
      for (const route of routes) {
        const { plannedRoutes = [] } = route
        const activity = plannedRoutes
          .filter(x => x.plannedActivityRows !== undefined)
          .map(x => x.plannedActivityRows!)
          .flat()
          .find((plannedActivityRow: IPlannedActivityRowPlanningViewItem) => plannedActivityRow.id === plannedActivity.id)

        if (activity !== undefined) {
          if (activity_RecurrenceId === RecurrenceEnum.Weekly && !plannedActivity.detachedFromSeries) {
            setActivityToUnplan(activity) // IPlannedActivityRowPlanningViewItem
            setAnchorPopEl(event.target as HTMLButtonElement)
            setMenuOpen(!menuOpen)
          } else {
            const hasDetached = plannedActivity?.hasFutureDetachedFromDaySeries || plannedActivity?.hasFutureDetachedFromSeries

            setActivityToUnplan(activity) // IPlannedActivityRowPlanningViewItem
            if (hasDetached && !plannedActivity?.hasFutureActivityRowChanges) {
              // User wants to unplan day series but there are pen marked future instances -> show dialog for confirmation
              setConfirmUnplanPenMarkedModalOpen(plannedActivity, activity_RecurrenceId === RecurrenceEnum.Weekly)
            } else if (!hasDetached && plannedActivity?.hasFutureActivityRowChanges) {
              if (activity_RecurrenceId === RecurrenceEnum.OneTime) {
                //onTime activity
                unplan(activity, true, true, true)
              } else {
                //User wants to unplan day series but there are future changed instances -> show dialog for confirmation
                setConfirmUnplanFutureChangesModalOpen(plannedActivity, activity_RecurrenceId === RecurrenceEnum.Weekly)
              }
            } else if (hasDetached && plannedActivity?.hasFutureActivityRowChanges) {
              // User wants to unplan day series but there are pen marked future instances and future changes instances -> show dialog for confirmation
              setConfirmUnplanPenmarkedAndFutureChangesModalOpen(plannedActivity, activity_RecurrenceId === RecurrenceEnum.Weekly)
            } else {
              // User wants to unplan day series and there are no pen marked future instances and no future changes instances -> go ahead and unplan
              setMenuOpen(false)
              setAnchorPopEl(null)
              unplan(activity, false)
            }
          }
          break
        }
      }
    }
  }

  const unplan = React.useCallback(
    (activity: IPlannedActivityRowPlanningViewItem | null, includePenMarked: boolean, overwrite?: boolean, implementChangesUntil?: boolean) => {
      if (activity) {
        unplanActivity(activity, overwrite, includePenMarked, implementChangesUntil).then(() => {
          setActivityToUnplan(null)
        })
      }
    },
    [unplanActivity, setActivityToUnplan]
  )

  const handleUnplanActivityRow = React.useCallback(
    (event: SyntheticEvent) => {
      if (plannedActivity?.hasFutureDetachedFromDaySeries && !plannedActivity?.hasFutureActivityRowChanges) {
        // User wants to unplan day series but there are pen marked future instances -> show dialog for confirmation
        setConfirmUnplanPenMarkedModalOpen(plannedActivity, true)
      } else if (!plannedActivity?.hasFutureDetachedFromDaySeries && plannedActivity?.hasFutureActivityRowChanges) {
        // User wants to unplan day series but there are future changed instances -> show dialog for confirmation
        setConfirmUnplanFutureChangesModalOpen(plannedActivity, true)
      } else if (plannedActivity?.hasFutureDetachedFromDaySeries && plannedActivity?.hasFutureActivityRowChanges) {
        // User wants to unplan day series but there are pen marked future instances and future changes instances -> show dialog for confirmation
        setConfirmUnplanPenmarkedAndFutureChangesModalOpen(plannedActivity, true)
      } else {
        // User wants to unplan day series and there are no pen marked future instances and no future changes instances -> go ahead and unplan
        setMenuOpen(false)
        setAnchorPopEl(null)
        unplan(activityToUnplan, false)
      }
    },
    [
      activityToUnplan,
      unplan,
      plannedActivity,
      setConfirmUnplanPenMarkedModalOpen,
      setConfirmUnplanFutureChangesModalOpen,
      setConfirmUnplanPenmarkedAndFutureChangesModalOpen,
      setMenuOpen,
      setAnchorPopEl,
    ]
  )

  const handleUnplanAllActivityRows = React.useCallback(
    (event: SyntheticEvent) => {
      if (plannedActivity?.hasFutureDetachedFromSeries && !plannedActivity?.hasFutureChanges) {
        // User wants to unplan whole series but there are pen marked future instances -> show dialog for confirmation
        setConfirmUnplanPenMarkedModalOpen(plannedActivity, false)
      } else if (!plannedActivity?.hasFutureDetachedFromSeries && plannedActivity?.hasFutureChanges) {
        // User wants to unplan whole series but there are future changed instances -> show dialog for confirmation
        setConfirmUnplanFutureChangesModalOpen(plannedActivity, false)
      } else if (plannedActivity?.hasFutureDetachedFromSeries && plannedActivity?.hasFutureChanges) {
        // User wants to unplan whole series but there are pen marked future instances and future changes instances -> show dialog for confirmation
        setConfirmUnplanPenmarkedAndFutureChangesModalOpen(plannedActivity, false)
      } else {
        // User wants to unplan whole series and there are no pen marked future instances and no future changes instances -> go ahead and unplan
        setMenuOpen(false)
        setAnchorPopEl(null)
        if (!plannedActivity) {
          return
        }
        const pari = new PlannedActivityRowPlanningViewItem({
          activity_SeverityLevelId: plannedActivity.activity_SeverityLevelId,
          activityReccurrenceId: Object.keys(RecurrenceEnum).indexOf(plannedActivity.activity_RecurrenceId) + 1,
          activityRowHasFutureChanges: plannedActivity.hasFutureActivityRowChanges ? plannedActivity.hasFutureActivityRowChanges : false,
          activityRowId: plannedActivity.activityRowId,
          activityShowColorOnDevice: plannedActivity.activity_ShowColorOnDevice,
          createdOn: plannedActivity.createdOn,
          detachedFromSeries: plannedActivity.detachedFromSeries,
          endTime: plannedActivity.endTime,
          hasUnfulfilledDelegations: false,
          id: plannedActivity.id,
          plannedActivityStatusId: plannedActivity.plannedActivityStatusId,
          plannedRouteId: plannedActivity.plannedRouteId,
          startTime: plannedActivity.startTime,
          useParticipantList: plannedActivity.useParticipantList,
          activityColor: plannedActivity.activity_Color,
          activityName: plannedActivity.activity_Title,
          comment: plannedActivity.comment,
          detachedBy: undefined,
          detachedTime: undefined,
          ownerAreaId: plannedActivity.activity_OwnerAreaId,
          ownerAreaName: plannedActivity.activity_OwnerAreaName,
          ownerCustomerFirstName: plannedActivity.activity_OwnerCustomerFirstName,
          ownerCustomerId: plannedActivity.activity_OwnerCustomerId,
          ownerCustomerLastName: plannedActivity.activity_OwnerCustomerLastName,
          plannedActivityParticipants: plannedActivity.plannedActivityParticipants,
          routeColor: undefined,
          routeName: undefined,
          templateActivityTitle: plannedActivity.templateActivityTitle,

        })
        void unplanAllPlannedActivity(pari, false, false, undefined).then(() => {
          closeDialogInfoActivity()
        })
      }
    },
    [
      closeDialogInfoActivity,
      plannedActivity,
      unplanAllPlannedActivity,
      setConfirmUnplanPenMarkedModalOpen,
      setConfirmUnplanFutureChangesModalOpen,
      setConfirmUnplanPenmarkedAndFutureChangesModalOpen,
      setMenuOpen,
      setAnchorPopEl,
    ]
  )

  const handleDetached = React.useCallback(() => {
    detachedFromSeries(dialogInfoActivityOpen.id)
  }, [detachedFromSeries, dialogInfoActivityOpen.id])

  const handleDetachedComment = React.useCallback(
    (event: SyntheticEvent) => {
      setEditCommentModalData(plannedActivity)
      setOpenEditCommentModal(true)
    },
    [plannedActivity, setEditCommentModalData, setOpenEditCommentModal]
  )

  const handleDetachedParticipants = React.useCallback(
    (event: SyntheticEvent) => {
      setEditParticipantsModalData(plannedActivity)
      fetchCustomersOnPlanningAreaAndOpenParticipantsModalAsync()
      //      setOpenEditParticipantsModal(true)
    },
    [plannedActivity, setEditParticipantsModalData, fetchCustomersOnPlanningAreaAndOpenParticipantsModalAsync]
  )

  if (!plannedActivity) {
    return null
  }

  const handleDeleteComment = () => {
    removeDetachedComment(dialogInfoActivityOpen.id)
  }

  const {
    activityRow,
    plannedRoute,
    activity_ActivityRows = [],
    activity_Title,
    activity_Color,
    activity_RecurrenceId,
    activity_RecurrenceInterval,
    activity_OwnerCustomerFirstName,
    activity_OwnerCustomerLastName,
    activity_OwnerCustomerRoomNumber,
    templateActivityTitle,
  } = plannedActivity

  if (!activityRow || !plannedRoute) {
    return null
  }

  const { fromTimeHour, fromTimeMinute, toTimeHour, toTimeMinute, monthlyDayId } = activityRow

  const { calendarDay } = plannedRoute

  const days = activity_ActivityRows.reduce(
    (prev, current) => {
      if (!!current.weeklyDayId) {
        return { ...prev, [current.weeklyDayId]: true }
      }
      return prev
    },
    {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    }
  )

  const fromTime = new Date()
  fromTime.setHours(fromTimeHour)
  fromTime.setMinutes(fromTimeMinute)
  const toTime = new Date()
  toTime.setHours(toTimeHour)
  toTime.setMinutes(toTimeMinute)
  const value: ActivityStatusItem = {
    ...days,
    startDate: plannedActivity.activity_StartDate,
    endDate: plannedActivity.activity_EndDate,
    recurrenceId: activity_RecurrenceId,
    recurrenceInterval: activity_RecurrenceInterval,
    fromTime,
    toTime,
    monthlyDayId,
  }

  const isComplete = hasCompleteStatus(plannedActivity.plannedActivityStatusId)

  const isBeforeCalendarDay = plannedActivity.startTime.getDate() < calendarStartDate.getDate()

  const shouldShowPen = activity_RecurrenceId !== RecurrenceEnum.OneTime && !readonlyPlanning && !isBeforeCalendarDay && !isComplete && plannedActivity.isPlanned

  const combinedActivityTitle = templateActivityTitle
    ? templateActivityTitle + ' ' + (activity_Title ? '(' + activity_Title + ')' : '')
    : activity_Title
      ? activity_Title
      : ''

  return (
    <>
      {
        <Popover
          open={dialogInfoActivityOpen.open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: dialogInfoActivityOpen.position ? dialogInfoActivityOpen.position.top : 0,
            left: dialogInfoActivityOpen.position ? dialogInfoActivityOpen.position.left : 0,
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <StyledInfoActivity color={activity_Color}>
            <Box display="flex">
              <div style={{ marginRight: '10px' }}>
                <Avatar className="avatar" style={{ marginTop: '5px' }} />
              </div>
              <div>
                <Typography data-matomo-mask variant="h6">{combinedActivityTitle}</Typography>
                <Typography variant="subtitle1" style={{ marginBottom: '4px' }}>
                  {format(calendarDay, 'PPPP')}
                </Typography>
                {plannedActivity.isPlanned && (
                  <Typography variant="subtitle1" style={{ marginBottom: '4px' }}>
                    {`${f('POPOVER_INFO_ACTIVITY_PLANNED')} ${dateFilter(plannedActivity.startTime)} ${f('POPOVER_INFO_ACTIVITY_UNTIL')} ${dateFilter(
                      plannedActivity.endTime
                    )} (${getDuration(plannedActivity)})`}
                  </Typography>
                )}
                {plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.Completed && (
                  <Typography data-matomo-mask variant="subtitle1" style={{ marginBottom: '4px' }} color={Colors.JungleGreen} >
                    {f('POPOVER_INFO_ACTIVITY_COMPLETED', {
                      signingDate: plannedActivity.signing_LatestSigningDate ? format(plannedActivity.signing_LatestSigningDate, 'Pp') : '',
                      signedBy: plannedActivity.signing_LatestSigningUser?.fullName
                    })}
                  </Typography>
                )}
                {plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.CompletedWithoutAction && (
                  <Typography data-matomo-mask variant="subtitle1" style={{ marginBottom: '4px' }} color={Colors.RedOrange}>
                    {f('POPOVER_INFO_ACTIVITY_COMPLETEDWITHOUTACTION', {
                      signingDate: plannedActivity.signing_LatestSigningDate ? format(plannedActivity.signing_LatestSigningDate, 'Pp') : '',
                      signedBy: plannedActivity.signing_LatestSigningUser?.fullName
                    })}
                  </Typography>
                )}

                <Typography variant="subtitle1" style={{ marginBottom: '0px' }}>
                  {value.recurrenceId === RecurrenceEnum.OneTime ? f('POPOVER_INFO_ACTIVITY_NOT_IN_A_SERIES') : generateActivityStatusText(f)(value)}
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: '10px', fontWeight: 600 }}>
                  {plannedActivity.detachedFromSeries ? f('POPOVER_INFO_ACTIVITY_TEMPORARY_CHANGE') : ''}
                </Typography>
                <Box display="flex" minWidth={380}>
                  {shouldShowPen && (
                    <>
                      {plannedActivity.detachedFromSeries ? (
                        <ContainedButton containerStyle={{ margin: 0 }} onClick={handleDetached} color={'secondary'}>
                          <PencilIcon />
                        </ContainedButton>
                      ) : (
                        <OutlinedButton onClick={handleDetached} color={'primary'}>
                          <PencilIcon />
                        </OutlinedButton>
                      )}
                      {plannedActivity.detachedFromSeries && (
                        <>
                          <Tooltip
                            title={f('POPOVER_INFO_ACTIVITY_COMMENT')}
                            aria-label={f('POPOVER_INFO_ACTIVITY_COMMENT')}>
                            <OutlinedButton disabled={isComplete} onClick={handleDetachedComment} color="primary" style={{ marginLeft: '8px' }}>
                              <CommentIcon />
                            </OutlinedButton>
                          </Tooltip>
                          {plannedActivity.useParticipantList && (
                            <Tooltip
                              title={f('POPOVER_INFO_ACTIVITY_PARTICIPANTS')}
                              aria-label={f('POPOVER_INFO_ACTIVITY_PARTICIPANTS')}>
                              <OutlinedButton disabled={isComplete} onClick={handleDetachedParticipants} color="primary" style={{ marginLeft: '8px' }}>
                                <ParticipantsIcon />
                              </OutlinedButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {!readonlyPlanning && !isBeforeCalendarDay && !isComplete && value.recurrenceId === RecurrenceEnum.OneTime && plannedActivity.useParticipantList && (
                    <Tooltip
                      title={f('POPOVER_INFO_ACTIVITY_PARTICIPANTS')}
                      aria-label={f('POPOVER_INFO_ACTIVITY_PARTICIPANTS')}>
                      <OutlinedButton disabled={isComplete} onClick={handleDetachedParticipants} color="primary" style={{ marginLeft: '8px' }}>
                        <ParticipantsIcon />
                      </OutlinedButton>
                    </Tooltip>
                  )}

                  <Box pr={1} />
                  {!readonlyPlanning && !isBeforeCalendarDay && !isComplete && plannedActivity.isPlanned && (
                    <OutlinedButton color="error" onClick={handleUnplan}>
                      {f('POPOVER_INFO_ACTIVITY_UNDO_BUTTON')}
                    </OutlinedButton>
                  )}
                  <Menu id="simple-menu" keepMounted={true} open={menuOpen} anchorEl={anchorPopEl} anchorReference="anchorEl" onClose={handleMenuClose}>
                    <MenuItem onClick={handleUnplanActivityRow}>{f('POPOVER_INFO_ACTIVITY_UNDO_BUTTON_SERIES')}</MenuItem>
                    <MenuItem onClick={handleUnplanAllActivityRows}>{f('POPOVER_INFO_ACTIVITY_UNDO_BUTTON_ALL')}</MenuItem>
                  </Menu>
                </Box>
              </div>
            </Box>
            <div
              style={{
                marginTop: '12px',
                marginBottom: '12px',
                height: '1px',
                backgroundColor: Colors.Alto,
              }}
            />
            {(activity_OwnerCustomerFirstName && activity_OwnerCustomerLastName || activity_OwnerCustomerRoomNumber) && (
              <Box display="flex">
                <div style={{ marginRight: '10px' }}>
                  <AccountIcon height="18px" />
                </div>
                {usingCalendarFromDevice && activity_OwnerCustomerRoomNumber && activity_OwnerCustomerRoomNumber.trim().length > 0 ? (
                  <Typography data-matomo-mask variant="body1">{`${activity_OwnerCustomerRoomNumber}`}</Typography>
                ) : (
                  <Typography data-matomo-mask variant="body1">{`${activity_OwnerCustomerFirstName} ${activity_OwnerCustomerLastName}`}</Typography>
                )}
              </Box>
            )}
            <Box display="flex">
              <Tooltip
                title={f('POPOVER_INFO_ACTIVITY_TIMESPAN')}
                aria-label={f('POPOVER_INFO_ACTIVITY_TIMESPAN')}>
                <div style={{ marginRight: '10px' }}>
                  <ClockOutlineIcon height="18px" />
                </div>
              </Tooltip>
              <Typography variant="body1">
                {timeFilter(activity_ActivityRows[0].fromTimeHour)}:{timeFilter(activity_ActivityRows[0].fromTimeMinute)} {' - '}
                {timeFilter(activity_ActivityRows[0].toTimeHour)}:{timeFilter(activity_ActivityRows[0].toTimeMinute)}
              </Typography>
            </Box>
            {plannedActivity.activity_SeverityLevelId === SeverityLevelEnum.Critical && (
              <Box display="flex">
                <div style={{ marginRight: '10px' }}>
                  <TimeCritical height="18px" />
                </div>
                <Typography variant="body1">{f('POPOVER_INFO_ACTIVITY_SEVERITYLEVEL_TIMECRITICAL')}</Typography>
              </Box>
            )}
            {plannedActivity.activity_RecurrenceId === RecurrenceEnum.OneTime && (
              <Box display="flex">
                <div style={{ marginRight: '10px' }}>
                  <TemporaryActivity height="18px" />
                </div>
                <Typography variant="body1">{f('POPOVER_INFO_ACTIVITY_RECURRANCE_ONETIME')}</Typography>
              </Box>
            )}
            {/* Missing comment for desired activity on IPlannedActivityRowItem */}
            {plannedActivity.activity_Comment && (
              <Box display="flex">
                <div style={{ marginRight: '10px' }}>
                  <TextSubjectIcon height="18px" />
                </div>
                <Typography data-matomo-mask variant="body1">{plannedActivity.activity_Comment}</Typography>
              </Box>
            )}
            {plannedActivity.templateActivityInheritanceDescription && (
              <Box display="flex">
                <div style={{ marginRight: '10px' }}>
                  <TextSubjectIcon height="18px" />
                </div>
                <Typography data-matomo-mask variant="body1">{plannedActivity.templateActivityInheritanceDescription}</Typography>
              </Box>
            )}
            {plannedActivity.useTemplateActivityDescription && plannedActivity.templateActivityDescription && (
              <Box display="flex">
                <div style={{ marginRight: '10px' }}>
                  <TextSubjectIcon height="18px" />
                </div>
                <Typography data-matomo-mask variant="body1">{plannedActivity.templateActivityDescription}</Typography>
              </Box>
            )}

            {/* Missing comment for desired activity on IPlannedActivityRowItem */}
            {plannedActivity.comment && (
              <Box display="flex">
                <CommentsWrapper>
                  <Tooltip
                    title={f('POPOVER_INFO_ACTIVITY_COMMENT')}
                    aria-label={f('POPOVER_INFO_ACTIVITY_COMMENT')}>
                    <div style={{ marginRight: '10px' }}>
                      <CommentIcon height="18px" />
                    </div>
                  </Tooltip>
                  <Typography data-matomo-mask variant="body1">{plannedActivity.comment}</Typography>
                  {!isComplete && (
                    <DeleteIconButton>
                      <IconButton size="small" onClick={handleDeleteComment}>
                        <Delete htmlColor="white" />
                      </IconButton>
                    </DeleteIconButton>
                  )}
                </CommentsWrapper>
              </Box>
            )}

            {plannedActivity.useParticipantList && plannedActivity.plannedActivityParticipants && plannedActivity.plannedActivityParticipants.length > 0 && (
              <Box display="flex">
                <CommentsWrapper>
                  <Tooltip
                    title={f('POPOVER_INFO_ACTIVITY_PARTICIPANTS')}
                    aria-label={f('POPOVER_INFO_ACTIVITY_PARTICIPANTS')}>
                    <div style={{ marginRight: '10px' }}>
                      <ParticipantsIcon height="18px" />
                    </div>
                  </Tooltip>
                  <SortedParticipants participants={plannedActivity.plannedActivityParticipants} />
                </CommentsWrapper>
              </Box>
            )}

          </StyledInfoActivity>
          <EditCommentModal />
          <EditParticipantsModal />
        </Popover>
      }
    </>
  )
}

const DeleteIconButton = styled.div`
    position: relative;
    min-width: 34px;
    min-height: 34px;
    max-height: 34px;
    margin-left: auto;
    margin-right: 0px;
    background-color: #DF3E31;
    border-radius: 5px;
  `

const CommentsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  width: 100%;

  ${DeleteIconButton} {
    display: none;
  }

  &:hover {
    ${DeleteIconButton} {
      display: inherit;
    }
  }
`

export default PopoverInfoActivity
