import { Box, Tabs } from '@mui/material'
import styled from 'styled-components'

export const areaBackgroundColor = '#f9f9f9'

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    height: 100%;
    background-color: ${areaBackgroundColor};
  }

  /* TODO: This should not be 100vh, do we need to set this? */
  /* .MuiTabs-scrollable {
    width: 100vh;
  } */
`

export const StyledDayPickerBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`
