import { Box } from '@mui/material'
import CenterModal from 'components/center-modal'
import ModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import { ModalBox } from 'pages/daily-activities/daily-activities-container'
import React from 'react'
import styled from 'styled-components'
import { InfoExpiringDelegation } from './components/info-expiring-delegation'
import { ExpiringDelegationsModalContext } from './expiring-delegations-modal-context'

const ExpiringDelegationsModalContainer: React.FC = () => {
  const f = useFormatMessage()
  const { expiringDelegationsModalOpen, expiringDelegations } = ExpiringDelegationsModalContext.useState()

  const { closeExpiringDelegationsModal } = ExpiringDelegationsModalContext.useActions()

  return (
    <CenterModal open={expiringDelegationsModalOpen} onClose={closeExpiringDelegationsModal} responsive>
      <ModalBox>
        <ModalHeader
          title={f('EXPIRING_DELEGATIONS_MODAL_HEADER_TITLE')}
          subtitle={f('EXPIRING_DELEGATIONS_MODAL_HEADER_SUBTITLE')}
          justifyContent="center"
        />
        <ModalBody>
          <Box sx={{ height: '300px', overflowY: 'auto', position: 'relative' }}>
            {expiringDelegations && expiringDelegations.length > 0 && expiringDelegations.map((expiringDelegation, index) => {
              return (<InfoExpiringDelegation key={`inf_ed_${index}`} expiringDelegation={expiringDelegation} />)
            })}
          </Box>
        </ModalBody>
      </ModalBox>
    </CenterModal>
  )
}

const ModalBody = styled(Box)`
  > div:first-child {
    > div:first-child {
      
    }
  }
  ${(props) => [props.theme.breakpoints.down('md')]}{
    > div:first-child {
      flex-direction: column;
      > div:first-child {
        padding-bottom: 20px;
      }
    }

  }
`

export default ExpiringDelegationsModalContainer
