import { useCallback, useEffect, useState } from 'react'
import {
  OrganizationSigningStatusesItem,
  OrganizationPlannedActivityStatusUpdateItem,
  OrganizationPlannedActivityStatusCreateItem } from 'api/tillit.api-client'
import { useSigningStatusApi } from 'api'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { sortStatuses } from 'utilities/signing-status-helpers'

const useAdministerSigningStatuses = () => {
  const [signingStatuses, setSigningStatuses] = useState<OrganizationSigningStatusesItem>()

  const { listSigningStatuses, abort, updateSigningStatus, createSigningStatus, RemoveSigningStatus } = useSigningStatusApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const fetchListSigningStatuses = useCallback(() => {
    if (!currentUser.organizationId) {
      return
    }
    fetchAsync(listSigningStatuses(currentUser.organizationId).then( statuses => setSigningStatuses(sortStatuses(statuses))))
  }, [currentUser, fetchAsync, listSigningStatuses])

  const handleUpdateSigningStatus = useCallback((status : OrganizationPlannedActivityStatusUpdateItem)=>fetchAsync(updateSigningStatus(status)),[])

  const handleCreateSigningStatus = useCallback((status : OrganizationPlannedActivityStatusCreateItem)=>fetchAsync(createSigningStatus(status)),[])

  const handleRemoveSigningStatus = useCallback((statusId : number | undefined)=>()=>{
    if(statusId){
      return fetchAsync(RemoveSigningStatus(statusId)).then(fetchListSigningStatuses)
    }
    return
  },[])

  useEffect(() => () => abort(), [abort])

  useEffect(() => {
    void fetchListSigningStatuses()
  }, [fetchListSigningStatuses])


  return {
    state: {
      orgId:currentUser.organizationId,
      signingStatuses,
      ...stateOfFetch
    },
    actions: {
      fetchListSigningStatuses,
      handleUpdateSigningStatus,
      handleCreateSigningStatus,
      handleRemoveSigningStatus
    }
  }
}

export const AdministerSigningStatusesContext = createContext(useAdministerSigningStatuses)
