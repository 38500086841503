import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAuthorization from 'utilities/use-authorization'
import { RoleEnum } from 'api/tillit.api-client'
import PageRoute from 'config/page-routes'

type Props = Readonly<{
  allowedRoles: RoleEnum[];
}>

const RoleBasedRouteContainer: React.FC<Props> = ({ allowedRoles }) => {
  const { hasRoleOnAnyArea } = useAuthorization()

  return (
    hasRoleOnAnyArea(...allowedRoles)
    ?
      <Outlet />
    :
      <Navigate to={PageRoute.Profile.path} />
  )

}

export default RoleBasedRouteContainer
