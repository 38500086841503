import ContainedButton from 'components/inputs/contained-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import React from 'react'

const PrivacyPolicyView: React.FC = () => {
  const f = useFormatMessage()

  const { toggleModalPrivacyPolicy } = ProfilePageContext.useActions()

  const handleOpenPrivacyPolicy = React.useCallback(
    () => toggleModalPrivacyPolicy(true),
    [toggleModalPrivacyPolicy]
  )

  return (
    <ModalSection
      title={f('PRIVACY_POLICY_TITLE')}
      subtitle={f('PRIVACY_POLICY_SUBTITLE')}
      responsive>
      <ModalSectionRow responsive>
        <ContainedButton color="secondary" onClick={handleOpenPrivacyPolicy}>
          {f('PRIVACY_POLICY_BUTTON_LINK')}
        </ContainedButton>
      </ModalSectionRow>
    </ModalSection>
  )
}

export default PrivacyPolicyView
