import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material'
import React from 'react'

const DeleteDialogContainer: React.FC<{
  dialogTitle: string
  dialogContentText: string
  buttonSubmitText: string
  buttonCancelText: string
  deleteDialogOpen: boolean
  onClose: () => void
  onSubmit: () => void
}> = ({ dialogTitle, dialogContentText, buttonCancelText, buttonSubmitText, deleteDialogOpen, onClose, onSubmit }) => {
  const theme = useTheme()

  return (
    <Dialog open={deleteDialogOpen} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText data-matomo-mask>{dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {buttonCancelText}
        </Button>
        <Box color={theme.palette.error.main}>
          <Button onClick={onSubmit} color="inherit" autoFocus={true}>
            {buttonSubmitText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialogContainer
