import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'

import { TemplateActivityCategoryListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'

import React from 'react'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterTemplateActivityCategories } from 'utilities/template-activity-helper'
import { TemplateActivityCategoryModalContext } from '../template-activity-category-modal/template-activity-category-modal-context'
import { AdministerTemplateActivityCategoriesContext } from './administer-template-activity-categories-context'

const AdministerTemplateActivityCategoriesView = () => {

  const { user: currentUser } = Authentication.useAuthenticatedState()
  const {
    templateActivityCategoryListItems,
    removeDialogOpen,
    templateActivityToRemove,
    isLoading,
    removeInvalidDialogOpen,
    templateActivityInvalidToRemove,
  } = AdministerTemplateActivityCategoriesContext.useState()
  const {
    openRemoveDialog,
    closeRemoveDialog,
    deleteTemplateActivityAsync,
    closeRemoveInvalidDialog,
  } = AdministerTemplateActivityCategoriesContext.useActions()

  const { openTemplateActivityCategoryModal, setOwnerOrganizationId } = TemplateActivityCategoryModalContext.useActions()

  const f = useFormatMessage()
  const getFilteredTemplateActivityCategories = filterTemplateActivityCategories(f)
  const handleAddNewTemplateActivityCategoryClick = React.useCallback(() => {
    setOwnerOrganizationId(currentUser.organizationId)
    openTemplateActivityCategoryModal()
  }, [openTemplateActivityCategoryModal, setOwnerOrganizationId, currentUser])

  const handleRemoveTemplateActivityClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, templateActivityCategory: TemplateActivityCategoryListItem) => {
      _?.stopPropagation()
      openRemoveDialog(templateActivityCategory)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveTemplateActivityClick = React.useCallback(() => deleteTemplateActivityAsync(templateActivityToRemove!.id), [
    templateActivityToRemove,
    deleteTemplateActivityAsync,
  ])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openTemplateActivityCategoryModal(params.row.id)
    },
    [openTemplateActivityCategoryModal]
  )

  const sortAccordingToTitle = (title1: string, title2: string): number => {
    if (title1 && title2 && title1 < title2) {
      return -1
    }
    if (title1 && title2 && title1 > title2) {
      return 1
    }
    return 0
  }

  const sortedTemplateActivityCategoryListItems = [...templateActivityCategoryListItems].sort((a, b) => {
    return sortAccordingToTitle(a.title ? a.title : '', b.title ? b.title : '')
  })

  const legislationsLookup: any[] = []
  sortedTemplateActivityCategoryListItems?.forEach(ta => {
    if (ta.legislationId && !legislationsLookup[ta.legislationId]) {
      legislationsLookup[ta.legislationId] = ta.legislationTitle
    }
  })

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredTemplateActivityCategories, setFilteredTemplateActivityCategories] = React.useState<TemplateActivityCategoryListItem[]>(templateActivityCategoryListItems)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredTemplateActivityCategories(filterValue, templateActivityCategoryListItems)
      setFilteredTemplateActivityCategories(filtered)
    } else {
      setFilteredTemplateActivityCategories(templateActivityCategoryListItems)
    }
    // NOTE: We are missing filterValue and getFilteredTemplateActivityCategories as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [templateActivityCategoryListItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredTemplateActivityCategories(searchValue, templateActivityCategoryListItems)
      setFilteredTemplateActivityCategories(filtered)
    },
    [setFilterValue, setFilteredTemplateActivityCategories, templateActivityCategoryListItems, getFilteredTemplateActivityCategories]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredTemplateActivityCategories(templateActivityCategoryListItems)
    },
    [setFilterValue, setFilteredTemplateActivityCategories, templateActivityCategoryListItems]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '0.5vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && (
            <div style={{ columnCount: 2 }}>
              <ContainedButton type="button" color="secondary" onClick={handleAddNewTemplateActivityCategoryClick}>
                {f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_ADD_BUTTON_TEXT')}
              </ContainedButton>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}
          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredTemplateActivityCategories}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_TITLE'),
                field: 'title',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_LEGISLATION'),
                field: 'legislationTitle',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_deactivate',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title,
                        })}
                        aria-label={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title,
                        })}>
                        <IconButton onClick={e => handleRemoveTemplateActivityClick(e, params.row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />
          {/*
          <MaterialTable
            title={f('CUSTOMERS_PAGE_VIEW_HEAD_TITLE')}
            icons={tableIcons}
            style={{
              maxWidth: '100%',
              width: '100%',
              minHeight: '60vh',
              maxHeight: '60vh',
            }}
            localization={{
              toolbar: {
                searchPlaceholder: f('BASIC_SEARCH_FIELD_PLACEHOLDER'),
              },
              pagination: {
                labelRowsSelect: f('TABLE_PAGINATION_ROWS_TEXT'),
                labelDisplayedRows: f('TABLE_PAGINATION_COUNT_TEXT'),
                firstTooltip: f('TABLE_PAGINATION_FIRST_PAGE_TOOLTIP_TEXT'),
                previousTooltip: f('TABLE_PAGINATION_PREVIOUS_PAGE_TOOLTIP_TEXT'),
                nextTooltip: f('TABLE_PAGINATION_NEXT_PAGE_TOOLTIP_TEXT'),
                lastTooltip: f('TABLE_PAGINATION_LAST_PAGE_TOOLTIP_TEXT'),
              },
              body: {
                emptyDataSourceMessage: f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_NO_VALUES_DIPLAYED_TEXT'),
              },
            }}
            columns={[
              {
                title: f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_TITLE'),
                field: 'title',
                cellStyle: {
                  borderBottom: '0px',
                },
                filterCellStyle: {
                  backgroundColor: 'white',
                  borderBottom: '0px',
                  position: 'sticky',
                  top: 50,
                  height: 50,
                },
              },

              {
                title: f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_LEGISLATION'),
                field: 'legislationTitle',
                render: row => {
                  return <span>{!!row.legislationTitle && row.legislationTitle}</span>
                },
                customFilterAndSearch: (term, rowData) => {
                  let match = false
                  if (term?.length === 0) {
                    return true
                  } // nothing selected should return all rows...
                  if (!!rowData.legislationId) {
                    term?.forEach((t: any) => {
                      if (match) {
                        return
                      }
                      if (rowData.legislationId === parseInt(t)) {
                        match = true
                        return
                      }
                    })
                  }

                  return match
                },
                lookup: legislationsLookup,
                cellStyle: {
                  borderBottom: '0px',
                },
                filterCellStyle: {
                  backgroundColor: 'white',
                  borderBottom: '0px',
                  paddingTop: '0px',
                  position: 'sticky',
                  top: 50,
                  height: 50,
                },
              },

              {
                title: '',
                field: '',
                render: row => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_BUTTON_TOOLTIP', {
                          title: row.title ? row.title : '',
                        })}
                        aria-label={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_BUTTON_TOOLTIP', {
                          title: row.title ? row.title : '',
                        })}>
                        <IconButton onClick={e => handleRemoveTemplateActivityClick(e, row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
                cellStyle: {
                  borderBottom: '0px',
                },
                filterCellStyle: {
                  width: '10px',
                  zIndex: 1000,
                  backgroundColor: 'white',
                  borderBottom: '0px',
                  position: 'sticky',
                  top: 50,
                  height: 50,
                },
              },
            ]}
            data={sortedTemplateActivityCategoryListItems}
            onRowClick={handleRowClick}
            options={{
              padding: 'dense',
              emptyRowsWhenPaging: false,
              filtering: true,
              pageSizeOptions: [30, 50, 100],
              pageSize: 30,
              paging: false,
              maxBodyHeight: '60vh',
              headerStyle: {
                borderWidth: 0,
              },
            }}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Toolbar: props => <div />,
              Pagination: props => (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                    width: '50vw',
                  }}>
                  <TablePagination {...props} />
                </div>
              ),
            }}
          */}

          {removeDialogOpen && templateActivityToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_MESSAGE', {
                title: templateActivityToRemove.title,
              })}
              buttonSubmitText={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen={true}
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemoveTemplateActivityClick}
            />
          )}

          {removeInvalidDialogOpen && templateActivityInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_MESSAGE', {
                title: templateActivityInvalidToRemove.title,
              })}
              buttonOkText={f('ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen={true}
              onClose={closeRemoveInvalidDialog}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdministerTemplateActivityCategoriesView
