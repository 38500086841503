import styled, { css } from 'styled-components'

const BigCheckboxStyles = styled.div<{ checked: boolean }>(
  ({ checked }) => css`
    background-color: ${checked ? '#FFF8F4' : 'rgba(0, 0, 0, 0.09)'};
    border: 1px solid ${checked ? '#FED1B6' : 'transparent'};
    height: 58px;
    width: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    user-select: none;

    &:hover {
      background-color: ${checked ? '#FFF5EF' : 'rgba(0, 0, 0, 0.13)'};
      border-color: ${checked ? '#EAC0A7' : 'transparent'};
    }
  `
)

export default BigCheckboxStyles
