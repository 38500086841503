import React from 'react'
import { ModalsContext } from './modals-context'
import { KioskTemporaryActivityCreateModalContext } from './components/kiosk-temporary-activity-create-modal/kiosk-temporary-activity-create-modal-context'
import KioskActivityCreateModal from './components/kiosk-temporary-activity-create-modal'
import PinModal from './components/pin-modal'
import UserModal from './components/user-modal'
import DailyActivitiesContainer from './daily-activities-container'
import { DailyActivitiesContext } from './daily-activities-context'
import DroplistCustomer from './components/modal-droplist-customer'
import HistoryModal from './components/history-modal'
import FutureModal from './components/future-modal'
import { HistoryModalContext } from './components/history-modal/history-modal-context'
import { FutureModalContext } from './components/future-modal/future-modal-context'
import ExpiringDelegationsModal from './components/expiring-delegations-modal'
import WorkScheduleModal from './components/work-schedule-modal'
import InternalInformationModal from './components/internal-information-modal'
import { ExpiringDelegationsModalContext } from './components/expiring-delegations-modal/expiring-delegations-modal-context'
import { DailyActivitiesSigningStatusesContext } from './daily-activities-signing-statuses-context'
import { SingleActivityModalContext } from './components/single-activity-modal'
const DailyActivitiesView: React.SFC = () => {
  return (
    <ModalsContext.Provider>
      <HistoryModalContext.Provider>
        <FutureModalContext.Provider>
          <ExpiringDelegationsModalContext.Provider>
            <SingleActivityModalContext.Provider>
              <DailyActivitiesContext.Provider>
                <DailyActivitiesSigningStatusesContext.Provider>
                  <KioskTemporaryActivityCreateModalContext.Provider>
                    <DailyActivitiesContainer />
                    <WorkScheduleModal />
                    <UserModal />
                    <PinModal />
                    <DroplistCustomer />
                    <KioskActivityCreateModal />
                    <HistoryModal />
                    <FutureModal />
                    <ExpiringDelegationsModal />
                    <InternalInformationModal />
                  </KioskTemporaryActivityCreateModalContext.Provider>
                </DailyActivitiesSigningStatusesContext.Provider>
              </DailyActivitiesContext.Provider>
            </SingleActivityModalContext.Provider>
          </ExpiringDelegationsModalContext.Provider>
        </FutureModalContext.Provider>
      </HistoryModalContext.Provider>
    </ModalsContext.Provider>
  )
}
export default DailyActivitiesView
