import React from 'react'
import { Typography } from '@mui/material'
import { useFormatMessage } from 'localization'
import { ReportFieldItem, UserReportFieldItem, UserReportItem } from 'api/tenilo.reporting.api-client'
import ReportTableHeaderColumn from './components/report-table-header-column'

type Props = Readonly<{
  reportFields: ReportFieldItem[] | undefined
  userReport?: UserReportItem
  showRowCounter?: boolean,
  formFields: ReportFieldItem[] | undefined
  sortedUserReportFields: UserReportFieldItem[] | undefined
  sortedReportFields: ReportFieldItem[] | undefined
}>

const ReportTableHeader: React.FC<Props> = ({ reportFields, userReport, showRowCounter, formFields, sortedReportFields, sortedUserReportFields }) => {
  const f = useFormatMessage()
  return (
    <>
      {showRowCounter && <Typography variant="caption" fontWeight={600} sx={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>{f('BASIC_ROW_TEXT')}</Typography>}
      {userReport && reportFields && sortedUserReportFields?.map(urf => {
        const rf = reportFields.find(field => field.id === urf.reportFieldId)
        if (!rf) { return }

        const formField = formFields?.find(ff => ff.id === urf.reportFieldId)
        if (!formField) { return }

        return <ReportTableHeaderColumn key={`rt_rth_${rf.fieldIndex}`} reportField={rf} />
      })}
      {userReport && reportFields && formFields?.map(ff => {
        const rf = reportFields.find(field => field.id === ff.id)
        if (!rf) { return }

        const userReportField = sortedUserReportFields?.find(urf => urf.reportFieldId === ff.id)
        if (userReportField) { return }

        return <ReportTableHeaderColumn key={`rt_mrth_${rf.fieldIndex}`} reportField={rf} />
      })}
      {!userReport && sortedReportFields?.map(rf => {
        const formField = formFields?.find(ff => ff.id === rf.id)
        if (!formField) { return }

        return <ReportTableHeaderColumn key={`rt_rth_${rf.fieldIndex}`} reportField={rf} />
      })}
    </>
  )
}

export default React.memo(ReportTableHeader)
