import React from 'react'
import { Box, useTheme, Typography } from '@mui/material'
import CenterModal from 'components/center-modal'
import BigCheckbox from 'components/inputs/big-checkbox'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import SelectInput from 'components/inputs/select-input'
import Authentication from 'components/authentication'
import ModalContent from 'components/modal-content'
import { compundedTemplateActivityTitle } from 'utilities/template-activity-helper'
import { TemplateActivityItem, UserAreaItem, RoleEnum } from 'api/tillit.api-client'
import { useDistinctAreas } from 'utilities/use-user-areas'
import { InheritedTemplateActivityModalContext } from './inherited-template-activity-modal-context'
import useFormData from './utilities/use-inherited-template-activity-form-data'

export type InheritedTemplateActivityModalProps = Readonly<{
  propsTitle?: string
  propsSubtitle?: string
  ownerAreaId?: number
  activeAreaId?: number
  onSave?: () => void
}>

const InheritedTemplateActivityModalContainer: React.FC<InheritedTemplateActivityModalProps> = ({ propsTitle, ownerAreaId, onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()
  const getCompundedTemplateActivityTitle = compundedTemplateActivityTitle(f, true)
  const { distinctAreas } = useDistinctAreas()
  const {
    modalOpen,
    selectedInheritedTemplateActivity,
    isLoading,
    templateActivityItems,
    selectedTemplateActivity,
    templateActivityAlreadyExistOnArea,
  } = InheritedTemplateActivityModalContext.useState()

  const { setOwnerAreaId, fetchNewSelectedTemplateActivityAsync, fetchNonInheritedTemplateActivitiesAsync } = InheritedTemplateActivityModalContext.useActions()

  React.useEffect(() => {
    setOwnerAreaId(ownerAreaId)
  }, [ownerAreaId, setOwnerAreaId])

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedInheritedTemplateActivity)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeInheritedTemplateActivityModal } = InheritedTemplateActivityModalContext.useActions()

  const getOwnerAreaName = (item: any) => {
    const ua = item as UserAreaItem

    if (ua.organizationParentsName) {
      return `${ua.areaName} (${ua.organizationParentsName})`
    }
    return ua.areaName || ''
  }

  const { user: currentUser } = Authentication.useAuthenticatedState()

  return (
    <CenterModal open={modalOpen} onClose={closeInheritedTemplateActivityModal} fullSize>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {

          const handleChangeOwnerArea = (_: React.ChangeEvent<{}>, value: any) => {
            const uaV = value as UserAreaItem
            if (uaV) {
              setFieldValue('areaId', uaV.areaId)
              void fetchNonInheritedTemplateActivitiesAsync(uaV.areaId)
            } else {
              setFieldValue('areaId', undefined)
            }
          }

          const handleChangeTemplateActivity = (event: React.ChangeEvent<{}>, value: any) => {
            const taV = value as TemplateActivityItem
            if (taV) {
              setFieldValue('templateActivityId', taV.id)
              void fetchNewSelectedTemplateActivityAsync(taV.id)
            } else {
              setFieldValue('templateActivityId', undefined)
              void fetchNewSelectedTemplateActivityAsync(undefined)
            }
          }

          return (
            <Form translate="yes" style={{display:'contents'}}>
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={
                  propsTitle
                    ? propsTitle
                    : selectedInheritedTemplateActivity
                      ? f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_HEADER_EDIT_TITLE')
                      : f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_HEADER_TITLE')
                }
              />
                <ModalContent style={{padding:'20px'}}>
                  <ModalSection
                    title={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_TITLE')}
                    subtitle={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Box flex={1}>
                          <SelectInput
                            label={f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_AREA_COMPONENT_LABEL_DEFAULT')}
                            onChange={handleChangeOwnerArea}
                            name={name.area}
                            disabled={!!selectedInheritedTemplateActivity?.id}
                            options={distinctAreas(currentUser.userAreas, [RoleEnum.Admin]) as any}
                            getOptionLabel={getOwnerAreaName}
                            fullWidth={true}
                            error={!!errors.area}
                            helperText={errors.area}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_TITLE')}
                    subtitle={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Box flex={1}>
                          <SelectInput
                            label={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_LABEL')}
                            onChange={handleChangeTemplateActivity}
                            name={name.templateActivity}
                            disabled={!!selectedInheritedTemplateActivity?.id}
                            options={templateActivityItems as any}
                            getOptionLabel={getCompundedTemplateActivityTitle}
                            fullWidth={true}
                            error={!!errors.templateActivity}
                            helperText={errors.templateActivity}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {templateActivityAlreadyExistOnArea && (
                    <ModalSection title="" subtitle="">
                      <ModalSectionRow>
                        <Box width="80%">
                          <Typography style={{ color: theme.palette.error.main }}>
                            {f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_COMBINATION_ALREADY_EXISTS')}
                          </Typography>
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_USETEMPLATEDESCRIPTION_TITLE')}
                    subtitle={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_USETEMPLATEDESCRIPTION_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('BASIC_TEXT_YES')}`}
                          name={name.useTemplateDescription}
                          value="true"
                          checkedByValue={true}
                          returnValue="value"
                        />
                        <BigCheckbox
                          label={`${f('BASIC_TEXT_NO')}`}
                          name={name.useTemplateDescription}
                          value="false"
                          checkedByValue={true}
                          returnValue="value"
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_TITLE')}
                    subtitle={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          data-matomo-mask
                          label={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_LABEL')}
                          color="secondary"
                          fullWidth={true}
                          multiline={true}
                          rows={5}
                          disabled={true}
                          value={selectedTemplateActivity?.description ? selectedTemplateActivity?.description : ''}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_TITLE')}
                    subtitle={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          data-matomo-mask
                          name={name.description}
                          label={f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL')}
                          color="secondary"
                          fullWidth={true}
                          multiline={true}
                          rows={5}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
               </ModalContent>
              <ModalFooter>
                <TextButton type="button" onClick={closeInheritedTemplateActivityModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedInheritedTemplateActivity ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default InheritedTemplateActivityModalContainer
