import { Backdrop, Box, Fade, FormControl, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material'
import { CustomerListItem } from 'api/tillit.api-client'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { TemporaryActivityCreateModalContext } from '../temporary-activity-create-modal/temporary-activity-create-modal-context'
import { StyledButtonAbort, StyledListItemLabel, StyledModal, StyledPaper } from './modal-search-customer-style'
import SelectInput from 'components/inputs/select-input'
import { Formik } from 'formik'
import useFormData from './utilities/use-search-customer-form-data'

const SearchCustomer: React.FC = () => {
  const f = useFormatMessage()

  const { searchCustomerOpen, listCustomer, planningId } = PlanningPageContext.useState()
  const { openSearchCustomer, customerSelected } = PlanningPageContext.useActions()

  const handleClose = () => {
    openSearchCustomer({ status: false })
  }

  const { openModal } = TemporaryActivityCreateModalContext.useActions()
  const { openModal: openModalCreateActivity, fetchCustomerInfoAsync } = ActivityCreateModalContext.useActions()

  const handleCommonActivityClick = () => {
    openModal(undefined)
    handleClose()
  }

  const handleCustomerSelected = (event: any, value: any) => {
    const customer = value as CustomerListItem
    if (searchCustomerOpen.type === 'showListCustomer') {
      void fetchCustomerInfoAsync(customer.id).then(() => {
        openModalCreateActivity(undefined, customer)
      })
    } else {
      void customerSelected(customer).then(() => {
        openModal(customer)
      })
    }
    handleClose()
  }

  const getCustomerName = React.useCallback((value: any): string => {
    const customer = value as CustomerListItem
    return customer.fullName as string
  }, [])

  const areaCustomers = React.useMemo(() => {
    return listCustomer?.items?.filter((customer) => {
      return customer.customerAreas!.some(prop => prop.areaId === planningId)
    })
  }, [listCustomer, planningId])

  const { initialValues, name, onSubmit } = useFormData()

  return (
    <StyledModal
      open={searchCustomerOpen.status}
      onClose={handleClose}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize >
        <Fade in={searchCustomerOpen.status}>
          <StyledPaper>
            <FormControl>
              <Box display="flex">
                <Box data-matomo-mask width={300}>
                  <SelectInput
                    name={"selectedCustomer"}
                    options={areaCustomers as any}
                    label={f('BASIC_CUSTOMER')}
                    onChange={handleCustomerSelected}
                    getOptionLabel={getCustomerName}
                    fullWidth
                  />
                </Box>
                <StyledButtonAbort onClick={handleClose}>{f('BASIC_BUTTON_CANCEL_TEXT')}</StyledButtonAbort>
              </Box>
            </FormControl>

            <Box>
              <List>
                {searchCustomerOpen.type === 'defaultCommon' && (
                  <ListItem
                    onClick={handleCommonActivityClick}
                    button>
                    <ListItemText primary={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_COMMON_ACTIVITY')} />
                    <ListItemSecondaryAction>
                      <StyledListItemLabel color="secondary">
                        <Typography
                          color="secondary"
                          onClick={handleCommonActivityClick}>
                          {f('PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_FORM_SELECT_LABEL')}
                        </Typography>
                      </StyledListItemLabel>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            </Box>
          </StyledPaper>
        </Fade>
      </Formik>
    </StyledModal>
  )
}

export default SearchCustomer
