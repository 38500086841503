import { FormHelperText } from '@mui/material'
import { FormHelperTextProps } from '@mui/material/FormHelperText'
import { ErrorMessage, ErrorMessageProps } from 'formik'
import React from 'react'

type Props = Omit<ErrorMessageProps, 'component' | 'className'> & Readonly<{ options?: Omit<FormHelperTextProps, 'error'> }>

const MuiErrorMessageView: React.SFC<Props> = ({ options = {}, ...rest }) => {
  return (
    <ErrorMessage {...rest}>
      {message => (
        <FormHelperText error={true} {...options}>
          {message}
        </FormHelperText>
      )}
    </ErrorMessage>
  )
}

export default MuiErrorMessageView
