import React from 'react'
import { useCustomerApi } from 'api'
import {
  CustomerAreaCreateItem,
  CustomerListItem,
  CustomerStatusEnum,
  PageResultOfCustomerListItem,
  RoleEnum
} from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import isEmpty from 'lodash/isEmpty'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import useUserAreas from 'utilities/use-user-areas'
import { getVisibleAreaData } from 'utilities/area-helper'
import { rootUser } from 'utilities/use-user-areas'
import useAuthorization from 'utilities/use-authorization'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'

export type CustomerCreateModel = Readonly<{
  active: boolean
  firstName?: string
  lastName?: string
  description?: string
  areaId: number
  extraInfo?: string
}>

const useCustomersPageContext = () => {
  const {
    list: listCustomers,
    create: createCustomer,
    remove: removeCustomer,
    inactivate: inactivateCustomer,
    abort,
  } = useCustomerApi()
  const { getAdminAreas } = useAuthorization()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const [data, setData] = React.useState<PageResultOfCustomerListItem | null>(
    null
  )

  const [activeCustomers, setActiveCustomers] = React.useState<CustomerListItem[] | null>(null)
  const [inactiveCustomers, setInactiveCustomers] = React.useState<CustomerListItem[] | null>(null)

  const [modalOpen, setModalOpen] = React.useState(false)
  const { customerId } = CustomerModalContext.useState()
  const areas = useUserAreas([RoleEnum.Admin, RoleEnum.Planner])

  const fetchCustomersAsync = React.useCallback(async () => {
    await fetchAsync(
      listCustomers(1, 999999999, currentUser.organizationId!!)
    ).then((res) => {
      if (!rootUser(currentUser)) {
        const adminAreas = getAdminAreas([RoleEnum.Planner])
        res.items = res.items.filter(item => item.customerAreas?.some(customerArea => adminAreas?.some(adminArea => adminArea.areaId === customerArea.areaId)))
      }
      const visibleArea = getVisibleAreaData().map(area => area.areaId)
      if (!isEmpty(visibleArea)) {
        res.items = res.items.filter(item => item.customerAreas?.some(customerArea => visibleArea.includes(customerArea.areaId)))
      }

      setActiveCustomers(res.items.filter(c => c.statusId === CustomerStatusEnum.Active))
      setInactiveCustomers(res.items.filter(c => c.statusId === CustomerStatusEnum.Inactive))

      setData(res)

    })
  }, [currentUser.organizationId, fetchAsync, listCustomers])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => abort(), [])

  // Fetch user info if user opens web app while already authenticated
  // (a token is present in local storage)
  React.useEffect(() => {
    if (customerId && data) { return }
    fetchCustomersAsync()
  }, [customerId])

  const createCustomerAsync = React.useCallback(
    (createUserModel: CustomerCreateModel) => {
      const { organizationId } = currentUser

      if (!organizationId) {
        throw Error('Logged in user do not have a organization id set.')
      }

      const { areaId } = createUserModel
      const customerAreas = [new CustomerAreaCreateItem({ areaId })]
      return fetchAsync(
        createCustomer({
          ...createUserModel,
          organizationId,
          customerAreas,
        })
          .then(result => void fetchCustomersAsync() && result)
          .then(user => !!user)
      )
    },
    [createCustomer, currentUser, fetchAsync, fetchCustomersAsync]
  )

  const {
    state: stateOfRemoveFetch,
    fetchAsync: removeFetchAsync,
  } = usePromiseApi()

  const [
    customerToRemove,
    setCustomerToRemove,
  ] = React.useState<CustomerListItem | null>(null)

  const removeDialogOpen = React.useMemo(() => customerToRemove !== null, [
    customerToRemove,
  ])

  const openRemoveDialog = React.useCallback(
    (customer: CustomerListItem) => setCustomerToRemove(customer),
    [setCustomerToRemove]
  )

  const closeRemoveDialog = React.useCallback(() => setCustomerToRemove(null), [
    setCustomerToRemove,
  ])

  const remove = React.useCallback(
    cId => {
      closeRemoveDialog()
      removeFetchAsync(
        removeCustomer(cId)
          .then(fetchCustomersAsync)
      )

    },
    [removeFetchAsync, removeCustomer, fetchCustomersAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  const inactivate = React.useCallback(
    cId => {
      closeRemoveDialog()
      removeFetchAsync(
        inactivateCustomer(cId)
          .then(fetchCustomersAsync)
      )
    },
    [removeFetchAsync, inactivateCustomer, fetchCustomersAsync, closeRemoveDialog]
  )


  return {
    state: {
      ...stateOfFetch,
      data,
      modalOpen,
      areas,
      removeDialogOpen,
      customerToRemove,
      isRemoving,
      activeCustomers,
      inactiveCustomers,
    },
    actions: {
      createCustomerAsync,
      openModal,
      closeModal,
      openRemoveDialog,
      closeRemoveDialog,
      remove,
      inactivate,
    },
  }
}

export const CustomersPageContext = createContext(useCustomersPageContext)
