export default {
ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_SUBTITLE:'The Expected amount in time unit or measurement unit',
ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_TITLE:'Expected amount / length',
ACTIVITY_DIALOG_CONTAINER_FOOTER_ADD_BUTTON_TEXT:'Add',
ACTIVITY_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Cancel',
ACTIVITY_DIALOG_CONTAINER_HEADER_EDIT_TITLE:'Edit activity',
ACTIVITY_DIALOG_CONTAINER_HEADER_OVERLINE:' ',
ACTIVITY_DIALOG_CONTAINER_HEADER_SUBTITLE:' ',
ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE:'Add activity',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_FROM_START_TEXTFIELD_LABEL:'Minutes before warning',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_EXPECTED_AMOUNT_LABEL:'Expected amount / length',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL:'Length of the visit',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_NAME_TEXTFIELD_LABEL:'Suffix to template activity',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL:'min',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TEMPLATEACTIVITY_REQUIRED:'Template activty is required',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_ROW2_SUBTITLE:'How much time the activity is estimated to take',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_ROW2_TITLE:'Length',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE:'Short additional information about the activity.',
ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE:'Additional information',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW1_SUBTITLE:'The start date for the activity',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW1_TITLE:'Start date',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW2_SUBTITLE:'Between which times the activity should begin',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW2_TITLE:'Time interval',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW3_SUBTITLE:'Number of minutes from where the activity is plannned to begin and when a warning (blinking) is shown on the device. By setting this to 0 minutes the warning is deactivated',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW3_TITLE:'Warning',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW4_SUBTITLE:'How frequently the activity recurs',
ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW4_TITLE:'Recurring',
ACTIVITY_DIALOG_CONTAINER_INFO_PLANNED_ACTIVITY_CANNOT_BE_CHANGED:'This visit has planned activities in the future, you can only edit certain values',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_INFOTEXT_SUBTITLE:'Colors can used to categorize different activity types and get an easy way of overviewing these in the planning views',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_INFOTEXT_TITLE:'Color',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_SHOW_ON_DEVICE:'Show color',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_SHOW_ON_DEVICE_SUBTITLE:'Should the color be shown on the device?',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLORPICKER_LABEL:'Color',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_NAME_LABEL:'Name',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL:'Other',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_AREA:'Description',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_ORG:'Description',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE:'Additional information that might be relevant to complete the activity.',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_AREA:'Description of the template activity from the area',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_ORG:'Description of the template activity from the organization',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE:'Other',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_AREA:'Area description',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_ORG:'Organization description',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS:'Participants',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS_SUBTITLE:'Persons that should participate in the activity',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_CRITICAL:'Time critical',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_NORMAL:'Normal',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_SUBTITLE:'The activity priority',
ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_TITLE:'Pritority',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_EVERYDAY_LABEL:'Everyday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_FRIDAY_LABEL:'Friday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_MONDAY_LABEL:'Monday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_SATURDAY_LABEL:'Saturday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_SUNDAY_LABEL:'Sunday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_THURSDAY_LABEL:'Thursday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_TUESDAY_LABEL:'Tuesday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_DAY_CHECKBOX_WEDNESDAY_LABEL:'Wednesday',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_ENDDATE_DATEPICKER_LABEL:'End date',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_MONTHLYDATEINTERVAL_TEXT:'Recurring the {prefix, selectordinal, one {#:st} two {#:nd} few {#:rd} other {#:th}} {recurrenceInterval, plural, =1 {every} =2 {every other} other {every {recurrenceInterval, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}}} month',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_MONTHLYDAYINTERVAL_TEXT:'Recurring the {prefix} {suffix} {recurrenceInterval, plural, =1 {every} =2 {every other} other {every {recurrenceInterval, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}}} month',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_STARTDATE_DATEPICKER_LABEL:'Start Date',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TIMEFROM_TIMEPICKER_LABEL:'From',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TIMETO_TIMEPICKER_LABEL:'To',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_BIWEEKLY_TEXT:'Occurs every other {day} from {startDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_CHOOSE_ENDDATE_TEXT:'Choose end date',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_DAILY_TEXT:'Occurs every {recurrenceInterval} day from {startDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_DELETE_ENDDATE_TEXT:'Remove end date',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_ENDDATE_TEXT:'until {endDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_EVERYDAY_TEXT:'Occurs every dat from {startDate}',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_MONTHLY_TEXT:'Occur on {monthlyDay} every {recurrenceInterval} month from {startDate}.',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_WEEKLY_TEXT:'Occurs every {day} between {fromTime} and {toTime} from {startDate}.',
ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_YEARLY_TEXT:'Occurs yearly',
ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_LABEL_DEFAULT:'Template activity',
ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_SUBTITLE:'Choose template activity',
ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_TITLE:'Template activity',
ACTIVITY_PARTICIPANT_ACCEPT_BUTTON_TOOLTIP:'{name} accepted participation',
ACTIVITY_PARTICIPANT_CHOOSE_PARTICIPANT_TO_ADD:'Choose a person to add to the participant list',
ACTIVITY_PARTICIPANT_DECLINED:'Declined',
ACTIVITY_PARTICIPANT_DECLINE_BUTTON_TOOLTIP:'{name} declined participation',
ACTIVITY_PARTICIPANT_DELETE_BUTTON_TOOLTIP:'Remove {name}',
ACTIVITY_PARTICIPANT_PARTICIPATED:'Participated',
ACTIVITY_TEMPORARY_DIALOG_CONTAINER_HEADER_EDIT_TITLE:'Edit temporary activity',
ACTIVITY_WEEK_REQUIRED_DAY_HELPER_TEXT:'At least one week day need to be selected',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK:'Copy link',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_COPIED:'Link copied',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_IP:'Copy IP-link',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_MANAGE:'Manage',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_OPEN_DEVICE:'Open device',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_HEAD_DEVICE:'Device',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_HEAD_ID:'ID',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_HEAD_STATUS:'Status',
ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TITLE:'Activated devices',
ADMINISTER_ACTIVATED_DEVICE_SECTION_TITLE:'All activated devices',
ADMINISTER_DELEGATIONS_INHERITED_ADD_BUTTON_TEXT:'Connect delegation',
ADMINISTER_DELEGATIONS_INHERITED_COLUMN_AREA:'Area',
ADMINISTER_DELEGATIONS_INHERITED_COLUMN_CATEGORY:'Category',
ADMINISTER_DELEGATIONS_INHERITED_COLUMN_NAME:'Name',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_BUTTON_TOOLTIP:'Remove {name}',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_MESSAGE:'You are going to remove {title} from area {areaName}, Are you sure?',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_DIALOG_TITLE:'Remove connected delegation',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is used as an active delegation',
ADMINISTER_DELEGATIONS_INHERITED_DELETE_INVALID_DIALOG_TITLE:'Remove delegation is not allowed',
ADMINISTER_DELEGATIONS_INHERITED_NO_VALUES_DIPLAYED_TEXT:'No connected delegations exist',
ADMINISTER_DELEGATIONS_MODAL_DIALOG_DELEGATION_CATEGORY_LABEL:'Category',
ADMINISTER_DELEGATIONS_ORGANIZATION_ADD_BUTTON_TEXT:'Add delegation',
ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_CATEGORY:'Category',
ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_DESCRIPTION:'Description',
ADMINISTER_DELEGATIONS_ORGANIZATION_COLUMN_NAME:'Name',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_BUTTON_TOOLTIP:'Remove {name}',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_MESSAGE:'You are going to remove {name}, are you sure?',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_DIALOG_TITLE:'Remove delegation',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to template activity',
ADMINISTER_DELEGATIONS_ORGANIZATION_DELETE_INVALID_DIALOG_TITLE:'Removal of delegation is not accepted',
ADMINISTER_DELEGATIONS_ORGANIZATION_NO_VALUES_DISPLAYED_TEXT:'No available delegations',
ADMINISTER_DELEGATIONS_PAGE_VIEW_HEADER:'Delegations',
ADMINISTER_DELEGATIONS_VIEW_CATEGORY_LABEL:'Categories',
ADMINISTER_DELEGATIONS_VIEW_ORGANIZATION_LABEL:'Organization',
ADMINISTER_DELEGATION_CATEGORY_ADD_BUTTON_TEXT:'Add category',
ADMINISTER_DELEGATION_CATEGORY_COLUMN_TITLE:'Category',
ADMINISTER_DELEGATION_CATEGORY_DELETE_BUTTON_TOOLTIP:'Remove {title}',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_MESSAGE:'You are going to remove {title}, are you sure?',
ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_TITLE:'Remove category',
ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to a delegation',
ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_TITLE:'Remove category is not allowed',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_HEADER_EDIT_TITLE:'Change category',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_HEADER_TITLE:'Add category',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Category',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_SUBTITLE:'Category description',
ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_TITLE:'Category',
ADMINISTER_DELEGATION_CATEGORY_NO_VALUES_DIPLAYED_TEXT:'No categories exist',
ADMINISTER_DELEGATION_MODAL_DIALOG_DELEGATION_CATEGORY_SUBTITLE:'Select category for the delegation if applicable',
ADMINISTER_DELEGATION_MODAL_DIALOG_DELEGATION_CATEGORY_TITLE:'Category',
ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_COMPONENT_LABEL_DEFAULT:'Description',
ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_SUBTITLE:'Delegation description',
ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_TITLE:'Description',
ADMINISTER_DELEGATION_MODAL_DIALOG_HEADER_EDIT_TITLE:'Change delegation',
ADMINISTER_DELEGATION_MODAL_DIALOG_HEADER_TITLE:'Add delegation',
ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Delegation',
ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_SUBTITLE:'Delegation title',
ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_TITLE:'Delegation',
ADMINISTER_DEVICE_SECTION_ACTIONS_SECTION_DESCRIPTION:'Because this device is activated, one can now use device specific actions such as enter daily activities view.',
ADMINISTER_DEVICE_SECTION_ACTIONS_SECTION_TITLE:'Device actions',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_BUTTON_ACTIVATE:'Activate Device',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_BUTTON_DEACTIVATE:'Deactivate Device',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_DESCRIPTION_ACTIVATED:'This device is activated and can now show daily activities, please use button below to enter daily activities view.',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_DESCRIPTION_NOT_ACTIVATED:'To be able to show daily activities on this device, it first needs to be activated. Use the button to the right to activate this device.',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_TITLE_ACTIVATED:'This device is activated',
ADMINISTER_DEVICE_SECTION_STATUS_SECTION_TITLE_NOT_ACTIVATED:'Device not activated',
ADMINISTER_DEVICE_SECTION_TITLE:'This device',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_DISABLE:'Disable',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_REMOVE:'Remove own changes',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ACTIONS_SAVE:'Save',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_ALIAS_TITLE:'Alias',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_DEFAULT_TITLE:'Default title',
ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_LABEL:'Signing statuses',
ADMINISTER_DRUG_LIST_VIEW_UNIT_LABEL:'Units',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREADESCRIPTION_LABEL:'Description',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREADESCRIPTION_SUBTITLE:'The area description can be used in order to complete or replace the description from the organization',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREADESCRIPTION_TITLE:'Area description',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREA_LABEL:'Area',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREA_SUBTITLE:'Choose the area to connect to',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_AREA_TITLE:'Area',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_COMBINATION_ALREADY_EXISTS:'The combination of area and delegation already exists and cannot be connected anew. Please examine the selections and try again!',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_DELEGATION_LABEL:'Connected delegation',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_DELEGATION_SUBTITLE:'Choose the delegation to connect',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_DELEGATION_TITLE:'Delegation',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_HEADER_EDIT_TITLE:'Edit connected delegation',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_HEADER_TITLE:'Connect delegation',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_ORGDESCRIPTION_LABEL:'Description',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_ORGDESCRIPTION_SUBTITLE:'Description of the delegation by the organization',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_ORGDESCRIPTION_TITLE:'Organization description',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_USETEMPLATEDESCRIPTION_SUBTITLE:'Choose whether the description should be used or not ',
ADMINISTER_INHERITED_DELEGATIONS_MODAL_DIALOG_USETEMPLATEDESCRIPTION_TITLE:'Use organization description',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL:'Description',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_SUBTITLE:'The area description can be used in order to complete or replace the description from the organization',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_TITLE:'Area description',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_LABEL:'Area',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_SUBTITLE:'Choose the area to connect to',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_TITLE:'Area',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_COMBINATION_ALREADY_EXISTS:'The combination of area and template activity already exists and cannot be connected anew. Please examine the selections and try again!',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_HEADER_EDIT_TITLE:'Edit connected template activity',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_HEADER_TITLE:'Connect template activity',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_LABEL:'Description',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_SUBTITLE:'Description of the template activity by the organization',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_ORGDESCRIPTION_TITLE:'Organization description',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_LABEL:'Connected template activity',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_SUBTITLE:'Choose the template activity to connect',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TEMPLATEACTIVITY_TITLE:'Template activity',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_USETEMPLATEDESCRIPTION_SUBTITLE:'Choose whether the description should be used or not ',
ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_USETEMPLATEDESCRIPTION_TITLE:'Use organization description',
ADMINISTER_LEGISLATION_ADD_BUTTON_TEXT:'Add legislation',
ADMINISTER_LEGISLATION_COLUMN_DESCRIPTION:'Description',
ADMINISTER_LEGISLATION_COLUMN_TITLE:'Legislation',
ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP:'Remove',
ADMINISTER_LEGISLATION_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_LEGISLATION_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_LEGISLATION_DELETE_DIALOG_MESSAGE:'You are going to remove {title}, are you sure?',
ADMINISTER_LEGISLATION_DELETE_DIALOG_TITLE:'Remove legislation',
ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to an activity template category',
ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_TITLE:'Remove legislation is not allowed',
ADMINISTER_LEGISLATION_MODAL_DIALOG_DESCRIPTION_COMPONENT_LABEL_DEFAULT:'Description',
ADMINISTER_LEGISLATION_MODAL_DIALOG_DESCRIPTION_SUBTITLE:'Legislation description',
ADMINISTER_LEGISLATION_MODAL_DIALOG_DESCRIPTION_TITLE:'Description',
ADMINISTER_LEGISLATION_MODAL_DIALOG_HEADER_EDIT_TITLE:'Change legislation',
ADMINISTER_LEGISLATION_MODAL_DIALOG_HEADER_TITLE:'Add legislation',
ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Legislation',
ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_SUBTITLE:'Legislation title',
ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_TITLE:'Legislation',
ADMINISTER_LEGISLATION_NO_VALUES_DIPLAYED_TEXT:'No legislations exist',
ADMINISTER_MANAGE_DEVICE_DIALOG_ADD_ADDITIONALAREAS_TITLE:'Add addition area',
ADMINISTER_MANAGE_DEVICE_DIALOG_BUTTON_CANCEL:'Cancel',
ADMINISTER_MANAGE_DEVICE_DIALOG_BUTTON_SAVE:'Save',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_COMBINED_SUBTITLE:'Add areas where routes will be available for planners to create combined routes from',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_BUTTON:'Deactivate Device',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_SUBTITLE:'Deactivates the physical device and you will no longer be able to sign activities on that device.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_TITLE:'Deactivate Device',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ADDITIONALAREAS_SUBTITLE:'Connect additional areas',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ADDITIONALAREAS_TITLE:'Additional areas',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_SUBTITLE:'This will show a button on the device, allowing navigation to the planning calendar in a read only mode',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_TITLE:'Allow navigate to planning calendar',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_SUBTITLE:'This will allow the usage of multisigning on the device',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_TITLE:'Allow usage of multi signing',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_DESCRIPTION_LABEL:'Description',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL:'Minutes',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_SUBTITLE:'Number of minutes before session expires when inactive. 1-540 minutes.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_TITLE:'Number of minutes',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASENDTIME_LABEL:'End time',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASSTARTTIME_LABEL:'Start time',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASTIMES_SUBTITLE:'Choose between which hours the additional areas will be show or choose the whole day',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASTIMES_TITLE:'Time for display',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREASWHOLEDAY_TITLE:'The whole day',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREAS_SUBTITLE:'Select further areas. Will be shown as tabs on the device.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_SHOWADDITIONALAREAS_TITLE:'Show additional areas',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_USEMANUALSESSIONEXPIRATION_SUBTITLE:'Usage of manual session expiration let you configure number of minutes before logged out.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_USEMANUALSESSIONEXPIRATION_TITLE:'Use manual session expiration',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_TITLE:'Description',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_MAIN_AREA_SUBTITLE:'Please choose a main area for this device',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_MAIN_AREA_TITLE:'Main area',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_FIELD_NAME_LABEL:'Name',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_SUBTITLE:'Please choose a name for this device',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_TITLE:'Name',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_FIELD_FROM_TIME_LABEL:'From time',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_FIELD_TO_TIME_LABEL:'To time',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_SUBTITLE:'Choose between which times the combination should occur.',
ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_TIME_BASED_TITLE:'Times for combination',
ADMINISTER_MANAGE_DEVICE_DIALOG_TITLE:'Manage Device',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_BUTTON_ACTIVATE:'ACTIVATE',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_BUTTON_CANCEL:'Cancel',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_ALLOWNAVIGATE:'Allow navigation to planning calendar',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_ALLOWUSEMULTISIGNING:'Allow usage of multi signing',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_AREA:'Search area',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_DESCRIPTION:'Description',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_NAME:'Name',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_USEMANUALSESSIONEXPIRATION:'Manuell time for session expiration',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_SUBTITLE:' ',
ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_TITLE:'Activate Device',
ADMINISTER_PAGE_NEW_ACTIVE_DEVICE_DIALOG_TITLE:'Add and activate new device',
ADMINISTER_PAGE_TITLE:'Administer',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_HEADER_DESCRIPTION:'List of all activated devices on your areas.',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_HEADER_TITLE:'Activated devices',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_ACTIVATEDBY_COLUMN_HEADER:'Activated by',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_ACTIVATEDON_COLUMN_HEADER:'Activated on',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_AREAS_COLUMN_HEADER:'Areas',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_CREATEDBY_TITLE:'Created by',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_CREATEDON_TITLE:'Date',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_DEVICE_TITLE:'Device',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_KIOSKNAME_TITLE:'Kiosk name',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_STATUSID_TITLE:'Status ID',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_STATUS_APPROVED:'Approved',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_STATUS_NOTAPPROVED:'Not approved',
ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TITLE:'All activated devices',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_ADD_DEVICE_DESCRIPTION:'Activate a new device that is not connected to this device',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_ADD_DEVICE_TITLE:'Add new device',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_BUTTON_Add_DEVICE_TEXT:'Add Device',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_DESCRIPTION:'To be able to show daily activities on this device, it first needs to be activated. Use the button to the right to activate this device.',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_HEADER_SUBTITLE:'This device is not activated',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_NEW_HEADER_TITLE:'Create new device',
ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_NOTACTIVATED_TITLE:'Device is not activated',
ADMINISTER_PAGE_VIEW_HEADER_TITLE:'Manage',
ADMINISTER_PAGE_VIEW_TAB_0_TITLE:'Devices',
ADMINISTER_PAGE_VIEW_TAB_1_TITLE:'Activity templates',
ADMINISTER_PAGE_VIEW_TAB_2_TITLE:'Legislations',
ADMINISTER_PAGE_VIEW_TAB_3_TITLE:'Delegations',
ADMINISTER_PAGE_VIEW_TAB_4_TITLE:'Drug list',
ADMINISTER_PAGE_VIEW_TAB_5_TITLE:'Personnel management',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_ADD_BUTTON_TEXT:'Connect work shift',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_DIALOG_SUBTITLE:'Choose an Area',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_DIALOG_TITLE:'Area',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_AREA_TITLE:'Area',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_CHOOSE_TO_CONNECT_BUTTON_TEXT:'Choose shifts to connect',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_HEADER:'Connect work shift',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_SUBTITLE:'Choose a work shift',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_TITLE:'Work shift',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_LABEL:'Connect work schedule',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TITLE:'Work schedule',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_BUTTON_ABORT:'Cancel',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_BUTTON_CONNECT:'Connect',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_COLUMN_AREA:'Area',
ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_TITLE:'Choose areas to connect to...',
ADMINISTER_PERSONNEL_WORKSCHEDULE_ADD_BUTTON_TEXT:'Add work shift',
ADMINISTER_PERSONNEL_WORKSCHEDULE_CONNECT_BUTTON_TEXT:'Connect chosen schedule',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_BUTTON_TEXT:'Delete {title}',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_MESSAGE:'You are going to remove {title}, are you sure?',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_SHIFT_DETAILS:'{description} ({from} to {to})',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_TITLE:'Remove Work schedule',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to a area',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_TITLE:'Remove sork shift is not allowed',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DESCRIPTION:'Description',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DIALOG_SUBTITLE:'Description',
ADMINISTER_PERSONNEL_WORKSCHEDULE_DIALOG_TITLE:'Work shift',
ADMINISTER_PERSONNEL_WORKSCHEDULE_END_TIME:'End',
ADMINISTER_PERSONNEL_WORKSCHEDULE_END_TIME_DIALOG_TITLE:'End time',
ADMINISTER_PERSONNEL_WORKSCHEDULE_LABEL:'Work schedule',
ADMINISTER_PERSONNEL_WORKSCHEDULE_START_TIME:'Start',
ADMINISTER_PERSONNEL_WORKSCHEDULE_START_TIME_DIALOG_TITLE:'Start time',
ADMINISTER_SEARCH_AREA_COMPONENT_LABEL_DEFAULT:'Search area',
ADMINISTER_SELECTED_VISIBLE_AREA_SUBTITLE:'Choose areas to filter on (The filtering is applied all over the application)',
ADMINISTER_SELECTED_VISIBLE_AREA_TITLE:'Choose area',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_ADD_BUTTON_TEXT:'Add template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_AREA:'Area',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_CATEGORY:'Category',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_LEGISLATION:'Legislation',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_TITLE:'Name',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_BUTTON_TOOLTIP:'Remove {name}',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_MESSAGE:'You are going to remove {name}, Are you sure?',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_TITLE:'Remove template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to an activity',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_TITLE:'Remove template activity is not allowed',
ADMINISTER_TEMPLATE_ACTIVITIES_AREA_NO_VALUES_DIPLAYED_TEXT:'No template activities exist',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_ADD_BUTTON_TEXT:'Connect template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_AREA:'Area',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_CATEGORY:'Category',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_LEGISLATION:'Legislation',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_COLUMN_NAME:'Name',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_BUTTON_TOOLTIP:'Remove {name}',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_MESSAGE:'You are going to remove {title} from area {areaName}, Are you sure?',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_DIALOG_TITLE:'Remove connected template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to an activity',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_DELETE_INVALID_DIALOG_TITLE:'Remove template activity is not allowed',
ADMINISTER_TEMPLATE_ACTIVITIES_INHERITED_NO_VALUES_DIPLAYED_TEXT:'No connected template activities exist',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_LABLE:'Delegation',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_SUBTITLE:'Add delegations that is required for this template activity.',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_COMBINE_DELEGATION_TITLE:'Delegation requirements',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_SUBTITLE:'Choose area',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREA_TITLE:'Area',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CATEGORY_SUBTITLE:'Category for the template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CATEGORY_TITLE:'Category',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_CANCEL:'Cancel',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_CONFIRM:'Confirm',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_MESSAGE:'Please confirm that you want to move the template activity to organization level',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_CONFIRM_MOVETOORGANIZATION_TITLE:'Move template activity to organization',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_EDIT_TITLE:'Edit template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_ALERT_MINUTES_TEXTFIELD_LABEL:'Minutes before warning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL:'Template activity length',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL:'Min',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_TITLE_TEXTFIELD_LABEL:'Template activity name',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_ALERT_MINUTES_SUBTITLE:'Number of minutes from where the activity is plannned to begin and when a warning (blinking) is shown on the device. By setting this to 0 minutes the warning is deactivated',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_ALERT_MINUTES_TITLE:'Warning',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_LENGTH_SUBTITLE:'How much time the template activity is estimated to take',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_LENGTH_TITLE:'Length (minutes)',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_SUBTITLE:'Information about the activity. Descriptive title.',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_INFOTEXT_TITLE:'Information',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_BUTTON_TEXT:'Move template activity to organization',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_SUBTITLE:'Move template activity to organization',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_MOVETOORGANIZATION_TITLE:'Move template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_INFOTEXT_SUBTITLE:'Colors can used to categorize different activity types and get an easy way of overviewing these in the planning views',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_INFOTEXT_TITLE:'Color',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_SHOW_ON_DEVICE_INFOTEXT_SUBTITLE:'Should the color be shown on the device?',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_COLOR_SHOW_ON_DEVICE_INFOTEXT_TITLE:'Show color',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_LABEL:'Description',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_SUBTITLE:'Further description',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_TITLE:'Description',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_USEPARTICIPANTLIST_INFOTEXT_SUBTITLE:'Should the template allow for usage of participants list?',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_USEPARTICIPANTLIST_INFOTEXT_TITLE:'Participant list',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_AREA_COMPONENT_LABEL_DEFAULT:'Area',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_CATEGORY_COMPONENT_LABEL_DEFAULT:'Category',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_UNIT_AMOUNT_COMPONENT_LABEL_DEFAULT:'Expected amount',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SELECT_UNIT_COMPONENT_LABEL_DEFAULT:'Unit',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_CRITICAL:'Time critical',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_NORMAL:'Normal',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_SUBTITLE:'The activity priority',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_SEVERITYLEVEL_TITLE:'Priority',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TITLE:'Add template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_AREA:'Common',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_CUSTOMER:'Customer',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_SUBTITLE:'The type decides where the template activity can be used',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_TITLE:'Type',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_UNIT_AMOUNT_SUBTITLE:'The Expected amount in time or measurement relating to chosen unit',
ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_UNIT_AMOUNT_TITLE:'Expected amount / length and unit',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_ABORT_CONNECT_TEMPLATES_BUTTON_TEXT:'Cancel',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_ADD_BUTTON_TEXT:'Add template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_CHOOSE_TEMPLATES_TO_CONNECT_BUTTON_TEXT:'Choose templates to connect',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_CATEGORY:'Category',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_LEGISLATION:'Legislation',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_NAME:'Name',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_TYPE:'Type',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_CONNECT_TEMPLATES_BUTTON_TEXT:'Connect chosen templates',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_BUTTON_TOOLTIP:'Remove {name}',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_MESSAGE:'You are going to remove {name}, Are you sure?',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_TITLE:'Remove template activity',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to an area',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_TITLE:'Remove template activity is not allowed',
ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_NO_VALUES_DIPLAYED_TEXT:'No template activities exist',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_AREA_LABEL:'Area',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_CATEGORY_LABEL:'Category',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_INHERITANCE_LABEL:'Connected actitity templates',
ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_ORGANIZATION_LABEL:'Organization',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_HEADER_EDIT_TITLE:'Change category',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_HEADER_TITLE:'Add category',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_LEGISLATION_SUBTITLE:'Choose legislation if applicable',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_LEGISLATION_TITLE:'Legislation',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_SELECT_LEGISLATION_COMPONENT_LABEL_DEFAULT:'Legislation',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Category',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_SUBTITLE:'Category description',
ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_TITLE:'Category',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_ADD_BUTTON_TEXT:'Add category',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_LEGISLATION:'Legislation',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_COLUMN_TITLE:'Category',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_BUTTON_TOOLTIP:'Remove {title}',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_MESSAGE:'You are going to remove {title}, are you sure?',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_DIALOG_TITLE:'Remove category',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to an activity template',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_DELETE_INVALID_DIALOG_TITLE:'Remove category is not allowed',
ADMINISTER_TEMPLATE_ACTIVITY_CATEGORY_NO_VALUES_DIPLAYED_TEXT:'No categories exist',
ADMINISTER_UNIT_ADD_BUTTON_TEXT:'Add unit',
ADMINISTER_UNIT_COLUMN_ABBREVIATION:'Abbreviation',
ADMINISTER_UNIT_COLUMN_TITLE:'Unit',
ADMINISTER_UNIT_DELETE_BUTTON_TOOLTIP:'Remove {title}',
ADMINISTER_UNIT_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
ADMINISTER_UNIT_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
ADMINISTER_UNIT_DELETE_DIALOG_MESSAGE:'You are going to remove {title}, are you sure?',
ADMINISTER_UNIT_DELETE_DIALOG_TITLE:'Remove unit',
ADMINISTER_UNIT_DELETE_INVALID_DIALOG_ACTIONS_OK:'Ok',
ADMINISTER_UNIT_DELETE_INVALID_DIALOG_MESSAGE:'You cannot remove {title} since it is connected to a template activity',
ADMINISTER_UNIT_DELETE_INVALID_DIALOG_TITLE:'Remove unit is not allowed',
ADMINISTER_UNIT_MODAL_DIALOG_ABBREVIATION_COMPONENT_LABEL_DEFAULT:'Abbreviation',
ADMINISTER_UNIT_MODAL_DIALOG_ABBREVIATION_SUBTITLE:'Unit abbreviation',
ADMINISTER_UNIT_MODAL_DIALOG_ABBREVIATION_TITLE:'Abbreviation',
ADMINISTER_UNIT_MODAL_DIALOG_HEADER_EDIT_TITLE:'Change unit',
ADMINISTER_UNIT_MODAL_DIALOG_HEADER_TITLE:'Add unit',
ADMINISTER_UNIT_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT:'Unit',
ADMINISTER_UNIT_MODAL_DIALOG_TITLE_SUBTITLE:'Unit title',
ADMINISTER_UNIT_MODAL_DIALOG_TITLE_TITLE:'Unit',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_MEASUREMENTUNIT:'Measurment',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_OTHER:'Misc',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_SUBTITLE:'Unit type',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_TIMEUNIT:'Time',
ADMINISTER_UNIT_MODAL_DIALOG_TYPE_TITLE:'Type',
ADMINISTER_UNIT_NO_VALUES_DIPLAYED_TEXT:'No units exist',
AREAS_DIALOG_TAB_TEMPORARY_ACTITIVIES_TITLE:'Temporary general activitites',
AREAS_DIALOG_VIEW_TAB_AREAS_TITLE:'Section and area',
AREAS_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basic information',
AREAS_DIALOG_VIEW_TAB_DESIRED_ACTIVITIES_TITLE:'General activities',
AREAS_PAGE_VIEW_COLUMN_NAME:'Area name',
AREAS_PAGE_VIEW_COLUMN_ORGANIZATION_PARENT_NAME:'Organization structure',
AREAS_PAGE_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'No areas available',
AREAS_PAGE_VIEW_HEAD_DESCRIPTION:'Overview of your areas',
AREAS_PAGE_VIEW_HEAD_TITLE:'Areas',
AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_AREA_NAME_SUBTITLE:'The name of the area',
AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_AREA_NAME_TITLE:'Area name',
AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INTERNAL_INFORMATION_TITLE:'Area internal information',
AREA_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL:'More information',
AREA_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_INFOTEXT_SUBTITLE:'More information',
AREA_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_INFOTEXT_TITLE:'More information',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_SUBTITLE:'This will show a button on the app, allowing navigation to the planning calendar in a read only mode',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_TITLE:'This will allow the usage of multisigning on the app',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_SUBTITLE:'This will allow the usage of multisigning on the app',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_TITLE:'Allow usage of multi signing on app',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_SUPPRESSTIMESONKIOSKANDPWA_SUBTITLE:'Activating this suppresses times on Device and app. Note: Time critical activities or activies with time set for warning are not affected. ',
AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_SUPPRESSTIMESONKIOSKANDPWA_TITLE:'Suppress times on Device and app',
BASIC_AREA:'Area',
BASIC_AREA_EMPTY_INPUT_TEXT:'--No areas avaliable--',
BASIC_BUTTON_ABORT_TEXT:'Abort',
BASIC_BUTTON_ADD_TEXT:'Add',
BASIC_BUTTON_BACK_TEXT:'Back',
BASIC_BUTTON_CANCEL_TEXT:'Cancel',
BASIC_BUTTON_CLOSE_TEXT:'Close',
BASIC_BUTTON_LOGOUT_TEXT:'Log out',
BASIC_BUTTON_MANAGE_TEXT:'Manage',
BASIC_BUTTON_OK_TEXT:'OK',
BASIC_BUTTON_REMOVE_TEXT:'Remove',
BASIC_BUTTON_SAVE_TEXT:'Save',
BASIC_BUTTON_SEND_TEXT:'Send',
BASIC_BUTTON_SUSPEND_TEXT:'Suspend',
BASIC_BUTTON_UPDATE_TEXT:'Update',
BASIC_COMMENT_TITLE_TEXT:'Comment',
BASIC_CUSTOMER:'Customer',
BASIC_CUSTOMER_LINK_TEXT:'Link to customer',
BASIC_DATE_DAY_FRIDAY_TEXT:'Friday',
BASIC_DATE_DAY_MONDAY_TEXT:'Monday',
BASIC_DATE_DAY_SATURDAY_TEXT:'Saturday',
BASIC_DATE_DAY_SUNDAY_TEXT:'Sunday',
BASIC_DATE_DAY_THURSDAY_TEXT:'Thursday',
BASIC_DATE_DAY_TUESDAY_TEXT:'Tuesday',
BASIC_DATE_DAY_WEDNESDAY_TEXT:'Wednesday',
BASIC_DATE_MONTH_APRIL_TEXT:'April',
BASIC_DATE_MONTH_AUGUST_TEXT:'August',
BASIC_DATE_MONTH_DECEMBER_TEXT:'December',
BASIC_DATE_MONTH_FEBRUARY_TEXT:'February',
BASIC_DATE_MONTH_JANUARY_TEXT:'January',
BASIC_DATE_MONTH_JULY_TEXT:'July',
BASIC_DATE_MONTH_JUNE_TEXT:'June',
BASIC_DATE_MONTH_MARCH_TEXT:'March',
BASIC_DATE_MONTH_MAY_TEXT:'May',
BASIC_DATE_MONTH_NOVEMBER_TEXT:'November',
BASIC_DATE_MONTH_OCTOBER_TEXT:'October',
BASIC_DATE_MONTH_SEPTEMBER_TEXT:'September',
BASIC_DECLINED_PARTICIPANT_TITLE_TEXT:'Persons that declined',
BASIC_DELEGATED_BY:'Delegated by',
BASIC_DESCRIPTION_AREA_TITLE_TEXT:'Area description',
BASIC_DESCRIPTION_ORGANIZATION_TITLE_TEXT:'Organization description',
BASIC_DESCRIPTION_OWNER_CUSTOMER_DESCRIPTION_TITLE_TEXT:'Other details',
BASIC_DESCRIPTION_TITLE_TEXT:'Description',
BASIC_ERROR_MAX:'Max {max}',
BASIC_ERROR_MIN:'Min {min}',
BASIC_INTEGER_FIELD_HELPER_TEXT:'{name} must be an integer',
BASIC_INVALID_TIME_FIELD_HELPER_TEXT:'Invalid Time',
BASIC_LABEL_AREA_SELECT_INPUT_TEXT:'Area',
BASIC_LABEL_EMAIL_TEXT:'E-Mail',
BASIC_LABEL_FROM_DATE_SELECT_INPUT_DATE:'From Date',
BASIC_LABEL_TO_DATE_SELECT_INPUT_DATE:'To Date',
BASIC_NO_RESULT:'No result',
BASIC_PARTICIPANT_TITLE_TEXT:'Participants',
BASIC_PLANNED_PARTICIPANT_TITLE_TEXT:'Planned participants',
BASIC_PLANNING_LINK_TEXT:'Link to planning',
BASIC_REQUIRED_FIELD_HELPER_TEXT:'Required field',
BASIC_REQUIRED_PASSWORD_LENGTH_HELPER_TEXT:'Password needs to be at least 8 characters',
BASIC_ROW_TEXT:'Row',
BASIC_SEARCH_FIELD_PLACEHOLDER:'Search',
BASIC_SHORT_DAY_TEXT:'d',
BASIC_SHORT_HOUR_TEXT:'h',
BASIC_SHORT_MINUTE_TEXT:'m',
BASIC_SIGNINGMODAL_LINK_TEXT:'Open signing',
BASIC_TEXT_AREA_COUNT_MULTIPLE_TEXT:'Areas',
BASIC_TEXT_AREA_COUNT_ONE_TEXT:'Area',
BASIC_TEXT_AREA_OR_ROUTE_COUNT_ZERO_TEXT:'No areas/routes',
BASIC_TEXT_COPY:'copy',
BASIC_TEXT_MINUTES:'Minutes',
BASIC_TEXT_MINUTES_ABBREVIATION:'min',
BASIC_TEXT_NO:'No',
BASIC_TEXT_ROUTE_COUNT_MULTIPLE_TEXT:'routes',
BASIC_TEXT_ROUTE_COUNT_ONE_TEXT:'route',
BASIC_TEXT_WEEKDAY_FRIDAY:'Friday',
BASIC_TEXT_WEEKDAY_MONDAY:'Monday',
BASIC_TEXT_WEEKDAY_SATURDAY:'Saturday',
BASIC_TEXT_WEEKDAY_SUNDAY:'Sunday',
BASIC_TEXT_WEEKDAY_THURSDAY:'Thursday',
BASIC_TEXT_WEEKDAY_TUESDAY:'Tuesday',
BASIC_TEXT_WEEKDAY_WEDNESDAY:'Wednesday',
BASIC_TEXT_YES:'Yes',
BASIC_USERDELEGATION_LINK_TEXT:'Link to user delegation',
BASIC_USER_LINK_TEXT:'Link to user',
BASIC_VALID:'Valid',
BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES:'{name} must be at most {maxsize} minutes.',
BASIC_WARNING_MAXSIZE_FIELD_TEXT:'{name} must be at most {maxsize} characters.',
BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES:'{name} must be atleast {minsize} minutes.',
BASIC_WARNING_REQUIRED_FIELD_TEXT:'{Email {Email} Password {Password}} is a required field.',
BULLETIN_BOARD_CONTAINER_BUTTON_CLOSE:'Close message board',
BULLETIN_BOARD_CONTAINER_BUTTON_CREATE_NEW:'Create message',
BULLETIN_BOARD_ITEM_MODAL_COLOR_TITLE:'Colour',
BULLETIN_BOARD_ITEM_MODAL_CONTENT_SUBTITLE:' ',
BULLETIN_BOARD_ITEM_MODAL_CONTENT_TITLE:'Content',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_CONTENT_LABEL:'Content',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_EDIT_TITLE:'Change message',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_LABEL:'Header',
BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_TITLE:'Create message',
BULLETIN_BOARD_ITEM_MODAL_HEADER_SUBTITLE:' ',
BULLETIN_BOARD_ITEM_MODAL_HEADER_TITLE:'Header',
CHANGE_PASSWORD_DASHBOARD_SECTION_BUTTON_TEXT:'Change password',
CHANGE_PASSWORD_DASHBOARD_SECTION_SUBTITLE:'Change password of current user',
CHANGE_PASSWORD_DASHBOARD_SECTION_TITLE:'Change password',
CHANGE_PASSWORD_SCENE_CHANGE_PASSWORD_BUTTON_TEXT:'Continue',
CHANGE_PASSWORD_SCENE_CHANGE_PASSWORD_TITLE:'Change password',
CHANGE_PASSWORD_SCENE_CONFIRM_PASSWORD_ERROR_TEXT:'{type, plural, =1 {Current password is incorrect} =2 {Password cannot contain email} =100 {Password is changed} other {}}',
CHANGE_PASSWORD_SCENE_CONFIRM_PASSWORD_INPUT_LABEL:'Confirm password',
CHANGE_PASSWORD_SCENE_CURRENT_PASSWORD_INPUT_LABEL:'Current password',
CHANGE_PASSWORD_SCENE_LOGIN_BUTTON_TEXT:'Login',
CHANGE_PASSWORD_SCENE_NEW_PASSWORD_CANNOT_CONTAIN_SPACES_ERROR_TEXT:'Password cannot contain spaces',
CHANGE_PASSWORD_SCENE_NEW_PASSWORD_INPUT_LABEL:'New password',
COMPOUNDED_TYPE_AREA:'Type: Area',
COMPOUNDED_TYPE_CUSTOMER:'Type: Customer',
CONFIRMATION_BOX_CREATED:'Created',
CONFIRMATION_BOX_CREATED_ACTIVITY:'Activity created',
CONFIRMATION_BOX_CREATED_AREA:'Area created',
CONFIRMATION_BOX_CREATED_CUSTOMER:'Customer created',
CONFIRMATION_BOX_CREATED_KIOSK:'Kiosk created',
CONFIRMATION_BOX_CREATED_USER:'User created',
CONFIRMATION_BOX_DELETED:'Deleted',
CONFIRMATION_BOX_DELETED_ACTIVITY:'Activity deleted',
CONFIRMATION_BOX_DELETED_AREA:'Area deleted',
CONFIRMATION_BOX_DELETED_CUSTOMER:'Customer deleted',
CONFIRMATION_BOX_DELETED_KIOSK:'Kiosk deleted',
CONFIRMATION_BOX_DELETED_USER:'User deleted',
CONFIRMATION_BOX_SUCCEEDED:'Succeeded',
CONFIRMATION_BOX_UPDATED:'Updated',
CONFIRMATION_BOX_UPDATED_ACTIVITY:'Activity updated',
CONFIRMATION_BOX_UPDATED_AREA:'Area updated',
CONFIRMATION_BOX_UPDATED_CUSTOMER:'Customer updated',
CONFIRMATION_BOX_UPDATED_KIOSK:'Kiosk updated',
CONFIRMATION_BOX_UPDATED_USER:'User updated',
CONNECT_TEMPLATE_ACTIVITIES_TO_AREAS_MODAL_BUTTON_CONNECT:'Connect',
CONNECT_TEMPLATE_ACTIVITIES_TO_AREAS_MODAL_COLUMN_AREA:'Area',
CONNECT_TEMPLATE_ACTIVITIES_TO_AREAS_MODAL_TITLE:'Choose areas to connect to...',
CONTACT_SUPPORT_INFO_TEXT_SUBTITLE:'Telephone number support',
CONTACT_SUPPORT_INFO_TEXT_TITLE:'Email address support',
CREATE_ACTIVITY_DAILY:'Day',
CREATE_ACTIVITY_MONTHLY:'Month',
CREATE_ACTIVITY_WEEKLY:'Week',
CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_ACTIVITIES_STATUS:'Occurs {prefix} every {recurrenceInterval, plural, =1 {} =2 {second} other {{dayOfMonth}}} {recurrenceType, select, monthly {month} weekly {week} daily {day} other {}} from {startDate, date, long}{hasEndDate, select, true { to {endDate, date, long}} false {} other {}} between {fromTime, time, short}-{toTime, time, short}',
CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_ACTIVITIES_WEEK_ORRDINAL:'{week, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}',
CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_TEMPORARY_ACTIVITIES_STATUS:'Occurs {prefix} {startDate, date, long} between {fromTime, time, short}-{toTime, time, short}',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_BUTTON_TOOLTIP:'Inactivate {fullName}',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_ACTIONS_REMOVE:'Inactivate',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_MESSAGE:'You are about to inactivate {fullName}, are you sure?',
CUSTOMERS_PAGE_VIEW_ACTIVE_DELETE_DIALOG_TITLE:'Inactivate customer',
CUSTOMERS_PAGE_VIEW_ADD_CUSTOMER_BUTTON_TEXT:'Add customer',
CUSTOMERS_PAGE_VIEW_COLUMN_AREAS_TITLE:'Area',
CUSTOMERS_PAGE_VIEW_COLUMN_FIRSTNAME:'Firstname',
CUSTOMERS_PAGE_VIEW_COLUMN_LASTNAME:'Lastname',
CUSTOMERS_PAGE_VIEW_COLUMN_NAME_TEXT:' ',
CUSTOMERS_PAGE_VIEW_COLUMN_NAME_TITLE:'Name',
CUSTOMERS_PAGE_VIEW_COLUMN_ROOMNUMBER:'Short name/number',
CUSTOMERS_PAGE_VIEW_COLUMN_STATUS_TEXT:'{active, select, true {Active} false {Inactive} other {Inactive}}',
CUSTOMERS_PAGE_VIEW_COLUMN_STATUS_TITLE:'Status',
CUSTOMERS_PAGE_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'No customers available',
CUSTOMERS_PAGE_VIEW_HEAD_DESCRIPTION:'Add new customers or edit existing customers.',
CUSTOMERS_PAGE_VIEW_HEAD_OVERLINE:'Manage',
CUSTOMERS_PAGE_VIEW_HEAD_TITLE:'Customers',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_BUTTON_TOOLTIP:'Delete {fullName}',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_ACTIONS_REMOVE:'Delete',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_MESSAGE:'You are about to delete {fullName}, are you sure? Personal information will be anonymized as well.',
CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_TITLE:'Delete customer',
CUSTOMERS_PAGE_VIEW_TAB_ACTIVE_CUSTOMERS:'Active customers',
CUSTOMERS_PAGE_VIEW_TAB_INACTIVE_CUSTOMERS:'Inactive customers',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_FORM_SELECT_AREA_LABEL:'Connect area',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_FORM_SELECT_NONE_TEXT:'Select area',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_INFOTEXT_SUBTITLE:'Connect customer to an area.',
CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_INFOTEXT_TITLE:'Area',
CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_SUBTITLE:'Information entered here will be shown on reports for signings',
CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_TEXTFIELD_LABEL:'Extra information',
CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_TITLE:'Extra information',
CUSTOMER_CREATE_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Cancel',
CUSTOMER_CREATE_DIALOG_CONTAINER_FOOTER_SUBMIT_BUTTON_TEXT:'Add customer',
CUSTOMER_CREATE_DIALOG_CONTAINER_HEADER_SUBTITLE:'Basic information',
CUSTOMER_CREATE_DIALOG_CONTAINER_HEADER_TITLE:'Add customer',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_FIRSTNAME_LABEL:'First name',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_LASTNAME_LABEL:'Surname',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE:'Information that makes it helps you identify and contact the customer.',
CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE:'Personal information',
CUSTOMER_CREATE_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL:'Other',
CUSTOMER_CREATE_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE:'Other',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_SUBTITLE:'Information entered here will be shown on reports for signings',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_TEXTFIELD_LABEL:'Extra information',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_EXTRAINFO_TITLE:'Extra information',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_CHANGE_AREA_TEMPLATE_ACTIVITY_ERROR:'Customer has connected template activities unique to the area you are switching from.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_FIRSTNAME_TEXTFIELD_LABEL:'First name',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_LASTNAME_TEXTFIELD_LABEL:'Surname',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_AREA:'Area',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_AREA_SUBTITLE:'Area the customer is connected to.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_SUBTITLE:'Information that makes it helps you identify and contact the customer.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INFOTEXT_TITLE:'Personal information',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_SUBTITLE:'Add room number to anonymize the customer. Short name/number will be shown instead of customer name.',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_ROOM_NUMBER_TITLE:'Short name/number',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL:'Other',
CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_OTHERSECTION_INFOTEXT_TITLE:'Other',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_ADDVISITS_BUTTON_TEXT:'Add activity',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_ALERT_FROM_START_ONLY_NUMBERS_ERROR_TEXT:'Alert from start minutes can only contain numbers',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_DUPLICATE:'Duplicate activity',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_CANCEL_BUTTON:'Cancel',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION:'You are about to remove {title}. Are you sure?',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION_IS_PLANNED:'You are about to remove {title} that have planned activities in the future. Are you sure?',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_REMOVE_BUTTON:'Remove',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_TITLE:'Remove activity',
CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_VISIT_LENGTH_ONLY_NUMBERS_ERROR_TEXT:'Visit Length can only contain numbers',
CUSTOMER_DIALOG_PAUSED_PERIOD_ADD:'Add paused period',
CUSTOMER_DIALOG_PAUSED_PERIOD_HIDE_HISTORY_TITLE:'Hide history',
CUSTOMER_DIALOG_PAUSED_PERIOD_INVALIDENDDATETIME:'End date must not be earlier than start date',
CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_SUBTITLE:'{startDate} {isEndDate, select, true {to {endDate}} false {} other {}}',
CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_TITLE:'Paused',
CUSTOMER_DIALOG_PAUSED_PERIOD_TITLE:'Paused periods',
CUSTOMER_DIALOG_PAUSED_PERIOD_VIEW_HISTORY_TITLE:'View history',
CUSTOMER_DIALOG_TAB_TEMPORARY_ACTITIVIES_TITLE:'Temporary Activities',
CUSTOMER_DIALOG_VIEW_FOOTER_CANCEL_BUTTON_TEXT:'Cancel',
CUSTOMER_DIALOG_VIEW_TAB_AREAS_TITLE:'Section and area',
CUSTOMER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basic information',
CUSTOMER_DIALOG_VIEW_TAB_DESIRED_ACTIVITIES_TITLE:'Activities',
DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT:'Common activity',
DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT_ABBREVIATION:'CA',
DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT:'Planned',
DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT_STARTED:'Started',
DAILY_ACTIVITIES_ITEM_CONTAINER_SIGNED_ACTIVITY_TEXT:'Signed',
DAILY_ACTIVITIES_MODAL_FROM_TITLE:'From',
DAILY_ACTIVITIES_MODAL_INTERNAL_INFORMATION_AREA_SECTION_TITLE:'Area',
DAILY_ACTIVITIES_MODAL_INTERNAL_INFORMATION_ORG_SECTION_TITLE:'Organization',
DAILY_ACTIVITIES_MODAL_INTERNAL_INFORMATION_TITLE:'Support information',
DAILY_ACTIVITIES_MODAL_PERSONNEL_TITLE:'Personnel',
DAILY_ACTIVITIES_MODAL_ROUTE_TITLE:'Route',
DAILY_ACTIVITIES_MODAL_SUBSTITUTES_TITLE:'substitute for',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_BUTTON:'Create',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_SUBTITLE:'Add a temporary activity for a customer or area',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_TITLE:'Create a temporary activity',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_CUSTOMER_ACTIVITY_BUTTON:'Create',
DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_CUSTOMER_ACTIVITY_DISABLED_BUTTON:'Choose customer',
DAILY_ACTIVITIES_MODAL_TO_TITLE:'To',
DAILY_ACTIVITIES_MODAL_WORKSCHEDULE_EMPTY_TEXT:'There are no work shifts',
DAILY_ACTIVITIES_MODAL_WORKSCHEDULE_TITLE:'Work schedules',
DAILY_ACTIVITIES_PAGE_CONTAINER_ACTIVATE_MULTISIGN_TOGGLE_TEXT:'Activate multi signing',
DAILY_ACTIVITIES_PAGE_CONTAINER_ACTIVITIES_NOSCHEDULED_MESSAGE_TEXT:'No activities are currently scheduled',
DAILY_ACTIVITIES_PAGE_CONTAINER_ADD_TEMPORARY_ACTIVITY:'New activity',
DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_HIDE_TEXT:'Hide message board',
DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_SHOW_TEXT:'Show message board',
DAILY_ACTIVITIES_PAGE_CONTAINER_DEACTIVATE_MULTISIGN_TOGGLE_TEXT:'Deactivate multi signing',
DAILY_ACTIVITIES_PAGE_CONTAINER_FUTURE:'Future',
DAILY_ACTIVITIES_PAGE_CONTAINER_GROUPSLIST_ALL_TITLE:'All',
DAILY_ACTIVITIES_PAGE_CONTAINER_HEADER_SUBTITLE:'Todays activities. Press on an activity to sign it. Log in with PIN-code.',
DAILY_ACTIVITIES_PAGE_CONTAINER_HEADER_TITLE:'Todays activities',
DAILY_ACTIVITIES_PAGE_CONTAINER_HIDE_SIGNED_TOGGLE_TEXT:'Hide Signed',
DAILY_ACTIVITIES_PAGE_CONTAINER_HISTORY:'History',
DAILY_ACTIVITIES_PAGE_CONTAINER_INTERNAL_INFORMATION_BUTTON_TEXT:'Support information',
DAILY_ACTIVITIES_PAGE_CONTAINER_LATEST_LOCK_TEXT:'Locks',
DAILY_ACTIVITIES_PAGE_CONTAINER_LATEST_UPDATE_TEXT:'Last updated',
DAILY_ACTIVITIES_PAGE_CONTAINER_PERFORM_MULTISIGN_TEXT:'Sign selected',
DAILY_ACTIVITIES_PAGE_CONTAINER_PLANNINGPAGE_BUTTON_TEXT:'Open calendar',
DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_LOCK_BUTTON_TEXT:'Lock',
DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_UNLOCK_BUTTON_TEXT:'Unlock',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_MAIN_MENU_CUSTOMER_VIEW:'Show the main menu sorted by customer',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_OPEN_MY_TOUR_TITLE:'Open my tour',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_SHOW_EXACT_TIME_ON_ACTIVITY:'Show length of the activities',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_TITLE:'Settings',
DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_UNIT_HOURS:'hr',
DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_LESS_DAILY_ACTIVITIES:'Show Less',
DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_MORE_DAILY_ACTIVITIES:'Time horizon for activities',
DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_SIGNED_TOGGLE_TEXT:'Show signed',
DAILY_ACTIVITIES_PAGE_CONTAINER_WORK_SCHEDULES_BUTTON_TEXT:'Work Schedules',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_HEADER_SUBTITLE:'Signing of multiple activities',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_HEADER_TITLE:'Sign multiple',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_INFOSECTION_SUBTITLE:'All activities will be signed according to the status chosen. If any should have another status, choose ordinary signing instead',
DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_INFOSECTION_TITLE:'Status for the signing',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Cancel',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_FOOTER_DELETE_BUTTON_TEXT:'Delete',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_HEADER_TITLE:'Enter PIN-code',
DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_WRONG_PIN_TEXT:'Incorrect PIN',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_ACTIONS_REMOVE:'Delete',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_MESSAGE:'You are about to delete this activity, are you sure?',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_TITLE:'Delete activity',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CANNOT_TAKE_INFO_TEXT:'Cannot take',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETEDWITHOUTACTION_TEXT:'Complete without action',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETEDWITHOUTACTION_WITH_COMMENT_TEXT:'Complete without action with comment',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETED_TEXT:'Complete',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_COMPLETED_WITH_COMMENT_TEXT:'Complete with comment',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_DELAY_TEXT:'Delay',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_DELAY_WITH_COMMENT_TEXT:'Delay with comment',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_PARTIALLY_COMPLETED_TEXT:'Partially completed',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_PARTIALLY_GIVEN_TEXT:'Partially given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_REMOVEACTIVITY_TEXT:'Remove',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT:'{status} with comment',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYEDSECTION_SUBTITLE:'The delayed activity needs to be handled',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYEDSECTION_TITLE:'Delayed activity',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYSECTION_SUBTITLE:'Delay the activity. If anything needs additional information please add it as a note.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYSECTION_TITLE:'Delay',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELEGATIONSECTION_SUBTITLE:'The activity has delegation requirements that has not been fulfilled',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELEGATIONSECTION_TITLE:'Delegation requirements',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_DELAYED_INFO:'This delayed activity has another occurence today and must be handled before todays activity can be signed',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_INFO:'The activity has a double instance today because of an earlier planned activity that has been delayed. The delayed instance must be handled before the current one can be signed',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_SUBTITLE:' ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_TITLE:'Double instance',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DRUGLISTSECTION_SUBTITLE:'The Expected amount in time unit or measurement unit',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DRUGLISTSECTION_TITLE:'Expected amount / length',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_SUBTITLE:'Sign the activity. If anything needs additional information please add it as a note.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_TITLE:'Sign',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECENTSECTION_SUBTITLE:'Shows recently performed the last 24h plus another performed.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECENTSECTION_TITLE:'Recently performed',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_ONETIME:' ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_SUBTITLE:' ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_TITLE:'Temporary activity',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_REMOVESECTION_SUBTITLE:'Temporary activities created in the last 24 hours are available for removal on this device',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_REMOVESECTION_TITLE:'Remove activity',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_SUBTITLE:'The activity priority',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_TIMECRITICAL:'Time critical, planned start: ',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_TITLE:'Priority',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STARTED_SUBTITLE:'Start the activity',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STARTED_TITLE:'Start',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STATUSSECTION_SUBTITLE:'Current status',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STATUSSECTION_TITLE:'Status',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_DELAYED_INFO_TEXT:'Delayed',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_GIVEN_INFO_TEXT:'Given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_SUBTITLE:'When you revert signing you are required to enter a notation.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_TITLE:'Notation is required',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_NOT_GIVEN_INFO_TEXT:'Not given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_PARTIALLY_COMPLETE_INFO_TEXT:'Partially complete',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_PARTIALLY_GIVEN_INFO_TEXT:'Partially given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_RELATIVE_GIVEN_INFO_TEXT:'Relative given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SELF_GIVEN_INFO_TEXT:'Self given',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SENT_ALONG_INFO_TEXT:'Sent along',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_DONE_INFO_TEXT:'Completed',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_NOTDONE_INFO_TEXT:'Completed without action',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_UNSIGNED_INFO_TEXT:'Unsigned',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SINGLE_ACTIVITY_COMPLETEDWITHOUTACTION_COMMENT_MODAL_SUBTITLE:'When signing an activity as completed without action, please add a comment below.',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_INFO_TEXT:'Expected amount is {expected} {abbreviation}',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_TITLE_PARTIALLY_COMPLETED_TEXT:'Partially completed',
DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_TITLE_PARTIALLY_GIVEN_TEXT:'Partially given',
DAILY_ACTIVITIES_PAGE_USER_DIALOG_CONTAINER_HEADER_PERSONAL_LIST_TITLE:'Select personel',
DAILY_ACTIVITIES_PAGE_USER_DIALOG_CONTAINER_HEADER_RECENT_LOGGED_IN_USERS_TITLE:'Recently logged in',
DAILY_ACTIVITY_EXPIRING_DELEGATIONS:'Expiring delegations',
DAILY_ACTIVITY_EXPIRING_DELEGATIONS_SHOW:'Show',
DAILY_ACTIVITY_LOGGED_IN_BUTTON_CHANGE:'Change',
DAILY_ACTIVITY_LOGGED_IN_STATUS:'Logged in',
DASHBOARD_PAGE_CONTAINER_HEADER_TITLE:'Dashboard',
DASHBOARD_PAGE_KPI_VALUE_UNIT_DAYS:'d',
DASHBOARD_PAGE_KPI_VALUE_UNIT_HOURS:'hr',
DASHBOARD_PAGE_KPI_VALUE_UNIT_MINUTES:'min',
DASHBOARD_PAGE_KPI_VALUE_UNIT_PERCENTAGE:'%',
DASHBOARD_PAGE_KPI_VALUE_UNIT_PIECES:'pc',
DASHBOARD_PAGE_NOTIFICATION_BUTTON_TEXT:'Read more',
DATE_PICKER_TOOLBAR_TITLE:'Select date',
DATE_TIME_PICKER_TOOLBAR_TITLE:'Select date & time',
DEFAULT_ERROR_FALLBACK_MSG:'Something went wrong!',
ERROR_USER_MISSING_ORG_ID:'Logged in user do not have a organization id set.',
EXPIRING_DELEGATIONS_CHANGE_ORGANIZATION:'Change Organization',
EXPIRING_DELEGATIONS_DASHBOARD_SECTION_BUTTON_TEXT:'Show expiring delegations',
EXPIRING_DELEGATIONS_DASHBOARD_SECTION_SUBTITLE:'Show expiring delgations',
EXPIRING_DELEGATIONS_DASHBOARD_SECTION_TITLE:'Expiring Delegations',
EXPIRING_DELEGATIONS_MODAL_HEADER_NO_RESULT_TITLE:'No delegations about to expire',
EXPIRING_DELEGATIONS_MODAL_HEADER_SUBTITLE:'Shows delegations about to expire in the near future',
EXPIRING_DELEGATIONS_MODAL_HEADER_TITLE:'Delegations about to expire',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATEDBYUSERFULLNAME_TABLE_HEADER:'Delegated by',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATEDFORAREANAME_TABLE_HEADER:'Delegated for area',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATEDFORCUSTOMERFULLNAME_TABLE_HEADER:'Delegated for customer',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATIONENDTIME_TABLE_HEADER:'End',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATIONSTARTTIME_TABLE_HEADER:'Start',
EXPIRING_DELEGATIONS_VIEW_DIALOG_DELEGATIONTITLE_TABLE_HEADER:'Delegation',
EXPIRING_DELEGATIONS_VIEW_DIALOG_USERFULLNAME_TABLE_HEADER:'Employee',
FORGOT_PASSWORD_BUTTON_TEXT:'Continue',
FORGOT_PASSWORD_EMAIL:'Enter email',
FORGOT_PASSWORD_ERROR_TEXT:'Cannot find user with entered email',
FORGOT_PASSWORD_INCORRECT_EMAIL:'Enter a valid email',
FORGOT_PASSWORD_LINK_SENT:'You will shortly receive an email with a link to reset your password / pin code.',
FORGOT_PASSWORD_TITLE:'Forgot password / pin code',
FUTURE_MODAL_HEADER_NO_RESULT_TITLE:'No planned activities the next two weeks',
FUTURE_MODAL_HEADER_SUBTITLE:'Shows activities planned the next two weeks',
FUTURE_MODAL_HEADER_TITLE:'Future planned activities',
FUTURE_MODAL_TOGGLE_ALL:'All',
FUTURE_MODAL_TOGGLE_ORDINARY:'Ordinary',
FUTURE_MODAL_TOGGLE_TEMPORARY:'Temporary',
GENERAL_APPROVE:'Approve',
GENERAL_BACK:'Back',
GENERAL_DENY:'Deny',
GENERATE_ACTIVITY_STATUS_PREFIX_EVERY_DAY_TEXT:'every day',
GIDS_CALLBACK_REQUEST_INFO:'Login in progress. Please wait.',
GIDS_CALLBACK_REQUEST_TITLE:'Logging in...',
GRANDIDSETTING_MODAL_DIALOG_HEADER_TITLE:'Administer Login method',
HANGFIRE_BUTTON_LINK:'Hangfire',
HANGFIRE_SUBTITLE:'Open Hangfire portal',
HANGFIRE_TITLE:'Hangfire',
HISTORY_MODAL_HEADER_NO_RESULT_TITLE:'No actions the last week',
HISTORY_MODAL_HEADER_SUBTITLE:'Shows actions performed the last week',
HISTORY_MODAL_HEADER_TITLE:'History of previous actions',
HISTORY_MODAL_TOGGLE_ALL:'All',
HISTORY_MODAL_TOGGLE_ORDINARY:'Ordinary',
HISTORY_MODAL_TOGGLE_TEMPORARY:'Temporary',
IMPERSONATION_REQUEST_INFO:'Tenilo support ({requestedby}) have asked for permission to login using your user account.',
IMPERSONATION_REQUEST_INFO_APPROVE:'To approve the request press the Approve button. If you do not want to approve the login press the deny button.',
IMPERSONATION_REQUEST_INFO_ENSURE:'Please ensure that you have agreed upon this.',
IMPERSONATION_REQUEST_INFO_HANDLED:'Thanks! The request is now handled.',
IMPERSONATION_REQUEST_TITLE:'Impersonation request',
INVOICE_FORM_CONTAINER_FILE_NAME:'customer_report_{fromDate}-{toDate}.csv',
INVOICE_FORM_CONTAINER_FROMDATE_DATEPICKER_LABEL:'From date',
INVOICE_FORM_CONTAINER_ORGANIZATIONID_SELECT_LABEL:'Organization',
INVOICE_FORM_CONTAINER_SUBMIT_BUTTON_TEXT:'Download',
INVOICE_FORM_CONTAINER_SUBTITLE:'To download a customer report, please choose a organization, from and to date then click download. The customer report will be downloaded as customer_report_ddmmyy-ddmmyy.csv',
INVOICE_FORM_CONTAINER_TITLE:'Download customer report',
INVOICE_FORM_CONTAINER_TODATE_DATEPICKER_LABEL:'To date',
LISTING_MODAL_BUTTON_ABORT:'Abort',
LISTING_MODAL_BUTTON_SAVE:'Save',
LISTING_MODAL_LISTING_FIELD_LABEL:'Notation',
LOGGED_IN_USER:'Logged in',
LOGIN_PAGE_CONTAINER_ALLEVI_LINK_TEXT:'Part of allevi',
LOGIN_PAGE_CONTAINER_ERROR:'Wrong username or password.',
LOGIN_PAGE_CONTAINER_FORM_EMAIL_LABEL:'Email',
LOGIN_PAGE_CONTAINER_FORM_FORGOTTEN_PASSWORD:'Forgot password',
LOGIN_PAGE_CONTAINER_FORM_LOGIN_BUTTON_TEXT:'Continue',
LOGIN_PAGE_CONTAINER_FORM_LOGIN_FAILED_LABEL:'Login failed. Please check entered email and password.',
LOGIN_PAGE_CONTAINER_FORM_ORGANIZATION_LABEL:'Organization',
LOGIN_PAGE_CONTAINER_FORM_PASSWORD_LABEL:'Password',
LOGIN_PAGE_CONTAINER_FORM_PWA_LINK:'Tenilo app',
LOGIN_PAGE_CONTAINER_FORM_REMEMBER_ME_TEXT:'Remember me',
LOGIN_PAGE_CONTAINER_FORM_REMEMBER_ORGANIZATION_TEXT:'Remember organizaion',
LOGIN_PAGE_CONTAINER_HEADER_LOGIN_TITLE:'Log in',
LOGIN_PAGE_CONTAINER_WELCOME_TEXT:'Welcome',
LOGIN_PAGE_MODAL_CHOOSE_ORGANIZATION:'Choose Organization',
LOGIN_SERVICE_TYPE_GIDS_BANKID:'BankID',
LOGIN_SERVICE_TYPE_GIDS_FREJAEID:'Freja eID',
LOGIN_SERVICE_TYPE_GIDS_SAML:'Saml',
LOGIN_SERVICE_TYPE_STANDARD:'Standard',
LOGOUT_ALERT_BUTTON_OK:'Ok',
LOGOUT_ALERT_TEXT:'Log in again if needed.',
LOGOUT_ALERT_TITLE:'`You',
LOG_IN_SCENE_LOG_IN_BUTTON_TEXT:'Log in',
LOG_IN_SCENE_LOG_IN_PASSWORD_INPUT_TEXT:'Password',
LOG_IN_SCENE_LOG_IN_TITLE:'Log in',
LOG_IN_SCENE_LOG_IN_USERNAME_INPUT_TEXT:'Username',
LOG_IN_SCENE_LOG_OUT_BUTTON_TEXT:'Log out',
MAIN_MENU_BUTTON_HOVER_ADMINISTER_DEVICES:'Administration',
MAIN_MENU_BUTTON_HOVER_AREAS:'Areas',
MAIN_MENU_BUTTON_HOVER_CUSTOMERS:'Customers',
MAIN_MENU_BUTTON_HOVER_DASHBOARD:'Dashboard',
MAIN_MENU_BUTTON_HOVER_DEVICE_PLANNING:'Deviceplanning',
MAIN_MENU_BUTTON_HOVER_LOGOUT:'Log out',
MAIN_MENU_BUTTON_HOVER_ORG_STRUCTURE:'Organization structure',
MAIN_MENU_BUTTON_HOVER_PERSONNEL:'Personnel',
MAIN_MENU_BUTTON_HOVER_PROFILE:'Profile',
MAIN_MENU_BUTTON_HOVER_REPORTS:'Reports',
MAIN_MENU_BUTTON_HOVER_SUPPORT:'Support',
MAIN_MENU_BUTTON_HOVER_UNIT_PLANNING:'Areaplanning',
META_FIELD_plannedActivityRowId:'Id',
NOTIFICATION_NEW_VERSION_AVAILABLE:'There is a new version of Tenilo. You need to reload the page to access the new update.',
NOTIFICATION_NEW_VERSION_UPDATE_NOW:'Click here to update now',
NOTIFICATION_SELECTED_VISIBLE_AREA_ACTION:'click here',
NOTIFICATION_SELECTED_VISIBLE_AREA_MESSAGE:'You are currently filtering on one or more areas ',
ORGANIZATION_PAGE_CONTAINER_HEADER_OVERLINE:'Manage',
ORGANIZATION_PAGE_CONTAINER_HEADER_SUBTITLE:'Overview of the areas in the organization.',
ORGANIZATION_PAGE_CONTAINER_HEADER_TITLE:' ',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_FOOTER_CANCEL_BUTTON:'Cancel',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_FOOTER_REMOVE_BUTTON:'Remove',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_INFOTEXT_SUBTITLE:'Name for area/section.',
ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_INFOTEXT_TITLE:'Area name',
PAUSED_PERIOD_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
PAUSED_PERIOD_DELETE_DIALOG_ACTIONS_REMOVE:'Remove',
PAUSED_PERIOD_DELETE_DIALOG_MESSAGE:'You are about to remove paused period for {date}. Press remove to continue, or cancel to not remove period',
PAUSED_PERIOD_DELETE_DIALOG_TITLE:'Remove paused period?',
PAUSED_PERIOD_DELETE_NOT_ALLOWED_DIALOG_MESSAGE:'You cannot remove a paused period with start date ({date}) in the past. You have to edit and set an end date. ',
PAUSED_PERIOD_DELETE_NOT_ALLOWED_DIALOG_TITLE:'Removal of paused period not allowed',
PAUSED_PERIOD_DIALOG_CANCEL:'Cancel',
PAUSED_PERIOD_DIALOG_SAVE:'Save',
PAUSED_PERIOD_DIALOG_SECTION_DATE:'Date',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INFOTEXT_SUBTITLE:'Date and time for the paused period.',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_COMMENT:'Comment',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_END_DATE:'End date',
PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_START_DATE:'Start date',
PAUSED_PERIOD_DIALOG_TITLE_ADD:'Add paused period',
PAUSED_PERIOD_DIALOG_TITLE_EDIT:'Edit paused period',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT:'Cancel',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_END_DATE:'End date',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1:'There are upcoming changes on this series. If you only want to implement the change until the next press UNTIL NEXT. If you want to replace them press REPLACE ALL.',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2:'If you rather just make the change for this day. Press CANCEL and then pen-mark the activity and redo the change.',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_MESSAGE_TITLE:'Upcoming changes in series',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_REPLACE_ALL_BUTTON_TEXT:'Replace all',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_ROUTE:'Route',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_START_DATE:'Start date',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_TIME:'Time',
PLANNING_PAGE_ALERT_FUTURE_CHANGES_VIEW_UP_UNTIL_BUTTON_TEXT:'Until next',
PLANNING_PAGE_AREA_CONTAINER_CREATE_ACTIVITY_TITLE:'Common Activity',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT:'Cancel',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_END_DATE:'End date',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1:'There are upcoming changes on this series. If you only want to unplan until the next change press UNTIL NEXT. If you want to unplan including the changes press UNPLAN ALL.',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2:'If you rather just make the change for this day. Press CANCEL and then pen-mark the activity and redo the change.',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_MESSAGE_TITLE:'Upcoming changes in series',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_REPLACE_ALL_BUTTON_TEXT:'Unplan all',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_ROUTE:'Route',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_START_DATE:'Start date',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_TIME:'Time',
PLANNING_PAGE_CONFIRM_UNPLAN_FUTURE_CHANGES_VIEW_UP_UNTIL_BUTTON_TEXT:'Until next',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT:'Cancel',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_COMMENT:'Commnet',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_CONFIRM_BUTTON_TEXT:'Unplan',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_END_DATE:'End date',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1:'There are upcoming changes and pen-marked instances on this series.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2:'For upcoming changes, please chose whether you want to unplan up until the next change (Until next) or if you want to unplan also the upcoming changes (Unplan all).',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION3:'For pen-marked instances, please chose whether you want these to stay planned (No action) or if you want to unplan also the pen-marked instances (Unplan).',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION4:'If you rather just unplan for this day. Press CANCEL and then pen-mark the activity and unplan anew.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TITLE:'Unplan series with upcoming changes and pen-marked instances',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MODIFIED_BY:'Modified by',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_PLANNED:'Planned',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_PLANNED_TIME:'{fromTime} to {endTime} ({duration} minutes)',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_NOACTION:'No action',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNPLAN:'Unplan',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNPLANALL:'Unplan all',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNTILNEXT:'Until next',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_ROUTE:'Route',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_START_DATE:'Start date',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_TIME:'Time',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_CANCEL:'Cancel',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_EXCL:'No',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_INCL:'Yes',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_COMMENT:'Comment',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_MODIFIED_BY:'Modified by',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_PLANNED:'Planned',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_ROUTE:'Route',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_PLANNED_TIME:'{fromTime} to {endTime} ({duration} Minutes)',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE:'Unplan pen marked {title}',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW1:'The series have pen marked instances below.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW2:'• If you want to unplan also the pen marked instances, press YES.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW3:'• If you want to leave the pen marked instances intact, press NO.',
PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_WARNING:'Please note that by unplanning the pen marked instances any changes done to them will be lost.',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_COMMON_ACTIVITY:'Common activity',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_FOOTER_ADD_BUTTON_TEXT:'Add',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_FOOTER_CANCEL_BUTTON_TEXT:'Cancel',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_HEADER_OVERLINE:' ',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_HEADER_SUBTITLE:' ',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE:'Add a temporary activity',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_ERROR_TEXT:'Minutes before warning may only contain numbers',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_LABEL:'Minutes before warning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_ERROR_TEXT:'Length may only contain numbers',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_LABEL:'Length',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_SUBTITLE:'How much time the activity is estimated to take',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TITLE:'Length',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTHOUR_LABEL:'h',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTMINUTE_LABEL:'min',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TEMPLATEACTIVITY_REQUIRED:'Template activity is required',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TITLE_LABEL:'Additional information',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE:'Short additional information about the activity.',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE:'Information',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_ALERT_SUBTITLE:'Number of minutes from where the activity is plannned to begin and when a warning (blinking) is shown on the device. By setting this to 0 minutes the warning is deactivated',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_ALERT_TITLE:'Warning',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_LABEL:'Color',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SHOW_ON_DEVICE:'Show color',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SHOW_ON_DEVICE_SUBTITLE:'Should the color be shown on the device?',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SUBTITLE:'Colors can used to categorize different activity types and get an easy way of overviewing these in the planning views',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_TITLE:'Color',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL:'Other',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_AREA:'Description',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_ORG:'Description',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE:'Additional relevant information for the personnel to complete the task.',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_AREA:'Description of the template activity from the area',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_ORG:'Description of the template activity from the organization',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE:'Other',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_AREA:'Area description',
PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_ORG:'Organization description',
PLANNING_PAGE_DEVICE_PLANNING_ADD_COMBINED_ROUTE_SET_COLOR:'Please give the new combined route a name and color.',
PLANNING_PAGE_DEVICE_PLANNING_ADD_COMBINED_ROUTE_TITLE:'Add combined route',
PLANNING_PAGE_DEVICE_PLANNING_MANAGE_COMBINED_ROUTE_ADD_ROUTE_BUTTON_TEXT:'Add combined route',
PLANNING_PAGE_DEVICE_PLANNING_MANAGE_COMBINED_ROUTE_MAIN_AREA_MISSING:'The device must contain all routes of its main area',
PLANNING_PAGE_DEVICE_PLANNING_MANAGE_DEVICE_ROUTE_BUTTON_TEXT:'Manage',
PLANNING_PAGE_DEVICE_PLANNING_PAGE_CONTAINER_NO_DEVICE_TEXT:'Please active a device',
PLANNING_PAGE_MAINMENU_LOCK:'Lock',
PLANNING_PAGE_MAINMENU_OPEN_DAILYACTIVITIES:'Open daily activities',
PLANNING_PAGE_ROUTESMENU_HIDE:'Hide',
PLANNING_PAGE_ROUTESMENU_HIDE_ALL:'Hide all',
PLANNING_PAGE_ROUTESMENU_SHOW:'Show',
PLANNING_PAGE_ROUTESMENU_SHOW_ALL:'Show all',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_ADDLEFT_ITEM_TEXT:'Add left',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_ADDRIGHT_ITEM_TEXT:'Add right',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_EDIT_ITEM_TEXT:'Edit',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ITEM_TEXT:'Remove',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ALREADY_REMOVED_FROM_OTHER_DATE_THAN_TOMORROW_DIALOG_MESSAGE:'{routeName} is already removed, end date is {endDate}. Would you like to remove it from chosen date?',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ALREADY_REMOVED_FROM_TOMORROW_DIALOG_MESSAGE:'{routeName} is already removed, end date is {endDate}. Would you like to remove it from tomorrows date?',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_CANCEL:'Cancel',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_MESSAGE:'You are about to delete the {route} that has planned in the future. Press Remove to continue or Cancel so as not to delete the route',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_REMOVE:'Remove',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_TITLE:'Remove route',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_CANCEL:'Cancel',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_MESSAGE:'{routeName} can not be removed from todays date. Would you like to remove {routeName} from tomorrows date?',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_REMOVE:'Remove from tomorrow',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_TITLE:'Remove route',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_MESSAGE:'{routeName} is already removed, end date is {endDate}.',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_OK:'Ok',
PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_TITLE:'Route is removed',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_CANCEL_BUTTON_TEXT:'Cancel',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_DIALOG_TITLE:'Create route',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_FORM_NAME_LABEL:'Name',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEROUTE_SAVE_BUTTON_TEXT:'Save',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_SUBTITLE:'The latest workschedules',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_TITLE:'Latest workschedules',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_DATE_LABEL:'To date',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_LABEL:'End time',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_DATE_LABEL:'From date',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_LABEL:'From time',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_STARTDATE_LABEL:'Start date',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_STARTDATE_SUBTITLE:'For the work schedule',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_STARTDATE_TITLE:'Start date',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_FOR_LABEL:'Substitute for',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_SUBTITLE:'Enter the work schedule time interval',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_TITLE:'Time interval',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TITLE:'Create user workschedule',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_LABEL:'Employee',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_SUBTITLE:'Choose the employee to create the work schedule for',
PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_TITLE:'Employee',
PLANNING_PAGE_SCHEDULE_VIEW_CREATE_ROUTE_BUTTON_TEXT:'Create route',
PLANNING_PAGE_SCHEDULE_VIEW_EDITCOMMENT_FORM_NAME_LABEL:'Comment',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_CANCEL_BUTTON_TEXT:'Cancel',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_DIALOG_TITLE:'Edit route',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_FORM_NAME_LABEL:'Name',
PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_SAVE_BUTTON_TEXT:'Save',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_SUBTITLE:'The latest workschedules',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_TITLE:'Latest workschedules',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_DATE_LABEL:'To date',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_LABEL:'End time',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_DATE_LABEL:'From date',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_LABEL:'From time',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_STARTDATE_SUBTITLE:'For the work schedule',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_STARTDATE_TITLE:'Start date',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_FOR_LABEL:'Substitute for',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_LABEL:'Substitute',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_CHECKBOX:'Replace multiple',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_LABEL:'Substitute for all schedules below',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_SUBTITLE:'Used if you want to replace all future planned schedules with a substitute.',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_TITLE:'Add substitute on all future planned schedules',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_SUBTITLE:'The employee to substitute the originally planned user',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_TITLE:'Substitute',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_SUBTITLE:'The work schedule time interval',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_TITLE:'Time interval',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TITLE:'Edit user workschedule',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TITLE_READONLY:'User workschedule',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_LABEL:'Employee',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_SUBTITLE:'The employee to perform the work schedule',
PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_TITLE:'Employee',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_FORM_CANCEL_BUTTON_TEXT:'Cancel',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_FORM_SEARCH_LABEL:'Customer name',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_FORM_SELECT_LABEL:'Select',
PLANNING_PAGE_SEARCH_CUSTOMER_DIALOG_VIEW_INFOTEXT_DESCRIPTION_TEXT:'Search for customer to create a temporary activity.',
PLANNING_PAGE_SHOW_PLANNED_WORKSCHEDULES:'Show planned workschdules',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_ACTIVITIES_NODATA_TEXT:'Create activities',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_ACTIVITIES_TITLE:'Activities',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_COMMON_TASKS_TITLE:'Common tasks',
PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_PERSONNEL_TITLE:'Personnel',
PLANNING_PAGE_SNAP_TO_5_MINUTES:'Snap to 5 minutes',
PLANNING_PAGE_TREEVIEW_CHECKBOX_SHOW_ALL_WORKSCHEDULES:'Show all workschedules',
PLANNING_PAGE_TREEVIEW_CHECKBOX_SHOW_PLANNED_ACTIVITIES:'Show all activities',
PLANNING_PAGE_VIEW_BUTTON_OPEN_DAILYACTIVITIES:'Open daily activities',
PLANNING_PAGE_VIEW_HEAD_SUBTITLE:' ',
PLANNING_PAGE_VIEW_HEAD_TITLE:'Planning',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_CANCEL_BUTTON:'Cancel',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_DESCRIPTION:'You are about to remove {title}. Are you sure?',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_REMOVE_BUTTON:'Remove',
PLANNING_VIEW_USER_SCHEDULE_REMOVE_MODAL_TITLE:'Remove work schedule',
POPOVER_INFO_ACTIVITY_COMMENT:'Comment',
POPOVER_INFO_ACTIVITY_COMPLETED:'Completed {signingDate} by {signedBy}',
POPOVER_INFO_ACTIVITY_COMPLETEDWITHOUTACTION:'Completed without action {signingDate} by {signedBy}',
POPOVER_INFO_ACTIVITY_EXCL_PENMARKED:' (exclusive pen-marked)',
POPOVER_INFO_ACTIVITY_INCL_PENMARKED:' (inclusive pen-marked)',
POPOVER_INFO_ACTIVITY_NOT_IN_A_SERIES:'Not in a series',
POPOVER_INFO_ACTIVITY_PARTICIPANTS:'Participants',
POPOVER_INFO_ACTIVITY_PLANNED:'Planned',
POPOVER_INFO_ACTIVITY_RECURRANCE_ONETIME:'Temporary activity',
POPOVER_INFO_ACTIVITY_SEVERITYLEVEL_TIMECRITICAL:'Time critical',
POPOVER_INFO_ACTIVITY_TEMPORARY_CHANGE:'Temporary change for todays date',
POPOVER_INFO_ACTIVITY_TIMESPAN:'Between which times the activity ought to be performed',
POPOVER_INFO_ACTIVITY_UNDO_BUTTON:'Unplan',
POPOVER_INFO_ACTIVITY_UNDO_BUTTON_ALL:'Unschedule all days in series',
POPOVER_INFO_ACTIVITY_UNDO_BUTTON_SERIES:'Unschedule todays series',
POPOVER_INFO_ACTIVITY_UNTIL:'until',
POPOVER_INFO_NAME_COMMON_ACTIVITY:'Common activity',
PRIVACY_POLICY_BUTTON_LINK:'Privacy policy',
PRIVACY_POLICY_SUBTITLE:'Read our privacy policy',
PRIVACY_POLICY_TITLE:'Privacy policy',
PROFILE_PAGE_GAP_REPORT_BUTTON:'Display gap report',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_AREANAME:'Area',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_AREA_BELONGING:'Choose area belonging',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_END:'Start time',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_LINK:'Link',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_LINK_TEXT:'Link to planning',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_GAP_START:'Start time',
PROFILE_PAGE_GAP_REPORT_MODAL_VALUE_ROUTENAME:'Route',
PROFILE_PAGE_GAP_REPORT_SUBTITLE:'View a report on the gap between work schedule and planned activities for any time period.',
PROFILE_PAGE_GAP_REPORT_TITLE:'Gap report',
PROFILE_PAGE_MANUALS_ACT_AND_PLAN:'Activities and planning',
PROFILE_PAGE_MANUALS_ENABLE_DEVICE:'Activation of device',
PROFILE_PAGE_MANUALS_GET_START_WITH_TENILO:'Get start with Tenilo',
PROFILE_PAGE_MANUALS_LINK:'Manuals',
PROFILE_PAGE_MANUALS_REPORTS:'Reports',
PROFILE_PAGE_MANUALS_SUBTITLE:'The user manuals for how to use Tenilo',
PROFILE_PAGE_MANUALS_TEMPLATE_ACTIVITIES:'Template activities',
PROFILE_PAGE_MANUALS_TITLE:'Manuals',
PROFILE_PAGE_MANUALS_USER:'Manual for user',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_LABEL:'Info',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_LINK:'Support information',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_SUBTITLE:'Support information for organization',
PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_TITLE:'Support information',
RECURRANCE_INTERVAL_1:'Every',
RECURRANCE_INTERVAL_10:'Every 10th',
RECURRANCE_INTERVAL_11:'Every 11th',
RECURRANCE_INTERVAL_12:'Every 12th',
RECURRANCE_INTERVAL_2:'Every other',
RECURRANCE_INTERVAL_3:'Every 3rd',
RECURRANCE_INTERVAL_4:'Every 4th',
RECURRANCE_INTERVAL_5:'Every 5th',
RECURRANCE_INTERVAL_6:'Every 6th',
RECURRANCE_INTERVAL_7:'Every 7th',
RECURRANCE_INTERVAL_8:'Every 8th',
RECURRANCE_INTERVAL_9:'Every 9th',
REPORT_FIELD_ActivityTitle:'Additional text',
REPORT_FIELD_AreaName:'Area',
REPORT_FIELD_CustomerRoomNumber:'Short name/number',
REPORT_FIELD_DelegatedAreaName:'Delegated area',
REPORT_FIELD_DelegatedBy:'Delegated by',
REPORT_FIELD_DelegatedCustomerFullName:'Delegated customer',
REPORT_FIELD_DelegationTitle:'Delegation',
REPORT_FIELD_EndTime:'End time',
REPORT_FIELD_ExpectedAmount:'Expected amount',
REPORT_FIELD_ExtraInformation:'Extra info',
REPORT_FIELD_Firstname:'Firstname',
REPORT_FIELD_GivenAmount:'Given amount',
REPORT_FIELD_Hour00:'00',
REPORT_FIELD_Hour01:'01',
REPORT_FIELD_Hour02:'02',
REPORT_FIELD_Hour03:'03',
REPORT_FIELD_Hour04:'04',
REPORT_FIELD_Hour05:'05',
REPORT_FIELD_Hour06:'06',
REPORT_FIELD_Hour07:'07',
REPORT_FIELD_Hour08:'08',
REPORT_FIELD_Hour09:'09',
REPORT_FIELD_Hour10:'10',
REPORT_FIELD_Hour11:'11',
REPORT_FIELD_Hour12:'12',
REPORT_FIELD_Hour13:'13',
REPORT_FIELD_Hour14:'14',
REPORT_FIELD_Hour15:'15',
REPORT_FIELD_Hour16:'16',
REPORT_FIELD_Hour17:'17',
REPORT_FIELD_Hour18:'18',
REPORT_FIELD_Hour19:'19',
REPORT_FIELD_Hour20:'20',
REPORT_FIELD_Hour21:'21',
REPORT_FIELD_Hour22:'22',
REPORT_FIELD_Hour23:'23',
REPORT_FIELD_IsTemporaryActivity:'Temporary',
REPORT_FIELD_Lastname:'Lastname',
REPORT_FIELD_Legislation:'Legislation',
REPORT_FIELD_MissingDelegations:'Missing delegations',
REPORT_FIELD_ParticipantsAttended:'Attended',
REPORT_FIELD_ParticipantsDenied:'Declined',
REPORT_FIELD_Personell:'Personell',
REPORT_FIELD_PlannedActivityComment:'Comment',
REPORT_FIELD_PlannedActivityStartTime:'Planned start',
REPORT_FIELD_PlannedActivityTime:'Planned activity time',
REPORT_FIELD_PlannedActivityTimeMinutes:'Planned time (minutes)',
REPORT_FIELD_PlannedParticipants:'Planned participants',
REPORT_FIELD_PlanningLink:'Link to planning',
REPORT_FIELD_RouteName:'Route',
REPORT_FIELD_SignedBy:'Signed by',
REPORT_FIELD_SignedByExtraInformation:'Extra info',
REPORT_FIELD_SignedPlannedTimeDiff:'Diff (planned / signed)',
REPORT_FIELD_SignedTime:'Signed time',
REPORT_FIELD_SigningComment:'Signing comment',
REPORT_FIELD_SigningModalLink:'Open signing',
REPORT_FIELD_SigningType:'Sign type',
REPORT_FIELD_StartTime:'Start time',
REPORT_FIELD_Substitute:'Substitute',
REPORT_FIELD_TemplateActivity:'Template activity',
REPORT_FIELD_TemplateActivityCategory:'Category',
REPORT_FIELD_Title:'Title',
REPORT_FIELD_Total:'Total',
REPORT_FIELD_Unit:'Unit',
REPORT_PAGE_TABLE_BORDERS:'Borders',
REPORT_PAGE_TABLE_EXPORT:'Export',
REPORT_PAGE_TABLE_NONE:'None',
REPORT_PAGE_TABLE_PAGE:'Page',
REPORT_PAGE_TABLE_SHOW_ROW_NO:'Show row number',
REPORT_PAGE_VIEW_DELETE_DIALOG_CANCEL_BUTTON:'Cancel',
REPORT_PAGE_VIEW_DELETE_DIALOG_DESCRIPTION:'You are about to remove {title}. Are you sure?',
REPORT_PAGE_VIEW_DELETE_DIALOG_REMOVE_BUTTON:'Remove',
REPORT_PAGE_VIEW_DELETE_DIALOG_TITLE:'Remove report',
REPORT_PAGE_VIEW_FILTER_REPORTS_ALL:'All',
REPORT_PAGE_VIEW_FILTER_REPORTS_AREA:'Area',
REPORT_PAGE_VIEW_FILTER_REPORTS_BASE:'Base',
REPORT_PAGE_VIEW_FILTER_REPORTS_ORGANISATION:'Organisation',
REPORT_PAGE_VIEW_FILTER_REPORTS_USER:'My reports',
REPORT_PAGE_VIEW_FILTER_SELECTION_ActivityType:'Activity type',
REPORT_PAGE_VIEW_FILTER_SELECTION_Area:'Area',
REPORT_PAGE_VIEW_FILTER_SELECTION_Customer:'Customer',
REPORT_PAGE_VIEW_FILTER_SELECTION_DESCRIPTION:'Description',
REPORT_PAGE_VIEW_FILTER_SELECTION_DeclinedParticipant:'Declined',
REPORT_PAGE_VIEW_FILTER_SELECTION_DelegationEndDate:'Delegation date',
REPORT_PAGE_VIEW_FILTER_SELECTION_DelegationEndDate_From:'Delegation date - from',
REPORT_PAGE_VIEW_FILTER_SELECTION_DelegationEndDate_To:'until',
REPORT_PAGE_VIEW_FILTER_SELECTION_DistributionAggregateType:'Aggregate level',
REPORT_PAGE_VIEW_FILTER_SELECTION_DistributionGroupType:'Group type',
REPORT_PAGE_VIEW_FILTER_SELECTION_Legislation:'Legislation',
REPORT_PAGE_VIEW_FILTER_SELECTION_NAME:'Name',
REPORT_PAGE_VIEW_FILTER_SELECTION_Participant:'Participated',
REPORT_PAGE_VIEW_FILTER_SELECTION_PlannedDate:'Planned date',
REPORT_PAGE_VIEW_FILTER_SELECTION_PlannedDate_From:'Planned date - from',
REPORT_PAGE_VIEW_FILTER_SELECTION_PlannedDate_To:'until',
REPORT_PAGE_VIEW_FILTER_SELECTION_Recurrence:'Recurrence',
REPORT_PAGE_VIEW_FILTER_SELECTION_Route:'Route',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignDate:'Signing date',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignDate_From:'Signing date - from',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignDate_To:'until',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignType:'Signing type',
REPORT_PAGE_VIEW_FILTER_SELECTION_SignedBy:'Signed by',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemplateActivity:'Template activity',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemplateActivityCategory:'Category',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemplateActivityType:'Activity type',
REPORT_PAGE_VIEW_FILTER_SELECTION_TemporaryActivity:'Temporary activity',
REPORT_PAGE_VIEW_FILTER_SELECTION_User:'Employee',
REPORT_PAGE_VIEW_FILTER_SELECTION_ViewReport:'View report',
REPORT_PAGE_VIEW_HEAD_TITLE:'Reports',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES:'Availabilities',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM:'Transform to oranization report',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM_ABORT:'Abort transformation',
REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM_INFO:'Press save to complete the transformation to a organization report.',
REPORT_PAGE_VIEW_SECTION_COLUMNS:'Columns',
REPORT_PAGE_VIEW_SECTION_INFO:'Description',
REPORT_PAGE_VIEW_SECTION_NAMEANDDESCRIPTION:'Name and Description',
REPORT_PAGE_VIEW_SECTION_SELECTIONS:'Selections',
REPORT_PAGE_VIEW_SECTION_SUBMIT:' ',
REPORT_PAGE_VIEW_SECTION_SUBMIT_CANCEL:'Cancel',
REPORT_PAGE_VIEW_SECTION_SUBMIT_REMOVE:'Remove',
REPORT_PAGE_VIEW_SECTION_SUBMIT_SAVE:'Save',
REPORT_PAGE_VIEW_SECTION_SUBMIT_SAVEAS:'Save as',
REPORT_PAGE_VIEW_SECTION_SUBMIT_UPDATE:'Save',
REPORT_PAGE_VIEW_SELECT_TITLE:'Choose report',
RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_INPUT_LABEL:'Confirm password',
RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_MIN_LENGTH:'Password must be aleast 8 characters',
RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_VALIDATION_EQUAL:'Passwords must match',
RESET_PASSWORD_PAGE_NEW_PASSWORD_INPUT_LABEL:'New password',
RESET_PASSWORD_PAGE_RESET_PASSWORD_TITLE:'Update password',
RESET_PASSWORD_PAGE_SUBMIT_BUTTON_TEXT:'Update password',
RESET_PINCODE_PAGE_CONFIRM_PINCODE_INPUT_LABEL:'Confirm pincode',
RESET_PINCODE_PAGE_CONFIRM_PINCODE_VALIDATION_EQUAL:'Pincodes must match',
RESET_PINCODE_PAGE_NEW_PINCODE_INPUT_LABEL:'New pincode',
RESET_PINCODE_PAGE_RESET_PINCODE_TITLE:'Update pincode',
RESET_PINCODE_PAGE_SUBMIT_BUTTON_TEXT:'Update pincode',
ROUTE_EDIT_MODAL_UNIQUE_COLOR_HELPER_TEXT:'Cannot have the same color as other routes',
ROUTE_EDIT_MODAL_UNIQUE_NAME_HELPER_TEXT:'Cannot have the same name as other routes',
ROUTE_MENU_PLANNING_COMPLIANCE_COMMOM_ACTIVITY:'Common activity',
ROUTE_MENU_PLANNING_COMPLIANCE_NON_COMPLIANT_WORKSCHEDULES:'Non compliant personnell and workschedules',
ROUTE_MENU_PLANNING_COMPLIANCE_NOT_SATISFIED_REQUIREMENTS:'Not satisfied requirements',
ROUTE_MENU_PLANNING_COMPLIANCE_OUTSIDE_WORKSCHEDULES:'Planned activities outside workschedules',
ROUTE_MENU_PLANNING_COMPLIANCE_WORKSCHEDULES_IN_CONFLICT:'Workschedules in conflict',
ROUTE_MENU_PLANNING_PARTICIPANTS:'Participants',
ROUTE_MENU_PLANNING_REQUIREMENTS:'Requirements',
SIGNED_ACTIVITIES_VIEW_DIALOG_ACTIVITY_NAME_TABLE_HEADER:'Additional text',
SIGNED_ACTIVITIES_VIEW_DIALOG_ACTIVITY_PLANNED_TIME_TABLE_HEADER:'Planned',
SIGNED_ACTIVITIES_VIEW_DIALOG_CUSTOMER_FIRSTNAME_TABLE_HEADER:'First Name',
SIGNED_ACTIVITIES_VIEW_DIALOG_CUSTOMER_LASTNAME_TABLE_HEADER:'Last Name',
SIGNED_ACTIVITIES_VIEW_DIALOG_CUSTOMER_ROOM_NUMBER_TABLE_HEADER:'Room Number',
SIGNED_ACTIVITIES_VIEW_DIALOG_DECLINED_PLANNED_ACTIVITY_SIGNED_PARTICIPANTS_TABLE_HEADER:'Declined',
SIGNED_ACTIVITIES_VIEW_DIALOG_DIFF_TABLE_HEADER:'Difference (planned vs signed)',
SIGNED_ACTIVITIES_VIEW_DIALOG_LEGISLATION_TABLE_HEADER:'Legislation',
SIGNED_ACTIVITIES_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'No signed activites between chosen dates',
SIGNED_ACTIVITIES_VIEW_DIALOG_PARTICIPATED_PLANNED_ACTIVITY_SIGNED_PARTICIPANTS_TABLE_HEADER:'Participated',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_CANCELED:'Canceled',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_COMPLETED:'Completed',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_DELAYED:'Delayed',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_NOT_COMPLETED:'Completed without action',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_PARTIALLY_COMPLETE:'Partially completed',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNED_VALUE_PARTIALLY_GIVEN:'Partially given',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_COMMENT_TABLE_HEADER:'Signingcomment',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_DATE_TABLE_HEADER:'Signed',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_EXPECTED_AMOUNT_TABLE_HEADER:'Expected amount',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_GIVEN_AMOUNT_TABLE_HEADER:'Given amount',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_NOTE_TABLE_HEADER:'Comment',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_UNIT_TABLE_HEADER:'Unit',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGNING_USER_TABLE_HEADER:'Signed by',
SIGNED_ACTIVITIES_VIEW_DIALOG_SIGN_TYPE_TABLE_HEADER:'Sign type',
SIGNED_ACTIVITIES_VIEW_DIALOG_TEMPLATE_ACTIVITY_CATEGORY_NAME_TABLE_HEADER:'Category',
SIGNED_ACTIVITIES_VIEW_DIALOG_TEMPLATE_ACTIVITY_NAME_TABLE_HEADER:'Templateactivity',
SIGNED_ACTIVITIES_VIEW_DIALOG_TITLE:'Signed Activities',
SIGNED_ACTIVITY_CHANGE_ORGANIZATION:'Change Organization',
SIGNED_ACTIVITY_DASHBOARD_SECTION_BUTTON_TEXT:'Show History',
SIGNED_ACTIVITY_DASHBOARD_SECTION_SUBTITLE:'Show signed activities history list',
SIGNED_ACTIVITY_DASHBOARD_SECTION_TITLE:'Signed Activity',
SIGNING_PARTICIPANTS_MODAL_TITLE:'Participants',
SIGNING_PARTICIPANTS_MODAL_TITLE_WITHCOMMENT:'Comment and participants',
SIGNING_STATUS_CANNOT_TAKE:'Cannot take',
SIGNING_STATUS_COMPLETED:'Completed',
SIGNING_STATUS_COMPLETED_WITHOUT_ACTION:'Completed without action',
SIGNING_STATUS_DELAYED:'Delayed',
SIGNING_STATUS_GIVEN:'Given',
SIGNING_STATUS_NOT_COMPLETED:'Cancelled',
SIGNING_STATUS_NOT_GIVEN:'Not given',
SIGNING_STATUS_PARTIALLY_COMPLETE:'Partially complete',
SIGNING_STATUS_PARTIALLY_GIVEN:'Partially ginven',
SIGNING_STATUS_PARTIALLY_HELPER:'Possible to sign with other amount.',
SIGNING_STATUS_RELATIVE_GIVEN:'Relative given',
SIGNING_STATUS_SELF_GIVEN:'Self given',
SIGNING_STATUS_SENT_ALONG:'Sent along',
SIGNING_STATUS_STARTED:'Started',
SINGLE_ACTIVITY_MODAL_BY:'by',
SINGLE_ACTIVITY_MODAL_UNDO:'Undo',
START_PAGE_VIEW_ACTIONS_GETINFO_BUTTON_TEXT:'Get my information',
START_PAGE_VIEW_ACTIONS_LOGOUT_BUTTON_TEXT:'Log out',
START_PAGE_VIEW_ACTIONS_REMOVETOKEN_BUTTON_TEXT:'Remove token',
START_PAGE_VIEW_INFOSECTION_NAME_TEXT:'Name',
SUPPORT_AREAS_COLUMN_AREANAME:'Area',
SUPPORT_AREAS_COLUMN_ORGANIZATIONNAME:'Organization',
SUPPORT_AREAS_COLUMN_PARENTSNAME:'Organization structure',
SUPPORT_AREA_BUTTON:'Support area',
SUPPORT_AREA_MODAL_DIALOG_ACTIVE_TITLE:'Active',
SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_MESSAGE:'This will initiate anonymization of personal information (Area and area activities).',
SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_OK:'Begin anonymization',
SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_TITLE:'Anonymize personal information',
SUPPORT_AREA_MODAL_DIALOG_AREA_ANONYMIZE:'Anonymize personal information',
SUPPORT_AREA_MODAL_DIALOG_AREA_TITLE:'Area',
SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_LABEL:'Date for autosigning deactivation',
SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_SUBTITLE:'The autosigning will be turned off early on the date given.',
SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_TITLE:'Date when autosigning should be deactived',
SUPPORT_AREA_MODAL_DIALOG_HAS_NOT_PERFORMAUTOSIGN_AND_DIRTY_DESCRIPTION:'The auto signing will be deactivated upon saving',
SUPPORT_AREA_MODAL_DIALOG_HAS_NOT_PERFORMAUTOSIGN_DESCRIPTION:'The auto signing is deactivated.',
SUPPORT_AREA_MODAL_DIALOG_HAS_PERFORMAUTOSIGN_AND_DIRTY_DESCRIPTION:'The auto signing will be activated upon saving and performed next night (and the following).',
SUPPORT_AREA_MODAL_DIALOG_HAS_PERFORMAUTOSIGN_DESCRIPTION:'The auto signing is active.',
SUPPORT_AREA_MODAL_DIALOG_HEADER_TITLE:'Support area',
SUPPORT_AREA_MODAL_DIALOG_IS_ACTIVE_AND_DIRTY_DESCRIPTION:'The area will be activited upon saving.',
SUPPORT_AREA_MODAL_DIALOG_IS_ACTIVE_DESCRIPTION:'The area is active',
SUPPORT_AREA_MODAL_DIALOG_IS_NOT_ACTIVE_AND_DIRTY_DESCRIPTION:'The area will be deactivited upon saving.',
SUPPORT_AREA_MODAL_DIALOG_IS_NOT_ACTIVE_DESCRIPTION:'The area is inactive',
SUPPORT_AREA_MODAL_DIALOG_NOTIFY_TENILO_REPRESENTATIVE:'Attention! Let the responsible at Tenilo know about the change if the changes are saved.',
SUPPORT_AREA_MODAL_DIALOG_ORGANIZATION_TITLE:'Organization',
SUPPORT_AREA_MODAL_DIALOG_PERFORMAUTOSIGN_TITLE:'Auto signing',
SUPPORT_GIDS_COLUMN_GRANDIDPARAMETERID:'Parameter',
SUPPORT_GIDS_COLUMN_GRANDIDSERVICEID:'Service',
SUPPORT_GIDS_COLUMN_PARAMETERVALUE:'Parameter value',
SUPPORT_GRANDID_MODAL_DIALOG_ACTIVE_SUBTITLE:'By activing the service will be available as login method on the login page.',
SUPPORT_GRANDID_MODAL_DIALOG_ACTIVE_TITLE:'Active',
SUPPORT_GRANDID_MODAL_DIALOG_AUTHKEY_SUBTITLE:'Enter the key from Svensk e-Id for the current service. NOTE: This is not the customer api key! That should be entered on the customer organization.',
SUPPORT_GRANDID_MODAL_DIALOG_AUTHKEY_TITLE:'Authenticate Service Key',
SUPPORT_GRANDID_MODAL_DIALOG_SERVICE_TITLE:'Service',
SUPPORT_IMPERSONATING_MESSAGE:'NOTE! You are currently impersonating a user.',
SUPPORT_IMPERSONATING_MESSAGE_REVERT:'Revert',
SUPPORT_IMPERSONATING_MESSAGE_VALID_UNTIL:'Valid until: {validUntil} ({numMinutes} minutes).',
SUPPORT_ORGANIZATION_BUTTON:'Support organization',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ACTIVE_TITLE:'Active',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ADD_GRANDID_SETTING_BUTTON_TEXT:'Add login method',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWAREATEMPLATEACTIVITIES_SUBTITLE:'Enable the areas on the organization to create own template activities.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWAREATEMPLATEACTIVITIES_TITLE:'Allow template activities on area level',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWCOMMENTONSIGNING_SUBTITLE:'Enable the possibility to give a comment when signing an activity.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWCOMMENTONSIGNING_TITLE:'Allow comment when signing',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSIGNINGWHENDELEGATIONSISNOTFULFILLED_TITLE:'Allow signing when required delegations are not met',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTANDARDLOGIN_SUBTITLE:'Standardlogin is email + password. If the organization uses e.g. SAML this setting can be used to prevent user logging in using standard login.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTANDARDLOGIN_TITLE:'Allow standard login',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTARTEDACTIVITYSTATUS_SUBTITLE:'Shows a button which can be used to say that the activity has been started.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTARTEDACTIVITYSTATUS_TITLE:'Allow status Started',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_MESSAGE:'This will initiate anonymization of personal information (customers and user) for the entire organization.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_OK:'Begin anonymization',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_TITLE:'Anonymize personal information',
SUPPORT_ORGANIZATION_MODAL_DIALOG_CUSTOMERPAUSEDPERIODDAYSHORIZON_SUBTITLE:'Determines how many days in advance a nofication is sent out when the customer is about to return.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_CUSTOMERPAUSEDPERIODDAYSHORIZON_TITLE:'Paused customers - time horizon (days)',
SUPPORT_ORGANIZATION_MODAL_DIALOG_EXPECTEDEMAILDOMAIN_TITLE:'Expected email domain',
SUPPORT_ORGANIZATION_MODAL_DIALOG_EXPIRINGDELEGATIONSWEEKSHORIZON_TITLE:'Expiring delegations - time horizon (weeks)',
SUPPORT_ORGANIZATION_MODAL_DIALOG_FEATURE_INFO:'Note! Any changes made when it comes to features (enabling/disabling) should be done in consultation with customer responsible from Tenilo.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_GRANDIDORGANIZATIONIDENTIFIER_SUBTITLE:'The identifier is used by the customer om the login page to state which organization to login with.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_GRANDIDORGANIZATIONIDENTIFIER_TITLE:'GrandID organization identifier',
SUPPORT_ORGANIZATION_MODAL_DIALOG_HEADER_TITLE:'Support organisation',
SUPPORT_ORGANIZATION_MODAL_DIALOG_INACTIVE_AND_DIRTY_TITLE:'OBS! The organization will be deactived when saving. It will no longer be possible to access it for any organization user.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_LOGINSLIDINGEXPIRATIONMINUTES_SUBTITLE:'Use sliding expirations for logins. Specify the number of minutes for the expiration.',
SUPPORT_ORGANIZATION_MODAL_DIALOG_LOGINSLIDINGEXPIRATIONMINUTES_TITLE:'Login sliding expiration',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_ANONYMIZE:'Anonymize personal information',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_IP_RANGE:'Allowed IP-range for device',
SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_TITLE:'Name',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REGISTRATIONNUMBER_TITLE:'Registration number',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_MESSAGE:'By removing the method, the organization will no longer be able to login using the chosen method. Do you want to remove?',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_OK:'Remove',
SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_TITLE:'Remove login method',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_0_TITLE:'Basic information',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_1_TITLE:'Settings',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_2_TITLE:'Features',
SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_3_TITLE:'GrandID-settings',
SUPPORT_ORGANIZATION_MODAL_DIALOG_USEDELEGATIONS_TITLE:'Uses delegations',
SUPPORT_ORGANIZATION_MODAL_DIALOG_USEDRUGLISTS_TITLE:'Uses drug list',
SUPPORT_ORGANIZATION_MODAL_DIALOG_USEWORKSCHEDULE_TITLE:'Uses work schedule',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETERVALUE_SUBTITLE:' ',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETERVALUE_TITLE:'Parameter value',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_APIKEY_TITLE:'Api key',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_SUBTITLE:' ',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_TITLE:'Parametertyp',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_BANKID_TITLE:'BankId',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_FREJAEID_TITLE:'Freja eID',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_SAML_TITLE:'Saml',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_SUBTITLE:' ',
SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_TITLE:'GrandID service',
SUPPORT_PAGE_VIEW_AREA_LABEL:'Area',
SUPPORT_PAGE_VIEW_HEAD_TITLE:'Support',
SUPPORT_PAGE_VIEW_ORGANIZATION_LABEL:'Organization',
SUPPORT_PAGE_VIEW_TAB_0_TITLE:'Users',
SUPPORT_PAGE_VIEW_TAB_1_TITLE:'Organizations and areas',
SUPPORT_PAGE_VIEW_TAB_2_TITLE:'Impersonfication of users',
SUPPORT_PAGE_VIEW_TAB_3_TITLE:'System settings',
SUPPORT_USERSIMPERSONATION_COLUMN_ORGANIZATIONNAME:'Organization',
SUPPORT_USERSIMPERSONATION_COLUMN_REQUESTEDON:'Requested',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONABANDONEDON:'Abandoned',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONAPPROVEDON:'Approved',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONDENIEDON:'Denied',
SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONEXPIRESON:'Expires',
SUPPORT_USERSIMPERSONATION_COLUMN_STATUS:'Status',
SUPPORT_USERSIMPERSONATION_COLUMN_USER:'User',
SUPPORT_USERS_COLUMN_EMAIL:'Email',
SUPPORT_USERS_COLUMN_FULLNAME:'Name',
SUPPORT_USERS_COLUMN_IMPERSONATE_APPROVED_BUTTON_TOOLTIP:'The request to login as {fullName} is approved',
SUPPORT_USERS_COLUMN_IMPERSONATE_AWAITING_BUTTON_TOOLTIP:'Awaiting approval of request to login as {fullName}',
SUPPORT_USERS_COLUMN_IMPERSONATE_BUTTON_TOOLTIP:'Create request to login as {fullName}',
SUPPORT_USERS_COLUMN_IMPERSONATE_NOT_ALLOWED_BUTTON_TOOLTIP:'Impersonation of user that only have personnel role is not permitted possible',
SUPPORT_USERS_COLUMN_ORGANIZATIONNAME:'Organization',
SUPPORT_USERS_COLUMN_PHONENUMBER:'Telephone number',
SUPPORT_USERS_COLUMN_USERTYPEID:'User type',
SUPPORT_USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_AREA_COLUMN_TITLE:'Area',
SUPPORT_USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_ROLE_COLUMN_TITLE:'{role, select, Admin {Admin} Planner {Planner} Caretaker {Personnel} other { }}',
SUPPORT_USER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basic information',
SUPPORT_USER_DIALOG_VIEW_TAB_ROLES_TITLE:'Roles',
SUPPORT_USER_IMPERSONATE_DIALOG_ABANDON_REQUEST:'Abandon request',
SUPPORT_USER_IMPERSONATE_DIALOG_ACTIVE_REQUEST_SUBTITLE:' ',
SUPPORT_USER_IMPERSONATE_DIALOG_ACTIVE_REQUEST_TITLE:'Status active request',
SUPPORT_USER_IMPERSONATE_DIALOG_CREATE_REQUEST:'Create request',
SUPPORT_USER_IMPERSONATE_DIALOG_HEADER_TITLE:'Impersonation request',
SUPPORT_USER_IMPERSONATE_DIALOG_LOGIN_AS_USER:'Login as user',
SUPPORT_USER_IMPERSONATE_DIALOG_REMOVE_REQUEST:'Remove request',
SUPPORT_USER_IMPERSONATE_DIALOG_USER_SUBTITLE:' ',
SUPPORT_USER_IMPERSONATE_DIALOG_USER_TITLE:'User',
SUPPORT_USER_IMPERSONATE_DIALOG_VALID_TO_TITLE:'Valid to',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDONEDON_SUBTITLE:'Date and time when the session was abandoned',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDONEDON_TITLE:'Abandoned',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDON_REQUEST:'Abandon impersonation request',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_HEADER_TITLE:'Impersonation request - user',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_LOGIN_AS_USER:'Login as user',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ORGANIZATION_TITLE:'Organization',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_REQUESTEDON_SUBTITLE:'Date and time when the request was created',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_REQUESTEDON_TITLE:'Requested',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_STATUS_SUBTITLE:'Information about current status for the request',
SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_STATUS_TITLE:'Status',
SUPPORT_USER_IMPERSONATIONREQUEST_EMAIL_LABEL:'Email',
SUPPORT_USER_IMPERSONATIONREQUEST_FIRSTNAME_LABEL:'Firstname',
SUPPORT_USER_IMPERSONATIONREQUEST_INFOTEXT_TITLE:'User information',
SUPPORT_USER_IMPERSONATIONREQUEST_LASTNAME_LABEL:'Lastname',
SUPPORT_USER_IMPERSONATIONREQUEST_PHONENUMBER_LABEL:'Telephone number',
SUPPORT_USER_MODAL_DIALOG_HEADER_TITLE:'Support user',
SUPPORT_USER_MODAL_DIALOG_ORGANIZATION_TITLE:'Organization',
SUPPORT_USER_MODAL_DIALOG_TYPE_MEMBER:'Member',
SUPPORT_USER_MODAL_DIALOG_TYPE_ORGADMIN:'Organization admin',
SUPPORT_USER_MODAL_DIALOG_TYPE_SUBTITLE:'"Member" are used for regular users such as personell and area admins. "Organization admin" should soly be used for power users as it gives access to everything for the current organization.',
SUPPORT_USER_MODAL_DIALOG_TYPE_TITLE:'User type',
SUPPORT_USER_MODAL_EMAIL_LABEL:'Email',
SUPPORT_USER_MODAL_FIRSTNAME_LABEL:'Firstname',
SUPPORT_USER_MODAL_INACTIVE_USER:'Attention! This is an inactive user. If the user should be re-activated it must be done by an area- or organization-administrator.',
SUPPORT_USER_MODAL_INFOTEXT_TITLE:'User information',
SUPPORT_USER_MODAL_LASTNAME_LABEL:'Lastname',
SUPPORT_USER_MODAL_PHONENUMBER_LABEL:'Phonenumber',
SWITCH_DEVICE_MODE_DASHBOARD_SECTION_BUTTON_TEXT:'Show device mode',
SWITCH_DEVICE_MODE_DASHBOARD_SECTION_SUBTITLE:'Shows the device mode',
SWITCH_DEVICE_MODE_DASHBOARD_SECTION_TITLE:'Device mode',
TABLE_PAGINATION_COUNT_TEXT:'{from}-{to} of {count}',
TABLE_PAGINATION_FIRST_PAGE_TOOLTIP_TEXT:'First page',
TABLE_PAGINATION_LAST_PAGE_TOOLTIP_TEXT:'Last page',
TABLE_PAGINATION_NEXT_PAGE_TOOLTIP_TEXT:'Next page',
TABLE_PAGINATION_PREVIOUS_PAGE_TOOLTIP_TEXT:'Previous page',
TABLE_PAGINATION_ROWS_TEXT:'Rows',
TEST_USE_FORMAT_MESSAGE_TEXT:'Increment',
TEST_USE_FORMAT_MESSAGE_TEXT_AND_VALUE:'Value {value}',
TIME_PICKER_TOOLBAR_TITLE:'Select time',
TOKEN_EXPIRED_TEXT:'You have been logged out because of inactivity. Click the Ok button to login again',
TOKEN_EXPIRED_TITLE:'Logged out',
USERS_PAGE_HEAD_TITLE:'Personnel',
USERS_PAGE_HEAD_TITLE_DESCRIPTION:'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sqeui nesciunt',
USERS_PAGE_HEAD_TITLE_OVERLINE:'Manage',
USERS_PAGE_VIEW_ACTIVATE_BUTTON_TOOLTIP:'Activate {fullName}',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_ACTIONS_CANCEL:'Cancel',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_ACTIONS_REMOVE:'Activate',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_MESSAGE:'You are about to activate {fullName}, are you sure?',
USERS_PAGE_VIEW_ACTIVATE_DIALOG_TITLE:'Activate personnel',
USERS_PAGE_VIEW_ADD_USERS_EMAIL_LIST_TEXT:'Select users to email',
USERS_PAGE_VIEW_ADD_USER_BUTTON_TEXT:'Add personnel',
USERS_PAGE_VIEW_COLUMN_AREAS_TEXT:'{firstArea} +{numberOfRemainingAreas} more',
USERS_PAGE_VIEW_COLUMN_AREAS_TITLE:'Area',
USERS_PAGE_VIEW_COLUMN_EMAIL:'Email',
USERS_PAGE_VIEW_COLUMN_EMAIL_SENT_DATE:'Welcome Email Sent Date',
USERS_PAGE_VIEW_COLUMN_FIRSTNAME:'Firstname',
USERS_PAGE_VIEW_COLUMN_INFO:'Information',
USERS_PAGE_VIEW_COLUMN_INFO_INACTIVE:'Inactivated',
USERS_PAGE_VIEW_COLUMN_LASTNAME:'Lastname',
USERS_PAGE_VIEW_COLUMN_NAME_SUBTEXT:' ',
USERS_PAGE_VIEW_COLUMN_NAME_TEXT:' ',
USERS_PAGE_VIEW_COLUMN_NAME_TITLE:'Name',
USERS_PAGE_VIEW_COLUMN_ROLE_TEXT:'{role, select, Admin {Company Admin} Planner {Planner} Caretaker {Personnel} other { }}',
USERS_PAGE_VIEW_COLUMN_ROLE_TITLE:'Role',
USERS_PAGE_VIEW_COLUMN_STATUS_TEXT:'{active, select, true {Active} false {Inactive} other {Inactive}}',
USERS_PAGE_VIEW_COLUMN_STATUS_TITLE:'Status',
USERS_PAGE_VIEW_DEACTIVATE_BUTTON_TOOLTIP:'Deactivate {fullName}',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_CANCEL:'Cancel',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_ACTIONS_REMOVE:'Deactivate',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_MESSAGE:'You are about to deactivate {fullName}, are you sure?',
USERS_PAGE_VIEW_DEACTIVATE_DIALOG_TITLE:'Deactivate personnel',
USERS_PAGE_VIEW_DEACTIVATE_ON_PRINCIPAL_AREAS_DIALOG_MESSAGE:'{fullName} has access to one or more areas you do not have access to. Inactivation not possible. Would you instead like to remove {fullName} from your areas?',
USERS_PAGE_VIEW_DEACTIVATE_ON_PRINCIPAL_AREAS_DIALOG_TITLE:'Remove personnel from your areas',
USERS_PAGE_VIEW_DELETE_BUTTON_TOOLTIP:'Delete {fullName}',
USERS_PAGE_VIEW_DELETE_DIALOG_ACTIONS_CANCEL:'Cancel',
USERS_PAGE_VIEW_DELETE_DIALOG_ACTIONS_REMOVE:'Delete',
USERS_PAGE_VIEW_DELETE_DIALOG_MESSAGE:'You are about to delete {fullName}, are you sure? Personal information will be anonymized as well.',
USERS_PAGE_VIEW_DELETE_DIALOG_TITLE:'Delete personnel',
USERS_PAGE_VIEW_DIALOG_NO_VALUES_DIPLAYED_TEXT:'No employees available',
USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_PRINCIPAL_TITLE:'Send to yourself',
USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_RESET_PASSOWRD_TITLE:'Reset password',
USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_WELCOME_EMAIL_TITLE:'Send welcome email',
USERS_PAGE_VIEW_EMAIL_DIALOG_USER_TITLE:'User',
USERS_PAGE_VIEW_EMAIL_SELECTED_USERS_TEXT:'Email the selected users',
USERS_PAGE_VIEW_HEAD_DESCRIPTION:'Add new personnel or administer existing personnel.',
USERS_PAGE_VIEW_HEAD_OVERLINE:'Manage',
USERS_PAGE_VIEW_HEAD_TITLE:'Personnel',
USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_ACTIONS_OK:'Cancel',
USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_MESSAGE:'You cannot activate {fullName}. Please make sure that now active user uses the same email {email}',
USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_TITLE:'Activate personnel invalid',
USERS_PAGE_VIEW_REMOVE_FROM_AREAS_DIALOG_ACTIONS_REMOVE:'Remove',
USERS_PAGE_VIEW_TAB_ACTIVE_USERS:'Active personnel',
USERS_PAGE_VIEW_TAB_INACTIVE_USERS:'Inactive personnel',
USERS_PAGE_VIEW_TAB_ORGANIZATION_USERS:'Organization personnel',
USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_AREA_OR_CUSTOMER_MUST_BE_SELECTED:'Either area or customer must be selected',
USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_DELEGATED_BY_REQUIRED:'Delegated by is required',
USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_DELEGATIONENDTIME_INVALID:'End date cannot be before start date',
USER_DIALOG_VIEW_DELEGATION_ADD_BUTTON_TEXT:'Add delegation',
USER_DIALOG_VIEW_DELEGATION_ADD_PLACEMENT_BUTTON_TITLE:'Add',
USER_DIALOG_VIEW_DELEGATION_AREA_CUSTOMER_TITLE:'Area/Customer',
USER_DIALOG_VIEW_DELEGATION_AREA_TITLE:'Area',
USER_DIALOG_VIEW_DELEGATION_AREA_VALUE:'Area: {value}',
USER_DIALOG_VIEW_DELEGATION_CLOSE_PLACEMENT_BUTTON_TITLE:'Close',
USER_DIALOG_VIEW_DELEGATION_CUSTOMER_TITLE:'Customer',
USER_DIALOG_VIEW_DELEGATION_CUSTOMER_VALUE:'Customer: {value}',
USER_DIALOG_VIEW_DELEGATION_DEACTIVATE_DIALOG_MESSAGE:'You are about to Remove this delegation, are you sure?',
USER_DIALOG_VIEW_DELEGATION_DEACTIVATE_DIALOG_TITLE:'Remove delegation',
USER_DIALOG_VIEW_DELEGATION_DEACTIVE_BUTTON_TITLE:'Remove',
USER_DIALOG_VIEW_DELEGATION_DELEGATED_BY_TITLE:'Delegated by',
USER_DIALOG_VIEW_DELEGATION_DESCRIPTION_TITLE:'Description',
USER_DIALOG_VIEW_DELEGATION_END_DATE_TITLE:'End date',
USER_DIALOG_VIEW_DELEGATION_END_DATE_VALUE:'To: {date}',
USER_DIALOG_VIEW_DELEGATION_OPEN_PLACEMENT_BUTTON_TITLE:'Open',
USER_DIALOG_VIEW_DELEGATION_START_DATE_TITLE:'Start date',
USER_DIALOG_VIEW_DELEGATION_START_DATE_VALUE:'From: {date}',
USER_DIALOG_VIEW_DELEGATION_TITLE:'Delegation',
USER_DIALOG_VIEW_EMAIL_ADMIN_AND_PLANNER_HELPER_TEXT:'Admins and planners must have an email',
USER_DIALOG_VIEW_EMAIL_UNIQUE_HELPER_TEXT:'Email must be unique',
USER_DIALOG_VIEW_EMAIL_VALID_HELPER_TEXT:'Email must be valid',
USER_DIALOG_VIEW_EXTRAINFO_LABEL:'Extra information',
USER_DIALOG_VIEW_EXTRAINFO_SUBTITLE:'Information entered here will be shown on reports for signings',
USER_DIALOG_VIEW_EXTRAINFO_TITLE:'Extra information',
USER_DIALOG_VIEW_FOOTER_CANCEL_BUTTON_TEXT:'Cancel',
USER_DIALOG_VIEW_FOOTER_CREATE_BUTTON_TEXT:'Add',
USER_DIALOG_VIEW_FOOTER_UPDATE_BUTTON_TEXT:'Update',
USER_DIALOG_VIEW_HEADER_SUBTITLE:' ',
USER_DIALOG_VIEW_HEADER_TITLE:'Add personnel',
USER_DIALOG_VIEW_INFOSECTION_FORM_EMAIL_DOMAIN_WARNING:'E-mail is using an unexpected domain. Expected {expectedEmailDomain}',
USER_DIALOG_VIEW_INFOSECTION_FORM_EMAIL_LABEL:'Email',
USER_DIALOG_VIEW_INFOSECTION_FORM_FIRSTNAME_LABEL:'First name',
USER_DIALOG_VIEW_INFOSECTION_FORM_LASTNAME_LABEL:'Last name',
USER_DIALOG_VIEW_INFOSECTION_FORM_PHONENUMBER_LABEL:'Phone number',
USER_DIALOG_VIEW_INFOSECTION_INFOTEXT_SUBTITLE:'Add information that makes it easier for you to identify and contact this person.',
USER_DIALOG_VIEW_INFOSECTION_INFOTEXT_TITLE:'Personal information',
USER_DIALOG_VIEW_OTHERSECTION_FORM_OTHER_LABEL:'Other',
USER_DIALOG_VIEW_OTHERSECTION_INFOTEXT_TITLE:'Other',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_BUTTON_TEXT:'Reset password',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_CHECKBOX_TEXT:'Send reset password link to self as well',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_RECEIPT_TEXT:'An email with a password reset link has been sent.',
USER_DIALOG_VIEW_RESETPASSWORDSECTION_INFOTEXT_TITLE:'Reset password',
USER_DIALOG_VIEW_RESETPASSWORDSECTON_MISSING_USER_EMAIL_TEXT:'Personnel needs an email to reset the password.',
USER_DIALOG_VIEW_ROLESECTION_FORM_ADDAREA_NO_AREAS_MENU_ITEM:'No available areas',
USER_DIALOG_VIEW_ROLESECTION_FORM_ADDAREA_SELECT_LABEL:'Add area',
USER_DIALOG_VIEW_ROLESECTION_INFOTEXT_SUBTITLE:'Add areas and mark roles that the user should have. A user must have a role on at least one area.',
USER_DIALOG_VIEW_ROLESECTION_INFOTEXT_TITLE:'Role',
USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_AREA_COLUMN_TITLE:'Area',
USER_DIALOG_VIEW_ROLESECTION_TABLE_HEADER_ROLE_COLUMN_TITLE:'{role, select, Admin {Admin} Planner {Planner} Caretaker {Personnel} other { }}',
USER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE:'Basic information',
USER_DIALOG_VIEW_TAB_DELEGATIONS_TITLE:'Delegations',
USER_DIALOG_VIEW_TAB_ROLES_TITLE:'Roles',
USER_DIALOG_VIEW_USERSECTION_FORM_AREA_HELPER_TEXT:'You need to add at least one area',
USER_DIALOG_VIEW_USERSECTION_FORM_AREA_ROLE_HELPER_TEXT:'Please select at least one role for each area',
USER_DIALOG_VIEW_USERSECTION_FORM_PHONENUMBER_HELPER_TEXT:'Phone number is typed incorrectly. Example: +46701234567',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ERROR_TEXT:'PIN needs to be exactly {length} numbers',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_LABEL:'PIN code',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ONLY_NUMBERS_ERROR_TEXT:'PIN can only contain numbers',
USER_DIALOG_VIEW_USERSECTION_FORM_PIN_REQUIRED_FOR_PERSONNELL_ERROR_TEXT:'PIN is required for personnell',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_CANNOT_CONTAIN_AT_TEXT:'Username cannot contain @',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_CANNOT_CONTAIN_SPACES_ERROR_TEXT:'Username cannot contain spaces',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_CANNOT_CONTAIN_SYMBOL_ERROR_TEXT:'Username cannot contain symbol',
USER_DIALOG_VIEW_USERSECTION_FORM_USERNAME_LABEL:'Username',
USER_DIALOG_VIEW_USERSECTION_INFOTEXT_SUBTITLE:'PIN code is used by caregivers to unlock the daily activity view and sign activities.',
USER_DIALOG_VIEW_USERSECTION_INFOTEXT_TITLE:'Login',
UserImpersonationRequestStatus_Abandoned:'Abandoned',
UserImpersonationRequestStatus_Approved:'Approved',
UserImpersonationRequestStatus_AwaitingApproval:'Awaiting approval',
UserImpersonationRequestStatus_Denied:'Denied',
UserImpersonationRequestStatus_Expired:'Expired',
WORK_SCHEDULE_REPORT_DASHBOARD_BUTTON:'Display schedule report',
WORK_SCHEDULE_REPORT_DASHBOARD_SUBTITLE:'A report that shows schedules of personnel during a set time period.',
WORK_SCHEDULE_REPORT_DASHBOARD_TITLE:'Schedule report',
WORK_SCHEDULE_REPORT_MODAL_VALUE_AREANAME:'Area',
WORK_SCHEDULE_REPORT_MODAL_VALUE_AREA_BELONGING:'Choose area belonging',
WORK_SCHEDULE_REPORT_MODAL_VALUE_ROUTENAME:'Route',
WORK_SCHEDULE_REPORT_MODAL_VALUE_SCHEDULED_END:'End time',
WORK_SCHEDULE_REPORT_MODAL_VALUE_SCHEDULED_START:'Start time',
WORK_SCHEDULE_REPORT_MODAL_VALUE_SUBSTITUTEUSER_FULLNAME:'Substitute',
WORK_SCHEDULE_REPORT_MODAL_VALUE_USER_FULLNAME:'Personnel',
muiLocaleColumnHeaderFiltersTooltipActive:'{count, plural, =1 {active filter} other {active filters}}',
muiLocaleColumnMenuFilter:'Filter',
muiLocaleColumnMenuHideColumn:'Hide',
muiLocaleColumnMenuLabel:'Menu',
muiLocaleColumnMenuShowColumns:'Show columns',
muiLocaleColumnMenuSortAsc:'Sort by ASC',
muiLocaleColumnMenuSortDesc:'Sort by DESC',
muiLocaleColumnMenuUnsort:'Unsort',
muiLocaleColumnsPanelDragIconLabel:'Reorder column',
muiLocaleColumnsPanelHideAllButton:'Hide all',
muiLocaleColumnsPanelShowAllButton:'Show all',
muiLocaleColumnsPanelTextFieldLabel:'Find column',
muiLocaleColumnsPanelTextFieldPlaceholder:'Column title',
muiLocaleErrorOverlayDefaultLabel:'An error occurred.',
muiLocaleFilterOperatorAfter:'is after',
muiLocaleFilterOperatorBefore:'is before',
muiLocaleFilterOperatorContains:'contains',
muiLocaleFilterOperatorEndsWith:'ends with',
muiLocaleFilterOperatorEquals:'equals',
muiLocaleFilterOperatorIs:'is',
muiLocaleFilterOperatorIsAnyOf:'is any of',
muiLocaleFilterOperatorIsEmpty:'is empty',
muiLocaleFilterOperatorIsNotEmpty:'is not empty',
muiLocaleFilterOperatorNot:'is not',
muiLocaleFilterOperatorOnOrAfter:'is on or after',
muiLocaleFilterOperatorOnOrBefore:'is on or before',
muiLocaleFilterOperatorStartsWith:'starts with',
muiLocaleFilterPanelAddFilter:'Add filter',
muiLocaleFilterPanelColumns:'Columns',
muiLocaleFilterPanelDeleteIconLabel:'Delete',
muiLocaleFilterPanelInputLabel:'Value',
muiLocaleFilterPanelInputPlaceholder:'Filter value',
muiLocaleFilterPanelOperatorAnd:'And',
muiLocaleFilterPanelOperatorOr:'Or',
muiLocaleFilterPanelOperators:'Operators',
muiLocaleFilterValueAny:'any',
muiLocaleFilterValueFalse:'false',
muiLocaleFilterValueTrue:'true',
muiLocaleFooterRowSelected:'row selected',
muiLocaleFooterRowsSelected:'rows selected',
muiLocaleNoResultsOverlayLabel:'No results found.',
muiLocaleNoRowsLabel:'No rows',
muiLocaleToolbarColumns:'Columns',
muiLocaleToolbarColumnsLabel:'Select columns',
muiLocaleToolbarDensity:'Density',
muiLocaleToolbarDensityComfortable:'Comfortable',
muiLocaleToolbarDensityCompact:'Compact',
muiLocaleToolbarDensityLabel:'Density',
muiLocaleToolbarDensityStandard:'Standard',
muiLocaleToolbarExport:'Export',
muiLocaleToolbarExportCSV:'Download as CSV',
muiLocaleToolbarExportLabel:'Export',
muiLocaleToolbarExportPrint:'Print',
muiLocaleToolbarFilters:'Filters',
muiLocaleToolbarFiltersLabel:'Show filters',
muiLocaleToolbarFiltersTooltipActive:'{count, plural, =1 {active filter} other {active filters}}',
muiLocaleToolbarFiltersTooltipHide:'Hide filters',
muiLocaleToolbarFiltersTooltipShow:'Show filters',
}