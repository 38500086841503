import { Box, Typography } from '@mui/material'
import React from 'react'
import format from 'utilities/format'
import { AccountMultiple as ParticipantIcon } from 'mdi-material-ui'

export type InfoFuturePlannedComponentProps = Readonly<{
  plannedStartTime: Date
  activityFullName: string | undefined
  routeName: string | undefined
  routeColor: string | undefined
  className?: string
  useParticipants: boolean
}>

export const InfoFuturePlannedView: React.FC<InfoFuturePlannedComponentProps> = ({ plannedStartTime, activityFullName, routeName, routeColor, className, useParticipants }) => {
  return (
    <Box className={className} py={1} px={1}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          {useParticipants && (
            <ParticipantIcon style={{ width: '16px'}}/>
          )}
        <Typography variant="subtitle1" style={{ opacity: 0.6 }}>
          {format(plannedStartTime, 'PPP p')}
        </Typography>
          <Box pr={1} />
          <Typography data-matomo-mask variant="subtitle1">
            {`${activityFullName}`}
          </Typography>

        </Box>
        <Box display="flex" flexDirection="row" alignItems="right">
          <Box sx={{
                backgroundColor: routeColor,
                borderRadius: '25px',
                height: '10px',
                width: '10px',
                minHeight: '10px',
                minWidth: '10px',
                margin: '5px',
                border: '1px solid #6e6e6e',
                alignSelf: 'center'
              }} />
          <Typography data-matomo-mask variant="subtitle1">
            {`${routeName}`}
          </Typography>
          </Box>
      </Box>

    </Box>
  )
}
