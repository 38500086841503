import React from 'react'
import { useUnitApi } from 'api'
import { UnitItem, UnitCreateItem, UnitUpdateItem } from 'api/tillit.api-client'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { AdministerUnitsContext } from '../../administer-units-context'

const useUnitModalContext = () => {
  const { getUnit, updateUnit, createUnit } = useUnitApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [ownerOrganizationId, setOwnerOrganizationId] = React.useState<number | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedUnit, setSelectedUnit] = React.useState<UnitItem | undefined>()

  const { fetchUnitsAsync } = AdministerUnitsContext.useActions()

  const fetchUnitAsync = React.useCallback(
    async (unitId: number) => {
      await fetchAsync(getUnit(unitId)).then(ita => {
        if (ita instanceof UnitItem) {
          setSelectedUnit(ita)
        }
      })
    },
    [fetchAsync, getUnit]
  )

  const openUnitModal = React.useCallback(
    (templateActivityId?: number) => {
      if (!!templateActivityId) {
        void fetchUnitAsync(templateActivityId).then(_ => setModalOpen(true))
      } else {
        setModalOpen(true)
      }
    },
    [fetchUnitAsync]
  )

  const closeUnitModal = React.useCallback(() => {
    setSelectedUnit(undefined)
    setModalOpen(false)
  }, [])

  const createUnitAsync = React.useCallback(
    (createUnitModel: UnitCreateItem) => {
      return fetchAsync(
        createUnit({
          ...createUnitModel,
        })
      ).then(_ => {
        void fetchUnitsAsync()
        closeUnitModal()
      })
    },
    [fetchAsync, createUnit, closeUnitModal, fetchUnitsAsync]
  )

  const updateUnitAsync = React.useCallback(
    (templateActivityCategory: UnitUpdateItem) => {
      return fetchAsync(updateUnit(templateActivityCategory))
        .then(_ => {
          void fetchUnitsAsync()
          closeUnitModal()
        })
        .catch(() => {
          closeUnitModal()
        })
    },
    [closeUnitModal, fetchAsync, fetchUnitsAsync, updateUnit]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedUnit,
      ownerOrganizationId,
    },
    actions: {
      openUnitModal,
      closeUnitModal,
      createUnitAsync,
      updateUnitAsync,
      setOwnerOrganizationId,
      setSelectedUnit,
    },
  }
}

export const UnitModalContext = createContext(useUnitModalContext)
