import { STORAGEPREFIX } from 'config/variables'
import { BulletinBoardModel } from '../bulletin-board-context'

export const DATA_KEY = (STORAGEPREFIX ? STORAGEPREFIX : '') + 'bulletin_data'

export const hasBulletinData = (): boolean => localStorage.getItem(DATA_KEY) !== null

export const getBulletinData = (): BulletinBoardModel | null => {
  const item = localStorage.getItem(DATA_KEY)
  return item ? JSON.parse(item) : null
}

export const setBulletinData = (data: BulletinBoardModel) => {
  localStorage.setItem(DATA_KEY, JSON.stringify(data))
}

export const clearBulletinData = () => {
  localStorage.removeItem(DATA_KEY)
}
