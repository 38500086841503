import React from 'react'
import * as yup from 'yup'
import { IUserItem, UserUpdateItem, UserTypeEnum } from 'api/tenilo.support.api-client'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { SupportUserModalContext } from '../support-user-modal-context'

export type UserForm = Readonly<{
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
  userTypeId: UserTypeEnum,
  organizationName: string,
  active: boolean,
}>

const mapUserFormToUserItem = (initialItem: IUserItem, item: UserForm): UserUpdateItem => {
  return new UserUpdateItem({
    id: initialItem.id,
    userTypeId: item.userTypeId,
  })
}

const mapUserItemToUserForm = (item: IUserItem): UserForm => {
  const mappedItem: UserForm = {
    firstName: item.firstName,
    lastName: item.lastName,
    phoneNumber: item.phoneNumber,
    email: item.email,
    userTypeId: item.userTypeId,
    organizationName: item.organizationName,
    active: item.active,
  }
  return mappedItem
}

const useSupportUserFormData = (initialUser?: IUserItem) => {
  const f = useFormatMessage()

  const { updateUserAsync } = SupportUserModalContext.useActions()
  const user = initialUser

  const initialValues = React.useMemo<UserForm>(
    () =>
      !!user
        ? mapUserItemToUserForm(user)
        : {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          userTypeId: UserTypeEnum.OrganizationMember,
          organizationName: '',
          active: false,
        },
    [user]
  )

  const onSubmit: OnSubmitFunction<UserForm> = React.useCallback(
    async (values, { setSubmitting }) => {

      if (initialUser) {
        const mappedUser = mapUserFormToUserItem(initialUser, values)
        await updateUserAsync(mappedUser).finally(() => setSubmitting(false))
      }
    },
    [initialUser, updateUserAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      /*title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),
*/
/*      description: yup.string().max(
        1024,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
          maxsize: 1024,
        })
      ),*/
    })
  }, [f])

  const name: Name<UserForm> = React.useMemo(
    () => ({
      firstName: 'firstName',
      lastName: 'lastName',
      phoneNumber: 'phoneNumber',
      email: 'email',
      userTypeId: 'userTypeId',
      organizationName: 'organizationName',
      active: 'active'
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useSupportUserFormData
