import { Field, FieldProps } from 'formik'
import React from 'react'
import styled from 'styled-components'
import generateRandomString from 'utilities/generate-random-string'
import BigCheckboxView from './big-checkbox-view'

type Props = Readonly<{
  name: string
  disabled?: boolean | undefined
  label?: string
  value?: string
  returnValue?: 'value' | 'boolean'
  checkedByValue?: boolean
  containerStyle?:React.CSSProperties
}>

const BigCheckboxContainer: React.FC<Props> = ({ name, disabled, label, value, returnValue = 'boolean', checkedByValue = false, containerStyle }) => {
  const id = React.useMemo(() => generateRandomString(), [])

  const checked = React.useCallback(
    (fieldValue: FieldProps['field']['value']) => {
      return checkedByValue ? fieldValue.toString() === value : fieldValue
    },
    [checkedByValue, value]
  )

  const handleChange = React.useCallback(
    ({ field, form }: FieldProps) => (event: any) => {
      switch (returnValue) {
        case 'value':
          form.setFieldValue(field.name, event.target.value || field.value)
          break
        default:
          form.setFieldValue(field.name, !field.value)
          break
      }
    },
    [returnValue]
  )

  return (
    <Field name={name} >
      {(props: FieldProps) => {
        const { field } = props
        return (
          <StyledLabel htmlFor={id} style={{...containerStyle}}>
            <input
              disabled={disabled}
              type="checkbox"
              name={name}
              id={id}
              {...field}
              {...(value ? { value } : null)}
              onChange={handleChange(props)}
              style={{ visibility: 'hidden', position: 'absolute' }}
            />
            <BigCheckboxView checked={checked(field.value)} label={label} />
          </StyledLabel>
        )
      }}
    </Field>
  )
}

const StyledLabel = styled.label`
  display: inline-block;
`

export default BigCheckboxContainer
