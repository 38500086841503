import ModalSection from 'components/modal-section'
import { useFormatMessage } from 'localization'
import React from 'react'
import { IUserItem } from 'api/tillit.api-client'

type Props = Readonly<
  {
    user: IUserItem
  }
>
const LoggedInUserView: React.FC<Props> = ({user}) => {
  const f = useFormatMessage()

  return (
    <>
      <ModalSection
        data-matomo-mask
        title={f('LOGGED_IN_USER')}
        subtitle={`${user.fullName} (${user.email})`}
      />
    </>
  )
}

export default LoggedInUserView
