import { Box, Typography, useTheme } from '@mui/material'
import CenterModal from 'components/center-modal'
import ModalHeader from 'components/modal-header'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { ModalBox } from 'pages/daily-activities/daily-activities-container'
import React from 'react'
import styled from 'styled-components'
import { InfoRecentContainer } from 'components/info-recent/info-recent-container'
import { RecentPerformedSigningItem, RecurrenceEnum } from 'api/tillit.api-client'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { DailyActivitiesSigningStatusesContext } from 'pages/daily-activities/daily-activities-signing-statuses-context'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { HistoryModalContext } from './history-modal-context'

const HistoryModalContainer: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const { fetchState, historyModalOpen, recentPerformedActivities, filteredActivities, filterReccuranceType } = HistoryModalContext.useState()
  const { isLoading } = fetchState

  const { closeHistoryModal, setFilteredActivities, setFilterReccuranceType } = HistoryModalContext.useActions()

  const { signingStatuses } = DailyActivitiesSigningStatusesContext.useState()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')

  const filterActivities = (searchValue: string, activities: RecentPerformedSigningItem[] | undefined): RecentPerformedSigningItem[] | undefined => {
    // 'NFC' means Normalization Form Canonical Composition
    searchValue = searchValue.toLowerCase().normalize('NFC')
    if (!searchValue || (searchValue && searchValue.length === 0)) {
      return activities
    }

    const filtered = activities?.filter(activity => {
      let valid = true
      const activityText = `${activity.activityFullName} ${activity.comment} ${activity.performedByFullName} `
      searchValue.split(' ').forEach(term => {
        if (activityText) {
          valid =
            valid &&
            activityText
              .toLowerCase()
              .normalize('NFC')
              .includes(term)
        }
      })
      return valid
    })

    if (filtered) {
      return filtered
    } else {
      return undefined
    }
  }

  let slash = ' / '

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = filterActivities(searchValue, recentPerformedActivities)
      setFilteredActivities(filtered)
    },
    [setFilterValue, setFilteredActivities, recentPerformedActivities]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredActivities(recentPerformedActivities)
    },
    [setFilterValue, setFilteredActivities, recentPerformedActivities]
  )

  const handleFilterReccuranceType = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, newFilterReccuranceType: string | null) => {

      let filtered: RecentPerformedSigningItem[] | undefined
      if (!filterValue) {
        filtered = filterActivities('', recentPerformedActivities)
      } else {
        filtered = filterActivities(filterValue, recentPerformedActivities)
      }

      if (newFilterReccuranceType === 'temporary') {
        filtered = filtered?.filter(rpsi => rpsi.recurranceType === RecurrenceEnum.OneTime)

      }
      if (newFilterReccuranceType === 'ordinary') {
        filtered = filtered?.filter(rpsi => rpsi.recurranceType !== RecurrenceEnum.OneTime)
      }

      setFilteredActivities(filtered)

      setFilterReccuranceType(newFilterReccuranceType);
    },
    [filterValue, recentPerformedActivities, setFilteredActivities, setFilterReccuranceType]
  );

  return (
    <CenterModal open={historyModalOpen} onClose={closeHistoryModal} responsive>
      {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
      {!isLoading && (
        <ModalBox>
          <ModalHeader
            title={(!isLoading && f('HISTORY_MODAL_HEADER_TITLE')) || ''}
            subtitle={(!isLoading && f('HISTORY_MODAL_HEADER_SUBTITLE')) || ''}
            justifyContent="center"
            responsive
          />
          <ModalBody>
            <Box sx={{display:'flex',paddingBottom:'20px'}}>
              <FilledSearchTextField
                label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                handleChangeFilter={handleChangeFilter}
                handleClearFilter={handleClearFilter}
                searchValue={filterValue}
              />
              <ToggleButtonGroup
                value={filterReccuranceType}
                exclusive
                onChange={handleFilterReccuranceType}
                aria-label="text alignment"
              >
                <ToggleButton value="all" aria-label="left aligned">
                  {f('HISTORY_MODAL_TOGGLE_ALL')}
                </ToggleButton>
                <ToggleButton value="ordinary" aria-label="centered">
                  {f('HISTORY_MODAL_TOGGLE_ORDINARY')}
                </ToggleButton>
                <ToggleButton value="temporary" aria-label="centered">
                  {f('HISTORY_MODAL_TOGGLE_TEMPORARY')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box sx={{ height: '300px', overflowY: 'auto',position:'relative' }}>
              {filteredActivities && (filteredActivities || []).length > 0 ?
                filteredActivities.map((recent: RecentPerformedSigningItem, i) => {
                  return (
                    <InfoRecentContainer
                      key={i}
                      signedComment={recent.signingComment && recent.comment ? recent.signingComment + slash + recent.comment : ((recent.signingComment || '') + (recent.comment || ''))}
                      signedDate={recent.performedOn}
                      signedUserFullname={recent.performedByFullName || ''}
                      status={recent.plannedActivityStatusId}
                      activityFullName={recent.activityFullName || ''}
                      signingStatuses={signingStatuses}
                      declined={recent.declined}
                      useParticipantList={recent.useParticipantList}
                    />
                  )
                })
                :
                <Typography variant="body1" sx={{ fontWeight: 500,position: 'absolute', top:'50%', transform: 'translate(0%, -50%)',width:'100%',textAlign:'center'}}>
                    {f('HISTORY_MODAL_HEADER_NO_RESULT_TITLE')}
                </Typography>
              }
            </Box>
          </ModalBody>
        </ModalBox>
      )}
    </CenterModal>
  )
}

export const Typography70 = styled(Typography)`
  opacity: 0.7;
  text-align: center;
`

export const UserSquareBox = styled.div`
  width: 154px;
  height: 154px;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  background-color: #e0efff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px;
  cursor: pointer;
`

const ModalBody = styled(Box)`
  > div:first-child {
    > div:first-child {
      margin-right: 20px;
    }
  }
  ${(props)=> [props.theme.breakpoints.down('md')]}{
    > div:first-child {
      flex-direction: column;
      > div:first-child {
        padding-bottom: 20px;
      }
    }

  }
`

export default HistoryModalContainer
