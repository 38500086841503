import React from 'react'
import {
    UserWorkScheduleClient,
    PlannedRouteUserWorkScheduleCreateItem,
    PlannedRouteUserWorkScheduleUpdateItem,
    PlannedRouteUserWorkScheduleItem,
    LatestUserWorkScheduleItem,
    PlannedRouteUserWorkScheduleReportItem,
    MovePlannedRouteUserWorkScheduleItem,
    GapReportItem,
    UpdateMultiplePlannedUserWorkScheduleItem
} from 'api/tenilo.workschedule.api-client'
import { WORKSCHEDULE_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class UserWorkScheduleApi {
    private readonly abortController = new AbortController()
    private readonly client: UserWorkScheduleClient

    public constructor() {
        this.client = new UserWorkScheduleClient(WORKSCHEDULE_API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public createPlannedRouteUserWorkSchedule = (model: PlannedRouteUserWorkScheduleCreateItem): Promise<PlannedRouteUserWorkScheduleItem> =>
        this.client
            .createPlannedRouteUserWorkSchedule(model)
            .then(result =>
                result === null
                    ? Promise.reject('When creating a planned route workschedule, no item received from backend.')
                    : Promise.resolve(result)
            )

    public updatePlannedRouteUserWorkSchedule = (model: PlannedRouteUserWorkScheduleUpdateItem): Promise<PlannedRouteUserWorkScheduleItem> =>
        this.client
            .updatePlannedRouteUserWorkSchedule(model)
            .then(result =>
                result === null
                    ? Promise.reject('When updating a planned route workschedule, no item received from backend.')
                    : Promise.resolve(result)
            )

    public getSinglePlannedRouteUserWorkSchedule = (id: number): Promise<PlannedRouteUserWorkScheduleItem> =>
        this.client
            .getSinglePlannedRouteUserWorkSchedule(id)
            .then(result =>
                result === null
                    ? Promise.reject(`No planned route workschedule item received from backend for id ${id}.`)
                    : Promise.resolve(result)
            )

    public deletePlannedRouteUserWorkSchedule = (id: number): Promise<number> =>
        this.client
            .deletePlannedRouteUserWorkSchedule(id)
            .then(() => Promise.resolve(id))

    public listLatestUserWorkSchedules = (userId: number, calendarStartDate: Date): Promise<LatestUserWorkScheduleItem[]> =>
        this.client
            .listLatestUserWorkSchedules(userId, calendarStartDate)
            .then(results =>
                results === null
                    ? Promise.reject(`No user workschedules received from backend for user id ${userId}, calendarStartDate ${calendarStartDate}.`)
                    : Promise.resolve(results)
            )

    public generateUserWorkScheduleReport = (areaIds: number[], startDate: Date, endDate: Date): Promise<PlannedRouteUserWorkScheduleReportItem[]> =>
        this.client
            .generateUserWorkScheduleReport(startDate, endDate, areaIds)
            .then(results =>
                results === null
                    ? Promise.reject(`No user workschedules received from backend for startDate ${startDate}, endDate ${endDate}.`)
                    : Promise.resolve(results)
            )

    public generateGapReport = (areaIds: number[], startDate: Date, endDate: Date): Promise<GapReportItem[]> =>
        this.client
            .generateGapReport(startDate, endDate, areaIds)
            .then(results =>
                results === null
                    ? Promise.reject(`No gaps received from backend for startDate ${startDate}, endDate ${endDate}.`)
                    : Promise.resolve(results)
            )

    public getRouteIdForUserWorkSchedule = (userId: number): Promise<number> =>
        this.client
            .getRouteIdForUserWorkSchedule(userId)
            .then(result =>
                result === null
                    ? Promise.reject(`No planned route workschedule item received from backend for id ${userId}.`)
                    : Promise.resolve(result)
            )

    public listCurrentDayPlannedUserWorkSchedulesOnArea = (areaId: number): Promise<PlannedRouteUserWorkScheduleItem[]> =>
        this.client
            .listCurrentDayPlannedUserWorkSchedulesOnArea(areaId)
            .then(results =>
                results === null
                    ? Promise.reject(`No user workschedules received from backend for area id ${areaId}.`)
                    : Promise.resolve(results)
            )

    public movePlannedRouteUserWorkSchedule = (model: MovePlannedRouteUserWorkScheduleItem): Promise<PlannedRouteUserWorkScheduleItem> =>
        this.client
            .movePlannedRouteUserWorkSchedule(model)
            .then(result =>
                result === null
                    ? Promise.reject('When moving a planned route workschedule, no item received from backend.')
                    : Promise.resolve(result)
            )

    public unplanPlannedRouteUserWorkSchedule = (model: PlannedRouteUserWorkScheduleItem): Promise<PlannedRouteUserWorkScheduleItem> =>
        this.client
            .unplanPlannedRouteUserWorkSchedule(model)
            .then(result =>
                result === null
                    ? Promise.reject('When unplanning a planned route workschedule, no item received from backend.')
                    : Promise.resolve(result)
            )

    public listFuturePlannedUserWorkSchedulesOnUser = (userId: number, areaId: number, plannedRouteUserWorkScheduleId: number): Promise<PlannedRouteUserWorkScheduleItem[]> =>
        this.client
            .listFuturePlannedUserWorkSchedulesOnUser(userId, areaId, plannedRouteUserWorkScheduleId)
            .then(results =>
                results === null
                    ? Promise.reject(`No user workschedules received from backend for user id ${userId}, area id ${areaId} and planned route user work schedule id ${plannedRouteUserWorkScheduleId}.`)
                    : Promise.resolve(results)
            )

    public updateMultiplePlannedUserWorkSchedules = (model: UpdateMultiplePlannedUserWorkScheduleItem[]): Promise<PlannedRouteUserWorkScheduleItem[]> =>
        this.client
            .updateMultiplePlannedUserWorkSchedules(model)
            .then(result =>
                result === null
                    ? Promise.reject('When updating a planned route workschedule, no item received from backend.')
                    : Promise.resolve(result)
            )
}

const useUserWorkScheduleApi = () => React.useMemo(() => new UserWorkScheduleApi(), [])

export default useUserWorkScheduleApi
