import CenterModal from 'components/center-modal'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { css } from 'styled-components'
import { ManageCombinedRoutesModalContainer } from './manage-combined-routes-modal-container'
import ErrorBoundary from 'components/error-boundary'

export const ManageCombinedRoutesModalView: React.SFC = () => {
  const {
    manageCombinedRoutes: { combinedRouteModalOpen },
  } = PlanningPageContext.useState()
  const {
    manageCombinedRoutes: { setCombinedRouteModalOpen },
  } = PlanningPageContext.useActions()

  const handleModalClose = () => {
    setCombinedRouteModalOpen(false)
  }

  return (
    <>
      <CenterModal
        css={css`
          .MuiPaper-root {
            min-width: 37rem;
            min-height: 20rem;
          }
        `}
        open={combinedRouteModalOpen}
        onClose={handleModalClose}>
        <ErrorBoundary>
          <ManageCombinedRoutesModalContainer />
        </ErrorBoundary>
      </CenterModal>
    </>
  )
}
