import { Box, Button, List, ListItemText, ListSubheader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { format } from 'date-fns'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { ListItem } from '@mui/material'
import { IPlannedActivityRowPlanningViewItem } from 'api/tillit.api-client'
import { Radio } from '@mui/material'
import { RadioGroup } from '@mui/material'
import { FormControlLabel } from '@mui/material'

enum UnplanUpcomingEnum {
  tillNext = 'tillNext',
  unplanAll = 'unplanAll',
}

enum UnplanPenMarkedEnum {
  noAction = 'noAction',
  unplan = 'unplan',
}

const ConfirmUnplanPenMarkedAndFutureChangesModalView: React.FC = () => {
  const f = useFormatMessage()
  const {
    openConfirmUnplanPenMarkedAndFutureChangesModal,
    confirmUnplanFutureChangesModalFutureRows,
    confirmUnplanPenMarkedModalFutureRows,
  } = PlanningPageContext.useState()
  const { onCancelConfirmUnplanPenMarkedAndFutureChangesModal, onConfirmUnplanPenMarkedAndFutureChangesModal } = PlanningPageContext.useActions()

  const [optionUpcoming, setOptionUpcoming] = React.useState(UnplanUpcomingEnum.tillNext)
  const [optionPenmarked, setOptionPenmarked] = React.useState(UnplanPenMarkedEnum.noAction)

  const handleConfirmClick = React.useCallback(() => {
    const shouldUnplanAllUpcoming = optionUpcoming === UnplanUpcomingEnum.unplanAll
    const shouldUnplanPenMarked = optionPenmarked === UnplanPenMarkedEnum.unplan
    onConfirmUnplanPenMarkedAndFutureChangesModal(shouldUnplanAllUpcoming, shouldUnplanPenMarked)
  }, [optionUpcoming, optionPenmarked, onConfirmUnplanPenMarkedAndFutureChangesModal])

  const handleCloseClick = React.useCallback(() => {
    onCancelConfirmUnplanPenMarkedAndFutureChangesModal()
  }, [onCancelConfirmUnplanPenMarkedAndFutureChangesModal])

  const handleCancelClick = React.useCallback(() => {
    onCancelConfirmUnplanPenMarkedAndFutureChangesModal()
  }, [onCancelConfirmUnplanPenMarkedAndFutureChangesModal])

  const getDurationInMinutes = (item: IPlannedActivityRowPlanningViewItem) => {
    const timeStart = new Date(item.startTime)
    const timeEnd = new Date(item.endTime)
    return `${(+timeEnd - +timeStart) / 60 / 1000}`
  }

  const handleOptionUpcomingChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOptionUpcoming(UnplanUpcomingEnum[(event.target as HTMLInputElement).value] as UnplanUpcomingEnum)
    },
    [setOptionUpcoming]
  )

  const handleOptionPenmarkedChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOptionPenmarked(UnplanPenMarkedEnum[(event.target as HTMLInputElement).value] as UnplanPenMarkedEnum)
    },
    [setOptionPenmarked]
  )

  return (
    <Dialog open={openConfirmUnplanPenMarkedAndFutureChangesModal} onClose={handleCloseClick} fullWidth={true} maxWidth="md">
      <DialogTitle>{f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION1')}</DialogContentText>
        <DialogContentText>{f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION2')}</DialogContentText>
        <Box display="flex" flexDirection="row">
          <RadioGroup value={optionUpcoming} onChange={handleOptionUpcomingChange}>
            <Box display="flex" flexDirection="row">
              <FormControlLabel
                value={UnplanUpcomingEnum.tillNext}
                control={<Radio />}
                label={f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNTILNEXT')}
              />
              <FormControlLabel
                value={UnplanUpcomingEnum.unplanAll}
                control={<Radio />}
                label={f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNPLANALL')}
              />
            </Box>
          </RadioGroup>
        </Box>

        <Box>
          <List style={{ maxHeight: '20vh', overflow: 'auto' }} subheader={<li />}>
            <div
              style={{
                position: 'sticky',
                top: '0',
                backgroundColor: '#ffffff',
                zIndex: 1000,
              }}>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '8vw',
                  maxWidth: '8vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_START_DATE')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '8vw',
                  maxWidth: '8vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_END_DATE')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '15vw',
                  maxWidth: '15vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_TIME')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '8vw',
                  maxWidth: '8vw',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_ROUTE')}
              </ListSubheader>
            </div>
            {(confirmUnplanFutureChangesModalFutureRows || []).map(item => (
              <ListItem key={item.routeActivityRowId} button>
                <ListItemText
                  style={{
                    minWidth: '8vw',
                    maxWidth: '8vw',
                    overflow: 'hidden',
                  }}>
                  <div>{item.startDate ? format(item.startDate, 'yyyy-MM-dd') : ''}</div>
                </ListItemText>
                <ListItemText
                  style={{
                    minWidth: '8vw',
                    maxWidth: '8vw',
                    overflow: 'hidden',
                  }}>
                  <div>{item.endDate ? format(item.endDate, 'yyyy-MM-dd') : ''}</div>
                </ListItemText>
                <ListItemText
                  style={{
                    minWidth: '15vw',
                    maxWidth: '15vw',
                    overflow: 'hidden',
                  }}>
                  {!item.unplannedContainerRoute && <div>{`${item.startTimeHHMM} - ${item.endTimeHHMM} (${item.durationMinutes} min) `}</div>}
                </ListItemText>
                <ListItemText style={{ minWidth: '8vw', maxWidth: '8vw' }}>
                  <>
                    {!item.unplannedContainerRoute && (
                      <div data-matomo-mask
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {item.color && (
                          <Box
                            style={{
                              backgroundColor: item.color,
                              height: '10px',
                              width: '10px',
                              minHeight: '10px',
                              minWidth: '10px',
                              marginRight: '5px',
                              borderRadius: '25px',
                              border: '1px solid #6e6e6e',
                              display: 'inline-block',
                            }}
                          />
                        )}
                        {item.name}
                      </div>
                    )}
                    {item.unplannedContainerRoute && (
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {'Oplanerad'}
                      </div>
                    )}
                  </>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>

        <DialogContentText>{f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION3')}</DialogContentText>
        <Box display="flex" flexDirection="row">
          <RadioGroup value={optionPenmarked} onChange={handleOptionPenmarkedChange}>
            <Box display="flex" flexDirection="row">
              <FormControlLabel
                value={UnplanPenMarkedEnum.noAction}
                control={<Radio />}
                label={f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_NOACTION')}
              />
              <FormControlLabel
                value={UnplanPenMarkedEnum.unplan}
                control={<Radio />}
                label={f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_RADIO_UNPLAN')}
              />
            </Box>
          </RadioGroup>
        </Box>

        <Box>
          <List style={{ maxHeight: '20vh', overflow: 'auto' }} subheader={<li />}>
            <div
              style={{
                position: 'sticky',
                top: '0',
                backgroundColor: '#ffffff',
                zIndex: 1000,
              }}>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '30vh',
                  maxWidth: '30vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_PLANNED')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '15vh',
                  maxWidth: '15vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_ROUTE')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '20vh',
                  maxWidth: '20vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MODIFIED_BY')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '20vh',
                  maxWidth: '20vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_COMMENT')}
              </ListSubheader>
            </div>
            {(confirmUnplanPenMarkedModalFutureRows || []).map((item: any) => (
              <ListItem key={item.id} button>
                <ListItemText
                  style={{
                    minWidth: '30vh',
                    maxWidth: '30vh',
                    overflow: 'hidden',
                  }}>
                  <div>{format(item.startTime, 'yyyy-MM-dd')}</div>
                  <div>
                    {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_PLANNED_TIME', {
                      fromTime: format(item.startTime, 'HH:mm'),
                      endTime: format(item.endTime, 'HH:mm'),
                      duration: getDurationInMinutes(item),
                    })}
                  </div>
                </ListItemText>
                <ListItemText style={{ minWidth: '15vh', maxWidth: '15vh' }}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.routeColor && (
                      <Box
                        style={{
                          backgroundColor: item.routeColor,
                          height: '10px',
                          width: '10px',
                          minHeight: '10px',
                          minWidth: '10px',
                          marginRight: '5px',
                          borderRadius: '25px',
                          border: '1px solid #6e6e6e',
                          display: 'inline-block',
                        }}
                      />
                    )}
                    {item.routeName}
                  </div>
                  <div>&nbsp;</div>
                </ListItemText>
                <ListItemText style={{ minWidth: '20vh', maxWidth: '20vh' }}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.detachedBy}
                  </div>
                  <div>{format(item.detachedTime, 'yyyy-MM-dd HH:mm')}</div>
                </ListItemText>
                <ListItemText style={{ minWidth: '20vh', maxWidth: '20vh' }}>
                  <div
                    title={item.comment}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.comment}
                  </div>
                  <div>&nbsp;</div>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
        <DialogContentText>{f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_MESSAGE_TEXT_SECTION4')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick} color="secondary">
          {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_CANCEL_BUTTON_TEXT')}
        </Button>
        <Button onClick={handleConfirmClick} color="secondary" autoFocus={false}>
          {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_AND_FUTURE_CHANGES_VIEW_CONFIRM_BUTTON_TEXT')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmUnplanPenMarkedAndFutureChangesModalView
