import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { NavLink, useLocation } from 'react-router-dom'
import PageRoute from 'config/page-routes'
import { LoginPage } from 'components/page'
import { useFormatMessage } from 'localization'
import styled, { css } from 'styled-components'
import { useOrganizationApi } from 'api'
//import { GidsCallbackRequestContext } from './gids-callback-request-context'
//import useGidsCallbackRequestForm from './utilities/use-impersonation-request-form'
//import { useSupportUserGidsCallbackRequestsOpenApi } from 'api'
//import { UserGidsCallbackRequestInfoItem } from 'api/tenilo.support.api-client'

import { GrandIdRequestModel, GrandIdServiceEnum, IGrandIdRequestModel, IUserItem, RoleEnum, SwaggerException, UserAreaItem, UserTypeEnum } from 'api/tillit.api-client'
import usePromiseApi from 'utilities/use-promise'
import Authentication from 'components/authentication'
import { useNavigate } from "react-router-dom"
import { setGidsSessionData } from 'components/authentication/utilities/gids-session-storage'

const StyledNavLink = styled(NavLink)(
  props => css`
    text-decoration: none;
    &.active > * {
      color: ${props.theme.palette.primary.contrastText};
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.3);
    }
  `
)

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const GidsCallbackRequestContainer: React.FC = () => {
  const f = useFormatMessage()
  const queryParameters = useQuery()
  const sessionId = queryParameters.get("sessionId")
  const organization = queryParameters.get("org")
  const serviceId = queryParameters.get("type")

  const { getAllAreasOfOrganization } = useOrganizationApi()
  const { logInWithGidsSessionId, setUser } = Authentication.useActions()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = React.useState(false)

  const redirectUserTo = React.useCallback(() => {
    //    setModalOrganization(false)

    //  if (
    /*   redirectTo === undefined ||
       redirectTo.pathname === PageRoute.Login.path ||
       redirectTo.pathname === PageRoute.DailyActivities.path
       ) {*/
    navigate(PageRoute.Dashboard.path)
    /*} else {
      navigate(redirectTo,{ replace: true })
    }*/
  }, []) //redirectTo])


  const userLoggedIn = React.useCallback(async (user: IUserItem) => {
    //Do we need to support below?
    //if user logged in with URL to /daily/activities/ containing deviceGuid, we validate if the user has access to an area wit that device guid and save cookie in localstorage
    /*  if (locationState?.pathname.startsWith(prefixDailyActivities) && locationState?.pathname.length > prefixDailyActivities.length) {
        const deviceGuidFromUrl = locationState.pathname.substring(prefixDailyActivities.length)
        const kioskAreas = (await fetchAsync(getKioskByGuid(deviceGuidFromUrl))).kioskAreas

        if (!kioskAreas) {
          throw new Error('KioskAreas does not exist')
        }
        const kioskAreaIds = kioskAreas?.map(kiosk => kiosk.areaId)
        const userAreaIds = user?.userAreas?.map(userArea => userArea.areaId)
        if (kioskAreaIds && userAreaIds) {
          const userLoggedInFromUrlValidated = userAreaIds.some(area => kioskAreaIds.includes(area));

          if (userLoggedInFromUrlValidated || user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport) {
            setDeviceGuid(deviceGuidFromUrl)
            navigate(PageRoute.DailyActivities.path)
          }
          else {
            redirectUserTo()
          }
        }
      }*/
    if (user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport) {
      const areas = await getAllAreasOfOrganization(user.organizationId!)

      setUser({
        ...user,
        userAreas: areas.map(
          a =>
            new UserAreaItem({
              id: -1,
              userId: user.id,
              areaId: a.id,
              areaName: a.name,
              roleId: RoleEnum.Admin,
              hasRoutes: a.routeCount > 0, // Note: hasRoutes and hasChildAreas are not telling the truth. We need to remove these or make them work as expected
              hasChildAreas: a.routeCount === 0,
              organizationParentsName: a.organizationParentsName,
            })
        ),
      })
      redirectUserTo()
    } else {
      redirectUserTo()
    }
  }, [])



  const loginUsingGidsSession = React.useCallback(() => {
    if (!(sessionId && organization && serviceId)) {
      return
    }

    const model = new GrandIdRequestModel({
      sessionId,
      organization,
      serviceId: GrandIdServiceEnum[serviceId]
    })

    logInWithGidsSessionId(model).then(user => {
      setGidsSessionData(model)
      userLoggedIn(user)
    })
  }, [fetchAsync, logInWithGidsSessionId, userLoggedIn, sessionId, organization, serviceId]
  )

  React.useEffect(() => {
    loginUsingGidsSession()
  }, [loginUsingGidsSession, sessionId, organization, serviceId])

  return (
    <LoginPage>
      <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
        <Grid item>
          <Typography sx={{
            fontWeight: 600,
            letterSpacing: '0.01em'
          }} variant="h3">{f('GIDS_CALLBACK_REQUEST_TITLE')}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
            <Grid item>
              <Typography data-matomo-mask>{f('GIDS_CALLBACK_REQUEST_INFO')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LoginPage>
  )

}

export default GidsCallbackRequestContainer
