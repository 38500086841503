import { useActivityApi, useCustomerApi, useDeviceApi, useRouteApi, useUserWorkScheduleApi, useUserApi } from 'api'
import usePlanningComplianceApi from 'api/planning-compliance-api'
import { NotSatisfiedPlannedActivityItem, PlanningComplianceItem } from 'api/tenilo.workschedule.api-client'
import {
  CustomerListItem,
  ICustomerItem,
  IKioskItem,
  IKioskListItem,
  IMovePlannedActivityRowSeriesItem,
  IPlannedActivityRowItem,
  IPlannedActivityRowPlanningViewItem,
  IPlannedRoutesActivitiesPlanningViewItem,
  IRouteCreateItem,
  IRoutePlanningViewItem,
  ISidePanelActivitiesPlanningViewItem,
  ISidePanelUsersWorkSchedulesItem,
  ISidePanelPlannedActivityRowsPlanningViewItem,
  SidePanelPlannedRouteUserWorkScheduleItem,
  PageResultOfCustomerListItem,
  PlannedActivityRowPlanningViewItem,
  SidePanelPlannedActivityRowsPlanningViewItem,
  TimeBasedRouteContainerItem,
  FutureRouteActivityRowItem,
  RecurrenceEnum,
  UserListItem,
  RoleEnum,
  UserTypeEnum,
  PlannedActivityParticipantItem,
  IRouteUpdateItem,
  CustomerStatusEnum,
} from 'api/tillit.api-client'
import PageRoute from 'config/page-routes'
import {
  IPlannedRouteUserWorkScheduleItem,
  PlannedRouteUserWorkScheduleItem,
  CommonShiftItem,
  PlannedRouteUserWorkScheduleUpdateItem,
  PlannedRouteUserWorkScheduleCreateItem,
  MovePlannedRouteUserWorkScheduleItem,
  NonCompliantRouteItem,
  NonCompliantRouteTimesItem,
} from 'api/tenilo.workschedule.api-client'

import cloneDeep from 'lodash/cloneDeep'
import { useNavigate } from "react-router-dom"
import { useWorkScheduleApi } from 'api'
import { useDelegationApi } from 'api'
import Authentication from 'components/authentication'
import { addDays, format, isToday, startOfDay } from 'date-fns'
import produce from 'immer'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import useUserAreas from 'utilities/use-user-areas'
import { PlannedActivityRequiredDelegationsItem } from 'api/tillit.api-client'
import { getOldTokenData, setTokenData } from 'api/token-data'
import { IInfoActivityPopover } from './components/popover-info-activity/popover-info-activity-view'
import { IPlannedActivityRowPlanningViewItemWithTime, movePlannedActivityRowDataHelper, plannedActivityRowDataHelper } from './planning-page-helper'

const usePlanningPageContext = (props: { planningId: number; initialDate: Date; type: 'areas' | 'devices'; }) => {
  const { initialDate, planningId, type: planningType } = props
  const { user: currentUser, planningDeviceId, usingCalendarFromDevice } = Authentication.useAuthenticatedState()
  const { setPlanningDeviceId, setPlanningId, unsetUser, setUsingCalendarFromDevice } = Authentication.useActions()
  const { listAreaInheritedCommonShifts } = useWorkScheduleApi()
  const [commonShifts, setCommonShifts] = React.useState<CommonShiftItem[]>()

  const [openMobileTourMenu, setOpenMobileTourMenu] = React.useState<boolean>(false)
  const toggleOpenMobileTourMenu = React.useCallback(() => {
    setOpenMobileTourMenu(!openMobileTourMenu)
  }, [openMobileTourMenu, setOpenMobileTourMenu])

  React.useEffect(() => {
    if (!planningId || !currentUser.useWorkSchedule) {
      return
    }
    fetchAsync(listAreaInheritedCommonShifts(planningId).then(shifts => setCommonShifts(shifts)))
  }, [currentUser, planningId])

  const {
    listPlanningViewForCalendarDay,
    createRoute,
    removeRoute,
    abort: routeAbort,
    editRoute,
    editTimeBasedRouteContainer,
    removeTimeBasedRouteContainer,
  } = useRouteApi()

  const { getPlanningViewForKioskForCalendarDayAsync, createOrUpdateKioskRouteContainersAsync, getDevices, getDevice, abort: deviceAbort } = useDeviceApi()

  const {
    moveDetachedActivity,
    movePlannedActivityRowSeries,
    unplanAllPlannedActivityRowSeries,
    getPlannedActivityRow,
    listFuturePenMarkedActivities,
    listFutureChangedActivities,
    abort: activityAbort,
    delete: deleteActivity,
    updateDetachedActivityParticipants,
  } = useActivityApi()

  const {
    getSinglePlannedRouteUserWorkSchedule,
    deletePlannedRouteUserWorkSchedule,
    updatePlannedRouteUserWorkSchedule,
    createPlannedRouteUserWorkSchedule,
    movePlannedRouteUserWorkSchedule
  } = useUserWorkScheduleApi()

  const {
    listActiveUsersByAreaId
  } = useUserApi()

  const { list: getListCustomer, get: getCustomer, abort: customerAbort, listActiveCustomersOnArea } = useCustomerApi()

  const abort = React.useCallback(() => {
    routeAbort()
    deviceAbort()
    activityAbort()
    customerAbort()
  }, [activityAbort, customerAbort, deviceAbort, routeAbort])

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { fetchAsync: scheduleFetch } = usePromiseApi()
  const { state: combinedRoutesState, fetchAsync: combinedRoutesFetchAsync } = usePromiseApi()
  const { listNonComplianceForAreaAndCalendarDate, listNonCompliantRoutesForPlannedUserWorkSchedule, listNonCompliantRoutesAndTimesForPlannedActivity } = usePlanningComplianceApi()
  const { listRequiredDelegationsForAreaAndCalendarDate } = useDelegationApi()
  const navigate = useNavigate()

  const [unplannedRouteId, setUnplannedRouteId] = React.useState<number | undefined>(undefined)

  const [customerActivities, setCustomerActivities] = React.useState<ISidePanelActivitiesPlanningViewItem[]>([])

  const [commonActivities, setCommonActivities] = React.useState<ISidePanelActivitiesPlanningViewItem[]>([])

  const [userWorkSchedules, setUserWorkSchedules] = React.useState<ISidePanelUsersWorkSchedulesItem[]>([])
  const [nonCompliantRoutesForPlannedUserWorkSchedule, setNonCompliantRoutesForPlannedUserWorkSchedule] = React.useState<NonCompliantRouteItem[] | null>(null)
  const [nonCompliantRoutesandTimesForPlannedActivity, setNonCompliantRoutesandTimesForPlannedActivity] = React.useState<NonCompliantRouteTimesItem[] | null>(null)

  const [timeScale, setTimeScale] = React.useState({
    enable: true,
    interval: 60,
    slotCount: 4,
  })

  const areas = useUserAreas()

  if (planningId === null || (planningType === 'areas' && !areas.some(a => a.areaId === planningId))) {
    throw Error(`Provided area id (${planningId}) for planning page context is not found among logged in users areas`)
  }

  if (!usingCalendarFromDevice && planningType === 'areas') {
    if (!(currentUser.userType === UserTypeEnum.OrganizationAdmin || currentUser.userType === UserTypeEnum.TeniloSupport || currentUser.userType === UserTypeEnum.SystemAdmin)) {

      if (currentUser.userAreas?.length === 0) {
        throw Error(`current user has no users areas`)
      }

      if (!currentUser.userAreas?.some(a => a.areaId === planningId && (a.roleId === RoleEnum.Admin || a.roleId === RoleEnum.Planner))) {
        //Not alllowed to plan.
        navigate(PageRoute.Profile.path)
      }
    }
  }

  const hasUpdatedAreas = React.useRef(false)
  const hasUpdatedDevices = React.useRef(false)
  const [selectedCommonActivityToDelete, setSelectedCommonActivityToDelete] = React.useState<ISidePanelPlannedActivityRowsPlanningViewItem | null>(null)
  const [selectedPlannedRouteUserWorkScheduleToDelete, setSelectedPlannedRouteUserWorkScheduleToDelete] = React.useState<SidePanelPlannedRouteUserWorkScheduleItem | null>(null)

  const [devices, setDevices] = React.useState<IKioskListItem[]>([])

  const [selectedDevice, setSelectedDevice] = React.useState<IKioskItem | undefined>(undefined)

  interface ISearchCustomer {
    status: boolean
    type?: 'showListCustomer' | 'defaultCommon'
  }

  const [searchCustomerOpen, setSearchCustomer] = React.useState<ISearchCustomer>({
    status: false,
    type: 'showListCustomer',
  })

  const [areaUsers, setAreaUsers] = React.useState<UserListItem[] | null>(null)

  const [customerInfo, setCustomerInfo] = React.useState<ICustomerItem | null>(null)

  const clearCustomerInfo = React.useCallback(() => setCustomerInfo(null), [setCustomerInfo])

  const [dayPickerOpen, setDayPickerOpen] = React.useState(false)
  const [day, setDay] = React.useState<Date>(initialDate)

  React.useEffect(() => {
    setDay(initialDate)
  }, [initialDate])

  const userTimer = React.useRef<number | undefined>()

  const cleanUpWhenUsingCalendarFromDevice = React.useCallback(() => {
    window.clearTimeout(userTimer.current)
    const oldTokenData = getOldTokenData()
    unsetUser()
    setPlanningId(null) // areaId
    setUsingCalendarFromDevice(false)
    if (oldTokenData) {
      setTokenData(oldTokenData)
    }
  }, [])

  React.useEffect(() => {
    if (usingCalendarFromDevice) {
      const documentBody = window.document.body
      const mc = new Hammer(documentBody)
      mc.add(new Hammer.Press({ time: 0 }))

      const revertToDailyActivities = () => {
        cleanUpWhenUsingCalendarFromDevice()
        navigate(PageRoute.DailyActivities.path)
      }

      const startTimer = () => {
        window.clearTimeout(userTimer.current)
        userTimer.current = window.setTimeout(revertToDailyActivities, 120 * 1000) //120
      }

      // Initiate the timer
      startTimer()
      mc.on('press', startTimer)
      documentBody.addEventListener('keypress', startTimer)
      return () => {
        mc.off('press', startTimer)
        documentBody.removeEventListener('keypress', startTimer)
        cleanUpWhenUsingCalendarFromDevice()
      }
    }
    return () => { }
  }, [usingCalendarFromDevice, cleanUpWhenUsingCalendarFromDevice])

  const [calendarStartDate, calendarEndDate] = React.useMemo((): [Date, Date] => {
    const dStart = new Date(day.getFullYear(), day.getMonth(), day.getDate() - 1, 24)

    const dEnd = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 0)

    return [dStart, dEnd]
  }, [day])

  const handlePlannedActivityRows = React.useCallback(
    (routesForPlannedActivityRows: IPlannedRoutesActivitiesPlanningViewItem[]): IPlannedActivityRowPlanningViewItem[] => {
      const activities: IPlannedActivityRowPlanningViewItem[] = []
      routesForPlannedActivityRows.forEach(route => {
        if (route.plannedRoutes !== undefined) {
          route.plannedRoutes.forEach(plannedRoute => {
            if (plannedRoute.plannedActivityRows !== undefined) {
              plannedRoute.plannedActivityRows.forEach(plannedActivityItem => {
                activities.push(plannedActivityItem)
              })
            }
          })
        }
      })
      return activities
    },
    []
  )

  const getFunc = React.useCallback(
    ({ areaId, deviceId }: { areaId?: number; deviceId?: number }) => {
      if (!!areaId && !!deviceId) {
        throw Error('Please pass either an areaId or a deviceId, received both.')
      }

      if (!!areaId) {
        return (startTime: Date, endTime: Date) => {
          return listPlanningViewForCalendarDay(areaId, startTime, endTime)
        }
      }
      if (!!deviceId) {
        return (startTime: Date, endTime: Date) => {
          return getPlanningViewForKioskForCalendarDayAsync(deviceId, startTime, endTime)
        }
      }
      throw Error('Please pass either an areaId or a deviceId, received none of them.')
    },
    [getPlanningViewForKioskForCalendarDayAsync, listPlanningViewForCalendarDay]
  )

  const convertTime = (cActivities: ISidePanelActivitiesPlanningViewItem[]) => {
    return cActivities.map(customer => {
      const sidePanelPlannedActivityRowsPlanningViewItems = customer.sidePanelPlannedActivityRowsPlanningViewItems!.map(activity => {
        const getUTCTimeString = (timeHour: number, timeMinute: number) => {
          const date = new Date()
          date.setHours(timeHour)
          date.setMinutes(timeMinute)
          return {
            time: format(date, 'HH:mm').toString(),
            hour: date.getHours(),
            minute: date.getMinutes(),
          }
        }
        return new SidePanelPlannedActivityRowsPlanningViewItem({
          ...activity,
          fromTimeHour: getUTCTimeString(activity.fromTimeHour, activity.fromTimeMinute).hour,
          fromTimeMinute: getUTCTimeString(activity.fromTimeHour, activity.fromTimeMinute).minute,
          fromFullTimeString: getUTCTimeString(activity.fromTimeHour, activity.fromTimeMinute).time,
          toTimeHour: getUTCTimeString(activity.toTimeHour, activity.toTimeMinute).hour,
          toTimeMinute: getUTCTimeString(activity.toTimeHour, activity.toTimeMinute).minute,
          toFullTimeString: getUTCTimeString(activity.toTimeHour, activity.toTimeMinute).time,
        })
      })
      return {
        ...customer,
        sidePanelPlannedActivityRowsPlanningViewItems,
      }
    })
  }

  const [notSatisfiedPlannedActivities, setNotSatisfiedPlannedActivities] = React.useState<Map<number, NotSatisfiedPlannedActivityItem> | null>(null)
  const [nonComplianceForAreaAndCalendarDate, setNonComplianceForAreaAndCalendarDate] = React.useState<PlanningComplianceItem[] | null>(null)
  const [requiredDelegationsForCalendarDay, setRequiredDelegationsForCalendarDay] = React.useState<Map<number, PlannedActivityRequiredDelegationsItem> | null>(null)

  const getNonComplianceItemsForAreaAndCalendarDate = React.useCallback(
    async (areaId: number, start: Date, end: Date) => {
      await listNonComplianceForAreaAndCalendarDate(areaId, start, end).then(planningComplianceItemList => {
        setNonComplianceForAreaAndCalendarDate(planningComplianceItemList)
      })
    },
    [listNonComplianceForAreaAndCalendarDate, setNonComplianceForAreaAndCalendarDate]
  )

  const getRequiredDelegationsForCalendarDay = React.useCallback(
    async (areaId: number, start: Date, end: Date) => {
      await listRequiredDelegationsForAreaAndCalendarDate(areaId, start, end).then(result => {

        const indexedActivityDelegations = new Map<number, PlannedActivityRequiredDelegationsItem>()
        result?.forEach(item => {
          indexedActivityDelegations[item.plannedActivityRowId] = item
        })
        setRequiredDelegationsForCalendarDay(indexedActivityDelegations)
      })
    },
    [listRequiredDelegationsForAreaAndCalendarDate, setRequiredDelegationsForCalendarDay]
  )

  const extractNotSatisfiedPlannedActivities = React.useMemo(() => {
    const indexedActivities = new Map<number, NotSatisfiedPlannedActivityItem>()
    nonComplianceForAreaAndCalendarDate?.forEach(complianceItem => {
      complianceItem.notSatisfiedPlannedActivities.forEach(activity => {
        indexedActivities[activity.plannedActivityId] = activity
      })
    })
    setNotSatisfiedPlannedActivities(indexedActivities)
  },
    [setNotSatisfiedPlannedActivities, nonComplianceForAreaAndCalendarDate]
  )

  const isActivityRequirementsNotSatisfied = React.useCallback(
    (plannedActivityRowId: number) => {
      if (!currentUser.useWorkSchedule) {
        return false
      }
      if (!notSatisfiedPlannedActivities) {
        return false
      }
      if (notSatisfiedPlannedActivities[plannedActivityRowId]) {
        return true
      }
      return false
    },
    [notSatisfiedPlannedActivities]
  )

  const activityRequirementsNotSatisfied = React.useCallback(
    (plannedActivityRowId: number) => {
      if (!currentUser.useWorkSchedule) {
        return null
      }
      if (!notSatisfiedPlannedActivities) {
        return null
      }
      if (notSatisfiedPlannedActivities[plannedActivityRowId]) {
        return notSatisfiedPlannedActivities[plannedActivityRowId]
      }
      return null
    },
    [notSatisfiedPlannedActivities]
  )

  const activityDelegationRequirements = React.useCallback(
    (plannedActivityRowId: number): (PlannedActivityRequiredDelegationsItem | null) => {
      if (!currentUser.useDelegations) {
        return null
      }
      if (!requiredDelegationsForCalendarDay) {
        return null
      }
      if (requiredDelegationsForCalendarDay[plannedActivityRowId]) {
        return requiredDelegationsForCalendarDay[plannedActivityRowId]
      }
      return null
    },
    [requiredDelegationsForCalendarDay]
  )


  const [routesHidden, setRoutesHidden] = React.useState<boolean[]>([])
  const [hasUnplannedWorkSchedules, setHasUnplannedWorkSchedules] = React.useState<boolean>(false)
  const [hasUnplannedCustomerActivities, setHasUnplannedCustomerActivities] = React.useState<boolean>(false)
  const [hasUnplannedCommonActivities, setHasUnplannedCommonActivities] = React.useState<boolean>(false)

  const setSidebarUnplannedInfo = React.useCallback(() => {
    let numberUnplannedCustomerActivities = 0
    customerActivities.forEach(ca => {
      return ca.sidePanelPlannedActivityRowsPlanningViewItems?.forEach(item => {
        numberUnplannedCustomerActivities += item.isPlanned ? 0 : 1
      })
    })
    setHasUnplannedCustomerActivities(numberUnplannedCustomerActivities > 0)

    let numberUnplannedCommonActivities = 0
    commonActivities.forEach(ca => {
      return ca.sidePanelPlannedActivityRowsPlanningViewItems?.forEach(item => {
        numberUnplannedCommonActivities += item.isPlanned ? 0 : 1
      })
    })
    setHasUnplannedCommonActivities(numberUnplannedCommonActivities > 0)

    let numberUnplannedWorkSchedules = 0
    userWorkSchedules.forEach(ca => {
      return ca.sidePanelPlannedRouteUserWorkScheduleItems?.forEach(item => {
        numberUnplannedWorkSchedules += item.isPlanned ? 0 : 1
      })
    })
    setHasUnplannedWorkSchedules(numberUnplannedWorkSchedules > 0)

  }, [customerActivities, commonActivities, userWorkSchedules, setHasUnplannedCustomerActivities, setHasUnplannedCommonActivities, setHasUnplannedWorkSchedules]
  )

  const getRoutePlanningView = React.useCallback(
    ({ areaId, deviceId }: { areaId?: number; deviceId?: number }) => {
      const getPlanningViewForCalendarDayAsync = getFunc({ areaId, deviceId })
      if (areaId && currentUser.useWorkSchedule) {
        getNonComplianceItemsForAreaAndCalendarDate(areaId, calendarStartDate, calendarEndDate)
      }

      if (areaId && currentUser.useDelegations) {
        getRequiredDelegationsForCalendarDay(areaId, calendarStartDate, calendarEndDate)
      }

      return getPlanningViewForCalendarDayAsync(calendarStartDate, calendarEndDate).then((res: IRoutePlanningViewItem) => {
        const { unplannedRouteId: unplannedRouteIdFromApi, sidePanelActivitiesPlanningViewItem, plannedRoutesActivitiesPlanningViewItem, sidePanelUsersWorkSchedulesItems } = res

        const splitContainer = splitRoutePlanningViewItems(sidePanelActivitiesPlanningViewItem)
        setUnplannedRouteId(unplannedRouteIdFromApi)
        setCustomerActivities(convertTime(splitContainer.customerActivities))
        setCommonActivities(convertTime(splitContainer.commonActivities))

        const fixedWorkSchedules = fixUserWorkSchedules(sidePanelUsersWorkSchedulesItems)
        setUserWorkSchedules(fixedWorkSchedules.workSchedules)

        let numberUnplannedCustomerActivities = 0
        splitContainer.customerActivities.forEach(ca => {
          return ca.sidePanelPlannedActivityRowsPlanningViewItems?.forEach(item => {
            numberUnplannedCustomerActivities += item.isPlanned ? 0 : 1
          })
        })
        setHasUnplannedCustomerActivities(numberUnplannedCustomerActivities > 0)

        let numberUnplannedCommonActivities = 0
        splitContainer.commonActivities.forEach(ca => {
          return ca.sidePanelPlannedActivityRowsPlanningViewItems?.forEach(item => {
            numberUnplannedCommonActivities += item.isPlanned ? 0 : 1
          })
        })
        setHasUnplannedCommonActivities(numberUnplannedCommonActivities > 0)

        let numberUnplannedWorkSchedules = 0
        fixedWorkSchedules.workSchedules.forEach(ca => {
          return ca.sidePanelPlannedRouteUserWorkScheduleItems?.forEach(item => {
            numberUnplannedWorkSchedules += item.isPlanned ? 0 : 1
          })
        })
        setHasUnplannedWorkSchedules(numberUnplannedWorkSchedules > 0)

        if (plannedRoutesActivitiesPlanningViewItem) {
          const sorted = plannedRoutesActivitiesPlanningViewItem.sort((a, b) => (a.sortingIndex! >= b.sortingIndex! ? 1 : -1))
          if (!!deviceId) {
            setDeviceData({
              planning: sorted,
              rows: handlePlannedActivityRows(sorted),
            })
          } else if (!!areaId) {
            setRoutes(plannedRoutesActivitiesPlanningViewItem)
            hidePreviouslyHiddenRoutes()
          }
        }
      })
    },
    [getFunc, calendarStartDate, calendarEndDate, handlePlannedActivityRows]
  )

  const updateDevicePlanningView = React.useCallback(() => {
    if (planningType !== 'devices') {
      return //Can not update devices while at areas view
    }

    void fetchAsync(getDevice(planningId)).then(setSelectedDevice)
    void fetchAsync(
      getRoutePlanningView({ deviceId: planningId }).then(() => {
        hasUpdatedDevices.current = false
      })
    ).catch(e => console.log(e))
  }, [fetchAsync, getDevice, getRoutePlanningView, planningId, planningType])

  const updateRoutePlanningView = React.useCallback(() => {
    if (planningType === 'areas') {
      void fetchAsync(
        getRoutePlanningView({ areaId: planningId }).then(() => {
          hasUpdatedAreas.current = false
        })
      ).catch(e => console.log(e))
    }

    if (planningType === 'devices') {
      updateDevicePlanningView()
    }
  }, [fetchAsync, getRoutePlanningView, planningId, planningType, updateDevicePlanningView])

  React.useEffect(() => {
    if (!hasUpdatedAreas.current && planningType === 'areas') {
      hasUpdatedAreas.current = true
      updateRoutePlanningView()
    }
  }, [planningType, updateRoutePlanningView])

  React.useEffect(() => {
    if (!hasUpdatedDevices.current && planningType === 'devices' && planningDeviceId !== null) {
      hasUpdatedDevices.current = true
      updateDevicePlanningView()
    }
  }, [planningDeviceId, planningType, updateDevicePlanningView])

  React.useEffect(() => {
    void combinedRoutesFetchAsync(
      getDevices().then(ds => {
        const timedDevices = ds.filter(device => device.nightFromHour)
        setDevices(timedDevices)
        // If we did not receive any devices, set planning device id to null
        if (timedDevices.length === 0) {
          setPlanningDeviceId(null)
        } else if (
          // Else if we have planning device id and it is not contained in received devices
          planningDeviceId === null ||
          !timedDevices.some(d => d.id === planningDeviceId)
        ) {
          setPlanningDeviceId(timedDevices[0].id)
        }
      })
    )
  }, [combinedRoutesFetchAsync, getDevices, planningDeviceId, setPlanningDeviceId])

  const customerSelected = React.useCallback(
    async (customer: CustomerListItem) => {
      await getCustomer(customer.id).then(customerDetail => {
        setCustomerInfo(customerDetail)
      })
    },
    [getCustomer]
  )

  const [dialogInfoActivityOpen, setDialogInfoActivityOpen] = React.useState<IInfoActivityPopover>({
    open: false,
    id: 0,
    position: null,
  })

  const [editWorkScheduleDialogOpen, setEditWorkScheduleDialogOpen] = React.useState<IInfoActivityPopover>({
    open: false,
    id: 0,
    position: null,
  })

  const [plannedActivity, setPlannedActivity] = React.useState<IPlannedActivityRowItem | null>(null)

  const [routes, setRoutes] = React.useState<IPlannedRoutesActivitiesPlanningViewItem[] | undefined>(undefined)

  const setRoutesVisibility = React.useCallback((hidden: boolean) => {
    setRoutes(prevRoutes => {
      if (prevRoutes === undefined) {
        return prevRoutes
      }
      return produce(prevRoutes.slice(0), draft => {
        draft.forEach(dr => {
          dr.hidden = hidden
          setRoutesHidden(prevRoutesHidden => {
            if (prevRoutesHidden === undefined) {
              return prevRoutesHidden
            }
            return produce(prevRoutesHidden.slice(0), rhdraft => {
              rhdraft[dr.id] = hidden
              return rhdraft
            })
          })
        })
        return draft
      })
    })
  }, [])

  const hideAllRoutes = React.useCallback(() => {
    const hidden = true
    setRoutesVisibility(hidden)
  }, [setRoutesVisibility])

  const showAllRoutes = React.useCallback(() => {
    const hidden = false
    setRoutesVisibility(hidden)
  }, [setRoutesVisibility])


  const toggleRouteVisibility = React.useCallback((routeId: number) => {
    setRoutes(prevRoutes => {
      if (prevRoutes === undefined) {
        return prevRoutes
      }
      return produce(prevRoutes.slice(0), draft => {
        draft.forEach(dr => {
          if (dr.id === routeId) {
            const hidden = !dr.hidden
            dr.hidden = hidden
            setRoutesHidden(prevRoutesHidden => {
              if (prevRoutesHidden === undefined) {
                return prevRoutesHidden
              }
              return produce(prevRoutesHidden.slice(0), rhdraft => {
                rhdraft[dr.id] = hidden
                return rhdraft
              })
            })
          }
        })
        return draft
      })
    })
  }, [])

  const hideRoute = React.useCallback((routeId: number) => {
    setRoutes(prevRoutes => {
      if (prevRoutes === undefined) {
        return prevRoutes
      }
      return produce(prevRoutes.slice(0), draft => {
        draft.forEach(dr => {
          if (dr.id === routeId) {
            dr.hidden = true
          }
        })
        return draft
      })
    })
  }, [])

  const hidePreviouslyHiddenRoutes = React.useCallback(() => {
    routesHidden?.forEach((hidden, index) => {
      if (hidden) {
        hideRoute(index)
      }
    })
  }, [routesHidden, hideRoute])

  const [deviceData, setDeviceData] = React.useState<{
    planning: IPlannedRoutesActivitiesPlanningViewItem[]
    rows: IPlannedActivityRowPlanningViewItem[]
  }>({ planning: [], rows: [] })

  const [listCustomer, setListCustomer] = React.useState<PageResultOfCustomerListItem>()

  const getAllCustomers = React.useCallback(() => {
    void getListCustomer(1, 250, currentUser.organizationId!!).then(list => {
      list.items = list.items.filter(c => c.statusId === CustomerStatusEnum.Active)
      setListCustomer(list)
    })
  }, [currentUser.organizationId])

  const openDialogInfoActivity = React.useCallback(
    (status: boolean, idData: number, position: any) => {
      if (status) {
        void getPlannedActivityRow(idData).then(res => {
          setPlannedActivity(res)
        })
      } else {
        setPlannedActivity(null)
      }
      setDialogInfoActivityOpen({
        open: status,
        id: idData,
        position,
      })
    },
    [getPlannedActivityRow]
  )

  const closeDialogInfoActivity = React.useCallback(() => {
    setPlannedActivity(null)
    setDialogInfoActivityOpen({
      open: false,
      id: 0,
      position: null,
    })
  }, [])

  const openEditUserWorkScheduleDialog = React.useCallback(
    (isPlanned: boolean, wsId: number) => {

      setEditUserWorkScheduleIsPlanned(isPlanned)

      void getSinglePlannedRouteUserWorkSchedule(wsId).then(pruws => {
        setEditUserWorkScheduleModalData(pruws)
        void listActiveUsersByAreaId(planningId).then(users => {
          setAreaUsers(users)
          setEditUserWorkScheduleModalOpen(true)
        })
      })
    },
    [getSinglePlannedRouteUserWorkSchedule, listActiveUsersByAreaId]
  )

  const closeEditUserWorkScheduleDialog = React.useCallback(() => {
    setEditUserWorkScheduleModalOpen(false)
    setEditUserWorkScheduleModalData(null)
  }, [])

  const openCreateUserWorkScheduleDialog = React.useCallback(
    () => {
      void listActiveUsersByAreaId(planningId).then(au => {
        setAreaUsers(au)
        setCreateUserWorkScheduleModalOpen(true)
      })
    },
    [listActiveUsersByAreaId, planningId]
  )

  const closeCreateUserWorkScheduleDialog = React.useCallback(() => {
    setCreateUserWorkScheduleModalOpen(false)
  }, [])

  const updateUserWorkScheduleAsync = React.useCallback(
    (item: PlannedRouteUserWorkScheduleUpdateItem) => {
      return fetchAsync(updatePlannedRouteUserWorkSchedule(item))
        .then(
          () => fetchAsync(getRoutePlanningView({ areaId: planningId }))
        ).catch(err => {
          return Promise.reject(err)
        }

        )
    }
    ,
    [updatePlannedRouteUserWorkSchedule, getRoutePlanningView, planningId]
  )

  const createUserWorkScheduleAsync = React.useCallback(
    (item: PlannedRouteUserWorkScheduleCreateItem) => {
      return fetchAsync(createPlannedRouteUserWorkSchedule(item))
        .then(
          () => fetchAsync(getRoutePlanningView({ areaId: planningId }))
        ).catch(err => {
          return Promise.reject(err)
        }

        )
    }
    ,
    [updatePlannedRouteUserWorkSchedule, getRoutePlanningView, planningId]
  )

  const openSearchCustomer = React.useCallback(
    (paramOpenSearch: ISearchCustomer) => {
      setSearchCustomer(paramOpenSearch)
      getAllCustomers()
    },
    [getAllCustomers]
  )
  const [openConfirmUnplanPenMarkedModal, setOpenConfirmUnplanPenMarkedModal] = React.useState(false)
  const [openConfirmUnplanFutureChangesModal, setOpenConfirmUnplanFutureChangesModal] = React.useState(false)
  const [openConfirmUnplanPenMarkedAndFutureChangesModal, setOpenConfirmUnplanPenMarkedAndFutureChangesModal] = React.useState(false)

  const [createUserWorkScheduleModalOpen, setCreateUserWorkScheduleModalOpen] = React.useState(false)

  const [editUserWorkScheduleModalOpen, setEditUserWorkScheduleModalOpen] = React.useState(false)
  const [editUserWorkScheduleModalData, setEditUserWorkScheduleModalData] = React.useState<IPlannedRouteUserWorkScheduleItem | null>(null)
  const [editUserWorkScheduleIsPlanned, setEditUserWorkScheduleIsPlanned] = React.useState<boolean>(false)

  const [openCreateRouteModal, setOpenCreateRouteModal] = React.useState(false)
  const [createRouteModalData, setCreateRouteModalData] = React.useState<IPlannedRoutesActivitiesPlanningViewItem | null>(null)
  const [createRouteModalPosition, setCreateRouteModalPosition] = React.useState<'left' | 'right' | null>(null)

  const [openEditRouteModal, setOpenEditRouteModal] = React.useState(false)
  const [editRouteModalData, setEditRouteModalData] = React.useState<IPlannedRoutesActivitiesPlanningViewItem | null>(null)
  const [editRouteModalDataIsCombinedRoute, setEditRouteModalDataIsCombinedRoute] = React.useState<boolean>(false)

  const [openEditCommentModal, setOpenEditCommentModal] = React.useState(false)
  const [editCommentModalData, setEditCommentModalData] = React.useState<IPlannedActivityRowItem | null>(null)

  const [openEditParticipantsModal, setOpenEditParticipantsModal] = React.useState(false)
  const [editParticipantsModalData, setEditParticipantsModalData] = React.useState<IPlannedActivityRowItem | null>(null)

  const [confirmUnplanPenMarkedModalData, setConfirmUnplanPenMarkedModalData] = React.useState<IPlannedActivityRowItem | null>(null)
  const [confirmUnplanFutureChangesModalData, setConfirmUnplanFutureChangesModalData] = React.useState<IPlannedActivityRowItem | null>(null)
  const [confirmUnplanPenMarkedAndFutureChangesModalData, setConfirmUnplanPenMarkedAndFutureChangesModalData] = React.useState<IPlannedActivityRowItem | null>(
    null
  )

  const [onlyTodaysSeries, setOnlyTodaysSeries] = React.useState<boolean | null>(null)

  const [confirmUnplanPenMarkedModalFutureRows, setConfirmUnplanPenMarkedModalFutureRows] = React.useState<PlannedActivityRowPlanningViewItem[] | null>(null)
  const [confirmUnplanFutureChangesModalFutureRows, setConfirmUnplanFutureChangesModalFutureRows] = React.useState<FutureRouteActivityRowItem[] | null>(null)

  const [menuOpen, setMenuOpen] = React.useState(false)

  const [anchorPopEl, setAnchorPopEl] = React.useState<HTMLButtonElement | null>(null)

  const [activityToUnplan, setActivityToUnplan] = React.useState<IPlannedActivityRowPlanningViewItem | null>(null)

  const openDayPicker = React.useCallback(() => {
    setDayPickerOpen(true)
  }, [])

  const closeDayPicker = React.useCallback(() => {
    setDayPickerOpen(false)
  }, [])

  const onDeviceChange = (deviceId: number) => {
    const device = devices.find(d => d.id === deviceId)
    setSelectedDevice(device)
  }




  const [alertFutureChangesIsOpen, setAlertFutureChangesIsOpen] = React.useState(false)
  const [alertFutureChangesMoveData, setAlertFutureChangesMoveData] = React.useState<IPlannedActivityRowPlanningViewItemWithTime | null>(null)
  const [alertFutureChangesPlanData, setAlertFutureChangesPlanData] = React.useState<IPlannedActivityRowPlanningViewItem | null>(null)
  const [alertFutureChangesPlanDataRoute, setAlertFutureChangesPlanDataRoute] = React.useState<number | undefined>(undefined)
  const [alertFutureChangesRows, setAlertFutureChangesRows] = React.useState<FutureRouteActivityRowItem[] | null>(null)

  const cleanUpFutureChanges = React.useCallback(() => {
    setAlertFutureChangesIsOpen(false)
    setAlertFutureChangesMoveData(null)
    setAlertFutureChangesPlanData(null)
    setAlertFutureChangesPlanDataRoute(undefined)
    setAlertFutureChangesRows(null)
  }, [])

  const verifyPlannedHasBeenDroppedCorrectly = React.useCallback((plannedActivityRowId: number) => {
    if (routes) {
      const item = routes.find(route => route.plannedRoutes?.find(pr => pr.plannedActivityRows?.find(par => par.id === plannedActivityRowId)))
      if (item) {
        return
      } else {
        // Illegal drop. Outside any element handling drops
        // revert
        setCustomerActivities(prevCustomerActivities => {
          if (!prevCustomerActivities) {
            return prevCustomerActivities
          }
          return produce(prevCustomerActivities.slice(0), draft => {

            const sideplanningItem = draft.find(ca => ca.sidePanelPlannedActivityRowsPlanningViewItems?.find(it => it.id === plannedActivityRowId))
            if (sideplanningItem) {
              const upaitem = sideplanningItem.sidePanelPlannedActivityRowsPlanningViewItems?.find(it => it.id === plannedActivityRowId)
              if (upaitem) {
                upaitem.isPlanned = false
              }
            }
            return draft
          })
        })

        setCommonActivities(prevCommonActivities => {
          if (!prevCommonActivities) {
            return prevCommonActivities
          }
          return produce(prevCommonActivities.slice(0), draft => {
            const sideplanningItem = draft.find(ca => ca.sidePanelPlannedActivityRowsPlanningViewItems?.find(it => it.id === plannedActivityRowId))
            if (sideplanningItem) {
              const upaitem = sideplanningItem.sidePanelPlannedActivityRowsPlanningViewItems?.find(it => it.id === plannedActivityRowId)
              if (upaitem) {
                upaitem.isPlanned = false
              }
            }
            return draft
          })
        })
      }
    }

  }, [routes, setCustomerActivities, setCommonActivities])

  const planActivity = (
    activity: IPlannedActivityRowPlanningViewItem,
    newRouteId?: number,
    overwrite = false,
    implementChangesUntil = false,
    implementChangesUntilDate: Date | undefined = undefined
  ) => {
    if (!(overwrite || implementChangesUntil) && activity.activityRowHasFutureChanges) {
      setAlertFutureChangesPlanData(activity)
      setAlertFutureChangesPlanDataRoute(newRouteId)
      void fetchFutureChangedActivitiesAsync(activity.id, true)
      return
    }

    const { id: plannedActivityRowId, startTime, endTime } = activity
    const model: IMovePlannedActivityRowSeriesItem = {
      plannedActivityRowId,
      newRouteId,
      startTime,
      endTime,
      forceOverwriteOfFutureChanges: overwrite,
      includePenMarked: false,
      implementChangesUntil,
      implementChangesUntilDate,
    }

    setRoutes(prevRoutes => {
      if (prevRoutes === undefined) {
        return prevRoutes
      }

      return produce(prevRoutes.slice(0), draft => {
        // Find the previous plannedRoute
        const oldCalendarDate =
          draft
            .filter(x => x.plannedRoutes !== undefined)
            .map(x => x.plannedRoutes)
            .flat()
            .filter(y => y && y.id === activity.plannedRouteId)
            .map(x => x && x.calendarDay)
            .find(() => true)
          ||
          customerActivities
            .concat(commonActivities)
            .flatMap(x => x.sidePanelPlannedActivityRowsPlanningViewItems)
            .filter(x => x)
            .filter(x => x!.id === plannedActivityRowId)
            .map(x => x!.calendarDay)
            .find(() => true)
        for (const route of draft) {
          const { plannedRoutes = [], id: routeId } = route
          if (routeId !== newRouteId) {
            continue
          }

          for (const plannedRoute of plannedRoutes) {
            const { id: plannedRouteId } = plannedRoute

            if (plannedRoute.plannedActivityRows === undefined) {
              plannedRoute.plannedActivityRows = []
            }

            if (oldCalendarDate && +plannedRoute.calendarDay !== +oldCalendarDate) {
              continue
            }

            const updatedActivity = new PlannedActivityRowPlanningViewItem({
              ...activity,
              plannedRouteId,
            })

            plannedRoute.plannedActivityRows.push(updatedActivity)
            return draft
          }
        }
        return draft
      })
    })

    const includePenMarked = false
    return scheduleFetch(
      movePlannedActivityRowSeries(model, overwrite, includePenMarked, implementChangesUntil, implementChangesUntilDate)
        .then(() => {
          Promise.resolve()
          setSidebarUnplannedInfo()
        })
    )
  }

  const unplanUserWorkSchedule = React.useCallback(
    (userWorkSchedule: PlannedRouteUserWorkScheduleItem) => {

      if (!unplannedRouteId) {
        return
      }

      const moveModel = new MovePlannedRouteUserWorkScheduleItem({
        id: userWorkSchedule.id,
        plannedRouteId: userWorkSchedule.plannedRouteId,
        userId: userWorkSchedule.userId,
        newRouteId: unplannedRouteId,
      })

      return scheduleFetch(
        movePlannedRouteUserWorkSchedule(moveModel)
          .then(() => updateRoutePlanningView())
          .then(() => Promise.resolve())
      )

    }, [unplannedRouteId, updateRoutePlanningView])


  const planUserWorkSchedule = (
    userWorkSchedule: PlannedRouteUserWorkScheduleItem,
    newRouteId?: number,
  ) => {

    if (!newRouteId) {
      return
    }

    const moveModel = new MovePlannedRouteUserWorkScheduleItem({
      id: userWorkSchedule.id,
      plannedRouteId: userWorkSchedule.plannedRouteId,
      userId: userWorkSchedule.userId,
      newRouteId,
    })

    return scheduleFetch(
      movePlannedRouteUserWorkSchedule(moveModel)
        .then(() => updateRoutePlanningView())
        .then(() => Promise.resolve())
    )
  }

  const splitRoutePlanningViewItems = (
    items: ISidePanelActivitiesPlanningViewItem[] | undefined
  ): {
    customerActivities: ISidePanelActivitiesPlanningViewItem[]
    commonActivities: ISidePanelActivitiesPlanningViewItem[]
  } => {
    if (!items || items.length === 0) {
      return { customerActivities: [], commonActivities: [] }
    }

    return items.reduce<{
      customerActivities: ISidePanelActivitiesPlanningViewItem[]
      commonActivities: ISidePanelActivitiesPlanningViewItem[]
    }>(
      (prev, current, index) => {
        return {
          customerActivities: !!current.ownerCustomerId ? [...prev.customerActivities, current] : prev.customerActivities,
          commonActivities: !!current.ownerAreaId ? [...prev.commonActivities, current] : prev.commonActivities,
        }
      },
      { customerActivities: [], commonActivities: [] }
    )
  }

  const fixUserWorkSchedules = (
    items: ISidePanelUsersWorkSchedulesItem[] | undefined
  ): {
    workSchedules: ISidePanelUsersWorkSchedulesItem[]
  } => {
    if (!items || items.length === 0) {
      return { workSchedules: [] }
    }

    return items.reduce<{
      workSchedules: ISidePanelUsersWorkSchedulesItem[]
    }>(
      (prev, current, index) => {
        return {
          workSchedules: !!current.userId ? [...prev.workSchedules, current] : prev.workSchedules,
        }
      },
      { workSchedules: [] }
    )
  }

  const updateActivityInRoutes = (plannedActivityRow: IPlannedActivityRowPlanningViewItemWithTime) => {
    setRoutes(prevRoutes => {
      if (prevRoutes === undefined) {
        return prevRoutes
      }
      return produce(prevRoutes.slice(0), draft => {
        // Find current planned route
        const currentPlannedRoute = draft
          .filter(x => x.plannedRoutes !== undefined)
          .flatMap(x => x.plannedRoutes)
          .find(x => x?.plannedActivityRows?.find(par => par.id === plannedActivityRow.id))

        // If we did not find one, make no changes
        if (currentPlannedRoute === undefined) {
          return draft
        }

        // Get the current calendar day and planned activity rows
        const { calendarDay, plannedActivityRows = [] } = currentPlannedRoute

        // Find the index of current planned activity row
        const activityIndex = plannedActivityRows.findIndex(p => p.id === plannedActivityRow.id)

        const newPlannedActivityRowPlanningViewItem = new PlannedActivityRowPlanningViewItem(plannedActivityRow)

        // If we found the current planned activity row
        if (activityIndex !== -1) {
          // If it was not moved to another route
          if (currentPlannedRoute.routeId === plannedActivityRow.newRouteId) {
            // Replace the current planned activity row with the new one and return
            plannedActivityRows[activityIndex] = newPlannedActivityRowPlanningViewItem
            return draft
          }

          // If it was moved to another route, just remove it from the current one and continue
          plannedActivityRows.splice(activityIndex, 1)
        }

        // Find thee new route
        const route = draft.find(x => x.id === plannedActivityRow.newRouteId)
        if (route === undefined) {
          return draft
        }

        const { plannedRoutes = [] } = route

        // Get the correct planned route from route by comparing calendar day from current planned route
        const plannedRoute = plannedRoutes.find(x => +x.calendarDay === +calendarDay)
        if (plannedRoute === undefined) {
          return draft
        }

        if (plannedRoute.plannedActivityRows === undefined) {
          plannedRoute.plannedActivityRows = []
        }

        // Add planned activity to this new route
        plannedRoute.plannedActivityRows.push(newPlannedActivityRowPlanningViewItem)
        return draft
      })
    })
  }

  const fetchFutureChangedActivitiesAsync = React.useCallback(
    async (plannedActivityRowId: number, onlyForTodaysSeries: boolean) => {
      await fetchAsync(listFutureChangedActivities(plannedActivityRowId, onlyForTodaysSeries)).then(activityList => {
        setAlertFutureChangesRows(activityList)
        setAlertFutureChangesIsOpen(true)
      })
    },
    [fetchAsync, listFutureChangedActivities]
  )

  const fetchNonCompliantRoutesForPlannedUserWorkScheduleAsync = React.useCallback(
    async (plannedRouteUserWorkScheduleId: number) => {
      await listNonCompliantRoutesForPlannedUserWorkSchedule(plannedRouteUserWorkScheduleId).then(nonCompliantRoutes => {
        setNonCompliantRoutesForPlannedUserWorkSchedule(nonCompliantRoutes)
      })
    },
    [fetchAsync, listNonCompliantRoutesForPlannedUserWorkSchedule, setNonCompliantRoutesForPlannedUserWorkSchedule]
  )

  const mapToNcrs = (ncrts: NonCompliantRouteTimesItem[]): NonCompliantRouteItem[] => {
    const ncrs: NonCompliantRouteItem[] = ncrts?.map(ncrt => {
      const ncr: NonCompliantRouteItem = new NonCompliantRouteItem()
      ncr.routeId = ncrt.routeId
      return ncr
    })
    return ncrs
  }

  const fetchNonCompliantRoutesAndTimesForPlannedActivityAsync = React.useCallback(
    async (plannedActivityRowId: number) => {

      if (!currentUser || !currentUser.useWorkSchedule) {
        return
      }

      await listNonCompliantRoutesAndTimesForPlannedActivity(plannedActivityRowId).then(nonCompliantRoutesAndTimes => {
        if (nonCompliantRoutesAndTimes.length > 0 || (nonCompliantRoutesForPlannedUserWorkSchedule || nonCompliantRoutesandTimesForPlannedActivity)) {
          setNonCompliantRoutesForPlannedUserWorkSchedule(mapToNcrs(nonCompliantRoutesAndTimes))
          setNonCompliantRoutesandTimesForPlannedActivity(nonCompliantRoutesAndTimes)
        }
      })
    },
    [fetchAsync, listNonCompliantRoutesAndTimesForPlannedActivity, setNonCompliantRoutesForPlannedUserWorkSchedule, currentUser]
  )

  const movePlannedActivity = React.useCallback(
    async (
      plannedActivityRow: IPlannedActivityRowPlanningViewItemWithTime,
      overwrite = false,
      allowRouteChanges = true,
      implementChangesUntil = false,
      implementChangesUntilDate: Date | undefined = undefined
    ) => {
      if (!(overwrite || implementChangesUntil) && plannedActivityRow.activityRowHasFutureChanges) {
        setAlertFutureChangesMoveData(plannedActivityRow)
        void fetchFutureChangedActivitiesAsync(plannedActivityRow.id, true)
        return
      }

      const data: IMovePlannedActivityRowSeriesItem = movePlannedActivityRowDataHelper(plannedActivityRow)

      if (!allowRouteChanges) {
        data.newRouteId = undefined
      }

      updateActivityInRoutes(plannedActivityRow)

      return scheduleFetch(
        movePlannedActivityRowSeries(data, overwrite, false, implementChangesUntil, implementChangesUntilDate)
          .then(() => Promise.resolve())
      )
    },
    [movePlannedActivityRowSeries, scheduleFetch, updateRoutePlanningView, fetchFutureChangedActivitiesAsync]
  )

  const unplanActivityInState = React.useCallback((plannedActivityRow: IPlannedActivityRowPlanningViewItem) => {

    if (plannedActivityRow.ownerCustomerId) {
      setCustomerActivities(prevCustomerActivities => {
        if (!prevCustomerActivities) {
          return prevCustomerActivities
        }
        return produce(prevCustomerActivities.slice(0), draft => {
          const sideplanningItem = draft.find(ca => ca.ownerCustomerId === plannedActivityRow.ownerCustomerId)
          if (sideplanningItem) {
            const upaitem = sideplanningItem.sidePanelPlannedActivityRowsPlanningViewItems?.find(item => item.id === plannedActivityRow.id)
            if (upaitem) {
              upaitem.isPlanned = false
            }
          }
          return draft
        })
      })
    }

    if (plannedActivityRow.ownerAreaId) {
      setCommonActivities(prevCommonActivities => {
        if (!prevCommonActivities) {
          return prevCommonActivities
        }
        return produce(prevCommonActivities.slice(0), draft => {
          const sideplanningItem = draft.find(ca => ca.ownerAreaId === plannedActivityRow.ownerAreaId)
          if (sideplanningItem) {
            const upaitem = sideplanningItem.sidePanelPlannedActivityRowsPlanningViewItems?.find(item => item.id === plannedActivityRow.id)
            if (upaitem) {
              upaitem.isPlanned = false
            }
          }
          return draft
        })
      })
    }

    setRoutes(prevRoutes => {
      if (prevRoutes === undefined) {
        return prevRoutes
      }
      return produce(prevRoutes.slice(0), draft => {
        for (const route of draft) {
          const { plannedRoutes = [] } = route

          for (const plannedRoute of plannedRoutes) {
            const { plannedActivityRows = [] } = plannedRoute
            const activityIndex = plannedActivityRows.findIndex(p => p.id === plannedActivityRow.id)
            if (activityIndex === -1) {
              continue
            }
            plannedActivityRows.splice(activityIndex, 1)
            return draft
          }
        }
        return draft
      })
    })

  }, [setCustomerActivities, setCommonActivities, setRoutes])

  const unplanActivity = React.useCallback(
    async (
      plannedActivityRow: IPlannedActivityRowPlanningViewItem,
      overwrite = false,
      includePenMarked: boolean,
      implementChangesUntil = false,
      implementChangesUntilDate: Date | undefined = undefined
    ) => {

      unplanActivityInState(plannedActivityRow)

      const data = {
        plannedActivityRowId: plannedActivityRow.id,
        newRouteId: unplannedRouteId,
        forceOverwriteOfFutureChanges: false,
        startTime: new Date(0),
        endTime: new Date(),
        includePenMarked,
        implementChangesUntil: false,
        implementChangesUntilDate: undefined,
      }

      return scheduleFetch(
        movePlannedActivityRowSeries(data, overwrite, includePenMarked, implementChangesUntil, implementChangesUntilDate)
          .then(() => {
            closeDialogInfoActivity()
            setSidebarUnplannedInfo()
            Promise.resolve()
          })
      )


    },
    [closeDialogInfoActivity, movePlannedActivityRowSeries, scheduleFetch, unplanActivityInState, unplannedRouteId, setSidebarUnplannedInfo]
  )

  const unplanAllPlannedActivity = React.useCallback(
    async (plannedActivityRow: IPlannedActivityRowPlanningViewItem, includePenMarked: boolean, implementChangesUntil: boolean, implementChangesUntilDate: Date | undefined) => {
      unplanActivityInState(plannedActivityRow)
      return scheduleFetch(
        unplanAllPlannedActivityRowSeries(plannedActivityRow.id, includePenMarked, implementChangesUntil, implementChangesUntilDate)
          .then(() => {
            closeDialogInfoActivity()
            setSidebarUnplannedInfo()
            Promise.resolve()
          })
      )
    },
    [scheduleFetch, unplanAllPlannedActivityRowSeries, unplanActivityInState, setSidebarUnplannedInfo]
  )

  const updateDetachedPlannedActivity = React.useCallback(
    (plannedActivityRow: IPlannedActivityRowPlanningViewItemWithTime, unplan: boolean) => {
      const data = plannedActivityRowDataHelper(plannedActivityRow)

      if (unplan && unplannedRouteId) {
        plannedActivityRow.newRouteId = unplannedRouteId
      }

      updateActivityInRoutes(plannedActivityRow)

      return scheduleFetch(
        moveDetachedActivity(data, plannedActivityRow.newRouteId ? plannedActivityRow.newRouteId : 0).then(x => {
          return x
        })
      )
    },
    [moveDetachedActivity, scheduleFetch, unplannedRouteId, updateRoutePlanningView]
  )

  const updateDetachedPlannedActivityParticipants = React.useCallback(
    (plannedActivityParticipants: (PlannedActivityParticipantItem[])) => {
      const patu = cloneDeep(plannedActivity)
      if (!patu) {
        return
      }
      patu.plannedActivityParticipants = plannedActivityParticipants;
      return scheduleFetch(
        updateDetachedActivityParticipants(patu).then(activity => {
          updateRoutePlanningView()
          setPlannedActivity(activity)
        })
      )

    },
    [moveDetachedActivity, scheduleFetch, plannedActivity]
  )


  // When this component unmounts, abort all ongoing calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => abort(), [])

  const fetchFuturePenMarkedActivitiesAsync = React.useCallback(
    async (plannedActivityRowId: number, onlyForTodaysSeries: boolean, openPenMarkedModal = true) => {
      await fetchAsync(listFuturePenMarkedActivities(plannedActivityRowId, onlyForTodaysSeries)).then(activityList => {
        setConfirmUnplanPenMarkedModalFutureRows(activityList)
        setOpenConfirmUnplanPenMarkedModal(openPenMarkedModal)
      })
    },
    [fetchAsync, listFuturePenMarkedActivities]
  )

  const fetchFutureChangesUponUnplanActivitiesAsync = React.useCallback(
    async (plannedActivityRowId: number, onlyForTodaysSeries: boolean, openFutureChangesModal = true) => {
      await fetchAsync(listFutureChangedActivities(plannedActivityRowId, onlyForTodaysSeries)).then(activityList => {
        setConfirmUnplanFutureChangesModalFutureRows(activityList)
        setOpenConfirmUnplanFutureChangesModal(openFutureChangesModal)
      })
    },
    [fetchAsync, listFutureChangedActivities]
  )

  const [activeCustomersOnPlanningArea, setActiveCustomersOnPlanningArea] = React.useState<CustomerListItem[]>([])

  const fetchCustomersOnPlanningAreaAndOpenParticipantsModalAsync = React.useCallback(
    async () => {
      await fetchAsync(listActiveCustomersOnArea(planningId)).then(customers => {
        setActiveCustomersOnPlanningArea(customers)
        setOpenEditParticipantsModal(true)
      })
    },
    [fetchAsync, planningId]
  )

  const setConfirmUnplanPenMarkedModalOpen = React.useCallback(
    (plannedActivityRowItem: IPlannedActivityRowItem | null, onlyForTodaysSeries: boolean) => {
      setOnlyTodaysSeries(onlyForTodaysSeries)
      setConfirmUnplanPenMarkedModalData(plannedActivityRowItem)

      if (plannedActivityRowItem?.id) {
        void fetchFuturePenMarkedActivitiesAsync(plannedActivityRowItem.id, onlyForTodaysSeries)
      }
    },
    [fetchFuturePenMarkedActivitiesAsync, setOnlyTodaysSeries]
  )

  const setConfirmUnplanFutureChangesModalOpen = React.useCallback(
    (plannedActivityRowItem: IPlannedActivityRowItem | null, onlyForTodaysSeries: boolean) => {
      setOnlyTodaysSeries(onlyForTodaysSeries)
      setConfirmUnplanFutureChangesModalData(plannedActivityRowItem)

      if (plannedActivityRowItem?.id) {
        void fetchFutureChangesUponUnplanActivitiesAsync(plannedActivityRowItem.id, onlyForTodaysSeries)
      }
    },
    [fetchFutureChangesUponUnplanActivitiesAsync, setOnlyTodaysSeries]
  )

  const setConfirmUnplanPenmarkedAndFutureChangesModalOpen = React.useCallback(
    (plannedActivityRowItem: IPlannedActivityRowItem | null, onlyForTodaysSeries: boolean) => {
      setOnlyTodaysSeries(onlyForTodaysSeries)
      setConfirmUnplanPenMarkedAndFutureChangesModalData(plannedActivityRowItem)

      if (plannedActivityRowItem?.id) {
        void fetchFuturePenMarkedActivitiesAsync(plannedActivityRowItem.id, onlyForTodaysSeries, false).then(() => {
          void fetchFutureChangesUponUnplanActivitiesAsync(plannedActivityRowItem.id, onlyForTodaysSeries, false).then(() => {
            setOpenConfirmUnplanPenMarkedAndFutureChangesModal(true)
          })
        })
      }
    },
    [fetchFuturePenMarkedActivitiesAsync, fetchFutureChangesUponUnplanActivitiesAsync, setOnlyTodaysSeries]
  )

  const onCancelConfirmUnplanPenMarkedModal = React.useCallback(() => {
    setOpenConfirmUnplanPenMarkedModal(false)
    setConfirmUnplanPenMarkedModalData(null)
    setConfirmUnplanPenMarkedModalFutureRows(null)

    setMenuOpen(false)
    setAnchorPopEl(null)
  }, [setMenuOpen, setAnchorPopEl])

  const onCancelConfirmUnplanFutureChangesModal = React.useCallback(() => {
    setOpenConfirmUnplanFutureChangesModal(false)
    setConfirmUnplanFutureChangesModalData(null)
    setConfirmUnplanFutureChangesModalFutureRows(null)

    setMenuOpen(false)
    setAnchorPopEl(null)
  }, [setMenuOpen, setAnchorPopEl])

  const onCancelConfirmUnplanPenMarkedAndFutureChangesModal = React.useCallback(() => {
    setOpenConfirmUnplanPenMarkedAndFutureChangesModal(false)
    setConfirmUnplanPenMarkedModalData(null)
    setConfirmUnplanFutureChangesModalData(null)
    setConfirmUnplanPenMarkedModalFutureRows(null)
    setConfirmUnplanFutureChangesModalFutureRows(null)

    setMenuOpen(false)
    setAnchorPopEl(null)
  }, [setMenuOpen, setAnchorPopEl])

  const performUnplanning = React.useCallback(
    (
      plannedActivityToUnplan: IPlannedActivityRowPlanningViewItem,
      includePenMarked: boolean,
      implementChangesUntil: boolean,
      implementChangesUntilDate: Date | undefined,
      forceUnplan = false
    ) => {
      if (onlyTodaysSeries == null) {
        return
      }

      if (onlyTodaysSeries) {
        void unplanActivity(plannedActivityToUnplan, forceUnplan, includePenMarked, implementChangesUntil, implementChangesUntilDate).then(() => {
          setActivityToUnplan(null)
          closeDialogInfoActivity()
          setOnlyTodaysSeries(null)
        })
      } else {
        void unplanAllPlannedActivity(plannedActivityToUnplan, includePenMarked, implementChangesUntil, implementChangesUntilDate).then(() => {
          setActivityToUnplan(null)
          closeDialogInfoActivity()
          setOnlyTodaysSeries(null)
        })
      }
    },
    [onlyTodaysSeries, closeDialogInfoActivity, unplanActivity, unplanAllPlannedActivity, setActivityToUnplan]
  )

  const onConfirmUnplanPenMarkedAndFutureChangesModal = React.useCallback(
    (shouldUnplanAllUpcoming: boolean, shouldUnplanPenMarked: boolean) => {
      setOpenConfirmUnplanPenMarkedAndFutureChangesModal(false)

      setMenuOpen(false)
      setAnchorPopEl(null)

      if (activityToUnplan) {
        const includePenMarked = shouldUnplanPenMarked
        const implementChangesUntil = !shouldUnplanAllUpcoming
        const implementChangesUntilDate = shouldUnplanAllUpcoming
          ? undefined
          : confirmUnplanFutureChangesModalFutureRows
            ? confirmUnplanFutureChangesModalFutureRows[0].startDate
            : undefined
        const forceUnplan = !implementChangesUntil && !implementChangesUntilDate
        performUnplanning(activityToUnplan, includePenMarked, implementChangesUntil, implementChangesUntilDate, forceUnplan)
      }

      setConfirmUnplanPenMarkedModalData(null)
      setConfirmUnplanFutureChangesModalData(null)
      setConfirmUnplanPenMarkedModalFutureRows(null)
      setConfirmUnplanFutureChangesModalFutureRows(null)
    },
    [setMenuOpen, setAnchorPopEl, performUnplanning, activityToUnplan, confirmUnplanFutureChangesModalFutureRows]
  )

  const handleConfirmUnplanPenMarkedModal = React.useCallback(
    (includePenMarked: boolean) => {
      setOpenConfirmUnplanPenMarkedModal(false)
      setConfirmUnplanPenMarkedModalData(null)
      setConfirmUnplanPenMarkedModalFutureRows(null)

      setMenuOpen(false)
      setAnchorPopEl(null)

      if (activityToUnplan) {
        const implementChangesUntil = false
        const implementChangesUntilDate = undefined
        performUnplanning(activityToUnplan, includePenMarked, implementChangesUntil, implementChangesUntilDate)
      }
    },
    [activityToUnplan, setMenuOpen, setAnchorPopEl, performUnplanning]
  )

  const onYesConfirmUnplanPenMarkedModal = React.useCallback(() => {
    handleConfirmUnplanPenMarkedModal(true)
  }, [handleConfirmUnplanPenMarkedModal])

  const onNoConfirmUnplanPenMarkedModal = React.useCallback(() => {
    handleConfirmUnplanPenMarkedModal(false)
  }, [handleConfirmUnplanPenMarkedModal])

  const handleConfirmUnplanFutureChangesModal = React.useCallback(
    (unplanAll: boolean) => {
      setOpenConfirmUnplanFutureChangesModal(false)
      setConfirmUnplanFutureChangesModalData(null)
      setConfirmUnplanFutureChangesModalFutureRows(null)

      setMenuOpen(false)
      setAnchorPopEl(null)
      if (activityToUnplan) {
        const includePenMarked = false
        const implementChangesUntil = !unplanAll
        const implementChangesUntilDate = unplanAll
          ? undefined
          : confirmUnplanFutureChangesModalFutureRows
            ? confirmUnplanFutureChangesModalFutureRows[0].startDate
            : undefined
        const forceUnplan = !implementChangesUntil && !implementChangesUntilDate
        performUnplanning(activityToUnplan, includePenMarked, implementChangesUntil, implementChangesUntilDate, forceUnplan)
      }
    },
    [activityToUnplan, confirmUnplanFutureChangesModalFutureRows, setMenuOpen, setAnchorPopEl, performUnplanning]
  )

  const onChangeUpTillNextConfirmUnplanFutureChangesModal = React.useCallback(() => {
    handleConfirmUnplanFutureChangesModal(false)
  }, [handleConfirmUnplanFutureChangesModal])

  const onReplaceAllConfirmUnplanFutureChangesModal = React.useCallback(() => {
    handleConfirmUnplanFutureChangesModal(true)
  }, [handleConfirmUnplanFutureChangesModal])

  const onOpenCreateRouteModal = React.useCallback((position: 'left' | 'right', route: IPlannedRoutesActivitiesPlanningViewItem) => {
    setCreateRouteModalData(route)
    setCreateRouteModalPosition(position)
    setOpenCreateRouteModal(true)
  }, [])

  const onCloseCreateRouteModal = React.useCallback(() => {
    setOpenCreateRouteModal(false)
    setCreateRouteModalData(null)
    setCreateRouteModalPosition(null)
  }, [])

  const onOpenEditRouteModal = React.useCallback((route: IPlannedRoutesActivitiesPlanningViewItem, isCombinedRoute: boolean) => {
    setEditRouteModalData(route)
    setEditRouteModalDataIsCombinedRoute(isCombinedRoute)
    setOpenEditRouteModal(true)
  }, [])

  const onCloseEditRouteModal = React.useCallback(() => {
    setOpenEditRouteModal(false)
    setEditRouteModalDataIsCombinedRoute(false)
    setEditRouteModalData(null)
  }, [])

  const onCloseEditCommentModal = React.useCallback(() => {
    setOpenEditCommentModal(false)
    setEditCommentModalData(null)
  }, [])

  const onCloseEditParticipantsModal = React.useCallback(() => {
    setOpenEditParticipantsModal(false)
    setEditParticipantsModalData(null)
  }, [])

  const editRouteAsync = React.useCallback(
    (route: IRouteUpdateItem, areaId: number) => {
      fetchAsync(editRoute(route).then(() => fetchAsync(getRoutePlanningView({ areaId })))).catch(err =>
        Promise.reject(err)
      )
    },
    [fetchAsync, editRoute, getRoutePlanningView]
  )

  const editTimeBasedRouteContainerAsync = React.useCallback(
    (route: IPlannedRoutesActivitiesPlanningViewItem, newName: string, newColor: string) => {
      fetchAsync(editTimeBasedRouteContainer(route.id, newName, newColor).then(() => updateDevicePlanningView())).catch(err => Promise.reject(err))
    },
    [fetchAsync, editTimeBasedRouteContainer, updateDevicePlanningView]
  )

  const createRouteAsync = React.useCallback(
    (route: IRouteCreateItem) => {
      return fetchAsync(
        createRoute(route)
          .then(routeItem => !!routeItem)
          .then(() => getRoutePlanningView({ areaId: route.areaId }))
      )
    },
    [fetchAsync, createRoute, getRoutePlanningView]
  )

  const removeRouteAsync = React.useCallback(
    (route: IPlannedRoutesActivitiesPlanningViewItem) => {
      if (isToday(calendarStartDate)) {
        const today = new Date()
        const tomorrow = addDays(startOfDay(today), 1)
        fetchAsync(removeRoute(route, tomorrow).then(() => getRoutePlanningView({ areaId: route.areaId }))).catch(err => Promise.reject(err))
      }
      else {
        fetchAsync(removeRoute(route, calendarStartDate).then(() => getRoutePlanningView({ areaId: route.areaId }))).catch(err => Promise.reject(err))
      }
    },
    [fetchAsync, removeRoute, calendarStartDate, getRoutePlanningView]
  )

  const removeTimeBasedRouteContainerAsync = React.useCallback(
    (route: IPlannedRoutesActivitiesPlanningViewItem) => {
      fetchAsync(removeTimeBasedRouteContainer(route, calendarStartDate).then(() => updateDevicePlanningView())).catch(err => Promise.reject(err))
    },
    [fetchAsync, removeTimeBasedRouteContainer, calendarStartDate, updateDevicePlanningView]
  )

  const removeCommonActivityAsync = React.useCallback(() => {
    if (selectedCommonActivityToDelete) {
      fetchAsync(deleteActivity(selectedCommonActivityToDelete.activityId))
        .then(() => updateRoutePlanningView())
        .catch(err => Promise.reject(err))
    }
  }, [deleteActivity, fetchAsync, selectedCommonActivityToDelete, updateRoutePlanningView])


  const removePlannedRouteUserWorkScheduleAsync = React.useCallback(() => {
    if (selectedPlannedRouteUserWorkScheduleToDelete) {
      fetchAsync(deletePlannedRouteUserWorkSchedule(selectedPlannedRouteUserWorkScheduleToDelete.id))
        .then(() => updateRoutePlanningView())
        .catch(err => Promise.reject(err))
    }
  }, [deleteActivity, fetchAsync, selectedPlannedRouteUserWorkScheduleToDelete, updateRoutePlanningView])

  const [addModalOpen, setAddModalOpen] = React.useState(false)
  const [combinedRouteModalOpen, setCombinedRouteModalOpen] = React.useState(false)

  const saveCombinedRoutes = React.useCallback(
    (kioskId: number, timeBasedRouteContainers: TimeBasedRouteContainerItem[]): Promise<void> => {
      if (planningDeviceId === null) {
        return Promise.reject('No planning device id found')
      }
      return combinedRoutesFetchAsync(createOrUpdateKioskRouteContainersAsync(kioskId, timeBasedRouteContainers)).then(() =>
        getRoutePlanningView({ deviceId: planningDeviceId })
      )
    },
    [combinedRoutesFetchAsync, createOrUpdateKioskRouteContainersAsync, getRoutePlanningView, planningDeviceId]
  )

  // TODO: Refactor below... duplicated code!
  const removeDetachedComment = React.useCallback(
    (activityId: number) => {
      if (routes === undefined) {
        return
      }
      const currentRoute = routes.find(
        route => route.plannedRoutes?.find(plannedRoute => plannedRoute.plannedActivityRows?.find(par => par.id === activityId) !== undefined) !== undefined
      )

      if (currentRoute === undefined) {
        return
      }

      const plannedActivityRow = routes
        .flatMap(route => {
          return route.plannedRoutes
        })
        .flatMap(x => x?.plannedActivityRows)
        .filter(x => x !== undefined)
        .find(pa => pa!.id === activityId)
      if (plannedActivityRow) {
        plannedActivityRow.comment = undefined // clear out any existing comment

        void updateDetachedPlannedActivity(
          {
            ...plannedActivityRow,
            newRouteId: currentRoute.id,
            visitLengthInMinutes: (+plannedActivityRow.endTime - +plannedActivityRow.startTime) / 60 / 1000,
          },
          false
        ).then(activity => {
          setPlannedActivity(activity)
        })
      }
    },
    [routes, updateDetachedPlannedActivity]
  )

  const detachedFromSeries = React.useCallback(
    (activityId: number) => {
      if (routes === undefined) {
        return
      }
      const currentRoute = routes.find(
        route => route.plannedRoutes?.find(plannedRoute => plannedRoute.plannedActivityRows?.find(par => par.id === activityId) !== undefined) !== undefined
      )

      if (currentRoute === undefined) {
        return
      }

      const plannedActivityRow = routes
        .flatMap(route => {
          return route.plannedRoutes
        })
        .flatMap(x => x?.plannedActivityRows)
        .filter(x => x !== undefined)
        .find(pa => pa!.id === activityId)
      if (plannedActivityRow) {
        const shouldDetach = !plannedActivityRow.detachedFromSeries
        plannedActivityRow.detachedFromSeries = shouldDetach
        plannedActivityRow.comment = undefined // clear out any existing comment

        void updateDetachedPlannedActivity(
          {
            ...plannedActivityRow,
            newRouteId: currentRoute.id,
            visitLengthInMinutes: (+plannedActivityRow.endTime - +plannedActivityRow.startTime) / 60 / 1000,
          },
          false
        ).then(activity => {
          setPlannedActivity(activity)
          if (!shouldDetach) {
            // we need to force reload in order to make sure previously penmarked is reverted to ordinary schedule
            updateRoutePlanningView()
          }
        })
      }
    },
    [routes, updateDetachedPlannedActivity, updateRoutePlanningView]
  )

  const updateDetachedComment = React.useCallback(
    (activityId: number, comment: string) => {
      if (routes === undefined) {
        return
      }
      const currentRoute = routes.find(
        route => route.plannedRoutes?.find(plannedRoute => plannedRoute.plannedActivityRows?.find(par => par.id === activityId) !== undefined) !== undefined
      )

      if (currentRoute === undefined) {
        return
      }

      const plannedActivityRow = routes
        .flatMap(route => {
          return route.plannedRoutes
        })
        .flatMap(x => x?.plannedActivityRows)
        .filter(x => x !== undefined)
        .find(pa => pa!.id === activityId)
      if (plannedActivityRow) {
        plannedActivityRow.comment = comment

        void updateDetachedPlannedActivity(
          {
            ...plannedActivityRow,
            newRouteId: currentRoute.id,
            visitLengthInMinutes: (+plannedActivityRow.endTime - +plannedActivityRow.startTime) / 60 / 1000,
          },
          false
        ).then(activity => {
          setPlannedActivity(activity)
        })
      }
    },
    [routes, updateDetachedPlannedActivity]
  )

  const plannedRouteIdFrom = React.useCallback(
    (oldPlannedActivityRowId: number, oldPlannedRouteId: number, routeId: number): number | undefined => {
      if (routes === undefined) {
        return undefined
      }

      const oldCalendarDate =
        routes
          .filter(x => x.plannedRoutes !== undefined)
          .flatMap(x => x.plannedRoutes)
          .filter(y => y!.id === oldPlannedRouteId)
          .map(x => x!.calendarDay)
          .find(() => true) ||
        customerActivities
          .concat(commonActivities)
          .filter(x => x.sidePanelPlannedActivityRowsPlanningViewItems !== undefined)
          .flatMap(x => x.sidePanelPlannedActivityRowsPlanningViewItems)
          .filter(x => x!.id === oldPlannedActivityRowId)
          .map(x => x!.calendarDay)
          .find(() => true)

      if (oldCalendarDate === undefined) {
        return undefined
      }

      const oldPlannedRoute = routes!.flatMap(x => x.plannedRoutes!).find(x => x.id === routeId && +x.calendarDay === +oldCalendarDate)
      return oldPlannedRoute?.id
    },
    [routes, customerActivities, commonActivities]
  )

  const userWantsToUnplanByDroppingInUnplannedSection = React.useCallback(
    (plannedActivityItem: IPlannedActivityRowPlanningViewItem) => {
      getPlannedActivityRow(plannedActivityItem.id)
        .then(pa => {
          const hasDetached = pa?.hasFutureDetachedFromDaySeries || pa?.hasFutureDetachedFromSeries
          if (hasDetached && !pa?.hasFutureActivityRowChanges) {
            // User wants to unplan day series but there are pen marked future instances -> show dialog for confirmation
            setActivityToUnplan(plannedActivityItem) // IPlannedActivityRowPlanningViewItem
            setConfirmUnplanPenMarkedModalOpen(pa, pa.activity_RecurrenceId === RecurrenceEnum.Weekly)
          } else if (!hasDetached && pa?.hasFutureActivityRowChanges) {
            setActivityToUnplan(plannedActivityItem) // IPlannedActivityRowPlanningViewItem
            if (pa.activity_RecurrenceId === RecurrenceEnum.OneTime) {
              unplanActivity(plannedActivityItem, true, true, true).then(() => {
                setActivityToUnplan(null)
              })
            } else {
              // User wants to unplan day series but there are future changed instances -> show dialog for confirmation
              setConfirmUnplanFutureChangesModalOpen(pa, pa.activity_RecurrenceId === RecurrenceEnum.Weekly)
            }
          } else if (hasDetached && pa?.hasFutureActivityRowChanges) {
            // User wants to unplan day series but there are pen marked future instances and future changes instances -> show dialog for confirmation
            setActivityToUnplan(plannedActivityItem) // IPlannedActivityRowPlanningViewItem
            setConfirmUnplanPenmarkedAndFutureChangesModalOpen(pa, pa.activity_RecurrenceId === RecurrenceEnum.Weekly)
          } else {
            unplanActivity(plannedActivityItem, false, false).catch(err => Promise.reject(err))
          }
        })
        .catch(err => Promise.reject(err))

    },
    [
      fetchAsync,
      getPlannedActivityRow,
      unplanActivity,
      setConfirmUnplanPenMarkedModalOpen,
      setConfirmUnplanFutureChangesModalOpen,
      setConfirmUnplanPenmarkedAndFutureChangesModalOpen,
    ]
  )

  return {
    state: {
      ...stateOfFetch,
      searchCustomerOpen,
      dayPickerOpen,
      calendarStartDate,
      calendarEndDate,
      routes,
      unplannedRouteId,
      customerActivities,
      commonActivities,
      userWorkSchedules,
      nonCompliantRoutesForPlannedUserWorkSchedule,
      nonCompliantRoutesandTimesForPlannedActivity,
      dialogInfoActivityOpen,
      editWorkScheduleDialogOpen,
      areas,
      listCustomer,
      customerInfo,
      plannedActivity,
      devices,
      selectedDevice,
      openCreateRouteModal,
      createRouteModalData,
      createRouteModalPosition,
      openEditRouteModal,
      editRouteModalData,
      editRouteModalDataIsCombinedRoute,
      editUserWorkScheduleModalOpen,
      editUserWorkScheduleModalData,
      editUserWorkScheduleIsPlanned,
      createUserWorkScheduleModalOpen,
      openEditCommentModal,
      editCommentModalData,
      openEditParticipantsModal,
      editParticipantsModalData,
      openConfirmUnplanPenMarkedModal,
      openConfirmUnplanFutureChangesModal,
      openConfirmUnplanPenMarkedAndFutureChangesModal,
      confirmUnplanPenMarkedModalData,
      confirmUnplanFutureChangesModalData,
      confirmUnplanPenMarkedAndFutureChangesModalData,
      confirmUnplanPenMarkedModalFutureRows,
      confirmUnplanFutureChangesModalFutureRows,
      menuOpen,
      anchorPopEl,
      activityToUnplan,
      deviceData,
      selectedCommonActivityToDelete,
      selectedPlannedRouteUserWorkScheduleToDelete,
      notSatisfiedPlannedActivities,
      manageCombinedRoutes: {
        ...combinedRoutesState,
        addModalOpen,
        combinedRouteModalOpen,
      },
      alertFutureChangesIsOpen,
      alertFutureChangesMoveData,
      alertFutureChangesPlanData,
      alertFutureChangesPlanDataRoute,
      alertFutureChangesRows,
      timeScale,
      plannedRouteIdFrom,
      planningId,
      planningDeviceId,
      areaUsers,
      commonShifts,
      nonComplianceForAreaAndCalendarDate,
      currentUser,
      openMobileTourMenu,
      hasUnplannedWorkSchedules,
      hasUnplannedCustomerActivities,
      hasUnplannedCommonActivities,
      activeCustomersOnPlanningArea
    },
    actions: {
      openSearchCustomer,
      openDialogInfoActivity,
      closeDialogInfoActivity,
      openEditUserWorkScheduleDialog,
      closeEditUserWorkScheduleDialog,
      openCreateUserWorkScheduleDialog,
      closeCreateUserWorkScheduleDialog,
      updateUserWorkScheduleAsync,
      createUserWorkScheduleAsync,
      planActivity,
      planUserWorkSchedule,
      unplanUserWorkSchedule,
      openDayPicker,
      closeDayPicker,
      createRouteAsync,
      removeRouteAsync,
      removeTimeBasedRouteContainerAsync,
      onDeviceChange,
      getAllCustomers,
      customerSelected,
      clearCustomerInfo,
      movePlannedActivity,
      unplanActivity,
      unplanAllPlannedActivity,
      updateDetachedPlannedActivity,
      detachedFromSeries,
      updateDetachedComment,
      removeDetachedComment,
      onOpenCreateRouteModal,
      onCloseCreateRouteModal,
      onOpenEditRouteModal,
      onCloseEditRouteModal,
      onCloseEditCommentModal,
      setOpenEditCommentModal,
      setEditCommentModalData,
      onCloseEditParticipantsModal,
      setOpenEditParticipantsModal,
      setEditParticipantsModalData,
      setConfirmUnplanPenMarkedModalOpen,
      setConfirmUnplanFutureChangesModalOpen,
      setConfirmUnplanPenmarkedAndFutureChangesModalOpen,
      onCancelConfirmUnplanPenMarkedModal,
      onCancelConfirmUnplanFutureChangesModal,
      onCancelConfirmUnplanPenMarkedAndFutureChangesModal,
      onConfirmUnplanPenMarkedAndFutureChangesModal,
      onYesConfirmUnplanPenMarkedModal,
      onNoConfirmUnplanPenMarkedModal,
      onChangeUpTillNextConfirmUnplanFutureChangesModal,
      onReplaceAllConfirmUnplanFutureChangesModal,
      setMenuOpen,
      setAnchorPopEl,
      setActivityToUnplan,
      editRouteAsync,
      editTimeBasedRouteContainerAsync,
      updateRoutePlanningView,
      updateDevicePlanningView,
      removeCommonActivityAsync,
      removePlannedRouteUserWorkScheduleAsync,
      setSelectedCommonActivityToDelete,
      isActivityRequirementsNotSatisfied,
      activityRequirementsNotSatisfied,
      activityDelegationRequirements,
      setSelectedPlannedRouteUserWorkScheduleToDelete,
      verifyPlannedHasBeenDroppedCorrectly,
      manageCombinedRoutes: {
        setCombinedRouteModalOpen,
        saveCombinedRoutes,
        setAddModalOpen,
      },
      setAlertFutureChangesIsOpen,
      setTimeScale,
      userWantsToUnplanByDroppingInUnplannedSection,
      cleanUpFutureChanges,
      fetchNonCompliantRoutesForPlannedUserWorkScheduleAsync,
      setNonCompliantRoutesForPlannedUserWorkSchedule,
      fetchNonCompliantRoutesAndTimesForPlannedActivityAsync,
      setNonCompliantRoutesandTimesForPlannedActivity,
      toggleOpenMobileTourMenu,
      toggleRouteVisibility,
      hideAllRoutes,
      showAllRoutes,
      fetchCustomersOnPlanningAreaAndOpenParticipantsModalAsync,
      updateDetachedPlannedActivityParticipants,
    },
  }
}

export const PlanningPageContext = createContext(usePlanningPageContext)
