import React from 'react'
import { Box, Dialog, DialogActions, DialogContent, Switch, Typography } from '@mui/material'
import { UserAreaItem, RoleEnum } from 'api/tillit.api-client'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import { Form, Formik } from 'formik'
import BigCheckbox from 'components/inputs/big-checkbox'
import { useFormatMessage } from 'localization'
import useUserAreas from 'utilities/use-user-areas'
import SearchAreaInput from '../search-area-input'
import { ActivateDeviceDialogContext } from './activate-device-dialog-context'
import { useActivateDeviceForm } from './utilities/use-activate-device-form'

const ActivateDeviceDialogView: React.FC = () => {
  const f = useFormatMessage()
  const { initialValues, name, onSubmit, validationSchema } = useActivateDeviceForm()


  const { dialogOpen, activateThisDevice } = ActivateDeviceDialogContext.useState()
  const { handleCloseDialog } = ActivateDeviceDialogContext.useActions()

  const getAreaName = (item: any) => {
    const a = item as UserAreaItem
    return a?.organizationParentsName ? `${a.areaName} (${a.organizationParentsName})` : a.areaName || ''
  }

  const areas = useUserAreas([RoleEnum.Admin])

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, errors, touched, isSubmitting, submitForm, setFieldValue }) => {
        const switchUseManualSessionExpiration = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          setFieldValue("useManualSessionExpiration", checked)
        }

        return (
          <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth sx={(theme) => ({
            [theme.customBreakpoints.justMobileTablet]: {
              marginLeft: '60px',
              ' .MuiPaper-root': {
                margin: '16px'
              }
            }
          })}>
            <DialogContent>
              <Form translate="yes">
                <Typography variant="h6">{activateThisDevice ? f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_TITLE') : f('ADMINISTER_PAGE_NEW_ACTIVE_DEVICE_DIALOG_TITLE')}</Typography>
                <Box my={2}>
                  <Typography>{f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_SUBTITLE')}</Typography>
                </Box>
                <Box mb={2}>
                  <FilledTextField name={name.name} label={f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_NAME')} color="secondary" fullWidth={true} />
                </Box>
                <Box mb={2}>
                  <SearchAreaInput
                    name={name.area}
                    label={f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_AREA')}
                    options={areas as any}
                    getOptionLabel={getAreaName}
                    error={!!errors.area && !!touched.area}
                    helperText={!!touched.area && errors.area}
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <FilledTextField
                    data-matomo-mask
                    name={name.comment}
                    label={f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_DESCRIPTION')}
                    color="secondary"
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Box>

                <Box my={2}>
                  <Typography>{f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_ALLOWNAVIGATE')}</Typography>
                </Box>
                <Box mb={2}>
                  <>
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_YES')}`}
                      name={name.allowNavigateToPlanningPage}
                      value="true"
                      checkedByValue
                      returnValue="value"
                    />
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_NO')}`}
                      name={name.allowNavigateToPlanningPage}
                      value="false"
                      checkedByValue
                      returnValue="value"
                    />
                  </>
                </Box>

                <Box my={2}>
                  <Typography>{f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_ALLOWUSEMULTISIGNING')}</Typography>
                </Box>
                <Box mb={2}>
                  <>
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_YES')}`}
                      name={name.allowUseMultiSigning}
                      value="true"
                      checkedByValue
                      returnValue="value"
                    />
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_NO')}`}
                      name={name.allowUseMultiSigning}
                      value="false"
                      checkedByValue
                      returnValue="value"
                    />
                  </>
                </Box>

                <Box my={2}>
                  <Typography>{f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_USEMANUALSESSIONEXPIRATION')}</Typography>
                </Box>
                <Box mb={2}>
                  <Switch checked={values.useManualSessionExpiration} color="primary" onChange={switchUseManualSessionExpiration} />
                </Box>
                {values.useManualSessionExpiration && (
                  <Box mb={2}>
                    <FilledTextField
                      name={name.manualSessionExpirationMinutes}
                      label={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL')}
                      color="secondary"
                    />
                  </Box>
                )}

              </Form>
            </DialogContent>
            <DialogActions>
              <TextButton type="button" color="secondary" onClick={handleCloseDialog} disabled={isSubmitting}>
                {f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_BUTTON_CANCEL')}
              </TextButton>
              <TextButton type="submit" color="secondary" disabled={isSubmitting} onClick={submitForm}>
                {f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_BUTTON_ACTIVATE')}
              </TextButton>
            </DialogActions>
          </Dialog>
        )
      }}
    </Formik>
  )
}

export default ActivateDeviceDialogView
