import React from 'react'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'

import { UnitListItem, UserTypeEnum } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'

import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterUnits } from 'utilities/unit-helper'

import { UnitModalContext } from './components/unit-modal/unit-modal-context'
import { AdministerUnitsContext } from './administer-units-context'
import { Authorize } from 'components/authorization';

const AdministerUnitsView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const {
    unitListItems,
    removeDialogOpen,
    unitToRemove,
    isLoading,
    removeInvalidDialogOpen,
    unitInvalidToRemove,
  } = AdministerUnitsContext.useState()
  const { openRemoveDialog, closeRemoveDialog, deleteunitAsync, closeRemoveInvalidDialog } = AdministerUnitsContext.useActions()

  const { openUnitModal, setOwnerOrganizationId } = UnitModalContext.useActions()

  const f = useFormatMessage()
  const getFilteredUnits = filterUnits(f)

  const handleAddNewUnitClick = React.useCallback(() => {
    setOwnerOrganizationId(currentUser.organizationId)
    openUnitModal()
  }, [openUnitModal, setOwnerOrganizationId, currentUser])

  const handleRemoveunitClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, unit: UnitListItem) => {
      _?.stopPropagation()
      openRemoveDialog(unit)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveunitClick = React.useCallback(() => deleteunitAsync(unitToRemove!.id), [
    unitToRemove,
    deleteunitAsync,
  ])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openUnitModal(params.row.id)
    },
    [openUnitModal]
  )

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredUnits, setFilteredUnits] = React.useState<UnitListItem[]>(unitListItems)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredUnits(filterValue, unitListItems)
      setFilteredUnits(filtered)
    } else {
      setFilteredUnits(unitListItems)
    }
    // NOTE: We are missing filterValue and getFilteredUnits as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [unitListItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredUnits(searchValue, unitListItems)
      setFilteredUnits(filtered)
    },
    [setFilterValue, setFilteredUnits, unitListItems, getFilteredUnits]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredUnits(unitListItems)
    },
    [setFilterValue, setFilteredUnits, unitListItems]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '2vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
        <Authorize mainRoles={[UserTypeEnum.OrganizationAdmin, UserTypeEnum.TeniloSupport]}>
          {currentUser.organizationId && (
            <div style={{ columnCount: 2 }}>
              <ContainedButton type="button" color="secondary" onClick={handleAddNewUnitClick}>
                {f('ADMINISTER_UNIT_ADD_BUTTON_TEXT')}
              </ContainedButton>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}
          </Authorize>

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredUnits}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_UNIT_COLUMN_TITLE'),
                field: 'title',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_UNIT_COLUMN_ABBREVIATION'),
                field: 'abbreviation',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_delete',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_UNIT_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title ? params.row.title : '',
                        })}
                        aria-label={f('ADMINISTER_UNIT_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title ? params.row.title : '',
                        })}>
                        <IconButton onClick={e => handleRemoveunitClick(e, params.row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />


          {removeDialogOpen && unitToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_UNIT_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_UNIT_DELETE_DIALOG_MESSAGE', {
                title: unitToRemove.title,
              })}
              buttonSubmitText={f('ADMINISTER_UNIT_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_UNIT_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen={true}
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemoveunitClick}
            />
          )}

          {removeInvalidDialogOpen && unitInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_UNIT_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_UNIT_DELETE_INVALID_DIALOG_MESSAGE', {
                title: unitInvalidToRemove.title,
              })}
              buttonOkText={f('ADMINISTER_UNIT_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen={true}
              onClose={closeRemoveInvalidDialog}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdministerUnitsView
