import { useFormatMessage } from 'localization'
import React from 'react'
import authenticationContext from 'components/authentication/authentication-context'
import { LoggedOutDialog } from 'components/logged-out-dialog'
import { useNavigate } from 'react-router-dom'
import PageRoute from 'config/page-routes'
import { clearIsLoggedOut, getIsLoggedOut } from 'utilities/logged-out-helper'

const TokenExpiredDialogContainer: React.FC = () => {
  const f = useFormatMessage()
  const { tokenExpired } = authenticationContext.useState()
  const { setTokenExpired } = authenticationContext.useActions()
  const navigate = useNavigate()

  const checkLoggedOutTimer = React.useRef<number | undefined>()

  React.useEffect(() => {

    const checkIfLoggedOut = () => {

      const isLoggedOut = getIsLoggedOut()
      if (isLoggedOut) {
        window.clearTimeout(checkLoggedOutTimer.current)
        clearIsLoggedOut()
        setTokenExpired(true)
      }
    }

    const startTimer = () => {
      window.clearTimeout(checkLoggedOutTimer.current)
      checkLoggedOutTimer.current = window.setInterval(checkIfLoggedOut, 1 * 1000)
    }

    // Initiate the timer
    startTimer()
    return () => {
      window.clearTimeout(checkLoggedOutTimer.current)
    }
  }, [])


  const handleClose = () => {
    window.location.replace(PageRoute.Login.path)
    setTokenExpired(false)

    //navigate(PageRoute.Login.path, { replace: true })
  }

  if (!tokenExpired) {
    return null
  }

  return (
    <LoggedOutDialog
      dialogTitle={f('TOKEN_EXPIRED_TITLE')}
      dialogContentText={f('TOKEN_EXPIRED_TEXT')}
      buttonOkText={'OK'}
      dialogOpen={tokenExpired}
      onClose={handleClose}
    />
  )
}

export default TokenExpiredDialogContainer
