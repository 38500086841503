import {
  IKioskAreaCreateItem,
  KioskAreaCreateItem,
  ManageKioskItem,
  UserAreaItem,
} from 'api/tillit.api-client'
import React from 'react'

const useManageDeviceHelper = (
  device: ManageKioskItem,
  areas: UserAreaItem[]
) => {
  const convertKioskAreaToUserArea = React.useCallback(
    (kioskArea: KioskAreaCreateItem): UserAreaItem | undefined =>
      areas.find(area => area.areaId === kioskArea.areaId),
    [areas]
  )

  const convertKioskAreasToUserAreas = React.useCallback(
    (kioskAreas: KioskAreaCreateItem[]): UserAreaItem[] => {
      return kioskAreas.reduce<UserAreaItem[]>((prev, kioskArea) => {
        const area = convertKioskAreaToUserArea(kioskArea)
        if (!area) {
          return prev
        }
        return [...prev, area]
      }, [])
    },
    [convertKioskAreaToUserArea]
  )

  const getTimeBasedAreas = React.useCallback((): UserAreaItem[] => {
    const { kioskAreas = [] } = device
    const timeBasedAreas = kioskAreas.filter(
      area => area.kioskMainArea === false
    )
    return convertKioskAreasToUserAreas(timeBasedAreas)
  }, [device, convertKioskAreasToUserAreas])

  const getMainArea = React.useCallback((): UserAreaItem | undefined => {
    const { kioskAreas = [] } = device

    const mainArea = kioskAreas.find(area => area.kioskMainArea === true)
    if (!mainArea) {
      return undefined
    }
    return convertKioskAreaToUserArea(mainArea)
  }, [device, convertKioskAreaToUserArea])

  const convertAreasToKioskAreas = React.useCallback(
    (mainArea: UserAreaItem, timeBasedAreas: UserAreaItem[]) => {
      const filteredAreas = timeBasedAreas.filter(
        a => a.areaId !== mainArea.areaId
      )
      const mergedAreas = [...filteredAreas, mainArea]
      const mergeUserAreasAndKioskAreas: IKioskAreaCreateItem[] = mergedAreas.map(
        area =>
          new KioskAreaCreateItem({
            kioskId: device.id,
            areaId: area.areaId,
            kioskMainArea: area.areaId === mainArea.areaId,
          })
      )
      return mergeUserAreasAndKioskAreas
    },
    [device]
  )

  const convertTimeToHourMinute = React.useCallback((time: Date) => {
    if (!time) {
      return { hour: undefined, minute: undefined }
    }
    const hour = time.getHours()
    const minute = time.getMinutes()
    return { hour, minute }
  }, [])

  const convertHourMinuteToTime = React.useCallback(
    (hour: number | undefined, minute: number | undefined) => {
      if (!hour && !minute) {
        return null
      }
      const time = new Date().setHours(hour!, minute!)
      return new Date(time)
    },
    []
  )

  return {
    getTimeBasedAreas,
    getMainArea,
    convertHourMinuteToTime,
    convertTimeToHourMinute,
    convertAreasToKioskAreas,
  }
}

export default useManageDeviceHelper
