import { Box, Button, Modal } from '@mui/material'
import OutlinedTextField from 'components/inputs/outlined-text-field'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { StyledModalBox } from './edit-comment-modal-style'
import useFormData from './utilities/use-edit-comment-modal-form-data'

const EditCommentModalView: React.FC = () => {
  const f = useFormatMessage()
  const { openEditCommentModal } = PlanningPageContext.useState()
  const { onCloseEditCommentModal } = PlanningPageContext.useActions()
  const { initialValues, name, onSubmit, validationSchema } = useFormData()

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={openEditCommentModal} onClose={onCloseEditCommentModal}>
      <StyledModalBox>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, errors }) => (
            <Form translate="yes">
              <Box className="content">
                <OutlinedTextField
                  data-matomo-mask
                  multiline={true}
                  rows={4}
                  name={name.comment}
                  label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITCOMMENT_FORM_NAME_LABEL')}
                  value={values.comment}
                  className="field"
                  color="secondary"
                  error={!!errors.comment}
                  helperText={errors.comment}
                />
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button type="button" color="secondary" onClick={onCloseEditCommentModal}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </Button>
                <Button type="submit" color="secondary">
                  {f('BASIC_BUTTON_SAVE_TEXT')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </StyledModalBox>
    </Modal>
  )
}

export default EditCommentModalView
