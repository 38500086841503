import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'
import { IPlannedActivityRowItem } from 'api/tillit.api-client'

import {
  ActivityParticipantItem,
  PlannedActivityParticipantItem,
  PlannedActivitySignedParticipantCreateItem
} from 'api/tillit.api-client'

import { ParticipantItem } from 'components/activity-participants/participant-item-type'

interface ISignParticipantsModel {
  plannedActivity: IPlannedActivityRowItem
  comment: string | undefined
  activityParticipants?: ParticipantItem[] | undefined
}

const useSingleActivityParticipantsModalFormData = (
  plannedActivity: IPlannedActivityRowItem,
  handleSubmit: (plannedActivitySignedParticipants: PlannedActivitySignedParticipantCreateItem[] | undefined, comment?: string) => void
) => {
  const f = useFormatMessage()

  const initialValues: ISignParticipantsModel = React.useMemo(
    () => ({
      plannedActivity,
      comment: plannedActivity.comment,
      activityParticipants:
        plannedActivity && plannedActivity.plannedActivityParticipants
          ? plannedActivity.plannedActivityParticipants.map((pap: PlannedActivityParticipantItem) => {
            const pa: ParticipantItem = new ActivityParticipantItem({
              id: pap.id,
              activityId: pap.plannedActivityRowId,
              customerId: pap.customerId,
              customerFullName: pap.customerFullName
            })
            pa.declined = false
            return pa
          })
          : [],
    }),
    [plannedActivity]
  )

  const onSubmit: OnSubmitFunction<ISignParticipantsModel> = React.useCallback(
    (values, { setSubmitting }) => {
      const plannedActivityParticipants = values.activityParticipants?.map(ap => new PlannedActivitySignedParticipantCreateItem({
        customerId: ap.customerId,
        declined: ap.declined ? true : false
      }))

      if (plannedActivityParticipants) {
        handleSubmit(plannedActivityParticipants, values.comment)
      }

      setSubmitting(false)
    },
    [handleSubmit]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({

      }),
    [f]
  )

  const name: Name<ISignParticipantsModel> = React.useMemo(
    () => ({
      plannedActivity: 'plannedActivity',
      comment: 'comment',
      activityParticipants: 'activityParticipants',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useSingleActivityParticipantsModalFormData
