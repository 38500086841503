import CenterModal from 'components/center-modal'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import ModalContent from 'components/modal-content'
import React from 'react'
import { Typography } from '@mui/material'
import { useFormatMessage } from 'localization'
import { Helmet } from 'react-helmet'

export const PrivacyPolicyContainer: React.SFC = () => {
  const f = useFormatMessage()

  const { privacyPolicyModal } = ProfilePageContext.useState()

  const { toggleModalPrivacyPolicy } = ProfilePageContext.useActions()

  const handleClose = React.useCallback(() => toggleModalPrivacyPolicy(false), [toggleModalPrivacyPolicy])

  return (
    <CenterModal open={privacyPolicyModal} onClose={handleClose} adminPageResponsive fullSize>
      <ModalContent style={{padding:'20px'}}>
          <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '10px' }}>
            Integritetspolicy
          </Typography>
          <Typography>
            Allevi AB, org. nr. 556780-4157, (“Allevi”) värdesätter informationssäkerhet. Vi tar våra kunders integritet på största allvar och för att skydda
            din information lägger vi stor vikt vid att våra lösningar ska vara så säkra som möjligt. När du använder våra tjänster hanterar vi dina
            personuppgifter i enlighet med denna policy, i vilken vi
          </Typography>
          <div>
            <ul>
              <Typography>
                <li>förklarar hur och varför vi använder den information som du som användare delar med oss,</li>
                <li>säkerställer att du förstår vilken information vi samlar in, och</li>
                <li>
                  informerar om dina rättigheter. Vi behandlar alltid dina personuppgifter i enlighet med gällande lagstiftning och endast för de ändamål och på
                  de sätt som anges i denna policy.
                </li>
              </Typography>
            </ul>
          </div>
          <Typography>
            Allevi är personuppgiftsansvariga avseende behandlingen av de insamlade personuppgifterna. Om du vill komma i kontakt med oss, skicka gärna ett
            e-mail till info@allevi.se.
          </Typography>

          <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '10px' }}>
            Vilka uppgifter vi behandlar, hur vi gör det, varför vi gör det och hur uppgifterna lagras
          </Typography>
          <Typography>
            För att du ska kunna registrera dig som användare och för att vi ska kunna tillhandahålla våra tjänster till dig behöver du uppge viss information
            om dig själv såsom namn, personnummer och mailadress. Den rättsliga grunden för denna behandling av dina personuppgifter är alltså att behandlingen
            är nödvändig för att du ska kunna använda våra tjänster. Som en del av att tillhandahålla Allevis tjänster lagras sedan dina kunduppgifter i Allevis
            stödsystem för att du ska kunna nyttja våra tjänster för exempelvis support. Allevi begär aldrig in mer uppgifter från dig än vad som är nödvändigt
            för att du ska kunna nyttja våra tjänster. Vi använder inte dina personuppgifter som grund för någon form av automatiskt beslutsfattande, inbegripet
            profilering. Behandlingen av uppgifter sker även som ett led i att uppfylla lagkrav, myndighetskrav och för att uppfylla kollektivavtal. De
            uppgifter som samlas in av Allevi kan således komma att rapporteras till myndigheter vid förfrågan. Den rättsliga grunden för viss behandling är
            således att Allevi ska fullgöra rättsliga förpliktelser. Allevis tjänster är inte riktade till barn under 18 år och vi samlar heller inte medvetet
            in personuppgifter från barn under 18 år.
          </Typography>

          <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '10px' }}>
            Till vilka delar vi dina uppgifter
          </Typography>
          <Typography>
            Vi delar din information med de underleverantörer vi behöver för att tillhandahålla våra tjänster, t.ex. extern lagring. Dessa parter har inte
            tillgång till, eller använder, personuppgifter för annat ändamål än vad som redovisats samt för att utföra molntjänster och lagringstjänster.
            Vidarebefordring av dina uppgifter till tredje part kommer endast ske inom ramen för de lagstadgade bestämmelserna eller med motsvarande samtycke. I
            övrigt kommer vidarebefordran till tredje part inte att ske, såvida vi inte är skyldiga att göra det på grund av tvingande lagstiftning (t.ex.
            vidarebefordran till externa organ som tillsynsmyndigheter eller brottsbekämpande myndigheter).
          </Typography>

          <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '10px' }}>
            Hur lång tid vi lagrar dina uppgifter
          </Typography>
          <Typography>
            De personuppgifter vi behandlar för att kunna tillhandahålla vår tjänst, dvs. dina användaruppgifter, kommer att lagras av oss så länge ditt
            användarkonto är aktivt. För det fall ditt användarkonto avaktiveras kommer dessa uppgifter att raderas efter 12 månader. Vi är medvetna om att de
            regler som är tillämpliga på de uppgifter du laddar upp när du använder tjänsten kan föreskriva långa lagringstider och krav på gallring, till
            exempel lag (1993:387) om stöd och service till vissa funktionshindrade. Allevi tar inte ansvar för din efterlevnad av dessa regler och du är
            således själv ansvarig för att tillse att lagrings och gallringskraven följs. Vi implementerar dock säkerhetsåtgärder i våra tjänster för att
            underlätta din efterlevnad. En avaktivering av kontot medför att även dessa uppgifter raderas. Vi kommer dock att hålla uppgifterna tillgängliga för
            din nedladdning under 12 månader efter att kontot avaktiverats.
          </Typography>

          <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '10px' }}>
            Hur vi skyddar de uppgifter som laddas upp
          </Typography>
          <Typography>
            För att skydda integriteten för dina personuppgifter vidtar vi lämpliga tekniska och organisatoriska skyddsåtgärder. Vi begränsar tillgången till
            personuppgifter till medarbetare på Allevi som behöver veta den för att utföra sitt arbete. Det är dock viktigt att du som användare också är
            försiktig och företrädesvis väljer ett unikt och starkt lösenord, begränsar åtkomsten till din dator och webbläsare samt loggar ut efter att ha
            använt Allevis tjänst.
          </Typography>

          <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '10px' }}>
            Dina rättigheter som användare
          </Typography>
          <Typography>
            Enligt tillämplig lagstiftning har du rätt att få information om hur Allevi behandlar dina personuppgifter och även invända mot den. Exempelvis har
            du rätt att få en kopia på önskade utdrag om vår behandling av dina personuppgifter. Om de uppgifter vi behandlar om dig skulle visa sig vara
            felaktiga, ofullständiga eller på annat sätt irrelevanta har du rätt att kontakta oss för att antingen rätta, begränsa eller radera de aktuella
            uppgifterna. Du har också rätt att få ut och använda dina personuppgifter på annat håll, till exempel i en annan tjänst (rätten till
            dataportabilitet). Kontakta oss gärna per e-mail på info@allevi.se om du vill utnyttja dina rättigheter.
          </Typography>

          <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '10px' }}>
            Kontakt med tillsynsmyndighet
          </Typography>
          <Typography>
            Du som användare har alltid möjlighet att inge klagomål till Integritetsskyddsmyndigheten (IMY), tillsynsmyndighet för efterlevnaden av aktuell
            dataskyddslagstiftning i Sverige. IMY kan nås via imy@imy.se
          </Typography>
          <div>
            <Helmet>
              <script
                id="CookieDeclaration"
                src="https://consent.cookiebot.com/c5bd0618-a80b-4631-a89c-bfb30434be28/cd.js"
                type="text/javascript"
                async></script>
            </Helmet>
          </div>
      </ModalContent>
      <ModalFooter>
        <TextButton onClick={handleClose}>{f('BASIC_BUTTON_CLOSE_TEXT')}</TextButton>
      </ModalFooter>
    </CenterModal>
  )
}
