/* eslint-disable react/jsx-no-bind */
import { Box, Typography, Icon, Checkbox } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import { PlannedActivityStatusEnum, RecurrenceEnum } from 'api/tillit.api-client'
import EllipsisTypographyStyle from 'components/ellipsis-typography'
import { format } from 'date-fns'
import { useFormatMessage } from 'localization'
import React from 'react'
import Color from 'color'
import { ReactComponent as TimerPlay } from 'assets/images/timer_play.svg'
import { History, ClockAlert as TimeCritical, SyncOff as TemporaryActivity, CommentTextOutline, AccountMultiple as ParticipantIcon } from 'mdi-material-ui'
import { SeverityLevelEnum } from 'pages/planning/components/temporary-activity-create-modal/temporary-activity-create-modal-context'
import { StyledBoxHeader } from './daily-activity-item-style'
import {CheckboxMarkedCircle, CheckboxBlankCircle} from 'mdi-material-ui'
type Props = Readonly<
  {
    areaName: string
    title: string
    color: string | undefined
    activityColor?: string
    customer: string
    initials: string
    isLocked: boolean
    status: PlannedActivityStatusEnum
    hasLapsed: boolean
    roomNumber?: string
    activityName?: string
    lastSignedOn?: Date
    isCommonActivity: boolean
    startTime?: Date
    endTime?: Date
    comment?: string
    activityShowColorOnDevice: boolean
    templateActivityTitle?: string
    hasUnfulfilledDelegations: boolean
    severityLevelId: SeverityLevelEnum
    recurranceId: RecurrenceEnum
    showExactTimeOnKioskFromUserSetting: boolean
    useParticipantList: boolean
    useMultiSigning: boolean
    onMultiSignClicked: () => void
    unitTypeId: number
    selectedUnitTypeId: number | undefined
    multiSignChecked: boolean
    suppressTimes: boolean
    alertFromStartInMinutes: number
  } & BoxProps
>

const DailyActivityItemContainer: React.FC<Props> = ({
  areaName,
  title,
  color,
  activityColor,
  customer,
  initials,
  isLocked,
  status,
  hasLapsed,
  roomNumber,
  activityName,
  lastSignedOn,
  isCommonActivity,
  startTime,
  endTime,
  comment,
  activityShowColorOnDevice,
  templateActivityTitle,
  hasUnfulfilledDelegations,
  severityLevelId,
  recurranceId,
  showExactTimeOnKioskFromUserSetting,
  useParticipantList,
  useMultiSigning,
  onMultiSignClicked,
  unitTypeId,
  selectedUnitTypeId,
  multiSignChecked,
  suppressTimes,
  alertFromStartInMinutes,
  ...rest
}) => {
  const f = useFormatMessage()

  const getTexts = () => {
    const combinedActivityTitle = templateActivityTitle ? templateActivityTitle + ' ' + (title ? '(' + title + ')' : '') : title ? title : ''

    if (isLocked && isCommonActivity) {
      return {
        title: f('DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT'),
        subtitle: '',
      }
    }
    if (!isLocked && isCommonActivity) {
      return {
        title: combinedActivityTitle,
        subtitle: f('DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT'),
      }
    }
    if (isLocked && !isCommonActivity) {
      return { title: roomNumber?.trim() || initials, subtitle: '' }
    }
    if (!isLocked && !isCommonActivity) {
      return { title: combinedActivityTitle, subtitle: roomNumber?.trim() ? roomNumber : customer }
    }
    return null
    // const isCommonActivity = isLocked
    //   ? f('DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT_ABBREVIATION')
    //   : f('DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT')

    // const isActivity = isLocked ? initials : activityName

    // const isRoomNumberOrActivity = roomNumber ? roomNumber : isActivity

    // const titleResult = areaName ? isCommonActivity : isRoomNumberOrActivity
    // return titleResult
  }

  // For activities with planned date < todays date; Show date + time as planned time. Otherwise (for activities planned for today) just show the time.
  const activityPlannedStart = !startTime ? '' : format(startTime, 'HH:mm')
  const activityPlannedEnd = !endTime ? '' : format(endTime, 'HH:mm')
  let activityPlannedPastDate: string | null = null
  if (startTime && format(startTime, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')) {
    activityPlannedPastDate = format(startTime, 'yyyy-MM-dd')
  }

  const [showExactTimeOnActivityOnKiosk, setShowExactTimeOnActivityOnKiosk] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isLocked && showExactTimeOnKioskFromUserSetting) {
      setShowExactTimeOnActivityOnKiosk(true)
    }
    else {
      setShowExactTimeOnActivityOnKiosk(false)
    }
  }, [setShowExactTimeOnActivityOnKiosk, isLocked, showExactTimeOnKioskFromUserSetting])

  let minutesOnActivity = 0
  if (showExactTimeOnActivityOnKiosk) {
    let diff = (Math.abs(endTime!.getTime() - startTime!.getTime()))
    minutesOnActivity = Math.floor((diff / 1000) / 60);
  }

  const texts = getTexts()
  const bgColor = color ? Color(color) : Color('white')
  const fadeBgColor = Color(color).fade(0.7).string()
  const iconContrastColor = 'black' // Black seems fine in all cases. Color(fadeBgColor).contrast(Color('rgba(0, 0, 0, 0.82)')) > 6.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'

  const showTimes = !suppressTimes || (severityLevelId === SeverityLevelEnum.Critical) || (alertFromStartInMinutes > 0)

  return (
    <Box {...rest}>

      <StyledBoxHeader tourColor={bgColor.string()}>
        <Box className={(status === PlannedActivityStatusEnum.NotCompleted && hasLapsed) ? 'header-tour' : ''} sx={(theme) => ({
          width: '32px',
          position: 'absolute',
          left: 0,
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '4px',
          borderBottomRightRadius: '100%',
          borderBottomLeftRadius: '0px',
          backgroundColor: color,
          [theme.breakpoints.down('md')]: {
            height: '25px',
            width: '27px',
          }
        })}>
        </Box>
        {useMultiSigning && (selectedUnitTypeId ? unitTypeId === selectedUnitTypeId : true) && !useParticipantList && !hasUnfulfilledDelegations && (
            <Checkbox checked={multiSignChecked} onClick={onMultiSignClicked}
              icon={<CheckboxBlankCircle sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: '23px',
                }
              })}/>}
              checkedIcon={<CheckboxMarkedCircle  sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: '23px',
                }
              })}/>}
              sx={(theme) => ({
              position: 'absolute',
              color: 'white',
              '&.Mui-checked': {
                color: 'white',
              },
              left: '-10px',
              top: '-10px',
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('md')]: {
                top: '-12px',
              }
          })}/>
          )}
        <Box sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '30px',
          [theme.breakpoints.down('md')]: {
            height: '25px',
          }
        })}>
          <Typography data-matomo-mask sx={{ fontWeight: 600 }} color={iconContrastColor} variant={'caption'} noWrap>{
            isLocked ?
              texts?.title
              :
              texts?.subtitle
          }</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          height: '22px',
          alignItems: 'center',
          position: 'absolute',
          right: 2
        }}>
          {severityLevelId === SeverityLevelEnum.Critical && (
            <TimeCritical style={{ width: '16px', color: iconContrastColor }} />
          )}
          {recurranceId === RecurrenceEnum.OneTime && (
            <TemporaryActivity style={{ width: '16px', color: iconContrastColor }} />
          )}
          {useParticipantList && (
            <ParticipantIcon style={{ width: '16px', color: iconContrastColor }} />
          )}
          {comment && (
            <CommentTextOutline style={{ width: '16px', color: iconContrastColor }} />
          )}
          {activityColor && activityShowColorOnDevice && (
            <Box sx={{
              display: 'inline-block',
              verticalAlign: 'text-bottom',
              width: '16px',
              height: '16px',
              borderRadius: '100%',
              backgroundColor: activityColor
            }}></Box>
          )}
        </Box>
      </StyledBoxHeader>

      <Box sx={(theme) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: (status !== PlannedActivityStatusEnum.NotCompleted && status !== PlannedActivityStatusEnum.Delayed && status !== PlannedActivityStatusEnum.Started) ? `linear-gradient(135deg, ${fadeBgColor} 3.13%, ${rest.bgcolor} 3.13%, ${rest.bgcolor} 50%, ${fadeBgColor} 50%, ${fadeBgColor} 53.13%, ${rest.bgcolor} 53.13%, ${rest.bgcolor} 100%)` : 'none',
        backgroundSize: (status !== PlannedActivityStatusEnum.NotCompleted && status !== PlannedActivityStatusEnum.Delayed && status !== PlannedActivityStatusEnum.Started) ? '16.00px 16.00px' : 'unset',
        flex: 1,
        '*': {
          lineHeight: isLocked ? 1.6 : 1.5
        },
      })}>
        <EllipsisTypographyStyle data-matomo-mask sx={{ fontWeight: 600, textAlign: 'center', width: '100%', padding: '0 5px', whiteSpace: 'nowrap' }} variant='caption'>
          {!isLocked && (
            texts?.title
          )}
        </EllipsisTypographyStyle>
        <Box sx={(theme) => ({
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 1
          }
        })}>
          <Typography variant='h6'>
            {status === PlannedActivityStatusEnum.NotCompleted && (
              <>
                {activityPlannedPastDate && !showExactTimeOnActivityOnKiosk && (
                  <>
                    <span style={{ color: '#ff0000', fontSize: 'small' }}>{activityPlannedPastDate}</span>
                    {showTimes && (
                      <span>&nbsp;{activityPlannedStart}</span>
                    )}
                  </>
                )}
                {activityPlannedPastDate && showExactTimeOnActivityOnKiosk && (
                  <>
                    <span style={{ color: '#ff0000', fontSize: 'small' }}>{activityPlannedPastDate}</span>
                    {showTimes && (
                      <span>&nbsp;{activityPlannedStart + '-' + activityPlannedEnd}</span>
                    )}
                  </>
                )}
                {showTimes && !activityPlannedPastDate && !showExactTimeOnActivityOnKiosk && (
                  <>
                    <span>{activityPlannedStart}</span>
                  </>
                )}
                {showTimes && !activityPlannedPastDate && showExactTimeOnActivityOnKiosk && (
                  <>
                    <span>&nbsp;{activityPlannedStart + '-' + activityPlannedEnd}</span><span style={{ fontSize: 'small' }}>{' (' + minutesOnActivity + ' min)'}</span>
                  </>
                )}
              </>
            )}
            {status === PlannedActivityStatusEnum.Delayed && (
              <>
                <Icon sx={{ height: '17px' }} component={History} />
                <>
                  {activityPlannedPastDate && !showExactTimeOnActivityOnKiosk && (
                    <>
                      <span style={{ color: '#ff0000', fontSize: 'small' }}>{activityPlannedPastDate}</span>
                      {showTimes && (
                        <span>&nbsp;{activityPlannedStart}</span>
                      )}
                    </>
                  )}
                  {activityPlannedPastDate && showExactTimeOnActivityOnKiosk && (
                    <>
                      <span style={{ color: '#ff0000', fontSize: 'small' }}>{activityPlannedPastDate}</span>
                      {showTimes && (
                        <span>&nbsp;{activityPlannedStart + '-' + activityPlannedEnd}</span>
                      )}
                    </>
                  )}
                  {showTimes && !activityPlannedPastDate && !showExactTimeOnActivityOnKiosk && (
                    <>
                      <span style={{ fontSize: 'small' }}>{f('DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT')}{' '}</span>
                      <span>{activityPlannedStart}</span>
                    </>
                  )}
                  {showTimes && !activityPlannedPastDate && showExactTimeOnActivityOnKiosk && (
                    <>
                      <span style={{ fontSize: 'small' }}>{f('DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT')}{' '}</span>
                      <span>&nbsp;{activityPlannedStart + '-' + activityPlannedEnd}</span>
                    </>
                  )}
                </>
              </>
            )}
            {status === PlannedActivityStatusEnum.Started && lastSignedOn && (
              <>
                <Icon sx={{ height: '17px' }} component={TimerPlay} />
                <>
                  <span style={{ fontSize: 'small' }}>{f('DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT_STARTED')}</span>
                  <>
                    <span>&nbsp;{format(lastSignedOn, 'HH:mm')}</span>
                  </>
                  {showTimes && showExactTimeOnActivityOnKiosk && (
                    <span style={{ fontSize: 'small' }}>&nbsp;{'(' + minutesOnActivity + ' min)'}</span>
                  )}
                </>
              </>
            )}
          </Typography>
          <Typography variant='caption'>
            {lastSignedOn && status !== PlannedActivityStatusEnum.NotCompleted && status !== PlannedActivityStatusEnum.Delayed && status !== PlannedActivityStatusEnum.Started && (
              <>
                {f('DAILY_ACTIVITIES_ITEM_CONTAINER_SIGNED_ACTIVITY_TEXT')} {format(lastSignedOn, 'HH:mm')} <br />
                {format(lastSignedOn, 'yyyy-MM-dd')}
              </>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default DailyActivityItemContainer
