import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { NavLink } from 'react-router-dom'
import { LoginPage } from 'components/page'
import Authentication from 'components/authentication'
import ContainedButton from 'components/inputs/contained-button'
import OutlinedTextField from 'components/inputs/outlined-text-field'
import { useFormatMessage } from 'localization'
import styled, { css } from 'styled-components'
import useChangePasswordForm from 'utilities/use-change-password-form'

const StyledNavLink = styled(NavLink)(
  props => css`
    text-decoration: none;
    &.active > * {
      color: ${props.theme.palette.primary.contrastText};
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.3);
    }
  `
)

const ChangePasswordContainer: React.SFC = () => {
  const { initialValues, onSubmit, validationSchema, name } = useChangePasswordForm()
  const f = useFormatMessage()

  return (
    <LoginPage>
      <Grid container direction="column"  rowSpacing={3} columnSpacing={0}>
        <Grid item>
          <Typography sx={{
            fontWeight:600,
            letterSpacing:'0.01em'
          }} variant="h3">{f('CHANGE_PASSWORD_SCENE_CHANGE_PASSWORD_TITLE')}</Typography>
        </Grid>
        <Grid item>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnBlur={false}>
            {({ isSubmitting, status }) => (
              <Form translate="yes">
                <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
                  <Grid item>
                    <OutlinedTextField
                      data-matomo-mask
                      fullWidth
                      name={name.currentPassword}
                      label={f('CHANGE_PASSWORD_SCENE_CURRENT_PASSWORD_INPUT_LABEL')}
                      type="password"
                      autoFocus
                      color="secondary"
                      disabled={status && status.error && status.error === 100}
                    />
                  </Grid>
                  <Grid item>
                    <OutlinedTextField
                      data-matomo-mask
                      fullWidth
                      name={name.newPassword}
                      label={f('CHANGE_PASSWORD_SCENE_NEW_PASSWORD_INPUT_LABEL')}
                      type="password"
                      color="secondary"
                      disabled={status && status.error && status.error === 100}
                    />
                  </Grid>
                  <Grid item>
                    <OutlinedTextField
                      data-matomo-mask
                      fullWidth
                      name={name.confirmPassword}
                      label={f('CHANGE_PASSWORD_SCENE_CONFIRM_PASSWORD_INPUT_LABEL')}
                      type="password"
                      color="secondary"
                      disabled={status && status.error && status.error === 100}
                    />
                  </Grid>
                  <Grid item sx={{minHeight:'50px',paddingTop:'0 !important'}}>
                    {status && status.error && status.error !== 100 && (
                      <Typography color={'error'}>
                        {f('CHANGE_PASSWORD_SCENE_CONFIRM_PASSWORD_ERROR_TEXT', {
                          type: status.error,
                        })}
                      </Typography>
                    )}
                    {status && status.error && status.error === 100 && (
                      <Typography color={'primary'}>
                        {f('CHANGE_PASSWORD_SCENE_CONFIRM_PASSWORD_ERROR_TEXT', {
                          type: status.error,
                        })}
                      </Typography>
                    )}
                  </Grid>
                  {status && status.error && status.error === 100 ? (
                    ''
                  ) : (
                    <Grid item sx={{paddingTop:'0 !important'}}>
                      <ContainedButton containerStyle={{margin:0,display:'flex',justifyContent:'center'}} sx={{
                            width:'80%',
                            paddingTop:'10px',
                            paddingBottom:'10px',
                            borderRadius:'20px'
                          }} type="submit" color="primary" showProgress={isSubmitting}>
                        {f('CHANGE_PASSWORD_SCENE_CHANGE_PASSWORD_BUTTON_TEXT')}
                      </ContainedButton>
                    </Grid>
                  )}
                  <Grid item sx={{textAlign: 'center'}}>
                    <StyledNavLink to="..">
                      <Typography sx={
                        {fontWeight:500}
                      } variant="subtitle1" color="secondary">{f('GENERAL_BACK')}</Typography>
                    </StyledNavLink>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </LoginPage>
  )
}

export default ChangePasswordContainer
