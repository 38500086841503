import React from 'react'
import AreaModal from 'components/area-modal'
import { AreasPageContext } from './areas-page-context'
import AreasPageView from './areas-page-view'

const AreasContainer: React.FC = () => {
  return (
    <AreasPageContext.Provider>
      <AreasPageView />
      <AreaModal />
    </AreasPageContext.Provider>
  )
}

export default AreasContainer
