import React from 'react'
import { Box, Grid, List, ListItem, ListItemText, Typography, Checkbox, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Form, Formik } from 'formik'
import { LoginPage } from 'components/page'
import { NavLink } from 'react-router-dom'
import PageRoute from 'config/page-routes'
import { useOrganizationApi } from 'api'
import { IOrganizationListItem, RoleEnum, UserAreaItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import OutlinedTextField from 'components/inputs/outlined-text-field'
import ModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import { useLocationState } from 'utilities/react-router-hooks'
import styled, { css } from 'styled-components'
import useLoginForm from './utilities/use-login-form'
import { getRememberData, setRememberMe, setRememberOrganization } from './utilities/remember-me-helpers'
import LoginPageContext from './login-page-context'
import { isEmpty } from 'lodash'
import { PWA_URL } from 'config/variables'


const StyledNavLink = styled(NavLink)(
  props => css`
    text-decoration: none;
    &.active > * {
      color: ${props.theme.palette.primary.contrastText};
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.3);
    }
  `
)

const LoginPageView: React.FC = () => {

  const locationState = useLocationState()
  const { loginMethods } = LoginPageContext.useState()
  const [loginMethod, setLoginMethod] = React.useState<string>('standard')

  const [rememberMeChecked, setRememberMeChecked] = React.useState<boolean>(() => {
    const rememberData = getRememberData()
    return Boolean(rememberData.email)
  })

  const [rememberOrgChecked, setRememberOrgChecked] = React.useState<boolean>(() => {
    const rememberData = getRememberData()
    return Boolean(rememberData.organization)
  })

  const handleRememberMeCheckbox = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMeChecked(event.target.checked)
    if (!event.target.checked) {
      setRememberMe('')
    }
  }, [])

  const handleRememberOrgCheckbox = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberOrgChecked(event.target.checked)
    if (!event.target.checked) {
      setRememberOrganization('')
    }
  }, [])

  const f = useFormatMessage()
  const { initialValues, onSubmit, validationSchema, name } = useLoginForm(locationState, rememberMeChecked, rememberOrgChecked, loginMethod)

  const handleChangeLoginMethod = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, newLoginMethod: string | null) => {
      if (newLoginMethod) {
        setLoginMethod(newLoginMethod);
      }
    },
    [setLoginMethod]
  );


  return (
    <>
      <LoginPage>
        <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
          <Grid item>
            <Typography sx={{
              fontWeight: 600,
              letterSpacing: '0.01em'
            }} variant="h3">{f('LOGIN_PAGE_CONTAINER_HEADER_LOGIN_TITLE')}</Typography>
          </Grid>
          {loginMethods && loginMethods.length > 1 && (
            <Grid item>
              <Box width='100%'>
                <ToggleButtonGroup
                  value={loginMethod}
                  exclusive
                  onChange={handleChangeLoginMethod}
                  aria-label="text alignment"
                  fullWidth
                >
                  {loginMethods.map((lm) => {
                    if (!lm.methodName) {
                      return null
                    }

                    const togglText = f(`LOGIN_SERVICE_TYPE_${lm.methodName?.toUpperCase()}`)
                    return (
                      <ToggleButton key={`toggllm_${lm.methodName}`} aria-label={togglText} value={lm.methodName as string}>
                        {togglText}
                      </ToggleButton>
                    )

                  })}
                </ToggleButtonGroup>
              </Box>
            </Grid>
          )}
          <Grid item>
            {loginMethod === 'standard' && (

              <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnBlur={false}>
                {({ isSubmitting, status, values }) => {
                  return (
                    <Form translate="yes">
                      <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
                        <Grid item>
                          <OutlinedTextField
                            data-matomo-mask
                            id='login-page-email-textfield'
                            label={f('LOGIN_PAGE_CONTAINER_FORM_EMAIL_LABEL')}
                            name={name.email}
                            type="text"
                            color="secondary"
                            autoFocus={!values.email}
                            inputProps={{ autoComplete: values.email ? 'on' : 'off' }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item>
                          <OutlinedTextField
                            data-matomo-mask
                            id='login-page-password-textfield'
                            label={f('LOGIN_PAGE_CONTAINER_FORM_PASSWORD_LABEL')}
                            name={name.password}
                            type="password"
                            color="secondary"
                            autoFocus={!values.password && !!values.email}
                            fullWidth
                          />
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox sx={{ p: 0 }} color="secondary" checked={rememberMeChecked} onChange={handleRememberMeCheckbox} id='login-page-remember-me-checkbox' />
                            <Typography sx={
                              { fontWeight: 500 }
                            } variant="subtitle2" color="secondary">{f('LOGIN_PAGE_CONTAINER_FORM_REMEMBER_ME_TEXT')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item sx={{ minHeight: '50px', paddingTop: '0 !important' }}>
                          {status && status.error && <Typography color={'error'} id='login-page-login-failed-typography'>{f('LOGIN_PAGE_CONTAINER_FORM_LOGIN_FAILED_LABEL')}</Typography>}
                        </Grid>
                        <Grid item sx={{ paddingTop: '0 !important' }}>
                          <ContainedButton containerStyle={{ margin: 0, display: 'flex', justifyContent: 'center' }} sx={{
                            width: '80%',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '20px'
                          }} type="submit" color="primary" showProgress={isSubmitting} id='login-page-continue-button'>
                            {f('LOGIN_PAGE_CONTAINER_FORM_LOGIN_BUTTON_TEXT')}
                          </ContainedButton>
                        </Grid>
                        <Grid item sx={{ textAlign: 'center' }}>
                          <StyledNavLink to={PageRoute.ForgotPassword.path} id='login-page-forgotpassword-link'>
                            <Typography sx={
                              { fontWeight: 500 }
                            } variant="subtitle1" color="secondary">{f('LOGIN_PAGE_CONTAINER_FORM_FORGOTTEN_PASSWORD')}</Typography>
                          </StyledNavLink>
                        </Grid>
                        <Grid item sx={{ textAlign: 'center' }}>
                          {PWA_URL && (
                            <StyledNavLink to={PWA_URL} reloadDocument>
                              <Typography sx={
                                { fontWeight: 500 }
                              } variant="subtitle1" color="secondary">{f('LOGIN_PAGE_CONTAINER_FORM_PWA_LINK')}</Typography>
                            </StyledNavLink>
                          )}
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>

            )}

            {(loginMethod === 'gids_saml' || loginMethod === 'gids_frejaeid') && (

              <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnBlur={false}>
                {({ isSubmitting, status, values }) => {
                  return (
                    <Form translate="yes">
                      <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
                        <Grid item>
                          <OutlinedTextField
                            data-matomo-mask
                            id='login-page-organization-textfield'
                            label={f('LOGIN_PAGE_CONTAINER_FORM_ORGANIZATION_LABEL')}
                            name={name.organization}
                            type="text"
                            color="secondary"
                            autoFocus={!values.organization}
                            inputProps={{ autoComplete: values.organization ? 'on' : 'off' }}
                            fullWidth
                          />
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox sx={{ p: 0 }} color="secondary" checked={rememberOrgChecked} onChange={handleRememberOrgCheckbox} id='login-page-remember-organization-checkbox' />
                            <Typography sx={
                              { fontWeight: 500 }
                            } variant="subtitle2" color="secondary">{f('LOGIN_PAGE_CONTAINER_FORM_REMEMBER_ORGANIZATION_TEXT')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item sx={{ minHeight: '50px', paddingTop: '0 !important' }}>
                          {status && status.error && <Typography color={'error'} id='login-page-login-failed-typography'>{f('LOGIN_PAGE_CONTAINER_FORM_LOGIN_FAILED_LABEL')}</Typography>}
                        </Grid>
                        <Grid item sx={{ paddingTop: '0 !important' }}>
                          <ContainedButton containerStyle={{ margin: 0, display: 'flex', justifyContent: 'center' }} sx={{
                            width: '80%',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '20px'
                          }} type="submit" color="primary" showProgress={isSubmitting} id='login-page-continue-button'>
                            {f('LOGIN_PAGE_CONTAINER_FORM_LOGIN_BUTTON_TEXT')}
                          </ContainedButton>
                        </Grid>
                        <Grid item sx={{ textAlign: 'center' }}>
                          {PWA_URL && (
                            <StyledNavLink to={PWA_URL}>
                              <Typography sx={
                                { fontWeight: 500 }
                              } variant="subtitle1" color="secondary">{f('LOGIN_PAGE_CONTAINER_FORM_PWA_LINK')}</Typography>
                            </StyledNavLink>
                          )}
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>

            )}


          </Grid>
        </Grid>
      </LoginPage>
    </>
  )
}

export default LoginPageView
