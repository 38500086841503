import { Box, ListItem, ListItemText } from '@mui/material'
import styled from 'styled-components'

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.palette.background.paper};
  box-shadow: ${props => props.theme.shadows[5]};
  outline: none;
  border-radius: 4px;
  padding: ${props => props.theme.spacing(1)};

  .title {
    margin-bottom: ${props => props.theme.spacing(2)};
  }

  .content {
    padding: ${props => props.theme.spacing(2)};

    .field {
      min-width: 300px;
    }
  }
`

export const StyledListItemText = styled(ListItemText)`
  p {
    text-transform: lowercase;
  }
  p::first-letter {
    text-transform: uppercase;
  }
`

/*
  border-top: 1px solid ${props => props.theme.palette.grey['300']};
  border-left: 1px solid ${props => props.theme.palette.grey['300']};
  border-right: 1px solid ${props => props.theme.palette.grey['300']};
  border-radius: 0px;
  margin-top: ${props => props.theme.spacing(0)}px;
  margin-bottom: ${props => props.theme.spacing(0)}px;
  padding-bottom: ${props => props.theme.spacing(1)}px;
  padding-bottom: ${props => props.theme.spacing(1)}px;

  &:hover {
    background-color: ${props => props.theme.palette.secondary.main};
  }

*/

export const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  flex: 1;
  &:hover {
    background-color: #f5f5f5;
  }
`
