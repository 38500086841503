import React from 'react'

import {
    DrugListClient,
    UnitItem,
    UnitListItem,
    UnitUpdateItem,
    UnitCreateItem,
    IUnitUpdateItem,
    IUnitCreateItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class UnitApi {
    private readonly abortController = new AbortController()
    private readonly client: DrugListClient

    public constructor() {
        this.client = new DrugListClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public getUnit = (unitId: number): Promise<UnitItem> =>
        this.client
            .getUnit(unitId)
            .then(unitItem =>
                unitItem === null
                    ? Promise.reject(`No unit item received from backend for unit id ${unitId}.`)
                    : Promise.resolve(unitItem)
            )

    public listUnits = (organizationId: number): Promise<UnitListItem[]> =>
        this.client
            .listOrganizationUnits(organizationId)
            .then(unitListItem =>
                unitListItem === null
                    ? Promise.reject(`No units received from backend for organization id ${organizationId}.`)
                    : Promise.resolve(unitListItem)
            )

    public updateUnit = (unit: IUnitUpdateItem) => {
        const model = new UnitUpdateItem(unit)
        return this.client
            .updateUnit(model)
            .then(unitItem =>
                unitItem === null ? Promise.reject(`When creating an unit, no unit item received from backend.`) : Promise.resolve(unitItem)
            )
    }

    public createUnit = (unit: IUnitCreateItem) => {
        const model = new UnitCreateItem(unit)
        return this.client
            .createUnit(model)
            .then(unitItem =>
                unitItem === null ? Promise.reject(`When creating a unit, no unit item received from backend.`) : Promise.resolve(unitItem)
            )
    }

    public deleteUnit = (unitId: number) => this.client.deleteUnit(unitId).then(() => Promise.resolve(unitId))

    public hasTemplateActivityConnectedToUnit = (unitId: number): Promise<boolean> =>
        this.client
            .hasTemplateActivityConnectedToUnit(unitId)
            .then(exists =>
                exists === null
                    ? Promise.reject(`Unexpected response from hasTemplateActivityConnectedToUnit for unit id ${unitId}.`)
                    : Promise.resolve(exists)
            )

}

const useUnitApi = () => React.useMemo(() => new UnitApi(), [])

export default useUnitApi
