import React from 'react'

import {
  IPlannedRoutesActivitiesPlanningViewItem,
  IRouteCreateItem,
  IRouteUpdateItem,
  RouteCreateItem,
  RouteItem,
  RoutePlanningViewItem,
  RouteUpdateItem,
  RoutesClient,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class RouteApi {
  private readonly abortController = new AbortController()
  private client: RoutesClient

  constructor() {
    this.client = new RoutesClient(
      API_URL,
      httpMiddleware({ signal: this.abortController.signal })
    )
  }

  public abort = () => this.abortController.abort()

  public listPlanningViewForCalendarDay = (
    areaId: number,
    startTime: Date,
    endTime: Date
  ): Promise<RoutePlanningViewItem> =>
    this.client
      .getPlanningViewForCalendarDay(areaId, startTime, endTime)
      .then(routePlanningViewItem =>
        routePlanningViewItem === null
          ? Promise.reject(
            `No route planning view item received from backend for area id ${areaId} and ${startTime} and ${endTime}.`
          )
          : Promise.resolve(routePlanningViewItem)
      )

  public createRoute = (route: IRouteCreateItem): Promise<RouteItem> => {
    const model = new RouteCreateItem(route)
    return this.client
      .createRoute(model)
      .then(routeItem =>
        routeItem === null
          ? Promise.reject(`No route item received from backend when creating.`)
          : Promise.resolve(routeItem)
      )
  }

  public removeRoute = (
    route: IPlannedRoutesActivitiesPlanningViewItem,
    today: Date
  ): Promise<RouteItem | null> =>
    this.client
      .removeRoute(route.id, today)
      .then(routeItem =>
        routeItem === null
          ? Promise.reject(`No route item received from backend when removing.`)
          : Promise.resolve(routeItem)
      )

  public removeTimeBasedRouteContainer = (
    route: IPlannedRoutesActivitiesPlanningViewItem,
    today: Date
  ): Promise<boolean> =>
    this.client
      .removeTimeBasedRouteContainer(route.id, today)
      .then(routeItem =>
        routeItem === null
          ? Promise.reject(
            `No combined route result received from backend when removing.`
          )
          : Promise.resolve(routeItem)
      )

  public editRoute = (route: IRouteUpdateItem): Promise<RouteItem> => {
    const model = new RouteUpdateItem(route)
    return this.client
      .editRoute(model)
      .then(routeItem =>
        routeItem === null
          ? Promise.reject(`No route item received from backend when creating.`)
          : Promise.resolve(routeItem)
      )
  }

  public editTimeBasedRouteContainer = (
    routeId: number,
    newName: string,
    newColor: string
  ): Promise<boolean> =>
    this.client
      .editTimeBasedRouteContainer(routeId, newName, newColor)
      .then(route =>
        route === null
          ? Promise.reject(
            `No combined route result received from backend when renaming.`
          )
          : Promise.resolve(route)
      )
}

const useRouteApi = () => React.useMemo(() => new RouteApi(), [])

export default useRouteApi
