import { Box, Typography, useTheme } from '@mui/material'
import CenterModal from 'components/center-modal'
import ModalHeader from 'components/modal-header'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { ModalBox } from 'pages/daily-activities/daily-activities-container'
import React from 'react'
import styled from 'styled-components'
import { InfoFuturePlannedContainer } from 'components/info-future-planned/info-future-planned-container'
import { FuturePlannedActivityItem, RecurrenceEnum } from 'api/tillit.api-client'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import format from 'utilities/format'
import { FutureModalContext } from './future-modal-context'

const FutureModalContainer: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const { fetchState, futureModalOpen, futurePlannedActivities, filteredActivities, filterReccuranceType } = FutureModalContext.useState()
  const { isLoading } = fetchState

  const { closeFutureModal, setFilteredActivities, setFilterReccuranceType } = FutureModalContext.useActions()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')

  const filterActivities = (searchValue: string, activities: FuturePlannedActivityItem[] | undefined): FuturePlannedActivityItem[] | undefined => {
    // 'NFC' means Normalization Form Canonical Composition
    searchValue = searchValue.toLowerCase().normalize('NFC')
    if (!searchValue || (searchValue && searchValue.length === 0)) {
      return activities
    }

    const filtered = activities?.filter(activity => {
      let valid = true
      const activityText = `${activity.activityFullName} ${activity.comment} ${activity.routeName} ${format(activity.plannedStartTime, 'PPP p')}`
      searchValue.split(' ').forEach(term => {
        if (activityText) {
          valid =
            valid &&
            activityText
              .toLowerCase()
              .normalize('NFC')
              .includes(term)
        }
      })
      return valid
    })

    if (filtered) {
      return filtered
    } else {
      return undefined
    }
  }

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = filterActivities(searchValue, futurePlannedActivities)
      setFilteredActivities(filtered)
    },
    [setFilterValue, setFilteredActivities, futurePlannedActivities]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredActivities(futurePlannedActivities)
    },
    [setFilterValue, setFilteredActivities, futurePlannedActivities]
  )

  const handleFilterReccuranceType = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, newFilterReccuranceType: string | null) => {

      let filtered: FuturePlannedActivityItem[] | undefined
      if (!filterValue) {
        filtered = filterActivities('', futurePlannedActivities)
      } else {
        filtered = filterActivities(filterValue, futurePlannedActivities)
      }

      if (newFilterReccuranceType === 'temporary') {
        filtered = filtered?.filter(rpsi => rpsi.recurranceType === RecurrenceEnum.OneTime)

      }
      if (newFilterReccuranceType === 'ordinary') {
        filtered = filtered?.filter(rpsi => rpsi.recurranceType !== RecurrenceEnum.OneTime)
      }

      setFilteredActivities(filtered)

      setFilterReccuranceType(newFilterReccuranceType);
    },
    [filterValue, futurePlannedActivities, setFilteredActivities, setFilterReccuranceType]
  );

  return (
    <CenterModal open={futureModalOpen} onClose={closeFutureModal} responsive>
      {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
      {!isLoading && (
        <ModalBox>
          <ModalHeader
            title={(!isLoading && f('FUTURE_MODAL_HEADER_TITLE')) || ''}
            subtitle={(!isLoading && f('FUTURE_MODAL_HEADER_SUBTITLE')) || ''}
            justifyContent="center"
            responsive
          />
          <ModalBody>
            <Box sx={{display:'flex',paddingBottom:'20px'}}>
              <FilledSearchTextField
                label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                handleChangeFilter={handleChangeFilter}
                handleClearFilter={handleClearFilter}
                searchValue={filterValue}
              />
              <ToggleButtonGroup
                value={filterReccuranceType}
                exclusive
                onChange={handleFilterReccuranceType}
                aria-label="text alignment"
              >
                <ToggleButton value="all" aria-label="left aligned">
                  {f('FUTURE_MODAL_TOGGLE_ALL')}
                </ToggleButton>
                <ToggleButton value="ordinary" aria-label="centered">
                  {f('FUTURE_MODAL_TOGGLE_ORDINARY')}
                </ToggleButton>
                <ToggleButton value="temporary" aria-label="centered">
                  {f('FUTURE_MODAL_TOGGLE_TEMPORARY')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box sx={{ height: '300px', overflowY: 'auto',position:'relative' }}>
              {filteredActivities && (filteredActivities || []).length > 0 ?
                filteredActivities.map((item: FuturePlannedActivityItem, i) => {
                  return (
                    <InfoFuturePlannedContainer
                      key={i}
                      activityFullName={item.activityFullName as (string | undefined)}
                      plannedStartTime={item.plannedStartTime as Date}
                      routeName={item.routeName as (string | undefined)}
                      routeColor={item.routeColor as (string | undefined)}
                      useParticipants={item.useParticipantList}
                    />
                  )
                })
                :
                <Typography variant="body1" sx={{ fontWeight: 500,position: 'absolute', top:'50%', transform: 'translate(0%, -50%)',width:'100%',textAlign:'center'}}>
                    {f('FUTURE_MODAL_HEADER_NO_RESULT_TITLE')}
                </Typography>
              }
            </Box>
          </ModalBody>
        </ModalBox>
      )}
    </CenterModal>
  )
}

export const Typography70 = styled(Typography)`
  opacity: 0.7;
  text-align: center;
`

export const UserSquareBox = styled.div`
  width: 154px;
  height: 154px;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  background-color: #e0efff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px;
  cursor: pointer;
`

const ModalBody = styled(Box)`
  > div:first-child {
    > div:first-child {
      margin-right: 20px;
    }
  }
  ${(props)=> [props.theme.breakpoints.down('md')]}{
    > div:first-child {
      flex-direction: column;
      > div:first-child {
        padding-bottom: 20px;
      }
    }

  }
`

export default FutureModalContainer
