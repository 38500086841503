import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material'
import ColorPicker from 'components/inputs/color-picker'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalSectionRow from 'components/modal-section-row'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'

type Model = Readonly<{ name: string; color: string }>

const useForm = (initialValues: Model) => {
  const f = useFormatMessage()
  const {
    manageCombinedRoutes: { setAddModalOpen },
  } = PlanningPageContext.useActions()

  const onSubmit: OnSubmitFunction<Model> = (values, { setSubmitting }) => {
    setSubmitting(false)
    setAddModalOpen(false)
  }

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            30,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_NAME_LABEL'),
              maxsize: 30,
            })
          ),
        color: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            7,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLORPICKER_LABEL'),
              maxsize: 7,
            })
          ),
      }),
    [f]
  )

  const name: Name<Model> = {
    name: 'name',
    color: 'color',
  }

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

type AddCombinedRouteModalContainerProps = Readonly<{
  onSaveClick: (name: string, color: string) => void
  initialValues?: Model
}>

export const AddCombinedRouteModalContainer: React.SFC<AddCombinedRouteModalContainerProps> = ({ onSaveClick, initialValues = { name: '', color: '' } }) => {
  const f = useFormatMessage()
  const theme = useTheme()
  const {
    manageCombinedRoutes: { setAddModalOpen },
  } = PlanningPageContext.useActions()
  const {
    manageCombinedRoutes: { addModalOpen },
  } = PlanningPageContext.useState()

  const { name, onSubmit, ...formData } = useForm(initialValues)

  const handleSubmit: OnSubmitFunction<Model> = (values, formikHelpers) => {
    const { name, color } = values
    onSaveClick(name, color)
    onSubmit(values, formikHelpers)
  }

  const handleClose = () => setAddModalOpen(false)
  return (
    <Dialog open={addModalOpen} onClose={handleClose} PaperProps={{ style: { position: 'static' } }}>
      <Formik {...formData} onSubmit={handleSubmit}>
        {({ isSubmitting, submitForm, values }) => {
          return (
            <Form translate="yes">
              <DialogTitle>{f('PLANNING_PAGE_DEVICE_PLANNING_ADD_COMBINED_ROUTE_TITLE')}</DialogTitle>
              <DialogContent>
                <DialogContentText>{f('PLANNING_PAGE_DEVICE_PLANNING_ADD_COMBINED_ROUTE_SET_COLOR')}</DialogContentText>
                <ModalSectionRow>
                  <FilledTextField label={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_NAME_LABEL')} name={name.name} />
                </ModalSectionRow>
                <Box pt={2} />
                <ModalSectionRow>
                  <ColorPicker name={name.color} />
                </ModalSectionRow>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </Button>
                <Box color={theme.palette.error.main}>
                  <Button color="secondary" onClick={submitForm} disabled={isSubmitting} autoFocus={true}>
                    {f('BASIC_BUTTON_ADD_TEXT')}
                  </Button>
                </Box>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
