import React from 'react'
import { Typography } from '@mui/material'
import { CheckCircle } from 'mdi-material-ui'
import {
  FulfilledDelegationItem,
} from 'api/tillit.api-client'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'

const DelegationsFulfillment: React.FC<{delegationsFulfillment: FulfilledDelegationItem[]}> = ({delegationsFulfillment}) => {
  const f = useFormatMessage()

  return (
    <ModalSection
      title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELEGATIONSECTION_TITLE')}
      subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELEGATIONSECTION_SUBTITLE')}
      responsive
    >
      {delegationsFulfillment &&
        delegationsFulfillment.map((delegation: FulfilledDelegationItem) => {
          return (
            <ModalSectionRow key={delegation.id} responsiveRow>
              <CheckCircle color={delegation.isFulfilled ? "success" : "error"} />
              <Typography variant="body1">{delegation.title}</Typography>
            </ModalSectionRow>
          )
        })}
    </ModalSection>
  )
}

export default React.memo(DelegationsFulfillment)
