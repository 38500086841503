import React from 'react'
import {
  IRecentPerformedSigningItem,
  OrganizationSigningStatusesItem
} from 'api/tillit.api-client'
import { InfoRecentContainer } from 'components/info-recent/info-recent-container'
import ModalSectionRow from 'components/modal-section-row'
import ModalSection from 'components/modal-section'
import { useFormatMessage } from 'localization'

const RecentlyPerfomedSignings: React.FC<{recentlyPerformedSignings : IRecentPerformedSigningItem[], signingStatuses: OrganizationSigningStatusesItem | undefined}> = ({recentlyPerformedSignings, signingStatuses }) => {
  const f = useFormatMessage()

  return (
    <ModalSection
      title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECENTSECTION_TITLE')}
      subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECENTSECTION_SUBTITLE')}
      responsive
    >
      <ModalSectionRow responsive>
        <div style={{ maxHeight: '18vh', overflowY: 'scroll', paddingRight: '5px' }}>
          {recentlyPerformedSignings.map((signing: IRecentPerformedSigningItem) => {
            return (
              <ModalSectionRow key={signing.plannedActivityRowSigningId}>
                <InfoRecentContainer
                  signedComment={signing.comment || ''}
                  signedDate={signing.performedOn}
                  signedUserFullname={signing.performedByFullName || ''}
                  status={signing.plannedActivityStatusId}
                  activityFullName={signing.activityFullName || ''}
                  signingStatuses={signingStatuses}
                  useParticipantList={signing.useParticipantList}
                />
              </ModalSectionRow>
            )
            })}
        </div>
      </ModalSectionRow>
    </ModalSection>
  )
}

export default React.memo(RecentlyPerfomedSignings)
