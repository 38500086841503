import { Box, Typography } from '@mui/material'
import { PlannedActivityStatusEnum, OrganizationSigningStatusesItem } from 'api/tillit.api-client'
import { signingStatusHandleDiff, getStatusColor } from 'utilities/signing-status-helpers'
import { useFormatMessage } from 'localization'
import React from 'react'
import format from 'utilities/format'
import { AccountMultiple as ParticipantIcon } from 'mdi-material-ui'

export type InfoRecentComponentProps = Readonly<{
  status: PlannedActivityStatusEnum
  signedDate: Date
  signedUserFullname: string
  signedComment: string
  className?: string
  activityFullName: string
  signingStatuses: OrganizationSigningStatusesItem | undefined
  declined?: boolean
  useParticipantList?: boolean
}>

export const InfoRecentView: React.FC<InfoRecentComponentProps> = ({ signedDate, signedUserFullname, signedComment, className, status, activityFullName, signingStatuses, declined, useParticipantList }) => {
  const f = useFormatMessage()

  let color = getStatusColor(status).color
  if (declined) {
    color = 'gray'
  }
  const title = signingStatusHandleDiff(f, signingStatuses, status).signingStatusLable
  const by = f('SINGLE_ACTIVITY_MODAL_BY')

  return (
    <Box className={className} py={1} px={1}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          {useParticipantList && (
            <ParticipantIcon style={{ width: '16px' }} />
          )}
          <Box pr={1} />
          <Typography data-matomo-mask variant="subtitle1" style={{ color }}>
            {`${activityFullName} - ${title} ${by} ${signedUserFullname}`}
          </Typography>
          {declined && (
            <Typography variant="subtitle1" color="error">
              &nbsp;{f('ACTIVITY_PARTICIPANT_DECLINED')}
            </Typography>
          )}
        </Box>
        <Typography variant="subtitle1" style={{ opacity: 0.6 }}>
          {format(signedDate, 'PPP p')}
        </Typography>
      </Box>
      {signedComment && <Typography data-matomo-mask className="textGray">{signedComment}</Typography>}
    </Box>
  )
}
