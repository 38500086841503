import { PropTypes } from '@mui/material'
import { Palette } from '@mui/material/styles'
import styled, { css } from 'styled-components'
import DateTimePickerContainer from './date-time-picker-container'

const getColor = (palette: Palette, color?: PropTypes.Color): string | undefined => (color && palette.hasOwnProperty(color) ? palette[color].main : undefined)

const DateTimePickerStyle = styled(DateTimePickerContainer)(props => {
  const color = getColor(props.theme.palette, props.color)
  return color
    ? css`
        & label.Mui-focused {
          color: ${color};
        }

        .MuiFilledInput-root {
          &:hover fieldset {
            border-color: ${color};
          }

          &.Mui-focused fieldset {
            border-color: ${color};
          }
        }

        .MuiFilledInput-underline:after {
          border-bottom-color: ${color};
        }
      `
    : ''
})

export default DateTimePickerStyle
