import { IconButton, ListItem, ListItemText } from '@mui/material'
import styled from 'styled-components'

export const StyledListItemText = styled(ListItemText)`
  p {
    text-transform: lowercase;
  }
  p::first-letter {
    text-transform: uppercase;
  }
`

export const StyledListItem = styled(ListItem)`
  border: 1px solid ${props => props.theme.palette.grey['300']};
  border-radius: 5px;
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};
  padding-bottom: ${props => props.theme.spacing(1)};
  padding-bottom: ${props => props.theme.spacing(1)};

  & + .secondary-action {
    display: none;
    :hover {
      display: block;
    }
  }

  &:hover {
    background-color: ${props => props.theme.palette.secondary.main}22;

    & + .secondary-action {
      display: block;
    }
  }
`

export const StyledDeleteIconButton = styled(IconButton)`
  color: ${props => props.theme.palette.error.main};
`
