import { Box, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import CenterModal from 'components/center-modal'
import TextButton from 'components/inputs/text-button'
import MuiModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import { DailyActivitiesContext } from 'pages/daily-activities/daily-activities-context'
import React from 'react'
import styled, { css } from 'styled-components'

const Circle = styled.div<{ active?: boolean }>(
  ({ active,theme }) => css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${active ? '#147EFB' : '#cbcbcb'};
    ${active ? 'background-color: #147EFB;' : ''};
    ${theme.breakpoints.down('md')}{
      width: 16px;
      height: 16px;
    }
  `
)

const ModalHeader = styled(MuiModalHeader)`
  text-align: center;
`
const ModalBox = styled(Box)`
  padding: 1vh 3vw 1vh 3vw;
  background-color: #f9f9f9;
  ${({theme}) => theme.breakpoints.down('md')}{
    padding: 5px;
  }
`

const KeyPad: React.FC<{handlePinClicked: (pinDigit: number) => () => void}> = ({handlePinClicked}) => {
  return React.useMemo(() => {
    return (
      <>
        <Box display={'flex'} flexDirection={'row'}>
          <PinButton onClick={handlePinClicked(1)}>
            <Typography variant={'h4'}>{1}</Typography>
          </PinButton>
          <PinButton onClick={handlePinClicked(2)}>
            <Typography variant={'h4'}>{2}</Typography>
          </PinButton>
          <PinButton onClick={handlePinClicked(3)}>
            <Typography variant={'h4'}>{3}</Typography>
          </PinButton>
        </Box>
        <Box display={'flex'} flexDirection={'row'}>
          <PinButton onClick={handlePinClicked(4)}>
            <Typography variant={'h4'}>{4}</Typography>
          </PinButton>
          <PinButton onClick={handlePinClicked(5)}>
            <Typography variant={'h4'}>{5}</Typography>
          </PinButton>
          <PinButton onClick={handlePinClicked(6)}>
            <Typography variant={'h4'}>{6}</Typography>
          </PinButton>
        </Box>
        <Box display={'flex'} flexDirection={'row'}>
          <PinButton onClick={handlePinClicked(7)}>
            <Typography variant={'h4'}>{7}</Typography>
          </PinButton>
          <PinButton onClick={handlePinClicked(8)}>
            <Typography variant={'h4'}>{8}</Typography>
          </PinButton>
          <PinButton onClick={handlePinClicked(9)}>
            <Typography variant={'h4'}>{9}</Typography>
          </PinButton>
        </Box>
      </>
    )
  },[])
}

const PinAsteriskContainer = styled(Box)`
  width:238px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  padding:${({theme}) => `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`};
  ${({theme}) => theme.breakpoints.down('md')}{
    width: 180px
  }
`

const PinAsterisk: React.FC<{ pin: number[] }> = React.memo(({ pin }) => {
  return (
    <PinAsteriskContainer>
      {Array.from({ length: 4 }, (_, i) => (
        <Circle key={i} active={pin.length > i} />
      ))}
    </PinAsteriskContainer>
  )
})

PinAsterisk.displayName = 'PinAsterisk'

const PinModalContainer: React.FC = () => {
  const f = useFormatMessage()
  const { pinModal: { fetchState: { isLoading }, pin, isOpen, pinError } } = DailyActivitiesContext.useState()
  const { pinModal: { onPinClick, onPinEraseClick, close } } = DailyActivitiesContext.useActions()

  const handlePinClicked = React.useCallback(
    (pinDigit: number) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      onPinClick(pinDigit)
    },
    []
  )

  return (
    <CenterModal open={isOpen} onClose={close} responsive>
      <ModalBox>
        <ModalHeader title={f('DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_HEADER_TITLE')} responsive />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box>
            {pinError && (
              <Typography variant="subtitle1" color="error">
                {pinError}
              </Typography>
            )}
            {isLoading && <CircularProgress color="primary" />}
          </Box>
          <PinAsterisk  pin={pin}/>
          <KeyPad handlePinClicked={handlePinClicked} />
          <Box display={'flex'} flexDirection={'row'}>
            <ActionButton onClick={close}>{f('BASIC_BUTTON_CANCEL_TEXT')}</ActionButton>
            <PinButton onClick={handlePinClicked(0)}>
              <Typography variant={'h4'}>{0}</Typography>
            </PinButton>
            <ActionButton color="error" onClick={onPinEraseClick} disabled={pin.length === 0}>
              {f('DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_FOOTER_DELETE_BUTTON_TEXT')}
            </ActionButton>
          </Box>
        </Box>
      </ModalBox>
    </CenterModal>
  )
}

const ActionButton = styled(TextButton)`
  margin: 12px;
  width: 88px;
  height: 88px;

  > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${props => props.theme.breakpoints.down('md')}{
    min-width: 44px;
    width: 44px;
    height: 44px;
    margin: 8px;
    >* {
      font-size: 1rem;
      font-weight: 500;
    }
  }
`

const PinButton = styled(ActionButton)`
  color: #147efb;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  background-color: white;

  &:hover {
    color: white;
    background-color: #147efb;
  }
`

export default PinModalContainer
