import React from 'react'
import { Typography } from '@mui/material'
import { PlannedActivityParticipantItem, PlannedActivitySignedParticipantItem, ActivityParticipantItem } from 'api/tillit.api-client'

type Props = Readonly<{
  participants?: PlannedActivityParticipantItem[]
  signedParticipants?: PlannedActivitySignedParticipantItem[]
}>

interface ParticipantItem extends ActivityParticipantItem {
  declined?: boolean
}

const SortedParticipantsContainer: React.FC<Props> = ({ participants, signedParticipants }) => {

  const mapPlannedActivityParticipantItemsToParticipantItem = (participantItems: PlannedActivityParticipantItem[]): (ParticipantItem[]) => {
    return participantItems.map(p => {
      const pa: ParticipantItem = new ActivityParticipantItem({
        id: p.id,
        activityId: p.plannedActivityRowId,
        customerId: p.customerId,
        customerFullName: p.customerFullName
      })
      pa.declined = false
      return pa
    })
  }

  const mapSignedActivityParticipantItemsToParticipantItem = (participantItems: PlannedActivitySignedParticipantItem[]): (ParticipantItem[]) => {
    return participantItems.map(p => {
      const pa: ParticipantItem = new ActivityParticipantItem({
        id: p.id,
        activityId: -1,
        customerId: p.customerId,
        customerFullName: p.customerFullName
      })
      pa.declined = p.declined
      return pa
    })
  }

  const sortedParticipants = React.useMemo(() => {
    let participantsToSort: ParticipantItem[] = []

    if (participants) {
      participantsToSort = mapPlannedActivityParticipantItemsToParticipantItem(participants)
    }

    if (signedParticipants) {
      participantsToSort = mapSignedActivityParticipantItemsToParticipantItem(signedParticipants)
    }

    return participantsToSort.sort((a, b) => (a.customerFullName || '').localeCompare(b.customerFullName || ''))
  }, [participants, signedParticipants])

  return (
    <Typography data-matomo-mask variant="body1">
      {sortedParticipants.map((pa, index) => {
        return `${pa.customerFullName}${sortedParticipants.length && index < (sortedParticipants.length - 1) ? ', ' : ''}`
      })}
    </Typography>
  )
}

export default SortedParticipantsContainer
