import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  html,
  body,
  #app {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
  }
`

export default GlobalStyle

