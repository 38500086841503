import React from 'react'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import useKpiApi from 'api/kpi-api'
import useKpiWorkScheduleApi from 'api/kpi-workschedule-api'
import useKpiDelegationsApi from 'api/kpi-delegations-api'
import useKpiDrugListsApi from 'api/kpi-druglist-api'


const useDashboardKpiContext = () => {
  const { fetchAsync } = usePromiseApi()
  const { getCurrentAreaIds, currentUser } = DashboardPageContext.useState()
  const { getSigningStatusesKpi,
    getNumDelayedActivitiesKpi,
    getNumUnsignedActivitiesKpi,
    getPlannedActivityTimeKpi,
    getFutureUnplannedActivitiesKpi,
    getActivitiesAboutToEndKpi,
    abort } = useKpiApi()

  const { getEfficiencyRateKpi } = useKpiWorkScheduleApi()
  const { getDelegationsAboutToExpireKpi, getActivitiesSignedViolatingDelegationRequirementKpi } = useKpiDelegationsApi()
  const { getNumDrugActivitiesWhereGivenDeviatesFromExpectedKpi } = useKpiDrugListsApi()

  const getKpiData = React.useCallback((kpiId: number) => {
    if (currentUser?.organizationId) {
      switch (kpiId) {
        case 512:
          return fetchAsync(getSigningStatusesKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 515:
          return fetchAsync(getNumDelayedActivitiesKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 516:
          return fetchAsync(getNumUnsignedActivitiesKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 517:
          return fetchAsync(getPlannedActivityTimeKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 520:
          return fetchAsync(getFutureUnplannedActivitiesKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 523:
          return fetchAsync(getEfficiencyRateKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 506:
          return fetchAsync(getDelegationsAboutToExpireKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 677:
          return fetchAsync(getActivitiesAboutToEndKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 514:
          return fetchAsync(getNumDrugActivitiesWhereGivenDeviatesFromExpectedKpi(currentUser.organizationId, getCurrentAreaIds()))
        case 720:
          return fetchAsync(getActivitiesSignedViolatingDelegationRequirementKpi(currentUser.organizationId, getCurrentAreaIds()))

        default:
          return fetchAsync(Promise.reject())
      }
    }
    return fetchAsync(Promise.reject())
  }, [currentUser])

  React.useEffect(() => () => abort(), [])
  return {
    state: {
    },
    actions: {
      getKpiData
    },
  }
}

export default createContext(useDashboardKpiContext)
