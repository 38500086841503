import HTMLContent from 'components/html-content'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import React from 'react'
import { HangfireModalContext } from './hangfire-modal-context'

import { Box } from '@mui/material'
import { useFormatMessage } from 'localization'

export const HangfireModalView = () => {
  const f = useFormatMessage()

  const { toggleModalHangfire } = ProfilePageContext.useActions()

  const handleClose = React.useCallback(() => toggleModalHangfire(false), [toggleModalHangfire])

  const { hangfireContent } = HangfireModalContext.useState()

  return (
    <>
      <Box overflow="auto">
        <HTMLContent content={hangfireContent} />
      </Box>
      <ModalFooter>
        <TextButton onClick={handleClose}>{f('BASIC_BUTTON_CLOSE_TEXT')}</TextButton>
      </ModalFooter>
    </>
  )
}
