import { useTheme } from '@mui/material'
import { AreaListItem } from 'api/tillit.api-client'
import React from 'react'
import { SteppedLineTo } from 'react-lineto'
import styled from 'styled-components'
import { OrganizationContext } from '../../organization-context'
import Sidebar from '../sidebar'
import OrganizationArea from './components/organization-area'
import OrganizationLevel from './components/organization-level'

const Line: React.FC<{ from: string; to: string }> = React.memo(({ from, to }) => {
  const theme = useTheme()
  return (
    <SteppedLineTo
      from={from}
      fromAnchor={'center right'}
      to={to}
      toAnchor="center left"
      borderColor={theme.palette.secondary.main}
      orientation="h"
      within="organization-wrapper"
    />
  )
})

type Props = Readonly<{ className?: string }>

const OrganizationTreeContainer: React.FC<Props> = ({ className }) => {
  const {
    organization,
    isLoading: isLoadingOrganization,
    levels,
    sidebar: { selectedAreaOrgTreeItem, isOpen: sidebarIsOpen },
  } = OrganizationContext.useState()
  const {
    onAreaListItemClick,
    onAddSiblingClick,
    onAddSiblingToRootClick,
    sidebar: { close },
  } = OrganizationContext.useActions()

  const activeRootId = levels.length > 0 ? levels[0].areaListItem.id : null
  const selectedId = selectedAreaOrgTreeItem && sidebarIsOpen ? selectedAreaOrgTreeItem.id : null

  const handleAreaListItemClick = React.useCallback(
    (areaListItem: AreaListItem) => (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation()
      onAreaListItemClick(areaListItem)
    },
    [onAreaListItemClick]
  )

  const handleAddSiblingClick = React.useCallback(
    (levelId: number) => (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation()
      onAddSiblingClick(levelId)
    },
    [onAddSiblingClick]
  )
  const handleAddSiblingToRootClick = React.useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation()
      onAddSiblingToRootClick()
    },
    [onAddSiblingToRootClick]
  )
  const handleOrganizationClick = React.useCallback(() => {
    close()
  }, [close])

  /*
  const spinnerOptions = {
    color: theme.palette.secondary.main,
    size: 32,
    css: css`
      top: 200px;
    `,
  }
*/
  return (
    <div className={className}>
      <OrganizationWrapper onClick={handleOrganizationClick} className="organization-wrapper">
        {/* Render root level (index -1)*/}
        <OrganizationLevel
          // showSpinner={isLoadingOrganization}
          // options={spinnerOptions}
          isLoading={isLoadingOrganization}
          onAddSiblingClick={handleAddSiblingToRootClick}
          className="level--1"
          name={(organization && organization.name) || ''}>
          {((organization && organization.areas) || []).map((areaListItem, areaListItemIndex) => {
            const active = activeRootId !== null && activeRootId === areaListItem.id
            const disabled = !active && activeRootId !== null
            return (
              <OrganizationArea
                key={areaListItemIndex}
                childCount={areaListItem.areaOrRouteCount}
                routeCount={areaListItem.routeCount}
                className={`level-${-1}-node-${areaListItem.id}`}
                active={active}
                selected={selectedId != null && areaListItem.id === selectedId}
                disabled={disabled}
                name={areaListItem.name}
                onClick={handleAreaListItemClick(areaListItem)}
              />
            )
          })}
        </OrganizationLevel>
        {/* Render all levels */}
        {levels.map((level, levelIndex) => {
          const { areaListItem, childrenOrRoutes } = level
          const { name: levelName, id: levelId } = areaListItem

          /* Set this lists active childs id if any, else null*/
          const activeChildId = levelIndex + 1 < levels.length ? levels[levelIndex + 1].areaListItem.id : null
          const isLoading = childrenOrRoutes === null

          // TODO Remove when bug #787 is complete
          const hasAreas = childrenOrRoutes && childrenOrRoutes.childAreas && childrenOrRoutes.childAreas.length > 0
          return (
            hasAreas && (
              <OrganizationLevel
                name={levelName}
                className={`level-${levelIndex}`}
                key={levelIndex}
                onAddSiblingClick={level.childrenOrRoutes !== null && level.childrenOrRoutes.routes === undefined ? undefined : handleAddSiblingClick(levelId)}
                // showSpinner={isLoading}
                isLoading={isLoading}
                // options={spinnerOptions}
                >
                {/* Render all child areas in level*/}
                {((childrenOrRoutes && childrenOrRoutes.childAreas) || []).map((childArea, childAreaIndex) => {
                  const active = activeChildId !== null && activeChildId === childArea.id
                  const disabled = !active && activeChildId !== null
                  // TODO: Remove when tbug #787 is implemented
                  const routesCount = childrenOrRoutes && childrenOrRoutes.routes ? childrenOrRoutes.routes.length : 0
                  return (
                    <React.Fragment key={childAreaIndex}>
                      <OrganizationArea
                        childCount={childArea.areaOrRouteCount - routesCount}
                        routeCount={childArea.routeCount}
                        className={`level-${levelIndex}-node-${childArea.id}`}
                        active={active}
                        selected={selectedId != null && childArea.id === selectedId}
                        disabled={disabled}
                        name={childArea.name}
                        onClick={handleAreaListItemClick(childArea)}
                      />

                      {active && <Line from={`level-${levelIndex - 1}-node-${levelId}`} to={`level-${levelIndex}-node-${childArea.id}`} />}
                    </React.Fragment>
                  )
                })}
              </OrganizationLevel>
            )
          )
        })}
      </OrganizationWrapper>
      <Sidebar />
    </div>
  )
}

const OrganizationWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: auto;
`

export default OrganizationTreeContainer
