import { STORAGEPREFIX } from 'config/variables'

interface RememberDataType {
  email: string
  organization: string
}

export const getRememberData = (): RememberDataType => {
  const items = localStorage.getItem(`${STORAGEPREFIX || ''}-remember-me`)
  return ( items ) ? JSON.parse(items) : {}
}

export const setRememberMe = ( email: string ) : void => {
  const oldData = getRememberData()
  const data: RememberDataType = {
    email,
    organization: ''
  }
  if (oldData) {
    data.organization = oldData.organization
  }

  localStorage.setItem(`${STORAGEPREFIX || ''}-remember-me`, JSON.stringify(data))
}

export const setRememberOrganization = ( organization: string ) : void => {
  const oldData = getRememberData()
  const data: RememberDataType = {
    email: '',
    organization
  }
  if (oldData) {
    data.email = oldData.email
  }

  localStorage.setItem(`${STORAGEPREFIX || ''}-remember-me`, JSON.stringify(data))
}

export const removeRememberMeData = ( ) : void => {
  localStorage.removeItem(`${STORAGEPREFIX || ''}-remember-me`)
}
