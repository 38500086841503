import React from 'react'

import {
  UserImpersonationRequestClient,
  UserImpersonationRequestItem,
  UserImpersonationRequestCreateItem,
  IUserImpersonationRequestCreateItem,
  UserImpersonationRequestListItem,
} from 'api/tenilo.support.api-client'
import { SUPPORT_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import { clearTokenData, getTokenData, hasTokenData, setTokenData, setSupportTokenData } from './token-data'


export class SupportUserImpersonationRequestsApi {
  private readonly abortController = new AbortController()
  private readonly client: UserImpersonationRequestClient

  public constructor() {
    this.client = new UserImpersonationRequestClient(SUPPORT_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getUserImpersonationRequest = (id: number): Promise<UserImpersonationRequestItem> =>
    this.client
      .getUserImpersonationRequest(id)
      .then(item =>
        item === null
          ? Promise.reject(`No UserImpersonationRequest item received from backend for id ${id}.`)
          : Promise.resolve(item)
      )

  public listUserImpersonationRequests = (): Promise<UserImpersonationRequestListItem[]> =>
    this.client
      .listUserImpersonationRequests()
      .then(items =>
        items === null
          ? Promise.reject(`No UserImpersonationRequest items received from backend.`)
          : Promise.resolve(items)
      )

  public abandonUserImpersonationRequest = (id: number) => {
    return this.client
      .abandonUserImpersonationRequest(id)
      .then(item =>
        item === null ? Promise.reject(`When abandoning an UserImpersonationRequest, no item received from backend.`) : Promise.resolve(item)
      )
  }

  public createUserImpersonationRequest = (userImpersonationRequest: IUserImpersonationRequestCreateItem) => {
    const model = new UserImpersonationRequestCreateItem(userImpersonationRequest)
    return this.client
      .createUserImpersonationRequest(model)
      .then(item =>
        item === null ? Promise.reject(`When creating an UserImpersonationRequest, no item received from backend.`) : Promise.resolve(item)
      )
  }

  public deleteUserImpersonationRequest = (id: number) => {
    return this.client
      .deleteUserImpersonationRequest(id)
      .then(() => Promise.resolve())
  }

  public getActiveUserImpersonationRequest = (userId: number): Promise<UserImpersonationRequestItem | undefined> =>
    this.client
      .getActiveUserImpersonationRequest(userId)
      .then(item => Promise.resolve(item))

  public generateJwtTokenForUserImpersonationRequest = (requestId: number): Promise<void> => {
    return this.client.generateJwtTokenForUserImpersonationRequest(requestId).then(resultItem => {
      if (resultItem === null) {
        return Promise.reject('No jwt token received from backend.')
      }

      var supportUserToken = getTokenData()
      if (supportUserToken) {
        setSupportTokenData(supportUserToken)

        setTokenData(resultItem)
      }

      return Promise.resolve()
    })
  }
}

const useSupportUserImpersonationRequestsApi = () => React.useMemo(() => new SupportUserImpersonationRequestsApi(), [])

export default useSupportUserImpersonationRequestsApi
