import React from 'react'
import CustomerModal from 'components/customer-modal'
import CustomerCreateModal from './components/customer-create-modal'
import { CustomersPageContext } from './customers-page-context'
import CustomersPageView from './customers-page-view'

const CustomersPageContainer = () => {

  return (
    <CustomersPageContext.Provider>
      <CustomersPageView />
      <CustomerCreateModal />
      <CustomerModal />
    </CustomersPageContext.Provider>
  )
}

export default CustomersPageContainer
