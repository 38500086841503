import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { ReportFieldItem } from 'api/tenilo.reporting.api-client'
import { Box, Typography, Icon, Switch } from '@mui/material'
import { useFormikContext, ArrayHelpers } from 'formik'
import type { ReportsPageFormType } from 'pages/reports/config'
import Colors from 'styles/colors'
import color from 'color'
import { Drag } from 'mdi-material-ui'

interface ReportsPageFieldViewProps {
  fieldItem: ReportFieldItem
  helper: ArrayHelpers
  dragItem: React.MutableRefObject<number | undefined>
  dragEnterItem: React.MutableRefObject<number | undefined>
  index: number
}

const ReportsPageFieldView: FC<ReportsPageFieldViewProps> = ({ fieldItem, helper, dragItem, dragEnterItem, index }) => {
  const f = useFormatMessage()
  const { values } = useFormikContext<ReportsPageFormType>()


  const handleCheckFieldItem = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      helper.push(fieldItem)
    } else {
      if (values.fields) {
        const i = values.fields.findIndex((field) => field.id === fieldItem.id)
        if (i >= 0) { helper.remove(i) }
      }
    }
  }, [values.fields])

  const handleDragStart = React.useCallback(() => {
    dragItem.current = index
  }, [])

  const handleDragEnter = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
    const elements = document.querySelectorAll('#report-field-enter')
    elements.forEach(element => element.removeAttribute('id'))
    dragEnterItem.current = index
    e.currentTarget.id = "report-field-enter"
  }, [])

  return React.useMemo(() => {
    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '20px',
        marginBottom: '10px',
        backgroundColor: Colors.AthensGray,
        padding: '0px 10px 0px 5px',
        boxSizing: 'border-box',
        borderRadius: '5px',
        transition: 'background-color 0.5s ease',
        '&:hover': {
          backgroundColor: color(Colors.AthensGray).darken(0.1).toString()
        },
        '&#report-field-enter': {
          backgroundColor: color(Colors.AthensGray).darken(0.1).toString()
        }
      }} onDragEnter={handleDragEnter}>
        <Box sx={{
          display: 'flex',
          cursor: 'pointer'
        }} draggable onDragStart={handleDragStart} >
          <Icon fontSize="small" component={Drag} />
          {fieldItem.headerName && (
            <Typography variant="body2">{f(fieldItem.headerName)}</Typography>
          )}
        </Box>
        <Switch size="small" checked={!!values.fields?.some(val => val.id === fieldItem.id)} onChange={handleCheckFieldItem} />
      </Box>
    )
  }, [values.fields,fieldItem])
}

export default ReportsPageFieldView
