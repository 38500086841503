import { IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import {
  AccountRemove,
} from 'mdi-material-ui'
import { CustomerListItem, CustomerAreaItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { useFormatMessage } from 'localization'
import React, { FC } from 'react'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterCustomers } from 'utilities/customer-helper'
import { CustomersPageContext } from './customers-page-context'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  'customers-container': {
    padding: '2vh 2vh 0 2vh'
  },
});

const CustomersInactiveView: FC = () => {
  const classes = useStyles();
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { inactiveCustomers, removeDialogOpen, customerToRemove } = CustomersPageContext.useState()
  const { openRemoveDialog, closeRemoveDialog, remove } = CustomersPageContext.useActions()
  const f = useFormatMessage()
  const getFilteredCustomers = filterCustomers(f)

  const handleRemoveCustomerClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, customer: CustomerListItem) => {
      _?.stopPropagation()
      openRemoveDialog(customer)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveCustomerClick = React.useCallback(() => remove(customerToRemove!.id), [customerToRemove, remove])

  let customers: CustomerListItem[] = []
  if (inactiveCustomers) {
    customers = inactiveCustomers
  }

  const customerAreasLookup: any[] = []
  customers?.forEach(customer => {
    customer.customerAreas?.forEach(customerArea => {
      if (!customerAreasLookup[customerArea.areaId]) {
        customerAreasLookup[customerArea.areaId] = customerArea.areaName
      }
    })
  })

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredCustomers, setFilteredCustomers] = React.useState<CustomerListItem[]>(customers)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredCustomers(filterValue, customers)
      setFilteredCustomers(filtered)
    } else {
      setFilteredCustomers(customers)
    }
    // NOTE: We are missing filterValue and getFilteredCustomers as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [customers])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredCustomers(searchValue, customers)
      setFilteredCustomers(filtered)
    },
    [setFilterValue, setFilteredCustomers, customers, getFilteredCustomers]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredCustomers(customers)
    },
    [setFilterValue, setFilteredCustomers, customers]
  )


  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div className={classes['customers-container']}>

      {currentUser.organizationId && (
        <div style={{ columnCount: 2 }}>
          <div>&nbsp;</div>
          <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
            <FilledSearchTextField
              label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
              handleChangeFilter={handleChangeFilter}
              handleClearFilter={handleClearFilter}
              searchValue={filterValue}
            />
          </div>
        </div>
      )}

      <DataGrid
        disableColumnFilter
        sx={{
          minHeight: '70vh',
          maxHeight: '70vh',
          '.MuiDataGrid-cell': { borderBottom: '0px' }
        }}
        rows={filteredCustomers}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        localeText={muiDataGridLocale(f)()}

        columns={[
          {
            flex: 1,
            headerName: f('CUSTOMERS_PAGE_VIEW_COLUMN_FIRSTNAME'),
            field: 'firstName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.firstName}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('CUSTOMERS_PAGE_VIEW_COLUMN_LASTNAME'),
            field: 'lastName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.lastName}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('CUSTOMERS_PAGE_VIEW_COLUMN_ROOMNUMBER'),
            field: 'roomNumber',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.roomNumber}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('CUSTOMERS_PAGE_VIEW_COLUMN_AREAS_TITLE'),
            field: 'customerAreas',
            valueGetter: (params: GridValueGetterParams) => {
              const customerAreas = params.value as CustomerAreaItem[]
              return !!customerAreas && customerAreas.map(ca => ca.organizationParentsName ? `${ca.areaName} (${ca.organizationParentsName})` : ca.areaName).join(', ')
            },
          },
          {
            flex: 1,
            headerName: '',
            field: '',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span>
                  <Tooltip
                    data-matomo-mask
                    title={f('CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_BUTTON_TOOLTIP', {
                      fullName: params.row.fullName,
                    })}
                    aria-label={f('CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_BUTTON_TOOLTIP', {
                      fullName: params.row.fullName,
                    })}>
                    <IconButton onClick={e => handleRemoveCustomerClick(e, params.row)} size="large">
                      <AccountRemove color={'secondary'} />
                    </IconButton>
                  </Tooltip>
                </span>
              )
            },
          },
        ]}

        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[25, 50, 100]}
        pagination

      />

      {
        removeDialogOpen && customerToRemove !== null && (
          <DeleteDialog
            dialogTitle={f('CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_TITLE')}
            dialogContentText={f('CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_MESSAGE', {
              fullName: customerToRemove.fullName,
            })}
            buttonSubmitText={f('CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_ACTIONS_REMOVE')}
            buttonCancelText={f('CUSTOMERS_PAGE_VIEW_INACTIVE_DELETE_DIALOG_ACTIONS_CANCEL')}
            deleteDialogOpen={true}
            onClose={closeRemoveDialog}
            onSubmit={handleConfirmRemoveCustomerClick}
          />
        )
      }
    </div>
  )
}

export default CustomersInactiveView
