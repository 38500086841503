import { Box } from '@mui/material'
import React from 'react'
import { Tab, Tabs as MuiTabs } from '@mui/material'
import Typography from '@mui/material/Typography'
import styled from 'styled-components/macro'
import { UserTypeEnum } from 'api/tillit.api-client'
import { Authorize } from 'components/authorization'
import { useFormatMessage } from 'localization'
import { AdministerOrganizationDelegationsContext } from './components/administer-organization-delegations/administer-organization-delegations-context'
import AdministerOrganizationDelegations from './components/administer-organization-delegations/administer-organization-delegations-view'
import { DelegationModalContext } from './components/administer-organization-delegations/components/delegation-modal/delegation-modal-context'
import DelegationModal from './components/administer-organization-delegations/components/delegation-modal'
import { AdministerDelegationCategoriesContext } from './components/administer-delegation-categories/administer-delegation-categories-context'
import AdministerDelegationCategories from './components/administer-delegation-categories/administer-delegation-categories-view'
import { DelegationCategoryModalContext } from './components/administer-delegation-categories/components/delegation-category-modal/delegation-category-modal-context'
import DelegationCategoryModal from './components/administer-delegation-categories/components/delegation-category-modal'

const AdministerDelegationsView: React.FC = () => {
  const initialTabValue = 0
  const [delegationTabvalue, setDelegationTabValue] = React.useState(initialTabValue)
  const f = useFormatMessage()

  const handleDelegationTabChange = (event: any, newValue: React.SetStateAction<number>) => {
    setDelegationTabValue(newValue)
  }

  const Tabs = styled(MuiTabs)`
    box-shadow: inset 0 -2px 2px -2px gray;
    width: 100vw;
  `
  type ITabPanelProps = Readonly<{
    index: number
    value: number
  }>

  const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} id={`sub-tabpanel-${index}`} aria-labelledby={`sub-tab-${index}`} {...other}>
        <Box p={3}>{children}</Box>
      </Typography>
    )
  }

  const a11yProps = (index: number) => {
    return {
      id: `sub-tab-${index}`,
      'aria-controls': `sub-tabpanel-${index}`,
    }
  }

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Tabs indicatorColor="primary" textColor="primary" value={delegationTabvalue} onChange={handleDelegationTabChange} centered={true}>
          <Tab label={f('ADMINISTER_DELEGATIONS_VIEW_ORGANIZATION_LABEL')} {...a11yProps(0)} />
          <Authorize mainRoles={[UserTypeEnum.OrganizationAdmin, UserTypeEnum.TeniloSupport, UserTypeEnum.SystemAdmin]}>
            <Tab label={f('ADMINISTER_DELEGATIONS_VIEW_CATEGORY_LABEL')} {...a11yProps(1)} />
          </Authorize>
        </Tabs>

        <TabPanel value={delegationTabvalue} index={0}>
          {delegationTabvalue === 0 && (
            <AdministerOrganizationDelegationsContext.Provider>
              <DelegationModalContext.Provider>
                <AdministerOrganizationDelegations />
                <DelegationModal />
              </DelegationModalContext.Provider>
            </AdministerOrganizationDelegationsContext.Provider>
          )}
        </TabPanel>

        <TabPanel value={delegationTabvalue} index={1}>
          {delegationTabvalue === 1 && (
            <AdministerDelegationCategoriesContext.Provider>
              <DelegationCategoryModalContext.Provider>
                <AdministerDelegationCategories />
                <DelegationCategoryModal />
              </DelegationCategoryModalContext.Provider>
            </AdministerDelegationCategoriesContext.Provider>
          )}
        </TabPanel>

      </div>
    </div>
  )
}

export default AdministerDelegationsView
