import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import { OkDialog } from 'components/ok-dialog'
import { DeleteDialog } from 'components/delete-dialog'
import { useFormatMessage } from 'localization'
import React from 'react'
import { PlanningPageContext } from 'pages/planning/planning-page-context'

const DeleteRouteInvalidDialogContainer: React.FC<{
  dialogDeleteRouteInvalid: boolean
  setDialogDeleteRouteInvalid: (value: React.SetStateAction<boolean>) => void
  selectedRoute: IPlannedRoutesActivitiesPlanningViewItem | undefined
}> = ({
  dialogDeleteRouteInvalid,
  setDialogDeleteRouteInvalid,
  selectedRoute,
}) => {
    const f = useFormatMessage()

    const { removeRouteAsync } = PlanningPageContext.useActions()

    const handleRemoveRouteFromTomorrowsDate = React.useCallback(() => {
      if (selectedRoute) {
        removeRouteAsync(selectedRoute)
      }
      setDialogDeleteRouteInvalid(false)
    },
      [selectedRoute, removeRouteAsync])

    const handleClose = () => {
      setDialogDeleteRouteInvalid(false)
    }

    return (
      <>
        {dialogDeleteRouteInvalid && (
          <DeleteDialog
            dialogTitle={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_TITLE')}
            dialogContentText={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_MESSAGE', {
              routeName: selectedRoute?.name,
            })}
            buttonSubmitText={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_TITLE')}
            buttonCancelText={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_INVALID_DIALOG_CANCEL')}
            deleteDialogOpen={dialogDeleteRouteInvalid}
            onClose={handleClose}
            onSubmit={handleRemoveRouteFromTomorrowsDate}
          />
        )}
      </>
    )
  }

export default DeleteRouteInvalidDialogContainer
