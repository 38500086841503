import React from 'react'
import * as yup from 'yup'
import {
  CommonShiftInheritanceItem
} from 'api/tenilo.workschedule.api-client'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { WorkScheduleConnectionContext } from '../work-schedule-connection-context'

type workConnectionScheduleFormData = Readonly<{
  workScheduleCommonShiftId: number | null;
  areaId: number | null;
}>

const useWorkConnectionScheduleFormData  = ( shift?: CommonShiftInheritanceItem ) => {
  const f = useFormatMessage()
  const { currentUser } = WorkScheduleConnectionContext.useState()
  const { fetchListInheritedCommonShifts, handleCreateInheritedCommonShift, handleUpdateInheritedCommonShift, closeWorkScheduleModal } = WorkScheduleConnectionContext.useActions()

  const initialValues = React.useMemo<workConnectionScheduleFormData>(
    () => {
      return shift ?
        {
          workScheduleCommonShiftId: shift.workScheduleCommonShiftId,
          areaId: shift.areaId
        }
      :
        {
          workScheduleCommonShiftId: null,
          areaId: null
        }

    },
    [shift]
  )

  const onSubmit: OnSubmitFunction<workConnectionScheduleFormData> = React.useCallback(
    async (values, actions) => {
      if(!currentUser.organizationId){
        return
      }
      if(shift && shift.id && values.workScheduleCommonShiftId && values.areaId){
        const updateItem = {
          id: shift.id,
          workScheduleCommonShiftId: values.workScheduleCommonShiftId,
          areaId: values.areaId
        }
        return handleUpdateInheritedCommonShift(updateItem).then(fetchListInheritedCommonShifts).then(closeWorkScheduleModal)
      }
      if(values.workScheduleCommonShiftId && values.areaId){
        const createItem = {
          workScheduleCommonShiftId: values.workScheduleCommonShiftId,
          areaId: values.areaId
        }
        return handleCreateInheritedCommonShift(createItem).then(fetchListInheritedCommonShifts).then(closeWorkScheduleModal)
      }
    },
    [shift]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      workScheduleCommonShiftId: yup.number().nullable().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      areaId: yup.number().nullable().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
    })
  }, [f])

  const name: Name<workConnectionScheduleFormData> = React.useMemo(
    () => ({
      workScheduleCommonShiftId: 'workScheduleCommonShiftId',
      areaId: 'areaId',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useWorkConnectionScheduleFormData
