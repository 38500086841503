import React, { PropsWithChildren } from 'react'
import { PlanningGridContext } from './planning-grid-context'
import { SessionProps, TrackProps } from './planning-grid-types'
import { DragAndDropProvider } from './utilities/drag-and-drop/drag-and-drop'

type PlanningGridProvider<
  T extends TrackProps,
  S extends SessionProps
> = Readonly<{ readonly?: boolean }>

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, prefer-arrow/prefer-arrow-functions
export function PlanningGridProvider<
  T extends TrackProps,
  S extends SessionProps
>({
  children,
  readonly = false,
}: PropsWithChildren<PlanningGridProvider<T, S>>) {
  return (
    <DragAndDropProvider readonly={readonly}>
      <PlanningGridContext.Provider>{children}</PlanningGridContext.Provider>
    </DragAndDropProvider>
  )
}
