import React from 'react'
import { Typography, Box } from '@mui/material'
import { Colors } from 'styles/colors'
import { sumBy, round} from 'lodash'
import Color from 'color'
import { useFormatMessage } from 'localization'
import type { KeyPerformanceIndicatorItem, KeyPerformanceIndicatorDetailItem } from 'api/tenilo.dashboard.api-client'
import { KeyPerformanceIndicatorUnit} from 'api/tenilo.dashboard.api-client'


const DashboardKpiDetailView : React.FC<{
  kpiData:KeyPerformanceIndicatorItem,
  detailsRef?: React.MutableRefObject<null>,
  percent: boolean
  filteredDatasetByLegend?: number[]
}> = (props) => {
  const { kpiData,detailsRef, percent, filteredDatasetByLegend } = props
  const f = useFormatMessage()
  const columnsCountArray : number[] = [1]
  const [ columnsCount, setColumnsCount ] = React.useState<number>(0)

  const total = React.useMemo(()=> {
    const filteredKpiDataDetails = kpiData.details?.filter((item,i) => !filteredDatasetByLegend?.includes(i))
    return sumBy(filteredKpiDataDetails,(item) => item.value)
  },[filteredDatasetByLegend,kpiData])

  const formatHours = ( decimalHours:number ) => {
    let minutes = decimalHours % 1
    const hours = decimalHours - minutes
    minutes *= 60
    minutes = Math.round(minutes)
    let format = `${hours} ${f('DASHBOARD_PAGE_KPI_VALUE_UNIT_HOURS')} `
    format += minutes ? `${minutes} ${f('DASHBOARD_PAGE_KPI_VALUE_UNIT_MINUTES')}` : ''
    return format
  }

  const getValueFormat = React.useCallback((detail:KeyPerformanceIndicatorDetailItem,i:number)=>{
    const value =
      filteredDatasetByLegend?.includes(i) ?
      '' :
      percent ?
      `${round(((detail.value/total)*100), 1)} ${f('DASHBOARD_PAGE_KPI_VALUE_UNIT_PERCENTAGE')}` :
      (detail.valueUnit === KeyPerformanceIndicatorUnit.Hours) ?
      formatHours(detail.value) :
      `${detail.value} ${f(`DASHBOARD_PAGE_KPI_VALUE_UNIT_${detail.valueUnit.toUpperCase()}`)}`

    return value
  },[kpiData, percent, filteredDatasetByLegend])

  return (
    <Box ref={detailsRef} sx={{
      display:'grid',
      gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
      padding:'8px 10px',
      maxWidth:'900px',
      '> *': {
        padding: '5px',
        border:`1px solid ${Color(Colors.AthensGray).lighten(0.01).string()}`
      },
    }}>
      {kpiData.details?.map((detail,di)=>{

        const res1 = detail.valueTitle?.split(kpiData.separator).map((item,i) => <Typography data-matomo-mask key={di+i} variant="caption">{item}</Typography>)
        const res2 = !detail.suppressValueAndUnit ? <Typography data-matomo-mask key={di} variant="caption">{`${getValueFormat(detail,di)}`}</Typography> : undefined
        const r1l = res1 ? res1.length : 0
        const rtl = res2 ? r1l + 1 : r1l
        columnsCountArray.push(rtl)
        if((di + 1 === kpiData.details?.length) && !columnsCount){
          setColumnsCount(Math.max(...columnsCountArray))
        }
        return [res1,res2]
      })}
    </Box>
  )
}

export default React.memo(DashboardKpiDetailView)
