import React from 'react'
import { Box } from '@mui/material'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import { useFormatMessage } from 'localization'
import ModalFooter from 'components/modal-footer'
import { FormikProps } from 'formik'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'


const FooterSection: React.FC<FormikProps<UserFormModelWithModifiedAreas>> = ({isSubmitting, ...props}) => {

  const f = useFormatMessage()
  const { user, dialogActionType, calledFromOtherPersonell } = UserDialogContext.useState()
  const { handleClose } = UserDialogContext.useActions()

  return (
    <ModalFooter>
      <TextButton type="button" onClick={handleClose} disabled={isSubmitting}>
        {f('BASIC_BUTTON_CLOSE_TEXT')}
      </TextButton>
      <Box pl={1} />
      {(dialogActionType === 'add' || (dialogActionType === 'edit' && (user?.active || calledFromOtherPersonell))) && (
        <ContainedButton type="submit" color="secondary" showProgress={isSubmitting}>
          {user ? f('BASIC_BUTTON_SAVE_TEXT') : f('USER_DIALOG_VIEW_FOOTER_CREATE_BUTTON_TEXT')}
        </ContainedButton>
      )}
    </ModalFooter>
  )
}

export default FooterSection
