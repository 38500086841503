import React from 'react'
import * as yup from 'yup'
import { TemplateActivitiesToAreasItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { ConnectTemplateActivitiesToAreasModalContext } from '../connect-template-activities-to-areas-modal-context'

type ConnectTemplateActivitiesToAreasForm = Readonly<{
  templateActivityIds?: number[] | undefined;
  areaIds?: number[] | undefined;
}>

const createTemplateActivitiesToAreasItem = (values: ConnectTemplateActivitiesToAreasForm): TemplateActivitiesToAreasItem => {
  const { templateActivityIds, areaIds } = values

  return new TemplateActivitiesToAreasItem({
    templateActivityIds,
    areaIds,
  })
}

const useConnectTemplateActivitiesToAreasFormData = (selectedTemplateActivityIds: [number]) => {
  const f = useFormatMessage()

  const { connectTemplateActivitiesToAreasAsync } = ConnectTemplateActivitiesToAreasModalContext.useActions()

  const initialValues = React.useMemo<ConnectTemplateActivitiesToAreasForm>(() => {
    return {
      templateActivityIds: selectedTemplateActivityIds,
      areaIds: undefined,
    }
  }, [])

  const onSubmit: OnSubmitFunction<ConnectTemplateActivitiesToAreasForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const item = createTemplateActivitiesToAreasItem(values);
      await connectTemplateActivitiesToAreasAsync(item).finally(() => setSubmitting(false))
    },
    [connectTemplateActivitiesToAreasAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
/*      description: yup.string().max(
        1024,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
          maxsize: 1024,
        })
      ),*/
    })
  }, [f])

  const name: Name<ConnectTemplateActivitiesToAreasForm> = React.useMemo(
    () => ({
      templateActivityIds: 'templateActivityIds',
      areaIds: 'areaIds'
    }), [])

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useConnectTemplateActivitiesToAreasFormData
