import { DeleteDialog } from 'components/delete-dialog'
import { useFormatMessage } from 'localization'
import React from 'react'
import { CustomerPausedPeriodFormModel } from '../pause-period-dialog/utilities/use-paused-period-dialog-form-data'
import { PausedPeriodContext } from '../paused-period-context'
import { formatDate } from '../paused-period-helper'

const DeleteDialogContainer: React.FC = () => {
  const f = useFormatMessage()

  const { deleteDialogOpen, pausedPeriod } = PausedPeriodContext.useState()
  const {
    closeDeleteDialog,
    deletePausedPeriodAsync,
  } = PausedPeriodContext.useActions()

  const handleClickRemove = React.useCallback(() => {
    deletePausedPeriodAsync(pausedPeriod! as CustomerPausedPeriodFormModel)
  }, [deletePausedPeriodAsync, pausedPeriod])

  return pausedPeriod ? (
    <DeleteDialog
      dialogTitle={f('PAUSED_PERIOD_DELETE_DIALOG_TITLE')}
      dialogContentText={f('PAUSED_PERIOD_DELETE_DIALOG_MESSAGE', {
        date: formatDate(pausedPeriod!.startDateTime),
      })}
      buttonSubmitText={f('PAUSED_PERIOD_DELETE_DIALOG_ACTIONS_REMOVE')}
      buttonCancelText={f('PAUSED_PERIOD_DELETE_DIALOG_ACTIONS_CANCEL')}
      deleteDialogOpen={deleteDialogOpen}
      onClose={closeDeleteDialog}
      onSubmit={handleClickRemove}
    />
  ) : null
}

export default DeleteDialogContainer
