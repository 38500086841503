import React, { Component, ReactNode } from 'react'
import { Typography } from '@mui/material'
import { useFormatMessage } from 'localization'

interface ErrorBoundaryProps {
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string | null;
}

const DefaultErrorFallback: React.FC = () => {
  const f = useFormatMessage()
  return (
    <Typography variant="caption">{f('DEFAULT_ERROR_FALLBACK_MSG')}</Typography>
  )
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {

  public state = { hasError: false, error: null }

  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error:null }
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ hasError: true, error:error.message })
    this.logErrorToMyService(error, info.componentStack)
  }

  public componentWillUnmount() : void {
    this.setState({ hasError: false, error:null })
  }

  public logErrorToMyService = (error: Error, componentStack: string | null): void => {
    console.error('Error occurred:', error);
    console.error('Component stack:', componentStack);
  }

  public render(): React.ReactNode {
    if(this.state.hasError) {
      return this.props.fallback ?
      this.props.fallback
      :
      <DefaultErrorFallback />
    }
    return this.props.children
  }
}

export default ErrorBoundary
