import React from 'react'
import Color from 'color'
import Icon from '@mui/material/Icon';
import { ArrowDownThick, ArrowUpThick } from 'mdi-material-ui'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { hasCompleteStatus } from 'utilities/planned-activity-helper'
import { InnerContext } from '../../contexts/inner-context'
import { PlanningGridContext } from '../../planning-grid-context'
import { ItemTypes, SessionProps } from '../../planning-grid-types'
import { DragAndDropContext, useDrag, UseDragProps } from '../../utilities/drag-and-drop/drag-and-drop'
import { getStartTimeInMinutes } from '../../utilities/get-start-time-in-minutes'
import { useResizeSession } from '../../utilities/resize-session'

type SessionRenderProps = Readonly<{
  startProportion: number
  widthProportion: number
  session: SessionProps
  children?: (props: {
    dragProps?: {
      itemType: any
      startDate: Date
    }
  }) => React.ReactNode
}>

const SessionContainer: React.FC<SessionRenderProps> = ({ children, startProportion, widthProportion, session }) => {
  const { readonly } = DragAndDropContext.useState()
  const { startDate, lengthInMinutes, meta } = session
  const { calendarStartDate, preventHorizontalDrag } = InnerContext.useState()
  const { mapMinutesToPixels, mapPixelsToMinutes, snapToPixels } = InnerContext.useActions()
  const { isDragging: anyIsDragging } = PlanningGridContext.useState()
  const [deltaTop, refAnchorTop, isResizingTop] = useResizeSession(session, 'top')
  const [deltaBottom, refAnchorBottom, isResizingBottom] = useResizeSession(session, 'bottom')
  const isComplete = meta ? hasCompleteStatus(meta.plannedActivityStatusId) : false
  const textColor = Color(session.meta.activityColor).contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'

  const { fetchNonCompliantRoutesAndTimesForPlannedActivityAsync, setNonCompliantRoutesForPlannedUserWorkSchedule, setNonCompliantRoutesandTimesForPlannedActivity } = PlanningPageContext.useActions()

  const handleDragStart = React.useCallback(
    (draggingItem: any) => {
      void fetchNonCompliantRoutesAndTimesForPlannedActivityAsync(draggingItem.id)
    }, [fetchNonCompliantRoutesAndTimesForPlannedActivityAsync]
  )

  const handleDragEnd = React.useCallback(
    (draggingItem: any) => {
      setNonCompliantRoutesForPlannedUserWorkSchedule(null)
      setNonCompliantRoutesandTimesForPlannedActivity(null)
    }, []
  )


  let height = 0
  let top = 0
  let isBeforeCalendarStart = false
  let hasAfterCalenderEnd = false
  try {
    top = mapMinutesToPixels(getStartTimeInMinutes(calendarStartDate, startDate))

    if (startDate < calendarStartDate) {
      isBeforeCalendarStart = true
    }
    let newLengthInMinutes = lengthInMinutes
    const endMinutes = (startDate.getHours() * 60) + startDate.getMinutes() + lengthInMinutes // checks if activity passes 00:00 into a new day
    const dayBeforeMinutes = (startDate.getHours() * 60) + startDate.getMinutes()
    if (endMinutes > 1440 && !isBeforeCalendarStart) { // if activity passes 00:00 it splits into 2
      newLengthInMinutes = 1440 - dayBeforeMinutes
      hasAfterCalenderEnd = true
    }
    if (endMinutes > 1440 && isBeforeCalendarStart) {
      newLengthInMinutes = endMinutes - 1440
    }
    const snapDeltaTop = isResizingTop ? snapToPixels(top + deltaTop) - top : 0
    const lengthInPixels = mapMinutesToPixels(newLengthInMinutes)
    const bottom = top + lengthInPixels
    const snapBottom = isResizingBottom ? snapToPixels(bottom + deltaBottom) - bottom : 0
    height = lengthInPixels + snapBottom - snapDeltaTop
    top += snapDeltaTop
  } catch (error) {
    console.log(error)
    // TODO: Start date of session needs to be bigger than start date of calendar
    // This probably occurs when we have sessions rendered for a date, then
    // the date changes to a later date and before we receive the new data
    // the old gets rendered for the new date and because the old sessions have
    // an old date above error occurs.
  }

  const handleStart: UseDragProps['onStart'] = React.useCallback(
    ({ item: draggingItem }) => {
      //const { meta: draggingMeta } = draggingItem as SessionProps<PlanMetaData>
      handleDragStart(draggingItem)
      //onDragStart?.(draggingMeta)
    },
    []
  )

  const handleEnd: UseDragProps['onEnd'] = React.useCallback(
    ({ item: draggedItem }) => {
      //const { meta: draggingMeta } = draggingItem as SessionProps<PlanMetaData>
      handleDragEnd(draggedItem)
      //onDragEnd?.(draggingMeta)
    },
    []
  )

  const [, drag, { isDragging }] = useDrag('SESSION_UPDATE', session, {
    preventHorizontalDrag,
    onStart: handleStart,
    onEnd: handleEnd,
  })

  const liveStartDate = React.useMemo(() => new Date(+calendarStartDate + mapPixelsToMinutes(top) * 60000), [calendarStartDate, mapPixelsToMinutes, top])
  const makeCompletedOpaque = false // Maybe we should consider making complete activities opaque for the current date?

  const div001: React.CSSProperties = {
    position: 'absolute',
    top: `${top}px`,
    left: `${startProportion * 100}%`,
    width: `${widthProportion * 100}%`,
    height: `${height}px`,
    opacity: (isDragging || (isComplete && makeCompletedOpaque) || isBeforeCalendarStart) ? 0.4 : 1,
    zIndex: (isResizingTop || isResizingBottom) ? 99 : 'auto',
    pointerEvents: (!isDragging && anyIsDragging) ? 'none' : 'auto'
  }
  const div002: React.CSSProperties = {
    height: '100%',
    cursor: (readonly || isComplete || isBeforeCalendarStart) ? 'auto' : isDragging ? '-webkit-grabbing' : '-webkit-grab'
  }

  const anchordivTop: React.CSSProperties = {
    height: isResizingTop ? 'auto' : '2px',
    backgroundColor: 'transparent',
    position: 'absolute',
    cursor: 'row-resize',
    left: 0,
    right: 0,
    top: 0,
    bottom: isResizingTop ? 0 : 'initial',
    zIndex: 100
  }

  const anchordivBottom: React.CSSProperties = {
    height: isResizingBottom ? 'auto' : '2px',
    backgroundColor: 'transparent',
    position: 'absolute',
    cursor: 'row-resize',
    left: 0,
    right: 0,
    bottom: 0,
    top: isResizingBottom ? 0 : 'initial',
    zIndex: 100
  }
  return (
    <div
      style={div001}>
      {!(readonly || isComplete || isBeforeCalendarStart) && (
        <>
          <div style={anchordivTop} ref={refAnchorTop} />
          <div style={anchordivBottom} ref={refAnchorBottom} />
        </>
      )}
      <div
        ref={drag}
        style={div002}>
        {
          (isBeforeCalendarStart || hasAfterCalenderEnd) && (
            <Icon component={isBeforeCalendarStart ? ArrowUpThick : ArrowDownThick} sx={{
              position: 'absolute',
              top: isBeforeCalendarStart ? 0 : '',
              bottom: hasAfterCalenderEnd ? 0 : '',
              right: 0,
              fontSize: '1rem',
              color: textColor
            }} />
          )
        }
        {children &&
          children({
            dragProps: {
              itemType: ItemTypes.SESSION_UPDATE,
              startDate: liveStartDate,
            },
          })}
      </div>
    </div>
  )
}

export const MemoizedSessionContainer = React.memo(SessionContainer)
