import styled, { css } from 'styled-components'
import OrganizationAreaView from './organization-area-view'

const OrganizationAreaStyles = styled(OrganizationAreaView)(
  ({ theme, selected = false, disabled = false, active = false }) => css`
    height: 42px;
    margin: ${theme.spacing(2)} 0;
    border-radius: 4px;
    border: 1px solid ${selected || active ? theme.palette.secondary.main : '#777777'};
    background-color: ${selected ? '#E7F2FE' : 'white'};
    padding: 11px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${disabled
      ? `
      opacity: 0.6;
    `
      : ''}

    &:hover {
      border: 1px solid ${theme.palette.secondary.main};
      background-color: #f7fafe;
    }
  `
)

export default OrganizationAreaStyles
