import React from 'react'
import { Typography } from '@mui/material';
import { useFormatMessage } from 'localization'
import { useIntl } from 'react-intl'
import EllipsisTypography from 'components/ellipsis-typography'
import { UserDelegationPlacementItem } from 'api/tillit.api-client'

type Props = Readonly<
  {
    placement: UserDelegationPlacementItem
  }
>

const UserDelegationPlacementCardStaticContent: React.FC<Props>  = ({placement}) => {
  const f = useFormatMessage()
  const intl = useIntl()

  const formatDate = React.useCallback(
    (date?: Date) => {
      if (!date) {
        return null
      }
      return intl.formatDate(date, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    []
  )

  return (
    <>
      <Typography data-matomo-mask variant="subtitle2">
        {
          placement.areaName ? f('USER_DIALOG_VIEW_DELEGATION_AREA_VALUE',{value:placement.areaName})
          : placement.customerName ? f('USER_DIALOG_VIEW_DELEGATION_CUSTOMER_VALUE', {value:placement.customerName}) : ''
        }
      </Typography>
      <Typography variant="subtitle2">
        {f('USER_DIALOG_VIEW_DELEGATION_START_DATE_VALUE',{date:formatDate(placement.delegationStartTime)})}
      </Typography>
      <Typography variant="subtitle2">
        {f('USER_DIALOG_VIEW_DELEGATION_END_DATE_VALUE',{date:formatDate(placement.delegationEndTime)})}
      </Typography>
      <EllipsisTypography data-matomo-mask variant="subtitle2">
        {placement.description}
      </EllipsisTypography>
      <Typography data-matomo-mask variant="subtitle2">
        {placement.delegatedBy}
      </Typography>
    </>
  )
}

export default UserDelegationPlacementCardStaticContent
