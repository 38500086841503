import { Box, Typography } from '@mui/material'
import CenterModal from 'components/center-modal'
import BigCheckbox from 'components/inputs/big-checkbox'
import ColorPicker from 'components/inputs/color-picker'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import ActivityParticipants from 'components/activity-participants'
import SelectInput from 'components/inputs/select-input'
import { TemplateActivityItem, TemplateActivityInheritanceItem, SeverityLevelEnum } from 'api/tillit.api-client'
import { compundedTemplateActivityTitle } from 'utilities/template-activity-helper'
import Authentication from 'components/authentication'
import usePromiseApi from 'utilities/use-promise'
import { useTemplateActivityApi } from 'api'
import { TemporaryActivityCreateModalContext } from '../temporary-activity-create-modal/temporary-activity-create-modal-context'
import { ICellData } from '../schedule/schedule-view'
import useFormData from './utilities/use-temporary-activity-create-form-data'

interface IProps {
  timeData: ICellData
}

const TemporaryActivityCreateModal: React.FC<IProps> = ({ timeData }) => {
  const { fetchAsync } = usePromiseApi()
  const { getTemplateActivity, getTemplateActivityInheritanceOnArea } = useTemplateActivityApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const f = useFormatMessage()
  const getCompundedTemplateActivityTitle = compundedTemplateActivityTitle(f)

  const {
    modalOpen,
    templateActivityItems,
    selectedTemplateActivity,
    selectedTemplateActivityInheritance,
    currentAreaId,
    customers,
  } = TemporaryActivityCreateModalContext.useState()
  const { closeModal, setSelectedTemplateActivity, setSelectedTemplateActivityInheritance } = TemporaryActivityCreateModalContext.useActions()
  const { customerInfo } = PlanningPageContext.useState()
  const { initialValues, name, onSubmit, validationSchema } = useFormData(timeData)

  return (
    <CenterModal open={modalOpen} onClose={closeModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, errors }) => {
          const handleChangeTemplateActivity = (event: React.ChangeEvent<{}>, value: any) => {
            const taV = value as TemplateActivityItem
            if (taV) {
              setFieldValue('templateActivityId', taV.id)
              setSelectedTemplateActivity(undefined)
              setSelectedTemplateActivityInheritance(undefined)

              void fetchAsync(getTemplateActivity(taV.id)).then(ta => {
                if (ta instanceof TemplateActivityItem) {
                  setFieldValue('visitLengthInMinutes', ta?.visitLengthInMinutes)
                  setFieldValue('alertFromStartInMinutes', ta?.alertFromStartInMinutes)
                  setFieldValue('color', ta?.color)
                  setFieldValue('showColorOnDevice', ta?.showColorOnDevice)
                  setFieldValue('expectedAmount', ta?.unitAmount)
                  setFieldValue('severityLevelId', ta?.severityLevelId)
                  setSelectedTemplateActivity(ta)

                  if (currentAreaId) {
                    void fetchAsync(getTemplateActivityInheritanceOnArea(taV.id, currentAreaId)).then(tai => {
                      if (tai instanceof TemplateActivityInheritanceItem) {
                        setSelectedTemplateActivityInheritance(tai)
                      }
                    })
                  }
                }
              })
            } else {
              setFieldValue('templateActivityId', undefined)
              setSelectedTemplateActivity(undefined)
              setSelectedTemplateActivityInheritance(undefined)
            }
          }

          return (
            <Form translate="yes">
              <ModalHeader
                data-matomo-mask
                title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE')}
                subtitle={customerInfo ? customerInfo.fullName : f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_COMMON_ACTIVITY')}
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    overflow: 'auto',
                  }}>
                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Box flex={1}>
                          <SelectInput
                            label={f('ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_LABEL_DEFAULT')}
                            onChange={handleChangeTemplateActivity}
                            name={name.templateActivity}
                            options={templateActivityItems as any}
                            getOptionLabel={getCompundedTemplateActivityTitle}
                            fullWidth
                            error={!!errors.templateActivity}
                            helperText={errors.templateActivity}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE')}
                    subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          data-matomo-mask
                          label={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TITLE_LABEL')}
                          name={name.title}
                          color="secondary"
                          fullWidth
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {currentUser.useDrugLists && selectedTemplateActivity && selectedTemplateActivity.unitId && (
                    <ModalSection title={f('ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_TITLE')} subtitle={f('ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_SUBTITLE')}>
                      <ModalSectionRow>
                        <FilledTextField
                          label={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_EXPECTED_AMOUNT_LABEL')}
                          name={name.expectedAmount}
                          type="number"
                          color="secondary"
                        />
                        {(selectedTemplateActivity.unitTitle || selectedTemplateActivity.unitAbbreviation) && (
                          <Typography style={{ marginTop: 'auto' }} >
                            {(selectedTemplateActivity?.unitTitle ? selectedTemplateActivity?.unitTitle : '') + (selectedTemplateActivity?.unitAbbreviation ? ` (${selectedTemplateActivity?.unitAbbreviation})` : '')}
                          </Typography>
                        )}

                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TITLE')}
                    subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_SUBTITLE')}>
                    <ModalSectionRow>
                      <FilledTextField
                        label={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_LABEL')}
                        name={name.visitLengthInMinutes}
                        color="secondary"
                      />
                    </ModalSectionRow>
                    <ModalSectionRow>
                      <BigCheckbox
                        label={`10 ${f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTMINUTE_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="10"
                        checkedByValue
                        returnValue="value"
                      />
                      <BigCheckbox
                        label={`20 ${f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTMINUTE_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="20"
                        checkedByValue
                        returnValue="value"
                      />
                      <BigCheckbox
                        label={`30 ${f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTMINUTE_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="30"
                        checkedByValue
                        returnValue="value"
                      />
                      <BigCheckbox
                        label={`60 ${f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORTMINUTE_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="60"
                        checkedByValue
                        returnValue="value"
                      />
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_ALERT_TITLE')}
                    subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_ALERT_SUBTITLE')}>
                    <ModalSectionRow>
                      <FilledTextField
                        label={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_LABEL')}
                        name={name.alertFromStartInMinutes}
                        color="secondary"
                      />
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_NORMAL')}`}
                          name={name.severityLevelId}
                          value={SeverityLevelEnum.Normal}
                          checkedByValue
                          returnValue="value"
                        />
                        <BigCheckbox
                          label={`${f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_CRITICAL')}`}
                          name={name.severityLevelId}
                          value={SeverityLevelEnum.Critical}
                          checkedByValue
                          returnValue="value"
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE')}
                    subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          data-matomo-mask
                          name={name.description}
                          label={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL')}
                          color="secondary"
                          fullWidth
                          multiline
                          rows={5}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_AREA')}
                    subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_AREA')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          data-matomo-mask
                          label={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_AREA')}
                          color="secondary"
                          fullWidth
                          multiline
                          rows={5}
                          disabled
                          value={
                            selectedTemplateActivityInheritance?.description
                              ? selectedTemplateActivityInheritance?.description
                              : !selectedTemplateActivityInheritance
                                ? selectedTemplateActivity?.description
                                  ? selectedTemplateActivity?.description
                                  : ''
                                : ''
                          }
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {selectedTemplateActivityInheritance && selectedTemplateActivityInheritance.useTemplateDescription && (
                    <ModalSection
                      title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_ORG')}
                      subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_ORG')}>
                      <ModalSectionRow>
                        <Box width="80%">
                          <FilledTextField
                            data-matomo-mask
                            label={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_ORG')}
                            color="secondary"
                            fullWidth
                            multiline
                            rows={5}
                            disabled
                            value={selectedTemplateActivity?.description ? selectedTemplateActivity?.description : ''}
                          />
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_TITLE')}
                    subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SUBTITLE')}>
                    <ModalSectionRow>
                      <div>
                        <ColorPicker name={name.color} />
                      </div>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SHOW_ON_DEVICE')}
                    subtitle={f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLOR_SHOW_ON_DEVICE_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox label={`${f('BASIC_TEXT_YES')}`} name={name.showColorOnDevice} value="true" checkedByValue={true} returnValue="value" />
                        <BigCheckbox label={`${f('BASIC_TEXT_NO')}`} name={name.showColorOnDevice} value="false" checkedByValue={true} returnValue="value" />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  {selectedTemplateActivity?.useParticipantList && (
                    <ModalSection
                      title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS')}
                      subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS_SUBTITLE')}>
                      <ModalSectionRow>
                        <Box width="80%">
                          <ActivityParticipants
                            activityParticipants={values.activityParticipants}
                            availableCustomers={customers}
                          />
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                </div>
              </Box>
              <ModalFooter>
                <TextButton type="button" onClick={closeModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color="secondary" showProgress={isSubmitting}>
                    {f('BASIC_BUTTON_ADD_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default TemporaryActivityCreateModal
