import React from 'react'
import AdministerPage from 'pages/administer'
import ChangePasswordPage from 'pages/change-password'
import CustomerPage from 'pages/customers'
import DailyActivities from 'pages/daily-activities'
import AreasPage from 'pages/areas'
import LoginPage from 'pages/login'
import ForgotPasswordPage from 'pages/forgot-password'
import ImpersonationRequestPage from 'pages/impersonation-request'
import Organization from 'pages/organization'
import PlanningPage from 'pages/planning'
import ProfilePage from 'pages/profile'
import DashboardPage from 'pages/dashboard'
import ResetPassword from 'pages/reset-password'
import ResetPinCode from 'pages/reset-pincode'
import UsersPage from 'pages/users'
import NotFoundPage from 'pages/not-found'
import ReportsPage from 'pages/reports'
import SupportPage from 'pages/support'
import ValidateDailyActivities, { ValidationLoader }  from 'pages/validate-daily-activities/validate-daily-activities'
import { ROUTEPREFIX } from 'config/variables'
import GidsCallbackRequestPage from 'pages/gids-callback-request'

const PageRoute = {
  Dashboard: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/dashboard',
    element: <DashboardPage />
  },
  Profile: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/profile',
    element: <ProfilePage />
  },
  Login: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/accounts/login',
    element: <LoginPage />,
  },
  ForgotPassword: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/accounts/forgot-password',
    element: <ForgotPasswordPage />,
  },
  ImpersonationRequest: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/accounts/impersonation-request/:userId/:token',
    element: <ImpersonationRequestPage />,
  },
  GidsCallbackRequest: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/accounts/gids-callback-request',
    element: <GidsCallbackRequestPage />,
  },
  Administer: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/administer',
    element: <AdministerPage />,
  },
  Users: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/users',
    element: <UsersPage />,
  },
  Customers: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/customers',
    element: <CustomerPage />,
  },
  ShowCustomer: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/customers/showcustomer/:customerId',
    element: <CustomerPage />,
  },
  Planning: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/planning/:areaId/:date/:type/:usingCalendarFromDevice',
    element: <PlanningPage />,
  },
  Areas: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/areas',
    element: <AreasPage />,
  },
    ChangePassword: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/accounts/change-password',
    element: <ChangePasswordPage />,
  },
  ResetPassword: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/accounts/reset-password/:userId/:token',
    element: <ResetPassword />,
  },
  ResetPinCode: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/accounts/reset-pincode/:userId/:token',
    element: <ResetPinCode />,
  },
  Organization: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/organization',
    element: <Organization />,
  },
  DailyActivities: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/daily-activities',
    element: <DailyActivities />,
  },
  DailyActivitiesWithDevice: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/daily-activities/:deviceGuid',
    element: <DailyActivities />,
  },
  ValidateDailyActivities: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/validate-daily-activities',
    element: <ValidateDailyActivities />,
  },
  ValidateDailyActivitiesWithDevice: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/validate-daily-activities/:deviceGuid',
    element: <ValidateDailyActivities />,
    loader: ValidationLoader
  },
  Reports: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/reports',
    element: <ReportsPage />,
  },
  Support: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/support',
    element: <SupportPage />,
  },
  NotFound: {
    path: (ROUTEPREFIX ? ROUTEPREFIX : '') + '/404',
    element: <NotFoundPage />,
  }
}

export default PageRoute
