import React from 'react'
import {
  AreaCreateItem,
  IAreaCreateItem,
  IAreaListItem,
  IAreaOrgTreeItem,
  IChildAreaOrRoutesForParentAreaItem,
  ICreatedNewAreaItem,
  IOrganizationListItem,
  OrganizationClient,
  OrganizationItem,
  OrganizationUpdateinternalInformationItem
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import { FileResponse } from './tillit.api-client'

export class OrganizationApi {
  private readonly abortController = new AbortController()
  private readonly client: OrganizationClient

  constructor() {
    this.client = new OrganizationClient(
      API_URL,
      httpMiddleware({ signal: this.abortController.signal })
    )
  }

  public abort = () => this.abortController.abort()

  public getOrganization = async (
    organizationId: number
  ): Promise<OrganizationItem> =>
    this.client
      .getOrganization(organizationId)
      .then(organizationItem =>
        organizationItem === null
          ? Promise.reject(
              `No organization item received from backend for organization id ${organizationId}.`
            )
          : Promise.resolve(organizationItem)
      )

  public getChildrensForAreaNode = (
    areaId: number
  ): Promise<IChildAreaOrRoutesForParentAreaItem> =>
    this.client
      .listChildAreasOrRoutesOfArea(areaId)
      .then(areas =>
        areas === null
          ? Promise.reject(
              `No areas of routes received from backend for area id ${areaId}.`
            )
          : Promise.resolve(areas)
      )

  public getAllAreasOfOrganization = (
    organizationId: number
  ): Promise<IAreaListItem[]> =>
    this.client
      .listAllAreas(organizationId)
      .then(areas =>
        areas === null
          ? Promise.reject(
              `No areas of organization received from backend for organization id ${organizationId}.`
            )
          : Promise.resolve(areas)
      )

  public getNodeInfo = (areaId: number): Promise<IAreaOrgTreeItem> =>
    this.client
      .getOrganizationTreeArea(areaId)
      .then(area =>
        area === null
          ? Promise.reject(
              `No area received from backend for area id ${areaId}.`
            )
          : Promise.resolve(area)
      )

  public createAreaNode = ({
    name,
    organizationId,
    parentAreaId,
  }: IAreaCreateItem): Promise<ICreatedNewAreaItem> => {
    const model = new AreaCreateItem({ name, organizationId, parentAreaId })
    return this.client
      .createArea(model)
      .then(newArea =>
        newArea === null
          ? Promise.reject(
              `No area received from backend when creating a new area.`
            )
          : Promise.resolve(newArea)
      )
  }

  public generateOrganizationCustomerReport = (
    organizationId: number,
    periodStartDate: Date,
    periodEndDate: Date | null
  ): Promise<FileResponse> =>
    this.client
      .generateOrganizationCustomerReport(
        organizationId,
        periodStartDate,
        periodEndDate
      )
      .then(response =>
        response === null
          ? Promise.reject(
              `No response received from backend when generating organization customer report.`
            )
          : Promise.resolve(response)
      )

  public getOrganizations = (): Promise<IOrganizationListItem[]> =>
    this.client
      .getOrganizations()
      .then(response =>
        response === null
          ? Promise.reject(`Did not receive any organizations from backend.`)
          : Promise.resolve(response)
      )
  public updateOrganizationinternalInformation = (model: OrganizationUpdateinternalInformationItem): Promise<OrganizationItem> =>
    this.client
        .updateOrganizationinternalInformation(model)
        .then(response =>
          response === null
          ? Promise.reject(`Did not receive any organizations from backend.`)
          : Promise.resolve(response)
        )

}

const useOrganizationApi = () => React.useMemo(() => new OrganizationApi(), [])

export default useOrganizationApi
