import React from 'react'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'

import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'

import { LegislationListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'

import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterLegislations } from 'utilities/legislation-helper'

import { LegislationModalContext } from './components/legislation-modal/legislation-modal-context'
import { AdministerLegislationsContext } from './administer-legislations-context'

const AdministerLegislationsView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const {
    legislationListItems,
    removeDialogOpen,
    legislationToRemove,
    isLoading,
    removeInvalidDialogOpen,
    legislationInvalidToRemove,
  } = AdministerLegislationsContext.useState()
  const { openRemoveDialog, closeRemoveDialog, deletelegislationAsync, closeRemoveInvalidDialog } = AdministerLegislationsContext.useActions()

  const { openLegislationModal, setOwnerOrganizationId } = LegislationModalContext.useActions()

  const f = useFormatMessage()
  const getFilteredLegislations = filterLegislations(f)

  const handleAddNewLegislationClick = React.useCallback(() => {
    setOwnerOrganizationId(currentUser.organizationId)
    openLegislationModal()
  }, [openLegislationModal, setOwnerOrganizationId, currentUser])

  const handleRemovelegislationClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, legislation: LegislationListItem) => {
      _?.stopPropagation()
      openRemoveDialog(legislation)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemovelegislationClick = React.useCallback(() => deletelegislationAsync(legislationToRemove!.id), [
    legislationToRemove,
    deletelegislationAsync,
  ])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openLegislationModal(params.row.id)
    },
    [openLegislationModal]
  )

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredLegislations, setFilteredLegislations] = React.useState<LegislationListItem[]>(legislationListItems)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredLegislations(filterValue, legislationListItems)
      setFilteredLegislations(filtered)
    } else {
      setFilteredLegislations(legislationListItems)
    }
    // NOTE: We are missing filterValue and getFilteredLegislations as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [legislationListItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredLegislations(searchValue, legislationListItems)
      setFilteredLegislations(filtered)
    },
    [setFilterValue, setFilteredLegislations, legislationListItems, getFilteredLegislations]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredLegislations(legislationListItems)
    },
    [setFilterValue, setFilteredLegislations, legislationListItems]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '2vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>

          {currentUser.organizationId && (
            <div style={{ columnCount: 2 }}>
              <ContainedButton type="button" color="secondary" onClick={handleAddNewLegislationClick}>
                {f('ADMINISTER_LEGISLATION_ADD_BUTTON_TEXT')}
              </ContainedButton>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredLegislations}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_LEGISLATION_COLUMN_TITLE'),
                field: 'title',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_LEGISLATION_COLUMN_DESCRIPTION'),
                field: 'description',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_delete',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title ? params.row.title : '',
                        })}
                        aria-label={f('ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title ? params.row.title : '',
                        })}>
                        <IconButton onClick={e => handleRemovelegislationClick(e, params.row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />

          {/*
          <MaterialTable
            title={f('CUSTOMERS_PAGE_VIEW_HEAD_TITLE')}
            icons={tableIcons}
            style={{
              maxWidth: '100%',
              width: '100%',
              minHeight: '70vh',
              maxHeight: '70vh',
            }}
            localization={{
              toolbar: {
                searchPlaceholder: f('BASIC_SEARCH_FIELD_PLACEHOLDER'),
              },
              pagination: {
                labelRowsSelect: f('TABLE_PAGINATION_ROWS_TEXT'),
                labelDisplayedRows: f('TABLE_PAGINATION_COUNT_TEXT'),
                firstTooltip: f('TABLE_PAGINATION_FIRST_PAGE_TOOLTIP_TEXT'),
                previousTooltip: f('TABLE_PAGINATION_PREVIOUS_PAGE_TOOLTIP_TEXT'),
                nextTooltip: f('TABLE_PAGINATION_NEXT_PAGE_TOOLTIP_TEXT'),
                lastTooltip: f('TABLE_PAGINATION_LAST_PAGE_TOOLTIP_TEXT'),
              },
              body: {
                emptyDataSourceMessage: f('ADMINISTER_LEGISLATION_NO_VALUES_DIPLAYED_TEXT'),
              },
            }}
            columns={[
              {
                title: f('ADMINISTER_LEGISLATION_COLUMN_TITLE'),
                field: 'title',
                cellStyle: {
                  borderBottom: '0px',
                },
                filterCellStyle: {
                  backgroundColor: 'white',
                  borderBottom: '0px',
                  position: 'sticky',
                  top: 50,
                  height: 50,
                },
              },

              {
                title: f('ADMINISTER_LEGISLATION_COLUMN_DESCRIPTION'),
                field: 'description',
                cellStyle: {
                  borderBottom: '0px',
                },
                filterCellStyle: {
                  backgroundColor: 'white',
                  borderBottom: '0px',
                  position: 'sticky',
                  top: 50,
                  height: 50,
                },
              },

              {
                title: '',
                field: '',
                render: row => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP', {
                          title: row.title ? row.title : '',
                        })}
                        aria-label={f('ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP', {
                          title: row.title ? row.title : '',
                        })}>
                        <IconButton onClick={e => handleRemovelegislationClick(e, row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
                cellStyle: {
                  borderBottom: '0px',
                },
                filterCellStyle: {
                  width: '10px',
                  zIndex: 1000,
                  backgroundColor: 'white',
                  borderBottom: '0px',
                  position: 'sticky',
                  top: 50,
                  height: 50,
                },
              },
            ]}
            data={sortedLegislationListItems}
            onRowClick={handleRowClick}
            options={{
              padding: 'dense',
              emptyRowsWhenPaging: false,
              filtering: true,
              pageSizeOptions: [30, 50, 100],
              pageSize: 30,
              paging: false,
              maxBodyHeight: '70vh',
              headerStyle: {
                borderWidth: 0,
              },
            }}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Toolbar: props => <div />,
              Pagination: props => (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                    width: '50vw',
                  }}>
                  <TablePagination {...props} />
                </div>
              ),
            }}
          />
          */}

          {removeDialogOpen && legislationToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_LEGISLATION_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_LEGISLATION_DELETE_DIALOG_MESSAGE', {
                title: legislationToRemove.title,
              })}
              buttonSubmitText={f('ADMINISTER_LEGISLATION_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_LEGISLATION_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen={true}
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemovelegislationClick}
            />
          )}

          {removeInvalidDialogOpen && legislationInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_MESSAGE', {
                title: legislationInvalidToRemove.title,
              })}
              buttonOkText={f('ADMINISTER_LEGISLATION_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen={true}
              onClose={closeRemoveInvalidDialog}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdministerLegislationsView
