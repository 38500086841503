import { Box, useTheme } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import SelectInput from 'components/inputs/select-input'
import { DelegationCategoryItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { UserTypeEnum } from 'api/tillit.api-client'
import { DelegationModalContext } from './delegation-modal-context'
import useFormData from './utilities/use-delegation-form-data'

export type DelegationModalProps = Readonly<{
  propsTitle?: string
  propsSubtitle?: string
  activeAreaId?: number
  onSave?: () => void
}>

const DelegationModalContainer: React.FC<DelegationModalProps> = ({ propsTitle, onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { modalOpen, selectedDelegation, isLoading, delegationCategoryItems } = DelegationModalContext.useState()

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedDelegation)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeDelegationModal, fetchNewSelectedDelegationCategoryAsync } = DelegationModalContext.useActions()

  const { user: currentUser } = Authentication.useAuthenticatedState()
  const userIsSysAdminOrOrgAdminOrTeniloSupport = currentUser.userType === UserTypeEnum.OrganizationAdmin || currentUser.userType === UserTypeEnum.TeniloSupport || currentUser.userType === UserTypeEnum.SystemAdmin

  const getDelegationCategoryTitle = (item: any) => {
    const ta = item as DelegationCategoryItem
    return ta?.title ? ta.title : ""
  }

  return (
    <CenterModal open={modalOpen} onClose={closeDelegationModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {
          //const { ...statusProps } = values

          const handleChangeDelegationCategory = (event: React.ChangeEvent<{}>, value: any) => {
            const dcV = value as DelegationCategoryItem
            if (dcV) {
              setFieldValue('delegationCategoryId', dcV.id)
              void fetchNewSelectedDelegationCategoryAsync(dcV.id)
            } else {
              setFieldValue('delegationCategoryId', undefined)
              void fetchNewSelectedDelegationCategoryAsync(undefined)
            }
          }
          return (
            <Form translate="yes">
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={
                  propsTitle
                    ? propsTitle
                    : selectedDelegation
                    ? f('ADMINISTER_DELEGATION_MODAL_DIALOG_HEADER_EDIT_TITLE')
                    : f('ADMINISTER_DELEGATION_MODAL_DIALOG_HEADER_TITLE')
                }
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>
                  <ModalSection title={f('ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_TITLE')} subtitle={f('ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          label={f('ADMINISTER_DELEGATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT')}
                          name={name.title}
                          color="secondary"
                          fullWidth={true}
                          disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_TITLE')}
                    subtitle={f('ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          name={name.description}
                          label={f('ADMINISTER_DELEGATION_MODAL_DIALOG_DESCRIPTION_COMPONENT_LABEL_DEFAULT')}
                          color="secondary"
                          fullWidth={true}
                          multiline={true}
                          rows={5}
                          disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_DELEGATION_MODAL_DIALOG_DELEGATION_CATEGORY_TITLE')}
                    subtitle={f('ADMINISTER_DELEGATION_MODAL_DIALOG_DELEGATION_CATEGORY_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Box flex={1}>
                          <SelectInput
                            label={f('ADMINISTER_DELEGATIONS_MODAL_DIALOG_DELEGATION_CATEGORY_LABEL')}
                            onChange={handleChangeDelegationCategory}
                            name={name.delegationCategory}
                            options={delegationCategoryItems as any}
                            getOptionLabel={getDelegationCategoryTitle}
                            fullWidth={true}
                            disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>                  
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeDelegationModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedDelegation ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default DelegationModalContainer
