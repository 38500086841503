import styled, { css } from 'styled-components'
import Colors from 'styles/colors'
import { InfoFuturePlannedView } from './info-future-planned-view'

export const InfoFuturePlannedStyle = styled(InfoFuturePlannedView)(({ theme }) => {
  const color = 'gray'
  return css`
    border-radius: 0.5vh;
    width: 96%;
    margin-bottom: 0.5vh;

    border: 1px solid ${color};

    .infoIcon {
      vertical-align: middle;
      color: ${color};
      margin-right: 15px;
    }

    .textInfo {
      padding-top: 5px;
    }

    .date {
      color: ${Colors.Tuna};
      opacity: 0.6;
      float: right;
    }

    .textGray {
      color: ${Colors.Tuna};
      opacity: 0.6;
      word-break: break-all;
    }

    ${theme.breakpoints.down('md')}{
      width: auto;
      padding:5px;
      display: block;
    }
  `
})
