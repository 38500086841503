import React from 'react'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'

import { DelegationCategoryListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'

import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterDelegationCategories } from 'utilities/delegation-helper'
import { DelegationCategoryModalContext } from './components/delegation-category-modal/delegation-category-modal-context'
import { AdministerDelegationCategoriesContext } from './administer-delegation-categories-context'

const AdministerDelegationCategoriesView: React.FC = () => {

  const { user: currentUser } = Authentication.useAuthenticatedState()
  const {
    delegationCategoryListItems,
    removeDialogOpen,
    delegationToRemove,
    isLoading,
    removeInvalidDialogOpen,
    delegationInvalidToRemove,
  } = AdministerDelegationCategoriesContext.useState()
  const {
    openRemoveDialog,
    closeRemoveDialog,
    deleteDelegationAsync,
    closeRemoveInvalidDialog,
  } = AdministerDelegationCategoriesContext.useActions()

  const { openDelegationCategoryModal, setOrganizationId } = DelegationCategoryModalContext.useActions()

  const f = useFormatMessage()
  const getFilteredDelegationCategories = filterDelegationCategories(f)
  const handleAddNewDelegationCategoryClick = React.useCallback(() => {
    setOrganizationId(currentUser.organizationId)
    openDelegationCategoryModal()
  }, [openDelegationCategoryModal, setOrganizationId, currentUser])

  const handleRemoveDelegationClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, delegationCategory: DelegationCategoryListItem) => {
      _?.stopPropagation()
      openRemoveDialog(delegationCategory)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveDelegationClick = React.useCallback(() => deleteDelegationAsync(delegationToRemove!.id), [
    delegationToRemove,
    deleteDelegationAsync,
  ])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openDelegationCategoryModal(params.row.id)
    },
    [openDelegationCategoryModal]
  )

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredDelegationCategories, setFilteredDelegationCategories] = React.useState<DelegationCategoryListItem[]>(delegationCategoryListItems)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredDelegationCategories(filterValue, delegationCategoryListItems)
      setFilteredDelegationCategories(filtered)
    } else {
      setFilteredDelegationCategories(delegationCategoryListItems)
    }
    // NOTE: We are missing filterValue and getFilteredDelegationCategories as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [delegationCategoryListItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredDelegationCategories(searchValue, delegationCategoryListItems)
      setFilteredDelegationCategories(filtered)
    },
    [setFilterValue, setFilteredDelegationCategories, delegationCategoryListItems, getFilteredDelegationCategories]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredDelegationCategories(delegationCategoryListItems)
    },
    [setFilterValue, setFilteredDelegationCategories, delegationCategoryListItems]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '0.5vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && (
            <div style={{ columnCount: 2 }}>
              <ContainedButton type="button" color="secondary" onClick={handleAddNewDelegationCategoryClick}>
                {f('ADMINISTER_DELEGATION_CATEGORY_ADD_BUTTON_TEXT')}
              </ContainedButton>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}
          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredDelegationCategories}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_DELEGATION_CATEGORY_COLUMN_TITLE'),
                field: 'title',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_deactivate',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title,
                        })}
                        aria-label={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_BUTTON_TOOLTIP', {
                          title: params.row.title,
                        })}>
                        <IconButton onClick={e => handleRemoveDelegationClick(e, params.row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />
          {removeDialogOpen && delegationToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_MESSAGE', {
                title: delegationToRemove.title,
              })}
              buttonSubmitText={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen={true}
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemoveDelegationClick}
            />
          )}

          {removeInvalidDialogOpen && delegationInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_MESSAGE', {
                title: delegationInvalidToRemove.title,
              })}
              buttonOkText={f('ADMINISTER_DELEGATION_CATEGORY_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen={true}
              onClose={closeRemoveInvalidDialog}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdministerDelegationCategoriesView
