import React, { FC, Fragment } from 'react'
import { useFormatMessage } from 'localization'
import { Collapse } from '@mui/material'
import { useFormikContext } from 'formik'
import type { ReportsPageFormType } from 'pages/reports/config'
import { dashboardSectionConfig } from 'pages/reports/config'
import ReportsPageContext from 'pages/reports/reports-page-context'
import ReportsPageSectionView from './reports-page-section-view'

const ReportsPageSectionsWrapperView: FC = () => {
  const f = useFormatMessage()
  const { values : {submitType, report : formReport} } = useFormikContext<ReportsPageFormType>()
  const { report, userReport } = ReportsPageContext.useState()

  const closeSection = React.useMemo(() => {
    if (submitType === 'seen') {
      return true
    }
    return false
  }, [submitType])

  return React.useMemo(() => {
    return (
      <Collapse in={!!formReport && !!(report || userReport)}>
        {dashboardSectionConfig.map(section => {
          const disableReportFields = !formReport?.showReportFields && section.id === 3
          return (
            <Fragment key={`${section.id}-${formReport?.reportId}-${formReport?.name}`}>
              {
                (!section.existIf || section.existIf.some(condition => formReport && formReport[condition])) && (
                  <ReportsPageSectionView {...section} reportListItem={formReport} closeSection={closeSection} disable={disableReportFields} />
                )
              }
            </Fragment>)
        }
        )}
      </Collapse>
    )

  }, [submitType, formReport, report, userReport])
}

export default ReportsPageSectionsWrapperView
