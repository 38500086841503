import { STORAGEPREFIX } from 'config/variables'
import { IUserItem } from 'api/tillit.api-client'

const USER_DATA_KEY = (STORAGEPREFIX ? STORAGEPREFIX : '') + 'user_data'

export const hasUserData = (): boolean =>
  localStorage.getItem(USER_DATA_KEY) !== null

export const getUserData = (): IUserItem | null => {
  const item = localStorage.getItem(USER_DATA_KEY)
  if (item === null) {
    return null
  }
  return JSON.parse(item)
}

export const setUserData = (userData: IUserItem) =>
  localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData))

export const clearUserData = () => {
  localStorage.removeItem(USER_DATA_KEY)
}
