import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import styled from 'styled-components'
import { Box, Typography, Collapse } from '@mui/material'
import { ReportListItem } from 'api/tenilo.reporting.api-client'
import Colors from 'styles/colors'
import color from 'color'
import type { ReportSection } from 'pages/reports/config'

const SectionWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  background-color: ${Colors.AthensGray};
  cursor: pointer;
  margin: 10px 0 0 0;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  &:hover {
    background-color: ${color(Colors.AthensGray).darken(0.1).toString()};
  }
  transition: all 0.5s;
`


const ReportsPageSectionView: FC<ReportSection & { reportListItem?: ReportListItem, closeSection: boolean, disable?: boolean }> = ({ id, name, InnerContainer, ActionBar, reportListItem, closeSection, disable }) => {
  const f = useFormatMessage()
  const [openSection, setOpenSection] = React.useState<boolean>(false)
  const [openSaveAsSection, setOpenSaveAsSection] = React.useState<boolean>(false)

  const toggleSection = React.useCallback(() => {
    if (disable) {
      return
    }
    setOpenSection((curr) => !curr)
  }, [disable])

  const toggleSaveAsSection = React.useCallback(() => {
    setOpenSaveAsSection((curr) => !curr)
  }, [])

  React.useEffect(() => {
    setOpenSection(false)
    setOpenSaveAsSection(false)
  }, [reportListItem])

  React.useEffect(() => {
    if (closeSection && openSection) {
      setOpenSection(false)
      setOpenSaveAsSection(false)
    }
  }, [closeSection])

  const applyRequirement = React.useMemo(()=>{return (id === 5 ? openSaveAsSection : openSection)},[id, openSaveAsSection, openSection])

  return (
    <>
      <SectionWrapper onClick={toggleSection}>
        <Typography variant="subtitle2" color={disable ? "ButtonFace" : "primary"} >{name && (f(`REPORT_PAGE_VIEW_SECTION_${name.toUpperCase()}`))}</Typography>
        {ActionBar && (
          <ActionBar toggleSaveAsSection={toggleSaveAsSection} openSaveAsSection={openSaveAsSection} />
        )}
      </SectionWrapper>
      <Collapse in={id === 5 ? openSaveAsSection : openSection}>
        <Box
          sx={{
            minHeight: '100px',
            width: '100%',
            boxSizing: 'border-box',
            border: `1px solid ${Colors.AthensGray}`,
            borderRadius: '5px',
            margin: '5px 0',
            padding: '10px',
            '>*': {
              opacity: (id === 5 && openSaveAsSection) ? 1 : openSection ? 1 : 0,
              transition: 'opacity 0.3s'
            }
          }}
        >
          {
            InnerContainer && (
              <InnerContainer applyRequirement={applyRequirement} toggleSaveAsSection={toggleSaveAsSection} />
            )
          }
        </Box>
      </Collapse>
    </>
  )
}

export default React.memo(ReportsPageSectionView)
