import { Box, Typography, useTheme, Switch, Button, List, ListItem } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { SupportGrandIdModalContext } from './support-grandid-modal-context'
import useFormData from './utilities/use-support-grandidservice-form-data'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid'
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import styled, { css } from 'styled-components'
import { GrandIdServiceItem } from 'api/tenilo.support.api-client'
import OutlinedTextField from 'components/inputs/outlined-text-field'

export type SupportGrandIdModalProps = Readonly<{
  onSave?: () => void
}>

const StyledBox = styled(Box)(
  ({ }) => css`
    &:hover {
      background-color: #F0F0F0};
      cursor: pointer;
    }
    `
)

const SupportGrandIdModalContainer: React.FC<SupportGrandIdModalProps> = ({ onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { modalOpen, selectedGrandIdService, grandIdServices, isLoading } = SupportGrandIdModalContext.useState()
  const { setSelectedGrandIdService } = SupportGrandIdModalContext.useActions()

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedGrandIdService)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeSupportGrandIdModal } = SupportGrandIdModalContext.useActions()

  const handleServiceClick = React.useCallback((service: GrandIdServiceItem) => {
    setSelectedGrandIdService(service)
  }, [setSelectedGrandIdService])

  return (
    <CenterModal open={modalOpen} onClose={closeSupportGrandIdModal}>
      <Box display={'flex'} flexDirection={'row'}>
        <List style={{ maxHeight: '50vh', overflow: 'auto', minWidth: '200px' }}>
          {(grandIdServices || []).map((service: GrandIdServiceItem, index: number) => {
            const backCol = service.id === selectedGrandIdService?.id ? '#F0F0F0' : 'white'
            return (
              <StyledBox sx={{ backgroundColor: backCol }} border={1} borderColor="grey.300" borderRadius="5px" marginBottom={1} width="auto" key={`gsvid_${index}`}>
                <ListItem onClick={() => handleServiceClick(service)}>
                  <Box display={'flex'}>
                    <Typography data-matomo-mask>{service.value}</Typography>
                  </Box>
                </ListItem>
              </StyledBox>
            )
          })}
        </List>

        {selectedGrandIdService ? (
          <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting, values, setFieldValue, getFieldMeta, errors, dirty }) => {
              //const { ...statusProps } = values

              const handleCheckActive = (e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(name.active, !values.active)
              }

              return (
                <Form translate="yes">
                  {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
                  <ModalHeader title={''} />
                  <Box mx={3}>
                    <div
                      style={{
                        maxHeight: '70vh',
                        minWidth: '70vw',
                        overflow: 'auto',
                      }}>
                      <ModalSection title={f('SUPPORT_GRANDID_MODAL_DIALOG_SERVICE_TITLE')}>
                        <ModalSectionRow>
                          <Box width="50%">
                            <FilledTextField
                              name={name.value}
                              color="secondary"
                              fullWidth
                              disabled
                            />
                          </Box>
                        </ModalSectionRow>
                      </ModalSection>

                      <ModalSection title={f('SUPPORT_GRANDID_MODAL_DIALOG_AUTHKEY_TITLE')} subtitle={f('SUPPORT_GRANDID_MODAL_DIALOG_AUTHKEY_SUBTITLE')}>
                        <ModalSectionRow>
                          <Box width="50%">
                            <OutlinedTextField
                              name={name.authenticateServiceKey}
                              color="secondary"
                              fullWidth
                              type="password"
                            />
                          </Box>
                        </ModalSectionRow>
                      </ModalSection>

                      <ModalSection title={f('SUPPORT_GRANDID_MODAL_DIALOG_ACTIVE_TITLE')} subtitle={f('SUPPORT_GRANDID_MODAL_DIALOG_ACTIVE_SUBTITLE')}>
                        <ModalSectionRow>
                          <Box width="50%">
                            <Switch checked={values.active} onChange={handleCheckActive} />
                          </Box>
                        </ModalSectionRow>
                      </ModalSection>

                    </div>
                  </Box>

                  <ModalFooter>
                    <TextButton type="button" onClick={closeSupportGrandIdModal} disabled={isSubmitting}>
                      {f('BASIC_BUTTON_CLOSE_TEXT')}
                    </TextButton>
                    <Box ml={2}>
                      <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                        {!selectedGrandIdService ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                      </ContainedButton>
                    </Box>
                  </ModalFooter>
                </Form>
              )
            }}
          </Formik>
        ) : (
          <ModalHeader
            title={'Välj en service att administrera!'}
          />

        )}

      </Box>
    </CenterModal>
  )
}

export default SupportGrandIdModalContainer
