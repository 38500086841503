import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { AreaForUserListItem, UserAreaItem, IUserItem, RoleEnum } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { getUserData } from 'components/authentication/utilities/user-storage'
import { STORAGEPREFIX } from 'config/variables'
import { isSearchValueValid } from './filter-helper'
import { reduceAreas, rootUser, userAreasByRole } from './use-user-areas'

export const filterAreas = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: AreaForUserListItem[]
): AreaForUserListItem[] => {
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const searchText = `${item.name} ${item.organizationParentsName}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as AreaForUserListItem[]
  }
}


export const getVisibleAreaData = (): UserAreaItem[] => {
  const user = getUserData()
  const items = localStorage.getItem(`${STORAGEPREFIX || ''}-visible-areas`)
  return ( items && user ) ? JSON.parse(items).filter((item : UserAreaItem) => item.userId === user.id) : []
}

export const getVisibleAreaIdsString = () : string => {
  return getVisibleAreaData().map(area => area.areaId).toString()
}

export const setVisibleAreaData = ( areaItems: UserAreaItem[] ) : void => {
  localStorage.setItem(`${STORAGEPREFIX || ''}-visible-areas`, JSON.stringify(areaItems))
}

export const removeVisibleAreaData = ( ) : void => {
  localStorage.removeItem(`${STORAGEPREFIX || ''}-visible-areas`)
}

interface UseCurrentAreasType {
  areas: UserAreaItem[];
  getAreaByRoles: (roleId?: RoleEnum[] | undefined) => UserAreaItem[];
  getVisibleAreaByRoles: (roleId?: RoleEnum[] | undefined) => UserAreaItem[];
  getCurrentAreaIds: (roleId?: RoleEnum[] | undefined) => number[];
  getCurrentAreaIdsWithEmptyRes: (roleId?: RoleEnum[] | undefined) => number[]
}

export const useCurrentAreas = ( user: IUserItem ) : UseCurrentAreasType => {

  const areas = React.useMemo(() => {
    const userAreas: UserAreaItem[] = []
    if (!user.userAreas) {
      return []
    }
    reduceAreas(user.userAreas).forEach(ua => {
      const existingDua = userAreas.filter(dua => dua.areaId === ua.areaId)
      if (existingDua && existingDua.length === 0) {
        userAreas.push(ua)
      }
    })
    return userAreas

  }, [user])

  const getAreaByRoles = React.useCallback((roleId?: RoleEnum[]) => {
    if (roleId && !rootUser(user)) {
      return userAreasByRole(areas, roleId)
    }
    return areas
  },[areas, user])

  const getVisibleAreaByRoles = React.useCallback((roleId?: RoleEnum[])=>{
    const visibleAreas = getVisibleAreaData()
    const areaByRoles = getAreaByRoles(roleId)
    if(!isEmpty(visibleAreas)){
      return areaByRoles.filter(area => visibleAreas.some(visibleArea => visibleArea.areaId === area.areaId))
    }
    return areaByRoles
  },[getAreaByRoles])

  const getCurrentAreaIds = React.useCallback((roleId?: RoleEnum[])=>{
    const areaIds = getVisibleAreaByRoles(roleId).map(area => area.areaId)
    return areaIds
  },[getVisibleAreaByRoles])

  const getCurrentAreaIdsWithEmptyRes = React.useCallback((roleId?: RoleEnum[])=>{
    const visibleAreas = getVisibleAreaData()
    if(isEmpty(visibleAreas)){
      return []
    }
    return getCurrentAreaIds(roleId)
  },[getCurrentAreaIds])

  return {
    areas,
    getAreaByRoles,
    getVisibleAreaByRoles,
    getCurrentAreaIds,
    getCurrentAreaIdsWithEmptyRes
  }
}
