import React from 'react'

import {
  AuthenticationClient,
  AuthWithRefreshTokenAppModel,
  JwtTokenGenerationResultItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import { setTokenData } from './token-data'

export class AuthenticationNoRefreshApi {
  private readonly client: AuthenticationClient

  constructor() {
    this.client = new AuthenticationClient(API_URL)
  }

  public generateJwtTokenFromRefreshToken = (
    token: string
  ): Promise<JwtTokenGenerationResultItem> => {
    const model = new AuthWithRefreshTokenAppModel({ token })

    return this.client
      .generateJwtTokenFromRefreshToken(model)
      .then(resultItem => {
        if (resultItem === null) {
          return Promise.reject(
            'No jwt token received from backend when trying to refresh.'
          )
        }
        setTokenData(resultItem)
        return Promise.resolve(resultItem)
      })
  }
}

const useAuthenticationNoRefreshApi = () =>
  React.useMemo(() => new AuthenticationNoRefreshApi(), [])

export default useAuthenticationNoRefreshApi
