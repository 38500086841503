import { AreaItem } from 'api/tillit.api-client'
import { AreaModalContext } from 'components/area-modal/area-modal-context'
import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'

interface IBasicInfoModel {
  name: string | undefined
  internalInformation: string | undefined | null
  pwaAllowNavigateToPlanningPage: boolean
  pwaAllowUseMultiSigning: boolean
  suppressTimesOnKioskAndPwa: boolean
}

const useBasicInfoAreaModalFormData = (areaInfo: AreaItem) => {
  const { updateAreaAsync, clearArea } = AreaModalContext.useActions()
  const f = useFormatMessage()

  const { name, id, internalInformation, pwaAllowNavigateToPlanningPage, pwaAllowUseMultiSigning, suppressTimesOnKioskAndPwa } = areaInfo

  const initialValues: IBasicInfoModel = React.useMemo(
    () => ({
      name,
      internalInformation,
      pwaAllowNavigateToPlanningPage,
      pwaAllowUseMultiSigning,
      suppressTimesOnKioskAndPwa,
    }),
    [name, internalInformation, pwaAllowNavigateToPlanningPage, pwaAllowUseMultiSigning, suppressTimesOnKioskAndPwa]
  )

  const onSubmit: OnSubmitFunction<IBasicInfoModel> = React.useCallback(
    (values, { setSubmitting }) => {
      const area = {
        id,
        name: values.name,
        internalInformation: values.internalInformation,
        pwaAllowNavigateToPlanningPage: values.pwaAllowNavigateToPlanningPage,
        pwaAllowUseMultiSigning: values.pwaAllowUseMultiSigning,
        suppressTimesOnKioskAndPwa: values.suppressTimesOnKioskAndPwa,
      } as AreaItem

      updateAreaAsync(area).then(_ => {
        setSubmitting(false)
        clearArea()
      })
    },
    [id, updateAreaAsync]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<IBasicInfoModel>({
        name: yup
          .string()
          .required('Area Name is a required field')
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_FORM_FIRSTNAME_TEXTFIELD_LABEL'),
              maxsize: 128,
            })
          ),
        internalInformation: yup
          .string()
          .max(
            2000,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', { name: f('AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INTERNAL_INFORMATION_TITLE'), maxsize: 2000 })
          )
          .nullable(true),
        pwaAllowNavigateToPlanningPage: yup.boolean(),
        pwaAllowUseMultiSigning: yup.boolean(),
        suppressTimesOnKioskAndPwa: yup.boolean(),
      }),
    [f]
  )

  const areaName: Name<IBasicInfoModel> = React.useMemo(
    () => ({
      name: 'name',
      internalInformation: 'internalInformation',
      pwaAllowNavigateToPlanningPage: 'pwaAllowNavigateToPlanningPage',
      pwaAllowUseMultiSigning: 'pwaAllowUseMultiSigning',
      suppressTimesOnKioskAndPwa: 'suppressTimesOnKioskAndPwa',
    }),
    []
  )

  return {
    initialValues,
    validationSchema,
    onSubmit,
    areaName,
  }
}

export default useBasicInfoAreaModalFormData
