import React from 'react'
import { ActivityParticipantItem, CustomerListItem } from 'api/tillit.api-client'
import { Box } from '@mui/material'
import SelectInput from 'components/inputs/select-input'
import { useFormatMessage } from 'localization'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import ParticipantsList from './components/participant-list'
import { ParticipantItem } from './participant-item-type'

type Props = Readonly<{
  availableCustomers: CustomerListItem[] | undefined
  activityParticipants: ParticipantItem[] | undefined
  enableDecline?: boolean
}>

const ActivityParticipants: React.FC<Props> = ({ availableCustomers, activityParticipants, enableDecline }) => {
  const f = useFormatMessage()

  const availableCustomersExcludingParticipants = React.useMemo(() => {
    return availableCustomers?.filter(customer => !activityParticipants?.some(ap => ap.customerId === customer.id)).sort((a, b) => (a.fullName || '').localeCompare(b.fullName || ''))
  }, [availableCustomers, activityParticipants])

  const sortedActivityParticipants = React.useMemo(() => {
    return activityParticipants?.sort((a, b) => (a.customerFullName || '').localeCompare(b.customerFullName || ''))
  }, [activityParticipants])

  const handleAddActivityParticipant = (FieldArrayProps :  FieldArrayRenderProps) => (event: any, value: any) => {
    const customer = value as CustomerListItem
    FieldArrayProps.unshift(new ActivityParticipantItem({
      id: -1,
      activityId: -1,
      customerId: customer.id,
      customerFullName: customer.fullName
    }))
  }

  const getCustomerName = React.useCallback((value: any): string => {
    const customer = value as CustomerListItem
    return customer.fullName as string
  }, [])

  return (
    <Box>
      <FieldArray name={'activityParticipants'}>
        {(FieldArrayProps) => {
          return (
            <>
              <SelectInput
                data-matomo-mask
                name='selectedCustomer'
                options={availableCustomersExcludingParticipants as any}
                label={f('ACTIVITY_PARTICIPANT_CHOOSE_PARTICIPANT_TO_ADD')}
                onChange={handleAddActivityParticipant(FieldArrayProps)}
                getOptionLabel={getCustomerName}
                fullWidth
              />
              <ParticipantsList activityParticipants={sortedActivityParticipants} enableDecline={enableDecline} {...FieldArrayProps} />
            </>
          )
        }}
      </FieldArray>
    </Box>
  )
}

export default ActivityParticipants
