import { Box, Button, List, ListItemText, ListSubheader, Modal, Typography } from '@mui/material'
import { IPlannedActivityRowPlanningViewItem } from 'api/tillit.api-client'
import { format } from 'date-fns'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { StyledListItem, StyledModalBox } from './confirm-unplan-penmarked-modal-style'

const ConfirmUnplanPenMarkedModalView: React.FC = () => {
  const f = useFormatMessage()
  const { openConfirmUnplanPenMarkedModal, confirmUnplanPenMarkedModalData, confirmUnplanPenMarkedModalFutureRows } = PlanningPageContext.useState()
  const { onCancelConfirmUnplanPenMarkedModal, onYesConfirmUnplanPenMarkedModal, onNoConfirmUnplanPenMarkedModal } = PlanningPageContext.useActions()

  const getDurationInMinutes = (item: IPlannedActivityRowPlanningViewItem) => {
    const timeStart = new Date(item.startTime)
    const timeEnd = new Date(item.endTime)
    return `${(+timeEnd - +timeStart) / 60 / 1000}`
  }

  const getDurationInMinutesFromTimeSpan = (startTime: Date, endTime: Date) => {
    return `${(+endTime - +startTime) / 60 / 1000}`
  }

  const plannedStartTime = confirmUnplanPenMarkedModalData?.startTime ? format(confirmUnplanPenMarkedModalData.startTime, 'HH:mm') : ''
  const plannedEndTime = confirmUnplanPenMarkedModalData?.endTime ? format(confirmUnplanPenMarkedModalData.endTime, 'HH:mm') : ''
  const plannedDuration =
    confirmUnplanPenMarkedModalData?.startTime && confirmUnplanPenMarkedModalData?.endTime
      ? getDurationInMinutesFromTimeSpan(confirmUnplanPenMarkedModalData.startTime, confirmUnplanPenMarkedModalData.endTime)
      : ''

  const templateActivityTitle = confirmUnplanPenMarkedModalData?.templateActivityTitle
  const title = confirmUnplanPenMarkedModalData?.activity_Title
  const combinedActivityTitle = templateActivityTitle ? templateActivityTitle + ' ' + (title ? '(' + title + ')' : '') : title ? title : ''

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openConfirmUnplanPenMarkedModal}
      onClose={onCancelConfirmUnplanPenMarkedModal}>
      <StyledModalBox>
        <Box className="content">
          <Typography data-matomo-mask variant="h6">
            {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE', {
              title: combinedActivityTitle,
            })}
          </Typography>
          <Typography variant="body1" className="title">
            {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_PLANNED_TIME', {
              fromTime: plannedStartTime,
              endTime: plannedEndTime,
              duration: plannedDuration,
            })}
          </Typography>
          <Typography variant="subtitle1">{f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW1')}</Typography>
          <Typography variant="subtitle1">{f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW2')}</Typography>
          <Typography variant="body1" className="title">
            {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_TITLE_ROW3')}
          </Typography>
          <Typography variant="body1" className="title" style={{ fontStyle: 'italic' }}>
            {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_WARNING')}
          </Typography>
        </Box>

        <Box>
          <List style={{ maxHeight: '50vh', overflow: 'auto' }} subheader={<li />}>
            <div
              style={{
                position: 'sticky',
                top: '0',
                backgroundColor: '#ffffff',
                zIndex: 1000,
              }}>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '30vh',
                  maxWidth: '30vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_PLANNED')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '15vh',
                  maxWidth: '15vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_ROUTE')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '20vh',
                  maxWidth: '20vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_MODIFIED_BY')}
              </ListSubheader>
              <ListSubheader
                component="span"
                style={{
                  minWidth: '20vh',
                  maxWidth: '20vh',
                  display: 'inline-block',
                }}>
                {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_LABEL_COMMENT')}
              </ListSubheader>
            </div>
            {(confirmUnplanPenMarkedModalFutureRows || []).map((item: any) => (
              <StyledListItem key={item.id} >
                <ListItemText
                  style={{
                    minWidth: '30vh',
                    maxWidth: '30vh',
                    overflow: 'hidden',
                  }}>
                  <div>{format(item.startTime, 'yyyy-MM-dd')}</div>
                  <div>
                    {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_PLANNED_TIME', {
                      fromTime: format(item.startTime, 'HH:mm'),
                      endTime: format(item.endTime, 'HH:mm'),
                      duration: getDurationInMinutes(item),
                    })}
                  </div>
                </ListItemText>
                <ListItemText style={{ minWidth: '15vh', maxWidth: '15vh' }}>
                  <div data-matomo-mask
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.routeColor && (
                      <Box
                        style={{
                          backgroundColor: item.routeColor,
                          height: '10px',
                          width: '10px',
                          minHeight: '10px',
                          minWidth: '10px',
                          marginRight: '5px',
                          borderRadius: '25px',
                          border: '1px solid #6e6e6e',
                          display: 'inline-block',
                        }}
                      />
                    )}
                    {item.routeName}
                  </div>
                  <div>&nbsp;</div>
                </ListItemText>
                <ListItemText style={{ minWidth: '20vh', maxWidth: '20vh' }}>
                  <div data-matomo-mask
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.detachedBy}
                  </div>
                  <div>{format(item.detachedTime, 'yyyy-MM-dd HH:mm')}</div>
                </ListItemText>
                <ListItemText style={{ minWidth: '20vh', maxWidth: '20vh' }}>
                  <div data-matomo-mask
                    title={item.comment}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.comment}
                  </div>
                  <div>&nbsp;</div>
                </ListItemText>
              </StyledListItem>
            ))}
          </List>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button type="button" color="secondary" onClick={onCancelConfirmUnplanPenMarkedModal}>
            {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_CANCEL')}
          </Button>
          <Button type="button" color="secondary" onClick={onYesConfirmUnplanPenMarkedModal}>
            {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_INCL')}
          </Button>
          <Button type="button" color="secondary" onClick={onNoConfirmUnplanPenMarkedModal}>
            {f('PLANNING_PAGE_CONFIRM_UNPLAN_PENMARKED_DIALOG_BUTTON_EXCL')}
          </Button>
        </Box>
      </StyledModalBox>
    </Modal>
  )
}

export default ConfirmUnplanPenMarkedModalView
