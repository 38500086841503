import { Tooltip, Typography } from '@mui/material'
import { UserListItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import React from 'react'
import { uniqueAndSortedUserAreas } from 'utilities/user-helper'

const UserAreas: React.FunctionComponent<{ user: UserListItem }> = ({ user }) => {
    const f = useFormatMessage()
    if (!user.userAreas) {
        return null
    }
    const arrAreasName = uniqueAndSortedUserAreas(user.userAreas)
    if (arrAreasName.length <= 2) {
        return <Typography>{arrAreasName.map(ua => ua).join(', ')}</Typography>
    }

    return (
        <Tooltip title={arrAreasName.join(', ')} aria-label={arrAreasName.join(', ')}>
            <Typography>
                {f('USERS_PAGE_VIEW_COLUMN_AREAS_TEXT', {
                    firstArea: arrAreasName[0],
                    numberOfRemainingAreas: arrAreasName.length - 1,
                })}
            </Typography>
        </Tooltip>
    )
}

export default UserAreas