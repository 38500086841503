import React from 'react'
import { dashboardKpiConfig } from 'pages/dashboard/config'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'
import { DashboardKpiMainWrapperView } from './components/dashboard-kpi-wrapper'

const DashboardKpiView: React.FC = () => {
  const { currentUser } = DashboardPageContext.useState()

  const checkRequiredFeatures = React.useCallback((requiredFeatures?: string[]) : boolean => {
    if(requiredFeatures){
        return requiredFeatures.every(feature => currentUser[feature])
    }
    return true
  },[currentUser])

  return (
    <div style={{
      display:'flex',
      flexWrap:'wrap',
      paddingBottom:'30px'
    }}>

    {dashboardKpiConfig.map(kpi => {
      if(checkRequiredFeatures(kpi.requiredFeatures)){
        return <DashboardKpiMainWrapperView key={kpi.id} {...kpi}/>
      }
      return null
    })}

    </div>
  )
}

export default DashboardKpiView
