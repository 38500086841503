import { useDeviceApi, useKioskApi, useMeApi, useCustomerApi, useAreaApi, useUserApi } from 'api'
import { clearDeviceGuid, getDeviceGuid } from 'api/device-guid'
import {
  AreaDropListItem,
  CustomerListItem,
  ICustomerItem,
  IPlannedActivityRowItem,
  IPlannedActivityRowKioskDailyViewItem,
  PlannedActivityRowKioskDailyViewItem,
  IPlannedRouteKioskDailyActivitiesViewItem,
  IRouteKioskDailyActivitiesViewItem,
  IUserItem,
  PlannedActivityStatusEnum,
  SeverityLevelEnum,
  RoleEnum,
  UserAreaItem,
  KioskAreaItem,
} from 'api/tillit.api-client'
import { getOldTokenData, getTokenData, setOldTokenData, setTokenData } from 'api/token-data'
import AuthenticationContext from 'components/authentication/authentication-context'
import PageRoute from 'config/page-routes'
import { addHours, subHours } from 'date-fns'
import React from 'react'
import { createContext } from 'utilities/create-context'
import { useNavigate, useLocation } from "react-router-dom"
import { RecurrenceEnum } from 'pages/planning/components/temporary-activity-create-modal/temporary-activity-create-modal-context'
import useUserWorkScheduleApi from 'api/user-workschedule-api'
import { PlannedRouteUserWorkScheduleItem } from 'api/tenilo.workschedule.api-client'
import usePromiseApi from 'utilities/use-promise'
import format from 'utilities/format'
import Authentication from 'components/authentication'
import { SingleActivityModalContext } from './components/single-activity-modal'
import { ModalsContext } from './modals-context'
import { usePinModal, useUserModal } from './utilities'
import { HistoryModalContext } from './components/history-modal/history-modal-context'
import { FutureModalContext } from './components/future-modal/future-modal-context'
import { ExpiringDelegationsModalContext } from './components/expiring-delegations-modal/expiring-delegations-modal-context'

export interface IRouteKioskDailyActivitiesContainerItemWithHasLapsed {
  kioskAreaId?: number | undefined
  kioskAreaName?: string | undefined
  allowNavigateToPlanningPage: boolean | undefined,
  allowUseMultiSigning: boolean | undefined,
  areaInternalInformation?: string,
  organizationInternalInformation?: string,
  items?: RouteKioskDailyActivitiesViewItemWithHasLapsed[] | undefined
  useManualSessionExpiration: boolean
  manualSessionExpirationMinutes: number | undefined
  showAdditionalAreas: boolean
  showAdditionalAreasAllDay: boolean
  showAdditionalAreasEndTimeTicks: number | undefined
  showAdditionalAreasStartTimeTicks: number | undefined
  additionalAreas: KioskAreaItem[] | undefined
  suppressTimesOnKioskAndPwa: boolean
}

export type RouteKioskDailyActivitiesViewItemWithHasLapsed = Omit<IRouteKioskDailyActivitiesViewItem, 'plannedRoutes'> & {
  plannedRoutes?: PlannedRouteKioskDailyActivitiesViewItemWithHasLapsed[]
}

export type PlannedRouteKioskDailyActivitiesViewItemWithHasLapsed = Omit<IPlannedRouteKioskDailyActivitiesViewItem, 'plannedActivityRows'> & {
  plannedActivityRows?: Array<IPlannedActivityRowKioskDailyViewItem & { hasLapsed: boolean }>
}

export type CustomerCreateModel = Readonly<{
  active: boolean
  firstName?: string
  lastName?: string
  description?: string
  organizationId?: number
}>

export interface PlanedRoute {
  routeId: number
  name: string
  color?: string
  activities: PlanedActivity[]
}

export interface PlanedActivity {
  areaName: string
  plannedActivityId: number
  title?: string
  firstName?: string
  lastName?: string
  status: PlannedActivityStatusEnum
  activityColor?: string
  hasLapsed: boolean
  roomNumber?: string
  activityName?: string
  comment?: string
  lastSignedOn?: Date
  startTime: Date
  endTime: Date
  isCommonActivity: boolean
  ownerCustomerId?: number
  ownerAreaId?: number
  activityShowColorOnDevice: boolean
  templateActivityTitle?: string
  hasUnfulfilledDelegations: boolean
  severityLevelId: SeverityLevelEnum
  recurranceId: RecurrenceEnum
  useParticipantList: boolean
  unitTypeId: number | undefined
  activity_AlertFromStartInMinutes: number
}

export type SubGroupItem = Readonly<{
  ownerCustomerId?: number
  ownerAreaId?: number
}>

const DAILY_ACTIVITIES_BACKWARD_OFFSET = 3 //hours
const DAILY_ACTIVITIES_FORWARD_OFFSET = 3 //hours

type WorkSchedules = Readonly<{
  openModal: boolean,
  list: PlannedRouteUserWorkScheduleItem[]
}>

const useDailyActivitiesContext = () => {
  const { getDailyActivitiesForKioskModeForIntervalAsync, getDailyActivitiesForKioskAndAreaAsync, abort } = useDeviceApi()
  const { getKioskTokenByGuid, hasMultipleAreasConnected } = useKioskApi()
  const { updateUserSettings } = useUserApi()
  const { getRouteIdForUserWorkSchedule } = useUserWorkScheduleApi()
  const { get: getMe } = useMeApi()
  const { logOut } = AuthenticationContext.useActions()
  const { user } = AuthenticationContext.useState()
  const { state: fetchState, fetchAsync } = usePromiseApi()
  const navigate = useNavigate()
  const location = useLocation()
  const { setUser } = Authentication.useActions()

  const { listCurrentDayPlannedUserWorkSchedulesOnArea } = useUserWorkScheduleApi()

  const [localUser, setLocalUser] = React.useState<IUserItem>()
  const [openMobileTourMenu, setOpenMobileTourMenu] = React.useState<boolean>(false)
  const [openSettingsModal, setOpenSettingsModal] = React.useState<boolean>(false)
  const [internalInfoModalOpen, setInternalInfoModalOpen] = React.useState<boolean>(false)
  const [checkedOpenAssignedRouteSwitch, setCheckedOpenAssignedRouteSwitch] = React.useState(true)
  const [lockTime, setLockTime] = React.useState<Date | undefined>(undefined)
  const [showBulletinBoard, setShowBulletinBoard] = React.useState(false)


  const [latestUpdated, setLatestUpdated] = React.useState<Date>(new Date())


  React.useEffect(() => {
    if (user && user.useWorkSchedule) {
      setCheckedOpenAssignedRouteSwitch(user.openAssignedRoute)
    }
  }, [user])

  const handleToggleInternalInformationModal = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setInternalInfoModalOpen(curr => !curr)
  }, [])

  const handleToggleSettingsModal = React.useCallback(() => {
    setOpenSettingsModal(curr => !curr)
  }, [])

  const { listCustomersOnKiosk, get: getCustomer } = useCustomerApi()
  const { listAreasOnKiosk } = useAreaApi()
  const [droplistCustomerOpen, setDroplistCustomerOpen] = React.useState<boolean>(false)
  const [listCustomer, setListCustomer] = React.useState<CustomerListItem[]>()
  const [workSchedules, setWorkSchedules] = React.useState<WorkSchedules>({
    openModal: false,
    list: []
  })

  const [droplistAreaOpen, setDroplistAreaOpen] = React.useState<boolean>(false)
  const [listArea, setListArea] = React.useState<AreaDropListItem[]>()

  const [routeIdForUserWorkSchedule, setRouteIdForUserWorkSchedule] = React.useState<number>()

  React.useEffect(() => {
    if (localUser && localUser.useWorkSchedule && localUser.openAssignedRoute) {
      fetchAsync(getRouteIdForUserWorkSchedule(localUser.id))
        .then((routeId: number) => {
          if (routeId === -1) {
            setRouteIdForUserWorkSchedule(undefined)
          } else {
            setRouteIdForUserWorkSchedule(routeId)
          }
        })
        .catch(() => {
          setRouteIdForUserWorkSchedule(undefined)
        })
    } else {
      setRouteIdForUserWorkSchedule(undefined)
    }
  }, [localUser])

  const [data, setData] = React.useState<IRouteKioskDailyActivitiesContainerItemWithHasLapsed | null>(null)
  const [selectedAdditionalAreaId, setSelectedAdditionalAreaId] = React.useState<number | undefined>()

  const getAllCustomers = React.useCallback(() => {
    const deviceGuid = getDeviceGuid()
    if (!deviceGuid) {
      return //No device guid
    }
    void listCustomersOnKiosk(deviceGuid).then(list => {
      if (data?.kioskAreaId) {
        list = list.filter(c => c.customerAreas?.some(ca => ca.areaId === data?.kioskAreaId))
      }

      setListCustomer(list)
    })
  }, [listCustomersOnKiosk, setListCustomer, data])

  const toggleOpenMobileTourMenu = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setOpenMobileTourMenu(!openMobileTourMenu)
  }, [openMobileTourMenu, setOpenMobileTourMenu])

  const getWorkSchedulesToday = React.useCallback(
    async (areaList: AreaDropListItem[]) => {
      Promise.all(areaList.map(async area => {
        return listCurrentDayPlannedUserWorkSchedulesOnArea(area.areaId)
          .then(workScheduleList => workScheduleList)
          .catch(() => [])
      })).then(res => {
        void setWorkSchedules((prev) => {
          return {
            ...prev,
            list: res.reduce((arr, item) => [...arr, ...item], [])
          }
        })
      });
    },
    []
  )

  const openWorkScheduleDialog = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setWorkSchedules((prev) => {
      return {
        ...prev,
        openModal: true
      }
    })
  }, [])

  const closeWorkScheduleDialog = React.useCallback(() => {
    setWorkSchedules((prev) => {
      return {
        ...prev,
        openModal: false,
      }
    })
  }, [])

  React.useEffect(() => {
    if (localUser && localUser.useWorkSchedule) {
      if (!listArea) {
        const deviceGuid = getDeviceGuid()
        if (!deviceGuid) { return }
        void listAreasOnKiosk(deviceGuid).then(getWorkSchedulesToday)
      }
      else {
        void getWorkSchedulesToday(listArea)
      }
    }
  }, [localUser])

  const getAllAreas = React.useCallback(() => {
    const deviceGuid = getDeviceGuid()
    if (!deviceGuid) {
      return //No device guid'
    }
    void listAreasOnKiosk(deviceGuid).then(list => {
      setListArea(list)
    })
  }, [listAreasOnKiosk, setListArea])

  const openDroplistCustomer = React.useCallback(
    (routeId: number) => {
      getAllCustomers()
      setDroplistCustomerOpen(true)
      getAllAreas()
      setDroplistAreaOpen(true)
    },
    [getAllCustomers, setDroplistCustomerOpen, getAllAreas, setDroplistAreaOpen]
  )
  const [customerInfo, setCustomerInfo] = React.useState<ICustomerItem | null>(null)
  const clearCustomerInfo = React.useCallback(() => setCustomerInfo(null), [setCustomerInfo])
  const [selectedRouteId, setSelectedRouteId] = React.useState<number>()
  const [selectedAreaId, setSelectedAreaId] = React.useState<number>()

  const customerSelected = React.useCallback(
    async (customer: CustomerListItem) => {
      await getCustomer(customer.id).then(customerDetail => {
        setCustomerInfo(customerDetail)
      })
    },
    [getCustomer]
  )


  // Used to keep track of the plannedActivityRowId that will be shown after user logged in with pin
  // Is cleared when user logs in
  const [pendingPlannedActivityId, setPendingPlannedActivityId] = React.useState<number | null>(null)

  // const [locked, setLocked] = React.useState(true)
  const locked = React.useMemo(() => !localUser, [localUser])
  const fetchMe = React.useCallback(() => {
    return fetchAsync(getMe().then(user => {
      setLocalUser(user)
      return user
    }))
  }, [])

  const userModal = useUserModal()
  const fetchMePinModalCb = React.useCallback(() => {
    return getMe().then(user => {
      setLocalUser(user)
      return user
    })
  }, [])
  const pinModal = usePinModal(fetchMePinModalCb)
  const { closeHistoryModal } = HistoryModalContext.useActions()
  const { closeFutureModal } = FutureModalContext.useActions()
  const { closeExpiringDelegationsModal } = ExpiringDelegationsModalContext.useActions()
  const { setCreateActivityModalOpen } = ModalsContext.useActions()

  const {
    fetchActivity,
    setOpen: setSingleActivityModalOpen,
    setListingModalOpen,
    setPlannedActivity,
    setIsPartiallyCompletedModalOpen
  } = SingleActivityModalContext.useActions()

  const onUserSelect = (userId: number) => {
    userModal.actions.setOpen(false)
    userModal.actions.setSelectedUser(userId)
    pinModal.actions.open()
  }

  const onLockClick = () => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    if (locked) {
      userModal.actions.setOpen(true)
      userModal.actions.fetchUsers()
    } else {
      const oldTokenData = getOldTokenData()
      setLocalUser(undefined)

      if (oldTokenData) {
        setTokenData(oldTokenData)
      }
    }
    setPendingPlannedActivityId(null)
  }

  const closeUserModal = React.useCallback(() => userModal.actions.setOpen(false), [])
  const onPlanedActivityClicked = React.useCallback(
    (plannedActivityId: number) => {

      if (locked) {
        setPendingPlannedActivityId(plannedActivityId)
        userModal.actions.setOpen(true)
        userModal.actions.fetchUsers()
      } else {
        fetchActivity(plannedActivityId)
          .then(_ => {
            setSingleActivityModalOpen(true)
          })
          .catch(_ => {
            setLocalUser(undefined)
          })
      }
    },
    [locked, setPendingPlannedActivityId, fetchActivity, setSingleActivityModalOpen, userModal.actions]
  )

  const onSingleActivityUndoOpen = React.useCallback(
    (plannedActivity: IPlannedActivityRowItem) => {
      setListingModalOpen(true)
      setPlannedActivity(plannedActivity)
    },
    [setListingModalOpen, setPlannedActivity]
  )

  const calculateHasLapsed = React.useCallback((dailyActivities: IRouteKioskDailyActivitiesViewItem[]): RouteKioskDailyActivitiesViewItemWithHasLapsed[] => {
    const b = dailyActivities.reduce<RouteKioskDailyActivitiesViewItemWithHasLapsed[]>((prev, dailyActivity) => {
      const { plannedRoutes } = dailyActivity
      if (plannedRoutes) {
        const c: RouteKioskDailyActivitiesViewItemWithHasLapsed = {
          ...dailyActivity,
          plannedRoutes: plannedRoutes.map(plannedRoute => {
            const { plannedActivityRows = [] } = plannedRoute
            const plannedActivityRowsWithLapsed: Array<IPlannedActivityRowKioskDailyViewItem & {
              hasLapsed: boolean
            }> = plannedActivityRows.map(calculateHasLapsedSingle)
            const d: PlannedRouteKioskDailyActivitiesViewItemWithHasLapsed = {
              ...plannedRoute,
              plannedActivityRows: plannedActivityRowsWithLapsed,
            }
            return d
          }),
        }
        return [...prev, c]
      }
      return prev
    }, [])
    return b
  }, [])

  const getDailyActivities = React.useCallback(
    (deviceGuid: string) => {
      setLatestUpdated(new Date())

      if (localUser && selectedAdditionalAreaId) {
        if (!localUser.userAreas?.some(ua => ua.areaId === selectedAdditionalAreaId && ua.roleId === RoleEnum.Caretaker)) {
          setSelectedAdditionalAreaId(undefined)
        }
      }

      if (selectedAdditionalAreaId) {
        return getDailyActivitiesForKioskAndAreaAsync(
          deviceGuid,
          selectedAdditionalAreaId,
          subHours(new Date(), DAILY_ACTIVITIES_BACKWARD_OFFSET),
          addHours(new Date(), locked ? DAILY_ACTIVITIES_FORWARD_OFFSET : localUser?.futureActivityTimeInHours ? localUser.futureActivityTimeInHours : DAILY_ACTIVITIES_FORWARD_OFFSET)
        )
      }

      return getDailyActivitiesForKioskModeForIntervalAsync(
        deviceGuid,
        subHours(new Date(), DAILY_ACTIVITIES_BACKWARD_OFFSET),
        addHours(new Date(), locked ? DAILY_ACTIVITIES_FORWARD_OFFSET : localUser?.futureActivityTimeInHours ? localUser.futureActivityTimeInHours : DAILY_ACTIVITIES_FORWARD_OFFSET)
      )
    },
    [getDailyActivitiesForKioskModeForIntervalAsync, getDailyActivitiesForKioskAndAreaAsync, localUser, locked, selectedAdditionalAreaId]
  )
  const [hasMultipleAreasOnKiosk, setHasMultipleAreasOnKiosk] = React.useState<boolean>(false)

  const updateDataOnStatusChanged = React.useCallback(
    (plannedActivity: IPlannedActivityRowItem) => {
      const deviceGuid = getDeviceGuid()
      if (!deviceGuid) {
        return //No device guid
      }

      setData(prevState => {
        if (!prevState) {
          return null
        }

        return {
          ...prevState,
          items: (prevState.items || []).map(item => ({
            ...item,
            plannedRoutes: (item.plannedRoutes || []).map(pr => ({
              ...pr,
              plannedActivityRows: (pr.plannedActivityRows || []).map(par => {
                if (par.id === plannedActivity.id) {
                  return {
                    ...par,
                    plannedActivityStatusId: plannedActivity.plannedActivityStatusId,
                    lastSignedOn: plannedActivity.signing_LatestSigningDate,
                    lastSignedById: plannedActivity.signing_LatestSigningUser?.id,
                  };
                }
                return par;
              }),
            })),
          })),
        }
      })

    },
    [fetchAsync, getDailyActivities, calculateHasLapsed, logOut]
  )

  const refreshData = React.useCallback(() => {
    const deviceGuid = getDeviceGuid()
    if (!deviceGuid) {
      return //No device guid
    }
    void hasMultipleAreasConnected(deviceGuid).then(result => {
      if (result) {
        setHasMultipleAreasOnKiosk(result)
      }
    })
    void fetchAsync(getDailyActivities(deviceGuid)).then(result => {
      if (result == null) {
        logOut()
        //SK: WHY???        clearDeviceGuid()
        return
      }
      setData({
        kioskAreaId: result.kioskAreaId,
        kioskAreaName: result.kioskAreaName,
        allowNavigateToPlanningPage: result.allowNavigateToPlanningPage,
        allowUseMultiSigning: result.allowUseMultiSigning,
        useManualSessionExpiration: result.useManualSessionExpiration,
        manualSessionExpirationMinutes: result.manualSessionExpirationMinutes,
        areaInternalInformation: result.areaInternalInformation,
        organizationInternalInformation: result.orgInternalInformation,
        items: (result.items && calculateHasLapsed(result.items!)) || undefined,
        showAdditionalAreas: result.showAdditionalAreas,
        showAdditionalAreasAllDay: result.showAdditionalAreasAllDay,
        showAdditionalAreasStartTimeTicks: result.showAdditionalAreasStartTimeTicks,
        showAdditionalAreasEndTimeTicks: result.showAdditionalAreasEndTimeTicks,
        additionalAreas: result.additionalAreas || undefined,
        suppressTimesOnKioskAndPwa: result.suppressTimesOnKioskAndPwa,
      })
    })
  }, [fetchAsync, getDailyActivities, hasMultipleAreasConnected, setHasMultipleAreasOnKiosk, calculateHasLapsed, logOut])

  const onSingleActivityRefresh = React.useCallback(() => {
    refreshData()
  }, [refreshData])

  const calculateHasLapsedSingle = (
    activity: IPlannedActivityRowItem | IPlannedActivityRowKioskDailyViewItem
  ): IPlannedActivityRowKioskDailyViewItem & {
    hasLapsed: boolean
  } => {
    let lapsed = false
    if (activity.activity_AlertFromStartInMinutes > 0) {
      const currentTime = new Date()
      const endTime = new Date(activity.startTime.getTime() + activity.activity_AlertFromStartInMinutes * 60000)
      lapsed = endTime < currentTime
    }

    let hasUnfulfilledDelegations = false
    let activity_RecurranceId = RecurrenceEnum.Daily
    if (activity instanceof PlannedActivityRowKioskDailyViewItem) {
      const item = activity as PlannedActivityRowKioskDailyViewItem
      hasUnfulfilledDelegations = item.hasUnfulfilledDelegations
      activity_RecurranceId = item.activity_RecurranceId
    }

    return { ...activity, hasLapsed: lapsed, hasUnfulfilledDelegations, activity_RecurranceId }
  }

  React.useEffect(() => {
    const oldTokenData = getOldTokenData()

    if (oldTokenData && !localUser) {
      setTokenData(oldTokenData)
    }
  }, [localUser])

  // Call abort on cleanup
  React.useEffect(() => () => abort(), [abort])
  const timeout = React.useRef<number | undefined>()
  const userTimer = React.useRef<number | undefined>()
  const lockTimeout = React.useRef<number | undefined>()

  const stopTimers = React.useCallback(() => {
    clearInterval(timeout.current)
    clearInterval(lockTimeout.current)
    window.clearTimeout(userTimer.current)
  }, [])

  const linkToPlanningPage = React.useCallback((currentUser: IUserItem | undefined) => {
    if (!currentUser) {
      return
    }
    if (!data?.kioskAreaId) {
      return
    }
    const areaId = data.kioskAreaId
    const areaName = data.kioskAreaName

    stopTimers()

    setUser({
      ...currentUser,
      organizationId: currentUser.organizationId,
      organizationName: currentUser.organizationName,
      userAreas: [new UserAreaItem({
        id: -1,
        userId: currentUser.id,
        areaId,
        areaName,
        roleId: RoleEnum.Caretaker,
        hasRoutes: true,
        hasChildAreas: false,
      })]
    })

    const planningPath =
      PageRoute.Planning.path
        .replace(':areaId', areaId.toString())
        .replace(':date', format(new Date(), 'P'))
        .replace(':type', 'areas')
        .replace(':usingCalendarFromDevice', 'true')
    navigate(planningPath, { replace: true, state: { from: location } })
  }, [data, stopTimers, setUser, navigate])


  React.useEffect(() => {
    const deviceGuid = getDeviceGuid()
    if (!deviceGuid) {
      navigate(PageRoute.Profile.path, { replace: true });
      return
    }

    const tokenData = getTokenData()
    if (!tokenData) {
      return
    }

    void hasMultipleAreasConnected(deviceGuid).then(result => {
      if (result) {
        setHasMultipleAreasOnKiosk(result)
      }
    })

    const fetchDailyActivities = () => {
      return getDailyActivities(deviceGuid).then(result => {
        if (result == null) {
          logOut()
          //SK: WHY???          clearDeviceGuid()
          return
        }
        setData({
          kioskAreaId: result.kioskAreaId,
          kioskAreaName: result.kioskAreaName,
          allowNavigateToPlanningPage: result.allowNavigateToPlanningPage,
          allowUseMultiSigning: result.allowUseMultiSigning,
          useManualSessionExpiration: result.useManualSessionExpiration,
          manualSessionExpirationMinutes: result.manualSessionExpirationMinutes,
          areaInternalInformation: result.areaInternalInformation,
          organizationInternalInformation: result.orgInternalInformation,
          items: (result.items && calculateHasLapsed(result.items!)) || undefined,
          showAdditionalAreas: result.showAdditionalAreas,
          showAdditionalAreasAllDay: result.showAdditionalAreasAllDay,
          showAdditionalAreasStartTimeTicks: result.showAdditionalAreasStartTimeTicks,
          showAdditionalAreasEndTimeTicks: result.showAdditionalAreasEndTimeTicks,
          additionalAreas: result.additionalAreas || undefined,
          suppressTimesOnKioskAndPwa: result.suppressTimesOnKioskAndPwa,
        })
      })
    }

    if (!localUser && !!user) {
      fetchAsync(
        getKioskTokenByGuid(deviceGuid).then(updatedToken =>
          // The logout will trigger a re-render and then we will fetch the daily activities
          logOut().then(() => {
            setTokenData(updatedToken)
            setOldTokenData(updatedToken)
            fetchDailyActivities()
          })
        )
      )
    } else {

      if (locked) {
        fetchDailyActivities()
      } else {
        fetchDailyActivities() // If unlocked. We might want to NOT fetchDailyActivities. But this introduced some other issues
      }

    }
    timeout.current = window.setInterval(fetchDailyActivities, 5 * 60 * 1000)
    return () => {
      clearInterval(timeout.current)
    }
  }, [
    calculateHasLapsed,
    fetchAsync,
    getDailyActivities,
    hasMultipleAreasConnected,
    getKioskTokenByGuid,
    localUser,
    logOut,
    user,
    locked,
    setLatestUpdated,
  ])

  React.useEffect(() => {
    const logout = () => {
      setShowBulletinBoard(false)
      setLocalUser(undefined)
      setLockTime(undefined)
      setOpen.current(false)
      closeHistory.current()
      closeFuture.current()
      closeExpiringDelegations.current()
      setCreateActivity.current(false)
      setDroplistCustomer.current(false)
      closeWorkschedules.current()
      setIsPartiallyCompletedModalOpen(false)
      const oldTokenData = getOldTokenData()
      if (oldTokenData) {
        setTokenData(oldTokenData)
      }
      navigate(PageRoute.DailyActivities.path)
    }

    const lockDevice = () => {
      const currentTime = new Date()
      if (lockTime && (currentTime.getTime() > lockTime?.getTime())) {
        logout()
      }
    }

    if (!locked && lockTime) {
      lockTimeout.current = window.setInterval(lockDevice, 1 * 1000)
    }

    return () => {
      clearInterval(lockTimeout.current)
    }
  }, [
    locked, lockTime, logOut
  ])


  // NOTE: When adding new modals we need to make sure to close them upon automatic logout.
  // This is done below!
  const setOpen = React.useRef(setSingleActivityModalOpen)
  const closeHistory = React.useRef(closeHistoryModal)
  const closeFuture = React.useRef(closeFutureModal)
  const closeExpiringDelegations = React.useRef(closeExpiringDelegationsModal)
  const setCreateActivity = React.useRef(setCreateActivityModalOpen)
  const setDroplistCustomer = React.useRef(setDroplistCustomerOpen)
  const closeWorkschedules = React.useRef(closeWorkScheduleDialog)

  React.useEffect(() => {
    if (!locked) {
      getAllCustomers();
      const documentBody = window.document.body
      const mc = new Hammer(documentBody)
      mc.add(new Hammer.Press({ time: 0 }))

      const startTimer = () => {
        clearInterval(timeout.current)
        const sessionLengthMinutes = data?.useManualSessionExpiration ? (data.manualSessionExpirationMinutes ? data.manualSessionExpirationMinutes : 2) : 2
        setLockTime(new Date((new Date()).getTime() + sessionLengthMinutes * 60000))
      }

      // Initiate the timer
      startTimer()
      mc.on('press', startTimer)
      documentBody.addEventListener('keypress', startTimer)
      return () => {
        mc.off('press', startTimer)
        documentBody.removeEventListener('keypress', startTimer)
        stopTimers()
      }
    }
    return () => {
      stopTimers()
    }
  }, [locked, getAllCustomers])

  React.useEffect(() => {
    if (!listArea) {
      getAllAreas()
    }
  }, [localUser])

  React.useEffect(() => {
    if (userModal.state.selectedUser !== null && pinModal.state.pin.length === 4) {
      // Always clear PIN after four characters
      pinModal.actions.clearPin()

      // login
      pinModal.actions.verifyUser(userModal.state.selectedUser).then(result => {
        if (result.success) {
          pinModal.actions.close()
          userModal.actions.setOpen(false)
          if (pendingPlannedActivityId) {
            setPendingPlannedActivityId(null)
            if (result.user && selectedAdditionalAreaId) {
              if (!result.user.userAreas?.some(ua => ua.areaId === selectedAdditionalAreaId && ua.roleId === RoleEnum.Caretaker)) {
                return
              }
            }

            fetchActivity(pendingPlannedActivityId).then(() => {
              setSingleActivityModalOpen(true)
            })
          }
        }
      })
    }
  }, [
    pinModal.state.pin.length,
    setSingleActivityModalOpen,
    userModal.state.selectedUser,
    pendingPlannedActivityId,
    localUser,
    selectedAdditionalAreaId,

  ])

  return {
    state: {
      ...fetchState,
      user: localUser,
      data,
      locked,
      userModal: userModal.state,
      pinModal: pinModal.state,
      droplistCustomerOpen,
      listCustomer,
      droplistAreaOpen,
      listArea,
      customerInfo,
      selectedRouteId,
      selectedAreaId,
      selectedAdditionalAreaId,
      hasMultipleAreasOnKiosk,
      openMobileTourMenu,
      routeIdForUserWorkSchedule,
      workSchedules,
      openSettingsModal,
      checkedOpenAssignedRouteSwitch,
      latestUpdated,
      internalInfoModalOpen,
      lockTime,
      showBulletinBoard,
    },
    actions: {
      userModal: userModal.actions,
      pinModal: pinModal.actions,
      onUserSelect,
      onLockClick,
      closeUserModal,
      onPlanedActivityClicked,
      onSingleActivityUndoOpen,
      updateDataOnStatusChanged,
      fetchMe,
      setDroplistCustomerOpen,
      getAllCustomers,
      openDroplistCustomer,
      getAllAreas,
      setDroplistAreaOpen,
      setCustomerInfo,
      customerSelected,
      clearCustomerInfo,
      setSelectedRouteId,
      setSelectedAreaId,
      setSelectedAdditionalAreaId,
      refreshData,
      toggleOpenMobileTourMenu,
      onSingleActivityRefresh,
      openWorkScheduleDialog,
      closeWorkScheduleDialog,
      linkToPlanningPage,
      handleToggleSettingsModal,
      updateUserSettings,
      handleToggleInternalInformationModal,
      setShowBulletinBoard,
    },
  }
}

export const DailyActivitiesContext = createContext(useDailyActivitiesContext)
