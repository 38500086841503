import FileSaver from 'file-saver'
import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction, UseFormHook } from 'types'
import format from 'utilities/format'
import * as yup from 'yup'
import { InvoiceFormContext } from '../invoice-form-context'

type InvoiceFormModel = Readonly<{
  organizationId?: number
  fromDate: Date | null
  toDate: Date | null
}>

export const useInvoiceForm: UseFormHook<InvoiceFormModel> = () => {
  const { fetchInvoice } = InvoiceFormContext.useActions()
  const f = useFormatMessage()
  const initialValues: InvoiceFormModel = { fromDate: null, toDate: null }
  const onSubmit: OnSubmitFunction<InvoiceFormModel> = (
    values,
    { setSubmitting, setStatus, setTouched }
  ) => {
    fetchInvoice(values.fromDate!, values.toDate!, values.organizationId!)
      .then(fileResponse =>
        FileSaver.saveAs(
          fileResponse.data,
          fileResponse.fileName ||
            f('INVOICE_FORM_CONTAINER_FILE_NAME', {
              fromDate: format(values.fromDate!, 'ddMMyy'),
              toDate: format(values.toDate!, 'ddMMyy'),
            })
        )
      )
      .catch(error => setStatus({ error: error.response }))
      .finally(() => setSubmitting(false))
  }

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<InvoiceFormModel>({
        fromDate: yup.date().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        toDate: yup.date().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        organizationId: yup
          .number()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      }),
    [f]
  )

  const name: Name<InvoiceFormModel> = {
    fromDate: 'fromDate',
    toDate: 'toDate',
    organizationId: 'organizationId',
  }
  return { initialValues, onSubmit, validationSchema, name }
}
