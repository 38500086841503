import React from 'react'
import {
    DrugListClient,
    OrganizationSigningStatusesItem,
    OrganizationPlannedActivityStatusItem,
    OrganizationPlannedActivityStatusCreateItem,
    IOrganizationPlannedActivityStatusCreateItem,
    OrganizationPlannedActivityStatusUpdateItem,
    IOrganizationPlannedActivityStatusUpdateItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class SigningStatusApi {
    private readonly abortController = new AbortController()
    private readonly client: DrugListClient

    public constructor() {
        this.client = new DrugListClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public listSigningStatuses = (organizationId: number): Promise<OrganizationSigningStatusesItem> =>
        this.client
            .listOrganizationSigningStatuses(organizationId)
            .then(statuses =>
                statuses === null
                    ? Promise.reject(`No statuses received from backend for organization id ${organizationId}.`)
                    : Promise.resolve(statuses)
            )

    public createSigningStatus = async (status: IOrganizationPlannedActivityStatusCreateItem): Promise<OrganizationPlannedActivityStatusItem> => {
      const model = new OrganizationPlannedActivityStatusCreateItem(status)
      return this.client
            .createOrganizationPlannedActivityStatus(model)
            .then(res =>
                res === null
                    ? Promise.reject('When creating an status, no status item received from backend.')
                    : Promise.resolve(res)
            )
    }

    public updateSigningStatus = async (status: IOrganizationPlannedActivityStatusUpdateItem): Promise<OrganizationPlannedActivityStatusItem> =>{
      const model = new OrganizationPlannedActivityStatusUpdateItem(status)
      return this.client
          .updateOrganizationPlannedActivityStatus(model)
          .then(res =>
              res === null
                  ? Promise.reject('When updating an status, no status item received from backend.')
                  : Promise.resolve(res)
          )
    }

    public RemoveSigningStatus = async (statusId: number) => {
      return this.client
          .deleteOrganizationPlannedActivityStatus(statusId)
          .then(() => Promise.resolve(statusId))
    }

}

const useSigningStatusApi = () => React.useMemo(() => new SigningStatusApi(), [])

export default useSigningStatusApi
