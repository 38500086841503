import { PropTypes } from '@mui/material'
import { Palette } from '@mui/material/styles'
import styled, { css } from 'styled-components'
import OutlinedTextFieldView from './outlined-text-field-view'

const getColor = (palette: Palette, color?: PropTypes.Color): string | undefined => (color && palette.hasOwnProperty(color) ? palette[color].main : undefined)

const OutlineTextFieldStyle = styled(OutlinedTextFieldView)(props => {
  const color = getColor(props.theme.palette, props.color)
  return color
    ? css`
        & label.Mui-focused {
          color: ${color};
        }

        .MuiOutlinedInput-root {
          &:hover fieldset {
            border-color: ${color};
          }

          &.Mui-focused fieldset {
            border-color: ${color};
          }
        }
      `
    : ''
})

export default OutlineTextFieldStyle
