import React from 'react'
import { Box, Button } from '@mui/material'
import CenterModal from 'components/center-modal'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import { Form, Formik } from 'formik'
import ActivityParticipants from 'components/activity-participants'
import { IPlannedActivityRowItem, PlannedActivitySignedParticipantCreateItem, CustomerListItem } from 'api/tillit.api-client'
import useFormData from './utilities/use-single-activity-participants-modal-form-data'

interface ISigningParticipantsModalContainerProps {
  withComment: boolean
  open: boolean
  plannedActivity: IPlannedActivityRowItem
  customers: CustomerListItem[] | undefined
  onSaveClick: (plannedActivitySignedParticipants: PlannedActivitySignedParticipantCreateItem[] | undefined, comment?: string) => void
  onCloseClick: () => void
}

const SingleActivityParticipantsModalContainer: React.FC<ISigningParticipantsModalContainerProps> = ({ withComment, open, plannedActivity, customers, onSaveClick, onCloseClick }) => {

  const handleSubmit = (plannedActivitySignedParticipants: PlannedActivitySignedParticipantCreateItem[] | undefined, comment?: string) => {
    onSaveClick(plannedActivitySignedParticipants, comment)
  }

  const handleClose = () => {
    onCloseClick()
  }

  const f = useFormatMessage()
  const { initialValues, name, onSubmit, validationSchema } = useFormData(plannedActivity, handleSubmit)

  return (
    <CenterModal open={open} onClose={onCloseClick} responsive>
      <ModalHeader data-matomo-mask title={withComment ? f('SIGNING_PARTICIPANTS_MODAL_TITLE_WITHCOMMENT') : f('SIGNING_PARTICIPANTS_MODAL_TITLE')} responsive />
      <Box sx={(theme) => ({
        px: 4,
        minWidth: '500px',
        [theme.breakpoints.down('md')]: {
          px: 1,
          minWidth: 'auto',
        }
      })}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, errors }) => (
            <Form translate="yes">
              <Box className="content">
                {withComment && (
                  <FilledTextField
                    data-matomo-mask
                    label={f('LISTING_MODAL_LISTING_FIELD_LABEL')}
                    fullWidth
                    multiline
                    rows="3"
                    margin="normal"
                    name={name.comment}
                  />
                )}
                <ActivityParticipants
                  activityParticipants={values.activityParticipants}
                  availableCustomers={customers}
                  enableDecline
                />
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button type="button" color="secondary" onClick={handleClose}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </Button>
                <Button type="submit" color="secondary">
                  {f('BASIC_BUTTON_SAVE_TEXT')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CenterModal>
  )
}

export default React.memo(SingleActivityParticipantsModalContainer)
