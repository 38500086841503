import styled from 'styled-components'
import Colors from 'styles/colors'
import DailyActivityGroupItemContainer from './daily-activity-group-item-container'

const DailyActivityGroupItemStyle = styled(DailyActivityGroupItemContainer)`
  width: 162px;
  height: 50px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px, rgba(0, 0, 0, 0.19) 0px 6px 6px;
  display: flex;
  align-items: center;
  ${props => (props.isActive ? `color: ${Colors.White};` : '')}
  background-color: ${props => (props.isActive ? props.color : 'white')};
  box-sizing: border-box;
  * {
    box-sizing: border-box
  };
  .right-pane {
    width: 24px;
    height: 100%;
    background-color: ${props => props.color}
  };
  .left-pane {
    flex: 1;
    width:100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  ${props => [props.theme.breakpoints.down('md')]}{
    width: 80%;
    max-width: 400px;
    height: 35px;
    margin: 10px auto;
  }
`

export default DailyActivityGroupItemStyle
