import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import { DeleteDialog } from 'components/delete-dialog'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'

const DeleteDialogContainer: React.FC<{
  dialogDelete: boolean
  setDialogDelete: (value: React.SetStateAction<boolean>) => void
  selectedRoute: IPlannedRoutesActivitiesPlanningViewItem | undefined
}> = ({ dialogDelete, setDialogDelete, selectedRoute }) => {
  const f = useFormatMessage()
  const { removeRouteAsync } = PlanningPageContext.useActions()

  const handleClose = () => {
    setDialogDelete(false)
  }

  const handleRemoveRoute = React.useCallback(() => {
    if (selectedRoute) {
      removeRouteAsync(selectedRoute)
    }
    setDialogDelete(false)
  }, [removeRouteAsync, selectedRoute, setDialogDelete])

  return (
    <DeleteDialog
      dialogTitle={f(
        'PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_TITLE'
      )}
      dialogContentText={f(
        'PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_MESSAGE',
        {
          route: selectedRoute?.name,
        }
      )}
      buttonSubmitText={f(
        'PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_REMOVE'
      )}
      buttonCancelText={f(
        'PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_CANCEL'
      )}
      deleteDialogOpen={dialogDelete}
      onClose={handleClose}
      onSubmit={handleRemoveRoute}
    />
  )
}

export default DeleteDialogContainer
