import React from 'react'
import { Colors } from 'styles/colors'
import { SizeS,SizeM,SizeL } from 'mdi-material-ui'
import Icon from '@mui/material/Icon'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'


const DashboardHeaderView: React.FC = () => {
  const { handleLayoutSize } = DashboardPageContext.useActions()
  const { layoutSize } = DashboardPageContext.useState()

  return (
    <>
        <Icon onClick={handleLayoutSize('s')}  component={SizeS} sx={{
        backgroundColor: layoutSize === 's' ? Colors.TeniloGreen : Colors.AthensGray,
        color: layoutSize === 's' ? Colors.AthensGray : Colors.TeniloGreen,
        fontSize: '1.2rem'
        }} />
        <Icon onClick={handleLayoutSize('m')}  component={SizeM} sx={{
        backgroundColor: layoutSize === 'm' ? Colors.TeniloGreen : Colors.AthensGray,
        color: layoutSize === 'm' ? Colors.AthensGray : Colors.TeniloGreen,
        fontSize: '1.2rem'
        }} />
        <Icon onClick={handleLayoutSize('l')}  component={SizeL} sx={{
        backgroundColor: layoutSize === 'l' ? Colors.TeniloGreen : Colors.AthensGray,
        color: layoutSize === 'l' ? Colors.AthensGray : Colors.TeniloGreen,
        fontSize: '1.2rem'
        }} />
    </>
  )
}

export default DashboardHeaderView
