import { IconButton } from '@mui/material'
import { PropTypes } from '@mui/material'
import { FilledTextFieldProps } from '@mui/material/TextField'
import React from 'react'
import FilledTextField from 'components/inputs/filled-text-field'
import InputAdornment from '@mui/material/InputAdornment'
import { Magnify, Close } from 'mdi-material-ui'

type Props = Omit<FilledTextFieldProps, 'variant' | 'color'> & {
  color?: PropTypes.Color
  searchValue: string | undefined
  handleChangeFilter: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  handleClearFilter: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const FilledSearchTextField: React.FC<Props> = ({ searchValue, handleChangeFilter, handleClearFilter, ...props }) => {
  return (
    <FilledTextField
      data-matomo-mask
      type="text"
      fullWidth={true}
      label={props.label}
      color="secondary"
      value={searchValue}
      onChange={handleChangeFilter}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Magnify color={'secondary'} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleClearFilter} size="small" disabled={!searchValue || searchValue.length === 0}>
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default FilledSearchTextField
