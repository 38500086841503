import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { Box, Typography } from '@mui/material'
import ReportsPageContext from 'pages/reports/reports-page-context'
import type { InnerContainerPropType } from 'pages/reports/config'
import { ReportTextField } from '../reports-page-inputs'


const ReportsPageInfoSectionView: FC<InnerContainerPropType> = ({ }) => {
  const f = useFormatMessage()
  const { report, userReport } = ReportsPageContext.useState()

  return React.useMemo(() => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        margin: '0px 0px 10px 0'
      }}>
        {userReport && (
          <>
            <ReportTextField name="updateName" label={f('REPORT_PAGE_VIEW_FILTER_SELECTION_NAME')} initialValue={userReport?.reportName} applyRequirement/>
            <ReportTextField name="updateDescription" multiline label={f('REPORT_PAGE_VIEW_FILTER_SELECTION_DESCRIPTION')} initialValue={userReport?.reportDescription} />
          </>
        )}
        <Typography variant="subtitle2">{report?.reportDescription}</Typography>
      </Box>
    )
  }, [report, userReport])
}

export default ReportsPageInfoSectionView
