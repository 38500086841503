import React, { useState, useEffect } from 'react'
import { useDelegationApi } from 'api'
import { Box, MenuItem } from '@mui/material';
import { useFormatMessage } from 'localization'
import { FieldArrayRenderProps } from 'formik'
import FilledTextField from 'components/inputs/filled-text-field'
import { UserDelegationAvailablePlacementsItem } from 'api/tillit.api-client'
import styled, { keyframes } from 'styled-components'
import DelegationSectionContext from 'components/user-dialog/components/delegation-section/delegation-section-context'
import ModalSection from 'components/modal-section'
import DatePicker from 'components/inputs/date-picker'
import FilledSelect from 'components/inputs/filled-select'

type Props = Readonly<
  {
    delegationIndex: number
    placementIndex: number
    delegationId: any
    isSavedPlacement: boolean
  } & FieldArrayRenderProps
>

const show = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
`;


const UserDelegationPlacementCardDynamicContentContainer = styled(Box)`
  overflow: hidden;
  animation: ${show} .15s ease-in;
  width: 100%;
  padding-top:30px;
`


const UserDelegationPlacementCardDynamicContent: React.FC<Props> = ({ delegationIndex, placementIndex, delegationId, isSavedPlacement, ...props }) => {
  const f = useFormatMessage()
  const [delegationAvailableItem, setDelegationAvailableItem] = useState<UserDelegationAvailablePlacementsItem | null>()
  const { fetchDelegationAvailableItem } = DelegationSectionContext.useActions()
  const { abort } = useDelegationApi()

  useEffect(() => {
    if (delegationId) {
      fetchDelegationAvailableItem(delegationId).then(setDelegationAvailableItem)
    } else {
      setDelegationAvailableItem(null)
    }
    return () => abort()
  }, [delegationId]);

  const handleChangeUserDelegationArea = (event: any, child: any) => {
    props.form.setFieldValue(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.areaId`, event.target.value)
  }
  const handleChangeUserDelegationCustomer = (event: any, child: any) => {
    props.form.setFieldValue(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.customerId`, event.target.value)
  }
  const handleChangeUserDelegationDelegatedById = (event: any, child: any) => {
    props.form.setFieldValue(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegatedById`, event.target.value)
  }


  return (
    <UserDelegationPlacementCardDynamicContentContainer>
      <ModalSection mb={3} title={f('USER_DIALOG_VIEW_DELEGATION_AREA_TITLE')}>
        <FilledSelect
          sx={{ width: '300px' }}
          labelId={`user-delegation-area-label-id-${placementIndex}`}
          id={`user-delegation-area-id-${placementIndex}`}
          label={f('USER_DIALOG_VIEW_DELEGATION_AREA_TITLE')}
          value={
            !delegationAvailableItem ? '' :
              props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.areaId`).value || ''
          }
          onChange={handleChangeUserDelegationArea}
          disabled={
            !delegationAvailableItem?.areas ||
            isSavedPlacement ||
            !!props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.customerId`).value
          }
          error={!!props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.areaId`).error}
          errorMessage={props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.areaId`).error}
          name={`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.areaId`}>
          {delegationAvailableItem?.areas?.map(item => (
            <MenuItem sx={{
              width: '300px'
            }} key={`delegation-area-${item.id}`} value={item.id}>
             {item.organizationParentsName ? `${item.name} (${item.organizationParentsName})` : item.name}
            </MenuItem>
          ))}
        </FilledSelect>
      </ModalSection>
      <ModalSection mb={3} title={f('USER_DIALOG_VIEW_DELEGATION_CUSTOMER_TITLE')}>
        <FilledSelect
          data-matomo-mask
          sx={{ width: '300px' }}
          labelId={`user-delegation-customer-label-id-${placementIndex}`}
          id={`user-delegation-customer-id-${placementIndex}`}
          label={f('USER_DIALOG_VIEW_DELEGATION_CUSTOMER_TITLE')}
          value={
            !delegationAvailableItem ? '' :
              props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.customerId`).value || ''
          }
          onChange={handleChangeUserDelegationCustomer}
          disabled={
            !delegationAvailableItem?.areas ||
            isSavedPlacement ||
            !!props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.areaId`).value
          }
          error={!!props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.customerId`).error}
          errorMessage={props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.customerId`).error}
          name={`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.customerId`}>
          {delegationAvailableItem?.customers?.map(item => (
            <MenuItem data-matomo-mask sx={{
              width: '300px'
            }} key={`delegation-area-${item.id}`} value={item.id}>
              {item.fullName}
            </MenuItem>
          ))}
        </FilledSelect>
      </ModalSection>
      <ModalSection mb={0} title={f('USER_DIALOG_VIEW_DELEGATION_START_DATE_TITLE')}>
        <DatePicker
          label={f('USER_DIALOG_VIEW_DELEGATION_START_DATE_TITLE')}
          name={`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegationStartTime`}
          color="secondary"
          format="yyyy-MM-dd"
        />
      </ModalSection>
      <ModalSection mb={0} title={f('USER_DIALOG_VIEW_DELEGATION_END_DATE_TITLE')}>
        <DatePicker
          label={f('USER_DIALOG_VIEW_DELEGATION_END_DATE_TITLE')}
          name={`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegationEndTime`}
          color="secondary"
          format="yyyy-MM-dd"
          showError={!!props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegationEndTime`).error}
          errorMessage={props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegationEndTime`).error}
        />
      </ModalSection>
      <ModalSection mb={3} title={f('USER_DIALOG_VIEW_DELEGATION_DESCRIPTION_TITLE')}>
        <FilledTextField
          data-matomo-mask
          fullWidth
          multiline
          rows={4}
          label={f('USER_DIALOG_VIEW_DELEGATION_DESCRIPTION_TITLE')}
          name={`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.description`}
          value={
            props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.description`).value || ''
          }
          color="secondary"
        />
      </ModalSection>
      <ModalSection mb={3} title={f('USER_DIALOG_VIEW_DELEGATION_DELEGATED_BY_TITLE')}>
        <FilledSelect
          data-matomo-mask
          sx={{ width: '300px' }}
          labelId={`user-delegation-delegated-by-label-id-${placementIndex}`}
          id={`user-delegation-delegated-by-id-${placementIndex}`}
          label={f('USER_DIALOG_VIEW_DELEGATION_DELEGATED_BY_TITLE')}
          value={
            props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegatedById`).value || ''
          }
          error={!!props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegatedById`).error}
          errorMessage={props.form.getFieldMeta(`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegatedById`).error}
          onChange={handleChangeUserDelegationDelegatedById}
          name={`selectedDelegations.${delegationIndex}.userDelegationPlacements.${placementIndex}.delegatedById`}>
          {delegationAvailableItem?.users?.map(item => (
            <MenuItem data-matomo-mask sx={{
              width: '300px'
            }} key={`delegation-delegated-by-${item.id}`} value={item.id}>
              {item.fullName}
            </MenuItem>
          ))}
        </FilledSelect>
      </ModalSection>
    </UserDelegationPlacementCardDynamicContentContainer>
  )
}

export default UserDelegationPlacementCardDynamicContent
