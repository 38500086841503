import React from 'react'
import { useDelegationApi } from 'api'
import { DelegationListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'

export type TemplateActivityCreateModel = Readonly<{
  title: string
  ownerOrganizationId: number
  fromTimeHour: number
  fromTimeMinute: number
  toTimeHour: number
  toTimeMinute: number
}>

const useAdministerOrganizationDelegationsContext = () => {
  const { listOrganizationDelegations, abort, deleteDelegation, hasDelegationConnectedToTemplateActivity } = useDelegationApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const location = useLocation()
  const [delegationItems, setDelegationItems] = React.useState<DelegationListItem[]>([])
  const [modalOpen, setModalOpen] = React.useState(false)

  const fetchDelegationsAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }

    await fetchAsync(listOrganizationDelegations(currentUser.organizationId)).then(setDelegationItems)
  }, [currentUser.organizationId, fetchAsync, listOrganizationDelegations])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    if (currentUser.useDelegations) {
      void fetchDelegationsAsync()
    }
  }, [fetchDelegationsAsync, location, currentUser])

  const { state: stateOfRemoveFetch, fetchAsync: removeFetchAsync } = usePromiseApi()

  const [delegationToRemove, setDelegationToRemove] = React.useState<DelegationListItem | null>(null)

  const [delegationInvalidToRemove, setDelegationInvalidToRemove] = React.useState<DelegationListItem | null>(null)

  const removeDialogOpen = React.useMemo(() => delegationToRemove !== null, [delegationToRemove])

  const removeInvalidDialogOpen = React.useMemo(() => delegationInvalidToRemove !== null, [delegationInvalidToRemove])

  const openRemoveDialog = React.useCallback(
    (delegation: DelegationListItem) => {
       void hasDelegationConnectedToTemplateActivity(delegation.id).then(result => {
         if (result) {
           setDelegationInvalidToRemove(delegation)
         } else {
          setDelegationToRemove(delegation)
         }
      })

    },
    [setDelegationToRemove, setDelegationInvalidToRemove, hasDelegationConnectedToTemplateActivity]
  )

  const closeRemoveInvalidDialog = React.useCallback(() => setDelegationInvalidToRemove(null), [setDelegationInvalidToRemove])

  const closeRemoveDialog = React.useCallback(() => setDelegationToRemove(null), [setDelegationToRemove])

  const deleteDelegationAsync = React.useCallback(
    delegationId => {
      void removeFetchAsync(
        deleteDelegation(delegationId)
          .then(fetchDelegationsAsync)
          .then(closeRemoveDialog)
      )
    },
    [removeFetchAsync, deleteDelegation, fetchDelegationsAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      removeDialogOpen,
      removeInvalidDialogOpen,
      isRemoving,
      delegationItems,
      delegationToRemove,
      delegationInvalidToRemove,
    },
    actions: {
      fetchDelegationsAsync,
      openModal,
      closeModal,
      closeRemoveInvalidDialog,
      deleteDelegationAsync,
      openRemoveDialog,
      closeRemoveDialog,
    },
  }
}

export const AdministerOrganizationDelegationsContext = createContext(useAdministerOrganizationDelegationsContext)
