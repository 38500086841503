import styled, { css } from 'styled-components'
import OrganizationContainer from './organization-container'

const OrganizationStyles = styled(OrganizationContainer)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(5)} ${theme.spacing(5)} 0 ${theme.spacing(5)};
    width: auto;
    height: 100%;
    box-sizing: border-box;
  `
)

export default OrganizationStyles
