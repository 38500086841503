import { STORAGEPREFIX } from 'config/variables'

let teniloGreenColor = '#0A5457'
const devColor = '#000000'

const prefix = STORAGEPREFIX ? STORAGEPREFIX : ''
if (prefix.includes('dev')) {
  // teniloGreenColor = devColor
}

export const Colors = {
  Alto: '#D8D8D8',
  AthensGray: '#EEEFF1',
  Bandicoot: '#878472',
  Denim: '#135DB5',
  DodgerBlue: '#147EFB',
  Ecstasy: '#fb6c14',
  Emerald: '#52D769',
  FieryOrange: '#b55113',
  JellyBean: '#228E90',
  JungleGreen: '#28B550',
  JungleGreenDark: '#1B7E37',
  LightApricot: '#fdd3b8',
  LightBlue: '#64bcfa',
  Malibu: '#5AA4FC',
  PaleLeaf: '#BBCFBC',
  Parsley: '#1B5E20',
  ParsleyDark: '#124116',
  Punch: '#DF3E31',
  RedOrange: '#FC3D39',
  Silver: '#CBCBCB',
  Sunglow: '#FECB2E',
  Tuna: '#323233',
  Valencia: '#D43430',
  White: '#FFFFFF',
  TeniloGreen: teniloGreenColor,
  TeniloLightGreen: '#B6CCCD',
  TeniloBeige: '#F8F2EA',
  TeniloBrown: '#A45217',
  TeniloYellow: '#F0C200',
  TeniloYellowDark: '#d7ad00',
}

export default Colors
