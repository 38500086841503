import { useActivityApi, useAreaApi, useCustomerApi } from 'api'
import {
  ActivityAreaCreateItem,
  ActivityCreateItem,
  ActivityItem,
  AreaItem,
  CustomerItem,
  IActivityItem,
  TemplateActivityTypeEnum,
  CustomerListItem,
  TemplateActivityListItem,
  TemplateActivityItem,
  TemplateActivityInheritanceItem,
} from 'api/tillit.api-client'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'
import { AreaModalContext } from 'components/area-modal/area-modal-context'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useFormatMessage } from 'localization'
import { useTemplateActivityApi } from 'api'

const useActivityCreateModal = () => {
  const { create: createActivity, get: getActivity, update: updateActivity, delete: deleteActivity } = useActivityApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { fetchCustomerActivitiesAsync } = CustomerModalContext.useActions()
  const { fetchAreaCommonActivitiesAsync, fetchAreaTemporaryCommonActivitiesAsync } = AreaModalContext.useActions()

  const { getTemplateActivity, listActiveTemplateActivitiesOnArea, getTemplateActivityInheritanceOnArea } = useTemplateActivityApi()

  const { get: getCustomerPromise, listActiveCustomersOnArea } = useCustomerApi()
  const { get: getAreaById } = useAreaApi()
  const f = useFormatMessage()

  const [customers, setCustomers] = React.useState<CustomerListItem[] | undefined>(undefined)
  const [customerInfo, setCustomerInfo] = React.useState<CustomerItem | null>(null)
  const [areaInfo, setAreaInfo] = React.useState<AreaItem | null>(null)
  const [ownerAreaId, setOwnerAreaId] = React.useState<number | undefined>(undefined)
  const [activeAreaId, setActiveAreaId] = React.useState<number | undefined>(undefined)
  const [currentAreaId, setCurrentAreaId] = React.useState<number | null>(null)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [selectedActivity, setSelectedActivity] = React.useState<IActivityItem | undefined>()

  const [duplicateActivity, setDuplicateActivity] = React.useState<IActivityItem | undefined>()

  const [templateActivityItems, setTemplateActivityItems] = React.useState<TemplateActivityListItem[]>([])

  const [selectedTemplateActivity, setSelectedTemplateActivity] = React.useState<TemplateActivityItem | undefined>()

  const [selectedTemplateActivityInheritance, setSelectedTemplateActivityInheritance] = React.useState<TemplateActivityInheritanceItem | undefined>()

  const fetchTemplateActivitiesAsync = React.useCallback(
    async (customer?: CustomerListItem) => {
      const templateType = customer
        ? TemplateActivityTypeEnum.CustomerTemplate
        : customerInfo
          ? TemplateActivityTypeEnum.CustomerTemplate
          : TemplateActivityTypeEnum.AreaTemplate
      let areaId = ownerAreaId ? ownerAreaId : null
      if (!areaId) {
        areaId = customer ? (customer.customerAreas ? customer.customerAreas[0].areaId : null) : null
        if (!areaId) {
          areaId = customerInfo ? (customerInfo.customerAreas ? customerInfo.customerAreas[0].areaId : null) : null
        }
      }
      setCurrentAreaId(areaId)
      if (areaId) {
        await fetchAsync(listActiveCustomersOnArea(areaId)).then(setCustomers)
        await fetchAsync(listActiveTemplateActivitiesOnArea(areaId, templateType)).then(setTemplateActivityItems)
      }
    },
    [listActiveTemplateActivitiesOnArea, customerInfo, ownerAreaId, setCurrentAreaId, listActiveCustomersOnArea, setCustomers, fetchAsync]
  )



  const fetchSelectedTemplateActivityAsync = React.useCallback(
    async (templateActivityId: number | undefined) => {
      if (!templateActivityId) {
        setSelectedTemplateActivity(undefined)
        setSelectedTemplateActivityInheritance(undefined)
        return
      }

      await fetchAsync(getTemplateActivity(templateActivityId)).then(templateActivity => {
        if (templateActivity instanceof TemplateActivityItem) {
          setSelectedTemplateActivity(templateActivity)

          if (currentAreaId) {
            void fetchAsync(getTemplateActivityInheritanceOnArea(templateActivityId, currentAreaId)).then(tai => {
              if (tai instanceof TemplateActivityInheritanceItem) {
                setSelectedTemplateActivityInheritance(tai)
              }
            })
          }
        }
      })
    },
    [getTemplateActivity, setSelectedTemplateActivity, getTemplateActivityInheritanceOnArea, setSelectedTemplateActivityInheritance, currentAreaId]
  )

  const fetchActivityAsync = React.useCallback(
    async (activityId: number) => {
      await fetchAsync(getActivity(activityId)).then(a => {
        void fetchSelectedTemplateActivityAsync(a.templateActivityId)

        setSelectedActivity(a)
      })
    },
    [getActivity, fetchSelectedTemplateActivityAsync]
  )

  const openModal = React.useCallback(
    (activityId?: number, customer?: CustomerListItem) => {
      setSelectedTemplateActivity(undefined)
      setSelectedTemplateActivityInheritance(undefined)

      void fetchTemplateActivitiesAsync(customer).then(_ => {
        setDuplicateActivity(undefined)
        if (!!activityId) {
          void fetchActivityAsync(activityId).then(_ => setModalOpen(true))
        } else {
          setModalOpen(true)
        }
      })
    },
    [fetchActivityAsync, fetchTemplateActivitiesAsync, setSelectedTemplateActivity, setSelectedTemplateActivityInheritance]
  )

  const openCreateActivityOnArea = React.useCallback(
    (areaId?) => {
      setSelectedTemplateActivity(undefined)
      setSelectedTemplateActivityInheritance(undefined)
      setDuplicateActivity(undefined)
      setCustomerInfo(null)
      setActiveAreaId(areaId)
      fetchAsync(listActiveCustomersOnArea(areaId)).then((result) => {
        setCustomers(result)
        fetchAsync(listActiveTemplateActivitiesOnArea(areaId, TemplateActivityTypeEnum.AreaTemplate)).then((t) => {
          setTemplateActivityItems(t)
          setModalOpen(true)
        })
      })

    },
    [fetchTemplateActivitiesAsync, setSelectedTemplateActivity, setSelectedTemplateActivityInheritance]
  )

  const openDuplicateModal = React.useCallback(
    async (activityId: number) => {
      await fetchAsync(getActivity(activityId)).then(a => {
        let duplicate: ActivityItem = new ActivityItem()
        duplicate.activityAreas = a.activityAreas
        duplicate.activityRows = a.activityRows
        duplicate.alertFromStartInMinutes = a.alertFromStartInMinutes
        duplicate.color = a.color
        duplicate.endDate = a.endDate
        duplicate.isPlanned = false
        duplicate.ownerAreaId = a.ownerAreaId
        duplicate.ownerCustomerId = a.ownerCustomerId
        duplicate.recurrenceId = a.recurrenceId
        duplicate.recurrenceInterval = a.recurrenceInterval
        duplicate.severityLevelId = a.severityLevelId
        duplicate.showColorOnDevice = a.showColorOnDevice
        duplicate.severityLevelId = a.severityLevelId
        duplicate.templateActivityId = a.templateActivityId
        duplicate.templateActivityTitle = a.templateActivityTitle
        duplicate.expectedAmount = a.expectedAmount
        duplicate.activityParticipants = a.activityParticipants
        if (a.startDate < new Date()) {
          duplicate.startDate = new Date() //Can start earliest today
        } else {
          duplicate.startDate = a.startDate
        }
        duplicate.title = a.title?.concat(' (' + f('BASIC_TEXT_COPY') + ')')
        setDuplicateActivity(duplicate)
        setModalOpen(true)
      })
    },
    [getActivity]
  )

  const closeModal = React.useCallback(() => {
    setSelectedActivity(undefined)
    setModalOpen(false)
  }, [])

  const fetchCustomerInfoAsync = React.useCallback(
    async (customerId: number) => {
      await fetchAsync(getCustomerPromise(customerId)).then(resCustomerInfo => {
        setCustomerInfo(resCustomerInfo)
        setAreaInfo(null)
      })
    },
    [getCustomerPromise]
  )

  const fetchAreaInfoAsync = React.useCallback(
    async (areaId: number) => {
      await fetchAsync(getAreaById(areaId)).then(resAreaInfo => {
        setAreaInfo(resAreaInfo)
        setCustomerInfo(null)
        setActiveAreaId(areaId)
      })
    },
    [getAreaById]
  )

  const removeActivityOnAreaAsync = React.useCallback(
    (activity: IActivityItem) => {
      return fetchAsync(deleteActivity(activity.id)).then(_ => {
        void fetchAreaInfoAsync(areaInfo!.id)
        void fetchAreaCommonActivitiesAsync(areaInfo!.id)
        void fetchAreaTemporaryCommonActivitiesAsync(areaInfo!.id)
        setSelectedActivity(undefined)
        closeModal()
      })
    },
    [closeModal, areaInfo, deleteActivity, fetchAreaInfoAsync, fetchAreaCommonActivitiesAsync, fetchAreaTemporaryCommonActivitiesAsync]
  )

  const createActivityAsync = React.useCallback(
    (createActivityModel: ActivityCreateItem) => {
      return fetchAsync(
        createActivity({
          ...createActivityModel,
          activityAreas: !!customerInfo
            ? customerInfo.customerAreas
            : activeAreaId
              ? [new ActivityAreaCreateItem({ areaId: activeAreaId })]
              : areaInfo?.id
                ? [new ActivityAreaCreateItem({ areaId: areaInfo.id })]
                : undefined,
        })
      ).then(() => {
        closeModal()
      })
    },
    [createActivity, customerInfo, closeModal, activeAreaId, areaInfo]
  )

  const updateActivityAsync = React.useCallback(
    (activity: IActivityItem) => {
      return fetchAsync(updateActivity(activity))
        .then(() => {
          fetchCustomerActivitiesAsync(customerInfo!.id)
          closeModal()
        })
        .catch(() => {
          closeModal()
        })
    },
    [closeModal, customerInfo, fetchCustomerActivitiesAsync, updateActivity]
  )

  const removeActivityAsync = React.useCallback(
    (activity: IActivityItem) => {
      return fetchAsync(deleteActivity(activity.id)).then(_ => {
        fetchCustomerActivitiesAsync(customerInfo!.id)
        setSelectedActivity(undefined)
        closeModal()
      })
    },
    [closeModal, customerInfo, deleteActivity, fetchCustomerActivitiesAsync]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedActivity,
      duplicateActivity,
      selectedTemplateActivity,
      selectedTemplateActivityInheritance,
      customerInfo,
      ownerAreaId,
      activeAreaId,
      areaInfo,
      templateActivityItems,
      currentAreaId,
      customers,
    },
    actions: {
      createActivityAsync,
      setCustomerInfo,
      openModal,
      openDuplicateModal,
      closeModal,
      fetchActivityAsync,
      updateActivityAsync,
      fetchCustomerInfoAsync,
      removeActivityAsync,
      setOwnerAreaId,
      setActiveAreaId,
      removeActivityOnAreaAsync,
      fetchAreaInfoAsync,
      fetchAreaTemporaryCommonActivitiesAsync,
      setSelectedTemplateActivity,
      setSelectedTemplateActivityInheritance,
      openCreateActivityOnArea
    },
  }
}

const ActivityCreateModalContext = createContext(useActivityCreateModal)
export default ActivityCreateModalContext
