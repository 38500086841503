import React from 'react'

import httpMiddleware from 'utilities/http-middleware'
import { API_URL } from '../config/variables'
import { VersionClient } from './tillit.api-client'

export class VersionApi {
  private readonly abortController = new AbortController()
  private readonly client: VersionClient

  constructor() {
    this.client = new VersionClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getVersionTillitApi = (): Promise<string> =>
    this.client
      .getAppVersion()
      .then(appVersion => (appVersion === null ? Promise.reject(`No app version received from backend.`) : Promise.resolve(appVersion)))
}

const useTillitVersionApi = () => React.useMemo(() => new VersionApi(), [])

export default useTillitVersionApi
