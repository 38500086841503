import React from 'react'
import { Box, Tabs as MuiTabs, Typography } from '@mui/material'
import styled from 'styled-components/macro'

export const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
  margin-bottom: 2rem;
`

export type ITabPanelProps = Readonly<{
  index: number
  value: number
}>

export const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box px={3}>{children}</Box>
    </Typography>
  )
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
