import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Authentication from 'components/authentication'
import Spinner from 'components/spinner'
import PageRoute from 'config/page-routes'
import useMediaQuery from '@mui/material/useMediaQuery'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'
import { AreaModalContext } from 'components/area-modal/area-modal-context'
import { NotificationContext } from 'components/notification'
import { TokenExpiredDialogContainer } from 'components/authentication'


const matchUrlPattern = (url: string, pattern: string) => {
  const urlSegments = url.split('/')
  const patternSegments = pattern.split('/')

  for (let i = 0; i < patternSegments.length; i++) {
    if (patternSegments[i] !== urlSegments[i] && !patternSegments[i].startsWith(':')) {
      return false
    }
  }

  return true
}

const PrivateRoutesOutlet: React.FC = () => {
  return (
    <NotificationContext.Provider>
      <CustomerModalContext.Provider>
        <AreaModalContext.Provider>
          <ActivityCreateModalContext.Provider>
            <Outlet />
            <TokenExpiredDialogContainer />
          </ActivityCreateModalContext.Provider>
        </AreaModalContext.Provider>
      </CustomerModalContext.Provider>
    </NotificationContext.Provider>
  )
}

const PrivateRoute: React.FC = () => {
  const theme = useTheme()
  const { isLoading } = Authentication.useState()
  const { isLoggedIn } = Authentication.useActions()
  const smallScreen = useMediaQuery(theme.customBreakpoints.justMobileTablet)
  const location = useLocation()
  const state = location.state as { from?: Location }

  return (
    isLoading
      ?
      <Spinner color={theme.palette.secondary.main} size={64} />
      :
      isLoggedIn()
        ?
        smallScreen && !matchUrlPattern(location.pathname, PageRoute.Planning.path) && location.pathname !== PageRoute.Profile.path && location.pathname !== PageRoute.Administer.path && state?.from?.pathname !== PageRoute.DailyActivities.path
          ?
          <Navigate to={PageRoute.Profile.path} />
          :
          <PrivateRoutesOutlet />
        :
        <Navigate to={PageRoute.Login.path} state={{ from: location }} replace />
  )
}

export default PrivateRoute
