/* eslint-disable react-hooks/exhaustive-deps */
import { useActivityApi } from 'api'
import {
  IPlannedActivityRowItem,
} from 'api/tillit.api-client'
import React from 'react'
import usePromiseApi from 'utilities/use-promise'
import { hasCompleteStatus } from 'utilities/planned-activity-helper'

export const useListingModal = () => {
  const { unsignPlannedActivityWithoutAction } = useActivityApi()
  const { state: fetchState, fetchAsync } = usePromiseApi()

  const [
    plannedActivity,
    setPlannedActivity,
  ] = React.useState<IPlannedActivityRowItem | null>(null)

  const onUnsignClicked = React.useCallback(
    async (comment?: string): Promise<IPlannedActivityRowItem> => {
      if (!plannedActivity) {
        throw Error('There is currently not any plannedActivity selected.')
      }
      const activity = await fetchAsync(
        unsignPlannedActivityWithoutAction({
          plannedActivityRowId: plannedActivity.id,
          comment,
          hasDelegationViolations: false,
        })
      )
      setPlannedActivity(activity)
      return activity
    },
    [plannedActivity]
  )

  const isComplete = React.useMemo(
    () =>
      plannedActivity && hasCompleteStatus(plannedActivity.plannedActivityStatusId),
    [plannedActivity]
  )

  const close = React.useCallback(() => {
    setOpen(false)
    setPlannedActivity(null)
  }, [])

  const [open, setOpen] = React.useState(false)

  return React.useMemo(
    () => ({
      actions: {
        setPlannedActivity,
        setOpen,
        close,
        onUnsignClicked,
      },
      state: {
        plannedActivity,
        fetchState,
        open,
        isComplete,
      },
    }),
    [plannedActivity, fetchState, open, isComplete]
  )
}
