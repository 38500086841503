import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Color from 'color'
import { Login as LoggedOutIcon } from 'mdi-material-ui'

import React from 'react'
import Colors from 'styles/colors'

const LoggedOutDialogContainer: React.FC<{
  dialogTitle: string
  dialogContentText: string
  buttonOkText: string
  dialogOpen: boolean
  onClose: () => void
}> = ({ dialogTitle, dialogContentText, buttonOkText, dialogOpen, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', padding: '2vh' }}>
        <LoggedOutIcon style={{ width: '100px', height: '100px', color: Color(Colors.TeniloLightGreen).string() }} />
        <DialogContentText data-matomo-mask>{dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {buttonOkText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoggedOutDialogContainer
