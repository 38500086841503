import React from 'react'
import { OnSubmitFunction } from 'types'
import { SelectionEnum, UserReportCreateItem, UserReportFieldCreateItem, UserReportSelectionCreateItem, IReportSelectionListItemValue, UserReportUpdateItem, UserReportFieldUpdateItem, UserReportSelectionUpdateItem, ReportFilterSelection, UserReportAvailabilityUpdateItem } from 'api/tenilo.reporting.api-client'
import { useFormatMessage } from 'localization'
import { isArray, isDate, isObject } from 'lodash'
import Authentication from 'components/authentication'
import type { ReportsPageFormType } from '../config'
import ReportsPageContext from '../reports-page-context'

const useReportsPageForm = () => {
  const f = useFormatMessage()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { report: baseReport, userReport, reportList } = ReportsPageContext.useState()
  const { handleRemoveUserReport, handleCreateUserReport, fetchReportData, handleUpdateUserReport, getReportList, setReport, setUserReport, setReportData } = ReportsPageContext.useActions()

  const memoizedSelections = React.useMemo(() => {
    if (baseReport) { return baseReport.reportSelections }
    if (userReport) { return userReport.parentReport.reportSelections }
    return []
  }, [baseReport, userReport])

  const initialValues: ReportsPageFormType = React.useMemo(
    () => ({
      name: undefined,
      description: undefined,
      report: undefined,
      fields: undefined,
      availabilities: undefined,
      submitType: undefined,
      ...Object.keys(SelectionEnum).reduce((acc, curr) => {
        acc[curr] = undefined
        return acc
      }, {})
    }),
    [baseReport, userReport]
  )

  const handleUpdateItem = React.useCallback(({ submitType, updateName, updateDescription, report, fields, availabilities, ...values }: ReportsPageFormType) => {
    if (!report) { return }
    if (!report.userReportId) { return }
    if (!userReport) { return }
    const item = new UserReportUpdateItem()
    item.id = report.userReportId
    item.reportName = updateName ? updateName : userReport.reportName
    item.reportDescription = updateDescription ? updateDescription : ""
    item.userReportFields = fields?.map((field, index) => new UserReportFieldUpdateItem({
      id: userReport.userReportFields.find(x => x.reportFieldId === field.id)?.id,
      reportFieldId: field.id,
      sortIndex: index
    }))
    if (values['TransformToOrganizationReport']) {
      item.userReportAvailabilities = [new UserReportAvailabilityUpdateItem({
        ownerOrganizationId: currentUser.organizationId})]
    } else {
      item.userReportAvailabilities = availabilities?.map(a => new UserReportAvailabilityUpdateItem({
        id: userReport.userReportAvailabilities?.find(ua => ua.ownerAreaId === a.areaId)?.id,
        ownerAreaId: a.areaId
      }))
    }
    const selections = memoizedSelections.filter(s => !!values[s.selectionId]).map(s => {
      if (isArray(values[s.selectionId])) {
        let val: string = values[s.selectionId].map((val: IReportSelectionListItemValue) => val.id.toString()).join('@Sep@')
        return new UserReportSelectionUpdateItem({ id: userReport.userReportSelections.find(x => x.reportSelectionId === s.id)?.id, reportSelectionId: s.id, selectedValues: val })
      }
      if (isDate(values[s.selectionId])) {
        let val = Math.round(((values[s.selectionId] as Date).getTime() / 1000)).toString()
        if (s.interval) {
          let interval = Math.round(((values[`${s.selectionId}-to`] as Date)?.getTime() / 1000))?.toString()
          if (interval) { val = val.concat('@Sep@', interval) }
        }
        return new UserReportSelectionUpdateItem({ id: userReport.userReportSelections.find(x => x.reportSelectionId === s.id)?.id, reportSelectionId: s.id, selectedValues: val })
      }
      if (isObject(values[s.selectionId])) {
        let val = (values[s.selectionId] as IReportSelectionListItemValue).id.toString()
        return new UserReportSelectionUpdateItem({ id: userReport.userReportSelections.find(x => x.reportSelectionId === s.id)?.id, reportSelectionId: s.id, selectedValues: val })
      }
      let val = values[s.selectionId].toString()
      return new UserReportSelectionUpdateItem({ id: userReport.userReportSelections.find(x => x.reportSelectionId === s.id)?.id, reportSelectionId: s.id, selectedValues: val })
    })
    item.userReportSelections = selections
    return item
  }, [memoizedSelections, userReport])

  const handleCreateItem = React.useCallback(({ submitType, name, description, report, fields, availabilities, ...values }: ReportsPageFormType) => {
    if (!report) { return }
    const item = new UserReportCreateItem()
    item.name = name
    item.description = description
    item.parentReportId = report.reportId
    item.userReportFields = fields?.map(field => new UserReportFieldCreateItem({
      reportFieldId: field.id,
      sortIndex: field.fieldIndex
    }))
    const selections = memoizedSelections.filter(s => !!values[s.selectionId]).map(s => {
      if (isArray(values[s.selectionId])) {
        let val: string = values[s.selectionId].map((val: IReportSelectionListItemValue) => val.id.toString()).join('@Sep@')
        return new UserReportSelectionCreateItem({ reportSelectionId: s.id, selectedValues: val })
      }
      if (isDate(values[s.selectionId])) {
        let val = Math.round(((values[s.selectionId] as Date).getTime() / 1000)).toString()
        if (s.interval) {
          let interval = Math.round(((values[`${s.selectionId}-to`] as Date)?.getTime() / 1000))?.toString()
          if (interval) { val = val.concat('@Sep@', interval) }
        }
        return new UserReportSelectionCreateItem({ reportSelectionId: s.id, selectedValues: val })
      }
      if (isObject(values[s.selectionId])) {
        let val = (values[s.selectionId] as IReportSelectionListItemValue).id.toString()
        return new UserReportSelectionCreateItem({ reportSelectionId: s.id, selectedValues: val })
      }
      let val = values[s.selectionId].toString()
      return new UserReportSelectionCreateItem({ reportSelectionId: s.id, selectedValues: val })
    })
    item.userReportSelections = selections
    return item
  }, [memoizedSelections])

  const handleViewItem = React.useCallback(({ submitType, name, description, report, fields, availabilities, ...values }: ReportsPageFormType) => {
    const selections: ReportFilterSelection[] = memoizedSelections.filter(s => !!values[s.selectionId]).map(s => {
      if (isArray(values[s.selectionId])) {
        let val: string = values[s.selectionId].map((val: IReportSelectionListItemValue) => val.id.toString()).join('@Sep@')
        return new ReportFilterSelection({ reportSelectionId: s.id, selectedValues: val })
      }
      if (isDate(values[s.selectionId])) {
        const dateValue = new Date((values[s.selectionId] as Date).setHours(0,0,0,0))
        let val = Math.round((dateValue.getTime() / 1000)).toString()
        if (s.interval) {
          const dateValueTo = new Date((values[`${s.selectionId}-to`] as Date).setHours(23,59,59,99))
          const interval = Math.round((dateValueTo.getTime() / 1000))?.toString()
          if (interval) { val = val.concat('@Sep@', interval) }
        }
        return new ReportFilterSelection({ reportSelectionId: s.id, selectedValues: val })
      }
      if (isObject(values[s.selectionId])) {
        let val = (values[s.selectionId] as IReportSelectionListItemValue).id.toString()
        return new ReportFilterSelection({ reportSelectionId: s.id, selectedValues: val })
      }
      let val = values[s.selectionId].toString()
      return new ReportFilterSelection({ reportSelectionId: s.id, selectedValues: val })
    })
    return selections.filter(selection => selection.selectedValues.length > 0)
  }, [memoizedSelections])

  const onSubmit: OnSubmitFunction<ReportsPageFormType> = React.useCallback(
    ({ submitType, name, description, report, fields, availabilities, ...values }, { setSubmitting, setStatus, ...rest }) => {
      setSubmitting(true)
      if (!report) { return }
      const viewItemSelections = handleViewItem({ submitType, name, description, report, fields, availabilities, ...values })
      if (submitType === 'view') {
        fetchReportData(report.reportId, viewItemSelections)
          .then(() => {
            setSubmitting(false)
            rest.setFieldValue('submitType', 'seen')
          })
          .catch(err => {
            setSubmitting(false)
            console.log(err)
          })
      }
      if (submitType === 'save') {
        const createItem = handleCreateItem({ submitType, name, description, report, fields, availabilities, ...values })
        if (!createItem) { return }
        handleCreateUserReport(createItem)
          .then(userReport => {
            if (userReport) {
              getReportList()
                .then((reports) => {
                  setReport(undefined)
                  setUserReport(undefined)
                  setReportData(undefined)
                  rest.resetForm(
                    {
                      values: {
                        name: undefined,
                        description: undefined,
                        report: undefined,
                        fields: undefined,
                        submitType: undefined,
                        ...Object.keys(SelectionEnum).reduce((acc, curr) => {
                          acc[curr] = undefined
                          return acc
                        }, {})
                      }
                    }
                  )
                  const reportListItem = reports?.find(x => x.userReportId === userReport.id)
                  if (reportListItem) {
                    rest.setFieldValue("report", reportListItem)
                  }
                }
                )
            }
          }).catch((err) => {console.log(err)})
      }
      if (submitType === 'update') {
        const updateItem = handleUpdateItem({ submitType, name, description, report, fields, availabilities, ...values })
        if (!updateItem) { return }
        handleUpdateUserReport(updateItem)
          .then((userReport) => {
            if (userReport) {
              getReportList()
                .then((reports) => {
                  setReport(undefined)
                  setUserReport(undefined)
                  setReportData(undefined)
                  rest.resetForm(
                    {
                      values: {
                        name: undefined,
                        description: undefined,
                        report: undefined,
                        fields: undefined,
                        submitType: undefined,
                        ...Object.keys(SelectionEnum).reduce((acc, curr) => {
                          acc[curr] = undefined
                          return acc
                        }, {})
                      }
                    }
                  )
                  const reportListItem = reports?.find(x => x.userReportId === userReport.id)
                  if (reportListItem) {
                    rest.setFieldValue("report", reportListItem)
                  }
                }
                )
            }
          }).catch(err =>{console.log(err)})
      }
      if (submitType === 'remove') {
        if (userReport) {
          handleRemoveUserReport(userReport.id)
          .then(() => {
            setSubmitting(false)
            rest.resetForm()
          })
          .catch((err) =>{
            setSubmitting(false)
            console.log(err)
          })
        }
      }
    },
    [memoizedSelections, reportList]
  )

  return {
    initialValues,
    onSubmit
  }
}

export default useReportsPageForm
