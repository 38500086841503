import React from 'react'
import { createContext } from 'utilities/create-context'

const useRouteMenuContext = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const open = React.useMemo(() => {
    return Boolean(anchorEl)
  }, [anchorEl])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [])

  return {
    state: { anchorEl, open },
    actions: { handleClick, handleClose },
  }
}

export const RouteMenuContext = createContext(useRouteMenuContext)
