import React from 'react'
import {  DockWindow, Percent } from 'mdi-material-ui'
import { Colors } from 'styles/colors'

const DashboardKpiChartHeaderActionBar : React.FC<{
    handleSetPercent: () => void,
    percent: boolean,
    layoutSize: string,
    handleZoomCardContent: () => void
}> = (props) => {
  const { percent, handleSetPercent, layoutSize, handleZoomCardContent } = props

  return (
    <>
      <Percent onClick={handleSetPercent} sx={{
          marginRight:'5px',
          cursor:'pointer',
          color: percent ? Colors.TeniloBrown : '#323233',
          fontSize:layoutSize === 's' ? '0.9rem' : layoutSize === 'm' ? '1.1rem' : '1.3rem',
      }} />
      <DockWindow onClick={handleZoomCardContent} sx={{
          marginRight:'5px',
          cursor:'pointer',
          fontSize:layoutSize === 's' ? '0.9rem' : layoutSize === 'm' ? '1.1rem' : '1.3rem',
      }} />
    </>
  )
}

export default React.memo(DashboardKpiChartHeaderActionBar)
