import { Box, Typography } from '@mui/material'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import React from 'react'
import { Name } from 'types'
import BigCheckbox from 'components/inputs/big-checkbox'
import { UserTypeEnum } from 'api/tenilo.support.api-client'
import type { UserForm } from './utilities/use-support-user-form-data'

export type SupportUserModalBasicInformationView = Readonly<{
  name: Name<UserForm>
  values: UserForm
}>

const SupportUserModalBasicInformationView: React.FC<SupportUserModalBasicInformationView> = ({name, values}) => {
  const f = useFormatMessage()

  return (
    <>
      <ModalSection title={f('SUPPORT_USER_MODAL_DIALOG_ORGANIZATION_TITLE')}>
        <ModalSectionRow>
          <Box width="50%">
            <FilledTextField
              name={name.organizationName}
              color="secondary"
              fullWidth
              disabled
            />
          </Box>
        </ModalSectionRow>
      </ModalSection>


      {!values.active && (
        <ModalSection title={f(' ')}>
          <ModalSectionRow>
            <Box width="100%">
              <Typography color="error">
              {f('SUPPORT_USER_MODAL_INACTIVE_USER')}

              </Typography>
            </Box>
          </ModalSectionRow>
        </ModalSection>
      )}

      <ModalSection title={f('SUPPORT_USER_MODAL_INFOTEXT_TITLE')}>
        <ModalSectionRow>
          <FilledTextField
            data-matomo-mask
            fullWidth
            label={f('SUPPORT_USER_MODAL_FIRSTNAME_LABEL')}
            name={name.firstName}
            color="secondary"
            disabled
          />
          <FilledTextField
            data-matomo-mask
            fullWidth
            label={f('SUPPORT_USER_MODAL_LASTNAME_LABEL')}
            name={name.lastName}
            color="secondary"
            disabled
          />
        </ModalSectionRow>
        <FilledTextField
          data-matomo-mask
          fullWidth
          label={f('SUPPORT_USER_MODAL_EMAIL_LABEL')}
          name={name.email}
          color="secondary"
          disabled
        />
        <FilledTextField
          data-matomo-mask
          fullWidth
          label={f('SUPPORT_USER_MODAL_PHONENUMBER_LABEL')}
          name={name.phoneNumber}
          disabled
        />
      </ModalSection>

      {values.active && (
        <ModalSection
          title={f('SUPPORT_USER_MODAL_DIALOG_TYPE_TITLE')}
          subtitle={f('SUPPORT_USER_MODAL_DIALOG_TYPE_SUBTITLE')}>
          <ModalSectionRow>
            <>
              <BigCheckbox
                label={`${f('SUPPORT_USER_MODAL_DIALOG_TYPE_MEMBER')}`}
                name={name.userTypeId}
                value={UserTypeEnum.OrganizationMember}
                checkedByValue
                returnValue="value"
              />
              <BigCheckbox
                label={`${f('SUPPORT_USER_MODAL_DIALOG_TYPE_ORGADMIN')}`}
                name={name.userTypeId}
                value={UserTypeEnum.OrganizationAdmin}
                checkedByValue
                returnValue="value"
              />
            </>
          </ModalSectionRow>
        </ModalSection>
      )}
    </>
  )
}

export default SupportUserModalBasicInformationView
