import * as React from 'react';
import { useField, FieldAttributes } from "formik"
import { useFormatMessage } from 'localization'
import { TextField } from "@mui/material"

type ReportTextFieldProps = {multiline?:boolean, applyRequirement?:boolean,label:string, initialValue?:string} & FieldAttributes<Record<string, unknown>>

const ReportTextField: React.FC<ReportTextFieldProps> = ({
  multiline,
  applyRequirement,
  label,
  initialValue,
  ...props
}) => {
  const [{onBlur, ...field}, meta, helpers] = useField<{}>(props)
  const f = useFormatMessage()

  React.useEffect(()=>{
    if(applyRequirement && !field.value && !meta.error){
      helpers.setError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
    }
    if(!applyRequirement && meta.error){
      helpers.setError(undefined)
    }
  },[applyRequirement,field,meta])

  React.useEffect(()=>{
    if(initialValue){
      helpers.setValue(initialValue)
    }
  },[initialValue])

  return  React.useMemo(()=>{
    return (
        <TextField
          data-matomo-mask
          {...field}
          value={field.value || ''}
          multiline={multiline}
          rows={3}
          label={label}
          helperText={(meta.touched && meta.error) ? meta.error : ' '}
          error={meta.touched && !!meta.error}
      />
    )

  },[field.value,meta.error,field.name,meta.touched])
}

export default React.memo(ReportTextField)
