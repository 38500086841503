import { useActivityApi, useCustomerApi } from 'api'
import { ActivityListItem, CustomerItem, ICustomerPausedPeriodItem, RecurrenceEnum } from 'api/tillit.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useCustomerModalContext = () => {
  const { listPausedPeriods, update: updateCustomerPromise, getCustomerWithActivitiesAndPausedPeriods } = useCustomerApi()
  const { list: listCustomerActivitiesPromise } = useActivityApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const [customerInfo, setCustomerInfo] = React.useState<CustomerItem | null>(null)
  const [activities, setActivities] = React.useState<ActivityListItem[] | null>(null)
  const [temporaryActivities, setTemporaryActivities] = React.useState<ActivityListItem[] | null>(null)
  const [pausedPeriods, setPausedPeriods] = React.useState<ICustomerPausedPeriodItem[] | null>([])
  const [passedPausedPeriods, setPassedPausedPeriods] = React.useState<ICustomerPausedPeriodItem[] | null>([])
  const [ customerId, setCustomerId ] = React.useState<number|null>(null)

  const [customerHasActivityOnCurrentAreaTemplateActivity, setCustomerHasActivityOnCurrentAreaTemplateActivity] = React.useState<boolean>(false)

  const fetchCustomerInfoAsync = React.useCallback(
    async (customerId: number) => {
      const customer = await fetchAsync(getCustomerWithActivitiesAndPausedPeriods(customerId))
      setCustomerInfo(customer)
      if (customer.activities) {
        setActivities(customer.activities?.filter(a => a.recurrenceId !== RecurrenceEnum.OneTime))
        setTemporaryActivities(customer.activities?.filter(a => a.recurrenceId === RecurrenceEnum.OneTime))
      }
      if (customer.customerPausedPeriods) {
        const now = new Date()
        const activePausedPeriods = customer.customerPausedPeriods.filter(pp => {
          let active = true
          if (pp.endDateTime && pp.endDateTime < pp.startDateTime) {
            active = false
          }
          if (pp.endDateTime && pp.endDateTime < now) {
            active = false
          }
          return active
        })

        const inactivePausedPeriods = customer.customerPausedPeriods.filter(pp => {
          let inactive = true
          if (pp.endDateTime && pp.endDateTime < pp.startDateTime) {
            inactive = false
          }
          if (!pp.endDateTime) {
            inactive = false
          }

          if (pp.endDateTime && pp.endDateTime > now) {
            inactive = false
          }
          return inactive
        })
        setPausedPeriods(activePausedPeriods)
        setPassedPausedPeriods(inactivePausedPeriods)
      }

    },
    [fetchAsync, getCustomerWithActivitiesAndPausedPeriods]
  )

  const fetchCustomerActivitiesAsync = React.useCallback(
    async (customerId: number) => {
      const customerActivities = await fetchAsync(listCustomerActivitiesPromise(customerId))
        setActivities(customerActivities.filter(a => a.recurrenceId !== RecurrenceEnum.OneTime))
        setTemporaryActivities(customerActivities.filter(a => a.recurrenceId === RecurrenceEnum.OneTime)
      )
    },
    [fetchAsync, listCustomerActivitiesPromise, setTemporaryActivities, setActivities]
  )

  const updateCustomerAsync = React.useCallback(
    (customer: CustomerItem) => {
      return fetchAsync(
        updateCustomerPromise(customer)
          .then(resCustomer => {
            setCustomerInfo(resCustomer)
            return fetchCustomerInfoAsync(resCustomer.id) && resCustomer
          })
          .then(customerItem => !!customerItem)
      )
    },
    [fetchAsync, updateCustomerPromise, fetchCustomerInfoAsync]
  )

  const fetchCustomerPausePeriodsAsync = React.useCallback(
    async (customerId: number) => {
      await fetchAsync(listPausedPeriods(customerId)).then(pps => {
        const now = new Date()
        const activePausedPeriods = pps.filter(pp => {
          let active = true
          if (pp.endDateTime && pp.endDateTime < pp.startDateTime) {
            active = false
          }
          if (pp.endDateTime && pp.endDateTime < now) {
            active = false
          }
          return active
        })

        const inactivePausedPeriods = pps.filter(pp => {
          let inactive = true
          if (pp.endDateTime && pp.endDateTime < pp.startDateTime) {
            inactive = false
          }
          if (!pp.endDateTime) {
            inactive = false
          }

          if (pp.endDateTime && pp.endDateTime > now) {
            inactive = false
          }
          return inactive
        })
        setPausedPeriods(activePausedPeriods)
        setPassedPausedPeriods(inactivePausedPeriods)
      })
    }, [fetchAsync, listPausedPeriods])

  const resetState = React.useCallback(() => {
    setCustomerInfo(null)
    setActivities(null)
    setPausedPeriods(null)
    setPassedPausedPeriods(null)
    setCustomerId(null)
  }, [])

  return {
    state: {
      ...stateOfFetch,
      customerInfo,
      activities,
      pausedPeriods,
      passedPausedPeriods,
      temporaryActivities,
      customerHasActivityOnCurrentAreaTemplateActivity,
      customerId
    },
    actions: {
      fetchCustomerInfoAsync,
      fetchCustomerActivitiesAsync,
      fetchCustomerPausePeriodsAsync,
      updateCustomerAsync,
      clearUser: resetState,
      setCustomerHasActivityOnCurrentAreaTemplateActivity,
      getCustomerWithActivitiesAndPausedPeriods,
      setCustomerId
    },
  }
}

export const CustomerModalContext = createContext(useCustomerModalContext)
