import React from 'react'

import {
  OrganizationsClient,
  OrganizationListItem,
  PageResultOfOrganizationListItem,
  OrganizationItem,
  OrganizationUpdateItem,
  IOrganizationUpdateItem,
  IOrganizationGrandIdSettingPersistItem,
  OrganizationGrandIdSettingPersistItem,
} from 'api/tenilo.support.api-client'
import { SUPPORT_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class SupportOrganizationsApi {
  private readonly abortController = new AbortController()
  private readonly client: OrganizationsClient

  public constructor() {
    this.client = new OrganizationsClient(SUPPORT_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getOrganization = (organizationId: number): Promise<OrganizationItem> =>
    this.client
      .getOrganization(organizationId)
      .then(organizationItem =>
        organizationItem === null
          ? Promise.reject(`No organization item received from backend for organization id ${organizationId}.`)
          : Promise.resolve(organizationItem)
      )

  public listOrganizations = (page: number, pageSize: number, organizationId: number | null): Promise<PageResultOfOrganizationListItem> =>
    this.client
      .list(page, pageSize, organizationId, null)
      .then(organizations =>
        organizations === null
          ? Promise.reject(`No organizations received from backend.`)
          : Promise.resolve(organizations)
      )

  public updateOrganization = (organization: IOrganizationUpdateItem) => {
    const model = new OrganizationUpdateItem(organization)
    return this.client
      .updateOrganization(model)
      .then(organizationItem =>
        organizationItem === null ? Promise.reject(`When updating an organization, no organization item received from backend.`) : Promise.resolve(organizationItem)
      )
  }

  public anonymizeOrganization = (organizationId: number) => {
    return this.client
      .anonymizeOrganization(organizationId)
      .then(organizationItem =>
        organizationItem === null ? Promise.reject(`When anonymizing an organization, no organization item received from backend.`) : Promise.resolve(organizationItem)
      )
  }

  public persistOrganizationGrandIdSetting = (setting: IOrganizationGrandIdSettingPersistItem) => {
    const model = new OrganizationGrandIdSettingPersistItem(setting)
    return this.client
      .persistOrganizationGrandIdSetting(model)
      .then(() =>
        Promise.resolve()
      )
  }

  public deleteOrganizationGrandIdSetting = (id: number) => {
    return this.client
      .deleteOrganizationGrandIdSetting(id)
      .then(() =>
        Promise.resolve()
      )
  }


}

const useSupportOrganizationsApi = () => React.useMemo(() => new SupportOrganizationsApi(), [])

export default useSupportOrganizationsApi
