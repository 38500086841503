import ContainedButton from 'components/inputs/contained-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import React from 'react'

const OrganizationInternalInformationView: React.FC = () => {
  const f = useFormatMessage()

  const { setOrganizationInternalInformationModalOpen } = ProfilePageContext.useActions()

  const handleOpenOrgIntInfoModal = React.useCallback(
    () => setOrganizationInternalInformationModalOpen(true),
    []
  )

  return (
    <ModalSection title={f('PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_TITLE')} subtitle={f('PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_SUBTITLE')} responsive>
      <ModalSectionRow responsive>
        <ContainedButton color="secondary" onClick={handleOpenOrgIntInfoModal}>
          {f('PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_LINK')}
        </ContainedButton>
      </ModalSectionRow>
    </ModalSection>
  )
}

export default OrganizationInternalInformationView
