import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { SelectionEnum } from 'api/tenilo.reporting.api-client'
import type { ReportsPageFormType, ActionBarPropType } from 'pages/reports/config'
import { useFormikContext } from 'formik'
import { AlertCircleOutline } from 'mdi-material-ui'


const ReportsPageSelectionsActionBarView : FC<ActionBarPropType> = () => {
  const f = useFormatMessage()
  const { errors, touched } = useFormikContext<ReportsPageFormType>()


  return (
    <>
      {
        errors && touched && (
          Object.keys(SelectionEnum).some(s => errors[s] && touched[s]) && (
            <AlertCircleOutline color="error" />
          )
        )
      }
    </>
  )
}

export default React.memo(ReportsPageSelectionsActionBarView)
