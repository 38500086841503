import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PageRoute from 'config/page-routes'
import { getDeviceGuid } from 'api/device-guid'
import { NotificationContext } from 'components/notification'

const DeviceRouteContainer: React.FunctionComponent = () => {
  const location = useLocation()
  const localStorageCookie = getDeviceGuid()

  const prefixDailyActivities = PageRoute.DailyActivities.path + "/"
  const deviceGuidFromUrl = location.pathname.substring(prefixDailyActivities.length)

  const validatedToOpenKiosk = !!((localStorageCookie && !deviceGuidFromUrl) || (localStorageCookie && localStorageCookie === deviceGuidFromUrl))

  return (
    validatedToOpenKiosk
      ?
      <NotificationContext.Provider>
        <Outlet />
      </NotificationContext.Provider>
      :
      <Navigate to={PageRoute.Login.path} state={{ from: location }} replace />
  )

}

export default DeviceRouteContainer
