import React from 'react'
import { LoginMethodsItem, OpenSettingsClient } from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class OpenSettingsApi {
  private readonly abortController = new AbortController()
  private readonly client: OpenSettingsClient

  constructor() {
    this.client = new OpenSettingsClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getActiveLoginMethods = async (): Promise<LoginMethodsItem[]> =>
    this.client
      .getActiveLoginMethods()
      .then(methods => (methods === null ? Promise.reject(`No login methods received from backend.`) : Promise.resolve(methods)))
}

const useOpenSettingsApi = () => React.useMemo(() => new OpenSettingsApi(), [])

export default useOpenSettingsApi
