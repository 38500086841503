import React from 'react'
import { useTemplateActivityApi } from 'api'
import { TemplateActivityListItem } from 'api/tillit.api-client'
import { IFlatTemplateActivityGroupListItem } from 'api/template-activity-api'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'

export type TemplateActivityCreateModel = Readonly<{
  title: string
  ownerAreaId: number
  fromTimeHour: number
  fromTimeMinute: number
  toTimeHour: number
  toTimeMinute: number
}>

const useAdministerAreaTemplateActivitiesContext = () => {
  const { listAreaTemplateActivities, abort, deleteTemplateActivity, hasTemplateActivityConnectedToActivity } = useTemplateActivityApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [templateActivityListItems, setTemplateActivityListItems] = React.useState<TemplateActivityListItem[]>([])

  const [modalOpen, setModalOpen] = React.useState(false)

  const location = useLocation()

  const fetchTemplateActivitiesAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listAreaTemplateActivities(currentUser.organizationId)).then(items => {
      setTemplateActivityListItems(items)
    }
    )
  }, [currentUser.organizationId, fetchAsync, listAreaTemplateActivities])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  // Should only fetch initial areaTemplateActivities if currentUser has the setting
  React.useEffect(() => {
    if (currentUser.allowAreaTemplateActivities) {
      void fetchTemplateActivitiesAsync()
    }
  }, [fetchTemplateActivitiesAsync, location])

  const { state: stateOfRemoveFetch, fetchAsync: removeFetchAsync } = usePromiseApi()

  const [templateActivityToRemove, setTemplateActivityToRemove] = React.useState<IFlatTemplateActivityGroupListItem | null>(null)

  const removeDialogOpen = React.useMemo(() => templateActivityToRemove !== null, [templateActivityToRemove])

  const [templateActivityInvalidToRemove, setTemplateActivityInvalidToRemove] = React.useState<IFlatTemplateActivityGroupListItem | null>(null)

  const removeInvalidDialogOpen = React.useMemo(() => templateActivityInvalidToRemove !== null, [templateActivityInvalidToRemove])

  const closeRemoveInvalidDialog = React.useCallback(() => setTemplateActivityInvalidToRemove(null), [setTemplateActivityInvalidToRemove])

  const openRemoveDialog = React.useCallback(
    (templateActivity: IFlatTemplateActivityGroupListItem) => {
      void hasTemplateActivityConnectedToActivity(templateActivity.id, templateActivity.ownerAreaId!).then(result => {
        if (result) {
          setTemplateActivityInvalidToRemove(templateActivity)
        } else {
          setTemplateActivityToRemove(templateActivity)
        }
      })
    },
    [setTemplateActivityToRemove, setTemplateActivityInvalidToRemove, hasTemplateActivityConnectedToActivity]
  )

  const closeRemoveDialog = React.useCallback(() => setTemplateActivityToRemove(null), [setTemplateActivityToRemove])

  const deleteTemplateActivityAsync = React.useCallback(
    templateActivityId => {
      void removeFetchAsync(
        deleteTemplateActivity(templateActivityId)
          .then(fetchTemplateActivitiesAsync)
          .then(closeRemoveDialog)
      )
    },
    [removeFetchAsync, deleteTemplateActivity, fetchTemplateActivitiesAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      templateActivityListItems,
      modalOpen,
      removeDialogOpen,
      templateActivityToRemove,
      templateActivityInvalidToRemove,
      isRemoving,
      removeInvalidDialogOpen,
    },
    actions: {
      fetchTemplateActivitiesAsync,
      openModal,
      closeModal,
      openRemoveDialog,
      closeRemoveDialog,
      closeRemoveInvalidDialog,
      deleteTemplateActivityAsync,
    },
  }
}

export const AdministerAreaTemplateActivitiesContext = createContext(useAdministerAreaTemplateActivitiesContext)
