import React from 'react'
import * as yup from 'yup'

import {
  TemplateActivityCreateItem,
  ITemplateActivityItem,
  TemplateActivityUpdateItem,
  TemplateActivityCategoryItem,
  UserAreaItem,
  TemplateActivityTypeEnum,
  TemplateActivityDelegationItem,
  TemplateActivityDelegationPersistItem,
  UnitItem,
  SeverityLevelEnum,
} from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { TemplateActivityModalContext } from '../template-activity-modal-context'

type TemplateActivityForm = Readonly<{
  id: number | undefined
  title: string
  color: string
  visitLengthInMinutes: number
  description: string
  alertFromStartInMinutes: number
  showColorOnDevice: boolean
  templateActivityCategoryId: number | undefined
  templateActivityCategory: TemplateActivityCategoryItem | undefined
  ownerOrganizationId: number | undefined
  ownerAreaId: number | undefined
  ownerArea: UserAreaItem | undefined
  templateActivityTypeId: TemplateActivityTypeEnum
  templateActivityDelegations?: TemplateActivityDelegationItem[] | undefined,
  unitId: number | undefined
  unit: UnitItem | undefined
  unitAmount: number | undefined
  severityLevelId: SeverityLevelEnum
  useParticipantList: boolean
}>

const createTemplateActivity = (values: TemplateActivityForm): TemplateActivityCreateItem => {
  const {
    ownerArea,
    ownerOrganizationId,
    title,
    color,
    alertFromStartInMinutes,
    showColorOnDevice,
    visitLengthInMinutes,
    description,
    templateActivityCategoryId,
    templateActivityTypeId,
    templateActivityDelegations,
    unitId,
    unitAmount,
    severityLevelId,
    useParticipantList,
  } = values

  const templateActivityDelegationItems = templateActivityDelegations?.map(tad => {
    return new TemplateActivityDelegationPersistItem({
      delegationId: tad.delegationId,
    })
  })

  return new TemplateActivityCreateItem({
    ownerAreaId: ownerArea ? ownerArea.areaId : undefined,
    ownerOrganizationId,
    title,
    color,
    alertFromStartInMinutes,
    showColorOnDevice,
    visitLengthInMinutes,
    description,
    templateActivityCategoryId,
    templateActivityTypeId,
    templateActivityDelegations: templateActivityDelegationItems,
    unitId: useParticipantList ? undefined : unitId,
    unitAmount: useParticipantList ? undefined : unitAmount,
    severityLevelId,
    useParticipantList: templateActivityTypeId === TemplateActivityTypeEnum.AreaTemplate ? useParticipantList : false,
  })
}

const mapTemplateActivityFormToTemplateActivityItem = (initialItem: ITemplateActivityItem, item: TemplateActivityForm): TemplateActivityUpdateItem => {
  const templateActivityDelegationItems = item.templateActivityDelegations?.map(tad => {
    return new TemplateActivityDelegationPersistItem({
      id: tad.id,
      templateActivityId: tad.templateActivityId,
      delegationId: tad.delegationId,
    })
  })

  return new TemplateActivityUpdateItem({
    id: initialItem.id,
    ownerAreaId: item.ownerArea ? (item.ownerArea.areaId !== -1 ? item.ownerArea.areaId : undefined) : undefined,
    ownerOrganizationId: initialItem.ownerOrganizationId,
    title: item.title,
    color: item.color,
    alertFromStartInMinutes: item.alertFromStartInMinutes,
    showColorOnDevice: item.showColorOnDevice,
    visitLengthInMinutes: item.visitLengthInMinutes,
    description: item.description,
    templateActivityCategoryId: item.templateActivityCategoryId,
    templateActivityTypeId: item.templateActivityTypeId,
    templateActivityDelegations: templateActivityDelegationItems,
    unitId: item.useParticipantList ? undefined : item.unitId,
    unitAmount: item.useParticipantList ? undefined : item.unitAmount,
    severityLevelId: item.severityLevelId,
    useParticipantList: item.templateActivityTypeId === TemplateActivityTypeEnum.AreaTemplate ? item.useParticipantList : false,
  })
}

const mapTemplateActivityItemToTemplateActivityForm = (item: ITemplateActivityItem): TemplateActivityForm => {
  const ownerArea: UserAreaItem = new UserAreaItem()
  ownerArea.areaId = item.ownerAreaId ? item.ownerAreaId : -1

  let areaName = item.ownerAreaName || ''
  if (item.ownerAreaOrganizationParentsName) {
    areaName =  `${item.ownerAreaName} (${item.ownerAreaOrganizationParentsName})`
  }
  ownerArea.areaName = areaName

  const templateActivityCategory: TemplateActivityCategoryItem = new TemplateActivityCategoryItem()
  templateActivityCategory.id = item.templateActivityCategoryId ? item.templateActivityCategoryId : -1
  templateActivityCategory.title = item.templateActivityCategoryTitle ? item.templateActivityCategoryTitle : ''

  const unit: UnitItem = new UnitItem()
  unit.id = item.unitId ? item.unitId : -1
  unit.title = item.unitTitle
  unit.abbreviation = item.unitAbbreviation

  const createItem: TemplateActivityForm = {
    id: item.id,
    title: item.title || '',
    color: item.color || '',
    visitLengthInMinutes: item.visitLengthInMinutes,
    description: item.description || '',
    alertFromStartInMinutes: item.alertFromStartInMinutes,
    showColorOnDevice: item.showColorOnDevice,
    templateActivityCategoryId: item.templateActivityCategoryId,
    templateActivityCategory,
    ownerOrganizationId: item.ownerOrganizationId,
    ownerAreaId: item.ownerAreaId,
    ownerArea,
    templateActivityTypeId: item.templateActivityTypeId,
    templateActivityDelegations: item.templateActivityDelegations,
    unitId: item.unitId,
    unitAmount: item.unitAmount,
    unit,
    severityLevelId: item.severityLevelId,
    useParticipantList: item.useParticipantList,
  }
  return createItem
}

const useTemplateActivityFormData = (initialTemplateActivity?: ITemplateActivityItem) => {
  const f = useFormatMessage()
  const { ownerOrganizationId } = TemplateActivityModalContext.useState()
  const { createTemplateActivityAsync, updateTemplateActivityAsync } = TemplateActivityModalContext.useActions()

  const activity = initialTemplateActivity

  const initialValues = React.useMemo<TemplateActivityForm>(
    () =>
      !!activity
        ? mapTemplateActivityItemToTemplateActivityForm(activity)
        : {
          id: undefined,
          title: '',
          color: '#bdbdbd',
          visitLengthInMinutes: 60,
          description: '',
          alertFromStartInMinutes: 0,
          showColorOnDevice: false,
          templateActivityCategoryId: undefined,
          templateActivityCategory: undefined,
          ownerAreaId: undefined,
          ownerOrganizationId,
          ownerArea: undefined,
          templateActivityTypeId: TemplateActivityTypeEnum.CustomerTemplate,
          templateActivityDelegations: [],
          unitId: undefined,
          unitAmount: undefined,
          unit: undefined,
          severityLevelId: SeverityLevelEnum.Normal,
          useParticipantList: false,
        },
    [activity, ownerOrganizationId]
  )

  const onSubmit: OnSubmitFunction<TemplateActivityForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const templateActivity = createTemplateActivity({
        ...values,
      })

      if (!initialTemplateActivity) {
        await createTemplateActivityAsync(templateActivity).finally(() => setSubmitting(false))
      } else {
        const mappedTemplateActivity = mapTemplateActivityFormToTemplateActivityItem(initialTemplateActivity, values)
        await updateTemplateActivityAsync(mappedTemplateActivity).finally(() => setSubmitting(false))
      }
    },
    [createTemplateActivityAsync, initialTemplateActivity, updateTemplateActivityAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_TITLE_TEXTFIELD_LABEL'),
            maxsize: 128,
          })
        ),

      ownerArea: yup
        .object()
        .when('ownerOrganizationId', {
          is: undefined,
          then: yup.object().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        })
        .when('ownerOrganizationId', {
          is: !undefined,
          then: yup.object().nullable(),
        })
        .typeError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      description: yup.string().max(
        1024,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_OTHERSECTION_INFOTEXT_LABEL'),
          maxsize: 1024,
        })
      ),
      visitLengthInMinutes: yup
        .number()
        .integer(
          f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL'),
          })
        )
        .typeError(
          f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL'),
          })
        )
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          1440,
          f('BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL'),
            maxsize: 1440,
          })
        )
        .min(
          1,
          f('BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL'),
            minsize: 1,
          })
        ),
      alertFromStartInMinutes: yup
        .number()
        .integer(
          f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_ALERT_MINUTES_TEXTFIELD_LABEL'),
          })
        )
        .typeError(
          f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_ALERT_MINUTES_TEXTFIELD_LABEL'),
          })
        )
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          1440,
          f('BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_ALERT_MINUTES_TEXTFIELD_LABEL'),
            maxsize: 1440,
          })
        )
        .min(
          0,
          f('BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_INFOSECTION_FORM_ALERT_MINUTES_TEXTFIELD_LABEL'),
            minsize: 0,
          })
        ),
    })
  }, [f])

  const name: Name<TemplateActivityForm> = React.useMemo(
    () => ({
      id: 'id',
      title: 'title',
      color: 'color',
      description: 'description',
      visitLengthInMinutes: 'visitLengthInMinutes',
      alertFromStartInMinutes: 'alertFromStartInMinutes',
      showColorOnDevice: 'showColorOnDevice',
      templateActivityCategoryId: 'templateActivityCategoryId',
      templateActivityCategory: 'templateActivityCategory',
      ownerOrganizationId: 'ownerOrganizationId',
      ownerAreaId: 'ownerAreaId',
      ownerArea: 'ownerArea',
      templateActivityTypeId: 'templateActivityTypeId',
      templateActivityDelegations: 'templateActivityDelegations',
      unitId: 'unitId',
      unitAmount: 'unitAmount',
      unit: 'unit',
      severityLevelId: 'severityLevelId',
      useParticipantList: 'useParticipantList',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useTemplateActivityFormData
