import React from 'react'
import { Bell } from 'mdi-material-ui'
import { Icon, Badge } from '@mui/material';
import type { ActionBarPropType } from 'pages/dashboard/config'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'

const DashboardNotificationActionBarView: React.FC<ActionBarPropType> = ({ openActionBar, actionBarHandler }) => {

  const { numberOfNewNotifications } = DashboardPageContext.useState()
  const { saveLatestNotificationId, setNumberOfNewNotifications } = DashboardPageContext.useActions()

  React.useEffect(() => {
    if (openActionBar) {
      saveLatestNotificationId()
      setNumberOfNewNotifications(0)
    }
  }, [openActionBar, saveLatestNotificationId, setNumberOfNewNotifications]);

  return (
    <>
      <Badge
        badgeContent={numberOfNewNotifications}
        color="secondary"
        sx={{
          '.MuiBadge-badge':{
            fontWeight:'100',
            fontSize:'0.6rem',
            minWidth:'15px',
            height:'15px',
            borderRadius:'50%',
            padding:'4px',
            top:'2px',
            right:'2px'
          }
        }}
        max={9}
      >
        <Icon color="primary"  component={Bell} sx={{fontSize: '1.3rem'}} />
      </Badge>
    </>
  )
}

export default React.memo(DashboardNotificationActionBarView)
