import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 500px;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.palette.background.paper};
  box-shadow: ${props => props.theme.shadows[5]};
  outline: none;
  border-radius: 4px;
  padding: ${props => props.theme.spacing(1)};

  .title {
    margin-bottom: ${props => props.theme.spacing(2)};
  }

  .content {
    padding: ${props => props.theme.spacing(2)};
  }
`
