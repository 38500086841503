import React from 'react'
import * as yup from 'yup'

import Authentication from 'components/authentication'
import PageRoute from 'config/page-routes'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { useNavigate } from "react-router-dom"

type ResetPinCodeForm = Readonly<{
  newPinCode: string
  confirmPinCode: string
}>

const handleError = (setStatus: (status?: any) => void) => (error: any) => {
  setStatus(JSON.parse(error.response))
}

// Define the hook to be used to leverage this form
const useResetPinCodeForm = (userId: number, token: string) => {
  const { changePinCodeWithToken } = Authentication.useActions()
  const f = useFormatMessage()
  const navigate = useNavigate()

  const initialValues: ResetPinCodeForm = React.useMemo(
    () => ({
      newPinCode: '',
      confirmPinCode: '',
    }),
    []
  )

  const onSubmit: OnSubmitFunction<ResetPinCodeForm> = React.useCallback(
    ({ newPinCode }, { setSubmitting, setStatus }) => {
      changePinCodeWithToken(userId, token, newPinCode)
        .then(() => navigate(PageRoute.Login.path))
        .catch(handleError(setStatus))
        .finally(() => {
          setSubmitting(false)
        })
    },
    [changePinCodeWithToken, token, userId]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ResetPinCodeForm>({
        newPinCode: yup
          .string()
          .length(4, f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ERROR_TEXT', { length: 4 }))
          .test('pinCode', f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ONLY_NUMBERS_ERROR_TEXT'), (value: string) => {
            if (!!!value) {
              return true
            }
            return !isNaN(+value)
          })
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        confirmPinCode: yup
          .string()
          .length(4, f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ERROR_TEXT', { length: 4 }))
          .test('pinCode', f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ONLY_NUMBERS_ERROR_TEXT'), (value: string) => {
            if (!!!value) {
              return true
            }
            return !isNaN(+value)
          })
          .oneOf(
            [yup.ref('newPinCode')],
            f('RESET_PINCODE_PAGE_CONFIRM_PINCODE_VALIDATION_EQUAL')
          )
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      }),
    [f]
  )

  const name: Name<ResetPinCodeForm> = React.useMemo(
    () => ({
      newPinCode: 'newPinCode',
      confirmPinCode: 'confirmPinCode',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useResetPinCodeForm
