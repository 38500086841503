import React from 'react'
import { InnerContext } from '../../contexts/inner-context'
import { getMinutesBetween } from '../../utilities/get-minutes-between'
import { useInterval } from '../../utilities/use-interval'

const useTimelinePosition = (
  centerScroll = false
): [number, React.RefObject<HTMLDivElement>] => {
  const { calendarStartDate, containerRef } = InnerContext.useState()
  const { mapMinutesToPixels } = InnerContext.useActions()

  const { current: container } = containerRef

  const timelineRef = React.useRef<HTMLDivElement>(null)

  const calculatePosition = React.useCallback(
    (date: Date) => {
      const currentDate = new Date()
      return mapMinutesToPixels(getMinutesBetween(date, currentDate))
    },
    [mapMinutesToPixels]
  )

  const [position, setPosition] = React.useState(() => {
    return calculatePosition(calendarStartDate)
  })

  React.useEffect(() => {
    if (centerScroll && timelineRef.current !== null && container !== null) {
      timelineRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  }, [centerScroll, container])

  const handleTick = React.useCallback(() => {
    setPosition(calculatePosition(calendarStartDate))
  }, [calculatePosition, calendarStartDate])

  React.useLayoutEffect(() => {
    handleTick()
  }, [handleTick])

  useInterval(handleTick, 60000)
  return [position, timelineRef]
}

type TimelineContainerProps = Readonly<{ centerScroll?: boolean }>
export const TimelineContainer: React.SFC<TimelineContainerProps> = ({
  centerScroll = false,
}) => {
  const { calendarStartDate, calendarEndDate } = InnerContext.useState()

  const [top, ref] = useTimelinePosition(centerScroll)

  if (new Date() > calendarEndDate || new Date() < calendarStartDate) {
    return null
  }

  return (
    <div
      ref={ref}
      style={{
        zIndex: 1,
        backgroundColor: 'red',
        opacity: 0.5,
        height: '2px',
        top,
        left: 0,
        right: 0,
        position: 'absolute',
      }}
    />
  )
}
