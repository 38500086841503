import React from 'react'
import { useAuthenticationApi } from 'api'
import { getDeviceGuid } from 'api/device-guid'
import { IUserItem, SwaggerException } from 'api/tillit.api-client'
import { setTokenData } from 'api/token-data'
import { useFormatMessage } from 'localization'
import usePromiseApi from 'utilities/use-promise'

export interface IVerificationResult {
  success: boolean,
  user: IUserItem | undefined
}

export const usePinModal = (fetchMe : () => Promise<IUserItem>) => {
  const f = useFormatMessage()
  const { generateJwtTokenFromPin } = useAuthenticationApi()

  const { state: fetchState, fetchAsync } = usePromiseApi()

  const [pin, setPin] = React.useState<number[]>([])

  const [isOpen, setIsOpen] = React.useState(false)

  const [pinError, setPinError] = React.useState<string | null>(null)

  const onPinClick = React.useCallback(
    (newPin: number) => {
      setPinError(null)
      setPin(prevPin => [...prevPin,newPin])
    },
    []
  )

  const onPinEraseClick = React.useCallback(() => {
    if (pin.length > 0) {
      setPin(pin.slice(0,-1))
    }
  }, [pin])

  const verifyUser = React.useCallback(
    async (userId: number) => {
      // verify user
      clearPin()
      try {
        const deviceGuid = getDeviceGuid()
        if (!deviceGuid) {
          throw new Error('No device guid')
        }
        const tokenData = await fetchAsync(generateJwtTokenFromPin(userId, pin.join(''), deviceGuid))
        setTokenData(tokenData)
        const user = await fetchAsync(fetchMe())
        const result: IVerificationResult = {
          success: true,
          user
        }
        return result
      } catch (error) {
        if (SwaggerException.isSwaggerException(error)) {
          if (error.status === 403) {
            setPinError(f('DAILY_ACTIVITIES_PAGE_PIN_DIALOG_CONTAINER_WRONG_PIN_TEXT'))
          }
        }
        const result: IVerificationResult = {
          success: false,
          user: undefined
        }
        return result
//        return false
      }
    },
    [pin]
  )

  const close = React.useCallback(() => {
    clearPin()
    setIsOpen(false)
  }, [])

  const open = React.useCallback(() => {
    setIsOpen(true)
    setPinError(null)
  }, [])

  const clearPin = React.useCallback(() => {
    setPin([])
    setPinError(null)
  }, [])

  return React.useMemo(
    () => ({
      actions: {
        open,
        close,
        onPinClick,
        verifyUser,
        clearPin,
        onPinEraseClick,
      },
      state: { fetchState, pin, isOpen, pinError },
    }),
    [fetchState, pin, isOpen, pinError]
  )
}
