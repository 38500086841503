import React from 'react'

// If date has past, set planning to be readonly
export const useIsPlanningReadonly = (initialDate: Date) =>
  React.useMemo(() => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    const pastDate = new Date(initialDate)
    pastDate.setHours(0, 0, 0, 0)
    return pastDate < date
  }, [initialDate])
