import React from 'react'
import * as yup from 'yup'

import { DelegationCategoryCreateItem, IDelegationCategoryItem, DelegationCategoryUpdateItem } from 'api/tillit.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { DelegationCategoryModalContext } from '../delegation-category-modal-context'

type DelegationCategoryForm = Readonly<{
  title: string
  organizationId: number
}>

const createDelegationCategory = (values: DelegationCategoryForm): DelegationCategoryCreateItem => {
  const { title, organizationId } = values

  return new DelegationCategoryCreateItem({
    title,
    organizationId,
  })
}

const mapDelegationCategoryFormToDelegationCategoryItem = (
  initialItem: IDelegationCategoryItem,
  item: DelegationCategoryForm
): DelegationCategoryUpdateItem => {
  return new DelegationCategoryUpdateItem({
    id: initialItem.id,
    title: item.title,
  })
}

const mapDelegationCategoryItemToDelegationCategoryForm = (item: IDelegationCategoryItem): DelegationCategoryForm => {
  const createItem: DelegationCategoryForm = {
    organizationId: item.organizationId,
    title: item.title ? item.title : '',
  }
  return createItem
}

const useDelegationCategoryFormData = (initialDelegationCategory?: IDelegationCategoryItem) => {
  const f = useFormatMessage()

  const { createDelegationCategoryAsync, updateDelegationCategoryAsync } = DelegationCategoryModalContext.useActions()
  const { organizationId } = DelegationCategoryModalContext.useState()
  const category = initialDelegationCategory

  const initialValues = React.useMemo<DelegationCategoryForm>(
    () =>
      !!category
        ? mapDelegationCategoryItemToDelegationCategoryForm(category)
        : {
            organizationId: organizationId ? organizationId : -1,
            title: '',
          },
    [category, organizationId]
  )

  const onSubmit: OnSubmitFunction<DelegationCategoryForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const delegationCategory = createDelegationCategory({
        ...values,
      })
      if (!initialDelegationCategory) {
        await createDelegationCategoryAsync(delegationCategory).finally(() => setSubmitting(false))
      } else {
        const mappedDelegationCategory = mapDelegationCategoryFormToDelegationCategoryItem(initialDelegationCategory, values)
        await updateDelegationCategoryAsync(mappedDelegationCategory).finally(() => setSubmitting(false))
      }
    },
    [createDelegationCategoryAsync, initialDelegationCategory, updateDelegationCategoryAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),
    })
  }, [f])

  const name: Name<DelegationCategoryForm> = React.useMemo(
    () => ({
      organizationId: 'organizationId',
      title: 'title',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useDelegationCategoryFormData
