import React from 'react'
import { Typography, Box } from '@mui/material'
import type { KeyPerformanceIndicatorItem } from 'api/tenilo.dashboard.api-client'
import { useFormatMessage } from 'localization'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'

const DashboardKpiSingleValueView : React.FC<{kpiData:KeyPerformanceIndicatorItem}> = (props) => {
  const { kpiData } = props
  const f = useFormatMessage()
  const { layoutSize } = DashboardPageContext.useState()

  return (
    <Box sx={{
      display:'flex',
      height:'100%',
      flexDirection:'column',
      justifyContent:'space-between'
    }}>
      <Typography color="primary" variant="subtitle1" sx={{
        fontSize:layoutSize === 'l' ? '16px' : layoutSize === 'm' ? '14px' : '12px',
        lineHeight:1,
        fontWeight:'bold',
        '&:first-letter':{
          textTransform: 'uppercase'
        }
      }}>
        {kpiData.valueTitle}
      </Typography>
      <Typography  variant={layoutSize === 'l' ? 'h3' : layoutSize === 'm' ? 'h4' : 'h5'} color="primary" sx={{
        alignSelf:'center',
        fontWeight:'bold'
      }}>
        {`${kpiData.value} ${f(`DASHBOARD_PAGE_KPI_VALUE_UNIT_${kpiData.valueUnit.toUpperCase()}`)}`}
      </Typography>
      <Typography color="gray"  variant="subtitle2" sx={{
        fontSize:layoutSize === 'l' ? '13px' : layoutSize === 'm' ? '12px' :  '11px',
        lineHeight: layoutSize === 'l' ? '1.57' : layoutSize === 'm' ? '1.3' :  '1'
      }}>
        {kpiData.valueDescription}
      </Typography>
    </Box>
  )
}

export default React.memo(DashboardKpiSingleValueView)
