import React from 'react'
import DashboardNotificationContext from './dashboard-notification-context'
import DashboardNotificationView from './dashboard-notification-view'

const DashboardNotificationContainer: React.FC = () => {

  return (
    <DashboardNotificationContext.Provider>
      <DashboardNotificationView />
    </DashboardNotificationContext.Provider>
  )
}

export default DashboardNotificationContainer
