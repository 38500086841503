import { Box } from '@mui/material'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { SideBarColumn, SidebarColumnType, SideBarUserColumn, SideBarUserColumnType } from './components/sidebar-column'

export const SidebarContainer: React.FC = () => {
  const f = useFormatMessage()
  const { isLoading, customerActivities, commonActivities, userWorkSchedules, currentUser, hasUnplannedWorkSchedules, hasUnplannedCustomerActivities, hasUnplannedCommonActivities } = PlanningPageContext.useState()

  return (
    <Box display="flex" bgcolor="grey.100" ml={1}>
      {currentUser && currentUser.useWorkSchedule && (
        <SideBarUserColumn
          columnType={SideBarUserColumnType.Personel}
          columnName={'personel'}
          title={f('PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_PERSONNEL_TITLE')}
          initialUsersWorkSchedules={userWorkSchedules}
          isLoading={isLoading}
          initialVisibility={hasUnplannedWorkSchedules}
        />
      )}
      <SideBarColumn
        columnType={SidebarColumnType.ClientActivities}
        columnName={'customer'}
        title={f('PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_ACTIVITIES_TITLE')}
        initialActivities={customerActivities}
        isLoading={isLoading}
        initialVisibility={hasUnplannedCustomerActivities}
      />
      <SideBarColumn
        columnType={SidebarColumnType.CommonActivities}
        columnName={'area'}
        title={f('PLANNING_PAGE_SIDE_BAR_SECTION_VIEW_COMMON_TASKS_TITLE')}
        initialActivities={commonActivities}
        isLoading={isLoading}
        initialVisibility={hasUnplannedCommonActivities}
      />
    </Box>
  )
}
