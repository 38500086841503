import React from 'react'
import type { Location } from 'history'
import { useLocation } from 'react-router-dom'

export type locationStateType = Location | undefined
export const useLocationState = () : locationStateType => {
  const location = useLocation()

  const locationState = React.useMemo(() => {
    const state = location.state as { from?: Location }

    return state?.from

  }, [location])

  return locationState

}
