import DashboardNotificationContainer, { DashboardNotificationActionBarView } from '../components/dashboard-notification'
import DashboardKpiContainer from '../components/dashboard-kpi'
import type { DashboardSection } from './dashboard-types'

const dashboardSectionConfig : DashboardSection[]  = [
  {
    id:1,
    resizable:true,
    InnerContainer: DashboardNotificationContainer,
    ActionBar: DashboardNotificationActionBarView
  },
  {
    id:2,
    resizable:false,
    InnerContainer: DashboardKpiContainer
  }
]

export { dashboardSectionConfig }

