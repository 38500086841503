import React from 'react'
import { Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormatMessage } from 'localization'
import DashboardSingleNotificationComponent from './components/dashboard-single-notification-component/dashboard-single-notifcation-component'
import DashboardNotificationContext from './dashboard-notification-context'

const DashboardNotificationView: React.FC = () => {
  const f = useFormatMessage()
  const { newNotifications, listNotifications } = DashboardNotificationContext.useState()
  const { fetchNotificationsPaged } = DashboardNotificationContext.useActions()

  return (
    <Box sx={{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      overflow:'hidden',
      boxSizing:'border-box',
      minHeight:'100%',
      justifyContent:'space-between',
      padding:'10px',
    }}>
      {/** search/filter bar */}
      <Box>
        {
          listNotifications.viewList ?

          listNotifications.items.map((item) => <DashboardSingleNotificationComponent key={item.id} {...item} />)

          :

          newNotifications?.map(item => <DashboardSingleNotificationComponent key={item.id} {...item} />)
        }
      </Box>
      <LoadingButton
        onClick={fetchNotificationsPaged(true)}
        color="primary"
        variant="contained"
        loading={listNotifications.loading}
        disabled={(listNotifications?.currentRes?.totalPages || 0) <= (listNotifications?.currentPage || 0)}
        sx={{
          minWidth:'100px',
          margin:'10px'
        }}>
        {f('DASHBOARD_PAGE_NOTIFICATION_BUTTON_TEXT')}
      </LoadingButton>
    </Box>
  )
}

export default DashboardNotificationView
