import { Box, FormControl, MenuItem } from '@mui/material'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledSelect from 'components/inputs/filled-select'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { CustomersPageContext } from '../../customers-page-context'
import useFormData from './utilities/use-customer-create-form-data'

const CustomerCreateModalContainer: React.FC = () => {
  const { initialValues, onSubmit, validationSchema, name } = useFormData()
  const f = useFormatMessage()
  const { closeModal } = CustomersPageContext.useActions()
  const { modalOpen, areas } = CustomersPageContext.useState()

  return (
    <CenterModal open={modalOpen} onClose={closeModal}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ isSubmitting, setFieldValue, values }) => {

          const handleChangeArea = (event: any, child: any) => {
            setFieldValue('areaId', event.target.value)
          }

          return (
            <Form translate="yes">
              <ModalHeader title={f('CUSTOMER_CREATE_DIALOG_CONTAINER_HEADER_TITLE')} subtitle={f('CUSTOMER_CREATE_DIALOG_CONTAINER_HEADER_SUBTITLE')} />
              <Box mx={5}>
                <ModalSection
                  title={f('CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE')}
                  subtitle={f('CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE')}>
                  <ModalSectionRow>
                    <FilledTextField
                      data-matomo-mask
                      fullWidth
                      label={f('CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_FIRSTNAME_LABEL')}
                      name={name.firstName}
                      color="secondary"
                      id='customers-create-modal-firstname-textfield'
                    />
                    <FilledTextField
                      data-matomo-mask
                      fullWidth
                      label={f('CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_LASTNAME_LABEL')}
                      name={name.lastName}
                      color="secondary"
                      id='customers-create-modal-lastname-textfield'
                    />
                  </ModalSectionRow>
                </ModalSection>
                <ModalSection title={f('CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_TITLE')} subtitle={f('CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_SUBTITLE')}>
                  <ModalSectionRow>
                    <FilledTextField
                      data-matomo-mask
                      fullWidth
                      label={f('CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_TEXTFIELD_LABEL')}
                      name={name.extraInfo}
                      color="secondary"
                      id='customers-create-modal-extrainfo-textfield'
                    />
                  </ModalSectionRow>
                </ModalSection>
                <ModalSection title={f('CUSTOMER_CREATE_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE')}>
                  <ModalSectionRow>
                    <FilledTextField
                      data-matomo-mask
                      fullWidth
                      multiline
                      rows={4}
                      label={f('CUSTOMER_CREATE_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL')}
                      name={name.description}
                      color="secondary"
                      id='customers-create-modal-description-textfield'
                    />
                  </ModalSectionRow>
                </ModalSection>
                <ModalSection
                  title={f('CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_INFOTEXT_TITLE')}
                  subtitle={f('CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_INFOTEXT_SUBTITLE')}>
                  <ModalSectionRow>
                    <FormControl>
                      <FilledSelect name={name.areaId} label={f('CUSTOMER_CREATE_DIALOG_CONTAINER_AREASECTION_FORM_SELECT_AREA_LABEL')} value={values.areaId} onChange={handleChangeArea}>
                        {areas.map((area, index) => (
                          <MenuItem key={index} value={area.areaId}>
                            {area.organizationParentsName ? `${area.areaName} (${area.organizationParentsName})` : area.areaName}
                          </MenuItem>
                        ))}
                      </FilledSelect>
                    </FormControl>
                  </ModalSectionRow>
                </ModalSection>
              </Box>
              <ModalFooter>
                <TextButton type="button" onClick={closeModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box pl={1} />
                <ContainedButton type="submit" color="secondary" showProgress={isSubmitting} id='customers-create-modal-submit-button'>
                  {f('CUSTOMER_CREATE_DIALOG_CONTAINER_FOOTER_SUBMIT_BUTTON_TEXT')}
                </ContainedButton>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default CustomerCreateModalContainer
