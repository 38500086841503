import { produce } from 'immer'
import { IEventProps, ITracksProps } from '../planning-grid-context'
import { getEndDate } from './get-end-date'

const expandEvent = (e: IEventProps, index: number, columns: IEventProps[][]) => {
  let colSpan = 1
  const subColumns = columns.slice(index + 1)
  for (const subColumn of subColumns) {
    for (const event of subColumn) {
      const collides = collidesWith(event, e)
      if (collides) {
        return colSpan
      }
    }
    colSpan++
  }
  return colSpan
}

const collidesWith = (e1: IEventProps, e2: IEventProps) => {
  const e1StartTime = e1.startDate.setSeconds(0, 0)
  const e1EndTime = e1StartTime + 60000 * e1.lengthInMinutes
  const e2StartTime = e2.startDate.setSeconds(0, 0)
  const e2EndTime = e2StartTime + 60000 * e2.lengthInMinutes
  return e1StartTime < e2EndTime && e1EndTime > e2StartTime
}

const packSessions = (columns: IEventProps[][]): void => {
  const numColumns = columns.length
  for (let i = 0; i < numColumns; i++) {
    const col = columns[i]
    for (const event of col) {
      const colSpan = expandEvent(event, i, columns)
      event.left = i / numColumns
      event.right = (i + colSpan) / numColumns
    }
  }
}

export const arrangePlanning = (tracks: ITracksProps[]) =>
  produce(tracks.slice(0), draftTracks => {
    for (const trackIndex in draftTracks) {
      if (!draftTracks.hasOwnProperty(trackIndex)) {
        continue
      }
      const track: ITracksProps = draftTracks[trackIndex]
      const events = track.sessions.sort((s1, s2) => {
        if (s1.startDate < s2.startDate) {
          return -1
        }
        if (s1.startDate > s2.startDate) {
          return 1
        }
        if (s1.lengthInMinutes < s2.lengthInMinutes) {
          return -1
        }
        if (s1.lengthInMinutes > s2.lengthInMinutes) {
          return 1
        }
        return 0
      })

      let columns: IEventProps[][] = []
      let lastEventEnding: Date | null = null
      let maxColumnsLength = 0

      for (const event of events) {
        if (lastEventEnding !== null && event.startDate >= lastEventEnding) {
          packSessions(columns)
          maxColumnsLength = Math.max(maxColumnsLength, columns.length)
          columns = []
          lastEventEnding = null
        }
        let placed = false
        for (const col of columns) {
          if (!collidesWith(col[col.length - 1], event)) {
            col.push(event)
            placed = true
            break
          }
        }

        if (!placed) {
          columns.push([event])
        }

        const eventEndDate = getEndDate(event.startDate, event.lengthInMinutes)
        if (lastEventEnding === null || eventEndDate > lastEventEnding) {
          lastEventEnding = eventEndDate
        }

        if (columns.length > 0) {
          packSessions(columns)
        }
      }
      track.columnCount = Math.max(maxColumnsLength, columns.length)
    }
  })
