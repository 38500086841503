import React from 'react'
import CenterModal from 'components/center-modal'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import { OrganizationInternalInfoContext } from './organization-internal-info-modal-context'
import { OrganizationInternalInfoModalView } from './organization-internal-info-modal-view'

export const OrganizationInternalInfoContainer: React.FC = () => {
  const { organizationInternalInformationModalOpen } = ProfilePageContext.useState()
  const { setOrganizationInternalInformationModalOpen} = ProfilePageContext.useActions()

  const handleClose = React.useCallback(() => setOrganizationInternalInformationModalOpen(false), [])

  return (
    <CenterModal open={organizationInternalInformationModalOpen} onClose={handleClose}>
      <OrganizationInternalInfoContext.Provider>
        <OrganizationInternalInfoModalView />
      </OrganizationInternalInfoContext.Provider>
    </CenterModal>
  )
}
