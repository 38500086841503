import React from 'react'
import { Checkbox, FormControlLabel, Typography, Box } from '@mui/material'
import ContainedButton from 'components/inputs/contained-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import { UserTypeEnum, RoleEnum } from 'api/tillit.api-client'
import useUserForm from 'components/user-dialog/utilities/use-user-form'
import FilledTextFieldView from 'components/inputs/filled-text-field/filled-text-field-view'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import { FormikProps } from 'formik'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'


const SecuritySection: React.FC<FormikProps<UserFormModelWithModifiedAreas>> = ({ setFieldValue, ...props  }) => {

  const f = useFormatMessage()
  const [sendToAdmin, setSendToAdmin] = React.useState(false)
  const { name } = useUserForm()
  const { passIsUpdated, checkedAreaRoles, user, dialogActionType, loggedInUser, calledFromOtherPersonell } = UserDialogContext.useState()
  const { changePassword } = UserDialogContext.useActions()
  const handleResetPasswordClick = async () => {
    await changePassword(sendToAdmin)
  }

  const handleSendToAdminChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSendToAdmin(checked)
  }

  const handleOnFocus: React.EventHandler<any> = event => {
    if (event.target.value === '••••') {
      setFieldValue(name.pinCode, '')
    }
  }
  const userIsPersonnell = checkedAreaRoles.some(a => a.roleId === RoleEnum.Caretaker)

  let disablePasswordReset = passIsUpdated || calledFromOtherPersonell

  return (
    <Box width="90%">
      {userIsPersonnell && (dialogActionType === 'add' || (dialogActionType === 'edit' && (user?.active || (calledFromOtherPersonell)))) && (
        <ModalSection title={f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_LABEL')} subtitle={f('USER_DIALOG_VIEW_USERSECTION_INFOTEXT_SUBTITLE')}>
          <ModalSectionRow>
            <FilledTextFieldView
              data-matomo-mask
              fullWidth
              label={f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_LABEL')}
              onFocus={handleOnFocus}
              type="text"
              name={name.pinCode}
              color="secondary"
              disabled={calledFromOtherPersonell && user?.hasSavedPinCode}
            />
          </ModalSectionRow>
        </ModalSection>
      )}
      {dialogActionType === 'edit' && user && loggedInUser && user.id !== loggedInUser.id && user?.active && (
        <ModalSection title={f('USER_DIALOG_VIEW_RESETPASSWORDSECTION_INFOTEXT_TITLE')} subtitle={''}>
          {user == null ||
            (!user.email && (
              <ModalSectionRow display="flex" flexDirection="row" alignItems="center">
                <Typography>{f('USER_DIALOG_VIEW_RESETPASSWORDSECTON_MISSING_USER_EMAIL_TEXT')}</Typography>
              </ModalSectionRow>
            ))}
          {user != null && !!user.email && (
            <>
              <ModalSectionRow display="flex" flexDirection="row" alignItems="center">
                <ContainedButton onClick={handleResetPasswordClick} disabled={disablePasswordReset}>
                  {f('USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_BUTTON_TEXT')}
                </ContainedButton>
              </ModalSectionRow>
              <ModalSectionRow display="flex" flexDirection="row" alignItems="center">
                <Typography variant={'caption'} color="textSecondary" hidden={!passIsUpdated}>
                  {f('USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_RECEIPT_TEXT')}
                </Typography>
              </ModalSectionRow>
              {loggedInUser.userType !== UserTypeEnum.OrganizationMember && (
                <ModalSectionRow display="flex" flexDirection="row" alignItems="center">
                  <FormControlLabel
                    label={f('USER_DIALOG_VIEW_RESETPASSWORDSECTION_FORM_RESETPASSWORD_CHECKBOX_TEXT')}
                    control={<Checkbox checked={sendToAdmin} onChange={handleSendToAdminChange} />}
                  />
                </ModalSectionRow>
              )}
            </>
          )}
        </ModalSection>
      )}
    </Box>
  )
}

export default SecuritySection
