import ModalSection from 'components/modal-section'
import { useFormatMessage } from 'localization'
import React from 'react'

const ContactSupportView: React.FC = () => {
  const f = useFormatMessage()

  return (
    <>
      <ModalSection
        title={f('CONTACT_SUPPORT_INFO_TEXT_TITLE')}
        subtitle={'support@tenilo.se'}
      />
      <ModalSection
        title={f('CONTACT_SUPPORT_INFO_TEXT_SUBTITLE')}
        subtitle={'031 - 767 52 00'}
      />
    </>
  )
}

export default ContactSupportView
