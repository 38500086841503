import { Box, Button, Modal, Typography } from '@mui/material'
import ColorPicker from 'components/inputs/color-picker'
import OutlinedTextField from 'components/inputs/outlined-text-field'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { StyledModalBox } from './edit-route-modal-style'
import useFormData from './utilities/use-edit-route-modal-form-data'

const EditRouteModalView: React.FC = () => {
  const f = useFormatMessage()
  const { openEditRouteModal, editRouteModalData } = PlanningPageContext.useState()
  const { onCloseEditRouteModal } = PlanningPageContext.useActions()
  const { initialValues, name, onSubmit, validationSchema } = useFormData()

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={openEditRouteModal} onClose={onCloseEditRouteModal}>
      <StyledModalBox>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, errors }) => (
            <Form translate="yes">
              <Box className="content">
                <Typography data-matomo-mask variant="body1" className="title">
                  {f('PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_DIALOG_TITLE')} {editRouteModalData!.name}
                </Typography>
                <OutlinedTextField
                  data-matomo-mask
                  name={name.routeName}
                  label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_FORM_NAME_LABEL')}
                  value={values.routeName}
                  className="field"
                  color="secondary"
                  error={!!errors.routeName}
                  helperText={errors.routeName}
                />
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <div>
                  <ColorPicker name={name.routeColor} />
                </div>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button type="button" color="secondary" onClick={onCloseEditRouteModal}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </Button>
                <Button type="submit" color="secondary">
                  {f('BASIC_BUTTON_SAVE_TEXT')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </StyledModalBox>
    </Modal>
  )
}

export default EditRouteModalView
