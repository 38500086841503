import * as React from 'react'
import { useField, FieldAttributes } from "formik"
import { useFormatMessage } from 'localization'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import sv from 'date-fns/locale/sv'
type ReportDatePickerProps = { initialValues?: number, required?: boolean, textFieldLable?: string } & FieldAttributes<Record<string, unknown>>

const ReportDatePicker: React.FC<ReportDatePickerProps> = ({
  textFieldLable,
  required,
  initialValues,
  ...props
}) => {
  const [field, meta, helpers] = useField<Record<string, unknown>>(props)

  const f = useFormatMessage()
  const handleChange = React.useCallback((newValue) => {
    helpers.setValue(newValue)
  }, [])

  const handleClose = React.useCallback(() => {
    helpers.setTouched(true)
  }, [])

  React.useEffect(() => {
    if (required && !field.value && !meta.error) {
      helpers.setError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
    }
  }, [field, meta, required])

  const handleBlur = React.useCallback((e) => {
    helpers.setTouched(true)
  }, [])

  React.useEffect(() => {
    if (initialValues) {
      helpers.setValue(new Date(initialValues * 1000) as unknown as Record<string, unknown>)
    } else {
      helpers.setValue(new Date() as unknown as Record<string, unknown>)
    }
  }, [initialValues])

  const getInitialValues = () => {
    if (field.value) {
      return field.value
    }
    return undefined
  }

  const onKeyDown = (e) => {
    e.preventDefault()
  }

  return  React.useMemo(()=>{
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <DatePicker
        label={textFieldLable}
        value={getInitialValues()}
        onClose={handleClose}
        format="yyyy-MM-dd"
        onChange={handleChange}
        sx={{ width: '49%' }}
        localeText={{
          toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
          okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
          cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
        }}
        slotProps={{
          actionBar: {
            actions: [],
          },
        }}

      />
      </LocalizationProvider>
    )

  },[field.value,meta.error,field.name,meta.touched])

}

export default React.memo(ReportDatePicker)
