import { Box, Button, Modal } from '@mui/material'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import ActivityParticipants from 'components/activity-participants'
import { StyledModalBox } from './edit-participants-modal-style'
import useFormData from './utilities/use-edit-participants-modal-form-data'

const EditParticipantsModalView: React.FC = () => {
  const f = useFormatMessage()
  const { openEditParticipantsModal, activeCustomersOnPlanningArea } = PlanningPageContext.useState()
  const { onCloseEditParticipantsModal } = PlanningPageContext.useActions()
  const { initialValues, name, onSubmit, validationSchema } = useFormData()

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={openEditParticipantsModal} onClose={onCloseEditParticipantsModal}>
      <StyledModalBox>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, errors }) => (
            <Form translate="yes">
              <Box className="content">

                <ActivityParticipants
                  activityParticipants={values.activityParticipants}
                  availableCustomers={activeCustomersOnPlanningArea}
                />

              </Box>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button type="button" color="secondary" onClick={onCloseEditParticipantsModal}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </Button>
                <Button type="submit" color="secondary">
                  {f('BASIC_BUTTON_SAVE_TEXT')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </StyledModalBox>
    </Modal>
  )
}

export default EditParticipantsModalView
