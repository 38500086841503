import React from 'react'
import { Icon, Box, Typography } from '@mui/material';
import { ChevronDown, ChevronUp } from 'mdi-material-ui'
import { INotificationListItem } from 'api/tenilo.dashboard.api-client'
import { format } from 'date-fns'
import { Colors } from 'styles/colors'

const DashboardSingleNotificationComponent: React.FC<INotificationListItem> = ({ title, description, createdOn }) => {
  const [openNotifi, setOpenNotifi] = React.useState<boolean>()

  const handleOpenNotifi = React.useCallback(()=>{
    if (window.getSelection()?.type === 'Range') {
      return
    }
    setOpenNotifi(curr => !curr)
  },[])

  const handleNotifiActionBarClick = React.useCallback((e)=>{
    e. stopPropagation()
  },[])

  return (
    <Box
      onClick={handleOpenNotifi}
      sx={{
        display:'flex',
        flexDirection:'column',
        position:'relative',
        width:'320px',
        height:'auto',
        borderRadius:'5px',
        boxSizing:'border-box',
        minHeight: '80px',
        maxHeight: openNotifi ? '600px' : '125px',
        transition:'all 0.5s linear',
        marginBottom:'10px',
        padding:'5px 5px 0 5px',
        borderTop:`1px solid ${Colors.Alto}`,
        borderLeft:`1px solid ${Colors.Alto}`,
        overflow:'hidden',
        boxShadow: `1px 1px 5px 1px ${Colors.Alto}`,
        cursor:'pointer',
        '&:hover':{
          boxShadow: `2px 2px 6px 1px ${Colors.Alto}`,
        }
      }}
    >
      <Box
        onClick={handleNotifiActionBarClick}
        sx={{
          display:'flex',
          padding:'2px',
          justifyContent:'flex-end',
          alignItems:'center',
          boxSizing:'border-box',
          position:'absolute',
          right:0,
          top:0,
          borderRadius:'0 5px 0 5px',
          width:'30%',
          height:'20px',
          cursor:'default',
          backgroundColor:Colors.AthensGray
        }}
      >
        {/* More notification action component...  */}
        {
          description.length > 120 && (
            <Icon onClick={handleOpenNotifi} color="primary"  component={openNotifi ? ChevronUp : ChevronDown} sx={{fontSize: '1.2rem',cursor:'pointer'}} />
          )
        }
      </Box>
      <Typography variant="subtitle1" sx={{
        fontSize:'15px',
        lineHeight:1,
        fontWeight:'bold',
        width:'70%',
        '&:first-letter':{
          textTransform: 'uppercase'
        }
      }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={{
        lineHeight:1,
      }}>
        {format(createdOn,'yyyy-MM-dd HH:mm')}
      </Typography>
      <Typography
        data-matomo-mask
        variant="body2"
        sx={{
        width:'100%',
        padding:'5px 5px 5px 0',
        transition:'all 0.5s linear',
        wordBreak:'break-word',
        overflow:'hidden',
        '&:first-letter':{
          textTransform: 'uppercase'
        }
      }}>
        {(!openNotifi &&  description.length > 120) ? (
          `${description.slice(0,120)}...`
        ): (
          description
        )}
      </Typography>
    </Box>
  )
}

export default React.memo(DashboardSingleNotificationComponent)
