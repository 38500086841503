import React from 'react'
import { Box, IconButton, Tooltip, useTheme, Typography } from '@mui/material'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import Authentication from 'components/authentication'
import { useFormatMessage } from 'localization'
import { uniqueAndSortedUserAreas } from 'utilities/user-helper'
import { filterUsers } from 'utilities/support-user-helper'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { ActiveUserImpersonationRequestListItem, UserImpersonationRequestStatusEnum, UserListItem, UserAreaListItem, UserTypeEnum } from 'api/tenilo.support.api-client';
import { AccountLockOpen as ImpersonateUserIcon } from 'mdi-material-ui'
import { SupportUserModalContext } from './components/support-user-modal/support-user-modal-context'
import { SupportUsersContext } from './support-users-context'
import { SupportUserImpersonateModalContext } from './components/support-user-impersonate-modal/support-user-impersonate-modal-context'
import SupportUserImpersonateModal from './components/support-user-impersonate-modal/support-user-impersonate-modal-container'
import { RoleEnum } from 'api/tillit.api-client';

const SupportUsersView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { userListItems, isLoading, impersonateUserModalOpen, userToImpersonate } = SupportUsersContext.useState()
  const [pageSize, setPageSize] = React.useState<number>(50);
  const f = useFormatMessage()
  const getFilteredUsers = filterUsers(f)
  const { openImpersonateUserModal, closeImpersonateUserModal } = SupportUsersContext.useActions()
  const { openSupportUserModal } = SupportUserModalContext.useActions()

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openSupportUserModal(params.row.id)
    },
    [openSupportUserModal]
  )

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredUsers, setFilteredUsers] = React.useState<UserListItem[] | undefined>(userListItems?.items)

  React.useEffect(() => {
    if (filterValue && userListItems?.items) {
      const filtered = getFilteredUsers(filterValue, userListItems?.items)
      setFilteredUsers(filtered)
    } else {
      if (userListItems?.items) {
        setFilteredUsers(userListItems?.items)
      } else {
        setFilteredUsers([])
      }
    }
    // NOTE: We are missing filterValue and getFilteredUsers as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [userListItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      if (userListItems?.items) {
        const filtered = getFilteredUsers(searchValue, userListItems?.items)
        setFilteredUsers(filtered)
      }
    },
    [setFilterValue, setFilteredUsers, userListItems, getFilteredUsers]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredUsers(userListItems?.items)
    },
    [setFilterValue, setFilteredUsers, userListItems]
  )

  const handleImpersonateUserClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, user: UserListItem, canBeImpersonated: boolean) => {
      _?.stopPropagation()
      if (!canBeImpersonated) {
        return
      }
      openImpersonateUserModal(user)
    },
    [openImpersonateUserModal]
  )

  const handleConfirmImpersonateUserClick = React.useCallback(() => {
    closeImpersonateUserModal()
  }, [closeImpersonateUserModal])

  const handleCloseImpersonateUserClick = React.useCallback(() => {
    closeImpersonateUserModal()
  }, [closeImpersonateUserModal])

  return (
    <div style={{ padding: '2vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box></Box>
              <Box sx={{ marginRight: '0px', marginLeft: 'auto', paddingBottom: '1vh', width: '25%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </Box>
            </Box>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredUsers as any}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}
            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('SUPPORT_USERS_COLUMN_FULLNAME'),
                field: 'fullName',
                renderCell: (params: GridRenderCellParams) => {
                  if (params.row.active) {
                    return (
                      <span data-matomo-mask>{params.row.fullName}</span>
                    )
                  }
                  return (
                    <span data-matomo-mask style={{ textDecoration: 'line-through' }}>{params.row.fullName}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERS_COLUMN_EMAIL'),
                field: 'email',
                renderCell: (params: GridRenderCellParams) => {
                  if (params.row.active) {
                    return (
                      <span data-matomo-mask>{params.row.email}</span>
                    )
                  }
                  return (
                    <span data-matomo-mask style={{ textDecoration: 'line-through' }}>{params.row.email}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERS_COLUMN_PHONENUMBER'),
                field: 'phoneNumber',
                renderCell: (params: GridRenderCellParams) => {
                  if (params.row.active) {
                    return (
                      <span data-matomo-mask>{params.row.phoneNumber}</span>
                    )
                  }
                  return (
                    <span data-matomo-mask style={{ textDecoration: 'line-through' }}>{params.row.phoneNumber}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERS_COLUMN_USERTYPEID'),
                field: 'userTypeId',
                renderCell: (params: GridRenderCellParams) => {
                  if (params.row.active) {
                    return (
                      <span data-matomo-mask>{params.row.userTypeId === UserTypeEnum.OrganizationAdmin ? f('SUPPORT_USER_MODAL_DIALOG_TYPE_ORGADMIN') : ''}</span>
                    )
                  }
                  return (
                    <span data-matomo-mask style={{ textDecoration: 'line-through' }}>{params.row.userTypeId === UserTypeEnum.OrganizationAdmin ? f('SUPPORT_USER_MODAL_DIALOG_TYPE_ORGADMIN') : ''}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERS_COLUMN_ORGANIZATIONNAME'),
                field: 'organizationName',
              },
              {
                flex: 1,
                headerName: f('USERS_PAGE_VIEW_COLUMN_AREAS_TITLE'),
                field: 'userAreas',
                renderCell: (params: GridRenderCellParams) => {
                  const userAreas = params.value as UserAreaListItem[]
                  const unigueUserAreas = uniqueAndSortedUserAreas(userAreas)
                  if (unigueUserAreas && unigueUserAreas.length <= 2) {
                    return <Typography>{unigueUserAreas.map(ua => ua).join(', ')}</Typography>
                  }
                  return (
                    <span>
                      <Tooltip title={unigueUserAreas.join(', ')} aria-label={unigueUserAreas.join(', ')}>
                        <Typography>
                          {f('USERS_PAGE_VIEW_COLUMN_AREAS_TEXT', {
                            firstArea: unigueUserAreas[0],
                            numberOfRemainingAreas: unigueUserAreas ? unigueUserAreas.length - 1 : 0,
                          })}
                        </Typography>
                      </Tooltip>
                    </span>
                  )
                },
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_deactivate',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {

                  if (!params.row.active) {
                    return (<span></span>)
                  }

                  const userAreas = params.row.userAreas as UserAreaListItem[]
                  const canBeImpersonated = userAreas && userAreas.some(ua => ua.roleId === RoleEnum.Admin || ua.roleId === RoleEnum.Planner)
                  const userImpersonationRequest: ActiveUserImpersonationRequestListItem | undefined = params.row.userImpersonationRequests && params.row.userImpersonationRequests.length > 0 ? params.row.userImpersonationRequests[0] : undefined
                  let requestBackGroundColor = ''
                  let requestButtonColor = 'secondary'
                  let tooltipText = 'SUPPORT_USERS_COLUMN_IMPERSONATE_BUTTON_TOOLTIP'
                  if (userImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.AwaitingApproval) {
                    requestBackGroundColor = 'yellow'
                    tooltipText = 'SUPPORT_USERS_COLUMN_IMPERSONATE_AWAITING_BUTTON_TOOLTIP'
                  }
                  if (userImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.Approved) {
                    requestBackGroundColor = 'lightgreen'
                    tooltipText = 'SUPPORT_USERS_COLUMN_IMPERSONATE_APPROVED_BUTTON_TOOLTIP'
                  }

                  if (!canBeImpersonated) {
                    tooltipText = 'SUPPORT_USERS_COLUMN_IMPERSONATE_NOT_ALLOWED_BUTTON_TOOLTIP'
                    requestButtonColor = 'disabled'
                  }

                  return (
                    <span>
                      <Tooltip
                        data-matomo-mask
                        title={f(tooltipText, {
                          fullName: params.row.fullName,
                        })}
                        aria-label={f(tooltipText, {
                          fullName: params.row.fullName
                        })}>
                        <IconButton sx={{ backgroundColor: requestBackGroundColor }} onClick={e => handleImpersonateUserClick(e, params.row, canBeImpersonated)} size="large" >
                          <ImpersonateUserIcon color={requestButtonColor as any} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },


            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />

          {impersonateUserModalOpen && userToImpersonate && (
            <SupportUserImpersonateModalContext.Provider>
              <SupportUserImpersonateModal
                userToImpersonate={userToImpersonate}
                onAbortImpersonation={handleCloseImpersonateUserClick}
                onConfirmImpersonation={handleConfirmImpersonateUserClick}
              />
            </SupportUserImpersonateModalContext.Provider>
          )}

        </>
      )}

    </div>
  )
}

export default SupportUsersView
