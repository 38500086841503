import React from 'react'
import { useKioskApi } from 'api'
import { clearDeviceGuid, getDeviceGuid } from 'api/device-guid'
import {
  IDeactivateKioskItem,
  IKioskListItem,
  KioskItem,
} from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { filterDevices } from 'utilities/administer-devices-helper'

const useAdministerDevicesContext = () => {
  const { getKioskByGuid, getKiosks, deactivateKiosk } = useKioskApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const [deviceGuid, setDeviceGuid] = React.useState<string | null>(null)
  const [kiosk, setKiosk] = React.useState<KioskItem | undefined>(undefined)
  const [activatedDevices, setActivatedDevices] = React.useState<
    IKioskListItem[]
  >([])
  const f = useFormatMessage()
  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const getFilteredDevices = filterDevices(f)
  const [filteredDevices, setFilteredDevices] = React.useState<IKioskListItem[]>([])
  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredDevices(searchValue, activatedDevices)
      setFilteredDevices(filtered)
    },
    [filterValue, activatedDevices]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredDevices(activatedDevices)
    },
    [activatedDevices]
  )
  const fetchDeviceGuidFromLocalStorageAsync = React.useCallback(() => {
    setDeviceGuid(getDeviceGuid())
  }, [])

  const fetchKioskFromDeviceGuidAsync = React.useCallback(() => {
    if (deviceGuid) {
      fetchAsync(getKioskByGuid(deviceGuid))
        .then(setKiosk)
        .catch(error => {
          setKiosk(undefined)
        })
    }
  }, [fetchAsync, getKioskByGuid, deviceGuid])

  const fetchActivatedDevicesListAsync = React.useCallback(() => {
    fetchAsync(getKiosks()).then(list=>{
      setActivatedDevices(list)
      setFilteredDevices(list)
    })
  }, [fetchAsync, getKiosks])

  React.useEffect(() => {
    fetchDeviceGuidFromLocalStorageAsync()
  }, [fetchDeviceGuidFromLocalStorageAsync])

  React.useEffect(() => {
    fetchKioskFromDeviceGuidAsync()
  }, [fetchKioskFromDeviceGuidAsync])

  React.useEffect(() => {
    fetchActivatedDevicesListAsync()
  }, [fetchActivatedDevicesListAsync])

  const handleDeactivatedDevice = async (item: IDeactivateKioskItem) => {
    return await fetchAsync(deactivateKiosk(item)).then(() => {
      if (kiosk && kiosk.id === item.id) {
        clearDeviceGuid()
      }
      fetchActivatedDevicesListAsync()
    })
  }

  return {
    state: { ...stateOfFetch, kiosk, activatedDevices, deviceGuid, filteredDevices, filterValue },
    actions: {
      handleDeactivatedDevice,
      fetchDeviceGuidFromLocalStorageAsync,
      fetchKioskFromDeviceGuidAsync,
      fetchActivatedDevicesListAsync,
      handleChangeFilter,
      handleClearFilter
    },
  }
}

export const AdministerDevicesContext = createContext(
  useAdministerDevicesContext
)
