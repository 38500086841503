import React from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import Authentication from 'components/authentication'
import { useFormatMessage } from 'localization'
import { filterAreas } from 'utilities/support-area-helper';
import FilledSearchTextField from 'components/inputs/filled-search-text-field';
import { AreaListItem } from 'api/tenilo.support.api-client';
import { SupportOrganizationModalContext } from './components/support-organization-modal/support-organization-modal-context'
import { SupportAreaModalContext } from './components/support-area-modal/support-area-modal-context'
import { SupportAreasContext } from './support-areas-context'
import supportPageContext from 'pages/support/support-page-context';

const SupportAreasView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { areaListItems, isLoading } = SupportAreasContext.useState()

  const { openSupportAreaModal } = SupportAreaModalContext.useActions()
  const { openSupportOrganizationModal } = SupportOrganizationModalContext.useActions()

  const { selectedOrganization } = supportPageContext.useState()

  const f = useFormatMessage()
  const getFilteredAreas = filterAreas(f)

  const handleSupportAreaClick = async (areaId?: number) => {
    if (!areaId) {
      console.error("Value is undefined or null");
      return;
    }

    openSupportAreaModal(areaId)
  }

  const handleSupportOrganizationClick = async (organizationId?: number) => {
    if (!organizationId) {
      console.error("Value is undefined or null");
      return;
    }
    openSupportOrganizationModal(organizationId)
  }

  const handleSupportCurrentOrganizationClick = React.useCallback(() => {
    if (!selectedOrganization) {
      console.error("Value is undefined or null");
      return;
    }
    openSupportOrganizationModal(selectedOrganization.id)
  }, [selectedOrganization])

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredAreas, setFilteredAreas] = React.useState<AreaListItem[] | undefined>(areaListItems?.items)

  React.useEffect(() => {
    if (filterValue && areaListItems?.items) {
      const filtered = getFilteredAreas(filterValue, areaListItems?.items)
      setFilteredAreas(filtered)
    } else {
      setFilteredAreas(areaListItems?.items)
    }
    // NOTE: We are missing filterValue and getFilteredLegislations as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [areaListItems])


  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      if (areaListItems?.items) {
        const filtered = getFilteredAreas(searchValue, areaListItems?.items)
        setFilteredAreas(filtered)
      }
    },
    [setFilterValue, setFilteredAreas, areaListItems, getFilteredAreas]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredAreas(areaListItems?.items)
    },
    [setFilterValue, setFilteredAreas, areaListItems]
  )


  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '2vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box><Button variant='contained' disabled={!selectedOrganization} onClick={handleSupportCurrentOrganizationClick}>Hantera organisation</Button></Box>
              <Box sx={{ marginRight: '0px', marginLeft: 'auto', paddingBottom: '1vh', width: '25%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </Box>
            </Box>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredAreas as any}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('SUPPORT_AREAS_COLUMN_ORGANIZATIONNAME'),
                field: 'organizationName',
              },
              {
                flex: 1,
                headerName: f('SUPPORT_AREAS_COLUMN_AREANAME'),
                field: 'name',
                renderCell: (params: GridRenderCellParams) => {
                  if (params.row.active) {
                    return (
                      <span>{params.row.name}</span>
                    )
                  }
                  return (
                    <span style={{ textDecoration: 'line-through' }}>{params.row.name}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_AREAS_COLUMN_PARENTSNAME'),
                field: 'organizationParentsName',
              },
              {
                headerName: ' ',
                flex: 2,
                field: 'no_field_SUPPORT_AREA',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <Button href="" variant="text" color="secondary" onClick={() => handleSupportAreaClick(params.row.id)}>
                      {f('SUPPORT_AREA_BUTTON')}
                    </Button>
                  )
                },
              },


            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />

        </>
      )}




    </div>
  )
}

export default SupportAreasView
