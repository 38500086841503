import { Box, FormControl } from '@mui/material'
import { AreaItem, RoleEnum } from 'api/tillit.api-client'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import useAuthorization from 'utilities/use-authorization'
import useFormData from './utilities/use-basic-information-form-data'
import BigCheckbox from 'components/inputs/big-checkbox'

interface IBasicInformation {
  customRef: React.RefObject<any>
  areaInfo: AreaItem
}

const BasicInformation: React.FC<IBasicInformation> = ({ customRef, areaInfo }) => {
  const f = useFormatMessage()
  const { initialValues, onSubmit, validationSchema, areaName } = useFormData(areaInfo)
  const { hasRoleOnAnyArea } = useAuthorization()
  const roleCheck = hasRoleOnAnyArea(RoleEnum.Admin)

  return (
    <Box px={2} mx={5} height="60vh" overflow="auto">
      <Formik innerRef={customRef} initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, errors }) => (
          <Form translate="yes">
            <ModalSection
              title={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_AREA_NAME_TITLE')}
              subtitle={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_AREA_NAME_SUBTITLE')}>
              <ModalSectionRow>
                <ModalSectionRow>
                    <FilledTextField
                      data-matomo-mask
                      type="text"
                      fullWidth
                      label={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_AREA_NAME_TITLE')}
                      name={areaName.name}
                      value={values.name}
                      color="secondary"
                      disabled={!roleCheck}
                    />
                </ModalSectionRow>
              </ModalSectionRow>
            </ModalSection>
            <ModalSection
              title={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_INFOSECTION_INTERNAL_INFORMATION_TITLE')}
              >
              <FilledTextField
                data-matomo-mask
                type="text"
                fullWidth
                rows={4}
                name={areaName.internalInformation}
                value={values.internalInformation || ''}
                color="secondary"
                multiline
                disabled={!roleCheck}
              />
            </ModalSection>

            <ModalSection
                title={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_TITLE')}
                subtitle={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_NAVIGATE_SUBTITLE')}>
                <ModalSectionRow>
                  <>
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_YES')}`}
                      name={areaName.pwaAllowNavigateToPlanningPage}
                      value="true"
                      checkedByValue
                      returnValue="value"
                    />
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_NO')}`}
                      name={areaName.pwaAllowNavigateToPlanningPage}
                      value="false"
                      checkedByValue
                      returnValue="value"
                    />
                  </>
                </ModalSectionRow>
              </ModalSection>

              <ModalSection
                title={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_TITLE')}
                subtitle={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_ALLOW_USEMULTISIGNING_SUBTITLE')}>
                <ModalSectionRow>
                  <>
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_YES')}`}
                      name={areaName.pwaAllowUseMultiSigning}
                      value="true"
                      checkedByValue
                      returnValue="value"
                    />
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_NO')}`}
                      name={areaName.pwaAllowUseMultiSigning}
                      value="false"
                      checkedByValue
                      returnValue="value"
                    />
                  </>
                </ModalSectionRow>
              </ModalSection>

              <ModalSection
                title={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_SUPPRESSTIMESONKIOSKANDPWA_TITLE')}
                subtitle={f('AREA_DIALOG_BASIC_INFORMATION_VIEW_SECTION_DESCRIPTION_FIELD_SUPPRESSTIMESONKIOSKANDPWA_SUBTITLE')}>
                <ModalSectionRow>
                  <>
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_YES')}`}
                      name={areaName.suppressTimesOnKioskAndPwa}
                      value="true"
                      checkedByValue
                      returnValue="value"
                    />
                    <BigCheckbox
                      label={`${f('BASIC_TEXT_NO')}`}
                      name={areaName.suppressTimesOnKioskAndPwa}
                      value="false"
                      checkedByValue
                      returnValue="value"
                    />
                  </>
                </ModalSectionRow>
              </ModalSection>

          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default BasicInformation
