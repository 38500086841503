import { useTemplateActivityApi } from 'api'
import { TemplateActivitiesToAreasItem } from 'api/tillit.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useConnectTemplateActivitiesToAreasModalContext = () => {
  const { connectTemplateActivitiesToAreas } = useTemplateActivityApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedTemplateActivityIds, setSelectedTemplateActivityIds] = React.useState<[number]>([0])

  const openConnectTemplateActivitiesToAreasModal = React.useCallback(
    (templateActivityIds: [number]) => {
      setSelectedTemplateActivityIds(templateActivityIds)
      setModalOpen(true)
    },
    [setSelectedTemplateActivityIds, setModalOpen]
  )

  const closeConnectTemplateActivitiesToAreasModal = React.useCallback(() => {
    setModalOpen(false)
  }, [])

  const connectTemplateActivitiesToAreasAsync = React.useCallback(
    (templateActivitiesToAreasItem: TemplateActivitiesToAreasItem) => {
      return fetchAsync(
        connectTemplateActivitiesToAreas({
          ...templateActivitiesToAreasItem,
        })
      ).then(() => {
        closeConnectTemplateActivitiesToAreasModal()
      })
    },
    [fetchAsync, connectTemplateActivitiesToAreas, closeConnectTemplateActivitiesToAreasModal]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedTemplateActivityIds,
    },
    actions: {
      openConnectTemplateActivitiesToAreasModal,
      closeConnectTemplateActivitiesToAreasModal,
      connectTemplateActivitiesToAreasAsync,
    },
  }
}

export const ConnectTemplateActivitiesToAreasModalContext = createContext(useConnectTemplateActivitiesToAreasModalContext)
