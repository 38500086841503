import React from 'react'
import { Box } from '@mui/material'
import { IPlannedActivityRowItem } from 'api/tillit.api-client'
import CenterModal from 'components/center-modal'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import SingleActivityModalContext  from '../../single-activity-modal-context'

interface IListingModalContainerProps {
  updateDataOnStatusChanged: (plannedActivity: IPlannedActivityRowItem) => void
}

const ListingModalContainer: React.FC<IListingModalContainerProps> = ({ updateDataOnStatusChanged }) => {
  const f = useFormatMessage()
  const [comment, setComment] = React.useState('')
  const { listingModalOpen } = SingleActivityModalContext.useState()
  const { close, onUnsignClicked } = SingleActivityModalContext.useActions()


  const handleUndoSigningActivityAction = React.useCallback(async () => {
    const activity = await onUnsignClicked(comment)
    updateDataOnStatusChanged(activity!)
    close()
  }, [onUnsignClicked, updateDataOnStatusChanged, close, comment])

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComment(event.target.value)
  }

  return (
    <CenterModal open={listingModalOpen} onClose={close} responsive>
      <ModalHeader
        title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_TITLE')}
        subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_SUBTITLE')}
        responsive
      />
      <Box sx={(theme)=>({
        px:4,
        [theme.breakpoints.down('md')]:{
          px:1
        }
      })}>
        <FilledTextField
          fullWidth={true}
          label={f('LISTING_MODAL_LISTING_FIELD_LABEL')}
          multiline={true}
          rows="4"
          margin="normal"
          value={comment}
          onChange={handleCommentChange}
        />
      </Box>
      <Box sx={(theme)=>({
        display: 'flex',
        alignSelf:'flex-end',
        px:4,
        pb:2,
        [theme.breakpoints.down('md')]:{
          px:1,
          pb:1
        }
      })}>
        <TextButton type="button" color="secondary" onClick={close}>
          {f('BASIC_BUTTON_CANCEL_TEXT')}
        </TextButton>
        <Box pl={1} />
        <TextButton type="button" color="secondary" onClick={handleUndoSigningActivityAction}>
          {f('BASIC_BUTTON_SAVE_TEXT')}
        </TextButton>
      </Box>
    </CenterModal>
  )
}

export default React.memo(ListingModalContainer)
