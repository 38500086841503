import { useTemplateActivityApi } from 'api'
import {
  TemplateActivityInheritanceItem,
  TemplateActivityListItem,
  TemplateActivityItem,
  TemplateActivityInheritanceCreateItem,
  TemplateActivityInheritanceUpdateItem
} from 'api/tillit.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import Authentication from 'components/authentication'

import { AdministerInheritedTemplateActivitiesContext } from '../administer-inherited-template-activities/administer-inherited-template-activities-context'

const useInheritedTemplateActivityModalContext = () => {
  const {
    getTemplateActivity,
    getTemplateActivityInheritance,
    updateTemplateActivityInheritance,
    createTemplateActivityInheritance,
    listOrganizationTemplateActivities,
    hasTemplateConnectedToArea,
    listNonInheritedTemplateActivities,
  } = useTemplateActivityApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const { fetchTemplateActivitiesAsync: fetchInheriteanceTemplateActivitiesAsync } = AdministerInheritedTemplateActivitiesContext.useActions()

  const [ownerAreaId, setOwnerAreaId] = React.useState<number | undefined>(undefined)

  const [ownerOrganizationId, setOwnerOrganizationId] = React.useState<number | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [selectedInheritedTemplateActivity, setSelectedInheritedTemplateActivity] = React.useState<TemplateActivityInheritanceItem | undefined>()

  const [templateActivityItems, setTemplateActivityItems] = React.useState<TemplateActivityListItem[]>([])

  const [selectedTemplateActivity, setSelectedTemplateActivity] = React.useState<TemplateActivityItem | undefined>()

  const [templateActivityAlreadyExistOnArea, setTemplateActivityAlreadyExistOnArea] = React.useState<boolean>(false)

  const fetchTemplateActivitiesAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listOrganizationTemplateActivities(currentUser.organizationId)).then(setTemplateActivityItems)
  }, [currentUser.organizationId, fetchAsync, listOrganizationTemplateActivities])

  const fetchNewSelectedTemplateActivityAsync = React.useCallback(
    async (templateActivityId: number | undefined) => {
      if (!templateActivityId) {
        setSelectedTemplateActivity(undefined)
        return
      }

      await fetchAsync(getTemplateActivity(templateActivityId)).then(templateActivity => {
        if (templateActivity instanceof TemplateActivityItem) {
          setSelectedTemplateActivity(templateActivity)
        }
      })
    },
    [fetchAsync, getTemplateActivity, setSelectedTemplateActivity]
  )

  const fetchNonInheritedTemplateActivitiesAsync = React.useCallback(
    async (areaId: number | undefined) => {
      if (!areaId) {
        return
      }
      await fetchAsync(listNonInheritedTemplateActivities(areaId)).then(setTemplateActivityItems)
    },
    [fetchAsync, listNonInheritedTemplateActivities, setTemplateActivityItems]
  )

  const fetchTemplateActivityInheritanceAsync = React.useCallback(
    async (templateActivityInheritanceId: number) => {
      await fetchAsync(getTemplateActivityInheritance(templateActivityInheritanceId)).then(ita => {
        if (ita instanceof TemplateActivityInheritanceItem) {
          setSelectedInheritedTemplateActivity(ita)
          void fetchNewSelectedTemplateActivityAsync(ita.templateActivityId)
          //setSelectedTemplateActivity(ita.templateActivity)
        }
      })
    },
    [fetchAsync, getTemplateActivityInheritance, fetchNewSelectedTemplateActivityAsync]
  )

  const openInheritedTemplateActivityModal = React.useCallback(
    (templateActivityId?: number) => {
      void fetchTemplateActivitiesAsync().then(_ => {
        if (!!templateActivityId) {
          void fetchTemplateActivityInheritanceAsync(templateActivityId).then(_ => setModalOpen(true))
        } else {
          setModalOpen(true)
        }
      })
    },
    [fetchTemplateActivityInheritanceAsync, fetchTemplateActivitiesAsync]
  )

  const closeInheritedTemplateActivityModal = React.useCallback(() => {
    setSelectedInheritedTemplateActivity(undefined)
    setModalOpen(false)
  }, [])

  const createInheritedTemplateActivityAsync = React.useCallback(
    (createTemplateActivityInheritanceModel: TemplateActivityInheritanceCreateItem) => {
      return fetchAsync(
        hasTemplateConnectedToArea(createTemplateActivityInheritanceModel.templateActivityId, createTemplateActivityInheritanceModel.areaId)
      ).then(exists => {
        if (exists) {
          setTemplateActivityAlreadyExistOnArea(true)
          return Promise.resolve()
        } else {
          setTemplateActivityAlreadyExistOnArea(false)
          return fetchAsync(
            createTemplateActivityInheritance({
              ...createTemplateActivityInheritanceModel,
            })
          ).then(_ => {
            void fetchInheriteanceTemplateActivitiesAsync()
            closeInheritedTemplateActivityModal()
          })
        }
      })
    },
    [fetchAsync, createTemplateActivityInheritance, closeInheritedTemplateActivityModal, fetchInheriteanceTemplateActivitiesAsync, hasTemplateConnectedToArea]
  )

  const updateTemplateActivityInheritanceAsync = React.useCallback(
    (templateActivityInheritance: TemplateActivityInheritanceUpdateItem, performHasTemplateConnectedToAreaCheck: boolean) => {
      if (performHasTemplateConnectedToAreaCheck) {
        return fetchAsync(hasTemplateConnectedToArea(templateActivityInheritance.templateActivityId, templateActivityInheritance.areaId)).then(exists => {
          if (exists) {
            setTemplateActivityAlreadyExistOnArea(true)
            return Promise.resolve()
          } else {
            setTemplateActivityAlreadyExistOnArea(false)

            return fetchAsync(updateTemplateActivityInheritance(templateActivityInheritance))
              .then(_ => {
                void fetchInheriteanceTemplateActivitiesAsync()
                closeInheritedTemplateActivityModal()
              })
              .catch(() => {
                closeInheritedTemplateActivityModal()
              })
          }
        })
      } else {
        return fetchAsync(updateTemplateActivityInheritance(templateActivityInheritance))
          .then(_ => {
            void fetchInheriteanceTemplateActivitiesAsync()
            closeInheritedTemplateActivityModal()
          })
          .catch(() => {
            closeInheritedTemplateActivityModal()
          })
      }
    },
    [closeInheritedTemplateActivityModal, fetchAsync, fetchInheriteanceTemplateActivitiesAsync, updateTemplateActivityInheritance, hasTemplateConnectedToArea]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedInheritedTemplateActivity,
      ownerAreaId,
      ownerOrganizationId,
      templateActivityItems,
      selectedTemplateActivity,
      templateActivityAlreadyExistOnArea,
    },
    actions: {
      openInheritedTemplateActivityModal,
      closeInheritedTemplateActivityModal,
      createInheritedTemplateActivityAsync,
      updateTemplateActivityInheritanceAsync,
      setOwnerAreaId,
      setOwnerOrganizationId,
      setSelectedTemplateActivity,
      fetchNewSelectedTemplateActivityAsync,
      fetchNonInheritedTemplateActivitiesAsync,
    },
  }
}

export const InheritedTemplateActivityModalContext = createContext(useInheritedTemplateActivityModalContext)
