import { useSupportGrandIdSettingsApi } from 'api'
import { GrandIdServiceItem, GrandIdServiceUpdateItem } from 'api/tenilo.support.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
//import { AdministerSupportGrandIdsContext } from '../administer-legislations/administer-legislations-context'

const useSupportGrandIdModalContext = () => {
  const { listGrandIdServices, updateGrandIdService } = useSupportGrandIdSettingsApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [grandIdServices, setGrandIdServices] = React.useState<GrandIdServiceItem[] | undefined>()

  const [selectedGrandIdService, setSelectedGrandIdService] = React.useState<GrandIdServiceItem | undefined>()

  const fetchGrandIdServicesAsync = React.useCallback(async () => {
    await fetchAsync(listGrandIdServices().then(setGrandIdServices))
  }, [fetchAsync, listGrandIdServices])


  const openSupportGrandIdModal = React.useCallback(
    () => {
      void fetchGrandIdServicesAsync().then(() => setModalOpen(true))
    },
    [fetchGrandIdServicesAsync]
  )

  const closeSupportGrandIdModal = React.useCallback(() => {
    setModalOpen(false)
  }, [])

  const refreshGrandIdServices = React.useCallback(
    () => {
      void fetchGrandIdServicesAsync()
    },
    [fetchGrandIdServicesAsync]
  )

  const updateGrandIdServiceAsync = React.useCallback(
    (service: GrandIdServiceUpdateItem) => {
      return fetchAsync(updateGrandIdService(service))
        .then(() => {
          refreshGrandIdServices()
//          closeSupportGrandIdModal()
        })
        .catch(() => {
          closeSupportGrandIdModal()
        })
    },
    [closeSupportGrandIdModal, fetchAsync, updateGrandIdService, refreshGrandIdServices]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      grandIdServices,
      selectedGrandIdService,
    },
    actions: {
      openSupportGrandIdModal,
      closeSupportGrandIdModal,
      updateGrandIdServiceAsync,
      setSelectedGrandIdService,
      refreshGrandIdServices,
    },
  }
}

export const SupportGrandIdModalContext = createContext(useSupportGrandIdModalContext)
