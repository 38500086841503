import { OrganizationGrandIdSettingItem, OrganizationGrandIdSettingPersistItem } from 'api/tenilo.support.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import { useSupportOrganizationsApi } from 'api'
import usePromiseApi from 'utilities/use-promise'

const useGrandIdSettingModalContext = () => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { persistOrganizationGrandIdSetting } = useSupportOrganizationsApi()
  const [selectedOrganizationGrandIdSetting, setSelectedOrganizationGrandIdSetting] = React.useState<OrganizationGrandIdSettingItem | undefined>()

  const closeGrandIdSettingModal = React.useCallback(() => {
    setSelectedOrganizationGrandIdSetting(undefined)
    setModalOpen(false)
  }, [])

  const persistOrganizationGrandIdSettingAsync = React.useCallback(
    (setting: OrganizationGrandIdSettingPersistItem) => {
      return fetchAsync(persistOrganizationGrandIdSetting(setting))
        .then(() => {
          closeGrandIdSettingModal()
        })
        .catch(() => {
          closeGrandIdSettingModal()
        })
    },
    [fetchAsync, persistOrganizationGrandIdSetting, closeGrandIdSettingModal]
  )

  const openGrandIdSettingModal = React.useCallback(
    (organizationGrandIdSetting?: OrganizationGrandIdSettingItem) => {
      setSelectedOrganizationGrandIdSetting(organizationGrandIdSetting)
      setModalOpen(true)
    },
    []
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedOrganizationGrandIdSetting,
    },
    actions: {
      openGrandIdSettingModal,
      closeGrandIdSettingModal,
      setSelectedOrganizationGrandIdSetting,
      persistOrganizationGrandIdSettingAsync
    },
  }
}

export const GrandIdSettingModalContext = createContext(useGrandIdSettingModalContext)
