import { useSupportOrganizationsApi } from 'api'
import { OrganizationItem, OrganizationUpdateItem } from 'api/tenilo.support.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useSupportOrganizationModalContext = () => {
  const { getOrganization, updateOrganization, anonymizeOrganization } = useSupportOrganizationsApi()
  const { deleteOrganizationGrandIdSetting } = useSupportOrganizationsApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedOrganization, setSelectedOrganization] = React.useState<OrganizationItem | undefined>()

  const fetchOrganizationAsync = React.useCallback(
    async (organizationId: number) => {
      await fetchAsync(getOrganization(organizationId)).then(organization => {
        if (organization instanceof OrganizationItem) {
          setSelectedOrganization(organization)
        }
      })
    },
    [fetchAsync, getOrganization]
  )

  const openSupportOrganizationModal = React.useCallback(
    (organizationId?: number) => {
      if (!!organizationId) {
        void fetchOrganizationAsync(organizationId).then(_ => setModalOpen(true))
      } else {
        setModalOpen(true)
      }
    },
    [fetchOrganizationAsync]
  )

  const refreshOrganizationModal = React.useCallback(
    () => {
      if (selectedOrganization) {
        void fetchOrganizationAsync(selectedOrganization.id)//.then(_ => setModalOpen(true))
      } else {
        setModalOpen(false)
      }
    },
    [selectedOrganization, fetchOrganizationAsync]
  )

  const deleteOrganizationGrandIdSettingAsync = React.useCallback(
    (id: number) => {
      return deleteOrganizationGrandIdSetting(id).then(() => {
        refreshOrganizationModal()
      }
      )
    },
    [refreshOrganizationModal]
  )

  const closeSupportOrganizationModal = React.useCallback(() => {
    setSelectedOrganization(undefined)
    setModalOpen(false)
  }, [])

  const updateOrganizationAsync = React.useCallback(
    (organization: OrganizationUpdateItem) => {
      return fetchAsync(updateOrganization(organization))
        .then(() => {
          closeSupportOrganizationModal()
        })
        .catch(() => {
          closeSupportOrganizationModal()
        })
    },
    [closeSupportOrganizationModal, fetchAsync, updateOrganization]
  )

  const anonymizeOrganizationAsync = React.useCallback(() => {
    if (!selectedOrganization) {
      return
    }
    return fetchAsync(anonymizeOrganization(selectedOrganization.id))
      .then(() => {
        closeSupportOrganizationModal()
      })
      .catch(() => {
        closeSupportOrganizationModal()
      })
  },
    [closeSupportOrganizationModal, fetchAsync, anonymizeOrganization, selectedOrganization]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedOrganization,
    },
    actions: {
      openSupportOrganizationModal,
      closeSupportOrganizationModal,
      updateOrganizationAsync,
      setSelectedOrganization,
      anonymizeOrganizationAsync,
      refreshOrganizationModal,
      deleteOrganizationGrandIdSettingAsync,
    },
  }
}

export const SupportOrganizationModalContext = createContext(useSupportOrganizationModalContext)
