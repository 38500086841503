import { Box } from '@mui/material'
import styled from 'styled-components'

export const SingleActivityModalMainBody = styled(Box)`
  margin: 0 ${(props) => props.theme.spacing(5)};
  flex:1;
  overflow: auto;
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    justify-content: flex-start;
    margin: 0 ${(props) => props.theme.spacing(1)};
  }
`

export const ModalSectionIconContainer = styled(Box)`
  margin-right: 0;
  height: 18px;

  ${({ theme }) => [theme.breakpoints.down('md')]}{
    display: none;
  }
`
