import React from 'react'
import { createContext } from 'utilities/create-context'

const useModalsContext = () => {
  const [createActivityModalOpen, setCreateActivityModalOpen] = React.useState<boolean>(false)

  return {
    state: {
      createActivityModalOpen,
    },
    actions: {
      setCreateActivityModalOpen,
    },
  }
}

export const ModalsContext = createContext(useModalsContext)
