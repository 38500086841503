import { useUserApi } from 'api'
import { IUserItem, RoleEnum, UserAreaItem } from 'api/tillit.api-client'
import { UserFormModel } from 'api/user-api'
import useAuthorization from 'utilities/use-authorization'
import Authentication from 'components/authentication'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { userAreasByRole } from 'utilities/use-user-areas'

export type Area = Readonly<{
  id: number
  name: string
  organizationParentsName: string
}>

export type AreaRole = Readonly<{
  areaId: number
  roleId: RoleEnum
}>

export type DialogActionType = Readonly<'add' | 'edit'>

type UserDialogState = Readonly<{
  availableAreas: Area[]
  listedAreas: Area[]
  checkedAreaRoles: AreaRole[]
  user?: IUserItem
  dialogActionType: DialogActionType
  domainOrganisation?: string
  passIsUpdated: boolean
  dialogOpen: boolean
  calledFromOtherPersonell: boolean
}>

const initState = (): UserDialogState => ({
  availableAreas: [],
  listedAreas: [],
  checkedAreaRoles: [],
  user: undefined,
  dialogActionType: 'add',
  dialogOpen: false,
  domainOrganisation: '',
  passIsUpdated: false,
  calledFromOtherPersonell: false
})

const getAllAreasOfUser = (user: IUserItem, roleId?:RoleEnum[]) => {
  let { userAreas = [] } = user
  if( roleId ) {
    userAreas = userAreasByRole( userAreas, roleId)
  }
  return Array.from<Area>(
    userAreas
      .reduce(
        (result, element) =>
          result.set(element.areaId, {
            id: element.areaId,
            name: element.areaName || '',
            organizationParentsName: element.organizationParentsName || '',
          }),
        new Map<number, Area>()
      )
      .values()
  )
}

const getDialogActionType = (userId?: number) => {
  return userId ? 'edit' : 'add'
}

const getDomain = (user: IUserItem) => {
  return user.email?.split('@')[1]
}

const useUserDialogContext = () => {
  const { create: createUser, get: getUser, update: updateUser, requestChangePasswordToken, abort } = useUserApi()
  const { fetchAsync } = usePromiseApi()
  const { user: loggedInUser } = Authentication.useAuthenticatedState()

  const { hasRoleOnAnyArea } = useAuthorization()
  const isPersonnel: boolean = hasRoleOnAnyArea(RoleEnum.Caretaker) && !hasRoleOnAnyArea(RoleEnum.Planner, RoleEnum.Admin)
  const [emailCannotBe, setEmailCannotBe] = React.useState<string>('')

  React.useEffect(() => () => abort(), [abort])

  const [state, setState] = React.useState<UserDialogState>({
    ...initState(),
    domainOrganisation: getDomain(loggedInUser),
    availableAreas: getAllAreasOfUser(loggedInUser,[RoleEnum.Admin, RoleEnum.Planner]),
  })

  const getAvailableAreas = React.useCallback((user: IUserItem, loggedUser?: IUserItem): Array<Readonly<{ id: number; name: string; organizationParentsName: string; }>> => {
    if (!loggedUser) {
      return []
    }
    const { userAreas = [] } = user
    const { userAreas: loggedUserAreas = [] } = loggedUser
    return userAreasByRole(loggedUserAreas,[RoleEnum.Admin, RoleEnum.Planner]).filter(lua => !userAreas.some(ua => ua.areaId === lua.areaId)).map(lua => ({ id: lua.areaId, name: lua.areaName!, organizationParentsName: lua.organizationParentsName! }))
  }, [])

  const fetchUserAsync = React.useCallback(
    async (userId: number) =>
      await fetchAsync(
        getUser(userId).then(u =>
          setState(prevState => ({
            ...prevState,
            availableAreas: getAvailableAreas(u, loggedInUser),
            listedAreas: getAllAreasOfUser(u),
            checkedAreaRoles: u.userAreas || [],
            user: u,
            dialogActionType: getDialogActionType(userId),
            domainOrganisation: getDomain(loggedInUser),
          }))
        )
      ).catch(error => {
        throw error
      }),
    [fetchAsync, getUser, getAvailableAreas, loggedInUser]
  )

  const createUserAsync = React.useCallback(
    async (createUserModel: UserFormModel) => {
      const success = await fetchAsync(createUser(createUserModel)).then(u => !!u)
      return success
    },
    [createUser, fetchAsync]
  )

  const updateUserAsync = React.useCallback(
    async (model: UserFormModel) => {
      const success = await fetchAsync(
        updateUser({
          ...model,
          id: model.id || 0,
        })
      ).then(u => !!u)
      return success
    },
    [updateUser, fetchAsync]
  )

  const resetState = React.useCallback(
    () =>
      setState({
        ...initState(),
        domainOrganisation: getDomain(loggedInUser),
        availableAreas: getAllAreasOfUser(loggedInUser,[RoleEnum.Admin, RoleEnum.Planner]),
      }),
    [loggedInUser]
  )

  const open = React.useCallback(
    async (userId?: number, calledFromOtherPersonellTab?: boolean) => {
      if (!loggedInUser) {
        return
      }

      if (userId !== undefined) {
        try {
          await fetchUserAsync(userId)
        } catch (error) {
          return
        }
      } else {
        resetState()
      }

      setState(prev => ({
        ...prev,
        dialogOpen: true,
        calledFromOtherPersonell: calledFromOtherPersonellTab ? calledFromOtherPersonellTab : false,
      }))
    },
    [loggedInUser, fetchUserAsync, resetState]
  )

  const handleClose = React.useCallback(() => {
    resetState()
    setState(prev => ({
      ...prev,
      dialogOpen: false,
      calledFromOtherPersonell: false,
    }))
  }, [resetState, setState])

  const addArea = React.useCallback(
    async (areaId: number) => {
      const { availableAreas, listedAreas } = state

      const area = availableAreas.find(a => a.id === areaId)
      if (!area) {
        setState(state)
        return Promise.resolve(state)
      }

      const newAvailableAreas = availableAreas.filter(a => a.id !== areaId)
      const newListedAreas = listedAreas.some(a => a.id === areaId) ? listedAreas : [...listedAreas, area]

      const newState = {
        ...state,
        availableAreas: newAvailableAreas,
        listedAreas: newListedAreas,
      }
      setState(newState)
      return Promise.resolve(newState)
    },
    [state]
  )

  const removeArea = React.useCallback(
    (areaId: number) => {
      setState(prevState => {
        const { availableAreas, listedAreas, checkedAreaRoles } = prevState

        const area = listedAreas.find(a => a.id === areaId)
        if (!area) {
          return prevState
        }

        const newAvailableAreas = availableAreas.some(a => a.id === areaId) ? availableAreas : [...availableAreas, area]
        const newListedAreas = listedAreas.filter(a => a.id !== areaId)
        const newCheckedAreaRoles = checkedAreaRoles.filter(ar => ar.areaId !== areaId)

        return {
          ...prevState,
          availableAreas: newAvailableAreas,
          listedAreas: newListedAreas,
          checkedAreaRoles: newCheckedAreaRoles,
        }
      })
    },
    [setState]
  )

  const changeRole = React.useCallback(
    async (areaId: number, roleId: RoleEnum, checked: boolean) => {
      const { checkedAreaRoles } = state

      if (checked) {
        const alreadyChecked = checkedAreaRoles.some(ar => ar.areaId === areaId && ar.roleId === roleId)
        if (alreadyChecked) {
          setState(state)
          return Promise.resolve(state)
        }
        const newCheckedAreaRoles = [...checkedAreaRoles, { areaId, roleId }]

        const newState = {
          ...state,
          checkedAreaRoles: newCheckedAreaRoles,
        }
        setState(newState)
        return Promise.resolve(newState)
      } else {
        const newCheckedAreaRoles = checkedAreaRoles.filter(ar => ar.areaId !== areaId || ar.roleId !== roleId)

        const newState = {
          ...state,
          checkedAreaRoles: newCheckedAreaRoles,
        }
        setState(newState)
        return Promise.resolve(newState)
      }
    },
    [state]
  )

  const changePassword = (sendToAdmin: boolean): Promise<void> => {
    if (state.user) {
      return requestChangePasswordToken(state.user.id, sendToAdmin).then(() => {
        setState(prevState => ({ ...prevState, passIsUpdated: true }))
        return Promise.resolve()
      })
    }
    return Promise.reject('No user found when changing password.')
  }

  return {
    state: {
      ...state,
      loggedInUser,
      isPersonnel,
      emailCannotBe
    },
    actions: {
      open,
      addArea,
      removeArea,
      changeRole,
      createUserAsync,
      updateUserAsync,
      changePassword,
      clearUser: resetState,
      fetchUserAsync,
      setState,
      handleClose,
      setEmailCannotBe
    },
  }
}

export const UserDialogContext = createContext(useUserDialogContext)
