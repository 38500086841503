import { useAuthenticationApi, useMeApi, useUserApi } from 'api'
import { getDeviceGuid } from 'api/device-guid'
import { IUserItem, UserAreaItem, RoleEnum, FederatedLoginResponse, GrandIdRequestModel } from 'api/tillit.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi, { PromiseState } from 'utilities/use-promise'
import { reduceAreas } from 'utilities/use-user-areas'
import isEmpty from 'lodash/isEmpty'
import { getVisibleAreaData } from 'utilities/area-helper'
import { rootUser } from 'utilities/use-user-areas'
import { clearSupportTokenData } from 'api/token-data'
import {
  clearUserData,
  getUserData,
  setUserData,
} from './utilities/user-storage'
import { clearGidsSessionData, getGidsSessionData } from './utilities/gids-session-storage'

const useUser = () => {
  const { get, abort } = useMeApi()
  const [user, setUser] = React.useState<IUserItem | null>(getUserData())

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    if (!user) {
      clearUserData()
    } else {
      setUserData(user)
    }
  }, [user])

  const reloadUser = React.useCallback(
    () =>
      get().then(u => {
        setUser(u)
        return u
      }),
    [get]
  )
  const unsetUser = React.useCallback(() => {
    setUser(null)
  }, [setUser])

  return { user, setUser, reloadUser, unsetUser }
}

const useAuthenticationContext = () => {
  const {
    generateJwtTokenFromLogin,
    revokeRefreshToken,
    abort: abortAuthentication,
    initiateGidsSaml,
    initiateGidsFrejaeId,
    generateJwtTokenFromGidsSession,
    logOutFromGids,
  } = useAuthenticationApi()

  const {
    changePasswordUsingCurrentPassword,
    changePasswordUsingToken,
    changePinCodeUsingToken,
    abort: abortUser,
  } = useUserApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user, setUser, reloadUser, unsetUser } = useUser()
  const [visibleAreas, setVisibleAreas] = React.useState<UserAreaItem[]>(getVisibleAreaData())
  const [usingCalendarFromDevice, setUsingCalendarFromDevice] = React.useState<boolean>(false)
  const [tokenExpired, setTokenExpired] = React.useState<boolean>(false)

  const userAreas = React.useMemo(() => {
    let reducedAreas = reduceAreas(user?.userAreas ?? [])
    if (user && !rootUser(user)) {
      const adminAreas = user.userAreas?.filter(area => area.roleId === RoleEnum.Admin || area.roleId === RoleEnum.Planner)
      reducedAreas = reducedAreas.filter(area => adminAreas?.some(adminArea => adminArea.areaId === area.areaId))
    }
    if (!isEmpty(visibleAreas) && reducedAreas.length > 1) {
      const visibleAreaIds = visibleAreas.map(area => area.areaId)
      reducedAreas = reducedAreas.filter(area => visibleAreaIds.includes(area.areaId))
    }
    return reducedAreas
  }, [
    user, visibleAreas, usingCalendarFromDevice
  ])



  const [planningAreaId, setPlanningAreaId] = React.useState<null | number>(
    () => {
      if (userAreas.length === 0) {
        return null
      }
      return userAreas[0].areaId
    }
  )

  const [planningDeviceId, setPlanningDeviceId] = React.useState<null | number>(
    null
  )
  const [planningId, setPlanningId] = React.useState<number | null>(
    planningAreaId
  )
  const [planningType, setPlanningType] = React.useState<'areas' | 'devices'>(
    'areas'
  )

  React.useLayoutEffect(() => {
    if (planningType === 'areas') {
      setPlanningAreaId(planningId)
    } else if (planningType === 'devices') {
      setPlanningDeviceId(planningId)
    }
  }, [planningId, planningType])

  React.useLayoutEffect(() => {
    setPlanningId(prev => {
      if (
        planningAreaId !== null &&
        userAreas.some(a => a.areaId === planningAreaId)
      ) {
        return planningAreaId
      }

      if (userAreas.length !== 0) {
        return userAreas[0].areaId
      }

      return null
    })

    setPlanningType('areas')
  }, [planningAreaId, userAreas])

  const isLoggedIn = () => !!user
  const isDeviceLoggedIn = () => !!getDeviceGuid()

  // When this component unmounts, abort all ongoing calls
  React.useEffect(
    () => () => {
      abortAuthentication()
      abortUser()
    },
    [abortAuthentication, abortUser]
  )

  const logOutGids = React.useCallback(
    async (): Promise<void> => {
      const gidsSessionData = getGidsSessionData()
      if (!gidsSessionData) {
        return
      }

      return fetchAsync(
        logOutFromGids(new GrandIdRequestModel(gidsSessionData)).then(() => {
          clearGidsSessionData()
        })
      )

    }, [fetchAsync, clearGidsSessionData]
  )

  const logOut = React.useCallback(
    async (): Promise<void> =>
      fetchAsync(revokeRefreshToken().then(() => {
        clearSupportTokenData()
        unsetUser()
        logOutGids()
      })),
    [fetchAsync, revokeRefreshToken, unsetUser, logOutGids]
  )

  const logIn = React.useCallback(
    (email: string, password: string): Promise<IUserItem> => {
      return fetchAsync(
        logOut()
          .then(() => generateJwtTokenFromLogin(email, password))
          .then(() => reloadUser())
      )
    }
    ,
    [fetchAsync, generateJwtTokenFromLogin, logOut, reloadUser]
  )

  const initiateLogInWithGidsSaml = React.useCallback(
    (organization: string): Promise<FederatedLoginResponse> =>
      fetchAsync(
        logOut()
          .then(() => initiateGidsSaml(organization))
      ),
    [fetchAsync, initiateGidsSaml, logOut, reloadUser]
  )

  const initiateLogInWithGidsFrejaeId = React.useCallback(
    (organization: string): Promise<FederatedLoginResponse> => //TODO: return redirect url to GID
      fetchAsync(
        logOut()
          .then(() => initiateGidsFrejaeId(organization))
      ),
    [fetchAsync, initiateGidsFrejaeId, logOut, reloadUser]
  )

  const logInWithGidsSessionId = React.useCallback(
    (model: GrandIdRequestModel): Promise<IUserItem> =>
      fetchAsync(
        logOut()
          .then(() => generateJwtTokenFromGidsSession(model))
          .then(() => reloadUser())
      ),
    [fetchAsync, generateJwtTokenFromLogin, logOut, reloadUser]
  )

  const changePassword = React.useCallback(
    (currentPassword: string, newPassword: string): Promise<void> => {
      if (!user) {
        return Promise.reject('No user found when changing password')
      }
      return changePasswordUsingCurrentPassword(
        user.id,
        currentPassword,
        newPassword
      )

    },
    [changePasswordUsingCurrentPassword, fetchAsync, user]
  )

  const changePasswordWithToken = React.useCallback(
    (userId: number, token: string, newPassword: string): Promise<void> => {
      return changePasswordUsingToken(userId, token, newPassword)
    },
    [changePasswordUsingToken, fetchAsync]
  )

  const changePinCodeWithToken = React.useCallback(
    (userId: number, token: string, newPinCode: string): Promise<void> => {
      return changePinCodeUsingToken(userId, token, newPinCode)
    },
    [changePinCodeUsingToken, fetchAsync]
  )


  return {
    state: {
      ...stateOfFetch,
      user,
      planningId,
      planningType,
      planningAreaId,
      planningDeviceId,
      usingCalendarFromDevice,
      tokenExpired
    },
    actions: {
      isLoggedIn,
      isDeviceLoggedIn,
      logIn,
      logOut,
      logInWithGidsSessionId,
      initiateLogInWithGidsSaml,
      initiateLogInWithGidsFrejaeId,
      changePassword,
      changePasswordWithToken,
      changePinCodeWithToken,
      setUser,
      unsetUser,
      reloadUser,
      setPlanningId,
      setPlanningType,
      setPlanningAreaId,
      setPlanningDeviceId,
      setVisibleAreas,
      setUsingCalendarFromDevice,
      setTokenExpired
    },
  }
}

const AuthenticationContext = createContext(useAuthenticationContext)

type AuthenticatedState = Readonly<
  {
    user: IUserItem
    planningId: number | null
    planningAreaId: number | null
    planningDeviceId: number | null
    planningType: 'areas' | 'devices'
    usingCalendarFromDevice: boolean
  } & PromiseState
>
/** Use this hooks inside pages that assumes user is authenticated */
const useAuthenticatedState = (): AuthenticatedState => {
  const context = React.useContext(AuthenticationContext.StateContext)
  if (context === undefined) {
    throw new Response('Missing AuthenticationProvider for useAuthenticationState', { status: 401, statusText: 'innerAuthError' })
  }

  if (!context.user) {
    throw new Response('Missing AuthenticationProvider for useAuthenticationState', { status: 401, statusText: 'innerAuthError' })
  }

  return {
    ...context,
    user: context.user,
  }
}

export default {
  ...AuthenticationContext,
  useAuthenticatedState,
}
