import React from 'react'
import { createContext } from 'utilities/create-context'
import { Callbacks, SessionProps, TrackProps } from './planning-grid-types'

export interface IEventProps {
  startDate: Date
  lengthInMinutes: number
  left?: number
  right?: number
}

export interface ITracksProps {
  trackId: number
  sortOrder: number
  columnCount: number
  sessions: SessionProps[]
}

type ContextProps = Readonly<{}>

function useContext(params: ContextProps) {
  const [isDragging, setIsDragging] = React.useState(false)

  const [containerRef, setContainerRef] = React.useState<
    React.RefObject<HTMLDivElement> | undefined
  >()
  const [timeScale, setTimeScale] = React.useState<undefined | number>()
  const [calendarStartDate, setCalendarStartDate] = React.useState<
    Date | undefined
  >()

  const [callbacks, setCallbacks] = React.useState<
    Callbacks<TrackProps, SessionProps> | undefined
  >()

  const mapMinutesToPixels = React.useCallback(
    (minutes: number): number | undefined => {
      if (timeScale === undefined) {
        return undefined
      }
      return minutes / (timeScale * window.devicePixelRatio * 0.7)
    },
    [timeScale]
  )

  return {
    state: {
      containerRef,
      calendarStartDate,
      isDragging,
    },
    actions: {
      setCallbacks,
      setCalendarStartDate,
      setTimeScale,
      callbacks,
      mapMinutesToPixels,
      setContainerRef,
      setIsDragging,
    },
  }
}

export const PlanningGridContext = createContext(
  useContext,
  'PlanningGridContext'
)
