import { useUserApi } from 'api'
import usePromiseApi from 'utilities/use-promise'
import {
  RoleEnum,
  UserTypeEnum,
  UserDelegationItem,
  UserDelegationPersistItem,
  UserDelegationPlacementItem,
  UserDelegationPlacementPersistItem,
} from 'api/tillit.api-client'
import { UserFormModel } from 'api/user-api'
import Authentication from 'components/authentication'
import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction, UseFormHook } from 'types'
import testPhoneNumber from 'utilities/test-phone-number'
import * as yup from 'yup'
import { UsersPageContext } from 'pages/users/users-page-context'
import { Area, AreaRole, UserDialogContext } from '../user-dialog-context'
import { convertUserAreasToListedAreas } from './use-user-helper'

export type UserFormModelWithModifiedAreas = Omit<UserFormModel, 'userAreas'> & {
  listedAreas: Area[]
  areaRoles: AreaRole[]
}

const PIN_PLACEHOLDER = '••••'

// Define the hook to be used to leverage this form
const useCreateUserForm: UseFormHook<UserFormModelWithModifiedAreas> = () => {
  const f = useFormatMessage()
  const {
    user: { organizationId },
  } = Authentication.useAuthenticatedState()
  const { checkedAreaRoles, listedAreas, user, availableAreas, emailCannotBe } = UserDialogContext.useState()
  const { createUserAsync, updateUserAsync, handleClose } = UserDialogContext.useActions()
  const { fetchUsersAsync, fetchOrganizationUsersAsync } = UsersPageContext.useActions()

  const { isEmailUnique } = useUserApi()
  const { fetchAsync } = usePromiseApi()

  const initialValues: UserFormModelWithModifiedAreas = React.useMemo(
    () => ({
      organizationId,
      id: (user && user.id) || undefined,
      userType: 'OrganizationMember',
      active: true,
      firstName: (user && user.firstName) || '',
      lastName: (user && user.lastName) || '',
      email: (user && user.email) || '',
      phoneNumber: (user && user.phoneNumber) || '',
      pinCode: user && user.hasSavedPinCode ? PIN_PLACEHOLDER : '',
      description: (user && user.description) || '',
      listedAreas: user && user.userAreas ? convertUserAreasToListedAreas(availableAreas, user.userAreas) : [],
      areaRoles: (user && user.userAreas) || [],
      selectedDelegations: (user && user.userDelegations) || [],
      extraInfo: (user && user.extraInfo) || '',
    }),
    [availableAreas, organizationId, user]
  )

  const mapUserDelegationPlacementItemArrayToUserDelegationPlacementPersistItemArray = (
    items: UserDelegationPlacementItem[] | undefined
  ): UserDelegationPlacementPersistItem[] | undefined => {
    if (!items) {
      return items
    }
    return items.map(item => {
      return new UserDelegationPlacementPersistItem({
        id: item.id,
        active: item.active,
        description: item.description,
        areaId: item.areaId,
        customerId: item.customerId,
        delegationStartTime: item.delegationStartTime,
        delegationEndTime: item.delegationEndTime,
        delegatedById: item.delegatedById,
      })
    })
  }

  const mapUserDelegationItemArrayToUserDelegationPersistItemArray = (items: UserDelegationItem[] | undefined): UserDelegationPersistItem[] | undefined => {
    if (!items) {
      return items
    }
    return items.map(item => {
      return new UserDelegationPersistItem({
        id: item.id,
        delegationId: item.delegationId,
        userId: item.userId,
        userDelegationPlacements: mapUserDelegationPlacementItemArrayToUserDelegationPlacementPersistItemArray(item.userDelegationPlacements),
      })
    })
  }

  const onSubmit: OnSubmitFunction<UserFormModelWithModifiedAreas> = React.useCallback(
    (
      {
        pinCode,
        // userAreas,
        selectedDelegations,
        ...rest
      },
      { setSubmitting }
    ) => {
      const modelWithAreaRoles = {
        ...rest,
        pinCode,
        userAreas: checkedAreaRoles,
        userDelegations: mapUserDelegationItemArrayToUserDelegationPersistItemArray(selectedDelegations),
      }
      if (rest.id) {
        updateUserAsync(modelWithAreaRoles)
          .then(fetchUsersAsync)
          .then(fetchOrganizationUsersAsync)
          .then(() => setSubmitting(false))
          .then(handleClose)
      } else {
        createUserAsync(modelWithAreaRoles)
          .then(fetchUsersAsync)
          .then(() => setSubmitting(false))
          .then(handleClose)
      }
    },
    [checkedAreaRoles, createUserAsync, handleClose, updateUserAsync, fetchUsersAsync, fetchOrganizationUsersAsync]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<UserFormModelWithModifiedAreas>({
        organizationId: yup.number().required(),
        userType: yup
          .mixed<'SystemAdmin' | 'OrganizationMember'>()
          .oneOf(['SystemAdmin', 'OrganizationMember'])
          .required(),
        active: yup.boolean().required(),
        firstName: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('USER_DIALOG_VIEW_INFOSECTION_FORM_FIRSTNAME_LABEL'),
              maxsize: 128,
            })
          ),
        lastName: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('USER_DIALOG_VIEW_INFOSECTION_FORM_LASTNAME_LABEL'),
              maxsize: 128,
            })
          ),
        extraInfo: yup
          .string()
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('USER_DIALOG_VIEW_EXTRAINFO_LABEL'),
              maxsize: 128,
            })
          )
          .notRequired(),
        email: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .email(f('USER_DIALOG_VIEW_EMAIL_VALID_HELPER_TEXT'))
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('USER_DIALOG_VIEW_INFOSECTION_FORM_EMAIL_LABEL'),
              maxsize: 128,
            })
          )
          .test('emailMustBeUnique', f('USER_DIALOG_VIEW_EMAIL_UNIQUE_HELPER_TEXT'), (value?: any) => {
            if (!!!value) {
              return true
            }
            return emailCannotBe !== value // if value entered is not unique, then emailCannotBe will contain value thus returning false and invalidating. Set in debouncedEmailCheck
          }),
        phoneNumber: yup
          .string()
          .test('phoneNumber', f('USER_DIALOG_VIEW_USERSECTION_FORM_PHONENUMBER_HELPER_TEXT'), testPhoneNumber)
          .max(
            32,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('USER_DIALOG_VIEW_INFOSECTION_FORM_PHONENUMBER_LABEL'),
              maxsize: 32,
            })
          ),
        pinCode: yup
          .string()
          .length(4, f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ERROR_TEXT', { length: 4 }))
          .test('pinCode', f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_ONLY_NUMBERS_ERROR_TEXT'), (value: string) => {
            if (!!!value) {
              return true
            }
            return value === PIN_PLACEHOLDER || !isNaN(+value)
          })
          .test('pinCodeRequiredForPersonnell', f('USER_DIALOG_VIEW_USERSECTION_FORM_PIN_REQUIRED_FOR_PERSONNELL_ERROR_TEXT'), (value?: any) => {
            const userIsPersonnell = checkedAreaRoles.some(a => a.roleId === RoleEnum.Caretaker)
            if (userIsPersonnell) {
              return !!value
            }
            return true
          }),

        description: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('USER_DIALOG_VIEW_OTHERSECTION_FORM_OTHER_LABEL'),
            maxsize: 1024,
          })
        ),
        listedAreas: yup
          .array(
            yup.object().shape({
              id: yup.number().required(),
              name: yup.string().required(),
              organizationParentsName: yup.string(),
            })
          )
          .test(
            'listedAreas',
            f('USER_DIALOG_VIEW_USERSECTION_FORM_AREA_HELPER_TEXT'),
            () => user?.userType === UserTypeEnum.OrganizationAdmin || listedAreas.length > 0
          ),
        areaRoles: yup
          .array(
            yup.object().shape({
              areaId: yup.number().required(),
              roleId: yup.mixed().required(),
            })
          )
          .test('areaRoles', f('USER_DIALOG_VIEW_USERSECTION_FORM_AREA_ROLE_HELPER_TEXT'), (value: AreaRole[]) => {
            if (checkedAreaRoles.length === 0 && user?.userType !== UserTypeEnum.OrganizationAdmin) {
              return false
            }

            const reducedAreas = checkedAreaRoles?.reduce<Readonly<{ areaId: number }>[]>((prev, area) => {
              const { areaId } = area
              if (!prev.some(p => p.areaId === areaId)) {
                return [...prev, area]
              }
              return prev
            }, [])

            return reducedAreas.length === listedAreas.length
          }),
        selectedDelegations: yup
          .array()
          .notRequired()
          .of(
            yup.object<UserDelegationItem>().shape({
              id: yup.mixed().nullable(),
              delegationId: yup.number().required(),
              userId: yup.number().required(),
              userDelegationPlacements: yup
                .array()
                .notRequired()
                .of(
                  yup.object<UserDelegationPlacementItem>().shape({
                    id: yup.mixed().nullable(),
                    areaId: yup
                      .mixed()
                      .nullable()
                      .test('eitherAreaOrCustomerMustBeSelectedAREA', f('USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_AREA_OR_CUSTOMER_MUST_BE_SELECTED'), function(
                        areaId
                      ) {
                        return areaId || this.parent.customerId
                      }),

                    customerId: yup
                      .mixed()
                      .nullable()
                      .test(
                        'eitherAreaOrCustomerMustBeSelectedCUSTOMER',
                        f('USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_AREA_OR_CUSTOMER_MUST_BE_SELECTED'),
                        function(customerId) {
                          return customerId || this.parent.areaId
                        }
                      ),
                    delegationEndTime: yup
                      .date()
                      .required()
                      .test('endTimeMustBeAfterStartTimeOrNull', f('USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_DELEGATIONENDTIME_INVALID'), function(endDateTime) {
                        if (!endDateTime) {
                          return true
                        }
                        return new Date(endDateTime) > new Date(this.parent.delegationStartTime)
                      }),

                    delegatedById: yup.number().required(f('USER_DIALOG_VIEW_DELEGATIONSECTION_FORM_DELEGATED_BY_REQUIRED')),
                  })
                )
                .min(0),
            })
          )
          .min(0),
      }),
    [user, f, listedAreas.length, fetchAsync, isEmailUnique, checkedAreaRoles]
  )

  const name: Name<UserFormModelWithModifiedAreas> = React.useMemo(
    () => ({
      organizationId: 'organizationId',
      id: 'id',
      userType: 'userType',
      active: 'active',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phoneNumber: 'phoneNumber',
      pinCode: 'pinCode',
      description: 'description',
      listedAreas: 'listedAreas',
      areaRoles: 'areaRoles',
      userDelegations: 'userDelegations',
      selectedDelegations: 'selectedDelegations',
      extraInfo: 'extraInfo',
    }),
    []
  )

  return { initialValues, onSubmit, validationSchema, name }
}

export default useCreateUserForm
