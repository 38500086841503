import React from 'react'
import * as yup from 'yup'
import {
  CommonShiftItem
} from 'api/tenilo.workschedule.api-client'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { inputDateTimeToTicks,  ticksToInputDateTime, normalWorkScheduleStartTime, normalWorkScheduleEndTime } from 'utilities/date-time-helpers'
import { WorkScheduleContext } from '../work-schedule-context'

type workScheduleFormData = Readonly<{
  scheduledStartTimeTicks: Date | null;
  scheduledEndTimeTicks: Date | null;
  description: string;
}>

const useWorkScheduleFormData  = ( shift?: CommonShiftItem ) => {
  const f = useFormatMessage()
  const { currentUser } = WorkScheduleContext.useState()
  const { fetchListCommonShifts,
  handleUpdateCommonShift,
  handleCreateCommonShift,
  closeWorkScheduleModal } = WorkScheduleContext.useActions()

  const initialValues = React.useMemo<workScheduleFormData>(
    () => {
      return shift ?
        {
          scheduledStartTimeTicks: ticksToInputDateTime(shift.scheduledStartTimeTicks),
          scheduledEndTimeTicks: ticksToInputDateTime(shift.scheduledEndTimeTicks),
          description: shift.description
        }
      :
        {
          scheduledStartTimeTicks: normalWorkScheduleStartTime(),
          scheduledEndTimeTicks: normalWorkScheduleEndTime(),
          description: ''
        }

    },
    [shift]
  )

  const onSubmit: OnSubmitFunction<workScheduleFormData> = React.useCallback(
    async (values, actions) => {
      if(!currentUser.organizationId){
        return
      }
      if(shift && shift.id){
        const updateItem = {
          id: shift.id,
          scheduledStartTimeTicks: inputDateTimeToTicks(values.scheduledStartTimeTicks),
          scheduledEndTimeTicks: inputDateTimeToTicks(values.scheduledEndTimeTicks),
          description: values.description.trim(),
          organizationId: currentUser.organizationId
        }
        return handleUpdateCommonShift(updateItem).then(fetchListCommonShifts).then(closeWorkScheduleModal)
      }
      const createItem = {
        scheduledStartTimeTicks: inputDateTimeToTicks(values.scheduledStartTimeTicks),
        scheduledEndTimeTicks: inputDateTimeToTicks(values.scheduledEndTimeTicks),
        description: values.description.trim(),
        organizationId: currentUser.organizationId
      }
      return handleCreateCommonShift(createItem).then(fetchListCommonShifts).then(closeWorkScheduleModal)
    },
    [shift]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      description: yup.string().max(40).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      scheduledStartTimeTicks: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      scheduledEndTimeTicks: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
    })
  }, [f])

  const name: Name<workScheduleFormData> = React.useMemo(
    () => ({
      scheduledStartTimeTicks: 'scheduledStartTimeTicks',
      scheduledEndTimeTicks: 'scheduledEndTimeTicks',
      description: 'description'
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useWorkScheduleFormData
