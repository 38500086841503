import React from 'react'
import LoginPageContext from './login-page-context'
import LoginPageView from './login-page-view'

const LoginPageContainer: React.FC = () => {

  return (
    <LoginPageContext.Provider>
      <LoginPageView />
    </LoginPageContext.Provider>
  )
}

export default LoginPageContainer
