import { useOrganizationApi } from 'api'
import { IOrganizationListItem } from 'api/tillit.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useInvoiceFormContext = () => {
  const {
    generateOrganizationCustomerReport,
    getOrganizations,
  } = useOrganizationApi()
  const { state, fetchAsync } = usePromiseApi()

  const [organizations, setOrganizations] = React.useState<
    IOrganizationListItem[]
  >([])

  React.useEffect(() => {
    fetchAsync(getOrganizations()).then(setOrganizations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrganizations])

  const fetchInvoice = (fromDate: Date, toDate: Date, organizationId: number) =>
    fetchAsync(
      generateOrganizationCustomerReport(organizationId, fromDate, toDate)
    )

  return {
    state: { ...state, organizations },
    actions: { fetchInvoice },
  }
}

export const InvoiceFormContext = createContext(useInvoiceFormContext)
