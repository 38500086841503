import React from 'react'
import * as yup from 'yup'

import { useKioskApi, useOrganizationApi } from 'api'
import {
  IUserItem,
  RoleEnum,
  SwaggerException,
  UserAreaItem,
  UserTypeEnum,
} from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import PageRoute from 'config/page-routes'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import type { locationStateType } from 'utilities/react-router-hooks'
import { useNavigate } from "react-router-dom"
import { setDeviceGuid } from 'api/device-guid'
import usePromiseApi from 'utilities/use-promise'
import { getRememberData, setRememberMe, setRememberOrganization } from './remember-me-helpers'

type LoginForm = Readonly<{
  email: string
  password: string
  organization: string
 }>

// Define the hook to be used to leverage this form
const useLoginForm = (redirectTo: locationStateType, rememberMeChecked: boolean, rememberOrgChecked: boolean, loginMethod: string) => {
  const f = useFormatMessage()
  const { getAllAreasOfOrganization } = useOrganizationApi()
  const { fetchAsync } = usePromiseApi()
  const { logIn, initiateLogInWithGidsSaml, initiateLogInWithGidsFrejaeId, setUser } = Authentication.useActions()
  const navigate = useNavigate()
  const [modalOrganization, setModalOrganization] = React.useState(false)
  const { getKioskByGuid } = useKioskApi()

  const initialValues: LoginForm = React.useMemo(
    () => {
      const rememberedData = getRememberData()
      return ({
        email: rememberedData.email || '',
        password: '',
        organization: rememberedData.organization || ''
      })
    },[]
  )

  const prefixDailyActivities = PageRoute.DailyActivities.path + "/"
  const locationState = redirectTo

  const redirectUserTo = React.useCallback(() => {
    setModalOrganization(false)

    if (
      redirectTo === undefined ||
      redirectTo.pathname === PageRoute.Login.path ||
      redirectTo.pathname === PageRoute.DailyActivities.path
      ) {
        navigate(PageRoute.Dashboard.path)
      } else {
        navigate(redirectTo,{ replace: true })
      }
  }, [redirectTo])


  const userLoggedIn = React.useCallback(async (user: IUserItem) => {
    //if user logged in with URL to /daily/activities/ containing deviceGuid, we validate if the user has access to an area wit that device guid and save cookie in localstorage
    if (locationState?.pathname.startsWith(prefixDailyActivities) && locationState?.pathname.length > prefixDailyActivities.length) {
      const deviceGuidFromUrl = locationState.pathname.substring(prefixDailyActivities.length)
      const kioskAreas = (await fetchAsync(getKioskByGuid(deviceGuidFromUrl))).kioskAreas

      if (!kioskAreas) {
        throw new Error('KioskAreas does not exist')
      }
      const kioskAreaIds = kioskAreas?.map(kiosk => kiosk.areaId)
      const userAreaIds = user?.userAreas?.map(userArea => userArea.areaId)
      if (kioskAreaIds && userAreaIds) {
        const userLoggedInFromUrlValidated = userAreaIds.some(area => kioskAreaIds.includes(area));

        if (userLoggedInFromUrlValidated || user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport) {
          setDeviceGuid(deviceGuidFromUrl)
          navigate(PageRoute.DailyActivities.path)
        }
        else {
          redirectUserTo()
        }
      }
    }
    if (user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport) {
      const areas = await getAllAreasOfOrganization(user.organizationId!)

      setUser({
        ...user,
        userAreas: areas.map(
          a =>
            new UserAreaItem({
              id: -1,
              userId: user.id,
              areaId: a.id,
              areaName: a.name,
              roleId: RoleEnum.Admin,
              hasRoutes: a.routeCount > 0, // Note: hasRoutes and hasChildAreas are not telling the truth. We need to remove these or make them work as expected
              hasChildAreas: a.routeCount === 0,
              organizationParentsName: a.organizationParentsName,
            })
        ),
      })
      redirectUserTo()
    } else {
      redirectUserTo()
    }
  },[])


  const logInStandard = React.useCallback((email, password, setSubmitting, setStatus) => {

      logIn(email, password).then(user => {
        if (rememberMeChecked) {
          setRememberMe(email)
        } else {
          setRememberMe('')
        }
        return userLoggedIn(user)
      })
      .catch(error => {
        if (SwaggerException.isSwaggerException(error)) {
          if (error.status === 403) {
            setStatus({
              error: f('LOGIN_PAGE_CONTAINER_ERROR'),
            })
          } else {
            setStatus({ error: error.response })
          }
        } else {
          if (error instanceof String) {
            setStatus({ error })
          } else {
            // tslint:disable-next-line: no-console
            setStatus({ error: 'Unknown non swagger exception occurred' })
          }
        }
      })
      .finally(() => {
        setSubmitting(false)
      })

    },[rememberMeChecked])

    const logInGidsSaml = React.useCallback((organization, setSubmitting, setStatus) => {

      initiateLogInWithGidsSaml(organization).then((result) => {
        if (rememberOrgChecked) {
          setRememberOrganization(organization)
        } else {
          setRememberOrganization('')
        }
        if (result.redirectUrl) {
          window.location.href = result.redirectUrl
        }
      })
      .catch(error => {
        if (SwaggerException.isSwaggerException(error)) {
          if (error.status === 403) {
            setStatus({
              error: f('LOGIN_PAGE_CONTAINER_ERROR'),
            })
          } else {
            setStatus({ error: error.response })
          }
        } else {
          if (error instanceof String) {
            setStatus({ error })
          } else {
            // tslint:disable-next-line: no-console
            setStatus({ error: 'Unknown non swagger exception occurred' })
          }
        }
      })
      .finally(() => {
        setSubmitting(false)
      })

    },[rememberOrgChecked])

    const logInGidsFrejaeId = React.useCallback((organization, setSubmitting, setStatus) => {
      initiateLogInWithGidsFrejaeId(organization).then((result) => {
          if (rememberOrgChecked) {
            setRememberOrganization(organization)
          } else {
            setRememberOrganization('')
          }
          if (result.redirectUrl) {
            window.location.href = result.redirectUrl
          }
          })
        .catch(error => {
          if (SwaggerException.isSwaggerException(error)) {
            if (error.status === 403) {
              setStatus({
                error: f('LOGIN_PAGE_CONTAINER_ERROR'),
              })
            } else {
              setStatus({ error: error.response })
            }
          } else {
            if (error instanceof String) {
              setStatus({ error })
            } else {
              // tslint:disable-next-line: no-console
              setStatus({ error: 'Unknown non swagger exception occurred' })
            }
          }
        })
        .finally(() => {
          setSubmitting(false)
        })

      },[rememberOrgChecked])



  const onSubmit: OnSubmitFunction<LoginForm> = React.useCallback(
    ({ email, password, organization }, { setSubmitting, setStatus }) => {
      switch (loginMethod) {
        case 'standard':
          logInStandard(email, password, setSubmitting, setStatus)
          break
        case 'gids_saml':
          logInGidsSaml(organization, setSubmitting, setStatus)
          break
        case 'gids_frejaeid':
          logInGidsFrejaeId(organization, setSubmitting, setStatus)
          break
        default:
          setSubmitting(false)
          console.log("Not supported login method")
          break
      }

    },
    [
      f,
      logIn,
      redirectUserTo,
      getAllAreasOfOrganization,
      setUser,
      loginMethod
    ]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<LoginForm>({
        email: yup.string()
          .test('emailRequiredForStandard', f('BASIC_REQUIRED_FIELD_HELPER_TEXT'), (value?: any) => {
            if (loginMethod === "standard") {
              return !!value
            }
            return true
          }),
        password: yup.string()
          .test('passwordRequiredForStandard', f('BASIC_REQUIRED_FIELD_HELPER_TEXT'), (value?: any) => {
            if (loginMethod === "standard") {
              return !!value
            }
            return true
          }),
        organization: yup.string()
          .test('organizationRequiredForSamlAndFrejaeId', f('BASIC_REQUIRED_FIELD_HELPER_TEXT'), (value?: any) => {
            if (loginMethod === "gids_saml" || loginMethod === "gids_frejaeid") {
              return !!value
            }
            return true
          }),
      }),
    [f, loginMethod]
  )

  const name: Name<LoginForm> = React.useMemo(
    () => ({
      email: 'email',
      password: 'password',
      organization: 'organization'
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
    redirectUserTo,
    modalOrganization,
  }
}

export default useLoginForm

