import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import ContainedButton from 'components/inputs/contained-button'
import OutlinedTextField from 'components/inputs/outlined-text-field'
import { useFormatMessage } from 'localization'
import { useParams } from 'react-router-dom'
import { LoginPage } from 'components/page'
import useResetPinCodeForm from './utilities/use-reset-pincode-form'

const ResetPinCodeContainer: React.SFC = () => {
  const { userId, token } = useParams() as { userId: string; token: string }

  const { initialValues, onSubmit, validationSchema, name } = useResetPinCodeForm(+userId, token)
  const f = useFormatMessage()
  return (
    <LoginPage>
      <Grid container direction="column"  rowSpacing={3} columnSpacing={0}>
        <Grid item>
          <Typography sx={{
            fontWeight:600,
            letterSpacing:'0.01em'
          }} variant="h4">{f('RESET_PINCODE_PAGE_RESET_PINCODE_TITLE')}</Typography>
        </Grid>
        <Grid item>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnBlur={false}>
            {({ isSubmitting, status }) => (
              <Form translate="yes">
                <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
                  <Grid item>
                    <OutlinedTextField
                      data-matomo-mask
                      name={name.newPinCode}
                      label={f('RESET_PINCODE_PAGE_NEW_PINCODE_INPUT_LABEL')}
                      type="password"
                      color="secondary"
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <OutlinedTextField
                      data-matomo-mask
                      name={name.confirmPinCode}
                      label={f('RESET_PINCODE_PAGE_CONFIRM_PINCODE_INPUT_LABEL')}
                      type="password"
                      color="secondary"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sx={{minHeight:'50px',paddingTop:'0 !important'}}>
                    {!!status && <Typography color={'error'}>{status.error}</Typography>}
                  </Grid>
                  <Grid item sx={{paddingTop:'0 !important'}}>
                    <ContainedButton containerStyle={{margin:0,display:'flex',justifyContent:'center'}}
                     type="submit" sx={{
                      width:'80%',
                      paddingTop:'10px',
                      paddingBottom:'10px',
                      borderRadius:'20px'
                    }} color="primary" showProgress={isSubmitting}>
                      {f('RESET_PINCODE_PAGE_SUBMIT_BUTTON_TEXT')}
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </LoginPage>
  )
}

export default ResetPinCodeContainer
