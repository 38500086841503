import React from 'react'

import { HangfireClient } from 'api/hangfire-api-client'

import { HANGFIRE_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import { hasTokenData } from './token-data'

export class HangfireApi {
  get isAuthenticated() {
    const data = hasTokenData()
    return data
  }
  private readonly client: HangfireClient
  private readonly abortController = new AbortController()

  constructor() {
    console.log(HANGFIRE_URL)
    this.client = new HangfireClient(
      HANGFIRE_URL,
      httpMiddleware({ signal: this.abortController.signal })
    )
  }

  public abort = () => this.abortController.abort()

  public getPortal = (): Promise<string> =>
    this.client.get().then(resultItem => {
      if (resultItem === null) {
        return Promise.reject('Not able to display Hangfire portal')
      }
      return Promise.resolve(resultItem)
    })
}

const useHangfireApi = () => React.useMemo(() => new HangfireApi(), [])

export default useHangfireApi
