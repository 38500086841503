import { PropTypes } from '@mui/material'
import { MobileDateTimePicker, MobileDateTimePickerProps } from '@mui/x-date-pickers';
import 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import sv from 'date-fns/locale/sv'
import { Field, FieldProps } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'

type Props = Omit<MobileDateTimePickerProps<any>, 'value' | 'onChange' | 'color' | 'renderInput'> & {
  color?: PropTypes.Color
  value?: Date
  name?: string
  onChange?: MobileDateTimePickerProps<any>['onChange']
}

const DateTimePickerContainer: React.FC<Props> = ({ name, value, onChange, color, ...props }) => {
  const f = useFormatMessage()
  if (!name && !onChange) {
    console.log('If no name is provided, please provide an onChange handler.')
    return null
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        {name === undefined ? (
          <MobileDateTimePicker
            disablePast
            label={props.label}
            ampm={false}
            value={value}
            onChange={onChange!}
            {...props}
            localeText={{
              toolbarTitle: f('DATE_TIME_PICKER_TOOLBAR_TITLE'),
              okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
              cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
            }}
          />
        ) : (
          <Field name={name}>
            {({ field: { onChange: _, ...restField }, form }: FieldProps) => {
              const handleChange = () => (date: any) => {
                form.setFieldValue(restField.name, date)
              }
              return (
                <MobileDateTimePicker
                  disablePast
                  label={props.label}
                  ampm={false}
                  onChange={handleChange()}
                  {...props}
                  {...restField}
                  localeText={{
                    toolbarTitle: f('DATE_TIME_PICKER_TOOLBAR_TITLE'),
                    okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                    cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                  }}
                />
              )
            }}
          </Field>
        )}
      </LocalizationProvider>
    </>
  )
}

export default DateTimePickerContainer
