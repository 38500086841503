import React from 'react'

import {
  TemplateActivityClient,
  TemplateActivityCreateItem,
  ITemplateActivityCreateItem,
  TemplateActivityUpdateItem,
  ITemplateActivityUpdateItem,
  TemplateActivityListItem,
  TemplateActivityItem,
  ITemplateActivityListItem,
  TemplateActivityCategoryListItem,
  TemplateActivityInheritanceItem,
  TemplateActivityInheritanceCreateItem,
  TemplateActivityInheritanceUpdateItem,
  ITemplateActivityInheritanceCreateItem,
  ITemplateActivityInheritanceUpdateItem,
  TemplateActivityCategoryItem,
  ITemplateActivityCategoryCreateItem,
  ITemplateActivityCategoryUpdateItem,
  TemplateActivityCategoryCreateItem,
  TemplateActivityCategoryUpdateItem,
  TemplateActivityTypeEnum,
  TemplateActivitiesToAreasItem,
  ITemplateActivitiesToAreasItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export interface IFlatTemplateActivityGroupListItem extends ITemplateActivityListItem {
  areaName: string | undefined
  areaId: number
  areaOrganizationParentsName: string | undefined
}

export interface IFlatInheritedTemplateActivityListItem extends ITemplateActivityListItem {
  areaName: string | undefined
  areaId: number
  areaOrganizationParentsName: string | undefined
}

export class TemplateActivityApi {
  private readonly abortController = new AbortController()
  private readonly client: TemplateActivityClient

  public constructor() {
    this.client = new TemplateActivityClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public hasInheritanceOnTemplateActivity = (templateActivityId: number): Promise<boolean> =>
    this.client
      .hasInheritanceOnTemplateActivity(templateActivityId)
      .then(exists =>
        exists === null
          ? Promise.reject(`Unexpected response from hasInheritanceOnTemplateActivity for template activity id ${templateActivityId}.`)
          : Promise.resolve(exists)
      )

  public hasTemplateActivityConnectedToActivity = (templateActivityId: number, areaId: number): Promise<boolean> =>
    this.client
      .hasTemplateActivityConnectedToActivity(templateActivityId, areaId)
      .then(exists =>
        exists === null
          ? Promise.reject(`Unexpected response from hasTemplateActivityConnectedToActivity for template activity id ${templateActivityId}.`)
          : Promise.resolve(exists)
      )

  public hasTemplateActivityCategoryConnectedToTemplateActivity = (templateActivityCategoryId: number): Promise<boolean> =>
    this.client
      .hasTemplateActivityCategoryConnectedToTemplateActivity(templateActivityCategoryId)
      .then(exists =>
        exists === null
          ? Promise.reject(
            `Unexpected response from hasTemplateActivityCategoryConnectedToTemplateActivity for template activity category id ${templateActivityCategoryId}.`
          )
          : Promise.resolve(exists)
      )

  public hasTemplateConnectedToArea = (templateActivityId: number, areaId: number): Promise<boolean> =>
    this.client
      .hasTemplateConnectedToArea(templateActivityId, areaId)
      .then(exists =>
        exists === null
          ? Promise.reject(`Unexpected response from hasTemplateConnectedToArea for template activity id ${templateActivityId} and area Id ${areaId}.`)
          : Promise.resolve(exists)
      )

  public getTemplateActivity = (templateActivityId: number): Promise<TemplateActivityItem> =>
    this.client
      .getTemplateActivity(templateActivityId)
      .then(templateActivityItem =>
        templateActivityItem === null
          ? Promise.reject(`No templateActivity item received from backend for template activity id ${templateActivityId}.`)
          : Promise.resolve(templateActivityItem)
      )

  public getTemplateActivityInheritance = (templateActivityInheritanceId: number): Promise<TemplateActivityInheritanceItem> =>
    this.client
      .getTemplateActivityInheritance(templateActivityInheritanceId)
      .then(templateActivityInheritanceItem =>
        templateActivityInheritanceItem === null
          ? Promise.reject(`No templateActivityInheritance item received from backend for template activity Inheritance id ${templateActivityInheritanceId}.`)
          : Promise.resolve(templateActivityInheritanceItem)
      )

  public getTemplateActivityInheritanceOnArea = (templateActivityId: number, areaId: number): Promise<TemplateActivityInheritanceItem> =>
    this.client
      .getTemplateActivityInheritanceOnArea(templateActivityId, areaId)
      .then(templateActivityInheritanceItem =>
        templateActivityInheritanceItem === null
          ? Promise.reject(`No templateActivityInheritance item received from backend for template activity id ${templateActivityId} and area id ${areaId}.`)
          : Promise.resolve(templateActivityInheritanceItem)
      )

  public getTemplateActivityCategory = (templateActivityCategoryId: number): Promise<TemplateActivityCategoryItem> =>
    this.client
      .getTemplateActivityCategory(templateActivityCategoryId)
      .then(templateActivityCategoryItem =>
        templateActivityCategoryItem === null
          ? Promise.reject(`No templateActivityCategory item received from backend for template activity category id ${templateActivityCategoryId}.`)
          : Promise.resolve(templateActivityCategoryItem)
      )

  public listOrganizationTemplateActivities = (organizationId: number): Promise<TemplateActivityListItem[]> =>
    this.client
      .listOrganizationTemplateActivities(organizationId)
      .then(templateActivityItems =>
        templateActivityItems === null
          ? Promise.reject(`No template activity items received from backend for organization id ${organizationId}.`)
          : Promise.resolve(templateActivityItems)
      )

  public listAreaTemplateActivities = (organizationId: number): Promise<TemplateActivityListItem[]> =>
    this.client
      .listAreaTemplateActivities(organizationId)
      .then(templateActivityItems =>
        templateActivityItems === null
          ? Promise.reject(`No template activity items received from backend for org id ${organizationId}.`)
          : Promise.resolve(templateActivityItems)
      )

  public listInheritedTemplateActivities = (organizationId: number): Promise<TemplateActivityInheritanceItem[]> =>
    this.client
      .listInheritedTemplateActivities(organizationId)
      .then(inheritedTemplateActivityListItems =>
        inheritedTemplateActivityListItems === null
          ? Promise.reject(`No inherited template activity items received from backend for org id ${organizationId}.`)
          : Promise.resolve(inheritedTemplateActivityListItems)
      )

  public listNonInheritedTemplateActivities = (areaId: number): Promise<TemplateActivityListItem[]> =>
    this.client
      .listNonInheritedTemplateActivitiesOnArea(areaId)
      .then(templateActivityItems =>
        templateActivityItems === null
          ? Promise.reject(`No template activity items recieved from backend for area id ${areaId}.`)
          : Promise.resolve(templateActivityItems)
      )

  public listTemplateActivityCategories = (organizationId: number): Promise<TemplateActivityCategoryListItem[]> =>
    this.client
      .listOrganizationTemplateActivityCategories(organizationId)
      .then(templateActivityCategoryListItems =>
        templateActivityCategoryListItems === null
          ? Promise.reject(`No template activity category list items received from backend for org id ${organizationId}.`)
          : Promise.resolve(templateActivityCategoryListItems)
      )

  public listOrganizationTemplateActivityCategories = (organizationId: number): Promise<TemplateActivityCategoryListItem[]> =>
    this.client
      .listOrganizationTemplateActivityCategories(organizationId)
      .then(templateActivityCategoryListItem =>
        templateActivityCategoryListItem === null
          ? Promise.reject(`No template activity category items received from backend for org id ${organizationId}.`)
          : Promise.resolve(templateActivityCategoryListItem)
      )

  public listActiveTemplateActivitiesOnArea = (areaId: number, templateActivityTypeId: TemplateActivityTypeEnum): Promise<TemplateActivityListItem[]> =>
    this.client
      .listActiveTemplateActivitiesOnArea(areaId, templateActivityTypeId)
      .then(templateActivityItems =>
        templateActivityItems === null
          ? Promise.reject(`No template activity items received from backend for area id ${areaId} , template activity type id ${templateActivityTypeId}..`)
          : Promise.resolve(templateActivityItems)
      )

  public createTemplateActivity = (templateActivity: ITemplateActivityCreateItem) => {
    const model = new TemplateActivityCreateItem(templateActivity)
    return this.client
      .createTemplateActivity(model)
      .then(templateActivityItem =>
        templateActivityItem === null
          ? Promise.reject(`When creating a template activity, no template activity item received from backend.`)
          : Promise.resolve(templateActivityItem)
      )
  }

  public updateTemplateActivity = (templateActivity: ITemplateActivityUpdateItem) => {
    const model = new TemplateActivityUpdateItem(templateActivity)
    return this.client
      .updateTemplateActivity(model)
      .then(templateActivityItem =>
        templateActivityItem === null
          ? Promise.reject(`When creating an activity, no activity item received from backend.`)
          : Promise.resolve(templateActivityItem)
      )
  }

  public deleteTemplateActivity = (templateActivityId: number) =>
    this.client.deleteTemplateActivity(templateActivityId).then(() => Promise.resolve(templateActivityId))

  public createTemplateActivityInheritance = (templateActivityInheritance: ITemplateActivityInheritanceCreateItem) => {
    const model = new TemplateActivityInheritanceCreateItem(templateActivityInheritance)
    return this.client
      .createTemplateActivityInheritance(model)
      .then(templateActivityInheritanceItem =>
        templateActivityInheritanceItem === null
          ? Promise.reject(`When creating a template activity Inheritance, no template activity Inheritance item received from backend.`)
          : Promise.resolve(templateActivityInheritanceItem)
      )
  }

  public updateTemplateActivityInheritance = (templateActivityInheritance: ITemplateActivityInheritanceUpdateItem) => {
    const model = new TemplateActivityInheritanceUpdateItem(templateActivityInheritance)
    return this.client
      .updateTemplateActivityInheritance(model)
      .then(templateActivityInheritanceItem =>
        templateActivityInheritanceItem === null
          ? Promise.reject(`When creating an template activity Inheritance, no template activity Inheritance item received from backend.`)
          : Promise.resolve(templateActivityInheritanceItem)
      )
  }

  public deleteTemplateActivityInheritance = (templateActivityInheritanceId: number) =>
    this.client.deleteTemplateActivityInheritance(templateActivityInheritanceId).then(() => Promise.resolve(templateActivityInheritanceId))

  public createTemplateActivityCategory = (templateActivityCategory: ITemplateActivityCategoryCreateItem) => {
    const model = new TemplateActivityCategoryCreateItem(templateActivityCategory)
    return this.client
      .createTemplateActivityCategory(model)
      .then(templateActivityCategoryItem =>
        templateActivityCategoryItem === null
          ? Promise.reject(`When creating a template activity Category, no template activity Category item received from backend.`)
          : Promise.resolve(templateActivityCategoryItem)
      )
  }

  public updateTemplateActivityCategory = (templateActivityCategory: ITemplateActivityCategoryUpdateItem) => {
    const model = new TemplateActivityCategoryUpdateItem(templateActivityCategory)
    return this.client
      .updateTemplateActivityCategory(model)
      .then(templateActivityCategoryItem =>
        templateActivityCategoryItem === null
          ? Promise.reject(`When creating an template activity Category, no template activity Category item received from backend.`)
          : Promise.resolve(templateActivityCategoryItem)
      )
  }

  public deleteTemplateActivityCategory = (templateActivityCategoryId: number) =>
    this.client.deleteTemplateActivityCategory(templateActivityCategoryId).then(() => Promise.resolve(templateActivityCategoryId))

  public moveAreaTemplateActivityToOrganization = (templateActivityId: number) =>
    this.client.moveAreaTemplateActivityToOrganization(templateActivityId).then(() => Promise.resolve(templateActivityId))

  public connectTemplateActivitiesToAreas = (templateActivitiesToAreas: ITemplateActivitiesToAreasItem) => {
    const model = new TemplateActivitiesToAreasItem(templateActivitiesToAreas)
    return this.client
      .connectTemplateActivitiesToAreas(model)
      .then(success =>
        success === null
          ? Promise.reject(`When creating a template activity Category, no template activity Category item received from backend.`)
          : Promise.resolve(success)
      )
  }

}

const useTemplateActivityApi = () => React.useMemo(() => new TemplateActivityApi(), [])

export default useTemplateActivityApi
