import React from 'react';
import { Box } from '@mui/material'
import styled from 'styled-components'

export const SigningStatusesItemsWrapper = styled(Box)`
  width: 100%;
  height: auto;
  border: 1px solid rgba(224,224,224,1);
  border-radius: 4px;
  margin-bottom:20px
`
