import React from 'react'
import { useSupportAreasApi } from 'api'
import { PageResultOfAreaListItem } from 'api/tenilo.support.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'
import supportPageContext from 'pages/support/support-page-context'

const useSupportAreasContext = () => {
  const { listAreas, abort } = useSupportAreasApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [areaListItems, setAreaListItems] = React.useState<PageResultOfAreaListItem | undefined>(undefined)
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(250)
  const { selectedOrganization, selectedArea } = supportPageContext.useState()

  const location = useLocation()

  const fetchAreasAsync = React.useCallback(async (page: number, pageSize: number, organizationId: number | null, areaId: number | null) => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listAreas(page, pageSize, organizationId, areaId).then(setAreaListItems))
  }, [currentUser.organizationId, fetchAsync, listAreas])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])

  const closeModal = React.useCallback(() => {
    setModalOpen(false)
  }, [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  const refreshAreasAsync = React.useCallback(() => {
    const page = 1
    const pageSize = 25000 //TODO FIX IN TEN-902
    if (selectedOrganization) {
      void fetchAreasAsync(page, pageSize, selectedOrganization.id, selectedArea ? selectedArea.id : null)
    } else {
      setAreaListItems(undefined)
    }
  }, [currentUser.organizationId, fetchAreasAsync, selectedOrganization, selectedArea])


  React.useEffect(() => {
    refreshAreasAsync()
  }, [refreshAreasAsync, location, currentPage, pageSize, selectedOrganization, selectedArea])

  return {
    state: {
      ...stateOfFetch,
      areaListItems,
      modalOpen,
      currentPage,
      pageSize,
    },
    actions: {
      refreshAreasAsync,
      openModal,
      closeModal,
    },
  }
}

export const SupportAreasContext = createContext(useSupportAreasContext)
