import { useTemplateActivityApi } from 'api'
import { useUnitApi } from 'api'
import { TemplateActivityCreateItem, TemplateActivityUpdateItem, ITemplateActivityItem, TemplateActivityCategoryListItem, UnitListItem } from 'api/tillit.api-client'
import { useDelegationApi } from 'api'
import { DelegationListItem } from 'api/tillit.api-client'

import Authentication from 'components/authentication'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

import { AdministerAreaTemplateActivitiesContext } from '../administer-area-template-activities/administer-area-template-activities-context'
import { AdministerOrganizationTemplateActivitiesContext } from '../administer-organization-template-activities/administer-organization-template-activities-context'

const useTemplateActivityModalContext = () => {
  const { getTemplateActivity, updateTemplateActivity, createTemplateActivity, listOrganizationTemplateActivityCategories, moveAreaTemplateActivityToOrganization } = useTemplateActivityApi()
  const { listUnits } = useUnitApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const { fetchTemplateActivitiesAsync: fetchOrganizationTemplateActivitiesAsync } = AdministerOrganizationTemplateActivitiesContext.useActions()

  const { fetchTemplateActivitiesAsync: fetchAreaTemplateActivitiesAsync } = AdministerAreaTemplateActivitiesContext.useActions()

  const { user: currentUser } = Authentication.useAuthenticatedState()

  const { listOrganizationDelegations } = useDelegationApi()

  const [ownerAreaId, setOwnerAreaId] = React.useState<number | undefined>(undefined)

  const [ownerOrganizationId, setOwnerOrganizationId] = React.useState<number | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedTemplateActivity, setSelectedTemplateActivity] = React.useState<ITemplateActivityItem | undefined>()

  const [templateActivityCategories, setTemplateActivityCategories] = React.useState<TemplateActivityCategoryListItem[] | undefined>()

  const [units, setUnits] = React.useState<UnitListItem[] | undefined>()

  const [confirmMoveDialogOpen, setConfirmMoveDialogOpen] = React.useState<boolean>(false)

  const [templateActivityIdToMove, setTemplateActivityIdToMove] = React.useState<number | undefined>(undefined)

  const [delegationItems, setDelegationItems] = React.useState<DelegationListItem[]>([])

  const openConfirmMoveDialog = React.useCallback((templateActivityId: number) => {
    setTemplateActivityIdToMove(templateActivityId)
    setConfirmMoveDialogOpen(true)
  }, [setConfirmMoveDialogOpen])

  const closeConfirmMoveDialog = React.useCallback(() => {
    setTemplateActivityIdToMove(undefined)
    setConfirmMoveDialogOpen(false)
  }, [setConfirmMoveDialogOpen])


  const moveToOrganization = React.useCallback(() => {

    if (!templateActivityIdToMove) {
      closeConfirmMoveDialog()
      return
    }

    void moveAreaTemplateActivityToOrganization(templateActivityIdToMove).then(() => {
      setModalOpen(false)
      void fetchAreaTemplateActivitiesAsync()
    })
  },
    [templateActivityIdToMove, moveAreaTemplateActivityToOrganization, closeConfirmMoveDialog]
  )

  const fetchDelegationsAsync = React.useCallback(async () => {
    if (!currentUser.organizationId || !currentUser.useDelegations) {
      return
    }
    await fetchAsync(listOrganizationDelegations(currentUser.organizationId)).then(setDelegationItems)
  }, [currentUser, fetchAsync, listOrganizationDelegations])

  const fetchTemplateActivityCategoriesAsync = React.useCallback(async () => {
    await fetchAsync(listOrganizationTemplateActivityCategories(currentUser.organizationId!)).then(setTemplateActivityCategories)
  }, [fetchAsync, setTemplateActivityCategories, listOrganizationTemplateActivityCategories, currentUser])

  const fetchUnitsAsync = React.useCallback(async () => {
    if (!currentUser.organizationId || !currentUser.useDrugLists) {
      return
    }
    await fetchAsync(listUnits(currentUser.organizationId!)).then(setUnits)
  }, [fetchAsync, setUnits, listUnits, currentUser])
  
  const fetchTemplateActivityAsync = React.useCallback(
    async (templateActivityId: number) => {
      await fetchAsync(getTemplateActivity(templateActivityId)).then(setSelectedTemplateActivity)
    },
    [fetchAsync, getTemplateActivity, setSelectedTemplateActivity]
  )

  const openTemplateActivityModal = React.useCallback(
    (templateActivityId?: number) => {

      Promise.all([fetchUnitsAsync(), 
          fetchDelegationsAsync(),
          fetchTemplateActivityCategoriesAsync()]).then(() => {
            if (!!templateActivityId) {
              void fetchTemplateActivityAsync(templateActivityId).then(() => setModalOpen(true))
            } else {
              setModalOpen(true)
            }
          }
      )
    },
    [fetchUnitsAsync, fetchDelegationsAsync, fetchTemplateActivityAsync, fetchTemplateActivityCategoriesAsync]
  )

  const closeTemplateActivityModal = React.useCallback(() => {
    setSelectedTemplateActivity(undefined)
    setModalOpen(false)
  }, [setSelectedTemplateActivity, setModalOpen])

  const createTemplateActivityAsync = React.useCallback(
    (createTemplateActivityModel: TemplateActivityCreateItem) => {
      return fetchAsync(
        createTemplateActivity({
          ...createTemplateActivityModel,
        })
      ).then(_ => {
        if (createTemplateActivityModel.ownerOrganizationId) {
          void fetchOrganizationTemplateActivitiesAsync()
        } else {
          void fetchAreaTemplateActivitiesAsync()
        }

        closeTemplateActivityModal()
      })
    },
    [fetchAsync, createTemplateActivity, closeTemplateActivityModal, fetchOrganizationTemplateActivitiesAsync, fetchAreaTemplateActivitiesAsync]
  )

  const updateTemplateActivityAsync = React.useCallback(
    (templateActivity: TemplateActivityUpdateItem) => {
      return fetchAsync(updateTemplateActivity(templateActivity))
        .then(_ => {
          if (templateActivity.ownerOrganizationId) {
            void fetchOrganizationTemplateActivitiesAsync()
          } else {
            void fetchAreaTemplateActivitiesAsync()
          }

          closeTemplateActivityModal()
        })
        .catch(() => {
          closeTemplateActivityModal()
        })
    },
    [closeTemplateActivityModal, fetchAsync, fetchOrganizationTemplateActivitiesAsync, fetchAreaTemplateActivitiesAsync, updateTemplateActivity]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedTemplateActivity,
      ownerAreaId,
      ownerOrganizationId,
      templateActivityCategories,
      units,
      delegationItems,
      confirmMoveDialogOpen,
    },
    actions: {
      openTemplateActivityModal,
      closeTemplateActivityModal,
      createTemplateActivityAsync,
      updateTemplateActivityAsync,
      setOwnerAreaId,
      setOwnerOrganizationId,
      openConfirmMoveDialog,
      closeConfirmMoveDialog,
      moveToOrganization,
    },
  }
}

export const TemplateActivityModalContext = createContext(useTemplateActivityModalContext)
