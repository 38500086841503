import React from 'react'
import { Tab, Tabs as MuiTabs } from '@mui/material'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import { Box } from '@mui/material'
import Page, { PageHeader } from 'components/page'
import { useFormatMessage } from 'localization'
import { UserTypeEnum } from 'api/tillit.api-client'
import AuthenticationContext from 'components/authentication/authentication-context'
import makeStyles from '@mui/styles/makeStyles'
import { NotificationContainer } from 'components/notification'
import { AdministerDevicesView, AdministerDevicesContext } from './components/administer-devices'
import { AdministerLegislationsContext } from './components/administer-legislations/administer-legislations-context'
import AdministerLegislationsView from './components/administer-legislations/administer-legislations-view'
import { LegislationModalContext } from './components/administer-legislations/components/legislation-modal/legislation-modal-context'
import LegislationModal from './components/administer-legislations/components/legislation-modal'
import { AdministerTemplateActivitiesView } from './components/administer-template-activities'
import { AdministerDelegationsView } from './components/administer-delegations'
import { AdministerDrugListContainer } from './components/administer-drug-list'
import { AdministerPersonnelView } from './components/administer-personnel'

const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
  width: 100vw;
  ${({theme}) => [theme.customBreakpoints.justMobileTablet]}{
    visibility: hidden;
  }
`

const useStyles = makeStyles(theme => {
  return ({
    'tenilo-administer-page': {
      [theme.customBreakpoints.justMobileTablet]: {
        '& .tenilo-main': {
          padding: '10px',
          '&>div':{
            marginLeft:0,
            marginRight:0
          }
        },
        '& .tenilo-banner': {
          height: '60px !important',
        }
      }
    }
  }
  )
})

const AdministerContainer: React.FC = () => {
  const classes = useStyles()
  const f = useFormatMessage()
  const { user } = AuthenticationContext.useAuthenticatedState()

  const [mainTabValue, setMainTabValue] = React.useState(0)

  const handleChange = (event, newValue: React.SetStateAction<number>) => {
    setMainTabValue(newValue)
  }

  type ITabPanelProps = Readonly<{
    index: number
    value: number
  }>

  const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {children}
      </Typography>
    )
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }


  const [tabIndexMap, setTabIndexMap] = React.useState(new Map())

  const TAB_1_Visible = React.useCallback((): boolean => {
    return user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport ||
      user.userType === UserTypeEnum.SystemAdmin ||
      user.userType === UserTypeEnum.OrganizationMember
  }, [user])

  const TAB_2_Visible = React.useCallback((): boolean => {
    return user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.SystemAdmin || user.userType === UserTypeEnum.TeniloSupport
  }, [user])

  const TAB_3_Visible = React.useCallback((): boolean => {
    return user.useDelegations && (user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport ||
      user.userType === UserTypeEnum.SystemAdmin ||
      user.userType === UserTypeEnum.OrganizationMember)
  }, [user])

  const TAB_4_Visible = React.useCallback((): boolean => {
    return user.useDrugLists && (user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport ||
      user.userType === UserTypeEnum.SystemAdmin)
  }, [user])

  const TAB_5_Visible = React.useCallback((): boolean => {
    return user.useWorkSchedule && (user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport ||
      user.userType === UserTypeEnum.SystemAdmin ||
      user.userType === UserTypeEnum.OrganizationMember)
  }, [user])

  React.useEffect(() => {
    const tabMap = new Map()
    let tabIndex = 0;

    tabMap.set("TAB_0", tabIndex)
    if (TAB_1_Visible()) {
      tabIndex++
      tabMap.set("TAB_1", tabIndex)
    }
    if (TAB_2_Visible()) {
      tabIndex++
      tabMap.set("TAB_2", tabIndex)
    }
    if (TAB_3_Visible()) {
      tabIndex++
      tabMap.set("TAB_3", tabIndex)
    }
    if (TAB_4_Visible()) {
      tabIndex++
      tabMap.set("TAB_4", tabIndex)
    }
    if (TAB_5_Visible()) {
      tabIndex++
      tabMap.set("TAB_5", tabIndex)
    }

    setTabIndexMap(tabMap)
  }, [setTabIndexMap])

  const getTabIndex = React.useCallback((tabKey: string): number => {
    return tabIndexMap.get(tabKey)
  }, [tabIndexMap])

  return (
    <NotificationContainer>
      <Page className={classes["tenilo-administer-page"]}>
        <PageHeader title={f('ADMINISTER_PAGE_VIEW_HEADER_TITLE')} />
        <Box display="flex" mx={-5}>
          <>
            <Box overflow="hidden">
              <Tabs indicatorColor="primary" textColor="primary" value={mainTabValue} onChange={handleChange} centered={true}>
                <Tab label={f('ADMINISTER_PAGE_VIEW_TAB_0_TITLE')} {...a11yProps(getTabIndex("TAB_0"))} />
                {TAB_1_Visible() &&
                  <Tab label={f('ADMINISTER_PAGE_VIEW_TAB_1_TITLE')} {...a11yProps(getTabIndex("TAB_1"))} />
                }

                {TAB_2_Visible() && (
                  <Tab label={f('ADMINISTER_PAGE_VIEW_TAB_2_TITLE')} {...a11yProps(getTabIndex("TAB_2"))} />
                )}

                {TAB_3_Visible() && (
                    <Tab label={f('ADMINISTER_PAGE_VIEW_TAB_3_TITLE')} {...a11yProps(getTabIndex("TAB_3"))} />
                  )}

                {TAB_4_Visible() && (
                    <Tab label={f('ADMINISTER_PAGE_VIEW_TAB_4_TITLE')} {...a11yProps(getTabIndex("TAB_4"))} />
                  )}

                {TAB_5_Visible() && (
                    <Tab label={f('ADMINISTER_PAGE_VIEW_TAB_5_TITLE')} {...a11yProps(getTabIndex("TAB_5"))} />
                  )}

              </Tabs>

              <TabPanel value={mainTabValue} index={getTabIndex("TAB_0")}>
                {mainTabValue === getTabIndex("TAB_0") && (
                  <AdministerDevicesContext.Provider>
                    <AdministerDevicesView />
                  </AdministerDevicesContext.Provider>
                )}
              </TabPanel>

              <TabPanel value={mainTabValue} index={getTabIndex("TAB_1")}>
                {mainTabValue === getTabIndex("TAB_1") && (
                  <AdministerTemplateActivitiesView />
                )}
              </TabPanel>

              <TabPanel value={mainTabValue} index={getTabIndex("TAB_2")}>
                {mainTabValue === getTabIndex("TAB_2") && (
                  <AdministerLegislationsContext.Provider>
                    <LegislationModalContext.Provider>
                      <AdministerLegislationsView />
                      <LegislationModal />
                    </LegislationModalContext.Provider>
                  </AdministerLegislationsContext.Provider>
                )}
              </TabPanel>

              {user.useDelegations && (
                <TabPanel value={mainTabValue} index={getTabIndex("TAB_3")}>
                  {mainTabValue === getTabIndex("TAB_3") && (
                    <AdministerDelegationsView />
                  )}
                </TabPanel>
              )}

              {user.useDrugLists && (
                <TabPanel value={mainTabValue} index={getTabIndex("TAB_4")}>
                  {mainTabValue === getTabIndex("TAB_4") && (
                    <AdministerDrugListContainer />
                  )}
                </TabPanel>
              )}

              { user.useWorkSchedule && (
                <TabPanel value={mainTabValue} index={getTabIndex("TAB_5")}>
                  {mainTabValue === getTabIndex("TAB_5") && (
                    <AdministerPersonnelView />
                  )}
                </TabPanel>
              )}
            </Box>
          </>
        </Box>
      </Page>
    </NotificationContainer>
  )
}

export default AdministerContainer
