import React from 'react'
import TeniloBanner from 'components/tenilo-banner'
import { NotificationBar } from 'components/notification'
import styled from 'styled-components'
import ConfirmationBoxView from 'components/confirmation-box'
import MainMenu from '../main-menu'

interface IPageBodyProps {
  noPadding?: boolean
  noMargin?: boolean
}

interface IPageProps extends IPageBodyProps {
  hideMenu?: boolean
  children?: React.ReactNode
  className?: string
  toggleOpenMobileTourMenu?: () => void
}

const StyledPage = styled.div`
  display: flex;
  height: 100%;
`

const StyledPageBody = styled.div<IPageBodyProps>`
  height: 100%;
  margin-left: ${props => (props.noMargin ? 0 : 60)}px;
  padding: ${props => (props.noPadding ? 0 : 40)}px;
  box-sizing: border-box;
  width: calc(100% - ${props => (props.noMargin ? 10 : 60)}px);
`

const Page: React.FC<IPageProps> = ({ hideMenu = false, children, noPadding = false, className, toggleOpenMobileTourMenu, noMargin = false }) => {
  const mobileTourViewProps = toggleOpenMobileTourMenu ? {
    toggleOpenMobileTourMenu
  } : {};
  return (
    <StyledPage className={className}>
      <TeniloBanner className="tenilo-banner" {...mobileTourViewProps} />
      <NotificationBar />
      {!hideMenu && <MainMenu />}
      <StyledPageBody className="tenilo-main" noPadding={noPadding} noMargin={noMargin}>
        {children}
      </StyledPageBody>
      {!hideMenu && <ConfirmationBoxView />}
    </StyledPage>
  )
}

export default Page
