import { Modal } from '@mui/material'
import { ModalProps } from '@mui/material/Modal'
import React from 'react'
import styled, {
  css,
  FlattenSimpleInterpolation,
} from 'styled-components'

const TempModal: React.FC<{
  width: string
  height: string
  fullSize?: boolean
  responsive?:boolean
  adminPageResponsive?:boolean
  css?: FlattenSimpleInterpolation
} & ModalProps> = ({ width, height, responsive, fullSize, adminPageResponsive, ...rest }) => <Modal {...rest} />

const CenterModalStyle = styled(TempModal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    ${({ fullSize = false, width, height }) =>
      fullSize
        ? css`
            width: ${width};
            height: ${height};
          `
        : css`
            max-width: ${width};
            max-height: ${height};
          `}
  }

  *::-ms-backdrop,
  .MuiPaper-root {
    width: 90%;
    display: block;
  }
  ${({ css: rootCss }) => rootCss}
  ${({responsive,theme}) => (
    responsive && (
      css`
        ${[theme.breakpoints.down('md')]}{
          align-items: flex-end;
          .MuiPaper-root {
            width: 100%;
            max-width: 100%;
          }
        }
      `
    )
  )}
  ${({adminPageResponsive,theme}) => (
    adminPageResponsive && (
      css`
        ${[theme.customBreakpoints.justMobileTablet]}{
            margin-left: 60px;
            .MuiPaper-root {
              margin: 16px
            }
        }
      `
    )
  )}
`

export default CenterModalStyle
