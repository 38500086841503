import { IKioskRequestItem, KioskAreaCreateItem, UserAreaItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction, UseFormHook } from 'types'
import * as yup from 'yup'
import { ActivateDeviceDialogContext } from '../activate-device-dialog-context'

export type ActivateDeviceFormModel = Readonly<{
  name: string
  area: UserAreaItem | undefined
  comment?: string
  allowNavigateToPlanningPage: boolean
  allowUseMultiSigning: boolean
  useManualSessionExpiration: boolean
  manualSessionExpirationMinutes: number
}>

export const useActivateDeviceForm: UseFormHook<ActivateDeviceFormModel> = () => {
  const f = useFormatMessage()
  const { requestActivation, handleCloseDialog } = ActivateDeviceDialogContext.useActions()

  const initialValues: ActivateDeviceFormModel = {
    name: '',
    area: undefined,
    comment: '',
    allowNavigateToPlanningPage: false,
    allowUseMultiSigning: false,
    useManualSessionExpiration: false,
    manualSessionExpirationMinutes: 2,
  }

  const onSubmit: OnSubmitFunction<ActivateDeviceFormModel> = (values, { setSubmitting }) => {
    const model: IKioskRequestItem = {
      name: values.name,
      comment: values.comment,
      mainArea: new KioskAreaCreateItem({
        areaId: values.area!.areaId,
        kioskMainArea: true,
      }),
      allowNavigateToPlanningPage: values.allowNavigateToPlanningPage,
      allowUseMultiSigning: values.allowUseMultiSigning,
      useManualSessionExpiration: values.useManualSessionExpiration,
      manualSessionExpirationMinutes: values.manualSessionExpirationMinutes,
    }

    requestActivation(model).finally(() => {
      setSubmitting(false)
      handleCloseDialog()
    })
  }

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ActivateDeviceFormModel>({
        name: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            50,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_NAME'),
              maxsize: 50,
            })
          ),
        area: yup.mixed().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        comment: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_PAGE_ACTIVE_DEVICE_DIALOG_LABEL_DESCRIPTION'),
            maxsize: 1024,
          })
        ),
        allowNavigateToPlanningPage: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        allowUseMultiSigning: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),

        useManualSessionExpiration: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        manualSessionExpirationMinutes: yup
          .number()
          .required(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL'),
            })
          )
          .integer(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL'),
            })
          )
          .typeError(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL'),
            })
          )
          .min(1, f('BASIC_ERROR_MIN', {min:1}))
          .max(540, f('BASIC_ERROR_MAX',{max:540}))
      }),
    []
  )

  const name: Name<ActivateDeviceFormModel> = {
    name: 'name',
    area: 'area',
    comment: 'comment',
    allowNavigateToPlanningPage: 'allowNavigateToPlanningPage',
    allowUseMultiSigning: 'allowUseMultiSigning',
    useManualSessionExpiration: 'useManualSessionExpiration',
    manualSessionExpirationMinutes: 'manualSessionExpirationMinutes',
  }

  return { initialValues, onSubmit, validationSchema, name }
}
