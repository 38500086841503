import { Box, useTheme } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { DelegationCategoryModalContext } from './delegation-category-modal-context'
import useFormData from './utilities/use-delegation-category-form-data'

export type DelegationCategoryModalProps = Readonly<{
  propsTitle?: string
  propsSubtitle?: string
  ownerAreaId?: number
  activeAreaId?: number
  onSave?: () => void
}>

const DelegationCategoryModalContainer: React.FC<DelegationCategoryModalProps> = ({ propsTitle, onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { modalOpen, selectedDelegationCategory, isLoading} = DelegationCategoryModalContext.useState() 

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedDelegationCategory)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeDelegationCategoryModal } = DelegationCategoryModalContext.useActions()

  return (
    <CenterModal open={modalOpen} onClose={closeDelegationCategoryModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {
          //const { ...statusProps } = values

          return (
            <Form translate="yes">
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={
                  propsTitle
                    ? propsTitle
                    : selectedDelegationCategory
                      ? f('ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_HEADER_EDIT_TITLE')
                      : f('ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_HEADER_TITLE')
                }
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>
                  <ModalSection
                    title={f('ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_TITLE')}
                    subtitle={f('ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          label={f('ADMINISTER_DELEGATION_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT')}
                          name={name.title}
                          color="secondary"
                          fullWidth={true}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeDelegationCategoryModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedDelegationCategory ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default DelegationCategoryModalContainer
