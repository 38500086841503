import React from 'react'

const HTMLContent = (props: any) => {
  const writeHTML = (frame: any) => {
    if (!frame) {
      return
    }
    const doc = frame.contentDocument
    doc.open()
    doc.write(props.content)
    doc.close()
    frame.style.width = '80vw'
    frame.style.height = '70vh'
  }

  return (
    <iframe
      src="about:blank"
      scrolling="yes"
      frameBorder="0"
      ref={writeHTML}
      title="tenilo_iframe"
    />
  )
}

export default HTMLContent
