import { STORAGEPREFIX } from 'config/variables'
import { IGrandIdRequestModel } from 'api/tillit.api-client'

const GIDS_SESSION_DATA_KEY = (STORAGEPREFIX ? STORAGEPREFIX : '') + 'gids_session_data'

export const hasGidsSessionData = (): boolean =>
  localStorage.getItem(GIDS_SESSION_DATA_KEY) !== null

export const getGidsSessionData = (): IGrandIdRequestModel | null => {
  const item = localStorage.getItem(GIDS_SESSION_DATA_KEY)
  if (item === null) {
    return null
  }
  return JSON.parse(item)
}

export const setGidsSessionData = (gidsSessionData: IGrandIdRequestModel) =>
  localStorage.setItem(GIDS_SESSION_DATA_KEY, JSON.stringify(gidsSessionData))

export const clearGidsSessionData = () => {
  localStorage.removeItem(GIDS_SESSION_DATA_KEY)
}
