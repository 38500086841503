import { useKioskApi } from 'api'
import {
  IManageKioskItem,
  ManageKioskItem,
  UserAreaItem,
} from 'api/tillit.api-client'
import AuthenticationContext from 'components/authentication/authentication-context'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useManageDeviceDialogContext = () => {
  const { getManageKiosk, manageKiosk } = useKioskApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user } = AuthenticationContext.useAuthenticatedState()

  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [kioskId, setKioskId] = React.useState<number | undefined>(undefined)
  const [device, setDevice] = React.useState<ManageKioskItem | null>(null)
  const [areas, setAreas] = React.useState<UserAreaItem[] | undefined>(
    undefined
  )

  const handleOpenDialog = React.useCallback((id: number) => {
    setKioskId(id)
    setDialogOpen(true)
  }, [])

  const handleCloseDialog = React.useCallback(() => {
    setKioskId(undefined)
    setDevice(null)
    setDialogOpen(false)
  }, [])

  const fetchDeviceAsync = React.useCallback(async () => {
    return await fetchAsync(getManageKiosk(kioskId!))
      .then(setDevice)
      .catch(error => {
        setDevice(null)
      })
  }, [fetchAsync, getManageKiosk, kioskId])

  const saveManageKioskItem = React.useCallback(
    async (kioskItem: IManageKioskItem) => {
      return await fetchAsync(manageKiosk(kioskItem))
    },
    [fetchAsync, manageKiosk]
  )

  React.useEffect(() => {
    if (user.userAreas) {
      setAreas(user.userAreas)
    }
  }, [user.userAreas])

  React.useEffect(() => {
    if (dialogOpen && kioskId) {
      fetchDeviceAsync()
    }
  }, [dialogOpen, fetchDeviceAsync, kioskId])

  return {
    state: { ...stateOfFetch, dialogOpen, device, kioskId, areas },
    actions: {
      handleOpenDialog,
      handleCloseDialog,
      fetchDeviceAsync,
      saveManageKioskItem
    },
  }
}

export const ManageDeviceDialogContext = createContext(
  useManageDeviceDialogContext
)
