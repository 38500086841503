import React from 'react'

import { useOrganizationApi } from 'api'
import {
  AreaListItem,
  ChildAreaOrRoutesForParentAreaItem,
  OrganizationItem,
} from 'api/tillit.api-client'
import { setTokenData } from 'api/token-data'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLevels, useSidebar } from './utilities'

export type Level = Readonly<{
  areaListItem: AreaListItem
  childrenOrRoutes: ChildAreaOrRoutesForParentAreaItem | null
}>

const useOrganizationContext = () => {
  const {
    user: { organizationId },
  } = Authentication.useAuthenticatedState()

  if (organizationId === undefined) {
    throw Error(`User does not belong to any organization`)
  }

  const { getOrganization, createAreaNode, abort } = useOrganizationApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const {
    state: { levels, stateOfFetch: levelsFetchState },
    actions: { addAreaListItemAsync, createAreaListItemAsync },
  } = useLevels()

  const sidebar = useSidebar()

  const [
    organization,
    setOrganization,
  ] = React.useState<OrganizationItem | null>(null)

  // When this component unmounts, abort all ongoing calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => abort(), [])

  React.useEffect(() => {
    fetchAsync(getOrganization(organizationId)).then(setOrganization)
  }, [fetchAsync, getOrganization, organizationId])

  const onAreaListItemClick = React.useCallback(
    (areaListItem: AreaListItem) => {
      // If we do not have a selection or it's not the same as selected
      if (
        sidebar.state.selectedAreaOrgTreeItem === null ||
        sidebar.state.selectedAreaOrgTreeItem.id !== areaListItem.id
      ) {
        sidebar.actions.setSelectedArea(areaListItem.id)
        // If selected area is not open anywhere in the tree
        if (!levels.some(level => level.areaListItem.id === areaListItem.id)) {
          // Fetch its children and add them to the tree
          addAreaListItemAsync(areaListItem)
        }
      }
    },
    [
      addAreaListItemAsync,
      levels,
      sidebar.actions,
      sidebar.state.selectedAreaOrgTreeItem,
    ]
  )

  const onAddSiblingToRootClick = React.useCallback(
    (name?: string) => {
      fetchAsync(
        createAreaNode({ name, organizationId })
          .catch(_ =>
            Promise.reject(
              Error(
                `You do not have the permissions to create an area under the organization`
              )
            )
          )
          .then(areaItem => {
            if (areaItem === null) {
              return Promise.reject('')
            }
            if (areaItem.newJwtToken) {
              setTokenData(areaItem.newJwtToken)
            }
            return Promise.resolve(areaItem.newlyCreatedAreaId)
          })
          .then(sidebar.actions.setSelectedArea)
          .then(_ => getOrganization(organizationId))
          .then(setOrganization)
      )
    },
    [
      createAreaNode,
      fetchAsync,
      getOrganization,
      organizationId,
      sidebar.actions.setSelectedArea,
    ]
  )

  const onAddSiblingClick = React.useCallback(
    (levelId: number, name?: string) => {
      createAreaListItemAsync(levelId, name).then(
        sidebar.actions.setSelectedArea
      )
    },
    [createAreaListItemAsync, sidebar.actions.setSelectedArea]
  )
  return {
    state: {
      ...stateOfFetch,
      sidebar: sidebar.state,
      organization,
      levels,
      levelsFetchState,
    },
    actions: {
      sidebar: sidebar.actions,
      onAreaListItemClick,
      onAddSiblingClick,
      onAddSiblingToRootClick,
    },
  }
}

export const OrganizationContext = createContext(useOrganizationContext)
