import React from 'react'
import { uniqBy } from 'lodash'
import { Box, Typography, useTheme, Link, IconButton, Button } from '@mui/material'
import { Portal } from '@mui/base/Portal'
import makeStyles from '@mui/styles/makeStyles'
import { PlannedActivityStatusEnum, CustomerListItem } from 'api/tillit.api-client'
import styled, { css, keyframes } from 'styled-components'
import ContainedButton from 'components/inputs/contained-button'
import Page, { PageHeader } from 'components/page'
import { NotificationContext } from 'components/notification'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Eye, EyeOff, ChevronDoubleLeft, Calendar, AccountDetails, CheckboxMultipleMarked, CheckboxMultipleBlankOutline, Refresh, BulletinBoard as BulletinBoardIcon } from 'mdi-material-ui'
import { CSSPropertiesWithVars } from 'styles/variables'
import { Colors } from 'styles/colors'
import Color from 'color'
import format from 'utilities/format'
import SingleActivityModal from './components/single-activity-modal'
import MultiSignActivitiesModal from './components/multi-sign-activities-modal'
import { MultiSignActivitiesModalContext } from './components/multi-sign-activities-modal'
import DailyActivityGroupItem from './components/daily-activity-group'
import DailyActivityItem from './components/daily-activity-item'
import DailyActivitySubGroupItem from './components/daily-activity-subgroup'
import DailyActivitySubButton from './components/daily-activity-sub-button'
import { DailyActivitiesContext, PlanedActivity, PlanedRoute, RouteKioskDailyActivitiesViewItemWithHasLapsed, SubGroupItem } from './daily-activities-context'
import { HistoryModalContext } from './components/history-modal/history-modal-context'
import { FutureModalContext } from './components/future-modal/future-modal-context'
import { ExpiringDelegationsModalContext } from './components/expiring-delegations-modal/expiring-delegations-modal-context'
import DailyActivityMainMenuMobile from './components/daily-activity-main-menu-mobile'
import { DailyActivitiesSigningStatusesContext } from './daily-activities-signing-statuses-context'
import { DailyActivitySettingsModal } from './components/daily-activity-settings'
import { DailyActivityMainMenuCustomerMode } from './components/daily-activity-main-menu-customer-mode'
import type { CustomerModeDataItemType } from './components/daily-activity-main-menu-customer-mode'
import { DailyActivityAdditionalAreas } from './components/daily-activity-additional-areas'
import BulletinBoard from './components/bulletin-board'
import { BulletinBoardContext } from './components/bulletin-board/bulletin-board-context'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import { BulletinBoardItemModalContext } from './components/bulletin-board/components/bulletin-board-item-modal/bulletin-board-item-modal-context'

const blink = keyframes`
  0% {
    transform: scale( 1 );
    opacity: 1;
    box-shadow: 0px 0px 2px 0px;
  }
  15% {
    opacity: 0.4;
    transform: scale( 1.04 );
    box-shadow: 0px 1px 3px 0px;
  }
  30% {
    transform: scale( 1 );
    opacity: 1;
    box-shadow: 0px 0px 2px 0px;
  }
  45% {
    transform: scale( 1.04 );
    opacity: 0.4;
    box-shadow: 0px 1px 3px 0px;
  }
  60% {
    opacity: 1;
    transform: scale( 1 );
    box-shadow: 0px 0px 2px 0px;
  }
  100% {
    opacity: 1;
    transform: scale( 1 );
    box-shadow: 0px 0px 2px 0px;
  }
`

const DailyActivityGroupList = styled(Box)(({ theme }) => css`
  >.tenilo-daily-activity-tour-menu-close-button{
    display: none;
  }
  ${[theme.breakpoints.down('md')]}{
    >.tenilo-daily-activity-tour-menu-close-button{
      display: block;
      margin-left: auto;
      color: ${Colors.TeniloBeige};
      >svg {
        font-size: 2.5rem;
      }
    }
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--dailyActivityGroupListMobileWidth,0);
    z-index: 1;
    transition: width 0.3s ease-in-out;
    background-color: rgba(10, 84, 87,0.97);
    overflow: hidden;
  }
`)
const DailyActivityList = styled(Box)(({ theme }) => css`
  .header-tour {
    animation: ${blink} 2s linear infinite;
  }
  ${[theme.breakpoints.down('md')]}{
    width: 100%;
  }
`)

const DailyActivityMainMenuDesktop = styled(Box)(({ theme }) => css`
  display: flex;
  padding-top: ${theme.spacing(2)};
  ${[theme.breakpoints.down('md')]}{
    display: none;
    padding-top: ${theme.spacing(1.25)};
  }
`)
const DailyActivityContainer = styled(Box)(({ theme }) => css`
  display: flex;
  padding-top: ${theme.spacing(5)};
  ${[theme.breakpoints.down('md')]}{
    padding-top: 0;
    margin-bottom: ${theme.spacing(1)};
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: ${theme.palette.primary.main};
      border-radius:20px;
    }
  }
`)

const useStyles = makeStyles(theme => {
  return ({
    leftMargin: {
      marginLeft: theme.spacing(2),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    'tenilo-daily-activity-page': {
      display: 'block',
      backgroundColor: Colors.TeniloBeige,
      height: 'auto',
      minHeight: '100%',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        '& .tenilo-main': {
          marginLeft: 0,
          width: '100%',
          paddingTop: theme.spacing(1.25),
          paddingLeft: theme.spacing(2.5),
          paddingRight: theme.spacing(2.5),
          paddingBottom: '0px',
          height: 'calc(100% - 60px)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        },
        '& .tenilo-banner': {
          position: 'relative !important',
          height: '60px !important',
          '& .tenilo-banner_tours': {
            display: 'flex'
          }
        }
      }
    }
  }
  )
})


const reduceGroups = (routes: RouteKioskDailyActivitiesViewItemWithHasLapsed[]) => {
  return routes
    .filter(route => !!route.plannedRoutes)
    .map(route => {
      const { plannedRoutes, name, color } = route

      const activities = plannedRoutes!.flatMap(plannedRoute =>
        (plannedRoute.plannedActivityRows || []).map<PlanedActivity>(plannedActivityRow => ({
          areaName: plannedActivityRow.areaName || '',
          plannedActivityId: plannedActivityRow.id,
          title: plannedActivityRow.activityName,
          firstName: plannedActivityRow.ownerCustomerFirstName,
          lastName: plannedActivityRow.ownerCustomerLastName,
          status: plannedActivityRow.plannedActivityStatusId,
          activityColor: plannedActivityRow.activityColor,
          hasLapsed: plannedActivityRow.hasLapsed,
          roomNumber: plannedActivityRow.ownerCustomerRoomNumber,
          activityName: plannedActivityRow.activityName,
          lastSignedOn: plannedActivityRow.lastSignedOn,
          startTime: plannedActivityRow.startTime,
          endTime: plannedActivityRow.endTime,
          comment: plannedActivityRow.comment,
          isCommonActivity: !plannedActivityRow.ownerCustomerId,
          ownerCustomerId: plannedActivityRow.ownerCustomerId,
          ownerAreaId: plannedActivityRow.ownerAreaId,
          activityShowColorOnDevice: plannedActivityRow.activity_ShowColorOnDevice,
          templateActivityTitle: plannedActivityRow.templateActivityTitle ? plannedActivityRow.templateActivityTitle : undefined,
          hasUnfulfilledDelegations: plannedActivityRow.hasUnfulfilledDelegations,
          severityLevelId: plannedActivityRow.activity_SeverityLevelId,
          recurranceId: plannedActivityRow.activity_RecurranceId,
          useParticipantList: plannedActivityRow.useParticipantList,
          unitTypeId: plannedActivityRow.unitTypeId,
          activity_AlertFromStartInMinutes: plannedActivityRow.activity_AlertFromStartInMinutes,
        }))
      )

      return { routeId: route.id, name, color, activities }
    })
}

const getActivitiesWithColor = (activities: PlanedActivity[], color?: string) => {
  return activities.map<{ color?: string } & PlanedActivity>(activity => ({
    ...activity,
    color,
  }))
}

const getActiveActivities = (activeGroups: PlanedRoute[]) => {
  return activeGroups.reduce<Array<{ color?: string } & PlanedActivity>>((prev, activeGroup) => {
    const activities = getActivitiesWithColor(activeGroup.activities, activeGroup.color)
    return [...prev, ...activities]
  }, [])
}

const RefreshButton: React.FunctionComponent<{ visible: boolean, refreshClicked: () => void }> = props => {
  const { visible, refreshClicked } = props
  if (!visible) {
    return <></>
  }

  return (
    <IconButton onClick={refreshClicked}>
      <Refresh color="primary" />
    </IconButton>
  )
}

const DailyActivitiesContainer: React.FC = () => {
  const classes = useStyles()
  const f = useFormatMessage()

  const theme = useTheme()
  const {
    data,
    locked,
    isLoading,
    user: currentUser,
    hasMultipleAreasOnKiosk,
    openMobileTourMenu,
    routeIdForUserWorkSchedule,
    listCustomer,
    latestUpdated,
    lockTime,
    selectedAdditionalAreaId,
    showBulletinBoard,
  } = DailyActivitiesContext.useState()
  const {
    onPlanedActivityClicked,
    onLockClick,
    onSingleActivityUndoOpen,
    updateDataOnStatusChanged,
    openDroplistCustomer,
    setSelectedRouteId,
    toggleOpenMobileTourMenu,
    onSingleActivityRefresh,
    openWorkScheduleDialog,
    linkToPlanningPage,
    handleToggleSettingsModal,
    getAllCustomers,
    handleToggleInternalInformationModal,
    refreshData,
    setSelectedAdditionalAreaId,
    setShowBulletinBoard,
  } = DailyActivitiesContext.useActions()
  //const [showBulletinBoard, setShowBulletinBoard] = React.useState(false)

  const { signingStatuses } = DailyActivitiesSigningStatusesContext.useState()

  const { openHistoryModal } = HistoryModalContext.useActions()
  const { openFutureModal } = FutureModalContext.useActions()
  const { openExpiringDelegationsModal } = ExpiringDelegationsModalContext.useActions()

  const allGroups = React.useMemo(() => reduceGroups((data && data.items) || []), [data])

  const [activeGroups, setActiveGroups] = React.useState<PlanedRoute[]>(allGroups)

  const [refreshButtonVisible, setRefreshButtonVisible] = React.useState<boolean>(false)

  const refreshTimer = React.useRef<number | undefined>()

  React.useEffect(() => {
    if (locked && refreshButtonVisible) {
      setRefreshButtonVisible(false)
    }

    if (!locked && !refreshButtonVisible) {
      const enableRefresh = () => {
        setRefreshButtonVisible(true)
      }

      const startTimer = () => {
        window.clearTimeout(refreshTimer.current)
        refreshTimer.current = window.setTimeout(enableRefresh, 1 * 60 * 1000)
      }

      startTimer()
    }
    return () => {
      window.clearTimeout(refreshTimer.current)
    }

  }, [locked, refreshButtonVisible])

  const refreshClicked = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setRefreshButtonVisible(false)
    refreshData()
  }, [])

  const [activeSubGroup, setActiveSubGroup] = React.useState<SubGroupItem | undefined>(undefined)

  const [activeGroupRouteId, setActiveGroupRouteId] = React.useState<number | undefined>(undefined)

  const { addAlerts, removeAlertByType } = NotificationContext.useActions()

  const currUserNotifiId = React.useRef<string>()

  const [showExactTimeOnKioskForUser, setShowExactTimeOnKioskForUser] = React.useState(false)

  const [selectedMenu, setSelectedMenu] = React.useState<{ selectedCustomer: number, selectedRoute?: number }>()

  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const [useMultiSigning, setUseMultiSigning] = React.useState(false)
  const [multiSigningPlannedActivityIds, setMultiSigningPlannedActivityIds] = React.useState<number[]>([])
  const [selectedUnitTypeId, setSelectedUnitTypeId] = React.useState<number | undefined>(undefined)
  const [openMultiSignActivitiesModal, setOpenMultiSignActivitiesModal] = React.useState<boolean>(false)

  const closeMultiSignActivitiesModal = React.useCallback(() => {
    setOpenMultiSignActivitiesModal(false)
  }, [])

  const multiSigningDone = React.useCallback(() => {
    setOpenMultiSignActivitiesModal(false)
    setUseMultiSigning(false)
    setMultiSigningPlannedActivityIds([])
    setSelectedUnitTypeId(undefined)
  }, [])

  const handleSelectMenu = React.useCallback((cId?: number, tId?: number) => () => {
    if (cId === undefined) {
      setSelectedMenu(undefined)
      return
    }
    setSelectedMenu({ selectedCustomer: cId, selectedRoute: tId })
  }, [])

  const handleOpenPlanningPage = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    linkToPlanningPage(currentUser)
  }, [currentUser, linkToPlanningPage])

  const handleSetSelectedAdditionalAreaId = (areaId: number) => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setSelectedAdditionalAreaId(areaId)
    setActiveSubGroup(undefined)
    setActiveGroupRouteId(undefined)
    setSelectedMenu(undefined)
  }

  React.useEffect(() => {
    if (currentUser) {
      setShowExactTimeOnKioskForUser(currentUser?.showExactTimeOnKioskActivity)
    }
  }, [currentUser])

  // Update active groups to contain all groups if the data changes
  React.useEffect(() => {
    if (activeGroupRouteId) {
      const newGroup = allGroups.filter(group => {
        return group.routeId === activeGroupRouteId
      })
      setActiveGroups(newGroup)
    } else {
      setActiveGroups(allGroups)
    }
  }, [allGroups, activeGroupRouteId])

  const customerModeData: CustomerModeDataItemType[] = React.useMemo(() => {
    if (!listCustomer) { return [] }
    return [new CustomerListItem(), ...listCustomer].map((item, i) => {
      return {
        id: i,
        customer: item,
        route: allGroups.map(group => {
          return {
            ...group,
            activities: item.id ? group.activities.filter(activity => activity.ownerCustomerId === item.id) : group.activities.filter(activity => !activity.ownerCustomerId && activity.ownerAreaId)
          }
        })
      }
    })
  }, [allGroups, listCustomer])

  React.useEffect(() => {
    setActiveGroupRouteId(routeIdForUserWorkSchedule)
  }, [routeIdForUserWorkSchedule])

  const activeActivities = React.useMemo(() => {
    let activities: Array<{
      color?: string | undefined;
    } & PlanedActivity>

    if (currentUser?.showCustomerMode) {
      if (!selectedMenu) {
        activities = getActiveActivities(allGroups)
      } else {
        if (!selectedMenu.selectedRoute) {
          activities = getActiveActivities(customerModeData[selectedMenu.selectedCustomer].route)
        }
        else {
          activities = getActiveActivities(customerModeData[selectedMenu.selectedCustomer].route.filter(route => route.routeId === selectedMenu.selectedRoute))
        }
      }
    } else {
      activities = getActiveActivities(activeGroups)
    }
    return activities.sort((a, b) => {
      // 31536000000 = 365 days in milliseconds. Will make sure delayed activities are on top...
      // 63072000000 = 365 * 2 days in milliseconds. Will make sure started activities are on top...
      const aSortKey = a.status === PlannedActivityStatusEnum.Delayed ? a.startTime.getTime() - 31536000000 : a.status === PlannedActivityStatusEnum.Started ? a.startTime.getTime() - 63072000000 : a.startTime.getTime()
      const bSortKey = b.status === PlannedActivityStatusEnum.Delayed ? b.startTime.getTime() - 31536000000 : b.status === PlannedActivityStatusEnum.Started ? b.startTime.getTime() - 63072000000 : b.startTime.getTime()
      return aSortKey - bSortKey
    })
  }, [activeGroups, customerModeData, selectedMenu, currentUser, allGroups])


  const handleGroupClick = React.useCallback(
    (...groupListItems: PlanedRoute[]) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      if (groupListItems.length === 1) {
        setActiveGroupRouteId(groupListItems[0].routeId)
      } else {
        setActiveGroupRouteId(undefined)
      }
      setActiveGroups(groupListItems)
      setActiveSubGroup(undefined)
    },
    []
  )

  const handleSubGroupClick = React.useCallback(
    (plannedGroup: PlanedActivity) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      const subGroupItem = {
        ownerCustomerId: plannedGroup.ownerCustomerId,
        ownerAreaId: plannedGroup.ownerAreaId,
      }
      setActiveSubGroup(subGroupItem)
    },
    []
  )

  React.useEffect(() => {
    if (!listCustomer) {
      getAllCustomers()
    }
  }, [currentUser])

  React.useEffect(() => {
    if (!currentUser) {
      handleGroupClick(...allGroups)()
      setSelectedMenu(undefined)
    }
  }, [currentUser, allGroups])

  const handleShowTFHistoryClick = React.useCallback(
    (customerId: number) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      if (customerId) {
        openHistoryModal(customerId)
      }
    },
    [openHistoryModal]
  )

  const handleShowFutureClick = React.useCallback(
    (customerId: number) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      if (customerId) {
        openFutureModal(customerId)
      }
    },
    [openFutureModal]
  )

  const handleActivitySelected = React.useCallback(
    (planedActivityId: number) => () => {
      if (!useMultiSigning) {
        try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
        onPlanedActivityClicked(planedActivityId)
      }
    },
    [onPlanedActivityClicked, useMultiSigning]
  )

  const handleActivityMultiSignClicked = React.useCallback(
    (plannedActivityId: number, unitTypeId: number) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      setMultiSigningPlannedActivityIds((prev) => {
        let mspaIds = [...prev]
        if (mspaIds.length === 0) {
          setSelectedUnitTypeId(unitTypeId) // Note: unitTypeId -1 (="normal"). -1 does not exist but we need undefined to signal "none selected"
          mspaIds.push(plannedActivityId)
        } else {
          if (mspaIds.includes(plannedActivityId)) {
            mspaIds = mspaIds.filter(mspaId => mspaId !== plannedActivityId)
            if (mspaIds.length === 0) {
              setSelectedUnitTypeId(undefined)
            }
          } else {
            mspaIds.push(plannedActivityId)
          }
        }
        return mspaIds
      })

    },
    [useMultiSigning]
  )

  const handleAddTemporaryActivity = React.useCallback(
    (routeId: number) => () => {
      setSelectedRouteId(routeId)
      openDroplistCustomer(routeId)
    },
    [openDroplistCustomer, setSelectedRouteId]
  )

  const handleLockClicked = React.useCallback(onLockClick, [onLockClick])

  const [showSigned, setShowSigned] = React.useState(false)

  const handleToggleSignedClick = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setShowSigned(!showSigned)
  }, [showSigned])

  const handleToggleMultiSigningClick = React.useCallback(() => {
    const useMulti = !useMultiSigning
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setUseMultiSigning(useMulti)
    if (!useMulti) {
      setMultiSigningPlannedActivityIds([])
      setSelectedUnitTypeId(undefined)
    }
  }, [useMultiSigning])


  const handleToggleBulletinBoardClick = React.useCallback(() => {
    const show = !showBulletinBoard
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setShowBulletinBoard(show)
    //    if (!show) {
    //      setSelectedUnitTypeId(undefined)
    //    }
  }, [showBulletinBoard])


  const handleSignMultipleClick = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setOpenMultiSignActivitiesModal(true)
    //setUseMultiSigning(false)
    //setMultiSigningPlannedActivityIds([])

  }, [multiSigningPlannedActivityIds])


  const handleShowExpiringDelegationsClick = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    openExpiringDelegationsModal(currentUser)
  }, [currentUser])

  React.useEffect(() => {

    if (!locked && currentUser && currentUser.hasExpiringDelegations) {
      addAlerts({ // add alert
        type: `user-hasExpiringDelegations-${currentUser.id}`,
        severity: 'warning',
        renderBody: () => (
          <Typography variant="subtitle2">
            {f('DAILY_ACTIVITY_EXPIRING_DELEGATIONS')}
            <Link
              component="button"
              onClick={handleShowExpiringDelegationsClick}
              style={{ paddingLeft: '10px' }}
            >
              {f('DAILY_ACTIVITY_EXPIRING_DELEGATIONS_SHOW')}
            </Link>
          </Typography>
        )
      })
      currUserNotifiId.current = `user-hasExpiringDelegations-${currentUser.id}`
    }
    if (locked) {
      if (currUserNotifiId.current) {
        removeAlertByType(currUserNotifiId.current)
        currUserNotifiId.current = undefined
      }

      //Turn off multisigning when locked
      setUseMultiSigning(false)
      setSelectedUnitTypeId(undefined)
      setMultiSigningPlannedActivityIds([])
      setOpenMultiSignActivitiesModal(false)
    }
  }, [locked, currentUser, handleShowExpiringDelegationsClick])

  const lockTimeInfo = !locked && lockTime ? `(${f('DAILY_ACTIVITIES_PAGE_CONTAINER_LATEST_LOCK_TEXT')}: ${format(lockTime, "HH:mm")})` : ''

  const touchOptions = {
    enableTouchEvents: true,
    enableMouseEvents: true
  }

  return (
    <Page
      className={classes["tenilo-daily-activity-page"]}
      hideMenu
      noMargin
      toggleOpenMobileTourMenu={toggleOpenMobileTourMenu}
    >
      <PageHeader
        title={(data && data.kioskAreaName) || ''}
        description={`${f('DAILY_ACTIVITIES_PAGE_CONTAINER_LATEST_UPDATE_TEXT')}: ${format(latestUpdated, "HH:mm")} ${lockTimeInfo}`}
        descriptionComponent={<RefreshButton visible={refreshButtonVisible} refreshClicked={refreshClicked} />}
      />
      {(!locked && currentUser) && (
        <Box sx={(theme) => ({
          height: '75px',
          position: 'absolute',
          display: 'flex',
          alignItems: 'flex-end',
          top: 0,
          right: '190px',
          [theme.breakpoints.down('md')]: {
            height: '59px',
            right: '155px'
          }
        })}>
          <Typography
            color='primary'
            variant="subtitle2"
            onClick={handleToggleSettingsModal}
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              lineHeight: 1.3,
              cursor: 'pointer',
              transition: 'color 0.3s ease-out',
              '*': {
                transition: 'color 0.3s ease-out'
              },
              '&:hover': {
                color: Color(Colors.TeniloGreen).darken(0.7).string(),
                '*': {
                  color: Color(Colors.TeniloGreen).darken(0.7).string()
                }
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '11px',
                lineHeight: 1.2,
              }
            })}>
            <AccountDetails color='primary' />
            {f('DAILY_ACTIVITY_LOGGED_IN_STATUS')} <br />
            <span data-matomo-mask>{currentUser?.fullName}</span>
          </Typography>
        </Box>
      )}
      {!showBulletinBoard && (
        <DailyActivityMainMenuMobile
          locked={locked}
          user={currentUser}
          showSigned={showSigned}
          useMultiSigning={useMultiSigning}
          handleLockClicked={handleLockClicked}
          handleToggleSignedClick={handleToggleSignedClick}
          handleToggleMultiSigningClick={handleToggleMultiSigningClick}
          handleSignMultipleClick={handleSignMultipleClick}
          handleToggleBulletinBoardClick={handleToggleBulletinBoardClick}
          openWorkScheduleDialog={openWorkScheduleDialog}
          openPlanningPage={handleOpenPlanningPage}
          handleToggleInternalInformationModal={handleToggleInternalInformationModal}
          allowNavigateToPlanningPage={data?.allowNavigateToPlanningPage}
          allowUseMultiSigning={data?.allowUseMultiSigning}
          disablePerformMultiSign={multiSigningPlannedActivityIds.length === 0}
          showBulletinBoard={showBulletinBoard}
        />
      )}

      {!showBulletinBoard && (
        <DailyActivityAdditionalAreas
          data={data}
          user={currentUser}
          selectedAreaId={selectedAdditionalAreaId}
          handleSetSelectedAdditionalAreaId={handleSetSelectedAdditionalAreaId}
        />
      )}
      <DailyActivityMainMenuDesktop>
        {!showBulletinBoard && (
          <ContainedButton onClick={handleLockClicked} color={'secondary'}>
            {locked ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_UNLOCK_BUTTON_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_SCREENLOCK_LOCK_BUTTON_TEXT')}
          </ContainedButton>
        )}

        {!showBulletinBoard && (
          <ContainedButton sx={{ height: '36.5px', alignSelf: 'center' }} onClick={handleToggleSignedClick} color='primary' >
            {showSigned ? <EyeOff className={classes.leftIcon} /> : <Eye className={classes.leftIcon} />}
            {showSigned ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_HIDE_SIGNED_TOGGLE_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_SIGNED_TOGGLE_TEXT')}
          </ContainedButton>
        )}

        {!locked && currentUser?.useWorkSchedule && !showBulletinBoard && (
          <>
            <ContainedButton onClick={openWorkScheduleDialog} color={'primary'}>
              {f('DAILY_ACTIVITIES_PAGE_CONTAINER_WORK_SCHEDULES_BUTTON_TEXT')}
            </ContainedButton>
          </>
        )}
        {((data?.areaInternalInformation && data.areaInternalInformation.length > 0) || (data?.organizationInternalInformation && data.organizationInternalInformation.length > 0)) && !showBulletinBoard && (
          <ContainedButton onClick={handleToggleInternalInformationModal} color={'primary'}>
            {f('DAILY_ACTIVITIES_PAGE_CONTAINER_INTERNAL_INFORMATION_BUTTON_TEXT')}
          </ContainedButton>
        )}
        {!locked && data?.allowNavigateToPlanningPage && !showBulletinBoard && (
          <>
            <ContainedButton sx={{ height: '36.5px', alignSelf: 'center' }} onClick={handleOpenPlanningPage}>
              <Calendar />{f('DAILY_ACTIVITIES_PAGE_CONTAINER_PLANNINGPAGE_BUTTON_TEXT')}
            </ContainedButton>
          </>
        )}

        {!locked && data?.allowUseMultiSigning && !showBulletinBoard && (
          <>
            <ContainedButton sx={{ height: '36.5px', alignSelf: 'center' }} onClick={handleToggleMultiSigningClick} color='primary' >
              {useMultiSigning ? <CheckboxMultipleMarked className={classes.leftIcon} /> : <CheckboxMultipleBlankOutline className={classes.leftIcon} />}
              {useMultiSigning ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_DEACTIVATE_MULTISIGN_TOGGLE_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_ACTIVATE_MULTISIGN_TOGGLE_TEXT')}
            </ContainedButton>

            {useMultiSigning && (
              <ContainedButton sx={{ height: '36.5px', alignSelf: 'center' }} onClick={handleSignMultipleClick} color='primary' disabled={multiSigningPlannedActivityIds.length === 0}>
                {f('DAILY_ACTIVITIES_PAGE_CONTAINER_PERFORM_MULTISIGN_TEXT')}
              </ContainedButton>
            )}
          </>
        )}

        {!locked && !showBulletinBoard && (
          <>
            <ContainedButton sx={{ height: '36.5px', alignSelf: 'center' }} onClick={handleToggleBulletinBoardClick}>
              {showBulletinBoard ? <BulletinBoardIcon className={classes.leftIcon} /> : <BulletinBoardIcon className={classes.leftIcon} />}
              {showBulletinBoard ? f('DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_HIDE_TEXT') : f('DAILY_ACTIVITIES_PAGE_CONTAINER_BULLETINBOARD_BUTTON_SHOW_TEXT')}
            </ContainedButton>
          </>
        )}

      </DailyActivityMainMenuDesktop>

      {!locked && showBulletinBoard && (
        <DndProvider backend={TouchBackend} options={touchOptions}>
          <BulletinBoardItemModalContext.Provider>
            <BulletinBoardContext.Provider>
              <BulletinBoard closeBulletinBoard={handleToggleBulletinBoardClick} />
            </BulletinBoardContext.Provider>
          </BulletinBoardItemModalContext.Provider>
        </DndProvider>
      )}

      {!showBulletinBoard && (
        <DailyActivityContainer>
          {isLoading ? (
            <Spinner size={64} color={theme.palette.primary.main} />
          ) : (
            <>
              {allGroups.length !== 0 && (
                <DailyActivityGroupList pr={5} style={{
                  '--dailyActivityGroupListMobileWidth': openMobileTourMenu ? '100%' : '0px'
                } as CSSPropertiesWithVars}>
                  <IconButton
                    className="tenilo-daily-activity-tour-menu-close-button"
                    aria-label='tours-close-button'
                    onClick={toggleOpenMobileTourMenu}
                  >
                    <ChevronDoubleLeft />
                  </IconButton>
                  {
                    !currentUser?.showCustomerMode ? (
                      <>
                        <DailyActivityGroupItem
                          isActive={activeGroups.length === allGroups.length}
                          onClick={handleGroupClick(...allGroups)}
                          color={matches ? Colors.TeniloBeige : Colors.TeniloGreen}
                          title={f('DAILY_ACTIVITIES_PAGE_CONTAINER_GROUPSLIST_ALL_TITLE')}
                          mb={3}
                        />
                        {allGroups.map((groupListItem, index) => (
                          <React.Fragment key={index}>
                            {activeGroups.some(a => a.routeId === groupListItem.routeId) && (
                              <>
                                <DailyActivityGroupItem
                                  isActive={activeGroups.some(a => a.routeId === groupListItem.routeId)}
                                  onClick={handleGroupClick(groupListItem)}
                                  title={groupListItem.name}
                                  color={groupListItem.color}
                                  mb={3}
                                />

                                {activeGroups.length === 1 && activeGroups.some(a => a.routeId === groupListItem.routeId) && (
                                  <>
                                    {!locked && (
                                      <DailyActivitySubGroupItem
                                        isLocked
                                        customer=""
                                        initials={f('DAILY_ACTIVITIES_PAGE_CONTAINER_ADD_TEMPORARY_ACTIVITY')}
                                        roomNumber=""
                                        isCommonActivity={false}
                                        isActive
                                        title=""
                                        onClick={handleAddTemporaryActivity(groupListItem.routeId)}
                                        color={groupListItem.color}
                                        mb={3}
                                      />
                                    )}
                                    {uniqBy(activeActivities, act => {
                                      if (act && (act.ownerCustomerId || act.ownerAreaId)) {
                                        return (act.ownerCustomerId ? act.ownerCustomerId.toString() : '') + '_' + (act.ownerAreaId ? act.ownerAreaId.toString() : '')
                                      }
                                      return null
                                    }).map((item, i) => (
                                      <React.Fragment key={i}>
                                        <DailyActivitySubGroupItem
                                          isLocked={locked}
                                          customer={`${item.firstName || ''} ${item.lastName || ''}`}
                                          initials={`${(item.firstName || '?')[0]}${(item.lastName || '?')[0]}`}
                                          roomNumber={item.roomNumber}
                                          isCommonActivity={item.isCommonActivity}
                                          isActive={!activeSubGroup || activeSubGroup.ownerCustomerId === item.ownerCustomerId}
                                          title={item.title || ''}
                                          onClick={handleSubGroupClick(item)}
                                          color={item.color as any}
                                          mb={3}
                                        />
                                        <>
                                          {!locked && activeSubGroup && activeSubGroup.ownerCustomerId && activeSubGroup.ownerCustomerId === item.ownerCustomerId && (
                                            <DailyActivitySubButton
                                              title={f('DAILY_ACTIVITIES_PAGE_CONTAINER_HISTORY')}
                                              onClick={handleShowTFHistoryClick(item.ownerCustomerId)}
                                              color={item.color as any}
                                              isLocked={false}
                                              mb={3}
                                            />
                                          )}
                                          {!locked && activeSubGroup && activeSubGroup.ownerCustomerId && activeSubGroup.ownerCustomerId === item.ownerCustomerId && (
                                            <DailyActivitySubButton
                                              title={f('DAILY_ACTIVITIES_PAGE_CONTAINER_FUTURE')}
                                              onClick={handleShowFutureClick(item.ownerCustomerId)}
                                              color={item.color as any}
                                              isLocked={false}
                                              mb={3}
                                            />
                                          )}
                                        </>
                                      </React.Fragment>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </>
                    ) : (
                      <DailyActivityMainMenuCustomerMode
                        customerModeData={customerModeData}
                        selectedMenu={selectedMenu}
                        handleSelectMenu={handleSelectMenu}
                        handleShowFutureClick={handleShowFutureClick}
                        handleShowTFHistoryClick={handleShowTFHistoryClick}
                      />
                    )
                  }
                </DailyActivityGroupList>
              )}
              {activeActivities.length === 0 ? (
                <Typography>{f('DAILY_ACTIVITIES_PAGE_CONTAINER_ACTIVITIES_NOSCHEDULED_MESSAGE_TEXT')}</Typography>
              ) : (
                <DailyActivityList display={'flex'} flexDirection={'row'} flexWrap={'wrap'} flex={1} alignContent={'flex-start'} justifyContent={'flex-start'}>
                  {((activeSubGroup && !currentUser?.showCustomerMode) ? activeActivities.filter(activity => activity.ownerCustomerId === activeSubGroup.ownerCustomerId) : activeActivities).map(
                    (
                      {
                        color,
                        firstName,
                        lastName,
                        title,
                        plannedActivityId,
                        status,
                        activityColor,
                        hasLapsed,
                        areaName,
                        roomNumber,
                        activityName,
                        lastSignedOn,
                        isCommonActivity,
                        startTime,
                        endTime,
                        comment,
                        activityShowColorOnDevice,
                        templateActivityTitle,
                        hasUnfulfilledDelegations,
                        severityLevelId,
                        recurranceId,
                        useParticipantList,
                        unitTypeId,
                        activity_AlertFromStartInMinutes,
                      },
                      index
                    ) => {
                      let itemBackgroundColor = status === PlannedActivityStatusEnum.Started ?
                        Color(Colors.LightBlue).lighten(0.35).string() :
                        status === PlannedActivityStatusEnum.Delayed ?
                          Color(Colors.TeniloBrown).lighten(0.5).string() :
                          status !== PlannedActivityStatusEnum.NotCompleted ?
                            Colors.White :
                            Colors.White
                      if (hasUnfulfilledDelegations) {
                        itemBackgroundColor = '#FFD3D3'
                      }
                      return (
                        (status === PlannedActivityStatusEnum.NotCompleted || status === PlannedActivityStatusEnum.Delayed || status === PlannedActivityStatusEnum.Started || showSigned) && (
                          <DailyActivityItem
                            areaName={areaName}
                            bgcolor={
                              itemBackgroundColor
                            }
                            key={`${index}${activityName}${startTime}`}
                            onClick={handleActivitySelected(plannedActivityId)}
                            isLocked={locked}
                            customer={`${firstName} ${lastName}`}
                            activityColor={activityColor}
                            activityShowColorOnDevice={activityShowColorOnDevice}
                            status={status}
                            title={title || ''}
                            initials={`${(firstName || '?')[0]}${(lastName || '?')[0]}`}
                            color={color as any}
                            roomNumber={roomNumber}
                            activityName={activityName}
                            hasLapsed={hasLapsed}
                            lastSignedOn={lastSignedOn}
                            isCommonActivity={isCommonActivity}
                            startTime={startTime}
                            endTime={endTime}
                            comment={comment}
                            templateActivityTitle={templateActivityTitle}
                            hasUnfulfilledDelegations={hasUnfulfilledDelegations}
                            severityLevelId={severityLevelId}
                            recurranceId={recurranceId}
                            showExactTimeOnKioskFromUserSetting={showExactTimeOnKioskForUser}
                            useParticipantList={useParticipantList}
                            useMultiSigning={useMultiSigning}
                            onMultiSignClicked={handleActivityMultiSignClicked(plannedActivityId, (unitTypeId ? unitTypeId : -1))}
                            unitTypeId={unitTypeId ? unitTypeId : -1}
                            selectedUnitTypeId={selectedUnitTypeId}
                            multiSignChecked={multiSigningPlannedActivityIds.length > 0 ? multiSigningPlannedActivityIds.some(mspaid => mspaid === plannedActivityId) : false}
                            mr={4}
                            mb={4}
                            suppressTimes={data?.suppressTimesOnKioskAndPwa ? data?.suppressTimesOnKioskAndPwa : false}
                            alertFromStartInMinutes={activity_AlertFromStartInMinutes}
                          />
                        )
                      )
                    }
                  )}
                </DailyActivityList>
              )}
            </>
          )}
        </DailyActivityContainer>
      )}
      <Portal>
        <SingleActivityModal
          currentUser={currentUser}
          updateDataOnStatusChanged={updateDataOnStatusChanged}
          onSingleActivityUndoOpen={onSingleActivityUndoOpen}
          onSingleActivityRefresh={onSingleActivityRefresh}
          signingStatuses={signingStatuses}
          listCustomer={listCustomer}
        />
        <MultiSignActivitiesModalContext.Provider>
          <MultiSignActivitiesModal
            plannedActivityIds={multiSigningPlannedActivityIds}
            selectedUnitTypeId={selectedUnitTypeId}
            signingStatuses={signingStatuses}
            open={openMultiSignActivitiesModal}
            onClose={closeMultiSignActivitiesModal}
            onSigningDone={multiSigningDone}
          />
        </MultiSignActivitiesModalContext.Provider>
      </Portal>
      <DailyActivitySettingsModal />
    </Page>
  )
}

export const ModalBox = styled(Box)`
  padding: 1vh 3vw 1vh 3vw;
  background-color: #f9f9f9;
  ${({ theme }) => theme.breakpoints.down('md')}{
    padding: 5px;
  }
`

export default DailyActivitiesContainer
