import { Box } from '@mui/material'
import React from 'react'
import { Tab, Tabs as MuiTabs } from '@mui/material'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import { UserTypeEnum } from 'api/tillit.api-client'
import AuthenticationContext from 'components/authentication/authentication-context'
import { useFormatMessage } from 'localization'

import { AdministerOrganizationTemplateActivitiesContext } from './components/administer-organization-template-activities/administer-organization-template-activities-context'
import AdministerOrganizationTemplateActivities from './components/administer-organization-template-activities/administer-organization-template-activities-view'

import { AdministerAreaTemplateActivitiesContext } from './components/administer-area-template-activities/administer-area-template-activities-context'
import AdministerAreaTemplateActivities from './components/administer-area-template-activities/administer-area-template-activities-view'

import { AdministerInheritedTemplateActivitiesContext } from './components/administer-inherited-template-activities/administer-inherited-template-activities-context'
import AdministerInheritedTemplateActivities from './components/administer-inherited-template-activities/administer-inherited-template-activities-view'

import { AdministerTemplateActivityCategoriesContext } from './components/administer-template-activity-categories/administer-template-activity-categories-context'
import AdministerTemplateActivityCategories from './components/administer-template-activity-categories/administer-template-activity-categories-view'

import { TemplateActivityModalContext } from './components/template-activity-modal/template-activity-modal-context'
import TemplateActivityModal from './components/template-activity-modal'

import { InheritedTemplateActivityModalContext } from './components/inherited-template-activity-modal/inherited-template-activity-modal-context'
import InheritedTemplateActivityModal from './components/inherited-template-activity-modal'

import { TemplateActivityCategoryModalContext } from './components/template-activity-category-modal/template-activity-category-modal-context'
import TemplateActivityCategoryModal from './components/template-activity-category-modal'

import { ConnectTemplateActivitiesToAreasModalContext } from './components/connect-template-activities-to-areas-modal/connect-template-activities-to-areas-modal-context'


const Tabs = styled(MuiTabs)`
    box-shadow: inset 0 -2px 2px -2px gray;
    width: 100vw;
`

const AdministerTemplateActivitiesView: React.FC = () => {
  const { user } = AuthenticationContext.useAuthenticatedState()
  const initialTabValue = 0
  const [activityTemplateTabvalue, setActivityTemplateTabValue] = React.useState(initialTabValue)
  const f = useFormatMessage()

  const handleActivityTemplateTabChange = (event: any, newValue: React.SetStateAction<number>) => {
    setActivityTemplateTabValue(newValue)
  }


  type ITabPanelProps = Readonly<{
    index: number
    value: number
  }>

  const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} id={`sub-tabpanel-${index}`} aria-labelledby={`sub-tab-${index}`} {...other}>
        <Box p={3}>{children}</Box>
      </Typography>
    )
  }

  const a11yProps = (index: number) => {
    return {
      id: `sub-tab-${index}`,
      'aria-controls': `sub-tabpanel-${index}`,
    }
  }

  const [tabIndexMap, setTabIndexMap] = React.useState(new Map())

  const TAB_1_Visible = React.useCallback((): boolean => {
    return user.allowAreaTemplateActivities && (user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport ||
      user.userType === UserTypeEnum.SystemAdmin ||
      user.userType === UserTypeEnum.OrganizationMember)
  }, [user])

  const TAB_2_Visible = React.useCallback((): boolean => {
    return user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport ||
      user.userType === UserTypeEnum.SystemAdmin ||
      user.userType === UserTypeEnum.OrganizationMember
    }, [user])

  const TAB_3_Visible = React.useCallback((): boolean => {
    return user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport || user.userType === UserTypeEnum.SystemAdmin
  }, [user])

  React.useEffect(() => {
    const tabMap = new Map()
    let tabIndex = 0;

    tabMap.set("TAB_0", tabIndex)
    if (TAB_1_Visible()) {
      tabIndex++
      tabMap.set("TAB_1", tabIndex)
    }
    if (TAB_2_Visible()) {
      tabIndex++
      tabMap.set("TAB_2", tabIndex)
    }
    if (TAB_3_Visible()) {
      tabIndex++
      tabMap.set("TAB_3", tabIndex)
    }

    setTabIndexMap(tabMap)
  }, [setTabIndexMap])

  const getTabIndex = React.useCallback((tabKey: string): number => {
    return tabIndexMap.get(tabKey)
  }, [tabIndexMap])

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={activityTemplateTabvalue}
          onChange={handleActivityTemplateTabChange}
          centered={true}>

          <Tab label={f('ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_ORGANIZATION_LABEL')} {...a11yProps(getTabIndex("TAB_0"))} />

          {TAB_1_Visible() && (
            <Tab label={f('ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_AREA_LABEL')} {...a11yProps(getTabIndex("TAB_1"))} />
          )}

          {TAB_2_Visible() && (
            <Tab label={f('ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_INHERITANCE_LABEL')} {...a11yProps(getTabIndex("TAB_2"))} />
          )}

          {TAB_3_Visible() && (
            <Tab label={f('ADMINISTER_TEMPLATE_ACTIVITIES_VIEW_CATEGORY_LABEL')} {...a11yProps(getTabIndex("TAB_3"))} />
          )}
        </Tabs>

        <TabPanel value={activityTemplateTabvalue} index={getTabIndex("TAB_0")}>
          {activityTemplateTabvalue === getTabIndex("TAB_0") && (
            <AdministerAreaTemplateActivitiesContext.Provider>
              <AdministerOrganizationTemplateActivitiesContext.Provider>
                <TemplateActivityModalContext.Provider>
                  <ConnectTemplateActivitiesToAreasModalContext.Provider>
                    <AdministerOrganizationTemplateActivities />
                    <TemplateActivityModal />
                  </ConnectTemplateActivitiesToAreasModalContext.Provider>
                </TemplateActivityModalContext.Provider>
              </AdministerOrganizationTemplateActivitiesContext.Provider>
            </AdministerAreaTemplateActivitiesContext.Provider>
          )}
        </TabPanel>
        {user.allowAreaTemplateActivities && (
          <TabPanel value={activityTemplateTabvalue} index={getTabIndex("TAB_1")}>
            {activityTemplateTabvalue === getTabIndex("TAB_1") && (
              <AdministerAreaTemplateActivitiesContext.Provider>
                <AdministerOrganizationTemplateActivitiesContext.Provider>
                  <TemplateActivityModalContext.Provider>
                    <AdministerAreaTemplateActivities />
                    <TemplateActivityModal />
                  </TemplateActivityModalContext.Provider>
                </AdministerOrganizationTemplateActivitiesContext.Provider>
              </AdministerAreaTemplateActivitiesContext.Provider>
            )}
          </TabPanel>
        )}

          <TabPanel value={activityTemplateTabvalue} index={getTabIndex("TAB_2")}>
            {activityTemplateTabvalue === getTabIndex("TAB_2") && (
              <AdministerInheritedTemplateActivitiesContext.Provider>
                <InheritedTemplateActivityModalContext.Provider>
                  <AdministerInheritedTemplateActivities />
                  <InheritedTemplateActivityModal />
                </InheritedTemplateActivityModalContext.Provider>
              </AdministerInheritedTemplateActivitiesContext.Provider>
            )}
          </TabPanel>

        <TabPanel value={activityTemplateTabvalue} index={getTabIndex("TAB_3")}>
          {activityTemplateTabvalue === getTabIndex("TAB_3") && (
            <AdministerTemplateActivityCategoriesContext.Provider>
              <TemplateActivityCategoryModalContext.Provider>
                <AdministerTemplateActivityCategories />
                <TemplateActivityCategoryModal />
              </TemplateActivityCategoryModalContext.Provider>
            </AdministerTemplateActivityCategoriesContext.Provider>
          )}
        </TabPanel>
      </div>


    </div>
  )
}

export default AdministerTemplateActivitiesView
