import React from 'react'
import { PropTypes } from '@mui/material'
import { TimePicker, TimePickerProps, renderTimeViewClock } from '@mui/x-date-pickers'
import 'date-fns'
import { Field, FieldProps, FormikHandlers } from 'formik'
import { useFormatMessage } from 'localization'

type Props = Omit<TimePickerProps<any>, 'value' | 'onChange' | 'renderInput'> & {
  name: string
  color?: PropTypes.Color
  value?: Date
  onChange?: FormikHandlers['handleChange'] | TimePickerProps<any>['onChange']
}

const TimePickerView: React.FC<Props> = ({ name, ...props }) => {
  const f = useFormatMessage()
  const handleChange = (form: FieldProps['form'], field: FieldProps['field']) => (date: any) => {
    form.setFieldValue(field.name, date)
  }
  return (
    <>
      {name ? (
        <Field name={name}>
          {({ field, form }: FieldProps) => (
            <TimePicker
              label={props.label}
              {...props}
              {...field}
              {...(props.value ? { value: props.value } : null)}
              onChange={handleChange(form, field)}
              localeText={{
                toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
              }}
              slotProps={{
                textField:{
                  variant:'filled'
                }
              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          )}
        </Field>
      ) : (
        <TimePicker
          {...props}
          value={props.value || new Date()}
          onChange={props.onChange!}
          label={props.label}
          localeText={{
            toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
          }}
          slotProps={{
            textField:{
              variant:'filled'
            }
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      )}
    </>
  )
}

export default TimePickerView

