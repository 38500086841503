import React from 'react'
import { useSupportUsersApi } from 'api'
import { UserListItem, PageResultOfUserListItem } from 'api/tenilo.support.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'
import supportPageContext from 'pages/support/support-page-context'

const useSupportUsersContext = () => {
  const { listUsers, abort } = useSupportUsersApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { selectedOrganization, selectedArea } = supportPageContext.useState()

  const [userListItems, setUserListItems] = React.useState<PageResultOfUserListItem | undefined>(undefined)
  const [impersonateUserModalOpen, setImpersonateUserModalOpen] = React.useState(false)
  const [userToImpersonate, setUserToImpersonate] = React.useState<UserListItem | undefined>(undefined)

  const location = useLocation()

  const fetchUsersAsync = React.useCallback(async (page: number, pageSize: number, organizationId: number | null, areaId: number | null) => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listUsers(page, pageSize, organizationId, areaId).then(setUserListItems))
  }, [currentUser.organizationId, fetchAsync, listUsers])

  const refreshUsersAsync = React.useCallback(() => {
    const page = 1
    const pageSize = 25000 //TODO FIX IN TEN-902
    if (selectedOrganization) {
      void fetchUsersAsync(page, pageSize, selectedOrganization.id, selectedArea ? selectedArea.id : null)
    } else {
      setUserListItems(undefined)
    }
  }, [currentUser.organizationId, fetchUsersAsync, selectedOrganization, selectedArea])

  const openImpersonateUserModal = React.useCallback((user: UserListItem) => {
    setImpersonateUserModalOpen(true)
    setUserToImpersonate(user)
  }, [setImpersonateUserModalOpen])

  const closeImpersonateUserModal = React.useCallback(() => {
    setImpersonateUserModalOpen(false)
    setUserToImpersonate(undefined)
    refreshUsersAsync()
  }, [setImpersonateUserModalOpen, refreshUsersAsync])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    refreshUsersAsync()
  }, [refreshUsersAsync, location])

  return {
    state: {
      ...stateOfFetch,
      userListItems,
      impersonateUserModalOpen,
      userToImpersonate,
    },
    actions: {
      fetchUsersAsync,
      openImpersonateUserModal,
      closeImpersonateUserModal,
      refreshUsersAsync,
    },
  }
}

export const SupportUsersContext = createContext(useSupportUsersContext)
