import { CustomerListItem, CustomerAreaItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { isSearchValueValid } from './filter-helper'

export const filterCustomers = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: CustomerListItem[]
): CustomerListItem[] => {
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(c => {
    const areaName = c && c.customerAreas && c.customerAreas.length > 0 ? c.customerAreas[0].areaName : ''
    const organizationParentsName = c && c.customerAreas && c.customerAreas.length > 0 ? c.customerAreas[0].organizationParentsName : ''
    const searchText = `${c.firstName} ${c.lastName} ${c.roomNumber} ${areaName} ${organizationParentsName}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as CustomerListItem[]
  }
}
