import React from 'react'

import type { FC } from 'react'
import { XYCoord, useDragLayer } from 'react-dnd'

import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import Color from 'color'
import { BulletinBoardItemModel } from '../bulletin-board-context'
export interface ItemProps {

}

export const BulletinBoardItemDragLayer: FC<ItemProps> = ({ }) => {

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  const bulletinBoardItem = item as BulletinBoardItemModel

  const textColor = React.useMemo(() => {
    if (!bulletinBoardItem) {
      return ''
    }
    return Color(bulletinBoardItem.color).contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'
  }, [bulletinBoardItem])

  const getItemStyles = React.useCallback((
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null
  ) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      }
    }

    const { x, y } = currentOffset
    const left = `${x}px`
    const top = `${y}px`
    return {
      left,
      top
    }
  }, [])

  if (!isDragging || !bulletinBoardItem) {
    return null
  }

  return (
    <Card
      sx={{
        // make the card resizable
        overflow: 'auto',
        resize: 'both',
        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: `${bulletinBoardItem.width}px`,
        height: `${bulletinBoardItem.height}px`,
        backgroundColor: bulletinBoardItem.color,
        position: 'absolute'
      }}
      style={getItemStyles(initialOffset, currentOffset)}
    >
      <CardHeader title={bulletinBoardItem.header} sx={{ '.MuiCardHeader-title': { color: textColor } }}
        action={
          <>
            <IconButton aria-label="settings"
              aria-haspopup="true"
            >
              <GridMoreVertIcon sx={{ color: textColor }} />
            </IconButton>

          </>
        } />
      <CardContent>
        <>
          {bulletinBoardItem.content?.split(/\n/g).map((row, index) => {
            if (row.trim().length === 0) {
              return (<br />)
            }
            return (
              <Typography key={`bbicr${index}`} variant="body2" sx={{ color: textColor }} display="block">
                {row}
              </Typography>
            )
          })
          }
        </>
      </CardContent>
    </Card >


  )
}
