import { useTheme } from '@mui/material'
import Spinner from 'components/spinner'
import { AreaModalContext } from 'components/area-modal/area-modal-context'
import React from 'react'
import BasicInformationView from './basic-information-view'

interface IBasicInformationContainer {
  customRef: React.RefObject<any>
}

export const BasicInformationContainer: React.SFC<IBasicInformationContainer> = ({ customRef }) => {
  const theme = useTheme()
  const { areaInfo, isLoading } = AreaModalContext.useState()
  return (
    <div style={{ position: 'relative' }}>
      {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
      {areaInfo && <BasicInformationView areaInfo={areaInfo} customRef={customRef} />}
    </div>
  )
}
