import React from 'react'
import * as yup from 'yup'

import Authentication from 'components/authentication'
import PageRoute from 'config/page-routes'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { useNavigate } from "react-router-dom"

type ResetPasswordForm = Readonly<{
  newPassword: string
  confirmPassword: string
}>

const handleError = (setStatus: (status?: any) => void) => (error: any) => {
  setStatus(JSON.parse(error.response))
}

// Define the hook to be used to leverage this form
const useResetPasswordForm = (userId: number, token: string) => {
  const { changePasswordWithToken } = Authentication.useActions()
  const f = useFormatMessage()
  const navigate = useNavigate()

  const initialValues: ResetPasswordForm = React.useMemo(
    () => ({
      newPassword: '',
      confirmPassword: '',
    }),
    []
  )

  const onSubmit: OnSubmitFunction<ResetPasswordForm> = React.useCallback(
    ({ newPassword }, { setSubmitting, setStatus }) => {
      changePasswordWithToken(userId, token, newPassword)
        .then(() => navigate(PageRoute.Login.path))
        .catch(handleError(setStatus))
        .finally(() => {
          setSubmitting(false)
        })
    },
    [changePasswordWithToken, token, userId]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ResetPasswordForm>({
        newPassword: yup
          .string()
          .min(8, f('RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_MIN_LENGTH'))
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        confirmPassword: yup
          .string()
          .min(8, f('RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_MIN_LENGTH'))
          .oneOf(
            [yup.ref('newPassword')],
            f('RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_VALIDATION_EQUAL')
          )
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      }),
    [f]
  )

  const name: Name<ResetPasswordForm> = React.useMemo(
    () => ({
      newPassword: 'newPassword',
      confirmPassword: 'confirmPassword',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useResetPasswordForm
