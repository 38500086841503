import React from 'react'
import * as yup from 'yup'

import { LegislationCreateItem, ILegislationItem, LegislationUpdateItem } from 'api/tillit.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { LegislationModalContext } from '../legislation-modal-context'

type LegislationForm = Readonly<{
  title: string
  organizationId: number
  description?: string
}>

const createLegislation = (values: LegislationForm): LegislationCreateItem => {
  const { title, organizationId, description } = values

  return new LegislationCreateItem({
    title,
    organizationId,
    description,
  })
}

const mapLegislationFormToLegislationItem = (initialItem: ILegislationItem, item: LegislationForm): LegislationUpdateItem => {
  return new LegislationUpdateItem({
    id: initialItem.id,
    title: item.title,
    organizationId: initialItem.organizationId,
    description: item.description ? item.description : undefined,
  })
}

const mapLegislationItemToLegislationForm = (item: ILegislationItem): LegislationForm => {
  const createItem: LegislationForm = {
    organizationId: item.organizationId,
    title: item.title ? item.title : '',
    description: item.description ? item.description : '',
  }
  return createItem
}

const useLegislationFormData = (initialLegislation?: ILegislationItem) => {
  const f = useFormatMessage()

  const { createLegislationAsync, updateLegislationAsync } = LegislationModalContext.useActions()
  const { ownerOrganizationId } = LegislationModalContext.useState()
  const legislation = initialLegislation

  const initialValues = React.useMemo<LegislationForm>(
    () =>
      !!legislation
        ? mapLegislationItemToLegislationForm(legislation)
        : {
            legislationId: undefined,
            legislation: undefined,
            organizationId: ownerOrganizationId ? ownerOrganizationId : -1,
            title: '',
            description: '',
          },
    [legislation, ownerOrganizationId]
  )

  const onSubmit: OnSubmitFunction<LegislationForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const templateActivityCategory = createLegislation({
        ...values,
      })
      if (!initialLegislation) {
        await createLegislationAsync(templateActivityCategory).finally(() => setSubmitting(false))
      } else {
        const mappedLegislation = mapLegislationFormToLegislationItem(initialLegislation, values)
        await updateLegislationAsync(mappedLegislation).finally(() => setSubmitting(false))
      }
    },
    [createLegislationAsync, initialLegislation, updateLegislationAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),

      description: yup.string().max(
        1024,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
          maxsize: 1024,
        })
      ),
    })
  }, [f])

  const name: Name<LegislationForm> = React.useMemo(
    () => ({
      organizationId: 'organizationId',
      title: 'title',
      description: 'description',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useLegislationFormData
