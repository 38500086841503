import React from 'react'
import { Tab } from '@mui/material'
import { Tabs, TabPanel, a11yProps } from 'components/tab-panel'
import { useFormatMessage } from 'localization'
import { WorkScheduleView, WorkScheduleDialog, WorkScheduleContext, ConnectWorkscheduleToAreasModal } from './components/work-schedule'
import { WorkScheduleConnectionView, WorkScheduleConnectionDialog, WorkScheduleConnectionContext } from './components/work-schedule-connection'


const AdministerPersonnelView: React.FC = () => {
  const f = useFormatMessage()
  const [tabValue, setTabValue] = React.useState(0)

  const handleDrugListTabChange = (event: any, newValue: React.SetStateAction<number>) => {
    setTabValue(newValue)
  }

  return (
    <div>
      <Tabs indicatorColor="primary" textColor="primary" value={tabValue} onChange={handleDrugListTabChange} centered>
        <Tab label={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_LABEL')} {...a11yProps(0)} />
        <Tab label={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_LABEL')} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        {tabValue === 0 && (
          <WorkScheduleContext.Provider>
            <WorkScheduleView />
            <WorkScheduleDialog/>
            <ConnectWorkscheduleToAreasModal />
          </WorkScheduleContext.Provider>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {tabValue === 1 && (
          <WorkScheduleConnectionContext.Provider>
            <WorkScheduleConnectionView />
            <WorkScheduleConnectionDialog />
          </WorkScheduleConnectionContext.Provider>
        )}
      </TabPanel>
    </div>
  )
}

export default AdministerPersonnelView
