import { Box, FormControlLabel, FormHelperText, MenuItem, Radio, Typography, useTheme } from '@mui/material'
import CenterModal from 'components/center-modal'
import BigCheckbox from 'components/inputs/big-checkbox'
import ColorPicker from 'components/inputs/color-picker'
import ContainedButton from 'components/inputs/contained-button'
import DatePicker from 'components/inputs/date-picker'
import FilledSelect from 'components/inputs/filled-select'
import FilledTextField from 'components/inputs/filled-text-field'
import RadioButton from 'components/inputs/radio-button'
import TextButton from 'components/inputs/text-button'
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import format from 'utilities/format'
import { generateActivityStatusText, getWeekOfMonthDateAsString } from 'utilities/generate-activity-status-text'
import SelectInput from 'components/inputs/select-input'
import ActivityParticipants from 'components/activity-participants'
import { TemplateActivityItem, TemplateActivityInheritanceItem, SeverityLevelEnum } from 'api/tillit.api-client'
import usePromiseApi from 'utilities/use-promise'
import { useTemplateActivityApi } from 'api'
import { compundedTemplateActivityTitle } from 'utilities/template-activity-helper'
import Authentication from 'components/authentication'
import ModalContent from 'components/modal-content'
import ActivityCreateModalContext from './activity-create-modal-context'
import useFormData from './utilities/use-activity-create-form-data'

export type ActivityCreateModalProps = Readonly<{
  propsTitle?: string
  propsSubtitle?: string
  ownerAreaId?: number
  activeAreaId?: number
  onSave?: () => void
}>

const ActivityCreateModalContainer: React.FC<ActivityCreateModalProps> = ({ propsTitle, propsSubtitle, ownerAreaId, activeAreaId, onSave }) => {
  const { fetchAsync } = usePromiseApi()
  const { getTemplateActivity, getTemplateActivityInheritanceOnArea } = useTemplateActivityApi()
  const f = useFormatMessage()
  const getCompundedTemplateActivityTitle = compundedTemplateActivityTitle(f)
  const intl = useIntl()
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const {
    customerInfo,
    modalOpen,
    selectedActivity,
    isLoading,
    templateActivityItems,
    selectedTemplateActivity,
    selectedTemplateActivityInheritance,
    currentAreaId,
    customers,
  } = ActivityCreateModalContext.useState()

  const { setOwnerAreaId, setActiveAreaId, setSelectedTemplateActivity, setSelectedTemplateActivityInheritance } = ActivityCreateModalContext.useActions()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  React.useEffect(() => {
    setOwnerAreaId(ownerAreaId)
    setActiveAreaId(activeAreaId)
  }, [ownerAreaId, activeAreaId, setOwnerAreaId, setActiveAreaId])

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedActivity)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }
  const { closeModal } = ActivityCreateModalContext.useActions()
  const recurrenceInterval = Array.from(Array(12).keys())
  const recurrenceId = ['Daily', 'Weekly', 'Monthly']

  const formatDate = React.useCallback(
    (date?: Date) => {
      if (!date) {
        return null
      }
      return intl.formatDate(date, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    []
  )

  const isPlanned = selectedActivity ? selectedActivity.isPlanned : false

  const toggleDatePickerEndDate = (status: boolean) => () => {
    setIsOpen(status)
  }

  const endDateStyle = {
    display: 'none',
    cursor: 'pointer',
  }

  const clearEndDate = (setFieldValue: any) => () => {
    setFieldValue('endDate', null)
  }

  const startDateDisabled = !!selectedActivity && selectedActivity.startDate < new Date(new Date().setHours(0, 0, 0, 0))

  return (
    <CenterModal open={modalOpen} onClose={closeModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty, touched }) => {
          const { endDate, templateActivityIdExistsInDb, templateActivityId, ...statusProps } = values
          // tslint:disable-next-line: no-string-literal
          const weekdaysErrorText: string | undefined = errors['undefined']
          const minEndDate = new Date(Math.max(+values.startDate, +new Date()))
          minEndDate.setHours(0, 0, 0, 0)
          minEndDate.setDate(minEndDate.getDate() + 1)

          const handleChangeTemplateActivity = (event: React.ChangeEvent<{}>, value: any) => {

            const taV = value as TemplateActivityItem
            if (taV) {
              setFieldValue('templateActivityId', taV.id)
              setSelectedTemplateActivity(undefined)
              setSelectedTemplateActivityInheritance(undefined)

              void fetchAsync(getTemplateActivity(taV.id)).then(ta => {
                if (ta instanceof TemplateActivityItem) {
                  if (!selectedActivity) {
                    // if adding new activity. Populate values from template activity
                    setFieldValue('visitLengthInMinutes', ta?.visitLengthInMinutes)
                    setFieldValue('alertFromStartInMinutes', ta?.alertFromStartInMinutes)
                    setFieldValue('color', ta?.color)
                    setFieldValue('showColorOnDevice', ta?.showColorOnDevice)
                    setFieldValue('expectedAmount', ta?.unitAmount)
                    setFieldValue('severityLevelId', ta?.severityLevelId)
                  }
                  setSelectedTemplateActivity(ta)

                  if (currentAreaId) {
                    void fetchAsync(getTemplateActivityInheritanceOnArea(taV.id, currentAreaId)).then(tai => {
                      if (tai instanceof TemplateActivityInheritanceItem) {
                        setSelectedTemplateActivityInheritance(tai)
                      }
                    })
                  }
                }
              })
            } else {
              setFieldValue('templateActivityId', undefined)
              setSelectedTemplateActivity(undefined)
              setSelectedTemplateActivityInheritance(undefined)
            }
          }

          const handleChangeRecurrenceInterval = (event: any, child: any) => {
            setFieldValue('recurrenceInterval', event.target.value)
          }

          const handleChangeRecurrenceId = (event: any, child: any) => {
            setFieldValue('recurrenceId', event.target.value)
          }

          const handleChangeFromTime = (val) => {
            setFieldValue('fromTime', val)
            if (val && !isNaN(val) && val instanceof Date) {

              const spanMinutes = values.visitLengthInMinutes > 60 ? +values.visitLengthInMinutes + 60 : 60
              const toTime = new Date(val.getTime() + spanMinutes * 60 * 1000)
              setFieldValue('toTime', toTime)
            }
          }

          const handleChangeToTime = (val: Date | null) => {
            setFieldValue('toTime', val)
          }

          return (
            <Form translate="yes" style={{display:'contents'}}>
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              {values.recurrenceId === 'OneTime' && (
                <ModalHeader
                  data-matomo-mask
                  title={
                    propsTitle
                      ? propsTitle
                      : selectedActivity
                        ? f('ACTIVITY_TEMPORARY_DIALOG_CONTAINER_HEADER_EDIT_TITLE')
                        : f('ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE')
                  }
                  subtitle={propsSubtitle ? propsSubtitle : customerInfo ? customerInfo.fullName : ''}
                />
              )}
              {values.recurrenceId !== 'OneTime' && (
                <ModalHeader
                  data-matomo-mask
                  title={
                    propsTitle ? propsTitle : selectedActivity ? f('ACTIVITY_DIALOG_CONTAINER_HEADER_EDIT_TITLE') : f('ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE')
                  }
                  subtitle={propsSubtitle ? propsSubtitle : customerInfo ? customerInfo.fullName : ''}
                />
              )}
                <ModalContent style={{padding:'20px'}}>
                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Box flex={1}>
                          <SelectInput
                            label={f('ACTIVITY_DIALOG_CONTAINER_TEMPLATE_ACTIVITY_LABEL_DEFAULT')}
                            onChange={handleChangeTemplateActivity}
                            name={name.templateActivity}
                            options={templateActivityItems as any}
                            getOptionLabel={getCompundedTemplateActivityTitle}
                            disabled={templateActivityIdExistsInDb}
                            fullWidth
                            error={!!errors.templateActivity}
                            helperText={errors.templateActivity}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_SUBTITLE')}>
                    {isPlanned && (
                      <Typography style={{ color: theme.palette.error.main }}>
                        {f('ACTIVITY_DIALOG_CONTAINER_INFO_PLANNED_ACTIVITY_CANNOT_BE_CHANGED')}
                      </Typography>
                    )}
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          data-matomo-mask
                          label={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_NAME_TEXTFIELD_LABEL')}
                          name={name.title}
                          color="secondary"
                          fullWidth
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {currentUser.useDrugLists && selectedTemplateActivity && selectedTemplateActivity.unitId && (
                    <ModalSection title={f('ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_TITLE')} subtitle={f('ACTIVITY_DIALOG_CONTAINER_EXPECTED_AMOUNT_SUBTITLE')}>
                      <ModalSectionRow>
                        <FilledTextField
                          label={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_EXPECTED_AMOUNT_LABEL')}
                          name={name.expectedAmount}
                          type="number"
                          color="secondary"
                        />
                        {(selectedTemplateActivity.unitTitle || selectedTemplateActivity.unitAbbreviation) && (
                          <Typography style={{ marginTop: 'auto' }} >
                            {(selectedTemplateActivity?.unitTitle ? selectedTemplateActivity?.unitTitle : '') + (selectedTemplateActivity?.unitAbbreviation ? ` (${selectedTemplateActivity?.unitAbbreviation})` : '')}
                          </Typography>
                        )}

                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_ROW2_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_INFOTEXT_ROW2_SUBTITLE')}>
                    <ModalSectionRow>
                      <FilledTextField
                        label={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL')}
                        name={name.visitLengthInMinutes}
                        color="secondary"
                        disabled={isPlanned}
                      />
                    </ModalSectionRow>
                    <ModalSectionRow>
                      <BigCheckbox
                        label={`10 ${f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="10"
                        checkedByValue
                        returnValue="value"
                        disabled={isPlanned}
                      />
                      <BigCheckbox
                        label={`20 ${f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="20"
                        checkedByValue
                        returnValue="value"
                        disabled={isPlanned}
                      />
                      <BigCheckbox
                        label={`30 ${f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="30"
                        checkedByValue
                        returnValue="value"
                        disabled={isPlanned}
                      />
                      <BigCheckbox
                        label={`60 ${f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_SHORT_MINUTE_CHECKBOX_LABEL')}`}
                        name={name.visitLengthInMinutes}
                        value="60"
                        checkedByValue
                        returnValue="value"
                        disabled={isPlanned}
                      />
                    </ModalSectionRow>
                  </ModalSection>

                  {values.recurrenceId !== 'OneTime' && (
                    <ModalSection title={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW1_TITLE')} subtitle={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW1_SUBTITLE')}>
                      <ModalSectionRow>
                        <DatePicker
                          label={f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_STARTDATE_DATEPICKER_LABEL')}
                          value={values.startDate}
                          minDate={startDateDisabled ? null : new Date(new Date().setHours(0, 0, 0, 0))}
                          name={name.startDate}
                          color="secondary"
                          disabled={startDateDisabled || isPlanned}
                          format="yyyy-MM-dd"
                        />
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  {values.recurrenceId !== 'OneTime' && (
                    <ModalSection title={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW2_TITLE')} subtitle={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW2_SUBTITLE')}>
                      <ModalSectionRow width={'50%'}>
                        <TimePicker
                          label={f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TIMEFROM_TIMEPICKER_LABEL')}
                          value={values.fromTime}
                          ampm={false}
                          disabled={isPlanned}
                          onChange={handleChangeFromTime}
                          slotProps={{
                            textField:{
                              variant:'filled',
                              error:touched && !!errors?.fromTime,
                              helperText:touched && errors?.fromTime
                            }
                          }}
                          localeText={{
                            toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                        <TimePicker
                          label={f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TIMETO_TIMEPICKER_LABEL')}
                          value={values.toTime}
                          ampm={false}
                          disabled={isPlanned}
                          onChange={handleChangeToTime}
                          slotProps={{
                            textField:{
                              variant:'filled',
                              error:touched && !!errors?.toTime,
                              helperText:touched && errors?.toTime
                            }
                          }}
                          localeText={{
                            toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection title={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW3_TITLE')} subtitle={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW3_SUBTITLE')}>
                    <ModalSectionRow>
                      <FilledTextField
                        label={f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_FROM_START_TEXTFIELD_LABEL')}
                        name={name.alertFromStartInMinutes}
                        color="secondary"
                      />
                    </ModalSectionRow>
                  </ModalSection>

                  {values.recurrenceId !== 'OneTime' && (
                    <ModalSection title={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW4_TITLE')} subtitle={f('ACTIVITY_DIALOG_CONTAINER_INFOTEXT_ROW4_SUBTITLE')}>
                      <ModalSectionRow>
                        <FilledSelect disabled={isPlanned} name={name.recurrenceInterval} value={values.recurrenceInterval} onChange={handleChangeRecurrenceInterval}>
                          {recurrenceInterval.map(num => (
                            <MenuItem key={`recurrenceInterval-${num}`} value={num + 1}>
                              {f('RECURRANCE_INTERVAL_' + (num + 1).toString())}
                            </MenuItem>
                          ))}
                        </FilledSelect>
                        <FilledSelect disabled={isPlanned} name={name.recurrenceId} value={values.recurrenceId} onChange={handleChangeRecurrenceId}>
                          {recurrenceId.map(id => (
                            <MenuItem key={`recurrenceId-${id}`} value={id} disabled={isPlanned}>
                              {f('CREATE_ACTIVITY_' + id.toUpperCase())}
                            </MenuItem>
                          ))}
                        </FilledSelect>
                      </ModalSectionRow>
                      {values.recurrenceId === 'Weekly' && !isPlanned && (
                        <>
                          <ModalSectionRow>
                            <BigCheckbox name={name.Monday} label={f('BASIC_DATE_DAY_MONDAY_TEXT').substring(0, 2)} value="Monday" />
                            <BigCheckbox name={name.Tuesday} label={f('BASIC_DATE_DAY_TUESDAY_TEXT').substring(0, 2)} value="Tuesday" />
                            <BigCheckbox name={name.Wednesday} label={f('BASIC_DATE_DAY_WEDNESDAY_TEXT').substring(0, 2)} value="Wednesday" />
                            <BigCheckbox name={name.Thursday} label={f('BASIC_DATE_DAY_THURSDAY_TEXT').substring(0, 2)} value="Thursday" />
                            <BigCheckbox name={name.Friday} label={f('BASIC_DATE_DAY_FRIDAY_TEXT').substring(0, 2)} value="Friday" />
                            <BigCheckbox name={name.Saturday} label={f('BASIC_DATE_DAY_SATURDAY_TEXT').substring(0, 2)} value="Saturday" />
                            <BigCheckbox name={name.Sunday} label={f('BASIC_DATE_DAY_SUNDAY_TEXT').substring(0, 2)} value="Sunday" />
                          </ModalSectionRow>
                          {weekdaysErrorText !== undefined && <FormHelperText error={true}>{weekdaysErrorText}</FormHelperText>}
                        </>
                      )}
                      {values.recurrenceId === 'Monthly' && !isPlanned && (
                        <ModalSectionRow>
                          <RadioButton name={name.monthlyDayId}>
                            <FormControlLabel
                              value="MonthlyDateInterval"
                              control={<Radio />}
                              label={f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_MONTHLYDATEINTERVAL_TEXT', {
                                prefix: format(values.startDate, 'd'),
                                recurrenceInterval: values.recurrenceInterval,
                              })}
                              disabled={isPlanned}
                            />
                            <FormControlLabel
                              value="MonthlyDayInterval"
                              control={<Radio />}
                              label={f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_MONTHLYDAYINTERVAL_TEXT', {
                                prefix: getWeekOfMonthDateAsString(f)(values.startDate),
                                suffix: format(values.startDate, 'eeee'),
                                recurrenceInterval: values.recurrenceInterval,
                              })}
                              disabled={isPlanned}
                            />
                          </RadioButton>
                        </ModalSectionRow>
                      )}
                      <ModalSectionRow>
                        <Typography>
                          {generateActivityStatusText(f)(statusProps)}
                          {String.fromCharCode(160)}
                          {!!values.endDate && (
                            <>
                              <span style={{ cursor: 'pointer', color: '#147EFB' }} onClick={toggleDatePickerEndDate(true)}>
                                {f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_ENDDATE_TEXT', {
                                  recurrenceInterval: values.recurrenceInterval,
                                  endDate: formatDate(values.endDate),
                                })}
                                &#x25BE;
                              </span>
                              <span
                                style={{
                                  cursor: 'pointer',
                                  color: '#147EFB',
                                  marginLeft: '10px',
                                }}
                                onClick={clearEndDate(setFieldValue)}>
                                {f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_DELETE_ENDDATE_TEXT')}
                              </span>
                            </>
                          )}
                          {!values.endDate && (
                            <span style={{ cursor: 'pointer', color: '#147EFB' }} onClick={toggleDatePickerEndDate(true)}>
                              {f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_TYPOGRAPHY_CHOOSE_ENDDATE_TEXT')}
                            </span>
                          )}
                        </Typography>
                        <div style={endDateStyle}>
                          <DatePicker
                            label={f('ACTIVITY_DIALOG_CONTAINER_RECURRINGSECTION_FORM_ENDDATE_DATEPICKER_LABEL')}
                            minDate={minEndDate}
                            open={isOpen}
                            onOpen={toggleDatePickerEndDate(true)}
                            onClose={toggleDatePickerEndDate(false)}
                            value={values.endDate}
                            name={name.endDate}
                            color="secondary"
                          />
                        </div>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_NORMAL')}`}
                          name={name.severityLevelId}
                          value={SeverityLevelEnum.Normal}
                          checkedByValue
                          returnValue="value"
                        />
                        <BigCheckbox
                          label={`${f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_SEVERITYLEVEL_CRITICAL')}`}
                          name={name.severityLevelId}
                          value={SeverityLevelEnum.Critical}
                          checkedByValue
                          returnValue="value"
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          data-matomo-mask
                          name={name.description}
                          label={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL')}
                          color="secondary"
                          fullWidth
                          multiline
                          rows={5}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_AREA')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_AREA')}>
                    <ModalSectionRow>
                      <Box width="80%">
                        <FilledTextField
                          data-matomo-mask
                          label={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_AREA')}
                          color="secondary"
                          fullWidth
                          multiline
                          rows={5}
                          disabled
                          value={
                            selectedTemplateActivityInheritance?.description
                              ? selectedTemplateActivityInheritance?.description
                              : !selectedTemplateActivityInheritance
                                ? selectedTemplateActivity?.description
                                  ? selectedTemplateActivity?.description
                                  : ''
                                : ''
                          }
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {selectedTemplateActivityInheritance && selectedTemplateActivityInheritance.useTemplateDescription && (
                    <ModalSection
                      title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_TITLE_ORG')}
                      subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_INFOTEXT_SUBTITLE_ORG')}>
                      <ModalSectionRow>
                        <Box width="80%">
                          <FilledTextField
                            data-matomo-mask
                            label={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL_ORG')}
                            color="secondary"
                            fullWidth
                            multiline
                            rows={5}
                            disabled
                            value={selectedTemplateActivity?.description ? selectedTemplateActivity?.description : ''}
                          />
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_INFOTEXT_TITLE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_INFOTEXT_SUBTITLE')}>
                    <ModalSectionRow>
                      <div>
                        <ColorPicker name={name.color} />
                      </div>
                    </ModalSectionRow>
                  </ModalSection>
                  <ModalSection
                    title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_SHOW_ON_DEVICE')}
                    subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_COLOR_SHOW_ON_DEVICE_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('BASIC_TEXT_YES')}`}
                          name={name.showColorOnDevice}
                          value="true"
                          checkedByValue
                          returnValue="value"
                          disabled={isPlanned}
                        />
                        <BigCheckbox
                          label={`${f('BASIC_TEXT_NO')}`}
                          name={name.showColorOnDevice}
                          value="false"
                          checkedByValue
                          returnValue="value"
                          disabled={isPlanned}
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  {selectedTemplateActivity?.useParticipantList && (
                    <ModalSection
                      title={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS')}
                      subtitle={f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_PARTICIPANTS_SUBTITLE')}>
                      <ModalSectionRow>
                        <Box width="80%" data-matomo-mask>
                          <ActivityParticipants
                            activityParticipants={values.activityParticipants}
                            availableCustomers={customers}
                          />
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}
              </ModalContent>
              <ModalFooter>
                <TextButton type="button" onClick={closeModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} disabled={!dirty || isSubmitting}>
                    {!selectedActivity ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export const MemoizedActivityCreateModalContainer = React.memo(ActivityCreateModalContainer);
export default MemoizedActivityCreateModalContainer
