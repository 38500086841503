import ReportsPageSubmitSectionView from '../components/reports-page-submit-section'
import ReportsPageInfoSectionView from '../components/reports-page-info-section'
import ReportsPageSelectionsSectionView from '../components/reports-page-selections-section'
import ReportsPageFieldsSectionView from '../components/reports-page-fields-section'
import ReportsPageAvailabilitiesView from '../components/reports-page-availabilities'
import ReportsPageSelectionsActionBarView from '../components/reports-page-selections-action-bar'
import ReportsSaveAsView from '../components/reports-save-as'
import type { ReportSection } from './report-types'

const dashboardSectionConfig: ReportSection[] = [
  {
    id: 1,
    name: 'Info',
    existIf: ['isBaseReport'],
    InnerContainer: ReportsPageInfoSectionView
  },
  {
    id: 6,
    name: 'NameAndDescription',
    existIf: ['isUserReport', 'isAreaReport', 'isOrganizationReport'],
    InnerContainer: ReportsPageInfoSectionView
  },
  {
    id: 2,
    name: 'Selections',
    ActionBar: ReportsPageSelectionsActionBarView,
    InnerContainer: ReportsPageSelectionsSectionView
  },
  {
    id: 3,
    name: 'Columns',
    InnerContainer: ReportsPageFieldsSectionView
  },
  {
    id: 4,
    name: 'Availabilities',
    existIf: ['isUserReport', 'isAreaReport'],
    InnerContainer: ReportsPageAvailabilitiesView
  },
  {
    id: 5,
    name: 'Submit',
    ActionBar: ReportsPageSubmitSectionView,
    InnerContainer: ReportsSaveAsView
  },
]

export { dashboardSectionConfig }
