import CenterModal from 'components/center-modal'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import { HangfireModalContext } from './hangfire-modal-context'
import { HangfireModalView } from './hangfire-modal-view'

import React from 'react'

export const HangfireContainer: React.SFC = () => {
  const { hangfireModal } = ProfilePageContext.useState()
  const { toggleModalHangfire } = ProfilePageContext.useActions()

  const handleClose = React.useCallback(() => toggleModalHangfire(false), [
    toggleModalHangfire,
  ])

  return (
    <CenterModal open={hangfireModal} onClose={handleClose}>
      <HangfireModalContext.Provider>
        <HangfireModalView />
      </HangfireModalContext.Provider>
    </CenterModal>
  )
}
