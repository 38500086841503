import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'

interface IEditCommentModel {
  comment: string
}

const useEditCommentModalFormData = () => {
  const f = useFormatMessage()
  const { editCommentModalData } = PlanningPageContext.useState()
  const {
    onCloseEditCommentModal,
    updateDetachedComment,
  } = PlanningPageContext.useActions()

  const initialValues: IEditCommentModel = React.useMemo(
    () => ({
      comment:
        editCommentModalData && editCommentModalData.comment
          ? editCommentModalData.comment
          : '',
    }),
    [editCommentModalData]
  )

  const onSubmit: OnSubmitFunction<IEditCommentModel> = React.useCallback(
    (values, { setSubmitting }) => {
      if (editCommentModalData?.id) {
        updateDetachedComment(editCommentModalData.id, values.comment)
      }
      onCloseEditCommentModal()
      setSubmitting(false)
    },
    [onCloseEditCommentModal, editCommentModalData, updateDetachedComment]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<IEditCommentModel>({
        comment: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('PLANNING_PAGE_SCHEDULE_VIEW_EDITCOMMENT_FORM_NAME_LABEL'),
            maxsize: 1024,
          })
        ),
      }),
    [f]
  )

  const name: Name<IEditCommentModel> = React.useMemo(
    () => ({
      comment: 'comment',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useEditCommentModalFormData
