import { Box } from '@mui/material'
import { DataGrid, GridRowParams, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { RoleEnum, UserListItem, UserAreaItem } from 'api/tillit.api-client'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import { useFormatMessage } from 'localization'
import React, { FC } from 'react'
import useAuthorization from 'utilities/use-authorization'
import { makeStyles } from '@mui/styles'
import { Authorize } from 'components/authorization'
import { uniqueAndSortedUserAreas } from 'utilities/user-helper'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterUsers } from 'utilities/user-helper'
import { UsersPageContext } from './users-page-context'
import UserAreas from './components/user-areas-container'
import { SendEmailToUsersModalContext } from './components/send-email-to-users-modal/send-email-to-users-modal-context';
import SendEmailToUsersModal from './components/send-email-to-users-modal';

const useStyles = makeStyles({
  'users-container': {
    padding: '2vh 2vh 0 2vh'
  },
});


const UsersInOrganizationView: FC = () => {
  const { hasRoleOnAnyArea } = useAuthorization()
  const { openSendEmailToUsersModal } = SendEmailToUsersModalContext.useActions()
  const { modalOpen } = SendEmailToUsersModalContext.useState()
  const classes = useStyles();

  const { organizationUsers } = UsersPageContext.useState()

  const { fetchOrganizationUsersAsync } = UsersPageContext.useActions()
  const f = useFormatMessage()
  const getFilteredUsers = filterUsers(f)
  const { open: openUserDialog } = UserDialogContext.useActions()

  const handleRowClick = React.useCallback(
    async (params: GridRowParams) => {
      openUserDialog(params.row.id, true) //calledFromOtherPersonell
    },
    [openUserDialog]
  )

  const userAreasLookup: any[] = []
  organizationUsers?.forEach(user => {
    user.userAreas?.forEach(userArea => {
      if (!userAreasLookup[userArea.areaId]) {
        userAreasLookup[userArea.areaId] = userArea.areaName
      }
    })
  })

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredUsers, setFilteredUsers] = React.useState<UserListItem[] | null>(organizationUsers)

  React.useEffect(() => {
    if (!organizationUsers) {
        fetchOrganizationUsersAsync()
    }

    if (filterValue) {
      const filtered = getFilteredUsers(filterValue, organizationUsers)
      setFilteredUsers(filtered)
    } else {
      setFilteredUsers(organizationUsers)
    }
    // NOTE: We are missing filterValue and getFilteredUsers as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [organizationUsers])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredUsers(searchValue, organizationUsers)
      setFilteredUsers(filtered)
    },
    [setFilterValue, setFilteredUsers, organizationUsers, getFilteredUsers]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredUsers(organizationUsers)
    },
    [setFilterValue, setFilteredUsers, organizationUsers]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div className={classes['users-container']}>

      <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Box sx={{
            display: 'flex',
            flex: '50%',
          }}>
          </Box>
          <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
            <FilledSearchTextField
              label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
              handleChangeFilter={handleChangeFilter}
              handleClearFilter={handleClearFilter}
              searchValue={filterValue}
            />
          </div>
        </Box>
      </Authorize>
      <DataGrid
        disableColumnFilter
        sx={{
          minHeight: '70vh',
          maxHeight: '70vh',
          '.MuiDataGrid-cell': { borderBottom: '0px' }
        }}
        rows={filteredUsers as any}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        onRowClick={handleRowClick}
        localeText={muiDataGridLocale(f)()}

        columns={[
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_FIRSTNAME'),
            field: 'firstName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.firstName}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_LASTNAME'),
            field: 'lastName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.lastName}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_EMAIL'),
            field: 'email',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.email}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_AREAS_TITLE'),
            field: 'userAreas',
            valueGetter: (params: GridValueGetterParams) => {
              const userAreas = params.value as UserAreaItem[]
              return !!userAreas && uniqueAndSortedUserAreas(userAreas).map(ua => ua).join(', ')
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span>
                  <UserAreas user={params.row} />
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_INFO'),
            field: 'active',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span>
                  {params.row.active ? '' : f('USERS_PAGE_VIEW_COLUMN_INFO_INACTIVE')}
                </span>
              )
            },
          },
        ]}

        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[25, 50, 100]}
        pagination

      />

      {modalOpen && (
        <SendEmailToUsersModal />
      )}
    </div>
  )
}

export default UsersInOrganizationView
