import ContainedButton from 'components/inputs/contained-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import PageRoute from 'config/page-routes'
import { useFormatMessage } from 'localization'
import React from 'react'
import { Link } from 'react-router-dom'

const SwitchDeviceModeView: React.FC = () => {
  const f = useFormatMessage()

  return (
    <ModalSection
      title={f('SWITCH_DEVICE_MODE_DASHBOARD_SECTION_TITLE')}
      subtitle={f('SWITCH_DEVICE_MODE_DASHBOARD_SECTION_SUBTITLE')}
      responsive>
      <ModalSectionRow responsive>
        <Link
          to={PageRoute.DailyActivities.path}
          style={{ textDecoration: 'none' }}>
          <ContainedButton color="secondary">
            {f('SWITCH_DEVICE_MODE_DASHBOARD_SECTION_BUTTON_TEXT')}
          </ContainedButton>
        </Link>
      </ModalSectionRow>
    </ModalSection>
  )
}

export default SwitchDeviceModeView
