import React from 'react'
import { Typography } from '@mui/material'
import TextButton from 'components/inputs/text-button'
import CenterModal from 'components/center-modal'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import ModalHeader from 'components/modal-header'
import ModalFooter from 'components/modal-footer'
import ModalContent from 'components/modal-content'
import { useFormatMessage } from 'localization'
import { DailyActivitiesContext } from 'pages/daily-activities/daily-activities-context'

const InternalInformationModal: React.FC = () => {
  const f = useFormatMessage()
  const { internalInfoModalOpen, data } = DailyActivitiesContext.useState()
  const { handleToggleInternalInformationModal } = DailyActivitiesContext.useActions()


  return (
    <CenterModal open={internalInfoModalOpen} onClose={handleToggleInternalInformationModal} responsive>
      <ModalHeader title={f('DAILY_ACTIVITIES_MODAL_INTERNAL_INFORMATION_TITLE')} responsive m={1} />
      <ModalContent style={{paddingLeft:'50px', paddingRight:'50px', paddingBottom:'50px'}}>
      {data?.organizationInternalInformation && data.organizationInternalInformation.length > 0 && (
            <Typography variant="body2" p={1} sx={{lineBreak:'normal'}}>
              {data?.organizationInternalInformation}
            </Typography>
      )}
      {data?.areaInternalInformation && data.areaInternalInformation.length > 0 && (
            <Typography variant="body2" p={1} sx={{lineBreak:'normal'}}>
              {data?.areaInternalInformation}
            </Typography>
      )}
      </ModalContent>
      <ModalFooter>
        <TextButton type="button" color="secondary" onClick={handleToggleInternalInformationModal}>
          {f('BASIC_BUTTON_CLOSE_TEXT')}
        </TextButton>
      </ModalFooter>
    </CenterModal>
  )
}




export default InternalInformationModal
