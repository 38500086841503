import { Box, Button, useTheme } from '@mui/material'
import { IPlannedRoutesActivitiesPlanningViewItem, IRouteCreateItem, PlannedActivityStatusEnum, PlannedRoutePlanningViewItem } from 'api/tillit.api-client'
import ActivityCreateModal from 'components/activity-create-modal'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import Authentication from 'components/authentication'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { areaBackgroundColor } from 'pages/planning/planning-page-styles'
import React from 'react'
import styled from 'styled-components'
import format from 'utilities/format'
import { addDays, isSameDay, startOfDay } from 'date-fns'
import CreateRouteModal from '../create-route-modal'
import EditRouteModal from '../edit-route-modal'
import EditUserWorkScheduleModal from '../edit-user-workschedule-modal'
import CreateUserWorkScheduleModal from '../create-user-workschedule-modal'
import { CreateUserWorkScheduleModalContext } from '../create-user-workschedule-modal/create-user-workschedule-modal-context'
import { EditUserWorkScheduleModalContext } from '../edit-user-workschedule-modal/edit-user-workschedule-modal-context'
import SearchCustomer from '../modal-search-customer'
import InfoActivity from '../popover-info-activity'
import { IRouteMenuOption } from '../route-menu/route-menu-options'
import Schedule from '../schedule'
import { StyledRouteButtonWrapper } from '../schedule/schedule-styles'
import { ICellData, ISchedule } from '../schedule/schedule-view'
import TemporaryActivityCreateModal from '../temporary-activity-create-modal'
import { TemporaryActivityCreateModalContext } from '../temporary-activity-create-modal/temporary-activity-create-modal-context'
import DeleteDialogContainer from './delete-dialog/delete-dialog-container'
import DeleteRouteInvalidDialogContainer from './delete-route-invalid-dialog/delete-route-invalid-dialog-container'
import { RouteIsRemovedDialogContainer } from './route-is-removed-dialog'

interface IRouteCreateItemOnEmptyRoutes {
  sortingIndex: number
  areaId: number
  plannedRoutes?: PlannedRoutePlanningViewItem[] | undefined
}

export interface IPlanningContainer {
  id: number
  activityName?: string
  startTime: Date
  endTime: Date
  plannedRouteId: number
  activityRowId: number
  startHour: number
  startMinute: number
  endHour: number
  endMinute: number
  plannedActivityStatusId: PlannedActivityStatusEnum
  activityColor: string
  visitLengthInMinutes: number
  ownerCustomerFirstName?: string | undefined
  ownerCustomerLastName?: string | undefined
  templateActivityTitle?: string
}

export const StyledAreaContainer = styled(Box)`
  background-color: ${areaBackgroundColor};
`

const AreaContainer: React.FC = () => {
  const theme = useTheme()
  const { reloadUser } = Authentication.useActions()
  const { routes = [], planningId, isLoading, calendarStartDate } = PlanningPageContext.useState()
  const { createRouteAsync, removeRouteAsync, openSearchCustomer, onOpenEditRouteModal, onOpenCreateRouteModal, updateRoutePlanningView } = PlanningPageContext.useActions()

  const { customerInfo, modalOpen : activityCreateModalOpen } = ActivityCreateModalContext.useState()
  const f = useFormatMessage()
  const [cellData, setCellData] = React.useState<ICellData | undefined>(undefined)

  const [dialogDelete, setDialogDelete] = React.useState<boolean>(false)
  const [dialogDeleteRouteInvalid, setDialogDeleteRouteInvalid] = React.useState<boolean>(false)
  const [routeIsRemoved, setRouteIsRemoved] = React.useState<boolean>(false)
  const [routeIsRemovedFromTomorrow, setRouteIsRemovedFromTomorrow] = React.useState<boolean>(false)
  const [selectedRoute, setSelectedRoute] = React.useState<IPlannedRoutesActivitiesPlanningViewItem | undefined>(undefined)

  const handleAddRouteOnEmptyScheduleRoutes = () => {
    return handleAddRoute('left', {
      sortingIndex: 0,
      areaId: planningId,
    }).then(() => {
      return reloadUser() // Get updated user areas
    })
  }
  const handleAddRoute = React.useCallback(
    (position: 'left' | 'right', route: IPlannedRoutesActivitiesPlanningViewItem | IRouteCreateItemOnEmptyRoutes) => {
      // Let's set the startdate for the route to adjacent route plannedRoute calendar date (which should correspond to the "chosen" date)
      // If there are no plannedRoutes... well then we set todays day.
      let routeStartDate: Date = new Date()
      if (route && route.plannedRoutes && route.plannedRoutes[0]) {
        routeStartDate = route.plannedRoutes[0].calendarDay
        if (route.plannedRoutes[1] && route.plannedRoutes[1].calendarDay > routeStartDate ) {
          routeStartDate = route.plannedRoutes[1].calendarDay
        }
      }

      const model: IRouteCreateItem = {
        name: 'Ny tur',
        color: '#000000',
        sortingIndex: position === 'left' ? route.sortingIndex! : route.sortingIndex! + 1,
        areaId: route.areaId,
        startDate: routeStartDate,
      }
      return createRouteAsync(model)
    },
    [createRouteAsync]
  )

  const handleOpenEditRouteModal = React.useCallback(
    (route: IPlannedRoutesActivitiesPlanningViewItem) => {
      onOpenEditRouteModal(route, false)
    },
    [onOpenEditRouteModal]
  )

  const handleOpenCreateRouteModal = React.useCallback(
    (position: 'left' | 'right', route: IPlannedRoutesActivitiesPlanningViewItem) => {
      onOpenCreateRouteModal(position, route)
    },
    [onOpenCreateRouteModal]
  )

  const handleClickMenuOptionParent = React.useCallback(
    (name: IRouteMenuOption['name'], route: IPlannedRoutesActivitiesPlanningViewItem) => {
      setSelectedRoute(route)
      switch (name) {
        case 'addRight':
          return handleOpenCreateRouteModal('right', route)
        case 'addLeft':
          return handleOpenCreateRouteModal('left', route)
        case 'edit':
          return handleOpenEditRouteModal(route)
        case 'remove':
          // Trying to remove route for today or earlier? Not possible! User get a choice to delete from tomorrow

          if (route.endDate) {
            const today = new Date()
            const tomorrow = addDays(startOfDay(today), 1)
            if (isSameDay(route.endDate, tomorrow)) {
              setRouteIsRemovedFromTomorrow(true)
            }
            setRouteIsRemoved(true)
          }
          else {
            let routeStartDate: Date = new Date()
            if (route && route.plannedRoutes && route.plannedRoutes[0]) {
              routeStartDate = route.plannedRoutes[0].calendarDay
              if (route.plannedRoutes[1] && route.plannedRoutes[1].calendarDay > routeStartDate ) {
                routeStartDate = route.plannedRoutes[1].calendarDay
              }

              if (format(routeStartDate, 'yyyy-MM-dd') <= format(new Date(), 'yyyy-MM-dd')) {
                setDialogDeleteRouteInvalid(true)
                return
              }
            }

            if (route.hasFuturePlannedActivities) {
              setDialogDelete(true)
            } else {
              removeRouteAsync(route)
            }
            return
          }
          return
        default:
          return
      }
    },
    [handleAddRoute, handleOpenEditRouteModal, removeRouteAsync]
  )

  const handleDoubleClick: ISchedule['onDoubleClick'] = React.useCallback(
    props => {
      const { routeId, startTime } = props
      openSearchCustomer({ status: true, type: 'defaultCommon' })
      setCellData({
        startTime,
        routeId,
      })
    },
    [openSearchCustomer]
  )

  const scheduleRoutes = React.useMemo(()=>{
    return routes.filter(r => !r.hidden)
  },[routes])

  return (
    <StyledAreaContainer pl={1} pt={1} display="flex" flex={1}>
      {isLoading && routes.length === 0 ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : routes.length > 0 ? (
        <>
          <InfoActivity /> {/**We need to refactor this and put it in the activity card. */}
          <TemporaryActivityCreateModalContext.Provider>
              <SearchCustomer />
              {activityCreateModalOpen && customerInfo && (
                <ActivityCreateModal
                  propsTitle={f('ACTIVITY_DIALOG_CONTAINER_HEADER_TITLE')}
                  propsSubtitle={(customerInfo && customerInfo.fullName) || ''}
                  onSave={updateRoutePlanningView}
                />
              )}
            {cellData && <TemporaryActivityCreateModal timeData={cellData} />}
          </TemporaryActivityCreateModalContext.Provider>
          <Schedule type="areas" onDoubleClick={handleDoubleClick} routes={scheduleRoutes} handleClickMenuOptionParent={handleClickMenuOptionParent} />
          <CreateRouteModal />
          <EditRouteModal />
          <EditUserWorkScheduleModalContext.Provider>
            <EditUserWorkScheduleModal />
          </EditUserWorkScheduleModalContext.Provider>
          <CreateUserWorkScheduleModalContext.Provider>
            <CreateUserWorkScheduleModal />
          </CreateUserWorkScheduleModalContext.Provider>
        </>
      ) : (
        <StyledRouteButtonWrapper>
          <Button variant="contained" color="primary" onClick={handleAddRouteOnEmptyScheduleRoutes}>
            {f('PLANNING_PAGE_SCHEDULE_VIEW_CREATE_ROUTE_BUTTON_TEXT')}
          </Button>
        </StyledRouteButtonWrapper>
      )}
      <RouteIsRemovedDialogContainer
      calendarStartDate={calendarStartDate}
      routeIsRemovedFromTomorrow={routeIsRemovedFromTomorrow}
      routeIsRemoved={routeIsRemoved}
      setRouteIsRemovedFromTomorrow={setRouteIsRemovedFromTomorrow}
      setRouteIsRemoved={setRouteIsRemoved}
      selectedRoute={selectedRoute}
      />
      <DeleteDialogContainer dialogDelete={dialogDelete} setDialogDelete={setDialogDelete} selectedRoute={selectedRoute} />
      <DeleteRouteInvalidDialogContainer
        dialogDeleteRouteInvalid={dialogDeleteRouteInvalid}
        setDialogDeleteRouteInvalid={setDialogDeleteRouteInvalid}
        selectedRoute={selectedRoute}
      />
    </StyledAreaContainer>
  )
}

export default AreaContainer
