import { useTheme } from '@mui/material'
import { IAreaOrgTreeItem } from 'api/tillit.api-client'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import React from 'react'
import styled, { css } from 'styled-components'

type Props = Readonly<{
  isOpen: boolean
  item: IAreaOrgTreeItem | null
  isLoading?: boolean
  className?: string
  onCloseClick?: () => void
  onRemoveClick?: () => void
}>

const SidebarView: React.FC<Props> = ({ item, isOpen, isLoading = false, className, onCloseClick, onRemoveClick }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  return (
    <div className={className}>
      <div>
        {isLoading ? (
          <Spinner color={theme.palette.secondary.main} />
        ) : (
          <>
            <ModalHeader title={(item && item.name) || '\u00A0'} subtitle={(item && item.parentName) || '\u00A0'} />
            <FormWrapper>
              <ModalSection
                title={f('ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_INFOTEXT_TITLE')}
                subtitle={f('ORGANIZATION_PAGE_SIDE_BAR_VIEW_AREASECTION_INFOTEXT_SUBTITLE')}>
                <ModalSectionRow>
                  <FilledTextField
                    fullWidth
                    label={'Title'}
                    value={(item && item.name) || '\u00A0'}
                    color="secondary"
                  />
                </ModalSectionRow>
              </ModalSection>
            </FormWrapper>
          </>
        )}
      </div>
      <ModalFooter>
        <TextButton onClick={onCloseClick}>{f('BASIC_BUTTON_CANCEL_TEXT')}</TextButton>
        <ContainedButton color="error" type="button" onClick={onRemoveClick} disabled={isLoading}>
          {f('BASIC_BUTTON_REMOVE_TEXT')}
        </ContainedButton>
      </ModalFooter>
    </div>
  )
}

const FormWrapper = styled.div`
  ${({ theme }) => `
    padding: 0 ${theme.spacing(5)};
  `}
`

export default SidebarView
