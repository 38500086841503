import React from 'react'
import DashboardKpiView from './dashboard-kpi-view'
import DashboardKpiContext from './dashboard-kpi-context'

const DashboardKpiContainer: React.FC = () => {

  return (
    <DashboardKpiContext.Provider>
      <DashboardKpiView />
    </DashboardKpiContext.Provider>
  )
}

export default DashboardKpiContainer
