import React from 'react'

import {
  AreasClient,
  AreaListItem,
  PageResultOfAreaListItem,
  AreaItem,
  AreaUpdateItem,
  IAreaUpdateItem,
} from 'api/tenilo.support.api-client'
import { SUPPORT_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class SupportAreasApi {
  private readonly abortController = new AbortController()
  private readonly client: AreasClient

  public constructor() {
    this.client = new AreasClient(SUPPORT_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getArea = (areaId: number): Promise<AreaItem> =>
    this.client
      .getArea(areaId)
      .then(areaItem =>
        areaItem === null
          ? Promise.reject(`No area item received from backend for area id ${areaId}.`)
          : Promise.resolve(areaItem)
      )

  public listAreas = (page: number, pageSize: number, organizationId: number | null, areaId: number | null): Promise<PageResultOfAreaListItem> =>
    this.client
      .list(page, pageSize, organizationId, areaId)
      .then(areas =>
        areas === null
          ? Promise.reject(`No areas received from backend.`)
          : Promise.resolve(areas)
      )

  public updateArea = (area: IAreaUpdateItem) => {
    const model = new AreaUpdateItem(area)
    return this.client
      .updateArea(model)
      .then(areaItem =>
        areaItem === null ? Promise.reject(`When updating an area, no area item received from backend.`) : Promise.resolve(areaItem)
      )
  }

  public anonymizeArea = (areaId: number) => {
    return this.client
      .anonymizeArea(areaId)
      .then(areaItem =>
        areaItem === null ? Promise.reject(`When anonymizing an area, no organization item received from backend.`) : Promise.resolve(areaItem)
      )
  }

}

const useSupportAreasApi = () => React.useMemo(() => new SupportAreasApi(), [])

export default useSupportAreasApi
