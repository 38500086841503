export const API_URL = process.env.REACT_APP_API_URL
export const WORKSCHEDULE_API_URL = process.env.REACT_APP_WORKSCHEDULE_API_URL
export const DASHBOARD_API_URL = process.env.REACT_APP_DASHBOARD_API_URL
export const REPORTING_API_URL = process.env.REACT_APP_REPORTING_API_URL
export const SUPPORT_API_URL = process.env.REACT_APP_SUPPORT_API_URL
export const HANGFIRE_URL = process.env.REACT_APP_HANGFIRE_URL
export const ROUTEPREFIX = process.env.REACT_APP_ROUTE_PREFIX
export const STORAGEPREFIX = process.env.REACT_APP_STORAGE_PREFIX
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL
export const APP_VERSION = process.env.REACT_APP_VERSION
export const PWA_URL = process.env.REACT_APP_PUBLIC_PWA_URL
