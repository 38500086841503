/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import React from 'react'

type Function<S, A, P> = (props: P) => { state: S; actions: A }

export function createContext<S, A, P extends object>(
  useHook: Function<S, A, P>,
  identification?: string
) {
  const StateContext = React.createContext<S | undefined>(undefined)
  const ActionsContext = React.createContext<A | undefined>(undefined)

  function Provider(props: React.PropsWithChildren<P>) {
    const { children, ...rest } = props
    const { state, actions } = useHook(rest as P)
    const memoizedState = React.useMemo(()=> state,[state])
    const memoizedActions = React.useMemo(()=> actions,[actions])
    return (
      <StateContext.Provider value={memoizedState}>
        <ActionsContext.Provider value={memoizedActions}>
          {children}
        </ActionsContext.Provider>
      </StateContext.Provider>
    )
  }

  const useState = () => {
    const context = React.useContext(StateContext)
    if (context === undefined) {
      throw new Response(`Missing Provider for useState ${identification}`, { status: 409,statusText:'contextError'})
    }
    return context
  }

  const useActions = () => {
    const context = React.useContext(ActionsContext)
    if (context === undefined) {
      throw new Response(`Missing Provider for useActions ${identification}`, { status: 409,statusText:'contextError'})
    }
    return context
  }

  const StateConsumer: React.SFC<{
    children: ({ state }: { state: S }) => React.ReactNode
  }> = ({ children }) => {
    return (
      <StateContext.Consumer>
        {state => {
          if (state === undefined) {
            throw Error('Missing Provider for StateConsumer')
          }
          return children({ state })
        }}
      </StateContext.Consumer>
    )
  }
  const ActionsConsumer: React.SFC<{
    children: ({ actions }: { actions: A }) => React.ReactNode
  }> = ({ children }) => {
    return (
      <ActionsContext.Consumer>
        {actions => {
          if (actions === undefined) {
            throw Error('Missing Provider for ActionsConsumer')
          }
          return children({ actions })
        }}
      </ActionsContext.Consumer>
    )
  }

  return {
    Provider,
    useState,
    useActions,
    StateContext,
    StateConsumer,
    ActionsContext,
    ActionsConsumer,
  }
}
