import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledRouteButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const StyledAppointment = styled('div')`
  position: relative;
  white-space: pre-wrap;
  background: ${props => props.color};
  height: 100%;

  .title {
    margin: 0;
  }

  .pen-mark {
    position: absolute;
    top: -3px;
    right: 10px;
    background-color: #147efb;
  }
`
