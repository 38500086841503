import React from 'react'
import { Box, Typography } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import TextButton from 'components/inputs/text-button'
import CenterModal from 'components/center-modal'
import ModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import { DailyActivitiesContext } from 'pages/daily-activities/daily-activities-context'
import styled from 'styled-components'
import { getHoursAndMinutes } from 'utilities/date-time-helpers'
import { History, Update } from 'mdi-material-ui'

const WorkScheduleModal: React.FC = () => {
  const f = useFormatMessage()
  const { workSchedules, locked, user } = DailyActivitiesContext.useState()
  const { closeWorkScheduleDialog } = DailyActivitiesContext.useActions()


  return (
    <CenterModal open={workSchedules.openModal && !locked} onClose={closeWorkScheduleDialog} responsive>
      <ModalHeader title={f('DAILY_ACTIVITIES_MODAL_WORKSCHEDULE_TITLE')} responsive m={2} />
      <ModalBody>
        {
          isEmpty(workSchedules.list) ? <Typography variant="subtitle1" sx={{borderBottom:'0 !important'}}> {f('DAILY_ACTIVITIES_MODAL_WORKSCHEDULE_EMPTY_TEXT')} </Typography> :
            <>
              <Typography variant="h6"> {f('DAILY_ACTIVITIES_MODAL_PERSONNEL_TITLE')} </Typography>
              <Typography variant="h6"> {f('DAILY_ACTIVITIES_MODAL_ROUTE_TITLE')} </Typography>
              <Typography variant="h6"> {f('DAILY_ACTIVITIES_MODAL_FROM_TITLE')} </Typography>
              <Typography variant="h6"> {f('DAILY_ACTIVITIES_MODAL_TO_TITLE')} </Typography>
              {
                workSchedules.list.map(workSchedule => {
                  const fromPreviousDate = workSchedule.scheduledStart.getTime() < new Date().setHours(0, 0, 0, 0)
                  const toNextDate = workSchedule.scheduledEnd.getTime() > new Date().setHours(23, 59, 59, 0)
                  let isMySchedule = false
                  if (workSchedule.substituteUserId && workSchedule.substituteUserId === user?.id) {
                    isMySchedule = true;
                  }
                  if (workSchedule.userId === user?.id && !workSchedule.substituteUserId) {
                    isMySchedule = true;
                  }
                  const fWeight = isMySchedule ? 700 : 400;
                  return (
                    <>
                      <Typography data-matomo-mask variant="subtitle1" fontWeight={fWeight}> {workSchedule.substituteUserId ? `${workSchedule.substituteUserFullName} (${f('DAILY_ACTIVITIES_MODAL_SUBSTITUTES_TITLE')} ${workSchedule.userFullName})` : workSchedule.userFullName} </Typography>
                      <Typography data-matomo-mask variant="subtitle1" fontWeight={fWeight}> {workSchedule.routeName} </Typography>
                      <Typography variant="subtitle1" fontWeight={fWeight}>
                        {fromPreviousDate && (
                          <History style={{ height: '2vh', marginTop: '0vh' }} />
                        )}
                        {getHoursAndMinutes(new Date(workSchedule.scheduledStart))}
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={fWeight}>
                        {toNextDate && (
                          <Update style={{ height: '2vh', marginTop: '0vh' }} />
                        )}
                        {getHoursAndMinutes(new Date(workSchedule.scheduledEnd))}
                      </Typography>
                    </>
                  )
                })
              }
            </>
        }
      </ModalBody>
      <ModalFooter>
        <TextButton type="button" color="secondary" onClick={closeWorkScheduleDialog}>
          {f('BASIC_BUTTON_CLOSE_TEXT')}
        </TextButton>
      </ModalFooter>
    </CenterModal>
  )
}

const ModalBody = styled(Box)`
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr;
  padding: ${({ theme }) => theme.spacing(1)};
  > * {
    padding: ${({ theme }) => theme.spacing(2)};
    border-bottom: 1px solid gray;
  }
`

const ModalFooter = styled(Box)`
  display: flex;
  flex: 1;
  align-self: flex-end;
  padding: ${({ theme }) => theme.spacing(2)}
`



export default WorkScheduleModal
