import React from 'react'
import { Box, Typography } from '@mui/material'
import { Chart } from 'react-chartjs-2'
import { isEmpty, sumBy, round} from 'lodash'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, PieController } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { DockWindow, Download, Percent } from 'mdi-material-ui'
import { Colors } from 'styles/colors'
import { useFormatMessage } from 'localization'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'
import type { ChartType } from 'pages/dashboard/config/dashboard-types'
import type { KeyPerformanceIndicatorItem } from 'api/tenilo.dashboard.api-client'
import { KeyPerformanceIndicatorUnit} from 'api/tenilo.dashboard.api-client'
import DashboardKpiDetailView from './dashboard-kpi-detail-wrapper'

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels, PieController);

const plugins = [ChartDataLabels]

const DashboardKpiChartView : React.FC<{
  kpiData:KeyPerformanceIndicatorItem,
  chartType:ChartType,
  forwardRef: React.MutableRefObject<null>,
  detailsRef: React.MutableRefObject<null>,
  zoomCardContent:boolean
  handleZoomCardContent: () => void
  downloadPDF: () => void
  percent: boolean
  handleSetPercent: () => void
  filteredDatasetByLegend: number[]
  handleFilterDatasetByLegend: (dataIndex: number, visibilityStatus: boolean) => void
}> = (props) => {
  const { kpiData, chartType, forwardRef, zoomCardContent, handleZoomCardContent, downloadPDF, detailsRef, percent, handleSetPercent, filteredDatasetByLegend, handleFilterDatasetByLegend } = props
  const { layoutSize } = DashboardPageContext.useState()
  const f = useFormatMessage()

  const chartData = ()=>{
    const filteredKpiDataDetails = kpiData.details?.filter((item,i) => !filteredDatasetByLegend.includes(i))
    return {
      regularData: kpiData.details?.map(detail => round(detail.value,1)),
      percentData: () => {
        const total = sumBy(filteredKpiDataDetails,(item) => item.value)
        return kpiData.details?.map((detail,i) => filteredDatasetByLegend.includes(i) ? 0 : round(((detail.value/total)*100), 1))
      }
    }
  }

  const formatHours = ( decimalHours:number ) => {
    let minutes = decimalHours % 1
    const hours = decimalHours - minutes
    minutes *= 60
    minutes = Math.round(minutes)
    let format = `${hours} ${f('DASHBOARD_PAGE_KPI_VALUE_UNIT_HOURS')} `
    format += minutes ? `${minutes} ${f('DASHBOARD_PAGE_KPI_VALUE_UNIT_MINUTES')}` : ''
    return format
  }

  const getValueFormat = React.useCallback((val:number, context)=>{
    const value = percent ?
      `${val} ${f('DASHBOARD_PAGE_KPI_VALUE_UNIT_PERCENTAGE')}` :
      (kpiData.details && !kpiData.details[context.dataIndex].suppressValueAndUnit) ?
      (kpiData.details[context.dataIndex].valueUnit === KeyPerformanceIndicatorUnit.Hours) ?
      formatHours(val) :
      `${val} ${f(`DASHBOARD_PAGE_KPI_VALUE_UNIT_${kpiData.details[context.dataIndex].valueUnit.toUpperCase()}`)}` :
      ''

    return zoomCardContent ?
      `${context.chart.config.data.labels[context.dataIndex]} ${value}`
      : `${value}`;
  },[zoomCardContent, kpiData, percent])

  const data = {
    labels: kpiData.details?.map(detail => detail.valueTitle),
    datasets: [
      {
        data:percent ? chartData().percentData() : chartData().regularData,
        backgroundColor: [
          '#065357',
          '#A25018',
          '#0B499D',
          '#C32C2C',
          '#4573b4',
          '#572300',
          '#4e4e4e',
          '#930A7E',
          '#6D4AB1',
          '#E26A8C',
          '#569F58',
          '#cc7430',
          '#42B4BA',
          '#a78800',
          '#000000',
        ],
      }
    ]
  }

  const options = {
    responsive: true,
    animation:{
      duration:500
    },
    plugins: {
      title:{
        font: {
          weight:'bold',
          size:'13px'
        },
        display:zoomCardContent,
        text:kpiData.valueTitle
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            return getValueFormat(context.raw, context)
          }
        }
      },
      datalabels: {
        color:Colors.White,
        font: {
          weight: "bold",
          size: zoomCardContent ? '14px' : layoutSize === 'l' ? '12px' : layoutSize === 'm' ? '10px' : '8px'
        },
        formatter: (val:number, context) => {
          return getValueFormat(val, context)
        }
      },
      legend: {
        display: zoomCardContent,
        position: "top" as const,
        title: {
          font: {
            weight:'bold',
            size:'12px'
          },
          display: true,
          text:kpiData.valueDescription
        },
        onClick: (e, legendItem, legend) => {
          legend.chart.toggleDataVisibility(legendItem.index)
          legend.chart.update()
          handleFilterDatasetByLegend(legendItem.index,legendItem.hidden)
        }
      }
    }
  }
  return (
      <Box sx={{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        height:'100%',
        overflow:'hidden'
      }}>
        <Typography color="primary" variant="subtitle1" sx={{
          fontSize:layoutSize === 'l' ? '16px' : layoutSize === 'm' ? '14px' : '12px',
          lineHeight:1,
          fontWeight:'bold',
          '&:first-letter':{
            textTransform: 'uppercase'
          }
        }}>
        {kpiData.valueTitle}
        </Typography>
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          width:'100%',
          height:'100%',
          overflow:'hidden',
          padding: zoomCardContent ? '30px' : 'none',
          marginTop: zoomCardContent ? 0 : '5px',
          backgroundColor: zoomCardContent ? Colors.White : 'none',
          position: zoomCardContent ? 'absolute' : 'unset',
          top:0,
          left:0,
          bottom:0,
          right:0,
          boxSizing:'border-box',
          zIndex:zoomCardContent ? 1 : 0,
        }}>
          {zoomCardContent &&
            (
              <>
                <Percent onClick={handleSetPercent} sx={{
                  margin:'0px 5px',
                  cursor:'pointer',
                  position:'absolute',
                  top:10,
                  right:80,
                  color: percent ? Colors.TeniloBrown : '#323233',
                  fontSize: '1.4rem',
                }} />
                <Download onClick={downloadPDF} sx={{
                  margin:'0px 5px',
                  cursor:'pointer',
                  position:'absolute',
                  top:10,
                  right:50,
                  fontSize:'1.4rem',
                }} />
                <DockWindow onClick={handleZoomCardContent} sx={{
                  margin:'0px 5px',
                  cursor:'pointer',
                  position:'absolute',
                  top:10,
                  right:20,
                  fontSize:'1.4rem',
                }} />
            </>
          )}
          <Chart type={chartType} data={data} ref={forwardRef} options={options as any} plugins={plugins} />
          {zoomCardContent && !isEmpty(kpiData.details) &&(
            <DashboardKpiDetailView percent={percent} kpiData={kpiData} filteredDatasetByLegend={filteredDatasetByLegend} detailsRef={detailsRef} />
          )}
        </Box>
        {
          layoutSize !== 's' && (
            <Typography color="gray"  variant="subtitle2" sx={{
              marginTop: '2px',
              fontSize:layoutSize === 'l' ? '13px' : layoutSize === 'm' ? '12px' :  '11px',
              lineHeight: layoutSize === 'l' ? '1.57' : layoutSize === 'm' ? '1.3' :  '1'
            }}>
              {kpiData.valueDescription}
            </Typography>
          )
        }
      </Box>
  )
}

export default React.memo(DashboardKpiChartView)
