import React from 'react'
import {
  DrugListKpiClient,
  KeyPerformanceIndicatorItem
} from 'api/tenilo.dashboard.api-client'
import { DASHBOARD_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class KpiDrugListsApi {
  private readonly abortController = new AbortController()
  private readonly client: DrugListKpiClient

  public constructor() {
    this.client = new DrugListKpiClient(DASHBOARD_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getNumDrugActivitiesWhereGivenDeviatesFromExpectedKpi = (organizationId: number, areaIds: number[]): Promise<KeyPerformanceIndicatorItem> =>
    this.client
      .numDrugListActivitiesWhereGivenDeviatesFromExpectedKpi(organizationId, areaIds)
      .then(res =>
        res === null
          ? Promise.reject(`No res received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
          : Promise.resolve(res)
      )
}

const useKpiDrugListsApi = () => React.useMemo(() => new KpiDrugListsApi(), [])

export default useKpiDrugListsApi
