import { Box, Typography } from '@mui/material'
import OutlinedButton from 'components/inputs/outlined-button'
import React from 'react'
import styled, { css } from 'styled-components'
// import { WithSpinner } from 'utilities/with-spinner'

type Props = Readonly<
  {
    name?: string
    className?: string
    onAddSiblingClick?: (e: React.MouseEvent<Element, MouseEvent>) => void
    isLoading?: boolean
  } // & WithSpinner
>

const OrganizationLevelView: React.FC<Props> = ({ name = '\u00A0', className, children, onAddSiblingClick, isLoading = false }) => {
  const childCount = React.Children.count(children)
  return (
    <>
      {childCount > 0 && (
        <div className={className}>
          <Typography variant={'subtitle1'}>{name}</Typography>
          <Box pb={2} />
          <FullWidthOutlinedButton disabled={isLoading || !onAddSiblingClick} color="secondary" onClick={onAddSiblingClick}>
            +
          </FullWidthOutlinedButton>
          <Box pb={1} />
          {children}
        </div>
      )}
    </>
  )
}

const FullWidthOutlinedButton = styled(OutlinedButton)(
  () => css`
    width: 100%;
    display: none;
  `
)

export default OrganizationLevelView
