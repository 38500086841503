import React from 'react'
import useAuthorization from 'utilities/use-authorization'
import Authentication from 'components/authentication'
import { RoleEnum, UserTypeEnum } from 'api/tillit.api-client'

type Props = Readonly<{
  allowedRoles?: RoleEnum[];
  disallowedRoles?: RoleEnum[];
  mainRoles?: UserTypeEnum[];
}>

const Authorize : React.FC<Props> = ({ allowedRoles, disallowedRoles, mainRoles, ...props }) => {
  const { hasRoleOnAnyArea } = useAuthorization()
  const { user } = Authentication.useAuthenticatedState()
  const {children, ...otherProps} = props

  return (
    <>
      {
        (!allowedRoles || hasRoleOnAnyArea(...allowedRoles))
        &&
        (!disallowedRoles || !hasRoleOnAnyArea(...disallowedRoles))
        &&
        (!mainRoles || mainRoles.some(role => role === user.userType))
        &&
        React.isValidElement(children) && (
          React.cloneElement(children,{ ...otherProps })
        )
      }
    </>
  )

}

export default Authorize
