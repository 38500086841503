import { Box, Button, Modal, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import format from 'utilities/format'
import Colors from 'styles/colors'
import React from 'react'
import { TimePicker, DatePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField';
import SelectInput from 'components/inputs/select-input'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { UserListItem } from 'api/tillit.api-client'
import { ticksToInputTime, ticksToInputDateTime } from 'utilities/date-time-helpers'
import { CommonShiftListItem } from 'api/tenilo.workschedule.api-client'
import { CreateUserWorkScheduleModalContext } from './create-user-workschedule-modal-context'
import { StyledModalBox } from './create-user-workschedule-modal-style'
import { StyledWorkScheduleItem } from './create-user-workschedule-item-style'
import useFormData from './utilities/use-create-user-workschedule-modal-form-data'

const CreateUserWorkScheduleModalView: React.FC = () => {
  const f = useFormatMessage()
  const { createUserWorkScheduleModalOpen, areaUsers, calendarStartDate, calendarEndDate, commonShifts } = PlanningPageContext.useState()
  const { closeCreateUserWorkScheduleDialog } = PlanningPageContext.useActions()
  const { initialValues, name, onSubmit, validationSchema } = useFormData()
  const { getLatestWorkSchedulesForUser, setLatestUserWorkSchedules } = CreateUserWorkScheduleModalContext.useActions();
  const { latestUserWorkSchedules } = CreateUserWorkScheduleModalContext.useState();

  const handleClose = React.useCallback(() => {
    setLatestUserWorkSchedules(undefined)
    closeCreateUserWorkScheduleDialog()
  }, [setLatestUserWorkSchedules, closeCreateUserWorkScheduleDialog])

  const handleSubmit = React.useCallback((values: any, formikHelpers: any) => {
    onSubmit(values, formikHelpers)
    closeCreateUserWorkScheduleDialog()
    setLatestUserWorkSchedules(undefined)
  }, [onSubmit, closeCreateUserWorkScheduleDialog, setLatestUserWorkSchedules])

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={createUserWorkScheduleModalOpen} onClose={handleClose}>
      <StyledModalBox>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue }) => {

            const handleChangeScheduledStart = (scheduledStart: Date | null) => {
              const { startDate, scheduledEnd } = values

              if (!scheduledStart) {
                if (startDate) {
                  setFieldValue('scheduledStart', new Date(startDate.getTime()))
                }

                return
              }
              setFieldValue('scheduledStart', scheduledStart)

              let { endDate } = values

              const milliSec24Hrs = 60 * 60 * 24 * 1000;
              if (startDate && endDate) {
                //If end is less than start then we should add 24 hours to end
                if (scheduledEnd.getHours() < scheduledStart.getHours()) {
                  endDate = new Date(startDate.getTime() + milliSec24Hrs)
                } else {
                  endDate = new Date(startDate.getTime())
                }
                setFieldValue('endDate', endDate)

                const newScheduledEnd = new Date(endDate.getTime())
                newScheduledEnd.setHours(scheduledEnd.getHours())
                newScheduledEnd.setMinutes(scheduledEnd.getMinutes())
                setFieldValue('scheduledEnd', newScheduledEnd)
              }
            }

            const handleChangeScheduledEnd = (scheduledEnd: Date | null) => {
              const { scheduledStart, startDate } = values

              if (!scheduledEnd) {
                if (startDate) {
                  setFieldValue('scheduledEnd', new Date(startDate.getTime()))
                }

                return
              }

              let { endDate } = values

              const milliSec24Hrs = 60 * 60 * 24 * 1000;
              if (startDate && scheduledEnd) {
                //If end is less than start then we should add 24 hours to end
                if (scheduledEnd.getHours() < scheduledStart.getHours()) {
                  endDate = new Date(startDate.getTime() + milliSec24Hrs)
                } else {
                  endDate = new Date(startDate.getTime())
                }
                setFieldValue('endDate', endDate)

                const newScheduledEnd = new Date(endDate.getTime())
                newScheduledEnd.setHours(scheduledEnd.getHours())
                newScheduledEnd.setMinutes(scheduledEnd.getMinutes())
                setFieldValue('scheduledEnd', newScheduledEnd)
              }
            }

            const handleChangeScheduledEndDate = (val) => {
              //Not used as endDate is disabled
            }

            const handleChangeUser = (_: React.ChangeEvent<{}>, value: any) => {
              const uV = value as UserListItem
              if (uV) {
                setFieldValue('userId', uV.id)
                getLatestWorkSchedulesForUser(uV.id)
              } else {
                setFieldValue('userId', undefined)
                setLatestUserWorkSchedules(undefined)
              }
            }

            const handleChangeCommonShift = (_: React.ChangeEvent<{}>, value: any) => {

              const commonShiftItem = value as CommonShiftListItem
              const milliSec24Hrs = 60 * 60 * 24 * 1000;
              const { scheduledStart, scheduledEnd, startDate } = values
              if (!startDate) {
                return
              }

              let { endDate } = values

              // commonshift is over midnight
              if (commonShiftItem.scheduledStartTimeTicks >= commonShiftItem.scheduledEndTimeTicks) {
                endDate = new Date(startDate.getTime() + milliSec24Hrs)
              } else {
                endDate = new Date(startDate.getTime())
              }
              setFieldValue('endDate', endDate)

              const startTime = ticksToInputDateTime(commonShiftItem.scheduledStartTimeTicks);
              const endTime = ticksToInputDateTime(commonShiftItem.scheduledEndTimeTicks);

              const newScheduledStart = new Date(startDate.getTime())
              newScheduledStart.setHours(startTime.getHours())
              newScheduledStart.setMinutes(startTime.getMinutes())
              setFieldValue('scheduledStart', newScheduledStart)

              const newScheduledEnd = new Date(endDate.getTime())
              newScheduledEnd.setHours(endTime.getHours())
              newScheduledEnd.setMinutes(endTime.getMinutes())
              setFieldValue('scheduledEnd', newScheduledEnd)
            }

            const getUserName = (item: any) => {
              const ua = item as UserListItem
              return `${ua.fullName}`
            }

            const getCommonShiftName = (item: any) => {
              const shift = item
              return f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_SHIFT_DETAILS', {
                description: shift.description,
                from: ticksToInputTime(shift.scheduledStartTimeTicks),
                to: ticksToInputTime(shift.scheduledEndTimeTicks)
              })
            }

            return (
              <Form translate="yes">

                <ModalHeader
                  title={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TITLE')}
                />
                <Box mx={3}>
                  <div
                    style={{
                      maxHeight: '70vh',
                      minWidth: '20vw',
                      overflow: 'auto',
                    }}></div>

                  <ModalSection
                    title={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_TITLE')}
                    subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="100%">
                        <Box flex={1}>
                          <SelectInput
                            data-matomo-mask
                            label={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_USER_LABEL')}
                            onChange={handleChangeUser}
                            name={name.user}
                            options={areaUsers as any}
                            getOptionLabel={getUserName}
                            fullWidth
                            error={!!errors.user}
                            helperText={errors.user}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_TITLE')}
                    subtitle={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box flex={1}>
                        <SelectInput
                          label={f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_DIALOG_TITLE')}
                          name={name.commonShift}
                          onChange={handleChangeCommonShift}
                          options={commonShifts as any}
                          getOptionLabel={getCommonShiftName}
                          fullWidth
                          error={!!errors.commonShift}
                          helperText={errors.commonShift}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_TITLE')}
                    subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_SUBTITLE')}>
                    <ModalSectionRow>
                      <DatePicker
                        label={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_DATE_LABEL')}
                        value={values.startDate}
                        disabled
                        format="yyyy-MM-dd"
                        slots={{
                          textField: (params) => <TextField {...params}
                          color="secondary"
                          variant="filled"
                          fullWidth />
                        }}
                        localeText={{
                          toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
                          okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                          cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                        }}
                        slotProps={{
                          actionBar: {
                            actions: [],
                          }
                        }}
                      />
                      <TimePicker
                        label={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_LABEL')}
                        value={values.scheduledStart}
                        onChange={handleChangeScheduledStart}
                        ampm={false}
                        sx={{width:'100%'}}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        localeText={{
                          toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                          okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                          cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                        }}
                        slotProps={{
                          textField:{
                            variant:'filled',
                            error:touched && !!errors?.scheduledStart,
                            helperText:touched && errors?.scheduledStart
                          }
                        }}
                      />
                    </ModalSectionRow>
                    <ModalSectionRow>
                      <DatePicker
                        label={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_DATE_LABEL')}
                        value={values.scheduledEnd}
                        disabled
                        minDate={calendarStartDate}
                        maxDate={calendarEndDate}
                        format="yyyy-MM-dd"
                        slots={{
                          textField: (params) => <TextField {...params}
                          color="secondary"
                          variant="filled"
                          fullWidth />
                        }}
                        localeText={{
                          toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
                          okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                          cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                        }}
                        slotProps={{
                          actionBar: {
                            actions: [],
                          },
                        }}
                      />
                      <TimePicker
                        label={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_LABEL')}
                        value={values.scheduledEnd}
                        onChange={handleChangeScheduledEnd}
                        ampm={false}
                        sx={{width:'100%'}}
                        minTime={values.scheduledStart.getDate() === values.scheduledEnd.getDate() ? values.scheduledStart : undefined}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        localeText={{
                          toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                          okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                          cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                        }}
                        slotProps={{
                          textField:{
                            variant:'filled',
                            error:touched && !!errors?.scheduledEnd,
                            helperText:touched && errors?.scheduledEnd
                          }
                        }}
                      />
                    </ModalSectionRow>
                  </ModalSection>

                  {latestUserWorkSchedules && latestUserWorkSchedules.length > 0 && (
                    <ModalSection
                      title={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_TITLE')}
                      subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_SUBTITLE')}>
                      {latestUserWorkSchedules.map(ws => {
                        return (
                          <StyledWorkScheduleItem key={ws.id} color={ws.substituteUserId ? Colors.TeniloBrown : Colors.TeniloGreen}>
                            <Typography data-matomo-mask variant="subtitle2" fontWeight={600} color='white'>
                              <div>{`${format(ws.scheduledStart, 'yyyy-MM-dd HH:mm')} - ${format(ws.scheduledEnd, 'HH:mm')} ${ws.areaName} - ${ws.routeName}`}</div>
                              <div>{`${ws.substituteUserId ? `(${f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_FOR_LABEL')} ${ws.userFullName})` : ''} `}</div>
                            </Typography>
                          </StyledWorkScheduleItem>
                        )
                      })}
                    </ModalSection>
                  )}
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                  <Button type="button" color="secondary" onClick={handleClose}>
                    {f('BASIC_BUTTON_CANCEL_TEXT')}
                  </Button>
                  <Button type="submit" color="secondary">
                    {f('BASIC_BUTTON_SAVE_TEXT')}
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </StyledModalBox>
    </Modal>
  )

}

export default CreateUserWorkScheduleModalView
