import { getMinutesBetween } from './get-minutes-between'

export const createTimeArray = (
  from: Date,
  to: Date,
  stepInMinutes: number
): Date[] => {
  if (to < from) {
    //To date needs to be bigger or equal to from date
    return []
  }

  const minutesBetween = getMinutesBetween(from, to)
  const arrayLength = Math.ceil(minutesBetween / stepInMinutes) - 1

  const array = [from]
  for (let i = 0; i < arrayLength; i++) {
    const prevDate = array[i]
    const d = new Date(+prevDate + stepInMinutes * 60000)
    array.push(d)
  }

  return array
}
