import React, { FC } from 'react';
import { IUserItem } from 'api/tillit.api-client'
import styled, { css } from 'styled-components';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useFormatMessage } from 'localization'
import Collapse from '@mui/material/Collapse';
import { ChevronUp, Menu } from 'mdi-material-ui'
import { Colors } from 'styles/colors'
import { ListItem } from '@mui/material';
import { addDays } from 'date-fns'
import DayPicker from '../day-picker'

type Props = Readonly<{
  className?: string
  locked: boolean
  user?: IUserItem
  handleLockClicked: () => void
  openDailyActivities: () => void
}>

const PlanningMainMenuMobile: FC<Props> = ({ className, handleLockClicked, openDailyActivities, locked, user }) => {
  const f = useFormatMessage()
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  /*
  Removed until there's and actual difference between lock and open Daily...
  <ListItemButton onClick={handleLockClicked}>
  <ListItemText primary={
    f('PLANNING_PAGE_MAINMENU_LOCK')
  } />
  </ListItemButton>
  */

  return (
    <List
      className={className}
    >
      <ListItemButton onClick={handleClick}>
        {open ? <ChevronUp /> : <Menu />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List disablePadding>
          <ListItemButton onClick={openDailyActivities}>
            <ListItemText primary={
              f('PLANNING_PAGE_MAINMENU_OPEN_DAILYACTIVITIES')
            } />
          </ListItemButton>
          <ListItem>
            <DayPicker maxDate={addDays(new Date(), 14)} />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
};

export default styled(PlanningMainMenuMobile)(({ theme }) => css`
  display: none;
  .MuiListItemButton-root{
    height: 25px;
  }
  >.MuiListItemButton-root {
    background-color: ${theme.palette.primary.main};
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.TeniloBeige};
    &:hover {
      background-color: ${theme.palette.primary.main};
    }
  }
  >.MuiCollapse-root {
    .MuiListItemButton-root {
      background-color: ${Colors.TeniloBeige};
      border-bottom: 1px solid ${theme.palette.primary.main};
      &:hover {
        background-color: ${Colors.TeniloBeige};
      }
    }
    .MuiListItemText-primary {
      color: ${theme.palette.primary.main};
      font-size: 14px;
      font-weight: 500;
    }
    .MuiListItem-root {
      background-color: ${Colors.TeniloBeige};
      border-bottom: 1px solid ${theme.palette.primary.main};
      &:hover {
        background-color: ${Colors.TeniloBeige};
      }
    }
  }
  ${[theme.breakpoints.down('md')]}{
    display: block;
  }
`)
