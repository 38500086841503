import { useEffect, useRef } from 'react'

type UseInterval = (callback: () => void, delay: number) => any

export const useInterval: UseInterval = (callback, delay): void => {
  const savedCallback = useRef<typeof callback>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current !== undefined) {
        savedCallback.current()
      }
    }
    const id = window.setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
