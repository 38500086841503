import { Card, Checkbox, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

export const CardItemPlannedStyle = {
  fontSize: '13px',
  fontFamily: 'sans-serif',
  color: 'rgb(179, 179, 179)',
  padding: '0 5px',
  border: '2px dashed #cccccc',
  borderRadius: '5px',
  margin: '10px 0',
  cursor: 'not-allowed',
}

export const StyledTreeViewContainer = styled.div<{
  active: boolean
  isover?: boolean
}>(
  ({ active, isover = false, theme }) => css`
    border-right: 1px solid ${theme.palette.grey[300]};
    width: 40px;
    ${isover ? 'background-color: #00000022;' : ''}
    .title-style {
      cursor: pointer;
      padding: 5px;
      writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
    }

    .list-container {
      position: relative;
      padding: 0 10px;
      display: none;

      .list-style {
        overflow-y: auto;
        max-height: calc(100vh - 340px);
      }
    }

    ${active &&
      css`
        max-width: 336px;
        width: 336px;
        min-width: 250px;

        .title-style {
          padding: 5px;
          writing-mode: unset;
          -ms-writing-mode: lr-tb;
        }

        .list-container {
          display: flex;
          flex-direction: column;
        }
      `}
  `
)

export const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 10px;
`

export const StyledCheckBox = styled(Checkbox)`
  padding: 0;
  margin-right: 8px;
`

export const StyledCheckBoxTypography = styled(Typography)`
  white-space: nowrap;
`
