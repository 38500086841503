import { UnitListItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { isSearchValueValid } from './filter-helper'

export const filterUnits = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: UnitListItem[]
): UnitListItem[] => {
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const searchText = `${item.title} ${item.abbreviation}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as UnitListItem[]
  }

}

