import React from 'react'
import {
    CommonShiftClient,
    CommonShiftListItem,
    CommonShiftCreateItem,
    ICommonShiftCreateItem,
    CommonShiftItem,
    CommonShiftUpdateItem,
    ICommonShiftUpdateItem,
    CommonShiftInheritanceItem,
    ICommonShiftInheritanceUpdateItem,
    CommonShiftInheritanceUpdateItem,
    CommonShiftInheritanceCreateItem,
    ICommonShiftInheritanceCreateItem,
    CommonShiftsToAreasItem,
    ICommonShiftsToAreasItem
} from 'api/tenilo.workschedule.api-client'
import { WORKSCHEDULE_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class WorkScheduleApi {
    private readonly abortController = new AbortController()
    private readonly client: CommonShiftClient

    public constructor() {
        this.client = new CommonShiftClient(WORKSCHEDULE_API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public getCommonShift = (shiftId: number): Promise<CommonShiftItem> =>
        this.client
            .getCommonShift(shiftId)
            .then(shift =>
                shift === null
                    ? Promise.reject(`No common shift received from backend with id ${shiftId}.`)
                    : Promise.resolve(shift)
            )

    public  listInheritedCommonShifts = (organizationId: number): Promise<CommonShiftInheritanceItem[]> =>
        this.client
            .listInheritedCommonShifts(organizationId)
            .then(shifts =>
                shifts === null
                    ? Promise.reject(`No inherited common shifts received from backend for organization id ${organizationId}.`)
                    : Promise.resolve(shifts)
            )

    public listCommonShifts = (organizationId: number): Promise<CommonShiftListItem[]> =>
        this.client
            .listCommonShifts(organizationId)
            .then(shifts =>
                shifts === null
                    ? Promise.reject(`No common shifts received from backend for organization id ${organizationId}.`)
                    : Promise.resolve(shifts)
            )

    public listAreaInheritedCommonShifts = (areaId: number): Promise<CommonShiftListItem[]> =>
        this.client
            .listAreaInheritedCommonShifts(areaId)
            .then(shifts =>
                shifts === null
                    ? Promise.reject(`No area inherited common shifts received from backend for area id ${areaId}.`)
                    : Promise.resolve(shifts)
            )
    
    public listNonInheritedCommonShifts = (areaId: number): Promise<CommonShiftListItem[]> =>
        this.client
            .listNonInheritedCommonShifts(areaId)
            .then(shifts =>
                shifts === null
                    ? Promise.reject(`No common shifts received from backend for areaId id ${areaId}.`)
                    : Promise.resolve(shifts)
            )

    public createCommonShift = async (shift: ICommonShiftCreateItem ): Promise<CommonShiftItem> => {
      const model = new CommonShiftCreateItem(shift)
      return this.client
            .createCommonShift(model)
            .then(res =>
                res === null
                    ? Promise.reject('When creating a common shift, no shift item received from backend.')
                    : Promise.resolve(res)
            )
    }

    public createCommonShiftInheritance = async (shift: ICommonShiftInheritanceCreateItem ): Promise<CommonShiftInheritanceItem> => {
      const model = new CommonShiftInheritanceCreateItem(shift)
      return this.client
            .createCommonShiftInheritance(model)
            .then(res =>
                res === null
                    ? Promise.reject('When creating a inherited common shift, no shift item received from backend.')
                    : Promise.resolve(res)
            )
    }

    public updateCommonShift = async (shift: ICommonShiftUpdateItem): Promise<CommonShiftItem> =>{
      const model = new CommonShiftUpdateItem(shift)
      return this.client
          .updateCommonShift(model)
          .then(res =>
              res === null
                  ? Promise.reject('When updating an common shift, no shift item received from backend.')
                  : Promise.resolve(res)
          )
    }

    public updateCommonShiftInheritance = async (shift: ICommonShiftInheritanceUpdateItem ): Promise<CommonShiftInheritanceItem>  => {
      const model = new CommonShiftInheritanceUpdateItem(shift)
      return this.client
          .updateCommonShiftInheritance(model)
          .then(res =>
              res === null
                  ? Promise.reject('When updating an inherited common shift, no shift item received from backend.')
                  : Promise.resolve(res)
          )
    }

    public deleteCommonShift = async (shiftId: number) => {
      return this.client
          .deleteCommonShift(shiftId)
          .then(() => Promise.resolve(shiftId))
    }

    public hasInheritanceOnCommonShift = async (shiftId: number) : Promise<boolean> => {
      return this.client
          .hasInheritanceOnCommonShift(shiftId)
          .then((res) => Promise.resolve(res))
    }

    public connectCommonShiftsToAreas = async (data: ICommonShiftsToAreasItem) : Promise<boolean> => {
      const model = new CommonShiftsToAreasItem(data)
      return this.client
          .connectCommonShiftsToAreas(model)
          .then((res) => Promise.resolve(res))
    }

    public deleteCommonShiftInheritance = async (shiftId: number)  => {
      return this.client
          .deleteCommonShiftInheritance(shiftId)
          .then(() => Promise.resolve(shiftId))
    }

}

const useWorkScheduleApi = () => React.useMemo(() => new WorkScheduleApi(), [])

export default useWorkScheduleApi
