import { useTemplateActivityApi } from 'api'
import { useLegislationApi } from 'api'
import {
  TemplateActivityCategoryItem,
  TemplateActivityCategoryCreateItem,
  TemplateActivityCategoryUpdateItem,
  LegislationItem,
  LegislationListItem,
} from 'api/tillit.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import Authentication from 'components/authentication'

import { AdministerTemplateActivityCategoriesContext } from '../administer-template-activity-categories/administer-template-activity-categories-context'

const useTemplateActivityCategoryModalContext = () => {
  const { getTemplateActivityCategory, updateTemplateActivityCategory, createTemplateActivityCategory } = useTemplateActivityApi()

  const { listLegislations, getLegislation } = useLegislationApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const { fetchTemplateActivitiesAsync: fetchTemplateActivityCategoriesAsync } = AdministerTemplateActivityCategoriesContext.useActions()

  const [ownerAreaId, setOwnerAreaId] = React.useState<number | undefined>(undefined)

  const [ownerOrganizationId, setOwnerOrganizationId] = React.useState<number | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [selectedLegislation, setSelectedLegislation] = React.useState<LegislationItem | undefined>()

  const [selectedTemplateActivityCategory, setSelectedTemplateActivityCategory] = React.useState<TemplateActivityCategoryItem | undefined>()

  const [legislations, setLegislationItems] = React.useState<LegislationListItem[]>([])

  const fetchTemplateActivitiesAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listLegislations(currentUser.organizationId)).then(setLegislationItems)
  }, [currentUser.organizationId, fetchAsync, listLegislations])

  const fetchNewSelectedLegislationAsync = React.useCallback(
    async (legislationId: number | undefined) => {
      if (!legislationId) {
        setSelectedLegislation(undefined)
        return
      }

      await fetchAsync(getLegislation(legislationId)).then(legislation => {
        if (legislation instanceof LegislationItem) {
          setSelectedLegislation(legislation)
        }
      })
    },
    [fetchAsync, getLegislation, setSelectedLegislation]
  )

  const fetchTemplateActivityCategoryAsync = React.useCallback(
    async (templateActivityCategoryId: number) => {
      await fetchAsync(getTemplateActivityCategory(templateActivityCategoryId)).then(ita => {
        if (ita instanceof TemplateActivityCategoryItem) {
          setSelectedTemplateActivityCategory(ita)
          void fetchNewSelectedLegislationAsync(ita.legislationId)
          //setSelectedTemplateActivity(ita.templateActivity)
        }
      })
    },
    [fetchAsync, getTemplateActivityCategory, fetchNewSelectedLegislationAsync]
  )

  const openTemplateActivityCategoryModal = React.useCallback(
    (templateActivityId?: number) => {
      void fetchTemplateActivitiesAsync().then(_ => {
        if (!!templateActivityId) {
          void fetchTemplateActivityCategoryAsync(templateActivityId).then(_ => setModalOpen(true))
        } else {
          setModalOpen(true)
        }
      })
    },
    [fetchTemplateActivityCategoryAsync, fetchTemplateActivitiesAsync]
  )

  const closeTemplateActivityCategoryModal = React.useCallback(() => {
    setSelectedTemplateActivityCategory(undefined)
    setModalOpen(false)
  }, [])

  const createTemplateActivityCategoryAsync = React.useCallback(
    (createTemplateActivityCategoryModel: TemplateActivityCategoryCreateItem) => {
      return fetchAsync(
        createTemplateActivityCategory({
          ...createTemplateActivityCategoryModel,
        })
      ).then(_ => {
        void fetchTemplateActivityCategoriesAsync()
        closeTemplateActivityCategoryModal()
      })
    },
    [fetchAsync, createTemplateActivityCategory, closeTemplateActivityCategoryModal, fetchTemplateActivityCategoriesAsync]
  )

  const updateTemplateActivityCategoryAsync = React.useCallback(
    (templateActivityCategory: TemplateActivityCategoryUpdateItem) => {
      return fetchAsync(updateTemplateActivityCategory(templateActivityCategory))
        .then(_ => {
          void fetchTemplateActivityCategoriesAsync()
          closeTemplateActivityCategoryModal()
        })
        .catch(() => {
          closeTemplateActivityCategoryModal()
        })
    },
    [closeTemplateActivityCategoryModal, fetchAsync, fetchTemplateActivityCategoriesAsync, updateTemplateActivityCategory]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedTemplateActivityCategory,
      ownerAreaId,
      ownerOrganizationId,
      legislations,
      selectedLegislation,
    },
    actions: {
      openTemplateActivityCategoryModal,
      closeTemplateActivityCategoryModal,
      createTemplateActivityCategoryAsync,
      updateTemplateActivityCategoryAsync,
      setOwnerAreaId,
      setOwnerOrganizationId,
      setSelectedLegislation,
      fetchNewSelectedLegislationAsync,
    },
  }
}

export const TemplateActivityCategoryModalContext = createContext(useTemplateActivityCategoryModalContext)
