import { OkDialog } from 'components/ok-dialog'
import { useFormatMessage } from 'localization'
import React from 'react'
import { PausedPeriodContext } from '../paused-period-context'
import { formatDate } from '../paused-period-helper'

const DeleteNotAllowedDialogContainer: React.FC = () => {
  const f = useFormatMessage()

  const { deleteNotAllowedDialogOpen, pausedPeriod } = PausedPeriodContext.useState()
  const {
    closeDeleteNotAllowedDialog,
  } = PausedPeriodContext.useActions()

  return pausedPeriod ? (
    <OkDialog
      dialogTitle={f('PAUSED_PERIOD_DELETE_NOT_ALLOWED_DIALOG_TITLE')}
      dialogContentText={f('PAUSED_PERIOD_DELETE_NOT_ALLOWED_DIALOG_MESSAGE', {
        date: formatDate(pausedPeriod!.startDateTime),
      })}
      buttonOkText={f('PAUSED_PERIOD_DELETE_DIALOG_ACTIONS_CANCEL')}
      okDialogOpen={deleteNotAllowedDialogOpen}
      onClose={closeDeleteNotAllowedDialog}
    />
  ) : null
}

export default DeleteNotAllowedDialogContainer
