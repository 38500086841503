export const padTo2Digits = (num : number) : string => {
  return String(num).padStart(2, '0');
}

export const getHoursAndMinutes = (date : Date | null) : string => {
  if(!date) { return '00:00' }
  return padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes());
}

export const inputDateTimeToTicks = ( date : Date | null ) : number => {
  if(!date) { return 0 }
  const array = getHoursAndMinutes(date).split(":")
  const millisecond = ((parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60))*1_000
  return millisecond * 10_000
}

export const ticksToInputDateTime = ( ticks : number ) : Date => {
  const millisecond = ticks / 10_000
  const hour = Math.floor((millisecond / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((millisecond / (1000 * 60)) % 60)
  const time = new Date();
  time.setHours(hour);
  time.setMinutes(minutes);
  return time
}

export const ticksToInputTime = ( ticks : number ) : string => {
  const millisecond = ticks / 10_000
  const hour = Math.floor((millisecond / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((millisecond / (1000 * 60)) % 60)
  const time = new Date();
  time.setHours(hour);
  time.setMinutes(minutes);
  return getHoursAndMinutes(time)
}

export const normalWorkScheduleStartTime = () : Date => {
  const time = new Date();
  time.setHours(8);
  time.setMinutes(0);
  return time
}

export const normalWorkScheduleEndTime = () : Date => {
  const time = new Date();
  time.setHours(17);
  time.setMinutes(0);
  return time
}

export const addDays = (date : Date | undefined, days : number) : Date => {
  if(!date) {
    date = new Date()
  }
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
