import { GridToolbarExport, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import React from 'react';

export const CustomToolbar = (): (ReturnType<typeof GridToolbarContainer>) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{ delimiter: ";", utf8WithBom: true }} printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}

export const EmptyToolbar = (): (ReturnType<typeof GridToolbarContainer>) => {
    return (
        <GridToolbarContainer />
    );
}