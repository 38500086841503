import { OrganizationContext } from 'pages/organization/organization-context'
import React from 'react'
import SidebarStyles from './sidebar-styles'

type Props = Readonly<{}>

const SidebarContainer: React.FC = () => {
    const {
    sidebar: { close },
  } = OrganizationContext.useActions()
  const {
    sidebar: { selectedAreaOrgTreeItem, isOpen, isLoading },
  } = OrganizationContext.useState()

  const handleCloseClick = React.useCallback(() => close(), [close])
  const handleRemoveClick = React.useCallback(() => {}, [])

  return (
    <>
      {isOpen && (
        <SidebarStyles
          isOpen={isOpen}
          item={selectedAreaOrgTreeItem}
          isLoading={isLoading}
          onCloseClick={handleCloseClick}
          onRemoveClick={handleRemoveClick}
        />
      )}
    </>
  )
}

export default SidebarContainer
