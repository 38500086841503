import React from 'react'
import ReportsPageContext from './reports-page-context'
import ReportsPageView from './reports-page-view'

const ReportsPageContainer: React.FC = () => {

  return (
    <ReportsPageContext.Provider>
      <ReportsPageView />
    </ReportsPageContext.Provider>
  )
}

export default ReportsPageContainer
