import React from 'react'
import { useTemplateActivityApi } from 'api'
import { TemplateActivityCategoryListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'

export type TemplateActivityCreateModel = Readonly<{
  title: string
  ownerAreaId: number
  fromTimeHour: number
  fromTimeMinute: number
  toTimeHour: number
  toTimeMinute: number
}>

const useAdministerTemplateActivityCategoriesContext = () => {
  const {
    listTemplateActivityCategories,
    deleteTemplateActivityCategory,
    abort,
    hasTemplateActivityCategoryConnectedToTemplateActivity,
  } = useTemplateActivityApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const location = useLocation()

  const [templateActivityCategoryListItems, setTemplateActivityCategoryListItems] = React.useState<TemplateActivityCategoryListItem[]>([]) // SK: WAS PageResultOfCustomerListItem
  const [modalOpen, setModalOpen] = React.useState(false)

  const fetchTemplateActivitiesAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listTemplateActivityCategories(currentUser.organizationId).then(setTemplateActivityCategoryListItems))
  }, [currentUser.organizationId, fetchAsync, listTemplateActivityCategories])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    void fetchTemplateActivitiesAsync()
  }, [fetchTemplateActivitiesAsync, location])

  const { state: stateOfRemoveFetch, fetchAsync: removeFetchAsync } = usePromiseApi()

  const [templateActivityToRemove, setTemplateActivityToRemove] = React.useState<TemplateActivityCategoryListItem | null>(null)

  const removeDialogOpen = React.useMemo(() => templateActivityToRemove !== null, [templateActivityToRemove])

  const [templateActivityInvalidToRemove, setTemplateActivityInvalidToRemove] = React.useState<TemplateActivityCategoryListItem | null>(null)

  const removeInvalidDialogOpen = React.useMemo(() => templateActivityInvalidToRemove !== null, [templateActivityInvalidToRemove])

  const closeRemoveInvalidDialog = React.useCallback(() => setTemplateActivityInvalidToRemove(null), [setTemplateActivityInvalidToRemove])

  const openRemoveDialog = React.useCallback(
    (templateActivityCategory: TemplateActivityCategoryListItem) => {
      void hasTemplateActivityCategoryConnectedToTemplateActivity(templateActivityCategory.id).then(result => {
        if (result) {
          setTemplateActivityInvalidToRemove(templateActivityCategory)
        } else {
          setTemplateActivityToRemove(templateActivityCategory)
        }
      })
    },
    [setTemplateActivityToRemove, setTemplateActivityInvalidToRemove, hasTemplateActivityCategoryConnectedToTemplateActivity]
  )

  const closeRemoveDialog = React.useCallback(() => setTemplateActivityToRemove(null), [setTemplateActivityToRemove])

  const deleteTemplateActivityAsync = React.useCallback(
    templateActivityId => {
      void removeFetchAsync(
        deleteTemplateActivityCategory(templateActivityId)
          .then(fetchTemplateActivitiesAsync)
          .then(closeRemoveDialog)
      )
    },
    [removeFetchAsync, deleteTemplateActivityCategory, fetchTemplateActivitiesAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      templateActivityCategoryListItems,
      modalOpen,
      removeDialogOpen,
      templateActivityToRemove,
      templateActivityInvalidToRemove,
      isRemoving,
      removeInvalidDialogOpen,
    },
    actions: {
      fetchTemplateActivitiesAsync,
      openModal,
      closeModal,
      openRemoveDialog,
      closeRemoveDialog,
      closeRemoveInvalidDialog,
      deleteTemplateActivityAsync,
    },
  }
}

export const AdministerTemplateActivityCategoriesContext = createContext(useAdministerTemplateActivityCategoriesContext)
