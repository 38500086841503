import React from 'react'
import * as yup from 'yup'

import {
  GrandIdParameterEnum,
  GrandIdServiceEnum,
  IOrganizationItem,
  OrganizationGrandIdSettingItem,
  OrganizationGrandIdSettingPersistItem,
  OrganizationUpdateItem,
} from 'api/tenilo.support.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { SupportOrganizationModalContext } from '../support-organization-modal-context'
import { SupportAreasContext } from '../../../support-areas-context'

type OrganizationForm = Readonly<{
  name: string
  registrationNumber: string
  expectedEmailDomain: string
  active: boolean
  customerPausedPeriodDaysHorizon: number
  expiringDelegationsWeeksHorizon: number
  allowSigningWhenDelegationsIsNotFulfilled: boolean
  allowStartedActivityStatus: boolean
  allowAreaTemplateActivities: boolean
  allowCommentOnSigning: boolean
  useDelegations: boolean
  useDrugLists: boolean
  useWorkSchedule: boolean
  deviceIpRange: string | undefined
  isAnonymized: boolean
  grandIdOrganizationIdentifier: string | undefined
  organizationGrandIdSettings: OrganizationGrandIdSettingItem[] | undefined
  allowStandardLogin: boolean
  useLoginSlidingExpirationMinutes: boolean
  loginSlidingExpirationMinutes: number | undefined
}>

const mapOrganizationFormToOrganizationItem = (initialItem: IOrganizationItem, item: OrganizationForm): OrganizationUpdateItem => {
  return new OrganizationUpdateItem({
    id: initialItem.id,
    name: item.name,
    registrationNumber: item.registrationNumber,
    expectedEmailDomain: item.expectedEmailDomain,
    active: item.active,
    customerPausedPeriodDaysHorizon: item.customerPausedPeriodDaysHorizon,
    expiringDelegationsWeeksHorizon: item.expiringDelegationsWeeksHorizon,
    allowSigningWhenDelegationsIsNotFulfilled: item.allowSigningWhenDelegationsIsNotFulfilled,
    allowStartedActivityStatus: item.allowStartedActivityStatus,
    allowAreaTemplateActivities: item.allowAreaTemplateActivities,
    allowCommentOnSigning: item.allowCommentOnSigning,
    useDelegations: item.useDelegations,
    useDrugLists: item.useDrugLists,
    useWorkSchedule: item.useWorkSchedule,
    deviceIpRange: item.deviceIpRange,
    grandIdOrganizationIdentifier: item.grandIdOrganizationIdentifier,
    allowStandardLogin: item.allowStandardLogin,
    useLoginSlidingExpirationMinutes: item.useLoginSlidingExpirationMinutes,
    loginSlidingExpirationMinutes: item.loginSlidingExpirationMinutes,
  })
}

const mapOrganizationItemToOrganizationForm = (item: IOrganizationItem): OrganizationForm => {
  const mappedItem: OrganizationForm = {
    name: item.name,
    registrationNumber: item.registrationNumber,
    expectedEmailDomain: item.expectedEmailDomain,
    active: item.active,
    customerPausedPeriodDaysHorizon: item.customerPausedPeriodDaysHorizon,
    expiringDelegationsWeeksHorizon: item.expiringDelegationsWeeksHorizon,
    allowSigningWhenDelegationsIsNotFulfilled: item.allowSigningWhenDelegationsIsNotFulfilled,
    allowStartedActivityStatus: item.allowStartedActivityStatus,
    allowAreaTemplateActivities: item.allowAreaTemplateActivities,
    allowCommentOnSigning: item.allowCommentOnSigning,
    useDelegations: item.useDelegations,
    useDrugLists: item.useDrugLists,
    useWorkSchedule: item.useWorkSchedule,
    deviceIpRange: item.deviceIpRange,
    isAnonymized: item.isAnonymized,
    grandIdOrganizationIdentifier: item.grandIdOrganizationIdentifier,
    organizationGrandIdSettings: item.organizationGrandIdSettings,
    allowStandardLogin: item.allowStandardLogin,
    useLoginSlidingExpirationMinutes: item.useLoginSlidingExpirationMinutes,
    loginSlidingExpirationMinutes: item.loginSlidingExpirationMinutes,
  }
  return mappedItem
}

const useSupportOrganizationFormData = (initialOrganization?: IOrganizationItem) => {
  const f = useFormatMessage()

  const { updateOrganizationAsync } = SupportOrganizationModalContext.useActions()
  const { refreshAreasAsync } = SupportAreasContext.useActions()

  const organization = initialOrganization

  const initialValues = React.useMemo<OrganizationForm>(
    () =>
      !!organization
        ? mapOrganizationItemToOrganizationForm(organization)
        : {
            name: '',
            registrationNumber: '',
            expectedEmailDomain: '',
            active: false,
            customerPausedPeriodDaysHorizon: 7,
            expiringDelegationsWeeksHorizon: 12,
            allowSigningWhenDelegationsIsNotFulfilled: false,
            allowStartedActivityStatus: false,
            allowAreaTemplateActivities: false,
            allowCommentOnSigning: false,
            useDelegations: false,
            useDrugLists: false,
            useWorkSchedule: false,
            deviceIpRange: '',
            isAnonymized: false,
            grandIdOrganizationIdentifier: '',
            organizationGrandIdSettings: [],
            allowStandardLogin: true,
            useLoginSlidingExpirationMinutes: false,
            loginSlidingExpirationMinutes: undefined,
          },
    [organization]
  )

  const onSubmit: OnSubmitFunction<OrganizationForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      if (initialOrganization) {
        const mappedOrganization = mapOrganizationFormToOrganizationItem(initialOrganization, values)
        await updateOrganizationAsync(mappedOrganization).finally(() => {
          setSubmitting(false)
          refreshAreasAsync()
        })
      }
    },
    [initialOrganization, updateOrganizationAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      /*title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),
*/
      /*      description: yup.string().max(
              1024,
              f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
                name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
                maxsize: 1024,
              })
            ),*/
    })
  }, [f])

  const name: Name<OrganizationForm> = React.useMemo(
    () => ({
      name: 'name',
      registrationNumber: 'registrationNumber',
      expectedEmailDomain: 'expectedEmailDomain',
      active: 'active',
      customerPausedPeriodDaysHorizon: 'customerPausedPeriodDaysHorizon',
      expiringDelegationsWeeksHorizon: 'expiringDelegationsWeeksHorizon',
      allowSigningWhenDelegationsIsNotFulfilled: 'allowSigningWhenDelegationsIsNotFulfilled',
      allowStartedActivityStatus: 'allowStartedActivityStatus',
      allowAreaTemplateActivities: 'allowAreaTemplateActivities',
      allowCommentOnSigning: 'allowCommentOnSigning',
      useDelegations: 'useDelegations',
      useDrugLists: 'useDrugLists',
      useWorkSchedule: 'useWorkSchedule',
      deviceIpRange: 'deviceIpRange',
      isAnonymized: 'isAnonymized',
      grandIdOrganizationIdentifier: 'grandIdOrganizationIdentifier',
      organizationGrandIdSettings: 'organizationGrandIdSettings',
      allowStandardLogin: 'allowStandardLogin',
      useLoginSlidingExpirationMinutes: 'useLoginSlidingExpirationMinutes',
      loginSlidingExpirationMinutes: 'loginSlidingExpirationMinutes',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useSupportOrganizationFormData
