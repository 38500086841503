import React from 'react';
import { useTheme } from '@mui/material'
import {  PlannedActivityStatusEnum } from 'api/tillit.api-client'
import { isEmpty, last } from 'lodash'
import Spinner from 'components/spinner'
import { AdministerSigningStatusesContext } from './administer-signing-statuses-context'
import { SigningStatusesViewContainer,
  SigningStatusesItemsWrapper,
  SigningStatusItem,
  SigningStatusItemsHeader,
  SigningStatusItemsMeasurementunitHeader,
  SigningStatusItemsTimeunitHeader,
  SigningStatusItemsOtherHeader
} from './components'

const SigningStatusesView : React.FC = () => {
  const theme = useTheme()
  const {
    signingStatuses,
    isLoading
  } = AdministerSigningStatusesContext.useState()

  const { handleRemoveSigningStatus } = AdministerSigningStatusesContext.useActions()

  return (
    <SigningStatusesViewContainer>
      { isLoading ?
        <Spinner color={theme.palette.primary.main} />
        :
        <SigningStatusesItemsWrapper>
          <SigningStatusItemsHeader/>
          {
            signingStatuses?.plannedActivityStatusItems?.map((status,i)=>{
              if(status.id === PlannedActivityStatusEnum.NotCompleted ||
                status.id === PlannedActivityStatusEnum.Delayed ||
                status.id === PlannedActivityStatusEnum.Started){ //när jag sätter started så försvinner "ORdinarie / tid" i listan som rubrik
                return
              }
              const orgStatusList = signingStatuses.organizationPlannedActivityStatusItems?.filter( orgStatus => orgStatus.plannedActivityStatusId === status.id )
              return (
                <React.Fragment key={i}>
                  {i === 0 && (
                    <SigningStatusItemsTimeunitHeader/>
                    )}
                  {i === 4 && (
                    <SigningStatusItemsMeasurementunitHeader/>
                  )}
                  {i === 11 && (
                    <SigningStatusItemsOtherHeader/>
                  )}
                  <SigningStatusItem
                    key={i}
                    plannedStatus={status}
                    orgPlannedStatus={!isEmpty(orgStatusList) ? last(orgStatusList) : null}
                    handleRemoveSigningStatus={handleRemoveSigningStatus}
                  />
                </React.Fragment>
              )
            })
          }
        </SigningStatusesItemsWrapper>
      }
    </SigningStatusesViewContainer>
  );
};

export default SigningStatusesView;
