import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider as MuiPickersUtilsProvider} from '@mui/x-date-pickers'
import React from 'react'
import sv from 'date-fns/locale/sv'
import { IntlProvider } from 'react-intl'
import { getLocale } from './get-locale'
import { getMessages } from './messages'

const LocalizationProvider: React.FunctionComponent = ({ children }) => {
  const currentLocale = getLocale()
  const messages = getMessages(currentLocale)
  return (
    <MuiPickersUtilsProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <IntlProvider
        key={currentLocale} // Add the key prop to make sure the app is re-rendered when changing locale
        locale={currentLocale}
        messages={messages}>
        {children}
      </IntlProvider>
    </MuiPickersUtilsProvider>
  )
}

export default LocalizationProvider
