import React, { MouseEvent } from 'react'

import type { FC } from 'react'
import { useDrag } from 'react-dnd'

import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Color from 'color'
import { BulletinBoardItemModel } from '../bulletin-board-context'
import { ItemTypes } from './itemtypes'

export interface ItemProps {
  bulletinBoardItem: BulletinBoardItemModel,
  bulletinBoardItemIndex: number,
  bulletinItemChanged: (item: BulletinBoardItemModel) => void
  bulletinItemHandleEdit: (item: BulletinBoardItemModel) => void
  bulletinItemHandleRemove: (item: BulletinBoardItemModel) => void
}

export const BulletinBoardItem: FC<ItemProps> = ({ bulletinBoardItem, bulletinBoardItemIndex, bulletinItemChanged, bulletinItemHandleEdit, bulletinItemHandleRemove }) => {

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.BulletinBoardItem,
      item: bulletinBoardItem,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }), [bulletinBoardItem])

  const textColor = React.useMemo(() => {
    return Color(bulletinBoardItem.color).contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'
  }, [bulletinBoardItem])

  const handleMouseUp = React.useCallback((e: MouseEvent<HTMLDivElement>, itemIndex: number) => {
    // This will handle resize of message...
    const messageDiv = e.target as HTMLDivElement

    const dataInfo = messageDiv.getAttribute('data-info')
    if (!dataInfo || dataInfo !== 'BulletinBoardItem') {
      return
    }

    const newWidth = parseInt(messageDiv.style.width)
    const newHeight = parseInt(messageDiv.style.height)
    const newItem: BulletinBoardItemModel = {
      ...bulletinBoardItem,
      width: newWidth >= 100 ? newWidth : 100,
      height: newHeight >= 100 ? newHeight : 100
    }
    bulletinItemChanged(newItem)
  }, [bulletinBoardItem])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleRemove = React.useCallback(() => {
    setAnchorEl(null)
    bulletinItemHandleRemove(bulletinBoardItem)
  }, [bulletinBoardItem, bulletinItemHandleRemove])

  const handleEdit = React.useCallback(() => {
    setAnchorEl(null)
    bulletinItemHandleEdit(bulletinBoardItem)
  }, [bulletinBoardItem, bulletinItemHandleEdit])

  if (isDragging) {
    // When draggning the "source" element should not be displayed
    return <div ref={drag} />
  }

  return (
    <Card ref={drag}
      sx={{
        // make the card resizable
        overflow: 'auto',
        resize: 'both',
        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
      style={{
        position: 'absolute',
        left: `${bulletinBoardItem.left}px`,
        top: `${bulletinBoardItem.top}px`,
        width: `${bulletinBoardItem.width}px`,
        height: `${bulletinBoardItem.height}px`,
        backgroundColor: bulletinBoardItem.color
      }}
      data-info="BulletinBoardItem"
      onMouseUp={(e) => handleMouseUp(e, bulletinBoardItemIndex)}
    >
      <CardHeader title={bulletinBoardItem.header} sx={{ '.MuiCardHeader-title': { color: textColor } }}
        action={
          <>
            <IconButton aria-label="settings"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <GridMoreVertIcon sx={{ color: textColor }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleEdit}>Ändra</MenuItem>
              <MenuItem onClick={handleRemove}>Ta bort</MenuItem>
            </Menu>
          </>
        } />
      <CardContent>
        {bulletinBoardItem.content?.split(/\n/g).map((row, index) => {
          if (row.trim().length === 0) {
            return (<br />)
          }
          return (
            <Typography key={`bbicr${index}`} variant="body2" sx={{ color: textColor }} display="block">
              {row}
            </Typography>
          )
        })}
      </CardContent>
    </Card >

  )
}
