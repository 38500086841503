import React from 'react'
import * as yup from 'yup'

import {
  ActivityCreateItem,
  ActivityRowCreateItem,
  ActivityRowItem,
  IActivityItem,
  MonthlyDayEnum,
  RecurrenceEnum,
  SeverityLevelEnum,
  TemplateActivityTypeEnum,
  WeeklyDayEnum,
  ActivityParticipantItem,
} from 'api/tillit.api-client'
import AuthenticationContext from 'components/authentication/authentication-context'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import format from 'utilities/format'
import { TemplateActivityItem } from 'api/tillit.api-client'
import ActivityCreateModalContext from '../activity-create-modal-context'


type CreateActivityForm = Readonly<{
  title: string
  startDate: Date
  endDate?: Date
  fromTime: Date
  toTime: Date
  color: string
  recurrenceId: RecurrenceEnum
  recurrenceInterval: number
  visitLengthInMinutes: number
  description: string
  monthlyDayId: MonthlyDayEnum
  Monday: boolean
  Tuesday: boolean
  Wednesday: boolean
  Thursday: boolean
  Friday: boolean
  Saturday: boolean
  Sunday: boolean
  alertFromStartInMinutes: number
  showColorOnDevice: boolean
  templateActivity: TemplateActivityItem | undefined
  templateActivityId: number | undefined
  templateActivityIdExistsInDb: boolean
  expectedAmount: number | undefined
  severityLevelId: SeverityLevelEnum
  activityParticipants?: ActivityParticipantItem[] | undefined
}>

const getActivityRows = (values: {
  fromTime: CreateActivityForm['fromTime']
  toTime: CreateActivityForm['toTime']
  recurrenceId: CreateActivityForm['recurrenceId']
  description: CreateActivityForm['description']
  visitLengthInMinutes: CreateActivityForm['visitLengthInMinutes']
  Monday: CreateActivityForm['Monday']
  Tuesday: CreateActivityForm['Tuesday']
  Wednesday: CreateActivityForm['Wednesday']
  Thursday: CreateActivityForm['Thursday']
  Friday: CreateActivityForm['Friday']
  Saturday: CreateActivityForm['Saturday']
  Sunday: CreateActivityForm['Sunday']
  monthlyDayId: CreateActivityForm['monthlyDayId']
}): ActivityRowCreateItem[] => {
  const fromTime = new Date(values.fromTime)
  const toTime = new Date(values.toTime)
  switch (values.recurrenceId) {
    case RecurrenceEnum.Daily: {
      return [
        new ActivityRowCreateItem({
          description: values.description,
          visitLengthInMinutes: values.visitLengthInMinutes,
          fromTimeHour: fromTime.getHours(),
          fromTimeMinute: fromTime.getMinutes(),
          toTimeHour: toTime.getHours(),
          toTimeMinute: toTime.getMinutes(),
        }),
      ]
    }
    case RecurrenceEnum.Weekly: {
      const weeklyDays = [
        values.Monday && WeeklyDayEnum.Monday,
        values.Tuesday && WeeklyDayEnum.Tuesday,
        values.Wednesday && WeeklyDayEnum.Wednesday,
        values.Thursday && WeeklyDayEnum.Thursday,
        values.Friday && WeeklyDayEnum.Friday,
        values.Saturday && WeeklyDayEnum.Saturday,
        values.Sunday && WeeklyDayEnum.Sunday,
      ]

      const chosenWeeklyDays = weeklyDays.filter(weeklyDay => weeklyDay !== false) as WeeklyDayEnum[]
      return chosenWeeklyDays.map(
        weeklyDay =>
          new ActivityRowCreateItem({
            description: values.description,
            visitLengthInMinutes: values.visitLengthInMinutes,
            fromTimeHour: fromTime.getHours(),
            fromTimeMinute: fromTime.getMinutes(),
            toTimeHour: toTime.getHours(),
            toTimeMinute: toTime.getMinutes(),
            weeklyDayId: weeklyDay,
          })
      )
    }
    case RecurrenceEnum.Monthly: {
      return [
        new ActivityRowCreateItem({
          description: values.description,
          visitLengthInMinutes: values.visitLengthInMinutes,
          fromTimeHour: fromTime.getHours(),
          fromTimeMinute: fromTime.getMinutes(),
          toTimeHour: toTime.getHours(),
          toTimeMinute: toTime.getMinutes(),
          monthlyDayId: values.monthlyDayId,
        }),
      ]
    }
    default:
      return []
  }
}
const createActivity = (
  values: CreateActivityForm & {
    customerId: number | undefined
    areaId: number | undefined
  }
): ActivityCreateItem => {
  const {
    customerId,
    areaId,
    title,
    startDate,
    endDate,
    color,
    recurrenceId,
    recurrenceInterval,
    alertFromStartInMinutes,
    showColorOnDevice,
    templateActivityId,
    expectedAmount,
    severityLevelId,
    activityParticipants,
    ...rest
  } = values
  const activityRows = getActivityRows({ recurrenceId, ...rest })
  const itemEndDate = endDate
  itemEndDate?.setHours(0, 0, 0, 0)
  return new ActivityCreateItem({
    ownerCustomerId: customerId || undefined,
    ownerAreaId: areaId,
    title,
    severityLevelId,
    startDate,
    endDate: itemEndDate,
    color,
    recurrenceId,
    recurrenceInterval,
    activityRows,
    alertFromStartInMinutes,
    showColorOnDevice,
    templateActivityId,
    expectedAmount,
    activityParticipants,
  })
}

const mapCreateActivityFormToActivityItem = (initialItem: IActivityItem, item: CreateActivityForm, signedInUserId: number): IActivityItem => {
  const activityRows = getActivityRows(item)
  const modifiedOn = new Date()
  let itemEndDate = item.endDate
  itemEndDate?.setHours(0, 0, 0, 0)
  if (initialItem.recurrenceId === RecurrenceEnum.OneTime) {
    itemEndDate = initialItem.endDate // Enddate for OneTimes cannot be changed
  }

  const returnItem: IActivityItem = {
    id: initialItem.id,
    ownerCustomerId: initialItem.ownerCustomerId,
    ownerAreaId: initialItem.ownerAreaId,
    title: item.title,
    severityLevelId: item.severityLevelId,
    startDate: item.startDate,
    endDate: itemEndDate,
    color: item.color,
    recurrenceId: item.recurrenceId,
    recurrenceInterval: item.recurrenceInterval,
    activityRows: activityRows.map(
      a =>
        new ActivityRowItem({
          id:
            (initialItem.activityRows &&
              initialItem.activityRows
                .filter(x => (x.monthlyDayId || null) === (a.monthlyDayId || null) && (x.weeklyDayId || null) === (a.weeklyDayId || null))
                .map(x => x.id)
                .find(_ => true)) ||
            -1,
          activityId: initialItem.id,
          createdById: initialItem.createdById,
          createdOn: initialItem.createdOn,
          modifiedById: signedInUserId,
          modifiedOn,
          ...a,
        })
    ),
    activityAreas: initialItem.activityAreas,
    createdById: initialItem.createdById,
    createdOn: initialItem.createdOn,
    modifiedById: signedInUserId,
    modifiedOn,
    isPlanned: initialItem.isPlanned,
    alertFromStartInMinutes: item.alertFromStartInMinutes,
    showColorOnDevice: item.showColorOnDevice,
    templateActivityId: item.templateActivityId,
    templateActivityTypeId: TemplateActivityTypeEnum.CustomerTemplate,
    expectedAmount: item.expectedAmount,
    activityParticipants: item.activityParticipants,
  }

  return returnItem
}

const mapActivityItemToCreateActivityForm = (item: IActivityItem): CreateActivityForm => {
  const { activityRows = [], recurrenceId } = item

  const initialDays = {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  }
  const days =
    recurrenceId === RecurrenceEnum.Weekly
      ? activityRows.reduce((prev, current) => {
          if (!!current.weeklyDayId) {
            return { ...prev, [current.weeklyDayId]: true }
          }
          return prev
        }, initialDays)
      : initialDays

  const { description, fromTimeHour, fromTimeMinute, monthlyDayId, toTimeHour, toTimeMinute, visitLengthInMinutes } = activityRows[0]

  const fromTime = new Date()
  fromTime.setHours(fromTimeHour)
  fromTime.setMinutes(fromTimeMinute)
  const toTime = new Date()
  toTime.setHours(toTimeHour)
  toTime.setMinutes(toTimeMinute)

  const itemEndDate = item.endDate ? new Date(item.endDate.getTime()) : undefined

  const templateActivity: TemplateActivityItem = new TemplateActivityItem()
  if (item.templateActivityId!) {
    templateActivity.id = item.templateActivityId ? item.templateActivityId : -1
    templateActivity.title = item.templateActivityTitle
    templateActivity.templateActivityCategoryId = item.templateActivityCategoryId
    templateActivity.templateActivityCategoryTitle = item.templateActivityCategoryTitle
    templateActivity.templateActivityLegislationId = item.templateActivityLegislationId
    templateActivity.templateActivityLegislationTitle = item.templateActivityLegislationTitle
    templateActivity.templateActivityTypeId = item.templateActivityTypeId
  }

  const createItem: CreateActivityForm = {
    title: item.title || '',
    startDate: item.startDate,
    endDate: itemEndDate,
    fromTime,
    toTime,
    color: item.color || '',
    recurrenceId: item.recurrenceId,
    recurrenceInterval: item.recurrenceInterval,
    visitLengthInMinutes,
    description: description || '',
    monthlyDayId: monthlyDayId || MonthlyDayEnum.MonthlyDateInterval,
    alertFromStartInMinutes: item.alertFromStartInMinutes,
    showColorOnDevice: item.showColorOnDevice,
    templateActivity,
    templateActivityId: item.templateActivityId,
    templateActivityIdExistsInDb: item.templateActivityId ? true : false,
    expectedAmount: item.expectedAmount,
    severityLevelId: item.severityLevelId,
    activityParticipants: item.activityParticipants,
    ...days,
  }
  return createItem
}

const useActivityCreateFormData = (initialActivity?: IActivityItem) => {
  const { user } = AuthenticationContext.useAuthenticatedState()
  const f = useFormatMessage()
  const { customerInfo, ownerAreaId, areaInfo, duplicateActivity } = ActivityCreateModalContext.useState()
  const { createActivityAsync, updateActivityAsync } = ActivityCreateModalContext.useActions()
  const fromTime = new Date()
  const toTime = new Date()
  toTime.setHours(fromTime.getHours() + 1)

  const activity = duplicateActivity ? duplicateActivity : initialActivity

  const initialValues = React.useMemo<CreateActivityForm>(
    () =>
      !!activity && !!activity.activityRows && activity.activityRows.length > 0
        ? mapActivityItemToCreateActivityForm(activity)
        : {
            title: '',
            startDate: new Date(),
            endDate: undefined,
            fromTime,
            toTime,
            color: '#bdbdbd',
            recurrenceId: RecurrenceEnum.Weekly,
            recurrenceInterval: 1,
            visitLengthInMinutes: 60,
            description: '',
            monthlyDayId: MonthlyDayEnum.MonthlyDateInterval,
            Monday: true,
            Tuesday: true,
            Wednesday: true,
            Thursday: true,
            Friday: true,
            Saturday: true,
            Sunday: true,
            alertFromStartInMinutes: 0,
            showColorOnDevice: false,
            templateActivity: undefined,
            templateActivityId: undefined,
            templateActivityIdExistsInDb: false,
            expectedAmount: undefined,
            severityLevelId: SeverityLevelEnum.Normal,
            activityParticipants: undefined,
          },
    [fromTime, toTime, activity]
  )

  const onSubmit: OnSubmitFunction<CreateActivityForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      if (!initialActivity) {
        const activityToCreate = createActivity({
          customerId: customerInfo && !ownerAreaId ? customerInfo.id : undefined,
          areaId: ownerAreaId || areaInfo?.id,
          ...values,
        })
        await createActivityAsync(activityToCreate).finally(() => setSubmitting(false))
      } else {
        const mappedActivity = mapCreateActivityFormToActivityItem(initialActivity, values, user.id)
        await updateActivityAsync(mappedActivity).finally(() => setSubmitting(false))
      }
    },
    [createActivityAsync, customerInfo, initialActivity, updateActivityAsync, user.id, ownerAreaId, areaInfo]
  )

  const validationSchema = React.useMemo(() => {
    const minDate = new Date()
    minDate.setHours(0)
    minDate.setMinutes(0)
    minDate.setSeconds(0)
    minDate.setMilliseconds(0)
    const startDateInThePast = !!initialActivity && +initialActivity.startDate < new Date().setHours(0, 0, 0, 0)

    return yup
      .object()
      .shape({
        templateActivity: yup
          .mixed()
          .test('TemplateActivityRequired', f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TEMPLATEACTIVITY_REQUIRED'), (value: TemplateActivityItem | undefined) => {
            if (!user.forceTemplateActivity) {
              return true;
            }
            return !!value
          }),
        title: yup.string().max(
          40,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_NAME_TEXTFIELD_LABEL'),
            maxsize: 40,
          })
        ),
        startDate: startDateInThePast
          ? yup
              .date()
              .test('startDateUnchanged', 'Cannot change start date that is in the past.', (value: Date | null) => {
                return !!value && !!initialActivity && new Date(value).setHours(0, 0, 0, 0) === new Date(initialActivity.startDate).setHours(0, 0, 0, 0)
              })
              .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          : yup
              .date()
              .min(format(minDate, 'P'))
              .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        fromTime: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        toTime: yup.date().typeError(f('BASIC_INVALID_TIME_FIELD_HELPER_TEXT')).required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        description: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL'),
            maxsize: 1024,
          })
        ),
        visitLengthInMinutes: yup
          .number()
          .integer(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL'),
            })
          )
          .typeError(f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_VISIT_LENGTH_ONLY_NUMBERS_ERROR_TEXT'))
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            1440,
            f('BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES', {
              name: f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL'),
              maxsize: 1440,
            })
          )
          .min(
            1,
            f('BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES', {
              name: f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_TEXTFIELD_LABEL'),
              minsize: 1,
            })
          ),
        alertFromStartInMinutes: yup
          .number()
          .integer(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_FROM_START_TEXTFIELD_LABEL'),
            })
          )
          .typeError(f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_ALERT_FROM_START_ONLY_NUMBERS_ERROR_TEXT'))
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            1440,
            f('BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES', {
              name: f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_FROM_START_TEXTFIELD_LABEL'),
              maxsize: 1440,
            })
          )
          .min(
            0,
            f('BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES', {
              name: f('ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_FROM_START_TEXTFIELD_LABEL'),
              minsize: 0,
            })
          ),
      })
      .test(
        'atLeastOneOf',
        f('ACTIVITY_WEEK_REQUIRED_DAY_HELPER_TEXT'),
        (item: CreateActivityForm) =>
          item.recurrenceId !== RecurrenceEnum.Weekly ||
          item.Monday ||
          item.Tuesday ||
          item.Wednesday ||
          item.Thursday ||
          item.Friday ||
          item.Saturday ||
          item.Sunday
      )
  }, [initialActivity, f, user.forceTemplateActivity])

  const name: Name<CreateActivityForm> = React.useMemo(
    () => ({
      title: 'title',
      startDate: 'startDate',
      endDate: 'endDate',
      color: 'color',
      description: 'description',
      visitLengthInMinutes: 'visitLengthInMinutes',
      fromTime: 'fromTime',
      toTime: 'toTime',
      recurrenceId: 'recurrenceId',
      recurrenceInterval: 'recurrenceInterval',
      monthlyDayId: 'monthlyDayId',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
      alertFromStartInMinutes: 'alertFromStartInMinutes',
      showColorOnDevice: 'showColorOnDevice',
      templateActivityId: 'templateActivityId',
      templateActivity: 'templateActivity',
      templateActivityIdExistsInDb: 'templateActivityIdExistsInDb',
      expectedAmount: 'expectedAmount',
      severityLevelId: 'severityLevelId',
      activityParticipants: 'activityParticipants',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useActivityCreateFormData
