import React from 'react'
import { List } from '@mui/material'
import { FieldArrayRenderProps } from 'formik'
import { UserAreaItem } from 'api/tillit.api-client'
import AdditionalAreaListItem from './components/area-list-item'

type Props = Readonly<{
  additionalAreas: UserAreaItem[] | undefined
} & FieldArrayRenderProps>

const ParticipantsList: React.FC<Props> = ({ additionalAreas, ...FieldArrayProps }) => {
  return (
    <List style={{ maxHeight: '50vh', overflow: 'auto' }}>
      {(additionalAreas || []).map((additionalArea: UserAreaItem, index: number) => {
        return (
          <AdditionalAreaListItem
            additionalArea={additionalArea}
            key={`additional-area-list-item-${index}`}
            index={index}
            {...FieldArrayProps}
          />
        )
      })}
    </List>
  )
}

export default ParticipantsList
