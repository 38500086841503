import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const OkDialogContainer: React.FC<{
  dialogTitle: string
  dialogContentText: string
  buttonOkText: string
  okDialogOpen: boolean
  onClose: () => void
}> = ({ dialogTitle, dialogContentText, buttonOkText, okDialogOpen, onClose }) => {
  return (
    <Dialog open={okDialogOpen} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText data-matomo-mask>{dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {buttonOkText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OkDialogContainer
