import React from 'react'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useCurrentAreas } from 'utilities/area-helper'
import useNotificationApi from 'api/notification-api'

const useDashboardPageContext = () => {
  const { fetchAsync } = usePromiseApi()
  const { user : currentUser } = Authentication.useAuthenticatedState()
  const { getCurrentAreaIdsWithEmptyRes : getCurrentAreaIds } = useCurrentAreas(currentUser)
  const [ layoutSize, setLayoutSize ] = React.useState('m')

  //Layout
  const handleLayoutSize = React.useCallback((size) => () => {
    setLayoutSize(size)
  },[])

  //Notification related
  const { saveLatestNotificationReadOnUser } = useNotificationApi()
  const [ numberOfNewNotifications, setNumberOfNewNotifications ] = React.useState<number>(0)
  const [ latestNotificationId, setLatestNotificationId ] = React.useState<number>(-1)

  const saveLatestNotificationId = React.useCallback(() => {
    if (latestNotificationId > 0) {
      fetchAsync(saveLatestNotificationReadOnUser(latestNotificationId))
    }
  }, [fetchAsync, saveLatestNotificationReadOnUser, latestNotificationId])

  return {
    state: {
      currentUser,
      getCurrentAreaIds,
      numberOfNewNotifications,
      layoutSize
    },
    actions: {
      setNumberOfNewNotifications,
      setLatestNotificationId,
      saveLatestNotificationId,
      handleLayoutSize
    },
  }
}

export default createContext(useDashboardPageContext)
