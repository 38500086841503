import React from 'react'
import * as yup from 'yup'

import { UnitCreateItem, IUnitItem, UnitUpdateItem, UnitTypeEnum } from 'api/tillit.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { UnitModalContext } from '../unit-modal-context'

type UnitForm = Readonly<{
  title?: string | undefined;
  abbreviation?: string | undefined;
  unitTypeId: UnitTypeEnum;
  organizationId: number
}>

const createUnit = (values: UnitForm): UnitCreateItem => {
  const { title, abbreviation, unitTypeId, organizationId } = values

  return new UnitCreateItem({
    title,
    abbreviation,
    unitTypeId,
    organizationId,
  })
}

const mapUnitFormToUnitItem = (initialItem: IUnitItem, item: UnitForm): UnitUpdateItem => {
  return new UnitUpdateItem({
    id: initialItem.id,
    title: item.title,
    abbreviation: item.abbreviation,
    unitTypeId: item.unitTypeId,
  })
}

const mapUnitItemToUnitForm = (item: IUnitItem): UnitForm => {
  const createItem: UnitForm = {
    title: item.title,
    abbreviation: item.abbreviation,
    unitTypeId: item.unitTypeId,
    organizationId: item.organizationId,
  }
  return createItem
}

const useUnitFormData = (initialUnit?: IUnitItem) => {
  const f = useFormatMessage()

  const { createUnitAsync, updateUnitAsync } = UnitModalContext.useActions()
  const { ownerOrganizationId } = UnitModalContext.useState()
  const unit = initialUnit

  const initialValues = React.useMemo<UnitForm>(
    () =>
      !!unit
        ? mapUnitItemToUnitForm(unit)
        : {
            title: '',
            abbreviation: '',
            unitTypeId: UnitTypeEnum.TimeUnit,
            organizationId: ownerOrganizationId ? ownerOrganizationId : -1,
          },
    [unit, ownerOrganizationId]
  )

  const onSubmit: OnSubmitFunction<UnitForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const templateActivityCategory = createUnit({
        ...values,
      })
      if (!initialUnit) {
        await createUnitAsync(templateActivityCategory).finally(() => setSubmitting(false))
      } else {
        const mappedUnit = mapUnitFormToUnitItem(initialUnit, values)
        await updateUnitAsync(mappedUnit).finally(() => setSubmitting(false))
      }
    },
    [createUnitAsync, initialUnit, updateUnitAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          40,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_UNIT_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),

      abbreviation: yup.string().max(
        10,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_UNIT_MODAL_DIALOG_ABBREVIATION_COMPONENT_LABEL_DEFAULT'),
          maxsize: 10,
        })
      ),
    })
  }, [f])

  const name: Name<UnitForm> = React.useMemo(
    () => ({
      title: 'title',
      abbreviation: 'abbreviation',
      unitTypeId: 'unitTypeId',
      organizationId: 'organizationId',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useUnitFormData
