import React, { useCallback, useMemo } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import {
  PlannedActivityStatusEnum,
  UnitTypeEnum,
  OrganizationSigningStatusesItem,
  MultiSignActivityItem,
} from 'api/tillit.api-client'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { pickSigningDetails, signingStatusHandleDiff, getStatusColor } from 'utilities/signing-status-helpers'

import { DailyActivitiesContext } from '../../daily-activities-context'
import MultiSignActivitiesModalContext from './multi-sign-activities-modal-context'

import { MultiSignActivitiesModalMainBody } from './multi-sign-activities-modal-style'
import { InfoActivity } from './components/info-activity'

export interface IMultiSignActvitiesModalContainerProps {
  plannedActivityIds: number[]
  selectedUnitTypeId: number | undefined
  signingStatuses: OrganizationSigningStatusesItem | undefined
  open: boolean
  onClose: () => void
  onSigningDone: () => void
}

const MultiSignActivitiesModalContainer: React.FC<IMultiSignActvitiesModalContainerProps> = ({
  plannedActivityIds,
  selectedUnitTypeId,
  signingStatuses,
  open,
  onClose,
  onSigningDone,
}) => {
  const f = useFormatMessage()
  const theme = useTheme()

  React.useEffect(() => {
    fetchActivitiesToMultiSign(plannedActivityIds)
  }, [plannedActivityIds])

  const { fetchState, multiSignActivities } = MultiSignActivitiesModalContext.useState()
  const { onSigningClick, fetchActivitiesToMultiSign } = MultiSignActivitiesModalContext.useActions()
  const { isLoading } = fetchState

  const { refreshData } = DailyActivitiesContext.useActions()

  const handleSigning = React.useCallback((statusId: PlannedActivityStatusEnum) => () => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    void onSigningClick(statusId, plannedActivityIds).then(() => {
      onSigningDone()
      refreshData()
    })
  }, [plannedActivityIds, refreshData])

  const signingButtonRules = useMemo(() => {
    const pickSigningDetailsCb = pickSigningDetails({
      f,
      useDrugList: selectedUnitTypeId !== -1,
      hasUnit: selectedUnitTypeId !== -1,
      unitType: selectedUnitTypeId === -1 ? undefined : (selectedUnitTypeId === 1 ? UnitTypeEnum.TimeUnit : UnitTypeEnum.MeasurementUnit),
      expectedAmount: 0, //plannedActivity?.activity_ExpectedAmount,
      signingStatuses
    })
    return {
      completeGroup: pickSigningDetailsCb({
        timeStatus: PlannedActivityStatusEnum.Completed,
        measurementStatus: PlannedActivityStatusEnum.Given
      }),
      notCompleteGroup: pickSigningDetailsCb({
        timeStatus: PlannedActivityStatusEnum.CompletedWithoutAction,
        measurementStatus: PlannedActivityStatusEnum.NotGiven
      }),
      partiallyCompleteGroup: pickSigningDetailsCb({
        timeStatus: PlannedActivityStatusEnum.PartiallyComplete,
        measurementStatus: PlannedActivityStatusEnum.PartiallyGiven
      }),
      selfCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.SelfGiven
      }),
      relativeCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.RelativeGiven
      }),
      sentAlongCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.SentAlong
      }),
      cannotTakeCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.CannotTake
      }),
      delayedGroup: signingStatusHandleDiff(f, signingStatuses, PlannedActivityStatusEnum.Delayed),
      completeDelayedWithoutActionGroup: signingStatusHandleDiff(f, signingStatuses, PlannedActivityStatusEnum.CompletedWithoutAction),
      startedGroup: signingStatusHandleDiff(f, signingStatuses, PlannedActivityStatusEnum.Started),
    }
  }, [signingStatuses, f])

  const containedButtonResponsiveStyle = useCallback((t) => {
    return {
      width: '100%',
      height: '100%',
      fontSize: '12px',
      [t.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        borderRadius: 0,
        height: '25px',
        letterSpacing: '0.5px'
      }
    }
  }, [])

  return (
    <CenterModal open={open} onClose={onClose} responsive>
      {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
      {!isLoading && plannedActivityIds.length > 0 && (
        <>
          <ModalHeader
            data-matomo-mask
            responsive
            title={f('DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_HEADER_TITLE')}
            subtitle={f('DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_HEADER_SUBTITLE')}
          />
          <MultiSignActivitiesModalMainBody>

            <Box sx={{ maxHeight: '300px', overflowY: 'auto', position: 'relative' }}>
              {multiSignActivities && (multiSignActivities || []).length > 0 ?
                multiSignActivities.map((item: MultiSignActivityItem, i) => {
                  return (
                    <InfoActivity
                      key={i}
                      ownerDescription={item.ownerDescription as (string | undefined)}
                      activityFullName={item.activityFullName as (string | undefined)}
                      plannedStartTime={item.plannedStartTime as Date}
                      routeName={item.routeName as (string | undefined)}
                      routeColor={item.routeColor as (string | undefined)}
                    />
                  )
                })
                :
                <Typography variant="body1" sx={{ fontWeight: 500, position: 'absolute', top: '50%', transform: 'translate(0%, -50%)', width: '100%', textAlign: 'center' }}>
                </Typography>
              }
            </Box>
            <ModalSection
              title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_TITLE')}
              subtitle={f('DAILY_ACTIVITIES_PAGE_MULTISIGN_ACTIVITIES_DIALOG_CONTAINER_INFOSECTION_SUBTITLE')}
              responsive
            >
              <ModalSectionRow sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1 }} responsive noSeparator>
                {(signingButtonRules.completeGroup.visibility && !signingButtonRules.completeGroup.disable) && (
                  <ContainedButton
                    containerStyle={{ marginLeft: 0 }}
                    sx={containedButtonResponsiveStyle}
                    color="success"
                    onClick={handleSigning(signingButtonRules.completeGroup.signingStatusId)}
                  >
                    {signingButtonRules.completeGroup.signingStatusLable}
                  </ContainedButton>
                )}
                {(signingButtonRules.selfCompleteGroup.visibility && !signingButtonRules.selfCompleteGroup.disable) && (
                  <ContainedButton
                    containerStyle={{ marginLeft: 0 }}
                    color={getStatusColor(signingButtonRules.selfCompleteGroup.signingStatusId).code}
                    onClick={handleSigning(signingButtonRules.cannotTakeCompleteGroup.signingStatusId)}
                    sx={containedButtonResponsiveStyle}
                  >
                    {signingButtonRules.selfCompleteGroup.signingStatusLable}
                  </ContainedButton>
                )}
                {(signingButtonRules.relativeCompleteGroup.visibility && !signingButtonRules.relativeCompleteGroup.disable) && (
                  <ContainedButton
                    containerStyle={{ marginLeft: 0 }}
                    color={getStatusColor(signingButtonRules.relativeCompleteGroup.signingStatusId).code}
                    onClick={handleSigning(signingButtonRules.relativeCompleteGroup.signingStatusId)}
                    sx={containedButtonResponsiveStyle}
                  >
                    {signingButtonRules.relativeCompleteGroup.signingStatusLable}
                  </ContainedButton>
                )}
                {(signingButtonRules.sentAlongCompleteGroup.visibility && !signingButtonRules.sentAlongCompleteGroup.disable) && (
                  <ContainedButton
                    containerStyle={{ marginLeft: 0 }}
                    color={getStatusColor(signingButtonRules.sentAlongCompleteGroup.signingStatusId).code}
                    onClick={handleSigning(signingButtonRules.sentAlongCompleteGroup.signingStatusId)}
                    sx={containedButtonResponsiveStyle}
                  >
                    {signingButtonRules.sentAlongCompleteGroup.signingStatusLable}
                  </ContainedButton>
                )}
                {(signingButtonRules.notCompleteGroup.visibility && !signingButtonRules.notCompleteGroup.disable) && (
                  <ContainedButton
                    containerStyle={{ marginLeft: 0 }}
                    color="error"
                    onClick={handleSigning(signingButtonRules.notCompleteGroup.signingStatusId)}
                    sx={containedButtonResponsiveStyle}
                  >
                    {signingButtonRules.notCompleteGroup.signingStatusLable}
                  </ContainedButton>
                )}
                {(signingButtonRules.cannotTakeCompleteGroup.visibility && !signingButtonRules.cannotTakeCompleteGroup.disable) && (
                  <ContainedButton
                    containerStyle={{ marginLeft: 0 }}
                    color={getStatusColor(signingButtonRules.cannotTakeCompleteGroup.signingStatusId).code}
                    onClick={handleSigning(signingButtonRules.cannotTakeCompleteGroup.signingStatusId)}
                    sx={containedButtonResponsiveStyle}
                  >
                    {signingButtonRules.cannotTakeCompleteGroup.signingStatusLable}
                  </ContainedButton>
                )}
              </ModalSectionRow>

            </ModalSection>

          </MultiSignActivitiesModalMainBody>

          <ModalFooter>
            <TextButton type="button" onClick={onClose}>
              {f('BASIC_BUTTON_CLOSE_TEXT')}
            </TextButton>
            <Box pl={1} />
          </ModalFooter>
        </>
      )}

    </CenterModal>
  )
}

export default MultiSignActivitiesModalContainer
