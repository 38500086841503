import { useFormatMessage } from 'localization'
import { generateActivityStatusText } from 'utilities/generate-activity-status-text'
import { ActivityListItem, IActivityListItem, ISidePanelPlannedActivityRowsPlanningViewItem } from 'api/tillit.api-client'
import { isSearchValueValid } from './filter-helper'


export const combinedTitle = (activity: IActivityListItem): string => {
  const { title, templateActivityTitle } = activity
  return templateActivityTitle ? templateActivityTitle + ' ' + (title ? '(' + title + ')' : '') : title ? title : ''
}

export const combinedTitleSideBarActivity = (activity: ISidePanelPlannedActivityRowsPlanningViewItem): string => {
  const { activityTitle, templateActivityTitle } = activity
  return templateActivityTitle ? templateActivityTitle + ' ' + (activityTitle ? '(' + activityTitle + ')' : '') : activityTitle ? activityTitle : ''
}

export const recurrenceText = (f: ReturnType<typeof useFormatMessage>) => (activity: IActivityListItem): string => {
  const textGenerator = generateActivityStatusText(f)
  const { activityRows = [], ...rest } = activity
  const days = activityRows.reduce(
    (prev, current) => {
      if (!!current.weeklyDayId) {
        return { ...prev, [current.weeklyDayId]: true }
      }
      return prev
    },
    {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    }
  )

  const secondary = (activityRows.length > 0
    ? () => {
        const fromTime = new Date()
        fromTime.setHours(activityRows[0].fromTimeHour)
        fromTime.setMinutes(activityRows[0].fromTimeMinute)
        const toTime = new Date()
        toTime.setHours(activityRows[0].toTimeHour)
        toTime.setMinutes(activityRows[0].toTimeMinute)
        return textGenerator({
          ...rest,
          ...days,
          fromTime,
          toTime,
          monthlyDayId: activityRows[0].monthlyDayId,
        })
      }
    : () => '')()

  return secondary
}

export const filterActivities = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  activities: ActivityListItem[] | null
): ActivityListItem[] | null => {
  const activityRecurrenceText = recurrenceText(f)
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return activities
  }

  const filtered = activities?.filter(activity => {
    const searchText = combinedTitle(activity) + ' ' + activityRecurrenceText(activity)
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return null
  }
}
