import React from 'react'
import { IconButton, Box } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'
import { TemplateActivityListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'
import { UserTypeEnum } from 'api/tillit.api-client'
import AuthenticationContext from 'components/authentication/authentication-context'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterTemplateActivities } from 'utilities/template-activity-helper'

import { TemplateActivityModalContext } from '../template-activity-modal/template-activity-modal-context'
import { ConnectTemplateActivitiesToAreasModalContext } from '../connect-template-activities-to-areas-modal/connect-template-activities-to-areas-modal-context'
import ConnectTemplateActivitiesToAreasModal from '../connect-template-activities-to-areas-modal'
import { AdministerOrganizationTemplateActivitiesContext } from './administer-organization-template-activities-context'

const AdministerOrganizationTemplateActivitiesView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const {
    templateActivityItems,
    removeDialogOpen,
    templateActivityToRemove,
    isLoading,
    removeInvalidDialogOpen,
    templateActivityInvalidToRemove,
  } = AdministerOrganizationTemplateActivitiesContext.useState()
  const {
    openRemoveDialog,
    closeRemoveDialog,
    deleteTemplateActivityAsync,
    closeRemoveInvalidDialog,
  } = AdministerOrganizationTemplateActivitiesContext.useActions()

  const { openTemplateActivityModal, setOwnerOrganizationId } = TemplateActivityModalContext.useActions()

  const { openConnectTemplateActivitiesToAreasModal } = ConnectTemplateActivitiesToAreasModalContext.useActions()
  const { modalOpen: connectModalOpen } = ConnectTemplateActivitiesToAreasModalContext.useState()
  const { user } = AuthenticationContext.useAuthenticatedState()
  const userIsSysAdminOrOrgAdminOrTeniloSupport = user.userType === UserTypeEnum.OrganizationAdmin || user.userType === UserTypeEnum.TeniloSupport || user.userType === UserTypeEnum.SystemAdmin

  const f = useFormatMessage()
  const getFilteredTemplateActivities = filterTemplateActivities(f)

  const [selectTemplatesToConnect, setSelectTemplatesToConnect] = React.useState<boolean>(false);
  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<[number] | undefined>(undefined);

  const handleEnableSelectTemplatesToConnect = React.useCallback(() => {
    setSelectTemplatesToConnect(true)
  }, [setSelectTemplatesToConnect])

  const handleCancelSelectTemplatesToConnect = React.useCallback(() => {
    setSelectTemplatesToConnect(false)
    setSelectedTemplateIds(undefined)
  }, [setSelectTemplatesToConnect, setSelectedTemplateIds])

  const onConnectedFinished = React.useCallback(() => {
    setSelectTemplatesToConnect(false)
    setSelectedTemplateIds(undefined)
  }, [])

  const handleConnectTemplates = React.useCallback(() => {
    if (selectedTemplateIds) {
      openConnectTemplateActivitiesToAreasModal(selectedTemplateIds)
    }
  }, [selectedTemplateIds])

  const handleSelectedTemplatesChange = React.useCallback((ids) => {
    setSelectedTemplateIds(ids)
  }, [setSelectedTemplateIds])

  const handleAddNewActivityTemplateClick = React.useCallback(() => {
    setOwnerOrganizationId(currentUser.organizationId)
    openTemplateActivityModal()
  }, [openTemplateActivityModal, currentUser, setOwnerOrganizationId])

  const handleRemoveTemplateActivityClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, templateActivity: TemplateActivityListItem) => {
      _?.stopPropagation()
      openRemoveDialog(templateActivity)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveTemplateActivityClick = React.useCallback(() => deleteTemplateActivityAsync(templateActivityToRemove!.id), [
    templateActivityToRemove,
    deleteTemplateActivityAsync,
  ])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      if (selectTemplatesToConnect) {
        return
      }
      openTemplateActivityModal(params.row.id)
    },
    [openTemplateActivityModal, selectTemplatesToConnect]
  )

  const theme = useTheme()

  const templateActivityCategoriesLookup: any[] = []
  templateActivityItems?.forEach(ta => {
    if (ta.templateActivityCategoryId && !templateActivityCategoriesLookup[ta.templateActivityCategoryId]) {
      templateActivityCategoriesLookup[ta.templateActivityCategoryId] = ta.templateActivityCategoryTitle
    }
  })

  const legislationsLookup: any[] = []
  templateActivityItems?.forEach(ta => {
    if (ta.templateActivityLegislationId && !legislationsLookup[ta.templateActivityLegislationId]) {
      legislationsLookup[ta.templateActivityLegislationId] = ta.templateActivityLegislationTitle
    }
  })

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredTemplateActivities, setFilteredTemplateActivities] = React.useState<TemplateActivityListItem[]>(templateActivityItems)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredTemplateActivities(filterValue, templateActivityItems)
      setFilteredTemplateActivities(filtered)
    } else {
      setFilteredTemplateActivities(templateActivityItems)
    }
    // NOTE: We are missing filterValue and getFilteredTemplateActivities as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [templateActivityItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredTemplateActivities(searchValue, templateActivityItems)
      setFilteredTemplateActivities(filtered)
    },
    [setFilterValue, setFilteredTemplateActivities, templateActivityItems, getFilteredTemplateActivities]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredTemplateActivities(templateActivityItems)
    },
    [setFilterValue, setFilteredTemplateActivities, templateActivityItems]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '0.5vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && userIsSysAdminOrOrgAdminOrTeniloSupport ? (
            <Box sx={{
              display: 'flex',
              justifyContent:'space-between'
            }}>
              <Box sx={{
                display: 'flex',
                flex: '50%',
              }}>
                <ContainedButton type="button" color="secondary" onClick={handleAddNewActivityTemplateClick}>
                  {f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_ADD_BUTTON_TEXT')}
                </ContainedButton>
                <>
                  {!selectTemplatesToConnect && (
                    <ContainedButton type="button" color="secondary" onClick={handleEnableSelectTemplatesToConnect}>
                      {f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_CHOOSE_TEMPLATES_TO_CONNECT_BUTTON_TEXT')}
                    </ContainedButton>
                  )}
                  {selectTemplatesToConnect && (
                    <>
                      <ContainedButton type="button" color="secondary" onClick={handleConnectTemplates} disabled={(selectedTemplateIds || []).length === 0}>
                        {f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_CONNECT_TEMPLATES_BUTTON_TEXT')}
                      </ContainedButton>
                      <ContainedButton type="button" color="secondary" onClick={handleCancelSelectTemplatesToConnect}>
                        {f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_ABORT_CONNECT_TEMPLATES_BUTTON_TEXT')}
                      </ContainedButton>
                    </>
                  )}
                </>
              </Box>
              <div style={{width:'25%', paddingBottom: '1vh'}}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </Box>
          ) : (
            <div style={{ columnCount: 2 }}>
              <div>&nbsp;</div>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredTemplateActivities}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            checkboxSelection={selectTemplatesToConnect}
            onSelectionModelChange={handleSelectedTemplatesChange}
            onRowClick={handleRowClick}
            localeText={muiDataGridLocale(f)()}
            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_NAME'),
                field: 'title'
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_TYPE'),
                field: 'templateActivityTypeId',
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      {params.row.templateActivityTypeId === "CustomerTemplate"  ? f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_CUSTOMER') : f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_AREA')}
                    </span>
                  )
                },
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_CATEGORY'),
                field: 'templateActivityCategoryTitle',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_LEGISLATION'),
                field: 'templateActivityLegislationTitle',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_deactivate',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.title,
                        })}
                        aria-label={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.title
                        })}>
                        <IconButton disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport} onClick={e => handleRemoveTemplateActivityClick(e, params.row)} size="large">
                          <Delete color={userIsSysAdminOrOrgAdminOrTeniloSupport ? 'secondary' : 'disabled'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />

          {removeDialogOpen && templateActivityToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_MESSAGE', {
                name: templateActivityToRemove.title,
              })}
              buttonSubmitText={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen={true}
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemoveTemplateActivityClick}
            />
          )}

          {removeInvalidDialogOpen && templateActivityInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_MESSAGE', {
                title: templateActivityInvalidToRemove.title,
              })}
              buttonOkText={f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen={true}
              onClose={closeRemoveInvalidDialog}
            />
          )}

          {connectModalOpen && (
            <ConnectTemplateActivitiesToAreasModal onSave={onConnectedFinished} />
          )}

        </>
      )}
    </div>
  )
}

export default AdministerOrganizationTemplateActivitiesView
