import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import Colors from 'styles/colors'

const ModalFooterStyle = styled(Box)(({theme})=>css`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-end;
  background-color: ${() => Colors.AthensGray};
  padding: ${theme.spacing(2)} ${theme.spacing(3)};
  ${theme.breakpoints.down('md')}{
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  }
`)

export default ModalFooterStyle
