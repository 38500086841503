import { useDelegationApi } from 'api'
import {
  DelegationCategoryItem,
  DelegationCategoryCreateItem,
  DelegationCategoryUpdateItem,
} from 'api/tillit.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import Authentication from 'components/authentication'

import { AdministerDelegationCategoriesContext } from '../../administer-delegation-categories-context'

const useDelegationCategoryModalContext = () => {
  const { getDelegationCategory, updateDelegationCategory, createDelegationCategory } = useDelegationApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const { fetchTemplateActivitiesAsync: fetchDelegationCategoriesAsync } = AdministerDelegationCategoriesContext.useActions()

  const [organizationId, setOrganizationId] = React.useState<number | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [selectedDelegationCategory, setSelectedDelegationCategory] = React.useState<DelegationCategoryItem | undefined>()

  const fetchDelegationCategoryAsync = React.useCallback(
    async (delegationCategoryId: number) => {
      await fetchAsync(getDelegationCategory(delegationCategoryId)).then(ita => {
        if (ita instanceof DelegationCategoryItem) {
          setSelectedDelegationCategory(ita)
        }
      })
    },
    [fetchAsync, getDelegationCategory]
  )

  const openDelegationCategoryModal = React.useCallback(
    (delegationId?: number) => {
      if (!!delegationId) {
        void fetchDelegationCategoryAsync(delegationId).then(_ => setModalOpen(true))
      } else {
        setModalOpen(true)
      }
    },
    [fetchDelegationCategoryAsync]
  )

  const closeDelegationCategoryModal = React.useCallback(() => {
    setSelectedDelegationCategory(undefined)
    setModalOpen(false)
  }, [])

  const createDelegationCategoryAsync = React.useCallback(
    (createDelegationCategoryModel: DelegationCategoryCreateItem) => {
      return fetchAsync(
        createDelegationCategory({
          ...createDelegationCategoryModel,
        })
      ).then(_ => {
        void fetchDelegationCategoriesAsync()
        closeDelegationCategoryModal()
      })
    },
    [fetchAsync, createDelegationCategory, closeDelegationCategoryModal, fetchDelegationCategoriesAsync]
  )

  const updateDelegationCategoryAsync = React.useCallback(
    (delegationCategory: DelegationCategoryUpdateItem) => {
      return fetchAsync(updateDelegationCategory(delegationCategory))
        .then(_ => {
          void fetchDelegationCategoriesAsync()
          closeDelegationCategoryModal()
        })
        .catch(() => {
          closeDelegationCategoryModal()
        })
    },
    [closeDelegationCategoryModal, fetchAsync, fetchDelegationCategoriesAsync, updateDelegationCategory]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedDelegationCategory,
      organizationId,
    },
    actions: {
      openDelegationCategoryModal,
      closeDelegationCategoryModal,
      createDelegationCategoryAsync,
      updateDelegationCategoryAsync,
      setOrganizationId,
    },
  }
}

export const DelegationCategoryModalContext = createContext(useDelegationCategoryModalContext)
