import styled from 'styled-components'
import SidebarView from './sidebar-view'

const SidebarStyles = styled(SidebarView)`
  ${({ theme, isOpen }) => `
    position: relative;
    min-width: 688px;
    width: 688px;
    background-color: white;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.12);
    margin: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0;
    border-radius: 4px;
  `}
`;

export default SidebarStyles
