import Box, { BoxProps } from '@mui/material/Box'
import React from 'react'

import ModalFooterStyle from './modal-footer-style'

const ModalFooterView: React.SFC<BoxProps> = ({ children, ...rest }) => (
  <ModalFooterStyle {...rest}>
    {React.Children.map(children, (childe, index) => (
      <React.Fragment key={index}>
        <Box pl={1} />
        {childe}
      </React.Fragment>
    ))}
  </ModalFooterStyle>
)

export default ModalFooterView
