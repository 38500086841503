import * as React from 'react'
import { TextField, Autocomplete } from "@mui/material"
import { AreaListItem } from 'api/tenilo.support.api-client'

type AreaSelectListProps = {
  multiple?: boolean,
  options: AreaListItem[],
  value?: AreaListItem,
  onChange?: (value: any) => void,
  textFieldLable?: string,
  filterOptions?: <T>(options: T[], state: Record<string, any>) => T[]
}

const AreaSelectList: React.FC<AreaSelectListProps> = ({
  multiple,
  textFieldLable,
  options,
  filterOptions,
  onChange,
  value,
  ...props
}) => {
  const getOptionLable = React.useCallback((option: any) => {
    if (!option) {
      return ""
    }
    return (`${option["name"]} ${(option["organizationParentsName"] && option["organizationParentsName"].length > 0 ? `(${option["organizationParentsName"]})` : '')}`) || ""
  }, [])

  const handleChange = React.useCallback((e, newValue: any) => {
    if (onChange) { onChange(newValue) }
  }, [])

  return React.useMemo(() => {
    return (
      <Autocomplete
        data-matomo-mask
        value={value}
        onChange={handleChange}
        multiple={multiple}
        options={options}
        limitTags={10}
        disableCloseOnSelect={multiple}
        getOptionLabel={getOptionLable}
        renderOption={(props, option: any) => {
          return (
            <li data-matomo-mask {...props} key={`area-select-${props.id}`}>
              {getOptionLable(option)}
            </li>
          )
        }}
        renderInput={(params) => <TextField data-matomo-mask {...params} label={textFieldLable} />}
      />
    )

  }, [options])
}

export default React.memo(AreaSelectList)
