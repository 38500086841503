import { Box, Typography } from '@mui/material'
import React from 'react'
import format from 'utilities/format'

export type InfoActivityComponentProps = Readonly<{
  plannedStartTime: Date
  ownerDescription: string | undefined
  activityFullName: string | undefined
  routeName: string | undefined
  routeColor: string | undefined
  className?: string
}>

export const InfoActivityView: React.FC<InfoActivityComponentProps> = ({ plannedStartTime, ownerDescription, activityFullName, routeName, routeColor, className }) => {
  return (
    <Box className={className} py={1} px={1}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1" style={{ opacity: 0.6 }}>
            {format(plannedStartTime, 'PPP p')}
          </Typography>
          <Box pr={1} />
          <Typography data-matomo-mask variant="subtitle1">
            {`${ownerDescription}`}
          </Typography>
          <Box pr={1} />
          <Typography data-matomo-mask variant="subtitle1">
            {`${activityFullName}`}
          </Typography>

        </Box>
        <Box display="flex" flexDirection="row" alignItems="right">
          <Box sx={{
            backgroundColor: routeColor,
            borderRadius: '25px',
            height: '10px',
            width: '10px',
            minHeight: '10px',
            minWidth: '10px',
            margin: '5px',
            border: '1px solid #6e6e6e',
            alignSelf: 'center'
          }} />
          <Typography data-matomo-mask variant="subtitle1">
            {`${routeName}`}
          </Typography>
        </Box>
      </Box>

    </Box>
  )
}
