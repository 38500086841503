import React from 'react'

import {
    ReportListItem,
    ReportItem,
    ReportSelectionsListItem,
    ReportFilterSelection,
    ReportDataItem,
    UserReportItem,
    ReportsClient,
    UserReportCreateItem,
    UserReportUpdateItem
} from 'api/tenilo.reporting.api-client'
import { REPORTING_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class ReportingApi {
    private readonly abortController = new AbortController()
    private readonly client: ReportsClient

    public constructor() {
        this.client = new ReportsClient(REPORTING_API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public listReports = (organizationId: number): Promise<ReportListItem[]> =>
        this.client
            .listReports(organizationId)
            .then(items =>
                items === null
                    ? Promise.reject(`No reports received from backend for organization id ${organizationId}.`)
                    : Promise.resolve(items)
            )

    public getReport = (reportId: number): Promise<ReportItem> =>
        this.client
            .getReport(reportId)
            .then(item =>
                item === null
                    ? Promise.reject(`No report received from backend for report id ${reportId}.`)
                    : Promise.resolve(item)
            )

    public getUserReport = (userReportId: number): Promise<UserReportItem> =>
        this.client
            .getUserReport(userReportId)
            .then(item =>
                item === null
                    ? Promise.reject(`No user report received from backend for report id ${userReportId}.`)
                    : Promise.resolve(item)
            )

    public getReportSelectionListItem = (reportId: number): Promise<ReportSelectionsListItem> =>
        this.client
            .getReportSelectionLists(reportId)
            .then(item =>
                item === null
                    ? Promise.reject(`No user report received from backend for report id ${reportId}.`)
                    : Promise.resolve(item)
            )

    public getReportData = (reportId: number, filterSelections: ReportFilterSelection[]): Promise<ReportDataItem> =>
        this.client
            .getReportData(reportId, filterSelections)
            .then(item =>
                item === null
                    ? Promise.reject(`No report data received from backend for report id ${reportId}.`)
                    : Promise.resolve(item)
            )

    public createUserReport = (model: UserReportCreateItem): Promise<UserReportItem> =>
        this.client
            .createUserReport(model)
            .then(item =>
                item === null
                    ? Promise.reject(`No report data received from backend for report id ${model}.`)
                    : Promise.resolve(item)
            )

    public updateUserReport = (model: UserReportUpdateItem): Promise<UserReportItem> =>
        this.client
            .updateUserReport(model)
            .then(item =>
                item === null
                    ? Promise.reject(`No report data received from backend for report id ${model}.`)
                    : Promise.resolve(item)
            )

    public removeUserReport = (reportId: number) =>
        this.client
            .deleteUserReport(reportId)
            .then(() => Promise.resolve(reportId))

}

const useReportingApi = () => React.useMemo(() => new ReportingApi(), [])

export default useReportingApi
