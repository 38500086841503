import { ReportFieldItem, ReportItem, UserReportItem } from 'api/tenilo.reporting.api-client';
import { IUserItem } from 'api/tillit.api-client';
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver';
import { useFormatMessage } from 'localization'
import {
  getReportName, getReportDescription, createReportFilename,
  extractReportHeadersAndData, extractUserReportHeadersAndData, fillAndTextColorFromAlpha, FillAndTextColor
} from './report-helper';

const decorateWorksheet = (worksheet: ExcelJS.Worksheet) => {
  for (let i = 0; i < worksheet.columns.length; i++) {
    const column = worksheet.getColumn(i + 1)
    let maxColumnWidth = 0;
    column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      // bold header
      if (rowNumber === 1) {
        cell.font = {
          bold: true
        }
      }

      // calculate approximate column width
      maxColumnWidth = Math.max(
        maxColumnWidth,
        10, // minimalWidth
        cell.value ? cell.value.toString().length : 0
      )
    })
    column.width = maxColumnWidth + 4 // add some extra space
  }

  // set up filter
  worksheet.autoFilter = {
    from: {
      column: 1,
      row: 1
    },
    to: {
      column: worksheet.columns.length,
      row: worksheet.rowCount
    }
  }

}

const processAlphaChannel = (worksheet: ExcelJS.Worksheet, alphaChannel: any[]) => {

  worksheet.eachRow((row, rownumber) => {
    row.eachCell((cell, colNumber) => {
      if (colNumber < 1 || rownumber < 2) { return }
      const alphaRow = alphaChannel[rownumber - 2]
      const ftColor = fillAndTextColorFromAlpha(+(alphaRow[colNumber - 1])) as FillAndTextColor
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: ftColor.fillColor.replace('#', 'FF') },
      }

      cell.font = {
        color: { argb: ftColor.textColor.replace('#', 'FF') }
      }
    })
  })

}

const fillWorkbook = (f: ReturnType<typeof useFormatMessage>, report: ReportItem | undefined, userReport: UserReportItem | undefined, dataRows: string[],
  alphaChannelRows: string[] | undefined, workbook: ExcelJS.Workbook, user: IUserItem, formFields: ReportFieldItem[] | undefined, sortedFieldItems: ReportFieldItem[] | undefined) => {
  const sheet = workbook.addWorksheet(getReportName(report, userReport), { views: [{ state: 'frozen', ySplit: 1 }] })

  let headersAndRows: any[] = []
  if (report) {
    const replaceCheckMark = false
    headersAndRows = extractReportHeadersAndData(f, report, dataRows, alphaChannelRows, user, replaceCheckMark, formFields, sortedFieldItems)
  }

  if (userReport) {
    headersAndRows = extractUserReportHeadersAndData(f, userReport, dataRows, alphaChannelRows, user, formFields, sortedFieldItems)
  }

  const cols: Array<Partial<ExcelJS.Column>> = []
  headersAndRows[0][0].forEach((headerName: string, index: number) => {
    cols.push({ header: `${headerName}`, key: `rf_key_${index}` })
  })
  sheet.columns = cols

  headersAndRows[1].forEach(dataRow => {
    const rowData = {}
    dataRow.forEach((dataValue: string, index: number) => {
      rowData[`rf_key_${index}`] = dataValue
    })
    sheet.addRow(JSON.parse(JSON.stringify(rowData)))
  })

  decorateWorksheet(sheet)

  if (headersAndRows[2] && headersAndRows[2].length > 0) {
    processAlphaChannel(sheet, headersAndRows[2])
  }


}


const createWorkbookFromReport = (f: ReturnType<typeof useFormatMessage>, report: ReportItem | undefined, userReport: UserReportItem | undefined, dataRows: string[],
  alphaChannelRows: string[] | undefined, user: IUserItem, formFields: ReportFieldItem[] | undefined, sortedFieldItems: ReportFieldItem[] | undefined): ExcelJS.Workbook => {
  if (report && userReport) {
    throw new Error("Both report and userReport cannot be set! Aborting")
  }

  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'tenilo.se'
  workbook.created = new Date()
  workbook.title = getReportName(report, userReport)
  workbook.description = getReportDescription(report, userReport)

  fillWorkbook(f, report, userReport, dataRows, alphaChannelRows, workbook, user, formFields, sortedFieldItems)
  return workbook
}

export const saveReportToXlsx = (f: ReturnType<typeof useFormatMessage>) => async (report: ReportItem | undefined, userReport: UserReportItem | undefined, dataRows: string[],
  alphaChannelRows: string[], user: IUserItem, formFields: ReportFieldItem[] | undefined, sortedFieldItems: ReportFieldItem[] | undefined): Promise<void> => {
  const workbook = createWorkbookFromReport(f, report, userReport, dataRows, alphaChannelRows, user, formFields, sortedFieldItems)
  const fileName = createReportFilename(report, userReport, 'xlsx')
  workbook.xlsx.writeBuffer().then(buffer => {
    saveAs(new Blob([buffer]), fileName)
  })
}

export const saveReportToCsv = (f: ReturnType<typeof useFormatMessage>) => async (report: ReportItem | undefined, userReport: UserReportItem | undefined, dataRows: string[],
  user: IUserItem, formFields: ReportFieldItem[] | undefined, sortedFieldItems: ReportFieldItem[] | undefined): Promise<void> => {
  const workbook = createWorkbookFromReport(f, report, userReport, dataRows, undefined, user, formFields, sortedFieldItems)
  const fileName = createReportFilename(report, userReport, 'csv')
  workbook.csv.writeBuffer({
    formatterOptions: {
      delimiter: ';',
      writeBOM: true
    }
  }).then(buffer => {
    saveAs(new Blob([buffer]), fileName)
  })
}

