import { Box, useTheme } from '@mui/material'
import SelectInput from 'components/inputs/select-input'
import CenterModal from 'components/center-modal'
import { LegislationListItem } from 'api/tillit.api-client'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { TemplateActivityCategoryModalContext } from './template-activity-category-modal-context'
import useFormData from './utilities/use-template-activity-category-form-data'



export type TemplateActivityCategoryModalProps = Readonly<{
  propsTitle?: string
  propsSubtitle?: string
  ownerAreaId?: number
  activeAreaId?: number
  onSave?: () => void
}>

const TemplateActivityCategoryModalContainer: React.FC<TemplateActivityCategoryModalProps> = ({ propsTitle, ownerAreaId, onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { modalOpen, selectedTemplateActivityCategory, isLoading, legislations } = TemplateActivityCategoryModalContext.useState()

  const { setOwnerAreaId } = TemplateActivityCategoryModalContext.useActions()

  React.useEffect(() => {
    setOwnerAreaId(ownerAreaId)
  }, [ownerAreaId, setOwnerAreaId])

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedTemplateActivityCategory)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeTemplateActivityCategoryModal } = TemplateActivityCategoryModalContext.useActions()

  const getLegislationTitle = (item: any) => {
    const l = item as LegislationListItem
    return l?.title ? l.title : ""
  }

  return (
    <CenterModal open={modalOpen} onClose={closeTemplateActivityCategoryModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {
          //const { ...statusProps } = values

          //const handleChangeLegislation = (_: React.ChangeEvent<{}>, value: LegislationListItem) => {
          const handleChangeLegislation = (_: React.ChangeEvent<{}>, value: any) => {
            const lV = value as LegislationListItem
            if (lV) {
              setFieldValue('legislationId', lV.id)
            } else {
              setFieldValue('legislationId', undefined)
            }
          }

          return (
            <Form translate="yes">
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={
                  propsTitle
                    ? propsTitle
                    : selectedTemplateActivityCategory
                      ? f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_HEADER_EDIT_TITLE')
                      : f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_HEADER_TITLE')
                }
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>
                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          label={f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT')}
                          name={name.title}
                          color="secondary"
                          fullWidth={true}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_LEGISLATION_TITLE')}
                    subtitle={f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_LEGISLATION_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Box flex={1}>
                          <SelectInput
                            label={f('ADMINISTER_TEMPLATE_ACTIVITITY_CATEGORY_MODAL_DIALOG_SELECT_LEGISLATION_COMPONENT_LABEL_DEFAULT')}
                            onChange={handleChangeLegislation}
                            name={name.legislation}
                            options={legislations as any}
                            getOptionLabel={getLegislationTitle}
                            fullWidth={true}
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeTemplateActivityCategoryModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedTemplateActivityCategory ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default TemplateActivityCategoryModalContainer
