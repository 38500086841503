import { useDelegationApi } from 'api'
import {
  UserDelegationAvailablePlacementsItem,
  DelegationAvailableItem,
  UserTypeEnum,
  RoleEnum
} from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import { UsersPageContext } from 'pages/users/users-page-context'
import { FieldArrayRenderProps } from 'formik'
import { rootUser } from 'utilities/use-user-areas'
import useAuthorization from 'utilities/use-authorization'

type DeactiveDelegationPlacementItemProps = Readonly<
  {
    delegationIndex: number
    placementIndex: number
    props: FieldArrayRenderProps
  }
>

const DelegationSectionContext = () => {
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { user, loggedInUser } = UserDialogContext.useState()
  const { listUserDelegations, listUserPlacements, abort } = useDelegationApi()
  const [delegationItems, setDelegationItems] = React.useState<DelegationAvailableItem[]>([])
  const { activeUsers } = UsersPageContext.useState()
  const { getAdminAreas } = useAuthorization()

  const fetchDelegationAvailableItem = React.useCallback(async (delegationId: number) => {
    if (!user?.id) { return }
    try {
      let availablePlacementsItem: UserDelegationAvailablePlacementsItem = await fetchAsync(listUserPlacements(user.id, delegationId))
      if (!availablePlacementsItem) return null
      if(!rootUser(currentUser) && availablePlacementsItem.areas){
        const adminAreas = getAdminAreas([RoleEnum.Planner])
        availablePlacementsItem.areas = availablePlacementsItem.areas.filter(area => adminAreas?.some(adminArea => adminArea.areaId === area.id))
      }
      return availablePlacementsItem
    } catch (error) {
      return null
    }
  }, [currentUser.organizationId, fetchAsync, user, listUserPlacements])

  const fetchDelegationsAsync = React.useCallback(async () => {
    if (!user?.id) { return }
    fetchAsync(listUserDelegations(user?.id)).then(setDelegationItems)
  }, [currentUser.organizationId, fetchAsync, user, listUserDelegations])

  React.useEffect(() => {
    fetchDelegationsAsync()
    return () => abort()
  }, [fetchDelegationsAsync])

  return {
    state: {
      ...stateOfFetch,
      delegationItems,
      user,
      loggedInUser
    },
    actions: {
      fetchDelegationAvailableItem
    },
  }
}

export default createContext(DelegationSectionContext)
