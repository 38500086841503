import React from 'react'
import { useDelegationApi } from 'api'
import { DelegationCategoryListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'

export type DelegationCreateModel = Readonly<{
  title: string
  ownerAreaId: number
  fromTimeHour: number
  fromTimeMinute: number
  toTimeHour: number
  toTimeMinute: number
}>

const useAdministerDelegationCategoriesContext = () => {
  const {
    listDelegationCategories,
    deleteDelegationCategory,
    hasDelegationCategoryConnectedToDelegation,
    abort,
  } = useDelegationApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [delegationCategoryListItems, setDelegationCategoryListItems] = React.useState<DelegationCategoryListItem[]>([])
  const [modalOpen, setModalOpen] = React.useState(false)

  const location = useLocation()

  const fetchTemplateActivitiesAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listDelegationCategories(currentUser.organizationId).then(setDelegationCategoryListItems))
  }, [currentUser.organizationId, fetchAsync, listDelegationCategories])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    void fetchTemplateActivitiesAsync()
  }, [fetchTemplateActivitiesAsync, location])

  const { state: stateOfRemoveFetch, fetchAsync: removeFetchAsync } = usePromiseApi()

  const [delegationToRemove, setDelegationToRemove] = React.useState<DelegationCategoryListItem | null>(null)

  const removeDialogOpen = React.useMemo(() => delegationToRemove !== null, [delegationToRemove])

  const [delegationInvalidToRemove, setDelegationInvalidToRemove] = React.useState<DelegationCategoryListItem | null>(null)

  const removeInvalidDialogOpen = React.useMemo(() => delegationInvalidToRemove !== null, [delegationInvalidToRemove])

  const closeRemoveInvalidDialog = React.useCallback(() => setDelegationInvalidToRemove(null), [setDelegationInvalidToRemove])

  const openRemoveDialog = React.useCallback(
    (delegationCategory: DelegationCategoryListItem) => {
      void hasDelegationCategoryConnectedToDelegation(delegationCategory.id).then(result => {
        if (result) {
          setDelegationInvalidToRemove(delegationCategory)
        } else {
          setDelegationToRemove(delegationCategory)
        }
      })
    },
    [setDelegationToRemove, setDelegationInvalidToRemove, hasDelegationCategoryConnectedToDelegation]
  )

  const closeRemoveDialog = React.useCallback(() => setDelegationToRemove(null), [setDelegationToRemove])

  const deleteDelegationAsync = React.useCallback(
    delegationId => {
      void removeFetchAsync(
        deleteDelegationCategory(delegationId)
          .then(fetchTemplateActivitiesAsync)
          .then(closeRemoveDialog)
      )
    },
    [removeFetchAsync, deleteDelegationCategory, fetchTemplateActivitiesAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      delegationCategoryListItems,
      modalOpen,
      removeDialogOpen,
      delegationToRemove,
      delegationInvalidToRemove,
      isRemoving,
      removeInvalidDialogOpen,
    },
    actions: {
      fetchTemplateActivitiesAsync,
      openModal,
      closeModal,
      openRemoveDialog,
      closeRemoveDialog,
      closeRemoveInvalidDialog,
      deleteDelegationAsync,
    },
  }
}

export const AdministerDelegationCategoriesContext = createContext(useAdministerDelegationCategoriesContext)
