import React from 'react'

import {
  ActivityClient,
  ActivityCreateItem,
  ActivityItem,
  IActivityCreateItem,
  IActivityItem,
  IMovePlannedActivityRowSeriesItem,
  IPlannedActivityRowItem,
  IPlannedActivityRowSigningCreateItem,
  MovePlannedActivityRowSeriesItem,
  PlannedActivityRowItem,
  PlannedActivityRowSigningCreateItem,
  PlannedActivityRowSigningItem,
  PlannedActivityStatusEnum,
  UnplanAllPlannedActivityRowSeriesItem,
  RecentPerformedSigningItem,
  FulfilledDelegationItem,
  FuturePlannedActivityItem,
  DailyActivityItem,
  MultiSignItem,
  MultiSignActivityItem,
  FileResponse,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class ActivityApi {
  private readonly abortController = new AbortController()
  private readonly client: ActivityClient

  constructor() {
    this.client = new ActivityClient(API_URL, httpMiddleware({ signal: this.abortController.signal,headers:{'key-name': 'activity'} }))
  }

  public abort = () => this.abortController.abort()

  public get = (activityId: number): Promise<ActivityItem> =>
    this.client
      .getActivity(activityId)
      .then(activityItem =>
        activityItem === null ? Promise.reject(`No activity item received from backend for activity id ${activityId}.`) : Promise.resolve(activityItem)
      )

  public getSignedActivities = (areaId: number, fromDateMills: Date, toDateMills: Date): Promise<PlannedActivityRowSigningItem[]> =>
    this.client
      .getAreasPlannedActivityRowSignings(areaId, fromDateMills, toDateMills)
      .then(activityItem =>
        activityItem === null ? Promise.reject(`No activity item received from backend for activity id ${areaId}.`) : Promise.resolve(activityItem)
      )

  public getPlannedActivityDelaySignings = (plannedActivityRowId: number): Promise<PlannedActivityRowSigningItem[]> =>
    this.client
      .getPlannedActivityRowDelaySignings(plannedActivityRowId)
      .then(delaySignings =>
        delaySignings === null ? Promise.reject(`No delay signings received for plannedActivityRowId ${plannedActivityRowId}.`) : Promise.resolve(delaySignings)
      )

  public getActiveDelayedSignings = (plannedActivityRowId: number): Promise<PlannedActivityRowSigningItem[]> =>
    this.client
      .getActiveDelayedSignings(plannedActivityRowId)
      .then(delaySignings =>
        delaySignings === null
          ? Promise.reject(`No active delayed signings received for plannedActivityRowId ${plannedActivityRowId}.`)
          : Promise.resolve(delaySignings)
      )

  public getRecentlyPerformedSignings = (plannedActivityRowId: number): Promise<RecentPerformedSigningItem[]> =>
    this.client
      .getRecentActivitySignings(plannedActivityRowId)
      .then(signings =>
        signings === null ? Promise.reject(`No signings received for plannedActivityRowId ${plannedActivityRowId}.`) : Promise.resolve(signings)
      )

  public listRecentPerformedActivitiesOnCustomer = (customerId: number): Promise<RecentPerformedSigningItem[]> =>
    this.client
      .listRecentPerformedActivitiesOnCustomer(customerId)
      .then(signings => (signings === null ? Promise.reject(`No signings received for customerId ${customerId}.`) : Promise.resolve(signings)))

  public listFuturePlannedActivitiesOnCustomer = (customerId: number): Promise<FuturePlannedActivityItem[]> =>
    this.client
      .listFuturePlannedActivitiesOnCustomer(customerId)
      .then(items => (items === null ? Promise.reject(`No future planned activity items received for customerId ${customerId}.`) : Promise.resolve(items)))



  public getDelayedActivityShouldOccurToday = (plannedActivityRowId: number, kioskGuid: string, start: Date, end: Date): Promise<boolean> => {
    return this.client
      .getDelayedActivityShouldOccurToday(plannedActivityRowId, kioskGuid, start, end)
      .then(shouldOccur =>
        shouldOccur === null ? Promise.reject(`No shouldOccur received for plannedActivityRowId ${plannedActivityRowId}.`) : Promise.resolve(shouldOccur)
      )
  }

  public getActivityHasActiveDelayedOccurance = (plannedActivityRowId: number): Promise<boolean> =>
    this.client
      .getActivityHasActiveDelayedOccurance(plannedActivityRowId)
      .then(hasActiveDelayedOccurance =>
        hasActiveDelayedOccurance === null
          ? Promise.reject(`No hasActiveDelayedOccurance received for plannedActivityRowId ${plannedActivityRowId}.`)
          : Promise.resolve(hasActiveDelayedOccurance)
      )

  public isTemporaryActivityCreatedToday = (plannedActivityRowId: number): Promise<boolean> =>
    this.client
      .isTemporaryActivityCreatedToday(plannedActivityRowId)
      .then(isCreatedToday =>
        isCreatedToday === null
          ? Promise.reject(`No hasActiveDelayedOccurance received for plannedActivityRowId ${plannedActivityRowId}.`)
          : Promise.resolve(isCreatedToday)
      )

  public getPlannedDailyActivity = (plannedActivityRowId: number): Promise<DailyActivityItem> =>
    this.client
      .getPlannedDailyActivity(plannedActivityRowId)
      .then(dailyActivityItem => dailyActivityItem === null
        ? Promise.reject(`No dailyActivityItem received for plannedActivityRowId ${plannedActivityRowId}.`)
        : Promise.resolve(dailyActivityItem)
      )

  public deleteByPlannedActivityRowId = (plannedActivityRowId: number) =>
    this.client
      .deleteActivityByPlannedActivityRow(plannedActivityRowId).then(() => Promise.resolve(plannedActivityRowId))

  public listNotFulfilledDelegations = (plannedActivityRowId: number): Promise<FulfilledDelegationItem[]> =>
    this.client
      .listNotFulfilledDelegations(plannedActivityRowId)
      .then(notFulfilledDelegations =>
        notFulfilledDelegations === null
          ? Promise.reject(`No notFulfilledDelegations received for plannedActivityRowId ${plannedActivityRowId}.`)
          : Promise.resolve(notFulfilledDelegations)
      )

  public getPlannedActivityRow = (activityId: number): Promise<PlannedActivityRowItem> =>
    this.client
      .getPlannedActivityRow(activityId)
      .then(plannedActivityRow =>
        plannedActivityRow === null
          ? Promise.reject(`No planned activity received from backend for activity id ${activityId}.`)
          : Promise.resolve(plannedActivityRow)
      )

  public list = (customerId: number) =>
    this.client
      .listCustomerActivities(customerId)
      .then(activityItems =>
        activityItems === null ? Promise.reject(`No activity items received from backend for customer id ${customerId}.`) : Promise.resolve(activityItems)
      )

  public listAreaActivities = (areaId: number) =>
    this.client
      .listAreaActivities(areaId)
      .then(activityItems =>
        activityItems === null ? Promise.reject(`No activity items received from backend for area id ${areaId}.`) : Promise.resolve(activityItems)
      )

  public listFuturePenMarkedActivities = (plannedActivityRowId: number, onlyTodaysSeries: boolean) =>
    this.client
      .listFuturePenMarkedActivities(plannedActivityRowId, onlyTodaysSeries)
      .then(activityItems =>
        activityItems === null
          ? Promise.reject(`No future pen marked activity items received from backend for plannedActivityRowId ${plannedActivityRowId}.`)
          : Promise.resolve(activityItems)
      )

  public listFutureChangedActivities = (plannedActivityRowId: number, onlyTodaysSeries: boolean) =>
    this.client
      .listFutureChangedActivities(plannedActivityRowId, onlyTodaysSeries)
      .then(activityItems =>
        activityItems === null
          ? Promise.reject(`No future changes activity items received from backend for plannedActivityRowId ${plannedActivityRowId}.`)
          : Promise.resolve(activityItems)
      )

  public create = (activity: IActivityCreateItem) => {
    const model = new ActivityCreateItem(activity)
    return this.client
      .create(model)
      .then(activityItem =>
        activityItem === null ? Promise.reject(`When creating an activity, no activity item received from backend.`) : Promise.resolve(activityItem)
      )
  }

  public update = (activity: IActivityItem) => {
    const model = new ActivityItem(activity)
    return this.client
      .updateActivity(model)
      .then(activityItem =>
        activityItem === null ? Promise.reject(`When creating an activity, no activity item received from backend.`) : Promise.resolve(activityItem)
      )
  }

  public delete = (activityId: number) => this.client.deleteActivity(activityId).then(() => Promise.resolve(activityId))

  public createTemporaryActivity = (activity: IActivityCreateItem, routeId: number) => {
    const model = new ActivityCreateItem(activity)
    return this.client
      .createOneTimeActivity(routeId, model)
      .then(activityItem =>
        activityItem === null ? Promise.reject(`When creating an temporary activity, no activity item received from backend.`) : Promise.resolve(activityItem)
      )
  }

  public completePlannedActivity = (activity: IPlannedActivityRowSigningCreateItem): Promise<IPlannedActivityRowItem> => {
    const model = new PlannedActivityRowSigningCreateItem({
      ...activity
    })
    return this.client
      .signPlannedActivityRow(model)
      .then(item => (item === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(item)))
  }

  public completePlannedActivityWithoutAction = (
    activity: IPlannedActivityRowSigningCreateItem
  ): Promise<IPlannedActivityRowItem> => {
    const model = new PlannedActivityRowSigningCreateItem({
      ...activity
    })
    return this.client
      .signPlannedActivityRow(model)
      .then(item => (item === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(item)))
  }

  public completeDelayedActivityWithoutAction = (
    activity: IPlannedActivityRowSigningCreateItem
  ): Promise<IPlannedActivityRowItem> => {
    const model = new PlannedActivityRowSigningCreateItem({
      ...activity
    })
    return this.client
      .signDelayedActivityRow(model)
      .then(item => (item === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(item)))
  }

  public completePartiallyGivenActivity = (
    activity: Omit<IPlannedActivityRowSigningCreateItem, 'plannedActivityStatusId'>
  ): Promise<IPlannedActivityRowItem> => {
    const model = new PlannedActivityRowSigningCreateItem({
      ...activity,
      plannedActivityStatusId: PlannedActivityStatusEnum.PartiallyGiven,
    })
    return this.client
      .signPlannedActivityRow(model)
      .then(item => (item === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(item)))
  }

  public completePartiallyCompletedActivity = (
    activity: Omit<IPlannedActivityRowSigningCreateItem, 'plannedActivityStatusId'>
  ): Promise<IPlannedActivityRowItem> => {
    const model = new PlannedActivityRowSigningCreateItem({
      ...activity,
      plannedActivityStatusId: PlannedActivityStatusEnum.PartiallyComplete,
    })
    return this.client
      .signPlannedActivityRow(model)
      .then(item => (item === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(item)))
  }

  public delayPlannedActivity = (activity: Omit<IPlannedActivityRowSigningCreateItem, 'plannedActivityStatusId'>): Promise<IPlannedActivityRowItem> => {
    const model = new PlannedActivityRowSigningCreateItem({
      ...activity,
      plannedActivityStatusId: PlannedActivityStatusEnum.Delayed,
    })
    return this.client
      .signPlannedActivityRow(model)
      .then(item => (item === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(item)))
  }

  public unsignPlannedActivityWithoutAction = (
    activity: Omit<IPlannedActivityRowSigningCreateItem, 'plannedActivityStatusId'>
  ): Promise<IPlannedActivityRowItem> => {
    const model = new PlannedActivityRowSigningCreateItem({
      ...activity,
      plannedActivityStatusId: PlannedActivityStatusEnum.NotCompleted,
    })
    return this.client
      .signPlannedActivityRow(model)
      .then(item => (item === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(item)))
  }

  public multiSignPlannedActivities = (model: MultiSignItem): Promise<FileResponse | null> => {
    return this.client
      .multiSignPlannedActivities(model)
      .then(item => (item === null ? Promise.reject(`No result from backend.`) : Promise.resolve(item)))
  }

  public listActivitiesToMultiSign = (plannedActivityIds: number[]) : Promise<MultiSignActivityItem[]> => {
    return this.client
      .listActivitiesToMultiSign(plannedActivityIds)
      .then(activityItems =>
        activityItems === null
          ? Promise.reject(`No activities to multisign received.`)
          : Promise.resolve(activityItems)
      )

  }


  public moveDetachedActivity = async (activity: IPlannedActivityRowItem, newRouteId: number | null) => {
    const model = new PlannedActivityRowItem(activity)
    return this.client
      .updatePlannedActivityRow(newRouteId == null ? 0 : newRouteId, model)
      .then(plannedActivityRowItem =>
        plannedActivityRowItem === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(plannedActivityRowItem)
      )
  }

  public updateDetachedActivityParticipants = async (activity: IPlannedActivityRowItem) => {
    const model = new PlannedActivityRowItem(activity)
    return this.client
      .updatePlannedActivityRow(0, model)
      .then(plannedActivityRowItem =>
        plannedActivityRowItem === null ? Promise.reject(`No planned activity row item received from backend.`) : Promise.resolve(plannedActivityRowItem)
      )
  }

  public movePlannedActivityRowSeries = async (
    activity: Omit<IMovePlannedActivityRowSeriesItem, 'forceOverwriteOfFutureChanges' | 'implementChangesUntil' | 'implementChangesUntilDate'>,
    forceOverwriteOfFutureChanges = false,
    includePenMarked = false,
    implementChangesUntil = false,
    implementChangesUntilDate: Date | undefined = undefined
  ) => {
    const model = new MovePlannedActivityRowSeriesItem({
      ...activity,
      forceOverwriteOfFutureChanges,
      includePenMarked,
      implementChangesUntil,
      implementChangesUntilDate,
    })
    return this.client
      .movePlannedActivityRowSeries(model)
      .then(plannedActivityRowItem =>
        plannedActivityRowItem === null ? Promise.reject(`Could not move planned activity.`) : Promise.resolve(plannedActivityRowItem)
      )
  }

  public unplanAllPlannedActivityRowSeries = async (
    plannedActivityRowId: number,
    includePenMarked: boolean,
    implementChangesUntil: boolean,
    implementChangesUntilDate: Date | undefined
  ) => {
    const model = new UnplanAllPlannedActivityRowSeriesItem({
      plannedActivityRowId,
      includePenMarked,
      implementChangesUntil,
      implementChangesUntilDate,
    })
    return this.client
      .unplanAllPlannedActivityRowSeries(model)
      .then(plannedActivityRowItem =>
        plannedActivityRowItem === null ? Promise.reject(`Could not unplan planned activity.`) : Promise.resolve(plannedActivityRowItem)
      )
  }
}

const useActivityApi = () => React.useMemo(() => new ActivityApi(), [])

export default useActivityApi
