import { Box, Typography } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import React from 'react'

type ModalHeaderProps = Readonly<
  {
    title?: string
    subtitle?: string
    responsive?: boolean
  } & BoxProps
>

const ModalHeaderContainer: React.SFC<ModalHeaderProps> = ({ title, subtitle, responsive, ...rest }) => (
  <Box
    p='32px 40px 16px 40px'
    {...rest}
    style={{boxSizing:'border-box',width:'100%'}}
    sx={(theme)=>({
      ...rest?.sx,
      [theme.breakpoints.down('md')]: responsive && {
        padding: '5px'
      }
    })}
    >
    {title && <Typography variant="h5">{title}</Typography>}
    {subtitle && (
      <Typography variant="subtitle1" style={{ opacity: 0.6 }}>
        {subtitle}
      </Typography>
    )}
  </Box>
)

export default ModalHeaderContainer
