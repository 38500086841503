import React from 'react'
import { useLegislationApi } from 'api'
import { LegislationListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'

const useAdministerLegislationsContext = () => {
  const { listLegislations, deleteLegislation, abort, hasTemplateActivityCategoryConnectedToLegislation } = useLegislationApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [legislationListItems, setLegislationListItems] = React.useState<LegislationListItem[]>([])
  const [modalOpen, setModalOpen] = React.useState(false)

  const location = useLocation()

  const fetchLegislationsAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listLegislations(currentUser.organizationId).then(setLegislationListItems))
  }, [currentUser.organizationId, fetchAsync, listLegislations])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    void fetchLegislationsAsync()
  }, [fetchLegislationsAsync, location])

  const { state: stateOfRemoveFetch, fetchAsync: removeFetchAsync } = usePromiseApi()

  const [legislationToRemove, setLegislationToRemove] = React.useState<LegislationListItem | null>(null)

  const removeDialogOpen = React.useMemo(() => legislationToRemove !== null, [legislationToRemove])

  const [legislationInvalidToRemove, setLegislationInvalidToRemove] = React.useState<LegislationListItem | null>(null)

  const removeInvalidDialogOpen = React.useMemo(() => legislationInvalidToRemove !== null, [legislationInvalidToRemove])

  const closeRemoveInvalidDialog = React.useCallback(() => setLegislationInvalidToRemove(null), [setLegislationInvalidToRemove])

  const openRemoveDialog = React.useCallback(
    (legislation: LegislationListItem) => {
      void hasTemplateActivityCategoryConnectedToLegislation(legislation.id).then(result => {
        if (result) {
          setLegislationInvalidToRemove(legislation)
        } else {
          setLegislationToRemove(legislation)
        }
      })
    },
    [setLegislationToRemove, setLegislationInvalidToRemove, hasTemplateActivityCategoryConnectedToLegislation]
  )

  const closeRemoveDialog = React.useCallback(() => setLegislationToRemove(null), [setLegislationToRemove])

  const deletelegislationAsync = React.useCallback(
    legislationId => {
      void removeFetchAsync(
        deleteLegislation(legislationId)
          .then(fetchLegislationsAsync)
          .then(closeRemoveDialog)
      )
    },
    [removeFetchAsync, deleteLegislation, fetchLegislationsAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      legislationListItems,
      modalOpen,
      removeDialogOpen,
      legislationToRemove,
      legislationInvalidToRemove,
      isRemoving,
      removeInvalidDialogOpen,
    },
    actions: {
      fetchLegislationsAsync,
      openModal,
      closeModal,
      openRemoveDialog,
      closeRemoveDialog,
      closeRemoveInvalidDialog,
      deletelegislationAsync,
    },
  }
}

export const AdministerLegislationsContext = createContext(useAdministerLegislationsContext)
