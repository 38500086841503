import produce from 'immer'
import React from 'react'
import { createContext } from 'utilities/create-context'
import { maxBy } from 'lodash'
import { getBulletinData, hasBulletinData, setBulletinData } from './utilities/bulletin-data'

export type BulletinBoardItemModel = Readonly<{
  id: number
  header?: string
  content?: string
  color: string
  createdByName: string
  createdDate: Date
  modifiedByName?: string
  modifiedDate?: Date
  left: number
  top: number
  width: number
  height: number
}>

export type BulletinBoardModel = Readonly<{
  name?: string
  items?: BulletinBoardItemModel[]
}>

const useBulletinBoardContext = () => {

  const [bulletin, setBulletin] = React.useState<BulletinBoardModel | undefined>(undefined)

  React.useEffect(() => {
    if (!hasBulletinData()) {
      const exampleBulletin: BulletinBoardModel = {
        name: 'Anslagstavlans namn',
        items: [
          {
            id: 1, header: 'Information',
            content: `Detta är ett exempel.
OBS: En sparad notis kommer bara att visas på den aktuella datorn (pekskärm, surfplatta etc).

Tryck på de tre punkterna för att:
* Ändra
* Ta bort
Tryck på knappen "Skapa notis" för att skapa dina egna notiser.

Du kan flytta runt notiserna på skärmen genom att dra i dem.
`,
            color: '#F0C200', createdByName: "Tenilo", createdDate: new Date(), left: 20, top: 140, width: 380, height: 380
          }
        ]
      }
      setBulletin(exampleBulletin)

    } else {
      const storedBulletin = getBulletinData()
      if (storedBulletin) {
        setBulletin(storedBulletin)
      }
    }

  }, [])

  React.useEffect(() => {
    // this will persist bulletin data to localstorage
    if (bulletin) {
      setBulletinData(bulletin)
    }

  }, [bulletin])

  const updateBulletinItem = React.useCallback((item: BulletinBoardItemModel) => {
    setBulletin(prevBull => {
      if (!prevBull || !prevBull.items) {
        return prevBull
      }

      const newItems = produce(prevBull.items.slice(0), draft => {
        const existingItem = draft.find(it => it.id === item.id)
        if (!existingItem) {
          return draft
        }
        existingItem.header = item.header
        existingItem.content = item.content
        existingItem.color = item.color
        existingItem.left = item.left
        existingItem.top = item.top
        existingItem.width = item.width
        existingItem.height = item.height

        return draft
      })

      return {
        ...prevBull,
        items: newItems
      }

    })

  }, [setBulletin])


  const createBulletinItem = React.useCallback((item: BulletinBoardItemModel) => {

    setBulletin(prevBull => {
      if (!prevBull || !prevBull.items) {
        return prevBull
      }

      const newItems = produce(prevBull.items.slice(0), draft => {
        const maxItem = maxBy(draft, (it) => it.id)
        if (maxItem) {
          draft.push({ ...item, id: maxItem.id + 1 })
        } else {
          draft.push(item)
        }

        return draft
      })

      return {
        ...prevBull,
        items: newItems
      }

    })

  }, [setBulletin])

  const removeBulletinItem = React.useCallback((item: BulletinBoardItemModel) => {
    setBulletin(prevBull => {
      if (!prevBull || !prevBull.items) {
        return prevBull
      }

      const newItems = produce(prevBull.items.slice(0), draft => {
        return draft.filter(it => it.id !== item.id)
      })

      return {
        ...prevBull,
        items: newItems
      }

    })
  }, [setBulletin])

  return {
    state: {
      bulletin,
    },
    actions: {
      updateBulletinItem,
      createBulletinItem,
      removeBulletinItem,
    },
  }
}

export const BulletinBoardContext = createContext(useBulletinBoardContext)
