import React from 'react'
import {
  NotificationClient,
  NotificationListItem,
  PageResultOfNotificationListItem
} from 'api/tenilo.dashboard.api-client'
import { DASHBOARD_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class NotificationApi {
    private readonly abortController = new AbortController()
    private readonly client: NotificationClient

    public constructor() {
        this.client = new NotificationClient(DASHBOARD_API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public listNewNotifications = (organizationId: number, areaIds: number[]): Promise<NotificationListItem[]> =>
        this.client
            .listNewNotificationsForUser(organizationId,areaIds)
            .then(notifications =>
              notifications === null
                    ? Promise.reject(`No notifications received from backend for organization id ${organizationId} and area ids ${areaIds}.`)
                    : Promise.resolve(notifications)
            )

    public listNotificationsPaged = async (page: number, pageSize: number, organizationId: number | null, areaIds: number[]): Promise<PageResultOfNotificationListItem> => {
      return this.client
            .listNotificationsPaged(page,pageSize,organizationId,null,areaIds)
            .then(res =>
                res === null
                    ? Promise.reject('no item received from backend.')
                    : Promise.resolve(res)
            )
    }

    public saveLatestNotificationReadOnUser = (notificationId: number) => this.client.saveLatestNotificationReadOnUser(notificationId).then(() => Promise.resolve(notificationId))

}

const useNotificationApi = () => React.useMemo(() => new NotificationApi(), [])

export default useNotificationApi
