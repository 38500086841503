import React from 'react'
import { Box } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { WorkScheduleContext } from './work-schedule-context'
import useConnectWorkscheduleToAreasFormData from './utilities/use-connect-work-schedule-to-areas-form-data'

const ConnectWorkscheduleToAreasModal: React.FC = () => {
  const f = useFormatMessage()
  const [pageSize, setPageSize] = React.useState<number>(50);
  const { connectSelectedWorkscheduleModalState, availableAreas, isLoading } = WorkScheduleContext.useState()
  const { handleCloseConnectWorkscheduleModal } = WorkScheduleContext.useActions()
  const { initialValues, name, onSubmit, validationSchema } = useConnectWorkscheduleToAreasFormData(connectSelectedWorkscheduleModalState.items)

  return (
    <CenterModal open={connectSelectedWorkscheduleModalState.open} onClose={handleCloseConnectWorkscheduleModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {

          const handleSelectedAreasChange = (ids) => {
            setFieldValue('areaIds', ids)
          }

          return (
            <Form translate="yes">
              <ModalHeader
                title={
                  f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_TITLE')
                }
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '60vh',
                    minWidth: '40vw',
                    overflow: 'auto',
                  }}>

                  <DataGrid
                    disableColumnFilter
                    sx={{
                      minHeight: '50vh',
                      maxHeight: '50vh',
                      '.MuiDataGrid-cell': { borderBottom: '0px' }
                    }}
                    rows={availableAreas || []}
                    disableSelectionOnClick
                    disableColumnMenu
                    disableColumnSelector
                    disableDensitySelector
                    disableExtendRowFullWidth
                    disableVirtualization
                    loading={isLoading}
                    getRowId={ ( item ) => item.areaId }
                    checkboxSelection
                    onSelectionModelChange={handleSelectedAreasChange}
                    localeText={muiDataGridLocale(f)()}

                    columns={[
                      {
                        flex: 1,
                        headerName: f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_COLUMN_AREA'),
                        field: 'areaName',
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <span>
                             {params.row.organizationParentsName ? `${params.row.areaName} (${params.row.organizationParentsName})` : params.row.areaName}
                            </span>
                          )
                        },         
                      },
                    ]}

                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                  />
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={handleCloseConnectWorkscheduleModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_MODAL_BUTTON_CONNECT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default ConnectWorkscheduleToAreasModal
