import { Box, Typography } from '@mui/material'
import React from 'react'

type ModalSectionContainerProps = Readonly<{
  title?: string
  subtitle?: string
  mb?: number
  responsive?:boolean
}>
const ModalSectionContainer: React.SFC<ModalSectionContainerProps> = ({ children, title, subtitle, mb = 5, responsive,  ...rest }) => {
  const childrenCount = React.useMemo(() => React.Children.count(children), [children])
  const childrenWithBox = React.Children.map(children, (child, index) => (
    <>
      {child}
      {index !== childrenCount - 1 && <Box pb={2} />}
    </>
  ))
  return (
    <Box
      {...rest}
      display="flex"
      sx={(theme)=>({
        mb,
        '&>*':{
          '&:first-of-type':{
            width: '34%',
            pr:3
          },
          '&:last-of-type':{
            width: '66%',
            display: 'flex',
            flexDirection:'column'
          }
        },
        [theme.breakpoints.down('md')]: responsive && ({
          mb:1.5,
          flexDirection:'column',
          width: '100%',
          '&>*':{
            '&:first-of-type':{
              width: '100%',
              flex: 1,
              p:0
            },
            '&:last-of-type':{
              width: '100%',
              flex: 1
            }
          }
        })
      })}
      >
      <Box>
        {title && <Typography variant="subtitle1">{title}</Typography>}
        {subtitle && (
          <Typography variant="body2" style={{ opacity: 0.6, fontStyle: 'italic' }}>
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box>
        {childrenWithBox}
      </Box>
    </Box>
  )
}

export default ModalSectionContainer
