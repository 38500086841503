import { Box, Typography, useTheme, Switch, Button } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { DatePicker } from '@mui/x-date-pickers'
import { useFormatMessage } from 'localization'
import React from 'react'
import { SupportAreaModalContext } from './support-area-modal-context'
import useFormData from './utilities/use-support-area-form-data'
import { DeleteDialog } from 'components/delete-dialog'

export type SupportAreaModalProps = Readonly<{
  onSave?: () => void
}>

const SupportAreaModalContainer: React.FC<SupportAreaModalProps> = ({ onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { modalOpen, selectedArea, isLoading } = SupportAreaModalContext.useState()
  const { anonymizeAreaAsync } = SupportAreaModalContext.useActions()

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedArea)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeSupportAreaModal } = SupportAreaModalContext.useActions()


  const [displayAnonymizationVerification, setDisplayAnonymizationVerification] = React.useState(false)

  const openAnonymizationVerification = React.useCallback(() => {
    setDisplayAnonymizationVerification(true)
  }, [setDisplayAnonymizationVerification])

  const closeAnonymizationVerification = React.useCallback(() => {
    setDisplayAnonymizationVerification(false)
  }, [setDisplayAnonymizationVerification])

  const handleAnonymization = React.useCallback(() => {
    closeAnonymizationVerification()
    void anonymizeAreaAsync()
  }, [anonymizeAreaAsync])


  return (
    <CenterModal open={modalOpen} onClose={closeSupportAreaModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, getFieldMeta, errors, dirty }) => {
          //const { ...statusProps } = values

          const handleCheckActive = (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(name.active, !values.active)
          }

          const handleDateForAutoSigningTurnOff = (val) => {
            setFieldValue('dateForAutoSigningTurnOff', val)
          }

          const handleCheckPerformAutoSign = (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(name.performAutoSign, !values.performAutoSign)
          }

          const activeIsDirty = getFieldMeta("active").value !== getFieldMeta("active").initialValue
          const performAutoSignIsDirty = getFieldMeta("performAutoSign").value !== getFieldMeta("performAutoSign").initialValue

          return (
            <Form translate="yes">
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={f('SUPPORT_AREA_MODAL_DIALOG_HEADER_TITLE')}
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>
                  <ModalSection title={f('SUPPORT_AREA_MODAL_DIALOG_AREA_TITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          name={name.areaName}
                          color="secondary"
                          fullWidth
                          disabled
                        />
                        <Typography color={"primary"}>
                          {values.organizationParentsName}
                        </Typography>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection title={f('SUPPORT_AREA_MODAL_DIALOG_ORGANIZATION_TITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <FilledTextField
                          name={name.organizationName}
                          color="secondary"
                          fullWidth
                          disabled
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection title={f('SUPPORT_AREA_MODAL_DIALOG_ACTIVE_TITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Switch checked={values.active} onChange={handleCheckActive} disabled={values.isAnonymized} />
                        {!activeIsDirty && (
                          <Typography fontStyle={"italic"}>
                            {values.active && (
                              f('SUPPORT_AREA_MODAL_DIALOG_IS_ACTIVE_DESCRIPTION')
                            )}
                            {!values.active && (
                              f('SUPPORT_AREA_MODAL_DIALOG_IS_NOT_ACTIVE_DESCRIPTION')
                            )}
                          </Typography>
                        )}

                        {activeIsDirty && (
                          <>
                            <Typography fontStyle={"italic"}>
                              {values.active && (
                                f('SUPPORT_AREA_MODAL_DIALOG_IS_ACTIVE_AND_DIRTY_DESCRIPTION')
                              )}
                              {!values.active && (
                                f('SUPPORT_AREA_MODAL_DIALOG_IS_NOT_ACTIVE_AND_DIRTY_DESCRIPTION')
                              )}
                            </Typography>
                            <Typography color={"error"}>
                              {f('SUPPORT_AREA_MODAL_DIALOG_NOTIFY_TENILO_REPRESENTATIVE')}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Box>
                        {!activeIsDirty && !values.active && (
                          <Button variant='contained' disabled={values.isAnonymized} onClick={openAnonymizationVerification}>
                            {f('SUPPORT_AREA_MODAL_DIALOG_AREA_ANONYMIZE')}
                          </Button>
                        )}
                      </Box>

                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection title={f('SUPPORT_AREA_MODAL_DIALOG_PERFORMAUTOSIGN_TITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <Switch checked={values.performAutoSign} onChange={handleCheckPerformAutoSign} />
                        {!performAutoSignIsDirty && (
                          <Typography fontStyle={"italic"}>
                            {values.performAutoSign && (
                              f('SUPPORT_AREA_MODAL_DIALOG_HAS_PERFORMAUTOSIGN_DESCRIPTION')
                            )}
                            {!values.performAutoSign && (
                              f('SUPPORT_AREA_MODAL_DIALOG_HAS_NOT_PERFORMAUTOSIGN_DESCRIPTION')
                            )}
                          </Typography>
                        )}

                        {performAutoSignIsDirty && (
                          <>
                            <Typography fontStyle={"italic"}>
                              {values.performAutoSign && (
                                f('SUPPORT_AREA_MODAL_DIALOG_HAS_PERFORMAUTOSIGN_AND_DIRTY_DESCRIPTION')
                              )}
                              {!values.performAutoSign && (
                                f('SUPPORT_AREA_MODAL_DIALOG_HAS_NOT_PERFORMAUTOSIGN_AND_DIRTY_DESCRIPTION')
                              )}
                            </Typography>
                          </>
                        )}

                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  {values.performAutoSign && (
                    <ModalSection title={f('SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_TITLE')}
                      subtitle={f('SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_SUBTITLE')}>
                      <ModalSectionRow>
                        <Box width="50%">
                          <DatePicker
                            label={f('SUPPORT_AREA_MODAL_DIALOG_DATEFORAUTOSIGNINGTURNOFF_LABEL')}
                            value={values.dateForAutoSigningTurnOff}
                            onChange={handleDateForAutoSigningTurnOff}
                            minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                            format="yyyy-MM-dd"
                            localeText={{
                              toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
                              okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                              cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                            }}
                            slotProps={{
                              actionBar: {
                                actions: [],
                              },
                              textField: {
                                variant: 'filled'
                              }
                            }}
                          />
                        </Box>
                      </ModalSectionRow>
                    </ModalSection>
                  )}
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeSupportAreaModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedArea ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>

      <DeleteDialog
        deleteDialogOpen={displayAnonymizationVerification}
        dialogTitle={f('SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_TITLE')}
        dialogContentText={f('SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_MESSAGE')}
        buttonCancelText={f('BASIC_BUTTON_CANCEL_TEXT')}
        buttonSubmitText={f('SUPPORT_AREA_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_OK')}
        onSubmit={handleAnonymization}
        onClose={closeAnonymizationVerification}
      />
    </CenterModal>
  )
}

export default SupportAreaModalContainer
