import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReportItem, ReportDataItem, UserReportItem, ReportFieldItem } from 'api/tenilo.reporting.api-client'
import { Colors } from 'styles/colors'
import Color from 'color'
import { useFormatMessage } from 'localization'
import Authentication from 'components/authentication'
import styled from 'styled-components'
import { TableMultiple as ReportsIcon } from 'mdi-material-ui'
import ReportsPageContext from 'pages/reports/reports-page-context'
import ReportTableToolbar from './components/report-table-toolbar'
import ReportTableHeader from './components/report-table-header'
import ReportTableDataRows from './components/report-table-data-rows'
import { saveReportToXlsx, saveReportToCsv } from './utilities/export-to-excel'
import { createReportTablePdf } from './utilities/export-to-pdf'
import { BorderType } from './components/report-table-toolbar/report-table-toolbar-container'
import { validFieldUse, sortUserReportFields, sortReportFields } from './utilities/report-helper'

type Props = Readonly<{
    report?: ReportItem
    userReport?: UserReportItem
    reportData: ReportDataItem | undefined

    enableRowCounter?: boolean

    enablePaging?: boolean
    pageSize?: number | undefined

    borders?: BorderType
    enableBorderSelector?: boolean
    formFields: ReportFieldItem[] | undefined

}>

const ReportTableWrapper = styled(Box)`
    :not(:fullscreen) {
        margin-top: 10px;
        background-color: white;
    };

    :fullscreen {
        background-color: white;
        padding:5px;
    };
`

const ReportTable: React.FC<Props> = ({ report, reportData, enableRowCounter, enablePaging, pageSize, userReport, borders, enableBorderSelector, formFields }) => {
    const f = useFormatMessage()
    const { user } = Authentication.useAuthenticatedState()
    const { sortedFieldItems } = ReportsPageContext.useState()
    const [pageNumber, setPageNumber] = React.useState<number>(1)
    const [borderType, setBorderType] = React.useState<BorderType>(borders || BorderType.All)
    const [showRowCounter, setShowRowCounter] = React.useState<boolean>(enableRowCounter || false)
    const [gridHeadersWidth, setGridHeadersWidth] = React.useState<number>(0)
    const reportGridRef = React.useRef<HTMLElement>(null)
    const exportReportToXlsx = saveReportToXlsx(f)
    const exportReportToCsv = saveReportToCsv(f)
    const exportReportTableToPdf = createReportTablePdf(f)

    const reportFields = React.useMemo(() => {
        if (report) {
            return report.reportFields.filter(rf => validFieldUse(rf, user))
        }
        if (userReport) {
            return userReport.parentReport.reportFields.filter(rf => validFieldUse(rf, user))
        }
        return undefined
    }, [report, userReport, user, formFields])

    const sortedUserReportFields = React.useMemo(() => {
        return sortUserReportFields(userReport, sortedFieldItems)
    }, [userReport, sortedFieldItems])

    const sortedReportFields = React.useMemo(() => {
        return sortReportFields(report, formFields)
    }, [report, formFields])

    const validColumnsCount = React.useMemo(() => {
        let count = 0
        reportFields?.forEach(rf => {

            const formField = formFields?.find(ff => ff.id === rf.id)
            if (!formField) { return }


            if (rf.isMeta) { return }
            if (rf.useDelegations && !user.useDelegations) { return }
            if (rf.useDrugLists && !user.useDrugLists) { return }
            if (rf.useWorkSchedule && !user.useWorkSchedule) { return }
            if (userReport && !userReport.userReportFields.some(urf => urf.reportFieldId === rf.id)) {
                return
            }
            count = count + 1
        })
        if (userReport) {
            formFields?.forEach(ff => {

                const reportField = reportFields?.find(rf => rf.id === ff.id)
                if (!reportField) { return }

                if (reportField.isMeta) { return }
                if (reportField.useDelegations && !user.useDelegations) { return }
                if (reportField.useDrugLists && !user.useDrugLists) { return }
                if (reportField.useWorkSchedule && !user.useWorkSchedule) { return }

                if (userReport && userReport.userReportFields.some(urf => urf.reportFieldId === ff.id)) {
                    return
                }
                count = count + 1
            })
        }

        return count + (showRowCounter ? 1 : 0)
    }, [reportFields, showRowCounter, user, formFields])

    React.useLayoutEffect(() => {
        if (reportGridRef && reportGridRef.current) {
            const numExportableCols = reportFields?.filter(rf => !(rf.isMeta || rf.isLink)).length
            if (!numExportableCols) { return }
            let width = 0
            for (let i = 0; i < numExportableCols; i++) {
                width += reportGridRef?.current.children[i]?.clientWidth
            }
            setGridHeadersWidth(width)
        }
    }, [reportGridRef, user, reportFields, reportData]);

    React.useLayoutEffect(() => {
        setPageNumber(1)
    }, [reportData])

    const visibleDataRows = React.useMemo(() => {
        if ((enablePaging && pageNumber && pageSize)) {
            const start = ((pageNumber - 1) * pageSize);
            return reportData?.reportDataRows.slice(start, start + pageSize);
        }
        return reportData?.reportDataRows
    }, [reportData, enablePaging, pageNumber, pageSize])

    const visibleAlphaChannelRows = React.useMemo(() => {
        if ((enablePaging && pageNumber && pageSize)) {
            const start = ((pageNumber - 1) * pageSize);
            return reportData?.reportAlphaChannelRows.slice(start, start + pageSize);
        }
        return reportData?.reportAlphaChannelRows
    }, [reportData, enablePaging, pageNumber, pageSize])

    const lastPageNumber = React.useMemo(() => {
        if (!pageSize || !reportData?.reportDataRows) {
            return 1
        }
        return (Math.trunc(reportData?.reportDataRows.length / pageSize) + 1)
    }, [pageSize, reportData])

    const firstPage = React.useCallback(() => {
        setPageNumber(1)
    }, [])

    const prevPage = React.useCallback(() => {
        setPageNumber(prevState => prevState > 1 ? prevState - 1 : prevState)
    }, [])

    const nextPage = React.useCallback(() => {
        if (!pageSize || !reportData?.reportDataRows) {
            return
        }
        setPageNumber(prevState => (prevState) * pageSize < reportData?.reportDataRows.length ? prevState + 1 : prevState)
    }, [pageSize, reportData])

    const lastPage = React.useCallback(() => {
        setPageNumber(lastPageNumber)
    }, [lastPageNumber])

    const setBorders = React.useCallback((border: BorderType) => () => {
        setBorderType(border)
    }, [])

    const switchShowRowNumbers = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setShowRowCounter(checked)
    }, [])

    const exportXLSX = React.useCallback(() => {
        if (reportFields && reportData?.reportDataRows) {
            try {
              void exportReportToXlsx(report, userReport, reportData.reportDataRows, reportData.reportAlphaChannelRows, user, formFields, sortedFieldItems)
            } catch (error) {
              console.log(error)
              return
            }
        }
    }, [report, userReport, reportData, user, formFields, sortedFieldItems])

    const exportCSV = React.useCallback(() => {
        if (reportFields && reportData?.reportDataRows) {
            try {
              void exportReportToCsv(report, userReport, reportData.reportDataRows, user, formFields, sortedFieldItems)
            } catch (error) {
              console.log(error)
              return
            }
        }
    }, [report, userReport, reportData, user, formFields, sortedFieldItems])

    const exportPDF = React.useCallback(() => {
        if (reportFields && reportData?.reportDataRows) {
            exportReportTableToPdf(gridHeadersWidth, report, userReport, reportData.reportDataRows, reportData.reportAlphaChannelRows, user, formFields, sortedFieldItems)
        }
    }, [gridHeadersWidth, report, userReport, reportData, user, formFields, sortedFieldItems])


    const reportBox = React.useRef<HTMLDivElement>(null);
    const [isFullscreen, setIsFullscreen] = React.useState(false)

    React.useLayoutEffect(() => {
        document.onfullscreenchange = () => {
            if (!document.fullscreenElement) {
                setIsFullscreen(false);
            }
        }

        return () => {
            document.onfullscreenchange = null
        }
    })

    const toggleFullscreen = React.useCallback(() => {
        if (reportBox.current == null) {
            return
        }

        if (document.fullscreenElement) {
            document.exitFullscreen()
            setIsFullscreen(false)
        } else {
            reportBox.current.requestFullscreen()
                .then(() => {
                    setIsFullscreen((prevState) => !prevState)
                })
                .catch(() => {
                    setIsFullscreen(false)
                })
        }

    }, [reportBox])

    return (
        <ReportTableWrapper ref={reportBox}>
            <ReportTableToolbar
                firstPage={firstPage} prevPage={prevPage} nextPage={nextPage} lastPage={lastPage}
                enablePaging={enablePaging} pageNumber={pageNumber} pageSize={pageSize} lastPageNumber={lastPageNumber}
                enableBorderSelector={enableBorderSelector} setBorders={setBorders} borderType={borderType}
                enableRowCounter={enableRowCounter} showRowCounter={showRowCounter} switchShowRowNumbers={switchShowRowNumbers}
                exportCSV={exportCSV} exportPDF={exportPDF} exportXLSX={exportXLSX}
                fullscreen={toggleFullscreen} isFullscreen={isFullscreen}
                disabled={!reportData}
            />
            {reportData ? (
                <Box sx={{ maxHeight: isFullscreen ? '94%' : '60vh', overflow: 'scroll', margin: '10px 0px' }}>
                    <Box ref={reportGridRef}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(${validColumnsCount}, 1fr)`,
                            padding: '0px 0px',
                            maxWidth: '600px',
                            '> *': {
                                padding: '5px',
                                borderTop: `${borderType === BorderType.All || borderType === BorderType.Rows ? 1 : 0}px solid ${Color(Colors.AthensGray).lighten(0.01).string()}`,
                                borderBottom: `${borderType === BorderType.All || borderType === BorderType.Rows ? 1 : 0}px solid ${Color(Colors.AthensGray).lighten(0.01).string()}`,
                                borderRight: `${borderType === BorderType.All || borderType === BorderType.Columns ? 1 : 0}px solid ${Color(Colors.AthensGray).lighten(0.01).string()}`,
                                borderLeft: `${borderType === BorderType.All || borderType === BorderType.Columns ? 1 : 0}px solid ${Color(Colors.AthensGray).lighten(0.01).string()}`
                            },
                        }}>
                        <ReportTableHeader
                            reportFields={reportFields}
                            userReport={userReport}
                            showRowCounter={showRowCounter}
                            formFields={formFields}
                            sortedReportFields={sortedReportFields}
                            sortedUserReportFields={sortedUserReportFields} />
                        <ReportTableDataRows
                            reportData={reportData}
                            reportFields={reportFields}
                            visibleDataRows={visibleDataRows}
                            visibleAlphaChannelRows={visibleAlphaChannelRows}
                            userReport={userReport}
                            pageNumber={pageNumber}
                            pageSize={pageSize}
                            enablePaging={enablePaging}
                            showRowCounter={showRowCounter}
                            formFields={formFields}
                            sortedReportFields={sortedReportFields}
                            sortedUserReportFields={sortedUserReportFields}
                        />
                    </Box>
                </Box>
            ) : (
                <Box sx={{ height: '50vh', width: '100%', margin: '100px auto', textAlign: 'center' }}>
                    <ReportsIcon style={{ width: '300px', height: '300px', color: Color(Colors.AthensGray).string() }} />
                    <Typography>{f('BASIC_NO_RESULT')}</Typography>
                </Box>
            )}
        </ReportTableWrapper>
    )
}

export default React.memo(ReportTable)
