import React from 'react'
import { Box, Tab, Tabs as MuiTabs, useTheme } from '@mui/material'
import { isNumber } from 'lodash'
import Typography from '@mui/material/Typography'
import ActivityCreateModal from 'components/activity-create-modal'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import Spinner from 'components/spinner'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import { useFormatMessage } from 'localization'
import styled from 'styled-components'
import ModalContent from 'components/modal-content'
import { RoleEnum } from 'api/tillit.api-client'
import { Authorize } from 'components/authorization'
import { BasicInformation } from './components/basic-information'
import DesiredCommonActivities from './components/desired-common-activities/desired-common-activities-view'
import DesiredTemporaryCommonActivities from './components/desired-temporary-common-activities/desired-temporary-common-activities-view'
import { AreaModalContext } from './area-modal-context'

const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
`

type ITabPanelProps = Readonly<{
  index: number
  value: number
}>

const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AreaInfoModalView: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const { fetchAreaInfoAsync: fetchAreaInfoActivityCreateModal } = ActivityCreateModalContext.useActions()
  const { areaInfo, commonActivities, isLoading, temporaryCommonActivities, areaId } = AreaModalContext.useState()
  const { fetchAreaInfoAsync, fetchAreaCommonActivitiesAsync, fetchAreaTemporaryCommonActivitiesAsync, clearArea} = AreaModalContext.useActions()

  const handleClose = React.useCallback(() => {
    clearArea()
  }, [clearArea])

  const [value, setValue] = React.useState(0)
  const handleChange =React.useCallback((event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue)
  },[value])

  const handleSaveCreateActivity = () => {
    void fetchAreaCommonActivitiesAsync(areaInfo!.id)
  }

  const basicInformationRef = React.createRef<any>()

  const handleSaveClick = (tabNumber: number) => () => {
    switch (tabNumber) {
      case 0:
        basicInformationRef.current.handleSubmit()
        break
      default:
        handleClose()
        break
    }
  }

  React.useEffect(() => {
    if(isNumber(areaId)){
      fetchAreaInfoActivityCreateModal(+areaId)
      fetchAreaInfoAsync(+areaId)
      fetchAreaCommonActivitiesAsync(+areaId)
      fetchAreaTemporaryCommonActivitiesAsync(+areaId)
    }
  }, [areaId, fetchAreaInfoActivityCreateModal, fetchAreaInfoAsync, fetchAreaCommonActivitiesAsync, fetchAreaTemporaryCommonActivitiesAsync, clearArea])

  return (
    <CenterModal open={isNumber(areaId)} onClose={handleClose} fullSize>
      {!areaInfo || !commonActivities || !temporaryCommonActivities ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          <ModalHeader title={areaInfo.name} />
          <ModalContent>
            <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} centered>
              <Tab label={f('AREAS_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE')} {...a11yProps(0)} />
              <Tab label={`${f('AREAS_DIALOG_VIEW_TAB_DESIRED_ACTIVITIES_TITLE')} (${commonActivities.length})`} {...a11yProps(1)} />
              <Tab label={`${f('AREAS_DIALOG_TAB_TEMPORARY_ACTITIVIES_TITLE')} (${temporaryCommonActivities.length})`} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <BasicInformation customRef={basicInformationRef} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DesiredCommonActivities />
              {isNumber(areaId) &&(
                <ActivityCreateModal onSave={handleSaveCreateActivity} ownerAreaId={+areaId} />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DesiredTemporaryCommonActivities />
            </TabPanel>
          </ModalContent>
          <ModalFooter>
            <TextButton onClick={handleClose}>{f('BASIC_BUTTON_CANCEL_TEXT')}</TextButton>
            <Authorize allowedRoles={[RoleEnum.Admin]}>
              <ContainedButton onClick={handleSaveClick(value)} disabled={isLoading} color="secondary">
                {f('BASIC_BUTTON_SAVE_TEXT')}
              </ContainedButton>
            </Authorize>
            <Authorize disallowedRoles={[RoleEnum.Admin]}>
              <ContainedButton onClick={handleSaveClick(value)} disabled color="secondary">
                {f('BASIC_BUTTON_SAVE_TEXT')}
              </ContainedButton>
            </Authorize>
          </ModalFooter>
        </>
      )}
    </CenterModal>
  )
}

export default AreaInfoModalView
