import React from 'react'
import * as yup from 'yup'
import { OnSubmitFunction } from 'types'
import { IUserSettings } from 'api/tillit.api-client'
import { DailyActivitiesContext } from 'pages/daily-activities/daily-activities-context'
import usePromiseApi from 'utilities/use-promise'
import { useFormatMessage } from 'localization'

const useDailyActivitySettingsForm = (futureActivityTimeInHoursSwitch: boolean) => {
  const { user: currentUser } = DailyActivitiesContext.useState()
  const f = useFormatMessage()
  const { updateUserSettings, handleToggleSettingsModal, fetchMe } = DailyActivitiesContext.useActions()
  const { fetchAsync } = usePromiseApi()

  const initialValues: IUserSettings = React.useMemo(
    () => ({
      openAssignedRoute: currentUser ? currentUser.openAssignedRoute : false,
      futureActivityTimeInHours: currentUser?.futureActivityTimeInHours,
      showExactTimeOnKioskActivity: currentUser ? currentUser.showExactTimeOnKioskActivity : false,
      showCustomerMode: currentUser ? currentUser.showCustomerMode : false
    }),
    [currentUser]
  )

  const onSubmit: OnSubmitFunction<IUserSettings> = React.useCallback(
    ({ openAssignedRoute, futureActivityTimeInHours, showExactTimeOnKioskActivity, showCustomerMode }, { setSubmitting, setStatus }) => {
      if (!currentUser) {return}
      const data = {
        openAssignedRoute,
        futureActivityTimeInHours: futureActivityTimeInHoursSwitch ? futureActivityTimeInHours : undefined,
        showExactTimeOnKioskActivity,
        showCustomerMode
      }
      fetchAsync(updateUserSettings(currentUser.id, data))
        .then((res) => {
          if (res?.status === 200) {
            return fetchMe()
          }
          return null
        })
        .then(() => {
          setSubmitting(false)
          handleToggleSettingsModal()
        })
    },
    [currentUser, futureActivityTimeInHoursSwitch]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<IUserSettings>({
        openAssignedRoute: yup.boolean(),
        futureActivityTimeInHours: yup.mixed().notRequired(),
        showExactTimeOnKioskActivity: yup.boolean(),
        showCustomerMode:yup.boolean()
      }),
    []
  )

  const name = React.useMemo(
    () => ({ openAssignedRoute: 'openAssignedRoute', futureActivityTimeInHours: 'futureActivityTimeInHours', showExactTimeOnKioskActivity: 'showExactTimeOnKioskActivity', showCustomerMode:'showCustomerMode' }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name
  }
}

export default useDailyActivitySettingsForm
