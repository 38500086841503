import styled from 'styled-components'
import Colors from 'styles/colors'
import DailyActivitySubButtonContainer from './daily-activity-sub-button-container'

const DailyActivitySubButtonStyle = styled(DailyActivitySubButtonContainer)`
  width: 102px;
  margin-left: 60px;
  padding: 0px;
  height: 50px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: ${Colors.White};
  display: flex;
  align-items: center;
  background-color: ${props => props.color};
  box-sizing: border-box;
  * {
    box-sizing: border-box
  };

  .right-pane{
    height: 100%;
    width: 24px;
    background-color: ${props => props.color}
  };
  .left-pane {
    flex: 1;
    width:100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  ${props => [props.theme.breakpoints.down('md')]}{
    width: 70%;
    max-width: 350px;
    height: 35px;
    margin: 10px auto;
  }
`

export default DailyActivitySubButtonStyle
