import React from 'react'
import { css } from 'styled-components'
import SpinnerStyle from './spinner-style'

export type Props = Readonly<{
  className?: string | null
  color: string
  size?: number
  css?: ReturnType<typeof css>
}>

const SpinnerView: React.FC<Props> = ({ className, size = 64, ...props }) => (
  <SpinnerStyle size={size} className={className || undefined} {...props}>
    <div />
    <div />
    <div />
    <div />
  </SpinnerStyle>
)

export default SpinnerView
