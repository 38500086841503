import { useTheme } from '@mui/material'
import Spinner from 'components/spinner'
import React from 'react'
import { CustomerModalContext } from './../../customer-modal-context'
import BasicInformationView from './basic-information-view'

interface IBasicInformationContainer {
  customRef: React.RefObject<any>
}

export const BasicInformationContainer: React.SFC<IBasicInformationContainer> = ({ customRef }) => {
  const theme = useTheme()
  const { customerInfo, isLoading } = CustomerModalContext.useState()
  return (
    <div style={{ position: 'relative' }}>
      {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
      {customerInfo && <BasicInformationView customerInfo={customerInfo} customRef={customRef} />}
    </div>
  )
}
