import React from 'react'
import { Box } from '@mui/material'
import { Colors } from 'styles/colors'
import Color from 'color'
import type { DashboardSection } from 'pages/dashboard/config'
import { WindowClose, Menu } from 'mdi-material-ui'
import Icon from '@mui/material/Icon'

const CbObserver: React.FC<{
  openActionBar: boolean | undefined;
  actionCb: (() => void) | undefined;
}> = React.memo((props) => {
  const { openActionBar, actionCb } = props
  React.useEffect(()=>{
    if(actionCb && openActionBar) {
      actionCb()
    }
  },[openActionBar])

  return null;
})

const DashboardResizableSectionView: React.FC<DashboardSection> = React.memo((props) => {

  const { InnerContainer, ActionBar, openByDefault, actionCb } = props
  const [openActionBar, setOpenActionBar] = React.useState(openByDefault)
  const actionBarHandler = React.useCallback(()=>{
    setOpenActionBar((curr)=>!curr)
  },[])

  return (
    <Box sx={{
      boxSizing:'border-box',
      position:'sticky',
      top:0,
      width: openActionBar ? '400px' : '40px',
      height:'calc(100% - 0px)',
      transition:'width 0.3s ease-out'
    }}>
      <Box sx={{
        position:'absolute',
        width:'calc(100% - 40px)',
        height:'100%',
        overflowX:'hidden',
        overflowY:'auto'
      }}>
        {InnerContainer && (
          <InnerContainer />
        )}
      </Box>
      <Box
        onClick={actionBarHandler}
        sx={{
          width:'40px',
          backgroundColor:Color(Colors.AthensGray).fade(0.2).string(),
          height:'100%',
          position:'absolute',
          right:0,
          cursor:'pointer',
          display:'flex',
          flexDirection:'column',
          paddingTop:'5px',
          justifyContent:'start',
          alignItems:'center',
          transition:'all 0.2s ease-out',
          '&:hover':{
            backgroundColor:Color(Colors.AthensGray).darken(0.01).string(),
          }
        }}
      >
        <CbObserver openActionBar={openActionBar} actionCb={actionCb}/>
        <Icon color="primary"  component={openActionBar ? WindowClose : Menu} sx={{fontSize: '1.3rem'}} />
        {ActionBar && (
          <ActionBar openActionBar={openActionBar} actionBarHandler={actionBarHandler} />
        )}
      </Box>
    </Box>
  )
})

const DashboardNonResizableSectionView: React.FC<DashboardSection> = React.memo((props) => {
  const { InnerContainer } = props
  return (
    <Box sx={{
      boxSizing:'border-box',
      flex:1,
      overflowY:'auto'
    }}>
      {InnerContainer && (
        <InnerContainer />
      )}
    </Box>
  )
})

const DashboardSectionView: React.FC<DashboardSection> = React.memo((props) => {
  const { resizable } = props
  return (
    <>
      {
        resizable ? <DashboardResizableSectionView {...props} /> : <DashboardNonResizableSectionView {...props} />
      }
    </>
  )
})

DashboardSectionView.displayName = 'DashboardSectionView'
DashboardNonResizableSectionView.displayName = 'DashboardNonResizableSectionView'
DashboardResizableSectionView.displayName = 'DashboardResizableSectionView'
CbObserver.displayName = 'CbObserver'

export {
  DashboardSectionView
}
