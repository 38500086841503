import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, Switch as MuiSwicth, Collapse, Slider } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { DailyActivitiesContext } from 'pages/daily-activities/daily-activities-context'
import { Form, Formik, Field } from 'formik'
import styled, { css } from 'styled-components'
import { useFormatMessage } from 'localization'
import { Colors } from 'styles/colors'
import { Switch } from 'formik-mui';
import useDailyActivitySettingsForm from './utilities/use-daily-activity-settings-form'

const DailyActivityModalWrapper = styled(Dialog)(({ theme }) => css`

  .MuiPaper-root  {
    width: 500px;
    .MuiDialogActions-root {
      background-color: ${Colors.AthensGray};
    }
    .MuiDialogTitle-root {
    }
    * {
      box-sizing: border-box;
    }
  }
  ${[theme.breakpoints.down('md')]}{
    .MuiDialog-container{
      align-items: flex-end;
      .MuiPaper-root {
        margin:0;
        width: 100%;
        max-width: 100%;
      }
    }
  }
`)

const DialogContentInnerBox = styled(Box)(({ theme }) => css`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border-bottom: 1px solid ${Colors.AthensGray};
`)

const DialogContentInnerDrawerBox = styled(DialogContentInnerBox)(({ theme }) => css`
  flex-direction: row;
  min-height: 50px;
  height: auto;
`)

const DailyActivitySettingsModal: React.FC = () => {
  const f = useFormatMessage()
  const { user: currentUser } = DailyActivitiesContext.useState()
  const [ futureActivityTimeInHoursSwitch, setFutureActivityTimeInHoursSwitch ] = React.useState(false)
  const { openSettingsModal } = DailyActivitiesContext.useState()
  const { handleToggleSettingsModal } = DailyActivitiesContext.useActions()
  const handleChangeFutureActivityTimeInHoursSwitch = React.useCallback(()=>{setFutureActivityTimeInHoursSwitch(curr=>!curr)},[])
  React.useEffect(()=>{
    if(currentUser?.futureActivityTimeInHours && !futureActivityTimeInHoursSwitch){
      handleChangeFutureActivityTimeInHoursSwitch()
    }
    if(!currentUser?.futureActivityTimeInHours && futureActivityTimeInHoursSwitch){
      handleChangeFutureActivityTimeInHoursSwitch()
    }
  },[currentUser])
  React.useEffect(()=>{
    if(!!currentUser && openSettingsModal){
      handleToggleSettingsModal()
    }
  },[currentUser])


  const { initialValues, onSubmit, validationSchema, name } = useDailyActivitySettingsForm(futureActivityTimeInHoursSwitch)
  return (
    <DailyActivityModalWrapper open={ !!currentUser && openSettingsModal} onClose={handleToggleSettingsModal}>
      <DialogTitle>{f('DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_TITLE')}</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ isSubmitting,values, setFieldValue}) => {

          const handleOpenAssignedRoute = (e,v:boolean) => {
            if(v && values.showCustomerMode){
              setFieldValue('showCustomerMode',false)
              setFieldValue('openAssignedRoute',v)
            }else{
              setFieldValue('openAssignedRoute',v)
            }
          }
          const handleMainMenuCustomerStich = (e,v:boolean) => {
            if(v && values.openAssignedRoute){
              setFieldValue('openAssignedRoute',false)
              setFieldValue('showCustomerMode',v)
            }else{
              setFieldValue('showCustomerMode',v)
            }
          }
          return (
            <Form translate="yes">
              <DialogContent>
                <DialogContentInnerBox>
                  <Typography variant="subtitle2">{f('DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_OPEN_MY_TOUR_TITLE')}</Typography>
                  <Field component={Switch} type="checkbox" onChange={handleOpenAssignedRoute}  name={name.openAssignedRoute} color="primary" />
                </DialogContentInnerBox>
                <DialogContentInnerBox>
                  <Typography variant="subtitle2">{f('DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_MAIN_MENU_CUSTOMER_VIEW')}</Typography>
                  <Field component={Switch} type="checkbox" onChange={handleMainMenuCustomerStich} name={name.showCustomerMode} color="primary" />
                </DialogContentInnerBox>
                <DialogContentInnerBox>
                  <Typography variant="subtitle2">{f('DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_SHOW_EXACT_TIME_ON_ACTIVITY')}</Typography>
                  <Field component={Switch} type="checkbox" name={name.showExactTimeOnKioskActivity} color="primary" />
                </DialogContentInnerBox>
                <DialogContentInnerDrawerBox>
                  <Typography variant="subtitle2">{f('DAILY_ACTIVITIES_PAGE_CONTAINER_SHOW_MORE_DAILY_ACTIVITIES')}</Typography>
                  <MuiSwicth checked={futureActivityTimeInHoursSwitch} disabled={isSubmitting} color="primary" onChange={handleChangeFutureActivityTimeInHoursSwitch}/>
                  <Collapse in={futureActivityTimeInHoursSwitch} sx={{height:futureActivityTimeInHoursSwitch ? '60px !important' : '0px',width:'100%'}}>
                    <Field
                      as={Slider}
                      sx={{width:'85%',margin:'auto',display:'block'}}
                      step={1}
                      name={name.futureActivityTimeInHours}
                      valueLabelDisplay={values.futureActivityTimeInHours ? 'auto' : 'off'}
                      min={1}
                      max={12}
                      marks={[
                        {value:2,label: `+2 ${f('DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_UNIT_HOURS')}`},
                        {value:6, label: `+6 ${f('DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_UNIT_HOURS')}`},
                        {value:12, label: `+12 ${f('DAILY_ACTIVITIES_PAGE_CONTAINER_SETTINGS_UNIT_HOURS')}`}]}
                      disabled={isSubmitting}
                    />
                  </Collapse>
                </DialogContentInnerDrawerBox>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleToggleSettingsModal}>{f('BASIC_BUTTON_CANCEL_TEXT')}</Button>
                <LoadingButton type="submit" loading={isSubmitting}>{f('BASIC_BUTTON_SAVE_TEXT')}</LoadingButton>
              </DialogActions>
            </Form>
        )}}
    </Formik>
    </DailyActivityModalWrapper>
  );
}

export default DailyActivitySettingsModal

