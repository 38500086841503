import { useActivityApi } from 'api'
import { MultiSignActivityItem, MultiSignItem } from 'api/tillit.api-client'
import { PlannedActivityStatusEnum } from 'api/tillit.api-client'
import React from 'react'
import usePromiseApi from 'utilities/use-promise'
import { createContext } from 'utilities/create-context'

const useMultiSignActivitesModalContext = () => {
  const { multiSignPlannedActivities, listActivitiesToMultiSign } = useActivityApi()
  const { state: fetchState, fetchAsync } = usePromiseApi()

  const [multiSignActivities, setMultiSignActivities] = React.useState<MultiSignActivityItem[] | null>(null)
  const [isPartiallyCompletedModalOpen, setIsPartiallyCompletedModalOpen] = React.useState(false)

  const fetchActivitiesToMultiSign = React.useCallback((plannedActivityIds: number[]) => {
    void listActivitiesToMultiSign(plannedActivityIds).then(items => {
      setMultiSignActivities(items)
    })
  }, [listActivitiesToMultiSign])

  const onSigningClick = React.useCallback(
    async (statusId: PlannedActivityStatusEnum, plannedActivityIds: number[]): Promise<void> => {

      const model = new MultiSignItem({
        plannedActivityIds: plannedActivityIds,
        plannedActivityStatusId: statusId
      })

      await fetchAsync(multiSignPlannedActivities(model))
    }, [])

  return {
    state: {
      multiSignActivities,
      fetchState,
      open,
      isPartiallyCompletedModalOpen,
    },
    actions: {
      fetchActivitiesToMultiSign,
      onSigningClick,
      close,
      setIsPartiallyCompletedModalOpen,
    },
  }
}

export default createContext(useMultiSignActivitesModalContext)
