import React from 'react'
import * as yup from 'yup'

import { IDelegationItem, DelegationCreateItem, DelegationUpdateItem, DelegationCategoryItem } from 'api/tillit.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { DelegationModalContext } from '../delegation-modal-context'

type DelegationForm = Readonly<{
  title: string
  organizationId: number
  description?: string
  delegationCategory: DelegationCategoryItem | undefined
  delegationCategoryId: number | undefined
}>

const createDelegation = (values: DelegationForm): DelegationCreateItem => {
  const { title, organizationId, description, delegationCategory } = values

  return new DelegationCreateItem({
    title,
    organizationId,
    description,
    delegationCategoryId: delegationCategory ? delegationCategory.id : undefined,
    })
}

const mapDelegationFormToDelegationItem = (initialItem: IDelegationItem, item: DelegationForm): DelegationUpdateItem => {
  return new DelegationUpdateItem({
    id: initialItem.id,
    title: item.title,
    description: item.description ? item.description : undefined,
    delegationCategoryId: item.delegationCategoryId,
  })
}

const mapDelegationItemToDelegationForm = (item: IDelegationItem): DelegationForm => {

  const delegationCategory: DelegationCategoryItem = new DelegationCategoryItem()
  if (item.delegationCategoryId) {
    delegationCategory.id = item.delegationCategoryId
    delegationCategory.title = item.delegationCategoryTitle
  }

  const createItem: DelegationForm = {
    organizationId: item.organizationId,
    title: item.title ? item.title : '',
    description: item.description ? item.description : '',
    delegationCategory,
    delegationCategoryId: item.delegationCategoryId,
  }
  return createItem
}

const useDelegationFormData = (initialDelegation?: IDelegationItem) => {
  const f = useFormatMessage()

  const { createDelegationAsync, updateDelegationAsync } = DelegationModalContext.useActions()
  const { ownerOrganizationId } = DelegationModalContext.useState()
  const delegation = initialDelegation

  const initialValues = React.useMemo<DelegationForm>(
    () =>
      !!delegation
        ? mapDelegationItemToDelegationForm(delegation)
        : {
            delegationId: undefined,
            delegation: undefined,
            organizationId: ownerOrganizationId ? ownerOrganizationId : -1,
            title: '',
            description: '',
            delegationCategory: undefined,
            delegationCategoryId: undefined,
          },
    [delegation, ownerOrganizationId]
  )

  const onSubmit: OnSubmitFunction<DelegationForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const templateActivityCategory = createDelegation({
        ...values,
      })
      if (!initialDelegation) {
        await createDelegationAsync(templateActivityCategory).finally(() => setSubmitting(false))
      } else {
        const mappedDelegation = mapDelegationFormToDelegationItem(initialDelegation, values)
        await updateDelegationAsync(mappedDelegation).finally(() => setSubmitting(false))
      }
    },
    [initialDelegation, createDelegationAsync, updateDelegationAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),

      description: yup.string().max(
        1024,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
          maxsize: 1024,
        })
      ),
    })
  }, [f])

  const name: Name<DelegationForm> = React.useMemo(
    () => ({
      organizationId: 'organizationId',
      title: 'title',
      description: 'description',
      delegationCategory: 'delegationCategory',
      delegationCategoryId: 'delegationCategoryId',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useDelegationFormData
