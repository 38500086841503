export type TrackProps<T = any> = Readonly<{
  id: number
  sortOrder: number
  meta: T
}>

export type SessionProps<S = any> = Readonly<{
  startDate: Date
  lengthInMinutes: number
  id: number
  trackId: number
  meta: S
  soughtDate?: Date
}>

export type Callbacks<T extends TrackProps, S extends SessionProps> = Readonly<{
  onStartDrag?: (ref: React.RefObject<HTMLDivElement>, meta: S) => void
  onStopDrag?: (ref: React.RefObject<HTMLDivElement>, meta: S) => void
  // onUpdateDrag: (ref: React.RefObject<HTMLDivElement>, meta: {}) => void
  onDrop?: (ref: React.RefObject<HTMLDivElement>, meta: T) => S | Promise<S>
  onStartResize?: (
    ref: React.RefObject<HTMLDivElement>,
    meta: S,
    anchor: 'up' | ' down'
  ) => void
  // onUpdateResize: (ref: React.RefObject<HTMLDivElement>, meta: {}) => void
  onUpdate?: (props: {
    internal: Omit<S, 'meta'>
    meta: S['meta']
    action: 'add' | 'remove' | 'update'
  }) => void
  onDoubleClick?: (props: {
    internal: Omit<T, 'meta'>
    meta: T['meta']
    startDate: Date
  }) => void
  onScroll?: (props: { topDate: Date; bottomDate: Date }) => void
  // onClick: (ref: React.RefObject<HTMLDivElement>, meta?: S) => void
  // onDbClick: (ref: React.RefObject<HTMLDivElement>, meta: S) => void
}>

// export type Spec<S, DropResult, CollectedProps> = Omit<
//   DropTargetHookSpec<DragObjectWithType & S, DropResult, CollectedProps>,
//   'accept'
// >

export const ItemTypes = {
  SESSION_UPDATE: 'SESSION_UPDATE',
  SESSION_REMOVE: 'SESSION_REMOVE',
  SESSION_ADD: 'SESSION_ADD',
}
