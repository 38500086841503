import { useSupportUsersApi } from 'api'
import { UserItem, UserUpdateItem } from 'api/tenilo.support.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useSupportUserModalContext = () => {
  const { getUser, updateUser } = useSupportUsersApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedUser, setSelectedUser] = React.useState<UserItem | undefined>()

  const fetchUserAsync = React.useCallback(
    async (userId: number) => {
      await fetchAsync(getUser(userId)).then(user => {
        if (user instanceof UserItem) {
          setSelectedUser(user)
        }
      })
    },
    [fetchAsync, getUser]
  )

  const openSupportUserModal = React.useCallback(
    (userId?: number) => {
      if (!!userId) {
        void fetchUserAsync(userId).then(_ => setModalOpen(true))
      } else {
        setModalOpen(true)
      }
    },
    [fetchUserAsync]
  )

  const closeSupportUserModal = React.useCallback(() => {
    setSelectedUser(undefined)
    setModalOpen(false)
  }, [])

  const updateUserAsync = React.useCallback(
    (user: UserUpdateItem) => {
      return fetchAsync(updateUser(user))
        .then(() => {
          //void fetchSupportUsersAsync()
          closeSupportUserModal()
        })
        .catch(() => {
          closeSupportUserModal()
        })
    },
    [closeSupportUserModal, fetchAsync, updateUser]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedUser,
    },
    actions: {
      openSupportUserModal,
      closeSupportUserModal,
      updateUserAsync,
      setSelectedUser,
    },
  }
}

export const SupportUserModalContext = createContext(useSupportUserModalContext)
