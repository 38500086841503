import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { useField } from 'formik'
import { Box, Button, Typography } from '@mui/material'

type Props = Readonly<{
  onTransformClick: () => void
  onAbortTransformClick: () => void
}>
const TransformToOrganizationReportView: FC<Props> = ({onTransformClick, onAbortTransformClick}) => {
  const f = useFormatMessage()
  const [field, meta, helpers] = useField({ name: 'TransformToOrganizationReport' })

  const [inTransformation, setInTransformation] = React.useState<boolean>(false);

  const transformToOrgReport = React.useCallback(() => {
    helpers.setValue(true)
    setInTransformation(true)
    onTransformClick()
  }, [])

  const abortTransformToOrgReport = React.useCallback(() => {
    helpers.setValue(undefined)
    setInTransformation(false)
    onAbortTransformClick()
  }, [])

  return  React.useMemo(()=>{
    return (
      <Box sx={{
        width: '50%',
        margin: '0px 0px 10px 0',
        padding: '10px 0 0 0'
      }}>
        {!inTransformation && (
          <>
            <Button onClick={transformToOrgReport}>{f('REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM')}</Button>
          </>
        )}
        {inTransformation && (
          <>
            <Typography>{f('REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM_INFO')}</Typography>
            <Button onClick={abortTransformToOrgReport}>{f('REPORT_PAGE_VIEW_SECTION_AVAILABILITIES_TRANSFORM_ABORT')}</Button>
          </>
        )}
      </Box>
    )

  },[field.value,field.name, inTransformation, transformToOrgReport, abortTransformToOrgReport])
}

export default TransformToOrganizationReportView
