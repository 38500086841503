import React from 'react'
import * as yup from 'yup'

import {
  TemplateActivityInheritanceCreateItem,
  TemplateActivityItem,
  ITemplateActivityInheritanceItem,
  UserAreaItem,
  TemplateActivityInheritanceUpdateItem,
} from 'api/tillit.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { InheritedTemplateActivityModalContext } from '../inherited-template-activity-modal-context'

type TemplateActivityInheritanceForm = Readonly<{
  area: UserAreaItem | undefined
  areaId: number | undefined
  description: string
  templateActivity: TemplateActivityItem | undefined
  templateActivityId: number
  useTemplateDescription: boolean
}>

const createInheritedTemplateActivity = (values: TemplateActivityInheritanceForm): TemplateActivityInheritanceCreateItem => {
  const { area, description, templateActivity, useTemplateDescription } = values

  return new TemplateActivityInheritanceCreateItem({
    areaId: area ? area.areaId : -1,
    description,
    templateActivityId: templateActivity ? templateActivity.id : -1,
    useTemplateDescription,
  })
}

const mapTemplateActivityInheritanceFormToInheritedTemplateActivityItem = (
  initialItem: ITemplateActivityInheritanceItem,
  item: TemplateActivityInheritanceForm
): TemplateActivityInheritanceUpdateItem => {
  return new TemplateActivityInheritanceUpdateItem({
    id: initialItem.id,
    areaId: item.area ? item.area.areaId : -1,
    description: item.description,
    useTemplateDescription: item.useTemplateDescription,
    templateActivityId: item.templateActivityId,
  })
}

const mapInheritedTemplateActivityItemToTemplateActivityInheritanceForm = (item: ITemplateActivityInheritanceItem): TemplateActivityInheritanceForm => {
  const area: UserAreaItem = new UserAreaItem()
  area.areaId = item.areaId
  let areaName = item.areaName || ''
  if (item.areaOrganizationParentsName) {
    areaName =  `${item.areaName} (${item.areaOrganizationParentsName})`
  }
  area.areaName = areaName

  const templateActivity: TemplateActivityItem = new TemplateActivityItem()
  templateActivity.id = item.templateActivityId
  templateActivity.title = item.templateActivityTitle
  templateActivity.templateActivityCategoryId = item.templateActivityCategoryId
  templateActivity.templateActivityCategoryTitle = item.templateActivityCategoryTitle
  templateActivity.templateActivityLegislationId = item.legislationId
  templateActivity.templateActivityLegislationTitle = item.legislationTitle
  templateActivity.templateActivityTypeId = item.templateActivityTypeId

  const createItem: TemplateActivityInheritanceForm = {
    area,
    useTemplateDescription: item.useTemplateDescription,
    description: item.description || '',
    areaId: item.areaId,
    templateActivity,
    templateActivityId: item.templateActivityId,
  }
  return createItem
}

const useTemplateActivityInheritanceFormData = (initialInheritedTemplateActivity?: ITemplateActivityInheritanceItem) => {
  const f = useFormatMessage()

  const { createInheritedTemplateActivityAsync, updateTemplateActivityInheritanceAsync } = InheritedTemplateActivityModalContext.useActions()

  const activity = initialInheritedTemplateActivity

  const initialValues = React.useMemo<TemplateActivityInheritanceForm>(
    () =>
      !!activity
        ? mapInheritedTemplateActivityItemToTemplateActivityInheritanceForm(activity)
        : {
            area: undefined,
            useTemplateDescription: true,
            description: '',
            areaId: undefined,
            templateActivity: undefined,
            templateActivityId: -1,
          },
    [activity]
  )

  const onSubmit: OnSubmitFunction<TemplateActivityInheritanceForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const templateActivityInheritance = createInheritedTemplateActivity({
        ...values,
      })
      if (!initialInheritedTemplateActivity) {
        await createInheritedTemplateActivityAsync(templateActivityInheritance).finally(() => setSubmitting(false))
      } else {
        const mappedTemplateActivityInheritance = mapTemplateActivityInheritanceFormToInheritedTemplateActivityItem(initialInheritedTemplateActivity, values)

        if (initialInheritedTemplateActivity.templateActivityId !== values.templateActivityId) {
          await updateTemplateActivityInheritanceAsync(mappedTemplateActivityInheritance, true).finally(() => setSubmitting(false))
        } else {
          await updateTemplateActivityInheritanceAsync(mappedTemplateActivityInheritance, false).finally(() => setSubmitting(false))
        }
      }
    },
    [createInheritedTemplateActivityAsync, initialInheritedTemplateActivity, updateTemplateActivityInheritanceAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      description: yup.string().max(
        1024,
        f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
          name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
          maxsize: 1024,
        })
      ),

      area: yup
        .object()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .typeError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      templateActivity: yup
        .object()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .typeError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
    })
  }, [f])

  const name: Name<TemplateActivityInheritanceForm> = React.useMemo(
    () => ({
      area: 'area',
      areaId: 'areaId',
      description: 'description',
      templateActivity: 'templateActivity',
      templateActivityId: 'templateActivityId',
      useTemplateDescription: 'useTemplateDescription',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useTemplateActivityInheritanceFormData
