import React from 'react'

import { useOrganizationApi } from 'api'
import {
  AreaListItem,
  ChildAreaOrRoutesForParentAreaItem,
} from 'api/tillit.api-client'
import { setTokenData } from 'api/token-data'
import usePromiseApi from 'utilities/use-promise'
import { Level } from '../organization-context'

export const useLevels = (initialLevels?: Level[]) => {
  const { getChildrensForAreaNode, createAreaNode } = useOrganizationApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const [levels, setLevels] = React.useState<Level[]>(initialLevels || [])

  const addLevel = React.useCallback((level: Level) => {
    setLevels(prevLevels => {
      const { areaListItem } = level
      const index = prevLevels.findIndex(
        l => l.areaListItem.parentAreaId === areaListItem.parentAreaId
      )

      // Add as only level
      if (prevLevels.length === 0) {
        return [level]
      }

      // Add as last level
      if (index === -1) {
        return [...prevLevels, level]
      }

      // Add in between level
      return [...prevLevels.slice(0, index), level]
    })
  }, [])

  const addAreaListItemAsync = React.useCallback(
    (areaListItem: AreaListItem) => {
      // Create draft level
      const draftLevel: Level = { areaListItem, childrenOrRoutes: null }
      addLevel(draftLevel)

      // fetch children for draft level
      return fetchAsync(
        getChildrensForAreaNode(areaListItem.id)
          .catch(_ =>
            Promise.reject(
              Error(
                `Could not get children for area ${areaListItem.name || ''}`
              )
            )
          )
          .then(childrenOrRoutes => {
            if (childrenOrRoutes === null) {
              return Promise.reject(
                Error(
                  `No children or routes found for area ${areaListItem.name ||
                    ''}`
                )
              )
            } else {
              // Else, update with new level
              addLevel({
                ...draftLevel,
                childrenOrRoutes: new ChildAreaOrRoutesForParentAreaItem(
                  childrenOrRoutes
                ),
              })
              return Promise.resolve()
            }
          })
      )
    },
    [addLevel, fetchAsync, getChildrensForAreaNode]
  )

  const createAreaListItemAsync = React.useCallback(
    (levelId: number, name?: string) => {
      const level = levels.find(
        ({ areaListItem }) => areaListItem.id === levelId
      )

      if (level === undefined) {
        throw Error(`Could not find level for level id ${levelId}`)
      }

      return fetchAsync(
        createAreaNode({ parentAreaId: levelId, name })
          .catch(_ =>
            Promise.reject(Error(`Could not create area ${name || ''}`))
          )
          .then(areaItem => {
            if (areaItem === null) {
              return Promise.reject('No jwt token received')
            }
            if (areaItem.newJwtToken) {
              setTokenData(areaItem.newJwtToken)
            }
            addAreaListItemAsync(level.areaListItem)
            return Promise.resolve(areaItem.newlyCreatedAreaId)
          })
      )
    },
    [addAreaListItemAsync, createAreaNode, fetchAsync, levels]
  )

  return React.useMemo(
    () => ({
      state: { levels, stateOfFetch },
      actions: { addAreaListItemAsync, createAreaListItemAsync },
    }),
    [addAreaListItemAsync, createAreaListItemAsync, levels, stateOfFetch]
  )
}
