import { useSupportUserImpersonationRequestsApi } from 'api'
import { UserImpersonationRequestItem } from 'api/tenilo.support.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useSupportUserImpersonationRequestModalContext = () => {
  const { getUserImpersonationRequest, abandonUserImpersonationRequest, generateJwtTokenForUserImpersonationRequest } = useSupportUserImpersonationRequestsApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [selectedUserImpersonationRequest, setSelectedUserImpersonationRequest] = React.useState<UserImpersonationRequestItem | undefined>()

  const fetchUserImpersonationRequestAsync = React.useCallback(
    async (userimpersonationrequestId: number) => {
      await fetchAsync(getUserImpersonationRequest(userimpersonationrequestId)).then(userimpersonationrequest => {
        if (userimpersonationrequest instanceof UserImpersonationRequestItem) {
          setSelectedUserImpersonationRequest(userimpersonationrequest)
        }
      })
    },
    [fetchAsync, getUserImpersonationRequest]
  )

  const generateJwtTokenForUserImpersonationRequestAsync = React.useCallback(
    async (userimpersonationrequestId: number) => {
      await fetchAsync(generateJwtTokenForUserImpersonationRequest(userimpersonationrequestId))
      //.then(() => {
       //console.log("jejjeje")
      //})
    },
    [fetchAsync, generateJwtTokenForUserImpersonationRequest]
  )

  const openSupportUserImpersonationRequestModal = React.useCallback(
    (userimpersonationrequestId?: number) => {
      if (!!userimpersonationrequestId) {
        void fetchUserImpersonationRequestAsync(userimpersonationrequestId).then(_ => setModalOpen(true))
      } else {
        setModalOpen(true)
      }
    },
    [fetchUserImpersonationRequestAsync]
  )

  const closeSupportUserImpersonationRequestModal = React.useCallback(() => {
    setSelectedUserImpersonationRequest(undefined)
    setModalOpen(false)
  }, [])

  const abandonUserImpersonationRequestAsync = React.useCallback(
    (id: number) => {
      return fetchAsync(abandonUserImpersonationRequest(id))
        .then(() => {
          closeSupportUserImpersonationRequestModal()
        })
        .catch(() => {
          closeSupportUserImpersonationRequestModal()
        })
    },
    [closeSupportUserImpersonationRequestModal, fetchAsync, abandonUserImpersonationRequest]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      selectedUserImpersonationRequest,
    },
    actions: {
      openSupportUserImpersonationRequestModal,
      closeSupportUserImpersonationRequestModal,
      abandonUserImpersonationRequestAsync,
      setSelectedUserImpersonationRequest,
      generateJwtTokenForUserImpersonationRequestAsync,
      fetchUserImpersonationRequestAsync,
    },
  }
}

export const SupportUserImpersonationRequestModalContext = createContext(useSupportUserImpersonationRequestModalContext)
