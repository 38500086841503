import React from 'react'
import useDebounce from 'utilities/use-debounce'

const calculateViewWidth = (innerWidth: number) => {
  if (innerWidth <= 640) {
    return 100
  }
  const value = Math.min(innerWidth, 1680)
  return (1 + (1 - 640 / value) * (-0.1 / (1 - 640 / 1680))) * 100
}

const calculateViewHeight = (innerHeight: number) => 90

const useDebounceState = <T extends object>(
  initValues: T
): [T, (value: T) => void] => {
  const [state, setState] = React.useState(initValues)
  const debounceState = useDebounce(state, 1000)
  return [debounceState, setState]
}

export const useViewSize = () => {
  const { innerWidth, innerHeight } = window
  const [debounceSize, setInnerSize] = useDebounceState({
    innerWidth,
    innerHeight,
  })

  const calculatedSize = React.useMemo(() => {
    const width = calculateViewWidth(debounceSize.innerWidth)
    const height = calculateViewHeight(debounceSize.innerHeight)
    return { width, height }
  }, [debounceSize.innerHeight, debounceSize.innerWidth])

  React.useEffect(() => {
    const handleResize = () => {
      setInnerSize({
        innerWidth,
        innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [innerHeight, innerWidth, setInnerSize])

  return calculatedSize
}
