import React from 'react'
import { createContext } from 'utilities/create-context'
import Authentication from 'components/authentication'
import usePromiseApi from 'utilities/use-promise'
import { useSupportAreasApi, useSupportOrganizationsApi } from 'api'
import { AreaListItem, OrganizationListItem } from 'api/tenilo.support.api-client'

const useSupportPageContext = () => {
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const { listOrganizations } = useSupportOrganizationsApi()
  const { listAreas } = useSupportAreasApi()

  const [selectedArea, setSelectedArea] = React.useState<AreaListItem | undefined>(undefined)
  const [areas, setAreas] = React.useState<AreaListItem[]>([])

  const [selectedOrganization, setSelectedOrganization] = React.useState<OrganizationListItem | undefined>(undefined)
  const [organizations, setOrganizations] = React.useState<OrganizationListItem[]>([])

  const fetchAreas = React.useCallback(() => {
    if (!selectedOrganization) {
      return
    }
    return fetchAsync(listAreas(1, 1000, selectedOrganization.id, null))
      .then(result => {
        if (result) {
          setAreas(result.items)
        }
        return result
      })
      .catch(err => { throw err })
  }, [selectedOrganization])

  const fetchOrganizations = React.useCallback(() => {

    return fetchAsync(listOrganizations(1, 1000, null))
      .then(result => {
        if (result) {
          setOrganizations(result.items)
        }
        return result
      })
      .catch(err => { throw err })
  }, [])

  React.useEffect(() => {
    setSelectedArea(undefined)
    setAreas([])
    try {
      void fetchAreas()
    } catch (error) {
      console.log(error)
    }
  }, [selectedOrganization])

  React.useEffect(() => {
    try {
      void fetchOrganizations()
    } catch (error) {
      console.log(error)
    }
  }, [fetchOrganizations])

  return {
    state: {
      currentUser,
      stateOfFetch,
      selectedOrganization,
      organizations,
      selectedArea,
      areas,
    },
    actions: {
      setSelectedArea,
      setSelectedOrganization
    },
  }
}

export default createContext(useSupportPageContext)
