import { MonthlyDayEnum, RecurrenceEnum } from 'api/tillit.api-client'
import { subDays, isSameMonth } from 'date-fns'
import { useFormatMessage } from 'localization'
import format from 'utilities/format'

export type ActivityStatusItem = Readonly<{
  startDate: Date
  endDate?: Date
  fromTime: Date
  toTime: Date
  recurrenceId: RecurrenceEnum
  recurrenceInterval: number
  monthlyDayId: MonthlyDayEnum | undefined
  Monday: boolean
  Tuesday: boolean
  Wednesday: boolean
  Thursday: boolean
  Friday: boolean
  Saturday: boolean
  Sunday: boolean
}>

const countPreviousDaysInSameMonth = (date: Date, counter = 1) : number => {
  const previousDate = subDays(date, 7)
  if (isSameMonth(date, previousDate)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return countPreviousDaysInSameMonth(previousDate, counter + 1)
  } else {
    return counter
  }
}

export const getDaysAsString = (...days: boolean[]): string =>
  days
    .reduce<number[]>((prev, next, index) => {
      if (next) {
        return [...prev, index]
      }
      return prev
    }, [])
    .map(day => {
      const date = new Date(0)
      date.setDate(day + 5)
      return date
    })
    .map(d => format(d, 'eeee'))
    .join(', ')

export const getWeekOfMonthDateAsString = (f: ReturnType<typeof useFormatMessage>) => (startDate: Date): string =>
  f('CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_ACTIVITIES_WEEK_ORRDINAL', {
    week: Math.min(
      5,
      countPreviousDaysInSameMonth(startDate)
    ),
  })

const getPrefix = (f: ReturnType<typeof useFormatMessage>) => (item: ActivityStatusItem): string => {
  const { startDate, recurrenceId, monthlyDayId } = item
  if (recurrenceId === RecurrenceEnum.Weekly) {
    const days = [item.Monday, item.Tuesday, item.Wednesday, item.Thursday, item.Friday, item.Saturday, item.Sunday]
    if (days.filter(x => x).length === days.length) {
      return f('GENERATE_ACTIVITY_STATUS_PREFIX_EVERY_DAY_TEXT')
    }
    return getDaysAsString(...days)
  }

  if (recurrenceId === RecurrenceEnum.Monthly) {
    if (monthlyDayId === MonthlyDayEnum.MonthlyDateInterval) {
      return format(startDate, 'do')
    }
    if (monthlyDayId === MonthlyDayEnum.MonthlyDayInterval) {
      const weekOfMonthString = getWeekOfMonthDateAsString(f)(startDate)
      const dayOfWeek = format(startDate, 'EEEE')
      return `${weekOfMonthString} ${dayOfWeek}`
    }
  }
  return ''
}

const getType = (recurrenceId: RecurrenceEnum): 'monthly' | 'weekly' | 'daily' | 'onetime' => {
  switch (recurrenceId) {
    case RecurrenceEnum.Monthly: {
      return 'monthly'
    }
    case RecurrenceEnum.Weekly: {
      return 'weekly'
    }
    case RecurrenceEnum.Daily: {
      return 'daily'
    }
    case RecurrenceEnum.OneTime: {
      return 'onetime'
    }
  }
}

export const getDayOfMonthAsString = (recurrenceInterval: number): string => {
  const recurrenceIntervalDate = new Date(0)
  recurrenceIntervalDate.setDate(recurrenceInterval)
  return format(recurrenceIntervalDate, 'do')
}

export const generateActivityStatusText = (f: ReturnType<typeof useFormatMessage>) => (item: ActivityStatusItem): string => {
  const { recurrenceInterval, startDate, recurrenceId, endDate } = item
  let { fromTime, toTime } = item
  const dayOfMonth = getDayOfMonthAsString(recurrenceInterval)
  const recurrenceType = getType(recurrenceId)

  const prefix = getPrefix(f)(item)

  if (!(fromTime && !isNaN(fromTime as any) && fromTime instanceof Date)) {
    fromTime = new Date()
  }

  if (!(toTime && !isNaN(toTime as any) && toTime instanceof Date)) {
    toTime = new Date()
  }

  if (recurrenceId !== RecurrenceEnum.OneTime) {
    return f('CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_ACTIVITIES_STATUS', {
      prefix,
      recurrenceInterval,
      dayOfMonth,
      recurrenceType,
      startDate,
      endDate,
      fromTime,
      toTime,
      hasEndDate: !(!endDate || endDate === startDate),
    })
  } else {
    return f('CUSTOMERS_PAGE_ADD_CUSTOMER_MODAL_SECTION_DESIRED_TEMPORARY_ACTIVITIES_STATUS', {
      prefix,
      startDate,
      fromTime,
      toTime,
    })
  }
}
