import styled from 'styled-components'
import TextButtonView from './text-button-view'

const TextButtonStyle = styled(TextButtonView)(
  ({ color, theme }) => `
    ${!!color && color === 'error' ? `color: ${theme.palette.error.main};` : ''}
  `
)

export default TextButtonStyle
