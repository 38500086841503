import { IRouteCreateItem, IRouteUpdateItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'

interface ICreateRouteModel {
  routeName: string
  routeColor: string
}

const useCreateRouteModalFormData = () => {
  const f = useFormatMessage()
  const { createRouteModalData, createRouteModalPosition, routes = [] } = PlanningPageContext.useState()

  const { onCloseCreateRouteModal, createRouteAsync } = PlanningPageContext.useActions()

  const routeNames = routes.reduce<string[]>((prevRoute, route) => {
    if (createRouteModalData && createRouteModalData.name && route.name === createRouteModalData.name && route.id === createRouteModalData.id) {
      return prevRoute
    }
    return [...prevRoute, route.name]
  }, [])

  const initialValues: ICreateRouteModel = React.useMemo(
    () => ({
      routeName: 'Ny tur',
      routeColor: '#000000',
    }),
    [createRouteModalData]
  )

  const onSubmit: OnSubmitFunction<ICreateRouteModel> = React.useCallback(
    (values, { setSubmitting }) => {
      if (!createRouteModalData) {
        return
      }

      let routeStartDate: Date = new Date()
      if (createRouteModalData && createRouteModalData.plannedRoutes && createRouteModalData.plannedRoutes[0]) {
        routeStartDate = createRouteModalData.plannedRoutes[0].calendarDay
        if (createRouteModalData.plannedRoutes[1] && createRouteModalData.plannedRoutes[1].calendarDay > routeStartDate) {
          routeStartDate = createRouteModalData.plannedRoutes[1].calendarDay
        }
      }

      const sortingIndex = createRouteModalPosition === 'left' ? createRouteModalData.sortingIndex! : createRouteModalData.sortingIndex! + 1

      const model: IRouteCreateItem = {
        name: values.routeName,
        color: values.routeColor,
        sortingIndex: sortingIndex,
        areaId: createRouteModalData.areaId,
        startDate: routeStartDate,
      }

      createRouteAsync(model)
      onCloseCreateRouteModal()
      setSubmitting(false)
    },
    [onCloseCreateRouteModal, createRouteAsync, createRouteModalData, createRouteModalPosition]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ICreateRouteModel>({
        routeName: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .notOneOf(routeNames, f('ROUTE_EDIT_MODAL_UNIQUE_NAME_HELPER_TEXT'))
          .max(
            40,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_FORM_NAME_LABEL'),
              maxsize: 40,
            })
          ),
        routeColor: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            7,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLORPICKER_LABEL'),
              maxsize: 7,
            })
          ),
      }),
    [routeNames, f]
  )

  const name: Name<ICreateRouteModel> = React.useMemo(
    () => ({
      routeName: 'routeName',
      routeColor: 'routeColor',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useCreateRouteModalFormData
