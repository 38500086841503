import React from 'react'
import { createContext } from 'utilities/create-context'
import { BulletinBoardItemModel } from '../../bulletin-board-context'

const useBulletinBoardItemModalContext = () => {

  const [bulletinBoardItemModalOpen, setBulletinBoardItemModalOpen] = React.useState(false)

  const [selectedBulletinBoardItem, setSelectedBulletinBoardItem] = React.useState<BulletinBoardItemModel | undefined>()

  const openBulletinBoardItemModal = React.useCallback(
    (item: BulletinBoardItemModel | undefined) => {
      setSelectedBulletinBoardItem(item)
      setBulletinBoardItemModalOpen(true)
    }, [])

  const closeBulletinBoardItemModal = React.useCallback(() => {
    setSelectedBulletinBoardItem(undefined)
    setBulletinBoardItemModalOpen(false)
  }, [])

  return {
    state: {
      bulletinBoardItemModalOpen,
      selectedBulletinBoardItem,
    },
    actions: {
      openBulletinBoardItemModal,
      closeBulletinBoardItemModal,
      setSelectedBulletinBoardItem,
    },
  }
}

export const BulletinBoardItemModalContext = createContext(useBulletinBoardItemModalContext)
