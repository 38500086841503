import styled, { css } from 'styled-components'
import Colors from 'styles/colors'
import ContainedButtonContainer from './contained-button-container'

const ContainedButtonStyle = styled(ContainedButtonContainer)`
  ${({ color, theme }) =>
    !color
      ? ''
      : color === 'error'
      ? css`
          background-color: ${theme.palette.error.main};
          color: ${theme.palette.error.contrastText};

          &:hover {
            background-color: ${theme.palette.error.dark};
          }
        `
        : color === 'partial'
        ? css`
            background-color: ${Colors.TeniloYellow};
            color: ${theme.palette.error.contrastText};
  
            &:hover {
              background-color: ${Colors.TeniloYellowDark};
            }
          `
      : color === 'success'
      ? css`
          background-color: ${Colors.JungleGreen};
          color: white;

          &:hover {
            background-color: ${Colors.JungleGreenDark};
          }
        `
      : ''}
`

export default ContainedButtonStyle
