import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { Box, Button } from '@mui/material'
import { UserTypeEnum } from 'api/tillit.api-client'
import type { ReportsPageFormType, ActionBarPropType } from 'pages/reports/config'
import { DeleteDialog } from 'components/delete-dialog'
import ReportsPageContext from 'pages/reports/reports-page-context'
import { useFormikContext } from 'formik'


const ReportsPageSubmitSectionView: FC<ActionBarPropType> = ({ toggleSaveAsSection, openSaveAsSection }) => {
  const f = useFormatMessage()
  const { values, submitForm, isValid, errors, setFieldValue, setTouched } = useFormikContext<ReportsPageFormType>()
  const { userReport, currentUser } = ReportsPageContext.useState()
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)

  const openDeleteDialog = React.useCallback(() => {
    setDeleteDialogOpen(true)
  }, [])

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteDialogOpen(false)
  }, [])

  const onRemove = React.useCallback((type: string) => () => {
    setFieldValue('submitType', type)
    submitForm()
  }, [])

  const onSubmit = React.useCallback((type: string) => () => {
    if (!isValid) {
      const shouldTouchSomeFields = Object.keys(errors).length > 0
      if (shouldTouchSomeFields) {
        setTouched(Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {}))
      }
      return
    }
    setFieldValue('submitType', type)
    submitForm()
  }, [isValid, errors])

  return (
    <Box>
      <Button disabled={openSaveAsSection} onClick={onSubmit('view')}>{f('REPORT_PAGE_VIEW_FILTER_SELECTION_ViewReport')}</Button>
      {
        values.report?.isBaseReport ||
          ((values.report?.isOrganizationReport && !(currentUser.userType === UserTypeEnum.OrganizationAdmin || currentUser.userType === UserTypeEnum.TeniloSupport)) ||
            !values.report?.isUserReport) ?
          <Button disabled={openSaveAsSection} onClick={toggleSaveAsSection}>{f('REPORT_PAGE_VIEW_SECTION_SUBMIT_SAVEAS')}</Button>
          :
          <>
            <Button disabled={openSaveAsSection} onClick={onSubmit('update')}>{f('REPORT_PAGE_VIEW_SECTION_SUBMIT_UPDATE')}</Button>
            <Button disabled={openSaveAsSection} onClick={toggleSaveAsSection}>{f('REPORT_PAGE_VIEW_SECTION_SUBMIT_SAVEAS')}</Button>
          </>
      }
      {
        userReport && (userReport.createdById === currentUser.id || currentUser.userType === UserTypeEnum.OrganizationAdmin || currentUser.userType === UserTypeEnum.TeniloSupport) && (
          <Button disabled={openSaveAsSection} onClick={openDeleteDialog}>{f('REPORT_PAGE_VIEW_SECTION_SUBMIT_REMOVE')}</Button>
        )
      }
      <DeleteDialog
        dialogTitle={f('REPORT_PAGE_VIEW_DELETE_DIALOG_TITLE')}
        dialogContentText={f('REPORT_PAGE_VIEW_DELETE_DIALOG_DESCRIPTION', { title: values.report?.name })}
        buttonSubmitText={f('REPORT_PAGE_VIEW_DELETE_DIALOG_REMOVE_BUTTON')}
        buttonCancelText={f('REPORT_PAGE_VIEW_DELETE_DIALOG_CANCEL_BUTTON')}
        deleteDialogOpen={deleteDialogOpen}
        onClose={closeDeleteDialog}
        onSubmit={onRemove('remove')}
      />
    </Box>
  )
}

export default ReportsPageSubmitSectionView
