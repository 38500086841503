import React, { useCallback, useMemo } from 'react'
import { Box, MenuItem, Typography, useTheme } from '@mui/material'
import { Portal } from '@mui/base/Portal'
import { Comment as CommentIcon, Information, Text as TextSubjectIcon, CheckCircle, ClockAlert as TimeCritical, SyncOff as TemporaryActivity, AccountMultiple as ParticipantsIcon } from 'mdi-material-ui'
import { format } from 'date-fns'
import {
  IPlannedActivityRowItem,
  PlannedActivityStatusEnum,
  OrganizationSigningStatusesItem,
  IUserItem,
  RecurrenceEnum,
  CustomerListItem,
  PlannedActivitySignedParticipantCreateItem,
  PlannedActivitySignedDelegationViolationsCreateItem,
} from 'api/tillit.api-client'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import ContainedMenuButton from './components/contained-menu-button'
import { SeverityLevelEnum } from 'pages/planning/components/temporary-activity-create-modal/temporary-activity-create-modal-context'
import { DeleteDialog } from 'components/delete-dialog'
import { pickSigningDetails, signingStatusHandleDiff, getStatusColor } from 'utilities/signing-status-helpers'
import SortedParticipants from 'components/sorted-participants'
import { InfoSignedContainer } from './components/info-signed'
import ListingModal from './components/listing-modal'
import { SingleActivityCommentModal } from './components/single-activity-comment-modal'
import SingleActivityModalContext from './single-activity-modal-context'
import { SingleActivityPartialModal } from './components/single-activity-partial-modal'
import SingleActivityParticipantsModal from './components/single-activity-participants-modal'
import { SingleActivityModalMainBody, ModalSectionIconContainer } from './single-activity-modal-style'
import DelegationsFulfillment from './components/delegations-fulfillment'
import DelaySignings from './components/delay-signings'
import RecentlyPerfomedSignings from './components/recently-performed-signings'

export interface ISingleActivityModalContainerProps {
  onSingleActivityUndoOpen?: (plannedActivity: IPlannedActivityRowItem) => void
  updateDataOnStatusChanged: (plannedActivity: IPlannedActivityRowItem) => void
  onSingleActivityRefresh: () => void
  signingStatuses: OrganizationSigningStatusesItem | undefined
  currentUser: IUserItem | undefined
  listCustomer?: CustomerListItem[] | undefined
}

const SingleActivityModalContainer: React.FC<ISingleActivityModalContainerProps> = ({
  onSingleActivityUndoOpen,
  updateDataOnStatusChanged,
  onSingleActivityRefresh,
  currentUser,
  signingStatuses,
  listCustomer,
}) => {
  const f = useFormatMessage()
  const theme = useTheme()
  const {
    fetchState,
    open,
    plannedActivity,
    delaySignings,
    delayedActivityShouldOccurToday,
    activityHasActiveDelayedOccurance,
    recentlyPerformedSignings,
    temporaryActivityCreatedToday,
    hasDelegationsNotFulfilled,
    delegationsFulfillment,
    removeActivityDialogOpen,
    isMeasurementUnitType,
    activityHasUnitType,
    isPartiallyCompletedModalOpen,
  } = SingleActivityModalContext.useState()
  const {
    close,
    onSigningClick,
    onDelayClicked,
    onCompleteDelayedWithoutActionClicked,
    handleRemoveActivity,
    openRemoveActivityDialog,
    closeRemoveActivityDialog,
    onPartiallyCompletedClicked,
    onPartiallyGivenClicked,
    setIsPartiallyCompletedModalOpen,
  } = SingleActivityModalContext.useActions()

  const { isLoading } = fetchState
  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false)
  const [isParticipantModalOpen, setIsParticipantModalOpen] = React.useState(false)

  const handleCompleteDelayedWithoutActionClicked = React.useCallback(
    (statusId: PlannedActivityStatusEnum, comment?: string) => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      void onCompleteDelayedWithoutActionClicked(statusId, comment).then(updateDataOnStatusChanged)
      close()
    },
    [onCompleteDelayedWithoutActionClicked, updateDataOnStatusChanged]
  )

  const handleDelayClicked = React.useCallback(
    (comment?: string) => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      void onDelayClicked(comment).then(updateDataOnStatusChanged)
      close()
    },
    [onDelayClicked, updateDataOnStatusChanged]
  )

  const [givenAmountModalProps, setGivenAmountModalProps] = React.useState<{
    title: string
    subtitle?: string
    withComment: boolean
    plannedActivity: IPlannedActivityRowItem | null
    onSaveClick: (comment?: string, givenAmount?: number) => void
  } | undefined>(undefined)

  const handleSavePartiallyClicked = React.useCallback(
    (comment?: string, givenAmount?: number) => {
      if (isMeasurementUnitType) {
        void onPartiallyGivenClicked(comment, givenAmount).then(updateDataOnStatusChanged)
      }
      else {
        void onPartiallyCompletedClicked(comment, givenAmount).then(updateDataOnStatusChanged)
      }
      close()
    },
    [onPartiallyCompletedClicked, onPartiallyGivenClicked, updateDataOnStatusChanged, plannedActivity]
  )

  const handleUndoClick = React.useCallback(
    (planedActivity: IPlannedActivityRowItem) => () => {
      try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
      onSingleActivityUndoOpen?.(planedActivity)
    },
    [onSingleActivityUndoOpen]
  )

  const [commentModalProps, setCommentModalProps] = React.useState<{
    title: string
    subtitle?: string
    onSaveClick: (comment?: string) => void
  } | null>(null)


  const handlePartiallyCompletedClicked = useCallback((withComment: boolean = false) => () => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setIsPartiallyCompletedModalOpen(true)
    if (!plannedActivity) {
      return
    }
    setGivenAmountModalProps({
      title: f(`${isMeasurementUnitType ?
        'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_TITLE_PARTIALLY_GIVEN_TEXT'
        :
        'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_PARTIAL_DIALOG_MODAL_TITLE_PARTIALLY_COMPLETED_TEXT'
        }`),
      plannedActivity,
      withComment,
      onSaveClick: (comment?: string, givenAmount?: number) => {
        try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
        handleSavePartiallyClicked(comment, givenAmount)
        setIsPartiallyCompletedModalOpen(false)
        close()
      },
    })
  }, [handleSavePartiallyClicked])

  const [participantModalProps, setParticipantModalProps] = React.useState<{
    withComment: boolean
    plannedActivity: IPlannedActivityRowItem
    customers: CustomerListItem[] | undefined
    onSaveClick: (plannedActivitySignedParticipants: PlannedActivitySignedParticipantCreateItem[] | undefined, comment?: string) => void
    onCloseClick: () => void
  } | null>(null)

  const handleSigningWithParticipants = React.useCallback((statusId: PlannedActivityStatusEnum, withComment: boolean = false) => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    if (!plannedActivity) {
      return
    }
    let plannedActivitySignedDelegationViolations: PlannedActivitySignedDelegationViolationsCreateItem[] = []
    if (hasDelegationsNotFulfilled && delegationsFulfillment) {
      plannedActivitySignedDelegationViolations = delegationsFulfillment.filter(df => !df.isFulfilled).map(df => {
        return new PlannedActivitySignedDelegationViolationsCreateItem({
          delegationId: df.id
        })
      })
    }
    setIsParticipantModalOpen(true)
    setParticipantModalProps({
      withComment,
      plannedActivity: plannedActivity,
      customers: listCustomer,
      onSaveClick: (plannedActivitySignedParticipants: PlannedActivitySignedParticipantCreateItem[] | undefined, comment?: string) => {
        try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
        void onSigningClick(statusId, comment, plannedActivitySignedParticipants, plannedActivitySignedDelegationViolations, hasDelegationsNotFulfilled).then(updateDataOnStatusChanged)
        setIsParticipantModalOpen(false)
        close()
      },
      onCloseClick: () => {
        setIsParticipantModalOpen(false)
        close()
      }
    })
  }, [close, setIsParticipantModalOpen, plannedActivity, listCustomer, onSigningClick, setParticipantModalProps, updateDataOnStatusChanged])

  const handleSigning = React.useCallback((statusId: PlannedActivityStatusEnum, withComment: boolean = false) => () => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    let plannedActivitySignedDelegationViolations: PlannedActivitySignedDelegationViolationsCreateItem[] = []
    if (hasDelegationsNotFulfilled && delegationsFulfillment) {
      plannedActivitySignedDelegationViolations = delegationsFulfillment.filter(df => !df.isFulfilled).map(df => {
        return new PlannedActivitySignedDelegationViolationsCreateItem({
          delegationId: df.id
        })
      })
    }

    if (plannedActivity?.useParticipantList && statusId !== PlannedActivityStatusEnum.Started) {
      handleSigningWithParticipants(statusId, withComment);
    } else {
      if (withComment) {
        setIsCommentModalOpen(true)
        setCommentModalProps({
          title: f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_TITLE'),
          onSaveClick: (comment?: string) => {
            try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
            void onSigningClick(statusId, comment, undefined, plannedActivitySignedDelegationViolations, hasDelegationsNotFulfilled).then(updateDataOnStatusChanged)
            setIsCommentModalOpen(false)
            close()
          },
        })
      } else {
        void onSigningClick(statusId, undefined, undefined, plannedActivitySignedDelegationViolations, hasDelegationsNotFulfilled).then(updateDataOnStatusChanged)
        close()
      }
    }
  }, [onSigningClick, close, f, updateDataOnStatusChanged, setIsCommentModalOpen, handleSigningWithParticipants, plannedActivity])

  const handleDelayWithCommentClick = () => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setIsCommentModalOpen(true)
    setCommentModalProps({
      title: f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_LISTING_CONTAINER_HEADER_TITLE'),
      onSaveClick: (comment?: string) => {
        handleDelayClicked(comment)
        setIsCommentModalOpen(false)
        close()
      },
    })
  }

  const handleConfirmRemoveActivityClick = React.useCallback(() => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    if (plannedActivity == null) {
      return//PlannedActivity does not exist
    }
    closeRemoveActivityDialog()
    void handleRemoveActivity(plannedActivity!.id).then(() => {
      onSingleActivityRefresh()
    })
    close()
  }, [handleRemoveActivity, plannedActivity, close, onSingleActivityRefresh, closeRemoveActivityDialog])

  const handleClose = () => {
    try { navigator.vibrate(100); console.log("vibrate"); } catch (e) { console.log(e) }
    setIsCommentModalOpen(false)
    setIsPartiallyCompletedModalOpen(false)
    setIsParticipantModalOpen(false)
  }
  const signingButtonRules = useMemo(() => {
    const pickSigningDetailsCb = pickSigningDetails({
      f,
      useDrugList: !!currentUser?.useDrugLists,
      hasUnit: !!plannedActivity?.templateActivityUnitId,
      unitType: plannedActivity?.templateActivityUnitTypeEnumId,
      expectedAmount: plannedActivity?.activity_ExpectedAmount,
      signingStatuses
    })
    return {
      completeGroup: pickSigningDetailsCb({
        timeStatus: PlannedActivityStatusEnum.Completed,
        measurementStatus: PlannedActivityStatusEnum.Given
      }),
      notCompleteGroup: pickSigningDetailsCb({
        timeStatus: PlannedActivityStatusEnum.CompletedWithoutAction,
        measurementStatus: PlannedActivityStatusEnum.NotGiven
      }),
      partiallyCompleteGroup: pickSigningDetailsCb({
        timeStatus: PlannedActivityStatusEnum.PartiallyComplete,
        measurementStatus: PlannedActivityStatusEnum.PartiallyGiven
      }),
      selfCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.SelfGiven
      }),
      relativeCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.RelativeGiven
      }),
      sentAlongCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.SentAlong
      }),
      cannotTakeCompleteGroup: pickSigningDetailsCb({
        measurementStatus: PlannedActivityStatusEnum.CannotTake
      }),
      delayedGroup: signingStatusHandleDiff(f, signingStatuses, PlannedActivityStatusEnum.Delayed),
      completeDelayedWithoutActionGroup: signingStatusHandleDiff(f, signingStatuses, PlannedActivityStatusEnum.CompletedWithoutAction),
      startedGroup: signingStatusHandleDiff(f, signingStatuses, PlannedActivityStatusEnum.Started),
    }
  }, [plannedActivity, signingStatuses, currentUser, f])

  const containedButtonResponsiveStyle = useCallback((t) => {
    return {
      width: '100%',
      height: '100%',
      fontSize: '12px',
      [t.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        borderRadius: 0,
        height: '25px',
        letterSpacing: '0.5px'
      }
    }
  }, [])

  const templateActivityTitle = plannedActivity?.templateActivityTitle
  const title = plannedActivity?.activity_Title
  const combinedActivityTitle = templateActivityTitle ? templateActivityTitle + ' ' + (title ? '(' + title + ')' : '') : title ? title : ''

  const shouldShowtemplateActivityInheritanceDescription = plannedActivity && !!plannedActivity.templateActivityInheritanceDescription
  // Note: For Area TemplateActivities, useTemplateActivityDescription is set to true on backend (templateActivityInheritanceDescription will be null)
  const shouldShowtemplateActivityDescription = plannedActivity && plannedActivity.useTemplateActivityDescription && plannedActivity.templateActivityDescription
  const templateAreaDescriptionLabel =
    plannedActivity && plannedActivity.isAreaTemplateActivity ? f('BASIC_DESCRIPTION_AREA_TITLE_TEXT') : f('BASIC_DESCRIPTION_ORGANIZATION_TITLE_TEXT')

  const disableButtons = plannedActivity
    ? !(
      plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.NotCompleted ||
      plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.Delayed ||
      plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.Started
    )
    : true

  let hideCompleteSection = false
  let allowSigning = true
  let isDelayed = plannedActivity && plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.Delayed
  let isStarted = plannedActivity && plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.Started
  let allowRemove = false
  let abbreviation = ''
  let allowDrugList = false;

  let slash = ' / '

  if (plannedActivity?.templateActivityUnitAbbreviation !== undefined) {
    abbreviation = plannedActivity?.templateActivityUnitAbbreviation
  }

  if (temporaryActivityCreatedToday) {
    allowRemove = true
  }

  if (isDelayed && delayedActivityShouldOccurToday) {
    // the current activity is delayed and there is another instance planned for today
    // then then current activity should not be allowed to be completed
    hideCompleteSection = true
  }

  if (!isDelayed && activityHasActiveDelayedOccurance) {
    // the current activity is not delayed but there is a active delayed instance today
    // then then current activity should not be allowed to be complete or delay until the active delayed instance has been handled.
    hideCompleteSection = true
  }

  if (hasDelegationsNotFulfilled && plannedActivity && !plannedActivity.organization_AllowSigningWhenDelegationsIsNotFulfilled) {
    hideCompleteSection = true
    allowSigning = false
  }

  if (currentUser?.useDrugLists) {
    allowDrugList = true
  }

  let shouldShowStatusSection = !hideCompleteSection
  if (plannedActivity && !plannedActivity.signing_LatestSigningUser) {
    shouldShowStatusSection = false
  }

  const signedParticipantsDeclined = plannedActivity?.signing_LatestSignedParticipants?.filter(sp => sp.declined)
  const signedParticipantsAttended = plannedActivity?.signing_LatestSignedParticipants?.filter(sp => !sp.declined)

  return (
    <CenterModal open={open} onClose={close} responsive>
      {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
      {!isLoading && plannedActivity && (
        <>
          <ModalHeader
            data-matomo-mask
            responsive
            title={combinedActivityTitle}
            subtitle={
              plannedActivity.activity_OwnerCustomerRoomNumber?.trim()
                ? plannedActivity.activity_OwnerCustomerRoomNumber
                : plannedActivity.activity_OwnerAreaName
                  ? plannedActivity.activity_OwnerAreaName
                  : `${plannedActivity.activity_OwnerCustomerFirstName} ${plannedActivity.activity_OwnerCustomerLastName}`
            }
          />
          <SingleActivityModalMainBody>
            {!!plannedActivity.plannedActivityParticipants && plannedActivity.plannedActivityParticipants.length > 0 && (
              <ModalSection title={f('BASIC_PLANNED_PARTICIPANT_TITLE_TEXT')} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <ParticipantsIcon />
                  </ModalSectionIconContainer>
                  <SortedParticipants participants={plannedActivity.plannedActivityParticipants} />
                </ModalSectionRow>
              </ModalSection>
            )}
            {signedParticipantsAttended && signedParticipantsAttended.length > 0 && (
              <ModalSection title={f('BASIC_PARTICIPANT_TITLE_TEXT')} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <ParticipantsIcon />
                  </ModalSectionIconContainer>
                  <SortedParticipants signedParticipants={signedParticipantsAttended} />
                </ModalSectionRow>
              </ModalSection>
            )}
            {signedParticipantsDeclined && signedParticipantsDeclined.length > 0 && (
              <ModalSection title={f('BASIC_DECLINED_PARTICIPANT_TITLE_TEXT')} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <ParticipantsIcon />
                  </ModalSectionIconContainer>
                  <SortedParticipants signedParticipants={signedParticipantsDeclined} />
                </ModalSectionRow>
              </ModalSection>
            )}
            {!!plannedActivity.comment && (
              <ModalSection title={f('BASIC_COMMENT_TITLE_TEXT')} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <CommentIcon />
                  </ModalSectionIconContainer>
                  <Typography data-matomo-mask variant="body1" style={{ fontWeight: 500 }}>
                    {plannedActivity.comment}
                  </Typography>
                </ModalSectionRow>
              </ModalSection>
            )}
            {!!plannedActivity.activity_Comment && (
              <ModalSection title={f('BASIC_DESCRIPTION_TITLE_TEXT')} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <TextSubjectIcon />
                  </ModalSectionIconContainer>
                  <Typography data-matomo-mask variant="body1">{plannedActivity.activity_Comment}</Typography>
                </ModalSectionRow>
              </ModalSection>
            )}
            {shouldShowtemplateActivityInheritanceDescription && (
              <ModalSection title={f('BASIC_DESCRIPTION_AREA_TITLE_TEXT')} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <TextSubjectIcon />
                  </ModalSectionIconContainer>
                  <Typography data-matomo-mask variant="body1">{plannedActivity.templateActivityInheritanceDescription}</Typography>
                </ModalSectionRow>
              </ModalSection>
            )}
            {!!plannedActivity.activity_OwnerCustomerDescription && (
              <ModalSection title={f('BASIC_DESCRIPTION_OWNER_CUSTOMER_DESCRIPTION_TITLE_TEXT')} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <TextSubjectIcon />
                  </ModalSectionIconContainer>
                  <Typography data-matomo-mask variant="body1">{plannedActivity.activity_OwnerCustomerDescription}</Typography>
                </ModalSectionRow>
              </ModalSection>
            )}
            {shouldShowtemplateActivityDescription && (
              <ModalSection title={templateAreaDescriptionLabel} responsive>
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <TextSubjectIcon />
                  </ModalSectionIconContainer>
                  <Typography data-matomo-mask variant="body1">{plannedActivity.templateActivityDescription}</Typography>
                </ModalSectionRow>
              </ModalSection>
            )}
            {activityHasUnitType && allowDrugList && plannedActivity.activity_ExpectedAmount && (
              <ModalSection
                title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DRUGLISTSECTION_TITLE')}
                subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DRUGLISTSECTION_SUBTITLE')}
                responsive>
                <ModalSectionRow responsive>
                  <Typography variant="body1">{plannedActivity.activity_ExpectedAmount?.toString() + abbreviation}</Typography>
                </ModalSectionRow>
              </ModalSection>
            )}
            {plannedActivity.activity_SeverityLevelId === SeverityLevelEnum.Critical && (
              <ModalSection
                title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_TITLE')}
                subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_SUBTITLE')}
                responsive>
                <ModalSectionRow responsiveRow>
                  <TimeCritical />
                  <Typography variant="body1">{`${f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_SEVERITYLEVEL_TIMECRITICAL')} ${format(plannedActivity.startTime, 'HH:mm')}`}</Typography>
                </ModalSectionRow>
              </ModalSection>
            )}

            {plannedActivity.activity_RecurrenceId === RecurrenceEnum.OneTime && (
              <ModalSection
                title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_TITLE')}
                subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_SUBTITLE')}
                responsive>
                <ModalSectionRow responsiveRow>
                  <TemporaryActivity />
                  <Typography variant="body1">{f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_RECURRANCE_ONETIME')}</Typography>
                </ModalSectionRow>
              </ModalSection>
            )}

            {hasDelegationsNotFulfilled && delegationsFulfillment && ((delegationsFulfillment || []).length > 0) && (
              <DelegationsFulfillment delegationsFulfillment={delegationsFulfillment} />
            )}

            {!hideCompleteSection && (
              <ModalSection
                title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_TITLE')}
                subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_SUBTITLE')}
                responsive
              >
                {plannedActivity.organization_AllowCommentOnSigning ? (
                  <ModalSectionRow sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1 }} responsive noSeparator>
                    {(signingButtonRules.completeGroup.visibility && !signingButtonRules.completeGroup.disable) && (
                      <ContainedMenuButton
                        color="success"
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.completeGroup.signingStatusId)}
                        items={[
                          <MenuItem onClick={handleSigning(signingButtonRules.completeGroup.signingStatusId, true)} key={0}>
                            {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.completeGroup.signingStatusLable })}
                          </MenuItem>,
                        ]}>
                        {signingButtonRules.completeGroup.signingStatusLable}
                      </ContainedMenuButton>
                    )}
                    {(signingButtonRules.selfCompleteGroup.visibility && !signingButtonRules.selfCompleteGroup.disable) && (
                      <ContainedMenuButton
                        color={getStatusColor(signingButtonRules.selfCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.selfCompleteGroup.signingStatusId)}
                        items={[
                          <MenuItem onClick={handleSigning(signingButtonRules.selfCompleteGroup.signingStatusId, true)} key={0}>
                            {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.selfCompleteGroup.signingStatusLable })}
                          </MenuItem>,
                        ]}>
                        {signingButtonRules.selfCompleteGroup.signingStatusLable}
                      </ContainedMenuButton>
                    )}
                    {(signingButtonRules.relativeCompleteGroup.visibility && !signingButtonRules.relativeCompleteGroup.disable) && (
                      <ContainedMenuButton
                        color={getStatusColor(signingButtonRules.relativeCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.relativeCompleteGroup.signingStatusId)}
                        items={[
                          <MenuItem onClick={handleSigning(signingButtonRules.relativeCompleteGroup.signingStatusId, true)} key={0}>
                            {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.relativeCompleteGroup.signingStatusLable })}
                          </MenuItem>,
                        ]}>
                        {signingButtonRules.relativeCompleteGroup.signingStatusLable}
                      </ContainedMenuButton>
                    )}
                    {(signingButtonRules.sentAlongCompleteGroup.visibility && !signingButtonRules.sentAlongCompleteGroup.disable) && (
                      <ContainedMenuButton
                        color={getStatusColor(signingButtonRules.sentAlongCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.sentAlongCompleteGroup.signingStatusId)}
                        items={[
                          <MenuItem onClick={handleSigning(signingButtonRules.sentAlongCompleteGroup.signingStatusId, true)} key={0}>
                            {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.sentAlongCompleteGroup.signingStatusLable })}
                          </MenuItem>,
                        ]}>
                        {signingButtonRules.sentAlongCompleteGroup.signingStatusLable}
                      </ContainedMenuButton>
                    )}
                    {(signingButtonRules.partiallyCompleteGroup.visibility && !signingButtonRules.partiallyCompleteGroup.disable) && (
                      <ContainedMenuButton
                        color={getStatusColor(signingButtonRules.partiallyCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handlePartiallyCompletedClicked()}
                        items={[
                          <MenuItem onClick={handlePartiallyCompletedClicked(true)} key={0}>
                            {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.partiallyCompleteGroup.signingStatusLable })}
                          </MenuItem>,
                        ]}>
                        {signingButtonRules.partiallyCompleteGroup.signingStatusLable}
                      </ContainedMenuButton>
                    )}
                    {(signingButtonRules.notCompleteGroup.visibility && !signingButtonRules.notCompleteGroup.disable) && (
                      <ContainedMenuButton
                        color="error"
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.notCompleteGroup.signingStatusId)}
                        items={[
                          <MenuItem onClick={handleSigning(signingButtonRules.notCompleteGroup.signingStatusId, true)} key={0}>
                            {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.notCompleteGroup.signingStatusLable })}
                          </MenuItem>,
                        ]}>
                        {signingButtonRules.notCompleteGroup.signingStatusLable}
                      </ContainedMenuButton>
                    )}
                    {(signingButtonRules.cannotTakeCompleteGroup.visibility && !signingButtonRules.cannotTakeCompleteGroup.disable) && (
                      <ContainedMenuButton
                        color={getStatusColor(signingButtonRules.cannotTakeCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.cannotTakeCompleteGroup.signingStatusId)}
                        items={[
                          <MenuItem onClick={handleSigning(signingButtonRules.cannotTakeCompleteGroup.signingStatusId, true)} key={0}>
                            {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.cannotTakeCompleteGroup.signingStatusLable })}
                          </MenuItem>,
                        ]}>
                        {signingButtonRules.cannotTakeCompleteGroup.signingStatusLable}
                      </ContainedMenuButton>
                    )}
                  </ModalSectionRow>
                ) : (
                  <ModalSectionRow sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1 }} responsive noSeparator>
                    {(signingButtonRules.completeGroup.visibility && !signingButtonRules.completeGroup.disable) && (
                      <ContainedButton
                        containerStyle={{ marginLeft: 0 }}
                        sx={containedButtonResponsiveStyle}
                        color="success"
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.completeGroup.signingStatusId)}
                      >
                        {signingButtonRules.completeGroup.signingStatusLable}
                      </ContainedButton>
                    )}
                    {(signingButtonRules.selfCompleteGroup.visibility && !signingButtonRules.selfCompleteGroup.disable) && (
                      <ContainedButton
                        containerStyle={{ marginLeft: 0 }}
                        color={getStatusColor(signingButtonRules.selfCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.cannotTakeCompleteGroup.signingStatusId)}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.selfCompleteGroup.signingStatusLable}
                      </ContainedButton>
                    )}
                    {(signingButtonRules.relativeCompleteGroup.visibility && !signingButtonRules.relativeCompleteGroup.disable) && (
                      <ContainedButton
                        containerStyle={{ marginLeft: 0 }}
                        color={getStatusColor(signingButtonRules.relativeCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.relativeCompleteGroup.signingStatusId)}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.relativeCompleteGroup.signingStatusLable}
                      </ContainedButton>
                    )}
                    {(signingButtonRules.sentAlongCompleteGroup.visibility && !signingButtonRules.sentAlongCompleteGroup.disable) && (
                      <ContainedButton
                        containerStyle={{ marginLeft: 0 }}
                        color={getStatusColor(signingButtonRules.sentAlongCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.sentAlongCompleteGroup.signingStatusId)}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.sentAlongCompleteGroup.signingStatusLable}
                      </ContainedButton>
                    )}
                    {(signingButtonRules.partiallyCompleteGroup.visibility && !signingButtonRules.partiallyCompleteGroup.disable) && (
                      <ContainedButton
                        color={getStatusColor(signingButtonRules.partiallyCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handlePartiallyCompletedClicked()}
                        containerStyle={{ marginLeft: 0 }}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.partiallyCompleteGroup.signingStatusLable}
                      </ContainedButton>
                    )}
                    {(signingButtonRules.notCompleteGroup.visibility && !signingButtonRules.notCompleteGroup.disable) && (
                      <ContainedButton
                        containerStyle={{ marginLeft: 0 }}
                        color="error"
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.notCompleteGroup.signingStatusId)}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.notCompleteGroup.signingStatusLable}
                      </ContainedButton>
                    )}
                    {(signingButtonRules.cannotTakeCompleteGroup.visibility && !signingButtonRules.cannotTakeCompleteGroup.disable) && (
                      <ContainedButton
                        containerStyle={{ marginLeft: 0 }}
                        color={getStatusColor(signingButtonRules.cannotTakeCompleteGroup.signingStatusId).code}
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.cannotTakeCompleteGroup.signingStatusId)}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.cannotTakeCompleteGroup.signingStatusLable}
                      </ContainedButton>
                    )}
                  </ModalSectionRow>
                )}
              </ModalSection>
            )}

            {
              !hideCompleteSection && allowSigning && currentUser?.allowStartedActivityStatus && !(plannedActivity.plannedActivityStatusId === PlannedActivityStatusEnum.Started) && (
                <ModalSection
                  title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STARTED_TITLE')}
                  subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STARTED_SUBTITLE')}
                  responsive
                >
                  <ModalSectionRow sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1 }} responsive noSeparator>
                    <ContainedMenuButton
                      color={getStatusColor(signingButtonRules.startedGroup.signingStatusId).code}
                      disabled={disableButtons || isStarted}
                      onClick={handleSigning(signingButtonRules.startedGroup.signingStatusId)}
                      items={[
                        <MenuItem
                          onClick={handleSigning(signingButtonRules.startedGroup.signingStatusId, true)}
                          key={0}>
                          {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.startedGroup.signingStatusLable })}
                        </MenuItem>,
                      ]}>
                      {signingButtonRules.startedGroup.signingStatusLable}
                    </ContainedMenuButton>
                  </ModalSectionRow>
                </ModalSection>
              )
            }

            {hideCompleteSection && allowSigning && (
              <ModalSection
                title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_TITLE')}
                subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_SUBTITLE')}
                responsive
              >
                <ModalSectionRow responsive>
                  <ModalSectionIconContainer>
                    <Information />
                  </ModalSectionIconContainer>
                  <Typography>
                    {f(
                      isDelayed
                        ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_DELAYED_INFO'
                        : 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DOUBLESECTION_INFO'
                    )}
                  </Typography>
                </ModalSectionRow>
              </ModalSection>
            )}
            {allowSigning && (
              <ModalSection
                title={f(
                  hideCompleteSection
                    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYEDSECTION_TITLE'
                    : 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYSECTION_TITLE'
                )}
                subtitle={f(
                  hideCompleteSection
                    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYEDSECTION_SUBTITLE'
                    : 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_DELAYSECTION_SUBTITLE'
                )}
                responsive
              >
                <>
                  {!hideCompleteSection && (
                    <ModalSectionRow sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1 }} responsive noSeparator>
                      {(!signingButtonRules.delayedGroup.disable) && (
                        <ContainedMenuButton
                          color="secondary"
                          disabled={disableButtons}
                          onClick={() => handleDelayClicked()}
                          items={[
                            <MenuItem onClick={handleDelayWithCommentClick} key={0}>
                              {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_WITH_COMMENT_TEXT', { status: signingButtonRules.delayedGroup.signingStatusLable })}
                            </MenuItem>,
                          ]}>
                          {signingButtonRules.delayedGroup.signingStatusLable}
                        </ContainedMenuButton>
                      )}
                    </ModalSectionRow>
                  )}

                  {/* it should only be possible to complete without action the current activity */}
                  {hideCompleteSection && isDelayed && (
                    (!signingButtonRules.completeDelayedWithoutActionGroup.disable) && (
                      <ContainedButton
                        color="error"
                        disabled={disableButtons}
                        onClick={handleSigning(signingButtonRules.completeDelayedWithoutActionGroup.signingStatusId)}
                        containerStyle={{ marginLeft: 0 }}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.completeDelayedWithoutActionGroup.signingStatusLable}
                      </ContainedButton>
                    )
                  )}

                  {/* it should only be possible to complete without action the delayed activity */}
                  {hideCompleteSection && allowSigning && !isDelayed && (
                    (!signingButtonRules.completeDelayedWithoutActionGroup.disable) && (
                      <ContainedButton
                        color="error"
                        disabled={disableButtons}
                        onClick={() => handleCompleteDelayedWithoutActionClicked(signingButtonRules.completeDelayedWithoutActionGroup.signingStatusId)}
                        containerStyle={{ marginLeft: 0 }}
                        sx={containedButtonResponsiveStyle}
                      >
                        {signingButtonRules.completeDelayedWithoutActionGroup.signingStatusLable}
                      </ContainedButton>
                    )
                  )}

                  {/* FIX! hideCompleteSection && !isDelayed then we should show delayed for the delayed activity! */}
                  {delaySignings && (delaySignings || []).length > 1 && (
                    <DelaySignings delaySignings={delaySignings} />
                  )}
                </>
              </ModalSection>
            )}
            {shouldShowStatusSection && (
              <ModalSection
                title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STATUSSECTION_TITLE')}
                subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_STATUSSECTION_SUBTITLE')}
                responsive
              >
                <ModalSectionRow responsive>
                  <InfoSignedContainer
                    signedComment={plannedActivity.signing_LatestSigningComment && plannedActivity.comment ? plannedActivity.signing_LatestSigningComment + slash + plannedActivity.comment :
                      ((plannedActivity.signing_LatestSigningComment || '') + (plannedActivity.comment || ''))}
                    signedDate={plannedActivity.signing_LatestSigningDate}
                    signedUser={plannedActivity.signing_LatestSigningUser}
                    status={plannedActivity.plannedActivityStatusId}
                    onUndoClick={handleUndoClick(plannedActivity)}
                  />
                </ModalSectionRow>
              </ModalSection>
            )}

            {recentlyPerformedSignings && (recentlyPerformedSignings || []).length > 0 && (
              <RecentlyPerfomedSignings recentlyPerformedSignings={recentlyPerformedSignings} signingStatuses={signingStatuses} />
            )}
            {allowRemove && (
              <ModalSection
                title={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_REMOVESECTION_TITLE')}
                subtitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_REMOVESECTION_SUBTITLE')} responsive>
                <ModalSectionRow responsive>
                  <ContainedButton
                    color="error"
                    disabled={disableButtons}
                    onClick={openRemoveActivityDialog}
                    containerStyle={{ marginLeft: 0 }}
                    sx={containedButtonResponsiveStyle}
                  >
                    {f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CONTAINER_BUTTON_REMOVEACTIVITY_TEXT')}
                  </ContainedButton>
                </ModalSectionRow>
              </ModalSection>
            )}
          </SingleActivityModalMainBody>
          <ModalFooter>
            <TextButton type="button" onClick={close}>
              {f('BASIC_BUTTON_CLOSE_TEXT')}
            </TextButton>
            <Box pl={1} />
          </ModalFooter>
        </>
      )}
      <Portal>
        <ListingModal updateDataOnStatusChanged={updateDataOnStatusChanged} />
        {commentModalProps && <SingleActivityCommentModal {...commentModalProps} onClose={handleClose} open={isCommentModalOpen} />}
        {givenAmountModalProps && <SingleActivityPartialModal {...givenAmountModalProps} onClose={handleClose} open={isPartiallyCompletedModalOpen} />}
        {participantModalProps && <SingleActivityParticipantsModal {...participantModalProps} onCloseClick={handleClose} open={isParticipantModalOpen} />}
      </Portal>
      {removeActivityDialogOpen && plannedActivity && (
        <DeleteDialog
          dialogTitle={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_TITLE')}
          dialogContentText={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_MESSAGE')}
          buttonSubmitText={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_ACTIONS_REMOVE')}
          buttonCancelText={f('DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DELETE_DIALOG_ACTIONS_CANCEL')}
          deleteDialogOpen
          onClose={closeRemoveActivityDialog}
          onSubmit={handleConfirmRemoveActivityClick}
        />
      )}
    </CenterModal>
  )
}

export default SingleActivityModalContainer
