export class HangfireClient {
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined
  private http: {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response>
  }
  private baseUrl: string

  constructor(
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    this.http = http ? http : (window as any)
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : ''
  }

  public get(): Promise<string> {
    console.log(this.baseUrl)
    const url_ = this.baseUrl // + '/api/me'
    // url_ = url_.replace(/[?&]$/, '')

    // url_ = "https://localhost:44401/hangfire"
    const options_ = {
      method: 'GET',
      headers: {
        Accept: 'text/html',
      },
    } as RequestInit

    return this.http
      .fetch(url_, options_)
      .then((_response: Response) => this.processGet(_response))
  }

  protected processGet(response: Response): Promise<string> {
    const status = response.status
    const _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then(_responseText => {
        // replace /hangfire with https://localhost:44401/hangfire
        const replacedLinks = _responseText
          .split('/hangfire')
          .join(this.baseUrl) // .join("https://localhost:44401/hangfire")
        return replacedLinks
      })
    } else if (status !== 200 && status !== 204) {
      return response
        .text()
        .then(_responseText =>
          throwException(
            'An unexpected server error occurred.',
            status,
            _responseText,
            _headers
          )
        )
    }
    return Promise.resolve<string>(null as any)
  }
}

export class SwaggerException extends Error {
  public static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true
  }
  public message: string
  public status: number
  public response: string
  public headers: { [key: string]: any }
  public result: any

  protected isSwaggerException = true

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super()

    this.message = message
    this.status = status
    this.response = response
    this.headers = headers
    this.result = result
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) {
    throw result
  } else {
    throw new SwaggerException(message, status, response, headers, null)
  }
}
