import { useActivityApi, useAreaApi } from 'api'
import {
  ActivityListItem,
  AreaItem,
  RecurrenceEnum,
} from 'api/tillit.api-client'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useAreaModalContext = () => {
  const { get: getAreaById, updateArea } = useAreaApi()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const [ areaId, setAreaId ] = React.useState<number|null>(null)
  const [areaInfo, setAreaInfo] = React.useState<AreaItem | null>(null)
  const [commonActivities, setCommonActivities] = React.useState<
    ActivityListItem[] | null
  >(null)
  const [
    temporaryCommonActivities,
    setCommonTemporaryActivities,
  ] = React.useState<ActivityListItem[] | null>(null)

  const { listAreaActivities: listAreaActivitiesPromise } = useActivityApi()

  const fetchAreaInfoAsync = React.useCallback(
    async (areaId: number) => {
      await fetchAsync(getAreaById(areaId)).then(setAreaInfo)
    },
    [fetchAsync, getAreaById]
  )

  const fetchAreaCommonActivitiesAsync = React.useCallback(
    async (areaId: number) => {
      await fetchAsync(listAreaActivitiesPromise(areaId)).then(activityList =>
        setCommonActivities(
          activityList.filter(a => a.recurrenceId !== RecurrenceEnum.OneTime)
        )
      )
    },
    [fetchAsync, listAreaActivitiesPromise]
  )

  const fetchAreaTemporaryCommonActivitiesAsync = React.useCallback(
    async (areaId: number) => {
      await fetchAsync(listAreaActivitiesPromise(areaId)).then(activityList =>
        setCommonTemporaryActivities(
          activityList.filter(a => a.recurrenceId === RecurrenceEnum.OneTime)
        )
      )
    },
    [fetchAsync, listAreaActivitiesPromise]
  )

  const updateAreaAsync = React.useCallback(
    (area: AreaItem) => {
      return fetchAsync(updateArea(area).then(areaItem => !!areaItem))
    },
    [fetchAsync, updateArea]
  )

  const resetState = React.useCallback(() => {
    setAreaInfo(null)
    setAreaId(null)
  }, [])

  return {
    state: {
      ...stateOfFetch,
      commonActivities,
      temporaryCommonActivities,
      areaInfo,
      areaId
    },
    actions: {
      fetchAreaInfoAsync,
      fetchAreaCommonActivitiesAsync,
      fetchAreaTemporaryCommonActivitiesAsync,
      updateAreaAsync,
      clearArea: resetState,
      setAreaId
    },
  }
}

export const AreaModalContext = createContext(useAreaModalContext)
