import { PlanMetaData } from 'pages/planning/components/schedule/schedule-view'
import React from 'react'
import styled from 'styled-components'
import { InnerContext } from '../../contexts/inner-context'
import { ItemTypes, SessionProps } from '../../planning-grid-types'
import { useDrop, UseDropProps } from '../../utilities/drag-and-drop/drag-and-drop'

export const useDropSessionCell = ({ trackId, accept }: { trackId: number; accept: Array<keyof typeof ItemTypes> }) => {
  const { containerRef, calendarStartDate } = InnerContext.useState()
  const { canSessionDrop, mapPixelsToMinutes, snapToPixels, callbacks } = InnerContext.useActions()

  const handleCanDrop: UseDropProps['canDrop'] = React.useCallback(
    ({ type, item, collection }) => {
      if (containerRef.current === null) {
        return false
      }

      const { lengthInMinutes, meta } = item as SessionProps<PlanMetaData>
      if (meta.metaType === "UserWorkScheduleMetaDataType") {
        return true
        //TODO: yeah?
      }

      const clientOffset = collection.currentOffset
      const yCoordOffset = clientOffset.top
      const { scrollTop } = containerRef.current
      const containerBoundingClientRect = containerRef.current.getBoundingClientRect()
      const pixelsFromTop = yCoordOffset + scrollTop - containerBoundingClientRect.top
      return canSessionDrop(pixelsFromTop, lengthInMinutes)
    },
    [canSessionDrop, containerRef]
  )

  const handleDrop: UseDropProps['onDrop'] = React.useCallback(
    ({ type, item, collection }) => {
      if (containerRef.current === null) {
        return
      }

      const clientOffset = collection.currentOffset
      const yCoordOffset = clientOffset.top
      const { scrollTop } = containerRef.current
      const containerBoundingClientRect = containerRef.current.getBoundingClientRect()
      const pixelsFromTop = yCoordOffset + scrollTop - containerBoundingClientRect.top

      const startTimeMinutes = mapPixelsToMinutes(snapToPixels(pixelsFromTop))

      const updStartDate = new Date(+calendarStartDate + startTimeMinutes * 60000)

      const updatedSession = {
        ...item,
        trackId,
        startDate: updStartDate,
      } as SessionProps<PlanMetaData>

      const { meta, ...internal } = updatedSession

      // Update existing session
      if (type === ItemTypes.SESSION_ADD && callbacks.onUpdate) {
        callbacks.onUpdate({ internal, meta, action: 'add' })
      } else if (type === ItemTypes.SESSION_UPDATE && callbacks.onUpdate) {
        callbacks.onUpdate({ internal, meta, action: 'update' })
      }
    },
    [calendarStartDate, callbacks, containerRef, mapPixelsToMinutes, snapToPixels, trackId]
  )

  return useDrop(accept, {
    canDrop: handleCanDrop,
    onDrop: handleDrop,
  })
}

type TimeSubCellProps = Readonly<{ trackId: number; className?: string }>

export const TimeSubCell: React.SFC<TimeSubCellProps> = React.memo(({ trackId, className }) => {
  const { calendarStartDate, containerRef } = InnerContext.useState()
  const { mapPixelsToMinutes, onTrackDoubleClick } = InnerContext.useActions()

  const handleDoubleClick = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (containerRef.current === null) {
      return
    }
    const trackBoundingClient = event.currentTarget.getBoundingClientRect()
    const { scrollTop } = containerRef.current
    const containerBoundingClientRect = containerRef.current.getBoundingClientRect()
    const pixelsFromTop = scrollTop + trackBoundingClient.top - containerBoundingClientRect.top
    const minutes = mapPixelsToMinutes(pixelsFromTop)
    const startDate = new Date(+calendarStartDate + minutes * 60000)
    onTrackDoubleClick(trackId, startDate)
  },[containerRef,calendarStartDate,onTrackDoubleClick,mapPixelsToMinutes])

  return <TimeSubCellStyle className={className} onDoubleClick={handleDoubleClick} />
})
TimeSubCell.displayName = 'TimeSubCell'

const TimeSubCellStyle = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    flex: 1;
    &:hover {
      background-color: #f5f5f5;
    }

    &:last-child {
      border-bottom-width: 0;
    }
`
