// tslint:disable: ordered-imports
import React from 'react'
import App from './components/app'
import * as serviceWorker from './serviceWorkerRegistration'

import { createRoot } from 'react-dom/client'
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

//ReactDOM.render(<App />, document.getElementById('app'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
