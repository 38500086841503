import * as React from 'react'
import { useFormatMessage } from 'localization'
import { TextField, Autocomplete } from "@mui/material"
import { OrganizationListItem } from 'api/tenilo.support.api-client'

type OrganizationSelectListProps = {
  multiple?: boolean,
  options: OrganizationListItem[],
  value?: OrganizationListItem,
  onChange?: (value: any) => void,
  textFieldLable?: string,
  filterOptions?: <T>(options: T[], state: Record<string, any>) => T[]
}

const OrganizationSelectList: React.FC<OrganizationSelectListProps> = ({
  multiple,
  textFieldLable,
  options,
  filterOptions,
  onChange,
  value,
  ...props
}) => {
  const f = useFormatMessage()
  const getOptionLabel = React.useCallback((option: any) => {
    return option["name"] || ""
  }, [])

  const handleChange = React.useCallback((e, newValue: any) => {
    if (onChange) { onChange(newValue) }
  }, [])

  return React.useMemo(() => {
    return (
      <Autocomplete
        data-matomo-mask
        value={value}
        onChange={handleChange}
        multiple={multiple}
        options={options}
        limitTags={10}
        disableCloseOnSelect={multiple}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option: any) => {
          return (
            <li data-matomo-mask {...props} key={`organization-select-${props.id}`}>
              {getOptionLabel(option)}
            </li>
          )
        }}
        renderInput={(params) => <TextField data-matomo-mask {...params} label={textFieldLable} />}
      />
    )

  }, [options])
}

export default React.memo(OrganizationSelectList)
