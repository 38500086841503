import { Box, Typography } from '@mui/material'
import { UserExpiringDelegationItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import React from 'react'
import format from 'utilities/format'
import styled from 'styled-components'

export type InfoExpiringDelegationComponentProps = Readonly<{
  expiringDelegation: UserExpiringDelegationItem
  className?: string
}>

export const InfoExpiringDelegationView: React.FC<InfoExpiringDelegationComponentProps> = ({ expiringDelegation, className }) => {
  const f = useFormatMessage()
  return (
    <Box className={className} py={1} px={1}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row">
          <Typography variant="subtitle1">
            {expiringDelegation.delegationTitle}<br />
            <OpacBox>
              {f('BASIC_DELEGATED_BY')}
            </OpacBox>
            <span data-matomo-mask>{expiringDelegation.delegatedByUserFullName}</span>

          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>
            <OpacBox>
              {f('BASIC_VALID')}
            </OpacBox>
            {`${format(expiringDelegation.delegationStartTime, 'PPP')} - ${format(expiringDelegation.delegationEndTime, 'PPP')}`}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="right">
          {expiringDelegation.delegatedForCustomerFullName && (
            <Typography variant="subtitle1">
              <OpacBox>
                {f('BASIC_CUSTOMER')}
              </OpacBox>
              {expiringDelegation.delegatedForCustomerFullName}
            </Typography>

          )}
          {expiringDelegation.delegatedForAreaName && (
            <Typography variant="subtitle1">
              <OpacBox>
                {f('BASIC_AREA')}
              </OpacBox>
              {expiringDelegation.delegatedForAreaName}
            </Typography>
          )}
        </Box>
      </Box>

    </Box>
  )

}

const OpacBox = styled(Box)`
  opacity: 0.6
`

