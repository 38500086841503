import PageRoute from 'config/page-routes'
import {
  addDays,
  format,
  getDate,
  getDay,
  getMonth,
  isToday,
  subDays,
} from 'date-fns'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { useNavigate, useParams } from "react-router-dom"
import useDebounce from 'utilities/use-debounce'
import DayPickerView from './day-picker-view'

type ParsableDate = string | number | object | Date | null | undefined
export interface IFormattedDate {
  month: string
  date: number
  dayName: string
  today: boolean
}

export type INavigateDate = 'prev' | 'next' | 'today'

interface IDayPickerContainer {
  maxDate?: Date
}

const DayPickerContainer: React.FC<IDayPickerContainer> = ({ maxDate }) => {
  const f = useFormatMessage()
  const { dayPickerOpen, calendarStartDate } = PlanningPageContext.useState()
  const { date: urlDate, areaId: urlAreaId, type, usingCalendarFromDevice } = useParams() as { date: string, areaId: string, type: string, usingCalendarFromDevice: string }
  const { closeDayPicker, openDayPicker } = PlanningPageContext.useActions()
  const navigate = useNavigate()

  const [localDate, setLocalDate] = React.useState(calendarStartDate)
  const day = useDebounce(localDate, 600)

  React.useEffect(() => {
    setLocalDate(calendarStartDate)
  }, [calendarStartDate])

  React.useEffect(() => {
    const newDate = format(day, 'yyyy-MM-dd')
    if (urlDate !== newDate) {
      navigate(
        PageRoute.Planning.path
          .replace(':areaId', urlAreaId)
          .replace(':date', newDate)
          .replace(':type', type)
          .replace(':usingCalendarFromDevice', usingCalendarFromDevice)
      )
    }
  }, [urlAreaId, urlDate, day, type])

  const months = [
    f('BASIC_DATE_MONTH_JANUARY_TEXT'),
    f('BASIC_DATE_MONTH_FEBRUARY_TEXT'),
    f('BASIC_DATE_MONTH_MARCH_TEXT'),
    f('BASIC_DATE_MONTH_APRIL_TEXT'),
    f('BASIC_DATE_MONTH_MAY_TEXT'),
    f('BASIC_DATE_MONTH_JUNE_TEXT'),
    f('BASIC_DATE_MONTH_JULY_TEXT'),
    f('BASIC_DATE_MONTH_AUGUST_TEXT'),
    f('BASIC_DATE_MONTH_SEPTEMBER_TEXT'),
    f('BASIC_DATE_MONTH_OCTOBER_TEXT'),
    f('BASIC_DATE_MONTH_NOVEMBER_TEXT'),
    f('BASIC_DATE_MONTH_DECEMBER_TEXT'),
  ]

  const days = [
    f('BASIC_DATE_DAY_SUNDAY_TEXT'),
    f('BASIC_DATE_DAY_MONDAY_TEXT'),
    f('BASIC_DATE_DAY_TUESDAY_TEXT'),
    f('BASIC_DATE_DAY_WEDNESDAY_TEXT'),
    f('BASIC_DATE_DAY_THURSDAY_TEXT'),
    f('BASIC_DATE_DAY_FRIDAY_TEXT'),
    f('BASIC_DATE_DAY_SATURDAY_TEXT'),
  ]

  const formattedDate = React.useMemo(() => {
    const month = months[getMonth(localDate)]
    const date = getDate(localDate as Date)
    const dayName = days[getDay(localDate)]
    const today = isToday(localDate)

    return { month, date, dayName, today }
  }, [localDate, days, months])

  const navigateDate = React.useCallback(
    (t: INavigateDate) => () => {
      switch (t) {
        case 'next':
          setLocalDate(addDays(localDate as Date, 1))
          break
        case 'prev':
          setLocalDate(subDays(localDate as Date, 1))
          break
        case 'today':
          setLocalDate(new Date())
          break
        default:
          break
      }
    },
    [localDate]
  )

  const handleChangeDay = React.useCallback((d: ParsableDate) => {
    setLocalDate(d as Date)
    closeDayPicker()
    // TODO: Skip bouncer when navigating using date picker
  }, [])

  return (
    <DayPickerView
      open={dayPickerOpen}
      onClose={closeDayPicker}
      onOpen={openDayPicker}
      day={localDate}
      onChangeDay={handleChangeDay}
      formattedDate={formattedDate}
      navigateDate={navigateDate}
      maxDate={maxDate}
    />
  )
}

export default DayPickerContainer
