import { OrganizationSigningStatusesItem, PlannedActivityStatusEnum, UnitTypeEnum, IPlannedActivityRowItem } from 'api/tillit.api-client'
import Colors from 'styles/colors'
import { isEmpty, last, has } from 'lodash'

export const getSigningStatusLabel = (
  f: (id: string, values?: Record<string, any> | undefined) => string,
  id: string | undefined
  ) : string => {
  switch (id) {
    case PlannedActivityStatusEnum.NotCompleted:
      return f('SIGNING_STATUS_NOT_COMPLETED') // Only action to reach this status is when cancelling a made signing --> Cancelled/Ångrad
    case PlannedActivityStatusEnum.Completed:
      return f('SIGNING_STATUS_COMPLETED')
    case PlannedActivityStatusEnum.CompletedWithoutAction:
      return f('SIGNING_STATUS_COMPLETED_WITHOUT_ACTION')
    case PlannedActivityStatusEnum.Delayed:
      return f('SIGNING_STATUS_DELAYED')
    case PlannedActivityStatusEnum.Given:
      return f('SIGNING_STATUS_GIVEN')
    case PlannedActivityStatusEnum.PartiallyGiven:
      return f('SIGNING_STATUS_PARTIALLY_GIVEN')
    case PlannedActivityStatusEnum.NotGiven:
      return f('SIGNING_STATUS_NOT_GIVEN')
    case PlannedActivityStatusEnum.CannotTake:
      return f('SIGNING_STATUS_CANNOT_TAKE')
    case PlannedActivityStatusEnum.SentAlong:
      return f('SIGNING_STATUS_SENT_ALONG')
    case PlannedActivityStatusEnum.SelfGiven:
      return f('SIGNING_STATUS_SELF_GIVEN')
    case PlannedActivityStatusEnum.RelativeGiven:
      return f('SIGNING_STATUS_RELATIVE_GIVEN')
    case PlannedActivityStatusEnum.PartiallyComplete:
      return f('SIGNING_STATUS_PARTIALLY_COMPLETE')
    case PlannedActivityStatusEnum.Started:
      return f('SIGNING_STATUS_STARTED')
    default:
      return ''
  }
}

type getStatusColorReturnType = Readonly<{
  color: string
  code: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "partial" | undefined
}>

export const getStatusColor = (id: string | undefined) : getStatusColorReturnType  => {
  switch (id) {
    case PlannedActivityStatusEnum.Completed:
      return { color: Colors.JungleGreen , code:'success' }
    case PlannedActivityStatusEnum.CompletedWithoutAction:
      return { color: Colors.Punch , code:'error' }
    case PlannedActivityStatusEnum.NotCompleted:
      return { color: Colors.TeniloYellow , code:'error' } // Only action to reach this status is when cancelling a made signing --> Cancelled/Ångrad
    case PlannedActivityStatusEnum.Delayed:
      return { color: Colors.TeniloBrown , code:'secondary' }
    case PlannedActivityStatusEnum.CannotTake:
      return { color: Colors.Punch , code:'error' }
    case PlannedActivityStatusEnum.Given:
      return { color: Colors.JungleGreen , code:'success' }
    case PlannedActivityStatusEnum.RelativeGiven:
      return { color: Colors.JungleGreen , code:'success' }
    case PlannedActivityStatusEnum.SelfGiven:
      return { color: Colors.JungleGreen , code:'success' }
    case PlannedActivityStatusEnum.SentAlong:
      return { color: Colors.JungleGreen , code:'success' }
    case PlannedActivityStatusEnum.NotGiven:
      return { color: Colors.Punch , code:'error' }
    case PlannedActivityStatusEnum.PartiallyComplete:
      return { color: Colors.TeniloYellow , code:'partial' }
    case PlannedActivityStatusEnum.PartiallyGiven:
        return { color: Colors.TeniloYellow , code:'partial' }
    case PlannedActivityStatusEnum.Started:
        return { color: Colors.LightBlue , code:'info' }
    default:
      return { color: Colors.TeniloBrown , code:'secondary' }
  }
}

export const sortStatuses = ( statuses :  OrganizationSigningStatusesItem ) : OrganizationSigningStatusesItem  =>  {
  const statusesSortModel = {
    Completed:0,
    PartiallyComplete:1,
    CompletedWithoutAction:2,
    NotCompleted:3,
    Given:4,
    SelfGiven:5,
    RelativeGiven:6,
    SentAlong:7,
    PartiallyGiven:8,
    NotGiven:9,
    CannotTake:10,
    Delayed:11,
  }
  statuses.plannedActivityStatusItems?.sort((a,b)=>{
    if(!has(statusesSortModel,a.id) || !has(statusesSortModel,b.id)){
      return -1
    }
    return statusesSortModel[a.id] - statusesSortModel[b.id]
  })
  return statuses
}

type PickSigningStatusProps = Readonly<{
  f: (id: string, values?: Record<string, any> | undefined) => string,
  useDrugList: boolean
  hasUnit: boolean
  expectedAmount: IPlannedActivityRowItem['activity_ExpectedAmount']
  unitType?: UnitTypeEnum
  signingStatuses?:OrganizationSigningStatusesItem
}>
type PickSigningStatusCbProps = Readonly<{
  measurementStatus?: PlannedActivityStatusEnum
  timeStatus?: PlannedActivityStatusEnum
}>

export const signingStatusHandleDiff = (
  f: (id: string, values?: Record<string, any> | undefined) => string,
  signingStatuses: OrganizationSigningStatusesItem | undefined,
  statusId: PlannedActivityStatusEnum
  ) : {
  signingStatusId: PlannedActivityStatusEnum;
  disable: boolean;
  signingStatusLable: string;
} => {
  const orgStatusList = signingStatuses?.organizationPlannedActivityStatusItems?.filter( orgStatus => orgStatus.plannedActivityStatusId === statusId )
  const signingStatusId = statusId
  let disable = false
  let signingStatusLable = getSigningStatusLabel(f,statusId)
  if(!isEmpty(orgStatusList)){
    const orgStatusObj = last(orgStatusList)
    disable = !!orgStatusObj?.disable
    if(orgStatusObj?.organizationTitle){
      signingStatusLable = orgStatusObj.organizationTitle
    }
  }
  return {
    signingStatusId,
    disable,
    signingStatusLable
  }
}

export const pickSigningDetails = (props : PickSigningStatusProps) => ( cbProps : PickSigningStatusCbProps )  => {
  const { f, useDrugList, hasUnit, unitType, signingStatuses, expectedAmount } = props
  const { measurementStatus, timeStatus } = cbProps
  const disable = false
  const signingStatusLable = ''
  const visibility = false
  const signingStatusId: PlannedActivityStatusEnum = PlannedActivityStatusEnum.Completed
  let timeStatusObj: {
    signingStatusId: PlannedActivityStatusEnum;
    disable: boolean;
    signingStatusLable: string;
  }
  let measurementStatusObj: {
    signingStatusId: PlannedActivityStatusEnum;
    disable: boolean;
    signingStatusLable: string;
  }
  if(!timeStatus && measurementStatus){
    if(!useDrugList){
      return {
        signingStatusId: measurementStatus,
        disable,
        signingStatusLable,
        visibility
      }
    }
    measurementStatusObj = signingStatusHandleDiff(f,signingStatuses,measurementStatus)
    if(unitType === UnitTypeEnum.TimeUnit || !hasUnit){ //|| !expectedAmount){
      return {
        ...measurementStatusObj,
        visibility: false
      }
    }
    return {
      ...measurementStatusObj,
      visibility: true
    }
  }

  if(timeStatus && !measurementStatus){
    if(!useDrugList){
      return {
        signingStatusId: timeStatus,
        disable,
        signingStatusLable,
        visibility
      }
    }
    timeStatusObj = signingStatusHandleDiff(f,signingStatuses,timeStatus)
    if(unitType === UnitTypeEnum.MeasurementUnit || !hasUnit){ // || !expectedAmount){
      return {
        ...timeStatusObj,
        visibility: false
      }
    }
    return {
      ...timeStatusObj,
      visibility: true
    }
  }

  if(timeStatus && measurementStatus){
    measurementStatusObj = signingStatusHandleDiff(f,signingStatuses,measurementStatus)
    timeStatusObj = signingStatusHandleDiff(f,signingStatuses,timeStatus)
    if( measurementStatusObj.signingStatusId === PlannedActivityStatusEnum.PartiallyGiven ||
    measurementStatusObj.signingStatusId === PlannedActivityStatusEnum.PartiallyComplete) {
      if(!useDrugList || !hasUnit){ // || !expectedAmount){
        return {
          ...timeStatusObj,
          visibility: false
        }
      }
    }
    if(!hasUnit){ // || !expectedAmount){
      return {
        ...timeStatusObj,
        visibility: true
      }
    }
    if(unitType === UnitTypeEnum.MeasurementUnit){
      if(!useDrugList){
        return {
          ...timeStatusObj,
          visibility: true
        }
      }
      return {
        ...measurementStatusObj,
        visibility: true
      }
    }
    if(unitType === UnitTypeEnum.TimeUnit){
      return {
        ...timeStatusObj,
        visibility: true
      }
    }
  }

  return {
    signingStatusId,
    disable,
    visibility,
    signingStatusLable
  }
}
