import React from 'react'
import { useKioskApi } from 'api'
import { getDeviceGuid } from 'api/device-guid'
import { UserListItem } from 'api/tillit.api-client'
import usePromiseApi from 'utilities/use-promise'

export const useUserModal = () => {

  const { getKioskUsers, getRecentLoggedInUserIds } = useKioskApi()
  const { state: fetchState, fetchAsync } = usePromiseApi()
  const [open, setOpen] = React.useState(false)
  const [users, setUsers] = React.useState<UserListItem[] | null>(null)
  const [selectedUser, setSelectedUser] = React.useState<number | null>(null)
  const [recentLoggedInUsers, setRecentLoggedInUsers] = React.useState<UserListItem[] | null>(null)
  const [indexLetterOfUsersInList, setIndexLetterOfUsersInList] = React.useState<string[] | undefined>(undefined)

  const indexLetterOfUsers = React.useCallback(
    (userList: UserListItem[] | null) => {
      const charArray = userList?.map(user => {
        if (user.firstName) {
          return user.firstName[0].toUpperCase()
        }
        return ''
      })
      setIndexLetterOfUsersInList(charArray)
    },
    []
  )

  const recentlyLoggedInUsersList = React.useCallback(
    (userList: UserListItem[] | null, userIdsList: number[] | null) => {
      const filteredUsers = userList?.filter(user => {
        let exists = false
        userIdsList?.forEach(userId => {
          if (userId === user.id) {
            exists = true
            return
          }
        })
        return exists
      })
      if (filteredUsers) {
        setRecentLoggedInUsers(filteredUsers)
      } else {
        setRecentLoggedInUsers(null)
      }
    },
    []
  )

  const fetchUsers = React.useCallback(() => {
    const deviceGuid = getDeviceGuid()
    if (!deviceGuid) {
      return
    }
    void fetchAsync(getKioskUsers(deviceGuid)).then(userList => {
      setUsers(userList)
      indexLetterOfUsers(userList)
      void getRecentLoggedInUserIds(deviceGuid).then(userIds => {
        recentlyLoggedInUsersList(userList, userIds)
      })
    })
  }, [getKioskUsers, recentlyLoggedInUsersList])


  return React.useMemo(
    () => ({
      actions: { fetchUsers, setOpen, setSelectedUser, recentlyLoggedInUsersList },
      state: { fetchState, open, users, selectedUser, recentLoggedInUsers, indexLetterOfUsersInList },
    }),
    [fetchState, open, users, selectedUser, recentLoggedInUsers, indexLetterOfUsersInList]
  )
}
