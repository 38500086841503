import { Avatar, Box } from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { useDeviceApi } from 'api'
import { RoleEnum, UserTypeEnum } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import PageRoute from 'config/page-routes'
import usePromiseApi from 'utilities/use-promise'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useFormatMessage } from 'localization'
import { useParams } from 'react-router-dom'
import {
  Account,
  AccountMultiple,
  CalendarWeekBegin,
  ClipboardText,
  Cog,
  FaceWomanProfile,
  FileTree,
  Heart,
  Home,
  Power,
  Tablet as TabletIpadIcon,
  CheckboxBlankBadge,
  ViewDashboard,
  TableMultiple,
  FaceAgent as SupportIcon
} from 'mdi-material-ui'
import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Colors from 'styles/colors'
import format from 'utilities/format'
import { Authorize } from 'components/authorization'

const Menu = styled.div`
  transition: width 0.5s;
  transition-delay: 0.5s;
  z-index: 10000;
  width: 60px;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  background-color: ${props => props.theme.palette.primary.main};
  overflow: hidden;

  & > *:first-child {
    background-color: ${props => props.theme.palette.primary.dark};
  }

  /* profile */
  & > *:first-child {
      & > *:first-child {
        padding-left:10px;
      }
  }

  /* profile */
  & > *:nth-child(2) {
    & > * {
      & > *:first-child {
        padding-left:16px;
      }
    }
  }

  /* log out */
  & > *:nth-child(3) {
    & > *:first-child {
      padding-left:16px;
    }
  }

  &:hover {
    width: 240px;
  }

`

const MiddleMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  ${({ theme }) => [theme.customBreakpoints.justMobileTablet]}{
    justify-content: flex-start;
  }
`

const MenuItemMarker = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  background-color: ${props => props.theme.palette.primary.contrastText};
  display: none;
`

const MenuButton = styled(ButtonBase)`
  position: relative;
  padding: 17px 0px;
  color: ${props => props.theme.palette.primary.contrastText};
  width: 100%;
  opacity: 0.7;
  border-radius: 10px;

  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.3);
    color: ${props => props.theme.palette.primary.contrastText};
    ${MenuItemMarker} {
      display: none;
    }
  }

`

const StyledNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(
  () => css`
    text-decoration: none;

    &.active > * {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.3);
    }
    &.active ${MenuItemMarker} {
      display: block;
    }
  `
)

const StyledAvatar = styled(Avatar)`
  background-color: ${Colors.White}B3;
  color: rgba(19, 20, 21, 0.7);
`

interface IIcon {
  [key: string]: React.ComponentType<SvgIconProps>
}

const Icon: IIcon = {
  home: Home,
  heart: Heart,
  fileTree: FileTree,
  settings: Cog,
  accountMultiple: AccountMultiple,
  faceWomanProfile: FaceWomanProfile,
  calendarWeekBegin: CalendarWeekBegin,
  clipboardText: ClipboardText,
  administration: ViewDashboard,
  dashboard: CheckboxBlankBadge,
  logOut: Power,
  device: TabletIpadIcon,
  account: Account,
  reports: TableMultiple,
  support: SupportIcon
}

interface IMenuItemProps extends NavLinkProps {
  icon?: keyof IIcon
  children?: React.ReactNode
  buttonid?: string
}

const MenuItem: React.FC<IMenuItemProps> = ({ children, icon, buttonid, title, ...rest }) => {
  const MenuIcon = icon ? Icon[icon] : undefined
  return (
    <StyledNavLink {...rest} >
      <MenuButton id={buttonid} sx={{ justifyContent: 'left' }} >
        <MenuItemMarker />
        {MenuIcon && <MenuIcon />}
        {children}
        <Box fontSize={'0.9rem'} fontWeight={600} sx={{ display: 'block', paddingLeft: '20px', paddingRight: '10px' }}>
          {title}
        </Box>
      </MenuButton>
    </StyledNavLink>
  )
}

const LogoutMenuItem: React.FC<IMenuItemProps> = ({ children, icon, title, ...rest }) => {
  const MenuIcon = icon ? Icon[icon] : undefined
  const { logOut } = Authentication.useActions()
  const logOutCallback = React.useCallback(() => logOut(), [logOut])
  return (
    <StyledNavLink {...rest} >
      <MenuButton onClick={logOutCallback} id='main-menu-logout-menubutton' sx={{ justifyContent: 'left' }} >
        <MenuItemMarker />
        {MenuIcon && <MenuIcon />}
        <Box fontSize={'0.9rem'} fontWeight={600} sx={{ display: 'block', paddingLeft: '20px', paddingRight: '10px', whiteSpace: 'nowrap' }}>
          {title}
        </Box>
      </MenuButton>
    </StyledNavLink>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/display-name
export default () => {
  const params = useParams() as {
    areaId: string
    date: string
    type: 'areas' | 'devices'
  }

  const {
    user: { firstName = '_', lastName = '_' },
    planningDeviceId,
    planningAreaId,
  } = Authentication.useAuthenticatedState()

  const { setPlanningDeviceId } = Authentication.useActions()
  const { usingCalendarFromDevice } = Authentication.useAuthenticatedState()

  const { getDevices } = useDeviceApi()

  const { fetchAsync: combinedRoutesFetchAsync } = usePromiseApi()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.customBreakpoints.justMobileTablet)

  React.useEffect(() => {
    combinedRoutesFetchAsync(
      getDevices().then(ds => {
        // If we did not receive any time based devices, set planning device id to null
        const timedDevices = ds.filter(device => device.nightFromHour)
        if (timedDevices.length === 0) {
          setPlanningDeviceId(null)
        } else if (
          // Else if we have planning device id and it is not contained in received devices
          planningDeviceId === null ||
          !timedDevices.some(d => d.id === planningDeviceId)
        ) {
          setPlanningDeviceId(timedDevices[0].id)
        }
      })
    )
  }, [combinedRoutesFetchAsync, getDevices, planningDeviceId, setPlanningDeviceId])

  const initialName = React.useMemo(() => firstName.charAt(0) + lastName.charAt(0), [firstName, lastName])

  const f = useFormatMessage()

  const planningPathAreas =
    planningAreaId !== null
      ? PageRoute.Planning.path
        .replace(':areaId', params?.areaId ? params.areaId : planningAreaId.toString())
        .replace(':date', params?.date ? params.date : format(new Date(), 'yyyy-MM-dd'))
        .replace(':type', 'areas')
        .replace(':usingCalendarFromDevice', 'false')
      : undefined

  const planningPathDevices =
    planningDeviceId !== null
      ? PageRoute.Planning.path
        .replace(':areaId', planningDeviceId.toString())
        .replace(':date', format(new Date(), 'yyyy-MM-dd'))
        .replace(':type', 'devices')
        .replace(':usingCalendarFromDevice', 'false')
      : undefined

  return usingCalendarFromDevice ? (
    null
  ) : (
    <Menu>

      <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner, RoleEnum.Caretaker]}>
        <MenuItem to={PageRoute.Profile.path} title={f('MAIN_MENU_BUTTON_HOVER_PROFILE')}>
          <StyledAvatar>
            <Box fontWeight="fontWeightBold" fontSize="1rem">
              {initialName}
            </Box>
          </StyledAvatar>
        </MenuItem>
      </Authorize>

      <MiddleMenu>

        <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
          {!smallScreen && (
            <MenuItem to={PageRoute.Dashboard.path} icon={'dashboard'} title={f('MAIN_MENU_BUTTON_HOVER_DASHBOARD')} />
          )}
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
          {!smallScreen && (
            <MenuItem to={PageRoute.Reports.path} icon={'reports'} title={f('MAIN_MENU_BUTTON_HOVER_REPORTS')} />
          )}
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Admin]}>
          <MenuItem to={PageRoute.Administer.path} icon={'administration'} title={f('MAIN_MENU_BUTTON_HOVER_ADMINISTER_DEVICES')} />
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Caretaker]} disallowedRoles={[RoleEnum.Planner, RoleEnum.Admin]}>
          {!smallScreen && (
            <MenuItem to={PageRoute.Users.path} icon={'account'} title={'Personal'} />
          )}
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
          {!smallScreen && (
            <MenuItem to={PageRoute.Users.path} icon={'accountMultiple'} title={f('MAIN_MENU_BUTTON_HOVER_PERSONNEL')} />
          )}
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
          {!smallScreen && (
            <MenuItem to={PageRoute.Customers.path} icon={'faceWomanProfile'} title={f('MAIN_MENU_BUTTON_HOVER_CUSTOMERS')} buttonid='main-menu-customers-menubutton' />
          )}
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
          {!smallScreen && <MenuItem to={PageRoute.Areas.path} icon={'home'} title={f('MAIN_MENU_BUTTON_HOVER_AREAS')} />}
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
          {planningPathAreas && !smallScreen && (
            <MenuItem to={planningPathAreas} icon={'calendarWeekBegin'} title={f('MAIN_MENU_BUTTON_HOVER_UNIT_PLANNING')} />
          )}
        </Authorize>

        <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
          {planningPathDevices && !smallScreen && (
            <MenuItem to={planningPathDevices} icon={'device'} title={f('MAIN_MENU_BUTTON_HOVER_DEVICE_PLANNING')} />
          )}
        </Authorize>

        <Authorize mainRoles={[UserTypeEnum.OrganizationAdmin, UserTypeEnum.TeniloSupport]}>
          {!smallScreen && (
            <MenuItem to={PageRoute.Organization.path} icon={'fileTree'} title={f('MAIN_MENU_BUTTON_HOVER_ORG_STRUCTURE')} />
          )}
        </Authorize>

        <Authorize mainRoles={[UserTypeEnum.TeniloSupport]}>
          {!smallScreen && (
            <MenuItem to={PageRoute.Support.path} icon={'support'} title={f('MAIN_MENU_BUTTON_HOVER_SUPPORT')} />
          )}
        </Authorize>

      </MiddleMenu>
      <LogoutMenuItem icon={'logOut'} to="/" title={f('MAIN_MENU_BUTTON_HOVER_LOGOUT')} />
    </Menu>
  )
}
