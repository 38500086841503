import React, { FC } from 'react'
import Page, { PageHeader } from 'components/page'
import { useFormatMessage } from 'localization'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { NotificationContainer } from 'components/notification'

import { Tab, Tabs as MuiTabs } from '@mui/material'
import Typography from '@mui/material/Typography'

import { SupportUsersContext } from './components/support-users/support-users-context'
import SupportUsersView from './components/support-users/support-users-view'
import { SupportUserModalContext } from './components/support-users/components/support-user-modal/support-user-modal-context'
import SupportUserModal from './components/support-users/components/support-user-modal'

import { SupportUserImpersonationRequestsContext } from './components/support-userimpersonationrequests/support-userimpersonationrequests-context'
import SupportUserImpersonationRequestsView from './components/support-userimpersonationrequests/support-userimpersonationrequests-view'
import { SupportUserImpersonationRequestModalContext } from './components/support-userimpersonationrequests/components/support-userimpersonationrequest-modal/support-userimpersonationrequest-modal-context'
import SupportUserImpersonationRequestModal from './components/support-userimpersonationrequests/components/support-userimpersonationrequest-modal/support-userimpersonationrequest-modal-container'

import { SupportAreasContext } from './components/support-areas/support-areas-context'
import SupportAreasView from './components/support-areas/support-areas-view'
import { SupportAreaModalContext } from './components/support-areas/components/support-area-modal/support-area-modal-context'
import SupportAreaModal from './components/support-areas/components/support-area-modal'
import { SupportOrganizationModalContext } from './components/support-areas/components/support-organization-modal/support-organization-modal-context'
import SupportOrganizationModal from './components/support-areas/components/support-organization-modal'
import supportPageContext from './support-page-context'
import OrganizationSelectList from './components/filter/organization-select-list'
import AreaSelectList from './components/filter/area-select-list'
import { SupportSystemContext } from './components/support-system/support-system-context'
import SupportGrandIdModalContainer from './components/support-system/components/support-grandid-modal/support-grandid-modal-container'
import SupportSystemView from './components/support-system/support-system-view'
import { SupportGrandIdModalContext } from './components/support-system/components/support-grandid-modal/support-grandid-modal-context'
import { GrandIdSettingModalContext } from './components/support-areas/components/support-organization-modal/components/grandid-setting-modal/grandid-setting-modal-context'
import GrandIdSettingModal from './components/support-areas/components/support-organization-modal/components/grandid-setting-modal'

const SupportPageInnerWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

type ITabPanelProps = Readonly<{
  index: number
  value: number
}>

const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {children}
    </Typography>
  )
}

const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
  width: 100vw;
  ${({ theme }) => [theme.customBreakpoints.justMobileTablet]}{
    visibility: hidden;
  }
`

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const SupportPageView: FC = () => {
  const f = useFormatMessage()

  const { selectedArea, areas, selectedOrganization, organizations } = supportPageContext.useState()
  const { setSelectedArea, setSelectedOrganization } = supportPageContext.useActions()

  const [mainTabValue, setMainTabValue] = React.useState(0)

  const handleChangeOrganization = React.useCallback((value: any) => {
    setSelectedArea(undefined)
    setSelectedOrganization(value)
  }, [setSelectedArea, setSelectedOrganization])

  const handleChangeArea = React.useCallback((value: any) => {
    setSelectedArea(value)
  }, [setSelectedArea])

  const handleChange = (event, newValue: React.SetStateAction<number>) => {
    setMainTabValue(newValue)
  }

  return (
    <NotificationContainer>
      <Page>
        <PageHeader
          title={f('SUPPORT_PAGE_VIEW_HEAD_TITLE')}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ width: '30%', paddingRight: '1vw' }}>
            <OrganizationSelectList
              options={organizations as any}
              textFieldLable={f('SUPPORT_PAGE_VIEW_ORGANIZATION_LABEL')}
              onChange={handleChangeOrganization}
              value={selectedOrganization}
            />
          </Box>
          <Box sx={{ width: '40%' }}>
            <AreaSelectList
              options={areas as any}
              textFieldLable={f('SUPPORT_PAGE_VIEW_AREA_LABEL')}
              onChange={handleChangeArea}
              value={selectedArea}
            />
          </Box>
        </Box>

        <SupportPageInnerWrapper>
          <Box display="flex" mx={-5}>
            <>
              <Box overflow="hidden">
                <Tabs indicatorColor="primary" textColor="primary" value={mainTabValue} onChange={handleChange} centered={true}>
                  <Tab label={f('SUPPORT_PAGE_VIEW_TAB_0_TITLE')} {...a11yProps(0)} />
                  <Tab label={f('SUPPORT_PAGE_VIEW_TAB_1_TITLE')} {...a11yProps(1)} />
                  <Tab label={f('SUPPORT_PAGE_VIEW_TAB_2_TITLE')} {...a11yProps(2)} />
                  <Tab label={f('SUPPORT_PAGE_VIEW_TAB_3_TITLE')} {...a11yProps(3)} />
                </Tabs>

                <TabPanel value={mainTabValue} index={0}>
                  {mainTabValue === 0 && (
                    <SupportUsersContext.Provider>
                      <SupportUserModalContext.Provider>
                        <SupportUsersView />
                        <SupportUserModal />
                      </SupportUserModalContext.Provider>
                    </SupportUsersContext.Provider>
                  )}
                </TabPanel>

                <TabPanel value={mainTabValue} index={1}>
                  {mainTabValue === 1 && (
                    <SupportAreasContext.Provider>
                      <GrandIdSettingModalContext.Provider>
                        <SupportOrganizationModalContext.Provider>
                          <SupportAreaModalContext.Provider>
                            <SupportAreasView />
                            <SupportAreaModal />
                            <SupportOrganizationModal />
                            <GrandIdSettingModal />
                          </SupportAreaModalContext.Provider>
                        </SupportOrganizationModalContext.Provider>
                      </GrandIdSettingModalContext.Provider>
                    </SupportAreasContext.Provider>

                  )}
                </TabPanel>

                <TabPanel value={mainTabValue} index={2}>
                  {mainTabValue === 2 && (
                    <SupportUserImpersonationRequestsContext.Provider>
                      <SupportUserImpersonationRequestModalContext.Provider>
                        <SupportUserImpersonationRequestsView />
                        <SupportUserImpersonationRequestModal />
                      </SupportUserImpersonationRequestModalContext.Provider>
                    </SupportUserImpersonationRequestsContext.Provider>
                  )}
                </TabPanel>

                <TabPanel value={mainTabValue} index={3}>
                  {mainTabValue === 3 && (
                    <SupportSystemContext.Provider>
                      <SupportGrandIdModalContext.Provider>
                        <SupportSystemView />
                        <SupportGrandIdModalContainer />
                      </SupportGrandIdModalContext.Provider>
                    </SupportSystemContext.Provider>
                  )}
                </TabPanel>

              </Box>
            </>
          </Box>

        </SupportPageInnerWrapper>
      </Page>
    </NotificationContainer>
  )
}

export default SupportPageView
