import React, { useCallback, Fragment } from 'react';
import { DailyActivitiesContext, PlanedActivity} from 'pages/daily-activities/daily-activities-context'
import { CustomerListItem } from 'api/tillit.api-client'
import { isEmpty } from 'lodash'
import { useFormatMessage } from 'localization'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
import Colors from 'styles/colors'
import DailyActivitySubButton from '../daily-activity-sub-button'
import DailyActivityGroupItem from '../daily-activity-group'
import DailyActivitySubGroupItem from '../daily-activity-subgroup'
import { KioskTemporaryActivityCreateModalContext } from '../kiosk-temporary-activity-create-modal/kiosk-temporary-activity-create-modal-context'

export interface CustomerModeDataItemType {
  id: number;
  customer: CustomerListItem
  route: Array<{
    activities: PlanedActivity[]
    routeId: number
    name: string
    color?: string | undefined
  }>
}

interface Props {
  customerModeData: CustomerModeDataItemType[]
  selectedMenu: {
    selectedCustomer: number;
    selectedRoute?: number | undefined;
  } | undefined
  handleSelectMenu: (cId?: number | undefined, tId?: number | undefined) => () => void
  handleShowFutureClick: (plannedGroup: number) => () => void
  handleShowTFHistoryClick: (plannedGroup: number) => () => void
}


const DailyActivityMainMenuCustomerMode: React.FC<Props> = ({customerModeData, selectedMenu, handleSelectMenu, handleShowFutureClick, handleShowTFHistoryClick}) => {
  const f = useFormatMessage()
  const { locked, hasMultipleAreasOnKiosk, data } = DailyActivitiesContext.useState()
  const { openModal } = KioskTemporaryActivityCreateModalContext.useActions()
  const { setSelectedRouteId, setCustomerInfo, setSelectedAreaId } = DailyActivitiesContext.useActions()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const handleAddTemporaryActivity = React.useCallback(
    (routeId: number,selectedCustomerListItem:CustomerListItem) => () => {
      setSelectedRouteId(routeId)
      if(!selectedCustomerListItem.id && data?.kioskAreaId){
        setCustomerInfo(null)
        setSelectedAreaId(data?.kioskAreaId)
        openModal(undefined)
      }else{
        setSelectedAreaId(undefined)
        setCustomerInfo(selectedCustomerListItem)
        openModal(selectedCustomerListItem)
      }
    },
    [data]
  )

  const getTitle = useCallback((item:CustomerModeDataItemType)=>{
    if(item.id === 0) {
      return f('DAILY_ACTIVITIES_ITEM_COMMON_ACTIVITY_ITEM_TEXT')
    }
    if(locked) {
      return item.customer.roomNumber?.trim() ? item.customer.roomNumber :  `${item.customer.firstName?.charAt(0).toUpperCase()} ${item.customer.lastName?.charAt(0).toUpperCase()}`
    }
    return item.customer.roomNumber?.trim() ? item.customer.roomNumber : `${item.customer.firstName} ${item.customer.lastName}`
  },[])

  return (
    <>
      {
        !isEmpty(customerModeData) && (
          <>
            <DailyActivityGroupItem
              isActive={!selectedMenu}
              onClick={handleSelectMenu()}
              color={matches ? Colors.TeniloBeige :Colors.TeniloGreen}
              title={f('DAILY_ACTIVITIES_PAGE_CONTAINER_GROUPSLIST_ALL_TITLE')}
              mb={3}
            />
            {customerModeData.map((item,index) => {
              return (
                <Fragment key={index}>
                  {
                    (!selectedMenu || selectedMenu.selectedCustomer === item.id) && (
                      <DailyActivityGroupItem
                        isActive={!selectedMenu || selectedMenu.selectedCustomer === item.id || !selectedMenu.selectedRoute}
                        onClick={handleSelectMenu(item.id)}
                        title={getTitle(item)}
                        color={matches ? Colors.TeniloBeige :Colors.TeniloGreen}
                        mb={3}
                      />
                    )
                  }
                  {
                    (item.id !== 0) && selectedMenu?.selectedCustomer === item.id && (
                      <>
                        <DailyActivitySubButton
                          title={f('DAILY_ACTIVITIES_PAGE_CONTAINER_FUTURE')}
                          onClick={handleShowFutureClick(item.customer.id)}
                          color={Colors.TeniloBrown}
                          isLocked={false}
                          mb={3}
                        />
                        <DailyActivitySubButton
                          title={f('DAILY_ACTIVITIES_PAGE_CONTAINER_HISTORY')}
                          onClick={handleShowTFHistoryClick(item.customer.id)}
                          color={Colors.TeniloBrown}
                          isLocked={false}
                          mb={3}
                        />
                      </>
                    )
                  }
                  {selectedMenu && selectedMenu.selectedCustomer === item.id && (
                    item.route.map(route => (
                      <Fragment key={route.routeId}>
                        <DailyActivitySubGroupItem
                          key={route.routeId}
                          isLocked={locked}
                          customer={route.name}
                          initials={route.name}
                          isActive={!selectedMenu.selectedRoute || selectedMenu.selectedRoute === route.routeId}
                          title={route.name}
                          onClick={handleSelectMenu(item.id,route.routeId)}
                          color={route.color}
                          mb={3}
                        />
                        {
                          selectedMenu.selectedRoute === route.routeId && (
                            <>
                              {!locked && (
                                <DailyActivitySubGroupItem
                                  isLocked
                                  customer=""
                                  initials={f('DAILY_ACTIVITIES_PAGE_CONTAINER_ADD_TEMPORARY_ACTIVITY')}
                                  roomNumber=""
                                  isCommonActivity={false}
                                  isActive
                                  title=""
                                  onClick={handleAddTemporaryActivity(route.routeId,item.customer)}
                                  color={route.color}
                                  mb={3}
                                />
                              )}
                            </>
                          )
                        }
                      </Fragment>
                    ))
                  )}
                </Fragment>
            )})}
          </>

        )
      }
    </>
  );
}

export default DailyActivityMainMenuCustomerMode
