import React from 'react'
import { InnerContext } from '../contexts/inner-context'
import Constants from '../planning-grid-constants'
import { SessionProps } from '../planning-grid-types'
import { useResize } from './use-resize'

type Direction = 'top' | 'bottom'

export const useResizeSession = (
  session: SessionProps,
  direction: Direction
): [number, React.RefObject<HTMLDivElement>, boolean] => {
  const {
    mapPixelsToMinutes,
    snapToMinutes,
    callbacks,
    canSessionResize,
  } = InnerContext.useActions()
  const { containerRef } = InnerContext.useState()

  const [delta, setDelta] = React.useState(0)
  const [isResizing, setIsResizing] = React.useState(false)
  const handleResizeStart = React.useCallback(() => setIsResizing(true), [])

  const handleResizeUpdate = React.useCallback(
    (d: number) => {
      const dMinutes = mapPixelsToMinutes(d)
      const startMinutes = +session.startDate / 60000
      const endMinutes = startMinutes + session.lengthInMinutes
      const options =
        direction === 'top'
          ? {
              startDate: new Date(
                +session.startDate +
                  (snapToMinutes(startMinutes + dMinutes) - startMinutes) *
                    60000
              ),
              lengthInMinutes:
                session.lengthInMinutes -
                (snapToMinutes(startMinutes + dMinutes) - startMinutes),
            }
          : {
              lengthInMinutes:
                session.lengthInMinutes +
                (snapToMinutes(endMinutes + dMinutes) - endMinutes),
            }

      const localCanResize = canSessionResize(session, options)
      if (localCanResize) {
        setDelta(d)
      }
    },
    [mapPixelsToMinutes, direction, session, snapToMinutes, canSessionResize]
  )

  const handleResizeStop = React.useCallback(
    (d: number) => {
      const dMinutes = mapPixelsToMinutes(d)
      const startMinutes = +session.startDate / 60000
      const endMinutes = startMinutes + session.lengthInMinutes

      const options =
        direction === 'top'
          ? {
              startDate: new Date(
                +session.startDate +
                  (snapToMinutes(startMinutes + dMinutes) - startMinutes) *
                    60000
              ),
              lengthInMinutes:
                session.lengthInMinutes -
                (snapToMinutes(startMinutes + dMinutes) - startMinutes),
            }
          : {
              lengthInMinutes:
                session.lengthInMinutes +
                (snapToMinutes(endMinutes + dMinutes) - endMinutes),
            }

      if (options.lengthInMinutes <= 0) {
        options.lengthInMinutes = 1
      }

      const { meta, ...internal } = { ...session, ...options }
      if (callbacks.onUpdate) {
        callbacks.onUpdate({ internal, meta, action: 'update' })
      }
      setDelta(0)
      setIsResizing(false)
    },
    [callbacks, direction, mapPixelsToMinutes, session, snapToMinutes]
  )

  React.useLayoutEffect(() => {
    const { current } = containerRef

    if (current !== null && isResizing) {
      const boundingRect = current.getBoundingClientRect()
      let mouseDirection: number | null = null
      const scrollSpeed = Constants.DEFAULT_SCROLL_SPEED

      const scroll = () => {
        if (mouseDirection !== null) {
          current.scrollBy(0, scrollSpeed * mouseDirection)
          window.requestAnimationFrame(scroll)
        }
      }

      const handleMouseEnter = (event: MouseEvent) => {
        mouseDirection = null
      }

      const handleMouseLeave = (event: MouseEvent) => {
        if (event.clientY <= boundingRect.top) {
          mouseDirection = -1
          scroll()
        }
        if (event.clientY >= boundingRect.bottom) {
          mouseDirection = 1
          scroll()
        }

        if (mouseDirection) {
          current.addEventListener('mouseenter', handleMouseEnter)
        }
      }

      current.addEventListener('mouseleave', handleMouseLeave)
      return () => {
        mouseDirection = null
        current.removeEventListener('mouseleave', handleMouseLeave)
        current.removeEventListener('mouseenter', handleMouseEnter)
      }
    }
    return () => {}
  }, [containerRef, isResizing])

  const callbacksProps = React.useMemo(
    () => ({
      onResizeStart: handleResizeStart,
      onResizeStop: handleResizeStop,
      onResizeUpdate: handleResizeUpdate,
    }),
    [handleResizeStart, handleResizeStop, handleResizeUpdate]
  )

  const [refAnchor] = useResize(callbacksProps)

  return [delta, refAnchor, isResizing]
}
