import React from 'react'
import { createContext } from 'utilities/create-context'
import Constants from '../planning-grid-constants'
import { PlanningGridContext } from '../planning-grid-context'
import { Callbacks, SessionProps, TrackProps } from '../planning-grid-types'
import { createTimeArray } from '../utilities/create-time-array'
import { getEndDate } from '../utilities/get-end-date'

type InnerContextProps = Readonly<{
  calendarStartDate: Date
  calendarEndDate: Date
  tracks: TrackProps[]
  sessions: SessionProps[]
  preventHorizontalDrag?: boolean
  snapToGrid?: boolean
  snapInMinutes?: number
  stepInMinutes?: number
  timeScale?: number
  callbacks: Callbacks<TrackProps, SessionProps>
}>

const useInnerContext = (params: InnerContextProps) => {
  const {
    calendarStartDate,
    calendarEndDate,
    tracks,
    sessions,
    callbacks,
    preventHorizontalDrag = false,
    snapToGrid = true,
    snapInMinutes = Constants.DEFAULT_SNAP_IN_MINUTES,
    stepInMinutes = Constants.DEFAULT_STEP_IN_MINUTES,
    timeScale = Constants.DEFAULT_TIME_SCALE,
  } = params
  const {
    setCalendarStartDate,
    setContainerRef,
    setCallbacks,
    setTimeScale,
  } = PlanningGridContext.useActions()

  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setContainerRef(containerRef)
  }, [setContainerRef, containerRef])

  React.useEffect(() => {
    setCallbacks(callbacks)
  }, [setCallbacks, callbacks])

  React.useEffect(() => {
    setCalendarStartDate(calendarStartDate)
  }, [calendarStartDate, setCalendarStartDate])

  const onTrackDoubleClick = React.useCallback(
    (trackId: number, startDate: Date) => {
      if (callbacks.onDoubleClick) {
        const track = tracks.find(t => t.id === trackId)
        if (!track) {
          console.log(`No track found with id ${trackId}`)
          return
        }
        const { meta, ...internal } = track
        callbacks.onDoubleClick({ meta, internal, startDate })
      }
    },
    [callbacks, tracks]
  )

  const isBetweenBounds = React.useCallback(
    (start: Date, end: Date) => {
      if (start < calendarStartDate) {
        return false
      }
      if (end > calendarEndDate) {
        return false
      }
      return true
    },
    [calendarEndDate, calendarStartDate]
  )

  const mapPixelsToMinutes = React.useCallback(
    (pixels: number): number =>
      pixels * (timeScale * window.devicePixelRatio * 0.7),
    [timeScale]
  )

  const mapMinutesToPixels = React.useCallback(
    (minutes: number): number =>
      minutes / (timeScale * window.devicePixelRatio * 0.7),
    [timeScale]
  )

  const snapToPixels = React.useCallback(
    (valueInPixels: number) => {
      if (!snapToGrid) {
        return valueInPixels
      }
      const snapInPixels = mapMinutesToPixels(snapInMinutes)
      return Math.round(valueInPixels / snapInPixels) * snapInPixels
    },
    [mapMinutesToPixels, snapInMinutes, snapToGrid]
  )

  const snapToMinutes = React.useCallback(
    (valueInMinutes: number) => {
      if (!snapToGrid) {
        return valueInMinutes
      }
      return Math.round(valueInMinutes / snapInMinutes) * snapInMinutes
    },
    [snapInMinutes, snapToGrid]
  )

  const canSessionDrop = React.useCallback(
    (pixelsFromTop: number, lengthInMinutes: number) => {
      const startTimeInMinutes = mapPixelsToMinutes(pixelsFromTop)
      const startDate = new Date(+calendarStartDate + startTimeInMinutes)
      const endDate = getEndDate(startDate, lengthInMinutes)
      return isBetweenBounds(startDate, endDate)
    },
    [calendarStartDate, isBetweenBounds, mapPixelsToMinutes]
  )

  const canSessionResize = React.useCallback(
    (session: SessionProps, options: Partial<Omit<SessionProps, 'id'>>) => {
      const updSession = { ...session, ...options }
      const endDate = getEndDate(
        updSession.startDate,
        updSession.lengthInMinutes
      )
      return isBetweenBounds(updSession.startDate, endDate)
    },
    [isBetweenBounds]
  )

  const timeArray = React.useMemo(
    () => createTimeArray(calendarStartDate, calendarEndDate, stepInMinutes),
    [calendarEndDate, calendarStartDate, stepInMinutes]
  )

  const scrollDate = React.useRef(0)
  React.useLayoutEffect(() => {
    setTimeScale(timeScale)
    const { current } = containerRef
    if (current !== null) {
      const y = mapMinutesToPixels(scrollDate.current / 60000)
      current.scrollTo(0, y)
    }
  }, [
    calendarStartDate,
    mapMinutesToPixels,
    mapPixelsToMinutes,
    setTimeScale,
    timeScale,
  ])

  React.useLayoutEffect(() => {
    const { current } = containerRef
    if (current != null) {
      const hanleScroll = (event: Event) => {
        const { scrollTop, offsetHeight } = event.target as HTMLDivElement
        const topDate = new Date(
          +calendarStartDate + mapPixelsToMinutes(scrollTop) * 60000
        )
        const bottomDate = new Date(
          +topDate + mapPixelsToMinutes(offsetHeight) * 60000
        )
        scrollDate.current = +topDate - +calendarStartDate

        if (callbacks.onScroll && event.target !== null) {
          callbacks.onScroll({ topDate, bottomDate })
        }
      }

      current.addEventListener('scroll', hanleScroll)
      return () => {
        current.removeEventListener('scroll', hanleScroll)
      }
    }
    return () => {}
  }, [calendarStartDate, callbacks, mapPixelsToMinutes])

  return {
    state: {
      calendarStartDate,
      calendarEndDate,
      timeArray,
      stepInMinutes,
      snapInMinutes,
      timeScale,
      containerRef,
      tracks,
      sessions,
      preventHorizontalDrag,
    },
    actions: {
      mapPixelsToMinutes,
      canSessionDrop,
      canSessionResize,
      onTrackDoubleClick,
      callbacks,
      mapMinutesToPixels,
      snapToPixels,
      snapToMinutes,
    },
  }
}

export const InnerContext = createContext(useInnerContext)
