import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import Colors from 'styles/colors'
import format from 'utilities/format'
import { TimePicker, DatePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import SelectInput from 'components/inputs/select-input'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import OutlinedButton from 'components/inputs/outlined-button'
import { useIsPlanningReadonly } from 'utilities/use-is-planning-readonly'
import Authentication from 'components/authentication'
import { UserListItem } from 'api/tillit.api-client'
import { IPlannedRouteUserWorkScheduleItem, PlannedRouteUserWorkScheduleItem } from 'api/tenilo.workschedule.api-client'
import { addDays } from 'utilities/date-time-helpers'
import { StyledModalBox } from './edit-user-workschedule-modal-style'
import { StyledWorkScheduleItem } from './edit-user-workschedule-item-style'
import { EditUserWorkScheduleModalContext } from './edit-user-workschedule-modal-context'
import useFormData from './utilities/use-edit-user-workschedule-modal-form-data'

const EditUserWorkScheduleModalView: React.FC = () => {
  const f = useFormatMessage()
  const { usingCalendarFromDevice } = Authentication.useAuthenticatedState()
  const {
    editUserWorkScheduleModalOpen,
    editUserWorkScheduleModalData,
    editUserWorkScheduleIsPlanned,
    areaUsers,
    calendarStartDate,
  } = PlanningPageContext.useState()
  const { closeEditUserWorkScheduleDialog, unplanUserWorkSchedule } = PlanningPageContext.useActions()
  const { planningId } = PlanningPageContext.useState()
  const { initialValues, name, onSubmit, validationSchema } = useFormData()
  const { getLatestWorkSchedulesForSubstituteUser, setLatestUserWorkSchedules, getFuturePlannedUserWorkSchedulesOnUser, setFutureUserWorkSchedulesOnUser } = EditUserWorkScheduleModalContext.useActions();
  const { latestUserWorkSchedules, futureUserWorkSchedulesOnUser } = EditUserWorkScheduleModalContext.useState();

  const readonlyPlanning = useIsPlanningReadonly(calendarStartDate) || usingCalendarFromDevice
  const isBeforeCalendarDay = editUserWorkScheduleModalData ? editUserWorkScheduleModalData?.scheduledStart < calendarStartDate : false

  const [replaceMultipleCheckBoxValue, setReplaceMultipleCheckBoxValue] = React.useState(false)

  const handleReplaceMultipleSchedulesCheckbox = React.useCallback(
    () => {
      setReplaceMultipleCheckBoxValue(!replaceMultipleCheckBoxValue)
      if (initialValues.userId !== undefined && !replaceMultipleCheckBoxValue && initialValues.id) {
        getFuturePlannedUserWorkSchedulesOnUser(initialValues.userId, planningId, initialValues.id)
      }
    }, [replaceMultipleCheckBoxValue, initialValues, getFuturePlannedUserWorkSchedulesOnUser]
  )

  if (!replaceMultipleCheckBoxValue) {
    setFutureUserWorkSchedulesOnUser(undefined)
  }

  const handleClose = React.useCallback(() => {
    setLatestUserWorkSchedules(undefined)
    setFutureUserWorkSchedulesOnUser(undefined)
    setReplaceMultipleCheckBoxValue(false)
    closeEditUserWorkScheduleDialog()
  }, [setLatestUserWorkSchedules, closeEditUserWorkScheduleDialog, setFutureUserWorkSchedulesOnUser, setReplaceMultipleCheckBoxValue])

  const handleSubmit = React.useCallback((values: any, formikHelpers: any) => {
    onSubmit(values, formikHelpers)
    closeEditUserWorkScheduleDialog()
    setLatestUserWorkSchedules(undefined)
    setFutureUserWorkSchedulesOnUser(undefined)
    setReplaceMultipleCheckBoxValue(false)

  }, [onSubmit, closeEditUserWorkScheduleDialog, setLatestUserWorkSchedules, setFutureUserWorkSchedulesOnUser, setReplaceMultipleCheckBoxValue])

  const handleUnplan = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (editUserWorkScheduleModalData) {
      const userWorkSchedule = mapDataToPlannedRouteUserWorkScheduleItem(editUserWorkScheduleModalData)
      unplanUserWorkSchedule(userWorkSchedule)
      closeEditUserWorkScheduleDialog()
    }
  }

  const mapDataToPlannedRouteUserWorkScheduleItem = (
    userWorkSchedule: IPlannedRouteUserWorkScheduleItem
  ): PlannedRouteUserWorkScheduleItem => {

    return new PlannedRouteUserWorkScheduleItem({
      id: userWorkSchedule.id,
      plannedRouteId: userWorkSchedule.plannedRouteId,
      userId: userWorkSchedule.userId,
      scheduledStart: userWorkSchedule.scheduledStart,
      scheduledEnd: userWorkSchedule.scheduledEnd,
      routeId: userWorkSchedule.routeId,
      routeName: userWorkSchedule.routeName,
      userFullName: userWorkSchedule.userFullName,
      substituteUserId: userWorkSchedule.substituteUserId,
      substituteUserFullName: userWorkSchedule.substituteUserFullName,
      areaId: userWorkSchedule.areaId,
      areaName: userWorkSchedule.areaName,
    })
  }

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={editUserWorkScheduleModalOpen} onClose={handleClose}>
      <StyledModalBox>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue }) => {

            const handleChangeScheduledStart = (scheduledStart: Date | null) => {
              const { startDate, scheduledEnd } = values

              if (!scheduledStart || !scheduledEnd) {
                if (startDate) {
                  setFieldValue('scheduledStart', new Date(startDate.getTime()))
                  setFieldValue('scheduledEnd', new Date(startDate.getTime()))
                }

                return
              }
              setFieldValue('scheduledStart', scheduledStart)

              let { endDate } = values

              const milliSec24Hrs = 60 * 60 * 24 * 1000;
              if (startDate && endDate) {
                //If end is less than start then we should add 24 hours to end
                if (scheduledEnd.getHours() < scheduledStart.getHours()) {
                  endDate = new Date(startDate.getTime() + milliSec24Hrs)
                } else {
                  endDate = new Date(startDate.getTime())
                }
                setFieldValue('endDate', endDate)

                const newScheduledEnd = new Date(endDate.getTime())
                newScheduledEnd.setHours(scheduledEnd.getHours())
                newScheduledEnd.setMinutes(scheduledEnd.getMinutes())
                setFieldValue('scheduledEnd', newScheduledEnd)
              }
            }

            const handleChangeScheduledEnd = (scheduledEnd: Date | null) => {
              const { scheduledStart, startDate } = values

              if (!scheduledEnd || !scheduledStart) {
                if (startDate) {
                  setFieldValue('scheduledEnd', new Date(startDate.getTime()))
                }

                return
              }

              let { endDate } = values

              const milliSec24Hrs = 60 * 60 * 24 * 1000;
              if (startDate && scheduledEnd) {
                //If end is less than start then we should add 24 hours to end
                if (scheduledEnd.getHours() < scheduledStart.getHours()) {
                  endDate = new Date(startDate.getTime() + milliSec24Hrs)
                } else {
                  endDate = new Date(startDate.getTime())
                }
                setFieldValue('endDate', endDate)

                const newScheduledEnd = new Date(endDate.getTime())
                newScheduledEnd.setHours(scheduledEnd.getHours())
                newScheduledEnd.setMinutes(scheduledEnd.getMinutes())
                setFieldValue('scheduledEnd', newScheduledEnd)
              }
            }

            const handleChangeScheduledEndDate = (val) => {
              //Not used as endDate is disabled
            }

            const handleChangeUser = (_: React.ChangeEvent<{}>, value: any) => {
              const uV = value as UserListItem
              if (uV) {
                setFieldValue('userId', uV.id)
              } else {
                setFieldValue('userId', undefined)
              }
            }

            const handleChangeSubstituteUser = (_: React.ChangeEvent<{}>, value: any) => {
              const uV = value as UserListItem
              if (uV) {
                setFieldValue('substituteUserId', uV.id)
                getLatestWorkSchedulesForSubstituteUser(uV.id, values.id)
              } else {
                setFieldValue('substituteUserId', undefined)
                setLatestUserWorkSchedules(undefined)
              }
            }

            const handleChangeSubstituteUserForAll = (_: React.ChangeEvent<{}>, value: any) => {
              const uV = value as UserListItem
              if (uV) {
                setFieldValue('substituteUserIdForAllSelected', uV.id)
              } else {
                setFieldValue('substituteUserIdForAllSelected', undefined)
              }
            }

            const getUserName = (item: any) => {
              const ua = item as UserListItem
              return `${ua.fullName}`
            }

            return (
              <Form translate="yes">
                <ModalHeader
                  title={readonlyPlanning ? f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TITLE_READONLY') : f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TITLE')}
                />
                <Box mx={3}>
                  <div
                    style={{
                      maxHeight: '70vh',
                      minWidth: '20vw',
                      overflow: 'auto',
                    }}></div>
                  <ModalSection
                    title={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_TITLE')}
                    subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="100%">
                        <Box data-matomo-mask flex={1}>
                          <SelectInput
                            label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_USER_LABEL')}
                            onChange={handleChangeUser}
                            name={name.user}
                            options={areaUsers as any}
                            getOptionLabel={getUserName}
                            fullWidth={true}
                            error={!!errors.user}
                            helperText={errors.user}
                            disabled
                          />
                        </Box>
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_TITLE')}
                    subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="100%">
                        <Box data-matomo-mask flex={1}>
                          <SelectInput
                            label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_LABEL')}
                            onChange={handleChangeSubstituteUser}
                            name={name.substituteUser}
                            options={areaUsers as any}
                            getOptionLabel={getUserName}
                            fullWidth={true}
                            error={!!errors.substituteUser}
                            helperText={errors.substituteUser}
                            disabled={readonlyPlanning}
                          />
                        </Box>
                      </Box>

                    </ModalSectionRow>
                  </ModalSection>

                  {!readonlyPlanning && (
                    <ModalSection
                      title={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_TITLE')}
                      subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_SUBTITLE')}>
                      <ModalSectionRow sx={{ display: "block" }}>
                        <div>
                          <FormControlLabel
                            onChange={handleReplaceMultipleSchedulesCheckbox}
                            control={<Checkbox checked={replaceMultipleCheckBoxValue} color="primary" disabled={readonlyPlanning} />}
                            label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_CHECKBOX')}
                            labelPlacement="end"
                          />
                          {replaceMultipleCheckBoxValue && (
                            <Box width="100%" sx={{ marginBottom: "1.5vh" }}>
                              <Box data-matomo-mask flex={1}>
                                <SelectInput
                                  label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_MULTIPLE_LABEL')}
                                  onChange={handleChangeSubstituteUserForAll}
                                  name={name.substituteUserForAllSelected}
                                  options={areaUsers as any}
                                  getOptionLabel={getUserName}
                                  fullWidth={true}
                                  error={!!errors.substituteUser}
                                  helperText={errors.substituteUser}
                                />
                              </Box>
                            </Box>
                          )}
                        </div>
                        {futureUserWorkSchedulesOnUser && futureUserWorkSchedulesOnUser.length > 0 && (
                          <div style={{ maxHeight: "15vh", overflow: "auto" }}>
                            {futureUserWorkSchedulesOnUser.map(ws => {
                              return (
                                <StyledWorkScheduleItem key={ws.id} color={ws.substituteUserId ? Colors.TeniloBrown : Colors.TeniloGreen}>
                                  <Typography data-matomo-mask variant="subtitle2" fontWeight={600} color='white'>
                                    <div>{`${format(ws.scheduledStart, 'yyyy-MM-dd HH:mm')} - ${format(ws.scheduledEnd, 'HH:mm')} - ${ws.routeName}`}</div>
                                    <div>{`${ws.substituteUserId ? `(${ws.substituteUserFullName} ${f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_FOR_LABEL')} ${ws.userFullName})` : ''} `}</div>
                                  </Typography>
                                </StyledWorkScheduleItem>
                              )
                            })}
                          </div>
                        )}
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  {!replaceMultipleCheckBoxValue && (
                    <ModalSection
                      title={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_TITLE')}
                      subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_TIMEINTERVAL_SUBTITLE')}>
                      <ModalSectionRow>
                        <DatePicker
                          label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_DATE_LABEL')}
                          value={initialValues.scheduledStart}
                          format="yyyy-MM-dd"
                          disabled
                          slots={{
                            textField: (params) => <TextField {...params}
                            color="secondary"
                            variant="filled"
                            fullWidth />
                          }}
                          localeText={{
                            toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                          slotProps={{
                            actionBar: {
                              actions: [],
                            },
                          }}
                        />
                        <TimePicker
                          label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDSTART_LABEL')}
                          value={values.scheduledStart}
                          onChange={handleChangeScheduledStart}
                          ampm={false}
                          sx={{width:'100%'}}
                          disabled={readonlyPlanning}
                          slotProps={{
                            textField:{
                              variant:'filled',
                              error:touched && !!errors?.scheduledStart,
                              helperText:touched && errors?.scheduledStart
                            }
                          }}
                          localeText={{
                            toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </ModalSectionRow>
                      <ModalSectionRow>
                        <DatePicker
                          label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_DATE_LABEL')}
                          value={values.scheduledEnd}
                          disabled
                          minDate={initialValues.scheduledStart}
                          maxDate={addDays(initialValues.scheduledStart, 1)}
                          format="yyyy-MM-dd"
                          slots={{
                            textField: (params) => <TextField {...params}
                            color="secondary"
                            variant="filled"
                            fullWidth />
                          }}
                          localeText={{
                            toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                          slotProps={{
                            actionBar: {
                              actions: [],
                            },
                          }}
                        />
                        <TimePicker
                          label={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_SCHEDULEDEND_LABEL')}
                          value={values.scheduledEnd}
                          onChange={handleChangeScheduledEnd}
                          ampm={false}
                          sx={{width:'100%'}}
                          disabled={readonlyPlanning}
                          slotProps={{
                            textField:{
                              variant:'filled',
                              error:touched && !!errors?.scheduledEnd,
                              helperText:touched && errors?.scheduledEnd
                            }
                          }}
                          localeText={{
                            toolbarTitle: f('TIME_PICKER_TOOLBAR_TITLE'),
                            okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                            cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </ModalSectionRow>
                    </ModalSection>
                  )}

                  {!replaceMultipleCheckBoxValue && latestUserWorkSchedules && latestUserWorkSchedules.length > 0 && (
                    <ModalSection
                      data-matomo-mask
                      title={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_TITLE')}
                      subtitle={f('PLANNING_PAGE_SCHEDULE_VIEW_EDITUSERWORKSCHEDULE_DIALOG_LATEST_WORKSCHEDULES_SUBTITLE', { userFullname: values.substituteUser?.fullName })}>
                      <div style={{ maxHeight: "20vh", overflow: "auto" }}>
                        {latestUserWorkSchedules.map(ws => {
                          return (
                            <StyledWorkScheduleItem key={ws.id} color={ws.substituteUserId ? Colors.TeniloBrown : Colors.TeniloGreen}>
                              <Typography variant="subtitle2" fontWeight={600} color='white'>
                                <div>{`${format(ws.scheduledStart, 'yyyy-MM-dd HH:mm')} - ${format(ws.scheduledEnd, 'HH:mm')} ${ws.areaName} - ${ws.routeName}`}</div>
                                <div>{`${ws.substituteUserId ? `(${f('PLANNING_PAGE_SCHEDULE_VIEW_CREATEUSERWORKSCHEDULE_DIALOG_SUBSTITUTE_FOR_LABEL')} ${ws.userFullName})` : ''} `}</div>
                              </Typography>
                            </StyledWorkScheduleItem>
                          )
                        })}
                      </div>
                    </ModalSection>
                  )}

                </Box>

                <Box flexDirection="column" alignItems="stretch">
                  <Box display="flex" flexDirection="row" justifyContent="flex-start">
                    {!readonlyPlanning && !isBeforeCalendarDay && editUserWorkScheduleIsPlanned && (
                      <OutlinedButton color="error" onClick={handleUnplan}>
                        {f('POPOVER_INFO_ACTIVITY_UNDO_BUTTON')}
                      </OutlinedButton>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button type="button" color="secondary" onClick={handleClose}>
                      {f('BASIC_BUTTON_CANCEL_TEXT')}
                    </Button>
                    {!readonlyPlanning && (
                      <Button type="submit" color="secondary">
                        {f('BASIC_BUTTON_SAVE_TEXT')}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </StyledModalBox>
    </Modal >
  )
}

export default EditUserWorkScheduleModalView
