import React from 'react'
import {  NotificationContainer } from 'components/notification'
import Page, {PageHeader} from 'components/page'
import { Box } from '@mui/material'
import { Colors } from 'styles/colors'
import { useFormatMessage } from 'localization'
import makeStyles from '@mui/styles/makeStyles'
import DashboardSectionContainer from './components/dashboard-section'
import DashboardHeaderView from './components/dashboard-header'


const useStyles = makeStyles(theme => {
  return ({
    'tenilo-dashboard-page': {
      [theme.customBreakpoints.justMobileTablet]: {},
      '& .tenilo-main': {
        display:'flex',
        flexDirection:'column',
        overflow:'hidden',
        '&>div:first-child':{ //page header
          padding:'40px',
          height:'120px',
          boxSizing:'border-box',
          width:'auto',
          margin: 0,
        }
      },
    }
  }
  )
})

const DashboardPageView: React.FC = () => {
  const f = useFormatMessage()
  const classes = useStyles()

  return (
    <NotificationContainer>
      <Page noPadding className={classes["tenilo-dashboard-page"]}>
        <PageHeader title={f('DASHBOARD_PAGE_CONTAINER_HEADER_TITLE')} />
        <Box sx={{
          width:'-webkit-fill-available',
          position:'fixed',
          display:'flex',
          justifyContent:'end',
          paddingRight:'20px',
          alignItems:'end',
          top:'95px',
          height:'25px',
          backgroundColor: Colors.AthensGray,
          '>*':{
            border:`1px solid ${Colors.TeniloGreen}`,
            margin:'1px 2px',
            height:'100%',
            cursor:'pointer',
            transition:'all 0.1s ease-in',
            '&:hover':{
              backgroundColor: Colors.TeniloGreen,
              color: Colors.AthensGray
            }
          }
        }}>
          <DashboardHeaderView />
        </Box>
        <DashboardSectionContainer />
      </Page>
    </NotificationContainer>
  )
}

export default DashboardPageView
