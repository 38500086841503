import { STORAGEPREFIX } from 'config/variables'

export const ITEMID = (STORAGEPREFIX ? STORAGEPREFIX : '') + '-is-logged-out'

export const hasIsLoggedOut = (): boolean => localStorage.getItem(ITEMID) !== null

export const getIsLoggedOut = (): boolean => {
  const r = localStorage.getItem(ITEMID)
  if (r) {
    return true
  }
  return false
}

export const setIsLoggedOut = () => localStorage.setItem(ITEMID, 'TRUE')

export const clearIsLoggedOut = () => localStorage.removeItem(ITEMID)
