import React from 'react'
import { Typography } from '@mui/material'
import { PlannedActivityParticipantItem } from 'api/tillit.api-client'
import cloneDeep from 'lodash/cloneDeep'

type Props = Readonly<{
  participants: PlannedActivityParticipantItem[]
}>

const SortedParticipantsBulletListContainer: React.FC<Props> = ({ participants }) => {

  const sortedParticipants = React.useMemo(() => {
    return cloneDeep(participants).sort((a, b) => (a.customerFullName || '').localeCompare(b.customerFullName || ''))
  }, [participants])

  return (
    <>
      {sortedParticipants.map((pa, index) => {
        return (
          <Typography data-matomo-mask key={`pap_${index}`} variant="subtitle2" fontWeight={400}> • {pa.customerFullName}</Typography>
        )
      })}
    </>
  )
}

export default SortedParticipantsBulletListContainer
