import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import { CustomerListItem } from 'api/tillit.api-client'

type SearchCustomerForm = Readonly<{
  selectedCustomer: CustomerListItem | undefined
}>

const useSearchCustomerFormData = () => {
  const initialValues = React.useMemo<SearchCustomerForm>(() => {
    return {
      selectedCustomer: undefined,
    }
  }, [])

  const onSubmit: OnSubmitFunction<SearchCustomerForm> = React.useCallback(async (values, { setSubmitting }) => {}, [])

  const name: Name<SearchCustomerForm> = React.useMemo(
    () => ({
      selectedCustomer: 'selectedCustomer',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    name,
  }
}

export default useSearchCustomerFormData
