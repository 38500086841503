import { useUnitApi } from 'api'
import { UnitListItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { useLocation } from 'react-router-dom'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useAdministerUnitsContext = () => {
  const { listUnits, deleteUnit, abort, hasTemplateActivityConnectedToUnit } = useUnitApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const location = useLocation()

  const [unitListItems, setUnitListItems] = React.useState<UnitListItem[]>([])
  const [modalOpen, setModalOpen] = React.useState(false)

  const fetchUnitsAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listUnits(currentUser.organizationId).then(setUnitListItems))
  }, [currentUser.organizationId, fetchAsync, listUnits])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    void fetchUnitsAsync()
  }, [fetchUnitsAsync, location])

  const { state: stateOfRemoveFetch, fetchAsync: removeFetchAsync } = usePromiseApi()

  const [unitToRemove, setUnitToRemove] = React.useState<UnitListItem | null>(null)

  const removeDialogOpen = React.useMemo(() => unitToRemove !== null, [unitToRemove])

  const [unitInvalidToRemove, setUnitInvalidToRemove] = React.useState<UnitListItem | null>(null)

  const removeInvalidDialogOpen = React.useMemo(() => unitInvalidToRemove !== null, [unitInvalidToRemove])

  const closeRemoveInvalidDialog = React.useCallback(() => setUnitInvalidToRemove(null), [setUnitInvalidToRemove])

  const openRemoveDialog = React.useCallback(
    (unit: UnitListItem) => {
      void hasTemplateActivityConnectedToUnit(unit.id).then(result => {
        if (result) {
          setUnitInvalidToRemove(unit)
        } else {
          setUnitToRemove(unit)
        }
      })
    },
    [setUnitToRemove, setUnitInvalidToRemove, hasTemplateActivityConnectedToUnit]
  )

  const closeRemoveDialog = React.useCallback(() => setUnitToRemove(null), [setUnitToRemove])

  const deleteunitAsync = React.useCallback(
    unitId => {
      void removeFetchAsync(
        deleteUnit(unitId)
          .then(fetchUnitsAsync)
          .then(closeRemoveDialog)
      )
    },
    [removeFetchAsync, deleteUnit, fetchUnitsAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      unitListItems,
      modalOpen,
      removeDialogOpen,
      unitToRemove,
      unitInvalidToRemove,
      isRemoving,
      removeInvalidDialogOpen,
    },
    actions: {
      fetchUnitsAsync,
      openModal,
      closeModal,
      openRemoveDialog,
      closeRemoveDialog,
      closeRemoveInvalidDialog,
      deleteunitAsync,
    },
  }
}

export const AdministerUnitsContext = createContext(useAdministerUnitsContext)
