import React from 'react'

import Authentication from 'components/authentication'
import { RoleEnum, UserTypeEnum } from './../api/tillit.api-client'

const useAuthorization = () => {
  const { user } = Authentication.useAuthenticatedState()

  const hasRoleOnAnyArea = React.useCallback(
    (...roles: RoleEnum[]) => {
      if (
        user.userType === UserTypeEnum.SystemAdmin ||
        user.userType === UserTypeEnum.OrganizationAdmin ||
        user.userType === UserTypeEnum.TeniloSupport
      ) {
        return true
      }

      return roles.some(role =>
        user.userAreas?.some(userArea => userArea.roleId === role)
      )
    },
    [user]
  )

  const getAdminAreas = React.useCallback((addedRoles: RoleEnum[] = [] )=>{
    return user.userAreas?.filter( area => [...addedRoles, RoleEnum.Admin].includes(area.roleId) )
  },[user])


  return {
    hasRoleOnAnyArea,
    getAdminAreas
  }
}

export default useAuthorization
