import React from 'react'
import { Tab } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { useFormatMessage } from 'localization'
import { Tabs, TabPanel, a11yProps } from 'components/tab-panel'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'
import { FormikProps } from 'formik'
import DelegationSectionContext from 'components/user-dialog/components/delegation-section/delegation-section-context'
import { Authorize } from 'components/authorization'
import { RoleEnum } from 'api/tillit.api-client'
import UserBasicForm from './components/forms/user-basic-form'
import UserRoleForm from './components/forms/user-role-form'
import DelegationContainer from './components/delegation-section'
import { UserDialogContext } from './user-dialog-context'

type Props = Readonly<{
    value: number
    handleTabChange: (hasError: boolean) => (event: any, newValue: React.SetStateAction<number>) => void
  } & FormikProps<UserFormModelWithModifiedAreas>
>

const UserDialogTabView: React.FC<Props> = ({ value, handleTabChange , ...props }) => {
  const f = useFormatMessage()
  const { loggedInUser } = UserDialogContext.useState()

  return (
    <>
      <Tabs
        indicatorColor={!isEmpty(props.errors) ? "secondary" : "primary"}
        textColor={!isEmpty(props.errors) ? "secondary" : "primary"}
        value={value} onChange={ handleTabChange(!isEmpty(props.errors)) }
        centered
        >
        <Tab label={f('USER_DIALOG_VIEW_TAB_BASICINFORMATION_TITLE')} {...a11yProps(0)} />
        <Tab label={`${f('USER_DIALOG_VIEW_TAB_ROLES_TITLE')}`} {...a11yProps(1)} />
        <Authorize allowedRoles={[RoleEnum.Admin]}>
          {loggedInUser.useDelegations && (
            <Tab label={`${f('USER_DIALOG_VIEW_TAB_DELEGATIONS_TITLE')}`} {...a11yProps(2)} />
          )}
        </Authorize>
      </Tabs>
      <TabPanel value={value} index={0}>
        <UserBasicForm {...props} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <UserRoleForm  {...props}/>
      </TabPanel>
      <Authorize allowedRoles={[RoleEnum.Admin]}>
        {loggedInUser.useDelegations &&(
          <TabPanel value={value} index={2}>
            <DelegationSectionContext.Provider>
              <DelegationContainer {...props} />
            </DelegationSectionContext.Provider>
          </TabPanel>
        )}
      </Authorize>
    </>
  )
}

export default UserDialogTabView
