import React from 'react'
import { Form, Formik } from 'formik'
import CenterModal from 'components/center-modal'
import ModalHeader from 'components/modal-header'
import useUserForm from 'components/user-dialog/utilities/use-user-form'
import FooterSection from 'components/user-dialog/components/footer-section'
import { useFormatMessage } from 'localization'
import ModalContent from 'components/modal-content'
import DialogTabView from './user-dialog-tab-view'
import { UserDialogContext } from './user-dialog-context'
import UserCreateForm from './components/forms/user-create-form'

const UseDialogContainer: React.FC = () => {
  const [value, setValue] = React.useState(0)
  const { initialValues, onSubmit, validationSchema } = useUserForm()
  const f = useFormatMessage()

  const { user, dialogOpen, calledFromOtherPersonell } = UserDialogContext.useState()
  const { handleClose } = UserDialogContext.useActions()

  const handleTabChange = (((hasError : boolean) => (event: any, newValue: React.SetStateAction<number>) => {
    if(hasError){
      return
    }
    setValue(newValue)
  }))

  return (
    <CenterModal open={dialogOpen} onClose={handleClose} fullSize>
      <ModalHeader
        data-matomo-mask
        title={user ? `${user.firstName || ''} ${user.lastName || ''}` : f('USER_DIALOG_VIEW_HEADER_TITLE')}
        subtitle={f('USER_DIALOG_VIEW_HEADER_SUBTITLE')}
      />
      <Formik initialValues={initialValues}  onSubmit={onSubmit} validationSchema={validationSchema}>
          {props => {
            return (
              <Form translate="yes" style={{display:'contents'}}>
                <ModalContent>
                  {user ? <DialogTabView value={value} handleTabChange={handleTabChange} {...props} /> : <UserCreateForm  {...props} />}
                </ModalContent>
                <FooterSection  {...props} />
              </Form>
            )
          }}
      </Formik>
    </CenterModal>
  )
}

export default UseDialogContainer
