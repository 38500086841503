import React from 'react'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import { CommonShiftItem } from 'api/tenilo.workschedule.api-client'
import { useFormatMessage } from 'localization'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { DeleteDialog } from 'components/delete-dialog'
import ContainedButton from 'components/inputs/contained-button'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import Tooltip from '@mui/material/Tooltip'
import { OkDialog } from 'components/ok-dialog'
import { IconButton, Box } from '@mui/material'
import { Delete } from 'mdi-material-ui'
import { ticksToInputTime } from 'utilities/date-time-helpers'
import { WorkScheduleContext } from './work-schedule-context'
import { UserTypeEnum } from 'api/tillit.api-client'
import { Authorize } from 'components/authorization'

type RemoveItemStateType = Readonly<{
  open: boolean,
  item?: CommonShiftItem,
  error?: boolean
}>

const WorkScheduleView: React.FC = () => {
  const f = useFormatMessage()
  const { commonShifts, currentUser, isLoading, connectSelectedWorkscheduleModalState, allowBulkAction } = WorkScheduleContext.useState()
  const { setAllowBulkAction, openWorkScheduleModal, handleRemoveCommonShift, checkIfHasInheritanceOnCommonShift, setConnectSelectedWorkscheduleModalState, handleCloseConnectWorkscheduleModal } = WorkScheduleContext.useActions()
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [filterValue, setFilterValue] = React.useState<string>('')
  const [filteredCommonShifts, setFilteredCommonShifts] = React.useState<CommonShiftItem[]>()
  const [removeItem, setRemoveItem] = React.useState<RemoveItemStateType>({
    open: false
  });

  const userIsSysAdminOrOrgAdminOrTeniloSupport = currentUser.userType === UserTypeEnum.OrganizationAdmin || currentUser.userType === UserTypeEnum.TeniloSupport || currentUser.userType === UserTypeEnum.SystemAdmin
  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
    },
    []
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
    },
    []
  )

  const handleDisallowBulkAction = React.useCallback(() => {
    setAllowBulkAction(false)
    handleCloseConnectWorkscheduleModal()
  }, [])

  React.useEffect(() => {
    if (filterValue) {
      const validFilteredCommonShifts: CommonShiftItem[] = []
      commonShifts?.forEach((shift, i) => {
        if (JSON.stringify({
          scheduledStartTimeTicks: ticksToInputTime(shift.scheduledStartTimeTicks),
          scheduledEndTimeTicks: ticksToInputTime(shift.scheduledEndTimeTicks),
          description: shift.description
        }).toLowerCase().normalize('NFC').includes(filterValue.toLowerCase().normalize('NFC'))) {
          validFilteredCommonShifts.push(shift)
        }
        if (i + 1 === commonShifts.length) {
          setFilteredCommonShifts(validFilteredCommonShifts)
        }
      })
    } else {
      setFilteredCommonShifts(commonShifts)
    }
  }, [filterValue, commonShifts])

  const handleRemoveClick = React.useCallback(
    (params) => (_: React.MouseEvent<Element, MouseEvent> | undefined) => {
      _?.stopPropagation()
      checkIfHasInheritanceOnCommonShift(params.row.id).then((hasInheritance) => {
        if (hasInheritance) {
          setRemoveItem({
            open: false,
            item: params.row,
            error: true
          })
        } else {
          setRemoveItem({
            open: true,
            item: params.row
          })
        }
      })
    },
    [removeItem]
  )

  const handleConfirmRemoveunitClick = React.useCallback(() => {
    if (removeItem.item?.id) {
      handleRemoveCommonShift(removeItem.item?.id).then(closeRemoveDialog)
    } else {
      return closeRemoveDialog()
    }
  }, [removeItem])

  const closeRemoveDialog = React.useCallback(() => {
    setRemoveItem({
      open: false,
      error: false
    })
  }, [])

  const handleRowClick = React.useCallback(
    (params, e) => {
      if (has(e.target, 'checked') || allowBulkAction) {
        return
      }
      openWorkScheduleModal(params.row)()
    },
    [allowBulkAction]
  )

  const handleSelectedWorkscheduleChange = React.useCallback((ids) => {
    setConnectSelectedWorkscheduleModalState(state => ({
      ...state,
      items: ids
    }))
  }, [setConnectSelectedWorkscheduleModalState])

  const handleConnectWorkschedule = React.useCallback(() => {
    if (!allowBulkAction) {
      setAllowBulkAction(true)
      return
    }
    if (!isEmpty(connectSelectedWorkscheduleModalState.items)) {
      setConnectSelectedWorkscheduleModalState(state => ({
        ...state,
        open: true
      }))

    }
  }, [connectSelectedWorkscheduleModalState, allowBulkAction])

  return (
    <div style={{ padding: '0.5vh' }}>
      <>
        {currentUser.organizationId && (
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flex: '50%' }}>
              <Authorize mainRoles={[UserTypeEnum.OrganizationAdmin, UserTypeEnum.TeniloSupport]}>
                <ContainedButton containerStyle={{ marginLeft: 0 }} type="button" color="secondary" onClick={openWorkScheduleModal()}>
                  {f('ADMINISTER_PERSONNEL_WORKSCHEDULE_ADD_BUTTON_TEXT')}
                </ContainedButton>
              </Authorize>
              <ContainedButton
                type="button"
                color="secondary"
                onClick={handleConnectWorkschedule}
                disabled={
                  (allowBulkAction && (isEmpty(connectSelectedWorkscheduleModalState.items) || !connectSelectedWorkscheduleModalState.items))
                }
              >
                {allowBulkAction ?
                  f('ADMINISTER_PERSONNEL_WORKSCHEDULE_CONNECT_BUTTON_TEXT')
                  :
                  f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_CHOOSE_TO_CONNECT_BUTTON_TEXT')
                }
              </ContainedButton>
              {
                allowBulkAction && (
                  <ContainedButton type="button" color="secondary" onClick={handleDisallowBulkAction}>
                    {f('ADMINISTER_PERSONNEL_CONNECT_WORKSCHEDULE_TO_AREAS_BUTTON_ABORT')}
                  </ContainedButton>
                )
              }

            </Box>
            <div style={{ width: '25%', paddingBottom: '1vh' }}>
              <FilledSearchTextField
                label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                handleChangeFilter={handleChangeFilter}
                handleClearFilter={handleClearFilter}
                searchValue={filterValue}
              />
            </div>
          </Box>
        )}
        <DataGrid
          disableColumnFilter
          sx={{
            minHeight: '70vh',
            maxHeight: '70vh',
            '.MuiDataGrid-cell': { borderBottom: '0px' }
          }}
          loading={isLoading && !commonShifts}
          rows={filteredCommonShifts || []}
          disableSelectionOnClick
          checkboxSelection={allowBulkAction}
          selectionModel={connectSelectedWorkscheduleModalState.items}
          onSelectionModelChange={handleSelectedWorkscheduleChange}
          components={{ Toolbar: CustomToolbar }}
          onRowClick={handleRowClick}
          localeText={muiDataGridLocale(f)()}
          columns={[
            {
              flex: 1,
              headerName: f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DESCRIPTION'),
              field: 'description',
            },
            {
              flex: 1,
              headerName: f('ADMINISTER_PERSONNEL_WORKSCHEDULE_START_TIME'),
              field: 'scheduledStartTimeTicks',
              valueFormatter: (params) => {
                if (params.value == null) {
                  return '';
                }
                return typeof params.value === 'number' ? ticksToInputTime(params.value) : ''
              }
            },
            {
              flex: 1,
              headerName: f('ADMINISTER_PERSONNEL_WORKSCHEDULE_END_TIME'),
              field: 'scheduledEndTimeTicks',
              valueFormatter: (params) => {
                if (params.value == null) {
                  return '';
                }
                return typeof params.value === 'number' ? ticksToInputTime(params.value) : ''
              }
            },
            {
              headerName: ' ',
              flex: 0.2,
              type: 'actions',
              field: 'no_field_delete',
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <span>
                    <Tooltip
                      title={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_BUTTON_TEXT', {
                        title: params.row.description ? params.row.description : '',
                      })}
                      aria-label={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_BUTTON_TEXT', {
                        title: params.row.description ? params.row.description : '',
                      })}>
                      <IconButton disabled={!userIsSysAdminOrOrgAdminOrTeniloSupport} onClick={handleRemoveClick(params)} size="large">
                        <Delete color={userIsSysAdminOrOrgAdminOrTeniloSupport ? 'secondary' : 'disabled'} />
                      </IconButton>
                    </Tooltip>
                  </span>
                )
              },
            },
          ]}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[25, 50, 100]}
          pagination
        />
      </>
      <DeleteDialog
        dialogTitle={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_TITLE')}
        dialogContentText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_MESSAGE', {
          title: removeItem.item?.description
        })}
        buttonSubmitText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_REMOVE')}
        buttonCancelText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_DIALOG_ACTIONS_CANCEL')}
        deleteDialogOpen={removeItem.open}
        onClose={closeRemoveDialog}
        onSubmit={handleConfirmRemoveunitClick}
      />
      <OkDialog
        dialogTitle={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_TITLE')}
        dialogContentText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_MESSAGE', {
          title: removeItem.item?.description
        })}
        buttonOkText={f('ADMINISTER_PERSONNEL_WORKSCHEDULE_DELETE_INVALID_DIALOG_ACTIONS_OK')}
        okDialogOpen={!!removeItem.error}
        onClose={closeRemoveDialog}
      />
    </div>
  )
}

export default WorkScheduleView
