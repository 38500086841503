import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { Field, FieldProps } from 'formik'
import React from 'react'

export type AutocompleteContainerProps = AutocompleteProps<string, true, true, true> & {
  name: string
}

const AutocompleteContainer: React.FC<any> = ({ name, renderInput, ...props }) => {
  const handleChangeAutocomplete = React.useCallback(
    ({ field, form }: FieldProps, onChange?: ((event: React.ChangeEvent<{}>, value: any) => void) | undefined) => (
      event: React.ChangeEvent<{}>,
      value: any
    ) => {
      form.setFieldValue(field.name, value)
      if (onChange) {
        onChange(event, value)
      }
    },
    []
  )

  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <Autocomplete
          id={name}
          {...props}
          renderInput={renderInput}
          onBlur={fieldProps.field.onBlur}
          onChange={handleChangeAutocomplete(fieldProps, props.onChange)}
          value={fieldProps.field.value}
        />
      )}
    </Field>
  )
}

export default AutocompleteContainer
