import { Box, useTheme } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import ColorPicker from 'components/inputs/color-picker'
import { BulletinBoardItemModalContext } from './bulletin-board-item-modal-context'
import useFormData from './utilities/use-bulletin-board-item-form-data'

export type BulletinBoardItemModalProps = Readonly<{
  propsTitle?: string
  propsSubtitle?: string
  activeAreaId?: number
  onSave?: () => void
}>

const BulletinBoardItemModalContainer: React.FC<BulletinBoardItemModalProps> = ({ propsTitle, onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { bulletinBoardItemModalOpen, selectedBulletinBoardItem } = BulletinBoardItemModalContext.useState()

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedBulletinBoardItem)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeBulletinBoardItemModal } = BulletinBoardItemModalContext.useActions()

  return (
    <CenterModal open={bulletinBoardItemModalOpen} onClose={closeBulletinBoardItemModal} responsive>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty }) => {

          return (
            <Form translate="yes">
              <ModalHeader
                title={
                  propsTitle
                    ? propsTitle
                    : selectedBulletinBoardItem
                      ? f('BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_EDIT_TITLE')
                      : f('BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_TITLE')
                }
                responsive
              />
              <Box sx={(theme) => ({
                mx: 3,
                minWidth: '70vh',

                [theme.breakpoints.down('md')]: {
                  ml: 1,
                  mr: 0,
                }
              })}>
                <div
                  style={{
                    maxHeight: '70vh',
                    overflow: 'auto',
                    paddingRight: '5px'
                  }}>
                  <ModalSection title={f('BULLETIN_BOARD_ITEM_MODAL_HEADER_TITLE')} subtitle={f('BULLETIN_BOARD_ITEM_MODAL_HEADER_SUBTITLE')} responsive>
                    <ModalSectionRow responsive>
                      <Box width="100%">
                        <FilledTextField
                          label={f('BULLETIN_BOARD_ITEM_MODAL_DIALOG_HEADER_LABEL')}
                          name={name.header}
                          color="secondary"
                          fullWidth
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('BULLETIN_BOARD_ITEM_MODAL_CONTENT_TITLE')}
                    subtitle={f('BULLETIN_BOARD_ITEM_MODAL_CONTENT_SUBTITLE')}
                    responsive>
                    <ModalSectionRow responsive>
                      <Box width="100%">
                        <FilledTextField
                          name={name.content}
                          label={f('BULLETIN_BOARD_ITEM_MODAL_DIALOG_CONTENT_LABEL')}
                          color="secondary"
                          fullWidth
                          multiline
                          rows={5}
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection
                    title={f('BULLETIN_BOARD_ITEM_MODAL_COLOR_TITLE')}
                    subtitle={f('BULLETIN_BOARD_ITEM_MODAL_CONTENT_SUBTITLE')}
                    responsive>
                    <ModalSectionRow responsive>
                      <ColorPicker name={name.color}></ColorPicker>
                    </ModalSectionRow>
                  </ModalSection>

                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeBulletinBoardItemModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color="secondary" showProgress={isSubmitting}>
                    {f('BASIC_BUTTON_ADD_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>

            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default BulletinBoardItemModalContainer
