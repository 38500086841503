import React from 'react'

import {
  UsersClient,
  UserItem,
  UserUpdateItem,
  IUserUpdateItem,
  PageResultOfUserListItem,
} from 'api/tenilo.support.api-client'
import { SUPPORT_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class SupportUsersApi {
  private readonly abortController = new AbortController()
  private readonly client: UsersClient

  public constructor() {
    this.client = new UsersClient(SUPPORT_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getUser = (userId: number): Promise<UserItem> =>
    this.client
      .getUser(userId)
      .then(userItem =>
        userItem === null
          ? Promise.reject(`No user item received from backend for user id ${userId}.`)
          : Promise.resolve(userItem)
      )

  public listUsers = (page: number, pageSize: number, organizationId: number | null,  areaId: number | null): Promise<PageResultOfUserListItem> =>
    this.client
      .list(page, pageSize, organizationId, areaId)
      .then(users =>
        users === null
          ? Promise.reject(`No users received from backend.`)
          : Promise.resolve(users)
      )

  public updateUser = (user: IUserUpdateItem) => {
    const model = new UserUpdateItem(user)
    return this.client
      .updateUser(model)
      .then(userItem =>
        userItem === null ? Promise.reject(`When updating an user, no user item received from backend.`) : Promise.resolve(userItem)
      )
  }

}

const useSupportUsersApi = () => React.useMemo(() => new SupportUsersApi(), [])

export default useSupportUsersApi
