import { TemplateActivityListItem, TemplateActivityCategoryListItem, TemplateActivityInheritanceItem, TemplateActivityTypeEnum } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { filter } from 'lodash'
import { isSearchValueValid } from './filter-helper'

const getFilteredItems =
  (searchValue: string,
    items: TemplateActivityListItem[] |
      TemplateActivityCategoryListItem[] |
      null) => {

    // 'NFC' means Normalization Form Canonical Composition
    searchValue = searchValue.toLowerCase().normalize('NFC')
    if (!searchValue || (searchValue && searchValue.length === 0)) {
      return items
    }

    const filteredItems = filter(items, (item: any) => {
      let searchText = ''
      if (item instanceof TemplateActivityCategoryListItem) {
        const taItem = item as TemplateActivityListItem
        searchText = `${taItem.title} ${taItem.templateActivityCategoryTitle} ${taItem.templateActivityLegislationTitle}`
      }

      if (item instanceof TemplateActivityCategoryListItem) {
        const tacItem = item as TemplateActivityCategoryListItem
        searchText = `${tacItem.title} ${tacItem.legislationTitle}`
      }
      return isSearchValueValid(searchValue, searchText);
    })

    if (filteredItems) {
      return filteredItems
    } else {
      return null
    }

  }

export const filterTemplateActivities = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: TemplateActivityListItem[]
): TemplateActivityListItem[] => {
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const typeSearchText = item.templateActivityTypeId === "CustomerTemplate"  ? f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_CUSTOMER') : f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_AREA')
    const searchText = `${item.title} ${item.ownerAreaName} ${typeSearchText} ${item.templateActivityCategoryTitle} ${item.templateActivityLegislationTitle}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as TemplateActivityListItem[]
  }

  // Slow AF...
  // return getFilteredItems(searchValue, items) as (TemplateActivityListItem[] | null)
}

export const filterTemplateActivityCategories = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: TemplateActivityCategoryListItem[]
): TemplateActivityCategoryListItem[] => {


  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const searchText = `${item.title} ${item.legislationTitle}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as TemplateActivityCategoryListItem[]
  }

  // Slow AF...
  // return getFilteredItems(searchValue, items) as (TemplateActivityCategoryListItem[] | null)
}


export const filterTemplateActivityInheritances = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  items: TemplateActivityInheritanceItem[]
): TemplateActivityInheritanceItem[] => {


  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const searchText = `${item.templateActivityTitle} ${item.areaName} ${item.areaOrganizationParentsName} ${item.templateActivityCategoryTitle} ${item.legislationTitle}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as TemplateActivityInheritanceItem[]
  }

  // Slow AF...
  // return getFilteredItems(searchValue, items) as (TemplateActivityInheritanceItem[] | null)
}

export const compundedTemplateActivityTitle = (f: ReturnType<typeof useFormatMessage>, includeType: boolean = false) => (
  templateActivity: any
): string => {
  const ta = templateActivity as TemplateActivityListItem
  const category = ta?.templateActivityCategoryTitle ? `${ta.templateActivityCategoryTitle}` : ''
  const legislation = ta?.templateActivityLegislationTitle ? `${ta.templateActivityLegislationTitle}` : ''

  let type = '';
  if (includeType) {
    if (ta.templateActivityTypeId === TemplateActivityTypeEnum.CustomerTemplate) {
      type = `, ${f('COMPOUNDED_TYPE_CUSTOMER')}`; // 'typ: Kund'
    }
    if (ta.templateActivityTypeId === TemplateActivityTypeEnum.AreaTemplate) {
      type = `, ${f('COMPOUNDED_TYPE_AREA')}`; // 'typ: Kund'
    }
  }

  let subTitle = ''
  if (category.length > 0) {
    if (legislation.length > 0) {
      subTitle = `(${category}, ${legislation}${type})`
    } else {
      subTitle = `(${category}${type})`
    }
  } else {
    if (type.length > 0) {
      type = type.substring(2, type.length) // remove leading comma and space
      subTitle = `(${type})`
    }
  }

  return ta?.title ? `${ta.title} ${subTitle}` : ""
}