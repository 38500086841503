import { Box } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Color from 'color'
import { Colors } from 'styles/colors'
import DailyActivityItemContainer from './daily-activity-item-container'

export const StyledBoxHeader: React.FC<{tourColor?:string}> = ({tourColor,children}) => {
  return (
    <Box sx={{
      backgroundColor:  tourColor ? Color(tourColor).fade(0.7).string() : Color(Colors.AthensGray).fade(0.7).string(),
      display:'flex',
      position:'relative',
      width:'100%',
      borderTopRightRadius:'4px',
      borderTopLeftRadius:'4px'
    }}>
      {children}
    </Box>
  )
}

const DailyActivityGroupItemStyle = styled(DailyActivityItemContainer)`
  width: 239px;
  height: 95px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border:solid 1px ${props => props.color ? Color(props.color).fade(0.65).string() : Color(Colors.AthensGray).fade(0.65).string() };
  box-shadow: ${props => props.color ? Color(props.color).fade(0.7).string() : Color(Colors.AthensGray).fade(0.7).string() } 1px 1px 6px 1px;
  * {
    box-sizing: border-box;
  }
  ${({theme}) => [theme.breakpoints.down('md')]}{
    width: 100%;
    margin-bottom: ${({theme}) => theme.spacing(1.5)};
    margin-right: 0;
    height: 80px;
  }
`

export default DailyActivityGroupItemStyle
