import React, { useCallback, useEffect, useState } from 'react'
import { OrganizationSigningStatusesItem } from 'api/tillit.api-client'
import { useSigningStatusApi } from 'api'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { ProfilePageContext} from './profile-page-context'

const useProfileSigningStatuses = () => {
  const [signingStatuses, setSigningStatuses] = useState<OrganizationSigningStatusesItem>()
  const { user: currentUser } = ProfilePageContext.useState()
  const { listSigningStatuses, abort } = useSigningStatusApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const fetchListSigningStatuses = useCallback(() => {
    if (!currentUser?.organizationId) {
      return
    }
    fetchAsync(listSigningStatuses(currentUser.organizationId).then(setSigningStatuses))
  }, [currentUser])


  useEffect(() => () => abort(), [abort])

  useEffect(() => {
    void fetchListSigningStatuses()
  }, [fetchListSigningStatuses])


  return {
    state: {
      signingStatuses,
      ...stateOfFetch
    },
    actions: {
      fetchListSigningStatuses,
    }
  }
}

export const ProfileSigningStatusesContext = createContext(useProfileSigningStatuses)
