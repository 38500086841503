import React from 'react'
import { Box, IconButton, ListItem, Typography, Tooltip } from '@mui/material'
import { CloseCircle } from 'mdi-material-ui'
import { useFormatMessage } from 'localization'
import styled, { css } from 'styled-components'
import { FieldArrayRenderProps } from 'formik'
import { UserAreaItem } from 'api/tillit.api-client'

type Props = Readonly<{
  additionalArea: UserAreaItem
  key: string
  index: number
} & FieldArrayRenderProps>

const AdditionalAreaListItemContainer: React.FC<Props> = ({ additionalArea, key, index, ...FieldArrayProps }) => {
  const f = useFormatMessage()

  const handleRemoveClick = React.useCallback(() => {
    FieldArrayProps.remove(index)
  }, [FieldArrayProps, index])

  return (
    <StyledBox border={1} borderColor="grey.300" borderRadius="5px" marginBottom={1} width="auto" key={key}>
      <ListItem>
        <Box display={'flex'}>
          <Typography data-matomo-mask>{additionalArea.areaName}</Typography>
        </Box>
        <Box ml={'auto'} />
        <Tooltip
          title={f('ACTIVITY_PARTICIPANT_DELETE_BUTTON_TOOLTIP', { name: additionalArea.areaName, })}
          aria-label={f('ACTIVITY_PARTICIPANT_DELETE_BUTTON_TOOLTIP', { name: additionalArea.areaName })}>
          <IconButton onClick={handleRemoveClick} size="large">
            <CloseCircle />
          </IconButton>
        </Tooltip>
      </ListItem>
    </StyledBox >
  )
}

const StyledBox = styled(Box)(
  ({ }) => css`
        &:hover {
          background-color: #F0F0F0};
        cursor: pointer;
    }
        `
)

export default AdditionalAreaListItemContainer
