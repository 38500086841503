import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'
import { PausedPeriodContext } from '../../paused-period-context'
export type CustomerPausedPeriodFormModel = Readonly<{
  id?: number
  customerId?: number
  startDateTime: Date | null
  endDateTime: Date | null
  comment: string
}>

const UsePausedPeriodCreateFormData = () => {
  const f = useFormatMessage()
  const { pausedPeriod, dialogActionType } = PausedPeriodContext.useState()
  const {
    createPausedPeriodAsync,
    updatePausedPeriodAsync,
  } = PausedPeriodContext.useActions()

  const initialValues = React.useMemo<CustomerPausedPeriodFormModel>(
    () => ({
      id: (pausedPeriod && pausedPeriod.id) || undefined,
      customerId: (pausedPeriod && pausedPeriod.customerId) || undefined,
      startDateTime: (pausedPeriod && pausedPeriod.startDateTime) || null,
      endDateTime: (pausedPeriod && pausedPeriod.endDateTime) || null,
      comment: (pausedPeriod && pausedPeriod.comment) || '',
    }),
    [pausedPeriod]
  )

  const onSubmit: OnSubmitFunction<CustomerPausedPeriodFormModel> = React.useCallback(
    (values, { setSubmitting }) => {
      if (dialogActionType === 'edit') {
        updatePausedPeriodAsync(values).then(_ => setSubmitting(false))
      } else {
        createPausedPeriodAsync(values).then(_ => setSubmitting(false))
      }
    },
    [createPausedPeriodAsync, updatePausedPeriodAsync, dialogActionType]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        startDateTime: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),

        endDateTime: yup
          .string()
          .nullable()
          .test(
            'endTimeMustBeAfterStartTimeOrNull',
            f('CUSTOMER_DIALOG_PAUSED_PERIOD_INVALIDENDDATETIME'),
            function(endDateTime) {
              if (!endDateTime) {
                return true
              }
              return new Date(endDateTime) > new Date(this.parent.startDateTime)
            }
          ),

        comment: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('PAUSED_PERIOD_DIALOG_SECTION_DATE_INPUT_COMMENT'),
            maxsize: 1024,
          })
        ),
      }),
    [f]
  )

  const name: Name<CustomerPausedPeriodFormModel> = React.useMemo(
    () => ({
      id: 'id',
      customerId: 'customerId',
      startDateTime: 'startDateTime',
      endDateTime: 'endDateTime',
      comment: 'comment',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default UsePausedPeriodCreateFormData
