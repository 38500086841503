import styled, { css } from 'styled-components'
const ColorPickerStyle = styled.div`
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 100%;
  border: 1px solid black;
`

export const ColorPickerCircleColor = styled.div<{ color: string | null }>(
  ({ color }) => css`
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: ${color};
  `
)

export const ColorPickerPopover = styled.div`
  position: absolute;
  top: 70%;
  z-index: 2;
`

export const ColorPickerCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export default ColorPickerStyle
