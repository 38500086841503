import { Typography } from '@mui/material'
import React from 'react'

import BigCheckboxStyles from './big-checkbox-styles'

export type ViewProps = Readonly<{ checked: boolean; label?: string }>

const BigCheckboxView: React.SFC<ViewProps> = ({ checked, label = '' }) => (
  <BigCheckboxStyles checked={checked}>
    <Typography variant={'subtitle2'} style={{ opacity: checked ? 1 : 0.6 }}>
      {label}
    </Typography>
  </BigCheckboxStyles>
)

export default BigCheckboxView
