import Button from '@mui/material/Button'
import React, { FC } from 'react'

type Color = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'

type ButtonProps = React.ComponentProps<typeof Button>

type Props = Omit<ButtonProps, 'variant' | 'color'> & {
  color?: Color
}
const TextButtonView: FC<Props> = ({ color = 'primary', ...rest }) => {
  return <Button color={color === 'error' ? 'primary' : color} variant="text" {...rest} />
}

export default TextButtonView
