import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { NavLink } from 'react-router-dom'
import PageRoute from 'config/page-routes'
import { LoginPage } from 'components/page'
import ContainedButton from 'components/inputs/contained-button'
import OutlinedTextField from 'components/inputs/outlined-text-field'
import { useFormatMessage } from 'localization'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import useImpersonationRequestForm from './utilities/use-impersonation-request-form'
import { useSupportUserImpersonationRequestsOpenApi } from 'api'
import { UserImpersonationRequestInfoItem } from 'api/tenilo.support.api-client'

const StyledNavLink = styled(NavLink)(
  props => css`
    text-decoration: none;
    &.active > * {
      color: ${props.theme.palette.primary.contrastText};
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.3);
    }
  `
)

const ImpersonationRequestContainer: React.SFC = () => {
  const { userId, token } = useParams() as { userId: string; token: string }
  const { initialValues, onSubmit, validationSchema, name } = useImpersonationRequestForm(+userId, token)
  const f = useFormatMessage()
  const { getUserImpersonationRequestInfo, approveUserImpersonationRequest, denyUserImpersonationRequest } = useSupportUserImpersonationRequestsOpenApi()
  const [requestInfo, setRequestInfo] = React.useState<UserImpersonationRequestInfoItem | undefined>(undefined)
  const [requestProcessed, setRequestProcessed] = React.useState<boolean>(false)

  React.useEffect(() => {
    getUserImpersonationRequestInfo(+userId, token).then((info) => {
      setRequestInfo(info)
    })
  }, [getUserImpersonationRequestInfo, userId, token])

  const onApproveClick = React.useCallback(() => {
    if (requestInfo) {
      approveUserImpersonationRequest(+userId, token).then(() => {
        setRequestProcessed(true)
      })
    }
  }, [requestInfo, userId, token])

  const onDenyClick = React.useCallback(() => {
    if (requestInfo) {
      denyUserImpersonationRequest(+userId, token).then(() => {
        setRequestProcessed(true)
      })
    }
  }, [requestInfo, userId, token])

  return (
    <LoginPage>
      <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
        <Grid item>
          <Typography sx={{
            fontWeight: 600,
            letterSpacing: '0.01em'
          }} variant="h3">{f('IMPERSONATION_REQUEST_TITLE')}</Typography>
        </Grid>
        <Grid item>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnBlur={false}>
            {({ isSubmitting, status }) => (
              <Form translate="yes">
                <Grid container direction="column" rowSpacing={3} columnSpacing={0}>
                  <Grid item>
                    <Typography data-matomo-mask>{f('IMPERSONATION_REQUEST_INFO', {requestedby: requestInfo ? requestInfo.requestedByFullname : ''})}</Typography>
                  </Grid>
                  {requestProcessed ? (
                    <Grid item sx={{ textAlign: 'center' }}>
                      <Typography>{f('IMPERSONATION_REQUEST_INFO_HANDLED')}<br /><br /></Typography>
                      <StyledNavLink to={PageRoute.Login.path}>
                        <Typography sx={
                          { fontWeight: 500 }
                        } variant="subtitle1" color="secondary">{f('GENERAL_BACK')}</Typography>
                      </StyledNavLink>
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <Typography>{f('IMPERSONATION_REQUEST_INFO_ENSURE')}<br /><br />{f('IMPERSONATION_REQUEST_INFO_APPROVE')}</Typography>
                      </Grid>
                      <Grid item display={'flex'} flexDirection={'row'} >
                        <ContainedButton onClick={onDenyClick} containerStyle={{ margin: 0, display: 'flex', justifyContent: 'center' }} sx={{
                          width: '200px',
                          padding: '10px',
                          borderRadius: '20px',
                          marginRight: '20px'
                        }} color="error" >
                          {f('GENERAL_DENY')}
                        </ContainedButton>
                        <ContainedButton onClick={onApproveClick} containerStyle={{ margin: 0, display: 'flex', justifyContent: 'center' }} sx={{
                          width: '200px',
                          padding: '10px',
                          borderRadius: '20px'
                        }} color="primary" >
                          {f('GENERAL_APPROVE')}
                        </ContainedButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </LoginPage>
  )


}

export default ImpersonationRequestContainer
