import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { ReportFieldItem, UserReportFieldItem } from 'api/tenilo.reporting.api-client'
import { Box } from '@mui/material'
import { FieldArray, useField } from 'formik'
import { isUndefined } from 'lodash'
import ReportsPageContext from 'pages/reports/reports-page-context'
import ReportsPageFieldView from './reports-page-field-view'


const ReportsPageFieldsWrapperView : FC<{fieldItems: ReportFieldItem[], userReportFieldItems?: UserReportFieldItem[]}> = ({ fieldItems, userReportFieldItems }) => {
  const f = useFormatMessage()
  const dragItem = React.useRef<number>()
  const dragEnterItem = React.useRef<number>()
  const { sortedFieldItems } = ReportsPageContext.useState()
  const { setSortedFieldItems } = ReportsPageContext.useActions()
  const [field, meta, helpers] = useField<ReportFieldItem[] | undefined>({name:'fields'})

  const handleDragOverEvent = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  },[])

  React.useEffect(()=>{
    if(userReportFieldItems){
      const items = fieldItems.filter(item => userReportFieldItems.some(userField => userField.reportFieldId === item.id))

      const sortedItems = [...items].sort((a, b) => {
        const aIndex = userReportFieldItems.findIndex(uf => uf.reportFieldId === a.id);
        const bIndex = userReportFieldItems.findIndex(uf => uf.reportFieldId === b.id);

        if (aIndex !== -1 && bIndex !== -1) {
          return userReportFieldItems[aIndex].sortIndex - userReportFieldItems[bIndex].sortIndex;
        } else if (aIndex !== -1) {
          return -1;
        } else if (bIndex !== -1) {
          return 1;
        } else {
          return a.fieldIndex - b.fieldIndex;
        }
      })
      helpers.setValue(sortedItems)
    }else{
      helpers.setValue(fieldItems)
    }
  },[fieldItems,userReportFieldItems])

  React.useEffect(()=>{
    if(userReportFieldItems){
      const list = [...fieldItems].sort((a, b) => {
        const aIndex = userReportFieldItems.findIndex(uf => uf.reportFieldId === a.id);
        const bIndex = userReportFieldItems.findIndex(uf => uf.reportFieldId === b.id);

        if (aIndex !== -1 && bIndex !== -1) {
          return userReportFieldItems[aIndex].sortIndex - userReportFieldItems[bIndex].sortIndex;
        } else if (aIndex !== -1) {
          return -1;
        } else if (bIndex !== -1) {
          return 1;
        } else {
          return a.fieldIndex - b.fieldIndex;
        }
      })
      setSortedFieldItems(list)
    }else{
      setSortedFieldItems(fieldItems)
    }
  },[fieldItems,userReportFieldItems])

  const handleDropEvent = React.useCallback((e: React.DragEvent<HTMLDivElement>) =>{
    if(!isUndefined(dragEnterItem.current) && !isUndefined(dragItem.current) && sortedFieldItems){
      const copyListItems = [...sortedFieldItems]
      const dragItemContent = copyListItems[dragItem.current]
      copyListItems.splice(dragItem.current, 1)
      copyListItems.splice(dragEnterItem.current, 0, dragItemContent)
      const newCopyListItems = copyListItems.map((item,i)=> new ReportFieldItem({...item,fieldIndex:i}))
      if(field.value){
        const selectedList = newCopyListItems.filter(item => field.value?.some(f => f.id === item.id))
        helpers.setValue(selectedList)
      }
      setSortedFieldItems(newCopyListItems)
    }
    dragItem.current = undefined
    dragEnterItem.current = undefined
  },[dragItem,dragEnterItem,sortedFieldItems,field.value])

  return React.useMemo(() => {
    return (
      <Box sx={{
        width:'50%',
        margin:'0px 0px 10px 0',
        padding:'20px 0'
      }}
      onDragOver={handleDragOverEvent}
      onDragEnter={handleDragOverEvent}
      onDrop={handleDropEvent}
      >
        {
          sortedFieldItems && (
            <FieldArray name="fields">
              {
                (arrayHelpers) => {
                  return (
                    <>
                      {
                        sortedFieldItems.map((item,index) => (
                          <ReportsPageFieldView
                              key={`${item.id}-${item.fieldName}-${item.fieldIndex}`}
                              index={index}
                              fieldItem={item}
                              helper={arrayHelpers}
                              dragItem={dragItem}
                              dragEnterItem={dragEnterItem}
                            />
                            ))
                      }
                    </>
                  )
                }
              }
            </FieldArray>
          )
        }
      </Box>
    )
  },[field.value,handleDragOverEvent,handleDragOverEvent,handleDropEvent,sortedFieldItems])
}

export default React.memo(ReportsPageFieldsWrapperView)
