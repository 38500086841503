import React, { FC } from 'react'
import { Box } from '@mui/material'
import type { InnerContainerPropType } from 'pages/reports/config'
import ReportsPageContext from 'pages/reports/reports-page-context'
import ReportsPageFieldsWrapperView from '../reports-page-fields'
import Authentication from 'components/authentication'

const ReportsPageFieldsSectionView: FC<InnerContainerPropType> = ({ }) => {
  const { report, userReport } = ReportsPageContext.useState()
  const { user } = Authentication.useAuthenticatedState()

  const memoizedFields = React.useMemo(() => {
    if (report) {
      return report.reportFields.filter(rf => {
        if (rf.isMeta) { return false }
        if (rf.useDelegations && !user.useDelegations) { return false }
        if (rf.useDrugLists && !user.useDrugLists) { return false }
        if (rf.useWorkSchedule && !user.useWorkSchedule) { return false }
        return true
      })
    }
    if (userReport) {
      return userReport.parentReport.reportFields.filter(rf => {
        if (rf.isMeta) { return false }
        if (rf.useDelegations && !user.useDelegations) { return false }
        if (rf.useDrugLists && !user.useDrugLists) { return false }
        if (rf.useWorkSchedule && !user.useWorkSchedule) { return false }
        return true
      })
    }
    return []
  }, [report, userReport, user])

  const memoizedUserFields = React.useMemo(() => {
    if (!userReport) { return undefined }
    return userReport.userReportFields
  }, [report, userReport])

  return (
    <Box>
      {
        (report || userReport) && (
          <ReportsPageFieldsWrapperView fieldItems={memoizedFields} userReportFieldItems={memoizedUserFields} />
        )
      }
    </Box>
  )
}

export default ReportsPageFieldsSectionView
