import React from 'react'
import DashboardPageView from './dashboard-page-view'
import DashboardPageContext from './dashboard-page-context'
const DashboardPageContainer: React.FC = () => {
  return (
    <DashboardPageContext.Provider>
      <DashboardPageView />
    </DashboardPageContext.Provider>
  )
}

export default DashboardPageContainer
