import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ContainedButton from 'components/inputs/contained-button'
import ModalContent from 'components/modal-content'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import React from 'react'
import { Form, Formik } from 'formik'
import ModalSection from 'components/modal-section'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalSectionRow from 'components/modal-section-row'
import ModalHeader from 'components/modal-header'
import { useFormatMessage } from 'localization'
import { useOrgInternalInfoForm } from './utilities/use-org-internal-info-form'

export const OrganizationInternalInfoModalView = () => {
  const f = useFormatMessage()
  const {name, ...formikProps} = useOrgInternalInfoForm()

  const { setOrganizationInternalInformationModalOpen } = ProfilePageContext.useActions()

  const handleClose = React.useCallback(() => setOrganizationInternalInformationModalOpen(false), [])

  return (
    <Formik {...formikProps} enableReinitialize>
      {({ values, ...rest }) => {
        return (
          <Form translate="yes" style={{display:'contents'}}>
            <ModalHeader
              title={f('PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_TITLE')}
            />
            <ModalContent style={{padding:'20px',width:"70vw"}}>

              <ModalSection
                  subtitle={f('PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_SUBTITLE')}>
                  <ModalSectionRow>
                      <FilledTextField
                        data-matomo-mask
                        label={f('PROFILE_PAGE_ORGANIZATION_INTERNAL_INFORMATION_LABEL')}
                        color="secondary"
                        fullWidth
                        multiline
                        rows={5}
                        name={name.internalInfo}
                      />
                  </ModalSectionRow>
              </ModalSection>

            </ModalContent>
            <ModalFooter>
              <TextButton onClick={handleClose}>{f('BASIC_BUTTON_CLOSE_TEXT')}</TextButton>
              <ContainedButton type="submit" showProgress={rest.isSubmitting}>
                  {f('BASIC_BUTTON_SAVE_TEXT')}
              </ContainedButton>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}
