import { Box, FormHelperText, PropTypes } from '@mui/material'
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers'
import MuiErrorMessage from 'components/inputs/mui-error-message'
import 'date-fns'
import { Field, FieldProps, FormikHandlers } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Colors from 'styles/colors'
import sv from 'date-fns/locale/sv'

type Props = Omit<MobileDatePickerProps<any>, 'value' | 'onChange' | 'renderInput'> & {
  name?: string
  color?: PropTypes.Color
  value?: Date
  label?: string
  onChange?: FormikHandlers['handleChange'] | MobileDatePickerProps<any>['onChange']
  showError?: boolean,
  errorMessage?: string,
}

const DatePickerView: React.FC<Props> = ({ name, showError, errorMessage, ...props }) => {
  const handleChange = (form: FieldProps['form'], field: FieldProps['field']) => (value) => {
    form.setFieldValue(field.name, value)
  }

  const f = useFormatMessage()

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        {name ? (
          <Box display="flex" flexDirection="column">
            <Field name={name}>
              {({ field, form }: FieldProps) => (
                <MobileDatePicker
                  label={props.label}
                  onChange={handleChange(form,field)}
                  closeOnSelect
                  format="yyyy-MM-dd"
                  value={field.value}
                  {...props}
                  localeText={{
                    toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
                    okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
                    cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
                  }}
                  slotProps={{
                    ...props.slotProps,
                    actionBar: {
                      actions: [],
                    },
                    textField:{
                      variant:'filled'
                    }
                  }}
                />
              )}
            </Field>
            {props.value !== null || undefined ? <FormHelperText>{'\u00a0'}</FormHelperText> : <MuiErrorMessage name={name} />}
            {showError && (
              <>
                <div style={{ color: Colors.RedOrange, fontSize: 'smaller' }}>{errorMessage}</div>
              </>
            )}

          </Box>
        ) : (
          <MobileDatePicker
            {...props}
            label="Uncontrolled picker"
            value={props.value}
            onChange={props.onChange!}
            localeText={{
              toolbarTitle: f('DATE_PICKER_TOOLBAR_TITLE'),
              okButtonLabel: f('BASIC_BUTTON_OK_TEXT'),
              cancelButtonLabel: f('BASIC_BUTTON_CANCEL_TEXT')
            }}
            slotProps={{
              ...props.slotProps,
              actionBar: {
                actions: [],
              },
              textField:{
                variant:'filled'
              }
            }}
          />
        )}
      </LocalizationProvider>
    </>
  )
}

export default DatePickerView
