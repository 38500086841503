import { Typography } from '@mui/material'
import Page, { PageHeader } from 'components/page'
import { useFormatMessage } from 'localization'
import React, { FC } from 'react'
import { Tab, Tabs as MuiTabs } from '@mui/material'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { NotificationContainer } from 'components/notification'
import UsersActiveView from './users-active-container'
import UsersInactiveView from './users-inactive-container'
import UsersInOrganizationView from './users-in-organization-container'
import AuthenticationContext from 'components/authentication/authentication-context'
import { UserTypeEnum } from 'api/tillit.api-client'


const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
  width: 100vw;
`
type ITabPanelProps = Readonly<{
  index: number
  value: number
}>

const UsersPageView : FC = () => {
  const f = useFormatMessage()
  const { user } = AuthenticationContext.useAuthenticatedState()
  const [mainTabValue, setMainTabValue] = React.useState(0)

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setMainTabValue(newValue)
  }

  const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {children}
      </Typography>
    )
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <NotificationContainer>
      <Page>
        <PageHeader
          overline={f('USERS_PAGE_VIEW_HEAD_OVERLINE')}
          title={f('USERS_PAGE_VIEW_HEAD_TITLE')}
          description={f('USERS_PAGE_VIEW_HEAD_DESCRIPTION')}
        />

        <Box display="flex" mx={-5}>
          <>
            <Box overflow="hidden">
              <Tabs indicatorColor="primary" textColor="primary" value={mainTabValue} onChange={handleChange} centered={true}>
                <Tab label={f('USERS_PAGE_VIEW_TAB_ACTIVE_USERS')} {...a11yProps(0)} />   
                <Tab label={f('USERS_PAGE_VIEW_TAB_INACTIVE_USERS')} {...a11yProps(1)} />
                {user.userType !== UserTypeEnum.OrganizationAdmin && (
                <Tab label={f('USERS_PAGE_VIEW_TAB_ORGANIZATION_USERS')} {...a11yProps(2)} />
                )}
              </Tabs>

              <TabPanel value={mainTabValue} index={0}>
                {mainTabValue === 0 && (
                  <UsersActiveView />
                )}
              </TabPanel>
              <TabPanel value={mainTabValue} index={1}>
                {mainTabValue === 1 && (
                  <UsersInactiveView />
                )}
              </TabPanel>
              <TabPanel value={mainTabValue} index={2}>
                {mainTabValue === 2 && (
                  <UsersInOrganizationView />
                )}
              </TabPanel>
            </Box>
          </>
        </Box>
      </Page>
    </NotificationContainer>
  )
}

export default UsersPageView
