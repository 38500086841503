import React from 'react'
import { createContext } from 'utilities/create-context'
import isEmpty from 'lodash/isEmpty'
import { useUserApi } from 'api'
import { PageResultOfUserListItem, UserListItem, RoleEnum, UserAreaItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import usePromiseApi from 'utilities/use-promise'
import { getVisibleAreaData } from 'utilities/area-helper'
import { rootUser } from 'utilities/use-user-areas'
import useAuthorization from 'utilities/use-authorization'

const useUsersPageContext = () => {
  const { list: listUser, remove: removeUser, activate, deactivate, abort, isValidToActivate, listAllPersonnelOnOtherAreas, getDbUserAreas, removeUserAreasOnUserPrincipalHasAccessTo } = useUserApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const [data, setData] = React.useState<PageResultOfUserListItem | null>(null)
  const [organizationUsersData, setOrganizationUsersData] = React.useState<PageResultOfUserListItem | null>(null)
  const [activeUsers, setActiveUsers] = React.useState<UserListItem[] | null>(null)
  const [inactiveUsers, setInactiveUsers] = React.useState<UserListItem[] | null>(null)
  const [organizationUsers, setOrganizationUsers] = React.useState<UserListItem[] | null>(null)
  const { getAdminAreas, hasRoleOnAnyArea } = useAuthorization()

  const fetchUsersAsync = React.useCallback(async () => {
    await fetchAsync(listUser(1, 999999999, currentUser.organizationId || null)).then(pageResult => {
      if (pageResult) {
        if(!rootUser(currentUser)){
          const isPersonnel: boolean = hasRoleOnAnyArea(RoleEnum.Caretaker) && !hasRoleOnAnyArea(RoleEnum.Planner, RoleEnum.Admin)
          if (!isPersonnel) {
            const adminAreas = getAdminAreas([RoleEnum.Planner])
            pageResult.items = pageResult.items.filter(item => item.userAreas?.some(userArea => adminAreas?.some(adminArea => adminArea.areaId === userArea.areaId)))
            
          }
        }
        const visibleArea = getVisibleAreaData().map(area=>area.areaId)
        if(!isEmpty(visibleArea)){
          pageResult.items = pageResult.items.filter(item=>item.userAreas?.some(userArea=>visibleArea.includes(userArea.areaId)))
        }
        setActiveUsers(pageResult.items.filter(u => u.active))
        setInactiveUsers(pageResult.items.filter(u => !u.active))

        setData(pageResult)
      }
    })
  }, [currentUser.organizationId, fetchAsync, listUser])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  // Fetch user info if user opens web app while already authenticated
  // (a token is present in local storage)
  React.useEffect(() => {
    fetchUsersAsync()
  }, [fetchUsersAsync])

  const fetchOrganizationUsersAsync = React.useCallback(async () => {
    await fetchAsync(listAllPersonnelOnOtherAreas(1, 999999999, currentUser.organizationId || null)).then(pageResult => {
      if (pageResult) {
        const visibleArea = getVisibleAreaData().map(area=>area.areaId)
        if(!isEmpty(visibleArea)){
          pageResult.items = pageResult.items.filter(item=>item.userAreas?.some(userArea=>visibleArea.includes(userArea.areaId)))
        }
        setOrganizationUsers(pageResult.items)
        setOrganizationUsersData(pageResult)
      }
    })
  }, [currentUser.organizationId, fetchAsync, listAllPersonnelOnOtherAreas])

  const [userToRemove, setUserToRemove] = React.useState<UserListItem | null>(null)
  const removeDialogOpen = React.useMemo(() => userToRemove !== null, [userToRemove])
  const [selectedUserUserAreasOutsidePrincipalAccess, setSelectedUserUserAreasOutsidePrincipalAccess] = React.useState<UserAreaItem[] | null>(null)
  const [userHasAccessToUserAreaPrincipalDoesNot, setUserHasAccessToUserAreaPrincipalDoesNot] = React.useState<boolean>(false)

  const openRemoveDialog = React.useCallback((user: UserListItem) => setUserToRemove(user), [setUserToRemove])
  const closeRemoveDialog = React.useCallback(() => setUserToRemove(null), [setUserToRemove])
  const remove = React.useCallback(
    userId =>
      fetchAsync(
        removeUser(userId)
          .then(fetchUsersAsync)
          .then(closeRemoveDialog)
      ),
    [fetchAsync, removeUser, fetchUsersAsync, closeRemoveDialog]
  )
  const isRemoving = stateOfFetch.isLoading

  const [userToActivate, setUserToActivate] = React.useState<UserListItem | null>(null)
  const [userInvalidToActivate, setUserInvalidToActivate] = React.useState<UserListItem | null>(null)
  const closeInvalidToActivateDialog = React.useCallback(() => setUserInvalidToActivate(null), [setUserInvalidToActivate])
  const activateUserDialogOpen = React.useMemo(() => userToActivate !== null, [userToActivate])

  const openActivateUserDialog = React.useCallback(
    (user: UserListItem) => {
      void fetchAsync(
        isValidToActivate(user.id).then(result => {
          if (result) {
            setUserToActivate(user)
          } else {
            setUserInvalidToActivate(user)
          }
        })
      )
    },
    [fetchAsync, setUserToActivate, isValidToActivate]
  )

  const closeActivateUserDialog = React.useCallback(() => setUserToActivate(null), [setUserToActivate])
  const activateUser = React.useCallback(
    userId =>
      fetchAsync(
        activate(userId)
          .then(fetchUsersAsync)
          .then(fetchOrganizationUsersAsync)
          .then(closeActivateUserDialog)
      ),
    [fetchAsync, activate, fetchUsersAsync, closeActivateUserDialog, fetchOrganizationUsersAsync]
  )

  const [userToDeactivate, setUserToDeactivate] = React.useState<UserListItem | null>(null)
  const deactivateUserDialogOpen = React.useMemo(() => userToDeactivate !== null, [userToDeactivate])
  const openDeactivateUserDialog = React.useCallback((user: UserListItem) => setUserToDeactivate(user), [setUserToDeactivate])
  const closeDeactivateUserDialog = React.useCallback(() => setUserToDeactivate(null), [setUserToDeactivate])

  const openRemoveUserAreasDialog = React.useCallback((userId: number) => removeUserAreasOnUserPrincipalHasAccessTo(userId), [removeUserAreasOnUserPrincipalHasAccessTo])
  const closeRemoveUserAreasDialog = React.useCallback(() => {
    setSelectedUserUserAreasOutsidePrincipalAccess(null)
    setUserHasAccessToUserAreaPrincipalDoesNot(false)
    closeDeactivateUserDialog()
  },
  [setSelectedUserUserAreasOutsidePrincipalAccess, closeDeactivateUserDialog, setUserHasAccessToUserAreaPrincipalDoesNot])

  const deactivateUserAreasPrincipalHasAccessTo = React.useCallback(
    (userId: number) => {
      removeUserAreasOnUserPrincipalHasAccessTo(userId)
      .then(fetchUsersAsync)
      .then(fetchOrganizationUsersAsync)
      .then(closeRemoveUserAreasDialog)
    }, [removeUserAreasOnUserPrincipalHasAccessTo, closeRemoveUserAreasDialog]
  )

  const deactivateUser = React.useCallback(
    async (userId: number) => {
      const userAreasPrincipalDoesNotHaveAccessTo = await fetchAsync(getDbUserAreas(userId))
      if(userAreasPrincipalDoesNotHaveAccessTo.length === 0) {
        fetchAsync(
          deactivate(userId)
            .then(fetchUsersAsync)
            .then(fetchOrganizationUsersAsync)
            .then(closeDeactivateUserDialog)
        )}
      else {
        setSelectedUserUserAreasOutsidePrincipalAccess(userAreasPrincipalDoesNotHaveAccessTo)
        setUserHasAccessToUserAreaPrincipalDoesNot(true)
      }  
      },
    [fetchAsync, deactivate, fetchUsersAsync, closeDeactivateUserDialog, fetchOrganizationUsersAsync]
  )
  

  //const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      data,
      removeDialogOpen,
      userToRemove,
      isRemoving,
      activeUsers,
      inactiveUsers,
      activateUserDialogOpen,
      userToActivate,
      deactivateUserDialogOpen,
      userToDeactivate,
      userInvalidToActivate,
      organizationUsers,
      organizationUsersData,
      selectedUserUserAreasOutsidePrincipalAccess,
      userHasAccessToUserAreaPrincipalDoesNot,
    },
    actions: {
      openRemoveDialog,
      closeRemoveDialog,
      remove,
      openActivateUserDialog,
      closeActivateUserDialog,
      activateUser,
      openDeactivateUserDialog,
      closeDeactivateUserDialog,
      deactivateUser,
      closeInvalidToActivateDialog,
      fetchUsersAsync,
      fetchOrganizationUsersAsync,
      openRemoveUserAreasDialog,
      closeRemoveUserAreasDialog,
      deactivateUserAreasPrincipalHasAccessTo
    },
  }
}

export const UsersPageContext = createContext(useUsersPageContext)
