import React from 'react'
import { Typography } from '@mui/material'
import { ReportFieldItem } from 'api/tenilo.reporting.api-client'
import { FillAndTextColor, fillAndTextColorFromAlpha } from 'pages/reports/components/report-table/utilities/report-helper'
import Authentication from 'components/authentication'
import ReportTableLinkColumn from './components'

type Props = Readonly<{
  dataField: string
  alphaChannelField: string
  reportField: ReportFieldItem
  metaData: string[] | undefined
}>

const ReportTableColumn: React.FC<Props> = ({ dataField, alphaChannelField, reportField, metaData }) => {
  const { user } = Authentication.useAuthenticatedState()
  if (!reportField || reportField.isMeta) {
    return null
  }
  if (reportField.useDelegations && !user.useDelegations) { return null }
  if (reportField.useDrugLists && !user.useDrugLists) { return null }
  if (reportField.useWorkSchedule && !user.useWorkSchedule) { return null }

  if (reportField.isLink) {
    return (
      <ReportTableLinkColumn reportField={reportField} metaData={metaData} />
    )
  }

  const alphaChannel = isNaN(+alphaChannelField) ? 0 : +alphaChannelField

  const ftColor = fillAndTextColorFromAlpha(alphaChannel) as FillAndTextColor
  return (
    <Typography data-matomo-mask key={reportField.fieldIndex} variant="caption" sx={{ whiteSpace: 'nowrap', backgroundColor: ftColor.fillColor, color: ftColor.textColor }}>{dataField}</Typography>
  )
}

export default React.memo(ReportTableColumn)
