import React from 'react'
import { Link, Typography } from '@mui/material'
import { LinkTypeEnum, ReportFieldItem } from 'api/tenilo.reporting.api-client'
import { useNavigate } from "react-router-dom";
import format from 'utilities/format'
import PageRoute from 'config/page-routes'
import { useFormatMessage } from 'localization'
import { Colors } from 'styles/colors'
import Color from 'color'

type Props = Readonly<{
  reportField: ReportFieldItem
  metaData: string[] | undefined
}>

const ReportTableLinkColumn: React.FC<Props> = ({ reportField, metaData }) => {
  const f = useFormatMessage()
  const navigate = useNavigate()

  const linkToCustomerPage = React.useCallback(() => {
    if (reportField.linkTypeId !== LinkTypeEnum.Customer || !metaData) { return }
    const path =
      PageRoute.ShowCustomer.path
        .replace(':customerId', metaData[0])
    navigate(path)
  }, [metaData, reportField])

  const linkToPlanningPage = React.useCallback(() => {
    if (reportField.linkTypeId !== LinkTypeEnum.Planning || !metaData) { return }
    const path =
      PageRoute.Planning.path
        .replace(':areaId', metaData[0])
        .replace(':date', format(new Date(metaData[1]), 'P'))
        .replace(':type', 'areas')
        .replace(':usingCalendarFromDevice', 'false')
    navigate(path)
  }, [metaData, reportField])

  const linkToUserPage = React.useCallback(() => {
    if (reportField.linkTypeId !== LinkTypeEnum.User || !metaData) { return }
    const path =
      PageRoute.Users.path
        .replace(':userId', metaData[0])
    navigate(path)
  }, [metaData, reportField])

  const linkToUserDelegationPage = React.useCallback(() => {
    if (reportField.linkTypeId !== LinkTypeEnum.UserDelegation || !metaData) { return }
    const path =
      PageRoute.Planning.path
        .replace(':userId', metaData[0])
        .replace(':userDelegationId', metaData[1])
    navigate(path)
  }, [metaData, reportField])

  const linkToSigningModal = React.useCallback(() => {
    if (reportField.linkTypeId !== LinkTypeEnum.SigningModal || !metaData) { return }
    alert ("Open signing modal for planned activity id " + metaData[0])
  }, [metaData, reportField])

  if (reportField.linkTypeId === LinkTypeEnum.Planning) {
    return (
      <Link component="button" variant="button" underline="none" color="primary" onClick={linkToPlanningPage} sx={{ border: `1px solid ${Color(Colors.AthensGray).lighten(0.01).string()}` }}>
        <Typography key={reportField.fieldIndex} variant="caption" fontWeight={600}>{f('BASIC_PLANNING_LINK_TEXT')}</Typography>
      </Link>
    )
  }

  if (reportField.linkTypeId === LinkTypeEnum.Customer) {
    return (
      <Link component="button" variant="button" underline="none" color="primary" onClick={linkToCustomerPage} sx={{ border: `1px solid ${Color(Colors.AthensGray).lighten(0.01).string()}` }}>
        <Typography key={reportField.fieldIndex} variant="caption" fontWeight={600}>{f('BASIC_CUSTOMER_LINK_TEXT')}</Typography>
      </Link>
    )
  }

  if (reportField.linkTypeId === LinkTypeEnum.User) {
    return (
      <Link component="button" variant="button" underline="none" color="primary" onClick={linkToUserPage} sx={{ border: `1px solid ${Color(Colors.AthensGray).lighten(0.01).string()}` }}>
        <Typography key={reportField.fieldIndex} variant="caption" fontWeight={600}>{f('BASIC_USER_LINK_TEXT')}</Typography>
      </Link>
    )
  }

  if (reportField.linkTypeId === LinkTypeEnum.UserDelegation) {
    return (
      <Link component="button" variant="button" underline="none" color="primary" onClick={linkToUserDelegationPage} sx={{ border: `1px solid ${Color(Colors.AthensGray).lighten(0.01).string()}` }}>
        <Typography key={reportField.fieldIndex} variant="caption" fontWeight={600}>{f('BASIC_USERDELEGATION_LINK_TEXT')}</Typography>
      </Link>
    )
  }

  if (reportField.linkTypeId === LinkTypeEnum.SigningModal) {
    return (
      <Link component="button" variant="button" underline="none" color="primary" onClick={linkToSigningModal} sx={{ border: `1px solid ${Color(Colors.AthensGray).lighten(0.01).string()}` }}>
        <Typography key={reportField.fieldIndex} variant="caption" fontWeight={600}>{f('BASIC_SIGNINGMODAL_LINK_TEXT')}</Typography>
      </Link>
    )
  }

  return (
    <Typography key={reportField.fieldIndex} variant="caption">{reportField.linkTypeId} {metaData?.join(',')}</Typography>
  )
}

export default React.memo(ReportTableLinkColumn)
