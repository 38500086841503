import React from 'react'
import * as yup from 'yup'

import { GrandIdParameterEnum, IOrganizationGrandIdSettingItem, OrganizationGrandIdSettingPersistItem } from 'api/tenilo.support.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { GrandIdServiceEnum } from 'api/tillit.api-client'
import { GrandIdSettingModalContext } from '../grandid-setting-modal-context'
import { SupportOrganizationModalContext } from '../../../support-organization-modal-context'

type OrganizationGrandIdSettingForm = Readonly<{
  organizationId: number
  grandIdServiceId: GrandIdServiceEnum | undefined
  grandIdParameterId: GrandIdParameterEnum | undefined
  parameterValue: string
}>

const mapOrganizationGrandIdSettingFormToOrganizationGrandIdSettingItem = (
  initialItem: IOrganizationGrandIdSettingItem | undefined,
  item: OrganizationGrandIdSettingForm
): OrganizationGrandIdSettingPersistItem => {
  return new OrganizationGrandIdSettingPersistItem({
    id: initialItem ? initialItem.id : undefined,
    organizationId: item.organizationId,
    grandIdServiceId: item.grandIdServiceId ? item.grandIdServiceId : GrandIdServiceEnum.Saml,
    grandIdParameterId: item.grandIdParameterId ? item.grandIdParameterId : GrandIdParameterEnum.ApiKey,
    parameterValue: item.parameterValue,
  })
}

const mapOrganizationGrandIdSettingItemToOrganizationGrandIdSettingForm = (item: IOrganizationGrandIdSettingItem): OrganizationGrandIdSettingForm => {
  const mappedItem: OrganizationGrandIdSettingForm = {
    organizationId: item.organizationId,
    grandIdServiceId: item.grandIdServiceId,
    grandIdParameterId: item.grandIdParameterId,
    parameterValue: item.parameterValue,
  }
  return mappedItem
}

const useGrandIdSettingFormData = (initialOrganizationGrandIdSetting?: IOrganizationGrandIdSettingItem) => {
  const f = useFormatMessage()

  const { persistOrganizationGrandIdSettingAsync } = GrandIdSettingModalContext.useActions()
  const { refreshOrganizationModal } = SupportOrganizationModalContext.useActions()
  const { selectedOrganization } = SupportOrganizationModalContext.useState()
  const organizationGrandIdSetting = initialOrganizationGrandIdSetting

  const initialValues = React.useMemo<OrganizationGrandIdSettingForm>(
    () =>
      !!organizationGrandIdSetting
        ? mapOrganizationGrandIdSettingItemToOrganizationGrandIdSettingForm(organizationGrandIdSetting)
        : {
            organizationId: selectedOrganization ? selectedOrganization.id : -1,
            grandIdServiceId: GrandIdServiceEnum.Saml,
            grandIdParameterId: GrandIdParameterEnum.ApiKey,
            parameterValue: '',
          },
    [organizationGrandIdSetting, selectedOrganization]
  )

  const onSubmit: OnSubmitFunction<OrganizationGrandIdSettingForm> = React.useCallback(
    async (values, { setSubmitting }) => {
      const persistItem = mapOrganizationGrandIdSettingFormToOrganizationGrandIdSettingItem(initialOrganizationGrandIdSetting, values)
      await persistOrganizationGrandIdSettingAsync(persistItem).finally(() => {
        setSubmitting(false)
        refreshOrganizationModal()
      })
    },
    [initialOrganizationGrandIdSetting, persistOrganizationGrandIdSettingAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      /*title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),
*/
      /*      description: yup.string().max(
              1024,
              f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
                name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
                maxsize: 1024,
              })
            ),*/
    })
  }, [f])

  const name: Name<OrganizationGrandIdSettingForm> = React.useMemo(
    () => ({
      organizationId: 'organizationId',
      grandIdServiceId: 'grandIdServiceId',
      grandIdParameterId: 'grandIdParameterId',
      parameterValue: 'parameterValue',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useGrandIdSettingFormData
