import React from 'react'
import { ReportFilterSelection, ReportItem, ReportDataItem, ReportListItem, UserReportItem, ReportSelectionsListItem, ReportSelectionItem, ReportSelectionListItemValue, UserReportCreateItem, UserReportUpdateItem, ReportFieldItem } from 'api/tenilo.reporting.api-client'
import { createContext } from 'utilities/create-context'
import Authentication from 'components/authentication'
import usePromiseApi from 'utilities/use-promise'
import useReportingApi from 'api/reporting-api'

const useReportsPageContext = () => {
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const [reportList, setReportList] = React.useState<ReportListItem[]>()
  const [report, setReport] = React.useState<ReportItem | undefined>(undefined)
  const [reportData, setReportData] = React.useState<ReportDataItem | undefined>(undefined)
  const [sortedFieldItems, setSortedFieldItems] = React.useState<ReportFieldItem[]>()
  const [reportSelectionListItem, setReportSelectionListItem] = React.useState<ReportSelectionsListItem>()
  const [userReport, setUserReport] = React.useState<UserReportItem | undefined>(undefined)
  const { abort, listReports, getReport, getReportData, getUserReport, getReportSelectionListItem, removeUserReport, createUserReport, updateUserReport } = useReportingApi()

  const getReportList = React.useCallback(() => {
    if (!currentUser?.organizationId) { throw new Error("No organizationId on user") }
    return fetchAsync(listReports(currentUser.organizationId))
      .then(reports => {
        if (reports) {
          setReportList(reports)
        }
        return reports
      })
      .catch(err => { throw err })
  }, [currentUser])

  const handleRemoveUserReport = React.useCallback(async (id) => {
    if (!currentUser?.organizationId) { return }
    return fetchAsync(removeUserReport(id))
      .then(res => {
        if (res) {
          getReportList()
        }
      })
      .catch(err => { console.error(err) })
  }, [currentUser])

  const handleCreateUserReport = React.useCallback(async (item: UserReportCreateItem): Promise<UserReportItem> => {
    if (!currentUser?.organizationId) {
      throw new Error("No organizationId on user")
    }
    if (item.description === undefined) {
      item.description = ""
    }
    return fetchAsync(createUserReport(item))
      .then(res => res)
      .catch(err => { throw err })
  }, [currentUser])

  const handleUpdateUserReport = React.useCallback(async (item: UserReportUpdateItem): Promise<UserReportItem> => {
    if (!currentUser?.organizationId) {
      throw new Error("No organizationId on user")
    }
    if (item.reportDescription === undefined) {
      item.reportDescription = ""
    }
    return fetchAsync(updateUserReport(item))
    .then(res => res)
    .catch(err => { throw err })
  }, [currentUser])

  const fetchReport = React.useCallback((reportId: number) => {
    setReportData(undefined)
    return fetchAsync(getReport(reportId))
      .then(result => {
        setReport(result)
        setUserReport(undefined)
      })
      .catch(err => { throw err })
  }, [])

  React.useEffect(() => {
    const id = report?.id || userReport?.parentReport.id
    if (id) {
      fetchAsync(getReportSelectionListItem(id))
        .then(res => {
          setReportSelectionListItem(res)
        })
    }
  }, [report, userReport])

  const fetchUserReport = React.useCallback((userReportId: number) => {
    setReportData(undefined)
    return fetchAsync(getUserReport(userReportId))
      .then(result => {
        setReport(undefined)
        setUserReport(result)
      })
      .catch(err => { throw err })
  }, [])

  const fetchReportData = React.useCallback((reportId: number, filterSelections: ReportFilterSelection[]) => {
    return fetchAsync(getReportData(reportId, filterSelections))
      .then(result => {
        setReportData(result)
      })
      .catch(err => { throw err })
  }, [])

  React.useEffect(() => {
    getReportList()?.catch(err => console.error(err))
  }, [])

  React.useEffect(() => () => abort(), [abort])

  return {
    state: {
      currentUser,
      report,
      reportData,
      reportList,
      userReport,
      reportSelectionListItem,
      stateOfFetch,
      sortedFieldItems
    },
    actions: {
      fetchReport,
      fetchUserReport,
      handleRemoveUserReport,
      setReport,
      setReportData,
      setUserReport,
      handleCreateUserReport,
      fetchReportData,
      handleUpdateUserReport,
      getReportList,
      setSortedFieldItems
    },
  }
}

export default createContext(useReportsPageContext)
