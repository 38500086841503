import React from 'react'
import ActivateDeviceDialog from '../activate-device-dialog'
import { ActivateDeviceDialogContext } from '../activate-device-dialog/activate-device-dialog-context'
import DeviceSectionView from './device-section-view'

const DeviceSectionContainer: React.FC = () => {
  return (
    <ActivateDeviceDialogContext.Provider>
      <DeviceSectionView />
      <ActivateDeviceDialog />
    </ActivateDeviceDialogContext.Provider>
  )
}

export default DeviceSectionContainer
