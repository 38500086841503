import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import EllipsisTypographyStyle from 'components/ellipsis-typography'
import Color from 'color'
import React from 'react'

type Props = Readonly<
  {
    title: string
    color: string | undefined
    isActive: boolean
  } & BoxProps
>

const DailyActivityGroupItemContainer: React.FC<Props> = ({ title, color, isActive, ...rest }) => {
  const textColor = !isActive || Color(color).contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'

  return (
    <Box {...rest}>
      <Box className="left-pane">
        <EllipsisTypographyStyle
          data-matomo-mask
          variant={'subtitle2'}
          sx={{
            color: textColor,
            width:'100%',
            whiteSpace: 'nowrap'
          }}>
            {title}
        </EllipsisTypographyStyle>
      </Box>
      <Box className={'right-pane'} />
    </Box>
  )
}

export default DailyActivityGroupItemContainer
