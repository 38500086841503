import { useOrganizationApi } from 'api'
import { IOrganizationListItem, UserTypeEnum } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

import {
  useListingModal,
  useSingleActivityModal,
} from 'components/single-activity-modal/utilities/'

import { KioskItem } from 'api/tillit.api-client'
import { useKioskApi } from 'api'
import { getDeviceGuid } from 'api/device-guid'

const useProfilePageContext = () => {
  const { getOrganizations } = useOrganizationApi()
  const [forcedReload, setForceReload] = React.useState<boolean>(false)
  const [expiringDelegationsModalOpen, setExpiringDelegationsModalOpen] = React.useState<boolean>(false)
  const [organizationInternalInformationModalOpen, setOrganizationInternalInformationModalOpen] = React.useState<boolean>(false)
  const [manualsModalOpen, setManualsModalOpen] = React.useState<boolean>(false)
  const [userWorkScheduleReportModalOpen, setUserWorkScheduleReportModalOpen] = React.useState<boolean>(false)
  const [gapReportModalOpen, setGapReportModalOpen] = React.useState<boolean>(false)
  const [hasUseWorkSchedule, setHasUseWorkSchedule] = React.useState<boolean>(false)

  const toggleGapReportModalOpen = React.useCallback(()=>{
    setGapReportModalOpen((curr)=> !curr)
  },[])

  const [modal, setModal] = React.useState<boolean>(false)
  const [privacyPolicyModal, setPrivacyPolicyModal] = React.useState<boolean>(
    false
  )
  const [hangfireModal, setHangfireModal] = React.useState<boolean>(false)
  const { fetchAsync, state } = usePromiseApi()
  const { user } = Authentication.useAuthenticatedState()
  const [organizations, setOrganizations] = React.useState<
    IOrganizationListItem[]
  >([])

  const { getKioskByGuid } = useKioskApi()
  const [kiosk, setKiosk] = React.useState<KioskItem | undefined>(undefined)

  const singleActivityModal = useSingleActivityModal()
  const listingModal = useListingModal()
  const [hasUseDrugList, setHasUseDrugList] = React.useState<boolean>(false)

  const checkUserHasDrugList = React.useCallback(() => {
    if (user.useDrugLists) {
      setHasUseDrugList(true)
    }
    else {
      setHasUseDrugList(false)
    }
  }, [setHasUseDrugList, user])

  const checkUserHasUseWorKSchedule = React.useCallback(() => {
    if (user.useWorkSchedule) {
      setHasUseWorkSchedule(true)
    }
    else {
      setHasUseWorkSchedule(false)
    }
  }, [setHasUseWorkSchedule, user])

  React.useEffect(() => {
    checkUserHasDrugList()
    checkUserHasUseWorKSchedule()
  }, [checkUserHasDrugList, checkUserHasUseWorKSchedule])

  React.useEffect(() => {
    if (user.userType === UserTypeEnum.SystemAdmin) {
      fetchAsync(getOrganizations()).then(setOrganizations)
    }

    const deviceGuid: string | null = getDeviceGuid()
    if (deviceGuid) {
      fetchAsync(getKioskByGuid(deviceGuid))
        .then(setKiosk)
        .catch(error => {
          setKiosk(undefined)
        })
    }
  }, [fetchAsync, getOrganizations, user.userType, getKioskByGuid])

  return {
    state: {
      ...state,
      modal,
      user,
      organizations,
      singleActivityModal: singleActivityModal.state,
      listingModal: listingModal.state,
      privacyPolicyModal,
      hangfireModal,
      forcedReload,
      kiosk,
      expiringDelegationsModalOpen,
      hasUseDrugList,
      userWorkScheduleReportModalOpen,
      hasUseWorkSchedule,
      gapReportModalOpen,
      organizationInternalInformationModalOpen,
      manualsModalOpen
    },
    actions: {
      toggleModalSignedActivities: setModal,
      singleActivityModal: singleActivityModal.actions,
      listingModal: listingModal.actions,
      toggleModalPrivacyPolicy: setPrivacyPolicyModal,
      toggleModalHangfire: setHangfireModal,
      forceReload: setForceReload,
      setExpiringDelegationsModalOpen,
      setUserWorkScheduleReportModalOpen,
      toggleGapReportModalOpen,
      setOrganizationInternalInformationModalOpen,
      setManualsModalOpen
    },
  }
}

export const ProfilePageContext = createContext(useProfilePageContext)
