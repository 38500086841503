import { Typography } from '@mui/material'
import React from 'react'
import styled, { css } from 'styled-components'

interface IPageHeaderProps {
  overline?: string
  title?: string
  description?: string
  descriptionComponent?: React.ReactNode | undefined
  children?: React.ReactNode
}

const StyledPageBodyHeader = styled.div(({theme})=>css`
  width: 100%;
  margin-bottom: ${theme.spacing(5)};
  ${[theme.breakpoints.down('md')]}{
    margin-bottom: 0;
  }
`)

const PageHeader: React.FunctionComponent<IPageHeaderProps> = ({ overline, title, description, descriptionComponent, children }) => (
  <StyledPageBodyHeader>
    {overline && <Typography variant="overline">{overline}</Typography>}
    <Typography variant="h4">{title}</Typography>
    {description && (
      <Typography variant="body1" style={{ maxWidth: '40rem' }}>
        {description}
        <>{descriptionComponent}</>
      </Typography>
    )}
    {children}
  </StyledPageBodyHeader>
)

export default PageHeader
