import React from 'react'
import {
  IPlannedActivityRowSigningItem,
} from 'api/tillit.api-client'
import ModalSectionRow from 'components/modal-section-row'
import { InfoDelayedContainer } from '../info-delayed'

const DelaySignings: React.FC<{delaySignings: IPlannedActivityRowSigningItem[]}> = ({delaySignings}) => {
  let slash = ' / '
  return (
    <div style={{ maxHeight: '15vh', overflowY: 'scroll', paddingRight: '5px' }}>
      {delaySignings &&
        delaySignings.map((delay: IPlannedActivityRowSigningItem) => {
          return (
            <ModalSectionRow key={delay.id} responsive>
              <InfoDelayedContainer
                signedComment={delay.signingComment && delay.comment ? delay.signingComment + slash + delay.comment :
                  ((delay.signingComment || '') + (delay.comment || ''))}
                signedDate={delay.createdOn}
                signedUser={delay.createdBy}
                status={delay.plannedActivityStatusId}
              />
            </ModalSectionRow>
          )
        })}
      </div>
  )
}

export default React.memo(DelaySignings)
