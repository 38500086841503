import { DashboardKpiChartHeaderActionBar,
  DashboardKpiDetailsfooteractionBar } from 'pages/dashboard/components/dashboard-kpi/components/dashboard-kpi-action-bar'
  import { KeyPerformanceIndicatorType } from 'api/tenilo.dashboard.api-client'
import type { DashboardKpi } from './dashboard-types'

const dashboardKpiConfig: DashboardKpi[] = [
  {
    id: 512,
    name: 'Signing statuses Kpi',
    type: KeyPerformanceIndicatorType.PieChartWithDetails,
    HeaderActionBar: DashboardKpiChartHeaderActionBar,
    FooterActionBar: DashboardKpiDetailsfooteractionBar
  },
  {
    id: 515,
    name: 'Num delayed activities Kpi',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar
  },
  {
    id: 516,
    name: 'Num unsigned activities Kpi',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar
  },
  {
    id: 517,
    name: 'Planned activity time Kpi',
    type: KeyPerformanceIndicatorType.PieChartWithDetails,
    HeaderActionBar: DashboardKpiChartHeaderActionBar,
    FooterActionBar: DashboardKpiDetailsfooteractionBar
  },
  {
    id: 520,
    name: 'Future unplanned activities Kpi',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar
  },
  {
    id: 523,
    name: 'Efficiency Rate Kpi',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar,
    requiredFeatures: [
      'useWorkSchedule'
    ]
  },
  {
    id: 506,
    name: 'Delegations about to expire Kpi',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar,
    requiredFeatures: [
      'useDelegations'
    ]
  },
  {
    id: 677,
    name: 'Actvities about to end Kpi',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar
  },
  {
    id: 514,
    name: 'Activities where given amount deviates from expected amount',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar,
    requiredFeatures: [
      'useDrugLists'
    ]
  },
  {
    id: 720,
    name: 'Activities Signed Violating Delegation Requirement Kpi',
    type: KeyPerformanceIndicatorType.SingleValueWithDetails,
    FooterActionBar: DashboardKpiDetailsfooteractionBar,
    requiredFeatures: [
      'useDelegations', 'allowSigningWhenDelegationsIsNotFulfilled'
    ]
  },
]

export { dashboardKpiConfig }
