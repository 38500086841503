import { Box } from '@mui/material'
import { UserTypeEnum } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import Page from 'components/page'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'
import { RoleEnum } from 'api/tillit.api-client'
import useAuthorization from 'utilities/use-authorization'
import { rootUser } from 'utilities/use-user-areas'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Authorize } from 'components/authorization'
import { useTheme } from '@mui/material/styles';
import { useDistinctAreas } from 'utilities/use-user-areas'
import ChangePassword from './components/change-password'
import ContactSupport from './components/contact-support'
import Hangfire from './components/hangfire'
import OrganizationInternalInformationView from './components/organization-internal-information'
import { InvoiceForm } from './components/invoice-form/index'
import PrivacyPolicy from './components/privacy-policy'
import SwitchDeviceMode from './components/switch-device-mode'
import { ProfilePageContext } from './profile-page-context'
import SelectedVisibleAreas from './components/selected-visible-areas'
import LoggedInUser from './components/logged-in-user'
import { ManualsView } from './components/manuals'

const useStyles = makeStyles(theme => {
  return ({
    'tenilo-profile-page': {
      [theme.customBreakpoints.justMobileTablet]: {
        '& .tenilo-main': {
          padding: '10px',
          paddingTop:'60px',
          '&>div':{
            marginLeft:0,
            marginRight:0
          }
        },
        '& .tenilo-banner': {
          height: '60px !important',
        }
      }
    }
  }
  )
})


export const ProfilePageView: FC = () => {
  const { user } = Authentication.useAuthenticatedState()
  const { setVisibleAreas } = Authentication.useActions()
  const { getAdminAreas } = useAuthorization()
  const { kiosk } = ProfilePageContext.useState()
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.customBreakpoints.justMobileTablet)
  const { distinctAreas } = useDistinctAreas()

  const selectableVisibleAreas = React.useMemo(()=>{
    if(!rootUser(user)){
      const adminAreas = getAdminAreas([RoleEnum.Planner])
      return distinctAreas(adminAreas)
    }
    return user.userAreas
  },[user])

  /*React.useEffect(() => {
    const script = document.createElement('script');
    script.id = "CookieDeclaration"
    script.src = "https://consent.cookiebot.com/c5bd0618-a80b-4631-a89c-bfb30434be28/cd.js"
    script.type="text/javascript"
    script.async = true
    document.body.appendChild(script);
return () => {
      document.body.removeChild(script);
    }
  }, []);*/

  return (
    <Page className={classes["tenilo-profile-page"]}>
      <ChangePassword />
      <Authorize mainRoles={[UserTypeEnum.SystemAdmin]}>
        <InvoiceForm />
      </Authorize>
      <PrivacyPolicy />
      <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
        {
          has( user, 'userAreas' ) &&
          isArray( selectableVisibleAreas ) &&
          ( selectableVisibleAreas.length > 1 ) &&
          !smallScreen &&
          (
            <SelectedVisibleAreas userAreas={selectableVisibleAreas} setVisibleAreas={setVisibleAreas} />
          )
        }
      </Authorize>
      <Authorize mainRoles={[UserTypeEnum.SystemAdmin]}>
        {!smallScreen &&  <Hangfire />}
      </Authorize>
      <Authorize disallowedRoles={[RoleEnum.Admin]}>
        {kiosk && <SwitchDeviceMode />}
      </Authorize>
      <Authorize mainRoles={[UserTypeEnum.OrganizationAdmin, UserTypeEnum.TeniloSupport]}>
        {!smallScreen &&  <OrganizationInternalInformationView />}
      </Authorize>
      <ManualsView />
      <Box pt={10}>
        <ContactSupport />
      </Box>
      <Box pb={10}>
        <LoggedInUser user={user} />
      </Box>
    </Page>
  )
}
