import React from 'react'
import { useAreaApi } from 'api'
import { AreaForUserListItem, RoleEnum } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import useUserAreas from 'utilities/use-user-areas'
import { getVisibleAreaData } from 'utilities/area-helper'
import isEmpty from 'lodash/isEmpty'
import { rootUser } from 'utilities/use-user-areas'
import useAuthorization from 'utilities/use-authorization'
import { AreaModalContext } from 'components/area-modal/area-modal-context'

export type CustomerCreateModel = Readonly<{
  active: boolean
  firstName?: string
  lastName?: string
  description?: string
  areaId: number
}>

const useAreasPageContext = () => {
  const { getAllAreasForUser: listAreasForUser, abort } = useAreaApi()
  const { areaId } = AreaModalContext.useState()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const [data, setData] = React.useState<AreaForUserListItem[] | null>(null)
  const [modalOpen, setModalOpen] = React.useState(false)
  const { getAdminAreas } = useAuthorization()

  const areas = useUserAreas()

  const fetchAreasForUserAsync = React.useCallback(async () => {
    await fetchAsync(listAreasForUser(currentUser.id)).then((res)=>{
      if(!rootUser(currentUser)){
        const adminAreas = getAdminAreas([RoleEnum.Planner])
        res = res.filter(area => adminAreas?.some(adminArea => adminArea.areaId === area.id))
      }
      const visibleArea = getVisibleAreaData().map(area=>area.areaId)
      if(!isEmpty(visibleArea)){
        res = res.filter(area=>visibleArea.includes(area.id))
      }
      setData(res)
    })
  }, [fetchAsync, listAreasForUser, currentUser.id])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => abort(), [])

  // Fetch user info if user opens web app while already authenticated
  // (a token is present in local storage)
  React.useEffect(() => {
    if(areaId && data) {return}
    fetchAreasForUserAsync()
  }, [areaId])

  return {
    state: {
      ...stateOfFetch,
      data,
      modalOpen,
      areas
    },
    actions: {
      openModal,
      closeModal
    },
  }
}

export const AreasPageContext = createContext(useAreasPageContext)
