import React from 'react'
import { useFormatMessage } from 'localization'
import { UserListItem, UserTypeEnum } from 'api/tenilo.support.api-client'
import { uniqueAndSortedUserAreas } from 'utilities/user-helper'
import { isSearchValueValid } from './filter-helper'

export const filterUsers = (f: ReturnType<typeof useFormatMessage>) => (searchValue: string, items: UserListItem[]): UserListItem[] => {
  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return items
  }

  const filtered = items?.filter(item => {
    const searchText = `${item.fullName} ${item.email} ${item.organizationName} ${item.phoneNumber} ${uniqueAndSortedUserAreas(item.userAreas || [])} ${
      item.userTypeId === UserTypeEnum.OrganizationAdmin ? f('SUPPORT_USER_MODAL_DIALOG_TYPE_ORGADMIN') : ''
    }`
    return isSearchValueValid(searchValue, searchText)
  })

  if (filtered) {
    return filtered
  } else {
    return [] as UserListItem[]
  }
}
