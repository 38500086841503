import React from 'react'
import {
    PlanningComplianceClient,
    PlanningComplianceItem,
    NonCompliantRouteItem,
    NonCompliantRouteTimesItem,
} from 'api/tenilo.workschedule.api-client'
import { WORKSCHEDULE_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class PlanningComplianceApi {
    private readonly abortController = new AbortController()
    private readonly client: PlanningComplianceClient

    public constructor() {
        this.client = new PlanningComplianceClient(WORKSCHEDULE_API_URL, httpMiddleware({ signal: this.abortController.signal }))
    }

    public abort = () => this.abortController.abort()

    public listNonComplianceForAreaAndCalendarDate = (areaId: number, start: Date, end: Date): Promise<PlanningComplianceItem[]> =>
        this.client
            .listNonComplianceForAreaAndCalendarDate(areaId, start, end)
            .then(result =>
                result === null
                    ? Promise.reject(`No planning compliance received from backend for area id ${areaId}, start ${start}, end ${end},.`)
                    : Promise.resolve(result)
            )

    public listNonComplianceForPlannedRoute = (plannedRouteId: number): Promise<PlanningComplianceItem[]> =>
        this.client
            .listNonComplianceForPlannedRoute(plannedRouteId)
            .then(result =>
                result === null
                    ? Promise.reject(`No planning compliance received from backend for planned route id ${plannedRouteId}.`)
                    : Promise.resolve(result)
            )

    public listNonComplianceForPlannedRouteAndUser = (plannedRouteId: number, userId: number): Promise<PlanningComplianceItem[]> =>
        this.client
            .listNonComplianceForPlannedRouteAndUser(plannedRouteId, userId)
            .then(result =>
                result === null
                    ? Promise.reject(`No planning compliance received from backend for planned route id ${plannedRouteId}, user id ${userId}.`)
                    : Promise.resolve(result)
            )

    public listNonCompliantRoutesForPlannedUserWorkSchedule = (plannedUserWorkScheduleId: number): Promise<NonCompliantRouteItem[]> =>
        this.client
            .listNonCompliantRoutesForPlannedUserWorkSchedule(plannedUserWorkScheduleId)
            .then(result =>
                result === null
                    ? Promise.reject(`No non compliant routes for planned user workschedule id ${plannedUserWorkScheduleId}.`)
                    : Promise.resolve(result)
            )

    public listNonCompliantRoutesAndTimesForPlannedActivity = (plannedActivityRowId: number): Promise<NonCompliantRouteTimesItem[]> =>
        this.client
            .listNonCompliantRoutesAndTimesForPlannedActivity(plannedActivityRowId)
            .then(result =>
                result === null
                    ? Promise.reject(`No non compliant routes and times for planned activity row id ${plannedActivityRowId}.`)
                    : Promise.resolve(result)
            )

}

const usePlanningComplianceApi = () => React.useMemo(() => new PlanningComplianceApi(), [])

export default usePlanningComplianceApi
