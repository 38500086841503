import React from 'react'
import { Container } from '@mui/material'
import { Colors } from 'styles/colors'
import { dashboardSectionConfig } from 'pages/dashboard/config'
import { DashboardSectionView } from './dashboard-section-view'

const DashboardSectionContainer: React.FC = () => {

  return (
    <Container maxWidth={false} sx={{
      display:'flex',
      height:'calc(100% - 120px)',
      position:'relative',
      paddingLeft:'0px !important',
      paddingRight:'0px !important',
      width:'100%',
      marginRight:0,
      borderTop:`1px solid ${Colors.AthensGray}`

    }}>
      {dashboardSectionConfig.map(section => <DashboardSectionView key={section.id} {...section}/>)}
    </Container>
  )
}

export default React.memo(DashboardSectionContainer)
