import { Box, Checkbox } from '@mui/material'
import CenterModal from 'components/center-modal'
import { SendEmailToUsersItem } from 'api/tillit.api-client'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { SendEmailToUsersModalContext } from './send-email-to-users-modal-context'
import useFormData from './utilities/send-email-to-users-form-data'


const SendEmailToUsersModalContainer: React.FC = () => {
  const f = useFormatMessage()

  const { modalOpen, selectedUserIds, activeUsers } = SendEmailToUsersModalContext.useState()

  const { initialValues, onSubmit } = useFormData(selectedUserIds)

  const { closeSendEmailToUsersModal } = SendEmailToUsersModalContext.useActions()

  const [pageSize, setPageSize] = React.useState<number>(50)

  const selectedUsers = React.useMemo(() => {
    return activeUsers?.filter(u => selectedUserIds.includes(u.id)).map(u => {
      return {
        id: u.id,
        name: u.fullName,
        sendWelcomeEmail: false,
        sendResetPassword: false,
        sendToPrincipal: false
      }
    })
  },[selectedUserIds])

  return (
    <CenterModal open={modalOpen} onClose={closeSendEmailToUsersModal}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, errors, dirty, ...rest }) => {
          const handleConfirmChange = (id:number, actionType:number) => (e,checked:boolean) => {
            switch (actionType) {
              case 1:
                rest.setValues(values.map(val => {
                  if(val.userId === id){
                    return new SendEmailToUsersItem({
                      ...val,
                      sendWelcomeEmail:checked
                    })
                  }
                  return val
                }))
                break;
              case 2:
                rest.setValues(values.map(val => {
                  if(val.userId === id){
                    return new SendEmailToUsersItem({
                      ...val,
                      sendResetPassword:checked
                    })
                  }
                  return val
                }))
                break;
              case 3:
                rest.setValues(values.map(val => {
                  if(val.userId === id){
                    return new SendEmailToUsersItem({
                      ...val,
                      sendToPrincipal:checked
                    })
                  }
                  return val
                }))
                break;

              default:
                break;
            }
          }

          return (
            <Form translate="yes">
              <ModalHeader/>
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '60vh',
                    minWidth: '50vw',
                    overflow: 'auto',
                  }}>

                  <DataGrid
                    sx={{
                      minHeight: '50vh',
                      maxHeight: '50vh',
                      '.MuiDataGrid-cell': { borderBottom: '0px' }
                    }}
                    rows={selectedUsers as any}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableDensitySelector
                    disableExtendRowFullWidth
                    disableVirtualization
                    components={{ Toolbar: CustomToolbar }}
                    localeText={muiDataGridLocale(f)()}

                    columns={[
                      {
                        flex: 2,
                        headerName: f('USERS_PAGE_VIEW_EMAIL_DIALOG_USER_TITLE'),
                        field: 'name',
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <span data-matomo-mask>
                              {params.row.organizationParentsName ? `${params.row.name} (${params.row.organizationParentsName})` : params.row.name}
                            </span>
                          )
                        },
                      },
                      {
                        flex: 1,
                        headerName: f('USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_WELCOME_EMAIL_TITLE'),
                        field: 'sendWelcomeEmail',
                        renderCell: (params) => (
                          <Checkbox
                            checked={values.find(val => val.userId === params.row.id)?.sendWelcomeEmail}
                            onChange={handleConfirmChange(params.row.id,1)}
                          />
                        )
                      },
                      {
                        flex: 1,
                        headerName: f('USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_RESET_PASSOWRD_TITLE'),
                        field: 'sendResetPassword',
                        renderCell: (params) => (
                          <Checkbox
                            checked={values.find(val => val.userId === params.id)?.sendResetPassword}
                            onChange={handleConfirmChange(params.row.id,2)}
                          />
                        )
                      },
                      {
                        flex: 1,
                        headerName: f('USERS_PAGE_VIEW_EMAIL_DIALOG_SEND_PRINCIPAL_TITLE'),
                        field: 'sendToPrincipal',
                        renderCell: (params) => (
                          <Checkbox
                            checked={values.find(val => val.userId === params.id)?.sendToPrincipal}
                            onChange={handleConfirmChange(params.row.id,3)}
                          />
                        )
                      },
                    ]}

                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                  />
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeSendEmailToUsersModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {f('BASIC_BUTTON_SEND_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </CenterModal>
  )
}

export default SendEmailToUsersModalContainer
