import React from 'react'

import {
  DelegationClient,
  DelegationCreateItem,
  DelegationItem,
  DelegationListItem,
  DelegationUpdateItem,
  IDelegationCreateItem,
  IDelegationUpdateItem,
  DelegationCategoryListItem,
  DelegationCategoryCreateItem,
  IDelegationCategoryCreateItem,
  DelegationCategoryItem,
  DelegationCategoryUpdateItem,
  IDelegationCategoryUpdateItem,
  DelegationAvailableItem,
  UserDelegationAvailablePlacementsItem,
  ExpiringDelegationItem,
  PlannedActivityRequiredDelegationsItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class DelegationApi {
  private readonly abortController = new AbortController()
  private readonly client: DelegationClient

  constructor() {
    this.client = new DelegationClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getDelegation = (delegationid: number): Promise<DelegationItem> =>
    this.client
      .getDelegation(delegationid)
      .then(delegationItem =>
        delegationItem === null
          ? Promise.reject(`No delegation item received from backend for delegation id ${delegationid}.`)
          : Promise.resolve(delegationItem)
      )

  public getDelegationCategory = (delegationcategoryId: number): Promise<DelegationCategoryItem> =>
    this.client
      .getSingleDelegationCategory(delegationcategoryId)
      .then(item =>
        item === null
          ? Promise.reject(`No delegation inheritance item received from backend for delegation category id ${delegationcategoryId}.`)
          : Promise.resolve(item)
      )

  public listOrganizationDelegations = (organizationId: number): Promise<DelegationListItem[]> =>
    this.client
      .listOrganizationDelegations(organizationId)
      .then(delegationItems =>
        delegationItems === null
          ? Promise.reject(`No delegation items received from backend for organization id ${organizationId}.`)
          : Promise.resolve(delegationItems)
      )

  public listUserDelegations = (userId: number): Promise<DelegationAvailableItem[]> =>
    this.client.getAvailableDelegationsForUser(userId)
      .then(delegationItems =>
        delegationItems === null
          ? Promise.reject(`No delegation items received from backend for user id ${userId}.`)
          : Promise.resolve(delegationItems)
      )


  public listUserPlacements = (userId: number, delegationId: number): Promise<UserDelegationAvailablePlacementsItem> =>
    this.client.getAvailableDelegationPlacementsForUser(userId, delegationId)
      .then(availableItems => {
        return availableItems === null
          ? Promise.reject(`No available items received from backend for user id ${userId} and delegation id ${delegationId}.`)
          : Promise.resolve(availableItems)
      }
      )
  public listExpiringDelegations = (organizationId: number, fromDate: Date, toDate: Date): Promise<ExpiringDelegationItem[]> =>
    this.client
      .listExpiringDelegations(organizationId, fromDate, toDate)
      .then(items =>
        items === null
          ? Promise.reject(`No exiring delegation items received from backend for organization id ${organizationId}.`)
          : Promise.resolve(items)
      )

  public listDelegationCategories = (organizationId: number): Promise<DelegationCategoryListItem[]> =>
    this.client
      .listOrganizationDelegationCategories(organizationId)
      .then(items =>
        items === null
          ? Promise.reject(`No delegation category items received from backend for organization id ${organizationId}.`)
          : Promise.resolve(items)
      )

  public hasDelegationCategoryConnectedToDelegation = (categoryId: number): Promise<boolean> =>
    this.client
      .hasDelegationCategoryConnectedToDelegation(categoryId)
      .then(exists =>
        exists === null
          ? Promise.reject(`Unexpected response from hasDelegationCategoryConnectedToDelegation for category id ${categoryId}.`)
          : Promise.resolve(exists)
      )

  public createDelegation = (delegation: IDelegationCreateItem) => {
    const model = new DelegationCreateItem(delegation)
    return this.client
      .createDelegation(model)
      .then(delegationItem =>
        delegationItem === null ? Promise.reject(`When creating a delegation, no delegation item received from backend.`) : Promise.resolve(delegationItem)
      )
  }

  public createDelegationCategory = (delegationCategory: IDelegationCategoryCreateItem) => {
    const model = new DelegationCategoryCreateItem(delegationCategory)
    return this.client
      .createDelegationCategory(model)
      .then(item =>
        item === null ? Promise.reject(`When creating a delegation category, no delegation category item received from backend.`) : Promise.resolve(item)
      )
  }

  public updateDelegation = (delegation: IDelegationUpdateItem) => {
    const model = new DelegationUpdateItem(delegation)
    return this.client
      .updateDelegation(model)
      .then(delegationItem =>
        delegationItem === null ? Promise.reject(`When creating a delegation, no delegation item received from backend.`) : Promise.resolve(delegationItem)
      )
  }

  public updateDelegationCategory = (delegationCategory: IDelegationCategoryUpdateItem) => {
    const model = new DelegationCategoryUpdateItem(delegationCategory)
    return this.client
      .updateDelegationCategory(model)
      .then(item =>
        item === null ? Promise.reject(`When creating a delegation category, no delegation category item received from backend.`) : Promise.resolve(item)
      )
  }

  public deleteDelegation = (delegationId: number) => this.client.deleteDelegation(delegationId).then(() => Promise.resolve(delegationId))

  public deleteDelegationCategory = (delegationCategoryId: number) =>
    this.client.deleteDelegationCategory(delegationCategoryId).then(() => Promise.resolve(delegationCategoryId))


  public hasDelegationConnectedToTemplateActivity = (delegationId: number): Promise<boolean> =>
    this.client
      .hasDelegationConnectedToTemplateActivity(delegationId)
      .then(exists =>
        exists === null
          ? Promise.reject(
            `Unexpected response from hasDelegationConnectedToTemplateActivity for delegation id ${delegationId}.`
          )
          : Promise.resolve(exists)
      )

  public listRequiredDelegationsForAreaAndCalendarDate = (areaId: number, start: Date, end: Date): Promise<PlannedActivityRequiredDelegationsItem[]> =>
    this.client
      .getRequiredDelegationsForCalendarDay(areaId, start, end)
      .then(result =>
        result === null
          ? Promise.reject(`No required delegations received from backend for area id ${areaId}, start ${start}, end ${end},.`)
          : Promise.resolve(result)
      )

}

const useDelegationApi = () => React.useMemo(() => new DelegationApi(), [])

export default useDelegationApi
