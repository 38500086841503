import React from 'react'
import type { DashboardKpi } from 'pages/dashboard/config'
import { KeyPerformanceIndicatorItem, KeyPerformanceIndicatorType } from 'api/tenilo.dashboard.api-client'
import { Colors } from 'styles/colors'
import isEmpty from 'lodash/isEmpty'
import Color from 'color'
import { jsPDF } from "jspdf"
import { Card, CardHeader, CardContent, CardActions, Collapse, Box, Skeleton } from '@mui/material'
import DashboardPageContext from 'pages/dashboard/dashboard-page-context'
import DashboardKpiContext from '../../dashboard-kpi-context'
import DashboardKpiSingleValueView from './dashboard-kpi-single-value-wrapper'
import DashboardKpiDetailView from './dashboard-kpi-detail-wrapper'
import DashboardKpiChartView from './dashboard-kpi-chart-wrapper'

const DashboardKpiMainWrapperView : React.FC<DashboardKpi> = (props) => {
  const [expanded, setExpanded] = React.useState(false)
  const [filteredDatasetByLegend, setFilteredDatasetByLegend] = React.useState<number[]>([])
  const [percent, setPercent] = React.useState<boolean>(false)
  const [kpiData, setKpiData] =React.useState<KeyPerformanceIndicatorItem>()
  const chartRef = React.useRef(null)
  const detailsRef = React.useRef(null)
  const [ zoomCardContent, setZoomCardContent ] = React.useState<boolean>(false)
  const { getKpiData } = DashboardKpiContext.useActions()
  const { layoutSize } = DashboardPageContext.useState()
  const { HeaderActionBar, FooterActionBar, id } = props

  const handleFilterDatasetByLegend = React.useCallback((dataIndex:number,visibilityStatus:boolean)=>{
    if(!visibilityStatus){
      setFilteredDatasetByLegend(curr => [
        ...curr,
        dataIndex
      ])
    }else{
      setFilteredDatasetByLegend(curr => curr.filter(item => item !== dataIndex))
    }
  },[])

  const handleExpandClick = React.useCallback(() => {
    setExpanded((curr) => !curr);
  },[])
  const checkKpiType = React.useCallback((kpiTypes: KeyPerformanceIndicatorType[]) : boolean => {

    if(!kpiData){
      return false
    }
    return kpiTypes.some(type => kpiData.type === type)
  },[kpiData])

  const downloadPDF = React.useCallback(() => {
    const doc = new jsPDF()
    doc.addImage(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current?.ctx.canvas, // canvas
      "PNG",
      15,
      15,
      180,
      180,
      "NONE"
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    doc.html(detailsRef.current, {
      callback: (d) => {
        d.save("chart.pdf")
      },
      margin:[200,10,10,10],
      width:180,
      windowWidth:675
   });
  },[chartRef,detailsRef])


  const handleZoomCardContent = React.useCallback(()=>{
    setZoomCardContent(curr => !curr)
  },[])

  const handleSetPercent = React.useCallback(()=>{
    setPercent(curr => !curr)
  },[])

  React.useEffect(()=>{
    getKpiData(id)
    .then(setKpiData)
    .catch((err)=>{
      // eslint-disable-next-line no-console
      console.error(err)
    })
  },[])

  return (
    <Card sx={{
      margin:'15px',
      display:'flex',
      width:expanded ? '100%' : 'auto',
      borderRadius:'2px',
      transition:'all 0.2s ease-out',
      boxShadow: expanded ? `2px 1px 5px 4px ${Color(Colors.AthensGray).darken(0.15)}` : `2px 2px 6px 3px ${Colors.AthensGray}`,
      '&:hover':{
        boxShadow: `2px 1px 5px 4px ${Color(Colors.AthensGray).darken(0.15)}`,
      }
    }}>
      <Box sx={{
        width: layoutSize === 's' ? 200 : layoutSize === 'm' ? 300 : 400,
        height: layoutSize === 's' ? 200 : layoutSize === 'm' ? 300 : 400,
        boxShadow:expanded ? `2px 0px 10px 3px ${Colors.AthensGray}` : 'none',
        display:'flex',
        flexShrink:0,
        flexGrow:1,
        flexBasis:'auto',
        flexDirection:'column',
        justifyContent:'space-between',
      }}>
        {!kpiData ? (
          <Skeleton variant="rectangular" animation="wave" sx={{
            margin:'4px',
            backgroundColor: Color(Colors.AthensGray).lighten(0.044).string()
          }} />
        ):(
          <CardHeader
            sx={{
              height:layoutSize === 's' ? '20px' : layoutSize === 'm' ? '25px' : '30px',
              padding:0,
              '& .MuiCardHeader-action':{
                margin:0,
                alignSelf:'center'
              }
            }}
            action={
              <>
                {kpiData &&  HeaderActionBar && (
                  <HeaderActionBar percent={percent} handleSetPercent={handleSetPercent} layoutSize={layoutSize} handleZoomCardContent={handleZoomCardContent} />
                )}
              </>
            }
          />
        )}
        <CardContent sx={{
          flex:1,
          overflow:'hidden',
          padding:'0px 15px 0 15px'
        }}>
          {!kpiData && (
            <Skeleton variant="rectangular" width={'100%'} height={'100%'} animation="wave" sx={{
              backgroundColor: Color(Colors.AthensGray).lighten(0.044).string()
            }} />
          )}
          {kpiData && checkKpiType([KeyPerformanceIndicatorType.SingleValue,KeyPerformanceIndicatorType.SingleValueWithDetails]) &&
          (
            <DashboardKpiSingleValueView kpiData={kpiData}/>
          )}
          {kpiData && checkKpiType([KeyPerformanceIndicatorType.PieChart,KeyPerformanceIndicatorType.PieChartWithDetails]) &&
          (
            <DashboardKpiChartView percent={percent} handleFilterDatasetByLegend={handleFilterDatasetByLegend} filteredDatasetByLegend={filteredDatasetByLegend} handleSetPercent={handleSetPercent} detailsRef={detailsRef} kpiData={kpiData} chartType="pie" forwardRef={chartRef} zoomCardContent={zoomCardContent} handleZoomCardContent={handleZoomCardContent} downloadPDF={downloadPDF} />
          )}
        </CardContent>
        <CardActions sx={{boxSizing:'border-box',
          padding:'4px',
          minHeight: layoutSize === 's' ? '30.5px' : layoutSize === 'm' ? '30.5px' : '33.5px'}} disableSpacing>
          {!kpiData && (
            <Skeleton variant="rectangular" width={'100%'} height={'100%'} animation="wave" sx={{
              backgroundColor: Color(Colors.AthensGray).lighten(0.044).string()
            }} />
          )}
          {kpiData && !isEmpty(kpiData.details) && FooterActionBar && (
            <FooterActionBar handleExpandClick={handleExpandClick} expanded={expanded} layoutSize={layoutSize} />
          )}
        </CardActions>
      </Box>
      <Collapse sx={{
        width:'100% !important',
        boxSizing:'border-box',
        transition:'none !important',
        '.MuiCollapse-wrapperInner':{
          width:'100%'
        }
      }} in={expanded} timeout={0} easing="ease-out" orientation="horizontal" unmountOnExit>
        <CardContent sx={{
          boxSizing:'border-box',
          height: layoutSize === 's' ? 200 : layoutSize === 'm' ? 300 : 400,
          overflowY:'auto',
          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar-track':{
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: Colors.AthensGray
          },
          '&::-webkit-scrollbar-thumb':{
            borderRadius: '10px',
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: Colors.TeniloGreen
          }
          }}>
          {kpiData && !isEmpty(kpiData.details) && checkKpiType([KeyPerformanceIndicatorType.PieChartWithDetails,KeyPerformanceIndicatorType.SingleValueWithDetails]) &&
          (
            <DashboardKpiDetailView percent={percent} kpiData={kpiData} filteredDatasetByLegend={filteredDatasetByLegend} />
          )}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default React.memo(DashboardKpiMainWrapperView)
