import React from 'react'

import {
  IKioskRequestItem,
  IManageKioskItem,
  KioskClient,
  KioskItem,
  KioskListItem,
  KioskRequestItem,
  ManageKioskItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import { DeactivateKioskItem, IDeactivateKioskItem } from './tillit.api-client'
import { inputDateTimeToTicks } from 'utilities/date-time-helpers'

export class KioskApi {
  private readonly abortController = new AbortController()
  private readonly client: KioskClient

  constructor() {
    this.client = new KioskClient(API_URL, httpMiddleware({ signal: this.abortController.signal, headers: { 'key-name': 'kiosk' } }))
  }

  public abort = () => this.abortController.abort()

  public requestKioskActivation = async (kioskRequestItem: IKioskRequestItem): Promise<string> => {
    const model = new KioskRequestItem(kioskRequestItem)
    return this.client
      .requestKioskActivation(model)
      .then(deviceGuid => (deviceGuid === null ? Promise.reject(`No device guid received from backend`) : Promise.resolve(deviceGuid)))
  }

  public getKioskByGuid = async (deviceGuid: string): Promise<KioskItem> =>
    this.client
      .getKioskByGuid(deviceGuid)
      .then(kioskItem => (kioskItem === null ? Promise.reject(`No kiosk item received from backend`) : Promise.resolve(kioskItem)))

  public getKiosks = async (): Promise<KioskListItem[]> =>
    this.client
      .getKiosks()
      .then(kioskListItem => (kioskListItem === null ? Promise.reject(`No kiosk items received from backend`) : Promise.resolve(kioskListItem)))

  public getKiosk = async (kioskId: number): Promise<KioskItem> =>
    this.client.getKiosk(kioskId).then(kioskItem => (kioskItem === null ? Promise.reject(`No kiosk item received from backend`) : Promise.resolve(kioskItem)))

  public getManageKiosk = async (kioskId: number): Promise<ManageKioskItem> =>
    this.client
      .getManageKiosk(kioskId)
      .then(manageKioskItem => (manageKioskItem === null ? Promise.reject(`No manage kiosk item received from backend`) : Promise.resolve(manageKioskItem)))

  public manageKiosk = async (kioskItem: IManageKioskItem) => {
    const model = new ManageKioskItem(kioskItem)
    return this.client
      .manageKiosk(model)
      .then(manageKioskItem => (manageKioskItem === null ? Promise.reject(`No manage kiosk item received from backend`) : Promise.resolve(manageKioskItem)))
  }

  public deactivateKiosk = async (kioskItem: IDeactivateKioskItem) => {
    const model = new DeactivateKioskItem(kioskItem)
    return this.client.deactivateKiosk(model)
  }

  public getKioskTokenByGuid = async (deviceGuid: string) =>
    this.client.getKioskTokenByGuid(deviceGuid).then(tokens => (tokens === null ? Promise.reject(`No tokens received from backend`) : Promise.resolve(tokens)))

  public getKioskUsers = async (deviceGuid: string) => {
    const now = inputDateTimeToTicks(new Date())
    return this.client
      .getKiosksUsers(deviceGuid, now)
      .then(userList => (userList === null ? Promise.reject(`No user list received from backend.`) : Promise.resolve(userList)))
  }

  public getRecentLoggedInUserIds = async (deviceGuid: string) =>
    this.client
      .getRecentLoginsOnKiosk(deviceGuid)
      .then(userIdsList => (userIdsList === null ? Promise.reject(`No user list received from backend.`) : Promise.resolve(userIdsList)))

  public hasMultipleAreasConnected = async (deviceGuid: string): Promise<boolean> =>
    this.client
      .hasMultipleAreasConnected(deviceGuid)
      .then(exists =>
        exists === null ? Promise.reject(`Unexpected response from hasMultipleAreasConnected for device guid ${deviceGuid}.`) : Promise.resolve(exists)
      )
}

const useKioskApi = () => React.useMemo(() => new KioskApi(), [])

export default useKioskApi
