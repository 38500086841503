import React from 'react'
import Router from 'components/router'
import ThemeProvider from 'components/theme-provider'
import { LocalizationProvider } from 'localization'
import { StyledEngineProvider } from '@mui/material/styles'
import GlobalStyle from 'styles/global-styles'
import { THEME } from 'styles/theme'
import { RouterProvider } from 'react-router-dom'
import Authentication from 'components/authentication'
import { ConfirmationBoxContext } from 'components/confirmation-box'

const App: React.FunctionComponent = () => {

  return (
    <LocalizationProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={THEME}>
          <GlobalStyle />
          <ConfirmationBoxContext.Provider>
            <Authentication.Provider>
              <RouterProvider router={Router} />
            </Authentication.Provider>
          </ConfirmationBoxContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}

export default App
