import React from 'react'
import { Box, Typography } from '@mui/material';
import { useFormatMessage } from 'localization'
import styled from 'styled-components/macro'

const UserDelegationTitlesContainer = styled(Box)`
  border-radius: 0.5rem;
  padding:0.5rem 0.5rem 0 0.5rem;
  display: flex;
  h6 {
    flex: 1;
    padding: 0 10px;
  }
`

const UserDelegationTitles: React.FC  = () => {
  const f = useFormatMessage()
  return (
    <UserDelegationTitlesContainer>
      <Typography variant="subtitle2">
        {f('USER_DIALOG_VIEW_DELEGATION_AREA_CUSTOMER_TITLE')}
      </Typography>
      <Typography variant="subtitle2">
        {f('USER_DIALOG_VIEW_DELEGATION_START_DATE_TITLE')}
      </Typography>
      <Typography variant="subtitle2">
        {f('USER_DIALOG_VIEW_DELEGATION_END_DATE_TITLE')}
      </Typography>
      <Typography variant="subtitle2">
        {f('USER_DIALOG_VIEW_DELEGATION_DESCRIPTION_TITLE')}
      </Typography>
      <Typography variant="subtitle2">
        {f('USER_DIALOG_VIEW_DELEGATION_DELEGATED_BY_TITLE')}
      </Typography>
    </UserDelegationTitlesContainer>
  )
}

export default UserDelegationTitles
