import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import { OkDialog } from 'components/ok-dialog'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { DeleteDialog } from 'components/delete-dialog'
import React from 'react'
import { isSameDay } from 'date-fns'

const RouteIsRemovedDialogContainer: React.FC<{
    calendarStartDate: Date
    routeIsRemovedFromTomorrow: boolean
    routeIsRemoved: boolean
    setRouteIsRemoved: (value: React.SetStateAction<boolean>) => void
    setRouteIsRemovedFromTomorrow: (value: React.SetStateAction<boolean>) => void
    selectedRoute: IPlannedRoutesActivitiesPlanningViewItem | undefined
}> = ({
    routeIsRemovedFromTomorrow,
    calendarStartDate,
    routeIsRemoved,
    setRouteIsRemovedFromTomorrow,
    setRouteIsRemoved,
    selectedRoute,
}) => {
    const f = useFormatMessage()
    const { removeRouteAsync } = PlanningPageContext.useActions()

    const handleRemoveRoute = React.useCallback(() => {
        if (selectedRoute) {
            removeRouteAsync(selectedRoute)
        }
        setRouteIsRemovedFromTomorrow(false)
        setRouteIsRemoved(false)
    }, [removeRouteAsync, selectedRoute])

    const handleClose = () => {
        setRouteIsRemovedFromTomorrow(false)
        setRouteIsRemoved(false)
    }
    const today = new Date()
    const calendarStartDateIsToday = isSameDay(calendarStartDate, today)

    const routeIsRemovedFromOtherDateThanTomorrow = !routeIsRemovedFromTomorrow && routeIsRemoved

    return (
        <>
            {routeIsRemovedFromTomorrow && (
                <OkDialog
                    dialogTitle={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_TITLE')}
                    dialogContentText={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_MESSAGE', {
                        routeName: selectedRoute?.name,
                        endDate: selectedRoute?.endDate?.toLocaleDateString()
                    })}
                    buttonOkText={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_OK')}
                    okDialogOpen={routeIsRemovedFromTomorrow}
                    onClose={handleClose}
                />
            )}

            {!routeIsRemovedFromTomorrow && routeIsRemoved && (
                <DeleteDialog
                    dialogTitle={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ROUTE_ALREADY_REMOVED_DIALOG_TITLE')}
                    dialogContentText={calendarStartDateIsToday ? f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ALREADY_REMOVED_FROM_TOMORROW_DIALOG_MESSAGE', {
                        routeName: selectedRoute?.name,
                        endDate: selectedRoute?.endDate?.toLocaleDateString()
                    }) : f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_ALREADY_REMOVED_FROM_OTHER_DATE_THAN_TOMORROW_DIALOG_MESSAGE', {
                        routeName: selectedRoute?.name,
                        endDate: selectedRoute?.endDate?.toLocaleDateString()
                    })
                    }
                    buttonSubmitText={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_TITLE')}
                    buttonCancelText={f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ROUTE_DIALOG_CANCEL')}
                    deleteDialogOpen={routeIsRemovedFromOtherDateThanTomorrow}
                    onClose={handleClose}
                    onSubmit={handleRemoveRoute}
                />
            )}
        </>
    )
}

export default RouteIsRemovedDialogContainer
