import React from 'react'

import { GrandIdSettingsClient, GrandIdServiceItem, GrandIdServiceUpdateItem, IGrandIdServiceUpdateItem } from 'api/tenilo.support.api-client'
import { SUPPORT_API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'

export class SupportGrandIdSettingsApi {
  private readonly abortController = new AbortController()
  private readonly client: GrandIdSettingsClient

  public constructor() {
    this.client = new GrandIdSettingsClient(SUPPORT_API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public abort = () => this.abortController.abort()

  public getGrandIdService = (serviceId: number): Promise<GrandIdServiceItem> =>
    this.client
      .getGrandIdService(serviceId)
      .then(serviceItem =>
        serviceItem === null ? Promise.reject(`No service item received from backend for service id ${serviceId}.`) : Promise.resolve(serviceItem)
      )

  public listGrandIdServices = (): Promise<GrandIdServiceItem[]> =>
    this.client.listGrandIdServices().then(services => (services === null ? Promise.reject(`No services received from backend.`) : Promise.resolve(services)))

  public updateGrandIdService = (service: IGrandIdServiceUpdateItem) => {
    const model = new GrandIdServiceUpdateItem(service)
    return this.client
      .updateGrandIdService(model)
      .then(serviceItem =>
        serviceItem === null ? Promise.reject(`When updating a service, no service item received from backend.`) : Promise.resolve(serviceItem)
      )
  }
}

const useSupportGrandIdSettingsApi = () => React.useMemo(() => new SupportGrandIdSettingsApi(), [])

export default useSupportGrandIdSettingsApi
