import React from 'react'
import { Box, Button, useTheme, Table, TableBody, TableRow, TableCell, Tooltip, IconButton } from '@mui/material'
import { DataGrid, GridValueGetterParams, GridRenderCellParams, GridRowParams, MuiEvent, GridCallbackDetails } from '@mui/x-data-grid'
import { DeleteDialog } from 'components/delete-dialog'
import { IKioskListItem } from 'api/tillit.api-client'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { useFormatMessage } from 'localization'
import { AdministerDevicesContext } from 'pages/administer/components/administer-devices'
import format from 'utilities/format'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Delete } from 'mdi-material-ui'
import { useNavigate } from 'react-router-dom'
import { setDeviceGuid } from 'api/device-guid'
import PageRoute from 'config/page-routes'
import { PUBLIC_URL } from 'config/variables'
import { ManageDeviceDialogContext } from '../manage-device-dialog/manage-device-dialog-context'
import NotificationContext from 'components/notification/notification-context'
import Authentication from 'components/authentication'

const ActivatedDevicesSectionView: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const { activatedDevices, filteredDevices } = AdministerDevicesContext.useState()
  const { handleDeactivatedDevice } = AdministerDevicesContext.useActions()
  const { handleOpenDialog } = ManageDeviceDialogContext.useActions()
  const [pageSize, setPageSize] = React.useState<number>(50)
  const [ deactivateDevice, setDeactivateDevice ] = React.useState<IKioskListItem>()
  const smallScreen = useMediaQuery(theme.customBreakpoints.justMobileTablet)
  const navigate = useNavigate()
  const [copyDisabledDeviceGuid, setCopyDisabledDeviceGuid] = React.useState<string | undefined>(undefined)
  const [copyIPDisabledDeviceGuid, setCopyIPDisabledDeviceGuid] = React.useState<string | undefined>(undefined)
  const { removeAlertByType } = NotificationContext.useActions()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const columnVisibilityModel = React.useMemo(() => {
    if (currentUser.organizationUsesIpFilterOnDevice) {
      return {
        no_field_COPYIP: true
      }
    }
    return {
      no_field_COPYIP: false
    }
}, [currentUser]);

const handleDeactivateDeviceDialogOpen = React.useCallback((device: IKioskListItem) => (e: React.MouseEvent) => {
    e.stopPropagation()
    setDeactivateDevice(device)
  },[])

  const handleDeactivateDeviceDialogClose = React.useCallback(() => {
    setDeactivateDevice(undefined)
  },[])

  const handleConfirmDeactivateDevice = React.useCallback(() => {
    if(deactivateDevice){
      handleDeactivatedDevice({id:deactivateDevice.id})
      .then(() => {
        handleDeactivateDeviceDialogClose()
      })
    }
  },[deactivateDevice])

  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      handleOpenDialog(params.row.id)
    },
    [handleOpenDialog]
  )

  const copyToClipboard = async (value?: string) => {
    if (!value) {
      console.error("Value is undefined or null");
      return;
    }

    try {
      const Url = new URL(`${PageRoute.DailyActivities.path}/${value}`, (PUBLIC_URL ? `https:${PUBLIC_URL}` : 'http://localhost:3000'))
      await navigator.clipboard.writeText(Url.toString());
    } catch (error) {
      console.error(`Failed to copy "${value}" to clipboard:`, error);
    }
  }

  const handleCopyClick = React.useCallback( async (_: React.MouseEvent<Element, MouseEvent> | undefined, value?: string) => {
      _?.stopPropagation()
      setCopyDisabledDeviceGuid(value)
      await copyToClipboard(value);
    },
    [setCopyDisabledDeviceGuid]
  )

  const copyIPToClipboard = async (value?: string) => {
    if (!value) {
      console.error("Value is undefined or null");
      return
    }

    try {
      const Url = new URL(`${PageRoute.ValidateDailyActivities.path}/${value}`, (PUBLIC_URL ? `https:${PUBLIC_URL}` : 'http://localhost:3000'))
      await navigator.clipboard.writeText(Url.toString());
    } catch (error) {
      console.error(`Failed to copy "${value}" to clipboard:`, error);
    }
  }

  const handleCopyIPClick = React.useCallback( async (_: React.MouseEvent<Element, MouseEvent> | undefined, value?: string) => {
    _?.stopPropagation()
    setCopyIPDisabledDeviceGuid(value)
    await copyIPToClipboard(value);
  },
  [setCopyIPDisabledDeviceGuid]
)

const handleOpenDeviceClick = async (value?: string) => {
    if (!value) {
      console.error("Value is undefined or null");
      return;
    }

    removeAlertByType('visible-area-alert')
    setDeviceGuid(value)

    const planningPath =
      PageRoute.DailyActivities.path
    navigate(planningPath)
  }

  const smallScreenView = () => {
    return (
      <Table>
        <TableBody>
          {activatedDevices.map(device => (
            <TableRow key={device.id}>
              <TableCell align="left" component="th" scope="row">
                {device.kioskName}
              </TableCell>
              <TableCell align="right">
                <Button href="" variant="text" color="secondary" onClick={(e) => handleCopyClick(e, device.deviceGuid)} disabled={copyDisabledDeviceGuid === device.deviceGuid}>
                  {copyDisabledDeviceGuid === device.deviceGuid ? f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_COPIED') : f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK')}
                </Button>
                <Button href="" variant="text" color="secondary" onClick={(e) => handleCopyIPClick(e, device.deviceGuid)} disabled={copyIPDisabledDeviceGuid === device.deviceGuid}>
                  {copyIPDisabledDeviceGuid === device.deviceGuid ? f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_COPIED') : f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_IP')}
                </Button>
                <Button href="" variant="text" color="secondary" onClick={() => handleOpenDeviceClick(device.deviceGuid)}>
                  {f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_OPEN_DEVICE')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  if(smallScreen){
    return smallScreenView()
  }



  return (
    <Box flex={1}>
      <Box sx={(theme) => ({
        [theme.customBreakpoints.justMobileTablet]: {
          padding: 0
        }
      })}>
      <DataGrid
        disableColumnFilter
        sx={{
          minHeight: '70vh',
          minWidth:'600px',
          maxHeight: '70vh',
          '.MuiDataGrid-cell': { borderBottom: '0px' }
        }}
        rows={filteredDevices}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        onRowClick={handleRowClick}
        localeText={muiDataGridLocale(f)()}
        columnVisibilityModel={columnVisibilityModel}
        columns={[
          {
            flex: 3,
            headerName: f('ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_DEVICE_TITLE'),
            field: 'kioskName',
          },
          {
            flex: 3,
            headerName: f('ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_CREATEDBY_TITLE'),
            field: 'createdBy',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.createdBy}
                </span>
              )
            },
          },
          {
            flex: 3,
            headerName: f('ADMINISTER_PAGE_VIEW_ACTIVATED_DEVICES_SECTION_VIEW_TABLE_DEVICE_COLUMN_HEADER_CREATEDON_TITLE'),
            field: 'createdOn',
            valueGetter: (params: GridValueGetterParams) => {
              const createdOn = params.value as Date | undefined
              return createdOn ? format(createdOn, 'yyyy-MM-dd HH:mm') : ''
            },
          },
          {
            headerName: ' ',
            flex: 2,
            field: 'no_field_COPY',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Button href="" variant="text" color="secondary" onClick={(e) => handleCopyClick(e, params.row.deviceGuid)} disabled={copyDisabledDeviceGuid === params.row.deviceGuid}>
                  {copyDisabledDeviceGuid === params.row.deviceGuid ? f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_COPIED') : f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK')}
                </Button>
              )
            },
          },
          {
            headerName: ' ',
            flex: 2,
            field: 'no_field_COPYIP',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Button href="" variant="text" color="secondary" onClick={(e) => handleCopyIPClick(e, params.row.deviceGuid)} disabled={copyIPDisabledDeviceGuid === params.row.deviceGuid}>
                  {copyIPDisabledDeviceGuid === params.row.deviceGuid ? f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_COPIED') : f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_COPY_LINK_IP')}
                </Button>
              )
            },
          },
          {
            headerName: ' ',
            flex: 2,
            field: 'no_field_OPEN_DEVICE',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Button href="" variant="text" color="secondary" onClick={() => handleOpenDeviceClick(params.row.deviceGuid)}>
                  {f('ADMINISTER_ACTIVATED_DEVICE_SECTION_DEVICE_TABLE_BUTTON_OPEN_DEVICE')}
                </Button>
              )
            },
          },
          {
            headerName: ' ',
            flex: 0.2,
            field: 'no_field_deactivate',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span>
                  <Tooltip
                    title={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_TITLE')}>
                    <IconButton onClick={handleDeactivateDeviceDialogOpen(params.row)} size="large">
                      <Delete color={'secondary'} />
                    </IconButton>
                  </Tooltip>
                </span>
              )
            },
          },
        ]}

        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
      />
      </Box>
      {deactivateDevice && (
        <DeleteDialog
          dialogTitle={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_TITLE')}
          dialogContentText={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_SUBTITLE')}
          buttonSubmitText={f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DEACTIVE_DEVICE_BUTTON')}
          buttonCancelText={f('BASIC_BUTTON_CANCEL_TEXT')}
          deleteDialogOpen={!!deactivateDevice}
          onClose={handleDeactivateDeviceDialogClose}
          onSubmit={handleConfirmDeactivateDevice}
        />
      )}
    </Box>
  )
}

export default ActivatedDevicesSectionView
