import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'

import {
  ActivityParticipantItem,
  PlannedActivityParticipantItem,
} from 'api/tillit.api-client'

interface IEditParticipantsModel {
  activityParticipants?: ActivityParticipantItem[] | undefined
}

const useEditparticipantsModalFormData = () => {
  const f = useFormatMessage()
  const { editParticipantsModalData } = PlanningPageContext.useState()
  const {
    onCloseEditParticipantsModal,
    updateDetachedPlannedActivityParticipants,
  } = PlanningPageContext.useActions()

  const initialValues: IEditParticipantsModel = React.useMemo(
    () => ({
      activityParticipants:
      editParticipantsModalData && editParticipantsModalData.plannedActivityParticipants
          ? editParticipantsModalData.plannedActivityParticipants.map((pap : PlannedActivityParticipantItem) => new ActivityParticipantItem({
            id: pap.id,
            activityId: pap.plannedActivityRowId,
            customerId: pap.customerId,
            customerFullName: pap.customerFullName
          }))
          : [],
    }),
    [editParticipantsModalData]
  )

  const onSubmit: OnSubmitFunction<IEditParticipantsModel> = React.useCallback(
    (values, { setSubmitting }) => {
      if (editParticipantsModalData?.id) {
        const plannedActivityParticipants = values.activityParticipants?.map(ap => new PlannedActivityParticipantItem({
          id: ap.id,
          plannedActivityRowId: ap.activityId,
          customerId: ap.customerId,
          customerFullName: ap.customerFullName
        }))
        if (plannedActivityParticipants) {
          updateDetachedPlannedActivityParticipants(plannedActivityParticipants)
        }
      }
      onCloseEditParticipantsModal()
      setSubmitting(false)
    },
    [updateDetachedPlannedActivityParticipants, onCloseEditParticipantsModal, editParticipantsModalData]
  )

  
  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<IEditParticipantsModel>({
/*        comment: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('PLANNING_PAGE_SCHEDULE_VIEW_EDITCOMMENT_FORM_NAME_LABEL'),
            maxsize: 1024,
          })
        ),*/
      }),
    [f]
  )

  const name: Name<IEditParticipantsModel> = React.useMemo(
    () => ({
      activityParticipants: 'activityParticipants',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useEditparticipantsModalFormData
