import React from 'react'
import { Typography } from '@mui/material'
import { useFormatMessage } from 'localization'
import { ReportFieldItem } from 'api/tenilo.reporting.api-client'
import Authentication from 'components/authentication'

type Props = Readonly<{
  reportField: ReportFieldItem
}>

const ReportTableHeaderColumn: React.FC<Props> = ({ reportField }) => {
  const f = useFormatMessage()
  const { user } = Authentication.useAuthenticatedState()

  const headerName = React.useMemo(() => {
    return reportField && reportField.headerName ? f(reportField.headerName) : 'MISSING HEADER'
  }, [reportField])

  if (!reportField || reportField.isMeta) {
    return null
  }

  if (reportField.useDelegations && !user.useDelegations) { return null }
  if (reportField.useDrugLists && !user.useDrugLists) { return null }
  if (reportField.useWorkSchedule && !user.useWorkSchedule) { return null }


  return (
    <Typography key={reportField.fieldIndex} variant="caption" fontWeight={600} sx={{ whiteSpace: 'nowrap', position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '999' }}>{headerName}</Typography>
  )
}

export default React.memo(ReportTableHeaderColumn)
