import { ISidePanelActivitiesPlanningViewItem } from 'api/tillit.api-client'
import { produce } from 'immer'
import React from 'react'

const filterCustomersOnShowStatus = (
  customers: ISidePanelActivitiesPlanningViewItem[],
  show: boolean
) => {
  if (show) {
    return customers
  }
  return customers.reduce<ISidePanelActivitiesPlanningViewItem[]>(
    (
      prev,
      { sidePanelPlannedActivityRowsPlanningViewItems = [], ...restCustomer }
    ) => {
      if (sidePanelPlannedActivityRowsPlanningViewItems.length === 0) {
        return prev
      }

      const filteredPlannedActivities = sidePanelPlannedActivityRowsPlanningViewItems.filter(
        activity => !activity.isPlanned
      )

      if (filteredPlannedActivities.length === 0) {
        return prev
      }

      return [
        ...prev,
        {
          ...restCustomer,
          sidePanelPlannedActivityRowsPlanningViewItems: filteredPlannedActivities,
        },
      ]
    },
    []
  )
}

const useFilterActions = (
  initialActivities: ISidePanelActivitiesPlanningViewItem[]
) => {
  const [isFiltered, filter] = React.useState(false)
  const [filteredActivities, setFilteredActivities] = React.useState(
    filterCustomersOnShowStatus(initialActivities, isFiltered)
  )

  React.useEffect(() => {
    setFilteredActivities(
      filterCustomersOnShowStatus(initialActivities, isFiltered)
    )
  }, [initialActivities, isFiltered])

  return { filteredActivities, filter, isFiltered }
}

export const useActivitiesSidepanel = (
  initialActivities: ISidePanelActivitiesPlanningViewItem[],
  initialVisibility: boolean
) => {
  const [activities, setActivities] = React.useState(initialActivities)
  const [isVisible, setVisibility] = React.useState(initialVisibility)

  React.useEffect(() => {
    setActivities(initialActivities)
    setVisibility(initialVisibility)
  }, [setActivities, initialActivities, setVisibility, initialVisibility])

  const setIsPlannedFor = React.useCallback(
    (id: number, isPlanned: boolean) => {
      setActivities(prev =>
        produce(prev.slice(0), draft => {
          draft.forEach(element => {
            const {
              sidePanelPlannedActivityRowsPlanningViewItems = [],
            } = element
            const item = sidePanelPlannedActivityRowsPlanningViewItems.find(
              i => i.id === id
            )
            if (item !== undefined) {
              item.isPlanned = isPlanned
            }
          })
        })
      )
    },
    []
  )

  const isPlanned = React.useCallback(
    (id: number): boolean => {
      for (const element of activities) {
        const { sidePanelPlannedActivityRowsPlanningViewItems = [] } = element
        const item = sidePanelPlannedActivityRowsPlanningViewItems.find(
          i => i.id === id
        )
        if (item === undefined) {
          continue
        }
        return item.isPlanned
      }
      return false
    },
    [activities]
  )

  const { filteredActivities, filter, isFiltered } = useFilterActions(
    activities
  )

  const toggleFilter = React.useCallback(() => {
    filter(prevShow => !prevShow)
  }, [filter])

  const toggleVisibility = React.useCallback(() => {
    setVisibility(prevVisibility => !prevVisibility)
  }, [setVisibility])

  return {
    setIsPlannedFor,
    isPlanned,
    activities: filteredActivities,
    isFiltered,
    toggleFilter,
    isVisible,
    toggleVisibility,
  }
}
