import React from 'react'
import { Box, IconButton, Tooltip, useTheme } from '@mui/material'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import Authentication from 'components/authentication'
import { useFormatMessage } from 'localization'
import { filterUserImpersonationRequests } from 'utilities/support-userimpersonationrequest-helper'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { UserImpersonationRequestListItem } from 'api/tenilo.support.api-client';
import format from 'utilities/format'
import { Delete } from 'mdi-material-ui';
import { SupportUserImpersonationRequestModalContext } from './components/support-userimpersonationrequest-modal/support-userimpersonationrequest-modal-context'
import { SupportUserImpersonationRequestsContext } from './support-userimpersonationrequests-context'

const SupportUserImpersonationRequestsView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { userImpersonationRequestListItems, isLoading } = SupportUserImpersonationRequestsContext.useState()
  const { deleteUserImpersonateRequestAsync, fetchUserImpersonationRequestsAsync } = SupportUserImpersonationRequestsContext.useActions()

  const { openSupportUserImpersonationRequestModal } = SupportUserImpersonationRequestModalContext.useActions()

  const f = useFormatMessage()
  const getFilteredUserImpersonationRequests = filterUserImpersonationRequests(f)


  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openSupportUserImpersonationRequestModal(params.row.id)
    },
    [openSupportUserImpersonationRequestModal]
  )

  const theme = useTheme()

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredUserImpersonationRequests, setFilteredUserImpersonationRequests] = React.useState<UserImpersonationRequestListItem[]>(userImpersonationRequestListItems)

  React.useEffect(() => {
    if (filterValue && userImpersonationRequestListItems) {
      const filtered = getFilteredUserImpersonationRequests(filterValue, userImpersonationRequestListItems)
      setFilteredUserImpersonationRequests(filtered)
    } else {
      setFilteredUserImpersonationRequests(userImpersonationRequestListItems)
    }
    // NOTE: We are missing filterValue and getFilteredUserImpersonationRequests as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [userImpersonationRequestListItems])


  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      if (userImpersonationRequestListItems) {
        const filtered = getFilteredUserImpersonationRequests(searchValue, userImpersonationRequestListItems)
        setFilteredUserImpersonationRequests(filtered)
      }
    },
    [setFilterValue, setFilteredUserImpersonationRequests, userImpersonationRequestListItems, getFilteredUserImpersonationRequests]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredUserImpersonationRequests(userImpersonationRequestListItems)
    },
    [setFilterValue, setFilteredUserImpersonationRequests, userImpersonationRequestListItems]
  )

  const handleDeleteRequestClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, item: UserImpersonationRequestListItem) => {
      _?.stopPropagation()
      deleteUserImpersonateRequestAsync(item.id).then(() => {
        void fetchUserImpersonationRequestsAsync()
      })
      //  openRemoveDialog(legislation)
    },
    []//openRemoveDialog]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '2vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>
          {currentUser.organizationId && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box></Box>
              <Box sx={{ marginRight: '0px', marginLeft: 'auto', paddingBottom: '1vh', width: '25%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </Box>
            </Box>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredUserImpersonationRequests as any}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}
            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_REQUESTEDON'),
                field: 'requestedOn',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>{format(item.requestedOn, 'yyyy-MM-dd HH:mm')}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_USER'),
                field: 'fullName',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <Box data-matomo-mask pt={1}>
                      <Box>{`${item.impersonateUser.fullName}, Tel: ${item.impersonateUser.phoneNumber}`}</Box>
                      <Box>{item.impersonateUser.email}</Box>
                    </Box>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_ORGANIZATIONNAME'),
                field: 'organizationName',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>{item.impersonateUser.organizationName}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_STATUS'),
                field: 'statusId',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>{f(`UserImpersonationRequestStatus_${item.statusId}`)}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONAPPROVEDON'),
                field: 'sessionApprovedOn',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>{item.sessionApprovedOn ? format(item.sessionApprovedOn, 'yyyy-MM-dd HH:mm') : ''}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONEXPIRESON'),
                field: 'sessionExpiresOn',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>{item.sessionExpiresOn ? format(item.sessionExpiresOn, 'yyyy-MM-dd HH:mm') : ''}</span>
                  )
                }
              },
              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONDENIEDON'),
                field: 'sessionDeniedOn',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>{item.sessionDeniedOn ? format(item.sessionDeniedOn, 'yyyy-MM-dd HH:mm') : ''}</span>
                  )
                }
              },

              {
                flex: 1,
                headerName: f('SUPPORT_USERSIMPERSONATION_COLUMN_SESSIONABANDONEDON'),
                field: 'sessionAbandonedOn',
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>{item.sessionAbandonedOn ? format(item.sessionAbandonedOn, 'yyyy-MM-dd HH:mm') : ''}</span>
                  )
                }
              },

              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_delete',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  const item = params.row as UserImpersonationRequestListItem
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP')}
                        aria-label={f('ADMINISTER_LEGISLATION_DELETE_BUTTON_TOOLTIP')}>
                        <IconButton onClick={e => handleDeleteRequestClick(e, item)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },


            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />

        </>
      )}




    </div>
  )
}

export default SupportUserImpersonationRequestsView
