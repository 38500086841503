import { STORAGEPREFIX } from 'config/variables'

export const DEVICE_GUID = (STORAGEPREFIX ? STORAGEPREFIX : '') + 'device_guid'

export const hasDeviceGuid = (): boolean => localStorage.getItem(DEVICE_GUID) !== null

export const getDeviceGuid = (): string | null => localStorage.getItem(DEVICE_GUID)

export const setDeviceGuid = (deviceGuid: string) => localStorage.setItem(DEVICE_GUID, deviceGuid)

export const clearDeviceGuid = () => localStorage.removeItem(DEVICE_GUID)
