import { Box, Typography, useTheme, Switch, Button } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { SupportOrganizationModalContext } from './support-organization-modal-context'
import useFormData from './utilities/use-support-organization-form-data'
import styled from 'styled-components'
import { Tab, Tabs as MuiTabs } from '@mui/material'
import { DeleteDialog } from 'components/delete-dialog'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { DataGrid, GridCallbackDetails, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid'
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { GrandIdSettingModalContext } from './components/grandid-setting-modal/grandid-setting-modal-context'
import { OrganizationGrandIdSettingItem } from 'api/tenilo.support.api-client'

export type SupportOrganizationModalProps = Readonly<{
  onSave?: () => void
}>

type ITabPanelProps = Readonly<{
  index: number
  value: number
}>

const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {children}
    </Typography>
  )
}

const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
  width: 100%;
  ${({ theme }) => [theme.customBreakpoints.justMobileTablet]}{
    visibility: hidden;
  }
`

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}


const SupportOrganizationModalContainer: React.FC<SupportOrganizationModalProps> = ({ onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { modalOpen, selectedOrganization, isLoading } = SupportOrganizationModalContext.useState()
  const { anonymizeOrganizationAsync, deleteOrganizationGrandIdSettingAsync } = SupportOrganizationModalContext.useActions()
  const { openGrandIdSettingModal } = GrandIdSettingModalContext.useActions()

  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedOrganization)

  const [mainTabValue, setMainTabValue] = React.useState(0)
  const [displayAnonymizationVerification, setDisplayAnonymizationVerification] = React.useState(false)

  const openAnonymizationVerification = React.useCallback(() => {
    setDisplayAnonymizationVerification(true)
  }, [setDisplayAnonymizationVerification])

  const closeAnonymizationVerification = React.useCallback(() => {
    setDisplayAnonymizationVerification(false)
  }, [setDisplayAnonymizationVerification])

  const handleAnonymization = React.useCallback(() => {
    closeAnonymizationVerification()
    void anonymizeOrganizationAsync()
  }, [anonymizeOrganizationAsync])

  const handleChange = (event, newValue: React.SetStateAction<number>) => {
    setMainTabValue(newValue)
  }

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeSupportOrganizationModal } = SupportOrganizationModalContext.useActions()

  const commonStyles = {
    bgcolor: 'background.paper',
    border: 1,
    borderColor: 'text.primary',
    borderRadius: 5,
    p: 1,
  };

  const [pageSize, setPageSize] = React.useState<number>(50);
  const [settingToRemove, setSettingToRemove] = React.useState<OrganizationGrandIdSettingItem | undefined>(undefined)

  const handleRemoveGrandIdSettingClick = React.useCallback(async (e: React.MouseEvent<Element, MouseEvent> | undefined, setting?: OrganizationGrandIdSettingItem) => {
    e?.stopPropagation()
    if (!setting) {
      console.error("Setting is undefined or null");
      return;
    }
    setSettingToRemove(setting)

    //TODO: Open remove dialog
    //openSupportAreaModal(areaId)
  }, [setSettingToRemove])

  const handleRemoveGrandIdSettingDialog = React.useCallback(() => {
    if (settingToRemove) {
      void deleteOrganizationGrandIdSettingAsync(settingToRemove.id)
    }
  }, [settingToRemove])

  const handleRemoveGrandIdSettingDialogClose = React.useCallback(() => {
    setSettingToRemove(undefined)
  }, [setSettingToRemove])

  const handleGrandIdSettingRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      console.log(params.row)
      openGrandIdSettingModal(params.row)
    },
    [openGrandIdSettingModal]
  )

  const handleAddNewGrandIdSettingClick = React.useCallback(() => {
    openGrandIdSettingModal()
  }, [openGrandIdSettingModal])

  return (
    <CenterModal open={modalOpen} onClose={closeSupportOrganizationModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, getFieldMeta, errors, dirty }) => {
          //const { ...statusProps } = values

          const toggleSwitch = (propertyName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(propertyName, !getFieldMeta(propertyName).value)

            if (propertyName === name.useLoginSlidingExpirationMinutes && !getFieldMeta(propertyName).value) {
              setFieldValue(name.loginSlidingExpirationMinutes, undefined)
            }
          }

          const activeIsDirty = getFieldMeta("active").value !== getFieldMeta("active").initialValue

          return (
            <Form translate="yes">
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_HEADER_TITLE')}
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>


                  <>
                    <Box overflow="hidden">
                      <Tabs indicatorColor="primary" textColor="primary" value={mainTabValue} onChange={handleChange} centered={true}>
                        <Tab label={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_0_TITLE')} {...a11yProps(0)} />
                        <Tab label={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_1_TITLE')} {...a11yProps(1)} />
                        <Tab label={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_2_TITLE')} {...a11yProps(2)} />
                        <Tab label={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_TAB_3_TITLE')} {...a11yProps(3)} />
                      </Tabs>

                      <TabPanel value={mainTabValue} index={0}>
                        {mainTabValue === 0 && (
                          <Box my={3}>
                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_TITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <FilledTextField
                                    name={name.name}
                                    color="secondary"
                                    fullWidth
                                  />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>


                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_REGISTRATIONNUMBER_TITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <FilledTextField
                                    name={name.registrationNumber}
                                    color="secondary"
                                    fullWidth
                                  />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>

                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_EXPECTEDEMAILDOMAIN_TITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <FilledTextField
                                    name={name.expectedEmailDomain}
                                    color="secondary"
                                    fullWidth
                                  />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>
                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ACTIVE_TITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.active} onChange={toggleSwitch(name.active)} disabled={values.isAnonymized} />
                                  {activeIsDirty && !values.active && (
                                    <Typography color="error">
                                      {f('SUPPORT_ORGANIZATION_MODAL_DIALOG_INACTIVE_AND_DIRTY_TITLE')}
                                    </Typography>
                                  )}
                                </Box>
                                <Box>
                                  {!activeIsDirty && !values.active && (
                                    <Button variant='contained' disabled={values.isAnonymized} onClick={openAnonymizationVerification}>
                                      {f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_ANONYMIZE')}
                                    </Button>
                                  )}
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>
                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ORGANIZATION_IP_RANGE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <FilledTextField
                                    name={name.deviceIpRange}
                                    color="secondary"
                                    fullWidth
                                  />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>
                          </Box>
                        )}
                      </TabPanel>

                      <TabPanel value={mainTabValue} index={1}>
                        {mainTabValue === 1 && (
                          <Box my={3}>

                            <ModalSection
                              title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_CUSTOMERPAUSEDPERIODDAYSHORIZON_TITLE')}
                              subtitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_CUSTOMERPAUSEDPERIODDAYSHORIZON_SUBTITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <FilledTextField
                                    name={name.customerPausedPeriodDaysHorizon}
                                    color="secondary"
                                    fullWidth
                                  />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>


                            <ModalSection
                              title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTARTEDACTIVITYSTATUS_TITLE')}
                              subtitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTARTEDACTIVITYSTATUS_SUBTITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.allowStartedActivityStatus} onChange={toggleSwitch(name.allowStartedActivityStatus)} />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>

                            <ModalSection
                              title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWAREATEMPLATEACTIVITIES_TITLE')}
                              subtitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWAREATEMPLATEACTIVITIES_SUBTITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.allowAreaTemplateActivities} onChange={toggleSwitch(name.allowAreaTemplateActivities)} />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>

                            <ModalSection
                              title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWCOMMENTONSIGNING_TITLE')}
                              subtitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWCOMMENTONSIGNING_SUBTITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.allowCommentOnSigning} onChange={toggleSwitch(name.allowCommentOnSigning)} />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>

                            <ModalSection
                              title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTANDARDLOGIN_TITLE')}
                              subtitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSTANDARDLOGIN_SUBTITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.allowStandardLogin} onChange={toggleSwitch(name.allowStandardLogin)} />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>


                            <ModalSection
                              title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_LOGINSLIDINGEXPIRATIONMINUTES_TITLE')}
                              subtitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_LOGINSLIDINGEXPIRATIONMINUTES_SUBTITLE')}>
                              <ModalSectionRow>
                                <Box>
                                  <Switch checked={values.useLoginSlidingExpirationMinutes} onChange={toggleSwitch(name.useLoginSlidingExpirationMinutes)} />
                                </Box>
                              </ModalSectionRow>
                              {values.useLoginSlidingExpirationMinutes && (
                                <ModalSectionRow>
                                  <Box width="10%">
                                    <FilledTextField
                                      name={name.loginSlidingExpirationMinutes}
                                      color="secondary"
                                      fullWidth
                                      disabled={!values.useLoginSlidingExpirationMinutes}
                                      label={f('BASIC_TEXT_MINUTES')}
                                    />
                                  </Box>
                                </ModalSectionRow>
                              )}
                            </ModalSection>
                          </Box>
                        )}
                      </TabPanel>



                      <TabPanel value={mainTabValue} index={2}>
                        {mainTabValue === 2 && (
                          <Box my={3}>
                            <Typography mb={3} color="error">{f('SUPPORT_ORGANIZATION_MODAL_DIALOG_FEATURE_INFO')}</Typography>

                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_USEDELEGATIONS_TITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.useDelegations} onChange={toggleSwitch(name.useDelegations)} />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>

                            {values.useDelegations && (
                              <Box sx={{ ...commonStyles, borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, width: "50%", marginBottom: 3, marginTop: -5, background: "linear-gradient(white, lightgray)" }}>
                                <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_EXPIRINGDELEGATIONSWEEKSHORIZON_TITLE')}>
                                  <ModalSectionRow>
                                    <Box width="50%">
                                      <FilledTextField
                                        name={name.expiringDelegationsWeeksHorizon}
                                        color="secondary"
                                        fullWidth
                                      />
                                    </Box>
                                  </ModalSectionRow>
                                </ModalSection>



                                <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ALLOWSIGNINGWHENDELEGATIONSISNOTFULFILLED_TITLE')}>
                                  <ModalSectionRow>
                                    <Box width="50%">
                                      <Switch checked={values.allowSigningWhenDelegationsIsNotFulfilled} onChange={toggleSwitch(name.allowSigningWhenDelegationsIsNotFulfilled)} />
                                    </Box>
                                  </ModalSectionRow>
                                </ModalSection>
                              </Box>
                            )}


                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_USEDRUGLISTS_TITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.useDrugLists} onChange={toggleSwitch(name.useDrugLists)} />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>

                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_USEWORKSCHEDULE_TITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <Switch checked={values.useWorkSchedule} onChange={toggleSwitch(name.useWorkSchedule)} />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>



                          </Box>
                        )}
                      </TabPanel>


                      <TabPanel value={mainTabValue} index={3}>
                        {mainTabValue === 3 && (
                          <Box my={3}>
                            <ModalSection title={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_GRANDIDORGANIZATIONIDENTIFIER_TITLE')} subtitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_GRANDIDORGANIZATIONIDENTIFIER_SUBTITLE')}>
                              <ModalSectionRow>
                                <Box width="50%">
                                  <FilledTextField
                                    name={name.grandIdOrganizationIdentifier}
                                    color="secondary"
                                    fullWidth
                                  />
                                </Box>
                              </ModalSectionRow>
                            </ModalSection>

                            <ContainedButton type="button" color="secondary" onClick={handleAddNewGrandIdSettingClick}>
                              {f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ADD_GRANDID_SETTING_BUTTON_TEXT')}
                            </ContainedButton>

                            <DataGrid
                              disableColumnFilter
                              sx={{
                                minHeight: '30vh',
                                maxHeight: '30vh',
                                '.MuiDataGrid-cell': { borderBottom: '0px' }
                              }}
                              rows={selectedOrganization?.organizationGrandIdSettings as any}
                              disableSelectionOnClick
                              components={{ Toolbar: CustomToolbar }}
                              onRowClick={handleGrandIdSettingRowClick}
                              localeText={muiDataGridLocale(f)()}

                              columns={[
                                {
                                  flex: 1,
                                  headerName: f('SUPPORT_GIDS_COLUMN_GRANDIDSERVICEID'),
                                  field: 'grandIdServiceId',
                                },
                                {
                                  flex: 1,
                                  headerName: f('SUPPORT_GIDS_COLUMN_GRANDIDPARAMETERID'),
                                  field: 'grandIdParameterId',
                                },
                                {
                                  headerName: ' ',
                                  flex: 2,
                                  field: 'no_field_REMOVE_SETTING',
                                  filterable: false,
                                  sortable: false,
                                  disableColumnMenu: true,
                                  renderCell: (params: GridRenderCellParams) => {
                                    return (
                                      <Button href="" variant="text" color="secondary" onClick={(e) => handleRemoveGrandIdSettingClick(e, params.row)}>
                                        {f('BASIC_BUTTON_REMOVE_TEXT')}
                                      </Button>
                                    )
                                  },
                                },


                              ]}

                              pageSize={pageSize}
                              onPageSizeChange={setPageSize}
                              rowsPerPageOptions={[25, 50, 100]}
                              pagination

                            />

                          </Box>
                        )}
                      </TabPanel>



                    </Box>
                  </>
                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeSupportOrganizationModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedOrganization ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
      <DeleteDialog
        deleteDialogOpen={displayAnonymizationVerification}
        dialogTitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_TITLE')}
        dialogContentText={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_MESSAGE')}
        buttonCancelText={f('BASIC_BUTTON_CANCEL_TEXT')}
        buttonSubmitText={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_ANONYMIZATION_VERIFICATION_OK')}
        onSubmit={handleAnonymization}
        onClose={closeAnonymizationVerification}
      />

      <DeleteDialog
        deleteDialogOpen={!!settingToRemove}
        dialogTitle={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_TITLE')}
        dialogContentText={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_MESSAGE')}
        buttonCancelText={f('BASIC_BUTTON_CANCEL_TEXT')}
        buttonSubmitText={f('SUPPORT_ORGANIZATION_MODAL_DIALOG_REMOVE_GRANDID_SETTING_VERIFICATION_OK')}
        onClose={handleRemoveGrandIdSettingDialogClose}
        onSubmit={handleRemoveGrandIdSettingDialog}
      />

    </CenterModal>
  )
}

export default SupportOrganizationModalContainer
