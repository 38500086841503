import * as React from 'react'
import { useField, FieldAttributes } from "formik"
import { useFormatMessage } from 'localization'
import { UserReportSelectionItem, ReportSelectionListItemValue } from 'api/tenilo.reporting.api-client'
import { TextField, Autocomplete } from "@mui/material"
import { isEmpty, isArray } from 'lodash'

type ReportSelectListProps = { multiple:boolean, options: any[], initialValues?: UserReportSelectionItem, onChangeCb?: () => void ,lableKey: string, required?:boolean , includedInForm?:boolean,textFieldLable?: string, filterOptions?: <T>(options: T[], state: Record<string, any>) => T[] } & FieldAttributes<Record<string, unknown>>

const ReportSelectList: React.FC<ReportSelectListProps> = ({
  multiple,
  lableKey,
  textFieldLable,
  options,
  filterOptions,
  required,
  onChangeCb,
  initialValues,
  includedInForm,
  ...props
}) => {
  const [field, meta, helpers] = useField<Record<string, unknown>>(props)

  const f = useFormatMessage()
  const getOptionLable = React.useCallback((option:any)=>{
    return option[lableKey] || ""
  },[])

  const handleChange = React.useCallback((e,newValue:any)=>{
    if(onChangeCb) {onChangeCb()}
    helpers.setValue(newValue)
  },[])

  const handleBlur = React.useCallback((e)=>{
    helpers.setTouched(true)
  },[])

  const checkOptionEquality = ( option, value ) => JSON.stringify(option) === JSON.stringify(value)

  React.useEffect(()=>{
    if(required && !multiple && !field.value && !meta.error){
      helpers.setError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
    }
    if(required && multiple && (!field.value || isEmpty(field.value)) && !meta.error){
      helpers.setError(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
    }
  },[field,meta])

  React.useEffect(()=>{
    if(initialValues){
      const stringValues = initialValues.selectedValues.split('@Sep@')
      const values = options.filter((option:ReportSelectionListItemValue)  => stringValues.includes(option.id.toString()))
      if(!isEmpty(values)){
        if(multiple){
          helpers.setValue(values as unknown as Record<string, unknown>)
        }else{
          helpers.setValue(values[0] as Record<string, unknown>)
        }
      }
    }else{
      if(required){
        helpers.setValue(options[0])
      }
    }
  },[initialValues,options,multiple])

  const getValue = () => {
    if(field.value){
      if(!isArray(field.value) && multiple){
        return [field.value]
      }
      return field.value
    }
    if(multiple){
      return []
    }
    return null
  }

  return  React.useMemo(()=>{
    return (
      <Autocomplete
      data-matomo-mask
      value={getValue()}
      onChange={handleChange}
      multiple={multiple}
      onBlur={handleBlur}
      options={options}
      limitTags={10}
      disableCloseOnSelect={multiple}
      isOptionEqualToValue={checkOptionEquality}
      getOptionLabel={getOptionLable}
      renderOption={(props, option:any) => {
        return (
          <li data-matomo-mask {...props} key={`${props.id}-${option[lableKey]}`}>
            {option[lableKey]}
          </li>
        )
      }}
      renderInput={(params) => <TextField data-matomo-mask {...params} label={textFieldLable} helperText={(meta.touched && meta.error) ? meta.error : includedInForm ? ' ' : '' } error={meta.touched && !!meta.error}/>}
      />
    )

  },[field.value,meta.error,field.name,meta.touched, includedInForm,options])
}

export default React.memo(ReportSelectList)
