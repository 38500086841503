import { Box, Typography } from '@mui/material'
import format from 'utilities/format'
import { IPlannedRoutesActivitiesPlanningViewItem } from 'api/tillit.api-client'
import { ReactComponent as MoreVert } from 'assets/images/icons/more_vert.svg'
import React from 'react'
import styled from 'styled-components'
import { RoleEnum } from 'api/tillit.api-client'
import { PlanningComplianceItem } from 'api/tenilo.workschedule.api-client'
import { Alert as ComplianceWarning } from 'mdi-material-ui'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import Authentication from 'components/authentication'
import { Authorize } from 'components/authorization'
import { IRouteMenuOption, useRouteMenuOptions } from './route-menu-options'

interface IRouteMenuContainer {
  route: IPlannedRoutesActivitiesPlanningViewItem
  handleClickMenuOptionParent?: (name: IRouteMenuOption['name'], route: IPlannedRoutesActivitiesPlanningViewItem) => void
  type: 'areas' | 'devices'
  routePlanningCompliance: PlanningComplianceItem | undefined
}

const RouteMenuContainer: React.FC<IRouteMenuContainer> = ({ route, handleClickMenuOptionParent, type, routePlanningCompliance }) => {
  const f = useFormatMessage()
  const { nonCompliantRoutesForPlannedUserWorkSchedule } = PlanningPageContext.useState()
  const nonCompliant = nonCompliantRoutesForPlannedUserWorkSchedule?.some(ncr => ncr.routeId === route.id) ? true : false
  const { usingCalendarFromDevice } = Authentication.useAuthenticatedState()

  const handleClickMenuOption = React.useCallback(
    (name: IRouteMenuOption['name']) => () => {
      if (handleClickMenuOptionParent) {
        handleClickMenuOptionParent(name, route)
      }
    },
    [handleClickMenuOptionParent, route]
  )

  const [popover, setPopover] = React.useState<boolean>(false)

  const handlePopover = (val: boolean) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setPopover(val)
  }

  const { routeMenuOptions } = useRouteMenuOptions(type)

  let shouldShowPlanningComplianceWarning = false
  if (routePlanningCompliance) {
    shouldShowPlanningComplianceWarning = routePlanningCompliance.nonCompliantUserWorkSchedules.length > 0 ||
      routePlanningCompliance.notSatisfiedPlannedActivities.length > 0 ||
      routePlanningCompliance.plannedActivitiesOutsideWorkSchedules.length > 0 ||
      routePlanningCompliance.workSchedulesInConflict.length > 0
  }

  return (
    <StyledRouteMenu color={route.color!} nonCompliant={nonCompliant}>
      <Box className={'left-pane'} />
      <Typography
        data-matomo-mask
        variant="body2"
        style={{
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
        {shouldShowPlanningComplianceWarning && (
          <HtmlTooltip
            title={
              <>

                <>
                  {routePlanningCompliance && routePlanningCompliance.notSatisfiedPlannedActivities.length > 0 && (
                    <Typography variant="subtitle2" pt="1vh" fontWeight={700}>{f('ROUTE_MENU_PLANNING_COMPLIANCE_NOT_SATISFIED_REQUIREMENTS')}</Typography>
                  )}
                  {routePlanningCompliance && routePlanningCompliance.notSatisfiedPlannedActivities.sort((a, b) => a.startTime.getTime() - b.startTime.getTime()).map((nspa,i)=> (
                    <React.Fragment key={i}>
                      <Typography data-matomo-mask variant="subtitle2" fontWeight={600}>{`${format(nspa.startTime, 'HH:mm')}-${format(nspa.endTime, 'HH:mm')}, ${nspa.customerName ? nspa.customerName : f('ROUTE_MENU_PLANNING_COMPLIANCE_COMMOM_ACTIVITY')}: ${nspa.title}`}</Typography>
                      {
                        nspa.notFulfilledDelegations.map(nffd => (
                          <Typography key={`nspa_nffd_${nffd.id}`} variant="subtitle2" fontWeight={400}> • {nffd.title}</Typography>
                        ))
                      }
                    </React.Fragment>
                  ))}
                </>

                <>
                  {routePlanningCompliance && routePlanningCompliance.nonCompliantUserWorkSchedules.length > 0 && (
                    <Typography variant="subtitle2" pt="1vh" fontWeight={700}>{f('ROUTE_MENU_PLANNING_COMPLIANCE_NON_COMPLIANT_WORKSCHEDULES')}</Typography>
                  )}
                  {routePlanningCompliance && routePlanningCompliance.nonCompliantUserWorkSchedules.sort((a, b) => a.plannedRouteUserWorkSchedule.scheduledStart.getTime() - b.plannedRouteUserWorkSchedule.scheduledStart.getTime()).map((ncuw ,i)=>
                    <React.Fragment key={i}>
                      <Typography data-matomo-mask variant="subtitle2" fontWeight={600}>{`${ncuw.plannedRouteUserWorkSchedule.substituteUserId ? ncuw.plannedRouteUserWorkSchedule.substituteUserFullName : ncuw.plannedRouteUserWorkSchedule.userFullName} (${format(ncuw.plannedRouteUserWorkSchedule.scheduledStart, 'HH:mm')}-${format(ncuw.plannedRouteUserWorkSchedule.scheduledEnd, 'HH:mm')})`}</Typography>
                      {
                        ncuw.notFulfilledDelegations.map(nffd => (
                          <Typography key={`ncuws_nffd_${nffd.id}`} variant="subtitle2" fontWeight={400}> • {nffd.title}</Typography>
                        ))
                      }
                    </React.Fragment>
                  )}
                </>

                <>
                  {routePlanningCompliance && routePlanningCompliance.plannedActivitiesOutsideWorkSchedules.length > 0 && (
                    <Typography variant="subtitle2" pt="1vh" fontWeight={700}>{f('ROUTE_MENU_PLANNING_COMPLIANCE_OUTSIDE_WORKSCHEDULES')}</Typography>
                  )}
                  {routePlanningCompliance && routePlanningCompliance.plannedActivitiesOutsideWorkSchedules.sort((a, b) => a.startTime.getTime() - b.startTime.getTime()).map((paow,i) => (
                    <React.Fragment key={i}>
                      <Typography data-matomo-mask variant="subtitle2" fontWeight={400}>{`${format(paow.startTime, 'HH:mm')}-${format(paow.endTime, 'HH:mm')},${paow.customerName ? paow.customerName : f('ROUTE_MENU_PLANNING_COMPLIANCE_COMMOM_ACTIVITY')}: ${paow.title}`}</Typography>
                    </React.Fragment>
                  ))}
                </>

                <>
                  {routePlanningCompliance && routePlanningCompliance.workSchedulesInConflict.length > 0 && (
                    <Typography variant="subtitle2" pt="1vh" fontWeight={700}>{f('ROUTE_MENU_PLANNING_COMPLIANCE_WORKSCHEDULES_IN_CONFLICT')}</Typography>
                  )}
                  {routePlanningCompliance && routePlanningCompliance.workSchedulesInConflict.map((wsic,i) =>
                    <React.Fragment key={i}>
                      <Typography data-matomo-mask variant="subtitle2" fontWeight={600}>{`${wsic.plannedRouteUserWorkSchedule.substituteUserId ? wsic.plannedRouteUserWorkSchedule.substituteUserFullName : wsic.plannedRouteUserWorkSchedule.userFullName} (${format(wsic.plannedRouteUserWorkSchedule.scheduledStart, 'HH:mm')}-${format(wsic.plannedRouteUserWorkSchedule.scheduledEnd, 'HH:mm')})`}</Typography>
                      {
                        wsic.conflictingWorkSchedules.sort((a, b) => a.scheduledStart.getTime() - b.scheduledStart.getTime()).map(cws => (
                          <Typography data-matomo-mask key={`ncuws_nffd_${cws.id}`} variant="subtitle2" fontWeight={400}>{` • ${wsic.plannedRouteUserWorkSchedule.areaId !== cws.areaId ? cws.areaName + ' - ' : ''} ${cws.routeName} - (${format(cws.scheduledStart, 'HH:mm')}-${format(cws.scheduledEnd, 'HH:mm')})`}</Typography>
                        ))
                      }
                    </React.Fragment>
                  )}
                </>

              </>
            }
          >
            <ComplianceWarning color='error' style={{ height: '20px', marginTop: '0vh' }} />
          </HtmlTooltip>
        )
        }
        {route.name}
      </Typography >
      <Authorize allowedRoles={[RoleEnum.Admin]}>
        {!usingCalendarFromDevice && (
          <>
            <span className="icon" onMouseEnter={handlePopover(true)} onMouseLeave={handlePopover(false)}>
              <MoreVert />
            </span>
            {popover && (
              <Popover onMouseEnter={handlePopover(true)} onMouseLeave={handlePopover(false)}>
                {routeMenuOptions.map((option) => (
                  <li onClick={handleClickMenuOption(option.name)} key={option.name}>
                    <Typography variant="subtitle2">{option.label}</Typography>
                  </li>
                ))}
              </Popover>
            )}
          </>
        )}
      </Authorize>
    </StyledRouteMenu >
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    zIndex: 1000000,
    maxWidth: 'none',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.438), 0 1px 2px rgba(0, 0, 0, 0.65)',
  },
}));

const StyledRouteMenu = styled.div<{ color: string, nonCompliant: boolean }>`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color:  #fff;
  border: ${({ nonCompliant }) => nonCompliant ? 'rgba(255, 0, 0, 0.82) solid 3px;' : 'rgba(0, 0, 0, 0.12) solid 1px;'};
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 15px;

  .icon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    align-items: center;
  }
  .left-pane {
    background-color: ${({ color }) => color};
    border-radius: 25px;
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
    margin: 5px;
    border: 1px solid #6e6e6e;
  }
`

const Popover = styled.ul`
  position: absolute;
  top: 8px;
  right: 0;
  white-space: nowrap;
  z-index: 9999;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.438), 0 1px 2px rgba(0, 0, 0, 0.65);
  background: #ffffff;

  li {
    list-style-type: none;
    padding: 5px;
    cursor: pointer;
  }

  li:hover {
    background: #ebebeb;
  }
`


export default RouteMenuContainer
