import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridRowParams, GridCallbackDetails, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import Spinner from 'components/spinner'
import { Delete } from 'mdi-material-ui'
import { TemplateActivityListItem } from 'api/tillit.api-client'

import { IFlatTemplateActivityGroupListItem } from 'api/template-activity-api'

import Authentication from 'components/authentication'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import ContainedButton from 'components/inputs/contained-button'
import { useFormatMessage } from 'localization'
import React from 'react'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterTemplateActivities } from 'utilities/template-activity-helper'

import { TemplateActivityModalContext } from '../template-activity-modal/template-activity-modal-context'
import { AdministerAreaTemplateActivitiesContext } from './administer-area-template-activities-context'


const AdministerAreaTemplateActivitiesView = () => {
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const {
    templateActivityListItems,
    removeDialogOpen,
    templateActivityToRemove,
    isLoading,
    removeInvalidDialogOpen,
    templateActivityInvalidToRemove,
  } = AdministerAreaTemplateActivitiesContext.useState()
  const { openRemoveDialog, closeRemoveDialog, deleteTemplateActivityAsync, closeRemoveInvalidDialog } = AdministerAreaTemplateActivitiesContext.useActions()

  const { openTemplateActivityModal, setOwnerOrganizationId } = TemplateActivityModalContext.useActions()

  const f = useFormatMessage()
  const getFilteredTemplateActivities = filterTemplateActivities(f)
  const handleAddNewActivityTemplateClick = React.useCallback(() => {
    setOwnerOrganizationId(undefined)
    openTemplateActivityModal()
  }, [openTemplateActivityModal, setOwnerOrganizationId])

  const handleRemoveTemplateActivityClick = React.useCallback(
    (
      _: React.MouseEvent<Element, MouseEvent> | undefined,
      templateActivity: IFlatTemplateActivityGroupListItem //TemplateActivityListItem
    ) => {
      _?.stopPropagation()
      openRemoveDialog(templateActivity)
    },
    [openRemoveDialog]
  )

  const handleConfirmRemoveTemplateActivityClick = React.useCallback(() => deleteTemplateActivityAsync(templateActivityToRemove!.id), [
    templateActivityToRemove,
    deleteTemplateActivityAsync,
  ])
  const handleRowClick = React.useCallback(
    async (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
      openTemplateActivityModal(params.row.id)
    },
    [openTemplateActivityModal]
  )

  const sortAccordingToTitle = (title1: string, title2: string): number => {
    if (title1 && title2 && title1 < title2) {
      return -1
    }
    if (title1 && title2 && title1 > title2) {
      return 1
    }
    return 0
  }

  const theme = useTheme()


  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredTemplateActivities, setFilteredTemplateActivities] = React.useState<TemplateActivityListItem[]>(templateActivityListItems)


  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredTemplateActivities(filterValue, templateActivityListItems)
      setFilteredTemplateActivities(filtered)
    } else {
      setFilteredTemplateActivities(templateActivityListItems)
    }
    // NOTE: We are missing filterValue and getFilteredTemplateActivities as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [templateActivityListItems])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredTemplateActivities(searchValue, templateActivityListItems)
      setFilteredTemplateActivities(filtered)
    },
    [setFilterValue, setFilteredTemplateActivities, templateActivityListItems, getFilteredTemplateActivities]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredTemplateActivities(templateActivityListItems)
    },
    [setFilterValue, setFilteredTemplateActivities, templateActivityListItems]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div style={{ padding: '0.5vh' }}>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        <>

          {currentUser.organizationId ? (
            <div style={{ columnCount: 2 }}>
              <ContainedButton type="button" color="secondary" onClick={handleAddNewActivityTemplateClick}>
                {f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_ADD_BUTTON_TEXT')}
              </ContainedButton>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          ) : (
            <div style={{ columnCount: 2 }}>
              <div>&nbsp;</div>
              <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
                <FilledSearchTextField
                  label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
                  handleChangeFilter={handleChangeFilter}
                  handleClearFilter={handleClearFilter}
                  searchValue={filterValue}
                />
              </div>
            </div>
          )}

          <DataGrid
            disableColumnFilter
            sx={{
              minHeight: '70vh',
              maxHeight: '70vh',
              '.MuiDataGrid-cell': { borderBottom: '0px' }
            }}
            rows={filteredTemplateActivities}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleRowClick}

            localeText={muiDataGridLocale(f)()}

            columns={[
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_TITLE'),
                field: 'title',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_AREA'),
                field: 'ownerAreaName',

              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_ORGANIZATION_COLUMN_TYPE'),
                field: 'templateActivityTypeId',
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      {params.row.templateActivityTypeId === "CustomerTemplate"  ? f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_CUSTOMER') : f('ADMINISTER_TEMPLATE_ACTIVITIES_MODAL_DIALOG_TYPE_AREA')}
                    </span>
                  )
                },
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_CATEGORY'),
                field: 'templateActivityCategoryTitle',
              },
              {
                flex: 1,
                headerName: f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_COLUMN_LEGISLATION'),
                field: 'templateActivityLegislationTitle',
              },
              {
                headerName: ' ',
                flex: 0.2,
                field: 'no_field_deactivate',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <span>
                      <Tooltip
                        title={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.title,
                        })}
                        aria-label={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_BUTTON_TOOLTIP', {
                          name: params.row.title,
                        })}>
                        <IconButton onClick={e => handleRemoveTemplateActivityClick(e, params.row)} size="large">
                          <Delete color={'secondary'} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )
                },
              },
            ]}

            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            pagination

          />

          {removeDialogOpen && templateActivityToRemove !== null && (
            <DeleteDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_MESSAGE', {
                name: templateActivityToRemove.title,
              })}
              buttonSubmitText={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_ACTIONS_REMOVE')}
              buttonCancelText={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_DIALOG_ACTIONS_CANCEL')}
              deleteDialogOpen
              onClose={closeRemoveDialog}
              onSubmit={handleConfirmRemoveTemplateActivityClick}
            />
          )}

          {removeInvalidDialogOpen && templateActivityInvalidToRemove !== null && (
            <OkDialog
              dialogTitle={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_TITLE')}
              dialogContentText={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_MESSAGE', {
                title: templateActivityInvalidToRemove.title,
              })}
              buttonOkText={f('ADMINISTER_TEMPLATE_ACTIVITIES_AREA_DELETE_INVALID_DIALOG_ACTIONS_OK')}
              okDialogOpen
              onClose={closeRemoveInvalidDialog}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdministerAreaTemplateActivitiesView
