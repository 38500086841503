import { useCustomerApi } from 'api'
import {
  ICustomerPausedPeriodCreateItem,
  ICustomerPausedPeriodItem,
} from 'api/tillit.api-client'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'
import { subDays } from 'date-fns'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi, { PromiseState } from 'utilities/use-promise'
import { CustomerPausedPeriodFormModel } from './pause-period-dialog/utilities/use-paused-period-dialog-form-data'

type PausedPeriodState = Readonly<
  {
    modalOpen: boolean
    dialogActionType: 'add' | 'edit'
    pausedPeriod: ICustomerPausedPeriodItem | null
    deleteDialogOpen: boolean
  } & PromiseState
>

const usePausedPeriodContext = () => {
  const { createPausedPeriod, updatePausedPeriod } = useCustomerApi()

  const { customerInfo } = CustomerModalContext.useState()
  const { fetchCustomerPausePeriodsAsync } = CustomerModalContext.useActions()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
  const [deleteNotAllowedDialogOpen, setDeleteNotAllowedDialogOpen] = React.useState(false)
  
  const [dialogActionType, setDialogActionType] = React.useState<
    PausedPeriodState['dialogActionType']
  >('add')

  const [
    pausedPeriod,
    setPausedPeriod,
  ] = React.useState<ICustomerPausedPeriodItem | null>(null)

  const openModal = React.useCallback(
    (pausedPeriodItem?: ICustomerPausedPeriodItem) => {
      if (pausedPeriodItem) {
        setDialogActionType('edit')
        setPausedPeriod(pausedPeriodItem)
      } else {
        setDialogActionType('add')
      }
      setModalOpen(true)
    },
    []
  )

  const closeModal = React.useCallback(() => {
    setDeleteDialogOpen(false)
    setModalOpen(false)
    setPausedPeriod(null)
  }, [])

  const openDeleteDialog = React.useCallback(() => {
    setDeleteDialogOpen(true)
  }, [])

  const openDeleteNotAllowedDialog = React.useCallback(() => {
    setDeleteNotAllowedDialogOpen(true)
  }, [])

  const closeDeleteNotAllowedDialog = React.useCallback(() => {
    setDeleteNotAllowedDialogOpen(false)
    setPausedPeriod(null)
  }, [])

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteDialogOpen(false)
    setPausedPeriod(null)
  }, [])

  const handleDeletePausedPeriod = React.useCallback(
    (pausedPeriodItem: ICustomerPausedPeriodItem) => {
      setPausedPeriod(pausedPeriodItem)
      const isStartDateIsPassed = pausedPeriodItem.startDateTime && pausedPeriodItem.startDateTime < (new Date())
      if (isStartDateIsPassed) {
        openDeleteNotAllowedDialog()
      } else {
        openDeleteDialog()
      }
    },
    [openDeleteDialog, openDeleteNotAllowedDialog]
  )

  const createPausedPeriodAsync = React.useCallback(
    async (pausedPeriodProp: CustomerPausedPeriodFormModel) => {
      const pausedPeriodItem = await fetchAsync(
        createPausedPeriod({
          ...pausedPeriodProp,
          customerId: customerInfo!.id,
        } as ICustomerPausedPeriodCreateItem)
      )
      fetchCustomerPausePeriodsAsync(customerInfo!.id)
      closeModal()
      return pausedPeriodItem
    },
    [
      fetchAsync,
      createPausedPeriod,
      customerInfo,
      fetchCustomerPausePeriodsAsync,
      closeModal,
    ]
  )

  const updatePausedPeriodAsync = React.useCallback(
    async (pausedPeriodProp: CustomerPausedPeriodFormModel) => {
      const pausedPeriodItem = await fetchAsync(
        updatePausedPeriod(pausedPeriodProp as ICustomerPausedPeriodItem)
      )
      fetchCustomerPausePeriodsAsync(customerInfo!.id)
      closeModal()
      return pausedPeriodItem
    },
    [
      fetchAsync,
      updatePausedPeriod,
      fetchCustomerPausePeriodsAsync,
      customerInfo,
      closeModal,
    ]
  )

  const deletePausedPeriodAsync = React.useCallback(
    async (pausedPeriodProp: CustomerPausedPeriodFormModel) => {
      const pausedPeriodItem = await fetchAsync(
        updatePausedPeriod({
          ...pausedPeriodProp,
          endDateTime: subDays(pausedPeriodProp.startDateTime!, 1),
        } as ICustomerPausedPeriodItem)
      )
      fetchCustomerPausePeriodsAsync(customerInfo!.id)
      closeModal()
      return pausedPeriodItem
    },
    [
      fetchAsync,
      updatePausedPeriod,
      fetchCustomerPausePeriodsAsync,
      customerInfo,
      closeModal,
    ]
  )

  return {
    state: {
      ...stateOfFetch,
      modalOpen,
      dialogActionType,
      pausedPeriod,
      deleteDialogOpen,
      deleteNotAllowedDialogOpen,
    },
    actions: {
      openModal,
      closeModal,
      createPausedPeriodAsync,
      updatePausedPeriodAsync,
      deletePausedPeriodAsync,
      closeDeleteDialog,
      closeDeleteNotAllowedDialog,
      handleDeletePausedPeriod,
    },
  }
}

export const PausedPeriodContext = createContext(usePausedPeriodContext)
