import React from 'react'
import { createContext } from 'utilities/create-context'
import Authentication from 'components/authentication'
import isUndefined from 'lodash/isUndefined'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import { useOrganizationApi } from 'api'
import {
  OrganizationUpdateinternalInformationItem
} from 'api/tillit.api-client'
import usePromiseApi from 'utilities/use-promise'

const useOrganizationInternalInfoModalContext = () => {

  const [ currInternalInfo, setCurrInternalInfo ] = React.useState<string>()
  const { setOrganizationInternalInformationModalOpen} = ProfilePageContext.useActions()

  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const {
    user: { organizationId },
  } = Authentication.useAuthenticatedState()

  const { getOrganization, abort, updateOrganizationinternalInformation } = useOrganizationApi()

  React.useEffect(() => () => abort(), [])

  React.useEffect(()=>{
    if(isUndefined(currInternalInfo) && organizationId){
      fetchAsync(getOrganization(organizationId)).then(org => {
        setCurrInternalInfo(org.internalInformation || '')
      })
    }
  },[organizationId, currInternalInfo])

  const handleUpdateOrgInternalInfo = React.useCallback((internalInfo:string | undefined)=>{
    if(!organizationId) return
    const model = new OrganizationUpdateinternalInformationItem({id: organizationId, internalInformation: internalInfo})
    return fetchAsync(updateOrganizationinternalInformation(model))
    .then(org => {
      setCurrInternalInfo(org.internalInformation || '')
      setOrganizationInternalInformationModalOpen(false)
    })
    .catch(err => {throw err})
  },[organizationId])


  return {
    state: {
      stateOfFetch,
      currInternalInfo,
      organizationId
    },
    actions: {
      handleUpdateOrgInternalInfo
    },
  }
}

export const OrganizationInternalInfoContext = createContext(useOrganizationInternalInfoModalContext)
