import { useTheme } from '@mui/material'
import Spinner from 'components/spinner'
import React from 'react'
import CenterModal from 'components/center-modal'
import ErrorBoundary from 'components/error-boundary'
import { ManageDeviceDialogContext } from './manage-device-dialog-context'
import ManageDeviceDialogView from './manage-device-dialog-view'

const ManageDeviceDialogContainer: React.FC = () => {
  const theme = useTheme()
  const { dialogOpen, isLoading, device } = ManageDeviceDialogContext.useState()
  const { handleCloseDialog } = ManageDeviceDialogContext.useActions()

  return (
    <>
      {isLoading ? (
        <Spinner size={64} color={theme.palette.primary.main} />
      ) : (
        !!device && (
          <CenterModal open={dialogOpen} onClose={handleCloseDialog} fullSize>
            <ErrorBoundary>
              <ManageDeviceDialogView />
            </ErrorBoundary>
          </CenterModal>
        )
      )}
    </>
  )
}

export default ManageDeviceDialogContainer
