import React from 'react'
import * as yup from 'yup'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { WorkScheduleContext } from '../work-schedule-context'

type ConnectWorkscheduleToAreasForm = Readonly<{
  commonShiftsIds: number[];
  areaIds: number[];
}>

const useConnectWorkscheduleToAreasFormData = ( selectedWorkscheduleIds?: number[] ) => {
  const f = useFormatMessage()

  const { handleConnectCommonShiftsToAreas, handleCloseConnectWorkscheduleModal, fetchListCommonShifts, setAllowBulkAction } = WorkScheduleContext.useActions()

  const initialValues = React.useMemo<ConnectWorkscheduleToAreasForm>(() => {
    return {
      commonShiftsIds: selectedWorkscheduleIds || [],
      areaIds: [],
    }
  }, [selectedWorkscheduleIds])

  const onSubmit: OnSubmitFunction<ConnectWorkscheduleToAreasForm> = React.useCallback(
    async (values) => {
      handleConnectCommonShiftsToAreas(values).then(fetchListCommonShifts).then(handleCloseConnectWorkscheduleModal).then(()=>{
        setAllowBulkAction(false)
      })
    },
    []
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
    })
  }, [f])

  const name: Name<ConnectWorkscheduleToAreasForm> = React.useMemo(
    () => ({
      commonShiftsIds: 'commonShiftsIds',
      areaIds: 'areaIds'
    }), [])

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useConnectWorkscheduleToAreasFormData
