import React from 'react'
import { useSupportUserImpersonationRequestsApi } from 'api'
import { UserImpersonationRequestListItem } from 'api/tenilo.support.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { useLocation } from 'react-router-dom'

const useSupportUserImpersonationRequestsContext = () => {
  const { listUserImpersonationRequests, deleteUserImpersonationRequest, abort } = useSupportUserImpersonationRequestsApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [userImpersonationRequestListItems, setUserImpersonationRequestListItems] = React.useState<UserImpersonationRequestListItem[]>([])
  const [modalOpen, setModalOpen] = React.useState(false)

  const location = useLocation()

  const fetchUserImpersonationRequestsAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listUserImpersonationRequests().then(setUserImpersonationRequestListItems))
  }, [currentUser.organizationId, fetchAsync, listUserImpersonationRequests])

  const deleteUserImpersonateRequestAsync = React.useCallback(
    (id: number) => {
      return fetchAsync(deleteUserImpersonationRequest(id))
/*        .then(() => {
          closeSupportUserImpersonateModal()
        })
        .catch(() => {
          closeSupportUserImpersonateModal()
        })*/
    },
    [fetchAsync, deleteUserImpersonationRequest]
  )


  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])

  const closeModal = React.useCallback(() => {
    setModalOpen(false)
  }, [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    void fetchUserImpersonationRequestsAsync()
  }, [fetchUserImpersonationRequestsAsync, location])

  return {
    state: {
      ...stateOfFetch,
      userImpersonationRequestListItems,
      modalOpen,
    },
    actions: {
      fetchUserImpersonationRequestsAsync,
      deleteUserImpersonateRequestAsync,
      openModal,
      closeModal,
    },
  }
}

export const SupportUserImpersonationRequestsContext = createContext(useSupportUserImpersonationRequestsContext)
