import { useActivityApi } from 'api'
import { RecentPerformedSigningItem } from 'api/tillit.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useHistoryModalContext = () => {
  const { listRecentPerformedActivitiesOnCustomer } = useActivityApi()

  const { state: fetchState, fetchAsync } = usePromiseApi()

  const [recentPerformedActivities, setRecentPerformedActivities] = React.useState<RecentPerformedSigningItem[]>()
  const [historyModalOpen, setHistoryModalOpen] = React.useState<boolean>(false)
  const [filteredActivities, setFilteredActivities] = React.useState<RecentPerformedSigningItem[] | undefined>(undefined)
  const [filterReccuranceType, setFilterReccuranceType] = React.useState<string | null>('all')

  const listRecentPerformedActivities = React.useCallback(
    (customerId: number) => {
      void fetchAsync(listRecentPerformedActivitiesOnCustomer(customerId)).then(list => {
        setRecentPerformedActivities(list)
        setFilteredActivities(list)
      })
    },
    [fetchAsync, listRecentPerformedActivitiesOnCustomer, setRecentPerformedActivities]
  )

  const openHistoryModal = React.useCallback(
    (customerId: number) => {
      listRecentPerformedActivities(customerId)
      setHistoryModalOpen(true)
    },
    [listRecentPerformedActivities]
  )

  const closeHistoryModal = React.useCallback(() => {
    setRecentPerformedActivities([])
    setFilteredActivities(undefined)
    setHistoryModalOpen(false)
    setFilterReccuranceType('all')
  }, [])

  return {
    state: {
      fetchState,
      recentPerformedActivities,
      historyModalOpen,
      filteredActivities,
      filterReccuranceType,
    },
    actions: {
      openHistoryModal,
      closeHistoryModal,
      setFilteredActivities,
      setFilterReccuranceType,
    },
  }
}

export const HistoryModalContext = createContext(useHistoryModalContext)
