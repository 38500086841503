import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { Box, Button } from '@mui/material'
import type { InnerContainerPropType, ReportsPageFormType } from 'pages/reports/config'
import ReportsPageContext from 'pages/reports/reports-page-context'
import { useFormikContext, FieldArray } from 'formik'
import { ReportSelectionItem } from 'api/tenilo.reporting.api-client'
import { FilterRemoveOutline as RemoveFilter } from 'mdi-material-ui'
import Colors from 'styles/colors'
import ReportsPageSelectionView from '../reports-page-selection'
import ReportsPageSelectionOptionalView from '../reports-page-selection-optional'

const ReportsPageSelectionsSectionView: FC<InnerContainerPropType> = ({ }) => {
  const f = useFormatMessage()
  const { values, setFieldValue } = useFormikContext<ReportsPageFormType>()
  const { report, userReport } = ReportsPageContext.useState()

  const memoizedSelections = React.useMemo(() => {
    let selections: ReportSelectionItem[] = []
    if (report) { selections = report.reportSelections }
    if (userReport) { selections = userReport.parentReport.reportSelections }
    selections = selections.sort((s1, s2) => (f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${s1.selectionId}`) || '').localeCompare(f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${s2.selectionId}`) || '') )
    selections = selections.sort((s1, s2) => (s1.required === s2.required)? 0 : s1.required? -1 : 1 )
    return selections
  }, [report, userReport])

  const memoizedOptionalSelections = React.useMemo(() => {
    let selections: ReportSelectionItem[] = []
    if (report) { selections = report.reportSelections.filter(rs => !rs.required) }
    if (userReport) { selections = userReport.parentReport.reportSelections.filter(rs => !rs.required) }
    return selections.sort((s1, s2) => (f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${s1.selectionId}`) || '').localeCompare(f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${s2.selectionId}`) || '') )
  }, [report, userReport])

  const memoizedUserReportSelectionItem = React.useMemo(() => {
    if (!userReport) { return undefined }
    return userReport.userReportSelections
  }, [report, userReport])

  const removeSelection = React.useCallback((selection: ReportSelectionItem) => () =>{
    setFieldValue("activeOptionalSelections", values.activeOptionalSelections?.filter(aos => aos.selectionId !== selection.selectionId))
    setFieldValue(selection.selectionId, undefined)
  }, [values])

  return React.useMemo(() => {
    return (
      <Box>
      {
        (report || userReport) && (
          memoizedSelections.map((selection) => {
            const selected = userReport?.userReportSelections.filter(urf => urf.reportSelectionId === selection.id)
            let selectedHasValues = false
            if (selected && selected.length > 0) {
              selectedHasValues = selected[0].selectedValues?.length > 0
            }
            const forceShow = (!selection.required && selectedHasValues) || selection.required
            if (!forceShow) {
              if ((!values[selection.selection.value] && (!selection.required && !values.activeOptionalSelections?.some(os => os.selectionId === selection.selectionId)))) {
                return
              }
            }
            return (
              <Box key={`box${selection.id}-${selection.selectionId}`} sx={{display: 'flex'}}>
                <ReportsPageSelectionView key={`${selection.id}-${selection.selectionId}`} {...selection} userReportSelections={memoizedUserReportSelectionItem?.find(selectionItem => selectionItem.reportSelectionId === selection.id)} />
                {!selection.required && (
                  <Button sx={{backgroundColor: Colors.AthensGray, height: '55px', marginLeft: '10px'}} onClick={removeSelection(selection)}><RemoveFilter/></Button>
                )}
              </Box>
            )
          }
          )
        )
      }
      {
        (report || userReport) && (
          <FieldArray name="activeOptionalSelections">
            {
              (arrayHelpers) => {
                return (

                  <Box sx={{ display: 'contents', float: 'left' }}>
                    {(
                      memoizedOptionalSelections.map((selection) => {
                        if (values.activeOptionalSelections?.some(os => os.selectionId === selection.selectionId)) {
                          return
                        }
                        if (!values[selection.selection.value] || values[selection.selection.value].length === 0) {
                          return (
                            <ReportsPageSelectionOptionalView key={`optionalSelection_${selection.id}`} selection={selection} helper={arrayHelpers} />
                          )
                        }
                        return null
                      })
                    )}
                  </Box>
                )
              }
            }
          </FieldArray>
        )
      }
    </Box>
    )
  },[values.activeOptionalSelections,memoizedSelections,report,userReport, memoizedOptionalSelections,  memoizedUserReportSelectionItem])
}

export default ReportsPageSelectionsSectionView
