import { Box, Typography, useTheme, Switch, Button } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import React from 'react'
import { GrandIdParameterEnum, GrandIdServiceEnum } from 'api/tenilo.support.api-client'
import BigCheckbox from 'components/inputs/big-checkbox'
import { GrandIdSettingModalContext } from './grandid-setting-modal-context'
import useFormData from './utilities/use-grandid-setting-form-data'
import OutlinedTextField from 'components/inputs/outlined-text-field'

export type GrandIdSettingModalProps = Readonly<{
  onSave?: () => void
}>

const GrandIdSettingModalContainer: React.FC<GrandIdSettingModalProps> = ({ onSave }) => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { modalOpen, selectedOrganizationGrandIdSetting, isLoading } = GrandIdSettingModalContext.useState()
  const { initialValues, name, onSubmit, validationSchema } = useFormData(selectedOrganizationGrandIdSetting)

  const handleSubmit: typeof onSubmit = async (...props) => {
    await onSubmit(...props)
    if (onSave !== undefined) {
      onSave()
    }
  }

  const { closeGrandIdSettingModal } = GrandIdSettingModalContext.useActions()

  return (
    <CenterModal open={modalOpen} onClose={closeGrandIdSettingModal}>
      <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, getFieldMeta, errors, dirty }) => {


          return (
            <Form translate="yes">
              {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
              <ModalHeader
                title={f('GRANDIDSETTING_MODAL_DIALOG_HEADER_TITLE')}
              />
              <Box mx={3}>
                <div
                  style={{
                    maxHeight: '70vh',
                    minWidth: '70vw',
                    overflow: 'auto',
                  }}>
                  <ModalSection
                    title={f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_TITLE')}
                    subtitle={f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_SAML_TITLE')}`}
                          name={name.grandIdServiceId}
                          value={GrandIdServiceEnum.Saml}
                          checkedByValue
                          returnValue="value"
                        />
                        <BigCheckbox
                          label={`${f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_FREJAEID_TITLE')}`}
                          name={name.grandIdServiceId}
                          value={GrandIdServiceEnum.FrejaEId}
                          checkedByValue
                          returnValue="value"
                        />
                        <BigCheckbox
                          label={`${f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_SERVICE_BANKID_TITLE')}`}
                          name={name.grandIdServiceId}
                          value={GrandIdServiceEnum.BankId}
                          checkedByValue
                          disabled
                          returnValue="value"
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>


                  <ModalSection
                    title={f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_TITLE')}
                    subtitle={f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_SUBTITLE')}>
                    <ModalSectionRow>
                      <>
                        <BigCheckbox
                          label={`${f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETER_APIKEY_TITLE')}`}
                          name={name.grandIdParameterId}
                          value={GrandIdParameterEnum.ApiKey}
                          checkedByValue
                          returnValue="value"
                        />
                      </>
                    </ModalSectionRow>
                  </ModalSection>

                  <ModalSection title={f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETERVALUE_TITLE')} subtitle={f('SUPPORT_ORG_GRANDID_SETTINGS_MODAL_DIALOG_PARAMETERVALUE_SUBTITLE')}>
                    <ModalSectionRow>
                      <Box width="50%">
                        <OutlinedTextField
                          name={name.parameterValue}
                          color="secondary"
                          fullWidth
                          type="password"
                        />
                      </Box>
                    </ModalSectionRow>
                  </ModalSection>

                </div>
              </Box>

              <ModalFooter>
                <TextButton type="button" onClick={closeGrandIdSettingModal} disabled={isSubmitting}>
                  {f('BASIC_BUTTON_CANCEL_TEXT')}
                </TextButton>
                <Box ml={2}>
                  <ContainedButton type="submit" color={'secondary'} showProgress={isSubmitting} disabled={!dirty || isSubmitting}>
                    {!selectedOrganizationGrandIdSetting ? f('BASIC_BUTTON_ADD_TEXT') : f('BASIC_BUTTON_SAVE_TEXT')}
                  </ContainedButton>
                </Box>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>

    </CenterModal>
  )
}

export default GrandIdSettingModalContainer
