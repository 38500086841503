import React from 'react'
import * as yup from 'yup'

import { IAreaItem, AreaUpdateItem } from 'api/tenilo.support.api-client'

import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { SupportAreaModalContext } from '../support-area-modal-context'
import { SupportAreasContext } from '../../../support-areas-context'

type AreaForm = Readonly<{
  organizationName: string,
  areaName: string,
  active: boolean,
  performAutoSign: boolean,
  organizationParentsName: string,
  dateForAutoSigningTurnOff: Date | undefined,
  isAnonymized: boolean,
}>

const mapAreaFormToAreaItem = (initialItem: IAreaItem, item: AreaForm): AreaUpdateItem => {
  return new AreaUpdateItem({
    id: initialItem.id,
    active: item.active,
    performAutoSign: item.performAutoSign,
    dateForAutoSigningTurnOff: item.dateForAutoSigningTurnOff
  })
}

const mapAreaItemToAreaForm = (item: IAreaItem): AreaForm => {
  const mappedItem: AreaForm = {
    organizationName: item.organizationName,
    areaName: item.name,
    active: item.active,
    performAutoSign: item.performAutoSign,
    organizationParentsName: item.organizationParentsName,
    dateForAutoSigningTurnOff: item.dateForAutoSigningTurnOff,
    isAnonymized: item.isAnonymized,
  }
  return mappedItem
}

const useSupportAreaFormData = (initialArea?: IAreaItem) => {
  const f = useFormatMessage()

  const { updateAreaAsync } = SupportAreaModalContext.useActions()
  const { refreshAreasAsync } = SupportAreasContext.useActions()

  const area = initialArea

  const initialValues = React.useMemo<AreaForm>(
    () =>
      !!area
        ? mapAreaItemToAreaForm(area)
        : {
          organizationName: '',
          areaName: '',
          active: false,
          performAutoSign: false,
          organizationParentsName: '',
          dateForAutoSigningTurnOff: undefined,
          isAnonymized: false,
        },
    [area]
  )

  const onSubmit: OnSubmitFunction<AreaForm> = React.useCallback(
    async (values, { setSubmitting }) => {

      if (initialArea) {
        const mappedArea = mapAreaFormToAreaItem(initialArea, values)
        await updateAreaAsync(mappedArea).finally(() => {
          setSubmitting(false)
          refreshAreasAsync()
        })
      }
    },
    [initialArea, updateAreaAsync]
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      /*title: yup
        .string()
        .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
        .max(
          128,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_LEGISLATION_MODAL_DIALOG_TITLE_COMPONENT_LABEL_DEFAULT'),
            maxsize: 128,
          })
        ),
*/
      /*      description: yup.string().max(
              1024,
              f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
                name: f('ADMINISTER_INHERITED_TEMPLATE_ACTIVITIES_MODAL_DIALOG_AREADESCRIPTION_LABEL'),
                maxsize: 1024,
              })
            ),*/
    })
  }, [f])

  const name: Name<AreaForm> = React.useMemo(
    () => ({
      organizationName: 'organizationName',
      areaName: 'areaName',
      active: 'active',
      performAutoSign: 'performAutoSign',
      organizationParentsName: 'organizationParentsName',
      dateForAutoSigningTurnOff: 'dateForAutoSigningTurnOff',
      isAnonymized: 'isAnonymized',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useSupportAreaFormData
