import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  ICommonShiftCreateItem,
  CommonShiftItem,
  ICommonShiftUpdateItem,
  ICommonShiftsToAreasItem
} from 'api/tenilo.workschedule.api-client'
import isEmpty from 'lodash/isEmpty'
import { RoleEnum } from 'api/tillit.api-client'
import { useWorkScheduleApi } from 'api'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { rootUser } from 'utilities/use-user-areas'
import useAuthorization from 'utilities/use-authorization'
import { useDistinctAreas } from 'utilities/use-user-areas'
import { getVisibleAreaData } from 'utilities/area-helper'

type workScheduleModalStateType = Readonly<{
  open: boolean,
  item?: CommonShiftItem
}>

type selectedWorkScheduleModalStateType = Readonly<{
  open: boolean,
  items?: number[]
}>

const useWorkScheduleContext = () => {
  const [commonShifts, setCommonShifts] = useState<CommonShiftItem[]>()
  const { getAdminAreas } = useAuthorization()
  const { listCommonShifts, createCommonShift, updateCommonShift, deleteCommonShift, getCommonShift, hasInheritanceOnCommonShift,connectCommonShiftsToAreas, abort } = useWorkScheduleApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()
  const { distinctAreas } = useDistinctAreas()
  const [allowBulkAction, setAllowBulkAction] = React.useState<boolean>(false)
  const [workScheduleModalState, setWorkScheduleModalState] = React.useState<workScheduleModalStateType>({
    open:false
  })
  const [connectSelectedWorkscheduleModalState, setConnectSelectedWorkscheduleModalState] = React.useState<selectedWorkScheduleModalStateType>({
    open:false,
    items:[]
  });

  const openWorkScheduleModal = useCallback((shift? : CommonShiftItem)=>()=>{
    setWorkScheduleModalState({
      open: true,
      item: shift
    })
  },[])

  const availableAreas = useMemo(()=>{
    const visibleArea = getVisibleAreaData().map(area=>area.areaId)
    if(!rootUser(currentUser)){
      const adminAreas = getAdminAreas([RoleEnum.Planner])
      if(!isEmpty(visibleArea)){
        return distinctAreas(adminAreas).filter(area => visibleArea.includes(area.areaId))
      }
      return distinctAreas(adminAreas)
    }
    if(!isEmpty(visibleArea)){
      return currentUser.userAreas?.filter(area => visibleArea.includes(area.areaId))
    }
    return currentUser.userAreas
  },[currentUser])

  const closeWorkScheduleModal = useCallback(()=>{
    setWorkScheduleModalState({
      open: false,
    })
  },[])

  const fetchListCommonShifts = useCallback(() => {
    if (!currentUser.organizationId) {
      return
    }
    fetchAsync( listCommonShifts(currentUser.organizationId).then( shifts => setCommonShifts(shifts)) )
  }, [currentUser, fetchAsync, listCommonShifts])

  const handleGetCommonShift = useCallback((shiftId : number)=>fetchAsync(getCommonShift(shiftId)),[])

  const handleUpdateCommonShift = useCallback((shift : ICommonShiftUpdateItem)=>fetchAsync(updateCommonShift(shift)),[])

  const handleCreateCommonShift = useCallback((shift : ICommonShiftCreateItem)=>fetchAsync(createCommonShift(shift)),[])

  const handleRemoveCommonShift = useCallback((shiftId : number) => fetchAsync(deleteCommonShift(shiftId)).then(fetchListCommonShifts),[])

  const checkIfHasInheritanceOnCommonShift = useCallback( (shiftId : number) => fetchAsync(hasInheritanceOnCommonShift(shiftId)),[])

  const handleConnectCommonShiftsToAreas = useCallback( ( data : ICommonShiftsToAreasItem ) => fetchAsync(connectCommonShiftsToAreas(data)),[])

  const handleCloseConnectWorkscheduleModal = useCallback(()=>{
    setConnectSelectedWorkscheduleModalState((state)=>({
      open:false,
      items:[]
    }))
  },[])

  useEffect(() => () => abort(), [abort])

  useEffect(() => {
    void fetchListCommonShifts()
  }, [fetchListCommonShifts])


  return {
    state: {
      ...stateOfFetch,
      commonShifts,
      currentUser,
      workScheduleModalState,
      availableAreas,
      connectSelectedWorkscheduleModalState,
      allowBulkAction
    },
    actions: {
      handleGetCommonShift,
      fetchListCommonShifts,
      handleUpdateCommonShift,
      handleCreateCommonShift,
      handleRemoveCommonShift,
      openWorkScheduleModal,
      closeWorkScheduleModal,
      checkIfHasInheritanceOnCommonShift,
      handleConnectCommonShiftsToAreas,
      setConnectSelectedWorkscheduleModalState,
      handleCloseConnectWorkscheduleModal,
      setAllowBulkAction
    }
  }
}

export const WorkScheduleContext = createContext(useWorkScheduleContext)
