import React from 'react'
import BasicInformationSection from 'components/user-dialog/components/basic-information-section'
import { FormikProps } from 'formik'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'

const UserBasicForm: React.FC<FormikProps<UserFormModelWithModifiedAreas>> = ({...props}) => {

  return (
    <BasicInformationSection {...props} />
  )
}

export default UserBasicForm
