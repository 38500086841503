import React from 'react'
import ManageDeviceDialog from '../manage-device-dialog'
import { ManageDeviceDialogContext } from '../manage-device-dialog/manage-device-dialog-context'
import ActivatedDevicesSectionView from './activated-devices-section-view'

const ActivatedDevicesSectionContainer: React.FC = () => {
  return (
    <ManageDeviceDialogContext.Provider>
      <ActivatedDevicesSectionView />
      <ManageDeviceDialog />
    </ManageDeviceDialogContext.Provider>
  )
}

export default ActivatedDevicesSectionContainer
