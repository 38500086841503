import { ISidePanelUsersWorkSchedulesItem } from 'api/tillit.api-client'
import { produce } from 'immer'
import React from 'react'

const filterUsersOnShowStatus = (
  users: ISidePanelUsersWorkSchedulesItem[],
  show: boolean
) => {
  if (show) {
    return users
  }
  return users.reduce<ISidePanelUsersWorkSchedulesItem[]>(
    (
      prev,
      { sidePanelPlannedRouteUserWorkScheduleItems = [], ...restUser }
    ) => {
      if (sidePanelPlannedRouteUserWorkScheduleItems.length === 0) {
        return prev
      }

      const filteredPlannedUserWorkSchedules = sidePanelPlannedRouteUserWorkScheduleItems.filter(
        workSchedule => !workSchedule.isPlanned
      )

      if (filteredPlannedUserWorkSchedules.length === 0) {
        return prev
      }

      return [
        ...prev,
        {
          ...restUser,
          sidePanelPlannedRouteUserWorkScheduleItems: filteredPlannedUserWorkSchedules,
        },
      ]
    },
    []
  )
}

const useFilterActions = (
  initialUserWorkSchedules: ISidePanelUsersWorkSchedulesItem[]
) => {
  const [isFiltered, filter] = React.useState(false)
  const [filteredUserWorkSchedules, setFilteredUserWorkSchedules] = React.useState(
    filterUsersOnShowStatus(initialUserWorkSchedules, isFiltered)
  )

  React.useEffect(() => {
    setFilteredUserWorkSchedules(
      filterUsersOnShowStatus(initialUserWorkSchedules, isFiltered)
    )
  }, [initialUserWorkSchedules, isFiltered])

  return { filteredUserWorkSchedules, filter, isFiltered }
}

export const useUsersSidepanel = (
  initialUserWorkSchedules: ISidePanelUsersWorkSchedulesItem[],
  initialVisibility: boolean
) => {
  const [userWorkSchedules, setUserWorkSchedules] = React.useState(initialUserWorkSchedules)
  const [isVisible, setVisibility] = React.useState(initialVisibility)

  React.useEffect(() => {
    setUserWorkSchedules(initialUserWorkSchedules)
    setVisibility(initialVisibility)
  }, [setUserWorkSchedules, initialUserWorkSchedules, setVisibility, initialVisibility])

  const setIsPlannedFor = React.useCallback(
    (id: number, isPlanned: boolean) => {
      setUserWorkSchedules(prev =>
        produce(prev.slice(0), draft => {
          draft.forEach(element => {
            const {
              sidePanelPlannedRouteUserWorkScheduleItems = [],
            } = element
            const item = sidePanelPlannedRouteUserWorkScheduleItems.find(
              i => i.id === id
            )
            if (item !== undefined) {
              item.isPlanned = isPlanned
            }
          })
        })
      )
    },
    []
  )

  const isPlanned = React.useCallback(
    (id: number): boolean => {
      for (const element of userWorkSchedules) {
        const { sidePanelPlannedRouteUserWorkScheduleItems = [] } = element
        const item = sidePanelPlannedRouteUserWorkScheduleItems.find(
          i => i.id === id
        )
        if (item === undefined) {
          continue
        }
        return item.isPlanned
      }
      return false
    },
    [userWorkSchedules]
  )

  const { filteredUserWorkSchedules, filter, isFiltered } = useFilterActions(
    userWorkSchedules
  )

  const toggleFilter = React.useCallback(() => {
    filter(prevShow => !prevShow)
  }, [filter])

  const toggleVisibility = React.useCallback(() => {
    setVisibility(prevVisibility => !prevVisibility)
  }, [setVisibility])

  return {
    setIsPlannedFor,
    isPlanned,
    userWorkSchedules: filteredUserWorkSchedules,
    isFiltered,
    toggleFilter,
    isVisible,
    toggleVisibility,
  }
}
