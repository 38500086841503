import React from 'react'

import httpMiddleware from 'utilities/http-middleware'
import { IUserItem, MeClient } from 'api/tillit.api-client'
import { API_URL } from '../config/variables'

export class MeApi {
  private readonly abortController = new AbortController()
  private readonly client: MeClient

  constructor() {
    this.client = new MeClient(
      API_URL,
      httpMiddleware({ signal: this.abortController.signal })
    )
  }

  public abort = () => this.abortController.abort()

  public get = (): Promise<IUserItem> =>
    this.client
      .get()
      .then(userItem =>
        userItem === null
          ? Promise.reject(`No user item received from backend.`)
          : Promise.resolve(userItem)
      )
}

const useMeApi = () => React.useMemo(() => new MeApi(), [])

export default useMeApi
