import React from 'react'
import { Box, Typography, Link, Stack } from '@mui/material'
import ContainedButton from 'components/inputs/contained-button'
import TextButton from 'components/inputs/text-button'
import CenterModal from 'components/center-modal'
import ModalFooter from 'components/modal-footer'
import { Download } from 'mdi-material-ui'
import ModalHeader from 'components/modal-header'
import ModalContent from 'components/modal-content'
import { useFormatMessage } from 'localization'
import color from 'color'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import PDFReader from 'components/pdf-reader'
import { Colors } from 'styles/colors'
import { PUBLIC_URL } from 'config/variables'

const prefix = (PUBLIC_URL ? `https:${PUBLIC_URL}/manuals` : 'http://localhost:3000/manuals') || ''
//const prefix = (PUBLIC_URL ? `https://tenilo.se/wp-content/uploads` : 'http://localhost:3000/manuals') || ''

const manuals: Array<{
  id: number;
  file: string;
  name: string;
}> = [
    {
      id: 1,
      file: `${prefix}/1-Kom-igang-med-Tenilo.pdf`,
      name: 'GET_START_WITH_TENILO'
    },
    {
      id: 2,
      file: `${prefix}/2-Manual-skapa-grundmallar.pdf`,
      name: 'TEMPLATE_ACTIVITIES'
    },
    {
      id: 3,
      file: `${prefix}/3-Manual-skapa-aktiviteter-och-planering.pdf`,
      name: 'ACT_AND_PLAN'
    },
    {
      id: 4,
      file: `${prefix}/4-Manual-aktivera-aktivitetstavla.pdf`,
      name: 'ENABLE_DEVICE'
    },
    {
      id: 5,
      file: `${prefix}/5-Manual-rapporter.pdf`,
      name: 'REPORTS'
    },
  ]

const ManualsModalContainer: React.FC = () => {
  const f = useFormatMessage()
  const { manualsModalOpen } = ProfilePageContext.useState()
  const { setManualsModalOpen } = ProfilePageContext.useActions()
  const [selectedManual, setSelectedManual] = React.useState<{
    id: number;
    file: any;
    name: string;
  }>()

  const handleClose = React.useCallback(() => {
    setManualsModalOpen(false)
    setSelectedManual(undefined)
  }, [])

  const handleManualClick = React.useCallback((manual) => () => {
    setSelectedManual(manual)
  }, [])

  const handleBackClick = React.useCallback(() => {
    setSelectedManual(undefined)
  }, [])

  const handleDownloadClick = React.useCallback((e) => {
    e.stopPropagation()
  }, [])


  // moved modal content. Replace with link since manuals gets corrupted in git/gitlab build pipeline
  /*
          {selectedManual && (
            <PDFReader {...selectedManual} />
          )}

          {!selectedManual && manuals.map(manual => {
            return (
              <Box key={manual.id} onClick={handleManualClick(manual)} sx={{
                height:'30px',
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                cursor:'pointer',
                margin:'20px',
                borderRadius:'5px',
                padding:'20px',
                backgroundColor: Colors.AthensGray,
                transition: 'background-color 0.5s ease',
                '&:hover':{
                  backgroundColor: color(Colors.AthensGray).darken(0.1).toString()
                }
              }}>
                <Typography variant="body2">{f(`PROFILE_PAGE_MANUALS_${manual.name}`)}</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Link color="primary" rel="noreferrer" target="_blank" onClick={handleDownloadClick} href={manual.file} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Download />
                  </Link>
                </Stack>
              </Box>
            )
          })}


  */


  return (
    <CenterModal open={manualsModalOpen} onClose={handleClose} fullSize>
      <ModalHeader
        title={f('PROFILE_PAGE_MANUALS_TITLE')}
        subtitle={selectedManual ? f(`PROFILE_PAGE_MANUALS_${selectedManual.name}`) : ''}
      />
      <ModalContent style={{ padding: '20px' }} >

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="body2">
            <Link color="primary" rel="noreferrer" target="_blank" onClick={handleDownloadClick} href="https://tenilo.se/manualer/" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14pt' }}>
              Klicka här för att öppna manualerna i en ny flik.
            </Link>
          </Typography>
          <Typography>
            Manualerna nås på adressen https://tenilo.se/manualer/
          </Typography>
        </Box>

      </ModalContent>
      <ModalFooter>
        <TextButton onClick={handleClose}>{f('BASIC_BUTTON_CLOSE_TEXT')}</TextButton>
        <ContainedButton color="secondary" onClick={handleBackClick} disabled={!selectedManual}>
          {f('BASIC_BUTTON_BACK_TEXT')}
        </ContainedButton>
      </ModalFooter>
    </CenterModal>
  )
}

export default ManualsModalContainer
