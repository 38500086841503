// import { PropTypes } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'
import React, { FC } from 'react'

type Color = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'

type Props = Omit<ButtonProps, 'variant' | 'color'> & { color?: Color }
const OutlinedButtonView: FC<Props> = ({ color = 'primary', ...rest }) => <Button color={color === 'error' ? 'primary' : color} variant="outlined" {...rest} />

export default OutlinedButtonView
