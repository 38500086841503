import ContainedButton from 'components/inputs/contained-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import React from 'react'

const HangfireView: React.FC = () => {
  const f = useFormatMessage()

  const { toggleModalHangfire } = ProfilePageContext.useActions()

  const handleOpenHangfire = React.useCallback(
    () => toggleModalHangfire(true),
    [toggleModalHangfire]
  )

  return (
    <ModalSection title={f('HANGFIRE_TITLE')} subtitle={f('HANGFIRE_SUBTITLE')} responsive>
      <ModalSectionRow responsive>
        <ContainedButton color="secondary" onClick={handleOpenHangfire}>
          {f('HANGFIRE_BUTTON_LINK')}
        </ContainedButton>
      </ModalSectionRow>
    </ModalSection>
  )
}

export default HangfireView
