import React from 'react'

import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import {
  IKioskItem,
  IKioskListItem,
  IKioskRouteContainerItem,
  IRoutePlanningViewItem,
  ITimeBasedRouteContainerItem,
  KioskClient,
  KioskRouteContainerCreateUpdateItem,
  RouteKioskDailyActivitiesContainerItem,
  RoutePlanningViewItem,
  TimeBasedRouteContainerItem,
} from './tillit.api-client'

class DeviceApi {
  private readonly abortController = new AbortController()
  private readonly client: KioskClient

  constructor() {
    this.client = new KioskClient(API_URL, httpMiddleware({ signal: this.abortController.signal }))
  }

  public getDevices = (): Promise<IKioskListItem[]> =>
    this.client.getKiosks().then(kioskItems => (kioskItems === null ? Promise.reject(`No kiosk items received from backend.`) : Promise.resolve(kioskItems)))

  public getDevice = (deviceId: number): Promise<IKioskItem> =>
    this.client.getKiosk(deviceId).then(kioskItem => (kioskItem === null ? Promise.reject(`No kiosk item received from backend.`) : Promise.resolve(kioskItem)))

  public getDailyActivitiesForKioskModeForIntervalAsync = async (
    kioskGuid: string,
    start: Date,
    end: Date
  ): Promise<RouteKioskDailyActivitiesContainerItem | null> =>
    await this.client.getDailyActivitiesForKioskModeForInterval(kioskGuid, start, end).then(result => Promise.resolve(result))

  public getDailyActivitiesForKioskAndAreaAsync = async (
    kioskGuid: string,
    areaId: number,
    start: Date,
    end: Date
  ): Promise<RouteKioskDailyActivitiesContainerItem | null> =>
    await this.client.getDailyActivitiesForKioskAndArea(kioskGuid, areaId, start, end).then(result => Promise.resolve(result))

  public getPlanningViewForKioskForCalendarDayAsync = async (kioskId: number, startTime: Date, endTime: Date): Promise<IRoutePlanningViewItem> =>
    await this.client.getPlanningViewForKioskForCalendarDay(kioskId, startTime, endTime).then(item =>
      item === null
        ? Promise.resolve(
            new RoutePlanningViewItem({
              plannedRoutesActivitiesPlanningViewItem: [],
              sidePanelActivitiesPlanningViewItem: [],
            })
          )
        : Promise.resolve(item)
    )

  public createOrUpdateKioskRouteContainersAsync = async (kioskId: number, containers: ITimeBasedRouteContainerItem[]): Promise<IKioskRouteContainerItem> => {
    const timeBasedRouteContainers = containers.map(c => new TimeBasedRouteContainerItem(c))
    const model = new KioskRouteContainerCreateUpdateItem({
      kioskId,
      timeBasedRouteContainers,
    })
    return await this.client
      .createOrUpdateKioskRouteContainers(model)
      .then(item => (item !== null ? Promise.resolve(item) : Promise.reject('No KioskRouteContainerItem received from backend')))
  }

  public abort = () => this.abortController.abort()
}

const useDeviceApi = () => React.useMemo(() => new DeviceApi(), [])

export default useDeviceApi
