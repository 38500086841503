import React from 'react'
import { Button, Modal } from '@mui/material'
import styled, { css } from 'styled-components'

export const StyledModal = styled(React.memo(Modal))`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPaper = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[5]};
    padding: ${theme.spacing(2, 4, 3)};
    z-index:1000;
  `
)

export const StyledListItemLabel = styled('div')`
  .showLabel {
    display: inline;
    cursor: pointer;
  }

  .hideLabel {
    display: none;
  }
`

export const StyledButtonAbort = styled(React.memo(Button))`
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 10px;
`
