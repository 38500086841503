import { useActivityApi, useCustomerApi, useTemplateActivityApi } from 'api'
import { ActivityAreaCreateItem, CustomerListItem, IActivityCreateItem } from 'api/tillit.api-client'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { TemplateActivityListItem, TemplateActivityItem, TemplateActivityTypeEnum, TemplateActivityInheritanceItem } from 'api/tillit.api-client'

export enum WeeklyDayEnum {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum MonthlyDayEnum {
  MonthlyDateInterval = 'MonthlyDateInterval', // Old: Day12
  MonthlyDayInterval = 'MonthlyDayInterval', // SecondWednesday
}

export enum SeverityLevelEnum {
  Critical = 'Critical',
  Normal = 'Normal',
  Low = 'Low',
}

export enum RecurrenceEnum {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
}

const useTemporaryActivityCreateModalContext = () => {
  const { createTemporaryActivity: createTemporary } = useActivityApi()

  const { updateRoutePlanningView, clearCustomerInfo } = PlanningPageContext.useActions()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const { customerInfo, planningId } = PlanningPageContext.useState()
  const [modalOpen, setModalOpen] = React.useState(false)

  const { listActiveTemplateActivitiesOnArea } = useTemplateActivityApi()
  const { listActiveCustomersOnArea } = useCustomerApi()

  const [customers, setCustomers] = React.useState<CustomerListItem[] | undefined>(undefined)

  const [templateActivityItems, setTemplateActivityItems] = React.useState<TemplateActivityListItem[]>([])

  const [selectedTemplateActivity, setSelectedTemplateActivity] = React.useState<TemplateActivityItem | undefined>()

  const [selectedTemplateActivityInheritance, setSelectedTemplateActivityInheritance] = React.useState<TemplateActivityInheritanceItem | undefined>()

  const [currentAreaId, setCurrentAreaId] = React.useState<number | null>(null)

  const fetchTemplateActivitiesAsync = React.useCallback(
    async (customer: CustomerListItem | undefined) => {
      const templateType = customer ? TemplateActivityTypeEnum.CustomerTemplate : TemplateActivityTypeEnum.AreaTemplate
      let areaId = customer ? (customer.customerAreas ? customer.customerAreas[0].areaId : null) : null
      if (!areaId) {
        areaId = planningId
      }

      setCurrentAreaId(areaId)
      if (areaId) {
        await fetchAsync(listActiveCustomersOnArea(areaId)).then(setCustomers)
        await fetchAsync(listActiveTemplateActivitiesOnArea(areaId, templateType)).then(setTemplateActivityItems)
      }
    },
    [fetchAsync, listActiveTemplateActivitiesOnArea, listActiveCustomersOnArea, planningId]
  )

  const openModal = React.useCallback(
    (customer: CustomerListItem | undefined) => {
      setSelectedTemplateActivity(undefined)
      setSelectedTemplateActivityInheritance(undefined)
      void fetchTemplateActivitiesAsync(customer).then(() => {
        setModalOpen(true)
      })
    },
    [fetchTemplateActivitiesAsync]
  )

  const closeModal = React.useCallback(() => {
    setModalOpen(false)
    clearCustomerInfo()
  }, [clearCustomerInfo])

  const createTemporaryActivityAsync = React.useCallback(
    (createActivityModel: IActivityCreateItem, routeId: number) =>
      fetchAsync(
        createTemporary(
          {
            ...createActivityModel,
            activityAreas: !!customerInfo ? customerInfo.customerAreas : planningId ? [new ActivityAreaCreateItem({ areaId: planningId })] : undefined,
          },
          routeId
        ).then(() => updateRoutePlanningView())
      ).then(() => {
        closeModal()
      }),
    [closeModal, createTemporary, customerInfo, fetchAsync, planningId, updateRoutePlanningView]
  )

  return {
    state: { ...stateOfFetch, modalOpen, templateActivityItems, selectedTemplateActivity, selectedTemplateActivityInheritance, currentAreaId, customers },
    actions: { openModal, closeModal, createTemporaryActivityAsync, setSelectedTemplateActivity, setSelectedTemplateActivityInheritance },
  }
}

export const TemporaryActivityCreateModalContext = createContext(useTemporaryActivityCreateModalContext)
