import React from 'react'
import * as yup from 'yup'
import {
  IOrganizationPlannedActivityStatusItem,
  IPlannedActivityStatusItem,
  OrganizationPlannedActivityStatusCreateItem,
  IOrganizationPlannedActivityStatusCreateItem,
  OrganizationPlannedActivityStatusUpdateItem,
  IOrganizationPlannedActivityStatusUpdateItem
} from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'
import { AdministerSigningStatusesContext } from '../administer-signing-statuses-context'

type SigningStatusForm = Readonly<{
  organizationTitle?: string
  disable: boolean
}>

type Props = Readonly<{
  plannedStatus?:  IPlannedActivityStatusItem
  orgPlannedStatus?: IOrganizationPlannedActivityStatusItem | null
}>

const mapOrganizationPlannedActivityStatusUpdateItem = ( status : IOrganizationPlannedActivityStatusUpdateItem) : OrganizationPlannedActivityStatusUpdateItem => {
  return new OrganizationPlannedActivityStatusUpdateItem(status)
}

const mapOrganizationPlannedActivityStatusCreateItem = ( status : IOrganizationPlannedActivityStatusCreateItem) : OrganizationPlannedActivityStatusCreateItem => {
  return new OrganizationPlannedActivityStatusCreateItem(status)
}

const useSigningFormData  = ( { plannedStatus, orgPlannedStatus } : Props ) => {
  const f = useFormatMessage()
  const { fetchListSigningStatuses, handleUpdateSigningStatus, handleCreateSigningStatus } = AdministerSigningStatusesContext.useActions()
  const { orgId } = AdministerSigningStatusesContext.useState()

  const initialValues = React.useMemo<SigningStatusForm>(
    () => {
      return orgPlannedStatus ?
        {
          organizationTitle: orgPlannedStatus.organizationTitle,
          disable: orgPlannedStatus.disable
        }
      :
        {
          organizationTitle: '',
          disable: false
        }

    },
    [plannedStatus, orgPlannedStatus]
  )

  const onSubmit: OnSubmitFunction<SigningStatusForm> = React.useCallback(
    async (values, actions) => {
      if(orgPlannedStatus && orgPlannedStatus.plannedActivityStatusId){
        const updateItem = mapOrganizationPlannedActivityStatusUpdateItem({
          id: orgPlannedStatus.id,
          plannedActivityStatusId: orgPlannedStatus.plannedActivityStatusId,
          organizationTitle: values.organizationTitle?.trim(),
          disable: values.disable
        })
        return handleUpdateSigningStatus(updateItem).then(fetchListSigningStatuses)
      }
      if(orgId){
        const createItem = mapOrganizationPlannedActivityStatusCreateItem({
          organizationId:orgId,
          plannedActivityStatusId: plannedStatus?.id,
          organizationTitle: values.organizationTitle?.trim(),
          disable: values.disable
        })
        return handleCreateSigningStatus(createItem).then(fetchListSigningStatuses)
      }
    },
    []
  )

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      organizationTitle: yup
        .string()
        .max(40)
    })
  }, [f])

  const name: Name<SigningStatusForm> = React.useMemo(
    () => ({
      organizationTitle: "organizationTitle",
      disable: "disable"
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useSigningFormData
