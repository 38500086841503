import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import CenterModal from 'components/center-modal'
import ContainedButton from 'components/inputs/contained-button'
import FilledTextField from 'components/inputs/filled-text-field'
import TextButton from 'components/inputs/text-button'
import ModalFooter from 'components/modal-footer'
import ModalHeader from 'components/modal-header'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import React from 'react'
import { UserImpersonationRequestStatusEnum } from 'api/tenilo.support.api-client'
import format from 'utilities/format'
import authenticationContext from 'components/authentication/authentication-context'
import { useNavigate } from "react-router-dom"
import PageRoute from 'config/page-routes'
import { SupportUserImpersonationRequestsContext } from '../../support-userimpersonationrequests-context'
import { SupportUserImpersonationRequestModalContext } from './support-userimpersonationrequest-modal-context'

export type SupportUserImpersonationRequestModalProps = Readonly<{
  onSave?: () => void
}>

const SupportUserImpersonationRequestModalContainer: React.FC<SupportUserImpersonationRequestModalProps> = ({ onSave }) => {
  const f = useFormatMessage()
  const navigate = useNavigate()

  const { reloadUser } = authenticationContext.useActions()
  const { closeSupportUserImpersonationRequestModal, abandonUserImpersonationRequestAsync, generateJwtTokenForUserImpersonationRequestAsync, fetchUserImpersonationRequestAsync } = SupportUserImpersonationRequestModalContext.useActions()
  const { fetchUserImpersonationRequestsAsync } = SupportUserImpersonationRequestsContext.useActions()

  const { modalOpen, selectedUserImpersonationRequest } = SupportUserImpersonationRequestModalContext.useState()
  const timeout = React.useRef<number | undefined>()

  React.useEffect(() => {
    if (!selectedUserImpersonationRequest) {
      return
    }

    if (selectedUserImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.Approved || selectedUserImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.Denied) {
      clearInterval(timeout.current)
    }

    const RefreshActiveRequest = () => {
      return fetchUserImpersonationRequestAsync(selectedUserImpersonationRequest.id)
    }

    timeout.current = window.setTimeout(RefreshActiveRequest, 3 * 1000)
    return () => {
      clearInterval(timeout.current)
    }
  }, [
    selectedUserImpersonationRequest
  ])

  const onAbandonUserRequestClick = React.useCallback(() => {
    if (selectedUserImpersonationRequest) {
      abandonUserImpersonationRequestAsync(selectedUserImpersonationRequest?.id).then(() => {
        closeSupportUserImpersonationRequestModal()
        void fetchUserImpersonationRequestsAsync()
      })
    }
  }, [selectedUserImpersonationRequest, fetchUserImpersonationRequestsAsync])

  const onLoginAsUserClick = React.useCallback(() => {
    if (selectedUserImpersonationRequest && selectedUserImpersonationRequest.statusId === UserImpersonationRequestStatusEnum.Approved) {
      void generateJwtTokenForUserImpersonationRequestAsync(selectedUserImpersonationRequest.id).then(() => {
        closeSupportUserImpersonationRequestModal()
        reloadUser().then(() => {
          navigate(PageRoute.Dashboard.path)
        })
      })
    }
  }, [selectedUserImpersonationRequest, generateJwtTokenForUserImpersonationRequestAsync])

  if (!selectedUserImpersonationRequest) {
    return null
  }

  return (
    <CenterModal open={modalOpen} onClose={closeSupportUserImpersonationRequestModal}>
      <ModalHeader
        title={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_HEADER_TITLE')}
      />
      <Box mx={3}>
        <div
          style={{
            maxHeight: '70vh',
            minWidth: '70vw',
            overflow: 'auto',
          }}>
          <ModalSection
            title={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_REQUESTEDON_TITLE')}
            subtitle={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_REQUESTEDON_SUBTITLE')}>
            <ModalSectionRow>
              <Typography>{format(selectedUserImpersonationRequest?.requestedOn as Date, 'yyyy-MM-dd HH:mm')}</Typography>
            </ModalSectionRow>
          </ModalSection>

          <ModalSection title={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ORGANIZATION_TITLE')}>
            <ModalSectionRow>
              <Box width="50%">
                <FilledTextField
                  value={selectedUserImpersonationRequest?.impersonateUser.organizationName}
                  color="secondary"
                  fullWidth
                  disabled
                />
              </Box>
            </ModalSectionRow>
          </ModalSection>

          <ModalSection title={f('SUPPORT_USER_IMPERSONATIONREQUEST_INFOTEXT_TITLE')}>
            <ModalSectionRow>
              <FilledTextField
                data-matomo-mask
                fullWidth
                label={f('SUPPORT_USER_IMPERSONATIONREQUEST_FIRSTNAME_LABEL')}
                value={selectedUserImpersonationRequest?.impersonateUser.firstName}
                color="secondary"
                disabled
              />
              <FilledTextField
                data-matomo-mask
                fullWidth
                label={f('SUPPORT_USER_IMPERSONATIONREQUEST_LASTNAME_LABEL')}
                value={selectedUserImpersonationRequest?.impersonateUser.lastName}
                color="secondary"
                disabled
              />
            </ModalSectionRow>
            <FilledTextField
              data-matomo-mask
              fullWidth
              label={f('SUPPORT_USER_IMPERSONATIONREQUEST_EMAIL_LABEL')}
              value={selectedUserImpersonationRequest?.impersonateUser.email}
              color="secondary"
              disabled
            />
            <FilledTextField
              data-matomo-mask
              fullWidth
              label={f('SUPPORT_USER_IMPERSONATIONREQUEST_PHONENUMBER_LABEL')}
              value={selectedUserImpersonationRequest?.impersonateUser.phoneNumber}
              disabled
            />
          </ModalSection>

          <ModalSection
            title={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_STATUS_TITLE')}
            subtitle={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_STATUS_SUBTITLE')}>
            <ModalSectionRow>
              <Typography>{f(`UserImpersonationRequestStatus_${selectedUserImpersonationRequest?.statusId}`)}</Typography>
              {selectedUserImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.AwaitingApproval && (
                <Box sx={{ marginLeft: '10px' }}>
                  <CircularProgress size={24} color="primary" />
                </Box>
              )}
            </ModalSectionRow>

            {selectedUserImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.Approved && (
              <>
                <ModalSectionRow>
                  <Typography>Godkändes av användaren: {selectedUserImpersonationRequest?.sessionApprovedOn ? format(selectedUserImpersonationRequest?.sessionApprovedOn as Date, 'yyyy-MM-dd HH:mm') : ''}</Typography>
                </ModalSectionRow>
                <ModalSectionRow>
                  <Typography>Giltig till: {selectedUserImpersonationRequest?.sessionApprovedOn ? format(selectedUserImpersonationRequest?.sessionExpiresOn as Date, 'yyyy-MM-dd HH:mm') : ''}</Typography>
                </ModalSectionRow>
              </>
            )}
          </ModalSection>

          {selectedUserImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.Abandoned && (
            <>
              <ModalSection
                title={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDONEDON_TITLE')}
                subtitle={f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDONEDON_SUBTITLE')}>
                <ModalSectionRow>
                  <Typography>{selectedUserImpersonationRequest?.sessionApprovedOn ? format(selectedUserImpersonationRequest?.sessionAbandonedOn as Date, 'yyyy-MM-dd HH:mm') : ''}</Typography>
                </ModalSectionRow>
              </ModalSection>
            </>
          )}
        </div>
      </Box>

      <ModalFooter>
        <TextButton type="button" onClick={closeSupportUserImpersonationRequestModal}>
          {f('BASIC_BUTTON_CLOSE_TEXT')}
        </TextButton>
        <Box ml={2}>
          {selectedUserImpersonationRequest?.statusId === UserImpersonationRequestStatusEnum.Approved && (
            <Box display={'flex'} flexDirection={'row'}>
              <ContainedButton color='secondary' onClick={onAbandonUserRequestClick}>{f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_ABANDON_REQUEST')}</ContainedButton>
              <ContainedButton color='secondary' onClick={onLoginAsUserClick}>{f('SUPPORT_USER_IMPERSONATIONREQUEST_DIALOG_LOGIN_AS_USER')}</ContainedButton>
            </Box>
          )}
        </Box>
      </ModalFooter>
    </CenterModal>
  )
}

export default SupportUserImpersonationRequestModalContainer
