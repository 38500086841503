import React from 'react'
import AuthenticationContext from 'components/authentication/authentication-context'
import { IUserAreaItem, RoleEnum, UserAreaItem, IUserItem, UserTypeEnum } from './../api/tillit.api-client'

export const reduceAreas = (areas: UserAreaItem[]) =>
  areas
    .reduce<UserAreaItem[]>((prev, area) => {
      const { areaId, hasChildAreas } = area
      if (
        !prev.some(p => p.areaId === areaId) // Area is not already added
      ) {
        return [...prev, area]
      }
      return prev
    }, [])
    .sort((a1, a2) => a1.areaId - a2.areaId)

const sortAccordingToTitle = (title1: string, title2: string): number => {
  if (title1 && title2 && title1 < title2) {
    return -1
  }
  if (title1 && title2 && title1 > title2) {
    return 1
  }
  return 0
}

export const userAreasByRole = (areas: UserAreaItem[], roleIds: RoleEnum[]): UserAreaItem[] => {
  return areas.filter(area => roleIds.includes(area.roleId))
}

export const useDistinctAreas = () : {distinctAreas: (areas: UserAreaItem[] | undefined, roleId?: RoleEnum[] | undefined) => UserAreaItem[]} => {

  const { user } = AuthenticationContext.useAuthenticatedState()

  const distinctAreas = React.useCallback((areas: UserAreaItem[] | undefined, roleId?: RoleEnum[]): UserAreaItem[] => {
    const duas: UserAreaItem[] = []

    if (areas && roleId && !rootUser(user)) {
      areas = userAreasByRole(areas, roleId)
    }

    areas?.forEach(ua => {
      const existingDua = duas.filter(dua => dua.areaId === ua.areaId)
      if (existingDua && existingDua.length === 0) {
        duas.push(ua)
      }
    })
    const sortedAreas = duas.sort((a1, a2) => sortAccordingToTitle(a1.areaName ? a1.areaName : '', a2.areaName ? a2.areaName : ''))
    return sortedAreas
  },[user])

  return {
    distinctAreas
  }

}


export const rootUser = (user: IUserItem): boolean => {
  if (
    user.userType === UserTypeEnum.SystemAdmin ||
    user.userType === UserTypeEnum.OrganizationAdmin ||
    user.userType === UserTypeEnum.TeniloSupport
  ) {
    return true
  }
  return false
}

const useUserAreas = (roleId?: RoleEnum[]): IUserAreaItem[] => {

  const { user } = AuthenticationContext.useAuthenticatedState()

  const areas = React.useMemo(() => {
    if (!user.userAreas) {
      return []
    }
    let userAreas = user.userAreas
    if (roleId && !rootUser(user)) {
      userAreas = userAreasByRole(userAreas, roleId)
    }
    return reduceAreas(userAreas)
  }, [user,roleId])

  return React.useMemo(() => areas, [user])
}

export default useUserAreas
