import React from 'react'
import { Box } from '@mui/material'

type ModalContentProps = Readonly<
  {
    style?: React.CSSProperties
  }
>

const ModalContentView: React.FC<ModalContentProps> = ({ children, style }) => (
  <Box sx={{
    width:'100%',
    flex:1,
    boxSizing:'border-box',
    overflow:'auto',
    '*':{
      boxSizing:'border-box'
    },
    ...style
  }}>
    { children }
  </Box>
)

export default ModalContentView
