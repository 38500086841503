import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PageRoute from 'config/page-routes'
import { getDeviceGuid, setDeviceGuid, clearDeviceGuid } from 'api/device-guid'
import { useAuthenticationApiNoMemo } from 'api'
import { setTokenData, setOldTokenData } from 'api/token-data'
import { useLoaderData } from "react-router-dom";

const ValidateDailyActivities: React.FunctionComponent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const valid = useLoaderData();
  const deviceGuidFromUrl = location.pathname.substring(`${PageRoute.ValidateDailyActivities.path}/`.length)

  React.useEffect(() => {
    navigate(valid ? PageRoute.DailyActivities.path : `${PageRoute.DailyActivities.path}/${deviceGuidFromUrl}`)
  }, [valid])

  return (<div>Validating...</div>)
}

export const ValidationLoader = async (): Promise<boolean> => {
  const { getRequestorValidity } = useAuthenticationApiNoMemo()

  const deviceGuidFromUrl = location.pathname.substring(`${PageRoute.ValidateDailyActivities.path}/`.length)
  if (!deviceGuidFromUrl) {
    return false
  }

  const validRequestor = async (deviceGuid: string): Promise<boolean> => {
    return await getRequestorValidity(deviceGuid).then(result => {
      if (result.requestValid && result.kioskTokenData) {
        setTokenData(result.kioskTokenData)
        setOldTokenData(result.kioskTokenData)
        setDeviceGuid(deviceGuid)
        return true
      }
      return false
    })
  }

  const localStorageCookie = getDeviceGuid()
  if (localStorageCookie && localStorageCookie !== deviceGuidFromUrl) {
    clearDeviceGuid()
  }
  return await validRequestor(deviceGuidFromUrl)
}

export default ValidateDailyActivities
