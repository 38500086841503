import { Avatar, Box, Button, List, ListItemAvatar, ListItemSecondaryAction, useTheme } from '@mui/material'
import { ICustomerPausedPeriodItem } from 'api/tillit.api-client'
import { CustomerModalContext } from 'components/customer-modal/customer-modal-context'
import Spinner from 'components/spinner'
import { useFormatMessage } from 'localization'
import { Delete, Plus, History } from 'mdi-material-ui'
import React from 'react'
import { DeleteDialog } from './delete-dialog'
import { DeleteNotAllowedDialog } from './delete-not-allowed-dialog'
import { PausedPeriodContext } from './paused-period-context'
import { formatDate } from './paused-period-helper'
import { StyledDeleteIconButton, StyledListItem, StyledListItemText } from './paused-period-style'

const PausedPeriodView: React.FC = () => {
  const theme = useTheme()
  const f = useFormatMessage()

  const { pausedPeriods, passedPausedPeriods, isLoading } = CustomerModalContext.useState()

  const { openModal, handleDeletePausedPeriod } = PausedPeriodContext.useActions()

  const [historyOpen, setHistoryOpen] = React.useState<boolean>(false);

  const handleOpenModal = React.useCallback(() => openModal(), [openModal])

  const handleClickListItem = React.useCallback(
    (item: ICustomerPausedPeriodItem) => () => {
      openModal(item)
    },
    [openModal]
  )

  const handleClickDeleteIcon = React.useCallback(
    (item: ICustomerPausedPeriodItem) => () => {
      handleDeletePausedPeriod(item)
    },
    [handleDeletePausedPeriod]
  )

  const handleShowHistory = React.useCallback(() => setHistoryOpen(!historyOpen), [historyOpen])

  return (
    <>
      <Box mx={5}>
        <Button variant="outlined" color="secondary" onClick={handleOpenModal}>
          <Plus />
          {f('CUSTOMER_DIALOG_PAUSED_PERIOD_ADD')}
        </Button>
        <List style={{ maxHeight: '50vh', overflow: 'auto' }}>
          {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
          {(pausedPeriods || []).sort((a,b ) => a.startDateTime.getDate() - b.startDateTime.getDate()).map(item => (
            <StyledListItem key={item.id} onClick={handleClickListItem(item)}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: theme.palette.secondary.main }} />
              </ListItemAvatar>
              <StyledListItemText
                data-matomo-mask
                primary={item.comment ? `${f('CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_TITLE')}: ${item.comment}` : f('CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_TITLE')}
                secondary={f('CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_SUBTITLE', {
                  startDate: formatDate(item.startDateTime),
                  endDate: !!item.endDateTime ? formatDate(item.endDateTime!) : undefined,
                  isEndDate: !!item.endDateTime,
                })}
              />
              <ListItemSecondaryAction className="secondary-action">
                <StyledDeleteIconButton edge="end" aria-label="delete" onClick={handleClickDeleteIcon(item)}>
                  <Delete />
                </StyledDeleteIconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
          ))}
        </List>
      </Box>

      <Box mx={5}>
        <Button variant="outlined" color="secondary" onClick={handleShowHistory}>
          <History />
          {historyOpen ? f('CUSTOMER_DIALOG_PAUSED_PERIOD_HIDE_HISTORY_TITLE') : f('CUSTOMER_DIALOG_PAUSED_PERIOD_VIEW_HISTORY_TITLE') }
        </Button>
        {historyOpen && (
          <List style={{ maxHeight: '50vh', overflow: 'auto' }}>
            {isLoading && <Spinner size={64} color={theme.palette.primary.main} />}
            {(passedPausedPeriods || []).sort((a,b ) => b.startDateTime.getDate() - a.startDateTime.getDate()).map(item => (
              <StyledListItem key={item.id} onClick={handleClickListItem(item)}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: theme.palette.secondary.main }} />
                </ListItemAvatar>
                <StyledListItemText
                  data-matomo-mask
                  primary={item.comment ? `${f('CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_TITLE')}: ${item.comment}` : f('CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_TITLE')}
                  secondary={f('CUSTOMER_DIALOG_PAUSED_PERIOD_LIST_SUBTITLE', {
                    startDate: formatDate(item.startDateTime),
                    endDate: !!item.endDateTime ? formatDate(item.endDateTime!) : undefined,
                    isEndDate: !!item.endDateTime,
                  })}
                />
              </StyledListItem>
            ))}
          </List>

        )}
      </Box>

      <DeleteDialog />
      <DeleteNotAllowedDialog />
    </>
  )
}

export default PausedPeriodView
