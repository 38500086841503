import { Box } from '@mui/material'
import React from 'react'
import RoleSection from 'components/user-dialog/components/role-section'
import UserSecurity from 'components/user-dialog/components/security-section'
import { FormikProps } from 'formik'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'

const UserRoleForm: React.FC<FormikProps<UserFormModelWithModifiedAreas>> = ({...props}) => {

  return (
    <Box>
      <RoleSection {...props} />
      <UserSecurity  {...props} />
    </Box>
  )
}

export default UserRoleForm
