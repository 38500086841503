import React, { useState, useCallback, useEffect } from 'react'
import { useFormatMessage } from 'localization'
import { createContext } from 'utilities/create-context'
import { callIntercept } from 'utilities/http-middleware'

const pathNameBlackList = [
  '/dashboard/api/notification/listpage',
  '/api/authentication/generateJwtTokenFromLogin',
  '/api/authentication/revokeRefreshToken',
  '/dashboard/api/GeneralKpi/GetActivitiesAboutToEndKpi',
  '/dashboard/api/notification/list/organization',
  '/dashboard/api/GeneralKpi/GetSigningStatusesKp',
  '/dashboard/api/GeneralKpi/GetNumDelayedActivitiesKpi',
  '/dashboard/api/GeneralKpi/GetNumUnsignedActivitiesKpi',
  '/dashboard/api/GeneralKpi/GetPlannedActivityTimeKpi',
  '/dashboard/api/WorkScheduleKpi/GetEfficiencyRateKpi',
  '/dashboard/api/GeneralKpi/GetFutureUnplannedActivitiesKpi',
  '/dashboard/api/GeneralKpi/GetActivitiesAboutToEndKpi',
  '/dashboard/api/DelegationsKpi/DelegationsAboutToExpireKpi',
  '/dashboard/api/DrugListKpi/DrugListActivitiesWhereGivenDeviatesFromExpectedKpi',
  '/dashboard/api/DelegationsKpi/ActivitiesSignedViolatingDelegationRequirementKpi'
]

const ConfirmationBoxContext = () => {
  const f = useFormatMessage()
  const [openConfirmationBox, setOpenConfirmationBox] = useState<{state:boolean, msg?:string}>({state:false})

  const createMsg = useCallback((msg:string,key?:string) => {

    return f(`CONFIRMATION_BOX_${msg.toUpperCase()}${key ? `_${key.toUpperCase()}` : ''}`)

  },[])

  const createConfirmationBox = useCallback((options?: RequestInit, response?: Response) => {

    if(!response?.ok) {
      return
    }
    if(response.url){
      const url = new URL(response.url)
      const path = url.pathname
      if(pathNameBlackList.some(blacklistedPath => path.startsWith(blacklistedPath))){
        return
      }
    }

    switch (options?.method) {
      case 'POST':
        setOpenConfirmationBox({state:true, msg: response.status === 201 ? createMsg('created', options.headers?.['key-name'] ?? undefined) : createMsg('succeeded') })
        break
      case 'PATCH':
        setOpenConfirmationBox({state:true, msg: createMsg('updated', options.headers?.['key-name'] ?? undefined) })
        break
      case 'PUT':
        setOpenConfirmationBox({state:true, msg: createMsg('updated', options.headers?.['key-name'] ?? undefined) })
        break
      case 'DELETE':
        setOpenConfirmationBox({state:true, msg: createMsg('deleted', options.headers?.['key-name'] ?? undefined) })
        break
      default:
        break
    }
  },[])

  useEffect(() => {
    callIntercept(createConfirmationBox)
  },[])

  return {
    state: {
      openConfirmationBox
    },
    actions: {
      setOpenConfirmationBox
    },
  }
}

export default createContext(ConfirmationBoxContext)
