import { ActivityCreateItem, ActivityRowCreateItem, IActivityCreateItem, ActivityParticipantItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'
import AuthenticationContext from 'components/authentication/authentication-context'
import { TemplateActivityItem } from 'api/tillit.api-client'
import { ICellData } from '../../schedule/schedule-view'
import { RecurrenceEnum, SeverityLevelEnum, TemporaryActivityCreateModalContext } from '../temporary-activity-create-modal-context'


type TemporaryActivityCreateForm = Readonly<{
  title: IActivityCreateItem['title']
  visitLengthInMinutes: ActivityRowCreateItem['visitLengthInMinutes']
  description: ActivityRowCreateItem['description']
  color: IActivityCreateItem['color']
  alertFromStartInMinutes: IActivityCreateItem['alertFromStartInMinutes']
  showColorOnDevice: IActivityCreateItem['showColorOnDevice']
  templateActivity: TemplateActivityItem | undefined
  templateActivityId: number | undefined
  expectedAmount: number | undefined
  severityLevelId: SeverityLevelEnum
  activityParticipants?: ActivityParticipantItem[] | undefined
}>

const useTemporaryActivityCreateFormData = (timeData: ICellData) => {
  const f = useFormatMessage()
  const { user } = AuthenticationContext.useAuthenticatedState()

  const { customerInfo, planningId } = PlanningPageContext.useState()

  const { createTemporaryActivityAsync } = TemporaryActivityCreateModalContext.useActions()
  const initialValues: TemporaryActivityCreateForm = {
    title: '',
    color: '#bdbdbd',
    visitLengthInMinutes: 60,
    description: '',
    alertFromStartInMinutes: 0,
    showColorOnDevice: false,
    templateActivity: undefined,
    templateActivityId: undefined,
    expectedAmount: undefined,
    severityLevelId: SeverityLevelEnum.Normal,
    activityParticipants: undefined,
  }

  const onSubmit: OnSubmitFunction<TemporaryActivityCreateForm> = (value, { setSubmitting }) => {
    const endTime = new Date(+timeData.startTime + value.visitLengthInMinutes * 60000)

    const activity: IActivityCreateItem = new ActivityCreateItem({
      ownerCustomerId: !!customerInfo ? customerInfo.id : undefined,
      ownerAreaId: !customerInfo ? planningId || undefined : undefined,
      title: value.title,
      severityLevelId: value.severityLevelId,
      activityParticipants: value.activityParticipants,
      startDate: timeData.startTime,
      endDate: endTime,
      color: value.color,
      showColorOnDevice: value.showColorOnDevice,
      recurrenceId: RecurrenceEnum.OneTime,
      recurrenceInterval: 1,
      alertFromStartInMinutes: value.alertFromStartInMinutes,
      templateActivityId: value.templateActivityId,
      expectedAmount: value.expectedAmount,
      activityRows: [
        new ActivityRowCreateItem({
          description: value.description,
          visitLengthInMinutes: value.visitLengthInMinutes,
          fromTimeHour: timeData.startTime.getHours(),
          fromTimeMinute: timeData.startTime.getMinutes(),
          toTimeHour: endTime.getHours(),
          toTimeMinute: endTime.getMinutes(),
        }),
      ],
    })
    createTemporaryActivityAsync(activity, timeData.routeId).finally(() => setSubmitting(false))
  }

  const validationSchema = yup.object().shape({
    templateActivity: yup
      .mixed()
      .test('TemplateActivityRequired', f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TEMPLATEACTIVITY_REQUIRED'), (value: TemplateActivityItem | undefined) => {
        if (!user.forceTemplateActivity) {
          return true;
        }
        return !!value
      }),
    title: yup.string().max(
      40,
      f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
        name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_TITLE_LABEL'),
        maxsize: 40,
      })
    ),

    showColorOnDevice: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
    description: yup.string().max(
      1024,
      f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
        name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_LABEL'),
        maxsize: 1024,
      })
    ),
    visitLengthInMinutes: yup
      .number()
      .integer(
        f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
          name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_LABEL'),
        })
      )
      .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
      .typeError(f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_ERROR_TEXT'))
      .max(
        1440,
        f('BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES', {
          name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_LABEL'),
          maxsize: 1440,
        })
      )
      .min(
        1,
        f('BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES', {
          name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_LENGTH_LABEL'),
          minsize: 1,
        })
      ),

    alertFromStartInMinutes: yup
      .number()
      .integer(
        f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
          name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_LABEL'),
        })
      )
      .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
      .typeError(f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_ERROR_TEXT'))
      .max(
        1440,
        f('BASIC_WARNING_MAXSIZE_FIELD_NUMBER_MINUTES', {
          name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_LABEL'),
          maxsize: 1440,
        })
      )
      .min(
        0,
        f('BASIC_WARNING_MINSIZE_FIELD_NUMBER_MINUTES', {
          name: f('PLANNING_PAGE_CREATE_TEMPORARY_ACTIVITY_DIALOG_CONTAINER_INFOSECTION_FORM_ALERT_LABEL'),
          minsize: 0,
        })
      ),

    templateActivityId: yup.number(),
  })

  const name: Name<TemporaryActivityCreateForm> = {
    title: 'title',
    color: 'color',
    description: 'description',
    visitLengthInMinutes: 'visitLengthInMinutes',
    alertFromStartInMinutes: 'alertFromStartInMinutes',
    showColorOnDevice: 'showColorOnDevice',
    templateActivityId: 'templateActivityId',
    templateActivity: 'templateActivity',
    expectedAmount: 'expectedAmount',
    severityLevelId: 'severityLevelId',
    activityParticipants: 'activityParticipants',
  }

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useTemporaryActivityCreateFormData
