import React from 'react'
import { Tab } from '@mui/material'
import { Tabs, TabPanel, a11yProps } from 'components/tab-panel'
import { useFormatMessage } from 'localization'
import { AdministerUnitsContext } from './components/administer-units/administer-units-context'
import AdministerUnits from './components/administer-units/administer-units-view'
import { UnitModalContext } from './components/administer-units/components/unit-modal/unit-modal-context'
import UnitModal from './components/administer-units/components/unit-modal'
import { AdministerSigningStatusesContext, SigningStatusesView  } from './components/administer-signing-statuses'


const AdministerDrugListContainer: React.FC = () => {
  const f = useFormatMessage()
  const [drugListTabvalue, setDrugListTabValue] = React.useState(0)

  const handleDrugListTabChange = (event: any, newValue: React.SetStateAction<number>) => {
    setDrugListTabValue(newValue)
  }


  return (
    <div>
      <Tabs indicatorColor="primary" textColor="primary" value={drugListTabvalue} onChange={handleDrugListTabChange} centered={true}>
        <Tab label={f('ADMINISTER_DRUG_LIST_VIEW_UNIT_LABEL')} {...a11yProps(0)} />
        <Tab label={f('ADMINISTER_DRUG_LIST_VIEW_SIGNING_STATUS_LABEL')} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={drugListTabvalue} index={0}>
        {drugListTabvalue === 0 && (
          <AdministerUnitsContext.Provider>
            <UnitModalContext.Provider>
              <AdministerUnits />
              <UnitModal />
            </UnitModalContext.Provider>
          </AdministerUnitsContext.Provider>
        )}
      </TabPanel>
      <TabPanel value={drugListTabvalue} index={1}>
        {drugListTabvalue === 1 &&(
          <AdministerSigningStatusesContext.Provider>
            <SigningStatusesView />
          </AdministerSigningStatusesContext.Provider>
        )}
      </TabPanel>
    </div>
  )
}

export default AdministerDrugListContainer
