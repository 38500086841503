export const isSearchValueValid = (searchValue: string, searchText: string) => {
  let valid = true
  searchValue.split(' ').forEach(term => {
    if (searchText) {
      valid =
        valid &&
        searchText
          .toLowerCase()
          .normalize('NFC')
          .includes(term)
    }
  })
  return valid
}

export const matchFilter = (object : Record<string, unknown>, filterValue : string) : boolean => {
  return isSearchValueValid(filterValue.toLowerCase().normalize('NFC'),Object.values(object).join(' '))
}
