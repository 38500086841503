import React from 'react'

import {
  CustomerClient,
  CustomerCreateItem,
  CustomerItem,
  CustomerListItem,
  CustomerPausedPeriodCreateItem,
  CustomerPausedPeriodItem,
  ICustomerCreateItem,
  ICustomerPausedPeriodCreateItem,
  ICustomerPausedPeriodItem,
  PageResultOfCustomerListItem,
} from 'api/tillit.api-client'
import { API_URL } from 'config/variables'
import httpMiddleware from 'utilities/http-middleware'
import { inputDateTimeToTicks } from 'utilities/date-time-helpers'

export class CustomerApi {
  private readonly abortController = new AbortController()
  private readonly client: CustomerClient

  constructor() {
    this.client = new CustomerClient(API_URL, httpMiddleware({ signal: this.abortController.signal, headers: { 'key-name': 'customer' } }))
  }

  public abort = () => this.abortController.abort()

  public get = (customerId: number): Promise<CustomerItem> =>
    this.client
      .get(customerId)
      .then(customerItem =>
        customerItem === null ? Promise.reject(`No customer item received from backend for customer id ${customerId}.`) : Promise.resolve(customerItem)
      )

  public list = (page: number, pageSize: number, organizationId: number): Promise<PageResultOfCustomerListItem> =>
    this.client
      .list(page, pageSize, organizationId, null)
      .then(pageResult => (pageResult === null ? Promise.reject(`No page result received from backend.`) : Promise.resolve(pageResult)))

  public listCustomersOnKiosk = (deviceGuid: string): Promise<CustomerListItem[]> => {
    const now = inputDateTimeToTicks(new Date())
    return this.client
      .listCustomersOnKiosk(deviceGuid, now)
      .then(customers => (customers === null ? Promise.reject(`No customers received from backend.`) : Promise.resolve(customers)))
    }


  public create = (customer: ICustomerCreateItem): Promise<CustomerItem> => {
    const model = new CustomerCreateItem(customer)
    return this.client
      .create(model)
      .then(customerItem => (customerItem === null ? Promise.reject(`No customer item received from backend.`) : Promise.resolve(customerItem)))
  }

  public update = (customer: CustomerItem): Promise<CustomerItem> =>
    this.client
      .update(customer)
      .then(customerItem => (customerItem === null ? Promise.reject(`No customer item received from backend.`) : Promise.resolve(customerItem)))

  public listPausedPeriods = (customerId: number): Promise<CustomerPausedPeriodItem[]> =>
    this.client
      .listCustomerPausedPeriods(customerId)
      .then(pausedPeriods => (pausedPeriods === null ? Promise.reject(`No paused period item received from backend.`) : Promise.resolve(pausedPeriods)))

  public createPausedPeriod = (pausedPeriod: ICustomerPausedPeriodCreateItem) => {
    const model = new CustomerPausedPeriodCreateItem(pausedPeriod)
    return this.client
      .createCustomerPausedPeriod(model)
      .then(pausedPeriodItem =>
        pausedPeriodItem === null ? Promise.reject(`No paused period item received from backend.`) : Promise.resolve(pausedPeriodItem)
      )
  }

  public updatePausedPeriod = (pausedPeriod: ICustomerPausedPeriodItem) => {
    const model = new CustomerPausedPeriodItem(pausedPeriod)
    return this.client
      .updateCustomerPausedPeriod(model)
      .then(pausedPeriodItem =>
        pausedPeriodItem === null ? Promise.reject(`No paused period item received from backend.`) : Promise.resolve(pausedPeriodItem)
      )
  }

  public hasAreaTemplateActivityUsage = (customerId: number): Promise<boolean> =>
    this.client
      .hasAreaTemplateActivityUsage(customerId)
      .then(exists =>
        exists === null ? Promise.reject(`Unexpected response from hasAreaTemplateActivityUsage for customer id ${customerId}.`) : Promise.resolve(exists)
      )

  public remove = (customerId: number): Promise<boolean> => this.client.remove(customerId, false).then(() => true)

  public inactivate = (customerId: number): Promise<boolean> => this.client.remove(customerId, true).then(() => true)

  public listActiveCustomersOnArea = (areaId: number): Promise<CustomerListItem[]> =>
    this.client
      .listActiveCustomersOnArea(areaId)
      .then(items =>
        items === null
          ? Promise.reject(`No customers received from backend for area id ${areaId}.`)
          : Promise.resolve(items)
      )

  public getCustomerWithActivitiesAndPausedPeriods = (customerId: number): Promise<CustomerItem> =>
    this.client
      .getCustomerWithActivitiesAndPausedPeriods(customerId)
      .then(customerItem =>
        customerItem === null ? Promise.reject(`No customer item received from backend for customer id ${customerId}.`) : Promise.resolve(customerItem)
      )

}

const useCustomerApi = () => React.useMemo(() => new CustomerApi(), [])

export default useCustomerApi
