import React from 'react'
import { Box } from '@mui/material'
import FilledTextField from 'components/inputs/filled-text-field'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import useUserForm from 'components/user-dialog/utilities/use-user-form'
import { FormikProps } from 'formik'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'
import { useUserApi } from 'api'
import usePromiseApi from 'utilities/use-promise'
import debounce from 'lodash/debounce'

const BasicInformationSection: React.FC<FormikProps<UserFormModelWithModifiedAreas>> = ({ values, setFieldValue }) => {
  const f = useFormatMessage()
  const { isEmailUnique } = useUserApi()
  const { fetchAsync } = usePromiseApi()

  const { name } = useUserForm()
  const { user, dialogActionType, loggedInUser, isPersonnel, calledFromOtherPersonell } = UserDialogContext.useState()
  const { setEmailCannotBe } = UserDialogContext.useActions()

  const { email } = values

  let showNotExpectedEmailDomain = false
  if (email && email.length > 0 && loggedInUser?.expectedEmailDomain) {
    showNotExpectedEmailDomain = !email.includes(loggedInUser.expectedEmailDomain)
  }

  const debouncedEmailCheck = React.useRef(
    debounce(async (value) => {
      await fetchAsync(isEmailUnique(value, user?.id ? user.id : -1)).then(unique => {
        if (!unique) {
          setEmailCannotBe(value)
        } else {
          setEmailCannotBe('')
        }
      })

    }, 1000)
  ).current;

  const handleChangeEmail = (event: any) => {
    const value = event.target.value
    debouncedEmailCheck(value);
    setFieldValue('email', value, false)
  }

  return (
    <Box width="90%">
      <ModalSection title={f('USER_DIALOG_VIEW_INFOSECTION_INFOTEXT_TITLE')}>
        <ModalSectionRow>
          <FilledTextField
            data-matomo-mask
            fullWidth
            label={f('USER_DIALOG_VIEW_INFOSECTION_FORM_FIRSTNAME_LABEL')}
            name={name.firstName}
            color="secondary"
            disabled={(dialogActionType === 'edit' && !user?.active) || calledFromOtherPersonell}
          />
          <FilledTextField
            data-matomo-mask
            fullWidth
            label={f('USER_DIALOG_VIEW_INFOSECTION_FORM_LASTNAME_LABEL')}
            name={name.lastName}
            color="secondary"
            disabled={(dialogActionType === 'edit' && !user?.active) || calledFromOtherPersonell}
          />
        </ModalSectionRow>
        <FilledTextField
          data-matomo-mask
          fullWidth
          label={f('USER_DIALOG_VIEW_INFOSECTION_FORM_EMAIL_LABEL')}
          name={name.email}
          color="secondary"
          disabled={(dialogActionType === 'edit' && !user?.active) || calledFromOtherPersonell}
          onChange={handleChangeEmail}
          helperText={
            showNotExpectedEmailDomain &&
            f('USER_DIALOG_VIEW_INFOSECTION_FORM_EMAIL_DOMAIN_WARNING', { expectedEmailDomain: loggedInUser?.expectedEmailDomain })
          }
        />
        <FilledTextField
          data-matomo-mask
          fullWidth
          label={f('USER_DIALOG_VIEW_INFOSECTION_FORM_PHONENUMBER_LABEL')}
          name={name.phoneNumber}
          disabled={(dialogActionType === 'edit' && !user?.active) || calledFromOtherPersonell}
        />
      </ModalSection>
      <ModalSection title={f('USER_DIALOG_VIEW_EXTRAINFO_TITLE')} subtitle={f('USER_DIALOG_VIEW_EXTRAINFO_SUBTITLE')}>
        <FilledTextField
          data-matomo-mask
          fullWidth
          label={f('USER_DIALOG_VIEW_EXTRAINFO_LABEL')}
          name={name.extraInfo}
          color="secondary"
          disabled={(isPersonnel || (dialogActionType === 'edit' && !user?.active) || calledFromOtherPersonell)}
        />
      </ModalSection>
      <ModalSection title={f('USER_DIALOG_VIEW_OTHERSECTION_INFOTEXT_TITLE')}>
        <FilledTextField
          data-matomo-mask
          fullWidth
          multiline
          rows={4}
          label={f('USER_DIALOG_VIEW_OTHERSECTION_FORM_OTHER_LABEL')}
          name={name.description}
          color="secondary"
          disabled={(isPersonnel || (dialogActionType === 'edit' && !user?.active) || calledFromOtherPersonell)}
        />
      </ModalSection>
    </Box>
  )
}

export default BasicInformationSection
