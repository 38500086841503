import styled, { css } from 'styled-components'
import OutlinedButtonView from './outlined-button-view'

const TextButtonStyle = styled(OutlinedButtonView)(({ theme, color = 'default' }) =>
  color === 'error'
    ? css`
        color: ${theme.palette.error.main};
        &.MuiButton-outlined {
          border: 1px solid ${theme.palette.error.main};
        }
        &.MuiButton-root:hover {
          background-color: ${theme.palette.error.main}08;
        }
      `
    : ''
)

export default TextButtonStyle
