import { Box, Typography, useTheme } from '@mui/material'
import { AreaDropListItem, CustomerListItem } from 'api/tillit.api-client'
import { Form, Formik } from 'formik'
import { useFormatMessage } from 'localization'
import SelectInput from 'components/inputs/select-input'
import React from 'react'
import ContainedButton from 'components/inputs/contained-button'
import CenterModal from 'components/center-modal'
import { ModalBox } from 'pages/daily-activities/daily-activities-container'
import styled from 'styled-components'
import { KioskTemporaryActivityCreateModalContext } from '../kiosk-temporary-activity-create-modal/kiosk-temporary-activity-create-modal-context'
import { DailyActivitiesContext } from '../../daily-activities-context'
import { StyledButtonAbort } from './modal-droplist-customer-style'
import useFormData from './utilities/use-droplist-customer-form-data'

const DroplistCustomerFieldContainer = styled(Box)`
  border-radius: 1vh;
  border-color: #948349;
  border-width: 1px;
  border-style: solid;
  padding: 1vh;
  min-width: 400px;
  ${({ theme }) => [theme.breakpoints.down('md')]}{
    min-width: auto;
    >div {
      flex-direction: column;
    }
  }

`

const DroplistCustomer: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()

  const { droplistCustomerOpen, listCustomer, listArea, customerInfo } = DailyActivitiesContext.useState()
  const { setDroplistCustomerOpen, setDroplistAreaOpen, customerSelected, setSelectedAreaId, setCustomerInfo } = DailyActivitiesContext.useActions()

  const handleClose = () => {
    setDroplistCustomerOpen(false)
    setDroplistAreaOpen(false)
  }

  const { openModal } = KioskTemporaryActivityCreateModalContext.useActions()

  const [selectedCustomerListItem, setSelectedCustomerListItem] = React.useState<CustomerListItem | undefined>(undefined)

  const handleSelectedCustomer = () => {
    setSelectedAreaId(undefined)
    openModal(selectedCustomerListItem)
    handleClose()
  }

  const handleCommonActivityClick = () => {
    setCustomerInfo(null)
    openModal(undefined)
    handleClose()
  }

  const getCustomerName = (item: any) => {
    const c = item as CustomerListItem
    return c && c.roomNumber && c.roomNumber.trim().length > 0 ? c.roomNumber : (c?.fullName ? c.fullName : "")
  }

  const getAreaName = (item: any) => {
    const a = item as AreaDropListItem
    return a?.name ? a.name : ""
  }

  const { initialValues, name, onSubmit } = useFormData(listArea)

  if (!listArea || (listArea && listArea.length === 0)) {
    return <></>
  }

  return (

    <CenterModal open={droplistCustomerOpen} onClose={handleClose} responsive>
      <ModalBox>
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
          {({ isSubmitting, values, setFieldValue, errors, dirty }) => {
            const handleChangeArea = (_: React.ChangeEvent<{}>, value: any) => {
              const a = value as AreaDropListItem
              setSelectedAreaId(a.areaId)
            }

            const handleChangeCustomer = (_: React.ChangeEvent<{}>, value: any) => {
              const c = value as CustomerListItem
              setSelectedCustomerListItem(c)
              void customerSelected(c)
            }

            return (
              <Form translate="yes">
                <Typography style={{ color: theme.palette.error.main }}>
                  <div style={{ color: 'black', marginLeft: '1vh' }}>{f('DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_TITLE')}</div>
                  <div style={{ color: 'grey', padding: '1vh', fontStyle: 'italic' }}>
                    {f('DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_SUBTITLE')}
                  </div>
                  <DroplistCustomerFieldContainer>
                    <Box display="flex" justifyContent="space-between">
                      <SelectInput
                        data-matomo-mask
                        label={f('Kund')}
                        name={name.selectedCustomer}
                        onChange={handleChangeCustomer}
                        options={listCustomer as any}
                        getOptionLabel={getCustomerName}
                        disabled={false}
                        fullWidth={true}
                        minWidth={200}
                      />
                      <ContainedButton disabled={!customerInfo} onClick={handleSelectedCustomer} color={'secondary'}>
                        {!customerInfo
                          ? f('DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_CUSTOMER_ACTIVITY_DISABLED_BUTTON')
                          : f('DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_CUSTOMER_ACTIVITY_BUTTON')}
                      </ContainedButton>
                    </Box>
                  </DroplistCustomerFieldContainer>
                  <DroplistCustomerFieldContainer>
                    <Box display="flex" justifyContent="space-between">
                      <SelectInput
                        label={f('Avdelning')}
                        name={name.mainArea}
                        onChange={handleChangeArea}
                        options={listArea as any}
                        getOptionLabel={getAreaName}
                        disabled={false}
                        fullWidth={true}
                        minWidth={200}
                      />
                      <ContainedButton onClick={handleCommonActivityClick} color={'secondary'}>
                        {f('DAILY_ACTIVITIES_MODAL_TEMPORARY_ACTIVITY_CREATE_COMMON_ACTIVITY_BUTTON')}
                      </ContainedButton>
                    </Box>
                  </DroplistCustomerFieldContainer>
                  <Box display="flex" justifyContent="flex-end">
                    <StyledButtonAbort onClick={handleClose}>{f('Avbryt')}</StyledButtonAbort>
                  </Box>
                </Typography>
              </Form>
            )
          }}
        </Formik>
      </ModalBox>
    </CenterModal>
  )
}

export default DroplistCustomer
