import { FormControl, FormLabel } from '@mui/material'
import { RadioGroupProps } from '@mui/material/RadioGroup'
import { Field } from 'formik'
import { RadioGroup } from 'formik-mui'
import React from 'react'

type Props = Readonly<{ label?: string }> & Omit<RadioGroupProps, 'name'> & { name: string }

const RadioButtonContainer: React.FC<Props> = ({ children, name, label, ...props }) => {
  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Field name={name} component={RadioGroup} {...props}>
        {children}
      </Field>
    </FormControl>
  )
}

export default RadioButtonContainer
