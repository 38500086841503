import React from 'react'
import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box'

import { Close, NotePlus } from 'mdi-material-ui'
import ContainedButton from 'components/inputs/contained-button'
import { XYCoord, useDrop } from 'react-dnd'
import { useFormatMessage } from 'localization'

import { BulletinBoardContext, BulletinBoardItemModel } from './bulletin-board-context'

import { BulletinBoardItem } from './components/bulletin-board-item'
import { ItemTypes } from './components/itemtypes'
import BulletinBoardItemModal from './components/bulletin-board-item-modal'
import { BulletinBoardItemModalContext } from './components/bulletin-board-item-modal/bulletin-board-item-modal-context'
import { BulletinBoardItemDragLayer } from './components/bulletin-board-item-drag-layer'

type Props = Readonly<
  {
    closeBulletinBoard: () => void
  } & BoxProps
>

const BulletinBoardContainer: React.FC<Props> = ({
  closeBulletinBoard,
  ...rest
}) => {

  const f = useFormatMessage()
  const { bulletin } = BulletinBoardContext.useState()
  const { updateBulletinItem, removeBulletinItem } = BulletinBoardContext.useActions()
  const { openBulletinBoardItemModal } = BulletinBoardItemModalContext.useActions()

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BulletinBoardItem,
      drop: (item: BulletinBoardItemModel, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord
        const left = Math.round(item.left + delta.x)
        const top = Math.round(item.top + delta.y)

        const newItem: BulletinBoardItemModel = {
          ...item,
          left,
          top
        }

        updateBulletinItem(newItem)
        return undefined
      },
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [updateBulletinItem],
  )

  const bulletinItemChangedHandler = React.useCallback((item: BulletinBoardItemModel) => {
    updateBulletinItem(item)
  }, [updateBulletinItem])

  const bulletinItemHandleEdit = React.useCallback((item: BulletinBoardItemModel) => {
    openBulletinBoardItemModal(item)
  }, [openBulletinBoardItemModal])

  const bulletinItemHandleRemove = React.useCallback((item: BulletinBoardItemModel) => {
    removeBulletinItem(item)
  }, [removeBulletinItem])

  const handleOpenModal = React.useCallback(() => {
    openBulletinBoardItemModal(undefined)
  }, [openBulletinBoardItemModal])

  return (
    <>
      <Box ref={drop} {...rest} sx={{ position: 'absolute', top: '0px', left: '0px', bottom: '0px', width: '100%' }}>
        <Box display={'flex'}>
          <ContainedButton sx={{ height: '36.5px', alignSelf: 'center' }} onClick={handleOpenModal}>
            <NotePlus />
            {f('BULLETIN_BOARD_CONTAINER_BUTTON_CREATE_NEW')}
          </ContainedButton>

          <ContainedButton sx={{ height: '36.5px', alignSelf: 'center' }} onClick={closeBulletinBoard}>
            <Close />
            {f('BULLETIN_BOARD_CONTAINER_BUTTON_CLOSE')}
          </ContainedButton>
        </Box>
        <BulletinBoardItemDragLayer />
        <>
          {bulletin && (
            bulletin.items?.map((bi, index) => {
              return (
                <BulletinBoardItem
                  bulletinBoardItem={bi}
                  bulletinBoardItemIndex={index}
                  key={`bulletin-item-${index}`}
                  bulletinItemChanged={bulletinItemChangedHandler}
                  bulletinItemHandleEdit={bulletinItemHandleEdit}
                  bulletinItemHandleRemove={bulletinItemHandleRemove}
                />
              )
            })
          )
          }
        </>
      </Box>
      <BulletinBoardItemModal />
    </>
  )
}

export default BulletinBoardContainer
