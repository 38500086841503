import styled, { css } from 'styled-components'

export const PenMarkIcon = {
  padding: '6px 10px',
  borderRadius: '4px',
  marginRight: '10px',
}

export const StyledInfoActivity = styled.div<{ color?: string }>`
  padding: ${props => props.theme.spacing(2)};
  max-width: 400px;

  .avatar {
    ${props => (props.color ? `background-color: ${props.color};` : '')}
    height: 24px;
    width: 24px;
  }
`
