import React from 'react'
import { createTheme, responsiveFontSizes } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from "@mui/styles"
import { ThemeOptions } from '@mui/material/styles'
import { ThemeProvider as ScThemeProvider } from 'styled-components'

type Props = Readonly<{
  theme: ThemeOptions
}>

const ThemeProvider: React.FunctionComponent<Props> = ({ theme, children }) => {
  const themeInstance = React.useMemo(
    () => responsiveFontSizes(createTheme(theme)),
    [theme]
  )

  return (
    <ScThemeProvider theme={themeInstance}>
      <MuiThemeProvider theme={themeInstance}>{children}</MuiThemeProvider>
    </ScThemeProvider>
  )
}

export default ThemeProvider
