import React, { FC } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridRowParams, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { muiDataGridLocale } from 'utilities/mui-datagrid-helper'
import { CustomToolbar } from 'utilities/mui-datagrid'
import { RoleEnum, UserListItem, UserAreaItem } from 'api/tillit.api-client'
import { DeleteDialog } from 'components/delete-dialog'
import { OkDialog } from 'components/ok-dialog'
import { UserDialogContext } from 'components/user-dialog/user-dialog-context'
import { useFormatMessage } from 'localization'
import useAuthorization from 'utilities/use-authorization'
import { makeStyles } from '@mui/styles';
import { AccountConvert, AccountOff, } from 'mdi-material-ui'
import AuthenticationContext from 'components/authentication/authentication-context'
import { uniqueAndSortedUserAreas } from 'utilities/user-helper'
import { Authorize } from 'components/authorization'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { filterUsers } from 'utilities/user-helper'
import { UsersPageContext } from './users-page-context'
import UserAreas from './components/user-areas-container'


const useStyles = makeStyles({
  'users-container': {
    padding: '2vh 2vh 0 2vh'
  },
});

const UsersInactiveView : FC = () => {
  const { hasRoleOnAnyArea } = useAuthorization()
  const { user: loggedInUser } = AuthenticationContext.useAuthenticatedState()
  const classes = useStyles()

  const { activateUserDialogOpen, removeDialogOpen, userToRemove, userToActivate, inactiveUsers, userInvalidToActivate } = UsersPageContext.useState()

  const {
    openRemoveDialog,
    closeRemoveDialog,
    openActivateUserDialog,
    closeActivateUserDialog,
    remove,
    activateUser,
    closeInvalidToActivateDialog,
  } = UsersPageContext.useActions()
  const f = useFormatMessage()
  const getFilteredUsers = filterUsers(f)
  const { open: openUserDialog } = UserDialogContext.useActions()

  const handleRowClick = React.useCallback(
    async (params: GridRowParams) => {
      openUserDialog(params.row.id)
    },
    [openUserDialog]
  )

  const handleConfirmRemoveUserClick = React.useCallback(() => remove(userToRemove!.id), [userToRemove, remove])

  const handleRemoveRowClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, user: UserListItem) => {
      _?.stopPropagation()
      openRemoveDialog(user)
    },
    [openRemoveDialog]
  )

  const handleConfirmActivateUserClick = React.useCallback(() => activateUser(userToActivate!.id), [userToActivate, activateUser])

  const handleActivateUserRowClick = React.useCallback(
    (_: React.MouseEvent<Element, MouseEvent> | undefined, user: UserListItem) => {
      _?.stopPropagation()
      openActivateUserDialog(user)
    },
    [openActivateUserDialog]
  )

  let users: UserListItem[] = []
  let isPersonnel: boolean = hasRoleOnAnyArea(RoleEnum.Caretaker) && !hasRoleOnAnyArea(RoleEnum.Planner, RoleEnum.Admin)
  if (inactiveUsers) {
    users = inactiveUsers
    if (isPersonnel) {
      users = users.filter(u => u.id === loggedInUser.id)
    }
  }

  const userAreasLookup: any[] = []
  users?.forEach(user => {
    user.userAreas?.forEach(userArea => {
      if (!userAreasLookup[userArea.areaId]) {
        userAreasLookup[userArea.areaId] = userArea.areaName
      }
    })
  })

  const [filterValue, setFilterValue] = React.useState<string | undefined>('')
  const [filteredUsers, setFilteredUsers] = React.useState<UserListItem[]>(users)

  React.useEffect(() => {
    if (filterValue) {
      const filtered = getFilteredUsers(filterValue, users)
      setFilteredUsers(filtered)
    } else {
      setFilteredUsers(users)
    }
    // NOTE: We are missing filterValue and getFilteredUsers as deps here.
    // Need to figure out how to do this correctly. If I include them now I end up with an infinite loop.
    // Leaving it as is for now as it seems to work as expected when excluding them.
    // eslint-disable-next-line
  }, [users])

  const handleChangeFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchValue = event.target.value
      setFilterValue(searchValue)
      const filtered = getFilteredUsers(searchValue, users)
      setFilteredUsers(filtered)
    },
    [setFilterValue, setFilteredUsers, users, getFilteredUsers]
  )

  const handleClearFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setFilterValue('')
      setFilteredUsers(users)
    },
    [setFilterValue, setFilteredUsers, users]
  )

  const [pageSize, setPageSize] = React.useState<number>(50);

  return (
    <div className={classes['users-container']}>
      <Authorize allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Box sx={{
            display: 'flex',
            flex: '50%',
          }}>
          </Box>
          <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
            <FilledSearchTextField
              label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
              handleChangeFilter={handleChangeFilter}
              handleClearFilter={handleClearFilter}
              searchValue={filterValue}
            />
          </div>
        </Box>
      </Authorize>
      <DataGrid
        disableColumnFilter
        sx={{
          minHeight: '70vh',
          maxHeight: '70vh',
          '.MuiDataGrid-cell': { borderBottom: '0px' }
        }}
        rows={filteredUsers}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        onRowClick={handleRowClick}

        localeText={muiDataGridLocale(f)()}

        columns={[
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_FIRSTNAME'),
            field: 'firstName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.firstName}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_LASTNAME'),
            field: 'lastName',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.lastName}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_EMAIL'),
            field: 'email',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span data-matomo-mask>
                  {params.row.email}
                </span>
              )
            },
          },
          {
            flex: 1,
            headerName: f('USERS_PAGE_VIEW_COLUMN_AREAS_TITLE'),
            field: 'userAreas',
            valueGetter: (params: GridValueGetterParams) => {
              const userAreas = params.value as UserAreaItem[]
              return !!userAreas && uniqueAndSortedUserAreas(userAreas).map(ua => ua).join(', ')
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <span>
                  <UserAreas user={params.row} />
                </span>
              )
            },
          },

          {
            headerName: ' ',
            flex: 0.2,
            field: 'no_field_activate',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              const user = params.row as UserListItem
              if (isPersonnel) {
                return <span/>
              }
              return (
                <span>
                  <Tooltip
                    data-matomo-mask
                    title={f('USERS_PAGE_VIEW_ACTIVATE_BUTTON_TOOLTIP', {
                      fullName: user.fullName,
                    })}
                    aria-label={f('USERS_PAGE_VIEW_ACTIVATE_BUTTON_TOOLTIP', {
                      fullName: user.fullName,
                    })}>
                    <IconButton onClick={e => handleActivateUserRowClick(e, user)} size="large">
                      <AccountConvert color={'secondary'} />
                    </IconButton>
                  </Tooltip>
                </span>
              )
            },
          },

          {
            headerName: ' ',
            flex: 0.2,
            field: 'no_field_delete',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              const user = params.row as UserListItem
              if (isPersonnel) {
                return <span/>
              }
              return (
                <span>
                  <Tooltip
                    data-matomo-mask
                    title={f('USERS_PAGE_VIEW_DELETE_BUTTON_TOOLTIP', {
                      fullName: user.fullName,
                    })}
                    aria-label={f('USERS_PAGE_VIEW_DELETE_BUTTON_TOOLTIP', {
                      fullName: user.fullName,
                    })}>
                    <IconButton onClick={e => handleRemoveRowClick(e, user)} size="large">
                      <AccountOff color={'secondary'} />
                    </IconButton>
                  </Tooltip>
                </span>
              )
            },
          },

        ]}

        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[25, 50, 100]}
        pagination

      />

      {userInvalidToActivate !== null && (
        <OkDialog
          dialogTitle={f('USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_TITLE')}
          dialogContentText={f('USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_MESSAGE', {
            fullName: userInvalidToActivate.fullName,
            email: userInvalidToActivate.email,
          })}
          buttonOkText={f('USERS_PAGE_VIEW_INVALID_ACTIVATE_DIALOG_ACTIONS_OK')}
          okDialogOpen={true}
          onClose={closeInvalidToActivateDialog}
        />
      )}

      {activateUserDialogOpen && userToActivate !== null && (
        <DeleteDialog
          dialogTitle={f('USERS_PAGE_VIEW_ACTIVATE_DIALOG_TITLE')}
          dialogContentText={f('USERS_PAGE_VIEW_ACTIVATE_DIALOG_MESSAGE', {
            fullName: userToActivate.fullName,
          })}
          buttonSubmitText={f('USERS_PAGE_VIEW_ACTIVATE_DIALOG_ACTIONS_REMOVE')}
          buttonCancelText={f('USERS_PAGE_VIEW_ACTIVATE_DIALOG_ACTIONS_CANCEL')}
          deleteDialogOpen={true}
          onClose={closeActivateUserDialog}
          onSubmit={handleConfirmActivateUserClick}
        />
      )}

      {removeDialogOpen && userToRemove !== null && (
        <DeleteDialog
          dialogTitle={f('USERS_PAGE_VIEW_DELETE_DIALOG_TITLE')}
          dialogContentText={f('USERS_PAGE_VIEW_DELETE_DIALOG_MESSAGE', {
            fullName: userToRemove.fullName,
          })}
          buttonSubmitText={f('USERS_PAGE_VIEW_DELETE_DIALOG_ACTIONS_REMOVE')}
          buttonCancelText={f('USERS_PAGE_VIEW_DELETE_DIALOG_ACTIONS_CANCEL')}
          deleteDialogOpen={true}
          onClose={closeRemoveDialog}
          onSubmit={handleConfirmRemoveUserClick}
        />
      )}
    </div>
  )
}

export default UsersInactiveView
