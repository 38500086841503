import { Box, Typography } from '@mui/material'
import EllipsisTypography from 'components/ellipsis-typography'
import { useFormatMessage } from 'localization'
import { CogOutline } from 'mdi-material-ui'
import { OrganizationContext } from 'pages/organization/organization-context'
import React from 'react'
import styled from 'styled-components'

type Props = Readonly<{
  selected?: boolean
  className?: string
  name?: string
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void
  childCount?: number
  routeCount?: number
  disabled?: boolean
  active?: boolean
}>

const OrganizationAreaView: React.FC<Props> = ({
  className,
  name = '\u00A0',
  onClick,
  childCount = 0,
  routeCount = 0,
  disabled = false,
  selected = false,
  active = false,
}) => {
  const {
    sidebar: { open },
  } = OrganizationContext.useActions()
  const [isHover, setIsHover] = React.useState(false)
  const f = useFormatMessage()
  const mouseHandlers = React.useMemo(
    () => ({
      onMouseEnter: () => setIsHover(true),
      onMouseLeave: () => setIsHover(false),
    }),
    []
  )

  const handleSettingsClicked = () => open()

  const childCountText =
    routeCount === 0
      ? childCount === 0
        ? `${f('BASIC_TEXT_AREA_OR_ROUTE_COUNT_ZERO_TEXT')}`
        : childCount === 1
        ? `${childCount} ${f('BASIC_TEXT_AREA_COUNT_ONE_TEXT')}`
        : `${childCount} ${f('BASIC_TEXT_AREA_COUNT_MULTIPLE_TEXT')}`
      : routeCount === 1
      ? `${routeCount} ${f('BASIC_TEXT_ROUTE_COUNT_ONE_TEXT')}`
      : `${routeCount} ${f('BASIC_TEXT_ROUTE_COUNT_MULTIPLE_TEXT')}`
  return (
    <div className={className} onClick={onClick} {...mouseHandlers}>
      <Box display="flex" flexDirection="column">
        <EllipsisTypography variant="subtitle1">{name}</EllipsisTypography>
        <Typography variant="caption">{childCountText}</Typography>
      </Box>
      {isHover && <StyledSettingsOutline fontSize="small" color="secondary" onClick={handleSettingsClicked} />}
    </div>
  )
}

const StyledSettingsOutline = styled(CogOutline)`
  align-self: flex-end;
  display: none;
`
export default OrganizationAreaView
