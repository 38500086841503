import React from 'react'
import { Typography, Box } from '@mui/material'
import { useFormatMessage } from 'localization'
import { Navigate, useRouteError } from 'react-router-dom'
import PageRoute from 'config/page-routes'

const RouterErrorBoundary = (props) => {
  const error = useRouteError()
  let errorStatus: number
  let errorStatusText: string
  let errorMessage: string = 'Unknown error'

  if (error instanceof Response) {
    errorStatus = error.status
    errorStatusText = error.statusText
    if ((errorStatus === 401 && errorStatusText === 'innerAuthError') || (errorStatus === 409 && errorStatusText === 'contextError')) {
      //SK: WHY??? Better just clearing user? localStorage.clear()
      return <Navigate to={PageRoute.Login.path} replace />
    }
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    errorMessage = 'Unknown error'
  }


  throw new Error(errorMessage)
}

const RootRouterErrorBoundary = (props) => {
  const error = useRouteError()
  const f = useFormatMessage()
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h2">{f('DEFAULT_ERROR_FALLBACK_MSG')}</Typography>
      {/* We can include a button here for either refreshing the page or clearing local storage */}
    </Box>
  )
}

export {
  RouterErrorBoundary,
  RootRouterErrorBoundary
}
