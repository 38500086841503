import ContainedButton from 'components/inputs/contained-button'
import ModalSection from 'components/modal-section'
import ModalSectionRow from 'components/modal-section-row'
import { useFormatMessage } from 'localization'
import { ProfilePageContext } from 'pages/profile/profile-page-context'
import React from 'react'

const ManualsView: React.FC = () => {
  const f = useFormatMessage()

  const { setManualsModalOpen } = ProfilePageContext.useActions()

  const handleOpenManualsModal = React.useCallback(
    () => setManualsModalOpen(true),
    []
  )

  return (
    <ModalSection title={f('PROFILE_PAGE_MANUALS_TITLE')} subtitle={f('PROFILE_PAGE_MANUALS_SUBTITLE')} responsive>
      <ModalSectionRow responsive>
        <ContainedButton color="secondary" onClick={handleOpenManualsModal}>
          {f('PROFILE_PAGE_MANUALS_LINK')}
        </ContainedButton>
      </ModalSectionRow>
    </ModalSection>
  )
}

export default ManualsView
