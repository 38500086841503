import { UserAreaItem } from 'api/tillit.api-client'
import { UserAreaListItem } from 'api/tenilo.support.api-client'
import { useFormatMessage } from 'localization'
import { UserListItem } from 'api/tillit.api-client'
import { isSearchValueValid } from './filter-helper'

type UserArea = UserAreaItem | UserAreaListItem;

export const uniqueAndSortedUserAreas = (userAreas: UserArea[]): string[] => {
  let arrAreasName: string[] = []
  userAreas?.forEach(ua => {
    if (!arrAreasName.includes(ua.areaName!)) {
      arrAreasName.push(ua.areaName!)
    }
  })

  arrAreasName = arrAreasName.sort((a: string, b: string) => {
    if (a && b && a < b) {
      return -1
    }
    if (a && b && a > b) {
      return 1
    }
    return 0
  })

  return arrAreasName
}

export const filterUsers = (f: ReturnType<typeof useFormatMessage>) => (
  searchValue: string,
  users: UserListItem[] | null
): UserListItem[] => {

  if (!users) {
    return [] as UserListItem[]
  }

  // 'NFC' means Normalization Form Canonical Composition
  searchValue = searchValue.toLowerCase().normalize('NFC')
  if (!searchValue || (searchValue && searchValue.length === 0)) {
    return users
  }

  const filtered = users?.filter(user => {
    const searchText = `${user.firstName} ${user.lastName} ${user.email} ${uniqueAndSortedUserAreas(user.userAreas || [])}`
    return isSearchValueValid(searchValue, searchText);
  })

  if (filtered) {
    return filtered
  } else {
    return [] as UserListItem[]
  }
}
