import { IManageKioskItem, KioskAreaCreateItem, KioskStatusEnum, UserAreaItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { AdministerDevicesContext } from 'pages/administer/components/administer-devices'
import React from 'react'
import { Name, OnSubmitFunction, UseFormHook } from 'types'
import * as yup from 'yup'
import { ManageDeviceDialogContext } from '../manage-device-dialog-context'
import useManageDeviceHelper from './use-manage-device-helper'
import { inputDateTimeToTicks,  ticksToInputDateTime } from 'utilities/date-time-helpers'

// export type KioskArea = UserAreaItem & KioskAreaCreateItem

export type ManageDeviceFormModel = Readonly<{
  id: number | undefined
  kioskStatusId: KioskStatusEnum | undefined
  name: string
  comment?: string
  mainArea: UserAreaItem | undefined
  fromTime: Date | null
  toTime: Date | null
  timeBasedArea: UserAreaItem[] | undefined
  allowNavigateToPlanningPage: boolean
  allowUseMultiSigning: boolean
  useManualSessionExpiration: boolean
  manualSessionExpirationMinutes: number
  showAdditionalAreas: boolean
  showAdditionalAreasAllDay: boolean
  showAdditionalAreasStartTimeTicks: Date | null
  showAdditionalAreasEndTimeTicks: Date | null

}>

export const useManageDeviceForm: UseFormHook<ManageDeviceFormModel> = () => {
  const f = useFormatMessage()
  const { fetchActivatedDevicesListAsync } = AdministerDevicesContext.useActions()
  const { device, areas = [] } = ManageDeviceDialogContext.useState()
  if (!device) {
    throw Error('No device found')
  }

  const { handleCloseDialog, saveManageKioskItem } = ManageDeviceDialogContext.useActions()
  const { getMainArea, getTimeBasedAreas, convertHourMinuteToTime, convertTimeToHourMinute, convertAreasToKioskAreas } = useManageDeviceHelper(device, areas)

  const initialValues: ManageDeviceFormModel = React.useMemo(() => {
    const timeBasedArea = getTimeBasedAreas()
    return {
      id: device.id,
      kioskStatusId: device.kioskStatusId,
      name: device.kioskName || '',
      comment: device.comment || '',
      mainArea: getMainArea(),
      timeBasedArea,
      fromTime: (device && convertHourMinuteToTime(device.nightFromHour, device.nightFromMinute)) || null,
      toTime: (device && convertHourMinuteToTime(device.nightToHour, device.nightToMinute)) || null,
      allowNavigateToPlanningPage: device.allowNavigateToPlanningPage,
      allowUseMultiSigning: device.allowUseMultiSigning,
      useManualSessionExpiration: device.useManualSessionExpiration,
      manualSessionExpirationMinutes: device.manualSessionExpirationMinutes,
      showAdditionalAreas: device.showAdditionalAreas,
      showAdditionalAreasAllDay: device.showAdditionalAreasAllDay,
      showAdditionalAreasStartTimeTicks: device.showAdditionalAreasStartTimeTicks ? ticksToInputDateTime(device.showAdditionalAreasStartTimeTicks) : null,
      showAdditionalAreasEndTimeTicks: device.showAdditionalAreasEndTimeTicks ? ticksToInputDateTime(device.showAdditionalAreasEndTimeTicks) : null,
    }
  }, [convertHourMinuteToTime, device, getMainArea, getTimeBasedAreas])

  const onSubmit: OnSubmitFunction<ManageDeviceFormModel> = (values, { setSubmitting }) => {
    const fromTime = convertTimeToHourMinute(values.fromTime!)
    const toTime = convertTimeToHourMinute(values.toTime!)

    const model: IManageKioskItem = {
      id: values.id as number,
      kioskName: values.name,
      comment: values.comment,
      nightFromHour: fromTime.hour,
      nightFromMinute: fromTime.minute,
      nightToHour: toTime.hour,
      nightToMinute: toTime.minute,
      kioskStatusId: values.kioskStatusId as KioskStatusEnum,
      kioskAreas: convertAreasToKioskAreas(values.mainArea!, values.timeBasedArea || []) as KioskAreaCreateItem[],
      allowNavigateToPlanningPage: values.allowNavigateToPlanningPage,
      allowUseMultiSigning: values.allowUseMultiSigning,
      useManualSessionExpiration: values.useManualSessionExpiration,
      manualSessionExpirationMinutes: values.manualSessionExpirationMinutes,
      showAdditionalAreas: values.showAdditionalAreas,
      showAdditionalAreasAllDay: values.showAdditionalAreasAllDay,
      showAdditionalAreasStartTimeTicks: inputDateTimeToTicks(values.showAdditionalAreasStartTimeTicks),
      showAdditionalAreasEndTimeTicks: inputDateTimeToTicks(values.showAdditionalAreasEndTimeTicks),
    }

    saveManageKioskItem(model).finally(() => {
      setSubmitting(false)
      handleCloseDialog()
      fetchActivatedDevicesListAsync()
    })
  }

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ManageDeviceFormModel>({
        id: yup.number(),
        kioskStatusId: yup.mixed().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        name: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            50,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_NAME_FIELD_NAME_LABEL'),
              maxsize: 50,
            })
          ),
        comment: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_DESCRIPTION_LABEL'),
            maxsize: 1024,
          })
        ),
        mainArea: yup.mixed().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        timeBasedArea: yup.array(),
        fromTime: yup.date().nullable(),
        toTime: yup.date().nullable(),
        allowNavigateToPlanningPage: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        allowUseMultiSigning: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        useManualSessionExpiration: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        manualSessionExpirationMinutes: yup
          .number()
          .required(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL'),
            })
          )
          .integer(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL'),
            })
          )
          .typeError(
            f('BASIC_INTEGER_FIELD_HELPER_TEXT', {
              name: f('ADMINISTER_MANAGE_DEVICE_DIALOG_SECTION_DESCRIPTION_FIELD_MANUALSESSIONEXPIRATIONMINUTES_LABEL'),
            })
          )
          .min(1, f('BASIC_ERROR_MIN', {min:1}))
          .max(540, f('BASIC_ERROR_MAX',{max:540})),
        showAdditionalAreas: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        showAdditionalAreasAllDay: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        showAdditionalAreasStartTimeTicks: yup.mixed(),
        showAdditionalAreasEndTimeTicks: yup.mixed(),
      }),
    [f]
  )

  const name: Name<ManageDeviceFormModel> = {
    id: 'id',
    kioskStatusId: 'kioskStatusId',
    name: 'name',
    comment: 'comment',
    mainArea: 'mainArea',
    timeBasedArea: 'timeBasedArea',
    fromTime: 'fromTime',
    toTime: 'toTime',
    allowNavigateToPlanningPage: 'allowNavigateToPlanningPage',
    allowUseMultiSigning: 'allowUseMultiSigning',
    useManualSessionExpiration: 'useManualSessionExpiration',
    manualSessionExpirationMinutes: 'manualSessionExpirationMinutes',
    showAdditionalAreas: 'showAdditionalAreas',
    showAdditionalAreasAllDay: 'showAdditionalAreasAllDay',
    showAdditionalAreasStartTimeTicks: 'showAdditionalAreasStartTimeTicks',
    showAdditionalAreasEndTimeTicks: 'showAdditionalAreasEndTimeTicks',

  }

  return { initialValues, onSubmit, validationSchema, name }
}
