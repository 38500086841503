import React from 'react'

import { useOrganizationApi } from 'api'
import { IAreaOrgTreeItem } from 'api/tillit.api-client'
import usePromiseApi from 'utilities/use-promise'

export const useSidebar = () => {
  const { getNodeInfo } = useOrganizationApi()
  const [
    selectedAreaOrgTreeItem,
    setSelectedAreaOrgTreeItem,
  ] = React.useState<IAreaOrgTreeItem | null>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()

  const setSelectedArea = React.useCallback(
    (areaId: number) => {
      setSelectedAreaOrgTreeItem(null)
      return fetchAsync(getNodeInfo(areaId).then(setSelectedAreaOrgTreeItem))
    },
    [fetchAsync, getNodeInfo]
  )

  const close = React.useCallback(() => {
    setIsOpen(false)
    setSelectedAreaOrgTreeItem(null)
  }, [])

  const open = React.useCallback(() => {
    setIsOpen(true)
  }, [])

  return React.useMemo(
    () => ({
      state: { selectedAreaOrgTreeItem, isOpen, ...stateOfFetch },
      actions: {
        setSelectedArea,
        close,
        open,
      },
    }),
    [
      selectedAreaOrgTreeItem,
      isOpen,
      stateOfFetch,
      setSelectedArea,
      close,
      open,
    ]
  )
}
