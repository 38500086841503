import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, CardContent, Grid, IconButton, List, Typography, FormControlLabel, Switch } from '@mui/material'
import { ChevronLeft, ChevronRight, Plus } from 'mdi-material-ui'
import { ReactComponent as PencilIcon } from 'assets/images/icons/pencil.svg'
import { ClockAlert as TimeCritical, SyncOff as TemporaryActivity, AccountMultiple as ParticipantsIcon } from 'mdi-material-ui'
import { ISidePanelActivitiesPlanningViewItem, ISidePanelPlannedActivityRowsPlanningViewItem, SeverityLevelEnum, PlannedActivityRequiredDelegationsItem, RequiredDelegationItem } from 'api/tillit.api-client'
import Color from 'color'
import ActivityCreateModal from 'components/activity-create-modal'
import ActivityCreateModalContext from 'components/activity-create-modal/activity-create-modal-context'
import { DeleteDialog } from 'components/delete-dialog'
import { PlanningGridContext } from 'components/planning-grid/planning-grid-context'
import { SessionProps } from 'components/planning-grid/planning-grid-types'
import { useDrag, UseDragProps, UseDropProps } from 'components/planning-grid/utilities/drag-and-drop/drag-and-drop'
import { getStartTimeInMinutes } from 'components/planning-grid/utilities/get-start-time-in-minutes'
import { useDropSessionRemove } from 'components/planning-grid/utilities/use-drop-session-remove'
import produce from 'immer'
import { useFormatMessage } from 'localization'
import { Delete } from 'mdi-material-ui'
import { StyledCard, StyledTreeViewContainer } from 'pages/planning/components/sidebar/side-bar-style'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import styled from 'styled-components'
import ErrorBoundary from 'components/error-boundary'
import format from 'utilities/format'
import { combinedTitleSideBarActivity } from 'utilities/activity-helper'
import SortedParticipantsBulletList from '../../../sorted-participants-bullet-list'
import { mapSidePanelPlannedActivityRowsPlanningViewItemToMetaData, PlanMetaData } from '../../../../schedule-view'
import { useActivitiesSidepanel } from './utilities/use-activities-sidepanel'

export enum SidebarColumnType {
  Personel,
  ClientActivities,
  CommonActivities,
}

interface ISidebarColumnProps {
  columnType: SidebarColumnType
  columnName: 'personel' | 'customer' | 'area'
  title: string
  isLoading: boolean
  initialActivities: ISidePanelActivitiesPlanningViewItem[]
  initialVisibility: boolean
}

export const SideBarColumnContainer: React.FC<ISidebarColumnProps> = ({ columnType, title, initialActivities, initialVisibility = false, columnName }) => {
  const { activities, isFiltered, isVisible, toggleFilter, toggleVisibility, setIsPlannedFor, isPlanned } = useActivitiesSidepanel(
    initialActivities,
    initialVisibility
  )

  const f = useFormatMessage()
  const { calendarStartDate, containerRef } = PlanningGridContext.useState()
  const { mapMinutesToPixels } = PlanningGridContext.useActions()

  const { areas, planningId, selectedCommonActivityToDelete } = PlanningPageContext.useState()
  const {
    openSearchCustomer,
    updateRoutePlanningView,
    removeCommonActivityAsync,
    setSelectedCommonActivityToDelete,
    fetchNonCompliantRoutesAndTimesForPlannedActivityAsync,
  } = PlanningPageContext.useActions()
  const { openModal, openCreateActivityOnArea } = ActivityCreateModalContext.useActions()
  const { modalOpen, customerInfo } = ActivityCreateModalContext.useState()

  const currentArea = areas.find(area => area.areaId === planningId)
  const handleOpenModalClick = React.useCallback(() => {
    switch (columnName) {
      case 'customer':
        openSearchCustomer({
          status: true,
          type: 'showListCustomer',
        })
        break

      case 'area':
        if (currentArea) {
          openCreateActivityOnArea(currentArea.areaId)
        }
        break

      default:
        break
    }
  }, [columnName, openSearchCustomer, openModal])

  const handleCanDrop: UseDropProps['canDrop'] = React.useCallback(
    ({ type, item }) => {
      const { meta } = item as SessionProps<PlanMetaData>
      const b = isPlanned(meta.plannedActivityRowId)
      return b
    },
    [isPlanned]
  )

  const handleDrop: UseDropProps['onDrop'] = React.useCallback(
    ({ type, item, element, collection }) => {
      //Note: This does not seem necessary since entities are reloaded upon unplan anyways (and thus sidepanel is re-rendered.)
      //However. it's an issue using this code when future penmarked exists and user choses to cancel the operation in the confirm-unplan-penmarked dialogue...
      //Leaving this remarked for now if I made some false assumptions (SK 211108)
      const { meta } = item as SessionProps<PlanMetaData>

      if (!meta.isPlanned) {
        setIsPlannedFor(meta.plannedActivityRowId, false)
      }
    },
    [setIsPlannedFor]
  )

  const [drop, { canDrop, isOver, isDragging }] = useDropSessionRemove({
    canDrop: handleCanDrop,
    onDrop: handleDrop,
  })

  const handleActivityPlan = React.useCallback(
    (meta: PlanMetaData) => {
      setIsPlannedFor(meta.plannedActivityRowId, meta.isPlanned)
    },
    [setIsPlannedFor]
  )

  /**
   * The purpose of this callback is to scroll the planning view to the centre of the desired
   * start and end date when user starts dragging an activity from the sidebar.
   */
  const handleDragStart = React.useCallback(
    (meta: PlanMetaData) => {
      void fetchNonCompliantRoutesAndTimesForPlannedActivityAsync(meta.plannedActivityRowId)

      if (containerRef && containerRef.current !== null && meta.desiredStartDate && meta.desiredEndDate && calendarStartDate) {
        const desiredEndDate = new Date(meta.desiredEndDate)
        if (desiredEndDate < meta.desiredStartDate) {
          // End date will be on the same date as start date even if it has passed midnight
          desiredEndDate.setDate(desiredEndDate.getDate() + 1)
        }

        const desiredCentreAsDate = new Date((+meta.desiredStartDate + +desiredEndDate) / 2)
        const desiredCentreInMinutes = getStartTimeInMinutes(calendarStartDate, desiredCentreAsDate)

        const desiredCentreInPixels = mapMinutesToPixels(desiredCentreInMinutes)
        if (desiredCentreInPixels !== undefined) {
          const topOfDesiredCentre = desiredCentreInPixels - containerRef.current.clientHeight / 2
          containerRef.current.scrollTo({
            top: topOfDesiredCentre,
            behavior: 'smooth',
          })
        }
      }
    },
    [calendarStartDate, containerRef, mapMinutesToPixels]
  )


  const handleRemoveActivity = React.useCallback(() => {
    removeCommonActivityAsync()
    setSelectedCommonActivityToDelete(null)
  }, [removeCommonActivityAsync, setSelectedCommonActivityToDelete])

  const handleDeleteDialogOnClose = React.useCallback(() => {
    setSelectedCommonActivityToDelete(null)
  }, [])

  return (
    <>
      <StyledTreeViewContainer ref={drop} active={isVisible} isover={isOver && canDrop && isDragging}>
        <div className="title-style">
          <GridContainer>
            <Grid item onClick={toggleVisibility}>
              {isVisible ? <ChevronRight /> : <ChevronLeft />}
            </Grid>
            <GridTitle>{title}</GridTitle>
            <Box ml={'auto'} />
            <Grid item>{isVisible && <Plus color="secondary" onClick={handleOpenModalClick} />}</Grid>
          </GridContainer>
        </div>
        <div className="list-container">
          <Box mb={2} display="flex" alignItems="center">
            <FormControlLabel
              onChange={toggleFilter}
              control={<Switch checked={isFiltered} color="primary" />}
              label={f('PLANNING_PAGE_TREEVIEW_CHECKBOX_SHOW_PLANNED_ACTIVITIES')}
              labelPlacement="end"
            />
          </Box>

          <div className={`list-style`}>
            {activities
              .slice()
              .sort((a1, a2) => {
                return sortAccordingToPanelTitle(getPanelTitle(a1), getPanelTitle(a2))
              })
              .map((item, index) => {
                const { sidePanelPlannedActivityRowsPlanningViewItems = [] } = item
                return (
                  <CardContainer
                    columnType={columnType}
                    key={index}
                    title={createTitle(item)}
                    onActivityPlan={handleActivityPlan}
                    onDragStart={handleDragStart}
                    sidePanelActivities={sidePanelPlannedActivityRowsPlanningViewItems}
                  />
                )
              })}
          </div>
          {columnName === 'area' && modalOpen && !customerInfo && (
            <ActivityCreateModal
              propsTitle={f('PLANNING_PAGE_AREA_CONTAINER_CREATE_ACTIVITY_TITLE')}
              propsSubtitle={currentArea && currentArea.areaName}
              ownerAreaId={currentArea ? currentArea.areaId : undefined}
              activeAreaId={currentArea ? currentArea.areaId : undefined}
              onSave={updateRoutePlanningView}
            />
          )}
        </div>
      </StyledTreeViewContainer>
      {selectedCommonActivityToDelete !== null && (
        <DeleteDialog
          dialogTitle={f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_TITLE')}
          dialogContentText={
            selectedCommonActivityToDelete.isPlanned
              ? f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION_IS_PLANNED', { title: combinedTitleSideBarActivity(selectedCommonActivityToDelete) || '' })
              : f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_DESCRIPTION', { title: combinedTitleSideBarActivity(selectedCommonActivityToDelete) || '' })
          }
          buttonSubmitText={f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_REMOVE_BUTTON')}
          buttonCancelText={f('CUSTOMER_DIALOG_DESIRED_ACTIVITIES_VIEW_REMOVE_MODAL_CANCEL_BUTTON')}
          deleteDialogOpen
          onClose={handleDeleteDialogOnClose}
          onSubmit={handleRemoveActivity}
        />
      )}
    </>
  )
}

const getPanelTitle = (item: ISidePanelActivitiesPlanningViewItem): string => {
  if (!!item.ownerAreaId) {
    return item.ownerAreaName ?? ''
  }
  if (!!item.ownerCustomerId) {
    return (item.ownerCustomerFirstName ?? '') + (item.ownerCustomerLastName ?? '')
  }
  return ''
}

const sortAccordingToPanelTitle = (title1: string, title2: string): number => {
  if (title1 && title2 && title1 < title2) {
    return -1
  }
  if (title1 && title2 && title1 > title2) {
    return 1
  }
  return 0
}

const GridTitle: React.FC = ({ children }) => {
  return (
    <Grid
      item
      sx={{
        paddingBottom: '5px',
        whiteSpace: 'nowrap'
      }}>
      <Typography>{children}</Typography>
    </Grid>
  )
}

const GridContainer: React.FC = ({ children }) => {
  return (
    <Grid container direction="row" alignItems="center" wrap="nowrap">
      {children}
    </Grid>
  )
}

const CardContainer: React.SFC<{
  columnType: SidebarColumnType
  title: string
  sidePanelActivities: ISidePanelPlannedActivityRowsPlanningViewItem[]
  onActivityPlan?: (meta: PlanMetaData) => void
  onDragStart?: (meta: PlanMetaData) => void
}> = ({ children, title, sidePanelActivities, columnType, onActivityPlan, onDragStart }) => {
  const { calendarStartDate } = PlanningPageContext.useState()
  const { setSelectedCommonActivityToDelete, activityDelegationRequirements } = PlanningPageContext.useActions()

  const handleOnDeleteClick = React.useCallback((plannedActivityRow: ISidePanelPlannedActivityRowsPlanningViewItem) => () => {
    setSelectedCommonActivityToDelete(plannedActivityRow)
  }, [])

  const sortedSidePanelActivities = React.useMemo(() => {
    return sidePanelActivities
      .slice(0)
      .sort((a1, a2) => {
        if (a1.fromFullTimeString && a2.fromFullTimeString && a1.fromFullTimeString < a2.fromFullTimeString) {
          return -1
        }

        if (a1.fromFullTimeString && a2.fromFullTimeString && a1.fromFullTimeString > a2.fromFullTimeString) {
          return 1
        }

        return 0
      })
  }, [sidePanelActivities])

  return (
    <StyledCard>
      <CardContent>
        <Typography data-matomo-mask>{title}</Typography>
        <List dense>
          {sortedSidePanelActivities
            .map(plannedActivityRow => {
              const meta = mapSidePanelPlannedActivityRowsPlanningViewItemToMetaData(plannedActivityRow, calendarStartDate)
              return (
                <ErrorBoundary key={`e-${plannedActivityRow.id}`}>
                  <SidebarActivity
                    onDeleteClick={handleOnDeleteClick(plannedActivityRow)}
                    columnType={columnType}
                    key={plannedActivityRow.id}
                    meta={meta}
                    cardTitle={title}
                    onActivityPlan={onActivityPlan}
                    onDragStart={onDragStart}
                    activityDelegationRequirements={activityDelegationRequirements}
                  />
                </ErrorBoundary>
              )
            })}
        </List>
      </CardContent>
    </StyledCard>
  )
}

const createTitle = (item: ISidePanelActivitiesPlanningViewItem): string => {
  if (!!item.ownerAreaId) {
    return `${item.ownerAreaName}`
  }
  if (!!item.ownerCustomerId) {
    return `${item.ownerCustomerFirstName} ${item.ownerCustomerLastName}`
  }
  return '' //`Neither owner area id nor owner customer id is set for activity
}

interface ISidebarActivityProps {
  columnType: SidebarColumnType
  meta: PlanMetaData
  onDeleteClick?: () => void
  onActivityPlan?: (meta: PlanMetaData) => void
  onDragStart?: (meta: PlanMetaData) => void
  className?: string
  activityDelegationRequirements: (plannedActivityRowId: number) => (PlannedActivityRequiredDelegationsItem | null)
  cardTitle?: string
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    zIndex: 1000000,
    maxWidth: 'none',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.438), 0 1px 2px rgba(0, 0, 0, 0.65)',
  },
}));


const SidebarActivity: React.SFC<ISidebarActivityProps> = ({ meta, onActivityPlan, onDragStart, onDeleteClick, className, columnType, activityDelegationRequirements, cardTitle }) => {
  const {
    desiredStartDate,
    desiredEndDate,
    activityName: activityTitle,
    desiredVisitLengthInMinutes: lengthInMinutes,
    plannedActivityRowId: id,
    isPlanned,
    activityColor = '',
    activityReccurrenceId,
    detachedFromSeries,
    templateActivityTitle,
    activity_SeverityLevelId,
    useParticipantList,
    plannedActivityParticipants,
  } = meta

  const { openDialogInfoActivity, setNonCompliantRoutesForPlannedUserWorkSchedule, setNonCompliantRoutesandTimesForPlannedActivity, verifyPlannedHasBeenDroppedCorrectly } = PlanningPageContext.useActions()

  const f = useFormatMessage()

  if (desiredStartDate === undefined) {
    throw Error('No desired start date received')
  }
  if (desiredEndDate === undefined) {
    throw Error('No desired end date received')
  }

  const fromFullTimeString = format(desiredStartDate, 'HH:mm')
  const toFullTimeString = format(desiredEndDate, 'HH:mm')

  const soughtDate = React.useMemo(() => new Date(+desiredStartDate + (+desiredEndDate - +desiredStartDate) / 2), [desiredEndDate, desiredStartDate])

  const item = React.useMemo(() => ({ lengthInMinutes, id, soughtDate, meta }), [id, lengthInMinutes, meta, soughtDate])

  const delegationRequirements = activityDelegationRequirements(item.id)

  const handleStart: UseDragProps['onStart'] = React.useCallback(
    ({ item: draggingItem }) => {
      const { meta: draggingMeta } = draggingItem as SessionProps<PlanMetaData>
      onDragStart?.(draggingMeta)
    },
    [onDragStart]
  )

  const handleEnd: UseDragProps['onEnd'] = React.useCallback(
    ({ item: draggedItem }) => {
      setNonCompliantRoutesForPlannedUserWorkSchedule(null)
      setNonCompliantRoutesandTimesForPlannedActivity(null)
      if (onActivityPlan) {
        const { meta: draggedMeta } = draggedItem as SessionProps<PlanMetaData>
        const updatedMeta = produce(draggedMeta, draft => {
          draft.isPlanned = true
        })
        onActivityPlan(updatedMeta)
        window.setTimeout(verifyPlannedHasBeenDroppedCorrectly.bind(null, updatedMeta.plannedActivityRowId), 200)
      }
      return undefined
    },
    [onActivityPlan, verifyPlannedHasBeenDroppedCorrectly]
  )

  const openPopoverInfoActivity = React.useCallback(
    (props: any) => {
      openDialogInfoActivity(true, props.event.id, {
        top: props.originalEvent.y,
        left: props.originalEvent.x,
      })
    },
    [openDialogInfoActivity]
  )

  const handleOnClick = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    openPopoverInfoActivity({
      event: { id },
      originalEvent: {
        x: event.clientX,
        y: event.clientY,
      },
    })
  }, [id])

  const handleCanDrag = React.useCallback(() => {
    return !isPlanned
  }, [isPlanned])

  // eslint-disable-next-line
  const [_, drag, { isDragging }] = useDrag('SESSION_ADD', item, {
    onStart: handleStart,
    onEnd: handleEnd,
    canDrag: handleCanDrag,
  })

  const timeString = `${fromFullTimeString} - ${toFullTimeString} (${lengthInMinutes} ${f('BASIC_TEXT_MINUTES_ABBREVIATION')})`

  const oneTimeOccurrence = 4 //Accoring to Recurrences-table

  const textColor = isPlanned || isDragging || Color(activityColor).contrast(Color('rgba(0, 0, 0, 0.82)')) > 5.0 ? 'rgba(0, 0, 0, 0.82)' : 'white'

  const combinedActivityTitle = templateActivityTitle
    ? templateActivityTitle + ' ' + (activityTitle ? '(' + activityTitle + ')' : '')
    : activityTitle
      ? activityTitle
      : ''

  return (
    <SidebarActivityWrapper>

      <HtmlTooltip data-matomo-mask followCursor
        title={
          <>
            <Typography data-matomo-mask variant="subtitle2" pt="1vh" fontWeight={600}>{`${cardTitle}: ${combinedActivityTitle}`}</Typography>
            {delegationRequirements && delegationRequirements.delegationItems && delegationRequirements.delegationItems.length > 0 && (
              <Typography variant="subtitle2" pt="1vh" fontWeight={600}>{f('ROUTE_MENU_PLANNING_REQUIREMENTS')}</Typography>
            )
            }
            {delegationRequirements && delegationRequirements.delegationItems?.map((rdi: RequiredDelegationItem) => (
              <Typography key={`rdpa_rdi_${rdi.id}`} variant="subtitle2" fontWeight={400}> • {rdi.title}</Typography>
            ))
            }
            {useParticipantList && plannedActivityParticipants && plannedActivityParticipants.length > 0 && (
              <Typography variant="subtitle2" pt="1vh" fontWeight={600}>{f('ROUTE_MENU_PLANNING_PARTICIPANTS')}</Typography>
            )}
            {useParticipantList && plannedActivityParticipants && plannedActivityParticipants.length > 0 && (
              <SortedParticipantsBulletList participants={plannedActivityParticipants} />
            )
            }

          </>
        }
      >
        <SidebarActivityStyle ref={drag} isPlanned={isPlanned || isDragging} textColor={textColor} activityColor={activityColor} onClick={handleOnClick}>
          <Typography
            data-matomo-mask
            variant="subtitle2"
            style={{
              color: textColor,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {combinedActivityTitle}
          </Typography>
          <RightSection className="typography-time">
            {timeString && (
              <Typography
                variant="subtitle2"
                style={{
                  paddingRight: '4px',
                  color: textColor,
                  whiteSpace: 'nowrap',
                }}>
                {timeString}
              </Typography>
            )}
            {activity_SeverityLevelId === SeverityLevelEnum.Critical && (
              <div
                style={{
                  height: '20px',
                  width: '18px',
                  display: 'inline-block',
                  paddingLeft: '2px',
                  borderRadius: '2px'
                }}>
                <TimeCritical
                  style={{
                    display: 'block',
                    height: '18px',
                    width: '16px',
                    fill: textColor,
                  }}
                />
              </div>
            )}
            {useParticipantList && plannedActivityParticipants && plannedActivityParticipants.length > 0 && (
              <div
                style={{
                  height: '20px',
                  width: '18px',
                  display: 'inline-block',
                  paddingLeft: '2px',
                  borderRadius: '2px'
                }}>
                <ParticipantsIcon
                  style={{
                    display: 'block',
                    height: '18px',
                    width: '16px',
                    fill: textColor,
                  }}
                />
              </div>
            )}
            {activityReccurrenceId === oneTimeOccurrence && (
              <div
                style={{
                  height: '20px',
                  width: '18px',
                  display: 'inline-block',
                  paddingLeft: '2px',
                  borderRadius: '2px'
                }}>
                <TemporaryActivity
                  style={{
                    display: 'block',
                    height: '18px',
                    width: '16px',
                    fill: textColor,
                  }}
                />
              </div>
            )}
            {detachedFromSeries && (
              <div
                style={{
                  height: '20px',
                  width: '18px',
                  display: 'inline-block',
                  paddingLeft: '2px',
                  borderRadius: '2px'
                }}>
                <PencilIcon
                  style={{
                    display: 'block',
                    height: '18px',
                    width: '16px',
                    fill: textColor,
                  }}
                />
              </div>
            )}

          </RightSection>
        </SidebarActivityStyle>
      </HtmlTooltip>
      {activityReccurrenceId === oneTimeOccurrence && (
        <DeleteIconButton>
          <IconButton size="small" onClick={onDeleteClick}>
            <Delete htmlColor="white" />
          </IconButton>
        </DeleteIconButton>
      )}
    </SidebarActivityWrapper>
  )
}

const DeleteIconButton = styled.div`
    position: relative;
    min-width: 34px;
    min-height: 34px;
    margin-left: 8px;
    background-color:#DF3E31;
    border-radius: 5px;
  `

const SidebarActivityWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 5px 0;

  ${DeleteIconButton} {
    display: none;
  }

  &:hover {
    ${DeleteIconButton} {
      display: inherit;
    }
  }
`

const RightSection = styled('div')`
  display: flex;
  flex-direction: row;
`

type SidebarActivityStyleProps = Readonly<{
  isPlanned: boolean
  activityColor: string
  textColor: string
}>
const SidebarActivityStyle = styled.div<SidebarActivityStyleProps>`
  flex: 1;
  color: ${props => props.textColor};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 34px;
  padding: 0 5px;
  border-radius: 5px;
  user-select: none;

  ${props =>
    props.isPlanned
      ? `
        border: 2px dashed rgb(179, 179, 179);
        color: rgb(179, 179, 179);
      `
      : `
        border: 2px dashed transparent;
        cursor: pointer;
        background-color: ${props.activityColor};
      `
  };
`;
