import { ThemeOptions } from '@mui/material/styles'
import Color from './colors'

const THEME: Readonly<ThemeOptions> = {
  palette: {
    primary: {
      main: Color.TeniloGreen,
    },
    secondary: {
      main: Color.TeniloBrown,
    },
    error: {
      main: Color.Punch,
    },
    info:{
      main:Color.LightBlue
    },
    text: {
      primary: Color.Tuna,
      secondary: 'rgba(0, 0, 0, 0.82)',
    }
  },
  typography: {
    fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 16,
    h1: {
      fontSize: 96,
      letterSpacing: -1.5,
      fontWeight: 300,
      color: 'rgba(0, 0, 0, 0.82)',
      '@media (max-width:600px)': {
        fontSize: 50,
      },
    },
    h2: {
      fontSize: 50,
      letterSpacing: -0.5,
      fontWeight: 300,
      color: 'rgba(0, 0, 0, 0.82)',
    },
    h3: {
      fontSize: 48,
      letterSpacing: 0.0,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.82)',
      '@media (max-width:600px)': {
        fontSize: 34,
      },
    },
    h4: {
      fontSize: 34,
      letterSpacing: 0.25,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.82)',
      '@media (max-width:600px)': {
        fontSize: 24,
      },
    },
    h5: {
      fontSize: 24,
      letterSpacing: 0,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.82)',
      '@media (max-width:600px)': {
        fontSize: 20,
      },
    },
    h6: {
      fontSize: 20,
      letterSpacing: 0.15,
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.82)',
      '@media (max-width:600px)': {
        fontSize: 16,
      },
    },
    subtitle1: {
      fontSize: 16,
      letterSpacing: 0.15,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.82)',
      '@media (max-width:600px)': {
        fontSize: 15,
      },
    },
    subtitle2: {
      fontSize: 14,
      letterSpacing: 0.1,
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.82)',
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0.5,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.82)',
      '@media (max-width:600px)': {
        fontSize: 15,
      },
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.25,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.82)',
    },
    button: {
      fontSize: 14,
      letterSpacing: 1.25,
      fontWeight: 500,
      textTransform: 'initial',
    },
    caption: {
      fontSize: 12,
      letterSpacing: 0.4,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.82)',
    },
    // Overline SF Pro Display Regular 10px All caps 1.5
    overline: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.82)',
    },
  },
  customBreakpoints:{
    justMobileTablet: '@media only screen and (max-device-width: 900px)'
  }
}

export { THEME }
