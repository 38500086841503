import React from 'react'
import ContainedButton from 'components/inputs/contained-button'
import { useTheme } from '@mui/material'
import { useFormatMessage } from 'localization'
import { AdministerDevicesContext } from 'pages/administer/components/administer-devices'
import useMediaQuery from '@mui/material/useMediaQuery'
import FilledSearchTextField from 'components/inputs/filled-search-text-field'
import { ActivateDeviceDialogContext } from '../activate-device-dialog/activate-device-dialog-context'


const DeviceSectionView: React.FC = () => {
  const f = useFormatMessage()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.customBreakpoints.justMobileTablet)
  const { handleOpenDialogForNewDevice } = ActivateDeviceDialogContext.useActions()
  const { filterValue } = AdministerDevicesContext.useState()
  const { handleChangeFilter, handleClearFilter } = AdministerDevicesContext.useActions()

  return (
    <div style={{ columnCount: 2 }}>
      <ContainedButton color='secondary' onClick={handleOpenDialogForNewDevice}>
        {f('ADMINISTER_PAGE_VIEW_DEVICE_SECTION_VIEW_BUTTON_Add_DEVICE_TEXT')}
      </ContainedButton>
      {!smallScreen && (
        <div style={{ marginRight: 0, marginLeft: 'auto', paddingBottom: '1vh', width: '50%' }}>
          <FilledSearchTextField
            label={f('BASIC_SEARCH_FIELD_PLACEHOLDER')}
            handleChangeFilter={handleChangeFilter}
            handleClearFilter={handleClearFilter }
            searchValue={ filterValue }
          />
        </div>
      )}
    </div>
  )
}

export default DeviceSectionView
