import React from 'react'
import { Route, Navigate, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { RoleEnum } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { RouterErrorBoundary, RootRouterErrorBoundary } from 'components/error-boundary'
import PageRoute from 'config/page-routes'
import ValidateDailyActivities, { ValidationLoader } from 'pages/validate-daily-activities/validate-daily-activities'
import DeviceRoute from './components/device-route'
import PrivateRoute from './components/private-route'
import PublicRoute from './components/public-route'
import RoleBasedRoute from './components/role-based-route'


const NavigateRoot = () => {
  const { isLoggedIn, isDeviceLoggedIn } = Authentication.useActions()
  return (
    isLoggedIn() ?
      <Navigate to={PageRoute.Dashboard.path} />
      : isDeviceLoggedIn()
        ? <Navigate to={PageRoute.DailyActivities.path} />
        : <Navigate to={PageRoute.Login.path} />
  )
}

const routeObjects = createRoutesFromElements(

  <Route errorElement={<RootRouterErrorBoundary />}>

    <Route element={<PublicRoute />} >
      <Route {...PageRoute.GidsCallbackRequest} />
      <Route {...PageRoute.ImpersonationRequest} />
      <Route {...PageRoute.ForgotPassword} />
      <Route {...PageRoute.ResetPassword} />
      <Route {...PageRoute.ResetPinCode} />
      <Route {...PageRoute.Login} />
    </Route>

    <Route element={<DeviceRoute />} errorElement={<RouterErrorBoundary />}>
      <Route {...PageRoute.DailyActivities}>
        <Route path={PageRoute.DailyActivitiesWithDevice.path} element={PageRoute.DailyActivities.element} />
      </Route>
    </Route>

    <Route element={<PrivateRoute />} errorElement={<RouterErrorBoundary />}>
      <Route {...PageRoute.Profile} />
      <Route {...PageRoute.ChangePassword} />
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]} />}>
        <Route {...PageRoute.Dashboard} />
      </Route>
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin]} />}>
        <Route {...PageRoute.Administer} />
      </Route>
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin, RoleEnum.Planner, RoleEnum.Caretaker]} />}>
        <Route {...PageRoute.Users} />
      </Route>
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]} />}>
        <Route {...PageRoute.Customers} />
      </Route>
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin, RoleEnum.Planner, RoleEnum.Caretaker]} />} >
        <Route {...PageRoute.Areas} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route {...PageRoute.Planning} />
      </Route>
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin]} />}>
        <Route {...PageRoute.Organization} />
      </Route>
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin, RoleEnum.Planner]} />}>
        <Route {...PageRoute.Reports} />
      </Route>
      <Route element={<RoleBasedRoute allowedRoles={[RoleEnum.Admin]} />}>
        <Route {...PageRoute.Support} />
      </Route>
    </Route>

    <Route path="*" element={<NavigateRoot />} />
  </Route>
)



const RouterContainer = createBrowserRouter(
  [
    ...routeObjects,
    { id: 'vl0', path: PageRoute.ValidateDailyActivitiesWithDevice.path, Component: ValidateDailyActivities, loader: ValidationLoader }

  ]
)


export default RouterContainer
