import { Field, FieldProps } from 'formik'
import React, { useState } from 'react'
import { TwitterPicker } from 'react-color'
import ColorPickerStyle, {
  ColorPickerCircleColor,
  ColorPickerCover,
  ColorPickerPopover,
} from './color-picker-style'

interface IProps {
  name: string
  defaultColor?: string
}

const ColorPickerContainer: React.FC<IProps> = ({
  name,
  defaultColor = '#ffffff',
}) => {
  const [dialogPicker, setDialogPicker] = useState(false)

  const handleClick = () => {
    setDialogPicker(true)
  }

  const handleClose = () => {
    setDialogPicker(false)
  }

  const handleChange = (setFieldValue: any, fieldName: string) => (
    val: any
  ) => {
    setFieldValue(fieldName, val.hex)
    setDialogPicker(false)
  }

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <>
          <ColorPickerStyle onClick={handleClick}>
            <ColorPickerCircleColor color={field.value || defaultColor} />
          </ColorPickerStyle>
          {dialogPicker ? (
            <ColorPickerPopover>
              <ColorPickerCover onClick={handleClose} />
              <TwitterPicker
                color={field.value || defaultColor}
                onChange={handleChange(form.setFieldValue, field.name)}
                width={'450px'}
                triangle="hide"
                colors={[
                  '#0B499D',
                  '#5C95E6',
                  '#6D4AB1',
                  '#930A7E',
                  '#E26A8C',
                  '#C32C2C',
                  '#FD903E',
                  '#F0C200',
                  '#BDBDBD',
                  '#6F6E6E',
                  '#000000',
                  '#569F58',
                  '#42B4BA',
                  '#065357',
                  '#572300',
                  '#A25018',
                ]}
              />
            </ColorPickerPopover>
          ) : null}
        </>
      )}
    </Field>
  )
}

export default ColorPickerContainer
