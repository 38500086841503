import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { useFormikContext } from 'formik'
import { Box, ToggleButtonGroup, ToggleButton,  CircularProgress } from '@mui/material'
import type { ReportsPageFormType } from 'pages/reports/config'
import { SelectionEnum } from 'api/tenilo.reporting.api-client'
import { ReportListItem } from 'api/tenilo.reporting.api-client'
import ReportsPageContext from 'pages/reports/reports-page-context'
import { ReportSelectList } from '../reports-page-inputs'

const ReportsPageSelectReportView : FC = () => {
  const f = useFormatMessage()
  const { reportList , report, userReport} = ReportsPageContext.useState()
  const { fetchReport, fetchUserReport, setReport, setReportData, setUserReport } = ReportsPageContext.useActions()
  const { values, resetForm, isSubmitting } = useFormikContext<ReportsPageFormType>()
  const [filterReports, setFilterReports] = React.useState('all')

  const handleFilterReports = React.useCallback((
    event: React.MouseEvent<HTMLElement>,
    newFilterType: string,
  ) => {
    setFilterReports(newFilterType)
  },[])

  const filteredOptions = React.useMemo(() =>{
    switch (filterReports) {
      case 'all':
        return reportList
      case 'base':
        return reportList?.filter((option) => (option as ReportListItem).isBaseReport)
      case 'user':
        return reportList?.filter((option) => (option as ReportListItem).isUserReport)
      case 'area':
        return reportList?.filter((option) => (option as ReportListItem).isAreaReport)
      case 'org':
        return reportList?.filter((option) => (option as ReportListItem).isOrganizationReport)
      default:
        return reportList
    }
  },[filterReports, reportList])

  React.useEffect(()=>{
    if(values.report){
      if (values.report.isBaseReport) {
        fetchReport(values.report.reportId).catch(err=>{console.log(err)})
      } else {
        if (values.report.userReportId) {
          fetchUserReport(values.report.userReportId).catch(err=>{console.log(err)})
        }
      }
    }
  },[values.report])

  const handleResetForm = () => {
    setReport(undefined)
    setUserReport(undefined)
    setReportData(undefined)
    resetForm(
      {
        values:{
          name: undefined,
          description: undefined,
          report: undefined,
          fields: undefined,
          submitType: undefined,
          ...Object.keys(SelectionEnum).reduce((acc,curr)=>{
              acc[curr] = undefined
              return acc
          },{})
      }}
    )
  }


  return (
    <Box sx={{display:'flex',justifyContent:'space-between'}}>
      <Box sx={{width:'50%'}}>
        <ReportSelectList
          name="report"
          multiple={false}
          options={filteredOptions || []}
          lableKey="name"
          textFieldLable={f('REPORT_PAGE_VIEW_SELECT_TITLE')}
          onChangeCb={handleResetForm}
        />
      </Box>
      {
        values.report && (
          ((!report && !userReport) || isSubmitting) && (
            <CircularProgress size={30} color="primary" />
          )
        )
      }
      <ToggleButtonGroup
        color="primary"
        sx={{
          display:'flex',
          '*':{
            minWidth:'80px',
            padding:'6px'
          }
        }}
        value={filterReports}
        exclusive
        onChange={handleFilterReports}
      >
        <ToggleButton value="all">{f('REPORT_PAGE_VIEW_FILTER_REPORTS_ALL')}</ToggleButton>
        <ToggleButton value="base">{f('REPORT_PAGE_VIEW_FILTER_REPORTS_BASE')}</ToggleButton>
        <ToggleButton value="org">{f('REPORT_PAGE_VIEW_FILTER_REPORTS_ORGANISATION')}</ToggleButton>
        <ToggleButton value="area">{f('REPORT_PAGE_VIEW_FILTER_REPORTS_AREA')}</ToggleButton>
        <ToggleButton value="user">{f('REPORT_PAGE_VIEW_FILTER_REPORTS_USER')}</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default ReportsPageSelectReportView
