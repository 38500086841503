import React from 'react'
import { PropTypes, InputAdornment, IconButton, Icon } from '@mui/material'
import { OutlinedTextFieldProps } from '@mui/material/TextField'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { Eye, EyeOff } from 'mdi-material-ui'

type Props = Omit<OutlinedTextFieldProps, 'variant'> & {
  color?: PropTypes.Color
}

const OutlinedTextFieldView: React.FC<Props> = props => {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = React.useCallback(() => setShowPassword((show) => !show),[])

  return (
    <Field
      variant={'outlined'}
      InputProps={{
        endAdornment: props.type === 'password' ? <InputAdornment position="end"><IconButton sx={{padding:'0px'}} onClick={handleClickShowPassword} id='showpassword-eye-icon-button'><Icon sx={{fontSize:'18px'}} color="primary" component={ showPassword ? EyeOff : Eye } /></IconButton></InputAdornment> : null,
        type: props.type === 'password' ? showPassword ? 'text' : 'password' : props.type
      }}
      helperText={'\u00a0'}
      {...props}
      component={TextField}
    />
  )

}

export default OutlinedTextFieldView
