import React from 'react'
import { useTemplateActivityApi } from 'api'
import { TemplateActivityInheritanceItem } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'
import { getVisibleAreaData } from 'utilities/area-helper'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'

export type TemplateActivityCreateModel = Readonly<{
  title: string
  ownerAreaId: number
  fromTimeHour: number
  fromTimeMinute: number
  toTimeHour: number
  toTimeMinute: number
}>

const useAdministerInheritedTemplateActivitiesContext = () => {
  const { listInheritedTemplateActivities, deleteTemplateActivityInheritance, abort, hasTemplateActivityConnectedToActivity } = useTemplateActivityApi()
  const { state: stateOfFetch, fetchAsync } = usePromiseApi()
  const { user: currentUser } = Authentication.useAuthenticatedState()

  const [inheritedTemplateActivityListItems, setInheritedTemplateActivityListItems] = React.useState<TemplateActivityInheritanceItem[]>([]) // SK: WAS PageResultOfCustomerListItem
  const [modalOpen, setModalOpen] = React.useState(false)

  const location = useLocation()

  const fetchTemplateActivitiesAsync = React.useCallback(async () => {
    if (!currentUser.organizationId) {
      return
    }
    await fetchAsync(listInheritedTemplateActivities(currentUser.organizationId).then((items)=>{
      const visibleArea = getVisibleAreaData().map(area=>area.areaId)
      if(!isEmpty(visibleArea)){
        items = items.filter(area=>visibleArea.includes(area.areaId))
      }
      setInheritedTemplateActivityListItems(items)
    }))
  }, [currentUser.organizationId, fetchAsync, listInheritedTemplateActivities])

  const openModal = React.useCallback(() => {
    setModalOpen(true)
  }, [])
  const closeModal = React.useCallback(() => setModalOpen(false), [])

  // When this component unmounts, abort all ongoing calls
  React.useEffect(() => () => abort(), [abort])

  React.useEffect(() => {
    void fetchTemplateActivitiesAsync()
  }, [fetchTemplateActivitiesAsync, location])

  const { state: stateOfRemoveFetch, fetchAsync: removeFetchAsync } = usePromiseApi()

  const [templateActivityToRemove, setTemplateActivityToRemove] = React.useState<TemplateActivityInheritanceItem | null>(null)

  const removeDialogOpen = React.useMemo(() => templateActivityToRemove !== null, [templateActivityToRemove])

  const [templateActivityInvalidToRemove, setTemplateActivityInvalidToRemove] = React.useState<TemplateActivityInheritanceItem | null>(null)

  const removeInvalidDialogOpen = React.useMemo(() => templateActivityInvalidToRemove !== null, [templateActivityInvalidToRemove])

  const closeRemoveInvalidDialog = React.useCallback(() => setTemplateActivityInvalidToRemove(null), [setTemplateActivityInvalidToRemove])

  const openRemoveDialog = React.useCallback(
    (templateActivity: TemplateActivityInheritanceItem) => {
      void hasTemplateActivityConnectedToActivity(templateActivity.templateActivityId, templateActivity.areaId).then(result => {
        if (result) {
          setTemplateActivityInvalidToRemove(templateActivity)
        } else {
          setTemplateActivityToRemove(templateActivity)
        }
      })
    },
    [setTemplateActivityToRemove, setTemplateActivityInvalidToRemove, hasTemplateActivityConnectedToActivity]
  )

  const closeRemoveDialog = React.useCallback(() => setTemplateActivityToRemove(null), [setTemplateActivityToRemove])

  const deleteTemplateActivityAsync = React.useCallback(
    templateActivityId => {
      void removeFetchAsync(
        deleteTemplateActivityInheritance(templateActivityId)
          .then(fetchTemplateActivitiesAsync)
          .then(closeRemoveDialog)
      )
    },
    [removeFetchAsync, deleteTemplateActivityInheritance, fetchTemplateActivitiesAsync, closeRemoveDialog]
  )

  const isRemoving = stateOfRemoveFetch.isLoading

  return {
    state: {
      ...stateOfFetch,
      inheritedTemplateActivityListItems,
      modalOpen,
      removeDialogOpen,
      templateActivityToRemove,
      templateActivityInvalidToRemove,
      isRemoving,
      removeInvalidDialogOpen,
    },
    actions: {
      fetchTemplateActivitiesAsync,
      openModal,
      closeModal,
      openRemoveDialog,
      closeRemoveDialog,
      closeRemoveInvalidDialog,
      deleteTemplateActivityAsync,
    },
  }
}

export const AdministerInheritedTemplateActivitiesContext = createContext(useAdministerInheritedTemplateActivitiesContext)
