import { Box } from '@mui/material'
import React from 'react'
import BasicInformationSection from 'components/user-dialog/components/basic-information-section'
import RoleSection from 'components/user-dialog/components/role-section'
import SecuritySection from 'components/user-dialog/components/security-section'
import { FormikProps } from 'formik'
import { UserFormModelWithModifiedAreas } from 'components/user-dialog/utilities/use-user-form'

const UserCreateForm: React.FC<FormikProps<UserFormModelWithModifiedAreas>> = ({...props}) => {

  return (
    <Box sx={{padding:'24px'}}>
      <BasicInformationSection {...props} />
      <RoleSection {...props} />
      <SecuritySection  {...props} />
    </Box>

  )
}

export default UserCreateForm
