import { IRouteUpdateItem } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { PlanningPageContext } from 'pages/planning/planning-page-context'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'

interface IEditRouteModel {
  routeName: string
  routeColor: string
}

const useEditRouteModalFormData = () => {
  const f = useFormatMessage()
  const {
    editRouteModalData,
    editRouteModalDataIsCombinedRoute,
    routes = [],
  } = PlanningPageContext.useState()
  const {
    onCloseEditRouteModal,
    editRouteAsync,
    editTimeBasedRouteContainerAsync,
  } = PlanningPageContext.useActions()

  const routeNames = routes.reduce<string[]>((prevRoute, route) => {
    if (
      editRouteModalData &&
      editRouteModalData.name &&
      route.name === editRouteModalData.name &&
      route.id === editRouteModalData.id
    ) {
      return prevRoute
    }
    return [...prevRoute, route.name]
  }, [])

  const initialValues: IEditRouteModel = React.useMemo(
    () => ({
      routeName:
        editRouteModalData && editRouteModalData.name
          ? editRouteModalData.name
          : '',
      routeColor:
        editRouteModalData && editRouteModalData.color
          ? editRouteModalData.color
          : '',
    }),
    [editRouteModalData]
  )

  const onSubmit: OnSubmitFunction<IEditRouteModel> = React.useCallback(
    (values, { setSubmitting }) => {
      if (editRouteModalDataIsCombinedRoute) {
        editTimeBasedRouteContainerAsync(
          editRouteModalData!,
          values.routeName,
          values.routeColor
        )
      } else {
        const model: IRouteUpdateItem = {
          id: editRouteModalData!.id,
          name: values.routeName,
          color: values.routeColor,
          
        }
        editRouteAsync(model, editRouteModalData!.areaId)
      }

      onCloseEditRouteModal()
      setSubmitting(false)
    },
    [
      onCloseEditRouteModal,
      editRouteAsync,
      editTimeBasedRouteContainerAsync,
      editRouteModalData,
      editRouteModalDataIsCombinedRoute,
    ]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<IEditRouteModel>({
        routeName: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .notOneOf(routeNames, f('ROUTE_EDIT_MODAL_UNIQUE_NAME_HELPER_TEXT'))
          .max(
            40,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('PLANNING_PAGE_SCHEDULE_VIEW_EDITROUTE_FORM_NAME_LABEL'),
              maxsize: 40,
            })
          ),
        routeColor: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            7,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f(
                'ACTIVITY_DIALOG_CONTAINER_OTHERSECTION_FORM_COLORPICKER_LABEL'
              ),
              maxsize: 7,
            })
          ),
      }),
    [routeNames, f]
  )

  const name: Name<IEditRouteModel> = React.useMemo(
    () => ({
      routeName: 'routeName',
      routeColor: 'routeColor',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useEditRouteModalFormData
