import { useFormatMessage } from 'localization'

export interface IRouteMenuOption {
  name: 'addRight' | 'addLeft' | 'addPersonnel' | 'edit' | 'remove'
  label: string
}

export const useRouteMenuOptions = (
  type: 'areas' | 'devices'
): {
  routeMenuOptions: IRouteMenuOption[]
} => {
  const f = useFormatMessage()

  if (type === 'areas') {
    return {
      routeMenuOptions: [
        {
          name: 'addRight',
          label: f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_ADDRIGHT_ITEM_TEXT'),
        },
        {
          name: 'addLeft',
          label: f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_ADDLEFT_ITEM_TEXT'),
        },
        {
          name: 'edit',
          label: f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_EDIT_ITEM_TEXT'),
        },
        {
          name: 'remove',
          label: f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ITEM_TEXT'),
        },
      ],
    }
  }

  // type === 'devices'
  return {
    routeMenuOptions: [
      {
        name: 'edit',
        label: f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_EDIT_ITEM_TEXT'),
      },
      {
        name: 'remove',
        label: f('PLANNING_PAGE_ROUTE_MENU_OPTIONS_REMOVE_ITEM_TEXT'),
      },
    ],
  }
}
