import React from 'react'
import * as yup from 'yup'

import { IChangePasswordError } from 'api/tillit.api-client'
import Authentication from 'components/authentication'
import { useFormatMessage } from 'localization'
import { Name, OnSubmitFunction } from 'types'

type ChangePasswordForm = Readonly<{
  currentPassword: string
  newPassword: string
  confirmPassword: string
}>

// Validate (lazy) and cast an object to a IChangePasswordError
const isChangePasswordError = (object: any): object is IChangePasswordError => {
  const changePasswordError = object as IChangePasswordError
  return changePasswordError.type !== undefined
}

const handleError = (setStatus: (status?: any) => void) => (error: any) => {
  const data = error.response && JSON.parse(error.response).data
  if (data && isChangePasswordError(data)) {
    let errNo = 2
    if (data.type.toString() === 'PasswordIncorrect') {
      errNo = 1
    }
    setStatus({ error: errNo })
  } else {
    setStatus({ error: 1 })
  }
}

// Define the hook to be used to leverage this form
const useChangePasswordForm = () => {
  const { changePassword } = Authentication.useActions()
  const f = useFormatMessage()

  const initialValues: ChangePasswordForm = React.useMemo(
    () => ({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }),
    []
  )

  const onSubmit: OnSubmitFunction<ChangePasswordForm> = React.useCallback(
    ({ currentPassword, newPassword }, { setSubmitting, setStatus }) => {
      changePassword(currentPassword, newPassword)
        .then(() => {
          setStatus({ error: 100 })
        })
        .catch(handleError(setStatus))
        .finally(() => {
          setSubmitting(false)
        })
    },
    [changePassword]
  )

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<ChangePasswordForm>({
        currentPassword: yup
          .string()
          .min(0)
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        newPassword: yup
          .string()
          .min(8, f('BASIC_REQUIRED_PASSWORD_LENGTH_HELPER_TEXT'))
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .matches(
            /^[\S]*$/,
            f(
              'CHANGE_PASSWORD_SCENE_NEW_PASSWORD_CANNOT_CONTAIN_SPACES_ERROR_TEXT'
            )
          ),
        confirmPassword: yup
          .string()
          .min(8, f('BASIC_REQUIRED_PASSWORD_LENGTH_HELPER_TEXT'))
          .oneOf(
            [yup.ref('newPassword')],
            f('RESET_PASSWORD_PAGE_CONFIRM_PASSWORD_VALIDATION_EQUAL')
          )
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .matches(
            /^[\S]*$/,
            f(
              'CHANGE_PASSWORD_SCENE_NEW_PASSWORD_CANNOT_CONTAIN_SPACES_ERROR_TEXT'
            )
          ),
      }),
    [f]
  )

  const name: Name<ChangePasswordForm> = React.useMemo(
    () => ({
      currentPassword: 'currentPassword',
      newPassword: 'newPassword',
      confirmPassword: 'confirmPassword',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useChangePasswordForm
