import { useFormatMessage } from 'localization'
import React from 'react'
import { Name, OnSubmitFunction } from 'types'
import * as yup from 'yup'
import { CustomerCreateModel, CustomersPageContext } from '../../../customers-page-context'

// Define the hook to be used to leverage this form
// useCustomerCreateFormData
const useCustomerCreateFormData = () => {
  const f = useFormatMessage()
  const { createCustomerAsync, closeModal } = CustomersPageContext.useActions()

  const initialValues: CustomerCreateModel = React.useMemo(
    () => ({
      active: true,
      firstName: '',
      lastName: '',
      description: '',
      areaId: -1,
      extraInfo: '',
    }),
    []
  )

  const onSubmit: OnSubmitFunction<CustomerCreateModel> = React.useCallback(
    (values, { setSubmitting }) => {
      closeModal()
      createCustomerAsync(values)
        .then(success => success)
        .finally(() => setSubmitting(false))
    },
    [closeModal, createCustomerAsync]
  )
  const validationSchema = React.useMemo(
    () =>
      yup.object().shape<CustomerCreateModel>({
        active: yup.boolean().required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
        firstName: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_FIRSTNAME_LABEL'),
              maxsize: 128,
            })
          ),
        lastName: yup
          .string()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_CREATE_DIALOG_CONTAINER_INFOSECTION_FORM_TEXTFIELD_LASTNAME_LABEL'),
              maxsize: 128,
            })
          ),
        extraInfo: yup
          .string()
          .max(
            128,
            f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
              name: f('CUSTOMER_CREATE_DIALOG_CONTAINER_EXTRAINFO_TEXTFIELD_LABEL'),
              maxsize: 128,
            })
          )
          .notRequired(),
        description: yup.string().max(
          1024,
          f('BASIC_WARNING_MAXSIZE_FIELD_TEXT', {
            name: f('CUSTOMER_CREATE_DIALOG_CONTAINER_OTHERSECTION_FORM_OTHER_TEXTFIELD_LABEL'),
            maxsize: 1024,
          })
        ),
        areaId: yup
          .number()
          .required(f('BASIC_REQUIRED_FIELD_HELPER_TEXT'))
          .min(0, f('BASIC_REQUIRED_FIELD_HELPER_TEXT')),
      }),
    [f]
  )

  const name: Name<CustomerCreateModel> = React.useMemo(
    () => ({
      active: 'active',
      firstName: 'firstName',
      lastName: 'lastName',
      description: 'description',
      areaId: 'areaId',
      extraInfo: 'extraInfo',
    }),
    []
  )

  return {
    initialValues,
    onSubmit,
    validationSchema,
    name,
  }
}

export default useCustomerCreateFormData
