import React from 'react'
import { IUserItem, UserExpiringDelegationItem } from 'api/tillit.api-client'
import { createContext } from 'utilities/create-context'

const useExpiringDelegationsModalContext = () => {

  const [expiringDelegationsModalOpen, setExpiringDelegationsModalOpen] = React.useState<boolean>(false)
  const [loggedInUser, setLoggedInUser] = React.useState<IUserItem | undefined>(undefined)
  const [expiringDelegations, setExpiringDelegations] = React.useState<UserExpiringDelegationItem[]>([]);

  const openExpiringDelegationsModal = React.useCallback(
    (loggedInUser: IUserItem | undefined) => {
      if (!loggedInUser) {
        return
      }
      setLoggedInUser(loggedInUser)
      setExpiringDelegations(loggedInUser.expiringDelegations || [])
      setExpiringDelegationsModalOpen(true)
    },
    []
  )

  const closeExpiringDelegationsModal = React.useCallback(() => {
    setExpiringDelegationsModalOpen(false)
    setExpiringDelegations([])
    setLoggedInUser(undefined)
  }, [])

  return {
    state: {
      expiringDelegationsModalOpen,
      loggedInUser,
      expiringDelegations,
    },
    actions: {
      openExpiringDelegationsModal,
      closeExpiringDelegationsModal,
    },
  }
}

export const ExpiringDelegationsModalContext = createContext(useExpiringDelegationsModalContext)
