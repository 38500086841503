import React from 'react'
import format from 'utilities/format'
import { Typography } from '@mui/material'
import { InnerContext } from '../../contexts/inner-context'
import Constants from '../../planning-grid-constants'
import { PlanningGridProps } from '../../planning-grid-container'
import { SessionProps, TrackProps } from '../../planning-grid-types'
import { useLayout } from '../../utilities/drag-and-drop/drag-and-drop'

type CustomDragLayerProps = Readonly<{
  renderSession: PlanningGridProps<TrackProps, SessionProps>['renderSession']
}>

// eslint-disable-next-line react/display-name
export const CustomDragLayerContainer: React.FC<CustomDragLayerProps> = React.memo(({ renderSession }) => {


  const { containerRef, tracks, calendarStartDate } = InnerContext.useState()
  const { mapMinutesToPixels, mapPixelsToMinutes, snapToPixels } = InnerContext.useActions()

  const [layoutData] = useLayout()
  const [isDragging, setIsDragging] = React.useState(false)
  const maneuverRoom = 150;

  React.useEffect(() => {
    setIsDragging(layoutData !== null)
  }, [layoutData])

  React.useEffect(() => {
    const { current } = containerRef

    if (current !== null && isDragging) {
      const boundingRect = current.getBoundingClientRect()
      let mouseDirection: number | null = null
      const scrollSpeed = Constants.DEFAULT_SCROLL_SPEED

      const scroll = () => {
        if (mouseDirection !== null) {
          current.scrollBy(0, scrollSpeed * mouseDirection)
          window.requestAnimationFrame(scroll)
        }
      }

      const handleMouseEnter = (event: MouseEvent) => {
        mouseDirection = null
      }
      const handleMouseLeave = (event: MouseEvent) => {
        if (event.clientY <= boundingRect.top + maneuverRoom) {
          mouseDirection = -1
          scroll()
        }
        if (event.clientY >= boundingRect.bottom - maneuverRoom) {
          mouseDirection = 1
          scroll()
        }

        if (mouseDirection) {
          current.addEventListener('mouseenter', handleMouseEnter)
        }
      }

      current.addEventListener('mouseleave', handleMouseLeave)
      return () => {
        mouseDirection = null
        current.removeEventListener('mouseleave', handleMouseLeave)
        current.removeEventListener('mouseenter', handleMouseEnter)
      }
    }
    return () => { }
  }, [containerRef, isDragging])

  // If we are not currently dragging anything, do not render
  if (layoutData === null) {
    return null
  }

  const { item, collection } = layoutData

  const { differenceFromInitialOffset, initialOffset, mousePos } = collection

  const { meta, ...internal } = item as SessionProps

  const { left, top } = initialOffset
  const height = mapMinutesToPixels(internal.lengthInMinutes)

  let width = 0
  let startTimePixels = 0
  if (containerRef.current !== null) {
    const { scrollTop } = containerRef.current
    const boundingRect = containerRef.current.getBoundingClientRect()

    const fullWidth = boundingRect.width - Constants.LEFT_TIME_COLUMN_WIDTH - Constants.SCROLLBAR_WIDTH

    width = fullWidth / tracks.length
    startTimePixels = initialOffset.top + scrollTop - boundingRect.top
  }

  const snapStartDate = new Date(+calendarStartDate + mapPixelsToMinutes(snapToPixels(startTimePixels)) * 60000)

  let { dx, dy } = differenceFromInitialOffset

  const dragDateStart = new Date(+calendarStartDate + mapPixelsToMinutes(snapToPixels(startTimePixels + dy)) * 60000)
  let dragTimeStart = format(dragDateStart, 'HH:mm')

  const dragDateEnd = new Date(+calendarStartDate + mapPixelsToMinutes(snapToPixels(startTimePixels + dy + height)) * 60000)
  let dragTimeEnd = format(dragDateEnd, 'HH:mm')

  let isWorkSchedule = meta.metaType === "UserWorkScheduleMetaDataType"
  // If we are dragging an activity from side column we do not have a start date yet
  dy = snapToPixels(startTimePixels + dy) - startTimePixels

  if (isWorkSchedule) {
    return (
      <div
        style={{
          transform: `translate(-${width/2}px, ${Math.round(dy)}px)`,
          left: `${mousePos.x}px`,
          top: `${top - 20}px`,
          width: `${width}px`,
          height: `${60}px`,
          position: 'fixed',
          zIndex: 100000,
          pointerEvents: 'none',
        }}>
        {renderSession &&
          meta &&
          internal &&
          renderSession({
            internal: { ...internal, startDate: snapStartDate },
            meta,
          })}
      </div>
    )
  } else {

    return (
      <div
        style={{
          transform: `translate(-${width/2}px, ${Math.round(dy)}px)`,
          left: `${mousePos.x}px`,
          top: `${top - 20}px`,
          width: `${width}px`,
          height: `${height}px`,
          position: 'fixed',
          zIndex: 100000,
          pointerEvents: 'none',
        }}>
        <div
          style={{
            width: `${width / 2}px`,
            height: '20px',
            backgroundColor: '#bdbdbd',
            textAlign: 'center',
            margin: 'auto',
            borderRadius: '5px 5px 0 0',
          }}>
          <Typography
            variant={'subtitle2'}
            sx={{
            color: 'black',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
            }}
            style={{}}
            >
            {dragTimeStart}
          </Typography>
        </div>
        {renderSession &&
          meta &&
          internal &&
          renderSession({
            internal: { ...internal, startDate: snapStartDate },
            meta,
          })}
        <div
          style={{
            width: `${width / 2}px`,
            height: '20px',
            backgroundColor: '#bdbdbd',
            textAlign: 'center',
            margin: 'auto',
            borderRadius: '0 0 5px 5px',
          }}>
          <Typography
            variant={'subtitle2'}
            sx={{
            color: 'black',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
            }}
            style={{}}
            >
            {dragTimeEnd}
          </Typography>
        </div>
      </div>
    )
  }

})
