import { Box, Paper } from '@mui/material'
import { ModalProps } from '@mui/material/Modal'
import React from 'react'
import {
  FlattenSimpleInterpolation,
} from 'styled-components'
import CenterModalStyle from './center-modal-style'
import { useViewSize } from './utilities/use-view-size'

const CenterModalContainer: React.FC<{
  children?: React.ReactNode
  fullSize?: boolean
  responsive?: boolean
  adminPageResponsive?:boolean
  css?: FlattenSimpleInterpolation
} & Omit<ModalProps, 'children'>> = ({ children, fullSize = false, responsive , ...rest }) => {
  const { width, height } = useViewSize()
  return (
    <CenterModalStyle {...rest} fullSize={fullSize} width={`${width}vw`} height={`${height}vh`} responsive={responsive}>
      <Paper>
        <Box display="flex" flexDirection="column" height="100%" width="100%" sx={{boxSizing:"border-box",overflow:'hidden'}}>
          {children}
        </Box>
      </Paper>
    </CenterModalStyle>
  )
}

export default CenterModalContainer
