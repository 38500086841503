import React, { FC } from 'react'
import { useFormatMessage } from 'localization'
import { IReportSelectionItem, UserReportSelectionItem } from 'api/tenilo.reporting.api-client'
import { Box } from '@mui/material'
import ReportsPageContext from 'pages/reports/reports-page-context'
import { ReportSelectList, ReportDatePicker, ReportToggleButton } from '../reports-page-inputs'


const ReportsPageSelectionView: FC<IReportSelectionItem & { userReportSelections?: UserReportSelectionItem }> = ({ selectionId, multiple, id, required, interval, selection, userReportSelections }) => {
  const f = useFormatMessage()
  const { reportSelectionListItem } = ReportsPageContext.useState()

  const getInitialDate = (int: number) => {
    if (!userReportSelections) { return undefined }
    const stringValues = userReportSelections.selectedValues.split('@Sep@')
    if (stringValues[int]) { return Number(stringValues[int]) }
    return undefined
  }

  return (
    <Box sx={{
      width: '50%',
      margin: '0px 0px 10px 0'
    }}>
      {selection.isList && (
        <ReportSelectList
          multiple={multiple}
          options={reportSelectionListItem?.selectionItems.find(item => item.reportSelectionId === id)?.selectionValues || []}
          lableKey="value"
          textFieldLable={f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${selectionId}`)}
          name={selectionId}
          required={required}
          initialValues={userReportSelections}
          includedInForm
        />
      )}

      {selection.isDate && (
        <>
          {interval ?
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <ReportDatePicker
                textFieldLable={f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${selectionId}_From`)}
                name={selectionId}
                required={required}
                initialValues={getInitialDate(0)}
              />
              <ReportDatePicker
                textFieldLable={f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${selectionId}_To`)}
                name={`${selectionId}-to`}
                required={required}
                initialValues={getInitialDate(1)}
              />
            </Box>
            :
            <ReportDatePicker
              textFieldLable={f(`REPORT_PAGE_VIEW_FILTER_SELECTION_${selectionId}`)}
              name={selectionId}
              required={required}
              initialValues={getInitialDate(0)}
            />
          }
        </>
      )}
      {selection.isOption && (
        <ReportToggleButton
          options={reportSelectionListItem?.selectionItems.find(item => item.reportSelectionId === id)?.selectionValues || []}
          name={selectionId}
          initialValues={userReportSelections}
          required={required}
          exclusive
        />
      )}
    </Box>
  )
}

export default React.memo(ReportsPageSelectionView)
