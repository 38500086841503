import { useActivityApi } from 'api'
import { FuturePlannedActivityItem } from 'api/tillit.api-client'

import React from 'react'
import { createContext } from 'utilities/create-context'
import usePromiseApi from 'utilities/use-promise'

const useFutureModalContext = () => {
  const { listFuturePlannedActivitiesOnCustomer } = useActivityApi()

  const { state: fetchState, fetchAsync } = usePromiseApi()

  const [futurePlannedActivities, setFuturePlannedActivities] = React.useState<FuturePlannedActivityItem[]>()
  const [futureModalOpen, setFutureModalOpen] = React.useState<boolean>(false)
  const [filteredActivities, setFilteredActivities] = React.useState<FuturePlannedActivityItem[] | undefined>(undefined)
  const [filterReccuranceType, setFilterReccuranceType] = React.useState<string | null>('all')

  const listFuturePlannedActivities = React.useCallback(
    (customerId: number) => {
      void fetchAsync(listFuturePlannedActivitiesOnCustomer(customerId)).then(list => {
        setFuturePlannedActivities(list)
        setFilteredActivities(list)
      })
    },
    [fetchAsync, listFuturePlannedActivitiesOnCustomer, setFuturePlannedActivities]
  )

  const openFutureModal = React.useCallback(
    (customerId: number) => {
      listFuturePlannedActivities(customerId)
      setFutureModalOpen(true)
    },
    [listFuturePlannedActivities]
  )

  const closeFutureModal = React.useCallback(() => {
    setFuturePlannedActivities([])
    setFilteredActivities(undefined)
    setFutureModalOpen(false)
    setFilterReccuranceType('all')
  }, [])

  return {
    state: {
      fetchState,
      futurePlannedActivities,
      futureModalOpen,
      filteredActivities,
      filterReccuranceType,
    },
    actions: {
      openFutureModal,
      closeFutureModal,
      setFilteredActivities,
      setFilterReccuranceType,
    },
  }
}

export const FutureModalContext = createContext(useFutureModalContext)
