import Page, { PageHeader } from 'components/page'
import { useFormatMessage } from 'localization'
import React from 'react'
import { NotificationContainer } from 'components/notification'

import { Typography } from '@mui/material'
import { Tab, Tabs as MuiTabs } from '@mui/material'
import styled from 'styled-components'
import { Box } from '@mui/material'
import CustomersActiveView from './customers-active-container'
import CustomersInactiveView from './customers-inactive-container'

const Tabs = styled(MuiTabs)`
  box-shadow: inset 0 -2px 2px -2px gray;
  width: 100vw;
`
type ITabPanelProps = Readonly<{
  index: number
  value: number
}>

const CustomersPageView: React.FC = () => {
  const f = useFormatMessage()
  const [mainTabValue, setMainTabValue] = React.useState(0)

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setMainTabValue(newValue)
  }

  const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index, ...other }) => {
    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {children}
      </Typography>
    )
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <NotificationContainer>
      <Page>
        <PageHeader
          overline={f('CUSTOMERS_PAGE_VIEW_HEAD_OVERLINE')}
          title={f('CUSTOMERS_PAGE_VIEW_HEAD_TITLE')}
          description={f('CUSTOMERS_PAGE_VIEW_HEAD_DESCRIPTION')}
        />

        <Box display="flex" mx={-5}>
          <>
            <Box overflow="hidden">
              <Tabs indicatorColor="primary" textColor="primary" value={mainTabValue} onChange={handleChange} centered={true}>
                <Tab label={f('CUSTOMERS_PAGE_VIEW_TAB_ACTIVE_CUSTOMERS')} {...a11yProps(0)} />
                <Tab label={f('CUSTOMERS_PAGE_VIEW_TAB_INACTIVE_CUSTOMERS')} {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={mainTabValue} index={0}>
                {mainTabValue === 0 && (
                  <CustomersActiveView />
                )}
              </TabPanel>
              <TabPanel value={mainTabValue} index={1}>
                {mainTabValue === 1 && (
                  <CustomersInactiveView />
                )}
              </TabPanel>
            </Box>
          </>
        </Box>

      </Page >
    </NotificationContainer>
  )
}

export default CustomersPageView
