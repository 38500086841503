import { Box, Button, Typography } from '@mui/material'
import { IUserListItem, PlannedActivityStatusEnum } from 'api/tillit.api-client'
import { useFormatMessage } from 'localization'
import { Information } from 'mdi-material-ui'
import React from 'react'
import Colors from 'styles/colors'
import format from 'utilities/format'

export type InfoSignedComponentProps = Readonly<{
  onUndoClick: () => void
  status: PlannedActivityStatusEnum
  signedDate: Date | undefined
  signedUser: IUserListItem
  signedComment: string
  className?: string
}>

const getColor = (status: PlannedActivityStatusEnum): string =>
  status === PlannedActivityStatusEnum.Completed
  ? Colors.JungleGreen
  : status === PlannedActivityStatusEnum.CompletedWithoutAction
  ? Colors.Punch
  : status === PlannedActivityStatusEnum.NotCompleted
  ? Colors.JellyBean
  : status === PlannedActivityStatusEnum.Delayed
  ? Colors.TeniloBrown
  : status === PlannedActivityStatusEnum.CannotTake
  ? Colors.Punch
  : status === PlannedActivityStatusEnum.Given
  ? Colors.JungleGreen
  : status === PlannedActivityStatusEnum.RelativeGiven
  ? Colors.JungleGreen
  : status === PlannedActivityStatusEnum.SelfGiven
  ? Colors.JungleGreen
  : status === PlannedActivityStatusEnum.SentAlong
  ? Colors.JungleGreen
  : status === PlannedActivityStatusEnum.NotGiven
  ? Colors.Punch
  : status === PlannedActivityStatusEnum.PartiallyComplete
  ? Colors.TeniloYellow
  : status === PlannedActivityStatusEnum.PartiallyGiven
  ? Colors.TeniloYellow
  : status === PlannedActivityStatusEnum.Started
  ? Colors.LightBlue
  : ''

const getTitleId = (status: PlannedActivityStatusEnum): string =>
  status === PlannedActivityStatusEnum.Completed
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_DONE_INFO_TEXT'
    : status === PlannedActivityStatusEnum.CompletedWithoutAction
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_NOTDONE_INFO_TEXT'
    : status === PlannedActivityStatusEnum.NotCompleted
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SIGNED_UNSIGNED_INFO_TEXT'
    : status === PlannedActivityStatusEnum.Delayed
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_DELAYED_INFO_TEXT'
    : status === PlannedActivityStatusEnum.CannotTake
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_CANNOT_TAKE_INFO_TEXT'
    : status === PlannedActivityStatusEnum.Given
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_GIVEN_INFO_TEXT'
    : status === PlannedActivityStatusEnum.RelativeGiven
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_RELATIVE_GIVEN_INFO_TEXT'
    : status === PlannedActivityStatusEnum.SelfGiven
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SELF_GIVEN_INFO_TEXT'
    : status === PlannedActivityStatusEnum.SentAlong
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_SENT_ALONG_INFO_TEXT'
    : status === PlannedActivityStatusEnum.NotGiven
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_NOT_GIVEN_INFO_TEXT'
    : status === PlannedActivityStatusEnum.PartiallyComplete
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_PARTIALLY_COMPLETE_INFO_TEXT'
    : status === PlannedActivityStatusEnum.PartiallyGiven
    ? 'DAILY_ACTIVITIES_PAGE_SINGLE_ACTIVITY_DIALOG_PARTIALLY_GIVEN_INFO_TEXT'
    : status === PlannedActivityStatusEnum.Started
    ? 'DAILY_ACTIVITIES_ITEM_CONTAINER_PLANNED_ACTIVITY_TEXT_STARTED'
    : 'UNKNOWN STATUS'

export const InfoSignedView: React.SFC<InfoSignedComponentProps> = ({ onUndoClick, signedDate, signedUser, signedComment, className, status }) => {
  const f = useFormatMessage()
  const userName = `${signedUser.firstName} ${signedUser.lastName}`

  const color = getColor(status)
  const title = f(getTitleId(status))
  const by = f('SINGLE_ACTIVITY_MODAL_BY')

  return (
    <Box className={className} py={1} px={1}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Information style={{ color }} />
          <Box pr={1} />
          <Typography data-matomo-mask variant="subtitle1" style={{ color }}>
            {`${title} ${by} ${userName}`}
          </Typography>
        </Box>
        <Typography variant="subtitle1" style={{ opacity: 0.6 }}>
          {signedDate ? format(signedDate, 'PPP p') : ''}
        </Typography>
      </Box>
      {signedComment && <Typography data-matomo-mask className="textGray">{signedComment}</Typography>}
      {status !== PlannedActivityStatusEnum.NotCompleted && (
        <Button className="buttonUndo" color="secondary" onClick={onUndoClick}>
          {f('SINGLE_ACTIVITY_MODAL_UNDO')}
        </Button>
      )}
    </Box>
  )
}
